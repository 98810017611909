import { API_URL } from 'config/constants';

const downloadAttributes = async (
    attributeDefinitonIds: string[],
    entityCodes: string[]
): Promise<Blob> => {
    const response = await fetch(`${API_URL}/attributes/download`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            Accept: 'text/csv',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            attributeDefinitonIds,
            entityCodes,
        }),
    });

    if (!response.ok) {
        throw new Error('Failed to download attributes');
    }

    return await response.blob();
};

export default downloadAttributes;
