import { API_URL } from 'config/constants';
import { ClientMode } from 'waypoint-types';

const getClientModes = async (): Promise<ClientMode[]> => {
    const response = await fetch(`${API_URL}/client/modes`, {
        credentials: 'include',
    });
    const { data } = await response.json();
    return data;
};

export default getClientModes;
