import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { Note } from 'waypoint-types';
import useSWR, { SWRConfiguration } from 'swr';
import { getNotesByReportMetadataId } from 'waypoint-requests';

export const useGetNotesByReportMetadataId = (
    reportMetadataId: string,
    config?: SWRConfiguration
): DataHookResponse<Note[]> => {
    const { data, error, mutate } = useSWR(
        reportMetadataId
            ? `/notes-by-report-metadata-id/${reportMetadataId}`
            : null,
        () => {
            return getNotesByReportMetadataId(reportMetadataId);
        },
        config
    );

    return {
        data,
        mutate,
        isLoading: !data,
        isError: !!error,
    };
};
