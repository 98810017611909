import { css } from 'emotion';
import { PropertyDetailsPropsWithTotals } from 'waypoint-types';

export const removeCardHeaderClass = css`
    .ant-card-head {
        border: none;
        height: 0;
        min-height: 0 !important;
    }
    box-shadow: none;
`;
export const EXPIRATION_DASHBOARD_DEVEXTREME_TABLE_HEIGHT = 605; // remove when converting expiration schedule card
export const EXPIRATION_DASHBOARD_SMALL_CARD_HEIGHT = 150;
export const PROPERTY_LEASE_TABLE = 'property-lease-details-table';

export type PropertyDetailsValuesType = {
    [key in Exclude<
        keyof PropertyDetailsPropsWithTotals,
        | 'key'
        | 'entity_code'
        | 'entity_codes'
        | 'monthly_in_place_base_rent_per_sq_ft'
        | 'in_place_base_rent_per_sq_ft'
        | 'property_name'
        | 'in_place_base_rent_sq_ft'
        | 'in_place_base_rent_occupied_sq_ft'
        | 'in_place_base_rent_pounit'
        | 'in_place_base_rent_punit'
        | 'monthly_in_place_base_rent_pounit'
        | 'monthly_in_place_base_rent_punit'
        | 'in_place_base_rent'
        | 'monthly_in_place_base_rent'
        | 'monthly_in_place_base_rent_occupied_sq_ft'
    >]: string;
};

export const propertyDetailsValues: PropertyDetailsValuesType = {
    lease_count: 'Lease Count',
    occupancy_rate: 'Occupancy %',
    occupied_sq_ft: 'Occupied Area',
    occupied_units: 'Occupied Units',
    rentable_sq_ft: 'Rentable SF',
    total_units: 'Total Units',
    vacant_area: 'Vacant Area',
    vacant_units: 'Vacant Units',
    wale: 'WALE',
    total_annual_per_sq_ft: 'Annual Charges Per Occupied SF',
    total_annual_per_unit: 'Annual Charges Per Unit',
    total_annual_per_occupied_unit: 'Annual Charges Per Occupied Unit',
    total_annual: 'Annual Charges',
    total_monthly_per_sq_ft: 'Monthly Charges Per Occupied SF',
    total_monthly_per_unit: 'Monthly Charges Per Unit',
    total_monthly_per_occupied_unit: 'Monthly Charges Per Occupied Unit',
    total_monthly: 'Monthly Charges',
};

export enum KPISortByOptions {
    ALPHABETICAL = 'alpha',
    LEFT_AXIS = 'left',
    RIGHT_AXIS = 'right',
}

export enum KPISortDirections {
    ASCENDING = 'asc',
    DESCENDING = 'desc',
}
