import React from 'react';
import { css } from 'emotion';
import theme from 'config/theme';
import EmptyState from 'components/style/EmptyState';

const page = css`
    display: block;
    background-color: ${theme.colors.grays.background};
    height: 100%;
    padding: 0;
    width: 100%;
`;

const emptyStateContainer = css`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const DisabledFinancialOverviewDashboard = ({
    text = 'No dashboard configuration',
}) => {
    return (
        <div className={page}>
            <div className={emptyStateContainer}>
                <EmptyState
                    headerText={text}
                    icon="assets/img/Analytics-Icon-Gray@2x.png"
                />
            </div>
        </div>
    );
};

export default DisabledFinancialOverviewDashboard;
