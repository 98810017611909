export const onContentReady = ({
    e,
    toggleFunc,
}: {
    e: any;
    toggleFunc: (condition: boolean) => void;
}) => {
    // detect group columns applied in columns
    let columns = e.component.getVisibleColumns();
    let groupingIsNotAppliedColumns = true;
    for (let i = 0; i < columns.length; i++) {
        if (columns[i].groupIndex !== undefined) {
            toggleFunc(false);
            groupingIsNotAppliedColumns = false;
            break;
        }
    }

    if (groupingIsNotAppliedColumns) {
        toggleFunc(true);
    }
};
