import React, { useContext } from 'react';
import { css, cx } from 'emotion';
import { isNil } from 'lodash';
import { Button, Popover } from 'antd';

import { DASH_DASH } from 'config/constants';
import {
    useClientPrefixedLocalStorage,
    useGetSelectedEntityCodes,
    useGetFilteredEntityCodes,
} from 'waypoint-hooks';
import {
    EntityAttributesContext,
    EntityAttributesContextType,
    SavedFilter,
} from 'contexts';
import { ACTIVE_GLOBAL_FILTER_KEY } from 'components/app/global-filter-drawer/GlobalFilterConstants';
import { ScrollablePropertyNameList } from 'components/app/global-filter-drawer/ScrollablePropertyNameList';
import { clearQueryParam } from 'waypoint-hooks/useGetQueryParam';

const selectedContentStyle = css`
    text-decoration: underline;
`;

const container = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    padding-left: 10px;

    #text {
        font-size: 17px;
        font-weight: bold;
    }
    .ant-skeleton {
        display: flex !important;
    }
`;

const clearFilterStyle = css`
    width: auto;
    margin-left: 10px;
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    #clear_filters_button {
        margin-left: 20px;
    }
`;

type AppliedFilterType = SavedFilter | null;

const ClearFilters = (): JSX.Element => {
    const entityAttributesContext =
        useContext<EntityAttributesContextType | null>(EntityAttributesContext);

    const [appliedAttributeFilters, setAppliedGlobalFilter] =
        useClientPrefixedLocalStorage<AppliedFilterType>(
            ACTIVE_GLOBAL_FILTER_KEY,
            null,
        );

    const [selectedEntityCodes, setSelectedEntityCodes] =
        useGetSelectedEntityCodes();
    const filteredEntityCodes = useGetFilteredEntityCodes();

    const entities = entityAttributesContext?.data?.entities;

    const clearAllFilters = () => {
        const entitiesCodeArray: string[] =
            (entities
                ?.filter((x) => typeof x.entityCode === 'string')
                .map((x) => x.entityCode) as string[]) ?? [];

        setAppliedGlobalFilter(null);
        setSelectedEntityCodes(entitiesCodeArray);
    };

    const selectedCopy = !isNil(selectedEntityCodes.length)
        ? selectedEntityCodes.length
        : DASH_DASH;

    const entityCopy = !isNil(entities?.length) ? entities?.length : DASH_DASH;

    const matchedCount = `${filteredEntityCodes.filteredEntityCodes.length}`;
    const matchedCopy = `${matchedCount} of ${entityCopy} matched`;

    const isEntitiesSelected = entities?.length === selectedEntityCodes.length;

    const showClearFilterButton =
        !isEntitiesSelected || appliedAttributeFilters?.filters;

    const selectedContent = (
        <Popover
            content={<ScrollablePropertyNameList />}
            title={'Properties Selected'}
            trigger={'hover'}
            mouseEnterDelay={0.3}
        >
            <span className={selectedContentStyle}>
                {selectedCopy} of {matchedCount} selected
            </span>
        </Popover>
    );

    return (
        <div className={cx([container, clearFilterStyle])}>
            <div>
                <span id="text">
                    {matchedCopy} ({selectedContent})
                </span>
            </div>
            {showClearFilterButton && (
                <Button
                    id="clear_filters_button"
                    danger
                    ghost
                    size="small"
                    onClick={() => {
                        clearQueryParam('comment_id');
                        clearAllFilters();
                    }}
                >
                    Clear Filters
                </Button>
            )}
        </div>
    );
};

export default ClearFilters;
