import { API_URL } from 'config/constants';
import { Report } from 'waypoint-types';

const updateEntityReport = async (body: any): Promise<Report> => {
    const response = await fetch(`${API_URL}/reports/entity-reports`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(body),
    });

    if (!response.ok) {
        throw new Error('Failed to update entity report');
    }

    const { data } = await response.json();

    return data as Report;
};

export default updateEntityReport;
