import { css } from 'emotion';
import { GRAY } from 'config/colors';
import theme from 'config/theme';

export const page = css`
    display: block;
    background-color: ${GRAY};
    height: 100%;
    padding: 0;
`;

export const monthPickerStyle = css`
    width: 100%;
    .ant-input {
        width: 230px;
        cursor: pointer;
        padding: 5px 10px;
        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-top-width: 1.02px;
        border-radius: 4px;
    }
`;

export const dateRangeContainer = css`
    position: fixed;
    top: 78px;
    right: 75px;
    z-index: 4;
    > div {
        padding: 0;
    }
`;

export const actionButtons = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const subheading = css`
    font-size: 14px;
    font-weight: bold;
    text-style: uppercase;
`;

export const cardChartBox = css`
    min-height: 500px;
    max-height: 1000px;
    border: 1px solid #e8eaeb;
    margin-top: 12px;
`;

export const cardPieChartBox = css`
    min-height: 500px;
    max-height: 1000px;
    min-width: 350px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    row-gap: 10px;
    @media (max-width: 1850px) {
        margin-top: 10px;
        margin-bottom: 10px;
    }
`;

export const fillerCardHeight = css`
    min-height: calc(100vh - 220px);
    display: flex;
    flex-direction: column;
    .ant-card-body {
        flex: 1;
        justify-content: center;
        align-items: center;
        display: flex;
    }
`;

export const menuContent = css`
    .ant-card-body {
        padding-top: 10px;
    }

    h3 {
        text-transform: uppercase;
        color: ${theme.colors.grays.dark};
        font-weight: 400;
    }
`;

export const menuControls = css`
    display: flex;
    width: 100%;
    padding: 0px;
    justify-content: space-between;
`;

export const menuSubControls = css`
    display: flex;
    justify-content: space-between;
    width: 175px;
`;

export const switchChartStyle = css`
    font-size: 12px;
    font-family: 'Lato';
    line-height: 18px;
    color: ${theme.colors.blues.primary};
    padding: 6.5px 18px;
    border: 1px solid ${theme.colors.blues.primary};
    text-decoration: none;
    &.active,
    &:hover {
        text-decoration: none;
        color: #fff;
        background-color: ${theme.colors.blues.primary};
    }
    &:nth-last-child(2) {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-right: none;
    }
    &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-left: none;
    }
`;

export const inlineChartSelectStyle = css`
    display: inline-block;
    marginright: 10px;
`;
