import React, {
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from 'react';
import { PDFExportable } from 'waypoint-utils/pdf/PDFExportable';
import getActualsGrossMonthlyData from 'waypoint-requests/leases/getActualsGrossMonthlyData';
import useSWR from 'swr';
import { OvertimeType } from 'waypoint-types';
import { message, Skeleton } from 'antd';
import OccupancyTrendChart from 'components/leases/expirations/cards/occupancy-trend/OccupancyTrendChart';
import {
    getAccountGraph,
    getAsOfDates,
    getOccupancyHistory,
} from 'waypoint-requests';
import { OccupancyTrendGrid } from 'components/leases/expirations/cards/occupancy-trend/OccupancyTrendGrid';
import moment, { Moment } from 'moment';
import {
    decorateAccountGraphForAntDesign,
    getDateRangeForPeriod,
} from 'waypoint-utils';
import { AccountingAsOfDateType } from '../../../app/as-of-date';
import { EntityReportWidgetPdfSettings } from '../../ReportUtils';
import { css } from 'emotion';

interface OccupancyTrendReportWidgetParams {
    entityCode: string;
    widgetId: string;
    widgetSettings: { [key: string]: any };
    widgetType: string;
    narrativePosition?: string;
    narrativeText?: string;
    isPDFExport?: boolean;
    pdfSettings?: EntityReportWidgetPdfSettings;
}

export const OccupancyTrendReportWidget = React.forwardRef<
    PDFExportable,
    OccupancyTrendReportWidgetParams
>(
    (
        {
            entityCode,
            widgetId,
            widgetSettings,
            widgetType,
            isPDFExport,
            narrativePosition,
            narrativeText,
            pdfSettings,
        }: OccupancyTrendReportWidgetParams,
        ref,
    ) => {
        const fetchAccountGraphData = async () => {
            const data = await getAccountGraph(undefined, {
                stakeholder: null,
                percentageType: null,
            });
            return decorateAccountGraphForAntDesign(data.children);
        };

        const fetchAsOfDatesData = async () => {
            return await getAsOfDates([entityCode]);
        };

        const [isChartReadyToExport, setIsChartReadyToExport] =
            useState<boolean>(false);

        const { data: accountGraphData, isValidating: isLoadingAccountGraph } =
            useSWR(
                !widgetSettings.accountMappingSelection.name
                    ? 'accountGraph'
                    : null,
                fetchAccountGraphData,
                {
                    revalidateOnFocus: false,
                    revalidateOnMount: true,
                },
            );

        const { data: asOfDatesData, isValidating: isLoadingPeriodRange } =
            useSWR(
                !widgetSettings.periodRange ? 'asOfDates' : null,
                fetchAsOfDatesData,
                {
                    revalidateOnFocus: false,
                    revalidateOnMount: true,
                },
            );

        const updatedWidgetSettings = useMemo(() => {
            if (!accountGraphData && !asOfDatesData) {
                return widgetSettings;
            }

            let accountMappingSelection, periodRange;

            if (accountGraphData) {
                accountMappingSelection = {
                    code: accountGraphData[0].account_mapping_code,
                    name: accountGraphData[0].name,
                };
            }

            if (asOfDatesData) {
                const findLatestActualDate = (
                    dates: AccountingAsOfDateType[],
                ): string | undefined => {
                    const actual = dates.find(
                        (item) => item.label === 'Actual',
                    );
                    if (actual) {
                        return actual.period_end;
                    }
                    return undefined;
                };

                const getDefaultGlobalPeriodForEntity = (
                    periodEnd: Date,
                ): [Moment, Moment] => {
                    const [startDate, endDate] = getDateRangeForPeriod(
                        'trailing_12',
                        periodEnd,
                    );
                    return [moment(startDate), moment(endDate)];
                };

                const { accounting } = asOfDatesData;
                const latestActualPeriodEnd = findLatestActualDate(accounting);
                if (!latestActualPeriodEnd) {
                    message.error('An error occurred while loading as of date');
                    return;
                }
                periodRange = getDefaultGlobalPeriodForEntity(
                    new Date(latestActualPeriodEnd),
                );
            }

            return {
                ...widgetSettings,
                accountMappingSelection:
                    accountMappingSelection ||
                    widgetSettings.accountMappingSelection,
                periodRange: periodRange || widgetSettings.periodRange,
            };
        }, [accountGraphData, asOfDatesData, widgetSettings]);

        const [actualsData, setActualsData] = useState<OvertimeType[]>();

        const fetchActualsData = async (updatedWidgetSettings: {
            [key: string]: any;
        }) => {
            if (
                updatedWidgetSettings.periodRange &&
                updatedWidgetSettings.accountMappingSelection
            ) {
                return getActualsGrossMonthlyData({
                    entity_codes: [entityCode],
                    account_mapping_code:
                        updatedWidgetSettings.accountMappingSelection.code,
                    account_mapping_name:
                        updatedWidgetSettings.accountMappingSelection.name,
                    start_date: updatedWidgetSettings.periodRange
                        ? moment(updatedWidgetSettings.periodRange[0]).format(
                              'YYYY-MM-DD',
                          )
                        : null,
                    end_date: updatedWidgetSettings.periodRange
                        ? moment(updatedWidgetSettings.periodRange[1])
                              .endOf('month')
                              .format('YYYY-MM-DD')
                        : null,
                    display_type: 'gross',
                    periodicity: 'monthly',
                    selected_data_level: {
                        stakeholder: null,
                        percentageType: null,
                    },
                });
            }

            return null;
        };

        const { data, isValidating: isLoadingActualsData } = useSWR(
            updatedWidgetSettings?.periodRange &&
                updatedWidgetSettings?.accountMappingSelection
                ? JSON.stringify(updatedWidgetSettings)
                : null,
            () => fetchActualsData(updatedWidgetSettings ?? widgetSettings),
            {
                revalidateOnFocus: false,
                revalidateOnMount: true,
            },
        );
        const {
            data: occupancyHistory,
            isValidating: isLoadingOccupancyHistory,
        } = useSWR(`${entityCode}-property-detail-occupancy-trend`, () =>
            getOccupancyHistory([entityCode]),
        );

        useEffect(() => {
            if (data) {
                setActualsData(data);
            }
        }, [data]);

        useImperativeHandle(ref, () => ({
            isReadyToExport(): boolean {
                return (
                    isChartReadyToExport &&
                    !isLoadingAccountGraph &&
                    !isLoadingPeriodRange &&
                    !isLoadingActualsData &&
                    !isLoadingOccupancyHistory &&
                    !!updatedWidgetSettings
                );
            },
        }));

        if (
            !updatedWidgetSettings ||
            isLoadingAccountGraph ||
            isLoadingPeriodRange
        ) {
            return (
                <Skeleton
                    loading={true}
                    active={true}
                    paragraph={{ rows: 1 }}
                />
            );
        }

        const period: [Moment, Moment] = updatedWidgetSettings.periodRange
            ? [
                  moment(updatedWidgetSettings.periodRange[0]),
                  moment(updatedWidgetSettings.periodRange[1]),
              ]
            : [moment(new Date()), moment(new Date())];

        const chartExportId = `chartOccTrend_${widgetType}_${widgetId}`;
        const gridExportId = `gridOccTrend_${widgetType}_${widgetId}`;

        let exportWidth = '1120px';
        if (pdfSettings?.landscape) {
            exportWidth =
                pdfSettings?.paperSize === 'Legal' ? '1910px' : '1480px';
        }

        const hideIcons = css`
            background-color: white;
            position: absolute;
            right: 0;
            margin-top: -7px;
            width: 150px;
            height: 35px;
            z-index: 10;
        `;

        return (
            <>
                <div
                    style={{
                        width: isPDFExport ? exportWidth : '100%',
                        marginTop: 10,
                    }}
                    id={chartExportId}
                >
                    {/* 
                        This is the only way to hide the icons from the chart zoomlinedy
                        Even the built-in exporter does not remove those icons
                    */}
                    <div
                        style={{ display: isPDFExport ? 'block' : 'none' }}
                        className={hideIcons}
                    ></div>
                    <OccupancyTrendChart
                        occupancyHistory={occupancyHistory ?? []}
                        actualsData={actualsData ?? []}
                        periodRange={period}
                        accountName={
                            updatedWidgetSettings.accountMappingSelection.name
                        }
                        isDataLoading={!isLoadingActualsData}
                        isPDFExport={isPDFExport}
                        setIsChartReadyToExport={
                            isPDFExport ? setIsChartReadyToExport : undefined
                        }
                    />
                </div>
                <div style={{ marginRight: '10px' }} id={gridExportId}>
                    {occupancyHistory?.length ? (
                        <OccupancyTrendGrid
                            occupancyHistory={occupancyHistory ?? []}
                            periodRange={updatedWidgetSettings.periodRange}
                            isPDFExport={isPDFExport}
                        />
                    ) : null}
                </div>
            </>
        );
    },
);
