import React, { PureComponent } from 'react';
import { range } from 'lodash';
import { css, cx } from 'emotion';

const width = '100%';
const height = '50px';
const col1Width = '250px';

// TODO (Daniel): move these back into the style prop
const shimmer = css`
    background-size: 2000px 500px !important;
    animation-name: wideShimmer !important;
    height: 100% !important;
    width: 100% !important;
`;

function LoadingTableRow({ rowIndex, numCols = 4 }) {
    const isEven = rowIndex % 2 === 0;
    const textWidth = isEven ? 200 : 150;
    const backgroundColor = isEven ? '' : '#fff';
    return (
        <div
            style={{
                display: 'flex',
                width: '100%',
                height,
                backgroundColor,
                justifyContent: 'space-between',
            }}
        >
            <div
                style={{
                    width: col1Width,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <TextPlaceholder textWidth={200} />
            </div>
            <div
                style={{
                    marginLeft: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    paddingRight: '15px',
                    width: '600px',
                }}
            >
                {range(numCols).map((item) => {
                    return <TextPlaceholder key={item} textWidth={textWidth} />;
                })}
            </div>
        </div>
    );
}

function TextPlaceholder({ textWidth }) {
    return (
        <span
            style={{
                width: `${textWidth}px`,
                height: '7px',
                marginLeft: '25px',
                borderRadius: '4px',
                backgroundColor: '#d2d2d2',
            }}
        />
    );
}

export default class LoadingTable extends PureComponent {
    render() {
        const { rowCount } = this.props;
        return (
            <div className={cx(['animated-background', shimmer])}>
                <div
                    style={{
                        height,
                        width,
                        borderBottom: '2px solid #ccc',
                        display: 'flex',
                        backgroundColor: '#fff',
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <LoadingTableRow rowIndex={1} />
                    </div>
                </div>
                {range(rowCount || 12).map((item) => {
                    return <LoadingTableRow rowIndex={item} key={item} />;
                })}
            </div>
        );
    }
}
