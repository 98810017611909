export const previousApplicationVersionKey =
    'waypointPreviousApplicationVersion';
export const newApplicationVersionKey = 'waypointNewApplicationVersion';

const getPreviousApplicationVersion = (): string => {
    return localStorage.getItem(previousApplicationVersionKey) ?? '';
};

const getNewApplicationVersion = (): string => {
    return localStorage.getItem(newApplicationVersionKey) ?? '';
};

export const applicationNeedsUpdate = (): boolean => {
    const previousApplicationVersion = getPreviousApplicationVersion();
    const newApplicationVersion = getNewApplicationVersion();

    if (!newApplicationVersion) {
        return false;
    }

    return previousApplicationVersion !== newApplicationVersion;
};

export const saveApplicationVersion = (newApplicationVersion: string) => {
    localStorage.setItem(newApplicationVersionKey, newApplicationVersion);
};

export const updateApplicationVersion = () => {
    localStorage.setItem(
        previousApplicationVersionKey,
        getNewApplicationVersion()
    );
};
