import React from 'react';
import { connect, RootStateOrAny } from 'react-redux';
import { Menu } from 'antd';
import { setClientCookie } from 'utils/cookie';
import { logoutAuth0andRedirect } from 'utils/authentication';
import { Link } from 'react-router-dom';
import {
    API_URL,
    ADMIN_SETTINGS_URL_SEGMENT,
    roles as userRoles,
    USER_PROFILE_SETTINGS_SEGMENT,
} from 'config/constants';
import {
    menuItemStyle,
    MenuSections,
    overrideMenuItems,
    sidebarDrawerMenuTitle,
} from '../AppLayoutUtils';

interface Client {
    id: number;
    name: string;
}

interface SettingsMenuParams {
    clients: Client[];
    isAdmin: boolean;
    pathname: string;
}

const SettingsMenu = ({ clients, isAdmin, pathname }: SettingsMenuParams) => {
    const logout = async () => {
        await fetch(`${API_URL}/logout`, {
            method: 'GET',
            credentials: 'include',
        });

        logoutAuth0andRedirect();
    };

    const renderAdminMenu = () => {
        return (
            <div>
                <h5
                    style={{
                        marginLeft: '15px',
                        marginTop: '30px',
                        marginBottom: '0px',
                        fontWeight: 'bold',
                    }}
                >
                    Administration
                </h5>
                <Menu.Item
                    key={'1'}
                    style={menuItemStyle(pathname, [
                        ADMIN_SETTINGS_URL_SEGMENT,
                    ])}
                >
                    <Link to={`${ADMIN_SETTINGS_URL_SEGMENT}/users`}>
                        Admin Panel
                    </Link>
                </Menu.Item>
            </div>
        );
    };

    return (
        <>
            {sidebarDrawerMenuTitle(MenuSections.Settings)}
            <h5
                style={{
                    marginLeft: '15px',
                    marginTop: '15px',
                    marginBottom: '0px',
                    fontWeight: 'bold',
                }}
            >
                Portfolio
            </h5>
            <Menu mode="vertical" className={overrideMenuItems}>
                {clients?.map((client) => {
                    return (
                        <Menu.Item
                            key={client.id}
                            onClick={() => {
                                // Clear sessionStorage to avoid stale client objects
                                window.sessionStorage.clear();
                                setClientCookie(client.id);
                            }}
                        >
                            <Link to={'/'}>{client.name}</Link>
                        </Menu.Item>
                    );
                })}
                {isAdmin && renderAdminMenu()}
                <h5
                    style={{
                        marginLeft: '15px',
                        marginTop: '30px',
                        marginBottom: '0px',
                        fontWeight: 'bold',
                    }}
                >
                    User Profile
                </h5>
                <Menu.Item
                    key={'2'}
                    style={menuItemStyle(pathname, [
                        USER_PROFILE_SETTINGS_SEGMENT,
                    ])}
                >
                    <Link to={USER_PROFILE_SETTINGS_SEGMENT}>My Profile</Link>
                </Menu.Item>
                <Menu.Item key={'3'} onClick={() => logout()}>
                    Log out
                </Menu.Item>
            </Menu>
        </>
    );
};

const mapState = (state: RootStateOrAny) => {
    const { userObj, roles } = state.user;
    const clients = userObj.clients;
    const isAdmin = roles.includes(userRoles.CLIENT_ADMIN);

    return {
        isAdmin,
        clients,
    };
};

export default connect(mapState)(SettingsMenu);
