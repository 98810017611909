import useSWR from 'swr';
import { getReportMetadataComments } from 'waypoint-requests';
import { GetReportMetadataCommentsParams } from 'waypoint-requests/report-metadata/getReportMetadataComments';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { ReportMetadata } from 'waypoint-types';

export const useGetReportMetadata = (
    params: GetReportMetadataCommentsParams | null
): DataHookResponse<ReportMetadata> => {
    const convertFiltersToKeySubstring = () => {
        if (params?.filters) {
            return params.filters.map((f) => JSON.stringify(f)).join('/');
        }
    };
    const key = params
        ? `/report-metadata/${params.reportType}/${params.referenceType}/${
              params.referenceId
          }/${convertFiltersToKeySubstring()}`
        : null;

    const { data, error, mutate } = useSWR(key, async () => {
        const { reportMetadata } = await getReportMetadataComments(
            params as GetReportMetadataCommentsParams
        );

        return reportMetadata;
    });

    return {
        isLoading: !data,
        isError: !!error,
        data,
        mutate,
    };
};
