import { ProseMirror } from '@progress/kendo-react-editor';
import { EditorView } from 'prosemirror-view';

type OnInsertCallback = (params: {
    view: EditorView;
    files: File[];
    event: ClipboardEvent | DragEvent;
}) => void;

export const insertImagePlugin = (onInsert: () => void) =>
    new ProseMirror.Plugin({
        props: {
            handleDOMEvents: {
                paste: (view, event) => insertImages(view, event, onInsert),
                drop: (view, event) => insertImages(view, event, onInsert),
            },
        },
        key: new ProseMirror.PluginKey('insert-image-plugin'),
    });

const insertImages = (
    view: EditorView,
    event: ClipboardEvent | DragEvent,
    onInsert: OnInsertCallback
) => {
    const items: Array<DataTransferItem | File> = [];
    if (event instanceof ClipboardEvent && event.clipboardData?.items) {
        items.push(...Array.from(event.clipboardData.items));
    }

    if (event instanceof DragEvent && event.dataTransfer?.files) {
        items.push(...Array.from(event.dataTransfer.files));
    }

    const files: File[] = [];
    Array.from(items).forEach((item) => {
        const isDataTransferItem = item instanceof DataTransferItem;
        const isFile = item instanceof File;

        if (
            isDataTransferItem &&
            item.kind === 'file' &&
            item.type.indexOf('image') !== -1
        ) {
            const file = item.getAsFile();
            if (file) {
                files.push(file);
            }
        }

        if (isFile && item.type.indexOf('image') !== -1) {
            files.push(item);
        }
    });

    if (files.length) {
        event.preventDefault();
        onInsert({ view, files, event });
    }
};
