import { compose } from 'redux';
import defaultStylePP from './defaultStylePP';
import filesizePP from './filesizePP';
import { DefaultCell } from './DefaultCellRenderer';

// NOTE: Do not need propTypes
//       Behavior and propTypes are held within the props-proxy HOC's
export const FileSizeCellRenderer = compose(
    defaultStylePP,
    filesizePP
)(DefaultCell);
export default FileSizeCellRenderer;
