import { DASH_DASH } from 'config/constants';
import theme from 'config/theme';
import { parseISO } from 'date-fns';
import { DataGrid } from 'devextreme-react';
import {
    Column,
    Export,
    Format,
    GroupItem,
    GroupPanel,
    Grouping,
    HeaderFilter,
    Pager,
    Paging,
    Summary,
    Toolbar,
    Item as ToolbarItem,
    TotalItem,
} from 'devextreme-react/data-grid';
import { ExportingEvent, RowPreparedEvent } from 'devextreme/ui/data_grid';
import { css } from 'emotion';
import { CustomizeCellInfoType, DelinquencyData } from 'waypoint-types';
import { formatInTimeZone } from 'date-fns-tz';
import {
    exportExcelFromDevExtremeDataGrid,
    onContentReady,
} from 'waypoint-utils';
import { ExpandAndCollapseButton } from 'waypoint-react';
import { useState } from 'react';

interface AgedReceivableDetailProps {
    delinquencies: DelinquencyData[];
}

const detailGridStyle = css`
    .dx-placeholder {
        left: 15px;
    }
`;

export const AgedReceivablesDetail = ({
    delinquencies,
}: AgedReceivableDetailProps) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [expandButtonEnable, setExpandButtonEnable] = useState<boolean>(true);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const onRowPrepared = (e: RowPreparedEvent) => {
        if (e.rowType === 'group') {
            e.rowElement.style.backgroundColor = theme.colors.grays.background;
        }
        if (e.rowType === 'header') {
            e.rowElement.style.fontWeight = 'bold';
            e.rowElement.style.color = 'white';
            e.rowElement.style.backgroundColor = '#32a3dde3';
        }
        if (e.rowType === 'totalFooter') {
            e.rowElement.style.fontWeight = 'bold';
        }
    };

    const formatPeriod = (cellInfo: CustomizeCellInfoType) => {
        return cellInfo.value
            ? formatInTimeZone(
                  parseISO(cellInfo.value.toString()),
                  'UTC',
                  'MM/yyyy',
              )
            : DASH_DASH;
    };

    const formatDate = (cellInfo: CustomizeCellInfoType) => {
        return cellInfo.value
            ? formatInTimeZone(
                  parseISO(cellInfo.value.toString()),
                  'UTC',
                  'MM/dd/yyyy',
              )
            : DASH_DASH;
    };

    const formatChargeName = (data: DelinquencyData) => {
        const chargeDisplayName = data.charge_display_name
            ? ` (${data.charge_display_name})`
            : '';
        return `${data.charge_code.toUpperCase()}${chargeDisplayName}`;
    };

    const onExporting = async (e: ExportingEvent) => {
        await exportExcelFromDevExtremeDataGrid(e, {
            worksheetName: 'Aged_Receivables',
            filename: `Aged_Receivable_Unit_${delinquencies[0].unit}_Detail.xlsx`,
        });
    };

    return (
        <DataGrid
            dataSource={delinquencies}
            height={225}
            allowColumnReordering={true}
            showBorders={true}
            onRowPrepared={onRowPrepared}
            wordWrapEnabled={true}
            columnMinWidth={100}
            hoverStateEnabled={true}
            onExporting={onExporting}
            className={detailGridStyle}
            onContentReady={(e) =>
                onContentReady({ e, toggleFunc: setExpandButtonEnable })
            }
        >
            <HeaderFilter visible={true} allowSearch={true} height={350} />
            <Export enabled={true} allowExportSelectedData={false} />
            <Grouping contextMenuEnabled autoExpandAll={expanded} />
            <GroupPanel visible={true} />
            <Pager visible={false} allowedPageSizes={['all']} />
            <Paging enabled={false} />
            <Toolbar>
                <ToolbarItem location="before" name="groupPanel" />
                <ToolbarItem location="before">
                    <ExpandAndCollapseButton
                        expanded={expanded}
                        toggleExpanded={toggleExpanded}
                        expandButtonEnable={expandButtonEnable}
                    />
                </ToolbarItem>
                <ToolbarItem name="exportButton" />
                <ToolbarItem name="searchPanel" locateInMenu="auto" />
            </Toolbar>

            <Column
                dataField="transaction_id"
                caption="Transaction ID"
                showInColumnChooser={false}
                allowHeaderFiltering
                allowGrouping={false}
                width={'auto'}
            />
            <Column
                dataField="charge_code"
                caption="Charge"
                width={'auto'}
                allowGrouping
                cellRender={(cellData) => formatChargeName(cellData.data)}
            />
            <Column
                dataField="transaction_date"
                caption="Date"
                alignment={'center'}
                customizeText={formatDate}
                showInColumnChooser={false}
                allowHeaderFiltering
                allowGrouping
            />
            <Column
                dataField="period"
                caption="Period"
                alignment={'center'}
                allowGrouping
                customizeText={formatPeriod}
            />

            <Column
                dataField="due_date"
                caption="Due Date"
                customizeText={formatDate}
                showInColumnChooser={false}
                visible={false}
                allowGrouping={false}
            />
            <Column
                dataField="late_date"
                caption="Late Date"
                customizeText={formatDate}
                showInColumnChooser={false}
                visible={false}
                allowGrouping={false}
            />
            <Column
                dataField="days_aged"
                caption="Days Aged"
                alignment="center"
                showInColumnChooser={false}
                allowHeaderFiltering={false}
                allowGrouping={false}
            >
                <Format type="fixedPoint" precision={0} />
            </Column>
            <Column
                dataField="total"
                caption="Total"
                allowHeaderFiltering={false}
                allowGrouping={false}
            >
                <Format type="currency" precision={2} />
            </Column>
            <Column
                dataField="balance"
                caption="Current"
                allowHeaderFiltering={false}
                allowGrouping={false}
            >
                <Format type="currency" precision={2} />
            </Column>
            <Column
                dataField="prepaid_balance"
                caption="Prepaid"
                allowHeaderFiltering={false}
                allowGrouping={false}
                visible={true}
            >
                <Format type="currency" precision={2} />
            </Column>
            <Column
                dataField="overdue_balance"
                caption="Overdue"
                allowHeaderFiltering={false}
                allowGrouping={false}
                visible={false}
            >
                <Format type="currency" precision={2} />
            </Column>
            <Column
                dataField="thirty_days_due_balance"
                caption="1-30 Days"
                allowHeaderFiltering={false}
                allowGrouping={false}
            >
                <Format type="currency" precision={2} />
            </Column>
            <Column
                dataField="sixty_days_due_balance"
                caption="31-60 Days"
                allowHeaderFiltering={false}
                allowGrouping={false}
            >
                <Format type="currency" precision={2} />
            </Column>
            <Column
                dataField="ninety_days_due_balance"
                caption="61-90 Days"
                allowHeaderFiltering={false}
                allowGrouping={false}
            >
                <Format type="currency" precision={2} />
            </Column>
            <Column
                dataField="ninety_more_days_due_balance"
                caption="> 90 Days"
                allowHeaderFiltering={false}
                allowGrouping={false}
            >
                <Format type="currency" precision={2} />
            </Column>
            <Summary>
                <TotalItem
                    name="total_transactions"
                    column="transaction_id"
                    displayFormat="Total: {0} Transactions"
                />
                <GroupItem
                    name="group_transaction_date"
                    column="transaction_date"
                    summaryType="count"
                    displayFormat="{0} Transactions"
                    alignByColumn
                    showInColumn="transaction_date"
                    valueFormat={{ type: 'fixedPoint', precision: 0 }}
                />
                <TotalItem
                    name="total_total"
                    column="total"
                    summaryType="sum"
                    displayFormat="{0}"
                    valueFormat={{ type: 'currency', precision: 2 }}
                />
                <GroupItem
                    name="group_total"
                    column="total"
                    summaryType="sum"
                    displayFormat="{0}"
                    alignByColumn
                    showInColumn="total"
                    valueFormat={{ type: 'currency', precision: 2 }}
                />
                <TotalItem
                    name="avg_days_aged"
                    column="days_aged"
                    summaryType="avg"
                    displayFormat="{0}"
                    valueFormat={{ type: 'fixedPoint', precision: 1 }}
                />
                <GroupItem
                    name="group_days_aged"
                    column="days_aged"
                    summaryType="avg"
                    displayFormat="{0}"
                    alignByColumn
                    showInColumn="days_aged"
                    valueFormat={{ type: 'fixedPoint', precision: 1 }}
                />
                <TotalItem
                    name="total_balance"
                    column="balance"
                    summaryType="sum"
                    displayFormat="{0}"
                    valueFormat={{ type: 'currency', precision: 2 }}
                />
                <GroupItem
                    name="group_balance"
                    column="balance"
                    summaryType="sum"
                    displayFormat="{0}"
                    alignByColumn
                    showInColumn="balance"
                    valueFormat={{ type: 'currency', precision: 2 }}
                />
                <TotalItem
                    name="total_prepaid_balance"
                    column="prepaid_balance"
                    summaryType="sum"
                    displayFormat="{0}"
                    valueFormat={{ type: 'currency', precision: 2 }}
                />
                <GroupItem
                    name="group_prepaid_balance"
                    column="prepaid_balance"
                    summaryType="sum"
                    displayFormat="{0}"
                    alignByColumn
                    showInColumn="prepaid_balance"
                    valueFormat={{ type: 'currency', precision: 2 }}
                />
                <TotalItem
                    name="total_thirty_days_due_balance"
                    column="thirty_days_due_balance"
                    summaryType="sum"
                    displayFormat="{0}"
                    valueFormat={{ type: 'currency', precision: 2 }}
                />
                <GroupItem
                    name="group_thirty_days_due_balance"
                    column="thirty_days_due_balance"
                    summaryType="sum"
                    displayFormat="{0}"
                    alignByColumn
                    showInColumn="thirty_days_due_balance"
                    valueFormat={{ type: 'currency', precision: 2 }}
                />
                <TotalItem
                    name="total_sixty_days_due_balance"
                    column="sixty_days_due_balance"
                    summaryType="sum"
                    displayFormat="{0}"
                    valueFormat={{ type: 'currency', precision: 2 }}
                />
                <GroupItem
                    name="group_sixty_days_due_balance"
                    column="sixty_days_due_balance"
                    summaryType="sum"
                    displayFormat="{0}"
                    alignByColumn
                    showInColumn="sixty_days_due_balance"
                    valueFormat={{ type: 'currency', precision: 2 }}
                />
                <TotalItem
                    name="total_ninety_days_due_balance"
                    column="ninety_days_due_balance"
                    summaryType="sum"
                    displayFormat="{0}"
                    valueFormat={{ type: 'currency', precision: 2 }}
                />
                <GroupItem
                    name="group_ninety_days_due_balance"
                    column="ninety_days_due_balance"
                    summaryType="sum"
                    displayFormat="{0}"
                    alignByColumn
                    showInColumn="ninety_days_due_balance"
                    valueFormat={{ type: 'currency', precision: 2 }}
                />
                <TotalItem
                    name="total_ninety_more_days_due_balance"
                    column="ninety_more_days_due_balance"
                    summaryType="sum"
                    displayFormat="{0}"
                    valueFormat={{ type: 'currency', precision: 2 }}
                />
                <GroupItem
                    name="group_ninety_more_days_due_balance"
                    column="ninety_more_days_due_balance"
                    summaryType="sum"
                    displayFormat="{0}"
                    alignByColumn
                    showInColumn="ninety_more_days_due_balance"
                    valueFormat={{ type: 'currency', precision: 2 }}
                />
            </Summary>
        </DataGrid>
    );
};
