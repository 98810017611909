import theme from 'config/theme';
import { css } from 'emotion';

// Sider z-index must be minor than AntD Modals, Drawer and layover elements
export const siderZIndex = 999;

/*
 * Full page Drawer z-index must be greather than the sider's, but
 * minor than AntD DatePicker, DevExtreme ColumnChooser, Column filter, popconfirm, menus, comment mentions dropdown etc.
 * Can work for elements in the drawer as well
 * */
export const fullPageDrawerZIndex = 1000;

export const overrideMenuItems = css`
    .ant-menu-item {
        height: 30px;
        line-height: 30px;
    }
    .ant-menu-item-selected {
        background-color: unset;
        color: unset;
    }
`;

export enum MenuSections {
    Home = 'Home',
    Dashboards = 'Dashboards',
    Analytics = 'Analytics',
    Leasing = 'Leasing',
    Planning = 'Planning',
    Financials = 'Financials',
    Workflows = 'Workflows',
    Notifications = 'Notifications',
    Help = 'Help',
    Settings = 'Settings',
}

export const menuItemStyle = (
    pathname: string,
    routeUrls: string[],
    submenuStyle = true
) => {
    if (!routeUrls.some((url) => pathname.includes(url))) {
        return {};
    }
    return submenuStyle
        ? {
              color: theme.colors.blues.activeBlue,
              backgroundColor: theme.colors.blues.activeBackground,
          }
        : {
              backgroundColor: theme.colors.blues.activeBlue,
          };
};

export const sidebarDrawerMenuTitle = (menuTitle: string) => {
    return (
        <h2
            style={{
                marginLeft: '13px',
                marginBottom: menuTitle === MenuSections.Help ? '10px' : '25px',
            }}
        >
            {menuTitle}
        </h2>
    );
};
