import { includes } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styles from 'components/settings/SubMenu.module.css';
import { PERSONAL_PROFILE_URL_SEGMENT } from 'config/constants';

class SubMenu extends React.Component {
    static propTypes = {
        url: PropTypes.string,
    };

    static contextTypes = {
        router: PropTypes.object,
    };

    getClassnames = (name) => {
        const { isActive } = this;
        const activeStateClassname = isActive(name) ? styles.active : '';
        const disableButtonClassname = '';
        return `${disableButtonClassname} ${activeStateClassname}`;
    };

    gotoPersonal = () => {
        const { history } = this.props;
        history.push('/settings/user/' + PERSONAL_PROFILE_URL_SEGMENT);
    };

    isActive = (name) => {
        const { url } = this.props;
        return includes(url, name);
    };

    render() {
        const { gotoPersonal, getClassnames } = this;
        return (
            <nav className={styles.profile}>
                <div
                    className={getClassnames(PERSONAL_PROFILE_URL_SEGMENT)}
                    onClick={gotoPersonal}
                >
                    Personal Profile
                </div>
            </nav>
        );
    }
}

function mapState(state) {
    const { pathname: url } = state.routing.locationBeforeTransitions;

    return {
        url,
    };
}

export default withRouter(compose(connect(mapState))(SubMenu));
