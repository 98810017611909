import {
    INotification,
    IRemoteNotification,
    useNotification,
} from '@magicbell/react-headless';
import { Badge, Dropdown, List, Space, Typography } from 'antd';
import React from 'react';
import { theme } from 'config/colors';
import { css } from 'emotion';
import {
    BookOutlined,
    CheckOutlined,
    DeleteOutlined,
    MoreOutlined,
} from '@ant-design/icons';
import { formatDistanceToNow } from 'date-fns';
import { cleanCommentFromMarkup } from 'waypoint-utils';
import { isEmpty } from 'lodash';

interface NotificationProps {
    rawNotification: IRemoteNotification;
    onClick: (notification: INotification) => void | Promise<void>;
    onDelete: (notification: INotification) => void | Promise<void>;
    onMarkRead: (notification: INotification) => void | Promise<void>;
    onMarkUnread: (notification: INotification) => void | Promise<void>;
}

const maxDescriptionLength = 140;

const notificationItemCSS = css`
    &:hover {
        background-color: ${theme.colors.buttonBlueLight} !important;
    }

    h4 {
        font-variant-caps: normal;
    }

    .ant-list-item-action-split {
        display: none;
    }
`;

export const NotificationItem = ({
    rawNotification,
    onClick,
    onDelete,
    onMarkRead,
    onMarkUnread,
}: NotificationProps): JSX.Element => {
    const notification = useNotification(rawNotification);
    const listItemStyle = {
        paddingLeft: '24px',
        paddingRight: '10px',
        marginBottom: '5px',
        marginTop: '5px',
        marginLeft: '8px',
        marginRight: '8px',
        border: `1px solid ${theme.colors.darkGray}`,
        cursor: 'pointer',
        borderRadius: '6px',
        backgroundColor: notification.readAt
            ? undefined
            : theme.colors.lightGray,
    };

    const trimmedContent = `"${cleanCommentFromMarkup(
        notification.content ?? ''
    )?.substr(0, maxDescriptionLength)}${
        notification.content &&
        notification.content.length >= maxDescriptionLength
            ? '...'
            : ''
    }"`;
    const formattedSentAtDate = formatDistanceToNow(
        notification.sentAt?.toDate() ?? new Date(),
        { addSuffix: true }
    );

    const description = (
        <Space direction="vertical">
            {isEmpty(trimmedContent) ? (
                <Typography.Text>{trimmedContent}</Typography.Text>
            ) : null}
            <Typography.Text type="secondary">
                {formattedSentAtDate}
            </Typography.Text>
        </Space>
    );

    const items = [
        {
            key: notification.isRead ? 'markUnread' : 'markRead',
            label: notification.isRead ? 'Mark Unread' : 'Mark Read',
            icon: notification.isRead ? <BookOutlined /> : <CheckOutlined />,
            onClick: ({ domEvent: event }: any) => {
                event.stopPropagation();
                if (notification.isRead) {
                    onMarkUnread(notification);
                } else {
                    onMarkRead(notification);
                }
            },
        },
        {
            key: 'delete',
            label: 'Delete',
            icon: <DeleteOutlined />,
            onClick: ({ domEvent: event }: any) => {
                event.stopPropagation();
                onDelete(notification);
            },
        },
    ];

    const actionDropdown = (
        <Dropdown menu={{ items }} trigger={['hover']}>
            <MoreOutlined
                style={{ fontSize: '22px' }}
                onClick={(event) => {
                    event.stopPropagation();
                }}
            />
        </Dropdown>
    );

    return (
        <List.Item
            style={listItemStyle}
            className={notificationItemCSS}
            onClick={async () => {
                onClick(notification);
            }}
            actions={[
                notification.isRead ? null : (
                    <Badge
                        dot={true}
                        size={'default'}
                        color={theme.colors.primaryBlue}
                    />
                ),
                actionDropdown,
            ]}
        >
            <List.Item.Meta
                description={description}
                title={notification.title}
            />
        </List.Item>
    );
};
