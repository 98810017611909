import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';

const RankingInfoTooltip = ({
    title,
    placement,
}: {
    title: string;
    placement: TooltipPlacement;
}): JSX.Element => {
    return (
        <Tooltip title={title} placement={placement} arrow>
            <i
                className="fa-solid fa-info-circle"
                style={{ display: 'inline-block' }}
            />
        </Tooltip>
    );
};

export default RankingInfoTooltip;
