import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import useSWR from 'swr';
import {
    getAttributesV2,
    GetAttributesV2Response,
} from 'waypoint-requests/attributes/getAttributesV2';

export const useGetAttributes = (
    entityCodes: string[],
): DataHookResponse<GetAttributesV2Response> => {
    const { data, error, mutate } = useSWR(
        `/api/attributes/${entityCodes.sort().join(',')}`,
        async () => {
            return getAttributesV2(entityCodes);
        },
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
        },
    );

    return {
        isLoading: !data,
        isError: !!error,
        data,
        mutate,
    };
};
