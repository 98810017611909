import { message } from 'antd';
import useSWR from 'swr';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { SavedConfiguration } from 'waypoint-types';
import { getSavedConfigurations } from 'waypoint-requests';
import { stringSort } from 'utils/tables/sorters';

export const useGetSavedConfigurations = (
    userId: number,
    clientId: number,
    filterType: string
): DataHookResponse<SavedConfiguration[]> => {
    const cacheKey = `api/saved-configurations/${filterType}/${userId}/${clientId}`;
    const { data, error, mutate, isValidating } = useSWR(
        cacheKey,
        () => getSavedConfigurations(filterType),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
            onError: () => {
                message.error('Failed to fetch saved configurations');
            },
            shouldRetryOnError: false,
        }
    );
    const sortedConfigDataByName = data?.sort(
        (a: SavedConfiguration, b: SavedConfiguration) =>
            stringSort(b.name, a.name)
    );
    return {
        isLoading: !data || isValidating,
        isError: !!error,
        data: sortedConfigDataByName,
        mutate,
    };
};
