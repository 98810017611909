import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { ThresholdRule } from 'waypoint-types';
import useSWR from 'swr';
import { getThresholdRulesByEntityCodes } from 'waypoint-requests';

export const useGetThresholdRules = (
    entityCodes: string[],
    onError: () => void
): DataHookResponse<ThresholdRule[]> => {
    const entityCodesCacheKey = entityCodes.join(',');
    const { data, error, mutate } = useSWR(
        entityCodesCacheKey
            ? `/api/comparative-income-statement/threshold-rules/${entityCodesCacheKey}`
            : null,
        () => getThresholdRulesByEntityCodes(entityCodes),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
            onError: () => onError(),
            shouldRetryOnError: false,
        }
    );
    return {
        isLoading: !data,
        isError: !!error,
        data,
        mutate,
    };
};
