import React, { useState } from 'react';
import { Button } from 'antd';
import { css } from 'emotion';
import { FilterTwoTone } from '@ant-design/icons';

import theme from 'config/theme';
import GlobalFilterDrawer from './GlobalFilterDrawer';

const buttonStyle = css`
    align-self: center;
    color: ${theme.colors.white} !important;
    background: ${theme.colors.blues.primary} !important;
    border-color: ${theme.colors.blues.primary} !important;
    width: 150px;
    border-radius: 5px !important;
    box-shadow: 0 8px 5px -8px rgb(0 0 0 / 70%) !important;
`;

const OpenGlobalFiltersButton = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

    return (
        <>
            <Button
                className={buttonStyle}
                onClick={() => setIsDrawerOpen(true)}
                icon={<FilterTwoTone />}
            >
                Properties
            </Button>
            <GlobalFilterDrawer
                isDrawerOpen={isDrawerOpen}
                setIsDrawerOpen={setIsDrawerOpen}
            />
        </>
    );
};

export default OpenGlobalFiltersButton;
