import PropTypes from 'prop-types';
import React from 'react';
import { assign } from 'lodash';

const headerStyle = {
    height: '72px',
    width: '397px',
    marginBottom: '20px',
    color: '#979797',
    fontFamily: 'Lato',
    fontSize: '24px',
    letterSpacing: '0.98px',
    lineHeight: '36px',
    textAlign: 'center',
};

const subheaderStyle = {
    height: '24px',
    width: '511px',
    marginBottom: '39px',
    color: '#979797',
    fontFamily: 'Lato',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
};

const iconStyle = {
    height: '84px',
    maxHeight: '84px',
    marginBottom: '21px',
};

const EmptyState = (props) => {
    const {
        icon,
        width,
        height,
        headerText,
        subheaderText,
        headerComponent,
        subheaderComponent,
        iconOverrideStyle,
    } = props;

    const containerStyle = {
        height: height || 'auto',
        width: width || 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };

    return (
        <div style={assign({}, containerStyle, props.style)}>
            {/* NOTE (Daniel): check wether icon is a string or a component */}
            {icon && <img src={icon} style={iconOverrideStyle || iconStyle} />}
            {/* NOTE: a component may be passed if anything beyond a string is needed */}
            {headerComponent && headerComponent}
            {headerText && <div style={headerStyle}>{headerText}</div>}
            {subheaderComponent && subheaderComponent}
            {subheaderText && <div style={subheaderStyle}>{subheaderText}</div>}
        </div>
    );
};

EmptyState.propTypes = {
    icon: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    headerText: PropTypes.string,
    subheaderText: PropTypes.string,
    iconOverrideStyle: PropTypes.object,
    headerComponent: PropTypes.element,
    subheaderComponent: PropTypes.element,
};

export default EmptyState;
