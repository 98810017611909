import PropTypes from 'prop-types';
import React from 'react';
import { GREY, BLUE, WHITE } from './colors';

class Cell extends React.Component {
    static propTypes = {
        selected: PropTypes.bool,
        disabled: PropTypes.bool,
        children: PropTypes.any,
        onClick: PropTypes.func,
        selectableWhenDisabled: PropTypes.bool,
    };

    render() {
        const {
            selected,
            disabled,
            children,
            onClick,
            selectableWhenDisabled,
        } = this.props;
        let color = BLUE;
        let backgroundColor = WHITE;
        if (selected && disabled) {
            color = WHITE;
            backgroundColor = GREY;
        } else if (disabled) {
            color = GREY;
        } else if (selected) {
            color = WHITE;
            backgroundColor = BLUE;
        }
        const clickHandler =
            !disabled || selectableWhenDisabled ? onClick : null;
        const cursor =
            disabled && !selectableWhenDisabled ? 'not-allowed' : 'pointer';
        return (
            // TODO (Nicholas): add a style sheet so that hover effects work.
            <span
                onClick={clickHandler}
                style={{
                    color,
                    backgroundColor,
                    cursor,
                    border: `1px solid ${selected ? backgroundColor : color}`,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '75px',
                    height: '35px',
                    margin: '2px',
                    fontSize: '12px',
                }}
            >
                {children || ''}
            </span>
        );
    }
}

export default Cell;
