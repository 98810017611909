// @ts-nocheck
// This file is a result of nx update

import { merge, cloneDeep } from 'lodash';
import { explanationTypes as EXPLANATION_TYPES } from 'state/actionTypes';

function loadExplanationTypes(state, payload) {
    return cloneDeep(merge(state, payload));
}

export default function reducer(explanationTypes = {}, action) {
    const { type, payload } = action;
    switch (type) {
        case EXPLANATION_TYPES.LOAD:
            return loadExplanationTypes(explanationTypes, payload);
        default:
            return explanationTypes;
    }
}
