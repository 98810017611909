import { message } from 'antd';
import useSWR from 'swr';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { getValueAndDebt } from 'waypoint-requests';
import { DebtAndValueKpis, SelectedDataLevel } from 'waypoint-types';

export const useGetValueAndDebt = (
    entityCodes: string[],
    periods: string[],
    selectedDataLevel: SelectedDataLevel,
    isLoadingAsOfDate: boolean
): DataHookResponse<DebtAndValueKpis> => {
    const swrKey = isLoadingAsOfDate
        ? null
        : [
              '/api/properties/value-and-debt-kpis',
              entityCodes.join(','),
              ...periods,
              selectedDataLevel.percentageType,
              selectedDataLevel.stakeholder,
          ];

    const { data, error, mutate, isValidating } = useSWR(
        swrKey,
        () => getValueAndDebt(entityCodes, periods, selectedDataLevel),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
            onError: () => {
                message.error('Failed to fetch debt and values');
            },
            shouldRetryOnError: false,
        }
    );

    return {
        isLoading: !data,
        isError: !!error,
        data,
        mutate,
        isValidating,
    };
};
