import { message } from 'antd';
import useSWR from 'swr';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { getCapitalProjects } from 'waypoint-requests';
import { CapitalProject } from 'waypoint-types';

export const useGetCapitalProjects = (
    entityCodes: string[]
): DataHookResponse<CapitalProject[]> => {
    const entityCodesCacheKey = entityCodes.join(',');
    const { data, error, mutate, isValidating } = useSWR(
        entityCodesCacheKey
            ? `/api/capital-project/${entityCodesCacheKey}`
            : null,
        () => getCapitalProjects({ entity_codes: entityCodes }),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
            onError: () => {
                message.error('Failed to fetch Capital Projects data');
            },
            shouldRetryOnError: false,
        }
    );
    return {
        isLoading: !data || isValidating,
        isError: !!error,
        data,
        mutate,
    };
};
