import React from 'react';
import { Skeleton } from 'antd';
import { css } from 'emotion';
import { sanitizeAndFormatHTML } from './Utils';

interface ContentRendererProps {
    isLoading: boolean;
    content: string;
    onClick?: () => void;
    placeholder?: string;
    canInvokeEdit?: boolean;
    disabled?: boolean;
    isPDFExport: boolean;
}

const fullWidth = css`
    width: 100%;
    color: #000;
`;

const placeholderContainer = (disabled?: boolean) => css`
    padding-left: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    width: 100%;
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid #dfe1e6;
    margin-bottom: 16px;
    color: #5e6c84;
    font-size: 14px;
    transition: border-color 0.2s ease-in-out;
    cursor: ${disabled ? 'not-allowed' : 'inherit'};
    &:hover {
        border-color: #c1c7d0;
    }
`;

const contentStyle = (disabled?: boolean) => css`
    cursor: ${disabled ? 'not-allowed' : 'inherit'};
    padding: 8px;
    transition: background-color 0.2s ease-in-out;
    &:hover {
        border-radius: 3px;
        background-color: rgba(235, 236, 240, 0.5);
    }
`;

const ContentRenderer = ({
    isLoading,
    content,
    onClick,
    placeholder,
    canInvokeEdit,
    disabled,
    isPDFExport = false,
}: ContentRendererProps) => {
    const onClickTrigger = () => {
        if (disabled) {
            return;
        }

        onClick && onClick();
    };

    const renderContent = () => {
        if (isLoading) {
            return (
                <div style={{ padding: '8rem' }}>
                    <Skeleton paragraph active />
                </div>
            );
        }

        if (canInvokeEdit && (!content || content === '<p></p>')) {
            return (
                <div
                    onClick={onClickTrigger}
                    className={placeholderContainer(disabled)}
                >
                    <span className="placeholder-text">{placeholder}</span>
                </div>
            );
        }

        return (
            <div className={contentStyle(disabled)} onClick={onClickTrigger}>
                <div
                    dangerouslySetInnerHTML={{
                        __html: sanitizeAndFormatHTML(isPDFExport, content),
                    }}
                />
            </div>
        );
    };

    return <div className={fullWidth}>{renderContent()}</div>;
};

export default ContentRenderer;
