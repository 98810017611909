import { message } from 'antd';
import useSWR from 'swr';
import { stringSort } from 'utils/tables/sorters';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { getEntityReportWidgetsByEntityReportIds } from 'waypoint-requests';
import {
    EntityReportWidget,
    GetEntityReportWidgetsParams,
} from 'waypoint-types';

export const useGetEntityReportWidgets = (
    params: GetEntityReportWidgetsParams
): DataHookResponse<EntityReportWidget[]> => {
    const entityReportIdsCacheKey = params.entityReportIds
        .sort((a, b) => stringSort(a, b))
        .join(',');

    const { data, error, mutate, isValidating } = useSWR(
        entityReportIdsCacheKey
            ? `/api/reports/entity-report-widgets/${entityReportIdsCacheKey}`
            : null,
        () => getEntityReportWidgetsByEntityReportIds(params),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
            shouldRetryOnError: false,
            onError: () => {
                message.error('Could not fetch widget data');
            },
        }
    );

    return {
        isLoading: (entityReportIdsCacheKey && !data) || isValidating,
        isError: !!error,
        data,
        mutate,
    };
};
