import { API_URL } from 'config/constants';
import {
    Contract,
    ContractsWithVendorAndServiceTypeOptions,
} from 'waypoint-types';

const getContracts = async (
    entityCodes: string[]
): Promise<ContractsWithVendorAndServiceTypeOptions> => {
    const response = await fetch(`${API_URL}/contracts`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            entity_codes: entityCodes,
        }),
    });

    if (!response.ok) {
        throw new Error('Failed to get contract');
    }

    const { data } = await response.json();
    return data as ContractsWithVendorAndServiceTypeOptions;
};

export default getContracts;
