import PropTypes from 'prop-types';
import React from 'react';
import { Grid } from 'react-bootstrap';
import styles from './Settings.module.css';

const Settings = (props) => {
    return <Grid className={styles.grid}>{props.children}</Grid>;
};

Settings.propTypes = {
    children: PropTypes.node,
};

export default Settings;
