import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selectDashboardConfigurationById } from 'state/dashboardConfigurations/selectors';
/*
    NOTE: This HOC selects a dashboard configuration from state. Right now, those only exist as a client level configuration.
    In the future, there will be user and property level overrides and the logic to determine
*/

const withDashboardConfiguration = (WrappedComponent) => {
    class WithDashboardConfiguration extends Component {
        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    const mapState = (state, props) => {
        const { module, dashboardConfigurationId } = props;
        if (!dashboardConfigurationId) {
            console.warn(
                `A dashboardConfigurationId is required for the dashboard selector. Provided: ${dashboardConfigurationId}`,
            );
            return {};
        }
        if (!module) {
            console.warn(
                `A module type is required for the dashboard selector. Provided: ${module}`,
            );
            return {};
        }
        const dashboardConfiguration = selectDashboardConfigurationById(state, {
            module,
            id: dashboardConfigurationId,
        });
        return { dashboardConfiguration };
    };

    return connect(mapState)(WithDashboardConfiguration);
};

export default withDashboardConfiguration;
