import React, { Component } from 'react';
import { css } from 'emotion';
import theme from 'config/theme';

const page = css`
    height: 100%;
    overflow-y: scroll;
    background-color: ${theme.colors.grays.background};
    width: 100%;
`;

class AnalyticsContainer extends Component {
    render() {
        return <div className={page}>{this.props.children}</div>;
    }
}

export default AnalyticsContainer;
