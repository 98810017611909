import { message } from 'antd';
import useSWR from 'swr';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { getEntityReportApprovals } from 'waypoint-requests';
import { EntityReportApproval } from 'waypoint-types';

interface GetReportApprovalsParams {
    entityReportIds: string[];
}

export const useGetReportApprovals = ({
    entityReportIds,
}: GetReportApprovalsParams): DataHookResponse<EntityReportApproval[]> => {
    const entityReportIdsCacheKey = entityReportIds?.length
        ? entityReportIds.join(',')
        : '';
    const { data, error, mutate, isValidating } = useSWR(
        entityReportIdsCacheKey
            ? `/api/report-approvals/${entityReportIdsCacheKey}`
            : null,
        () =>
            getEntityReportApprovals({
                entityReportIds: entityReportIds ?? [],
            }),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
            onError: (e) => {
                message.error('Failed to fetch report approvals');
            },
            shouldRetryOnError: false,
        }
    );
    return {
        isLoading: !data,
        isError: !!error,
        data,
        mutate,
        isValidating,
    };
};
