import { css, cx } from 'emotion';
import React, { useState, useEffect } from 'react';
import { isEmpty, size, pull } from 'lodash';
import { getEveryNodeValueFor } from 'waypoint-utils';
import theme from 'config/theme';
import { Table } from 'waypoint-react';
import { AccountGraphNode } from 'waypoint-types';
import {
    decorateFirstColumnWithSearchProps,
    getRowClassNameFor,
} from './utils';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Tooltip, Button } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { monthDisplayNames } from 'utils/consts';

/**
 * This is an expandable/collapsible table that displays data in a tree format.
 *
 * EXPAND/COLLAPSE: The tree defaults to collapsed. When searched, it automatically expands. When the search is reset, it collapses back to its default state. Individual nodes can be expanded or collapsed piece meal. To ensure desired behavior, this is now controlled and a list of expanded row keys are kept in component state.
 *
 * SEARCHING: To make the tree searchable, pass an array of keys to the searchableKeys prop. There is a single search field in the first column that acts as a global search for the entire table if searchableKeys prop is not empty.  This search functionality does not filter the data passed to the table. Instead, CSS is used to hide or highlight elements based on if they pass the search filter. getRowClassNameFor handles all of this and conditionally passes classes  based on the result of the search filter.
 *
 * There is coupling with a util called decorateAccountGraphForAntDesign. It decorates each node with isRootNode, isTotalNode, isTitleNode & isTotalForRootNode props that are used to style rows unrelated to the search. getRowClassNameFor uses those to pass a class name to a row, in addtion to the search functionality you see here.
 */

const tableStyle = css`
    .ant-table-thead > tr > th {
        background-color: ${theme.colors.grays.background} !important;
        font-weight: ${theme.fontWeights.bold} !important;
        white-space: pre;
        vertical-align: bottom;
    }

    .ant-table-cell {
        white-space: break-spaces;
    }

    .ant-table-fixed-left tbody {
        font-weight: ${theme.fontWeights.bold};
    }
`;

const highlightHeader = css`
    .ant-table-thead th:first-of-type {
        background-color: #e9f5fb !important;
    }
`;

const tooltipStyle = css`
    .ant-tooltip-disabled-compatible-wrapper {
        display: flex !important;
        justify-content: center;
        align-items: center;
        button {
            display: flex;
        }
    }
`;

interface FinancialTreeTableProps {
    data?: AccountGraphNode[];
    columns?: any;
    searchableKeys?: string[];
    isEnablePagination?: boolean;
    highlightRows?: string[] | null;
    expandedRows?: string[] | null;
    setExpandedRows?: (rows: string[]) => void;
    financialYearEnding?: string | null;
    displayFooter?: boolean;
    [key: string]: any;
}

interface ExpandCollapseColumnProps {
    canExpand: boolean;
    canCollapse: boolean;
    expandLevel: () => void;
    collapseLevel: () => void;
}

const FinancialTreeTable = ({
    data,
    columns,
    searchableKeys,
    isEnablePagination,
    highlightRows,
    expandedRows,
    setExpandedRows,
    financialYearEnding,
    displayFooter,
    ...props
}: FinancialTreeTableProps) => {
    const {
        expandAllRows,
        parentExpand,
        isReportWidget,
        isPDFExport,
        hideExpandableButtons,
        styleProps,
    } = props;
    // SEARCH
    const [searchText, setSearchText] = useState('');

    // EXPANSION
    const [clickedRows, setClickedRows] = useState<any | null>([]);
    const [localExpandedRowKeys, setLocalExpandedRowKeys] = useState<
        any | null
    >([]);

    const expandAllClickedRows = () =>
        setClickedRows(getEveryNodeValueFor(data, 'key'));

    const collapseAllRows = () => {
        setClickedRows([]);
        if (setExpandedRows) setExpandedRows([]);
    };

    // COLUMN DECORATION
    const decorateFirstColumnWithExpandCollapse = (
        columns: ColumnType<any>[],
        expandCollapseProps: ExpandCollapseColumnProps,
    ): ColumnType<any>[] => {
        if (isReportWidget) {
            return columns;
        }
        const newColumns = [...columns];
        const firstColumn = { ...newColumns[0] };

        const headerButtons = (
            <div
                className={tooltipStyle}
                style={{ display: 'flex', alignItems: 'center' }}
            >
                <Tooltip placement="top" title={'Collapse level'}>
                    <Button
                        size="small"
                        icon={
                            <MinusOutlined
                                style={{
                                    height: 10,
                                    width: 10,
                                    fontSize: 10,
                                }}
                            />
                        }
                        onClick={expandCollapseProps.collapseLevel}
                        style={{
                            marginRight: 6,
                            height: 18,
                            width: 18,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        disabled={!expandCollapseProps.canCollapse}
                    />
                </Tooltip>
                <Tooltip placement="top" title={'Expand level'}>
                    <Button
                        size="small"
                        style={{
                            height: 18,
                            width: 18,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        icon={
                            <PlusOutlined
                                style={{
                                    height: 10,
                                    width: 10,
                                    fontSize: 10,
                                }}
                            />
                        }
                        onClick={expandCollapseProps.expandLevel}
                        disabled={!expandCollapseProps.canExpand}
                    />
                </Tooltip>
            </div>
        );

        firstColumn.title = (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {firstColumn.title}
                {'  '}
                {!hideExpandableButtons && headerButtons}
            </div>
        );

        newColumns[0] = firstColumn;
        return newColumns;
    };

    const paginationSettings = isEnablePagination
        ? {
              defaultPageSize: size(data),
              hideOnSinglePage: true,
          }
        : false;

    useEffect(() => {
        if (!expandedRows) return;
        if (expandedRows) {
            const expanded = searchText ? clickedRows : expandedRows;
            setClickedRows(expanded);
            if (setExpandedRows) setExpandedRows(expanded);
        }
        if (expandedRows.length === 0) {
            const resetExpanded = !!searchText
                ? clickedRows
                : localExpandedRowKeys;
            setClickedRows(resetExpanded);
            if (setExpandedRows) setExpandedRows(resetExpanded);
        }
    }, [expandedRows, searchText]);

    useEffect(() => {
        if (parentExpand) {
            const expandedKeys = new Set(expandedRows ?? []);

            const expandableRows = getExpandableRows(data ?? []);

            expandableRows.forEach((row) => {
                if (row.children) {
                    if (!expandedKeys.has(row.key)) {
                        expandedKeys.add(row.key);
                    }
                }
            });
            if (setExpandedRows) {
                setExpandedRows([...expandedKeys]);
            }
        }
    }, [parentExpand]);

    const getExpandableRows = (rows: any[], userExpanded = false) => {
        const expandableRows: any[] = [];

        rows.forEach((row) => {
            if (row.children) {
                if (userExpanded && localExpandedRowKeys.includes(row.key)) {
                    expandableRows.push(row);
                } else if (!userExpanded) {
                    expandableRows.push(row);
                } else {
                    expandableRows.push(
                        ...getExpandableRows(row.children, userExpanded),
                    );
                }
            }
        });

        return expandableRows;
    };

    const expandLevel = () => {
        const expandedKeys = new Set(expandedRows ?? []);

        const expandNextLevel = (rows: any[]) => {
            let expandedAtLeastOne = false;

            rows.forEach((row) => {
                if (row.children && !expandedKeys.has(row.key)) {
                    expandedKeys.add(row.key);
                    expandedAtLeastOne = true;
                } else if (row.children && expandedKeys.has(row.key)) {
                    if (expandNextLevel(row.children)) {
                        expandedAtLeastOne = true;
                    }
                }
            });

            return expandedAtLeastOne;
        };

        const expandableRows = getExpandableRows(data ?? []);

        expandNextLevel(expandableRows);

        if (setExpandedRows) {
            setExpandedRows([...expandedKeys]);
        }
        setLocalExpandedRowKeys([...expandedKeys]);
    };

    const collapseLevel = () => {
        const expandedKeys = new Set(expandedRows ?? []);

        const findDeepestLevel = (rows: any[], currentLevel = 0): number => {
            let deepestLevel = currentLevel;

            rows.forEach((row) => {
                if (row.children && expandedKeys.has(row.key)) {
                    deepestLevel = Math.max(
                        deepestLevel,
                        findDeepestLevel(row.children, currentLevel + 1),
                    );
                }
            });

            return deepestLevel;
        };

        const collapseNodesAtLevel = (
            rows: any[],
            targetLevel: number,
            currentLevel = 0,
        ): boolean => {
            let collapsedAtLeastOne = false;

            rows.forEach((row) => {
                if (row.children) {
                    if (
                        expandedKeys.has(row.key) &&
                        currentLevel === targetLevel
                    ) {
                        expandedKeys.delete(row.key);
                        collapsedAtLeastOne = true;
                    } else {
                        collapsedAtLeastOne =
                            collapseNodesAtLevel(
                                row.children,
                                targetLevel,
                                currentLevel + 1,
                            ) || collapsedAtLeastOne;
                    }
                }
            });

            return collapsedAtLeastOne;
        };

        const expandableRows = getExpandableRows(data ?? []);
        const deepestLevel = findDeepestLevel(expandableRows);

        let targetLevel = deepestLevel;
        let collapsedAtLeastOne = false;
        while (targetLevel >= 0 && !collapsedAtLeastOne) {
            collapsedAtLeastOne = collapseNodesAtLevel(
                expandableRows,
                targetLevel,
            );
            targetLevel--;
        }

        if (setExpandedRows) {
            setExpandedRows([...expandedKeys]);
        }
        setLocalExpandedRowKeys([...expandedKeys]);
    };

    const anyRowCanBeExpanded = (rows: any[], expandedKeys: any[]): boolean => {
        for (const row of rows) {
            if (row.children) {
                if (!expandedKeys.includes(row.key)) {
                    return true;
                }
                if (anyRowCanBeExpanded(row.children, expandedKeys)) {
                    return true;
                }
            }
        }
        return false;
    };

    const canCollapse = localExpandedRowKeys.length > 0;
    const canExpand = anyRowCanBeExpanded(data ?? [], localExpandedRowKeys);

    let tableColumns: ColumnType<any>[] = columns;
    if (!isEmpty(searchableKeys)) {
        tableColumns = decorateFirstColumnWithSearchProps(columns, {
            expandAllRows: expandAllClickedRows,
            collapseAllRows,
            searchText,
            setSearchText,
        });
    }
    tableColumns = decorateFirstColumnWithExpandCollapse(tableColumns, {
        canExpand,
        canCollapse,
        expandLevel,
        collapseLevel,
    });

    const expandableSettings = () => {
        const settings = {
            expandedRowKeys: !expandAllRows && clickedRows,
            defaultExpandAllRows: expandAllRows,
        };
        if (isPDFExport) {
            return {
                ...settings,
                expandIcon: () => {
                    return null;
                },
            };
        }
        return settings;
    };

    const renderFooter = () => {
        if (!displayFooter) {
            return <></>;
        }

        const dateParts = financialYearEnding?.split('/');
        const spanStyle = {
            color: theme.colors.grays.text,
            fontWeight: 'bold',
            fontStyle: 'italic',
            fontSize: '12px',
        };

        if (!dateParts || dateParts[0] === '12') {
            return (
                <span style={spanStyle}>
                    For the Calendar Year Ending December 31
                </span>
            );
        }

        return (
            <span style={spanStyle}>
                {`For the Fiscal Year Ending ${monthDisplayNames[Number(dateParts[0]) - 1]} ${dateParts[1]}`}
            </span>
        );
    };

    const financialStyle = isEmpty(searchText)
        ? tableStyle
        : cx([tableStyle, highlightHeader]);

    return (
        <>
            <Table
                {...props}
                data-testid="financial-tree-table"
                className={
                    styleProps
                        ? cx([financialStyle, styleProps])
                        : financialStyle
                }
                columns={tableColumns}
                data={data}
                expandable={expandableSettings()}
                rowClassName={(row) =>
                    getRowClassNameFor({
                        row,
                        searchText,
                        searchableKeys,
                        highlightRows,
                    })
                }
                pagination={paginationSettings}
                footer={renderFooter}
                onExpand={(expanded, record: any) => {
                    record.isCollapsed = !expanded;
                    const expandedKeys = new Set(localExpandedRowKeys);
                    if (expanded) {
                        expandedKeys.add(record.key);
                    } else {
                        expandedKeys.delete(record.key);
                    }
                    setLocalExpandedRowKeys([...expandedKeys]);

                    if (!localExpandedRowKeys.includes(record.key)) {
                        setLocalExpandedRowKeys([
                            ...localExpandedRowKeys,
                            record.key,
                        ]);
                    }
                    if (expandedRows && setExpandedRows) {
                        if (!expandedRows.includes(record.key)) {
                            setExpandedRows([...expandedRows, record.key]);
                        }
                    }
                    if (!clickedRows.includes(record.key)) {
                        setClickedRows([...clickedRows, record.key]);
                        return;
                    }
                    if (expandedRows && setExpandedRows) {
                        const withoutRowRecord = pull(
                            [...expandedRows],
                            record.key,
                        );
                        setExpandedRows(withoutRowRecord);
                    }
                    const withoutRecord = pull([...clickedRows], record.key);
                    const withoutLocalRecord = pull(
                        [...localExpandedRowKeys],
                        record.key,
                    );
                    setLocalExpandedRowKeys(withoutLocalRecord);
                    setClickedRows(withoutRecord);
                }}
            />
        </>
    );
};

export default FinancialTreeTable;
