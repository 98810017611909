import { API_URL } from 'config/constants';
import { LeasingGuideline } from 'waypoint-types';

const updateLeasingGuidelines = async (
    body: LeasingGuideline
): Promise<any> => {
    const response = await fetch(
        `${API_URL}/leasing-guidelines/${body.entity_code}/${body.id}`,
        {
            method: 'PUT',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body),
        }
    );

    if (!response.ok) {
        throw new Error('FAILED');
    }

    const { data } = await response.json();
    return data;
};

export default updateLeasingGuidelines;
