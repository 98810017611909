import { API_URL } from 'config/constants';
import { dateSort } from 'utils/tables/sorters';
import { OvertimeType, SelectedDataLevel } from 'waypoint-types';

/**
 * @returns occupancy history for property or property group
 * @param params
 */
const getActualsGrossMonthlyData = async (params: {
    entity_codes: string[];
    account_mapping_code: string;
    account_mapping_name: string;
    start_date: string | null;
    end_date: string | null;
    display_type: string;
    periodicity: string;
    selected_data_level: SelectedDataLevel;
}): Promise<OvertimeType[]> => {
    const response = await fetch(`${API_URL}/financial-overview/overtime`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
    });

    if (!response.ok) {
        throw new Error('FAILED');
    }
    const { data } = await response.json();

    return data.sort((a: { period_end: number }, b: { period_end: number }) =>
        dateSort(new Date(b.period_end), new Date(a.period_end))
    );
};

export default getActualsGrossMonthlyData;
