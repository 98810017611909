import React, { useEffect, useMemo } from 'react';
import DataGrid, {
    Export,
    HeaderFilter,
    Column,
    Paging,
    SearchPanel,
    Summary,
    GroupItem,
    Grouping,
    MasterDetail,
    Lookup,
    Toolbar,
    GroupPanel,
    Pager,
    Format,
    TotalItem,
    ColumnChooser,
    Item as ToolbarItem,
    StateStoring,
    FilterPanel,
    DataGridRef,
    ColumnChooserSelection,
    Position,
} from 'devextreme-react/data-grid';
import { InitializedEvent } from 'devextreme/ui/data_grid';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import { Dictionary } from 'ts-essentials';
import {
    CapitalProject,
    CellInfoType,
    CustomizeCellInfoType,
    PropertyType,
    SavedConfigFilterType,
    SavedConfiguration,
    SavedConfigurationState,
    UnpackedMonthlyCosts,
} from 'waypoint-types';
import {
    usePlanningCapitalConfig,
    useGridConfigSettings,
    useGetLeasesPerformanceOverview,
} from 'waypoint-hooks';
import PlanningCapitalProjectModal from './PlanningCapitalProjectModal';
import { Button } from 'antd';

import {
    DisabledDashboard,
    ExpandAndCollapseButton,
    RangePicker,
    SelectionsBanner,
} from 'waypoint-react';
import { format } from 'date-fns';
import {
    monthlyCostCellRender,
    unpackMonthlyCosts,
    tagRender,
    getTotal,
    getRowCostTotal,
    wordWrapColumn,
    getTextValueOrEmpty,
    groupCell,
    groupCellBoolean,
    getGroupedItemCount,
    filterCapitalProjects,
    convertNumbersToRangeValues,
} from './PlanningCapitalProjectUtils';
import PlanningCapitalProjectTypesSettings from './PlanningCapitalProjectTypesSettings';
import { PlusOutlined, SettingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { dateSort, numericalSort } from 'utils/tables/sorters';
import { formatMoney } from 'utils/formatters';
import { DASH_DASH } from 'config/constants';
import {
    SavedConfigEditor,
    SavedConfigManageDropdown,
    SavedGridConfigSelect,
    applyStoredConfiguration,
    createSavedConfigurationPayload,
} from 'components/saved-configurations';
import { FilterReferenceTypes } from 'contexts';
import { monthDisplayNames } from 'utils/consts';
import { getPropertyName, getPropertyOptions } from 'waypoint-utils/entity';
import CapitalProjectEditorCell from './CapitalProjectEditorCell';
import { onContentReady } from 'waypoint-utils';
import { PlanningCapitalProjectsExportableGrid } from 'components/reports/components/entity-report-widgets/report-widget-export-grids/PlanningCapitalProjectsTableExportableGrid';
import { CalculateCustomSummaryOptions } from 'waypoint-utils/dev-extreme/weighted-averages';

const dataGridStyle = {
    boxShadow: `0px 3px 5px -1px rgba(0, 0, 0, 0.2),
                0px 6px 10px 0px rgba(0, 0, 0, 0.04)`,
};

interface PlanningCapitalProjectsTableProps {
    properties: Dictionary<PropertyType>;
    activeTab: string;
    entityCode?: string;
    dataGridRef?: React.RefObject<DataGridRef<any, any>>;
    setIsReadyForPDFExport?: (isReady: boolean) => void;
    yearsSelectedForWidget?: number[];
    configSelectedForWidget?: SavedConfiguration | null;
    hideRangePickerAndViews?: boolean;
    isModalVisible: boolean;
    setIsModalVisible: (isModalVisible: boolean) => void;
    isSettingsModalVisible: boolean;
    setIsSettingsModalVisible: (isSettingsModalVisible: boolean) => void;
    selectedRowData: CapitalProject | null;
    setSelectedRowData: (selectedRowData: CapitalProject | null) => void;
    heightOffset: number;
    isPDFExport?: boolean;
}

const PlanningCapitalProjectsTable = ({
    properties,
    activeTab,
    entityCode,
    dataGridRef,
    setIsReadyForPDFExport,
    hideRangePickerAndViews,
    yearsSelectedForWidget,
    configSelectedForWidget,
    isModalVisible,
    setIsModalVisible,
    isSettingsModalVisible,
    setIsSettingsModalVisible,
    selectedRowData,
    setSelectedRowData,
    heightOffset,
    isPDFExport,
}: PlanningCapitalProjectsTableProps) => {
    const {
        capexDataGrid,
        expanded,
        setExpanded,
        toggleExpanded,
        expandButtonEnable,
        setExpandButtonEnable,
        onRowPrepared,
        onExporting,
        capitalProjectsData,
        entityCodes,
        updateDimensions,
        isLoading,
        filteredYears,
        createYearsArray,
        yearsSelected,
        setYearsSelected,
        onAddCapitalProject,
        onUpdateCapitalProject,
        onDeleteCapitalProject,
        onRefreshData,
        calculateCustomSummary: weightedCalc,
    } = usePlanningCapitalConfig({ entityCode });

    const propertyOptions = getPropertyOptions(properties, entityCodes);

    const {
        selectedConfiguration,
        setSelectedConfiguration,
        setGridConfig,
        setLocalConfig,
        saveConfigDisabled,
        configKey,
        savedConfigurationsData,
        resetSelectedConfiguration,
        onDeleteConfig,
        onSaveConfig,
        onUpdateConfig,
        isAdmin,
        isEditorOpen,
        setIsEditorOpen,
        existingConfigNames,
    } = useGridConfigSettings(SavedConfigFilterType.CapitalProjects);

    const { data: leasesData, isLoading: isLeasesLoading } =
        useGetLeasesPerformanceOverview(entityCodes, {
            stakeholder: null,
            percentageType: null,
        });

    useEffect(() => {
        setLocalConfig({ expanded, yearsSelected });
    }, [expanded, yearsSelected]);

    useEffect(() => {
        configSelectedForWidget &&
            setSelectedConfiguration(configSelectedForWidget);
    }, [configSelectedForWidget]);

    useEffect(() => {
        if (selectedConfiguration?.filters_json?.local_config) {
            const config = selectedConfiguration.filters_json.local_config;
            config['expanded'] !== undefined && setExpanded(config['expanded']);
            config['yearsSelected'] !== undefined
                ? setYearsSelected(config['yearsSelected'])
                : setYearsSelected([]);
            setLocalConfig(config);
            return;
        }
        setExpanded(false);
        setYearsSelected(yearsSelectedForWidget ?? []);
        setLocalConfig({ expanded, yearsSelected });
    }, [selectedConfiguration, configKey]);

    useMemo(() => {
        if (yearsSelectedForWidget) {
            setYearsSelected(yearsSelectedForWidget);
        }
    }, [yearsSelectedForWidget]);

    useEffect(() => {
        updateDimensions();
    }, [activeTab]);

    const groupCellPropertyName = (options: CellInfoType | any) => {
        return (
            <div>
                {getPropertyName(propertyOptions, options.value as string)} (
                {getGroupedItemCount(options)} projects)
            </div>
        );
    };

    const DetailSection = ({ data }: { data: CapitalProject }) => {
        const types = ['budget', 'actual'];
        const startYear = moment(data.estimated_start_date).year();
        const endYear = moment(data.estimated_completion_date).year();

        const startMonth = moment(data.estimated_start_date).month();
        const endMonth = moment(data.estimated_completion_date).month();

        const monthlyCosts = unpackMonthlyCosts(data);

        const getLikeKeysFromCosts = (filterKey: string) => {
            const monthlyCostsByKey = Object.keys(monthlyCosts)
                .map((key) => {
                    if (key.includes(filterKey)) {
                        return [
                            ...monthlyCosts[key as keyof UnpackedMonthlyCosts],
                        ].filter(
                            (y) =>
                                Number(y.year) >= startYear &&
                                Number(y.year) <= endYear,
                        );
                    }

                    return null;
                })
                .filter((item) => item);
            return monthlyCostsByKey[0] ?? [];
        };

        return types.map((t) => {
            return (
                <div
                    style={{
                        marginBottom: '5px',
                        marginTop: '5px',
                        marginLeft: '8%',
                        marginRight: '8%',
                    }}
                >
                    <div
                        style={{
                            fontWeight: 'bold',
                            marginLeft: '5px',
                            marginBottom: '3px',
                        }}
                    >{`${t === 'budget' ? 'Budgeted' : 'Actual'} Costs`}</div>
                    <DataGrid
                        dataSource={getLikeKeysFromCosts(t)}
                        showBorders={true}
                        showColumnLines={true}
                    >
                        <Column
                            dataField="year"
                            caption="Year"
                            alignment="center"
                        />
                        {[...monthDisplayNames].map((month) => {
                            return (
                                <Column
                                    dataField={month.toLowerCase()}
                                    caption={month.slice(0, 3)}
                                    alignment="center"
                                    format="currency"
                                    cellRender={(e) =>
                                        monthlyCostCellRender(e, [
                                            moment(data.estimated_start_date),
                                            moment(
                                                data.estimated_completion_date,
                                            ),
                                        ])
                                    }
                                />
                            );
                        })}
                        <Column
                            caption="Total"
                            calculateCellValue={(e: {
                                [key: string]: number;
                            }) =>
                                getTotal(
                                    e,
                                    startYear,
                                    endYear,
                                    startMonth,
                                    endMonth,
                                )
                            }
                            alignment="center"
                            format="currency"
                        />
                    </DataGrid>
                </div>
            );
        });
    };

    const onClickAddRow = () => {
        setSelectedRowData(null);
        setIsModalVisible(true);
    };

    const itShouldShowTheButton = hideRangePickerAndViews ? 'block' : 'none';

    const saveState = (state: SavedConfigurationState) => {
        const config = createSavedConfigurationPayload(state);
        config && setGridConfig(config);
    };

    const calculateCustomSummary = (
        options: CalculateCustomSummaryOptions<any, any>,
    ) => {
        if (['actual_psf', 'budget_psf'].includes(options.name)) {
            weightedCalc(options);
        } else if (options.name === 'rentable_square_footage') {
            if (options.summaryProcess === 'start') {
                options.totalValue = {
                    prevValue: 0,
                    uniqueRSF: new Set<string>(),
                };
            }
            if (options.summaryProcess === 'calculate') {
                const uniqueRSFKey = `${options.value.entity_code}_${options.value.rentable_square_footage}`;
                if (!options.totalValue.uniqueRSF.has(uniqueRSFKey)) {
                    options.totalValue.prevValue +=
                        options.value.rentable_square_footage;
                }
                options.totalValue.uniqueRSF.add(uniqueRSFKey);
            }
            if (options.summaryProcess === 'finalize') {
                options.totalValue = options.totalValue.prevValue;
            }
        }
    };

    if (isLeasesLoading || isLoading) {
        return <DisabledDashboard text={'Loading...'} />;
    }

    if (!capitalProjectsData || !leasesData) {
        return <DisabledDashboard text={'No Data'} />;
    }

    const capitalProjectsDataFilteredByEntityCodes = filterCapitalProjects(
        capitalProjectsData ?? [],
        entityCodes,
        filteredYears,
        leasesData,
    );

    if (isPDFExport && setIsReadyForPDFExport) {
        return (
            <PlanningCapitalProjectsExportableGrid
                key={selectedConfiguration?.id ?? ''}
                capitalProjectsData={
                    capitalProjectsDataFilteredByEntityCodes ?? []
                }
                savedConfig={selectedConfiguration}
                setIsReadyForPDFExport={setIsReadyForPDFExport}
                propertyOptions={propertyOptions}
            />
        );
    }

    return (
        <div style={{ height: '100%', overflowX: 'scroll' }}>
            <PlanningCapitalProjectModal
                key={isModalVisible.toString()}
                capitalProjectsData={capitalProjectsData}
                onAddCapitalProject={onAddCapitalProject}
                onUpdateCapitalProject={onUpdateCapitalProject}
                selectedRowData={selectedRowData}
                setIsVisible={setIsModalVisible}
                isVisible={isModalVisible}
                propertyOptions={propertyOptions}
            />
            <PlanningCapitalProjectTypesSettings
                isVisible={isSettingsModalVisible}
                tableData={capitalProjectsData}
                onClose={() => setIsSettingsModalVisible(false)}
                onRefreshData={onRefreshData}
            />
            <SelectionsBanner
                isCollapsed={false}
                data-testid="capital-projects-selections-banner"
                bannerInlineStyles={{
                    boxShadow: 'none',
                    border: 'none',
                }}
            >
                <Button
                    style={{
                        marginRight: 10,
                        marginLeft: 10,
                        display: itShouldShowTheButton,
                    }}
                    onClick={onClickAddRow}
                    type="primary"
                    icon={<PlusOutlined />}
                >
                    Add Project
                </Button>
                {!hideRangePickerAndViews && (
                    <div style={{ display: 'flex', marginLeft: 8 }}>
                        <SavedGridConfigSelect
                            savedConfigurationsData={savedConfigurationsData}
                            selectedConfiguration={selectedConfiguration}
                            setSelectedConfiguration={setSelectedConfiguration}
                        />
                        <SavedConfigManageDropdown
                            onUpdateConfig={onUpdateConfig}
                            onDeleteConfig={onDeleteConfig}
                            resetSelectedConfiguration={
                                resetSelectedConfiguration
                            }
                            hasUserAccess={
                                !isAdmin &&
                                selectedConfiguration?.reference_type !==
                                    FilterReferenceTypes.USER
                            }
                            allowDeleteOnly={saveConfigDisabled}
                            defaultConfigSelected={!selectedConfiguration}
                            setIsEditorOpen={setIsEditorOpen}
                        />
                    </div>
                )}
                {isEditorOpen && (
                    <SavedConfigEditor
                        handleClose={() => setIsEditorOpen(false)}
                        onSave={onSaveConfig}
                        isAdmin={isAdmin}
                        existingConfigNames={existingConfigNames}
                    />
                )}
                <div>
                    {!hideRangePickerAndViews && (
                        <RangePicker
                            value={convertNumbersToRangeValues(filteredYears)}
                            placeholder={['Start Year', 'End Year']}
                            defaultValue={undefined}
                            allowClear={true}
                            picker="year"
                            onChange={(values) => createYearsArray(values)}
                            style={{ marginRight: 12 }}
                        />
                    )}
                    <Button
                        style={{
                            marginRight: 10,
                            display: itShouldShowTheButton,
                        }}
                        onClick={() =>
                            setIsSettingsModalVisible(!isSettingsModalVisible)
                        }
                        type="default"
                    >
                        <SettingOutlined />
                    </Button>
                </div>
            </SelectionsBanner>
            <DataGrid
                key={`${selectedConfiguration?.id ?? ''}_${configKey ?? ''}`}
                style={dataGridStyle}
                ref={capexDataGrid}
                dataSource={capitalProjectsDataFilteredByEntityCodes}
                onExporting={onExporting}
                height={window.innerHeight - heightOffset}
                allowColumnReordering={true}
                columnAutoWidth={true}
                showBorders={true}
                showRowLines={true}
                showColumnLines={true}
                rowAlternationEnabled={false}
                onRowPrepared={onRowPrepared}
                id="capital-projects-table"
                hoverStateEnabled
                noDataText="No data"
                onContentReady={(e) => {
                    onContentReady({ e, toggleFunc: setExpandButtonEnable });
                    if (
                        e?.component?.getDataSource() &&
                        setIsReadyForPDFExport
                    ) {
                        setIsReadyForPDFExport(true);
                    }
                }}
                onInitialized={(e: InitializedEvent) => {
                    if (selectedConfiguration && e?.component) {
                        e.component.clearSorting();
                        const hiddenColumns = !entityCode
                            ? ['entity_code']
                            : [];
                        applyStoredConfiguration(
                            e.component,
                            selectedConfiguration,
                            hiddenColumns,
                        );
                    }
                }}
            >
                <MasterDetail enabled={true} render={DetailSection} />
                <Column
                    dataField="name"
                    caption="Project"
                    allowHeaderFiltering={!hideRangePickerAndViews}
                    alignment="left"
                    fixedPosition="left"
                    fixed={true}
                    width={'auto'}
                    allowGrouping={false}
                />
                <Column
                    name="fake"
                    allowExporting={false}
                    fixed={true}
                    width={0.001}
                    showInColumnChooser={false}
                />

                <Column
                    dataField="entity_code"
                    caption="Property"
                    visible={!entityCode}
                    showInColumnChooser={!entityCode}
                    allowHeaderFiltering={!hideRangePickerAndViews}
                    alignment="left"
                    cellRender={(data) =>
                        getPropertyName(propertyOptions, data.value)
                    }
                    fixed={false}
                    width={'auto'}
                    groupCellRender={groupCellPropertyName}
                >
                    <Lookup
                        dataSource={propertyOptions}
                        valueExpr="value"
                        displayExpr="label"
                    />
                </Column>
                <Column
                    dataField="category.value"
                    caption="Category"
                    allowHeaderFiltering={!hideRangePickerAndViews}
                    alignment="center"
                    groupCellRender={groupCell}
                    customizeText={getTextValueOrEmpty}
                />
                <Column
                    allowGrouping={false}
                    dataField="rentable_square_footage"
                    dataType="number"
                    caption="RSF"
                    alignment={'center'}
                    minWidth={140}
                    allowHeaderFiltering={false}
                    allowEditing={false}
                >
                    <Format type="fixedPoint" precision={0} />
                </Column>
                <Column
                    dataField="subcategory.value"
                    caption="Subcategory"
                    allowHeaderFiltering={!hideRangePickerAndViews}
                    alignment="center"
                    groupCellRender={groupCell}
                    customizeText={getTextValueOrEmpty}
                />
                <Column
                    dataField="description"
                    caption="Description"
                    allowHeaderFiltering={false}
                    alignment="left"
                    cssClass={wordWrapColumn}
                    minWidth={300}
                    customizeText={getTextValueOrEmpty}
                    allowGrouping={false}
                />
                <Column
                    dataField="priority.value"
                    caption="Priority"
                    allowHeaderFiltering={!hideRangePickerAndViews}
                    alignment="center"
                    cellRender={(data) => tagRender(data, 'priority')}
                    groupCellRender={groupCell}
                />
                <Column
                    dataField="status.value"
                    caption="Status"
                    allowHeaderFiltering={!hideRangePickerAndViews}
                    alignment="center"
                    cellRender={(data) => tagRender(data, 'status')}
                    groupCellRender={groupCell}
                />
                <Column
                    dataField="underwriting"
                    caption="Incl. in UW"
                    allowHeaderFiltering={!hideRangePickerAndViews}
                    alignment="center"
                    groupCellRender={groupCellBoolean}
                />
                <Column
                    dataField="is_escalatable"
                    caption="Escalatable"
                    allowHeaderFiltering={!hideRangePickerAndViews}
                    alignment="center"
                    groupCellRender={groupCellBoolean}
                />
                <Column
                    dataField="energy_saving"
                    caption="Energy Savings"
                    allowHeaderFiltering={!hideRangePickerAndViews}
                    alignment="center"
                    groupCellRender={groupCellBoolean}
                />
                <Column
                    dataField="energy_estimated_savings"
                    caption="Est. Savings"
                    allowHeaderFiltering={false}
                    alignment="center"
                    dataType={'number'}
                    customizeText={(cellInfo: CustomizeCellInfoType) => {
                        return cellInfo.valueText
                            ? formatMoney(cellInfo.valueText)
                            : DASH_DASH;
                    }}
                />

                <Column
                    dataField="estimated_start_date"
                    caption="Start Date"
                    dataType="date"
                    alignment="center"
                    headerFilter={{
                        groupInterval: 'month',
                    }}
                    cellRender={(data) =>
                        data.value
                            ? format(new Date(data.value), 'MMM yyyy')
                            : DASH_DASH
                    }
                    sortingMethod={dateSort}
                    allowGrouping={false}
                />
                <Column
                    dataField="estimated_completion_date"
                    caption="End Date"
                    alignment="center"
                    headerFilter={{
                        groupInterval: 'month',
                    }}
                    dataType="date"
                    cellRender={(data) =>
                        data.value
                            ? format(new Date(data.value), 'MMM yyyy')
                            : DASH_DASH
                    }
                    sortingMethod={dateSort}
                    allowGrouping={false}
                />
                <Column
                    allowSorting
                    name="total_budget"
                    caption="Total Budget"
                    minWidth={120}
                    width={'auto'}
                    fixed={true}
                    alignment="center"
                    fixedPosition="right"
                    calculateCellValue={(e: CapitalProject) =>
                        getRowCostTotal(e, 'budget')
                    }
                    sortingMethod={numericalSort}
                    allowGrouping={false}
                >
                    <Format type="currency" precision={2} />
                </Column>
                <Column
                    allowSorting
                    caption="Total Actual"
                    name="total_actual"
                    minWidth={120}
                    width={'auto'}
                    alignment="center"
                    fixed={true}
                    fixedPosition={'right'}
                    calculateCellValue={(e: CapitalProject) =>
                        getRowCostTotal(e, 'actual')
                    }
                    sortingMethod={numericalSort}
                    allowGrouping={false}
                >
                    <Format type="currency" precision={2} />
                </Column>

                <Column
                    caption="Actual / SF"
                    dataField="actual_psf"
                    fixed={true}
                    fixedPosition="right"
                    defaultVisible={false}
                    dataType={'number'}
                    allowHeaderFiltering={false}
                    alignment={'center'}
                    allowGrouping={false}
                >
                    <Format type="currency" precision={2} />
                </Column>

                <Column
                    caption="Budget / SF"
                    dataField="budget_psf"
                    fixed={true}
                    fixedPosition="right"
                    defaultVisible={false}
                    dataType={'number'}
                    alignment={'center'}
                    allowHeaderFiltering={false}
                    allowGrouping={false}
                >
                    <Format type="currency" precision={2} />
                </Column>

                <Column
                    fixed={true}
                    fixedPosition="right"
                    width={85}
                    allowExporting={false}
                    showInColumnChooser={false}
                    cellRender={(e) => (
                        <CapitalProjectEditorCell
                            data={e.data}
                            setSelectedRowData={setSelectedRowData}
                            setIsModalVisible={setIsModalVisible}
                            onDeleteCapitalProject={onDeleteCapitalProject}
                        />
                    )}
                />

                <Summary calculateCustomSummary={calculateCustomSummary}>
                    <GroupItem
                        column="name"
                        alignByColumn
                        summaryType="count"
                        displayFormat="{0} Projects"
                    />

                    <GroupItem column="fake" alignByColumn />

                    <GroupItem
                        column="Total Budget"
                        summaryType="sum"
                        displayFormat="{0}"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        alignByColumn
                    />
                    <GroupItem
                        column="Total Actual"
                        summaryType="sum"
                        displayFormat="{0}"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        alignByColumn
                    />
                    <TotalItem
                        column="name"
                        summaryType="count"
                        displayFormat="{0} Projects"
                    />

                    <TotalItem
                        column="Total Budget"
                        summaryType="sum"
                        displayFormat="{0}"
                        valueFormat={{ type: 'currency', precision: 2 }}
                    />
                    <TotalItem
                        column="Total Actual"
                        summaryType="sum"
                        displayFormat="{0}"
                        valueFormat={{ type: 'currency', precision: 2 }}
                    />
                    <TotalItem
                        name="actual_psf"
                        showInColumn="actual_psf"
                        displayFormat="{0}"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        summaryType="custom"
                    />
                    <TotalItem
                        name="budget_psf"
                        showInColumn="budget_psf"
                        displayFormat="{0}"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        summaryType="custom"
                    />
                    <TotalItem
                        name="rentable_square_footage"
                        showInColumn="rentable_square_footage"
                        displayFormat="{0}"
                        summaryType="custom"
                        valueFormat={{ type: 'fixedpoint', precision: 0 }}
                    />
                    <GroupItem
                        name="actual_psf"
                        showInColumn="actual_psf"
                        summaryType="custom"
                        displayFormat="{0}"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        alignByColumn
                    />
                    <GroupItem
                        name="budget_psf"
                        showInColumn="budget_psf"
                        summaryType="custom"
                        displayFormat="{0}"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        alignByColumn
                    />
                    <GroupItem
                        name="rentable_square_footage"
                        showInColumn="rentable_square_footage"
                        summaryType="custom"
                        alignByColumn
                        valueFormat={{ type: 'fixedpoint', precision: 0 }}
                        displayFormat="{0}"
                    />
                </Summary>
                <ColumnChooser
                    enabled={!hideRangePickerAndViews}
                    mode={'select'}
                    height={400}
                    allowSearch={true}
                >
                    <Position
                        my="right top"
                        at="right bottom"
                        of=".dx-datagrid-column-chooser-button"
                    />
                    <ColumnChooserSelection
                        allowSelectAll={true}
                        recursive={true}
                    />
                </ColumnChooser>
                <Paging enabled={true} defaultPageSize={60} />

                <Pager
                    visible
                    displayMode={'full'}
                    showPageSizeSelector={true}
                    allowedPageSizes={[20, 40, 60, 80, 100]}
                    showNavigationButtons={true}
                    showInfo={true}
                    infoText="Page {0} of {1} ({2} items)"
                />
                <FilterPanel visible={!hideRangePickerAndViews} />
                <HeaderFilter
                    allowSelectAll={true}
                    allowSearch={true}
                    visible={true}
                />
                <Grouping
                    autoExpandAll={expanded}
                    contextMenuEnabled={!hideRangePickerAndViews}
                />
                <GroupPanel visible={!hideRangePickerAndViews} />
                <Toolbar>
                    <ToolbarItem location="after" name="columnChooserButton" />
                    <ToolbarItem name="exportButton" />
                    <ToolbarItem name="searchPanel" />
                    <ToolbarItem name="groupPanel" />
                    <ToolbarItem
                        location="before"
                        visible={!hideRangePickerAndViews}
                    >
                        <ExpandAndCollapseButton
                            expanded={expanded}
                            toggleExpanded={toggleExpanded}
                            expandButtonEnable={expandButtonEnable}
                        />
                    </ToolbarItem>
                </Toolbar>
                <SearchPanel visible={true} highlightCaseSensitive={false} />
                <Export enabled={true} allowExportSelectedData={false} />
                <StateStoring
                    enabled={!setIsReadyForPDFExport}
                    type="custom"
                    savingTimeout={100}
                    customSave={saveState}
                />
            </DataGrid>
        </div>
    );
};

export default PlanningCapitalProjectsTable;
