import { find, curry, flatten, first, last, range, uniq, get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import Select from 'react-select';
import PeriodPickerMenu from './PeriodPickerMenu';
import { timestampToMonth, timestampToQuarter } from './utils';
import { BLUE } from './colors';
import 'react-select/dist/react-select.css';

// NOTE: External margin must be synced between PeriodPicker and PeriodPickerMenu
const margin = 3;
// NOTE: Keep CellWidth sync'd with Cell
const cellWidth = 75 + 4; // width + interstitial margin

// NOTE: See moment docs for input format explanations: https://momentjs.com/docs/#/parsing/
const startOfPeriod = curry((inputFormat, formatAsUtc, period) =>
    Number(
        formatAsUtc
            ? moment.utc(period, inputFormat).format('x')
            : moment(period, inputFormat).format('x')
    )
);
const PeriodPicker = createReactClass({
    displayName: 'PeriodPicker',

    propTypes: {
        type: PropTypes.oneOf(['month', 'quarter']),
        timestamps: PropTypes.array,
        disabled: PropTypes.bool,
        options: PropTypes.array,
        value: PropTypes.any,
        lastYear: PropTypes.number,
        firstYear: PropTypes.number,
        selectableWhenDisabled: PropTypes.bool,
    },

    defaultProps: {
        type: 'month',
        closeOnSelect: true,
        formatAsUtc: true,
    },

    getInitialState() {
        // NOTE: When using redux-form, this value
        //       Exists but is ignored
        return {
            selectedOption: '',
        };
    },

    generatePeriodStrings(optionsPerYear, sortedTimestamps) {
        const firstYear =
            this.props.firstYear ||
            Number(
                this.props.formatAsUtc
                    ? moment.utc(first(sortedTimestamps), 'x').format('YYYY')
                    : moment(first(sortedTimestamps), 'x').format('YYYY')
            );
        const lastYear =
            this.props.lastYear ||
            Number(
                this.props.formatAsUtc
                    ? moment.utc(last(sortedTimestamps), 'x').format('YYYY')
                    : moment(last(sortedTimestamps), 'x').format('YYYY')
            );
        const yearsToFill = range(firstYear, lastYear + 1);

        return flatten(
            yearsToFill.map((year) => {
                return range(1, optionsPerYear + 1).map(
                    (period) => `${year}-${period}`
                );
            })
        );
    },

    generatePeriodData() {
        const { type, timestamps, formatAsUtc } = this.props;
        const sortedTimestamps = uniq(timestamps).sort();
        const startOfMonth = startOfPeriod('YYYY-MM')(formatAsUtc);
        const startOfQuarter = startOfPeriod('YYYY-Q')(formatAsUtc);
        let optionsPerYear = 12;
        let periodParser = startOfMonth;
        let timestampToLabel = timestampToMonth;
        if (type === 'quarter') {
            optionsPerYear = 4;
            periodParser = startOfQuarter;
            timestampToLabel = timestampToQuarter;
        }

        // Generate 1 or more years of period options
        const allPeriodOptions = this.generatePeriodStrings(
            optionsPerYear,
            sortedTimestamps
        )
            .map(periodParser)
            .map((t) => ({
                label: timestampToLabel(t, formatAsUtc),
                value: t,
                disabled: true,
            }));
        // Then selectively enable options based on timestamp array
        sortedTimestamps.forEach((t) => {
            const periodLabel = timestampToLabel(t, formatAsUtc);
            const correspondingPeriod = find(
                allPeriodOptions,
                ({ label }) => label === periodLabel
            );
            if (correspondingPeriod) {
                correspondingPeriod.value = t;
                correspondingPeriod.disabled = false;
            }
        });
        return allPeriodOptions;
    },

    handleChange(selectedOption) {
        // NOTE: When using redux-form, this handler is overwritten
        this.setState({ selectedOption });
    },

    renderMenu(selectMenuProps) {
        const { selectableWhenDisabled, value, ...rest } = this.props;
        const { firstYear, lastYear } = rest;
        return (
            <PeriodPickerMenu
                firstYear={firstYear}
                lastYear={lastYear}
                type={this.props.type}
                {...selectMenuProps}
                value={value}
                selectableWhenDisabled={selectableWhenDisabled}
            />
        );
    },

    renderCalendarIcon({ onMouseDown }) {
        return (
            <i
                style={{ color: BLUE }}
                onMouseDown={onMouseDown}
                className="fa-regular fa-calendar"
            />
        );
    },

    render() {
        const { state, props } = this;
        const { type, timestamps, ...rest } = props;
        const { removeTitle, title } = rest;
        const { selectedOption } = state;
        // NOTE: Assigned disabled differently to avoid conflicting names / excess verbosity
        const disabled = !uniq(timestamps).length || props.disabled;

        let columns = 3;
        let labelText = title || 'MONTH';
        if (type === 'quarter') {
            columns = 2;
            labelText = 'QUARTER';
        }
        const options = this.generatePeriodData();
        const placeholder = '–';
        const width = cellWidth * columns + margin * 2;
        return (
            <div
                style={{
                    width: props.width || width,
                }}
            >
                {!removeTitle && (
                    <div
                        style={{
                            fontSize: '12px',
                            color: '#888888',
                            marginTop: '2px',
                            marginBottom: '2px',
                        }}
                    >
                        {labelText}
                    </div>
                )}
                <Select
                    name="period_picker"
                    style={{ borderRadius: '0px' }}
                    value={get(selectedOption, 'value')}
                    options={options}
                    disabled={disabled}
                    onChange={this.handleChange}
                    clearable={false}
                    searchable={false}
                    placeholder={placeholder}
                    menuRenderer={this.renderMenu}
                    arrowRenderer={this.renderCalendarIcon}
                    // NOTE: onChange, onBlur, value props overwrite here
                    {...rest}
                    menuContainerStyle={this.props.menuContainerStyle}
                />
            </div>
        );
    },
});

export default PeriodPicker;
