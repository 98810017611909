// @ts-nocheck
// This file is a result of nx update

import { user as TYPES } from 'state/actionTypes';

export const toggleIsLoadingProperties = (data) => {
    return {
        type: TYPES.TOGGLE_LOADING_PROPERTIES,
        payload: data,
    };
};

export function setActiveAccountTab(data) {
    return {
        type: TYPES.SET_ACTIVE_ACCOUNT_TAB,
        payload: { activeAccountTab: data },
    };
}

export function setDecimalDisplay(value) {
    return {
        type: TYPES.SET_DECIMAL_DISPLAY,
        payload: { value },
    };
}

export function setNegativeValue(value) {
    return {
        type: TYPES.SET_NEGATIVE_VALUE,
        payload: { value },
    };
}
