import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'waypoint-react';
import { getOvertimeTableColumns } from 'components/analytics/balance-sheet-overview/tables/overtime/utils';

const OvertimeTable = ({ data, periodicity, displayType, height = 215 }) => {
    return (
        <Table
            data={data}
            columns={getOvertimeTableColumns(data, periodicity, displayType)}
            height={height}
            size="small"
            data-testid="component-overtime-table"
        />
    );
};

OvertimeTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    displayType: PropTypes.string,
    periodicity: PropTypes.string,
    height: PropTypes.number,
};

export default OvertimeTable;
