import { deleteReports, restoreReports } from 'waypoint-requests';

export const useReports = () => {
    const restoreReportsRequest = async (reportIds: string[]) => {
        const response = await restoreReports({ reportIds });
        return response;
    };

    const deleteReportRequest = async (
        reportIds: string[],
        deletePermanently: boolean
    ) => {
        const deleteReportsRequestsParams = {
            reportIds,
            deletePermanently,
        };
        const response = await deleteReports(deleteReportsRequestsParams);
        return response;
    };

    return {
        restoreReportsRequest,
        deleteReportRequest,
    };
};
