import { API_URL } from 'config/constants';

interface DeletedNote {
    id: string;
}

const deleteNote = async (note_id: string): Promise<DeletedNote> => {
    const response = await fetch(`${API_URL}/notes/${note_id}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
    });

    if (!response.ok) {
        throw new Error('Failed to delete note');
    }

    const { data } = await response.json();
    return data as DeletedNote;
};

export default deleteNote;
