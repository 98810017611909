import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { ClientOwnershipPercentageType } from 'waypoint-types';
import useSWR from 'swr';
import { getClientOwnershipPercentageTypes } from 'waypoint-requests';

export function useGetClientOwnershipPercentageTypes(): DataHookResponse<
    ClientOwnershipPercentageType[]
> {
    const { data, error, mutate } = useSWR(
        `/ownership-percentage-types`,
        getClientOwnershipPercentageTypes
    );

    return {
        isLoading: !data,
        isError: !!error,
        data,
        mutate,
    };
}
