import { message } from 'antd';
import useSWR from 'swr';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import getValuations from 'waypoint-requests/valuations/getValuations';
import { ValuationsWithMethodOptions } from 'waypoint-types/valuations/types';

export const useGetValuations = (
    entityCode: string
): DataHookResponse<ValuationsWithMethodOptions> => {
    const { data, error, mutate, isValidating } = useSWR(
        `/api/valuations/${entityCode}`,
        () => getValuations(entityCode),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
            onError: () => {
                message.error('Failed to fetch valuations');
            },
            shouldRetryOnError: false,
        }
    );
    return {
        isLoading: !data,
        isError: !!error,
        data,
        mutate,
        isValidating,
    };
};
