import {
    LeaseExpirationScheduleProps,
    CustomizeCellInfoType,
    CellInfoType,
} from 'waypoint-types';
import { useGetClientData } from 'waypoint-hooks';
import { exportExcelFromDevExtremeDataGrid } from 'waypoint-utils';
import DataGrid, {
    Export,
    HeaderFilter,
    StateStoring,
    Column,
    Format,
    Summary,
    TotalItem,
    Paging,
    DataGridRef,
} from 'devextreme-react/data-grid';
import { ExportingEvent } from 'devextreme/ui/data_grid';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import {
    ExpirationDataGroupKeys,
    expirationGroupingByOptions,
    expirationGroupingKeyName,
    expirationLabelsByGroupingOption,
    EXPIRED_KEY,
    isYearSelected,
    VACANT_KEY,
} from './utils';
import { isNil } from 'lodash';
import { useEffect } from 'react';
import { ExpirationScheduleExportableGrid } from 'components/reports/components/entity-report-widgets/report-widget-export-grids/ExpirationScheduleExportableGrid';
import { DASH_DASH } from 'config/constants';

interface ExpirationScheduleTableProps {
    leaseExpirations: LeaseExpirationScheduleProps[];
    error?: boolean;
    dataGridRef?: React.RefObject<DataGridRef<any, any>>;
    isPDFExport?: boolean;
    setIsTableReadyToExport?: (value: boolean) => void;
    groupByOption?: expirationGroupingByOptions;
}

const ExpirationScheduleTable = ({
    leaseExpirations,
    dataGridRef,
    isPDFExport,
    setIsTableReadyToExport,
    groupByOption = expirationGroupingByOptions.year,
}: ExpirationScheduleTableProps): JSX.Element => {
    useEffect(() => {
        if (!isPDFExport) {
            dataGridRef?.current?.instance()?.clearFilter();
            dataGridRef?.current?.instance()?.clearSorting();
        }
    }, [groupByOption, dataGridRef, isPDFExport]);

    const clientDisplayName: string = useGetClientData('display_name');

    const groupByKey = expirationGroupingKeyName[groupByOption];

    const leasesWithExpiringEntries = leaseExpirations
        .filter(
            (lease) =>
                lease.is_expired ||
                (isYearSelected(groupByOption)
                    ? !isNil(lease.total_expiring_leases) &&
                    lease.total_expiring_leases > 0
                    : true)
        )
        .map((item) => {
            const vacantExpiring = isPDFExport ? 0 : DASH_DASH;
            return {
                ...item,
                total_expiring_leases:
                    item.expiration_year === VACANT_KEY
                        ? vacantExpiring
                        : item.total_expiring_leases,
            };
        });

    const onExporting = async (e: ExportingEvent) => {
        await exportExcelFromDevExtremeDataGrid(e, {
            worksheetName: 'Lease Expiration Schedule',
            filename: 'Lease Expiration Schedule.xlsx',
        });
    };

    /* 
        the property cumulative_expiring_area_percentage is not multiplied by 100
        because the % comes as a whole number e.g 100% = 100 not 1
        only add the suffix is enough to make the number look like a percentage 
    */
    const addPercentageSuffix = (cellInfo: CustomizeCellInfoType) =>
        `${cellInfo.value}%`;

    const headerTextRender = (caption: string) => {
        return (
            <div
                style={
                    isPDFExport ? { width: '100px', paddingBottom: '1px' } : {}
                }
            >
                {caption}
            </div>
        );
    };

    const onContentReady = () => {
        setIsTableReadyToExport && setIsTableReadyToExport(true);
    };

    if (isPDFExport) {
        return (
            <ExpirationScheduleExportableGrid
                data={leasesWithExpiringEntries}
                groupByKey={groupByKey}
                groupByOption={groupByOption}
            />
        );
    }

    return (
        <DataGrid
            ref={dataGridRef}
            wordWrapEnabled={false}
            dataSource={leasesWithExpiringEntries}
            onExporting={onExporting}
            height={'auto'}
            allowColumnReordering={true}
            columnAutoWidth={true}
            columnMinWidth={isPDFExport ? 120 : 160}
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}
            rowAlternationEnabled={true}
            onContentReady={onContentReady}
            data-testid="expiration-schedule-table"
            id="expirationScheduleTable"
        >
            <Column
                dataField={groupByKey}
                caption={expirationLabelsByGroupingOption[groupByOption]}
                headerCellRender={() =>
                    headerTextRender(
                        expirationLabelsByGroupingOption[groupByOption],
                    )
                }
                allowHeaderFiltering={!isPDFExport}
                alignment="center"
                dataType={'string'}
                calculateCellValue={(rowData: LeaseExpirationScheduleProps) => {
                    const value =
                        rowData[groupByKey as keyof ExpirationDataGroupKeys];
                    if (value === null) {
                        return EXPIRED_KEY;
                    }
                    return `${value}`;
                }}
            />
            <Column
                dataField="total_expiring_leases"
                caption={'# of Leases'}
                headerCellRender={() => headerTextRender('# of Leases')}
                allowHeaderFiltering={false}
                alignment="center"
            />
            <Column
                dataField="expiring_square_footage"
                caption={'Area'}
                headerCellRender={() => headerTextRender('Area')}
                allowHeaderFiltering={false}
                alignment="center"
            >
                <Format type="fixedPoint" precision={0} />
            </Column>
            <Column
                dataField="expiring_square_footage_percentage"
                caption={'% of Total Area'}
                headerCellRender={() => headerTextRender('% of Total Area')}
                allowHeaderFiltering={false}
                alignment="center"
            >
                <Format type="percent" precision={1} />
            </Column>
            <Column
                dataField="cumulative_expiring_area"
                caption={'Cumulative Area'}
                headerCellRender={() => headerTextRender('Cumulative Area')}
                allowHeaderFiltering={false}
                alignment="center"
            >
                <Format type="fixedPoint" precision={0} />
            </Column>
            <Column
                dataField="cumulative_expiring_area_percentage"
                caption={'Cumulative Area %'}
                headerCellRender={() => headerTextRender('Cumulative Area %')}
                allowHeaderFiltering={false}
                alignment="center"
                customizeText={addPercentageSuffix}
            />
            <Column
                dataField="in_place_base_rent"
                caption={'Total Annual Base Rent'}
                headerCellRender={() =>
                    headerTextRender('Total Annual Base Rent')
                }
                allowHeaderFiltering={false}
                alignment="center"
            >
                <Format type="currency" precision={2} />
            </Column>
            <Column
                dataField="in_place_base_rent_percentage"
                caption={'% of Gross Annual Rent'}
                headerCellRender={() =>
                    headerTextRender('% of Gross Annual Rent')
                }
                allowHeaderFiltering={false}
                alignment="center"
            >
                <Format type="percent" precision={1} />
            </Column>

            <Summary skipEmptyValues={true}>
                <TotalItem column={groupByKey} displayFormat="Total" />
                <TotalItem
                    column="total_expiring_leases"
                    summaryType="sum"
                    valueFormat={{ type: 'fixedPoint', precision: 0 }}
                    displayFormat="{0}"
                />
                <TotalItem
                    column="in_place_base_rent"
                    summaryType="sum"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                />
                <TotalItem
                    column="in_place_base_rent_percentage"
                    summaryType="sum"
                    valueFormat={{ type: 'percent', precision: 1 }}
                    displayFormat="{0}"
                />
                <TotalItem
                    column="expiring_square_footage"
                    summaryType="sum"
                    valueFormat={{ type: 'fixedPoint', precision: 0 }}
                    displayFormat="{0}"
                />
                <TotalItem
                    column="expiring_square_footage_percentage"
                    summaryType="sum"
                    valueFormat={{ type: 'percent', precision: 1 }}
                    displayFormat="{0}"
                />
                <TotalItem
                    column="cumulative_expiring_area"
                    summaryType="max"
                    valueFormat={{ type: 'fixedPoint', precision: 0 }}
                    displayFormat="{0}"
                />
                <TotalItem
                    column="cumulative_expiring_area_percentage"
                    summaryType="max"
                    valueFormat={{ type: 'fixedPoint', precision: 1 }}
                    displayFormat="{0}%"
                />
            </Summary>
            <Paging pageSize={leaseExpirations.length} />
            <HeaderFilter allowSearch={true} visible={true} />
            <Export enabled={!isPDFExport} allowExportSelectedData={false} />
            <StateStoring
                enabled={!isPDFExport} // this will be disabled for entity report widgets
                type="localStorage"
                storageKey={`${clientDisplayName}_leases_expiration_table`}
            />
        </DataGrid>
    );
};

export default ExpirationScheduleTable;
