import { API_URL } from 'config/constants';
import { CapitalProject, CapitalProjectMonthlyCost } from 'waypoint-types';

interface ClientDefinedType {
    type: string;
    value: string;
    tag_color: string;
}

interface CreateCapitalProjectsParams {
    entity_code: string;
    name: string;
    description: string;
    energy_saving: boolean;
    underwriting: boolean;
    category: ClientDefinedType;
    subcategory: ClientDefinedType;
    priority: ClientDefinedType;
    status: ClientDefinedType;
    estimated_start_date: string | null;
    estimated_completion_date: string | null;
    energy_estimated_savings?: number;
    monthly_costs: CapitalProjectMonthlyCost[];
}

const createCapitalProject = async (
    body: CreateCapitalProjectsParams
): Promise<CapitalProject> => {
    const response = await fetch(
        `${API_URL}/capital-project/${body.entity_code}`,
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(body),
        }
    );

    if (!response.ok) {
        throw new Error('Failed to create capital project');
    }

    const { data } = await response.json();

    return data as CapitalProject;
};

export default createCapitalProject;
