const GROSS_DISPLAY_LABEL = 'Gross $';

const grossDisplayValue = {
    label: GROSS_DISPLAY_LABEL,
    value: 'gross',
    axisName: GROSS_DISPLAY_LABEL,
    tooltipText: null,
    decimalFormat: '$,.0f',
};

export const PERIODICITY_MONTH_VALUE = 'monthly';
export const PERIODICITY_QUARTER_VALUE = 'quarterly';
export const PERIODICITY_YEAR_VALUE = 'yearly';
export const PERIODICITY_MONTH_LABEL = 'Month';
export const PERIODICITY_QUARTER_LABEL = 'Quarter';
export const PERIODICITY_YEAR_LABEL = 'Year';

export const periodicityOptions = [
    { value: PERIODICITY_MONTH_VALUE, label: PERIODICITY_MONTH_LABEL },
    { value: PERIODICITY_QUARTER_VALUE, label: PERIODICITY_QUARTER_LABEL },
    { value: PERIODICITY_YEAR_VALUE, label: PERIODICITY_YEAR_LABEL },
];

export const defaultDisplayValue = grossDisplayValue;

const CALENDAR_YEAR_LABEL = 'Calendar Year';
const TRAILING_TWELVE_LABEL = 'Trailing 12';
const YTD_LABEL = 'YTD';
const LATEST_MONTH_LABEL = 'Latest Month';

export const periods = {
    ytd: {
        label: YTD_LABEL,
        type: YTD_LABEL,
    },
    calendarYear: {
        label: CALENDAR_YEAR_LABEL,
        abbreviatedLabel: 'CY',
        type: CALENDAR_YEAR_LABEL,
    },
    trailingTwelve: {
        label: TRAILING_TWELVE_LABEL,
        abbreviatedLabel: 'T12',
        type: TRAILING_TWELVE_LABEL,
    },
    latestMonth: {
        label: LATEST_MONTH_LABEL,
        type: LATEST_MONTH_LABEL,
    },
};

export const PRIMARY_COLUMN_NAME = { property: 'Property', account: 'Account' };

export const TABLE_CHART = 'TABLE';
export const PIE_CHART = 'PIE';
export const BAR_CHART = 'BAR';

export const PERFORMANCE_OVERVIEW_CHARTS = [
    { key: 'BAR', name: 'Bar' },
    { key: 'PIE', name: 'Pie' },
    { key: 'TABLE', name: 'Table' },
];
