const section = {
    display: 'flex',
    width: '200px',
    justifyContent: 'space-between',
};

const topContentStyle = { margin: '5px 0 10px 0 ' };
const bottomContentStyle = { margin: '10px 0 5px 0 ' };

const FinancialOverviewTooltip = ({ title, topContent, bottomContent }) => {
    return (
        <div>
            {title && (
                <h3 style={{ textAlign: 'center', marginTop: 0 }}>{title}</h3>
            )}
            <div style={{ ...section, ...topContentStyle }}>
                <div>{topContent.label}</div>
                <div style={{ fontWeight: 'bold', color: topContent.color }}>
                    {topContent.value}
                </div>
            </div>
            <div style={{ ...section, ...bottomContentStyle }}>
                <div>{bottomContent.label}</div>
                <div style={{ fontWeight: 'bold', color: bottomContent.color }}>
                    {bottomContent.value}
                </div>
            </div>
        </div>
    );
};

export default FinancialOverviewTooltip;
