import React from 'react';
import { css, cx } from 'emotion';

const modal = css`
    box-sizing: border-box;
    outline: none;
    padding: 0px;
    position: fixed;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 550px;
    overflow: visible;
    bottom: 35%;
    overflow: auto;
`;
const container = css`
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    top: 0;
    overflow: auto;
`;

const overlay = css`
    top: 0;
    left: 0;
    height: 100%;
    width: 100vw;
    position: fixed;
    background-color: #182738;
    opacity: 0.6;
    overflow-y: scroll;
`;

interface ModalProps {
    zIndex: number;
    className: string;
    children: React.ReactNode;
}

// NOTE: You may need to use a className or style prop to postion/style children
// NOTE: This is deprecated. Please use AntD's modal: https://ant.design/components/modal/
const Modal = ({ zIndex, children, className }: ModalProps) => {
    return (
        <div className={container} style={{ display: 'flex', height: '100%' }}>
            <div className={overlay} style={{ zIndex: zIndex || 2 }} />
            <div
                className={cx([modal, className])}
                style={{ zIndex: zIndex || 2 }}
            >
                {children}
            </div>
        </div>
    );
};

export default Modal;
