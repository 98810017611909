import PropTypes from 'prop-types';
import React from 'react';
import { css, cx } from 'emotion';
import { DARK_GRAY } from 'config/colors';
import EmptyState from 'components/style/EmptyState';
import { loadingText, noDataText } from 'components/leases/tenants/constants';

const emptyState = css`
    margin-top: 2.2em;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    color: ${DARK_GRAY};
    h1 {
        font-weight: 400;
        text-align: center;
    }
`;
const imgStyle = css`
    width: 100px;
    margin-bottom: 6px;
`;

const TenantEmptyState = ({ isLoading = false, className, imgClassName }) => {
    return (
        <EmptyState
            headerComponent={
                <div className={cx([emptyState, className])}>
                    <img
                        src="/assets/img/Leases-Empty-Icon@2x.png"
                        className={cx([imgStyle, imgClassName])}
                    />
                    <h1>{isLoading ? loadingText : noDataText}</h1>
                </div>
            }
        />
    );
};

TenantEmptyState.propTypes = {
    className: PropTypes.string,
    imgClassName: PropTypes.string,
    isLoading: PropTypes.bool,
};

export default TenantEmptyState;
