export const FINANCIALS_INCOME_STATEMENT = 'financials-income-statement';
export const FINANCIALS_BALANCE_SHEET = 'financials-balance-sheet';

export const LEASES_OCCUPANCY_TREND = 'occupancy-trend';
export const LEASES_EXPIRATIONS = 'leases-expirations';
export const LEASES_RENT_ROLL = 'leases-rent-roll';
export const LEASES_TOP_TENANTS = 'leases-top-tenants';
export const LEASES_UNIT_MIX = 'leases-unit-mix';

export const PLANNING_CAPITAL_PROJECTS = 'capital-projects';

export const SECTION_KEY = 'section';
export const SUBSECTION_KEY = 'subsection';
