import { API_URL } from 'config/constants';

const uploadAttributes = async (
    attributesUpload: FormData
): Promise<string[]> => {
    const response = await fetch(`${API_URL}/attributes/upload`, {
        method: 'POST',
        credentials: 'include',
        body: attributesUpload,
    });

    if (!response.ok) {
        throw new Error('Failed to upload attributes');
    }

    const { data } = await response.json();

    return data as string[];
};

export default uploadAttributes;
