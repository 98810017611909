import { message } from 'antd';
import useSWR from 'swr';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { getContracts } from 'waypoint-requests';
import { ContractsWithVendorAndServiceTypeOptions } from 'waypoint-types';

export const useGetContracts = (
    entityCodes: string[]
): DataHookResponse<ContractsWithVendorAndServiceTypeOptions> => {
    const { data, error, mutate, isValidating } = useSWR(
        `/api/contracts/${entityCodes.join(',')}`,
        () => getContracts(entityCodes),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
            onError: () => {
                message.error('Failed to fetch contracts');
            },
            shouldRetryOnError: false,
        }
    );
    return {
        isLoading: !data || isValidating,
        isError: !!error,
        data,
        mutate,
        isValidating,
    };
};
