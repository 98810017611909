import { API_URL } from 'config/constants';

const getDocumentUploadById = async (
    documentUploadId: string
): Promise<ArrayBuffer> => {
    const response = await fetch(
        `${API_URL}/document-uploads/${documentUploadId}`,
        {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        }
    );

    if (!response.ok) {
        throw new Error('FAILED');
    }
    const data = await response.arrayBuffer();
    return data;
};

export default getDocumentUploadById;
