import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import useSWR, { SWRConfiguration } from 'swr';
import { getPropertyValuation } from 'waypoint-requests';
import { PropertyProfileValuation } from 'waypoint-types';

export const useGetPropertyValuation = (
    entityCode: string,
    config?: SWRConfiguration
): DataHookResponse<PropertyProfileValuation[]> => {
    const { data, error, mutate } = useSWR(
        entityCode ? `/properties/valuation/${entityCode}` : null,
        () => {
            return getPropertyValuation(entityCode);
        },
        config
    );

    return {
        data,
        mutate,
        isLoading: !data,
        isError: !!error,
    };
};
