import { isNull, isUndefined, assign } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { numberWithCommas } from './utils';
import { DASH_DASH } from 'config/constants';

export default function numberWithCommasPP(WrappedComponent) {
    class NumberWithCommas extends React.Component {
        render() {
            const { cellData, style, ...rest } = this.props;
            // NOTE: We must pass rest through instead of spreading props
            //       So that we don't overwrite the formatted cellData
            const isInvalid = isNull(cellData) || isUndefined(cellData);
            const s = assign(
                {},
                {
                    justifyContent: 'flex-start',
                    paddingRight: '15px',
                },
                style
            );
            if (isInvalid) {
                s.fontWeight = 500;
                s.letterSpacing = '1px';
            }
            // Guard against null and undefined values
            const datum = isInvalid ? DASH_DASH : numberWithCommas(cellData);
            return (
                <WrappedComponent
                    style={s}
                    cellData={datum}
                    originalValue={cellData}
                    {...rest}
                />
            );
        }
    }

    NumberWithCommas.propTypes = {
        style: PropTypes.object,
        cellData: PropTypes.any,
    };

    return NumberWithCommas;
}
