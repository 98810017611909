import { API_URL } from 'config/constants';
import { EntityReportWorkflowRole } from 'waypoint-types';

const getWorkflowRolesByEntityCodes = async (
    entityCodes: string[]
): Promise<EntityReportWorkflowRole[]> => {
    const response = await fetch(`${API_URL}/workflow-roles/entity-codes`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(entityCodes),
    });

    if (!response.ok) {
        throw new Error('Failed to get workflow roles');
    }

    const { data } = await response.json();

    return data as EntityReportWorkflowRole[];
};

export default getWorkflowRolesByEntityCodes;
