import { EntityOwnershipContext } from './EntityOwnershipContext';
import { useGetClientOwnershipPercentageTypes } from 'waypoint-hooks/data-access/useGetClientOwnershipPercentageTypes';
import { PropsWithChildren } from 'react';
import { useClientPrefixedLocalStorage } from 'waypoint-hooks';
import { SelectedDataLevel } from 'waypoint-types';

interface EntityOwnershipProviderProps {
    isDisabled: boolean;
}

export function EntityOwnershipProvider({
    isDisabled,
    children,
}: PropsWithChildren<EntityOwnershipProviderProps>): JSX.Element {
    const {
        data: percentageTypeOptions,
        isLoading: isPercentageTypesLoading,
        isError: isPercentageTypesError,
    } = useGetClientOwnershipPercentageTypes();

    const defaultDataLevel: SelectedDataLevel = {
        percentageType: null,
        stakeholder: null,
    };

    const [selectedDataLevel, setSelectedDataLevel] =
        useClientPrefixedLocalStorage<SelectedDataLevel>(
            'dataLevel',
            defaultDataLevel
        );

    return (
        <EntityOwnershipContext.Provider
            value={{
                data: {
                    percentageTypeOptions,
                    selectedDataLevel: isDisabled
                        ? defaultDataLevel
                        : selectedDataLevel,
                    setSelectedDataLevel,
                },
                isError: isPercentageTypesError,
                isLoading: isPercentageTypesLoading,
            }}
        >
            {children}
        </EntityOwnershipContext.Provider>
    );
}
