import React from 'react';

interface PropertyOptionsProps {
    option: {
        length: number;
        value: string;
        color: string;
    };
}

const PropertyOption = ({ option }: PropertyOptionsProps) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    marginRight: 6,
                    backgroundColor: option.color,
                    borderRadius: '50%',
                    width: '8px',
                    height: '8px',
                }}
            >
                &nbsp;
            </div>
            <div
                title={`${option.value} (${option.length})`}
                style={{
                    maxWidth: '24ch',
                }}
            >
                {option.value} ({option.length})
            </div>
        </div>
    );
};

export default PropertyOption;
