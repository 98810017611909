import React, { Component } from 'react';
import { pick, get, isEqual, isUndefined } from 'lodash';
import { toISO } from 'components/dates/utils';
import { connect } from 'react-redux';
import { message } from 'antd';
import { saveAs } from 'file-saver';
import { ActionsCard, Modal } from 'waypoint-react';
import { getFilenameFromResponse } from 'waypoint-utils';
import { getPureId } from 'state/routing/selectors';
import { cardHashMap } from 'components/analytics/financialOverview/cards';
import { FinancialOverviewMenu } from 'components/analytics/financialOverview/menu';

/*
    This component:
        1. Renders a modal with a menu when gear icon is clicked
        2. Manages the selections made when a user presses apply
        3. Passes selections as props to the card content
*/

class FinancialOverviewGridItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true,
            showMenu: false,
            selections: null,
            downloading: false,
        };
        this.showMenu = this.showMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.handleApply = this.handleApply.bind(this);
        this.handleReset = this.handleReset.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        const { entityCodes } = this.props;

        const selectionHasChanged = !isEqual(
            prevState.selections,
            this.state.selections,
        );

        const entityHasChanged = !isEqual(
            prevProps.entityCodes,
            this.props.entityCodes,
        );

        if (
            (!isUndefined(entityCodes) &&
                (selectionHasChanged || entityHasChanged)) ||
            this.getHasGlobalPeriodChanged(
                prevProps.globalPeriod,
                this.props.globalPeriod,
            )
        ) {
            this.fetchData();
        }
    }

    /**
     * This utlizes getSelectionOrDefaultFor to get all menu values. Period is the exception. It is dervied from the globalPeriod passed from the parent.
     * @returns {object} - all selections (or defaults)
     */
    getAllSelectionsOrDefaults() {
        return {
            period: this.props.globalPeriod,
            displayType: this.getSelectionOrDefaultFor('displayType'),
            periodicity: this.getSelectionOrDefaultFor('periodicity'),
            accountMapping: this.getSelectionOrDefaultFor('accountMapping'),
            isHideNull: this.getSelectionOrDefaultFor('isHideNull'),
        };
    }

    /**
     * This is a patch. Comparing moment instances in componentDidUpdate caused new requests because the unix timestamp of the end date technically changes as time passes. However, we are only concerned with date changes and a better fix would be to store only ISO dates in state.
     */
    getHasGlobalPeriodChanged = (prevGlobalPeriod, currGlobalPeriod) => {
        return (
            toISO(prevGlobalPeriod[0]) !== toISO(currGlobalPeriod[0]) ||
            toISO(prevGlobalPeriod[1]) !== toISO(currGlobalPeriod[1])
        );
    };

    setData(data) {
        this.setState({ data, loading: false });
    }

    setErrorState(error) {
        this.setState({ error, loading: false, data: [] });
    }

    getSelectionOrDefaultFor(field) {
        return get(this.state.selections, field, this.props.defaults[field]);
    }

    getParametersForRequest() {
        const selections = this.getAllSelectionsOrDefaults();
        const params = {
            account_mapping_code: selections.accountMapping.code,
            account_mapping_name: selections.accountMapping.name,
            start_date: selections.period[0] // TODO: move to utils
                .startOf('month')
                .format('YYYY-MM-DD'),
            end_date: selections.period[1].endOf('month').format('YYYY-MM-DD'),
            display_type: selections.displayType,
        };

        if (Array.isArray(selections.accountMapping.name)) {
            params.account_mapping_name = selections.accountMapping.name[0];
        }

        if (this.props.componentName === 'overtime') {
            params.periodicity = selections.periodicity;
        }
        if (this.props.componentName === 'breakdown') {
            params.is_hide_null = selections.isHideNull;
        }
        return params;
    }

    fetchData() {
        const { entityCodes, selectedDataLevel, request, componentName } =
            this.props;

        if (componentName === 'title' || !request) {
            // Don't request data for Title components
            this.setState({ loading: false, data: [] });
            return;
        }

        this.setState({ loading: true, data: [] }, () => {
            request
                .get({
                    entityCodes,
                    selectedDataLevel,
                    ...this.getParametersForRequest(),
                })
                .then((res) => res.json())
                .then(({ data }) => this.setData(data))
                .catch(() => this.setErrorState());
        });
    }

    downloadCsv = () => {
        const { entityCodes, selectedDataLevel, request } = this.props;

        this.setState({ downloading: true }, async () => {
            try {
                const data = await request.download({
                    entityCodes,
                    selectedDataLevel,
                    ...this.getParametersForRequest(),
                });

                const blob = await data.blob();
                const filename = getFilenameFromResponse(data);
                saveAs(blob, filename);
            } catch (e) {
                message.error(
                    'Download Failed',
                    'An error occurred while downloading the file',
                );
            } finally {
                this.setState({ downloading: false });
            }
        });
    };

    showMenu() {
        this.setState({ showMenu: true });
    }

    closeMenu() {
        this.setState({ showMenu: false });
    }

    handleApply(newSelections) {
        this.setState({ selections: { ...newSelections } });
        this.closeMenu();
    }

    handleReset() {
        this.handleApply(this.props.defaults);
    }

    render() {
        const componentToRender = get(
            cardHashMap,
            this.props.componentName,
            ActionsCard,
        );

        const { entityCodes } = this.props;
        const selections = this.getAllSelectionsOrDefaults();

        return (
            <div>
                {this.state.showMenu && (
                    <Modal>
                        <FinancialOverviewMenu
                            selections={selections}
                            handleClose={this.closeMenu}
                            handleApply={this.handleApply}
                            handleReset={this.handleReset}
                            accountGraph={this.props.accountGraph}
                            showHideNullValuesSelector={
                                this.props.componentName === 'breakdown'
                            }
                            isOvertime={this.props.componentName === 'overtime'}
                        />
                    </Modal>
                )}
                {React.createElement(componentToRender, {
                    ...pick(this.props, ['data-grid', 'style', 'title']), // NOTE: title is only used on title card
                    data: this.state.data,
                    onClick: this.showMenu,
                    period: selections.period,
                    displayType: selections.displayType,
                    loading: this.state.loading,
                    periodicity: selections.periodicity,
                    accountMappingName: selections.accountMapping.name,
                    accountMappingCode: selections.accountMapping.code,
                    downloadCsv: this.downloadCsv,
                    downloading: this.state.downloading,
                    entityCodes,
                    // TODO: pass whole account mapping { name: <name>, code: <code>}
                })}
            </div>
        );
    }
}
const mapState = (s) => ({ entityOrEntityGroupCode: getPureId(s) });
export default connect(mapState)(FinancialOverviewGridItem);
