import { RecurringCharge, SelectedDataLevel } from 'waypoint-types';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import useSWR from 'swr';
import { getRentSteps } from 'waypoint-requests';

export const useRentSteps = (
    entityCodes: string[],
    selectedDataLevel: SelectedDataLevel
): DataHookResponse<RecurringCharge[]> => {
    const recurringChargesCacheKey = `/leases/rent-steps/${entityCodes.join(
        ','
    )}/${selectedDataLevel.percentageType}/${selectedDataLevel.stakeholder}`;

    const { data, error, mutate, isValidating } = useSWR(
        entityCodes.length ? recurringChargesCacheKey : null,
        () => getRentSteps(entityCodes, selectedDataLevel),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
        }
    );

    return {
        isLoading: !data || isValidating,
        isError: !!error,
        data,
        mutate,
        isValidating,
    };
};
