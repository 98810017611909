import { API_URL } from 'config/constants';
import { CapitalProject } from 'waypoint-types';

const updateCapitalProject = async (
    entity_code: string,
    projectId: string,
    body: CapitalProject
): Promise<CapitalProject> => {
    const response = await fetch(
        `${API_URL}/capital-project/${entity_code}/${projectId}`,
        {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(body),
        }
    );

    if (!response.ok) {
        throw new Error('Failed to update capital project');
    }

    const { data } = await response.json();

    return data as CapitalProject;
};

export default updateCapitalProject;
