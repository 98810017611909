import React, { useState } from 'react';
import { TreeSelect } from 'antd';
import { RecurringCharge } from 'waypoint-types';
import { Dictionary } from 'ts-essentials';

interface RecurringChargeSelectProps {
    recurringCharges: Dictionary<RecurringCharge[]>;
    onChange: (value: string[]) => void;
    selectedChargeCodes?: string[];
    useDrawerStyle?: boolean;
    style?: React.CSSProperties;
    loading?: boolean;
}

export const RecurringChargeSelect = ({
    recurringCharges,
    onChange,
    selectedChargeCodes = undefined,
    useDrawerStyle = false,
    style = {},
    loading,
}: RecurringChargeSelectProps) => {
    const [selectedChargeCodesState, setSelectedChargeCodesState] = useState<
        string[]
    >(selectedChargeCodes ?? []);
    const [isDropdownOpened, setIsDropdownOpened] = useState<boolean>(false);

    return (
        <TreeSelect
            loading={loading}
            size={'middle'}
            value={selectedChargeCodesState}
            allowClear
            multiple
            maxTagCount={3}
            showSearch
            treeCheckable={true}
            showCheckedStrategy={TreeSelect.SHOW_PARENT}
            treeNodeFilterProp="title"
            style={
                useDrawerStyle
                    ? { width: '200px', marginBottom: '25px', ...style }
                    : { width: '400px', ...style }
            }
            treeData={Object.values(recurringCharges)}
            placeholder={'Select Charges'}
            onChange={(value: string[]) => {
                if (!isDropdownOpened) {
                    setSelectedChargeCodesState(value);
                    onChange(value);
                }
                setSelectedChargeCodesState(value);
            }}
            onDropdownVisibleChange={(open) => {
                setIsDropdownOpened(open);
                if (!open) {
                    onChange(selectedChargeCodesState);
                }
            }}
        />
    );
};
