import PropTypes from 'prop-types';
import React from 'react';
import defaultHeaderStylePP from './defaultHeaderStylePP';
import CaretControl from './CaretControl';

export const HeaderCell = ({
    sortable,
    cellData,
    onClick,
    disabled,
    active,
    style,
    sortDirection,
    ...rest
}) => {
    const { headerTitle } = cellData;
    return (
        <div
            style={Object.assign({}, style, {
                backgroundColor: '#FFF',
                fontSize: '14px',
            })}
            {...rest}
        >
            {headerTitle}
            {sortable && (
                <CaretControl
                    active={active}
                    disabled={disabled}
                    sortDirection={sortDirection}
                    onClick={onClick}
                />
            )}
        </div>
    );
};

HeaderCell.propTypes = {
    cellData: PropTypes.any,
    style: PropTypes.object,
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    sortable: PropTypes.bool,
    sortDirection: PropTypes.string,
    onClick: PropTypes.func,
};

export const HeaderCellRenderer = defaultHeaderStylePP(HeaderCell);
export default HeaderCellRenderer;
