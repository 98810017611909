import { AccountGraphNode } from 'waypoint-types';
import { filterAccountGraph } from 'waypoint-utils';
import { SHOW_OVER_THRESHOLDS } from 'waypoint-utils/account-graphs/constants';

/**
 * It filters the account graph based on the array of filter
 * @param {array} accountGraph deeply nested array representing an account graph
 */
const checkForThresholdExceeded = (accountGraph: AccountGraphNode[] | null) => {
    if (!accountGraph) {
        return false;
    }

    return filterAccountGraph(accountGraph, [SHOW_OVER_THRESHOLDS]).length > 0;
};

export default checkForThresholdExceeded;
