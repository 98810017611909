import React, { useState, useEffect } from 'react';
import { css } from 'emotion';
import { message } from 'antd';
import { decorateThresholdsRules } from './utils';
import { Table } from 'waypoint-react';
import { getThresholdRules } from 'waypoint-requests';

interface ColumnThresholdType {
    title: string;
    dataIndex: string;
    key: string;
}

const columns: ColumnThresholdType[] = [
    {
        title: 'Periodicity',
        dataIndex: 'periodicity',
        key: 'periodicity',
    },
    {
        title: 'Fixed Variance Amount',
        dataIndex: 'variance_amount',
        key: 'variance_amount',
    },
    {
        title: 'Percent Variance Amount',
        dataIndex: 'variance_percent',
        key: 'variance_percent',
    },
];

const thresholdsTable = css`
    width: 600px !important;
`;

const ViewThresholds = (): JSX.Element => {
    const [threshold, setThreshold] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchThresholdRules = async () => {
        try {
            const data = await getThresholdRules();
            setThreshold(decorateThresholdsRules(data));
        } catch (e) {
            message.error(
                'Request Failed! An error occurred while requesting thresholds',
            );
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchThresholdRules();
    }, []);

    return (
        <div className={thresholdsTable}>
            <Table
                size={'small'}
                data={threshold}
                loading={loading}
                columns={columns}
                loadingRows={3}
                height={300}
            />
        </div>
    );
};

export default ViewThresholds;
