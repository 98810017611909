import { Layout, MenuProps, Skeleton } from 'antd';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import { css } from 'emotion';
import React, {
    ReactElement,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { SWRConfig } from 'swr';
import { Dictionary } from 'ts-essentials';
import { WHITE } from 'config/colors';
import { PropertyType } from 'waypoint-types';
import PropertyProfileMenu from './PropertyProfileMenu';
import { MenuItem } from './types';
import PropertyProfileSection from './PropertyProfileSection';
import { LeasesSection } from './LeasesSection';
import FinancialSection from './FinancialSection';
import StrategyPlanningSection from './StrategyPlanningSection';
import {
    useGetEntityProfile,
    useGetFilteredEntityCodes,
    useGetQueryParam,
} from 'waypoint-hooks';
import {
    setQueryParam,
    clearQueryParam,
} from 'waypoint-hooks/useGetQueryParam';
import { HOLD_SELL_CARD_TYPE } from 'components/holdSellAnalysis/HoldSellAnalysis';
import { SWOT_CARD_TYPE } from 'components/swotAnalysis/SWOTAnalysis';
import { MARKET_OVERVIEW_CARD_TYPE } from 'components/marketOverview/MarketOverview';
import { ANNUAL_PRIORITIES_AND_OBJECTIVES_CARD_TYPE } from 'components/annualPrioritiesAndObjectives/AnnualPrioritiesAndObjectives';
import { DisabledDashboard } from 'waypoint-react';
import { LEASING_GUIDELINES_CARD_TYPE } from 'components/leasingGuidelines/LeasingGuidelines';
import { CONTRACTS_CARD_TYPE } from 'components/contracts/ContractsContainer';
import { VALUATIONS_CARD_TYPE } from 'components/valuations/ValuationsTable';
import { ValuationsSection } from './ValuationsSection';
import { LEASING_PLAN_CARD_TYPE } from 'components/leasingPlan/LeasingPlanCard';
import { AGED_RECEIVABLES_CARD_TYPE } from 'components/agedReceivables/AgedReceivablesCard';
import { EntityOwnershipDataLevelDropdown } from 'components/ownershipPercentage/EntityOwnershipDataLevelDropdown';
import { AsOfDateIcon } from 'components/app/as-of-date';
import theme from 'config/theme';
import { usePermissions } from 'contexts';
import { AppFeaturePermissions } from 'shared-types';
import DocumentsSection from './DocumentsSection';
import {
    CommentsContextType,
    useCommentsPane,
} from 'contexts/comments/CommentsContext';

import { COMMENT_ID_QUERY_KEY } from 'components/reports/ReportUtils';
import { useCommentsContextManager } from 'contexts/comments/CommentsManagerContext';
import { PropertyProfileCommentPaneSubheading } from 'components/comments/CommentsUtils';
import { PropertyProfileMenuSections } from './PropertyProfileUtils';
import {
    FINANCIALS_BALANCE_SHEET,
    FINANCIALS_INCOME_STATEMENT,
    LEASES_EXPIRATIONS,
    LEASES_OCCUPANCY_TREND,
    LEASES_RENT_ROLL,
    LEASES_TOP_TENANTS,
    LEASES_UNIT_MIX,
    PLANNING_CAPITAL_PROJECTS,
    SECTION_KEY,
    SUBSECTION_KEY,
} from './constants';
import { useGetReportMetadataByCommentId } from 'waypoint-hooks/data-access/useGetReportMetadataByCommentId';
import { ItemType } from 'antd/es/menu/interface';

const { Content, Header, Sider } = Layout;

interface PropertyDetailContainerProps {
    entityCode: string;
    propertyInfo: PropertyType;
    hasOccupancyTrendEnabled: boolean;
    hasBalanceSheetEnabled: boolean;
    hasCapitalTrackerEnabled: boolean;
    hasPlanningSectionEnabled: boolean;
    hasUnitMixEnabled: boolean;
    hasAgedReceivablesEnabled: boolean;
    hasOwnershipPercentageEnabled: boolean;
    hasDocumentsEnabled: boolean;
}

const mainContentPane = css`
    background-color: ${theme.colors.grays.background};
    height: 100%;
    overflow-y: auto;
`;

const propertyNameStyle = css`
    text-align: left;
    font-size: 30px;
    font-weight: 700;
    display: flex;
    align-items: center;
    margin-right: 15px;
    margin-left: 10px;
`;

const dataLevelWrapper = css`
    display: flex;
    align-items: 'center;
    margin-right: 16px;
    margin-top: 3px;
`;

const asOfDateContainer = css`
    display: flex;
    align-items: center;
    margin-top: 5px;
`;

const headerStyle = css`
    width: 100%;
    height: 70px;
    background-color: ${WHITE};
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    padding-right: 10px;
    padding-left: 10px;
    position: sticky;
    top: 0px;
    z-index: 100;
`;

const commentsIconStyle = css`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 5px;
    margin-right: 15px;
`;

const layoutStyle = css`
    flex-wrap: wrap;
    height: calc(100% - 70px);
`;

const filterShowItems = (menuItems: MenuItem[]): MenuItem[] =>
    menuItems.filter((item) => item.showItem === true);

const PropertyProfileContainer = ({
    entityCode,
    propertyInfo,
    hasOccupancyTrendEnabled,
    hasBalanceSheetEnabled,
    hasCapitalTrackerEnabled,
    hasPlanningSectionEnabled,
    hasUnitMixEnabled,
    hasAgedReceivablesEnabled,
    hasOwnershipPercentageEnabled,
    hasDocumentsEnabled,
}: PropertyDetailContainerProps) => {
    const { featurePermissions } = usePermissions();
    const [siderCollapsed, setSiderCollapsed] = useState(false);
    const sectionQueryParam = useGetQueryParam(SECTION_KEY);

    const commentIdQueryParam = useGetQueryParam(COMMENT_ID_QUERY_KEY);
    const entityCodes = useGetFilteredEntityCodes();

    const { data: entityProfile } = useGetEntityProfile(entityCode);

    const commentPaneCtx: CommentsContextType = useCommentsPane();
    const { update, initializeComments, openComments, toggleComments } =
        commentPaneCtx;
    const { registerCommentThread } = useCommentsContextManager();

    const componentKey = useRef(new Date().toISOString());

    useEffect(() => {
        registerCommentThread(commentPaneCtx, componentKey.current);
    }, [commentPaneCtx]);

    const leaseChildren: MenuItem[] = [
        {
            key: AGED_RECEIVABLES_CARD_TYPE,
            label: 'Aged Receivables',
            showItem:
                featurePermissions?.includes(
                    AppFeaturePermissions.AgedReceivables,
                ) && hasAgedReceivablesEnabled,
        },
        {
            key: LEASES_EXPIRATIONS,
            label: 'Expiration Schedule',
            showItem: featurePermissions?.includes(
                AppFeaturePermissions.ExpirationSchedule,
            ),
        },
        {
            key: LEASES_RENT_ROLL,
            label: 'Rent Roll',
            showItem: featurePermissions?.includes(
                AppFeaturePermissions.RentRoll,
            ),
        },
        {
            key: LEASES_TOP_TENANTS,
            label: 'Top Tenants',
            showItem: featurePermissions?.includes(
                AppFeaturePermissions.TopTenants,
            ),
        },
        {
            key: LEASES_UNIT_MIX,
            label: 'Unit Mix',
            showItem:
                featurePermissions?.includes(AppFeaturePermissions.UnitMix) &&
                hasUnitMixEnabled,
        },
        {
            key: LEASES_OCCUPANCY_TREND,
            label: 'Occupancy Trend',
            showItem:
                featurePermissions?.includes(
                    AppFeaturePermissions.OccupancyTrend,
                ) && hasOccupancyTrendEnabled,
        },
    ];

    const financials = [
        {
            key: FINANCIALS_INCOME_STATEMENT,
            label: 'Income Statement',
            showItem: featurePermissions?.includes(
                AppFeaturePermissions.IncomeStatement,
            ),
        },
    ];

    const financialChildren = hasBalanceSheetEnabled
        ? [
              ...financials,

              {
                  key: FINANCIALS_BALANCE_SHEET,
                  label: 'Balance Sheet',
                  showItem: featurePermissions?.includes(
                      AppFeaturePermissions.BalanceSheet,
                  ),
              },
          ]
        : financials;

    const strategyAndPlanningChildren = [
        {
            key: PLANNING_CAPITAL_PROJECTS,
            label: 'Capital',
            showItem: featurePermissions?.includes(
                AppFeaturePermissions.CapitalProjects,
            ),
        },
        {
            key: ANNUAL_PRIORITIES_AND_OBJECTIVES_CARD_TYPE,
            label: 'Priorities & Objectives',
            showItem: featurePermissions?.includes(
                AppFeaturePermissions.PrioritiesAndObjectives,
            ),
        },
        {
            key: MARKET_OVERVIEW_CARD_TYPE,
            label: 'Market Overview',
            showItem: featurePermissions?.includes(
                AppFeaturePermissions.MarketOverview,
            ),
        },
        {
            key: SWOT_CARD_TYPE,
            label: 'SWOT',
            showItem: featurePermissions?.includes(AppFeaturePermissions.Swot),
        },
        {
            key: HOLD_SELL_CARD_TYPE,
            label: 'Hold/Sell',
            showItem: featurePermissions?.includes(
                AppFeaturePermissions.HoldSell,
            ),
        },
        {
            key: LEASING_GUIDELINES_CARD_TYPE,
            label: 'Leasing Guidelines',
            showItem: featurePermissions?.includes(
                AppFeaturePermissions.LeasingGuidelines,
            ),
        },
        {
            key: LEASING_PLAN_CARD_TYPE,
            label: 'Leasing Plan',
            showItem: featurePermissions?.includes(
                AppFeaturePermissions.LeasingPlan,
            ),
        },
        {
            key: CONTRACTS_CARD_TYPE,
            label: 'Service Contracts',
            showItem: featurePermissions?.includes(
                AppFeaturePermissions.Contracts,
            ),
        },
    ];

    const valuationsChildren = [
        {
            key: VALUATIONS_CARD_TYPE,
            label: 'Valuations',
            showItem: featurePermissions?.includes(
                AppFeaturePermissions.Valuations,
            ),
        },
    ];

    const commonItems = [
        {
            key: 'executive-summary',
            label: 'Executive Summary',
            showItem: featurePermissions?.includes(
                AppFeaturePermissions.ExecutiveSummary,
            ),
        },
        {
            key: 'property-attributes',
            label: 'Property Attributes',
            showItem: featurePermissions?.includes(
                AppFeaturePermissions.Attributes,
            ),
        },
    ];

    const overviewChildrenHasOwnership = hasOwnershipPercentageEnabled
        ? [
              {
                  key: 'ownership',
                  label: 'Ownership',
                  showItem: true,
              },
              ...commonItems,
          ]
        : commonItems;

    const overviewChildren = [
        {
            key: 'property-info',
            label: 'Property Information',
            showItem: featurePermissions?.includes(
                AppFeaturePermissions.PropertyInformation,
            ),
        },
    ];

    overviewChildren.push(...overviewChildrenHasOwnership);

    const unvalidatedMenuItems: (boolean | MenuItem)[] = [
        filterShowItems(overviewChildren).some((b) => b.showItem) && {
            key: 'overview',
            label: 'Overview',
        },
        filterShowItems(leaseChildren).some((b) => b.showItem) && {
            key: 'leases',
            label: 'Leasing',
        },
        filterShowItems(financialChildren).some((b) => b.showItem) && {
            key: 'financials',
            label: 'Financials',
        },
    ];

    const sectionMenuItems: MenuItem[] = unvalidatedMenuItems.filter(
        Boolean,
    ) as MenuItem[];

    const valuationsSection = filterShowItems(valuationsChildren).some(
        (c) => c.showItem,
    ) && {
        key: 'valuations',
        label: 'Valuations',
    };

    const strategyAndPlanningSection = filterShowItems(
        strategyAndPlanningChildren,
    ).some((c) => c.showItem) && {
        key: 'strategyAndPlanning',
        label: 'Strategy & Planning',
    };

    const documentsSection = featurePermissions?.includes(
        AppFeaturePermissions.Documents,
    ) && {
        key: 'documents',
        label: 'Documents',
    };

    const mappedMenuChildren: Dictionary<ItemType[]> = {
        [PropertyProfileMenuSections.Overview]: [],
        [PropertyProfileMenuSections.Leasing]: filterShowItems(leaseChildren),
        [PropertyProfileMenuSections.Financials]:
            filterShowItems(financialChildren),
        [PropertyProfileMenuSections.Valuations]: [],
        [PropertyProfileMenuSections.StrategyAndPlanning]: filterShowItems(
            strategyAndPlanningChildren,
        ),
        [PropertyProfileMenuSections.Documents]: [],
    };

    if (valuationsSection) {
        sectionMenuItems.push(valuationsSection);
    }

    if (hasPlanningSectionEnabled && strategyAndPlanningSection) {
        sectionMenuItems.splice(1, 0, strategyAndPlanningSection);
    }

    if (hasDocumentsEnabled && documentsSection) {
        sectionMenuItems.push(documentsSection);
    }

    const [activeSection, setActiveSection] = useState(
        sectionQueryParam || sectionMenuItems[0]?.key,
    );

    const {
        data: reportMetadataForCommentId,
        isValidating: isValidatingReportMetadata,
    } = useGetReportMetadataByCommentId(commentIdQueryParam ?? undefined);

    useEffect(() => {
        initializeComments(entityProfile?.comment_thread_id, entityCode);
        update({
            heading: propertyInfo
                ? `${propertyInfo?.name} (${propertyInfo?.display_code})`
                : '',
            subheading: PropertyProfileCommentPaneSubheading,
            scrollToCommentId: commentIdQueryParam,
        });
        if (
            commentIdQueryParam &&
            !reportMetadataForCommentId &&
            !isValidatingReportMetadata
        ) {
            openComments();
        }
    }, [
        entityProfile,
        commentIdQueryParam,
        reportMetadataForCommentId,
        isValidatingReportMetadata,
    ]);

    useEffect(() => {
        if (sectionMenuItems.length > 0) {
            const sectionExists = sectionMenuItems.some(
                (item) => item.key === sectionQueryParam,
            );
            if (!sectionExists) {
                clearQueryParam(SECTION_KEY);
                clearQueryParam(SUBSECTION_KEY);
                setActiveSection(sectionMenuItems[0]?.key);
                return;
            }
            setActiveSection(sectionQueryParam || sectionMenuItems[0]?.key);
        }
    }, [sectionMenuItems, sectionQueryParam]);

    const onClick: MenuProps['onClick'] = (e) => {
        const newMenuSelection = e.key;
        clearQueryParam('comment_id');
        setActiveSection(e.key);
        setQueryParam(SECTION_KEY, e.key);
        const menuSectionChildren = mappedMenuChildren[newMenuSelection];
        if (menuSectionChildren.length) {
            const key = menuSectionChildren[0]?.key as string;
            setQueryParam(SUBSECTION_KEY, key);
            return;
        }
        setQueryParam(SUBSECTION_KEY, newMenuSelection);
    };

    const onSetSubsection = (subsection: string) => {
        setQueryParam(SUBSECTION_KEY, subsection);
    };

    const sectionsByRootMenuItemKey: Dictionary<() => ReactElement> = {
        overview: () => (
            <PropertyProfileSection
                entityCode={entityCode}
                featurePermissions={featurePermissions}
            />
        ),
        strategyAndPlanning: () => (
            <StrategyPlanningSection
                key={reportMetadataForCommentId?.id ?? ''}
                entityCode={entityCode}
                menuItems={
                    mappedMenuChildren[
                        PropertyProfileMenuSections.StrategyAndPlanning
                    ]
                }
                onSetSubsection={onSetSubsection}
                hasCapitalTrackerEnabled={hasCapitalTrackerEnabled}
            />
        ),
        leases: () => (
            <LeasesSection
                hasAgedReceivablesEnabled={hasAgedReceivablesEnabled}
                hasOccupancyTrendEnabled={hasOccupancyTrendEnabled}
                hasUnitMixEnabled={hasUnitMixEnabled}
                entityCode={entityCode}
                menuItems={
                    mappedMenuChildren[PropertyProfileMenuSections.Leasing]
                }
                onSetSubsection={onSetSubsection}
            />
        ),
        financials: () => (
            <FinancialSection
                hasBalanceSheetEnabled={hasBalanceSheetEnabled}
                entityCodes={[entityCode]}
                menuItems={
                    mappedMenuChildren[PropertyProfileMenuSections.Financials]
                }
                onSetSubsection={onSetSubsection}
            />
        ),
        valuations: () => <ValuationsSection entityCode={entityCode} />,
        documents: () => <DocumentsSection entityCode={entityCode} />,
    };

    const SectionComponent = useMemo(
        () => sectionsByRootMenuItemKey[activeSection] ?? (() => null),
        [activeSection],
    );

    if (
        !entityCodes.filteredEntityCodes.includes(entityCode) ||
        !sectionMenuItems?.length ||
        isValidatingReportMetadata
    ) {
        return (
            <div style={{ width: '100%', height: '100%' }}>
                <DisabledDashboard text={'No data'} />
            </div>
        );
    }

    return (
        <Layout className={layoutStyle}>
            <Header className={headerStyle}>
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    {hasOwnershipPercentageEnabled ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {propertyInfo ? (
                                <div
                                    className={propertyNameStyle}
                                    data-test="property-detail-property-title"
                                >
                                    {propertyInfo.name} (
                                    {propertyInfo.display_code})
                                </div>
                            ) : (
                                <Skeleton paragraph={{ rows: 0 }} active />
                            )}
                            <div className={dataLevelWrapper}>
                                <EntityOwnershipDataLevelDropdown />
                            </div>
                        </div>
                    ) : (
                        <div>
                            {propertyInfo ? (
                                <div
                                    className={propertyNameStyle}
                                    data-test="property-detail-property-title"
                                >
                                    {propertyInfo.name} (
                                    {propertyInfo.display_code})
                                </div>
                            ) : (
                                <Skeleton paragraph={{ rows: 0 }} active />
                            )}
                        </div>
                    )}
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: '15px',
                    }}
                >
                    <div
                        className={commentsIconStyle}
                        onClick={() => toggleComments()}
                    >
                        <i className="fa-regular fa-comment-dots fa-xl" />
                    </div>
                    <div className={asOfDateContainer}>
                        <AsOfDateIcon entityCode={entityCode} />
                    </div>
                </div>
            </Header>

            {/* left sidebar */}
            <Sider
                style={{
                    height: '100%',
                    overflowY: 'auto',
                }}
                width={220}
                theme={'light'}
                collapsible={false}
                collapsed={siderCollapsed}
                onCollapse={(collapsed) => setSiderCollapsed(collapsed)}
            >
                <PropertyProfileMenu
                    defaultOpenKeys={[activeSection]}
                    menuItems={sectionMenuItems}
                    onClick={onClick}
                    selectedKeys={[activeSection || '']}
                />
            </Sider>

            {/* main content */}
            <Content className={mainContentPane}>
                <SWRConfig
                    value={{
                        revalidateOnFocus: false,
                        revalidateOnMount: true,
                        dedupingInterval: 5000,
                    }}
                >
                    <SectionComponent key={activeSection} />
                </SWRConfig>
            </Content>
        </Layout>
    );
};

export default PropertyProfileContainer;
