import React from 'react';
import { first } from 'lodash';
import { TreeSelect } from 'antd';

const AccountSelect = (props) => {
    const { value, loading, accountGraph, onChange } = props;
    return (
        <TreeSelect
            showSearch
            treeNodeFilterProp="title"
            style={{ width: '100%' }}
            value={loading ? null : value}
            disabled={loading}
            treeDefaultExpandedKeys={[value]}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            treeData={accountGraph}
            placeholder={loading ? 'Loading...' : 'Select Account'}
            onChange={(code, name) => {
                const accountMappingName = first(name); // Tree-select emits an array for array for second argumen
                onChange(code, accountMappingName);
            }}
            {...props}
        />
    );
};

export default AccountSelect;
