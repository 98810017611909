import React from 'react';
import { Table } from 'waypoint-react';
import { integerRenderer } from 'utils/tables/renderers';

const columns = [
    {
        title: 'Industry',
        dataIndex: 'industry',
        ellipsis: true,
    },
    {
        title: 'WALE',
        dataIndex: 'totalWALE',
        ellipsis: true,
        align: 'right',
        render: (val) => integerRenderer(val, 1),
    },
];

const WALEByIndustryTable = ({ data: tenants = [], height = 275 }) => {
    return (
        <Table data={tenants} columns={columns} size="small" height={height} />
    );
};

export default WALEByIndustryTable;
