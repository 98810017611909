import React, { useEffect, useState } from 'react';
import { FilterReferenceTypes } from 'contexts';
import { ActionsCard, DisabledDashboard } from 'waypoint-react';
import { useGridConfigSettings } from 'waypoint-hooks';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import Skeleton from 'antd/lib/skeleton';
import {
    AttributeDefinition,
    Entity,
    SavedConfigFilterType,
} from 'waypoint-types';
import {
    SavedConfigEditor,
    SavedConfigManageDropdown,
    SavedGridConfigSelect,
} from 'components/saved-configurations';
import { Button } from 'antd';
import { EditOutlined, SaveOutlined, SettingOutlined } from '@ant-design/icons';
import AttributesDownloadModal from '../modal/AttributesDownloadModal';
import AttributesUploadModal from '../modal/AttributesUploadModal';
import { CARD_HEIGHT_OFFSET, DASHBOARD_MARGIN, cardTitle } from './utils';
import { KeyedMutator } from 'swr';
import { GetAttributesV2Response } from 'waypoint-requests/attributes/getAttributesV2';
import { AttributesDataGrid } from './AttributesDataGrid';
import AttributesDrawer from './AttributesDrawer';

export interface AttributesCardProps {
    attributes: AttributeDefinition[];
    entities: Partial<Entity>[];
    entityCodes: string[];
    isLoading: boolean;
    error: string | null;
    mutateAttributes: KeyedMutator<GetAttributesV2Response>;
}

export const AttributesEditableCard = ({
    attributes,
    entities,
    entityCodes,
    isLoading,
    error,
    mutateAttributes,
}: AttributesCardProps): JSX.Element => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [downloadModalIsOpen, setDownloadModalIsOpen] =
        useState<boolean>(false);
    const [uploadModalIsOpen, setUploadModalIsOpen] = useState<boolean>(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [triggerSaveAction, setTriggerSaveAction] = useState<boolean>(false);
    const [triggerCancelAction, setTriggerCancelAction] =
        useState<boolean>(false);

    const WINDOW_HEIGHT = window.innerHeight;
    const WINDOW_WIDTH = window.innerWidth;

    const {
        selectedConfiguration,
        setSelectedConfiguration,
        setLocalConfig,
        saveConfigDisabled,
        configKey,
        savedConfigurationsData,
        resetSelectedConfiguration,
        onDeleteConfig,
        onSaveConfig,
        onUpdateConfig,
        isAdmin,
        isEditorOpen,
        setIsEditorOpen,
        existingConfigNames,
        setGridConfig,
    } = useGridConfigSettings(SavedConfigFilterType.Attributes);

    useEffect(() => {
        setLocalConfig({ expanded });
    }, [expanded]);

    useEffect(() => {
        if (selectedConfiguration?.filters_json?.local_config) {
            const config = selectedConfiguration.filters_json.local_config;
            config['expanded'] !== undefined && setExpanded(config['expanded']);
            setLocalConfig(config);
            return;
        }
        setExpanded(false);
        setLocalConfig({ expanded });
    }, [selectedConfiguration, expanded, configKey]);

    if (error) {
        return <DisabledDashboard text={error} />;
    }

    return (
        <>
            <AttributesDrawer
                isDrawerOpen={isDrawerOpen}
                setIsDrawerOpen={setIsDrawerOpen}
                attributes={attributes}
                mutateAttributes={mutateAttributes}
                setDownloadModalIsOpen={setDownloadModalIsOpen}
                setUploadModalIsOpen={setUploadModalIsOpen}
            />
            <ActionsCard
                style={
                    isEditMode
                        ? {
                              height: WINDOW_HEIGHT - CARD_HEIGHT_OFFSET,
                              maxWidth: WINDOW_WIDTH - DASHBOARD_MARGIN * 2,
                              margin: DASHBOARD_MARGIN,
                              boxShadow: '#8b9a9f 0px 0px 10px 5px',
                          }
                        : {
                              height: WINDOW_HEIGHT - CARD_HEIGHT_OFFSET,
                              maxWidth: WINDOW_WIDTH - DASHBOARD_MARGIN * 2,
                              margin: DASHBOARD_MARGIN,
                          }
                }
                title={
                    isEditMode ? (
                        <>
                            <div className={cardTitle}>
                                <h2 style={{ marginRight: '10px' }}>
                                    Edit Attributes
                                </h2>
                                (Edit attribute values directly in the grid)
                            </div>
                        </>
                    ) : (
                        <>
                            <h2 className={cardTitle}>
                                <span
                                    style={{
                                        marginTop: '5px',
                                        marginLeft: '5px',
                                        marginRight: '10px',
                                    }}
                                >
                                    Attributes
                                </span>
                                <SavedGridConfigSelect
                                    savedConfigurationsData={
                                        savedConfigurationsData
                                    }
                                    selectedConfiguration={
                                        selectedConfiguration
                                    }
                                    setSelectedConfiguration={
                                        setSelectedConfiguration
                                    }
                                />
                                <SavedConfigManageDropdown
                                    onUpdateConfig={onUpdateConfig}
                                    onDeleteConfig={onDeleteConfig}
                                    resetSelectedConfiguration={
                                        resetSelectedConfiguration
                                    }
                                    hasUserAccess={
                                        !isAdmin &&
                                        selectedConfiguration?.reference_type !==
                                            FilterReferenceTypes.USER
                                    }
                                    allowDeleteOnly={saveConfigDisabled}
                                    defaultConfigSelected={
                                        !selectedConfiguration
                                    }
                                    setIsEditorOpen={setIsEditorOpen}
                                />
                                {isEditorOpen && (
                                    <SavedConfigEditor
                                        handleClose={() =>
                                            setIsEditorOpen(false)
                                        }
                                        onSave={onSaveConfig}
                                        isAdmin={isAdmin}
                                        existingConfigNames={
                                            existingConfigNames
                                        }
                                    />
                                )}
                                {isAdmin && (
                                    <>
                                        <AttributesDownloadModal
                                            key={`${downloadModalIsOpen}`}
                                            attributes={attributes}
                                            entityCodes={entityCodes}
                                            isModalOpen={downloadModalIsOpen}
                                            setIsModalOpen={
                                                setDownloadModalIsOpen
                                            }
                                        />
                                        <AttributesUploadModal
                                            key={`${uploadModalIsOpen}`}
                                            isModalOpen={uploadModalIsOpen}
                                            setIsModalOpen={
                                                setUploadModalIsOpen
                                            }
                                            mutateAttributes={mutateAttributes}
                                        />
                                    </>
                                )}
                            </h2>
                        </>
                    )
                }
                id="popup"
                extra={
                    isAdmin ? (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '20px',
                                marginRight: '10px',
                            }}
                        >
                            {isEditMode && (
                                <Button
                                    danger
                                    onClick={() => setTriggerCancelAction(true)}
                                >
                                    Cancel
                                </Button>
                            )}
                            {isEditMode ? (
                                <Button
                                    style={{
                                        width: 100,
                                    }}
                                    type="primary"
                                    icon={<SaveOutlined />}
                                    onClick={() => setTriggerSaveAction(true)}
                                >
                                    Save
                                </Button>
                            ) : (
                                <Button
                                    style={{ width: 100 }}
                                    icon={<EditOutlined />}
                                    onClick={() => setTriggerSaveAction(true)}
                                >
                                    Edit
                                </Button>
                            )}
                            <Button
                                disabled={isEditMode}
                                onClick={() => {
                                    setIsDrawerOpen(true);
                                }}
                            >
                                <SettingOutlined />
                            </Button>
                        </div>
                    ) : (
                        <></>
                    )
                }
            >
                {isLoading ? (
                    <Skeleton
                        paragraph={{
                            rows: Math.round(WINDOW_HEIGHT / 70) || 7,
                        }}
                    />
                ) : (
                    <AttributesDataGrid
                        isEditMode={isEditMode}
                        setIsEditMode={setIsEditMode}
                        selectedConfiguration={selectedConfiguration}
                        setGridConfig={setGridConfig}
                        configKey={configKey}
                        entities={entities}
                        entityCodes={entityCodes}
                        attributes={attributes}
                        mutateAttributes={mutateAttributes}
                        triggerSaveAction={triggerSaveAction}
                        setTriggerSaveAction={setTriggerSaveAction}
                        triggerCancelAction={triggerCancelAction}
                        setTriggerCancelAction={setTriggerCancelAction}
                    />
                )}
            </ActionsCard>
        </>
    );
};
