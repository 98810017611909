import { useGetReportStatus, useGetWorkflowRoles } from 'waypoint-hooks';
import {
    WORKFLOW_ASSIGNEE,
    WORKFLOW_REVIEWER,
    COMPARATIVE_INCOME_STATEMENT_URL,
    READY_FOR_REVIEW,
} from 'components/financials/comparative-income-statement/constants';
import {
    buildStatusNotificationTitle,
    reportTitle,
} from 'components/financials/comparative-income-statement/banner/ComparisonSelectionsBannerUtils';
import { updateWorkflowReportStatus } from 'waypoint-requests';
import { message } from 'antd';
import { MentionableDataSource, ReportMetadata } from 'waypoint-types';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { VARIANCE_REPORTING_URL } from 'components/financials/variance-report-status/constants';

interface useWorkflowReportProps {
    entityCodes: string[];
    reportMetadata: ReportMetadata | undefined;
    userId: number;
    propertyDisplayName: string | null;
    userMentionOptions: MentionableDataSource[];
}

export const useWorkflowReport = ({
    entityCodes,
    reportMetadata,
    userId,
    propertyDisplayName,
    userMentionOptions,
}: useWorkflowReportProps) => {
    const [selectedWorkflowRole, setSelectedWorkflowRole] = useState<
        string | null
    >(null);

    const [workflowModalIsOpen, setWorkflowModalIsOpen] =
        useState<boolean>(false);

    const { data: workflowRoles, mutate: mutateWorkflowRoles } =
        useGetWorkflowRoles(
            entityCodes,
            userMentionOptions.map((user) => {
                return {
                    id: Number(user.id),
                    name: user.text,
                    profile_image_url: user.profile_image_url ?? '',
                };
            })
        );

    const location = useLocation();

    const { data: workflowReportStatus, mutate: mutateWorkflowReportStatus } =
        useGetReportStatus(reportMetadata?.id, 'variance', entityCodes[0]);

    const handleWorkflowModal = (role: string | null) => {
        if (role) {
            setSelectedWorkflowRole(role);
        }
        setWorkflowModalIsOpen(!workflowModalIsOpen);
    };

    const getUserDisplayNameById = (id: number) => {
        return (
            userMentionOptions.find((option) => option.id === id)?.text ?? ''
        );
    };

    const updateReportStatus = async (newStatus: string) => {
        if (!workflowRoles || !workflowReportStatus || !reportMetadata) {
            return;
        }

        let userRoleIds: number[] = [];

        if (newStatus === READY_FOR_REVIEW) {
            userRoleIds = workflowRoles.reviewers.map((wr) => wr.userId);
        } else if (workflowReportStatus.report_status === READY_FOR_REVIEW) {
            userRoleIds = workflowRoles.assignees.map((wa) => wa.userId);
        }

        const notificationTitle = buildStatusNotificationTitle(
            newStatus,
            workflowReportStatus.report_status,
            reportMetadata.filter_raw_json,
            propertyDisplayName ?? ''
        );

        const reportNameTitle =
            reportTitle(reportMetadata.filter_raw_json) ?? '';

        await mutateWorkflowReportStatus(async () => {
            try {
                const url = `${location.pathname}${location.search}`;

                const params = {
                    id: workflowReportStatus.id,
                    reportStatus: newStatus,
                    reportPriorStatus: workflowReportStatus.report_status,
                    lastUpdatedBy: userId,
                    entityCode: entityCodes[0],
                    userRoleIds,
                    notificationTitle,
                    reportPage: COMPARATIVE_INCOME_STATEMENT_URL,
                    notificationRedirectUrl: url.includes(
                        VARIANCE_REPORTING_URL
                    )
                        ? url
                        : undefined,
                    propertyDisplayName: propertyDisplayName ?? '',
                    reportNameTitle,
                };

                const response = await updateWorkflowReportStatus(params);
                message.success('Report status updated!');

                return response;
            } catch (e) {
                message.error('An error occurred while updating report status');
            }
        });
    };

    const userWorkflowRole = () => {
        if (!userId || !workflowRoles) {
            return null;
        }

        const workflowReviewerUserIds = workflowRoles.reviewers.map(
            (wr) => wr.userId
        );

        if (workflowReviewerUserIds.includes(userId)) {
            return WORKFLOW_REVIEWER;
        }

        const workflowAssigneeUserIds = workflowRoles.assignees.map(
            (wa) => wa.userId
        );

        if (workflowAssigneeUserIds.includes(userId)) {
            return WORKFLOW_ASSIGNEE;
        }

        return null;
    };

    return {
        selectedWorkflowRole,
        setSelectedWorkflowRole,
        workflowModalIsOpen,
        setWorkflowModalIsOpen,
        handleWorkflowModal,
        workflowRoles,
        mutateWorkflowRoles,
        workflowReportStatus,
        mutateWorkflowReportStatus,
        getUserDisplayNameById,
        updateReportStatus,
        userWorkflowRole,
    };
};
