import PropTypes from 'prop-types';
import React from 'react';
import { Jumbotron } from 'react-bootstrap';

class ErrorPage extends React.Component {
    static propTypes = {
        children: PropTypes.element,
    };

    render() {
        return (
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '20px',
                }}
            >
                <Jumbotron
                    style={{
                        width: '400px',
                        height: '300px',
                        borderRadius: '5px',
                        padding: '20px',
                    }}
                >
                    {this.props.children || (
                        <div>
                            <h1>Error!</h1>
                        </div>
                    )}
                </Jumbotron>
            </div>
        );
    }
}

// NOTE: Error is a reserved JS keyword
export default ErrorPage;
