import React from 'react';
import { Descriptions } from 'antd';
import {
    formatMoney,
    formatNumber,
    formatNumberToDecimalPlace,
    formatPercentage,
} from 'utils/formatters';
import { getFormattedLabel, KPIMode } from '../PropertyProfileUtils';
import { DASH_DASH } from 'config/constants';
import { usePropertyInformation } from 'waypoint-hooks';
import { PropertyInformationSectionProps } from '../types';
import PropertyDetailsEditor from '../PropertyDetailsEditor';
import { hasDebtAndValuePropertyInfo } from 'state/user/selectors';

const labelStyle = {
    width: 200,
    paddingLeft: 20,
};

const PropertyCoreInformation = ({
    propertyInfo,
    entityCode,
    selectedDataLevel,
}: PropertyInformationSectionProps) => {
    const {
        streetAddress,
        city,
        state,
        postalCode,
        assetType,
        leasePropertyData,
        clientKpiPreferences,
    } = usePropertyInformation({
        entityCode,
        selectedDataLevel,
        propertyInfo,
    });

    const formatValueOrDash = (
        value: number | undefined,
        formatFn: (value: number) => string,
    ): string =>
        value === undefined || value === 0 ? DASH_DASH : formatFn(value);

    const kpiPreference =
        clientKpiPreferences === KPIMode.PSF
            ? 'Per Occupied SF'
            : 'Per Occupied Unit';

    const fullAddress = [streetAddress, city, state]
        .map((attr) => (attr.length ? attr[0].value : ''))
        .join(', ');

    const postalCodeValue = postalCode.length ? postalCode[0].value : '';

    const items = [
        {
            label: 'Address',
            value: `${fullAddress} ${postalCodeValue}`.trim() ?? DASH_DASH,
            span: 2,
        },
        {
            label: 'Asset Type',
            value: assetType.length ? assetType[0].value : DASH_DASH,
        },
        {
            label: 'Property Code',
            value: propertyInfo.display_code.length
                ? propertyInfo.display_code
                : DASH_DASH,
        },
        {
            label: 'Total Units',
            value: formatValueOrDash(
                leasePropertyData?.total_units,
                formatNumber,
            ),
        },
        {
            label: 'Occupied Units',
            value: formatValueOrDash(
                leasePropertyData?.occupied_units,
                formatNumber,
            ),
        },
        {
            label: 'Rentable SF',
            value: formatValueOrDash(
                leasePropertyData?.rentable_sq_ft,
                formatNumber,
            ),
        },
        {
            label: 'Occupied SF',
            value: formatValueOrDash(
                leasePropertyData?.occupied_sq_ft,
                formatNumber,
            ),
        },
        {
            label: 'Occupancy',
            value:
                leasePropertyData?.occupancy_rate !== undefined
                    ? formatPercentage(
                          leasePropertyData.occupancy_rate * 100,
                          1,
                      )
                    : DASH_DASH,
        },
        {
            label: 'WALE (yrs)',
            value: formatValueOrDash(leasePropertyData?.wale, (val) =>
                formatNumberToDecimalPlace(val, 1),
            ),
        },
        {
            label: 'Annual Base Rent',
            value: formatValueOrDash(
                leasePropertyData?.in_place_base_rent,
                formatMoney,
            ),
        },
        {
            label: kpiPreference,
            value: formatValueOrDash(
                clientKpiPreferences === KPIMode.PSF
                    ? leasePropertyData?.in_place_base_rent_occupied_sq_ft
                    : leasePropertyData?.in_place_base_rent_pounit,
                formatMoney,
            ),
        },
    ];

    return (
        <Descriptions
            size="small"
            bordered={false}
            column={{
                xxl: 2,
                xl: 2,
                lg: 2,
                md: 2,
                sm: 1,
                xs: 1,
            }}
            contentStyle={{
                textAlign: 'left',
                marginRight: 15,
            }}
            labelStyle={labelStyle}
        >
            {items.map((item, index) => (
                <Descriptions.Item
                    key={index}
                    label={getFormattedLabel(item.label)}
                    span={item.span}
                >
                    {item.value}
                </Descriptions.Item>
            ))}
        </Descriptions>
    );
};

export default PropertyCoreInformation;
