import React, { useState } from 'react';
import { get } from 'lodash';
import {
    menuContent,
    menuControls,
    menuSubControls,
} from 'components/analytics/ranking/rankingCss';
import {
    valueTypes,
    defaultValueType,
    accountGraphTypes,
    accountGraphValues,
} from 'components/analytics/ranking/constants';
import {
    displayTypeOptions,
    defaultDisplayValue,
} from 'components/analytics/financialOverview/menu/displayType/constants';
import { AccountSelect, Modal, Select } from 'waypoint-react';
import { getAccountGraph } from 'waypoint-requests';
import { decorateAccountGraphForAntDesign } from 'waypoint-utils';
import { message } from 'antd';
import { Divider, Button, Card as AntDesignCard } from 'antd';
import {
    hasBalanceSheetEnabled,
    hasBalanceSheetOverviewEnabled,
} from 'state/user/selectors';
import { connect, RootStateOrAny } from 'react-redux';
import {
    AccountGraphNode,
    AccountMappingSelection,
    RankingCardSelections,
} from 'waypoint-types';
import { AccountGraphObjectType } from 'waypoint-types/account-graph/types';
import RankingInfoTooltip from 'components/analytics/ranking/components/RankingInfoTooltip';
import { useSelectedDataLevel } from 'waypoint-hooks';

const RankingMenu = ({
    accountGraph,
    selections,
    handleApply,
    handleClose,
    setAccountGraph,
    onChangeAccountGraphSelection,
    hasBalanceSheet,
    hasBalanceSheetOverview,
}: {
    accountGraph: AccountGraphObjectType;
    selections: RankingCardSelections;
    handleApply: (values: RankingCardSelections) => void;
    handleClose: () => void;
    setAccountGraph: (accountGraph: AccountGraphObjectType) => void;
    onChangeAccountGraphSelection: (accountGraphSelection: string) => void;
    hasBalanceSheet: boolean;
    hasBalanceSheetOverview: boolean;
}): JSX.Element => {
    const [accountMappingSelection, setAccountMappingSelection] =
        useState<AccountMappingSelection>(selections.accountMapping);
    const [valueTypeSelection, setValueTypeSelection] = useState<string>(
        selections.valueType,
    );
    const [displayTypeSelection, setDisplayTypeSelection] = useState<string>(
        selections.displayType,
    );
    const [accountGraphCodeSelection, setAccountGraphCodeSelection] =
        useState<string>(selections.accountGraphCode);

    const [accountGraphRanking, setAccountGraphRanking] =
        useState<AccountGraphObjectType>(accountGraph);

    const [loading, setLoading] = useState(false);

    const selectedDataLevel = useSelectedDataLevel();

    const handleSubmit = () => {
        setAccountGraph(accountGraphRanking);
        onChangeAccountGraphSelection(accountGraphCodeSelection);
        handleApply({
            accountMapping: accountMappingSelection,
            valueType: valueTypeSelection,
            displayType: displayTypeSelection,
            accountGraphCode: accountGraphCodeSelection,
            period: selections.period,
        });
    };

    const handleChangeAccountGraphSelection = async (
        selectionValue: string,
    ): Promise<AccountGraphNode[]> => {
        setAccountGraphCodeSelection(selectionValue);
        return await fetchAccountGraph(selectionValue);
    };

    const resetOptions = async () => {
        const accountGraphData = await handleChangeAccountGraphSelection(
            accountGraphValues.incomeStatement,
        );
        setAccountGraph(accountGraphData);
        onChangeAccountGraphSelection(accountGraphValues.incomeStatement);
        handleApply({
            valueType: defaultValueType,
            displayType: defaultDisplayValue,
            accountGraphCode: accountGraphValues.incomeStatement,
            accountMapping: {
                code: accountGraphData[0].account_mapping_code,
                name: accountGraphData[0].name,
            },
            period: selections.period,
        });
    };

    const fetchAccountGraph = async (accountGraphValue: string) => {
        setLoading(true);

        try {
            const data = await getAccountGraph(
                accountGraphValue,
                selectedDataLevel,
            );
            const accountGraphData = decorateAccountGraphForAntDesign(
                data.children,
            );
            setAccountGraphRanking(accountGraphData);

            const accountMapping = {
                code: accountGraphData[0].account_mapping_code,
                name: accountGraphData[0].name,
            };
            setAccountMappingSelection(accountMapping);
            setLoading(false);
            return accountGraphData;
        } catch (e) {
            message.error(
                'Request Failed! An error occurred while requesting accounts',
            );
            setLoading(false);
        }
    };

    return (
        <Modal zIndex={5} className={''}>
            <AntDesignCard
                title="Card Settings"
                className={menuContent}
                data-testid="ranking-menu"
            >
                {hasBalanceSheet && hasBalanceSheetOverview && (
                    <div style={{ marginTop: 5 }}>
                        <h3>Account Type</h3>
                        <Select
                            options={accountGraphTypes}
                            value={accountGraphCodeSelection}
                            onChange={(item: string) =>
                                handleChangeAccountGraphSelection(item)
                            }
                        />
                    </div>
                )}
                <div style={{ marginTop: 5 }}>
                    <h3>Account</h3>
                    <AccountSelect
                        value={get(accountMappingSelection, 'code')}
                        accountGraph={accountGraphRanking}
                        onChange={(code: string, name: string) =>
                            setAccountMappingSelection({ code, name })
                        }
                        loading={loading}
                    />
                </div>
                <div style={{ marginTop: 5 }}>
                    <h3>Value Type</h3>
                    <Select
                        options={
                            accountGraphCodeSelection ===
                            accountGraphTypes[1].value
                                ? valueTypes.balance_sheet
                                : valueTypes.income_statement
                        }
                        value={valueTypeSelection}
                        onChange={(value: string) => {
                            setValueTypeSelection(value);
                        }}
                    />
                </div>
                <div>
                    <h3 style={{ display: 'inline-block', marginRight: '5px' }}>
                        Display Value
                    </h3>
                    <RankingInfoTooltip
                        title="Pie Charts are only visible when Gross $ is selected"
                        placement="right"
                    />
                    <Select
                        options={displayTypeOptions}
                        value={displayTypeSelection}
                        onChange={(value: string) =>
                            setDisplayTypeSelection(value)
                        }
                    />
                </div>
                <Divider />
                <div className={menuControls}>
                    <div>
                        <Button disabled={loading} onClick={resetOptions}>
                            Reset Default
                        </Button>
                    </div>
                    <div className={menuSubControls}>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button
                            type="primary"
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            Apply
                        </Button>
                    </div>
                </div>
            </AntDesignCard>
        </Modal>
    );
};

const mapState = (state: RootStateOrAny) => ({
    hasBalanceSheet: hasBalanceSheetEnabled(state),
    hasBalanceSheetOverview: hasBalanceSheetOverviewEnabled(state),
});

export default connect(mapState)(RankingMenu);
