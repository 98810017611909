import useSWR, { KeyedMutator } from 'swr';
import {
    getEntityProfileCards,
    getEntityProfileCardYearMetadata,
} from 'waypoint-requests';
import { EntityProfileCard } from 'waypoint-types';
import { EntityProfileCardYearMetadata } from 'waypoint-types/properties';

export const useEntityProfileCard = (
    entityCode: string,
    type: string
): {
    items: EntityProfileCard[] | undefined;
    isLoading: boolean;
    isError: boolean;
    mutateItems: KeyedMutator<EntityProfileCard[]>;
} => {
    const fetcher = () => getEntityProfileCards(entityCode, type);

    const { data, error, isValidating, mutate } = useSWR(
        `/api/properties/${entityCode}/profile-cards/${type}`,
        fetcher,
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
            dedupingInterval: 0,
            shouldRetryOnError: false,
        }
    );

    return {
        items: data,
        isLoading: !error && (!data || isValidating),
        isError: !!error,
        mutateItems: mutate,
    };
};

export const useEntityProfileYearMetadata = (
    entityCode: string,
    type: string
): {
    yearMetadata: EntityProfileCardYearMetadata[] | undefined;
    isLoading: boolean;
    isError: boolean;
    mutateYearMetadata: KeyedMutator<EntityProfileCardYearMetadata[]>;
} => {
    const { mutate, data, isValidating, error } = useSWR(
        `/api/properties/${entityCode}/profile-cards/${type}/year-metadata`,
        () => getEntityProfileCardYearMetadata(entityCode, type),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
            dedupingInterval: 0,
            shouldRetryOnError: false,
        }
    );
    return {
        yearMetadata: data,
        isLoading: !error && (!data || isValidating),
        isError: !!error,
        mutateYearMetadata: mutate,
    };
};
