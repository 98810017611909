import React, { useEffect, useState } from 'react';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { useGetDocumentUploadByReferenceParams } from 'waypoint-hooks';
import {
    EntityReport,
    ReportMetadata,
    EntityReportPublished,
    EntityReportApproval,
    MentionableDataSource,
    DocumentUpload,
} from 'waypoint-types';
import theme from 'config/theme';
import { Drawer } from 'antd';
import { useCommentsPane } from 'contexts/comments/CommentsContext';
import { formatDate, workflowGroupRenderer } from '../ReportUtils';
import { WORKFLOW_REVIEWER } from 'components/financials/comparative-income-statement/constants';
import { Dictionary } from 'ts-essentials';
import { CommentsPane } from 'components/comments/CommentsPane';
import { DisabledDashboard } from 'waypoint-react';
import {
    ReportWorkflowReviewStatus,
    WorkflowRoleReferenceType,
} from '../constants';
import { fullPageDrawerZIndex } from 'components/app/layout/AppLayoutUtils';

interface EntityReportDrawerViewProps {
    userMentionOptions: MentionableDataSource[] | undefined;
    reportName: string;
    entityReport: EntityReport;
    entityReportApprovals: Dictionary<EntityReportApproval>;
    selectedEntityReportPublished: EntityReportPublished | null;
    selectedEntityReportPublishedReportMetadata: ReportMetadata | null;
    reviewerViewDrawerIsOpen: boolean;
    setReviewerViewDrawerIsOpen: (value: boolean) => void;
    approvalSettingsType: string;
    setPublishedPDFUpload: (
        publishedEntityReportPDF: DocumentUpload | undefined,
        setPublishedPDF: (value: string | undefined) => void,
        setDocumentFetchError: (value: boolean) => void
    ) => void;
    commentIdQueryParam: string | null;
}

const EntityReportDrawerView = ({
    userMentionOptions,
    reportName,
    entityReport,
    entityReportApprovals,
    selectedEntityReportPublished,
    selectedEntityReportPublishedReportMetadata,
    reviewerViewDrawerIsOpen,
    setReviewerViewDrawerIsOpen,
    approvalSettingsType,
    setPublishedPDFUpload,
    commentIdQueryParam,
}: EntityReportDrawerViewProps) => {
    const [publishedPDF, setPublishedPDF] = useState<string | undefined>(
        undefined
    );

    // we don't need property or formatted due date, only included for type matching purposes
    const workflowGroupParams = {
        ...entityReport,
        property: '',
        report_status: ReportWorkflowReviewStatus.Rejected,
        report_name: reportName,
        formatted_due_date: '',
        approval_type: approvalSettingsType,
        has_approvals: true,
        reviews:
            entityReportApprovals[selectedEntityReportPublished?.id ?? '']
                ?.reviews ?? [],
    };

    const [documentFetchError, setDocumentFetchError] =
        useState<boolean>(false);

    const {
        update: updateCommentsPane,
        initializeComments,
        closeComments,
    } = useCommentsPane();

    useEffect(() => {
        if (!selectedEntityReportPublishedReportMetadata) {
            return;
        }
        initializeComments(
            selectedEntityReportPublishedReportMetadata.comment_thread_id
        );
    }, [selectedEntityReportPublishedReportMetadata]);

    const PDF_VIEWER_HEIGHT = `${window.innerHeight - 125}px`;

    useEffect(() => {
        updateCommentsPane({
            userMentionOptions,
            heading: reportName,
            subheading: `Submitted on ${formatDate(
                selectedEntityReportPublished?.timestamps?.created_at ?? ''
            )}`,
            scrollToCommentId: commentIdQueryParam,
        });
    }, [userMentionOptions]);

    const {
        data: publishedEntityReportPDF,
        isError: publishedEntityReportPDFError,
    } = useGetDocumentUploadByReferenceParams(
        WorkflowRoleReferenceType.EntityReportPublished,
        selectedEntityReportPublished?.id ?? ''
    );

    useEffect(() => {
        if (publishedEntityReportPDF?.id) {
            setPublishedPDFUpload(
                publishedEntityReportPDF,
                setPublishedPDF,
                setDocumentFetchError
            );
        }
    }, [publishedEntityReportPDF]);

    const drawerTitleSection = () => {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <div style={{ fontSize: '18px' }}>
                    {`Submitted on ${formatDate(
                        selectedEntityReportPublished?.timestamps?.created_at ??
                            ''
                    )}`}
                </div>
                <div style={{ width: '200px', marginRight: '25px' }}>
                    {workflowGroupRenderer(
                        workflowGroupParams,
                        userMentionOptions,
                        WORKFLOW_REVIEWER
                    )}
                </div>
            </div>
        );
    };

    if (!publishedPDF) {
        return <></>;
    }

    return (
        <Drawer
            title={drawerTitleSection()}
            zIndex={fullPageDrawerZIndex}
            open={reviewerViewDrawerIsOpen}
            onClose={() => {
                setReviewerViewDrawerIsOpen(false);
                closeComments();
            }}
            width="100VW"
            footer={null}
        >
            <div>
                <ReflexContainer orientation="horizontal">
                    <ReflexElement>
                        <ReflexContainer orientation="vertical">
                            <ReflexSplitter />
                            <ReflexElement>
                                <div>
                                    {documentFetchError ||
                                    publishedEntityReportPDFError ? (
                                        <DisabledDashboard
                                            text={
                                                'Could not load entity report PDF'
                                            }
                                        />
                                    ) : (
                                        <iframe
                                            src={publishedPDF}
                                            width="100%"
                                            height={PDF_VIEWER_HEIGHT}
                                        />
                                    )}
                                </div>
                            </ReflexElement>
                            <ReflexSplitter
                                style={{
                                    borderColor: theme.colors.blues.outline,
                                }}
                            />
                            <ReflexElement
                                minSize={400}
                                maxSize={1200}
                                size={400}
                                style={{
                                    height: PDF_VIEWER_HEIGHT,
                                    backgroundColor: theme.colors.white,
                                    border: '1px solid lightgrey',
                                }}
                            >
                                <CommentsPane showCloseIcon={false} />
                            </ReflexElement>
                        </ReflexContainer>
                    </ReflexElement>
                </ReflexContainer>
            </div>
        </Drawer>
    );
};

export default EntityReportDrawerView;
