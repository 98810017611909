// GRID
export const DEFAULT_GRID_WIDTH = 1200;
export const DEFAULT_ROW_HEIGHT = 105;
export const DEFAULT_COLUMN_COUNT = 12;
export const DEFAULT_MARGIN = 25;

// GRID ITEMS
// NOTE: Heights and widths are in grid units
export const DEFAULT_SMALL_ITEM_WIDTH = 2;
export const DEFAULT_SMALL_ITEM_HEIGHT = 1;
export const DEFAULT_LARGE_ITEM_WIDTH = 6;
export const DEFAULT_LARGE_ITEM_HEIGHT = 3;

const gridDefaults = {
    DEFAULT_GRID_WIDTH,
    DEFAULT_ROW_HEIGHT,
    DEFAULT_COLUMN_COUNT,
    DEFAULT_MARGIN,
    DEFAULT_SMALL_ITEM_WIDTH,
    DEFAULT_SMALL_ITEM_HEIGHT,
    DEFAULT_LARGE_ITEM_WIDTH,
    DEFAULT_LARGE_ITEM_HEIGHT,
};

export default gridDefaults;
