import { API_URL } from 'config/constants';
import { LeaseSummaryType, SelectedDataLevel } from 'waypoint-types';

/**
 * @param {array} entityCodes
 * @param selectedDataLevel
 * @returns lease summary for property or property group
 */
const getLeaseSummary = async (
    entityCodes: string[],
    selectedDataLevel: SelectedDataLevel
): Promise<LeaseSummaryType> => {
    const response = await fetch(`${API_URL}/leases/summary`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            entity_codes: entityCodes,
            selected_data_level: selectedDataLevel,
        }),
    });

    if (!response.ok) {
        throw new Error('FAILED');
    }
    const { data } = await response.json();
    return data;
};

export default getLeaseSummary;
