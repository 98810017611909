// @ts-nocheck
// This file is a result of nx update

import { get, set, merge, assign, pick } from 'lodash';
import { user as TYPES } from 'state/actionTypes';

/**
    TODO - Alex - Generally this reducer file is big and could be cut down
    * the 'user' key in the store is fine to use but it's turning into a catchall
    * good candidate for a refactor to make these reducer functions more specific (tighter)
*/

const defaultState = {
    isLoadingProperties: true,
    filtersActive: {
        area: 'RENTABLE',
        year: '2016',
        report: 'ACTUAL',
        period: 'CY',
        // NOTE: hard coded at this moment. Will be a per client basis.
        code: null,
    },
    filters: {
        area: ['RENTABLE', 'OCCUPIED'],
        year: ['2016', '2015'],
        report: ['ACTUAL', 'BUDGET'],
        period: ['CY', 'T12', 'YTD'],
        peerYear: ['2015'],
    },
    roles: [],
    userObj: {},
    clientObj: {
        // TODO (Nicholas): update this when the value is stored in the client obj
        units: 'rentable sq ft',
        unitsAbbrev: 'sq ft',
    },
    configuration: {
        DECIMAL_DISPLAY: 'Yes',
        NEGATIVE_VALUE_SYMBOLS: 'Currency',
        WAYPOINT_LEDGER_DROPDOWNS: {
            defaults: {
                code: null,
            },
        },
    },
};

function setNotification(state, payload) {
    state = assign({}, state);
    state.userObj.config_json[payload.type] = payload.value;
    return state;
}

// NOTE: Use this in selectors for checking for client loading correctly
export function clientLoaded(clientObj) {
    // Because of defaults above, can't rely on client object being empty
    return !!clientObj.id;
}

export default function reducer(state = defaultState, action) {
    /* eslint-disable no-case-declarations */
    switch (action.type) {
        // TODO - Alex - GET_USER_OBJ is good candidate for break up
        //  * some of its contents is needed elsewhere anyway
        case TYPES.GET_USER_OBJ:
            const userObj = Object.values(action.payload)[0];
            const { config_json: userConfig } = userObj;
            // defaults still come from the clientDetails obj
            // TODO (Alex): organise nomenclature of filters
            // const {
            //     DEFAULTS: defaultFilters,
            // } = userConfig.WAYPOINT_LEDGER_DROPDOWNS
            // const filtersActive = cloneDeep(defaultFilters)

            const cleanUserObj = pick(userObj, [
                'id',
                'name',
                'client_id',
                'roles',
            ]);

            return assign(
                {},
                state,
                cleanUserObj,
                // Here we unpack the most useful parts of the payload
                {
                    image: get(
                        userObj,
                        'image_json.USERIMAGE.medium-sq.url',
                        undefined,
                    ),
                    // defaultFilters,
                    // filtersActive,
                    userObj,
                    configuration: {
                        ...state.configuration,
                        ...userConfig,
                    },
                },
            );

        case TYPES.GET_CLIENT_OBJ:
            const { display_name, config_json: configuration } = action.payload;
            // NOTE: We should build a multi-filter abstraction
            // Pull out default global ledger filters / selectors
            // FIXME (Nicholas): Give the client object its own state slice, refactor out of here
            const clientObj = assign({}, action.payload, {
                // Overwrite the default 'sq ft' units for multifamily
                units:
                    configuration?.DEFAULT_EXPENSE_UNIT_DENOMINATOR || 'unit',
            });

            // Delete the app loading screen when the page loads first time
            if (clientLoaded(clientObj)) {
                const loadingScreen =
                    document.querySelector('.loader-container');
                if (loadingScreen) {
                    loadingScreen.remove();
                    window.loading = false;
                }
            }
            return merge({}, state, {
                clientObj,
                display_name,
                // filters,
                // NOTE (Daniel): Merge current state config with config extracted from client
                // This allows for overrides set by individual users
                configuration: {
                    ...configuration,
                    ...state.configuration,
                    DECIMAL_DISPLAY: configuration.DECIMAL_DISPLAY,
                    NEGATIVE_VALUE_SYMBOLS:
                        configuration.NEGATIVE_VALUE_SYMBOLS,
                },
            });

        case TYPES.GET_HEARTBEAT:
            return assign({}, state, { heartbeat: action.payload });

        // TODO (Nicholas): Create a first class filters reducer
        case TYPES.RESET_FILTERS:
            return assign({}, state, { filtersActive: state.defaultFilters });

        case TYPES.SET_USER_CONFIG:
            return assign({}, state, {
                config_json: assign(
                    {},
                    state.userObj.config_json,
                    action.payload,
                ),
            });

        case TYPES.SET_ACTIVE_ACCOUNT_GROUPS:
            return assign(
                {},
                state,
                set(state, 'filtersActive.accountTypeFilters', action.payload),
            );

        case TYPES.SET_FILTER:
            return assign({}, state, {
                filtersActive: assign({}, state.filtersActive, action.payload),
            });

        case TYPES.SET_ACTIVE_FILTER_CODE:
            return assign({}, state, {
                filtersActive: assign({}, state.filtersActive, action.payload),
            });
        case TYPES.SET_ACTIVE_ACCOUNT_TAB:
            return assign({}, state, {
                filtersActive: assign({}, state.filtersActive, action.payload),
            });

        case TYPES.SET_ACCOUNT_TYPE_LEVEL:
            merge(state.filtersActive.accountTypeFilters, action.payload);
            return assign({}, state, state.filtersActive);

        case TYPES.SET_IMAGE:
            return assign({}, state, { image: action.payload.url });

        case TYPES.SET_NOTIFICATION:
            return setNotification(state, action.payload);

        case TYPES.SET_DECIMAL_DISPLAY:
            return {
                ...state,
                configuration: {
                    ...state.configuration,
                    DECIMAL_DISPLAY: action.payload.value,
                },
            };

        case TYPES.SET_NEGATIVE_VALUE:
            return {
                ...state,
                configuration: {
                    ...state.configuration,
                    NEGATIVE_VALUE_SYMBOLS: action.payload.value,
                },
            };

        case TYPES.TOGGLE_LOADING_PROPERTIES:
            return assign({}, state, { isLoadingProperties: action.payload });

        default:
            return state;
    }
    /* eslint-enable no-case-declarations */
}
