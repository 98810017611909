import { API_URL } from 'config/constants';
import { AttributeValue } from 'waypoint-types';

export interface UpdateEntityAttributeValuesParams {
    attribute_codes: string[];
    attribute_values: string[];
    deleted_attribute_value_ids?: string[];
    entity_code: string;
    is_standard_attribute: boolean;
}

const createOrUpdateEntityAttributeValues = async (
    body: UpdateEntityAttributeValuesParams[]
): Promise<AttributeValue[]> => {
    const response = await fetch(`${API_URL}/attributes/update-entity-values`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(body),
    });

    if (!response.ok) {
        throw new Error('Failed to update entity attribute values');
    }

    const { data } = await response.json();

    return data as AttributeValue[];
};

export default createOrUpdateEntityAttributeValues;
