import PropTypes from 'prop-types';
import React from 'react';
import { pick, isEmpty } from 'lodash';
import { DASH_DASH } from 'config/constants';
import { ChartTableSwitchCard, DownloadButton } from 'waypoint-react';
import { waleByIndustryCardTitle } from 'components/leases/tenants/constants';
import { exportCSV, getWaleTableData } from 'components/leases/tenants/utils';
import WALEByIndustryTable from 'components/leases/tenants/cards/WALEByIndustry/Table';
import WALEByIndustryChart from 'components/leases/tenants/cards/WALEByIndustry/Chart';

const WALEByIndustryCard = ({ statistics, isLoading, ...props }) => {
    const gridProps = pick(props, ['data-grid', 'style']);

    let tableData = [];
    let csvData = null;
    if (statistics) {
        tableData = getWaleTableData(statistics);
        csvData = tableData.map((item) => ({
            Industry: item.industry,
            Sum: item.totalWALE ? item.totalWALE.toFixed(1) : DASH_DASH,
        }));
    }

    return (
        <ChartTableSwitchCard
            {...gridProps}
            subheading={<span />}
            extra={
                <DownloadButton
                    disabled={isLoading || isEmpty(statistics)}
                    onClick={() => exportCSV(csvData, 'Industries by WALE')}
                />
            }
            title={waleByIndustryCardTitle}
            empty={isEmpty(statistics)}
            loading={isLoading}
            table={<WALEByIndustryTable data={tableData} />}
            chart={<WALEByIndustryChart data={statistics} />}
        />
    );
};

WALEByIndustryCard.propTypes = {
    statistics: PropTypes.array,
    isLoading: PropTypes.bool,
};

export default WALEByIndustryCard;
