import { isEmpty } from 'lodash';

const addIsRootNodeKeyToNode = (node) => {
    return (node.isRootNode = !node.parent_account_mapping_code);
};
const addValueKeyToNode = (node) => {
    return (node.value = node.account_mapping_code);
};
const addKeyFieldToNode = (node) => (node.key = node.account_mapping_code);
const addTitleFieldToNode = (node) => (node.title = node.name);
/**
 * Documentation
 * This outputs an array. It decorates each item in the array, and children of each item, with fields required to integrate with Ant Design components. Use module *specific decorators if in need of of more decoration.
 * @param {array} data deeply nested array representing an account graph
 */
const decorateAccountGraphForAntDesign = (data) => {
    return data.map((node) => {
        if (node.children?.length > 0) {
            decorateAccountGraphForAntDesign(node.children);
            addIsRootNodeKeyToNode(node);
            addValueKeyToNode(node);
            addTitleFieldToNode(node);
        }
        if (isEmpty(node.children)) {
            // This ensures Ant Design doesn't render expand/collapse icon for a node without children. If node.children is an empty array, it does render an icon
            node.children = null;
            addValueKeyToNode(node);
            addTitleFieldToNode(node);
        }
        // NOTE: key MUST be added for Ant Design Tree Table to properly render
        addKeyFieldToNode(node);
        return node;
    });
};

export default decorateAccountGraphForAntDesign;
