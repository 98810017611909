import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Grid, Row } from 'react-bootstrap';
import SubHeader from './SubHeader';
import sharedStyles from './shared.module.css';
import styles from './Settings.module.css';
import { roles as userRoles } from 'config/constants';
import { useHistory } from 'react-router-dom';

const AdminSettings = ({ children, isAdmin }) => {
    const history = useHistory();
    // NOTE (Daniel): this redirects the user to the homepage if by any chance
    // they happen to type /settings/admin/ on the address bar
    if (!isAdmin) {
        history.replace('/');
        return null;
    }

    return (
        <Grid className={styles.grid}>
            <Row className={sharedStyles.row}>
                <SubHeader title="Admin Settings" admin={true} />
            </Row>
            {children}
        </Grid>
    );
};

AdminSettings.propTypes = {
    children: PropTypes.node,
    isAdmin: PropTypes.bool,
    isWaypointAssociate: PropTypes.bool,
    isWaypointSysadmin: PropTypes.bool,
    history: PropTypes.object,
};

const mapState = (state) => {
    const { roles } = state.user;
    const isAdmin = roles.includes(userRoles.CLIENT_ADMIN);

    return {
        isAdmin,
    };
};

export default connect(mapState)(AdminSettings);
