import { format } from 'date-fns';
import { DataGrid } from 'devextreme-react';
import {
    Column,
    DataGridRef,
    Export,
    Item,
    Paging,
    Scrolling,
    Toolbar,
} from 'devextreme-react/data-grid';
import { ExportingEvent } from 'devextreme/ui/data_grid';
import { OccupancyHistoryProps, OccupancyTrendGridData } from 'waypoint-types';
import { exportExcelFromDevExtremeDataGrid } from 'waypoint-utils';
import { formatNumberToDecimalPlace, formatPercentage } from 'utils/formatters';
import moment, { Moment } from 'moment';
import { CustomizeCell } from 'waypoint-utils/dev-extreme/exportExcelFromDevExtremeDataGrid';
import { OccupancyTrendExportableGrid } from 'components/reports/components/entity-report-widgets/report-widget-export-grids/OccupancyTrendExportableGrid';

interface OccupancyTrendGridProps {
    occupancyHistory: OccupancyHistoryProps[];
    periodRange: [Moment, Moment] | null;
    isPDFExport?: boolean;
}

export const OccupancyTrendGrid = ({
    occupancyHistory,
    periodRange,
    isPDFExport,
}: OccupancyTrendGridProps): JSX.Element | null => {
    const startDate = moment(periodRange?.[0], 'YYYY-MM-DD');
    const endDate = moment(periodRange?.[1], 'YYYY-MM-DD');

    const filteredData = occupancyHistory.filter((d) => {
        const periodEnd = moment(d.period_end, 'YYYY-MM-DD');
        return periodEnd >= startDate && periodEnd <= endDate;
    });

    const months = filteredData.map((d) =>
        format(new Date(d.period_end), 'MMM yyyy'),
    );

    const dataSource = filteredData.reduce(
        (acc: OccupancyTrendGridData, r) => {
            const monthColumn = format(new Date(r.period_end), 'MMM yyyy');
            acc.occupancy = {
                ...acc.occupancy,
                [monthColumn]: formatPercentage((r.occupancy ?? 0) * 100),
                total:
                    Number(acc.occupancy.total) +
                    Number(r.occupancy) / months.length,
            };
            acc.occupied_area = {
                ...acc.occupied_area,
                [monthColumn]: formatNumberToDecimalPlace(r.occupied_area, 0),
                total:
                    Number(acc.occupied_area.total) +
                    Number(r.occupied_area) / months.length,
            };
            acc.total_area = {
                ...acc.total_area,
                [monthColumn]: formatNumberToDecimalPlace(r.total_area, 0),
                total:
                    Number(acc.total_area.total) +
                    Number(r.total_area) / months.length,
            };
            return acc;
        },
        {
            occupied_area: { name: 'Occupied SF', total: 0 },
            total_area: { name: 'Total SF', total: 0 },
            occupancy: { name: 'Occupancy %', total: 0 },
        },
    );

    const formatTotals = (dataSource: OccupancyTrendGridData) => {
        dataSource.occupancy.total = formatPercentage(
            Number(dataSource.occupancy.total) * 100,
        );
        dataSource.occupied_area.total = formatNumberToDecimalPlace(
            dataSource.occupied_area.total,
            0,
        );
        dataSource.total_area.total = formatNumberToDecimalPlace(
            dataSource.total_area.total,
            0,
        );
        return Object.values(dataSource);
    };

    const onExporting = async (e: ExportingEvent) => {
        const customizeCell: CustomizeCell = ({ gridCell, excelCell }) => {
            if (gridCell?.rowType === 'data') {
                if (
                    gridCell?.data.name === 'Occupancy %' &&
                    gridCell?.value !== 'Occupancy %'
                ) {
                    excelCell.value = formatPercentage(gridCell?.value * 100);
                }
                if (gridCell?.column?.dataField === 'total') {
                    if (gridCell?.data.name === 'Occupancy %') {
                        excelCell.value = formatPercentage(
                            (gridCell?.value * 100) / months.length,
                        );
                    } else {
                        excelCell.value = formatNumberToDecimalPlace(
                            gridCell?.value / months.length,
                            0,
                        );
                    }
                }
            }
        };
        await exportExcelFromDevExtremeDataGrid(
            e,
            {
                worksheetName: 'Occupancy History',
                filename: 'Occupancy_History.xlsx',
            },
            customizeCell,
        );
    };

    if (isPDFExport) {
        return (
            <OccupancyTrendExportableGrid
                data={formatTotals(dataSource)}
                months={months}
            />
        );
    }

    return (
        <DataGrid
            wordWrapEnabled
            dataSource={formatTotals(dataSource)}
            allowColumnReordering={false}
            columnAutoWidth={true}
            columnMinWidth={100}
            showBorders={true}
            showRowLines={true}
            hoverStateEnabled={true}
            showColumnLines={true}
            rowAlternationEnabled={false}
            id="occupancy-history-table"
            onExporting={onExporting}
        >
            <Toolbar>
                <Item name="exportButton" />
            </Toolbar>
            <Export enabled={true} allowExportSelectedData={false} />
            <Column
                dataField="name"
                caption="Metric"
                alignment={'left'}
                allowSorting={false}
                fixed={true}
            />
            {months.map((m) => {
                return (
                    <Column
                        dataField={m}
                        caption={m}
                        dataType="string"
                        allowSorting={false}
                        alignment="center"
                    />
                );
            })}
            <Column
                dataField="total"
                caption="Average"
                alignment="center"
                dataType="string"
                fixed={true}
                fixedPosition="right"
            />
            <Scrolling />
            <Paging enabled={false} />
        </DataGrid>
    );
};
