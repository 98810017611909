import { assign } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// 3 States: Inactive, Ascending, Descending
export default class CaretControl extends React.Component {
    render() {
        const { active, disabled, sortDirection, onClick, style } = this.props;
        return (
            <span
                style={assign(
                    {},
                    {
                        marginLeft: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                    },
                    style
                )}
            >
                <i
                    style={{
                        color:
                            active && sortDirection === 'ASC'
                                ? '#585858'
                                : '#CCC',
                        cursor: !disabled ? 'pointer' : 'not-allowed',
                        width: '20px',
                        height: '13px',
                        paddingTop: '2px',
                        paddingLeft: '2px',
                        lineHeight: '8px',
                        fontSize: '23px',
                        overflowY: 'hidden',
                    }}
                    className="fa-solid fa-caret-up"
                    size="lg"
                    onClick={disabled ? null : () => onClick('ASC')}
                />
                <i
                    style={{
                        color:
                            active && sortDirection === 'DESC'
                                ? '#585858'
                                : '#CCC',
                        cursor: !disabled ? 'pointer' : 'not-allowed',
                        width: '20px',
                        height: '13px',
                        paddingLeft: '2px',
                        lineHeight: '8px',
                        fontSize: '23px',
                        overflowY: 'hidden',
                    }}
                    onClick={disabled ? null : () => onClick('DESC')}
                    className="fa-solid fa-caret-down"
                    size="lg"
                />
            </span>
        );
    }
}

CaretControl.propTypes = {
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    onClick: PropTypes.func,
    sortDirection: PropTypes.string,
};
