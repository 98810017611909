import React from 'react';
import { Modal } from 'antd';
import { css } from 'emotion';
import PlanningCapitalProjectsCategorySelect from './PlanningCapitalProjectCategorySelect';
import { CapitalProject } from 'waypoint-types';

const flexBoxContainer = css`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
`;

interface PlanningCapitalProjectTypesSettingsProps {
    isVisible: boolean;
    tableData: CapitalProject[];
    onClose: () => void;
    onRefreshData: () => void;
}

const PlanningCapitalProjectTypesSettings = ({
    tableData,
    isVisible,
    onClose,
    onRefreshData,
}: PlanningCapitalProjectTypesSettingsProps) => {
    return (
        <Modal
            closable
            centered
            footer={null}
            open={isVisible}
            title={'Capital Project Settings'}
            onCancel={onClose}
            afterClose={onRefreshData}
        >
            <div className={flexBoxContainer}>
                <p>
                    Here you can define your own categories, status,
                    subcategories and priorities for your projects
                </p>
                <div style={{ marginTop: 12 }}>
                    <PlanningCapitalProjectsCategorySelect
                        isAllowedToEdit
                        type="category"
                        rowData={null}
                        tableData={tableData}
                        onRefreshData={onRefreshData}
                    />
                </div>
                <div style={{ marginTop: 12 }}>
                    <PlanningCapitalProjectsCategorySelect
                        isAllowedToEdit
                        type="subcategory"
                        rowData={null}
                        tableData={tableData}
                        onRefreshData={onRefreshData}
                    />
                </div>

                <div style={{ marginTop: 12 }}>
                    <PlanningCapitalProjectsCategorySelect
                        isAllowedToEdit
                        type="status"
                        rowData={null}
                        tableData={tableData}
                        onRefreshData={onRefreshData}
                    />
                </div>

                <div style={{ marginTop: 12 }}>
                    <PlanningCapitalProjectsCategorySelect
                        isAllowedToEdit
                        type="priority"
                        rowData={null}
                        tableData={tableData}
                        onRefreshData={onRefreshData}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default PlanningCapitalProjectTypesSettings;
