import React, { useState } from 'react';
import { Select } from 'antd';
import { AttributeDefinition } from 'waypoint-types';

export interface AttributesEditableCellTemplateProps {
    value: string | string[];
    setValue: (value: string | string[]) => void;
}

interface AttributesEditableCellProps {
    template: AttributesEditableCellTemplateProps;
    attribute?: AttributeDefinition;
}

export const AttributesEditableSelect = ({
    template,
    attribute,
}: AttributesEditableCellProps) => {
    const [values, setValues] = useState<string[]>(
        Array.isArray(template.value) ? template.value : [template.value],
    );

    const existingAttributeValues =
        attribute?.attributeValues?.map((attr) => attr.value) ?? [];

    const options = Array.from(
        new Set(
            attribute?.allowed_values
                ? attribute.allowed_values
                : [...values, ...existingAttributeValues],
        ),
    ).map((option) => ({
        label: option,
        value: option,
        key: option,
    }));

    function onChange(newValues: string[]) {
        setValues(newValues);
        template.setValue(newValues);
    }

    return (
        <Select
            mode={
                attribute?.is_multiselect
                    ? attribute.allowed_values
                        ? 'multiple'
                        : 'tags'
                    : undefined
            }
            allowClear={true}
            value={values}
            style={{ width: '100%' }}
            options={options}
            onChange={onChange}
        />
    );
};
