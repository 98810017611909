import React from 'react';

interface MyReportHeaderProps {
    title: string;
    count: number;
    percentage: number;
}

const MyReportHeader = ({ title, count, percentage }: MyReportHeaderProps) => {
    return (
        <div>
            <span>{title}</span>
            <p>
                {count} Report(s) | {percentage}% Complete
            </p>
        </div>
    );
};

export default MyReportHeader;
