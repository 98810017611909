// @ts-nocheck
// This file is a result of nx update

import { merge, cloneDeep } from 'lodash';
import { leases as LEASES } from 'state/actionTypes';

const defaultState = {
    propertyLeaseStatistics: {},
    groupLeaseStatistics: {},
    // TODO: add leases here
    leases: {},
    // TODO: move leaseSummaries here
};

const loadPropertyLeaseStatistics = (state, payload) => {
    return cloneDeep(merge(state, { propertyLeaseStatistics: payload }));
};

const loadGroupLeaseStatistics = (state, payload) => {
    return cloneDeep(merge(state, { groupLeaseStatistics: payload }));
};

const loadLeases = (state, payload) => {
    return cloneDeep(merge(state, { leases: payload }));
};

export default function reducer(leases = defaultState, action) {
    const { type, payload } = action;
    switch (type) {
        case LEASES.LOAD_LEASES:
            return loadLeases(leases, payload);
        case LEASES.LOAD_PROPERTY_LEASE_STATISTICS:
            return loadPropertyLeaseStatistics(leases, payload);
        case LEASES.LOAD_GROUP_LEASE_STATISTICS:
            return loadGroupLeaseStatistics(leases, payload);
        default:
            return leases;
    }
}
