import { API_URL } from 'config/constants';
import { BudgetUpload } from 'waypoint-types';

export const uploadBudgetFile = async (
    budgetUpload: FormData,
    entityCode: string,
    sourcePropertyCode: string,
    year: string
): Promise<BudgetUpload> => {
    const url = `${API_URL}/budget-upload/entity-budget/${entityCode}/${sourcePropertyCode}/${year}`;

    const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        body: budgetUpload,
    });

    const res = await response.json();

    if (!response.ok) {
        throw new Error(res.message);
    }

    const { data } = res;
    return data;
};
