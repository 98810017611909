import useSWR from 'swr';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { getReportById } from 'waypoint-requests';
import { Report } from 'waypoint-types';

export const useGetReportById = (
    reportId: string,
    includeEntityReportWidgets = true
): DataHookResponse<Report> => {
    const reportCacheKey =
        reportId && reportId !== 'create'
            ? `/api/reports/${reportId}/${includeEntityReportWidgets}`
            : null;
    const { data, error, mutate, isValidating } = useSWR(
        reportCacheKey,
        () => getReportById({ reportId, includeEntityReportWidgets }),
        { revalidateOnFocus: false }
    );

    return {
        data,
        mutate,
        isError: !!error,
        isLoading: !data || isValidating,
        isValidating: isValidating,
    };
};
