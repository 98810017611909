import {
    currencyRenderer,
    integerRenderer,
    percentageRenderer,
} from 'utils/tables/renderers';
import {
    ColumnDescriptor,
    ExportableGridSummaryFormatter,
    convertColumnsToAntd,
    getColumnTotalCells,
    headerClass,
    rowClass,
} from './GridExportConversionUtils';
import { Table } from 'antd';
import { Dictionary } from 'ts-essentials';
import { TransformedUnitMix } from 'waypoint-types';

const bedroomCountToStudio = (value: number) => {
    if (value === 0) {
        return 'Studio';
    }

    return value;
};

export const unitMixBaseColumns: ColumnDescriptor[] = [
    {
        title: 'Beds',
        dataIndex: 'bedroom_count',
        key: 'bedroom_count',
        dataType: 'number',
        align: 'center',
        render: bedroomCountToStudio,
    },

    {
        title: '# of Units',
        dataIndex: 'number_of_units',
        key: 'number_of_units',
        dataType: 'number',
        align: 'center',
    },
    {
        title: 'Avg SF',
        dataIndex: 'avg_sf',
        key: 'avg_sf',
        dataType: 'number',
        align: 'center',
        render: integerRenderer,
    },
    {
        title: 'Total SF',
        dataIndex: 'total_sf',
        key: 'total_sf',
        dataType: 'number',
        align: 'center',
        render: integerRenderer,
    },
    {
        title: '% of Total Units',
        dataIndex: 'percent_of_total_units',
        key: 'percent_of_total_units',
        dataType: 'number',
        align: 'center',
        render: percentageRenderer,
    },
    {
        title: 'Avg Charges',
        dataIndex: 'average_total_monthly',
        key: 'average_total_monthly',
        dataType: 'number',
        align: 'center',
        render: currencyRenderer,
    },
    {
        title: 'Avg Charges PSF',
        dataIndex: 'average_total_monthly_psf',
        key: 'average_total_monthly_psf',
        dataType: 'number',
        align: 'center',
        render: currencyRenderer,
    },
    {
        title: 'Total Charges',
        dataIndex: 'total_monthly',
        key: 'total_monthly',
        dataType: 'number',
        align: 'center',
        render: currencyRenderer,
    },
    {
        title: '% of Total Charges',
        dataIndex: 'percent_of_avg_monthly_charges',
        key: 'percent_of_avg_monthly_charges',
        dataType: 'number',
        align: 'center',
        render: percentageRenderer,
    },
];

const unitMixSummaryFormatters: Dictionary<ExportableGridSummaryFormatter> = {
    bedroom_count: {
        summaryType: 'sum',
        render: () => 'Total/Avg',
    },
    number_of_units: {
        summaryType: 'sum',
        render: (value: number) => integerRenderer(value, 0),
    },
    avg_sf: {
        summaryType: 'custom',
        render: (value: number) => integerRenderer(value, 0),
    },
    total_sf: {
        summaryType: 'sum',
        render: (value: number) => integerRenderer(value, 0),
    },
    percent_of_total_units: {
        summaryType: 'sum',
        render: percentageRenderer,
    },
    average_total_monthly: {
        summaryType: 'custom',
        render: currencyRenderer,
    },
    average_total_monthly_psf: {
        summaryType: 'custom',
        render: currencyRenderer,
    },
    total_monthly: {
        summaryType: 'sum',
        render: currencyRenderer,
    },
    percent_of_avg_monthly_charges: {
        summaryType: 'sum',
        render: percentageRenderer,
    },
};

const calculateCustomSummary = (column: string, data: TransformedUnitMix[]) => {
    const weightedAverageMetrics = [
        'avg_sf',
        'average_total_monthly',
        'average_total_monthly_psf',
    ];

    if (weightedAverageMetrics.includes(column)) {
        const totalUnits = data.reduce(
            (total, item) => total + item.number_of_units,
            0
        );
        const totalMetric = data.reduce((total, item) => {
            const metricValue = item[column as keyof TransformedUnitMix];
            return typeof metricValue === 'number'
                ? total + metricValue * item.number_of_units
                : total;
        }, 0);
        return totalUnits ? totalMetric / totalUnits : 0;
    }

    if (column === 'total_sf') {
        return data.reduce((total, item) => total + item.total_sf, 0);
    }

    return 0;
};
interface UnitMixExportableGridProps {
    unitMix: TransformedUnitMix[];
}

export const UnitMixExportableGrid = ({
    unitMix,
}: UnitMixExportableGridProps): JSX.Element => {
    const sortedByStudioBedroomCountFirst = unitMix.sort(
        (a: TransformedUnitMix, b: TransformedUnitMix) => {
            if (a.bedroom_count === 'Studio') {
                return -1;
            }

            if (b.bedroom_count === 'Studio') {
                return 1;
            }

            return (a?.bedroom_count ?? 0) - (b?.bedroom_count ?? 0);
        }
    );

    const { gridColumns } = convertColumnsToAntd(
        unitMixBaseColumns,
        true,
        null
    );

    return (
        <Table
            dataSource={sortedByStudioBedroomCountFirst}
            size="small"
            columns={gridColumns}
            pagination={false}
            bordered={true}
            className={headerClass}
            rowClassName={rowClass}
            summary={() =>
                getColumnTotalCells(
                    gridColumns,
                    unitMix,
                    unitMixSummaryFormatters,
                    calculateCustomSummary
                )
            }
        />
    );
};
