import { API_URL } from 'config/constants';
import { LeasingPlanActivity } from 'waypoint-types/leasing-plans';

const createLeasingPlanActivity = async (
    body: LeasingPlanActivity
): Promise<LeasingPlanActivity> => {
    const response = await fetch(`${API_URL}/leasing-plans/activity`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(body),
    });

    if (!response.ok) {
        throw new Error('Failed to create leasing plan activity');
    }

    const { data } = await response.json();

    return data as LeasingPlanActivity;
};

export default createLeasingPlanActivity;
