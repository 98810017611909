import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { get } from 'lodash';
import { css } from 'emotion';
import moment from 'moment';
import {
    getDataGridPropFor,
    getDateRangeForPeriod,
    searchAccountGraphForAccountName,
} from 'waypoint-utils';
import { PERIODICITY_MONTH_VALUE } from 'components/analytics/constants';
import Grid from 'components/grids/Grid';
import { getComponentAndRequestFor } from 'components/analytics/balance-sheet-overview/utils';
import { RangeSelectConfirm } from 'waypoint-react';
import { BalanceSheetOverviewGridItem } from 'components/analytics/balance-sheet-overview/grid';
import { buildDashboardConfigFromSlimJson } from 'components/analytics/utils';

const dateRangeContainer = css`
    position: fixed;
    top: 9px;
    right: 300px;
    z-index: 2;
    > div {
        padding: 0;
    }
`;

const BSO_RANGE_SELECT_ID = 'balance-sheet-overview-range-select';
class BalanceSheetGrid extends Component {
    static propTypes = {
        accountGraph: PropTypes.arrayOf(PropTypes.object),
        asOfDate: PropTypes.string,
        dashboardConfiguration: PropTypes.shape({
            items: PropTypes.arrayOf(PropTypes.object),
        }),
        entityCodes: PropTypes.arrayOf(PropTypes.string),
        style: PropTypes.object,
        selectedDataLevel: PropTypes.shape({
            stakeholder: PropTypes.string,
            percentageType: PropTypes.string,
        }),
    };

    state = {
        isRangeSelectOpen: false,
        globalPeriod: null,
    };

    getDefaultSelectionsFor = (item) => {
        const { accountGraph } = this.props;

        return {
            accountMapping: {
                name:
                    searchAccountGraphForAccountName(
                        accountGraph,
                        item.account_mapping_code,
                    ) || item.account_mapping_code,
                code: item.account_mapping_code,
            },
            isHideNull: true,
            displayType: 'gross',
            periodicity: PERIODICITY_MONTH_VALUE,
        };
    };

    getDefaultGlobalPeriodForEntity = () => {
        const { asOfDate } = this.props;
        const [start, end] = getDateRangeForPeriod('trailing_12', asOfDate);

        return [moment(start), moment(end)];
    };

    renderTitleComponent = (item) => {
        const dataGridProp = getDataGridPropFor(item);
        const [componentToRender] = getComponentAndRequestFor(item);

        return React.createElement(componentToRender, {
            'data-grid': dataGridProp,
            key: JSON.stringify(dataGridProp),
            title: item.title,
            style: this.props.style, // comes from RGL grid
        });
    };

    renderBalanceSheetGridItem = (item) => {
        // Should this logic be moved to BalanceSheetGridItem
        const dataGridProp = getDataGridPropFor(item);
        const accountMappingName = get(
            this.getDefaultSelectionsFor(item),
            'accountMapping.name',
        );
        const [componentToRender, requestToFetch] =
            getComponentAndRequestFor(item);

        return (
            <BalanceSheetOverviewGridItem
                title={item.title}
                accountGraph={this.props.accountGraph}
                accountMappingName={accountMappingName}
                key={JSON.stringify(dataGridProp)}
                data-grid={dataGridProp}
                componentToRender={componentToRender}
                componentName={item.component}
                request={requestToFetch}
                defaults={this.getDefaultSelectionsFor(item)}
                globalPeriod={
                    this.state.globalPeriod ||
                    this.getDefaultGlobalPeriodForEntity()
                }
                entityCodes={this.props.entityCodes}
                selectedDataLevel={this.props.selectedDataLevel}
            />
        );
    };

    render = () => {
        const { dashboardConfiguration } = this.props;
        const config = buildDashboardConfigFromSlimJson(
            dashboardConfiguration.config_json,
            dashboardConfiguration.config_type,
        );

        return (
            <React.Fragment>
                <div className={dateRangeContainer} id={BSO_RANGE_SELECT_ID}>
                    <RangeSelectConfirm
                        open={this.state.isRangeSelectOpen}
                        onFocus={() =>
                            this.setState({ isRangeSelectOpen: true })
                        }
                        onConfirm={(values) => {
                            this.setState({
                                isRangeSelectOpen: false,
                                globalPeriod: values,
                            });
                        }}
                        onCancel={() =>
                            this.setState({ isRangeSelectOpen: false })
                        }
                        value={
                            this.state.globalPeriod ||
                            this.getDefaultGlobalPeriodForEntity()
                        }
                        getCalendarContainer={() =>
                            document.getElementById(BSO_RANGE_SELECT_ID)
                        }
                    />
                </div>
                <Grid>
                    {config.items.map((item) => {
                        if (item.component === 'title') {
                            // Avoid using BalanceGridItem for Title because it does not fetch data or render a menu
                            return this.renderTitleComponent(item);
                        }
                        return this.renderBalanceSheetGridItem(item);
                    })}
                </Grid>
            </React.Fragment>
        );
    };
}

export default BalanceSheetGrid;
