import { Dispatch, SetStateAction } from 'react';

import { useClientPrefixedLocalStorage } from './useClientPrefixedLocalStorage';

// used to improve readability of the function signature
type SetValue<T> = Dispatch<SetStateAction<T>>;

/**
 * Returns the entity codes stored in localStorage
 */
export function useGetSelectedEntityCodes(): [string[], SetValue<string[]>] {
    const [storedValue, setStoredValue] = useClientPrefixedLocalStorage<
        string[]
    >('selected_entity_codes', []);
    return [storedValue, setStoredValue];
}
