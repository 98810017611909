import { css } from 'emotion';
import { DownloadButton, CollapseButtonArrow } from 'waypoint-react';
import React from 'react';

const settingsButtons = css`
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
`;

const noMargin = css`
    margin: 0;
`;

interface SettingsProps {
    loading: boolean;
    downloadCsv: () => Promise<void>;
    isBannerCollapsed: boolean;
    onCollapseBanner: () => void;
    workflowStatus?: React.ReactNode;
    isCollapsible?: boolean;
}

const Settings = ({
    loading,
    downloadCsv,
    isBannerCollapsed,
    onCollapseBanner,
    workflowStatus,
    isCollapsible,
}: SettingsProps) => {
    return (
        <div className={settingsButtons}>
            {workflowStatus}
            <span style={{ marginLeft: '10px', marginRight: '10px' }}>
                <DownloadButton
                    className={noMargin}
                    disabled={loading}
                    onClick={downloadCsv}
                />
            </span>
            {isCollapsible ? (
                <CollapseButtonArrow
                    isCollapsed={isBannerCollapsed}
                    onClick={() => onCollapseBanner()}
                />
            ) : null}
        </div>
    );
};

export default Settings;
