import { API_URL } from 'config/constants';
import { EntityProfileCardYearMetadataWithCommentCount } from 'waypoint-types/properties/types';

export async function getYearMetadata(
    entityCodes: string[],
    type: string
): Promise<EntityProfileCardYearMetadataWithCommentCount[]> {
    const response = await fetch(`${API_URL}/planning/${type}/year-metadata`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            entity_codes: entityCodes,
        }),
    });

    if (!response.ok) {
        throw new Error('Failed to get planning year metadata');
    }

    const { data } = await response.json();

    return data as EntityProfileCardYearMetadataWithCommentCount[];
}
