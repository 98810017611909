import { API_URL } from 'config/constants';
import { LeasingPlan } from 'waypoint-types/leasing-plans';

interface LeasingPlanUpdateParams {
    year: number;
    as_of_date: string;
    year_activity_filter: number;
}

const updateLeasingPlan = async (
    entityCode: string,
    leasingPlanId: string,
    body: LeasingPlanUpdateParams
): Promise<LeasingPlan> => {
    const response = await fetch(
        `${API_URL}/leasing-plans/${entityCode}/${leasingPlanId}`,
        {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(body),
        }
    );

    if (!response.ok) {
        throw new Error('Failed to update leasing plan');
    }

    const { data } = await response.json();

    return data as LeasingPlan;
};

export default updateLeasingPlan;
