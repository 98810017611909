import _ from 'lodash';
import {
    AttributeHashmap,
    SelectedAttributeFilter,
    SelectedAttributes,
    SelectOptionType,
} from 'components/app/global-filter-drawer/types/globalFilterTypes';
import {
    AttributeFilter,
    FilterReferenceTypes,
    SavedFilter,
} from 'contexts/saved-filters';
import { createUUID } from 'waypoint-utils';
import { AttributeDefinition, Entity } from 'waypoint-types';
import { Dictionary } from 'ts-essentials';

/** This utility simply creates a hashmap where filter uuids are keys and their values are a list of entities that pass that filter. It is used in other utils to funnel properties through a collection of filters and to produce another hashmap of available options for each attribute in the FilterEditor UI */
function getEntitiesByFilterHashmap(
    attributes: AttributeDefinition[],
    entities: Entity[],
    currentSelections: SelectedAttributes,
) {
    const validFilters = getValidAttributeFilterSelections(currentSelections);

    const validFilterUUIDs = Object.keys(validFilters);

    if (!validFilterUUIDs) {
        return {};
    }

    return validFilterUUIDs.reduce(
        (
            filteredEntityHashmap: Dictionary<Entity[]>,
            uuid: string,
        ): Dictionary<Entity[]> => {
            const {
                attributeDataIndex,
                attributeValues,
            }: SelectedAttributeFilter = currentSelections[uuid];

            const attributeDefinition = attributes.find(
                (attr) => attr.attribute_code === attributeDataIndex,
            );

            filteredEntityHashmap[uuid] = entities.filter((ent: Entity) => {
                const attributeValueForEntity =
                    attributeDefinition?.attributeValues?.find(
                        (valueRow) => valueRow.entity_code === ent.entityCode,
                    );

                if (!attributeValueForEntity) {
                    return false;
                }

                return attributeValues.includes(attributeValueForEntity.value);
            });

            return filteredEntityHashmap;
        },
        {},
    );
}

// filter both previously selected attributes and
// attributes with no assigned values for entities
export function filterOutPreviouslySelectedOrUnassignedAttributes(
    currentSelections: SelectedAttributes,
    attributeLookup: AttributeHashmap,
    keysToRemove?: string[],
): SelectOptionType[] {
    const previouslySelectedAttributes = Object.values(currentSelections);

    const previouslySelectedAttributeNames: string[] =
        previouslySelectedAttributes
            ? previouslySelectedAttributes.map((attr) => attr.attribute)
            : [];

    return Object.keys(attributeLookup).reduce(
        (unSelectedAttributes: SelectOptionType[], attrName: string) => {
            if (
                !previouslySelectedAttributeNames.includes(attrName) &&
                !keysToRemove?.includes(attributeLookup[attrName].dataIndex) &&
                attributeLookup[attrName].options.length > 0
            ) {
                unSelectedAttributes.push({
                    label: attrName,
                    value: attributeLookup[attrName].dataIndex,
                });
            }
            return unSelectedAttributes;
        },
        [] as SelectOptionType[],
    );
}

/* Due to UX requirements, filters can be added to the UI without valid values in state (aka, they are empty). This util filters out empty/invalid filter state values. It's used in a couple of places but was primarily implemented so that invalid filters are never applied or sent to the API.
 */
export function getValidAttributeFilterSelections(
    currentSelections: SelectedAttributes,
) {
    const uuids = Object.keys(currentSelections);

    return uuids.reduce((validAttributeFilters, uuid): SelectedAttributes => {
        const attributeFilter = currentSelections[uuid];

        if (
            attributeFilter.attribute &&
            attributeFilter.attributeValues.length
        ) {
            validAttributeFilters[uuid] = attributeFilter;
        }

        return validAttributeFilters;
    }, {} as SelectedAttributes);
}

function createAttributeHashmap(
    attributes: AttributeDefinition[],
): AttributeHashmap {
    return attributes.reduce(
        (attributeHashMap: AttributeHashmap, attr: AttributeDefinition) => {
            attributeHashMap[attr.name] = {
                title: attr.name,
                dataIndex: attr.attribute_code,
                options: deriveAttributeValueOptions(attr),
                isSelected: false,
            };
            return attributeHashMap;
        },
        {},
    );
}

/**
 * This util removes invalid selections. If a user amends filters, it could make selections below them invalid. Occurs if a user has added multiple filters and edits any up the funnel.
 */

export function removeInvalidAttributeSelections(
    attributes: AttributeDefinition[],
    entities: Entity[],
    currentSelections: SelectedAttributes,
): SelectedAttributes {
    const uuids = Object.keys(currentSelections);

    const funneledAttributeHashmap = getFunneledAttributeHashmap(
        attributes,
        entities,
        currentSelections,
    );

    // for each uuid,
    return uuids.reduce((validSelections, uuid): SelectedAttributes => {
        const selection: SelectedAttributeFilter = currentSelections[uuid];
        // get attribute title
        // get the selected values for that option
        const { attribute, attributeValues } = selection;

        // get the hash map for that filter
        const attributeHashmapForFilter = funneledAttributeHashmap[uuid];

        // use attribute title to lookup valid options for that uuid and attribute

        const validOptions: string[] =
            attribute && attribute in attributeHashmapForFilter
                ? attributeHashmapForFilter[attribute].options.map(
                      (option: SelectOptionType) => option.label,
                  )
                : [];

        // remove attributeValues that are not found in that list
        const validAttributeValues = attributeValues.filter((attr) =>
            validOptions.includes(`${attr}`),
        );

        // amend selections
        validSelections[uuid] = {
            ...selection,
            attributeValues: validAttributeValues,
        };

        return validSelections;
    }, {} as SelectedAttributes);
}

/** This returns an array of possible options for any attribute. The options are derived directly from values found in the entity collection. */
export function deriveAttributeValueOptions(
    attr: AttributeDefinition,
): SelectOptionType[] {
    const uniqueValidAttributeValues = Array.from(
        new Set(attr.attributeValues?.map((val) => val.value) ?? []),
    );

    return uniqueValidAttributeValues.map((attributeValue) => ({
        value: attributeValue,
        label: attributeValue,
    }));
}

/** This returns an array of entity codes. Each entity code represents an entity that has passed every filter. Used for FilterEditor */
export function funnelEntities(
    attributes: AttributeDefinition[],
    entities: Entity[],
    currentSelections: SelectedAttributes,
): Entity[] {
    const filteredEntitiesByFilter = getEntitiesByFilterHashmap(
        attributes,
        entities,
        currentSelections,
    );

    // NOTE: it is unclear why apply and the requirement to pass the lodash _ dependency to this intersection is necessary.
    return _.intersection.apply(
        _,
        Object.values(filteredEntitiesByFilter),
    ) as Entity[];
}

export function getFunneledAttributeHashmap(
    attributes: AttributeDefinition[],
    entities: Entity[],
    currentSelections: SelectedAttributes,
) {
    // iterate over currentSelections
    const uuids = Object.keys(currentSelections);

    return uuids.reduce((nestedHashmap, uuid) => {
        const indexOfSelection = uuids.indexOf(uuid);

        const uuidsAboveCurrent = [...uuids].slice(0, indexOfSelection);

        const selectionsAboveCurrent = uuidsAboveCurrent.reduce((hash, id) => {
            hash[id] = currentSelections[id];
            return hash;
        }, {} as Dictionary<SelectedAttributeFilter>);

        // if top filter or is edge case where all filters above are cleared (aka, invalid)...
        // ... default to all available attributes and options

        const allFiltersAboveAreInvalid =
            Object.keys(
                getValidAttributeFilterSelections(selectionsAboveCurrent),
            ).length === 0;

        if (uuidsAboveCurrent.length === 0 || allFiltersAboveAreInvalid) {
            nestedHashmap[uuid] = createAttributeHashmap(attributes);
            return nestedHashmap;
        }

        nestedHashmap[uuid] = createAttributeHashmap(attributes);

        return nestedHashmap;
    }, {} as Dictionary<AttributeHashmap>);
}

/** Applied filters in local storage have a different shape than filters in component state. This simply converts an applied filter to one consumable by the FilterEditor */
export function convertSavedFilterToSelectedAttributes(
    appliedFilter: SavedFilter | null,
): SelectedAttributes {
    if (!appliedFilter || !appliedFilter.filters) {
        return {};
    }
    const { filters } = appliedFilter;

    return filters.reduce((collection, filter): SelectedAttributes => {
        const uuid = createUUID();
        collection[uuid] = {
            uuid,
            attribute: filter.title,
            attributeDataIndex: filter.key,
            attributeValues: filter.values,
        };
        return collection;
    }, {} as SelectedAttributes);
}

/** Applied filters in local storage have a different shape than filters in component state. This simply converts a component (FilterEditor) state to the required local storage shape. */
export function convertSelectedAttributesToSavedFilter(
    attributeFilters: SelectedAttributes,
): SavedFilter {
    const validFilters = removeBlankAttributeFilters(attributeFilters);

    const filterValues = Object.values(validFilters);

    const validFilterValues: AttributeFilter[] = filterValues
        ? filterValues.map((filter: SelectedAttributeFilter) => {
              return {
                  key: filter.attributeDataIndex,
                  title: filter.attribute,
                  values: filter.attributeValues,
              };
          })
        : [];

    return {
        name: '',
        reference_type: FilterReferenceTypes.USER,
        filters: validFilterValues,
    };
}

/** Removes filter fields that have empty values before they are applied. Defensive. It's a bit unlikely a user will try to apply a blank filter, but it's possible  */
function removeBlankAttributeFilters(
    attributeFilters: SelectedAttributes,
): SelectedAttributes {
    const uuids = Object.keys(attributeFilters);

    return uuids.reduce(
        (submittableAttributeFilters, uuid): SelectedAttributes => {
            const attributeFilter = attributeFilters[uuid];
            if (
                attributeFilter.attribute &&
                attributeFilter.attributeValues.length
            ) {
                submittableAttributeFilters[uuid] = attributeFilter;
            }
            return submittableAttributeFilters;
        },
        {} as SelectedAttributes,
    );
}
