import { API_URL } from 'config/constants';
import { Contract } from 'waypoint-types';

const updateContract = async (
    contractId: string,
    body: Contract
): Promise<Contract> => {
    const response = await fetch(`${API_URL}/contracts/${contractId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(body),
    });

    if (!response.ok) {
        throw new Error('Failed to update contract');
    }

    const { data } = await response.json();

    return data as Contract;
};

export default updateContract;
