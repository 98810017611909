export const loadingText = 'We are loading your tenants';
export const noDataText = 'No data to display';

/*
    Change this if you want to display top n number of industries (and categorize rest as "Other" on Pie Chart).
    Or change to null if you want to display all industries in charts and tables.
*/
export const topIndustryCount = 5;

export const rentByIndustryCardTitle = `Top ${topIndustryCount} Industries by Total Annual In-Place Rent`;
export const sqftByIndustryCardTitle = `Top ${topIndustryCount} Industries by Sq Ft`;
export const waleByIndustryCardTitle = `Top ${topIndustryCount} Industries by WALE`;
