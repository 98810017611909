import React, { Component } from 'react';
import { css } from 'emotion';
import { isNull, isEqual } from 'lodash';
import { Tabs } from 'antd';
import {
    PROPERTY_ATTRIBUTES_TITLE,
    PERFORMANCE_OVERVIEW_TITLE,
    PROPERTY_VALUE_TITLE,
    PERFORMANCE_OVERVIEW_KEY,
    PROPERTY_ATTRIBUTES_KEY,
    PROPERTY_VALUE_KEY,
    SEGMENTATION_CARD_OFFSET,
} from 'components/analytics/portfolioSegmentation/constants';
import theme from 'config/theme';
import {
    getPerformances,
    getGroupByOptions,
    getMetrics,
} from 'components/analytics/portfolioSegmentation/requests';
import {
    savePerformanceOverviewConfiguration,
    getPerformanceOverviewConfiguration,
    transformMetricsToSelectOptions,
    transformGroupByToSelectOptions,
} from 'components/analytics/portfolioSegmentation/utils';
import Card from 'components/style/Card';
import PerformanceOverview from 'components/analytics/portfolioSegmentation/performanceOverview/PerformanceOverview';
import PerformanceOverviewPopover from 'components/analytics/portfolioSegmentation/performanceOverview/menu/PerformanceOverviewPopover';
import PropertyValueTableContainer from 'components/analytics/portfolioSegmentation/propertyValues/PropertyValueTableContainer';
import ActionButton from 'components/style/ActionButton';
import DownloadPerformanceOverviewButton from 'components/analytics/portfolioSegmentation/performanceOverview/DownloadPerformanceOverviewButton';
import DownloadPortfolioSegmentationButton from 'components/analytics/portfolioSegmentation/dashboard/DownloadPortfolioSegmentationButton';
import { numericalSort } from 'components/tables/sorts';
import { AppFeaturePermissions } from 'contexts';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';

const portfolioDashboardStyle = css`
    margin: 20px;
    width: calc(100% - 40px);
`;

const tabStyle = css`
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: visible !important;
    .ant-tabs-tab.ant-tabs-tab-active {
        color: ${theme.colors.blues.primary} !important;
    }

    .ant-tabs-ink-bar {
        background: ${theme.colors.blues.primary} !important;
    }

    .ant-tabs-extra-content {
        line-height: 43px;
    }

    .ant-tabs-tabpane.ant-tabs-tabpane-active,
    .ant-tabs-top.ant-tabs-line.ant-tabs-no-animation {
        height: 100% !important;
    }
`;

const doubleButtonContainerStyle = css`
    display: flex;
    align-items: center;
    .fa-solid:first-child {
        margin-right: 5px;
    }
`;

const actionButtonDisabledStyle = css`
    cursor: not-allowed;
    pointer-events: none;
    color: ${theme.colors.grays.medium};
`;

const { TabPane } = Tabs;

class SegmentationCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            groupBy: null,
            kpiSelected: null,
            config: { metric: null, group_by: null },
            data: [],
            performanceOverviews: false,
            isLoadingPerformanceOverviews: false,
            metricsOptions: [],
            groupByOptions: [],
            configButtonEnabled: false,
            showPopOver: false,
        };

        this.getExtraActions = this.getExtraActions.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.fetchPerformance = this.fetchPerformance.bind(this);
        this.changeKpi = this.changeKpi.bind(this);
        this.saveConfig = this.saveConfig.bind(this);
        this.changeGroupBy = this.changeGroupBy.bind(this);
        this.handleFilterApply = this.handleFilterApply.bind(this);
    }

    componentDidMount() {
        const { config } = this.props;
        const optionsApiCalls = Promise.all([
            getMetrics(),
            getGroupByOptions(),
        ]);
        const performanceOverviewConfigurations =
            getPerformanceOverviewConfiguration(config);

        if (!isNull(performanceOverviewConfigurations)) {
            this.setState({
                isLoadingPerformanceOverviews: true,
                performanceOverviews: false,
            });
        }

        optionsApiCalls
            .then((results) => {
                Promise.all(results.map((r) => r.json())).then((allOptions) => {
                    this.setState(
                        {
                            metricsOptions: transformMetricsToSelectOptions(
                                allOptions[0].data
                            ),
                            groupByOptions: transformGroupByToSelectOptions(
                                allOptions[1].data
                            ),
                            configButtonEnabled: true,
                        },
                        this.setInitialConfigs
                    );
                });
            })
            .catch(() =>
                this.setState({ metricsOptions: [], groupsByOptions: [] })
            );
    }

    componentDidUpdate(prevProps) {
        const entityCodesChanged = !isEqual(
            this.props.entityCodes,
            prevProps.entityCodes
        );
        const periodChanged = !isEqual(this.props.period, prevProps.period);
        const configsSavedOrApplied =
            !isNull(this.state.config.metric) &&
            !isNull(this.state.config.group_by);
        if (configsSavedOrApplied && (entityCodesChanged || periodChanged)) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState(
                { isLoadingPerformanceOverviews: true },
                this.fetchPerformance()
            );
        }
    }

    /**
     *
     * Returns a component for "extra actions" in antd table.
     *
     * @param {number} key The active tab key
     * @returns {Component|React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>}
     */
    // MANU: Need to refactor this function because was copying the state, not refreshing live so you need to do multiple clicks for the popover
    getExtraActions(key) {
        switch (key) {
            case PERFORMANCE_OVERVIEW_KEY:
                return (
                    <div className={doubleButtonContainerStyle}>
                        <DownloadPerformanceOverviewButton
                            performanceOverviews={
                                this.state.performanceOverviews
                            }
                            groupBy={this.state.groupBy}
                            kpiSelected={this.state.kpiSelected}
                            entityCodes={this.props.entityCodes}
                            period={this.props.period}
                        />
                        <PerformanceOverviewPopover
                            show={this.state.showPopOver}
                            groupBy={this.state.groupBy}
                            kpiSelected={this.state.kpiSelected}
                            metricsOptions={this.state.metricsOptions}
                            groupByOptions={this.state.groupByOptions}
                            onClose={this.closeMenu}
                            onApply={this.handleFilterApply}
                            changeKpi={this.changeKpi}
                            saveConfig={this.saveConfig}
                            changeGroupBy={this.changeGroupBy}
                        >
                            <ActionButton
                                onClick={this.toggleMenu}
                                size="lg"
                                className={
                                    !this.state.configButtonEnabled &&
                                    actionButtonDisabledStyle
                                }
                            />
                        </PerformanceOverviewPopover>
                    </div>
                );
            case PROPERTY_ATTRIBUTES_KEY: {
                return (
                    <DownloadPortfolioSegmentationButton
                        tab={PROPERTY_ATTRIBUTES_TITLE}
                        entityCodes={this.props.entityCodes}
                        period={this.props.period}
                    />
                );
            }
            default:
                return;
        }
    }

    setInitialConfigs() {
        const { groupBy, kpiSelected, metricsOptions, groupByOptions } =
            this.state;
        const { config } = this.props;
        const performanceOverviewConfigurations =
            getPerformanceOverviewConfiguration(config);

        if (!isNull(performanceOverviewConfigurations)) {
            this.setState(
                {
                    kpiSelected: performanceOverviewConfigurations.metric,
                    groupBy: performanceOverviewConfigurations.group_by,
                },
                this.fetchPerformance
            );
        } else {
            // MANU: This is a crunch temporary fix, those values in the near future will come from an endpoint.
            if (isNull(groupBy) && isNull(kpiSelected)) {
                this.setState({
                    groupBy: groupByOptions[0].value,
                    kpiSelected: metricsOptions[0].value,
                });
            }
        }
    }

    setError() {
        this.setState({
            error: true,
        });
    }

    setPerfomance(data, config) {
        this.setState({
            data: data.sort((a, b) => numericalSort(b.value, a.value)),
            config,
            performanceOverviews: true,
            isLoadingPerformanceOverviews: false,
        });
    }

    handleFilterApply() {
        this.closeMenu();
        this.fetchPerformance();
    }

    toggleMenu() {
        this.setState({
            showPopOver: !this.state.showPopOver,
        });
    }

    closeMenu() {
        this.setState({
            showPopOver: false,
        });
    }

    fetchPerformance() {
        const { entityCodes, period } = this.props;
        const { kpiSelected, groupBy } = this.state;
        const configToSave = { metric: kpiSelected, group_by: groupBy };
        this.props.saveConfig(configToSave);

        this.setState(
            {
                isLoadingPerformanceOverviews: true,
                performanceOverviews: false,
            },
            () => {
                getPerformances({
                    ...configToSave,
                    entityCodes,
                    period,
                })
                    .then((res) => res.json())
                    .then((res) => this.setPerfomance(res.data, configToSave))
                    .catch((error) => this.setError(error));
            }
        );
    }

    /**
     * Change the value of the group by selection on the performance popover
     *
     * @param {object} selection
     */
    changeGroupBy(selection) {
        this.setState({
            groupBy: selection.value,
        });
    }

    /**
     * Change the value of the metric selection on the performance popover
     *
     * @param {object} selection
     */
    changeKpi(selection) {
        this.setState({
            kpiSelected: selection.value,
        });
    }

    /**
     *
     * Save the configuration of the performance popover on the localStorage
     *
     */
    saveConfig() {
        const { kpiSelected, groupBy } = this.state;
        savePerformanceOverviewConfiguration({
            metric: kpiSelected,
            group_by: groupBy,
        });
        this.fetchPerformance();
        this.closeMenu();
    }

    render() {
        const {
            error,
            data,
            groupBy,
            config,
            performanceOverviews,
            isLoadingPerformanceOverviews,
        } = this.state;

        const height = window.innerHeight - SEGMENTATION_CARD_OFFSET;

        return (
            <PermissionedWrapper
                featureKey={AppFeaturePermissions.PortfolioSummary}
                showDisabledView={true}
            >
                <Card
                    fluid
                    className={portfolioDashboardStyle}
                    style={{ height }}
                >
                    <Tabs
                        className={tabStyle}
                        animated={false}
                        onChange={(e) => this.props.setActiveTab(e)}
                        defaultActiveKey={this.props.activeTabKey}
                        tabBarExtraContent={this.getExtraActions(
                            this.props.activeTabKey
                        )}
                    >
                        <TabPane
                            tab={PROPERTY_VALUE_TITLE}
                            key={PROPERTY_VALUE_KEY}
                        >
                            <PropertyValueTableContainer
                                entityCodes={this.props.entityCodes}
                                isDependenciesLoading={
                                    this.props.isDependenciesLoading
                                }
                                period={this.props.period}
                            />
                        </TabPane>
                        <TabPane
                            tab={PERFORMANCE_OVERVIEW_TITLE}
                            key={PERFORMANCE_OVERVIEW_KEY}
                        >
                            <PerformanceOverview
                                error={error}
                                groupBy={groupBy}
                                config={config}
                                data={data}
                                performanceOverviews={performanceOverviews}
                                isLoading={
                                    isLoadingPerformanceOverviews ||
                                    this.props.isDependenciesLoading
                                }
                            />
                        </TabPane>
                    </Tabs>
                </Card>
            </PermissionedWrapper>
        );
    }
}

export default SegmentationCard;
