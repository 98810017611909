import React from 'react';
import fusioncharts from 'fusioncharts';
import charts from 'fusioncharts/fusioncharts.charts';
import ReactFC from 'react-fusioncharts';

import chartBuilder from 'components/charts/chartBuilder';
import { theme } from 'config/colors';

import { map } from 'lodash';
import { TenantData, parseMetric } from './topTenantsUtils';
import { css } from 'emotion';
import { EntityReportWidgetPdfSettings } from 'components/reports/ReportUtils';

const chartCardStyle = (isPDFExport?: boolean, exportWidth?: string) => css`
    margin-top: auto;
    margin-right: 24px;
    border: 1px solid #e0e0e0;
    padding: 4px;
    width: ${isPDFExport ? exportWidth : 'auto'};
    @media screen and (max-width: 1268px) {
        width: ${isPDFExport ? exportWidth : '100%'};
        margin-bottom: 24px;
    }
`;

charts(fusioncharts);

const chartStyle = {
    useEllipsesWhenOverflow: '1',
    maxLabelWidthPercent: '20',
    showValues: '1',
    placeValuesInside: '1',
    numVisiblePlot: '15',
    numDivLines: '5',
    baseFontColor: theme.colors.mediumGray,
    xAxisNameFontColor: theme.colors.mediumGray,
    yAxisNameFontColor: theme.colors.mediumGray,
};

interface TopTenantsChartProps {
    tenants: TenantData[];
    metric: string;
    setIsChartReadyToExport?: (isReady: boolean) => void;
    isPDFExport?: boolean;
    isPropertyProfilePage?: boolean;
    exportWidth?: string;
    chartExportId?: string;
    widgetVisualOption?: string;
    pdfSettings?: EntityReportWidgetPdfSettings;
}

const TopTenantsChart = ({
    tenants,
    setIsChartReadyToExport,
    isPDFExport,
    isPropertyProfilePage,
    exportWidth,
    chartExportId,
    metric,
    widgetVisualOption,
}: TopTenantsChartProps) => {
    const dataForTheChart = map(tenants, (value) => {
        return {
            tenant_industry: value.tenant,
            rentable_sq_ft: value[parseMetric(metric) as keyof TenantData],
        };
    });

    const chart = chartBuilder();
    chart.type('scrollbar2d');
    chart.height(
        isPDFExport && tenants.length > 10
            ? '600'
            : isPropertyProfilePage
              ? '360'
              : '375'
    );
    chart.width('100%');
    chart.style(
        isPDFExport
            ? {
                  baseFont: 'Arial',
                  xAxisNameFont: 'Arial',
                  pYAxisNameFont: 'Arial',
                  sYAxisNameFont: 'Arial',
                  exportEnabled: '0',
                  animation: '0',
                  showValues: '1',
                  placeValuesInside: 1,
                  numVisiblePlot: 0,
                  numDivLines: '5',
              }
            : chartStyle
    );
    chart.exportFormats('PNG|PDF|JPG|SVG');
    chart.categories([
        {
            category: dataForTheChart.map((s) => ({
                label: s.tenant_industry,
            })),
        },
    ]);
    chart.dataset([
        {
            data: dataForTheChart.map((s) => ({ value: s.rentable_sq_ft })),
        },
    ]);

    const { config: chartConfig } = chart;

    const onDrawComplete = () => {
        setIsChartReadyToExport && setIsChartReadyToExport(true);
    };

    if (widgetVisualOption === 'table') {
        return <div style={{ marginBottom: '-5px' }} />;
    }

    return (
        <div className={chartCardStyle(isPDFExport, exportWidth)}>
            <ReactFC {...chartConfig} fcEvent-drawComplete={onDrawComplete} />
        </div>
    );
};

export default TopTenantsChart;
