import { formatInTimeZone } from 'date-fns-tz';

const getLocalTimeZone = (): string => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const formatDateInUserTimeZone = (
    date: Date | string,
    format: string = 'MM/dd/yyyy hh:mm aa'
): string => {
    const localTimeZone = getLocalTimeZone();
    return formatInTimeZone(new Date(date), localTimeZone, format);
};
