import React from 'react';
import { Modal, Radio } from 'antd';
import { EntityReportWidgetListItem } from 'waypoint-types';
import { Checkbox } from 'waypoint-react';
import { PDFExportOptions } from '../constants';

interface EntityReportPDFExportModalProps {
    isModalOpen: boolean;
    isExporting: boolean;
    exportDocument: () => Promise<void>;
    reportWidgetList: EntityReportWidgetListItem[];
    selectedEntityReportWidgetName: string;
    selectedExportOption: string;
    setSelectedExportOption: (value: string) => void;
    selectedExportComponentIds: string[];
    setSelectedExportComponentIds: (value: string[]) => void;
    onCancel: () => void;
}

const EntityReportPDFExportModal = ({
    isModalOpen,
    isExporting,
    exportDocument,
    reportWidgetList,
    selectedEntityReportWidgetName,
    selectedExportOption,
    setSelectedExportOption,
    selectedExportComponentIds,
    setSelectedExportComponentIds,
    onCancel,
}: EntityReportPDFExportModalProps) => {
    const updateSelectedExportComponentIdsList = (
        checked: boolean,
        componentId: string
    ) => {
        if (checked) {
            setSelectedExportComponentIds([
                ...selectedExportComponentIds,
                componentId,
            ]);
            return;
        }
        setSelectedExportComponentIds(
            selectedExportComponentIds.filter((id) => id !== componentId)
        );
    };

    return (
        <Modal
            centered={true}
            width="500px"
            bodyStyle={{ height: '400px' }}
            open={isModalOpen}
            closable={false}
            maskClosable={false}
            forceRender
            okText="Export"
            okButtonProps={
                selectedExportOption === PDFExportOptions.SELECTED_SECTIONS &&
                !selectedExportComponentIds.length
                    ? { disabled: true }
                    : { loading: isExporting }
            }
            cancelButtonProps={{ disabled: isExporting }}
            onOk={exportDocument}
            onCancel={onCancel}
        >
            <h3>Export Options</h3>
            <div style={{ fontSize: '16px', marginBottom: '15px' }}>
                Select the options you want to include in the exported report.
            </div>
            <Radio.Group
                disabled={isExporting}
                defaultValue={PDFExportOptions.ENTIRE_REPORT}
                onChange={(e) => setSelectedExportOption(e.target.value)}
            >
                <div>
                    <Radio value={PDFExportOptions.ENTIRE_REPORT}>
                        Entire Report
                    </Radio>
                </div>
                <div>
                    <Radio value={PDFExportOptions.CURRENT_SECTION}>
                        {`Current Section (${selectedEntityReportWidgetName})`}
                    </Radio>
                </div>
                <div>
                    <Radio value={PDFExportOptions.SELECTED_SECTIONS}>
                        Selected Sections...
                    </Radio>
                </div>
            </Radio.Group>

            {selectedExportOption === PDFExportOptions.SELECTED_SECTIONS && (
                <div
                    style={{
                        marginTop: '15px',
                        border: '1px solid lightgrey',
                        paddingTop: '5px',
                        height: '50%',
                        overflowY: 'auto',
                    }}
                >
                    {reportWidgetList.map((w) => {
                        return (
                            <div key={w.id} style={{ marginLeft: '5px' }}>
                                <Checkbox
                                    checked={selectedExportComponentIds.includes(
                                        w.id
                                    )}
                                    onChange={(ev) =>
                                        updateSelectedExportComponentIdsList(
                                            ev.target.checked,
                                            w.id
                                        )
                                    }
                                >
                                    {w.name}
                                </Checkbox>
                            </div>
                        );
                    })}
                </div>
            )}
        </Modal>
    );
};

export default EntityReportPDFExportModal;
