import { API_URL } from 'config/constants';
import { Valuation } from 'waypoint-types';

const updateValuation = async (
    valuationId: string,
    body: Valuation
): Promise<Valuation> => {
    const response = await fetch(`${API_URL}/valuations/${valuationId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(body),
    });

    if (!response.ok) {
        throw new Error('Failed to update valuation');
    }

    const { data } = await response.json();

    return data as Valuation;
};

export default updateValuation;
