import React, { useEffect, useState } from 'react';
import { useGetDataAvailabilityDate } from 'waypoint-hooks';
import { AvailabilityDateType } from 'components/financials/balanceSheet/types';
import moment, { Moment } from 'moment';
import { toISO } from 'components/dates/utils';
import { RangePickerProps } from 'antd/lib/date-picker';
import { startDate } from 'components/financials/balanceSheet/constants';
import { getRangeOfMonths } from 'components/financials/balanceSheet/components/banner/utils';

export const useBalanceSheetSettings = (entityCodes: string[]) => {
    const [primaryPeriod, setPrimaryPeriod] = useState<string | undefined>(
        undefined
    );
    const [secondaryPeriod, setSecondaryPeriod] = useState<string | undefined>(
        undefined
    );
    const {
        data: asOfDate,
        isLoading: isLoadingAsOfDate,
        isError: isErrorAsOfDate,
    } = useGetDataAvailabilityDate(entityCodes);

    const getDefaultDateSelections = (): AvailabilityDateType => {
        if (!asOfDate) {
            return {
                primary: '',
                secondary: '',
                primaryDefault: '',
                secondaryDefault: '',
            };
        }

        const primaryDefault = toISO(asOfDate);
        const secondaryDefault = moment(asOfDate)
            .subtract(1, 'month')
            .endOf('month')
            .format('YYYY-MM-DD');

        return {
            primary: primaryDefault,
            primaryDefault: primaryDefault,
            secondary: secondaryDefault,
            secondaryDefault: secondaryDefault,
        };
    };

    const dateSelections = getDefaultDateSelections();

    const monthRange: string[] = getRangeOfMonths({
        // array of timestamps
        startMonth: startDate,
        endMonth: dateSelections.primaryDefault ?? '',
    });

    const disabledDate = (current: Moment | null): boolean => {
        // Disable dates after the last available month
        const lastMonth = moment(monthRange[monthRange.length - 1]);
        return !!(current && current.isAfter(lastMonth));
    };

    useEffect(() => {
        !primaryPeriod && setPrimaryPeriod(dateSelections.primary);
        !secondaryPeriod && setSecondaryPeriod(dateSelections.secondary);
    }, [asOfDate]);

    return {
        dateSelections,
        disabledDate,
        primaryPeriod,
        setPrimaryPeriod,
        secondaryPeriod,
        setSecondaryPeriod,
    };
};
