import React, { useEffect, useState } from 'react';
import { Checkbox } from 'waypoint-react';
import { RecurringChargeSelect } from 'components/leases/components/recurring-charge/RecurringChargeSelect';
import { useRecurringCharges, useGridConfigSettings } from 'waypoint-hooks';
import {
    ReportWidgetSettingsBuilderProps,
    SavedConfigFilterType,
} from 'waypoint-types';
import ReportWidgetSettingsModal from 'components/reports/report-widget/ReportWidgetSettingsModal';
import { SavedGridConfigSelect } from 'components/saved-configurations';
import {
    RENT_ROLL_DEFAULT_COLUMN_SELECTION,
    RentRollColumnSelection,
    rentRollDefaultConfigs,
} from 'components/leases/expirations/cards/rent-roll/utils';

const RentRollGridSettingsBuilder = ({
    entityCodes,
    isModalOpen,
    setIsModalOpen,
    onSaveWidgetSettings,
    widgetType,
    currentSettings,
}: ReportWidgetSettingsBuilderProps): JSX.Element => {
    const [selectedChargeCodes, setSelectedChargeCodes] = useState<string[]>(
        currentSettings?.selectedChargeCodes ?? []
    );

    const [includeRecurringChargeSummary, setIncludeRecurringChargeSummary] =
        useState<boolean>(
            currentSettings?.includeRecurringChargeSummary ?? false
        );

    const [columnSelection, setColumnSelection] =
        useState<RentRollColumnSelection>(
            currentSettings?.selectedViewKey ??
                RENT_ROLL_DEFAULT_COLUMN_SELECTION
        );

    const {
        selectedConfiguration,
        setSelectedConfiguration,
        savedConfigurationsData,
    } = useGridConfigSettings(SavedConfigFilterType.RentRoll);

    useEffect(() => {
        const savedColumnSelection =
            selectedConfiguration?.filters_json?.local_config?.columnSelection;
        if (savedColumnSelection) {
            setColumnSelection(savedColumnSelection);
        }
    }, [selectedConfiguration]);

    useEffect(() => {
        if (currentSettings?.savedConfigId) {
            setSelectedConfiguration(
                savedConfigurationsData?.find(
                    (config) => config.id === currentSettings.savedConfigId
                ) ?? null
            );
        }
    }, [currentSettings, savedConfigurationsData]);

    const { data } = useRecurringCharges(entityCodes, {
        stakeholder: null,
        percentageType: null,
    });
    if (!data || !savedConfigurationsData) {
        return <></>;
    }

    const { summary } = data;
    const buildReportWidgetSettingsInputs = () => {
        return {
            selectedChargeCodes,
            includeRecurringChargeSummary,
            savedConfigId: selectedConfiguration?.id ?? '',
            selectedViewKey: columnSelection,
        };
    };

    return (
        <ReportWidgetSettingsModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            settingsJsonBuilder={buildReportWidgetSettingsInputs}
            onSaveWidgetSettings={onSaveWidgetSettings}
            widgetType={widgetType}
        >
            <div style={{ fontSize: '12px', fontWeight: 'bold' }}>
                Saved Grid Configuration
            </div>
            <SavedGridConfigSelect
                key={selectedConfiguration?.id ?? null}
                savedConfigurationsData={savedConfigurationsData}
                selectedConfiguration={selectedConfiguration}
                setSelectedConfiguration={setSelectedConfiguration}
                rentRollDefaultConfigs={rentRollDefaultConfigs}
                rentRollColumnSelection={columnSelection}
                setRentRollColumnSelection={setColumnSelection}
                excludeUserConfigs
            />
            <div
                style={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                    marginTop: '15px',
                }}
            >
                Recurring Charges
            </div>
            <RecurringChargeSelect
                recurringCharges={summary.buckets}
                selectedChargeCodes={selectedChargeCodes}
                onChange={setSelectedChargeCodes}
                useDrawerStyle={true}
            />
            <div>
                <Checkbox
                    checked={includeRecurringChargeSummary}
                    onChange={(ev) =>
                        setIncludeRecurringChargeSummary(ev.target.checked)
                    }
                >
                    <span>Include Recurring Charges Summary</span>
                </Checkbox>
            </div>

            {selectedConfiguration?.filters_json.local_config[
                'selectedChargeCode'
            ]?.length && selectedChargeCodes.length ? (
                <div
                    style={{
                        marginTop: '25px',
                        fontSize: '12px',
                        color: 'red',
                    }}
                >
                    Note: The selected grid configuration contains recurring
                    charges that will supersede your selected recurring charges
                </div>
            ) : (
                <></>
            )}
        </ReportWidgetSettingsModal>
    );
};

export default RentRollGridSettingsBuilder;
