import { Avatar, Badge, Steps, Tooltip } from 'antd';
import theme from 'config/theme';
import { css } from 'emotion';
import React from 'react';
import { numericalSort } from 'utils/tables/sorters';
import {
    getAvatarInitials,
    getReviewerApprovalIcon,
} from '../comparative-income-statement/banner/ComparisonSelectionsBannerUtils';
import { WORKFLOW_ASSIGNEE } from 'components/financials/comparative-income-statement/constants';

const stepsStyles = css`
    .ant-steps-item-content > .ant-steps-item-title::after {
        top: 16px !important;
        background-color: ${theme.colors.blues.antBlue};
    }
    .ant-badge {
        .anticon {
            top: 3px;
            border-radius: 50%;
            background-color: ${theme.colors.white};
        }
    }
`;

interface StepsWorkflowAvatarGroupProps {
    roles: {
        userName: string;
        profile_image_url?: string;
        approval_status?: string;
        hierarchy_index?: number;
    }[];
    roleType: string;
    currentReviewIndex?: number;
}

const MAXIMUM_VISIBLES_AVATARS = 4;

const StepsWorkflowAvatarGroup = ({
    roles,
    roleType,
    currentReviewIndex = 0,
}: StepsWorkflowAvatarGroupProps) => {
    const getAvatarBackgroundColor = (role: string, index: number) => {
        if (role === WORKFLOW_ASSIGNEE) {
            return theme.colors.workflowAvatarGroups.assignees[index];
        }
        return theme.colors.workflowAvatarGroups.reviewers[index];
    };
    return (
        <Steps
            className={stepsStyles}
            size="small"
            current={currentReviewIndex}
            items={roles
                .sort((a, b) =>
                    numericalSort(
                        a.hierarchy_index ?? 0,
                        b.hierarchy_index ?? 0
                    )
                )
                .map((user, index) => ({
                    icon: (
                        <Tooltip
                            placement="top"
                            trigger="hover"
                            arrow
                            title={user.userName}
                        >
                            <Badge
                                count={getReviewerApprovalIcon(
                                    user?.approval_status
                                )}
                            >
                                <Avatar
                                    style={{
                                        backgroundColor:
                                            getAvatarBackgroundColor(
                                                roleType,
                                                Math.min(
                                                    index,
                                                    MAXIMUM_VISIBLES_AVATARS
                                                )
                                            ),
                                        fontSize: '12px',
                                    }}
                                    src={user?.profile_image_url}
                                    size={'small'}
                                >
                                    {getAvatarInitials(user.userName ?? '')}
                                </Avatar>
                            </Badge>
                        </Tooltip>
                    ),
                }))}
        />
    );
};

export default StepsWorkflowAvatarGroup;
