import React from 'react';
import { UpOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { css } from 'emotion';

interface CollapseButtonArrowProps {
    isCollapsed: boolean;
    onClick: () => void;
}

export const buttonArrowStyle = css`
    width: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    svg,
    span {
        cursor: pointer;
        transition: transform 0.2s ease-in-out;
    }
`;

const CollapseButtonArrow = ({
    isCollapsed,
    onClick,
}: CollapseButtonArrowProps) => {
    const collapsibleText = isCollapsed
        ? 'Expand Report Options'
        : 'Collapse Report Options';
    return (
        <>
            <Tooltip placement="topRight" title={collapsibleText}>
                <div
                    data-testid="collapse-button-arrow"
                    className={buttonArrowStyle}
                    onClick={onClick}
                >
                    <UpOutlined rotate={isCollapsed ? 180 : 0} />
                </div>
            </Tooltip>
        </>
    );
};

export default CollapseButtonArrow;
