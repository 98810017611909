import { Field } from 'devextreme/ui/pivot_grid/data_source';
import { Dictionary } from 'ts-essentials';
import { CrosstabAccount } from 'components/financials/crosstab/accounts';
import {
    CROSSTAB_DEFAULT_PRIMARY_MODE,
    CROSSTAB_DEFAULT_SECONDARY_MODE,
    CROSSTAB_PRIMARY_MODE,
    CROSSTAB_ROOT_ACCOUNT_DATA_FIELD,
    CROSSTAB_SECONDARY_MODE,
    FIELD_ATTRIBUTE,
    FIELD_PROPERTY,
    MEASURE_FIELD_VARIANCE_$,
} from 'components/financials/crosstab/CrosstabConstants';
import {
    PERIOD_MONTH_FIELD,
    PERIOD_QUARTER_FIELD,
    PERIOD_YEAR_FIELD,
} from 'components/financials/crosstab/CrosstabPeriods';

import { calculateVariancePercentSummary } from './calculateVariancePercentSummary';
import { createCrosstabAccountSorter } from './createCrosstabAccountSorter';
import { ClientMode } from 'waypoint-types';

export const getCrosstabGridFields = (
    rootAccount: CrosstabAccount,
    attributeSelected: string | null,
    isFieldVisible: Dictionary<boolean>,
    modeSelection: string[],
    clientModes: ClientMode[]
): Field[] => {
    const [
        primaryMode = CROSSTAB_DEFAULT_PRIMARY_MODE,
        secondaryMode = CROSSTAB_DEFAULT_SECONDARY_MODE,
    ] = modeSelection.length === 2 ? modeSelection : [];
    let [primaryModeUppercase, secondaryModeUppercase] = [
        primaryMode,
        secondaryMode,
    ].map((name) => name.toUpperCase());
    secondaryModeUppercase =
        secondaryModeUppercase === primaryModeUppercase
            ? `${secondaryModeUppercase}2`
            : secondaryModeUppercase;

    const modeDisplayNames = [primaryMode, secondaryMode].map((mode) => {
        const foundMode = clientModes.find(
            (clientMode) => clientMode.code === mode
        );
        return foundMode ? foundMode.display_name : mode;
    });
    const [primaryModeTitlecase, secondaryModeTitlecase] = modeDisplayNames.map(
        (name) => name.charAt(0).toUpperCase() + name.slice(1)
    );

    const accountNameSorter = createCrosstabAccountSorter(rootAccount);

    return [
        {
            caption: 'Summary Accounts',
            width: 285,
            dataField: CROSSTAB_ROOT_ACCOUNT_DATA_FIELD,
            area: 'row',
            isMeasure: false,
            displayFolder: 'Row Fields',
            allowSortingBySummary: false,
            allowSorting: false,
            sortingMethod: accountNameSorter,
        },
        {
            caption: 'LVL1NAME',
            width: 285,
            dataField: 'LVL1NAME',
            area: 'row',
            allowSortingBySummary: false,
            allowSorting: false,
            isMeasure: false,
            displayFolder: 'Row Fields',
            sortingMethod: accountNameSorter,
        },
        {
            caption: 'LVL2NAME',
            width: 285,
            dataField: 'LVL2NAME',
            area: 'row',
            allowSortingBySummary: false,
            allowSorting: false,
            isMeasure: false,
            displayFolder: 'Row Fields',
            sortingMethod: accountNameSorter,
        },
        {
            caption: 'LVL3NAME',
            width: 285,
            dataField: 'LVL3NAME',
            area: 'row',
            allowSortingBySummary: false,
            allowSorting: false,
            isMeasure: false,
            displayFolder: 'Row Fields',
            sortingMethod: accountNameSorter,
        },
        {
            caption: attributeSelected ?? 'Attribute',
            dataField: FIELD_ATTRIBUTE,
            dataType: 'string',
            area: 'column',
            isMeasure: false,
            visible: isFieldVisible[FIELD_ATTRIBUTE],
            displayFolder: 'Column Fields',
        },
        {
            caption: 'Property',
            dataField: FIELD_PROPERTY,
            dataType: 'string',
            area: 'column',
            isMeasure: false,
            displayFolder: 'Column Fields',
        },
        {
            groupName: 'Period',
            area: 'column',
        },
        {
            ...PERIOD_MONTH_FIELD,
            visible: true,
        },
        {
            ...PERIOD_QUARTER_FIELD,
            visible: true,
        },
        {
            ...PERIOD_YEAR_FIELD,
            visible: true,
        },
        {
            caption: primaryModeTitlecase,
            dataField: CROSSTAB_PRIMARY_MODE,
            dataType: 'number',
            summaryType: 'sum',
            format: {
                type: 'currency',
                precision: 2,
            },
            area: 'data',
            isMeasure: true,
            displayFolder: 'Measures',
            summaryDisplayMode: undefined,
            visible: isFieldVisible[CROSSTAB_PRIMARY_MODE],
        },
        {
            caption: secondaryModeTitlecase,
            dataField: CROSSTAB_SECONDARY_MODE,
            dataType: 'number',
            summaryType: 'sum',
            format: {
                type: 'currency',
                precision: 2,
            },
            area: 'data',
            isMeasure: true,
            displayFolder: 'Measures',
            summaryDisplayMode: undefined,
            visible: isFieldVisible[CROSSTAB_SECONDARY_MODE],
        },
        {
            caption: 'Variance $',
            dataType: 'number',
            dataField: MEASURE_FIELD_VARIANCE_$,
            summaryType: 'sum',
            format: {
                type: 'currency',
                precision: 2,
            },
            area: 'data',
            isMeasure: true,
            displayFolder: 'Measures',
            visible: isFieldVisible[MEASURE_FIELD_VARIANCE_$],
        },
        {
            caption: 'Variance %',
            dataType: 'number',
            // synthetic value used for internal PivotGrid state persistence
            dataField: '_variance_percent',
            calculateSummaryValue: calculateVariancePercentSummary,
            allowCrossGroupCalculation: true,
            format: {
                type: 'percent',
                precision: 2,
            },
            area: 'data',
            isMeasure: true,
            displayFolder: 'Measures',
            visible: isFieldVisible['_variance_percent'],
        },
    ];
};
