import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'waypoint-react';
import { currencyRenderer } from 'utils/tables/renderers';
import { numericalSort, stringSort } from 'utils/tables/sorters';

export const breakdownColumns = [
    {
        title: 'Account',
        dataIndex: 'account_mapping_name',
        type: 'text',
        sorter: (a, b) =>
            stringSort(a.account_mapping_name, b.account_mapping_name),
        ellipsis: true,
    },
    {
        title: 'Actual',
        align: 'right',
        dataIndex: 'actual_amount',
        type: 'currency',
        sorter: (a, b) => numericalSort(a.actual_amount, b.actual_amount),
        render: currencyRenderer,
    },
];

const BreakdownTable = ({ data, displayType, height = 215 }) => {
    return (
        <Table
            data={data}
            columns={breakdownColumns}
            height={height}
            size="small"
            data-testid="component-breakdown-table"
        />
    );
};

BreakdownTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    height: PropTypes.number,
};

export default BreakdownTable;
