import { RecurringChargeSelect } from 'components/leases/components/recurring-charge/RecurringChargeSelect';
import React, { useCallback, useState } from 'react';
import { useRecurringCharges } from 'waypoint-hooks';
import { ReportWidgetSettingsBuilderProps } from 'waypoint-types';
import ReportWidgetSettingsModal from 'components/reports/report-widget/ReportWidgetSettingsModal';
import { DisabledDashboard } from 'waypoint-react';

const UnitMixSettingsBuilder = ({
    entityCodes,
    isModalOpen,
    setIsModalOpen,
    onSaveWidgetSettings,
    widgetType,
    currentSettings,
}: ReportWidgetSettingsBuilderProps) => {
    const [selectedChargeCode, setSelectedChargeCode] = useState<string[]>(
        currentSettings?.widgetSelectedChargeCode ?? []
    );

    const { data: charges, isLoading } = useRecurringCharges(entityCodes, {
        stakeholder: null,
        percentageType: null,
    });

    const buildReportWidgetSettingsInputs = () => {
        return {
            widgetSelectedChargeCode: selectedChargeCode,
        };
    };

    const onChange = useCallback(
        (value: string[]) => {
            setSelectedChargeCode(value);
        },
        [setSelectedChargeCode]
    );

    if (!charges) {
        return <DisabledDashboard text={'Loading'} />;
    }

    const { summary } = charges;

    return (
        <ReportWidgetSettingsModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            settingsJsonBuilder={buildReportWidgetSettingsInputs}
            onSaveWidgetSettings={onSaveWidgetSettings}
            widgetType={widgetType}
        >
            <div style={{ marginBottom: '10px' }}>
                <div>Selected Charge:</div>
                <>
                    <RecurringChargeSelect
                        recurringCharges={summary.buckets}
                        selectedChargeCodes={selectedChargeCode}
                        onChange={onChange}
                        style={{
                            width: '100%',
                            minWidth: '200px',
                            marginTop: 12,
                        }}
                        loading={isLoading}
                    />
                </>
            </div>
        </ReportWidgetSettingsModal>
    );
};

export default UnitMixSettingsBuilder;
