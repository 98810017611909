import { API_URL } from 'config/constants';

/**
 *
 * @param {Object} params
 * @param {array} params.entityCodes - Entity codes
 * @param {boolean} params.isGroup - Defines if request is meant for Entity or Entity-Group
 * @param {string} params.accountMappingCode
 * @param {string} params.startDate - ISO Date (YYYY-MM-DD)
 * @param {string} params.endDate - ISO Date (YYYY-MM-DD)
 */
const getBalanceSheetOverviewTotal = async (params) => {
    const {
        entityCodes,
        selectedDataLevel,
        account_mapping_code,
        start_date,
        end_date,
        display_type,
    } = params;
    const response = await fetch(`${API_URL}/balance-sheet-overview/total`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
            entity_codes: entityCodes,
            account_mapping_code,
            start_date,
            end_date,
            display_type: display_type || 'gross',
            selected_data_level: selectedDataLevel,
        }),
    });
    if (!response.ok) {
        throw new Error('FAILED');
    }
    const { data } = await response.json();
    return data;
};

export default getBalanceSheetOverviewTotal;
