import React from 'react';
import DataGrid, {
    Column,
    GroupItem,
    GroupPanel,
    Grouping,
    HeaderFilter,
    Paging,
    Scrolling,
    SearchPanel,
    Selection,
    Summary,
    TotalItem,
    DataGridRef,
} from 'devextreme-react/data-grid';
import { IDataGrid, ReportGridType } from 'waypoint-types';
import { Dictionary } from 'ts-essentials';
import {
    renderDueDate,
    renderPercentage,
    renderReportStatus,
} from 'components/reports/ReportUtils';
import ReportEmptyState from './ReportEmptyState';
import { useDataGridHeight } from 'waypoint-hooks';

interface EntityReportsDeletedProps {
    deletedReports: ReportGridType[];
    setDeletedReportsMasterDetailGrid: (
        ref: DataGridRef<ReportGridType, any> | null,
    ) => void;
    onSelectionChanged: (data: Dictionary<ReportGridType[]>) => void;
    isAdmin: boolean;
}

const EntityReportsDeleted = ({
    setDeletedReportsMasterDetailGrid,
    deletedReports,
    onSelectionChanged,
    isAdmin,
}: EntityReportsDeletedProps) => {
    const dataGridHeight = useDataGridHeight({
        cardTitleHeight: 44,
        cardOuterPadding: 24,
        cardInnerPadding: 24,
        topBarHeight: 150, // Included the height of the banner
    });

    if (!deletedReports.length) {
        return (
            <ReportEmptyState
                text="
            No deleted reports found.
            "
            />
        );
    }

    return (
        <div>
            <DataGrid
                ref={setDeletedReportsMasterDetailGrid}
                dataSource={deletedReports}
                height={dataGridHeight}
                allowColumnReordering={false}
                showBorders={true}
                wordWrapEnabled={false}
                hoverStateEnabled={true}
                noDataText="No reports found"
                onSelectionChanged={onSelectionChanged as any}
                columnMinWidth={100}
            >
                {isAdmin ? (
                    <Selection
                        mode="multiple"
                        showCheckBoxesMode={isAdmin ? 'always' : 'none'}
                    />
                ) : null}
                <Scrolling mode="virtual" />
                <HeaderFilter visible={true} allowSearch={true} height={250} />
                <Column
                    caption="Report Name"
                    dataField="report_name"
                    dataType="string"
                    allowFiltering={true}
                    width={'auto'}
                />
                <Column
                    dataField={'report_status'}
                    caption="Status"
                    cellRender={renderReportStatus}
                    alignment="center"
                    allowFiltering={true}
                />
                <Column
                    caption="Properties Assigned"
                    dataField="properties"
                    alignment="center"
                    allowFiltering={false}
                />
                <Column
                    caption="Due Date"
                    dataField="due_date"
                    alignment="center"
                    cellRender={renderDueDate}
                    allowFiltering={false}
                />
                <Column
                    caption="Complete"
                    dataField="complete"
                    dataType="number"
                    alignment="center"
                    allowFiltering={false}
                />
                <Column
                    caption="Complete %"
                    dataField="complete_percentage"
                    dataType="number"
                    alignment="center"
                    cellRender={renderPercentage}
                    allowFiltering={false}
                />
                <Column
                    caption="Created by"
                    dataField="createdByUser"
                    dataType="string"
                    allowFiltering={false}
                />
                <Column
                    dataField="updatedByUser"
                    caption={'Last Updated By'}
                    alignment={'center'}
                    width={'auto'}
                    allowFiltering={false}
                />
                <Summary>
                    <TotalItem
                        name="report_name"
                        column="report_name"
                        displayFormat="{0} Deleted Reports"
                    ></TotalItem>
                    <GroupItem
                        name="report_name"
                        column="report_name"
                        summaryType="count"
                        displayFormat="{0} Deleted Reports"
                        alignByColumn
                    />
                </Summary>
                <Paging enabled={false} />
                <Scrolling />
                <SearchPanel
                    visible={true}
                    highlightCaseSensitive={false}
                    width={250}
                />
                <Grouping autoExpandAll={false} />
                <GroupPanel visible={true} />
            </DataGrid>
        </div>
    );
};

export default EntityReportsDeleted;
