import {
    AddItemButtonProps,
    EntityProfileCardsAddItemButton,
} from 'waypoint-react/entity-profile-cards/EntityProfileCards';
import { Empty } from 'antd';
import React from 'react';

interface EntityProfileCardsEmptyStateProps {
    title: string;
    addItemButton?: AddItemButtonProps;
    onAddItemClicked?: () => void;
    isPDFExport?: boolean;
}

export const EntityProfileCardsEmptyState = ({
    title,
    addItemButton,
    onAddItemClicked,
    isPDFExport,
}: EntityProfileCardsEmptyStateProps): JSX.Element => {
    return (
        <div style={{ margin: '10px' }}>
            {!isPDFExport && (
                <Empty
                    imageStyle={{
                        height: 0,
                    }}
                    description={
                        <span>You haven't added any {title} yet!</span>
                    }
                >
                    {addItemButton && (
                        <EntityProfileCardsAddItemButton
                            onAddItemClicked={onAddItemClicked}
                            addItemButton={addItemButton}
                            isPDFExport={isPDFExport}
                        />
                    )}
                </Empty>
            )}
        </div>
    );
};
