import React from 'react';
import DataGrid, {
    Column,
    DataGridRef,
    Format,
} from 'devextreme-react/data-grid';
import { LeasingPlan, LeasingPlanActivity } from 'waypoint-types/leasing-plans';
import { safeDivision } from 'waypoint-utils';
import { formatNumber, formatPercentage } from 'utils/formatters';
import { RENEWING } from './LeasingPlanUtils';
import { LeasingPlanExportableGrid } from 'components/reports/components/entity-report-widgets/report-widget-export-grids/LeasingPlanExportableGrid';

interface LeasingPlanTableProps {
    leasingPlan: LeasingPlan | undefined;
    activities: LeasingPlanActivity[] | undefined;
    newLeases: LeasingPlanActivity[] | undefined;
    dataGridRef?: React.RefObject<DataGridRef<any, any>>;
    isPDFExport?: boolean;
}

export const LeasingPlanTable = ({
    leasingPlan,
    activities,
    newLeases,
    dataGridRef,
    isPDFExport,
}: LeasingPlanTableProps) => {
    const buildTableData = () => {
        if (!leasingPlan) {
            return [];
        }

        const planYear = leasingPlan.year;
        const rentableSF = leasingPlan.rentable_sf;

        const renewalsSF =
            activities
                ?.filter(
                    (a) =>
                        a.activity_type === null &&
                        a.expectation === RENEWING &&
                        a.lease_expiration_date &&
                        new Date(a.lease_expiration_date).getFullYear() ===
                            planYear,
                )
                .reduce((total, item) => {
                    return total + item.rentable_sf;
                }, 0) ?? 0;

        const newLeasesSF =
            newLeases
                ?.filter(
                    (a) =>
                        a.activity_type !== null &&
                        a.commencement_date &&
                        new Date(a.commencement_date).getFullYear() ===
                            planYear,
                )
                .reduce((total, item) => {
                    return total + item.rentable_sf;
                }, 0) ?? 0;

        const absorption = renewalsSF + newLeasesSF - leasingPlan.expiring_sf;
        const yearEndOccupancy = absorption + leasingPlan.starting_occupancy_sf;

        const displaySqFt = (sqFt: number) => {
            // display negative amounts in parentheses
            return sqFt < 0 ? `(${formatNumber(-sqFt)})` : formatNumber(sqFt);
        };

        const displayPercentage = (pct: number) => {
            // display negative amounts in parentheses
            const percentOfRSF = safeDivision(pct, rentableSF) * 100;
            return percentOfRSF < 0
                ? `(${formatPercentage(-percentOfRSF)})`
                : formatPercentage(percentOfRSF);
        };

        const tableData = [
            {
                leasing_activity: 'Starting Occupancy',
                sq_ft: displaySqFt(leasingPlan.starting_occupancy_sf),
                rentable_sq_ft: formatNumber(rentableSF),
                percent_of_total: displayPercentage(
                    leasingPlan.starting_occupancy_sf,
                ),
            },
            {
                leasing_activity: 'Expiring SF',
                sq_ft: displaySqFt(-leasingPlan.expiring_sf), // this is intended to always display as a negative number
                rentable_sq_ft: '',
                percent_of_total: displayPercentage(-leasingPlan.expiring_sf),
            },
            {
                leasing_activity: 'Renewals',
                sq_ft: displaySqFt(renewalsSF),
                rentable_sq_ft: '',
                percent_of_total: displayPercentage(renewalsSF),
            },
            {
                leasing_activity: 'New Leases',
                sq_ft: displaySqFt(newLeasesSF),
                rentable_sq_ft: '',
                percent_of_total: displayPercentage(newLeasesSF),
            },
            {
                leasing_activity: 'Absorption',
                sq_ft: displaySqFt(absorption),
                rentable_sq_ft: '',
                percent_of_total: displayPercentage(absorption),
            },
            {
                leasing_activity: 'Year End Occupancy',
                sq_ft: displaySqFt(yearEndOccupancy),
                rentable_sq_ft: formatNumber(rentableSF),
                percent_of_total: displayPercentage(yearEndOccupancy),
            },
        ];

        return tableData;
    };

    if (isPDFExport) {
        return <LeasingPlanExportableGrid data={buildTableData()} />;
    }

    return (
        <DataGrid
            id="leasing-plan-table"
            ref={dataGridRef}
            style={{ height: '177px', overflowY: 'none' }}
            dataSource={buildTableData()}
            showBorders={true}
            scrolling={{ showScrollbar: 'never' }}
            allowColumnReordering={false}
            sorting={{ mode: 'none' }}
            width={'500px'}
            hoverStateEnabled={true}
        >
            <Column
                dataField="leasing_activity"
                caption="Leasing Activity"
                width={'150px'}
                alignment={'center'}
            />
            <Column
                dataField="sq_ft"
                caption="Sq. Ft."
                width={'120px'}
                alignment={'center'}
            />
            <Column
                dataField="rentable_sq_ft"
                caption="Rentable Sq. Ft."
                width={'120px'}
                alignment={'center'}
            />
            <Column
                dataField="percent_of_total"
                caption="%"
                width={'80px'}
                alignment={'center'}
            >
                <Format type="percent" precision={1} />
            </Column>
        </DataGrid>
    );
};
