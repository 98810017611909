import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import defaultStylePP from './defaultStylePP';
import DefaultCellRenderer from './DefaultCellRenderer';

export const LinkCell = ({ cellData, ...rest }) => {
    const { text, link, onClick, disabled } = cellData;
    if (disabled) {
        return <DefaultCellRenderer cellData={text} {...rest} />;
    }

    return (
        <Link to={link} onClick={onClick}>
            <DefaultCellRenderer cellData={text} {...rest} />
        </Link>
    );
};

LinkCell.propTypes = {
    style: PropTypes.object,
    cellData: PropTypes.object,
};

export const LinkCellRenderer = defaultStylePP(LinkCell);
export default LinkCellRenderer;
