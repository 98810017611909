import React from 'react';
import { css } from 'emotion';
import { CommentsBox } from 'waypoint-react';
import {
    createComment,
    deleteComment,
    resolveComment,
    updateComment,
} from 'waypoint-requests';
import * as Sentry from '@sentry/react';
import UserBubble from 'components/style/UserBubble';
import theme from 'config/theme';
import {
    CommentData,
    CommentMention,
    CommentThreadData,
    LocalCommentData,
} from 'waypoint-types';
import { Button, message, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useCommentsPane } from 'contexts/comments/CommentsContext';
import { useLocation } from 'react-router-dom';
import { VARIANCE_REPORTING_URL } from 'components/financials/variance-report-status/constants';
import { monthDisplayNames } from 'utils/consts';
import { RouteUrls } from 'routes/RouteConstants';
import { PropertyProfileCommentPaneSubheading } from './CommentsUtils';

const drawerStyle = css`
    .ant-tabs-bar {
        margin: 0;
        padding: 0 24px;
    }

    .ant-tabs-tab {
        font: 16px ${theme.fonts.heading};
    }
`;

export const CommentsPane = ({ showCloseIcon = true }): JSX.Element => {
    const {
        closeComments,
        isLoading,
        isError,
        commentThread,
        mutateCommentThread,
        heading,
        subheading,
        financialYearEnding,
        accountFilterSelection,
    } = useCommentsPane();

    const location = useLocation();
    const url = `${location.pathname}${location.search}`;

    const handleCommentDelete = async (commentId: string) => {
        if (!commentThread) {
            return;
        }

        const errorMessage = 'There was an error deleting your comment.';

        const filteredComments = commentThread.comments.filter(
            (comment) => comment.id !== commentId
        );
        const updatedCommentThread: CommentThreadData = {
            ...commentThread,
            comments: filteredComments,
        };

        await mutateCommentThread(
            async () => {
                try {
                    const response = await deleteComment(commentId);

                    if (response.status !== 200) {
                        handleError(errorMessage);
                    }
                } catch (e) {
                    handleError(errorMessage);
                    throw e;
                }

                return updatedCommentThread;
            },
            {
                optimisticData: updatedCommentThread,
                rollbackOnError: true,
            }
        );
    };

    const decorateComments = (comments: CommentData[]): LocalCommentData[] => {
        return comments.map((comment) => {
            return {
                ...comment,
                name: `${comment.author.firstname} ${comment.author.lastname}`,
                avatar: <UserBubble userId={Number(comment.user_id)} />,
                onClickDelete: handleCommentDelete,
                replies: comment.replies.map((reply) => ({
                    ...reply,
                    name: `${reply.author.firstname} ${reply.author.lastname}`,
                    avatar: <UserBubble userId={Number(reply.user_id)} />,
                    onClickDelete: handleCommentDelete,
                })),
            };
        });
    };

    const handleError = (text: string) => {
        Sentry.captureException(text);
        message.error(text);
    };

    const notificationRedirectURL = (): string | undefined => {
        if (url.includes(VARIANCE_REPORTING_URL)) {
            return url;
        }

        const isCommentingInPropertyProfileCommentPane =
            url.includes(RouteUrls.PROPERTY_PROFILE_DETAILS) &&
            subheading === PropertyProfileCommentPaneSubheading;

        if (isCommentingInPropertyProfileCommentPane) {
            const strippedUrl = url.split('&')[0];
            return `${strippedUrl}&subsection=property-info&section=overview`;
        }

        return;
    };

    const updateExistingComment = async (
        message: string,
        userMentions: number[],
        accountMentions: string[],
        parent_comment_id: string | null,
        comment_id: string
    ) => {
        if (!commentThread) {
            return;
        }
        const editingCommentId = parent_comment_id ?? comment_id;

        const newUserMentions: CommentMention[] = userMentions.map(
            (mention) => {
                return {
                    id: '',
                    comment_id,
                    reference_type: 'user',
                    reference_id: mention.toString(),
                };
            }
        );

        const newAccountMentions: CommentMention[] = accountMentions.map(
            (mention) => {
                return {
                    id: '',
                    comment_id,
                    reference_type: 'user',
                    reference_id: mention,
                };
            }
        );

        const mentions = [...newUserMentions, ...newAccountMentions];
        const existingComment = commentThread?.comments.find(
            (comment) => comment.id === editingCommentId
        );

        if (!existingComment) {
            return;
        }

        const replyThread = parent_comment_id
            ? existingComment.replies.find(
                  (comment) => comment.id === comment_id
              )
            : null;

        const updatedReply = {
            ...replyThread,
            text: message,
            mentions,
        };

        const updatedComment: CommentData = replyThread
            ? {
                  ...existingComment,
                  replies: [
                      ...existingComment.replies.filter(
                          (comment) => comment.id !== comment_id
                      ),
                      updatedReply as LocalCommentData,
                  ],
              }
            : {
                  ...existingComment,
                  text: message,
                  mentions,
              };

        const updatedThread = {
            ...commentThread,
            comments: [
                ...commentThread.comments.filter(
                    (c) => c.id !== editingCommentId
                ),
                updatedComment,
            ],
        };

        await mutateCommentThread(
            async () => {
                await updateComment(
                    comment_id,
                    message,
                    commentThread.id,
                    userMentions,
                    accountMentions,
                    notificationRedirectURL(),
                    `${heading} - ${subheading}`
                );

                return {
                    ...commentThread,
                    comments: [
                        ...commentThread.comments.filter(
                            (c) => c.id !== comment_id
                        ),
                        updatedComment,
                    ],
                };
            },
            {
                optimisticData: updatedThread,
                rollbackOnError: true,
                revalidate: true,
            }
        );
    };

    const createNewComment = async (
        message: string,
        userMentions: number[],
        accountMentions: string[],
        parent_comment_id: string | null
    ) => {
        if (!commentThread) {
            return;
        }

        await mutateCommentThread(async () => {
            const newComment = await createComment(
                commentThread.id,
                message,
                parent_comment_id,
                userMentions,
                accountMentions,
                notificationRedirectURL(),
                `${heading} - ${subheading}`
            );

            return {
                ...commentThread,
                comments: [...commentThread.comments, newComment],
            };
        });
    };

    const handleFormSubmit = async (
        message: string,
        userMentions: number[],
        accountMentions: string[],
        parent_comment_id: string | null,
        comment_id: string | null
    ) => {
        if (!commentThread) {
            return;
        }

        if (comment_id) {
            return updateExistingComment(
                message,
                userMentions,
                accountMentions,
                parent_comment_id,
                comment_id
            );
        }

        return createNewComment(
            message,
            userMentions,
            accountMentions,
            parent_comment_id
        );
    };

    const handleResolveComment = async (
        commentThreadId: string,
        commentId: string,
        is_resolved: boolean
    ) => {
        if (!commentThread) {
            return;
        }

        const errorMessage = 'Could not resolve comment';
        const commentToUpdate = commentThread.comments.find(
            (c) => c.id === commentId
        );

        if (!commentToUpdate) {
            return {
                ...commentThread,
            };
        }

        const updatedComment = {
            ...commentToUpdate,
            is_resolved,
        };

        const optimisticData = {
            ...commentThread,
            comments: [
                ...commentThread.comments.filter((c) => c.id !== commentId),
                updatedComment,
            ],
        };

        await mutateCommentThread(
            async () => {
                try {
                    const response = await resolveComment(
                        commentThreadId,
                        commentId,
                        is_resolved
                    );
                    const { success } = await response.json();

                    if (!success) {
                        handleError(errorMessage);
                    }
                } catch (e) {
                    handleError(errorMessage);
                }

                return optimisticData;
            },
            {
                optimisticData,
                rollbackOnError: true,
            }
        );
    };

    const renderFinancialYearEnding = () => {
        if (!financialYearEnding) {
            return '';
        }

        const financialYearEndParts = financialYearEnding?.split('/');
        const forWording =
            financialYearEndParts[0] === '12'
                ? 'For Calendar Year Ending'
                : 'For Fiscal Year Ending';
        return financialYearEnding
            ? `${forWording} ${
                  monthDisplayNames[Number(financialYearEndParts[0]) - 1]
              } ${financialYearEndParts[1]}`
            : '';
    };

    if (isError) {
        return <p>Comments are not available.</p>;
    }

    const headerText = (
        <div style={{ marginLeft: '10px', marginTop: '10px' }}>
            <div style={{ fontSize: 15 }}>
                <b>{heading}</b>
            </div>{' '}
            <div style={{ fontSize: 14 }}>{subheading}</div>
            <span style={{ fontSize: 13, fontWeight: 'bold' }}>
                {renderFinancialYearEnding()}
            </span>
            {showCloseIcon && (
                <Tooltip title="Close">
                    <Button
                        style={{
                            position: 'absolute',
                            top: '5px',
                            right: '5px',
                        }}
                        type="text"
                        icon={<CloseOutlined />}
                        onClick={() => {
                            closeComments();
                        }}
                    />
                </Tooltip>
            )}
        </div>
    );

    return (
        <div
            className={drawerStyle}
            style={{ backgroundColor: theme.colors.white, overflowY: 'hidden' }}
        >
            {headerText}

            <CommentsBox
                loading={isLoading}
                onSubmit={handleFormSubmit}
                comments={
                    commentThread
                        ? decorateComments(commentThread.comments)
                        : []
                }
                onResolveComment={handleResolveComment}
                accountFilterSelection={accountFilterSelection}
            />
        </div>
    );
};
