import { CrosstabAccount } from '../accounts';
import { Dictionary } from 'ts-essentials';

type Node = { children?: any[]; value?: string | number };
type AccountSorter = (a: Node, b: Node) => number;

/**
 * Creates an account name sorted based on the order of the accounts provided in sortOrder
 * @param rootAccount Root crosstab account with metadata
 */
export const createCrosstabAccountSorter = (
    rootAccount: CrosstabAccount
): AccountSorter => {
    const sortOrder: Dictionary<number> = {};

    const traverseAccounts = (account: CrosstabAccount) => {
        const meta = account.meta;
        if (typeof meta?.sort_order === 'number') {
            sortOrder[meta.account_mapping_code] = meta.sort_order;
        }

        if (account.children) {
            account.children.forEach(traverseAccounts);
        }
    };

    traverseAccounts(rootAccount);

    const maxSortOrder = Math.max(...Object.values(sortOrder));
    return (a: Node, b: Node) => {
        if (typeof a.value !== 'string' || typeof b.value !== 'string') {
            return -1;
        }
        const indexA = sortOrder[a.value] ?? maxSortOrder + 1;
        const indexB = sortOrder[b.value] ?? maxSortOrder + 1;

        if (indexA < indexB) return -1;
        else if (indexA > indexB) return 1;
        else return 0;
    };
};
