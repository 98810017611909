import PropTypes from 'prop-types';
import React from 'react';
import defaultStylePP from './defaultStylePP';
import { getCellStyling } from 'components/tables/utils';
import './CheckBox.module.css';

// TODO(Colby): Implement this across all cells. Right now,
//              this is only implemented on default cell.
const ellipsisStyle = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
};

const italicStyle = {
    color: '#aaa',
    fontStyle: 'italic',
};

// This is the simplest possible cell renderer that works
// NOTE (Daniel): to avoid adding styles to an item when they're not
// necessary, we pass an empty object as a default to getCellStyling
// which should return a font weight of 400 for anything that's not a CF or RTAG on Variance
export const CheckBoxCell = ({
    cellData = {},
    color,
    selectedColumn,
    ...rest
}) => {
    const { checked, text, handleOnCheck, handleOnClickTitle } = cellData;

    const getItalicStyle = () => {
        if (checked) return italicStyle;
        return {};
    };

    return (
        <div {...rest}>
            <input
                type="checkbox"
                checked={!!checked}
                onChange={handleOnCheck}
            />
            {!checked && (
                <a>
                    <span
                        style={{
                            ...ellipsisStyle,
                            ...getCellStyling(selectedColumn ? { color } : {}),
                            ...getItalicStyle(),
                        }}
                        onClick={handleOnClickTitle}
                    >
                        {text}
                    </span>
                </a>
            )}
            {checked && (
                <span
                    style={{
                        ...ellipsisStyle,
                        ...getCellStyling(selectedColumn ? { color } : {}),
                        ...getItalicStyle(),
                    }}
                    onClick={handleOnClickTitle}
                >
                    {text}
                </span>
            )}
        </div>
    );
};

CheckBoxCell.propTypes = {
    cellData: PropTypes.any,
    color: PropTypes.string,
    selectedColumn: PropTypes.bool,
};

export const CheckBoxCellRenderer = defaultStylePP(CheckBoxCell);

export default CheckBoxCellRenderer;
