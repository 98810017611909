import Cookies from 'js-cookie';
import { API_URL } from 'config/constants';

export function clearCookie(cookie) {
    let cookieString = cookie + '=';
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() - 864000 * 1000);
    cookieString += ';max-age=0';
    cookieString += ';expires=' + expiryDate.toUTCString();
    document.cookie = cookieString;
}

export function getClientID() {
    // type convert to int
    return Number.parseInt(Cookies.get('CLIENT_ID_COOKIE'));
}

export const setClientCookie = (clientID) => {
    const oldClientID = getClientID();

    const p = Promise.all([
        fetch(`${API_URL}/client/set-cookie/${clientID}`, {
            method: 'GET',
            credentials: 'include',
        }),
    ]);
    if (oldClientID > 0 && clientID !== oldClientID) {
        // Set cookie and force logout, user has multiple clients
        window.sessionStorage.clear();
        p.then(() => {
            // Wipe cache to avoid session storage size limits
            window.location.replace(window.location.origin);
        });
    }
    return p;
};
