import { css } from 'emotion';
import React from 'react';
import ResponsiveBox, {
    Col,
    Item,
    Location,
    Row,
} from 'devextreme-react/responsive-box';
import { useGetSelectedFilteredEntityCodes } from 'waypoint-hooks';
import { ContractsContainer } from 'components/contracts/ContractsContainer';
import { TypeOfContractsView } from 'components/contracts/interfaces';
import { SavedConfiguration } from 'waypoint-types';
import { AppFeaturePermissions } from 'contexts';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';

const fixDxItemHeightStyle = css`
    .dx-item.dx-box-item {
        min-height: 640px;
        height: 100%;
        background-color: #fff;
    }
`;

interface PlanningServiceContractsContainerProps {
    hideRangePickerAndViews?: boolean;
    savedConfigData?: SavedConfiguration | null;
}

const PlanningServiceContractsContainer = ({
    savedConfigData,
}: PlanningServiceContractsContainerProps) => {
    const entityCodes: string[] = useGetSelectedFilteredEntityCodes();

    return (
        <PermissionedWrapper
            featureKey={AppFeaturePermissions.Contracts}
            showDisabledView={true}
        >
            <div
                className={fixDxItemHeightStyle}
                style={{
                    marginLeft: '10px',
                    marginTop: '10px',
                    marginRight: '10px',
                    overflowY: 'auto',
                }}
            >
                <ResponsiveBox>
                    <Row />
                    <Col />
                    <Item>
                        <Location row={0} col={0} colspan={1} />
                        <ContractsContainer
                            entityCodes={entityCodes}
                            typeOfView={TypeOfContractsView.MultipleProperties}
                            savedConfigData={savedConfigData}
                        />
                    </Item>
                </ResponsiveBox>
            </div>
        </PermissionedWrapper>
    );
};

export default PlanningServiceContractsContainer;
