import PropTypes from 'prop-types';
import React from 'react';
import defaultStylePP from './defaultStylePP';

export const AdminLinkCell = ({ cellData, ...rest }) => {
    const { status, linkText, clickHandler } = cellData;
    return (
        <div {...rest}>
            {status}
            &emsp;
            <a style={{ textDecoration: 'underline' }} onClick={clickHandler}>
                {linkText}
            </a>
        </div>
    );
};

AdminLinkCell.propTypes = {
    cellData: PropTypes.string,
};

export const AdminLinkCellRenderer = defaultStylePP(AdminLinkCell);
export default AdminLinkCellRenderer;
