import React from 'react';
import { Button } from 'antd';
import { DeleteOutlined, UndoOutlined } from '@ant-design/icons';

interface BottomMenuProps {
    batchValues: any;
    onCancel: () => void;
    onRestore: () => void;
    onDeletePermanently: () => void;
}

const DeleteBottomMenu = ({
    batchValues,
    onDeletePermanently,
    onRestore,
    onCancel,
}: BottomMenuProps) => {
    return (
        <>
            <div>
                <span
                    style={{
                        marginLeft: '18px',
                        fontSize: '14px',
                        paddingRight: '18px',
                    }}
                >
                    {batchValues.selectedReports.length} Report
                    {batchValues.selectedReports.length > 1 ? 's' : ''} Selected
                </span>
                <Button type="primary" onClick={onRestore}>
                    <UndoOutlined />
                    Restore
                </Button>
                <Button
                    style={{
                        marginLeft: 18,
                    }}
                    onClick={onDeletePermanently}
                    danger
                >
                    <DeleteOutlined />
                    Delete Permanently
                </Button>
            </div>
            <div>
                <Button
                    style={{
                        float: 'right',
                        marginRight: 18,
                    }}
                    onClick={onCancel}
                >
                    Cancel
                </Button>
            </div>
        </>
    );
};

export default DeleteBottomMenu;
