import { API_URL } from 'config/constants';
import { EntityProfile } from 'waypoint-types/properties/types';

const getEntityProfile = async (entity_code: string) => {
    const response = await fetch(
        `${API_URL}/properties/${entity_code}/entity-profile`,
        {
            credentials: 'include',
        }
    );

    if (!response.ok) {
        throw new Error('Failed');
    }

    const { data } = await response.json();
    return data as EntityProfile;
};

export default getEntityProfile;
