import { API_URL } from 'config/constants';
import { EntityProfileCard } from 'waypoint-types/properties';

interface UpdateEntityProfileCardIndexesDto {
    sorted_entity_profile_card_ids: string[];
}

export async function updateEntityProfileCardSortIndexes(
    entityCode: string,
    body: UpdateEntityProfileCardIndexesDto
) {
    const response = await fetch(
        `${API_URL}/properties/${entityCode}/profile-cards/sort-indexes`,
        {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(body),
        }
    );

    if (!response.ok) {
        throw new Error('Failed to update profile card indexes');
    }

    const { data } = await response.json();

    return data as EntityProfileCard;
}
