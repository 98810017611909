import PropTypes from 'prop-types';
import React from 'react';
import { omit } from 'lodash';
import { cx } from 'emotion';
import { legendContainerStyle } from './styles';

const Legend = (props) => {
    const { className } = props;
    return (
        <div
            className={cx([legendContainerStyle, className])}
            {...omit(props, 'children')}
        >
            {props.children}
        </div>
    );
};

Legend.propTypes = {
    children: PropTypes.any,
    isDisabled: PropTypes.bool,
};

export default Legend;
