import { API_URL } from 'config/constants';

interface UpdateOrInsertPropertyValuationInputs {
    entity_code: string;
    valuation_id: string;
    period: string;
}

export const updateOrInsertPropertyValuation = async ({
    entity_code,
    valuation_id,
    period,
}: UpdateOrInsertPropertyValuationInputs) => {
    const response = await fetch(`${API_URL}/properties/valuation`, {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ valuation_id, entity_code, period }),
    });

    if (!response.ok) {
        throw new Error('Failed');
    }

    const data = await response.json();
    return data;
};
