import React from 'react';
import { Descriptions, Row as AntRow } from 'antd';
import { DASH_DASH } from 'config/constants';
import { getFormattedLabel } from '../PropertyProfileUtils';
import { PropertyInformationSectionProps } from '../types';
import { usePropertyInformation } from 'waypoint-hooks';

const labelStyle = {
    width: 200,
    paddingLeft: 20,
};

const PropertyTeam = ({
    propertyInfo,
    entityCode,
    selectedDataLevel,
}: PropertyInformationSectionProps) => {
    const {
        selectedPortfolioManager,
        selectedAssetManager,
        selectedAnalyst,
        selectedPropertyManager,
        selectedManagementCompany,
    } = usePropertyInformation({
        entityCode,
        selectedDataLevel,
        propertyInfo,
    });

    const items = [
        {
            label: 'Portfolio Manager',
            value: selectedPortfolioManager
                ? selectedPortfolioManager.join(', ')
                : DASH_DASH,
            span: 1,
        },
        {
            label: 'Management Company',
            value: selectedManagementCompany
                ? selectedManagementCompany.join(', ')
                : DASH_DASH,
            span: 1,
        },
        {
            label: 'Asset Manager',
            value: selectedAssetManager
                ? selectedAssetManager.join(', ')
                : DASH_DASH,
            span: 1,
        },
        {
            label: 'Property Manager',
            value: selectedPropertyManager
                ? selectedPropertyManager.join(', ')
                : DASH_DASH,
            span: 1,
        },
        {
            label: 'Analyst',
            value: selectedAnalyst ? selectedAnalyst.join(', ') : DASH_DASH,
            span: 1,
        },
    ];

    return (
        <AntRow align="stretch" wrap={true}>
            <Descriptions
                size="small"
                title={
                    <h2>
                        <div
                            style={{
                                padding: '4px 0px',
                                borderBottom: '2px solid #001628',
                            }}
                        >
                            Team
                        </div>
                    </h2>
                }
                bordered={false}
                layout="horizontal"
                column={{
                    xxl: 2,
                    xl: 2,
                    lg: 2,
                    md: 2,
                    sm: 1,
                    xs: 1,
                }}
                contentStyle={{
                    textAlign: 'left',
                    marginRight: 15,
                }}
                labelStyle={labelStyle}
            >
                {items.map((item, index) => (
                    <Descriptions.Item
                        key={index}
                        label={getFormattedLabel(item.label)}
                        span={item.span}
                    >
                        {item.value}
                    </Descriptions.Item>
                ))}
            </Descriptions>
        </AntRow>
    );
};

export default PropertyTeam;
