import PropTypes from 'prop-types';
import React from 'react';
import { DARK_GRAY } from 'config/colors';
import Radio from 'components/style/Radio';

const spanStyle = {
    flex: 1,
    textAlign: 'right',
    paddingRight: '10px',
};

const containerStyle = {
    padding: '4em 1em',
    border: `1px solid ${DARK_GRAY}`,
    borderRadius: '3px',
    flex: '1',
    margin: '1em 0.5em',
    cursor: 'pointer',
    display: 'flex',
    fontWeight: 'bold',
    fontSize: '16px',
};

const OptionBox = ({
    name,
    id,
    value,
    selector,
    style = {},
    label,
    active,
    example,
    ...props
}) => {
    return (
        <div style={{ ...containerStyle, ...style }}>
            <Radio
                active={active}
                label={label}
                name={name}
                id={id}
                value={value}
                handler={(e) => selector(e.target)}
                metaStyle={{ flex: '2' }}
                {...props}
            />
            <span style={spanStyle}>{example}</span>
        </div>
    );
};

OptionBox.propTypes = {
    value: PropTypes.any,
    name: PropTypes.string,
    example: PropTypes.string,
    id: PropTypes.number,
    style: PropTypes.object,
    label: PropTypes.string,
    active: PropTypes.bool,
    selector: PropTypes.func,
};

export default OptionBox;
