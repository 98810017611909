import { AsOfDatesType } from 'components/app/as-of-date';
import { API_URL } from 'config/constants';

/**
 * @param {array} entityCodes - list of entity codes
 * @param {string} [mode] - financial mode/GL type (ex: 'actual', 'budget', 'proforma'...)
 * @return {object} object with accounting and leases fields
 */

export async function getAsOfDates(
    entityCodes: string[],
    mode: string | null = null
): Promise<AsOfDatesType> {
    const requestBody = mode
        ? { mode, entity_codes: entityCodes }
        : { entity_codes: entityCodes };

    if (!entityCodes.length) {
        throw new Error('MUST SUPPLY ENTITY CODES');
    }

    const response = await fetch(`${API_URL}/as-of-date`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
        throw new Error('FAILED');
    }

    const { data } = await response.json();
    return data as AsOfDatesType;
}

export default getAsOfDates;
