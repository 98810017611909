import React from 'react';
import { Table } from 'waypoint-react';
import { floatRenderer, integerRenderer } from 'utils/tables/renderers';

const columns = [
    {
        title: 'Industry',
        dataIndex: 'tenant_industry',
        ellipsis: true,
    },
    {
        title: 'Occ. Sq Ft',
        dataIndex: 'tenant_occupied_area',
        render: integerRenderer,
        align: 'right',
    },
    {
        title: '% of Total Occ. Sq Ft',
        dataIndex: 'tenant_occupied_area_percent_of_portfolio',
        render: floatRenderer,
        align: 'right',
    },
];

const SqftByIndustryTable = ({ data, height = 275 }) => {
    const { tenantStatsByIndustry: tableData } = data;
    return (
        <Table
            data={tableData}
            columns={columns}
            size="small"
            height={height}
        />
    );
};

export default SqftByIndustryTable;
