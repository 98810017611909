import React, { useEffect, useMemo, useRef } from 'react';
import {
    Editor,
    EditorTools,
    EditorUtils,
    ProseMirror,
    EditorMountEvent,
    EditorChangeEvent,
    PasteCleanupSettings,
    EditorPasteEvent,
} from '@progress/kendo-react-editor';
import DOMPurify from 'dompurify';
import { insertImagePlugin } from './InsertImagePlugin';
import { createInsertImageTool } from './Utils';
import { FixAnyType } from '../../waypoint-types';

const { imageResizing } = EditorUtils;

const { sanitize } = DOMPurify;

const { EditorState, EditorView, Plugin, PluginKey, InputRule, inputRules } =
    ProseMirror;
const { pasteCleanup, tableResizing } = EditorUtils;

const {
    Bold,
    Italic,
    Underline,
    AlignLeft,
    AlignRight,
    AlignCenter,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    Link,
    Unlink,
    FontSize,
    Strikethrough,
    FormatBlock,
    FontName,
    SelectAll,
    TableProperties,
    TableCellProperties,
    CleanFormatting,
    ForeColor,
    BackColor,
    NumberedList,
    BulletedList,
    InsertTable,
    DeleteTable,
    AddRowBefore,
    AddRowAfter,
    AddColumnBefore,
    AddColumnAfter,
    DeleteRow,
    DeleteColumn,
    MergeCells,
    SplitCell,
} = EditorTools;

const resize: React.CSSProperties = {
    resize: 'vertical',
};

interface KendoEditorProps {
    content: string;
    isLoading: boolean;
    onChange: (content: string) => void;
    height: number;
    referenceType: string;
    entityCode?: string;
    disableImageUpload?: boolean;
}

const editorStyles = `
    .k-editor-resize-wrap-element {
        display: table;
    }
    .ProseMirror table {
        width: initial;

    }
    .ProseMirror td {
        vertical-align: middle;
    }
    table {
        min-width: 600px !important;
    }
`;

const KendoEditor = ({
    content,
    isLoading,
    onChange,
    height,
    referenceType,
    disableImageUpload,
}: KendoEditorProps) => {
    const htmlEditorInput = useRef<Editor>(null);
    const editableRef = React.useRef<boolean>(true);
    const view = React.useRef<any>(null);

    useEffect(() => {
        if (htmlEditorInput.current) {
            htmlEditorInput.current.focus();
        }
    }, [htmlEditorInput]);

    const readonlyPlugin = new Plugin({
        key: new PluginKey('readonly'),
        props: { editable: () => !isLoading }, // disable editor on loading state
        filterTransaction: (tr, _st) => editableRef.current || !tr.docChanged,
    });

    const inputRule = (nodes: any) => {
        return inputRules({
            rules: [
                // Converts an URL to a link.
                new InputRule(
                    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})\s$/,
                    (state, match, start, end) => {
                        const link = state.schema.marks.link.create({
                            href: `https://${match[1]}`,
                        });
                        return state.tr
                            .addMark(start, end, link)
                            .insertText(' ');
                    },
                ),
            ],
        });
    };

    const onMount = (event: EditorMountEvent) => {
        const iframeDocument = event.dom.ownerDocument;
        const style = iframeDocument.createElement('style');
        style.appendChild(iframeDocument.createTextNode(editorStyles));
        iframeDocument.head.appendChild(style);

        const { doc, plugins, schema } = event.viewProps.state;
        view.current = new EditorView(
            { mount: event.dom },
            {
                ...event.viewProps,
                state: EditorState.create({
                    doc,
                    plugins: [
                        ...tableResizing(),
                        readonlyPlugin,
                        ProseMirror.keymap({ 'Mod-u': () => true }),
                        inputRule(schema.nodes),
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        insertImagePlugin(() => {}),
                        imageResizing(),
                        ...plugins,
                    ],
                }),
            },
        );
        return view.current;
    };

    const onChangeEditorHtml = (event: EditorChangeEvent) => {
        const html = setEditorLinksHttps(setEditorContentStyles(event.html));
        onChange(sanitize(html, { ADD_ATTR: ['target'] }));
    };

    const setEditorContentStyles = (html: string): string => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const body = doc.body;

        const tables = body.querySelectorAll('table');
        const rows = body.querySelectorAll('tr');
        const cells = body.querySelectorAll('td');
        const paragraphsWithinTables = body.querySelectorAll('table p');
        for (const table of tables) {
            // let rows determine the height of the table, as kendo doesn't handle its height well on PDFs page break
            table.style.removeProperty('height');
            table.style['min-width'] =
                table.style['min-width'] === ''
                    ? '400px'
                    : table.style['min-width'];
            table.style['min-height'] =
                table.style['min-height'] === ''
                    ? '50px'
                    : table.style['min-height'];
        }
        for (const row of rows) {
            row.style['height'] =
                row.style['height'] === '' ? '25px' : row.style['height'];
        }
        for (const cell of cells) {
            cell.style['min-width'] =
                cell.style['min-width'] === ''
                    ? '5px'
                    : cell.style['min-width'];
            cell.style['border-width'] =
                cell.style['border-width'] === ''
                    ? '1px'
                    : cell.style['border-width'];
            cell.style['border-style'] =
                cell.style['border-style'] === ''
                    ? 'solid'
                    : cell.style['border-style'];
        }
        for (const paragraph of paragraphsWithinTables) {
            (paragraph as FixAnyType).style.margin =
                (paragraph as FixAnyType).style.margin === ''
                    ? '0'
                    : (paragraph as FixAnyType).style.margin;
        }
        return body.innerHTML;
    };

    const setEditorLinksHttps = (html: string) => {
        const hrefWithHttpsWwwRegex = /href\s*=\s*"https:\/\/www\./g;
        const hrefWithHttpsRegex = /href\s*=\s*"https:\/\//g;
        const hrefWithWwwRegex = /href="www\./g;
        const hrefStartRegex = /href\s*=\s*"/g;
        return html
            .replace(hrefWithHttpsWwwRegex, 'href="')
            .replace(hrefWithHttpsRegex, 'href="')
            .replace(hrefWithWwwRegex, 'href="')
            .replace(hrefStartRegex, 'href="https://www.');
    };

    const setImagesCrossOrigin = (html: string) => {
        const regex = /<img([^>]*\bid="[^"]*"[^>]*)>/g;
        return html.replace(regex, '<img$1 crossorigin="use-credentials">');
    };

    const pasteSettings: PasteCleanupSettings = {
        convertMsLists: true,
        stripTags: 'img',
    };

    const InsertImageTool = useMemo(
        () => createInsertImageTool({ referenceType }),
        [referenceType],
    );

    const tools = [
        [Bold, Italic, Underline, Strikethrough],
        [ForeColor, BackColor, CleanFormatting],
        [Link, Unlink],
        [AlignLeft, AlignCenter, AlignRight, AlignJustify],
        [NumberedList, BulletedList],
        [OrderedList, UnorderedList, Indent, Outdent],
        [FontSize],
        [FormatBlock],
        [FontName],
        [SelectAll],
        [Undo, Redo],
        [TableProperties, TableCellProperties],
        [
            InsertTable,
            DeleteTable,
            AddRowBefore,
            AddRowAfter,
            AddColumnBefore,
            AddColumnAfter,
            DeleteRow,
            DeleteColumn,
            MergeCells,
            SplitCell,
        ],
    ];

    if (!disableImageUpload) {
        tools.push([InsertImageTool]);
    }

    return (
        <Editor
            ref={htmlEditorInput}
            onMount={onMount}
            style={{
                height,
                ...resize,
            }}
            defaultContent={setImagesCrossOrigin(content)}
            onChange={onChangeEditorHtml}
            onPasteHtml={(event: EditorPasteEvent) =>
                pasteCleanup(
                    sanitize(event.pastedHtml, {
                        ADD_ATTR: ['target'],
                    }),
                    pasteSettings,
                )
            }
            tools={tools}
        />
    );
};

export default KendoEditor;
