import React from 'react';
import fusioncharts from 'fusioncharts';
import charts from 'fusioncharts/fusioncharts.charts';
import { css } from 'emotion';
import ReactFC from 'react-fusioncharts';
import { theme } from 'config/colors';
import {
    getBarChartData,
    generateWaleTooltip,
} from 'components/leases/tenants/utils';
import { topIndustryCount } from 'components/leases/tenants/constants';
import chartBuilder from 'components/charts/chartBuilder';

// Resolves charts dependancy
charts(fusioncharts);

const chartContainer = css`
    position: relative;
`;
const chartStyle = {
    yaxisname: 'Years',
    plottooltext: 'Average of <b>$dataValue</b> years',
    baseFontColor: theme.colors.mediumGray,
    xAxisNameFontColor: theme.colors.mediumGray,
    yAxisNameFontColor: theme.colors.mediumGray,
};

const WALEByIndustryChart = ({ data: statistics = [] }) => {
    const data = getBarChartData({
        statistics,
        labelPath: 'tenant_industry',
        valuePath: 'wale_years',
        tooltipFunc: (value) => generateWaleTooltip(value),
        partition: topIndustryCount,
    });

    const chart = chartBuilder();
    chart.type('bar2d');
    chart.height('300');
    chart.width('100%');
    chart.style(chartStyle);
    chart.exportFormats('PNG|PDF|JPG|SVG');
    chart.data(data);
    const { config: chartConfig } = chart;

    return (
        <div className={chartContainer}>
            <ReactFC {...chartConfig} />
        </div>
    );
};

export default WALEByIndustryChart;
