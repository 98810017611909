import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import { cx } from 'emotion';

// TODO (Daniel): Refactor FilterDropdown to accept more than just booleans
const arrowRenderer = ({ isOpen }) => {
    return (
        <span style={{ color: '#25A5E1' }}>
            <i
                className={
                    isOpen
                        ? 'fa-solid fa-chevron-up'
                        : 'fa-solid fa-chevron-down'
                }
            />
        </span>
    );
};

arrowRenderer.propTypes = {
    isOpen: PropTypes.bool,
};

const displayNoArrow = () => {
    return null;
};

const FilterDropdown = ({
    name,
    className,
    value,
    onChange,
    placeholder,
    isSearchable = false,
    options,
    ...props
}) => {
    return (
        <Select
            value={value}
            name={name}
            className={cx(['filter-set-dropdown', className])}
            onChange={onChange}
            options={options}
            placeholder={placeholder}
            arrowRenderer={!!value ? displayNoArrow : arrowRenderer}
            isClearable={true}
            isSearchable={isSearchable}
            {...props}
        />
    );
};

FilterDropdown.propTypes = {
    onChange: PropTypes.func,
    options: PropTypes.array,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.object,
    name: PropTypes.string,
    isSearchable: PropTypes.bool,
};

export default FilterDropdown;
