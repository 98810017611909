import { EntityReportWidgetPdfSettings } from 'components/reports/ReportUtils';
import React, { useImperativeHandle } from 'react';
import { numericalSort } from 'utils/tables/sorters';
import { ReportWidget } from 'waypoint-types';
import { PDFExportable } from 'waypoint-utils/pdf/PDFExportable';

interface TableOfContentsWidgetProps {
    reportWidgets: ReportWidget[] | null;
    propertyName: string;
    pdfSettings?: EntityReportWidgetPdfSettings;
}

export const TableOfContentsWidget = React.forwardRef<
    PDFExportable,
    TableOfContentsWidgetProps
>(
    (
        {
            reportWidgets,
            pdfSettings,
            propertyName,
        }: TableOfContentsWidgetProps,
        ref
    ) => {
        useImperativeHandle(ref, () => ({
            isReadyToExport(): boolean {
                return !!reportWidgets;
            },
        }));

        const calculateToCPageHeight = () => {
            if (pdfSettings?.landscape) {
                return '1040px';
            }

            return pdfSettings?.paperSize === 'Legal' ? '1830px' : '1400px';
        };

        return (
            <>
                <div style={{ marginTop: '-30px' }}></div>
                <div
                    style={{
                        border: '3px solid #c3ced9',
                        paddingBottom: '24px',
                        height: calculateToCPageHeight(),
                    }}
                >
                    <h1 style={{ textAlign: 'center', fontSize: '30px' }}>
                        <div style={{ marginBottom: '15px' }}>
                            {propertyName}
                        </div>
                        <div>Table of Contents</div>
                    </h1>
                    <ul style={{ marginLeft: '10%', marginTop: '30px' }}>
                        {reportWidgets
                            ?.filter((widget) => widget.sort_index >= 0)
                            .sort((a, b) =>
                                numericalSort(a.sort_index, b.sort_index)
                            )
                            .map((widget) => (
                                <li
                                    style={{
                                        marginTop: '15px',
                                        fontSize: '24px',
                                    }}
                                >
                                    {widget.name}
                                </li>
                            ))}
                    </ul>
                </div>
            </>
        );
    }
);
