import React from 'react';
import { Manager, Target, Popper } from 'react-popper';
import Menu from 'components/analytics/portfolioSegmentation/performanceOverview/menu/Menu';

const PerformanceOverviewPopover = ({
    children,
    show,
    onClose,
    onApply,
    changeGroupBy,
    changeKpi,
    kpiSelected,
    saveConfig,
    groupBy,
    metricsOptions,
    groupByOptions,
}) => {
    return (
        <Manager>
            <Target>{children}</Target>
            {show && (
                <Popper
                    placement="bottom"
                    className="popper"
                    style={{ zIndex: 100 }}
                >
                    <Menu
                        onApply={onApply}
                        onClose={onClose}
                        changeGroupBy={changeGroupBy}
                        saveConfig={saveConfig}
                        changeKpi={changeKpi}
                        groupBy={groupBy}
                        kpiSelected={kpiSelected}
                        metricsOptions={metricsOptions}
                        groupByOptions={groupByOptions}
                    />
                </Popper>
            )}
        </Manager>
    );
};

export default PerformanceOverviewPopover;
