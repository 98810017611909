import { API_URL } from 'config/constants';
import { SavedConfiguration } from 'waypoint-types';

const getSavedConfigurations = async (
    filterType: string
): Promise<SavedConfiguration[]> => {
    const response = await fetch(`${API_URL}/global-filters/${filterType}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
    });

    if (!response.ok) {
        throw new Error('Failed to get saved configurations');
    }

    const { data } = await response.json();
    return data.saved_filters as SavedConfiguration[];
};

export default getSavedConfigurations;
