import PivotGridDataSource, {
    Field,
} from 'devextreme/ui/pivot_grid/data_source';
import { CROSSTAB_ACCOUNT_NAME_FIELDS } from 'components/financials/crosstab/CrosstabConstants';

export const resetAccountFieldPlacement = async (
    dataSource: PivotGridDataSource,
    visibleFields: string[] = CROSSTAB_ACCOUNT_NAME_FIELDS
) => {
    const fields = dataSource.fields();

    // check against all possible name fields, not just the visible fields
    const isAccountField = (f: Field) =>
        f.dataField && CROSSTAB_ACCOUNT_NAME_FIELDS.includes(f.dataField);

    const accountFields = fields
        .filter(isAccountField)
        .sort((a, b) => a.dataField!.localeCompare(b.dataField!));

    // ensure all account fields are in the row area, with no areaIndex set
    for (const accountField of accountFields) {
        accountField.area = 'row';
        accountField.visible = visibleFields.includes(accountField.dataField!);
    }

    const nonAccountFields = fields.filter((f) => !isAccountField(f));

    // replace fields with array that contains no account fields
    dataSource.fields(nonAccountFields);

    // replace again with all fields
    dataSource.fields([...nonAccountFields, ...accountFields]);

    await dataSource.load();
};
