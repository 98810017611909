import { get, find } from 'lodash';
import { DASH_DASH } from 'config/constants';
import {
    periodicityOptions,
    PERIODICITY_QUARTER_VALUE,
    PERIODICITY_YEAR_VALUE,
} from 'components/analytics/constants';
import {
    toQuarter,
    toCalendarYear,
    toCalendarMonthAndYearAbbrv,
} from 'components/dates/utils';

/**
 * This pulls the label for any given periodicity value. The value is what is stored in component state and used for the menu/request parameters.
 * @param {string} periodicityValue - quarterly, monthly or yearly
 * @returns {string} periodicty label - Quarter, Month, Year or '--'
 */
export const getPeriodicityLabelFor = (periodicityValue) => {
    const option = find(periodicityOptions, { value: periodicityValue });
    return get(option, 'label', DASH_DASH);
};

/**
 * This returns a formatted date based off the selected periodcity
 * @param {string} ISODate - ex. 2021-03-01
 * @param {string} periodicityValue
 * @returns {string} date - Examples: 'Q1 2021', 'Jan 2021', '2021'
 */
export const getOvertimeDataLabelFor = (ISODate, periodicityValue) => {
    if (periodicityValue === PERIODICITY_QUARTER_VALUE) {
        return `Q${toQuarter(ISODate)} ${toCalendarYear(ISODate)}`;
    }
    if (periodicityValue === PERIODICITY_YEAR_VALUE) {
        return toCalendarYear(ISODate);
    }
    return toCalendarMonthAndYearAbbrv(ISODate);
};
