import React from 'react';
import { Radio } from 'antd';

interface ViewToggleProps {
    active: string;
    options: string[];
    onChange: (value: string) => void;
}

/**
 * This component is designed to toggle views between views in parent component
 * @param {func} onChange callback changes view state of parent component
 * @param {string} active current view of parent component
 * @param {string[]} options teh available options for the user to click
 */
const ViewToggle = ({ active, options, onChange }: ViewToggleProps) => {
    return (
        <Radio.Group
            defaultValue={options[0]}
            onChange={(e) => onChange(e.target.value)}
            value={active}
            optionType="button"
            size="small"
        >
            {options.map((option) => (
                <Radio.Button key={option} value={option}>
                    {option}
                </Radio.Button>
            ))}
        </Radio.Group>
    );
};

export default ViewToggle;
