import React from 'react';
import { Descriptions, Row as AntRow } from 'antd';
import { DASH_DASH } from 'config/constants';
import { formatNumber } from 'utils/formatters';
import { getFormattedLabel, KPIMode } from '../PropertyProfileUtils';
import { usePropertyInformation } from 'waypoint-hooks';
import { calculateParkingRatio } from '../PropertyProfileUtils';
import { PropertyInformationSectionProps } from '../types';

const labelStyle = {
    width: 200,
    paddingLeft: 20,
};
interface PropertySiteInformationProps extends PropertyInformationSectionProps {
    hideTitle?: boolean;
}
const PropertySiteInformation = ({
    propertyInfo,
    entityCode,
    selectedDataLevel,
    hideTitle = false,
}: PropertySiteInformationProps) => {
    const {
        clientYearBuilt,
        clientLastRenovated,
        clientSiteArea,
        clientParkingSpaces,
        clientParkingNotes,
        buildingCount,
        floors,
        constructionType,
        clientKpiPreferences,
        leasePropertyData,
    } = usePropertyInformation({
        entityCode,
        selectedDataLevel,
        propertyInfo,
    });

    const parkingRatio = calculateParkingRatio(
        clientKpiPreferences as KPIMode,
        clientParkingSpaces ?? 0,
        leasePropertyData,
    );

    const items = [
        {
            label: 'Construction Type',
            value: constructionType?.length ? constructionType : DASH_DASH,
            span: 2,
        },
        {
            label: 'Year Built',
            value: clientYearBuilt?.length
                ? clientYearBuilt.join(', ')
                : DASH_DASH,
            span: 1,
        },
        {
            label: 'Last Renovated',
            value: clientLastRenovated?.length
                ? clientLastRenovated.join(', ')
                : DASH_DASH,
            span: 1,
        },
        {
            label: 'Site Area',
            value:
                clientSiteArea && clientSiteArea > 0
                    ? `${clientSiteArea} Acres`
                    : DASH_DASH,
            span: 1,
        },
        {
            label: 'Buildings / Floors',
            value: `${buildingCount ? buildingCount : DASH_DASH} / ${floors ? floors : DASH_DASH}`,
            span: 1,
        },
        {
            label: 'Parking Spaces',
            value:
                clientParkingSpaces && clientParkingSpaces > 0
                    ? formatNumber(clientParkingSpaces)
                    : DASH_DASH,
            span: 1,
        },
        { label: 'Parking Ratio', value: parkingRatio, span: 1 },
        {
            label: 'Parking Notes',
            value: clientParkingNotes.length ? clientParkingNotes : '',
            span: 2,
        },
    ];

    return (
        <AntRow align="stretch" wrap={true}>
            <Descriptions
                size="small"
                title={
                    !hideTitle && (
                        <h2>
                            <div
                                style={{
                                    padding: '4px 0px',
                                    borderBottom: '2px solid #001628',
                                }}
                            >
                                Site Information
                            </div>
                        </h2>
                    )
                }
                bordered={false}
                layout="horizontal"
                column={{
                    xxl: 2,
                    xl: 2,
                    lg: 2,
                    md: 2,
                    sm: 1,
                    xs: 1,
                }}
                contentStyle={{
                    textAlign: 'left',
                    marginRight: 15,
                }}
                labelStyle={labelStyle}
            >
                {items.map((item, index) => (
                    <Descriptions.Item
                        key={index}
                        label={getFormattedLabel(item.label)}
                        span={item.span}
                    >
                        {item.value}
                    </Descriptions.Item>
                ))}
            </Descriptions>
        </AntRow>
    );
};

export default PropertySiteInformation;
