import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { Note } from 'waypoint-types';
import useSWR, { SWRConfiguration } from 'swr';
import { getNotesByReferenceId } from 'waypoint-requests';

export const useGetNotesByReferenceId = (
    referenceId: string,
    config?: SWRConfiguration
): DataHookResponse<Note[]> => {
    const { data, error, mutate } = useSWR(
        referenceId ? `/notes/by-reference/${referenceId}` : null,
        () => {
            return getNotesByReferenceId(referenceId);
        },
        config
    );

    return {
        data,
        mutate,
        isLoading: !data,
        isError: !!error,
    };
};
