import { getDocumentUploadListByReferenceParams } from 'waypoint-requests';
import useSWR from 'swr';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { DocumentUpload } from 'waypoint-types';

export const useGetDocumentUploadListByReferenceParams = (
    referenceType: string,
    referenceId: string
): DataHookResponse<DocumentUpload[]> => {
    const cacheKey = `/api/document-uploads/list/${referenceType}/${referenceId}`;
    const { data, error, isValidating, mutate } = useSWR(
        cacheKey,
        () =>
            getDocumentUploadListByReferenceParams(referenceType, referenceId),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
            shouldRetryOnError: false,
        }
    );

    return {
        isLoading: data === undefined || isValidating,
        isError: !!error,
        data,
        mutate,
    };
};
