import React from 'react';
import { formatMoney } from 'components/leases/utils';
import { cx } from 'emotion';
import { toolTipClassnames } from 'components/leases/tenants/utils';

const RentTooltip = ({ grossRent, totalAnnualInPlaceRentSqFt }) => {
    return (
        <div className={toolTipClassnames.container}>
            <div
                className={cx([
                    toolTipClassnames.label,
                    toolTipClassnames.topLabel,
                ])}
            >
                <div>Industry</div>
                <div className={toolTipClassnames.figure}>$label</div>
            </div>
            <div
                className={cx([
                    toolTipClassnames.label,
                    toolTipClassnames.topLabel,
                ])}
            >
                <div>Total Gross Amount</div>
                <div className={toolTipClassnames.figure}>
                    {formatMoney(grossRent)}
                </div>
            </div>
            <div
                className={cx([
                    toolTipClassnames.label,
                    toolTipClassnames.bottomLabel,
                ])}
            >
                <div>Total Ann. In-Place Rent / sq ft</div>
                <div className={toolTipClassnames.figure}>
                    {formatMoney(totalAnnualInPlaceRentSqFt)}
                </div>
            </div>
        </div>
    );
};

export default RentTooltip;
