import PropTypes from 'prop-types';
import React from 'react';
import { isEmpty, get, pick } from 'lodash';
import { ChartTableSwitchCard, ActionButtons } from 'waypoint-react';
import { toCalendarDate } from 'components/dates/utils';
import { Legend, LineLegendItem } from 'components/style/legends'; // TODO: move to waypoint-react
import { BreakdownChart } from 'components/analytics/balance-sheet-overview/charts';
import { BreakdownTable } from 'components/analytics/balance-sheet-overview/tables';

/**
 * Card commponent that renders a Breakdown Chart and Breakdown Table. See ChartTableSwitchCard for abstracted view switch, loading, and empty state logic.
 */
const BreakdownCard = ({
    data,
    selections,
    onClick,
    loading,
    downloadCsv,
    downloading,
    ...props
}) => {
    const accountMappingName = get(selections, 'accountMapping.name');
    const period = toCalendarDate(selections.period[1]);
    const gridProps = pick(props, ['data-grid', 'style']);
    return (
        <ChartTableSwitchCard
            {...gridProps}
            loading={loading}
            extra={
                <ActionButtons
                    onClick={onClick}
                    loading={loading}
                    downloadCsv={downloadCsv}
                    downloading={downloading}
                />
            }
            title={`Account Breakdown: ${accountMappingName}`}
            subheading={`Period: ${period}`}
            empty={isEmpty(data)}
            legend={
                <Legend style={{ width: '200px', marginRight: '20px' }}>
                    <LineLegendItem
                        color="#3588BD" // TODO: define these in theme
                        label="Actual"
                    />
                </Legend>
            }
            chart={
                <BreakdownChart
                    data={data}
                    displayType={selections.displayType}
                />
            }
            table={
                <BreakdownTable
                    data={data}
                    displayType={selections.displayType}
                />
            }
            data-testid="component-breakdown-card"
        />
    );
};

BreakdownCard.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    selections: PropTypes.shape({
        accountMapping: PropTypes.shape({
            name: PropTypes.string,
            code: PropTypes.string,
        }),
        period: PropTypes.arrayOf(PropTypes.string),
        displayType: PropTypes.string,
    }),
};

export default BreakdownCard;
