import React from 'react';
import { RootStateOrAny, connect } from 'react-redux';
import { CommentsPaneWrapper } from 'waypoint-react';
import { PropertyType } from 'waypoint-types';
import {
    hasAgedReceivablesEnabled,
    hasBalanceSheetEnabled,
    hasCapitalTrackerEnabled,
    hasDocumentsEnabled,
    hasOccupancyTrendEnabled,
    hasOwnershipPercentageEnabled,
    hasPlanningSectionEnabled,
    hasUnitMixEnabled,
} from 'state/user/selectors';
import { getPureId } from 'state/routing/selectors';
import { selectPropertiesById } from 'state/properties/selectors';
import PropertyProfileContainer from './PropertyProfileContainer';
import { useGetMentionableUsers } from 'waypoint-hooks';
import { Skeleton } from 'antd';
import { CommentsContextManagerProvider } from 'contexts/comments/CommentsContextManagerProvider';

interface PropertyProfileCommentsContainerProps {
    entityCode: string;
    propertyInfo: PropertyType;
    hasOccupancyTrendEnabled: boolean;
    hasBalanceSheetEnabled: boolean;
    hasCapitalTrackerEnabled: boolean;
    hasPlanningSectionEnabled: boolean;
    hasUnitMixEnabled: boolean;
    hasAgedReceivablesEnabled: boolean;
    hasOwnershipPercentageEnabled: boolean;
    hasDocumentsEnabled: boolean;
}

export const PropertyProfileCommentsContainer = ({
    entityCode,
    propertyInfo,
    hasOccupancyTrendEnabled,
    hasBalanceSheetEnabled,
    hasCapitalTrackerEnabled,
    hasPlanningSectionEnabled,
    hasUnitMixEnabled,
    hasAgedReceivablesEnabled,
    hasOwnershipPercentageEnabled,
    hasDocumentsEnabled,
}: PropertyProfileCommentsContainerProps) => {
    const userMentionOptions = useGetMentionableUsers([entityCode]);
    if (!userMentionOptions) {
        return <Skeleton />;
    }

    return (
        <CommentsContextManagerProvider>
            <CommentsPaneWrapper
                userMentionOptions={userMentionOptions}
                commentsPaneWrapperStyle={{ zIndex: 5 }}
            >
                <PropertyProfileContainer
                    entityCode={entityCode}
                    propertyInfo={propertyInfo}
                    hasOccupancyTrendEnabled={hasOccupancyTrendEnabled}
                    hasBalanceSheetEnabled={hasBalanceSheetEnabled}
                    hasCapitalTrackerEnabled={hasCapitalTrackerEnabled}
                    hasPlanningSectionEnabled={hasPlanningSectionEnabled}
                    hasUnitMixEnabled={hasUnitMixEnabled}
                    hasAgedReceivablesEnabled={hasAgedReceivablesEnabled}
                    hasOwnershipPercentageEnabled={
                        hasOwnershipPercentageEnabled
                    }
                    hasDocumentsEnabled={hasDocumentsEnabled}
                />
            </CommentsPaneWrapper>
        </CommentsContextManagerProvider>
    );
};

const mapState = (s: RootStateOrAny): PropertyProfileCommentsContainerProps => {
    const entityCode = getPureId(s);
    const properties = selectPropertiesById(s, { propertyIds: [entityCode] });

    return {
        entityCode: getPureId(s) ?? '',
        propertyInfo: properties[Object.keys(properties)[0]],
        hasOccupancyTrendEnabled: hasOccupancyTrendEnabled(s),
        hasBalanceSheetEnabled: hasBalanceSheetEnabled(s),
        hasCapitalTrackerEnabled: hasCapitalTrackerEnabled(s),
        hasPlanningSectionEnabled: hasPlanningSectionEnabled(s),
        hasUnitMixEnabled: hasUnitMixEnabled(s),
        hasAgedReceivablesEnabled: hasAgedReceivablesEnabled(s),
        hasOwnershipPercentageEnabled: hasOwnershipPercentageEnabled(s),
        hasDocumentsEnabled: hasDocumentsEnabled(s),
    };
};

export default connect(mapState)(PropertyProfileCommentsContainer);
