import { useEffect, useState } from 'react';

export const useReportWorkflowContainerHeight = (): number => {
    const OFFSET = 285;
    const calculateHeight = () => window.innerHeight - OFFSET;

    const [workflowContainerHeight, setWorkflowContainerHeight] =
        useState<number>(calculateHeight);

    useEffect(() => {
        const handleResize = () => {
            setWorkflowContainerHeight(calculateHeight());
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return workflowContainerHeight;
};
