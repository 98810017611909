import React, { useEffect, useState } from 'react';
import {
    AMOUNT_AND_PCT,
    COLUMN_A_NAME,
    COLUMN_B_NAME,
    COLUMN_C_NAME,
    COMPARISON_PERIOD,
    COMPARISON_PERIOD_TERTIARY,
    FINANCIAL_YEAR_ENDING,
    PERIODICITY_FIELD,
    PERIOD_FIELD_NAME_PRIMARY,
    PERIOD_FIELD_NAME_SECONDARY,
    PERIOD_FIELD_NAME_TERTIARY,
    VARIANCE_DISPLAY,
    VARIANCE_DISPLAY_TERTIARY,
} from 'components/financials/comparative-income-statement/constants';
import {
    getStartAndEndDatesForColumn,
    getDefaultFieldValuesFor,
} from 'components/financials/comparative-income-statement/banner/ComparisonSelectionsBannerUtils';
import {
    ComparisonSelections,
    SelectOptions,
} from 'components/financials/comparative-income-statement/ComparisonIncomeStatementTypes';
import {
    useGetClientAndEntityAttributeValues,
    useGetClientModes,
    useGetDataAvailabilityDate,
} from 'waypoint-hooks';
import { Moment } from 'moment';
import { PropertyDetailAttributeEnum } from 'components/propertyProfile/PropertyProfileConstants';

export const useIncomeStatementSettings = (entityCodes: string[]) => {
    const [rangeSelectIsOpen, setRangeSelectIsOpen] = useState<boolean>(false);
    const [defaultSelections, setDefaultSelections] =
        useState<ComparisonSelections | null>(null);
    const [localSelections, setLocalSelections] =
        useState<ComparisonSelections | null>(null);

    const {
        data: asOfDate,
        isLoading: isLoadingAsOfDate,
        isError: isErrorAsOfDate,
    } = useGetDataAvailabilityDate(entityCodes);

    const { data: clientModes, isLoading: isLoadingClientModes } =
        useGetClientModes();

    const { data: clientPropertyAttributes } =
        useGetClientAndEntityAttributeValues(
            [PropertyDetailAttributeEnum.FinancialYearEnding],
            true
        );

    const clientFinancialYearEnding = clientPropertyAttributes?.find(
        (a) =>
            entityCodes.length === 1 &&
            a.attributeDefinition?.attribute_code ===
                PropertyDetailAttributeEnum.FinancialYearEnding &&
            a.entity_code === entityCodes[0]
    );

    const modeOptions: SelectOptions[] =
        clientModes?.map(({ code, display_name }) => ({
            label: display_name,
            value: code,
        })) ?? [];

    const modeOptionsWithNone = [
        ...modeOptions,
        {
            label: 'None',
            value: 'none',
        },
    ];

    const localModes = modeOptions.filter(
        (option) => option.value !== 'actual'
    );

    const getUserSelectionFor = (field: string) => {
        // falls back to previous selection if field is clean
        if (localSelections && defaultSelections) {
            return (
                (localSelections[
                    field as keyof ComparisonSelections
                ] as string) ||
                (defaultSelections[
                    field as keyof ComparisonSelections
                ] as string)
            );
        }
        return '';
    };

    const handleOnChange = (
        field: string,
        value: string | string[] | Moment[]
    ) => {
        if (localSelections) {
            setLocalSelections({ ...localSelections, [field]: value });
        }
    };

    useEffect(() => {
        if (localSelections?.comparison_type[1] === 'none') {
            setLocalSelections({
                ...localSelections,
                [COMPARISON_PERIOD]: 'selected_period',
                [COMPARISON_PERIOD_TERTIARY]: 'selected_period',
                [VARIANCE_DISPLAY]: AMOUNT_AND_PCT,
                [VARIANCE_DISPLAY_TERTIARY]: AMOUNT_AND_PCT,
            });
        }
    }, [localSelections?.comparison_type]);

    // fiscal year setting doesn't apply to trailing 12 and custom periodicities,
    // so to keep the report metadata in line, set financial_year_ending
    // to its default value if one of those options is selected for periodicity
    useEffect(() => {
        if (
            localSelections &&
            ['trailing_12', 'custom'].includes(
                localSelections?.periodicity ?? ''
            )
        ) {
            setLocalSelections({
                ...localSelections,
                [FINANCIAL_YEAR_ENDING]:
                    clientFinancialYearEnding?.value ?? '12/31',
            });
        }
    }, [localSelections?.periodicity]);

    useEffect(() => {
        if (asOfDate) {
            const initialSelectionValues = getDefaultFieldValuesFor(
                asOfDate,
                clientFinancialYearEnding?.value
            );
            setDefaultSelections(initialSelectionValues);
            !localSelections && setLocalSelections(initialSelectionValues);
        }
    }, [asOfDate]);

    const handleOnChangeDateRange = (value: Date | string) => {
        const financialYearEnding = localSelections
            ? localSelections[FINANCIAL_YEAR_ENDING]
            : clientFinancialYearEnding?.value ?? '12/31';

        const primaryDateRange = getStartAndEndDatesForColumn(
            getUserSelectionFor(PERIODICITY_FIELD),
            value,
            COLUMN_A_NAME,
            getUserSelectionFor,
            financialYearEnding
        );
        handleOnChange(PERIOD_FIELD_NAME_PRIMARY, primaryDateRange);
        const secondaryDateRange = getStartAndEndDatesForColumn(
            getUserSelectionFor(PERIODICITY_FIELD),
            value,
            COLUMN_B_NAME,
            getUserSelectionFor,
            financialYearEnding,
            primaryDateRange
        );
        const tertiaryDateRange = getStartAndEndDatesForColumn(
            getUserSelectionFor(PERIODICITY_FIELD),
            value,
            COLUMN_C_NAME,
            getUserSelectionFor,
            financialYearEnding,
            primaryDateRange
        );
        const newSelections = {
            ...localSelections,
            [PERIOD_FIELD_NAME_PRIMARY]: primaryDateRange,
            [PERIOD_FIELD_NAME_SECONDARY]: secondaryDateRange,
            [PERIOD_FIELD_NAME_TERTIARY]: tertiaryDateRange,
        };
        setLocalSelections(newSelections as ComparisonSelections);
        return newSelections;
    };

    return {
        asOfDate,
        defaultSelections,
        localSelections,
        setLocalSelections,
        getUserSelectionFor,
        localModes,
        handleOnChange,
        rangeSelectIsOpen,
        setRangeSelectIsOpen,
        handleOnChangeDateRange,
        comparisonTypeOptions: modeOptions,
        comparisonTypeOptionsWithNone: modeOptionsWithNone,
        clientFinancialYearEnding,
    };
};
