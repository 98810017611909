import { API_URL } from 'config/constants';
import { User, UserWithRoleData } from 'waypoint-types';

export async function getUsersWithEntityPermissions(
    entityCode: string[],
    strictAccessToAll = false
): Promise<User[]> {
    if (!entityCode) {
        return [];
    }

    const response = await fetch(`${API_URL}/entity-permissions/entity`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
            entity_code: entityCode,
            strict_access_to_all: strictAccessToAll,
        }),
    });

    if (!response.ok) {
        throw new Error('Failed to get users with entity permissions');
    }

    const { data } = await response.json();

    const userDataWithRoles = data?.map((user: UserWithRoleData) => {
        return {
            ...user,
            roles: user.roles?.map((role) => role.name),
        };
    });

    return userDataWithRoles as User[];
}
