import { stringSort } from 'utils/tables/sorters';

export const arrayToCapitalizedString = (arrayOfStrings: string[]) =>
    arrayOfStrings
        .map((word) => {
            return typeof word === 'string' && word.length > 0
                ? word
                      .split(' ')
                      .map(
                          (word) =>
                              word.charAt(0).toUpperCase() +
                              word.slice(1).toLowerCase()
                      )
                      .join(' ')
                : '';
        })
        .sort((a: string, b: string) => stringSort(b, a))
        .join(', ');
