import { API_URL } from 'config/constants';
import { Report } from 'waypoint-types';

interface GetReportParams {
    reportId: string;
}

const cloneReports = async (body: GetReportParams): Promise<Report> => {
    const { reportId } = body;
    const response = await fetch(`${API_URL}/reports/clone/${reportId}`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
    });

    if (!response.ok) {
        throw new Error('FAILED');
    }

    const { data } = await response.json();
    return data;
};

export default cloneReports;
