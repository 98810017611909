import { API_URL } from 'config/constants';

export async function getLeasingAsOfDateByEntityCode(
    entityCode: string
): Promise<string> {
    const response = await fetch(`${API_URL}/as-of-date/${entityCode}`, {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
    });

    if (!response.ok) {
        throw new Error('FAILED');
    }

    const { data } = await response.json();
    return data as string;
}

export default getLeasingAsOfDateByEntityCode;
