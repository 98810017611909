import React from 'react';
import { omit } from 'lodash';
import { css, cx } from 'emotion';
import theme from 'config/theme';
import { DASH_DASH } from 'config/constants';
import { Card as AntDesignCard, Empty, Skeleton } from 'antd';

const card = css`
    box-shadow:
        0px 3px 5px -1px rgba(0, 0, 0, 0.2),
        0px 6px 10px 0px rgba(0, 0, 0, 0.04);
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const headerTitle = css`
    .ant-card-head-wrapper {
        height: 100%;
    }
    .ant-card-head-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin: 0;
        }
        h2 {
            font-size: 18px;
        }
    }
    .ant-card-extra {
        padding: 0 !important;
    }
`;

const button = css`
    color: ${theme.colors.grays.medium};
    cursor: pointer;
    &:active {
        color: ${theme.colors.blues.primary};
    }
    &:hover {
        color: ${theme.colors.blues.primary};
    }
`;

interface ActionsCardProps {
    title?: string | JSX.Element;
    extra?: JSX.Element;
    loadingData?: boolean;
    loadingRows?: number;
    empty?: boolean;
    emptyClassName?: string;
    error?: boolean;
    onClick?: () => void;
    className?: string;
    children?: JSX.Element[] | JSX.Element | boolean | string | number;
    style?: React.CSSProperties;
    id?: string;
    size?: 'small' | 'default';
}

const ActionsCard = (props: ActionsCardProps) => {
    const cardProps = omit(props, 'onClick');

    const renderContent = () => {
        if (props.loadingData) {
            return <Skeleton paragraph={{ rows: props.loadingRows || 16 }} />;
        }

        if (props.empty) {
            return (
                <Empty
                    description="Data unavailable"
                    className={props.emptyClassName}
                />
            );
        }

        if (props.error) {
            return (
                <Empty
                    description="An error occurred"
                    className={props.emptyClassName}
                />
            );
        }

        return props.children;
    };

    return (
        <AntDesignCard
            size="small"
            {...cardProps}
            // NOTE: nothing below props spread should be overriden, everything above can
            extra={
                props.extra || ( // NOTE: you can pass more than one extra "action"
                    <i
                        data-testid="action-element"
                        onClick={props.onClick}
                        className={`fa-solid fa-cog ${button}`}
                    />
                )
            }
            loading={false}
            title={props.title || DASH_DASH}
            className={cx([card, headerTitle, props.className])}
        >
            {renderContent()}
        </AntDesignCard>
    );
};

export default ActionsCard;
