import PropTypes from 'prop-types';
import React from 'react';
import { get, pick } from 'lodash';
import { css } from 'emotion';
import { theme } from 'config/colors';
import { StatisticsCard } from 'waypoint-react';
import withStatisticSelectionPP from 'components/leases/tenants/withStatisticSelection';

const valueStyle = css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: center;
    font-size: 20px;
`;

const suffixStyle = css`
    font-size: 14px;
    font-family: Lato;
    font-weight: 400;
    color: ${theme.colors.text.normal};
`;

const TenantStatCard = ({
    statistics,
    dataKey,
    isLoading,
    title,
    statFormatter,
    suffix,
    ...props
}) => {
    const cardProps = pick(props, ['data-grid', 'style', 'key', 'className']);
    const stat = get(statistics, dataKey);

    const valueComponent = suffix ? (
        <div className={valueStyle}>
            <span>{statFormatter ? statFormatter(stat) : stat}</span>
            <span className={suffixStyle}>&nbsp;{suffix}</span>
        </div>
    ) : (
        <div className={valueStyle}>
            {statFormatter ? statFormatter(stat) : stat}
        </div>
    );

    return (
        <StatisticsCard
            title={title}
            value={stat && valueComponent}
            loading={isLoading}
            subheading={<span />}
            {...cardProps}
        />
    );
};

TenantStatCard.propTypes = {
    title: PropTypes.string,
    dataKey: PropTypes.string,
    suffix: PropTypes.string,
    statistics: PropTypes.object,
    isLoading: PropTypes.bool,
    statFormatter: PropTypes.func,
};

export default TenantStatCard;
