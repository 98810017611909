import PropTypes from 'prop-types';
import React from 'react';
import { merge } from 'lodash';
import {
    Button,
    Popover,
    OverlayTrigger,
    ButtonToolbar,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

/**
 * Previously this component catered specifically to the questionmark button and it's popover.
 * I've made this generic although kept the default to reflect the original questionmark button.
 *
 * TODO (Alex) - adjust the all instances of questionmark to make this more generic still
 *
 * Also, it seems that using an overlay with the popover means that the positional props are ignored.
 * So please add a 'className' prop, which will be added to the list on the popover container, and
 * then style this in the client/styles/bootstrap/post-bootstrap.scss stylesheet. Isolating the arrow
 * is a good candidate for this method. Then to position the popover container, you can use the 'styles' prop.
 * NOTE: Please correct me (Alex) if I'm wrong, as I'd love to just pass positional props through the overlay to the popover.
 */
class PopoverGeneric extends React.Component {
    static propTypes = {
        placement: PropTypes.string,
        trigger: PropTypes.string,
        message: PropTypes.string,
        icon: PropTypes.any,
        children: PropTypes.any,
        style: PropTypes.object,
        className: PropTypes.string,
    };

    static defaultProps = {
        placement: 'right',
        trigger: 'click',
        icon: (
            <i
                className="fa-regular fa-question-circle"
                style={{ fontSize: '16px' }}
            />
        ),
        style: {
            width: 300,
        },
    };

    getOverlay = () => {
        const { message, children, style, className } = this.props;
        const popoverStyles = {
            border: '1px solid #3FA8F4',
            fontSize: '11px',
            color: '#4D4D4D',
        };
        return (
            <Popover
                className={className}
                style={merge(popoverStyles, style)}
                arrowOffsetLeft={30}
            >
                {children || message}
            </Popover>
        );
    };

    render() {
        const { getOverlay, props } = this;
        const { placement, icon, trigger } = props;
        return (
            <div className="questionButtonContainer">
                <div style={{ display: 'inline-block' }}>
                    <ButtonToolbar>
                        <OverlayTrigger
                            rootClose
                            trigger={trigger}
                            placement={placement}
                            overlay={getOverlay()}
                        >
                            <Button bsStyle="link">{icon}</Button>
                        </OverlayTrigger>
                    </ButtonToolbar>
                </div>
            </div>
        );
    }
}

export default PopoverGeneric;
