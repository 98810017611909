import { PDFExportable } from 'waypoint-utils/pdf/PDFExportable';
import React, { useImperativeHandle, useMemo, useState } from 'react';
import { EntityReport } from 'waypoint-types/report/types';
import {
    useEntityPhoto,
    useGetMentionableUsers,
    usePropertyInformation,
} from 'waypoint-hooks';
import { PropertyType, Report, MentionableDataSource } from 'waypoint-types';
import {
    EntityReportWidgetPdfSettings,
    getWorkflowRole,
} from 'components/reports/ReportUtils';
import { DASH_DASH } from 'config/constants';
import { format } from 'date-fns';
import {
    WORKFLOW_ASSIGNEE,
    WORKFLOW_REVIEWER,
} from 'components/financials/comparative-income-statement/constants';
import { WidgetTypes } from 'components/reports/constants';

interface CoverPageReportWidgetProps {
    widgetId: string;
    widgetType: string;
    entityCode: string;
    propertyInfo?: PropertyType;
    isPDFExport?: boolean;
    pdfSettings?: EntityReportWidgetPdfSettings;
    entityReport: EntityReport | null;
    report?: Report;
}

export const CoverPageReportWidget = React.forwardRef<
    PDFExportable,
    CoverPageReportWidgetProps
>(
    (
        {
            widgetId,
            widgetType,
            entityCode,
            propertyInfo,
            isPDFExport,
            pdfSettings,
            entityReport,
            report,
        }: CoverPageReportWidgetProps,
        ref
    ) => {
        const [isImageLoaded, setIsImageLoaded] = useState(false);

        const users = useGetMentionableUsers([entityCode]);

        const coverPageSettings = report?.reportSettings.find(
            (setting) => setting.settings_type === WidgetTypes.CoverPage
        )?.settings;

        const { isLoadingPropertyDetails, isImageError } =
            usePropertyInformation({
                entityCode,
                selectedDataLevel: {
                    stakeholder: null,
                    percentageType: null,
                },
                propertyInfo,
            });

        const entityPhotoData = useEntityPhoto(entityCode);

        const isWidgetReady =
            (!isLoadingPropertyDetails && entityPhotoData && isImageLoaded) ||
            isImageError;

        useImperativeHandle(ref, () => ({
            isReadyToExport(): boolean {
                return isWidgetReady;
            },
        }));

        const isLandscape = useMemo(
            () => isPDFExport && pdfSettings?.landscape,
            [isPDFExport, pdfSettings]
        );
        const isLegalSize = useMemo(
            () => isPDFExport && pdfSettings?.paperSize === 'Legal',
            [isPDFExport, pdfSettings]
        );

        const calculateImageSize = () => {
            if (isPDFExport && !isLandscape) {
                return isLegalSize ? '625px' : '450px';
            }

            if (isLandscape) {
                return '255px';
            }

            return '450px';
        };

        const calculateCoverPageHeight = () => {
            if (isPDFExport) {
                if (isLandscape) {
                    return '575px';
                }

                if (!isLandscape) {
                    return isLegalSize ? '1020px' : '780px';
                }
            }

            return '100%';
        };

        const workflowGroupRenderer = (
            data: EntityReport,
            users: MentionableDataSource[] | undefined,
            roleType: string
        ) => {
            const roles = getWorkflowRole(data, users, roleType);

            if (!roles.length) {
                return DASH_DASH;
            }

            return roles.map((user) => user.userName).join(', ');
        };

        const fullPropertyAddress = [
            propertyInfo?.display_address,
            propertyInfo?.city,
            `${propertyInfo?.state || ''} ${propertyInfo?.postal_code || ''}`,
        ]
            .filter(Boolean)
            .join(', ');

        if (!propertyInfo) {
            return <></>;
        }

        return (
            <div
                style={
                    isPDFExport
                        ? {
                              height: calculateCoverPageHeight(),
                              marginTop: isPDFExport ? '-30px' : '0',
                          }
                        : {
                              minHeight: '100%',
                          }
                }
            >
                <div
                    style={{
                        border: '2px solid #c3ced9',
                        padding: '24px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                    }}
                >
                    <h1 style={{ marginBottom: 6 }}>
                        {propertyInfo?.display_name}
                    </h1>
                    <p style={{ marginBottom: 6 }}>{fullPropertyAddress}</p>
                    <h2 style={{ margin: 6 }}>{report?.name ?? ''}</h2>

                    <div
                        style={{
                            display: coverPageSettings?.propertyPhoto
                                ? 'block'
                                : 'none',
                        }}
                    >
                        {!isImageError ? (
                            <div
                                style={{
                                    overflow: 'hidden',
                                    position: 'relative',
                                    height: calculateImageSize(),
                                    width: '600px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <img
                                    src={`data:image/jpeg;base64,${entityPhotoData}`}
                                    alt={propertyInfo.display_name}
                                    style={{
                                        margin: '0 auto',
                                        maxWidth: isPDFExport ? '80%' : '100%',
                                        objectFit: 'contain',
                                        position: 'absolute',
                                        maxHeight: isPDFExport ? '90%' : '100%',
                                    }}
                                    onLoad={() => setIsImageLoaded(true)}
                                />
                            </div>
                        ) : null}
                    </div>

                    <div
                        style={{
                            marginTop: !isImageError ? '12px' : '24px',
                        }}
                    >
                        <p
                            style={{
                                display: coverPageSettings?.dueDate
                                    ? 'block'
                                    : 'none',
                            }}
                        >
                            <span
                                style={{
                                    width: 100,
                                    display: 'inline-block',
                                    fontWeight: 'bold',
                                }}
                            >
                                Due Date:
                            </span>{' '}
                            {entityReport?.due_date
                                ? format(
                                      new Date(entityReport.due_date),
                                      'MMMM dd, yyyy'
                                  )
                                : DASH_DASH}
                        </p>
                        <p
                            style={{
                                display: coverPageSettings?.assignees
                                    ? 'block'
                                    : 'none',
                                maxWidth: isPDFExport ? 'auto' : '500px',
                            }}
                        >
                            <span
                                style={{
                                    width: 100,
                                    display: 'inline-block',
                                    fontWeight: 'bold',
                                }}
                            >
                                Assignees:
                            </span>{' '}
                            {entityReport &&
                                workflowGroupRenderer(
                                    entityReport,
                                    users,
                                    WORKFLOW_ASSIGNEE
                                )}
                        </p>

                        <p
                            style={{
                                display: coverPageSettings?.reviewers
                                    ? 'block'
                                    : 'none',
                                maxWidth: isPDFExport ? 'auto' : '500px',
                            }}
                        >
                            <span
                                style={{
                                    width: 100,
                                    display: 'inline-block',
                                    fontWeight: 'bold',
                                }}
                            >
                                Reviewers:
                            </span>{' '}
                            {entityReport &&
                                workflowGroupRenderer(
                                    entityReport,
                                    users,
                                    WORKFLOW_REVIEWER
                                )}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
);

export default CoverPageReportWidget;
