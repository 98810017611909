import React from 'react';
import { css } from 'emotion';
import { StatisticsCard } from 'waypoint-react';

const contentStyle = css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

interface LeaseSummaryCardProps {
    content: JSX.Element[];
    tooltip: any;
    loading: boolean;
    label: string;
    error: boolean;
    style: React.CSSProperties;
}

const LeaseSummaryCard = ({
    content,
    loading,
    label,
    error,
    style,
    ...props
}: LeaseSummaryCardProps) => {
    const shouldDisplayMetric = content.length > 1;

    return (
        <StatisticsCard
            style={style}
            loading={loading}
            title={label}
            extra={props.tooltip && <props.tooltip />}
            error={error}
            metric={shouldDisplayMetric && <div>{content[0]}</div>}
            value={
                <div className={contentStyle}>
                    {content[content.length - 1]}
                </div>
            }
        />
    );
};

export default LeaseSummaryCard;
