import { API_URL } from 'config/constants';

const uploadUserProfilePicture = async (userId: number, photo: FormData) => {
    const response = await fetch(
        `${API_URL}/users/${userId}/update-profile-picture`,
        {
            method: 'POST',
            credentials: 'include',
            body: photo,
        }
    );

    if (!response.ok) {
        throw new Error('Failed');
    }

    const data = await response.json();

    return data;
};

export default uploadUserProfilePicture;
