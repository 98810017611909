import { getDataAvailabilityDate } from 'waypoint-requests';
import parseDate from 'date-fns/parse';
import useSWR from 'swr';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';

export const useGetDataAvailabilityDate = (
    entityCodes: string[]
): DataHookResponse<Date> => {
    const { data, error, mutate } = useSWR(
        entityCodes.length
            ? `/as-of-date/${entityCodes.sort().join(',')}`
            : null,
        async () => {
            const { data } = await getDataAvailabilityDate(entityCodes).then(
                (resp) => resp.json()
            );

            const earliestDateAvailable = data[0]?.period_end;
            return parseDate(earliestDateAvailable, 'yyyy-MM-dd', new Date());
        }
    );

    return {
        isLoading: data === undefined,
        isError: !!error,
        data,
        mutate,
    };
};
