import React, { useEffect, useState } from 'react';

import {
    ACTIVE_GLOBAL_FILTER_KEY,
    SAVED_FILTERS_KEY,
} from 'components/app/global-filter-drawer/GlobalFilterConstants';
import { SavedFilter, SavedFiltersContext } from 'contexts';
import {
    getGlobalFilters,
    createGlobalFilter,
    deleteGlobalFilter,
    updateGlobalFilter,
} from 'waypoint-requests';
import { useClientPrefixedLocalStorage } from 'waypoint-hooks';

export const SavedFiltersProvider = (props: any) => {
    const [savedFilters, setSavedFilters] = useClientPrefixedLocalStorage<
        SavedFilter[] | null
    >(SAVED_FILTERS_KEY, null);

    const [, setActiveFilter] =
        useClientPrefixedLocalStorage<SavedFilter | null>(
            ACTIVE_GLOBAL_FILTER_KEY,
            null
        );

    const [isSavedFiltersError, setIsSavedFiltersError] =
        useState<boolean>(false);

    const [isSavedFiltersLoading, setIsSavedFiltersLoading] =
        useState<boolean>(true);

    useEffect(() => {
        async function fetchGlobalFilters() {
            setIsSavedFiltersError(false);
            setIsSavedFiltersLoading(true);

            try {
                const globalFilters = await getGlobalFilters();
                setSavedFilters(globalFilters);
            } catch (e) {
                setSavedFilters(null);
                setIsSavedFiltersError(true);
            } finally {
                setIsSavedFiltersLoading(false);
            }
        }

        fetchGlobalFilters();
    }, []);

    const saveNewFilter = async (
        draftFilter: SavedFilter
    ): Promise<SavedFilter> => {
        setIsSavedFiltersLoading(true);
        try {
            if (draftFilter.id) {
                throw new Error('ID cannot be set when creating a filter');
            }

            if (!draftFilter.reference_type) {
                throw new Error('No permissions set on filter');
            }

            const savedFilter: SavedFilter =
                await createGlobalFilter(draftFilter);

            if (savedFilters) {
                setSavedFilters([...savedFilters, savedFilter]);
            } else {
                setSavedFilters([savedFilter]);
            }
            setActiveFilter(savedFilter);

            return savedFilter;
        } finally {
            setIsSavedFiltersLoading(false);
        }
    };

    const updateFilter = async (filterToUpdate: SavedFilter): Promise<void> => {
        if (!filterToUpdate.id) {
            throw new Error('Attempting to update an unsaved filter');
        }

        setIsSavedFiltersLoading(true);

        try {
            const filterId = await updateGlobalFilter(filterToUpdate);

            const index =
                savedFilters?.findIndex((f) => f.id === filterId) ?? -1;

            if (!savedFilters || index < 0) {
                throw new Error('Unable to update filters locally');
            }

            const updatedFilters = [
                ...savedFilters.slice(0, index),
                filterToUpdate,
                ...savedFilters.slice(index + 1),
            ];

            setActiveFilter(filterToUpdate);
            setSavedFilters(updatedFilters);
        } finally {
            setIsSavedFiltersLoading(false);
        }
    };

    const deleteFilter = async (id: string) => {
        setIsSavedFiltersLoading(true);

        try {
            await deleteGlobalFilter(id);

            setSavedFilters(
                savedFilters ? savedFilters.filter((f) => f.id !== id) : []
            );
        } finally {
            setActiveFilter(null);
            setIsSavedFiltersLoading(false);
        }
    };

    return (
        <SavedFiltersContext.Provider
            value={{
                savedFilters,
                isSavedFiltersError,
                isSavedFiltersLoading,
                saveNewFilter,
                updateFilter,
                deleteFilter,
            }}
        >
            {props.children}
        </SavedFiltersContext.Provider>
    );
};
