import PropTypes from 'prop-types';
import React from 'react';
import { isEmpty, get, pick } from 'lodash';
import { formatDateRangeForCopy } from 'waypoint-utils';
import { ChartTableSwitchCard, ActionButtons } from 'waypoint-react';
import { dateSort } from 'utils/tables/sorters';
import { getPeriodicityLabelFor } from './utils';
import { Legend, LineLegendItem } from 'components/style/legends'; // TODO: move to waypoint-react
import { OvertimeTable } from 'components/analytics/balance-sheet-overview/tables';
import { OvertimeChart } from 'components/analytics/balance-sheet-overview/charts';

/**
 * Card commponent that renders a Overtime Chart and Overtime Table. See ChartTableSwitchCard for abstracted view switch, loading, and empty state logic.
 */
const OvertimeCard = ({
    data,
    selections,
    onClick,
    loading,
    downloadCsv,
    downloading,
    ...props
}) => {
    const accountMappingName = get(selections, 'accountMapping.name');
    const period = formatDateRangeForCopy(selections.period);
    const periodicityLabel = getPeriodicityLabelFor(selections.periodicity);
    const sortedData = data.sort((a, b) =>
        dateSort(b.period_end, a.period_end),
    );
    const gridProps = pick(props, ['data-grid', 'style']);
    return (
        <ChartTableSwitchCard
            {...gridProps}
            loading={loading}
            extra={
                <ActionButtons
                    onClick={onClick}
                    loading={loading}
                    downloadCsv={downloadCsv}
                    downloading={downloading}
                />
            }
            title={`Over Time: ${accountMappingName}`}
            subheading={`${period} by ${periodicityLabel}`}
            empty={isEmpty(data)}
            legend={
                <Legend style={{ width: '200px', marginRight: '20px' }}>
                    <LineLegendItem
                        color="#3588BD" // TODO: define these in theme
                        label="Actual"
                    />
                </Legend>
            }
            table={
                <OvertimeTable
                    data={sortedData}
                    displayType={selections.displayType}
                    periodicity={selections.periodicity}
                />
            }
            chart={
                <OvertimeChart
                    data={sortedData}
                    displayType={selections.displayType}
                    periodicity={selections.periodicity}
                />
            }
            data-testid="component-overtime-card"
        />
    );
};

OvertimeCard.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    selections: PropTypes.shape({
        accountMapping: PropTypes.shape({
            name: PropTypes.string,
            code: PropTypes.string,
        }),
        period: PropTypes.arrayOf(PropTypes.object),
        displayType: PropTypes.string,
    }),
};

export default OvertimeCard;
