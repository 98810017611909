import { API_URL } from 'config/constants';
import { convertObjKeysToCamelCase } from 'waypoint-utils';

const getUserEntityPermission = async (userId: string) => {
    const response = await fetch(
        `${API_URL}/entity-permissions/user/${userId}`,
        {
            credentials: 'include',
        }
    );

    if (!response.ok) {
        throw new Error('FAILED');
    }

    const { data } = await response.json();
    return convertObjKeysToCamelCase(data);
};

export default getUserEntityPermission;
