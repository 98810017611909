import { merge } from 'lodash';
import { ShortcutManager } from 'react-shortcuts';

export const keyMap = {
    APP: {
        PORTFOLIO: 'alt+a', // all
        GROUPS: 'alt+g', // groups
        PROPERTIES: 'alt+p', // properties
        NEW_GROUP: 'alt+n', // new
        TOGGLE_KEYMAP: 'alt+t', // toggle
    },
    ANALYTICS: {
        OVERVIEW: 'o',
        EXPENSE: 'e',
        YEAR_OVER_YEAR: 'y',
        OPEN_OPPORTUNITY: 'alt+o',
    },
    TABLE: {
        NEXT_ROW: 'down',
        PREVIOUS_ROW: 'up',
        NEXT_COLUMN: 'right',
        PREVIOUS_COLUMN: 'left',
        TOGGLE_STATE: ['enter', 'space'],
    },
    COMMENTS: {
        SEND: {
            osx: 'command+enter',
            linux: 'alt+enter',
            windows: 'alt+enter',
        },
    },
};

export const keyMap2 = merge({}, keyMap, {
    ANALYTICS: {
        OVERVIEW: 'alt+o',
        EXPENSE: 'alt+e',
        YEAR_OVER_YEAR: 'alt+y',
        OPEN_OPPORTUNITY: 'shift+alt+o',
    },
});

export default new ShortcutManager(keyMap);
