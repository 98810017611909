import React, { ReactElement } from 'react';
import { Popover } from 'antd';
import { AsOfDateInfo } from 'components/app/as-of-date';

/**
 * When hovered displays information about data dumps from our clients. API is pinged when tooltip "content" of the popover is rendered.
 * @return Popover with Icon as a child.
 */

interface AsOfDateIconProps {
    entityCode?: string;
}

export function AsOfDateIcon({ entityCode }: AsOfDateIconProps): ReactElement {
    return (
        <Popover
            id="as_of_date_popover"
            placement="bottomLeft"
            arrow={false}
            content={<AsOfDateInfo singleEntityCode={entityCode} />}
        >
            <i className="fa-regular fa-calendar fa-xl" />
        </Popover>
    );
}
