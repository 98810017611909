import React, { useState } from 'react';
import { Modal, message } from 'antd';
import {
    Upload,
    UploadFileInfo,
    UploadOnAddEvent,
    UploadOnRemoveEvent,
} from '@progress/kendo-react-upload';
import { hideKendoUploadElementsClass } from 'components/uploads/DocumentUploadsUtils';
import { uploadAttributes } from 'waypoint-requests';
import { KeyedMutator } from 'swr';
import { GetAttributesV2Response } from 'waypoint-requests/attributes/getAttributesV2';

interface AttributesUploadModalParams {
    isModalOpen: boolean;
    setIsModalOpen: (value: boolean) => void;
    mutateAttributes: KeyedMutator<GetAttributesV2Response>;
}

const AttributesUploadModal = ({
    isModalOpen,
    setIsModalOpen,
    mutateAttributes,
}: AttributesUploadModalParams) => {
    const [filesToUpload, setFilesToUpload] = useState<UploadFileInfo[]>([]);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [uploadErrors, setUploadErrors] = useState<string[]>([]);
    const onAddFile = (event: UploadOnAddEvent) => {
        if (!event.newState[0] || !event.newState[0].getRawFile) {
            return;
        }
        setUploadErrors([]);
        setFilesToUpload(event.newState);
    };

    const onRemove = (event: UploadOnRemoveEvent) => {
        setFilesToUpload([]);
    };

    const upload = async () => {
        setIsUploading(true);
        try {
            const filesData = new FormData();
            const file = filesToUpload[0];
            file.getRawFile && filesData.append('file', file.getRawFile());

            const errorList = await uploadAttributes(filesData);
            setUploadErrors(errorList);
            setFilesToUpload([]);
            if (!errorList.length) {
                setIsModalOpen(false);
                message.success('Attribute values updated successfully!');
                mutateAttributes();
            }
        } catch (e) {
            message.error('Error uploading attributes');
        } finally {
            setIsUploading(false);
        }
    };

    return (
        <Modal
            title={'Upload Attributes'}
            centered
            open={isModalOpen}
            okText={'Upload'}
            onCancel={() => setIsModalOpen(false)}
            onOk={() => upload()}
            okButtonProps={
                isUploading || !filesToUpload.length ? { disabled: true } : {}
            }
        >
            <Upload
                className={hideKendoUploadElementsClass}
                batch={false}
                multiple={false}
                restrictions={{
                    allowedExtensions: ['.csv'],
                }}
                accept={'.csv'}
                files={filesToUpload}
                withCredentials={false}
                autoUpload={false}
                onAdd={onAddFile}
                onRemove={onRemove}
                disabled={false}
                selectMessageUI={() => <>Select file</>}
            />
            {uploadErrors.length ? (
                uploadErrors.map((err) => (
                    <div style={{ color: 'red' }}>{err}</div>
                ))
            ) : (
                <></>
            )}
        </Modal>
    );
};

export default AttributesUploadModal;
