// import store from 'state';
import { ClientData } from 'waypoint-types/clients/ClientData';
import store from '../state/store';
import { FixAnyType } from '../waypoint-types';

// @see https://stackoverflow.com/a/57894749
export function useGetClientData<K extends keyof ClientData>(
    lookup: K,
): ClientData[K] {
    const clientObject: ClientData = (store.getState() as FixAnyType).user
        .clientObj;
    return clientObject[lookup];
}
