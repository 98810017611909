import React from 'react';
import { DatePicker } from 'waypoint-react';
import moment from 'moment';

interface AgedReceivablesDatePickerProps {
    period: string | undefined;
    setPeriod: (value: string | undefined) => void;
}

const AgedReceivablesDatePicker = ({
    period,
    setPeriod,
}: AgedReceivablesDatePickerProps) => {
    return (
        <DatePicker
            picker="month"
            style={{ width: '150px' }}
            format={'MMMM YYYY'}
            disabledDate={(date) => date.isAfter(moment().toISOString())}
            value={period ? moment(period, 'YYYY-MM-DD') : undefined}
            onChange={(value) => {
                if (value) {
                    setPeriod(value.format('YYYY-MM-DD'));
                } else {
                    setPeriod(undefined);
                }
            }}
            placeholder="Select month"
        />
    );
};

export default AgedReceivablesDatePicker;
