import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import defaultStylePP from './defaultStylePP';
import { OverlayTrigger, Button, Popover } from 'react-bootstrap';

const buttonStyle = {
    backgroundColor: 'white',
    border: 'none',
    outline: 'none',
    paddingBottom: '0px',
};

const tooltip = (
    <Popover id="overlay-popover-file-deletion">
        Oops! You can only delete files you have uploaded
    </Popover>
);

export const DeleteCell = ({ cellData, ...rest }) => {
    const { style } = rest;
    const { userUploaded, onDelete } = cellData;
    return (
        <div {...rest} style={style}>
            {userUploaded ? (
                <Button style={buttonStyle}>
                    <i
                        style={{ color: 'red', marginBottom: '0px' }}
                        className="fa-solid fa-trash"
                        onClick={onDelete}
                    />
                </Button>
            ) : (
                <OverlayTrigger placement="right" overlay={tooltip}>
                    <i
                        style={{ color: 'grey', marginBottom: '0px' }}
                        className="fa-solid fa-trash"
                    />
                </OverlayTrigger>
            )}
        </div>
    );
};

DeleteCell.propTypes = {
    cellData: PropTypes.object,
};

// TODO (Colby): Add Prop Types to Download Cell
export const DeleteCellRenderer = defaultStylePP(DeleteCell);
export default DeleteCellRenderer;
