import { API_URL } from 'config/constants';
import { SelectedDataLevel } from 'waypoint-types';

const getAccountGraph = async (
    accountGraphCode = 'income_statement',
    selectedDataLevel: SelectedDataLevel
) => {
    const response = await fetch(`${API_URL}/account-graph`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            account_graph_code: accountGraphCode,
            selected_data_level: selectedDataLevel,
        }),
    });
    if (!response.ok) {
        throw new Error('Failed');
    }
    const { data } = await response.json();
    return data;
};

export default getAccountGraph;
