import Cookies from 'js-cookie';
import { useLocalStorage } from './useLocalStorage';
import { Dispatch, SetStateAction } from 'react';

type SetValue<T> = Dispatch<SetStateAction<T>>;

/**
 * Prefixes the localStorage key with the active client's unique numeric identifier.
 * @see https://usehooks-typescript.com/react-hook/use-local-storage
 * @param key
 * @param initialValue
 */
export function useClientPrefixedLocalStorage<T>(
    key: string,
    initialValue: T
): [T, SetValue<T>] {
    // Use the cookie of the current client as the prefix
    const clientId = Cookies.get('CLIENT_ID_COOKIE');

    if (!clientId) {
        throw new Error(
            'Cookie missing: CLIENT_ID_COOKIE. Application must be bootstrapped to call useClientPrefixedLocalStorage.'
        );
    }

    return useLocalStorage(`${clientId ?? ''}:${key}`, initialValue);
}
