import { textRenderer } from 'utils/tables/renderers';
import { Checkbox } from 'antd';
import { stringSort } from 'utils/tables/sorters';

export const ACCESS_ALL_ENTITIES = 1;
export const CHOOSE_ENTITY = 2;

export const entityPermissionColumns = (onChange, entityCodes) => {
    return [
        {
            title: 'Selected',
            align: 'center',
            width: 100,
            render: (node) => {
                let isChecked;
                if (entityCodes.includes(node.id)) {
                    isChecked = true;
                } else {
                    isChecked = false;
                }

                return (
                    <Checkbox
                        value={node.id}
                        checked={isChecked}
                        onChange={onChange}
                    />
                );
            },
        },
        {
            title: 'Entity',
            align: 'left',
            dataIndex: 'display_name',
            render: textRenderer,
            sorter: (a, b) => stringSort(b.display_name, a.display_name),
        },
    ];
};
