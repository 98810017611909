import React, { useState } from 'react';
import useSWR from 'swr';
import { RentRollCard } from 'components/leases/expirations';
import { getOccupancyHistory } from 'waypoint-requests';
import OccupancyTrendCard from 'components/leases/expirations/cards/occupancy-trend/OccupancyTrendCard';
import TopTenantsCard from 'components/leases/tenants/top-tenants/TopTenantsCard';
import LeasesExpirationCard from 'components/leases/expirations/cards/leases-expiration/LeasesExpirationCard';
import UnitMixCard from 'components/leases/unit-mix/UnitMixCard';
import AgedReceivablesCard, {
    AGED_RECEIVABLES_CARD_TYPE,
} from 'components/agedReceivables/AgedReceivablesCard';
import { useSelectedDataLevel } from 'waypoint-hooks';
import SubsectionMenu from './SubsectionMenu';
import {
    LEASES_EXPIRATIONS,
    LEASES_OCCUPANCY_TREND,
    LEASES_RENT_ROLL,
    LEASES_TOP_TENANTS,
    LEASES_UNIT_MIX,
} from './constants';
import { ItemType } from 'antd/es/menu/interface';

export const LeasesSection = ({
    entityCode,
    hasAgedReceivablesEnabled,
    hasOccupancyTrendEnabled,
    hasUnitMixEnabled,
    menuItems,
    onSetSubsection,
}: {
    entityCode: string;
    hasAgedReceivablesEnabled: boolean;
    hasOccupancyTrendEnabled: boolean;
    hasUnitMixEnabled: boolean;
    menuItems: ItemType[];
    onSetSubsection: (subsection: string) => void;
}) => {
    const selectedDataLevel = useSelectedDataLevel();
    const defaultMenuKey = menuItems[0]?.key as string;
    const [menuSelection, setMenuSelection] = useState<string>(defaultMenuKey);

    const {
        data: occupancyHistory,
        error: occupancyHistoryError,
        isValidating: occupancyHistoryValidating,
    } = useSWR(`${entityCode}-property-detail-occupancy-trend`, () =>
        getOccupancyHistory([entityCode]),
    );

    return (
        <>
            <SubsectionMenu
                menuItems={menuItems}
                setMenuSelection={setMenuSelection}
                onSetSubsection={onSetSubsection}
                defaultMenuKey={defaultMenuKey}
            />
            <div
                title={'Leases Section'}
                style={{ margin: '60px 0', padding: '0 24px' }}
                id="leases-section"
            >
                {hasAgedReceivablesEnabled &&
                    menuSelection === AGED_RECEIVABLES_CARD_TYPE && (
                        <div
                            id="aged-receivables"
                            style={{ marginTop: '60px' }}
                        >
                            <AgedReceivablesCard
                                selectedDataLevel={selectedDataLevel}
                                entityCodes={[entityCode]}
                                isPropertyProfilePage={true}
                            />
                        </div>
                    )}
                {hasOccupancyTrendEnabled &&
                    menuSelection === LEASES_OCCUPANCY_TREND && (
                        <OccupancyTrendCard
                            entityCode={entityCode}
                            occupancyHistory={occupancyHistory ?? []}
                            isDataLoading={
                                occupancyHistoryValidating ||
                                (!occupancyHistoryError && !occupancyHistory)
                            }
                            dataLoadError={occupancyHistoryError}
                        />
                    )}
                {menuSelection === LEASES_EXPIRATIONS && (
                    <div style={{ marginTop: '20px' }}>
                        <LeasesExpirationCard
                            boxShadow="none"
                            entityCodes={[entityCode]}
                            isPropertyProfile={true}
                        />
                    </div>
                )}
                {menuSelection === LEASES_RENT_ROLL && (
                    <div id="leases-rent-roll" style={{ marginTop: '20px' }}>
                        <RentRollCard
                            entityCode={entityCode}
                            hidePropertyColumns
                        />
                    </div>
                )}
                {menuSelection === LEASES_TOP_TENANTS && (
                    <div id="leases-top-tenants" style={{ marginTop: '20px' }}>
                        <TopTenantsCard
                            entityCodes={[entityCode]}
                            isPropertyProfilePage={true}
                        />
                    </div>
                )}
                {hasUnitMixEnabled && menuSelection === LEASES_UNIT_MIX && (
                    <div id="leases-unit-mix" style={{ marginTop: '20px' }}>
                        <UnitMixCard
                            entityCode={entityCode}
                            hidePropertyColumns
                        />
                    </div>
                )}
            </div>
        </>
    );
};
