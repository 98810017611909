import { API_URL } from 'config/constants';
import { UserNotificationSettings } from 'waypoint-types';

export async function getUserNotificationSettings(): Promise<UserNotificationSettings> {
    const response = await fetch(`${API_URL}/users/notification-settings`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
    });

    if (!response.ok) {
        throw new Error('Failed to get notification settings');
    }

    const { data } = await response.json();

    return data as UserNotificationSettings;
}
