// @ts-nocheck
// This file is a result of nx update

import { message } from 'antd';
import { favorites as TYPES } from 'state/actionTypes';

export default class FavoritesService {
    saveFavorite(id, name, entityType, userID) {
        return (dispatch) => {
            const favoritesPayload = this.getFavoritesPayload(userID);
            const favoriteKey = this.getFavoriteKey(entityType, id);
            const favoriteData = {
                id,
                name,
                type: entityType,
                favoriteID: favoriteKey,
            };

            favoritesPayload[favoriteKey] = favoriteData;

            let status = 'success';

            try {
                this.updateFavoritesPayload(userID, favoritesPayload);
            } catch (e) {
                status = 'error';

                message.error(
                    'Error',
                    `We were unable to add this ${entityType} to your favorites. Please try again later and if the problem persists contact support.`,
                );
            }

            dispatch({
                type: TYPES.ADD_FAVORITE,
                status,
                payload: favoriteData,
            });
        };
    }

    removeFavorite(id, entityType, favoriteID, userID) {
        return (dispatch) => {
            const favoritesPayload = this.getFavoritesPayload(userID);
            delete favoritesPayload[favoriteID];

            let status = 'success';

            try {
                this.updateFavoritesPayload(userID, favoritesPayload);
            } catch (e) {
                status = 'error';

                message.error(
                    'Error',
                    `We were unable to remove this ${entityType} from your favorites. Please try again later and if the problem persists contact support.`,
                );
            }

            dispatch({
                type: TYPES.REMOVE_FAVORITE,
                status,
                payload: {
                    id,
                    type: entityType,
                },
            });
        };
    }

    getFavoriteKey(entityType, id) {
        return `${entityType}__${id}`;
    }

    getFavoritesPayload(userID) {
        const storageKey = this.getFavoritesStorageKey(userID);
        const existingPayloadString = localStorage.getItem(storageKey);

        return existingPayloadString ? JSON.parse(existingPayloadString) : {};
    }

    updateFavoritesPayload(userID, payload) {
        const storageKey = this.getFavoritesStorageKey(userID);
        localStorage.setItem(storageKey, JSON.stringify(payload));
    }

    getFavoritesStorageKey(userID) {
        return `waypointFavorites__${userID}`;
    }
}
