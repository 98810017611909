import { Drawer } from 'antd';
import { SelectedDataLevel } from 'waypoint-types';
import AgedReceivablesCard from './AgedReceivablesCard';
import { fullPageDrawerZIndex } from 'components/app/layout/AppLayoutUtils';

interface DelinquentTenantsDrawerProps {
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
    entityCode: string;
    selectedDataLevel: SelectedDataLevel;
    propertyName: string;
    period?: string;
}

const DelinquentTenantsDrawer = ({
    isVisible,
    setIsVisible,
    entityCode,
    selectedDataLevel,
    propertyName,
    period,
}: DelinquentTenantsDrawerProps) => {
    return (
        <Drawer
            title={`${propertyName} - Tenant Detail`}
            zIndex={fullPageDrawerZIndex}
            open={isVisible}
            onClose={() => setIsVisible(false)}
            width="100VW"
            footer={null}
        >
            <AgedReceivablesCard
                selectedDataLevel={selectedDataLevel}
                entityCodes={[entityCode]}
                isPropertyProfilePage={true}
                rollupPeriod={period}
            />
        </Drawer>
    );
};

export default DelinquentTenantsDrawer;
