import { API_URL } from 'config/constants';
import { CommentData } from 'waypoint-types';

/**
 * @param {string} commentThreadId - Comment thread id
 * @param {string} text - Contains the comment body
 * @param {string} parent_comment_id - Parent comment id (replies to comment)
 * @param {number[]} user_mentions - Array of user IDs mentioned in the comment
 * @param {string[]} account_mentions - Array of account IDs mentioned in the comment
 */
const createComment = async (
    commentThreadId: string,
    text: string,
    parent_comment_id: string | null,
    user_mentions?: number[],
    account_mentions?: string[],
    notificationRedirectUrl?: string,
    reportName?: string
): Promise<CommentData> => {
    const response = await fetch(`${API_URL}/comments/${commentThreadId}`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            text,
            parent_comment_id,
            user_mentions,
            account_mentions,
            notificationRedirectUrl,
            reportName,
        }),
    });

    const { data } = await response.json();

    return data as CommentData;
};

export default createComment;
