import { Avatar, message } from 'antd';
import AntDesignCard from 'antd/lib/card';
import {
    WORKFLOW_ASSIGNEE,
    WORKFLOW_REVIEWER,
} from 'components/financials/comparative-income-statement/constants';
import { Modal, Select } from 'waypoint-react';
import Button from 'antd/lib/button';
import { css } from 'emotion';
import theme from 'config/theme';
import { useEffect, useState } from 'react';
import { addOrRemoveWorkflowRoles } from 'waypoint-requests';
import { WorkflowRole } from 'waypoint-types';
import { getAvatarInitials } from 'components/financials/comparative-income-statement/banner/ComparisonSelectionsBannerUtils';
import { WorkflowRolesResponse } from 'waypoint-hooks/data-access/useGetWorkflowRoles';
import { KeyedMutator } from 'swr';
import { useGetMentionableUsers } from 'waypoint-hooks';

const workflowModalStyle = css`
    width: 350px;
    height: 450px;
    .ant-card-extra {
        display: flex;
        justify-content: flex-end;
    }
`;

const buttonStyle = css`
    &.ant-btn-primary:enabled {
        color: ${theme.colors.white} !important;
        background-color: ${theme.colors.blues.primary} !important;
        border-color: ${theme.colors.blues.outline} !important;
    }
`;

interface UserOption {
    value: number;
    label: string;
}

interface WorkflowModalParams {
    workflowRoleList: WorkflowRole[];
    mutateWorkflowRoles: KeyedMutator<WorkflowRolesResponse>;
    reportRole: string;
    setWorkflowModalIsOpen: (value: boolean) => void;
    userOptions: UserOption[];
    entityCode: string;
    reportType: string;
}

const WorkflowModal = ({
    workflowRoleList,
    mutateWorkflowRoles,
    reportRole,
    setWorkflowModalIsOpen,
    userOptions,
    entityCode,
    reportType,
}: WorkflowModalParams): JSX.Element => {
    const filteredUserOptions = () => {
        const localRoleUserIds = localWorkflowRoleList.map(
            (wf: WorkflowRole) => wf.userId,
        );
        return userOptions.filter(
            (opt: UserOption) => !localRoleUserIds.includes(opt.value),
        );
    };

    const userData = useGetMentionableUsers();

    const [localWorkflowRoleList, setLocalWorkflowRoleList] = useState<
        WorkflowRole[]
    >([...workflowRoleList]);

    const [validUserOptions, setValidUserOptions] = useState<UserOption[]>(
        filteredUserOptions(),
    );

    useEffect(() => {
        setValidUserOptions(filteredUserOptions());
    }, [localWorkflowRoleList]);

    const closeModal = () => {
        setWorkflowModalIsOpen(false);
    };

    const updateWorkflowRoles = async () => {
        const workflowRoleUserIds = workflowRoleList.map(
            (wf: WorkflowRole) => wf.userId,
        );

        const localRoleUserIds = localWorkflowRoleList.map(
            (lw: WorkflowRole) => lw.userId,
        );

        const addedUserIds = localRoleUserIds.filter(
            (id) => !workflowRoleUserIds.includes(id),
        );

        const removedRowIds = workflowRoleList
            .filter(
                (wf: WorkflowRole) =>
                    wf.dataRowId && !localRoleUserIds.includes(wf.userId),
            )
            .map((wf: WorkflowRole) => wf.dataRowId) as string[];

        const params = {
            entityCode,
            reportRole,
            reportType,
            workflowType: 'variance',
            addedUserIds,
            removedRowIds,
        };

        const updatedWorkflowRoles: WorkflowRolesResponse = {
            assignees: [],
            reviewers: [],
        };

        if (reportRole === WORKFLOW_REVIEWER) {
            updatedWorkflowRoles.reviewers = [...localWorkflowRoleList];
        }

        if (reportRole === WORKFLOW_ASSIGNEE) {
            updatedWorkflowRoles.assignees = [...localWorkflowRoleList];
        }

        await mutateWorkflowRoles(
            async (previousData) => {
                try {
                    await addOrRemoveWorkflowRoles(params);
                    return updatedWorkflowRoles;
                } catch (e) {
                    await message.error('Failed to update workflow roles.');
                }

                return previousData;
            },
            {
                rollbackOnError: true,
                optimisticData: updatedWorkflowRoles,
            },
        );

        setWorkflowModalIsOpen(false);
    };

    const getUsername = (userId: number) => {
        return userOptions.filter(
            (user: UserOption) => user.value === userId,
        )[0].label;
    };

    const addLocalRoleUsers = (userId: number) => {
        if (
            !localWorkflowRoleList
                .map((lw: WorkflowRole) => lw.userId)
                .includes(userId)
        ) {
            localWorkflowRoleList.push({
                userId,
                userName: getUsername(userId),
                dataRowId: null,
                profile_image_url: userData?.find((user) => user.id === userId)
                    ?.profile_image_url,
            });
            setLocalWorkflowRoleList([...localWorkflowRoleList]);
        }
    };

    const removeLocalRoleUsers = (userId: number) => {
        setLocalWorkflowRoleList(
            localWorkflowRoleList.filter((lw) => lw.userId !== userId),
        );
    };

    return (
        <Modal zIndex={5} className={workflowModalStyle}>
            <AntDesignCard
                title={`Add/Edit ${
                    reportRole === WORKFLOW_REVIEWER ? 'Reviewers' : 'Assignees'
                }`}
                className={workflowModalStyle}
                data-testid="ranking-menu"
                extra={
                    <i
                        className="fa-solid fa-times"
                        onClick={closeModal}
                        style={{ cursor: 'pointer', fontSize: '2em' }}
                    />
                }
                style={{ position: 'relative' }}
            >
                <div style={{ maxHeight: '290px', overflowY: 'auto' }}>
                    {localWorkflowRoleList.map((w: WorkflowRole) => (
                        <div
                            style={{
                                fontSize: '14px',
                                justifyContent: 'space-between',
                                display: 'flex',
                                marginBottom: '7px',
                            }}
                        >
                            <span
                                style={{
                                    textOverflow: 'ellipsis',
                                    overflowX: 'hidden',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                <Avatar
                                    style={{
                                        marginRight: '15px',
                                        fontSize: '12px',
                                    }}
                                    src={w.profile_image_url ?? null}
                                >
                                    {getAvatarInitials(w.userName ?? '')}
                                </Avatar>
                                {w.userName}
                            </span>
                            <span>
                                <i
                                    className="fa-solid fa-times"
                                    style={{
                                        cursor: 'pointer',
                                        color: 'red',
                                        fontSize: '18px',
                                        marginLeft: '25px',
                                        marginTop: '7px',
                                        marginRight: '20px',
                                    }}
                                    onClick={() =>
                                        removeLocalRoleUsers(w.userId)
                                    }
                                />
                            </span>
                        </div>
                    ))}
                </div>
                <div
                    style={{
                        position: 'absolute',
                        bottom: '5px',
                        width: '94%',
                        alignItems: 'center',
                        overflowX: 'hidden',
                    }}
                >
                    <hr style={{ marginBottom: '0px' }} />
                    <Select
                        style={{
                            width: '100%',
                        }}
                        key={validUserOptions.length}
                        options={validUserOptions}
                        placeholder={`Add ${reportRole}`}
                        onChange={(value: number) => addLocalRoleUsers(value)}
                        placement="topLeft"
                    />
                    <Button
                        className={buttonStyle}
                        style={{
                            marginTop: '5px',
                            marginRight: '20px',
                            width: '100%',
                        }}
                        type="primary"
                        onClick={() => updateWorkflowRoles()}
                    >
                        Save and Close
                    </Button>
                </div>
            </AntDesignCard>
        </Modal>
    );
};

export default WorkflowModal;
