import { FC, useEffect, useState } from 'react';

export const PropertyMapMarker: FC<
    google.maps.MarkerOptions & {
        onClick?: () => void;
    }
> = ({ onClick, ...options }) => {
    const [marker, setMarker] = useState<google.maps.Marker>();

    if (onClick) {
        marker?.addListener('click', onClick);
    }

    useEffect(() => {
        if (!marker) {
            setMarker(new google.maps.Marker());
        }

        // remove marker from map on unmount
        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker]);

    useEffect(() => {
        if (marker) {
            marker.setOptions(options);
        }
    }, [marker, options]);

    return null;
};
