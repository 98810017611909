import { message } from 'antd';
import useSWR from 'swr';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { getClientDefinedTypes } from 'waypoint-requests';
import { CapitalProjectClientDefinedType } from 'waypoint-types';

export const useGetCapitalProjectClientDefinedTypes = (
    type: string
): DataHookResponse<CapitalProjectClientDefinedType[]> => {
    const { data, error, mutate } = useSWR(
        `/api/capital-project/client-defined-types/${type}`,
        () => getClientDefinedTypes(type),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
            onError: () => {
                message.error('Failed to fetch client defined types');
            },
            shouldRetryOnError: false,
        }
    );
    return {
        isLoading: !data,
        isError: !!error,
        data,
        mutate,
    };
};
