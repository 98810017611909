import React, { useState } from 'react';
import {
    CheckOutlined,
    CloseOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import {
    Upload,
    UploadFileInfo,
    UploadFileRestrictions,
    UploadOnAddEvent,
    UploadOnRemoveEvent,
} from '@progress/kendo-react-upload';
import { Button, Modal, message } from 'antd';
import { uploadDocument } from 'waypoint-requests';
import { DocumentUpload } from 'waypoint-types';
import {
    DEFAULT_FILE_SIZE_LIMIT,
    MB_SIZE,
    SINGLE_FILE_UPLOAD,
    helperText,
    hideKendoUploadElementsClass,
    invalidFileSizes,
} from './DocumentUploadsUtils';
import theme from 'config/theme';
import { KeyedMutator } from 'swr';

interface DocumentsSectionProps {
    referenceId: string;
    referenceType: string;
    documentsUploaded: DocumentUpload[];
    isModalOpen?: boolean;
    setIsModalOpen?: (value: boolean) => void;
    restrictions: UploadFileRestrictions;
    disabled?: boolean;
    isModal: boolean;
    maxFilesAllowed?: number;
    mutateMany?: KeyedMutator<DocumentUpload[]>;
    mutateOne?: KeyedMutator<DocumentUpload>;
}

interface FailedFileUploadInfo {
    fileName: string;
    error: string;
}

export const DocumentUploads = ({
    referenceType,
    referenceId,
    documentsUploaded,
    mutateMany,
    mutateOne,
    isModalOpen,
    setIsModalOpen,
    restrictions,
    disabled,
    isModal,
    maxFilesAllowed,
}: DocumentsSectionProps) => {
    const [isUploading, setIsUploading] = useState<boolean>();
    const [filesToUpload, setFilesToUpload] = useState<UploadFileInfo[]>([]);
    const [isUploadButtonAvailable, setIsUploadButtonAvailable] =
        useState<boolean>(false);
    const [failedUploads, setFailedUploads] = useState<FailedFileUploadInfo[]>(
        [],
    );
    const [successfulUploads, setSuccessUploads] = useState<DocumentUpload[]>(
        [],
    );

    const isMultipleFiles =
        maxFilesAllowed === undefined || maxFilesAllowed > SINGLE_FILE_UPLOAD;

    const invalidFileTypes = (): boolean => {
        if (!restrictions?.allowedExtensions?.length) {
            return false;
        }

        return filesToUpload.some(
            (file: UploadFileInfo) =>
                file.extension?.length &&
                !restrictions?.allowedExtensions?.includes(file.extension),
        );
    };

    const upload = async () => {
        try {
            if (!filesToUpload.length) {
                return;
            }

            if (invalidFileSizes(restrictions, filesToUpload)) {
                message.error(
                    `File size should not exceed ${
                        restrictions?.maxFileSize ?? DEFAULT_FILE_SIZE_LIMIT
                    } MB`,
                );
                return;
            }

            setIsUploading && setIsUploading(true);

            const failedUploadsFileNames: string[] = failedUploads.map(
                (failed: FailedFileUploadInfo) => failed.fileName,
            );
            const filesToRetry: UploadFileInfo[] = failedUploads.length
                ? filesToUpload.filter((file) =>
                      failedUploadsFileNames.includes(file.name),
                  )
                : [];

            const filesToProcess: UploadFileInfo[] = filesToRetry.length
                ? filesToRetry
                : filesToUpload;

            const filesData = new FormData();
            filesToProcess.forEach(
                (file) =>
                    file.getRawFile &&
                    filesData.append('files', file.getRawFile()),
            );

            const {
                successfulUploads: attemptSuccessfulUploads,
                failedUploads: attemptFailedUploads,
            } = await uploadDocument(filesData, referenceType, referenceId);

            if (!attemptFailedUploads.length) {
                setIsModalOpen && setIsModalOpen(false);
                setFilesToUpload([]);
            }

            if (attemptFailedUploads.length) {
                const attemptFailedFilenames: string[] =
                    attemptFailedUploads.map(
                        (failed: FailedFileUploadInfo) => failed.fileName,
                    );
                const remainingFiles: UploadFileInfo[] = filesToUpload.filter(
                    (file) => attemptFailedFilenames.includes(file.name),
                );
                setFilesToUpload(remainingFiles);
            }

            attemptFailedUploads.length
                ? message.warning('Some documents require attention.')
                : message.success('Documents uploaded successfully');

            setSuccessUploads(attemptSuccessfulUploads);
            setFailedUploads(attemptFailedUploads);

            return [
                ...(attemptSuccessfulUploads ?? []),
                ...(documentsUploaded ?? []),
            ];
        } catch (e) {
            message.error('Error uploading document');
            throw e;
        } finally {
            setIsUploading && setIsUploading(false);
        }
    };

    const onUploadFiles = () => {
        if (mutateMany) {
            mutateMany(upload, {
                rollbackOnError: true,
                populateCache: true,
                revalidate: false,
            });
        }

        if (mutateOne) {
            mutateOne(
                async () => {
                    const correctUploads = await upload();
                    return correctUploads ? correctUploads[0] : undefined;
                },
                {
                    rollbackOnError: true,
                    populateCache: true,
                    revalidate: false,
                },
            );
        }
    };

    const onRemove = (event: UploadOnRemoveEvent) => {
        const affectedFileUids = event.affectedFiles.map((file) => file.uid);
        setFilesToUpload(
            filesToUpload.filter(
                (file) => !affectedFileUids.includes(file.uid),
            ),
        );
    };

    const onAddFile = (event: UploadOnAddEvent) => {
        if (!event.newState[0] || !event.newState[0].getRawFile) {
            return;
        }

        const totalFiles =
            (documentsUploaded?.length || 0) + event.newState.length;

        if (
            maxFilesAllowed !== undefined &&
            maxFilesAllowed !== SINGLE_FILE_UPLOAD &&
            totalFiles > maxFilesAllowed
        ) {
            message.error(`You can only upload up to ${maxFilesAllowed} files`);
            event.newState = event.newState.slice(
                0,
                maxFilesAllowed - (documentsUploaded?.length || 0),
            );
        }

        setFilesToUpload(event.newState);
        setIsUploadButtonAvailable(true);
    };

    const onCloseModal = (): void => {
        setIsModalOpen && setIsModalOpen(false);
        setIsUploadButtonAvailable(false);
        setSuccessUploads([]);
        setFailedUploads([]);
        setFilesToUpload([]);
    };

    const formattedAcceptedFileTypes = (): string => {
        const dotsRegex = /\./g;
        const types = restrictions?.allowedExtensions
            ?.join(', ')
            .replace(dotsRegex, '')
            .toUpperCase();

        return types?.length ? ` ${types} only.` : '';
    };

    const renderHelperText = () => {
        if (
            maxFilesAllowed !== SINGLE_FILE_UPLOAD &&
            documentsUploaded?.length === maxFilesAllowed
        ) {
            return <p className={helperText}>Maximum files uploaded</p>;
        }

        const maxFilesMessage =
            maxFilesAllowed !== undefined &&
            maxFilesAllowed !== SINGLE_FILE_UPLOAD
                ? `Max ${maxFilesAllowed} files allowed, `
                : '';
        const maxSizeMessage = `${
            (restrictions?.maxFileSize ?? DEFAULT_FILE_SIZE_LIMIT) / MB_SIZE
        } MB max size.`;
        const fileTypesMessage =
            (restrictions.allowedExtensions?.length || 0) <
                DEFAULT_FILE_SIZE_LIMIT ||
            !restrictions.allowedExtensions?.length
                ? formattedAcceptedFileTypes()
                : '';

        return (
            <p className={helperText}>
                {maxFilesMessage}
                {maxSizeMessage}
                {fileTypesMessage}
            </p>
        );
    };

    const renderUpload = (): JSX.Element => {
        return (
            <>
                <Upload
                    className={hideKendoUploadElementsClass}
                    batch={false}
                    multiple={isMultipleFiles}
                    restrictions={restrictions}
                    accept={restrictions?.allowedExtensions?.join(', ')}
                    files={filesToUpload}
                    withCredentials={false}
                    autoUpload={false}
                    onAdd={onAddFile}
                    onRemove={onRemove}
                    disabled={disabled}
                    selectMessageUI={() => (
                        <>Select file{isMultipleFiles && 's'}</>
                    )}
                />
                {renderHelperText()}
                {!!failedUploads?.length && (
                    <div style={{ marginTop: '20px' }}>
                        {!!successfulUploads?.length && (
                            <div>
                                <div style={{ marginBottom: '5px' }}>
                                    <CheckOutlined
                                        style={{
                                            color: theme.colors.green,
                                        }}
                                    />{' '}
                                    <span
                                        style={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Successful uploads
                                    </span>
                                </div>
                                <div>
                                    {successfulUploads?.map((uploaded) => {
                                        return <p>{uploaded.document_url}</p>;
                                    })}
                                </div>
                            </div>
                        )}

                        <div style={{ marginTop: '20px' }}>
                            <div style={{ marginBottom: '5px' }}>
                                <CloseOutlined
                                    style={{ color: theme.colors.red }}
                                />{' '}
                                <span
                                    style={{
                                        fontWeight: 'bold',
                                        marginBottom: '5px',
                                    }}
                                >
                                    Failed uploads
                                </span>
                            </div>
                            <div>
                                {failedUploads?.map((failed) => {
                                    return (
                                        <p>
                                            {failed.fileName} {failed.error}
                                        </p>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    };

    const renderUploadButton = (isRetry = false) => {
        if (
            (!filesToUpload.length && !failedUploads.length) ||
            !isUploadButtonAvailable
        ) {
            return <></>;
        }

        return (
            <Button
                loading={isUploading}
                disabled={
                    disabled ||
                    isUploading ||
                    (maxFilesAllowed !== SINGLE_FILE_UPLOAD &&
                        documentsUploaded?.length === maxFilesAllowed) ||
                    invalidFileSizes(restrictions, filesToUpload) ||
                    invalidFileTypes()
                }
                type="primary"
                icon={<UploadOutlined />}
                style={{ marginTop: 6, float: 'right' }}
                onClick={() => onUploadFiles()}
            >
                {isUploading ? 'Uploading...' : isRetry ? 'Retry' : 'Upload'}
            </Button>
        );
    };

    const renderModalUpload = (): JSX.Element => {
        return (
            <Modal
                data-testid="document-uploads-modal"
                centered={true}
                width="600px"
                bodyStyle={{
                    height: 'max-content',
                    minHeight: '170px',
                }}
                open={isModalOpen}
                maskClosable={false}
                footer={null}
                title={'Upload files'}
                onCancel={onCloseModal}
                closable={!isUploading}
            >
                {renderUpload()}
                {isUploadButtonAvailable && (
                    <div
                        style={{
                            marginTop: '15px',
                            marginBottom: '5px',
                            height: '25px',
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        {renderUploadButton(!!failedUploads.length)}
                    </div>
                )}
            </Modal>
        );
    };

    const renderInlineUpload = (): JSX.Element => {
        return (
            <>
                {renderUpload()}
                {renderUploadButton()}
            </>
        );
    };

    return isModal ? renderModalUpload() : renderInlineUpload();
};
