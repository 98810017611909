import { PlusOutlined } from '@ant-design/icons';
import { Button, message, Select, Tooltip, Modal } from 'antd';
import { useEffect, useState } from 'react';
import {
    createClientDefinedType,
    updateClientDefinedType as updateClientDefinedTypeRequest,
    deleteClientDefinedType as deleteClientDefinedTypeRequest,
} from 'waypoint-requests';
import {
    CapitalProject,
    CapitalProjectClientDefinedType,
    TypeOption,
} from 'waypoint-types';
import {
    canShowColorOptionOrPicker,
    colorOfTheTypeSelect,
    typeOptionSelectStyle,
    typeOptionStyle,
} from './PlanningCapitalProjectUtils';
import OptionLabel from './OptionLabel';
import { Dictionary } from 'ts-essentials';
import theme from 'config/theme';
import { useGetCapitalProjectClientDefinedTypes } from 'waypoint-hooks';

const { Option } = Select;

interface PlanningCapitalProjectCategorySelectProps {
    type: string;
    rowData: CapitalProject | null;
    tableData?: CapitalProject[];
    onRefreshData?: () => void;
    setTypesToSend?: (
        types: Dictionary<CapitalProjectClientDefinedType | null>,
    ) => void;
    typesToSend?: Dictionary<CapitalProjectClientDefinedType | null>;
    isAllowedToEdit?: boolean;
    value?: string | undefined;
    onChange?: (value: string | undefined) => void;
}

const PlanningCapitalProjectsCategorySelect = ({
    type,
    rowData,
    tableData,
    isAllowedToEdit,
    onRefreshData,
    typesToSend,
    setTypesToSend,
    value,
    onChange,
}: PlanningCapitalProjectCategorySelectProps) => {
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [selectedTypeToEdit, setSelectedTypeToEdit] = useState<string | null>(
        null,
    );
    const [isAddingType, setIsAddingType] = useState<boolean>(false);
    const [newClientDefinedValueName, setNewClientDefinedValueName] =
        useState<string>('');
    const [newClientDefinedValueColor, setNewClientDefinedValueColor] =
        useState<string>('');
    const [typeOptions, setTypeOptions] = useState<TypeOption[] | null>(null);

    const setClientDefinedTypeOptions = (
        types: CapitalProjectClientDefinedType[],
    ) => {
        const clientDefinedTypeOptions = types
            .filter((c: CapitalProjectClientDefinedType) => c.type === type)
            .map((o: CapitalProjectClientDefinedType) => {
                return {
                    value: o.id ?? '',
                    label: o.value,
                    color: o.tag_color,
                    type: o.type,
                };
            });
        setTypeOptions(clientDefinedTypeOptions);
    };

    const { data: clientDefinedTypes, mutate: mutateClientDefinedTypes } =
        useGetCapitalProjectClientDefinedTypes(type);

    useEffect(() => {
        if (!typeOptions && clientDefinedTypes) {
            setClientDefinedTypeOptions(clientDefinedTypes);
        }
    }, [typeOptions, clientDefinedTypes]);

    const updateClientDefinedType = async (option: TypeOption) => {
        const updatedType = {
            type: type,
            value: newClientDefinedValueName || option.label,
            tag_color: newClientDefinedValueColor || option.color,
        };

        if (clientDefinedTypes) {
            mutateClientDefinedTypes(
                async () => {
                    try {
                        const result = await updateClientDefinedTypeRequest(
                            option.value,
                            updatedType,
                        );

                        setNewClientDefinedValueColor('');
                        setNewClientDefinedValueName('');

                        setSelectedTypeToEdit(null);

                        if (onRefreshData) {
                            onRefreshData();
                        }

                        message.success('Type updated successfully!');

                        setClientDefinedTypeOptions(
                            clientDefinedTypes.map(
                                (t: CapitalProjectClientDefinedType) => {
                                    return t.id === result.id ? result : t;
                                },
                            ),
                        );
                    } catch (e) {
                        message.error('Error updating type');

                        return [...clientDefinedTypes];
                    }
                },
                {
                    rollbackOnError: true,
                    populateCache: true,
                    revalidate: true,
                },
            );
        }
    };

    const addClientDefinedType = () => {
        const newType = {
            type: type,
            value: newClientDefinedValueName,
            tag_color: newClientDefinedValueColor || '#000000',
        };

        if (clientDefinedTypes) {
            mutateClientDefinedTypes(
                async () => {
                    try {
                        const result = await createClientDefinedType(newType);

                        message.success('Type added successfully!');
                        setNewClientDefinedValueColor('');
                        setNewClientDefinedValueName('');
                        setIsAddingType(false);

                        setClientDefinedTypeOptions([
                            ...clientDefinedTypes.map(
                                (t: CapitalProjectClientDefinedType) => {
                                    return t.id === result.id ? result : t;
                                },
                            ),
                            result,
                        ]);
                    } catch (e) {
                        message.error('Error updating type');

                        return [...clientDefinedTypes];
                    }
                },
                {
                    rollbackOnError: true,
                    populateCache: true,
                    revalidate: true,
                },
            );
        }
    };

    const deleteClientDefinedType = (option: TypeOption) => {
        const optionToDelete = clientDefinedTypes
            ? clientDefinedTypes.find((t) => t.id === option.value)
            : null;

        if (clientDefinedTypes) {
            mutateClientDefinedTypes(
                async () => {
                    try {
                        await deleteClientDefinedTypeRequest(
                            optionToDelete?.type ?? '',
                            optionToDelete?.id ?? '',
                        );

                        message.success('Type deleted successfully!');
                        setNewClientDefinedValueColor('');
                        setNewClientDefinedValueName('');
                        setSelectedTypeToEdit(null);

                        setClientDefinedTypeOptions(
                            clientDefinedTypes.filter(
                                (t: CapitalProjectClientDefinedType) =>
                                    t.id !== option.value,
                            ),
                        );
                    } catch (e) {
                        message.error('Error deleting type');

                        return [...clientDefinedTypes];
                    }
                },
                {
                    rollbackOnError: true,
                    populateCache: true,
                    revalidate: true,
                },
            );
        }
    };

    const renderLabel = (option: TypeOption, isEditing = false) => {
        return (
            <div
                key={option.id}
                className={isEditing ? typeOptionStyle : typeOptionSelectStyle}
                onClick={() => setSelectedTypeToEdit(option.value)}
            >
                {!canShowColorOptionOrPicker(option.type ?? '') ? null : (
                    <div className={colorOfTheTypeSelect(option.color)}>
                        &nbsp;
                    </div>
                )}
                {option.label}
            </div>
        );
    };

    const editPencil = () => (
        <Tooltip
            title={
                typeOptions?.length
                    ? `Add, edit, or delete a ${type}`
                    : `You must add at least one ${type} to create a new project.`
            }
            placement="top"
            arrow
        >
            <i
                style={{
                    color: theme.colors.blues.linkBlue,
                    cursor: 'pointer',
                    fontSize: '15px',
                    marginLeft: '5px',
                    verticalAlign: 'bottom',
                    paddingRight: '12px',
                }}
                className="fa-solid fa-pencil"
                onClick={(e) => setModalIsOpen(true)}
            />
        </Tooltip>
    );

    const handleClose = () => {
        setSelectedTypeToEdit(null);
        setIsAddingType(false);
        setModalIsOpen(false);
    };

    if (!typeOptions) {
        return <></>;
    }

    return (
        <div key={type}>
            <div style={{ margin: 'auto', width: '400px' }}>
                <Modal
                    centered
                    closable
                    onCancel={() => setModalIsOpen(false)}
                    open={modalIsOpen}
                    key="1"
                    title={`Update ${type} options`}
                    footer={
                        <>
                            <div>
                                <Button onClick={handleClose}>Close</Button>
                            </div>
                        </>
                    }
                >
                    <div>
                        {typeOptions?.map((t: TypeOption) =>
                            t.value === selectedTypeToEdit ? (
                                <OptionLabel
                                    key={t.value}
                                    option={t}
                                    setNewClientDefinedValueColor={
                                        setNewClientDefinedValueColor
                                    }
                                    setNewClientDefinedValueName={
                                        setNewClientDefinedValueName
                                    }
                                    newClientDefinedValueName={
                                        newClientDefinedValueName
                                    }
                                    updateClientDefinedType={
                                        updateClientDefinedType
                                    }
                                    deleteClientDefinedType={
                                        deleteClientDefinedType
                                    }
                                    type={type}
                                    tableData={tableData ?? []}
                                    setSelectedTypeToEdit={
                                        setSelectedTypeToEdit
                                    }
                                    newClientDefinedValueColor={
                                        newClientDefinedValueColor
                                    }
                                    setIsAddingType={setIsAddingType}
                                    addClientDefinedType={addClientDefinedType}
                                />
                            ) : (
                                renderLabel(t, true)
                            ),
                        )}
                    </div>
                    <div style={{ marginTop: '25px' }} key="1">
                        {isAddingType ? (
                            <OptionLabel
                                setNewClientDefinedValueColor={
                                    setNewClientDefinedValueColor
                                }
                                setNewClientDefinedValueName={
                                    setNewClientDefinedValueName
                                }
                                newClientDefinedValueName={
                                    newClientDefinedValueName
                                }
                                updateClientDefinedType={
                                    updateClientDefinedType
                                }
                                deleteClientDefinedType={
                                    deleteClientDefinedType
                                }
                                type={type}
                                tableData={tableData ?? []}
                                setSelectedTypeToEdit={setSelectedTypeToEdit}
                                newClientDefinedValueColor={
                                    newClientDefinedValueColor
                                }
                                setIsAddingType={setIsAddingType}
                                addClientDefinedType={addClientDefinedType}
                            />
                        ) : (
                            <Button
                                style={{ marginBottom: '15px' }}
                                disabled={selectedTypeToEdit !== null}
                                onClick={() =>
                                    !isAddingType && setIsAddingType(true)
                                }
                            >
                                Add Item
                                <PlusOutlined />
                            </Button>
                        )}
                    </div>
                </Modal>
            </div>

            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '5px',
                }}
            >
                {isAllowedToEdit ? (
                    <div>
                        <h3 style={{ fontSize: '16px' }}>
                            {type.toLocaleUpperCase()}{' '}
                            <span
                                style={{
                                    display: 'inline',
                                    position: 'relative',
                                    bottom: 3,
                                    marginLeft: 6,
                                }}
                            >
                                {editPencil()}
                            </span>
                        </h3>
                    </div>
                ) : (
                    <Select
                        style={{ width: '100%', marginRight: 8 }}
                        placeholder={`Add a ${type}`}
                        onChange={(value) => {
                            const optionSelected = clientDefinedTypes?.find(
                                (t: CapitalProjectClientDefinedType) =>
                                    t.id === value,
                            );

                            if (typesToSend && setTypesToSend) {
                                setTypesToSend({
                                    ...typesToSend,
                                    [type]: {
                                        type,
                                        value: optionSelected?.value,
                                        tag_color: optionSelected?.tag_color,
                                    } as CapitalProjectClientDefinedType,
                                });
                            }

                            if (onChange) {
                                onChange(value);
                            }
                        }}
                        value={value}
                    >
                        {typeOptions?.map((o: TypeOption) => (
                            <Option key={o.id} value={o.value} label={o.label}>
                                {renderLabel(o)}
                            </Option>
                        ))}
                    </Select>
                )}
            </div>
        </div>
    );
};

export default PlanningCapitalProjectsCategorySelect;
