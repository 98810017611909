import { API_URL } from 'config/constants';
import { Report } from 'waypoint-types';

interface GetReportParams {
    entity_codes: string[];
}

const getMyReports = async (body: GetReportParams): Promise<Report[]> => {
    const response = await fetch(`${API_URL}/reports/my-reports`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
    });

    if (!response.ok) {
        throw new Error('FAILED');
    }

    const { data } = await response.json();
    return data as Report[];
};

export default getMyReports;
