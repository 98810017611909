import PropTypes from 'prop-types';
import React from 'react';
import styles from './LoadingIndicator.module.css';
import { pick } from 'lodash';

const acceptedStyleProps = {
    minHeight: PropTypes.string,
};

class LoadingIndicator extends React.Component {
    static propTypes = {
        ...acceptedStyleProps,
        style: PropTypes.object,
    };

    render() {
        const { props } = this;
        const containerStyle = Object.assign(
            {},
            props.style,
            pick(props, Object.keys(acceptedStyleProps)),
        );
        if (props.children) {
            return (
                <div className={styles.container} style={containerStyle}>
                    {props.children}
                    <i size="2x" className="fa-solid fa-spinner" pulse />
                </div>
            );
        }
        return (
            <div className={styles.container} style={containerStyle}>
                <i size="2x" className="fa-solid fa-spinner" pulse />
            </div>
        );
    }
}

export default LoadingIndicator;
