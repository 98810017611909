export const COMPARATIVE_INCOME_STATEMENT_FEATURE_NAME = 'Income Statement';

export const COMPARATIVE_INCOME_STATEMENT_URL = 'comparative-income-statement';

export const COMPARATIVE_INCOME_REPORT_TYPE = 'comparative-income';

export const FILTERS_DIALOG_CONTAINER_ID = 'filters-dialog-backdrop';

export const COLUMN_A_NAME = 'primary';
export const COLUMN_B_NAME = 'secondary';
export const COLUMN_C_NAME = 'tertiary';

export const PERIODICITIES_OR_CUSTOM_DEFAULT = 'ytd';
export const PERIODICITIES_PRIOR_PERIOD_DEFAULT = 'ytd';

// MODE
export const DEFAULT_MODE = 'budget';
export const MODE_ACTUAL = 'actual';

// PERIOD
export const PERIOD_FIELD_NAME_PRIMARY = 'period_primary';
export const PERIOD_FIELD_NAME_SECONDARY = 'period_secondary';
export const PERIOD_FIELD_NAME_TERTIARY = 'period_tertiary';

export const COMPARISON_PERIOD = 'comparison_period';
export const COMPARISON_PERIOD_TERTIARY = 'comparison_period_tertiary';

export const COMPARISON_TYPE = 'comparison_type';

export const FINANCIAL_YEAR_ENDING = 'financial_year_ending';

export const VARIANCE_DISPLAY = 'variance_display';
export const VARIANCE_DISPLAY_TERTIARY = 'variance_display_tertiary';

export const VARIANCE_COMPARISON = 'variance_comparison';
export const VARIANCE_COMPARISON_TERTIARY = 'variance_comparison_tertiary';

export const ACTUAL_TO_ACTUAL_VALUE = 'actual_to_actual';
export const ACTUAL_TO_BUDGET_VALUE = 'actual_to_budget';

export const PERIODICITY_FIELD = 'periodicity';

export const ACTUAL_VALUE = 'actual';
export const BUDGET_VALUE = 'budget';
export const NONE_VALUE = 'none';
export const DISABLED_VALUE = 'disabled';

export const SAME_PERIOD_PRIOR_YEAR_VALUE = 'same_period_prior_year';
export const SAME_PERIOD_PRIOR_YEAR_LABEL = 'Same Period Prior Year';
export const PRIOR_PERIOD_VALUE = 'prior_period';
export const PRIOR_PERIOD_LABEL = 'Prior Period';
export const SELECTED_PERIOD_VALUE = 'selected_period';
export const SELECTED_PERIOD_LABEL = 'Selected Period';

export const PERIODICITY_MONTH = 'month';
export const PERIODICITY_QUARTER = 'quarter';

export const THRESHOLD_PERIODICITIES = new Set(['ytd', 'qtd', 'mtd']);

// Threshold Types
export const THRESHOLD_FIXED_AND_PERCENTAGES = 'fixed_and_percentage';
export const THRESHOLD_FIXED_ONLY = 'fixed_only';
export const THRESHOLD_PERCENTAGES_ONLY = 'percentage_only';
export const THRESHOLD_FIXED_OR_PERCENTAGES = 'fixed_or_percentage';

// Variance types
export const PCT_ONLY = 'pct_only';
export const AMOUNT_ONLY = 'amount_only';
export const AMOUNT_AND_PCT = 'amount_and_pct';

// Workflow Roles
export const WORKFLOW_ASSIGNEE = 'assignee';
export const WORKFLOW_REVIEWER = 'reviewer';

// Workflow Report Statuses
export const OPEN = 'open';
export const IN_PROGRESS = 'in_progress';
export const READY_FOR_REVIEW = 'ready_for_review';
export const APPROVED = 'approved';
