import React from 'react';
import { get } from 'lodash';
import { css, cx } from 'emotion';
import { DASH_DASH } from 'config/constants';
import theme from 'config/theme';

const titleStyle = css`
    margin: 0 auto;
    h2 {
        margin: 3px;
    }
    hr {
        margin: 0;
        padding: 0;
        border-color: ${theme.colors.grays.light};
    }
`;

const Title = (props) => {
    const title = get(props, 'title', DASH_DASH);
    const dataGrid = get(props, 'data-grid');
    return (
        <div
            data-grid={dataGrid}
            className={cx([titleStyle, props.className])}
            style={props.style}
        >
            <h2>{title || ''}</h2>
            <hr />
        </div>
    );
};

export default Title;
