import { useEffect, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';
import { Dictionary } from 'ts-essentials';

import { getAccountTree } from 'waypoint-requests';
import { AccountTreeNode } from 'waypoint-types';

import { CrosstabAccount } from 'components/financials/crosstab/accounts';
import { getCrosstabAccountStructure } from 'components/financials/crosstab/utils';

type GetCrosstabAccountTree = {
    accountNamesByMappingCode: Dictionary<string>;
    isLoadingAccountTree: boolean;
    loadingError: string | null;
    rootAccount: CrosstabAccount | null;
};

export const useGetCrosstabAccountTree = (): GetCrosstabAccountTree => {
    const rootAccount = useRef<CrosstabAccount | null>(null);

    const [isLoadingAccountTree, setIsLoadingAccountTree] = useState(false);

    const [accountTreeError, setAccountTreeError] = useState<string | null>(
        null
    );

    const accountNamesByMappingCode = useRef<Dictionary<string>>({});

    useEffect(() => {
        const loadAccountTree = async () => {
            try {
                setIsLoadingAccountTree(true);

                const accountNodes = await getAccountTree();

                const newRootNode = getCrosstabAccountStructure(accountNodes);

                if (!isEqual(newRootNode, rootAccount.current)) {
                    rootAccount.current = newRootNode;

                    if (accountNodes !== null) {
                        updateAccountLookup(accountNodes);
                    }
                }
            } catch (e) {
                console.error('failed to load account tree', e);
                setAccountTreeError(
                    'Crosstab had an issue loading the account structure'
                );
            } finally {
                setIsLoadingAccountTree(false);
            }
        };

        const updateAccountLookup = (
            accountNodes: AccountTreeNode[] | null
        ) => {
            const accountNameLookup: Dictionary<string> = {};

            const visitAccount = (account: AccountTreeNode) => {
                account.children.forEach(visitAccount);
                if (account.display_name && account.account_mapping_code) {
                    accountNameLookup[account.display_name] =
                        account.account_mapping_code;
                }
            };

            if (accountNodes) {
                accountNodes.forEach(visitAccount);
            }

            accountNamesByMappingCode.current = accountNameLookup;
        };

        loadAccountTree();
    }, []);

    return {
        accountNamesByMappingCode: accountNamesByMappingCode.current,
        isLoadingAccountTree,
        loadingError: accountTreeError,
        rootAccount: rootAccount.current,
    };
};
