export const postData = (url, bodyParams = {}) => {
    const { download, ...params } = bodyParams;
    const headers = {
        'Content-Type': 'application/json',
    };

    if (download) {
        Object.assign(headers, { Accept: 'text/csv' });
    }
    return fetch(url, {
        method: 'POST',
        headers,
        credentials: 'include',
        body: JSON.stringify(params),
    });
};
