import { API_URL } from 'config/constants';
import { EntityProfileCardYearMetadata } from 'waypoint-types/properties';

interface CreateEntityProfileCardYearMetadataDto {
    year: number;
    metadata: {
        key: string;
        value: string;
    }[];
}

export async function createEntityProfileCardYearMetadata(
    entityCode: string,
    type: string,
    { year, metadata }: CreateEntityProfileCardYearMetadataDto
): Promise<EntityProfileCardYearMetadata> {
    const response = await fetch(
        `${API_URL}/properties/${entityCode}/profile-cards/${type}/year-metadata`,
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({
                year,
                metadata,
            }),
        }
    );

    if (!response.ok) {
        throw new Error('Failed to create profile card year metadata');
    }

    const { data } = await response.json();

    return data as EntityProfileCardYearMetadata;
}
