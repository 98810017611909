import PropTypes from 'prop-types';
import React from 'react';
import { pick, isEmpty, get } from 'lodash';
import { ChartTableSwitchCard, DownloadButton } from 'waypoint-react';
import { sqftByIndustryCardTitle } from 'components/leases/tenants/constants';
import SqftByIndustryTable from 'components/leases/tenants/cards/SqFtByIndustry/Table';
import SqftByIndustryChart from 'components/leases/tenants/cards/SqFtByIndustry/Chart';
import { exportCSV, getSqFtData } from 'components/leases/tenants/utils';
import { topIndustryCount } from 'components/leases/tenants/constants';

const downloadCSV = (csvData) => {
    exportCSV(csvData, 'Industries by Sq Ft');
};

const SqftByIndustryCard = ({ statistics, isLoading, ...props }) => {
    const gridProps = pick(props, ['data-grid', 'style']);
    let portfolioTotalSquareFootage = null;
    let tenantStatsByIndustry = null;
    let chartData = [];
    let csvData = null;
    if (statistics) {
        portfolioTotalSquareFootage = get(
            statistics,
            'portfolioTotalSquareFootage'
        );
        tenantStatsByIndustry = get(statistics, 'tenantStatsByIndustry');
        const allData = getSqFtData({
            statistics: tenantStatsByIndustry,
            totalPortfolioSqFt: portfolioTotalSquareFootage,
            partition: topIndustryCount,
        });
        chartData = allData.data;
        csvData = allData.csvData;
    }

    return (
        <ChartTableSwitchCard
            {...gridProps}
            extra={
                <DownloadButton
                    disabled={isLoading || isEmpty(statistics)}
                    onClick={() => downloadCSV(csvData)}
                />
            }
            subheading={<span />}
            title={sqftByIndustryCardTitle}
            empty={isEmpty(statistics)}
            loading={isLoading}
            table={<SqftByIndustryTable data={statistics} />}
            chart={<SqftByIndustryChart data={chartData} />}
        />
    );
};

SqftByIndustryCard.propTypes = {
    isLoading: PropTypes.bool,
    statistics: PropTypes.object,
};

export default SqftByIndustryCard;
