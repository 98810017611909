import React, { useEffect, useMemo, useState } from 'react';
import { DocumentsTable } from 'components/documents/DocumentsTable';
import { Button, Card, Select, Space, message, Modal } from 'antd';
import { deleteDocument, getDocumentUploadById } from 'waypoint-requests';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DocumentUpload } from 'waypoint-types';
import { useGetDocumentUploadListByReferenceParams } from 'waypoint-hooks';
import { DocumentUploads } from 'components/uploads/DocumentUploads';
import {
    DOCUMENTS_SECTION_RESTRICTIONS,
    actionButtonsStyle,
    banner,
    cardTitleStyle,
    documentsStyle,
    selectStyle,
    getUniqueOptions,
} from 'components/documents/DocumentsUtils';

interface DocumentsContainerProps {
    entityCode: string;
}

export const DocumentsContainer = ({ entityCode }: DocumentsContainerProps) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedRows, setSelectedRows] = useState<DocumentUpload[]>([]);
    const [isFooterVisible, setIsFooterVisible] = useState(false);
    const [filterCreatedBy, setFilterCreatedBy] = useState<number | null>();
    const [filterType, setFilterType] = useState<string | null>();
    const [filteredDocuments, setFilteredDocuments] = useState<
        DocumentUpload[]
    >([]);

    const { confirm } = Modal;

    const {
        data: documentsUploaded,
        isError: documentsUploadedError,
        isLoading: isValidatingDocumentsUploaded,
        mutate,
    } = useGetDocumentUploadListByReferenceParams('entity', entityCode);

    const isFilterDisabled =
        isValidatingDocumentsUploaded || !!selectedRows?.length;

    const { uniqueFileTypeOptions, uniqueAddedByOptions } = useMemo(
        () => getUniqueOptions(documentsUploaded ?? []),
        [documentsUploaded],
    );

    useEffect(() => {
        const filteredDocs =
            documentsUploaded?.filter((document) => {
                let matches = true;
                if (filterType) {
                    matches = matches && document.document_type === filterType;
                }
                if (filterCreatedBy) {
                    matches =
                        matches && document.created_by === filterCreatedBy;
                }
                return matches;
            }) ?? [];

        setFilteredDocuments(filteredDocs);
    }, [filterCreatedBy, filterType, documentsUploaded]);

    const onDownloadFiles = async () => {
        for (const file of selectedRows) {
            const fileBuffer = await getDocumentUploadById(file.id);
            const blob = new Blob([fileBuffer], {
                type: 'application/octet-stream',
            });

            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = file.document_url;

            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        }
    };

    const onDeleteFiles = async () => {
        confirm({
            title: 'Are you sure you want to delete the selected documents?',
            icon: <ExclamationCircleOutlined />,
            content: 'This action cannot be undone.',
            centered: true,
            onOk() {
                const ids = selectedRows.map((row) => row.id);
                const optimisticData = documentsUploaded?.filter(
                    (upload) => !ids.includes(upload.id),
                );
                mutate(
                    async () => {
                        const ids = selectedRows.map((row) => row.id);
                        const deletedDocuments: DocumentUpload[] =
                            await deleteDocument({
                                documentUploadIds: ids,
                                softDelete: false,
                            });

                        if (ids.length !== deletedDocuments.length) {
                            message.warning('Some documents were not deleted.');
                        }

                        if (ids.length === deletedDocuments.length) {
                            message.success('Documents deleted successfully.');
                        }

                        return optimisticData;
                    },
                    {
                        optimisticData,
                        rollbackOnError: true,
                        populateCache: false,
                        revalidate: true,
                    },
                );
            },
        });
    };

    const renderActionButtons = () => {
        return (
            <div className={actionButtonsStyle}>
                <Button type="primary" onClick={onDownloadFiles}>
                    Download
                </Button>
                <Button type="primary" danger onClick={() => onDeleteFiles()}>
                    Delete
                </Button>
            </div>
        );
    };

    const renderUpload = () => {
        return (
            <>
                <Button type="primary" onClick={() => setIsModalOpen(true)}>
                    Upload
                </Button>
                <DocumentUploads
                    referenceId={entityCode}
                    referenceType="entity"
                    documentsUploaded={filteredDocuments}
                    mutateMany={mutate}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    restrictions={DOCUMENTS_SECTION_RESTRICTIONS}
                    isModal={true}
                />
            </>
        );
    };

    const handleSelectionChanged = ({ selectedRowKeys }: any) => {
        if (selectedRowKeys.length === 0) {
            setIsFooterVisible(false);
            setSelectedRows([]);
            return;
        }

        setIsFooterVisible(true);
        setSelectedRows(selectedRowKeys);
    };

    return (
        <>
            <Card
                title="Documents"
                className={cardTitleStyle}
                extra={renderUpload()}
            ></Card>
            <div className={banner}>
                <Space>
                    <Select
                        disabled={isFilterDisabled}
                        placeholder="Added By"
                        value={filterCreatedBy}
                        onChange={(e) => {
                            setFilterCreatedBy(e);
                        }}
                        options={uniqueAddedByOptions}
                        className={selectStyle}
                        style={{ width: 300 }}
                        allowClear
                    />
                    <Select
                        disabled={isFilterDisabled}
                        placeholder="Type"
                        onChange={(e) => {
                            setFilterType(e ? e : null);
                        }}
                        allowClear
                        value={filterType}
                        options={uniqueFileTypeOptions}
                        className={selectStyle}
                    />
                </Space>
            </div>
            <Card
                id="documents-section"
                actions={[renderActionButtons()]}
                className={documentsStyle(isFooterVisible)}
            >
                <div
                    id="documents"
                    style={{ margin: '20px 0', padding: '0 24px' }}
                >
                    <DocumentsTable
                        entityCode={entityCode}
                        documentsUploaded={filteredDocuments}
                        documentsUploadedError={documentsUploadedError}
                        isValidatingDocumentsUploaded={
                            isValidatingDocumentsUploaded
                        }
                        handleSelectionChanged={handleSelectionChanged}
                    />
                </div>
            </Card>
        </>
    );
};
