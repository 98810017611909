import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import useSWR from 'swr';
import { getPermissionGroups } from 'waypoint-requests';
import { PermissionGroup } from 'waypoint-types';

export const useGetPermissionGroups = (
    clientId: number,
    userId: number
): DataHookResponse<PermissionGroup[]> => {
    const { data, error, mutate } = useSWR(
        `/permissions-groups/${clientId}/${userId}`,
        () => {
            return getPermissionGroups(clientId);
        },
        {
            revalidateOnFocus: true,
            revalidateOnMount: true,
        }
    );

    return {
        data,
        mutate,
        isLoading: !data,
        isError: !!error,
    };
};
