import React from 'react';
import { format } from 'date-fns';
import { User } from 'waypoint-types';
import { css } from 'emotion';
import { DASH_DASH } from 'config/constants';

const mutedText = css`
    color: #999;
    font-size: 12px;
    margin: 0 0 1pt;
`;
interface ReportDetailDatesProps {
    user: User | null;
    date: string | Date;
    title: string;
}

const ReportDetailDates = ({ title, user, date }: ReportDetailDatesProps) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'flex-end',
            }}
        >
            <p className={mutedText}>
                {title} {user?.email ?? DASH_DASH} on{' '}
                {format(new Date(date), 'PPpp')}
            </p>
        </div>
    );
};

export default ReportDetailDates;
