import memoizeOne from 'memoize-one';
import { isEqual, get } from 'lodash';
import { renderToString } from 'react-dom/server';
import chartBuilder from 'components/charts/chartBuilder';
import { floatRenderer, getVarianceColor } from 'utils/tables/renderers';
import { formatMoney, formatPercentage } from 'utils/formatters';
import {
    getDisplayTypeLabel,
    getDisplayTypeAbbreviation,
} from 'components/analytics/financialOverview/menu/displayType/utils';
import { FinancialOverviewTooltip } from 'components/analytics/financialOverview/charts';
import theme from 'config/theme';

const generateTooltip = (props) => {
    return renderToString(<FinancialOverviewTooltip {...props} />);
};

export const chartStyle = {
    decimals: '1',
    drawAnchors: '1',
    anchorRadius: '3',
    anchorBorderThickness: '1',
    numVisiblePlot: '48',
    sYAxisName: 'Variance %',
    numberPrefix: '$',
    sNumberSuffix: '%',
    sYAxisMaxValue: 'auto',
    drawCrossLine: '1',
    showLegend: '1',
    plotHighlightEffect: 'fadeout',
    legendPosition: 'bottom',
    legendAllowDrag: '0',
    showTickMarks: '1',
    exportFileName: 'Actual vs Budget Over Time',
    scrolltoend: '1',
    scrollheight: '7',
    compactdatamode: '1',
    plotBorderAlpha: '0',
    plotBorderThickness: '0',
    plotColorinTooltip: '0',
    plotFillAlpha: '100',
    seriesNameInToolTip: '0',
    showPlotBorder: '0',
    showToolTipShadow: '0',
    toolTipBorderColor: '#C1C1C1',
    tooltipPadding: '10',
    tooltipborderradius: '5',
    animation: '0',
    exportFormats: 'PNG|PDF|JPG|SVG',
    chartBottomMargin: 0,
    legendIconScale: 1,
    legendItemFontSize: 12,
};

export const getFinancialOverviewOvertimeChartConfig = ({
    data,
    displayType,
}) => {
    const chart = chartBuilder();
    chart.type('scrollcombidy2d');
    chart.height('70%');
    chart.data(); // Why is this necessary?
    chart.style({
        ...chartStyle,
        pYAxisName: getDisplayTypeLabel(displayType),
    });
    chart.categories([
        {
            category: data.map((node) => ({
                label: node.label,
            })),
        },
    ]);

    chart.dataset([
        {
            seriesname: 'Actual',
            color: '#3588BD',
            plotBorderColor: '#3588BD',
            data: data.map((node) => ({
                value: node.actual || 0, // Fusion charts will not plot null values
                toolText: generateTooltip({
                    title: node.label,
                    topContent: {
                        value: `${formatMoney(
                            node.actual,
                        )} ${getDisplayTypeAbbreviation(displayType)}`,
                        label: 'Actual',
                    },
                    bottomContent: {
                        value: `${formatMoney(
                            node.budget,
                        )} ${getDisplayTypeAbbreviation(displayType)}`,
                        label: 'Budget',
                    },
                }),
            })),
        },
        {
            seriesname: 'Budget',
            color: '#F4AE5E',
            plotBorderColor: '#F4AE5E',
            data: data.map((node) => ({
                value: node.budget || 0, // Fusion charts will not plot null values
                toolText: ' ', // Workaround for ensuring some data sets do not display a toolitp.  See: https://forum.fusioncharts.com/topic/10794-disable-tooltips-in-only-a-part-of-the-available-datasets/
            })),
        },
        {
            seriesname: '$ Variance',
            initiallyhidden: '0',
            color: '#9c9c9c',
            plotBorderColor: '#9c9c9c',
            data: data.map((node) => ({
                color: getVarianceColor(get(node, 'variance_is_good')),
                value: node.variance || 0, // Fusion charts will not plot null values
                toolText: generateTooltip({
                    title: '',
                    topContent: {
                        value: formatMoney(get(node, 'variance')),
                        label: 'Variance $',
                        color: getVarianceColor(get(node, 'variance_is_good')),
                    },
                    bottomContent: {
                        value: floatRenderer(get(node, 'variance_ratio'), 2),
                        label: 'Variance %',
                        color: getVarianceColor(get(node, 'variance_is_good')),
                    },
                }),
            })),
        },
        {
            seriesname: '% Variance',
            parentYAxis: 'S',
            renderAs: 'spline',
            color: '#383838',
            plotBorderColor: '#383838',
            data: data.map((node) => ({
                value: node.variance_ratio * 100 || 0, // Fusion charts will not plot null/NaN values
                toolText: ' ',
            })),
        },
    ]);

    if (displayType !== 'gross') {
        // use displayTypeOption to avoid strings comparison
        chart.style({
            ...chartStyle,
            yAxisName: getDisplayTypeLabel(displayType),
            numberPrefix: '$',
            yAxisValueDecimals: '2',
            forceYAxisValueDecimals: '0',
        });
    } else {
        chart.style({
            ...chartStyle,
            yAxisName: getDisplayTypeLabel(displayType),
        });
    }
    const { config } = chart; // this should be a method
    return config;
};

// NOTE: memoizeOne boosts chart performance when toggling between table/chart
export const buildFinancialOverviewOvertimeChartConfig = memoizeOne(
    getFinancialOverviewOvertimeChartConfig,
    isEqual,
);
