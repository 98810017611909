import { message } from 'antd';
import useSWR from 'swr';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { getReports } from 'waypoint-requests';
import { Report } from 'waypoint-types';

export const useGetReports = (
    entityCodes: string[]
): DataHookResponse<Report[]> => {
    const entityCodesCacheKey = entityCodes.join(',');
    const { data, error, mutate, isValidating } = useSWR(
        entityCodesCacheKey ? `/api/reports/${entityCodesCacheKey}` : null,
        () => getReports({ entity_codes: entityCodes }),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
            onError: () => {
                message.error('Failed to fetch Reports data');
            },
            shouldRetryOnError: false,
        }
    );
    return {
        isLoading: !data,
        isError: !!error,
        data,
        mutate,
        isValidating,
    };
};
