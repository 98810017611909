import PropTypes from 'prop-types';
import React from 'react';
import { last, pick, get } from 'lodash';
import { toCalendarDate } from 'components/dates/utils';
import { formatMoney } from 'utils/formatters';
import { StatisticsCard } from 'waypoint-react';
import { getDisplayTypeAbbreviation } from 'components/analytics/financialOverview/menu/displayType/utils';

/**
 * Receives and displays an amount in dolars
 * Only displays the selected end_date in the subheading
 * See StatisticsCard for abstracted loading and empty state logic
 */
const TotalCard = ({ data, selections, onClick, loading, ...props }) => {
    const gridProps = pick(props, ['data-grid', 'style']);
    const accountMappingName = get(selections, 'accountMapping.name');
    const { amount } = data;
    const statValue =
        amount &&
        `${formatMoney(data.amount)} ${getDisplayTypeAbbreviation(
            selections.displayType,
        )}`;
    const endDate = last(selections.period);
    const period = toCalendarDate(endDate);
    return (
        <StatisticsCard
            {...gridProps}
            loading={loading}
            action={onClick}
            title={accountMappingName}
            value={statValue}
            subheading={`Period: ${period}`}
            data-testid="component-total-card"
        />
    );
};

TotalCard.propTypes = {
    data: PropTypes.object,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    selections: PropTypes.shape({
        accountMapping: PropTypes.shape({
            name: PropTypes.string,
            code: PropTypes.string,
        }),
        period: PropTypes.array,
        displayType: PropTypes.string,
    }),
};

export default TotalCard;
