import { API_URL } from 'config/constants';
import { NotificationPreferencesResponse } from 'waypoint-types';

export async function getUserNotificationPreferences(): Promise<NotificationPreferencesResponse> {
    const response = await fetch(`${API_URL}/users/notification-preferences`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
    });

    if (!response.ok) {
        throw new Error('Failed to get notification settings');
    }

    const { data } = await response.json();

    return data as NotificationPreferencesResponse;
}
