import { postData } from './fetchData';
import { API_URL } from 'config/constants';

const getTotalData = ({
    entityCodes,
    selectedDataLevel,
    ...parameters
} = {}) => {
    const url = `${API_URL}/financial-overview/total`;
    return postData(url, {
        entity_codes: entityCodes,
        selected_data_level: selectedDataLevel,
        ...parameters,
    });
};

export default getTotalData;
