import { API_URL } from 'config/constants';

const getAccountHistory = async (body: any) => {
    const response = await fetch(
        `${API_URL}/comparative-income-statement/account-history`,
        {
            credentials: 'include',
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                ...body,
            }),
        }
    );
    if (!response.ok) {
        throw new Error('Failed');
    }
    const { data } = await response.json();
    return data;
};

export default getAccountHistory;
