import { API_URL } from 'config/constants';

interface DeleteLeasingPlanActivityReturnValue {
    message: string;
}

const deleteLeasingPlanActivity = async (
    entityCode: string,
    leasingPlanActivityId: string
): Promise<DeleteLeasingPlanActivityReturnValue> => {
    const response = await fetch(
        `${API_URL}/leasing-plans/activity/${entityCode}/${leasingPlanActivityId}`,
        {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
        }
    );

    if (!response.ok) {
        throw new Error('Failed to delete leasing plan activity');
    }

    const { data } = await response.json();

    return data as DeleteLeasingPlanActivityReturnValue;
};

export default deleteLeasingPlanActivity;
