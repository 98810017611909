import React from 'react';
import ResponsiveBox, {
    Row,
    Col,
    Location,
    Item,
} from 'devextreme-react/responsive-box';
import { RecurringChargeRollUp } from 'waypoint-types';
import { RecurringChargePieChart } from 'components/leases/components/recurring-charge/RecurringChargePieChart';
import { filterRecurringCharges } from 'components/leases/components/recurring-charge/utils';
import { RecurringChargeGrid } from 'components/leases/components/recurring-charge/RecurringChargeGrid';
import DOMPurify from 'dompurify';
import { AppFeaturePermissions } from 'contexts';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';

const { sanitize } = DOMPurify;
interface RentRollRecurringChargeSummaryParams {
    rollUp: RecurringChargeRollUp[];
    selectedChargeCode: string[];
    isPDFExport?: boolean;
    isLandscape?: boolean;
    narrativeText?: string;
    narrativePosition?: string;
    widgetId?: string;
    setIsChartReadyToExport?: (isReady: boolean) => void;
}

const RentRollRecurringChargeSummary = ({
    rollUp,
    selectedChargeCode,
    isPDFExport,
    isLandscape,
    narrativeText,
    narrativePosition,
    widgetId,
    setIsChartReadyToExport,
}: RentRollRecurringChargeSummaryParams) => {
    const stackVertically = isPDFExport && !isLandscape;
    const getChartStyle = () => {
        /*
        NOTE: do not remove this code! Fusion charts need to be set within a div with a specified width
        when exporting... otherwise, they will export with sizing based on the size of the window rather than
        based on the specified paper size and orientation
        */
        if (!isPDFExport) {
            return {};
        }
        return {
            width: '500px',
        };
    };

    const narrativeTextBox = (
        <div
            id={`narrativeTextBox_${widgetId}`}
            dangerouslySetInnerHTML={{
                __html: sanitize(narrativeText ?? ''),
            }}
        />
    );

    return (
        <PermissionedWrapper
            featureKey={AppFeaturePermissions.RentRoll}
            showDisabledView={true}
        >
            <ResponsiveBox>
                <Row ratio={0} />
                <Row ratio={0} />
                <Col ratio={1} />
                <Col ratio={2} />
                <Item>
                    <Location
                        row={0}
                        col={0}
                        colspan={stackVertically ? 2 : 1}
                    />
                    <div
                        id={`recurringChargeChart_${widgetId}`}
                        style={getChartStyle()}
                    >
                        {narrativePosition === 'above'
                            ? narrativeTextBox
                            : null}
                        <RecurringChargePieChart
                            data={filterRecurringCharges(
                                rollUp,
                                selectedChargeCode,
                            )}
                            isPDFExport={!!isPDFExport}
                            setIsChartReadyToExport={setIsChartReadyToExport}
                        />
                        {narrativePosition === 'below'
                            ? narrativeTextBox
                            : null}
                    </div>
                </Item>
                <Item>
                    {stackVertically ? (
                        <Location row={1} col={0} colspan={2} />
                    ) : (
                        <Location row={0} col={1} colspan={1} />
                    )}

                    <RecurringChargeGrid
                        data={filterRecurringCharges(
                            rollUp,
                            selectedChargeCode,
                        )}
                        height={350}
                        isPDFExport={!!isPDFExport}
                    />
                </Item>
            </ResponsiveBox>
        </PermissionedWrapper>
    );
};

export default RentRollRecurringChargeSummary;
