import React, { useState } from 'react';
import { Form, Input, Modal, Radio } from 'antd';
import { FilterReferenceTypes } from 'contexts/saved-filters';

interface SavedConfigEditorProps {
    handleClose: () => void;
    onSave: (name: string, reference_type: string) => void;
    isAdmin: boolean;
    existingConfigNames: string[];
    headerText?: string;
}

const SavedConfigEditor = ({
    handleClose,
    onSave,
    isAdmin,
    existingConfigNames,
    headerText,
}: SavedConfigEditorProps): JSX.Element => {
    const [form] = Form.useForm();
    const [formIsInvalid, setFormIsInvalid] = useState<boolean>(true);
    const editorHeaderText = headerText ?? 'View';

    return (
        <Modal
            open
            onOk={async () => {
                await form.validateFields();
                onSave(
                    form.getFieldValue(`${editorHeaderText} name`),
                    form.getFieldValue('permissions') ??
                        FilterReferenceTypes.USER
                );
            }}
            okButtonProps={formIsInvalid ? { disabled: true } : {}}
            onCancel={handleClose}
            closable={false}
            destroyOnClose
            title={`Save ${editorHeaderText} as...`}
        >
            <Form form={form} layout={'vertical'} autoComplete={'off'}>
                <Form.Item
                    name={`${editorHeaderText} name`}
                    label={`${editorHeaderText} name`}
                    rules={[
                        { required: true, message: '' },
                        {
                            validator: (_, value) => {
                                if (
                                    value.length &&
                                    !existingConfigNames.includes(value)
                                ) {
                                    setFormIsInvalid(false);
                                    return Promise.resolve();
                                }
                                setFormIsInvalid(true);
                                if (!value.length) {
                                    return Promise.reject(
                                        new Error(
                                            `${editorHeaderText} name is required`
                                        )
                                    );
                                }
                                return Promise.reject(
                                    new Error(
                                        `${editorHeaderText} name already in use`
                                    )
                                );
                            },
                        },
                    ]}
                >
                    <Input
                        placeholder={`${editorHeaderText}`}
                        maxLength={50}
                        showCount
                    />
                </Form.Item>

                <Form.Item
                    label={'Permissions'}
                    name={'permissions'}
                    hidden={!isAdmin}
                >
                    <Radio.Group defaultValue={FilterReferenceTypes.USER}>
                        <Radio value={FilterReferenceTypes.USER}>Only me</Radio>
                        <Radio value={FilterReferenceTypes.CLIENT}>
                            Everyone
                        </Radio>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default SavedConfigEditor;
