import { isNull, isUndefined, assign } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { DASH_DASH } from 'config/constants';

const convertFileSize = (size) => {
    if (size >= 1000000) {
        return (size / 1000000).toFixed(2) + ' MB';
    }
    return (size / 1000).toFixed(2) + ' kB';
};

export default function filesizePP(WrappedComponent) {
    class FileSizeCell extends React.Component {
        render() {
            const { cellData, style, ...rest } = this.props;
            // NOTE: We must pass rest through instead of spreading props
            //       So that we don't overwrite the formatted cellData
            const isInvalid = isNull(cellData) || isUndefined(cellData);
            const s = assign(
                {},
                {
                    paddingRight: '15px',
                },
                style
            );
            if (isInvalid) {
                s.fontWeight = 500;
                s.letterSpacing = '1px';
            }
            // Guard against null and undefined values
            const datum = isInvalid
                ? `$${DASH_DASH}`
                : convertFileSize(cellData);
            return (
                <WrappedComponent
                    style={s}
                    cellData={datum}
                    originalValue={cellData}
                    {...rest}
                />
            );
        }
    }

    FileSizeCell.propTypes = {
        style: PropTypes.object,
        cellData: PropTypes.any,
    };

    return FileSizeCell;
}
