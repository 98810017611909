import { API_URL } from 'config/constants';
import { EntityReportWorkflowStatus } from 'waypoint-types';

interface EntityReportWorkflowUpdateStatusParams {
    id: string;
    reportStatus: string;
    reportPriorStatus: string | null;
    lastUpdatedBy: number;
    entityCode: string;
    userRoleIds: number[];
    notificationTitle: string;
    reportPage: string;
    notificationRedirectUrl?: string;
    propertyDisplayName?: string;
    reportNameTitle?: string;
}

const updateWorkflowReportStatus = async (
    entityReportWorkflowUpdateStatusParams: EntityReportWorkflowUpdateStatusParams
): Promise<EntityReportWorkflowStatus> => {
    const response = await fetch(`${API_URL}/report-metadata/status`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(entityReportWorkflowUpdateStatusParams),
    });

    if (!response.ok) {
        throw new Error('Failed to update workflow report status');
    }

    const { data } = await response.json();

    return data as EntityReportWorkflowStatus;
};

export default updateWorkflowReportStatus;
