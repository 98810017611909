import { postData } from './fetchData';
import { API_URL } from 'config/constants';

/**
 * Submits a network request to request the breakdown of the account
 *
 * @param {object} options
 * @returns {Promise<Response>}
 */
export const getFinancialOverviewBreakdown = ({
    entityCodes,
    selectedDataLevel,
    ...parameters
} = {}) => {
    const url = `${API_URL}/financial-overview/breakdown`;
    return postData(url, {
        entity_codes: entityCodes,
        selected_data_level: selectedDataLevel,
        ...parameters,
    });
};

export const getBreakdownDownload = ({
    entityCodes,
    selectedDataLevel,
    ...parameters
} = {}) => {
    const url = `${API_URL}/financial-overview/breakdown/download`;
    return postData(url, {
        entity_codes: entityCodes,
        selected_data_level: selectedDataLevel,
        ...parameters,
    });
};
