import { Route } from 'react-router-dom';
import UserManagement from 'components/settings/users/UserManagement';
import ClientSettings from 'components/settings/client/Container';
import AdminSettings from 'components/settings/AdminSettings';
import { Permissions } from 'components/settings/permissions';
import PermissionsGroups from 'components/settings/permissionsGroups/PermissionsGroups';
import PermissionsGroupsForm from 'components/settings/permissionsGroups/PermissionsGroupForm';
import { ADMIN_SETTINGS_URL_SEGMENT } from 'config/constants';
import CompanySettings from 'components/settings/company/CompanySettings';
const AdminRoutes = () => (
    <>
        <Route
            exact
            path={`${ADMIN_SETTINGS_URL_SEGMENT}/users`}
            render={() => (
                <AdminSettings>
                    <UserManagement />
                </AdminSettings>
            )}
        />
        <Route
            path={`${ADMIN_SETTINGS_URL_SEGMENT}/users/:id/permissions`}
            render={() => (
                <AdminSettings>
                    <Permissions />
                </AdminSettings>
            )}
        />
        <Route
            path={`${ADMIN_SETTINGS_URL_SEGMENT}/permission-groups`}
            render={() => (
                <AdminSettings>
                    <PermissionsGroups />
                </AdminSettings>
            )}
        />
        <Route
            path={`${ADMIN_SETTINGS_URL_SEGMENT}/new-permission-group`}
            render={() => (
                <AdminSettings>
                    <PermissionsGroupsForm />
                </AdminSettings>
            )}
        />
        <Route
            path={`${ADMIN_SETTINGS_URL_SEGMENT}/edit-permission-group/:id`}
            render={() => (
                <AdminSettings>
                    <PermissionsGroupsForm />
                </AdminSettings>
            )}
        />
        <Route
            path={`${ADMIN_SETTINGS_URL_SEGMENT}/client`}
            render={() => (
                <AdminSettings>
                    <ClientSettings />
                </AdminSettings>
            )}
        />
        <Route
            path={`${ADMIN_SETTINGS_URL_SEGMENT}/company`}
            render={() => (
                <AdminSettings>
                    <CompanySettings />
                </AdminSettings>
            )}
        />
    </>
);

export default AdminRoutes;
