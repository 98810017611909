import React, { useState, useEffect } from 'react';
import { toArray, isNil } from 'lodash';
import { connect, RootStateOrAny } from 'react-redux';
import { message, Transfer, TransferProps, Button } from 'antd';
import { css } from 'emotion';
import { useHistory } from 'react-router-dom';
import {
    ACCESS_ALL_ENTITIES,
    CHOOSE_ENTITY,
} from 'components/settings/permissions/utils';
import {
    getUserEntityPermission,
    updateUserEntityPermission,
} from 'waypoint-requests';
import { selectFilteredProperties, selectFilteredUsers } from 'waypoint-utils';
import { selectProperties } from 'state/properties/selectors';
import {
    PermissionsOption,
    PermissionsSelect,
} from 'components/settings/permissions';
import { PropertyType, User } from 'waypoint-types';
import {
    EntityPermission,
    EntityPermissionSettings,
    UserEntityPermission,
} from 'waypoint-types/entities/types';

const tableContainerStyle = css`
    height: 80%;
    overflow-y: hidden;
`;
const header = css`
    margin-bottom: 12px;
    display: flex;
    justify-content: flex-start;

    & strong {
        margin-top: 26px;
        margin-left: 140px;
        font-size: 16px;
    }
`;
const selectEntityContainer = css`
    padding-bottom: 15px;
`;

const menuControls = css`
    margin-top: 20px;
    display: flex;
    width: 100%;
    justify-content: flex-start;
`;

const saveButton = css`
    margin-right: 10px;
`;

const transferStyle = () => css`
    width: 100%;
    height: 100%;
    .ant-transfer-list {
        flex: 1;
        height: 100%;
        max-height: ${window.innerHeight - 440}px;
    }
    .ant-transfer-list-header > *,
    .ant-transfer-list-content-item {
        input[type='checkbox'] {
            position: absolute;
            margin-right: unset;
            margin-left: unset;
            cursor: pointer;
            width: auto;
    }
`;

type TransferPropertyType = { id: string; display_name: string };

const PermissionsCard = ({
    permissions,
    properties,
    users,
    user,
}: {
    permissions: UserEntityPermission;
    properties: PropertyType[];
    users: User[];
    user: User;
}) => {
    const history = useHistory();

    const [searchText, setSearchText] = useState('');
    const [isTableVisible, setIsTableVisible] = useState(
        !permissions.entityPermissionSettings.accessAllEntities,
    );
    const [entityPermissionSettings, setEntityPermissionSettings] = useState<
        Partial<EntityPermissionSettings>
    >(permissions.entityPermissionSettings);
    const [entityPermissions, setEntityPermissions] = useState<
        Partial<EntityPermission>[]
    >(permissions.entityPermissions);

    const initialTargetKeys = permissions.entityPermissions.map(
        (p: { entityCode: string }) => p.entityCode,
    );

    const [targetKeys, setTargetKeys] =
        useState<TransferProps['targetKeys']>(initialTargetKeys);
    const [selectedKeys, setSelectedKeys] = useState<
        TransferProps['targetKeys']
    >([]);

    const [submitting, setSubmitting] = useState(false);
    const [userSelected, setUserSelected] = useState<number | null>(null);

    useEffect(() => {
        const updatedEntityPermissions = targetKeys?.map((entityCode) => ({
            entityCode: entityCode,
        }));
        setEntityPermissions(updatedEntityPermissions as EntityPermission[]);
    }, [targetKeys]);

    const handleOnChange = ({ target }: { target: { value: number } }) => {
        if (target.value === CHOOSE_ENTITY) {
            setIsTableVisible(true);
            setEntityPermissionSettings({ accessAllEntities: false });
        } else {
            setIsTableVisible(false);
            setEntityPermissionSettings({ accessAllEntities: true });
        }
    };

    const sortTargetKeys = (targetKeys: string[]) => {
        const keyToTitleMap = Object.fromEntries(
            toArray(filteredProperties).map(
                ({ id, display_name }: TransferPropertyType) => [
                    id,
                    display_name,
                ],
            ),
        );

        return targetKeys.sort((a, b) => {
            const titleA = keyToTitleMap[a] || '';
            const titleB = keyToTitleMap[b] || '';
            return titleA.localeCompare(titleB);
        });
    };

    const applyPermissionsFromSelectedUser = async () => {
        try {
            const { entityPermissionSettings, entityPermissions } =
                await getUserEntityPermission<UserEntityPermission>(
                    userSelected,
                );

            setEntityPermissionSettings(entityPermissionSettings);
            setTargetKeys(
                entityPermissions.map(
                    (p: { entityCode: string }) => p.entityCode,
                ),
            );
            setEntityPermissions(entityPermissions);
        } catch {
            message.error('Request Failed');
        }
    };

    const handleOnChangeUserSelect = (userId: number) => {
        setUserSelected(isNil(userId) ? user.id : userId);
    };

    const handleSearch = ({ target }: { target: { value: string } }) => {
        setSearchText(target.value);
    };

    const handleOnSave = async () => {
        const payload = { entityPermissionSettings, entityPermissions };
        setSubmitting(true);
        try {
            await updateUserEntityPermission(payload, user.id);
            message.success('Success! Permissions updated successfully.');
            history.push('/settings/admin/users');
        } catch {
            message.error('Request Failed');
        } finally {
            setSubmitting(false);
        }
    };

    const onEntityTransferChange: TransferProps['onChange'] = (
        nextTargetKeys,
    ) => {
        setTargetKeys(nextTargetKeys);
    };

    const onEntityTransferSelectChange: TransferProps['onSelectChange'] = (
        sourceSelectedKeys,
        targetSelectedKeys,
    ) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    const isDisableSaveButton = () =>
        submitting ||
        (!entityPermissionSettings.accessAllEntities &&
            entityPermissions.length === 0);

    const isAllEntityCode = () =>
        entityPermissionSettings.accessAllEntities
            ? ACCESS_ALL_ENTITIES
            : CHOOSE_ENTITY;

    const filteredProperties = selectFilteredProperties(
        { properties },
        searchText,
    ) as TransferPropertyType[];

    const transferDataSource = (properties: TransferPropertyType[]) => {
        return toArray(properties)
            .sort((a, b) => a.display_name.localeCompare(b.display_name))
            .map((property: { id: string; display_name: string }) => ({
                key: property.id,
                title: property.display_name,
            }));
    };

    const filteredUsers = selectFilteredUsers({ users }, searchText);

    return (
        <div>
            <div className={header}>
                <h1>Permissions</h1>
                <strong>
                    {user?.firstname} {user?.lastname} - {user?.email}
                </strong>
            </div>
            <div className={tableContainerStyle}>
                <div className={selectEntityContainer}>
                    <PermissionsOption
                        onChange={handleOnChange}
                        isAllEntityCode={isAllEntityCode()}
                        onSearch={handleSearch}
                        searchText={searchText}
                    />
                    <PermissionsSelect
                        users={toArray(filteredUsers)}
                        onChange={handleOnChangeUserSelect}
                        onApply={applyPermissionsFromSelectedUser}
                    />
                </div>
                <div>
                    {isTableVisible && (
                        <Transfer
                            className={transferStyle()}
                            titles={[
                                'Available Properties',
                                'Assigned Properties',
                            ]}
                            dataSource={transferDataSource(filteredProperties)}
                            targetKeys={sortTargetKeys(targetKeys as string[])}
                            selectedKeys={selectedKeys}
                            onChange={onEntityTransferChange}
                            onSelectChange={onEntityTransferSelectChange}
                            render={(item: { title: string; key: string }) =>
                                item.title
                            }
                            showSearch
                            filterOption={(input, option) =>
                                (option?.title ?? '')
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                        />
                    )}
                </div>
            </div>
            <div className={menuControls}>
                <Button
                    disabled={isDisableSaveButton()}
                    className={saveButton}
                    onClick={handleOnSave}
                    type="primary"
                    size="middle"
                >
                    Save
                </Button>
                <Button
                    size="middle"
                    onClick={() => history.push('/settings/admin/users')}
                >
                    Cancel
                </Button>
            </div>
        </div>
    );
};

const mapState = (state: RootStateOrAny) => ({
    properties: selectProperties(state),
    users: state.users,
});

export default connect(mapState)(PermissionsCard);
