import { API_URL } from 'config/constants';

/**
 * @param {string} commentId - Comment id
 */
const deleteComment = (commentId: string): Promise<Response> => {
    return fetch(`${API_URL}/comments/${commentId}`, {
        method: 'DELETE',
        credentials: 'include',
    });
};

export default deleteComment;
