import { Card, Collapse, Popconfirm, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import {
    AddItemButtonProps,
    EntityProfileCardsAddItemButton,
} from 'waypoint-react/entity-profile-cards/EntityProfileCards';
import { EntityProfileCardsEmptyState } from 'waypoint-react/entity-profile-cards/EntityProfileCardsEmptyState';
import ResponsiveBox, { Col, Row } from 'devextreme-react/responsive-box';
import { MoreOutlined } from '@ant-design/icons';
import { css } from 'emotion';
import { EntityProfileCardProgress } from 'waypoint-react';
import { Dropdown, Menu } from 'antd';
import { useCommentsPane } from 'contexts/comments/CommentsContext';
import { CommentsButton } from 'waypoint-react/comment/CommentsButton';
import { clearQueryParam } from 'waypoint-hooks/useGetQueryParam';
import { HOLD_SELL_CARD_TYPE } from 'components/holdSellAnalysis/HoldSellAnalysis';
import { useCommentsContextManager } from 'contexts/comments/CommentsManagerContext';

const collapseHeaderCss = css`
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        align-items: center;
    }

    .ant-collapse-item {
        margin: 0 !important;
    }

    .ant-collapse {
        margin-bottom: 5px;
    }

    .ant-select-selection-item {
        color: #fff !important;
    }
`;

interface EntityProfileCardsGroupContainerProps {
    typeTitle: string;
    title: string;
    commentThreadId?: string;
    onAddItemClicked?: () => void;
    addItemButton?: AddItemButtonProps;
    children: JSX.Element[];
    getGroupGrid?: () => { rows: number; columns: number };
    isCollapse?: boolean;
    onDelete?: () => void;
    extraFunctions?: React.ReactNode;
    defaultKey?: string;
    enableStatusItems?: boolean;
    allItems?: number;
    itemsCompleted?: number;
    isPDFExport?: boolean;
    includeNotesInPdf?: boolean;
    cardType?: string;
}

export const EntityProfileCardsGroupContainer = ({
    typeTitle,
    title,
    addItemButton,
    onAddItemClicked,
    children,
    getGroupGrid,
    onDelete,
    extraFunctions,
    isCollapse = false,
    defaultKey,
    enableStatusItems,
    allItems,
    itemsCompleted,
    commentThreadId,
    isPDFExport,
    includeNotesInPdf,
    cardType,
}: EntityProfileCardsGroupContainerProps): JSX.Element => {
    const [collapseActiveKeys, setCollapseActiveKeys] = useState<string[]>([]);

    const commentPaneCtx = useCommentsPane();

    const { registerCommentThread } = useCommentsContextManager();

    const componentKey = useRef(new Date().toISOString());

    const {
        closeComments,
        update: updateCommentsPane,
        commentThreadId: contextCommentThreadId,
    } = commentPaneCtx;

    useEffect(() => {
        registerCommentThread &&
            registerCommentThread(commentPaneCtx, componentKey.current);
    }, [commentPaneCtx]);

    const containerId = commentThreadId
        ? `groupContainer-${commentThreadId}`
        : undefined;

    useEffect(() => {
        if (contextCommentThreadId === commentThreadId) {
            updateCommentsPane({
                heading: typeTitle,
                subheading: title,
            });

            forceOpenCollapsePanel();
        }
    }, [contextCommentThreadId]);

    const commentsButton =
        commentThreadId && !isPDFExport ? (
            <CommentsButton
                commentThreadId={commentThreadId}
                onClicked={(isOpening) => {
                    clearQueryParam('comment_id');

                    if (isOpening) {
                        forceOpenCollapsePanel();
                    }
                }}
            />
        ) : null;

    const forceOpenCollapsePanel = () => {
        setCollapseActiveKeys([title]);
        scrollTo();
    };

    const getEmptyState = () => {
        return (
            <EntityProfileCardsEmptyState
                title={title}
                addItemButton={addItemButton}
                onAddItemClicked={onAddItemClicked}
            />
        );
    };

    const getGrid = () => {
        if (!getGroupGrid) {
            return <>{children}</>;
        }

        const groupGrid = getGroupGrid();

        return (
            <ResponsiveBox>
                {Array.from({ length: groupGrid.rows }).map(() => {
                    return <Row />;
                })}

                {Array.from({ length: groupGrid.columns }).map(() => {
                    return <Col />;
                })}

                {children}
            </ResponsiveBox>
        );
    };

    const panelTitle =
        enableStatusItems && allItems ? (
            <EntityProfileCardProgress
                title={title}
                allItems={allItems}
                itemsCompleted={itemsCompleted ?? 0}
            />
        ) : (
            title
        );

    const moreMenu = (
        <Menu>
            {onDelete ? (
                <Popconfirm
                    placement="top"
                    title={'Are you sure you want to delete this item?'}
                    onConfirm={(event) => {
                        event?.stopPropagation();
                        onDelete();
                    }}
                    onCancel={(event) => {
                        event?.stopPropagation();
                    }}
                    okType="danger"
                    okText="Delete"
                    cancelText="Cancel"
                >
                    <Menu.Item
                        onClick={(event) => event.domEvent.stopPropagation()}
                        key={'1'}
                    >
                        <div>Delete</div>
                    </Menu.Item>
                </Popconfirm>
            ) : null}
        </Menu>
    );

    const scrollTo = (delay: number = 200) => {
        setTimeout(() => {
            const container = document?.querySelector(`#${containerId}`);

            container?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }, delay);
    };

    const isHoldSell = cardType === HOLD_SELL_CARD_TYPE;

    const cardHeader = isHoldSell ? (
        <div>
            {panelTitle}{' '}
            {isHoldSell && (
                <span style={{ margin: '0 10px 0 50px' }}>
                    {extraFunctions}
                </span>
            )}
        </div>
    ) : (
        panelTitle
    );

    const cardExtraFunctions = !isHoldSell ? extraFunctions : null;

    if (isCollapse) {
        return (
            <div className={collapseHeaderCss} id={containerId}>
                <Collapse
                    defaultActiveKey={[defaultKey ?? '']}
                    activeKey={collapseActiveKeys}
                    onChange={(activeKeys) => {
                        setCollapseActiveKeys(
                            typeof activeKeys === 'string'
                                ? [activeKeys]
                                : activeKeys
                        );

                        const isCollapsing = !activeKeys.length;

                        if (isCollapsing) {
                            closeComments();
                        } else {
                            scrollTo();
                        }
                    }}
                >
                    <Collapse.Panel
                        key={title}
                        header={cardHeader}
                        extra={
                            <>
                                <Space>
                                    {cardExtraFunctions}
                                    {commentsButton}
                                    {addItemButton && (
                                        <EntityProfileCardsAddItemButton
                                            addItemButton={addItemButton}
                                            onAddItemClicked={onAddItemClicked}
                                            circle={true}
                                        />
                                    )}
                                    <Dropdown
                                        placement={'bottomRight'}
                                        overlay={moreMenu}
                                    >
                                        <span
                                            onClick={(event) =>
                                                event.stopPropagation()
                                            }
                                            style={{
                                                fontSize: '13px',
                                                fontWeight: 'normal',
                                                textTransform: 'none',
                                                textDecoration: 'underline',
                                                cursor: 'pointer',
                                                marginLeft: '5px',
                                            }}
                                        >
                                            <MoreOutlined />
                                        </span>
                                    </Dropdown>
                                </Space>
                            </>
                        }
                        style={{ height: '100%', margin: '5px' }}
                    >
                        <div style={{ padding: '10px' }}>
                            {!!children.length ? getGrid() : getEmptyState()}
                        </div>
                    </Collapse.Panel>
                </Collapse>
            </div>
        );
    }

    return (
        <Card
            title={cardHeader}
            id={containerId}
            extra={
                <Space>
                    {cardExtraFunctions}
                    {commentsButton}
                    {addItemButton && (
                        <EntityProfileCardsAddItemButton
                            addItemButton={addItemButton}
                            onAddItemClicked={onAddItemClicked}
                            circle={true}
                        />
                    )}
                </Space>
            }
            style={{ height: '100%', margin: '5px' }}
            bodyStyle={{ padding: '10px' }}
        >
            {!!children.length ? getGrid() : getEmptyState()}
        </Card>
    );
};
