import type { PivotGridCell } from 'devextreme/excel_exporter';
import * as Excel from 'exceljs';
import { exportPivotGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import dxPivotGrid from 'devextreme/ui/pivot_grid';

const { Workbook } = Excel;
export type ExcelCellStyle = {
    fill: string;
    font: string;
    bold?: boolean;
};

export function isTotalCell(cell: PivotGridCell) {
    return (
        cell.type === 'T' ||
        cell.type === 'GT' ||
        cell.rowType === 'T' ||
        cell.rowType === 'GT' ||
        cell.columnType === 'T' ||
        cell.columnType === 'GT'
    );
}

export function getExcelCellFormat({ fill, font, bold }: ExcelCellStyle) {
    return {
        fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: fill },
        },
        font: { color: { argb: font }, bold },
    };
}

export function getCssStyles(styles?: ExcelCellStyle) {
    if (styles) {
        const { fill, font, bold } = styles;
        return {
            'background-color': `#${fill}`,
            color: `#${font}`,
            'font-weight': bold ? 'bold' : undefined,
        };
    }
}

export function getConditionalAppearance(
    cell: PivotGridCell,
): ExcelCellStyle | undefined {
    if (isTotalCell(cell)) {
        return { fill: 'F2F2F2', font: '3F3F3F', bold: true };
    }
}

export type CustomizeCell = (options: {
    pivotCell?: PivotGridCell;
    excelCell?: any;
}) => void;

export const exportPivotGridComponent = async (
    component: dxPivotGrid,
    customizeCell: CustomizeCell,
): Promise<void> => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Crosstab');

    await exportPivotGrid({
        component,
        worksheet,
        customizeCell,
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const excelFile = new Blob([buffer], { type: 'application/octet-stream' });

    saveAs(excelFile, 'Crosstab.xlsx', {
        autoBom: false,
    });
};
