import { API_URL } from 'config/constants';
import {
    EntityProfileCard,
    EntityProfileCardNote,
} from 'waypoint-types/properties';

interface CreateEntityProfileCardDto {
    section: string;
    year: number;
    title: string;
    notes: string;
    sort_index: number;
    metadata?: { value: string; label: string; color: string }[];
}

export async function createEntityProfileCard(
    entityCode: string,
    type: string,
    {
        section,
        year,
        title,
        notes,
        sort_index,
        metadata,
    }: CreateEntityProfileCardDto
): Promise<EntityProfileCard> {
    const response = await fetch(
        `${API_URL}/properties/${entityCode}/profile-cards/${type}`,
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({
                section,
                year,
                title,
                metadata,
                notes: notes ?? '',
                sort_index: sort_index ?? 0,
            }),
        }
    );

    if (!response.ok) {
        throw new Error('Failed to create profile card');
    }

    const { data } = await response.json();

    return data as EntityProfileCard;
}
