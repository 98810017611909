import { get } from 'lodash';

const defaultItemHeight = 3;
const defaultItemWidth = 6;

/**
 * This is takes a dashboard configuration item and returns dataGridProp that is used to integrate with React-Grid-Layout. Without this prop, the item will not render or will render in unexpected ways.
 *
 * @param {object} item - this represents an item in a dashboard configuration's items collection
 * @returns {object} dataGridProp - The position & dimensions for a single item. { x: <number>, y: <number>, h: <number> , w: <number>}
 */
const getDataGridPropFor = (item) => {
    const position = get(item, 'position');
    const height = get(item.dimensions, 'h', defaultItemHeight);
    const width = get(item.dimensions, 'w', defaultItemWidth);
    const dimensions = { h: height, w: width };
    const dataGridConfig = {
        ...position,
        ...dimensions,
        static: true, // Ensures items are not draggable
    };
    return dataGridConfig;
};

export default getDataGridPropFor;
