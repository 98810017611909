import { MentionableDataSource } from 'waypoint-types';
import { AccountGraphNode } from 'waypoint-types';

const getMentionableAccounts = (data: AccountGraphNode[]) => {
    const mentionableAccounts: MentionableDataSource[] = [];
    const getAccountNode = (node: AccountGraphNode[]) => {
        node.map((item: AccountGraphNode) => {
            const accountCode = item.account_code
                ? `(${item.account_code})`
                : '';
            mentionableAccounts.push({
                id: item.account_mapping_code,
                text: `${item.name} ${accountCode}`,
            });

            if (item.children && item.children.length > 0) {
                return getAccountNode(item.children);
            }
        });
    };
    getAccountNode(data);
    return mentionableAccounts;
};

export default getMentionableAccounts;
