import React, { useState } from 'react';
import { Checkbox } from 'waypoint-react';
import { ReportWidgetSettingsBuilderProps } from 'waypoint-types';
import ReportWidgetSettingsModal from 'components/reports/report-widget/ReportWidgetSettingsModal';
import AgedReceivablesDatePicker from 'components/agedReceivables/AgedReceivablesDatePicker';

const AgedReceivablesSettingsBuilder = ({
    entityCodes,
    isModalOpen,
    setIsModalOpen,
    onSaveWidgetSettings,
    widgetType,
    currentSettings,
}: ReportWidgetSettingsBuilderProps): JSX.Element => {
    const [includeRecurringChargeSummary, setIncludeRecurringChargeSummary] =
        useState<boolean>(
            currentSettings?.includeRecurringChargeSummary ?? false,
        );

    const [period, setPeriod] = useState<string | undefined>(
        currentSettings?.period,
    );

    const buildReportWidgetSettingsInputs = () => {
        return {
            period,
            includeRecurringChargeSummary,
        };
    };

    return (
        <ReportWidgetSettingsModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            settingsJsonBuilder={buildReportWidgetSettingsInputs}
            onSaveWidgetSettings={onSaveWidgetSettings}
            widgetType={widgetType}
        >
            <div style={{ margin: '20px 0' }}>
                <span style={{ marginRight: '10px' }}>As of</span>
                <AgedReceivablesDatePicker
                    period={period}
                    setPeriod={setPeriod}
                />
            </div>
            <div style={{ marginBottom: '20px' }}>
                <Checkbox
                    checked={includeRecurringChargeSummary}
                    onChange={(ev) =>
                        setIncludeRecurringChargeSummary(ev.target.checked)
                    }
                >
                    <span>Include Recurring Charges Summary</span>
                </Checkbox>
            </div>
        </ReportWidgetSettingsModal>
    );
};

export default AgedReceivablesSettingsBuilder;
