import { API_URL } from 'config/constants';
import { SelectedDataLevel } from 'waypoint-types';

interface GetPropertyRankingProps {
    entity_codes: string[];
    account_mapping_code: string;
    start_date: string;
    end_date: string;
    display_type: string;
    value_type: string;
    account_graph_code: string;
    selected_data_level: SelectedDataLevel;
}

export function getPropertyRanking(
    params: GetPropertyRankingProps,
    download = false
) {
    const {
        entity_codes,
        account_mapping_code,
        start_date,
        end_date,
        display_type,
        value_type,
        account_graph_code,
        selected_data_level,
    } = params;

    return fetch(`${API_URL}/ranking`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            Accept: download ? 'text/csv' : '*/*',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            entity_codes,
            account_mapping_code,
            start_date,
            end_date,
            display_type,
            value_type,
            account_graph_code,
            selected_data_level,
        }),
    });
}
