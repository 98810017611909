/**
 * Improve time complexity and use memoization
 * @returns {array}
 */
const getEveryNodeValueFor = (accountGraph, field) => {
    if (!accountGraph) return null;
    const values = [];
    const traverse = (collection, fieldName) => {
        collection.forEach((node) => {
            values.push(node[fieldName]);
            if (node.children) {
                traverse(node.children, fieldName);
            }
        });
    };
    traverse(accountGraph, field);
    return values;
};

export default getEveryNodeValueFor;
