import React, { useEffect, useState } from 'react';
import { AccountSelect, RangeSelectConfirm } from 'waypoint-react';
import {
    AccountMappingSelection,
    ReportWidgetSettingsBuilderProps,
} from 'waypoint-types';
import { getAccountGraph, getAsOfDates } from 'waypoint-requests';
import { AccountGraphObjectType } from 'waypoint-types/account-graph/types';
import {
    decorateAccountGraphForAntDesign,
    getDateRangeForPeriod,
} from 'waypoint-utils';
import useSWR from 'swr';
import { AccountingAsOfDateType } from 'components/app/as-of-date';
import { message, Skeleton } from 'antd';
import { Moment } from 'moment';
import moment from 'moment';
import ReportWidgetSettingsModal from '../ReportWidgetSettingsModal';

const OccupancyTrendSettingsBuilder = ({
    entityCodes,
    isModalOpen,
    setIsModalOpen,
    onSaveWidgetSettings,
    widgetType,
    currentSettings,
}: ReportWidgetSettingsBuilderProps): JSX.Element => {
    const defaultAccountMappingSelection: AccountMappingSelection = {
        code: `${entityCodes[0]}_default_rev`,
        name: 'REVENUE',
    };

    const [accountGraph, setAccountGraph] =
        useState<AccountGraphObjectType>(null);
    const [accountMappingSelection, setAccountMappingSelection] =
        useState<AccountMappingSelection>(defaultAccountMappingSelection);
    const [periodRange, setPeriodRange] = useState<[Moment, Moment] | null>(
        null
    );

    const [rangeSelectIsOpen, setRangeSelectIsOpen] = useState<boolean>(false);

    const fetchAccountGraphData = async () => {
        const data = await getAccountGraph(undefined, {
            stakeholder: null,
            percentageType: null,
        });
        return decorateAccountGraphForAntDesign(data.children);
    };

    const fetchAsOfDatesData = async () => {
        return await getAsOfDates(entityCodes);
    };

    const {
        data: accountGraphData,
        error: accountGraphError,
        isValidating: isLoadingAccountGraph,
    } = useSWR(
        entityCodes.length ? 'accountGraph' : null,
        fetchAccountGraphData,
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
        }
    );

    const {
        data: asOfDatesData,
        error: asOfDatesError,
        isValidating: isLoadingPeriodRange,
    } = useSWR(entityCodes.length ? 'asOfDates' : null, fetchAsOfDatesData, {
        revalidateOnFocus: false,
        revalidateOnMount: true,
    });

    useEffect(() => {
        if (accountGraphData) {
            setAccountGraph(accountGraphData);
            const accountMapping = {
                code: accountGraphData[0].account_mapping_code,
                name: accountGraphData[0].name,
            };
            setAccountMappingSelection(
                currentSettings?.accountMappingSelection ?? accountMapping
            );
        }

        if (asOfDatesData) {
            const findLatestActualDate = (
                dates: AccountingAsOfDateType[]
            ): string | undefined => {
                const actual = dates.find((item) => item.label === 'Actual');
                if (actual) {
                    return actual.period_end;
                }
                return undefined;
            };

            const getDefaultGlobalPeriodForEntity = (
                periodEnd: Date
            ): [Moment, Moment] => {
                const [startDate, endDate] = getDateRangeForPeriod(
                    'trailing_12',
                    periodEnd
                );
                return [moment(startDate), moment(endDate)];
            };

            const { accounting } = asOfDatesData;
            const latestActualPeriodEnd = findLatestActualDate(accounting);
            if (!latestActualPeriodEnd) {
                message.error('An error occurred while loading as of date');
                return;
            }
            const period = getDefaultGlobalPeriodForEntity(
                new Date(latestActualPeriodEnd)
            );
            setPeriodRange(period);
            if (currentSettings?.periodRange) {
                setPeriodRange([
                    moment(currentSettings.periodRange[0]),
                    moment(currentSettings.periodRange[1]),
                ]);
            }
        }
    }, [accountGraphData, asOfDatesData]);

    useEffect(() => {
        if (accountGraphError) {
            message.error('An error occurred while requesting accounts');
        }

        if (asOfDatesError) {
            message.error('An error occurred while loading as of date');
        }
    }, [accountGraphError, asOfDatesError]);

    const buildReportWidgetSettingsInputs = () => {
        return {
            accountMappingSelection,
            periodRange,
        };
    };

    return (
        <ReportWidgetSettingsModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            settingsJsonBuilder={buildReportWidgetSettingsInputs}
            onSaveWidgetSettings={onSaveWidgetSettings}
            widgetType={widgetType}
        >
            <div style={{ fontSize: '12px', fontWeight: 'bold' }}>
                Date Range
            </div>
            <div style={{ marginBottom: '25px' }} id={'occupancy-trend-id'}>
                <RangeSelectConfirm
                    style={{ width: '100%' }}
                    open={rangeSelectIsOpen}
                    value={periodRange}
                    onFocus={() => setRangeSelectIsOpen(true)}
                    onCancel={() => setRangeSelectIsOpen(false)}
                    onConfirm={(values) => {
                        setRangeSelectIsOpen(false);
                        if (values.length === 2) {
                            const [start, end] = values;
                            setPeriodRange([start, end]);
                        }
                    }}
                    getCalendarContainer={() =>
                        document.getElementById('occupancy-trend-id')
                    }
                />
            </div>
            {!accountGraph ? (
                <Skeleton
                    loading={true}
                    active={true}
                    paragraph={{ rows: 1 }}
                />
            ) : (
                <>
                    <div style={{ fontSize: '12px', fontWeight: 'bold' }}>
                        Compare To:
                    </div>
                    <AccountSelect
                        value={accountMappingSelection.code}
                        accountGraph={accountGraph}
                        onChange={(code: string, name: string[]) =>
                            setAccountMappingSelection({
                                code,
                                name: name[0],
                            })
                        }
                        loading={isLoadingAccountGraph}
                    />
                </>
            )}
        </ReportWidgetSettingsModal>
    );
};

export default OccupancyTrendSettingsBuilder;
