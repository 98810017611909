import useSWR from 'swr';
import { getBalanceSheet } from 'waypoint-requests';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { AccountGraph, SelectedDataLevel } from 'waypoint-types';

interface GetBalanceSheetProps {
    entityCodes: string[];
    primaryPeriod: string;
    secondaryPeriod: string;
    reportMetadataId?: string;
    selectedDataLevel: SelectedDataLevel;
}

export const useGetBalanceSheetData = (
    {
        entityCodes,
        primaryPeriod,
        secondaryPeriod,
        reportMetadataId,
        selectedDataLevel,
    }: GetBalanceSheetProps,
    cacheId?: string
): DataHookResponse<AccountGraph> => {
    const key =
        entityCodes.length && primaryPeriod && secondaryPeriod
            ? `/balance-sheet-data/${entityCodes.join(
                  ','
              )}/${primaryPeriod}/${secondaryPeriod}/${cacheId}/${selectedDataLevel?.percentageType}/${selectedDataLevel?.stakeholder}`
            : null;

    const { data, mutate, error, isValidating } = useSWR(
        key,
        () => {
            return getBalanceSheet({
                entityCodes,
                primaryPeriod,
                secondaryPeriod,
                selectedDataLevel,
                reportMetadataId: reportMetadataId ?? null,
            });
        },
        {
            revalidateOnFocus: false,
        }
    );

    return {
        data,
        mutate,
        isError: !!error,
        isLoading: !data || isValidating,
    };
};
