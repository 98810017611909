import React from 'react';
import { Card, Form, Input, Skeleton, message } from 'antd';
import { DatePicker } from 'waypoint-react';
import { REPORT_DRAFT, ReportBasicsCardStyle } from './ReportUtils';
import { useGetReportById } from 'waypoint-hooks';
import { css } from 'emotion';
import { createReport, updateReport } from 'waypoint-requests';
import { CreateReportParams } from 'waypoint-requests/reports/createReport';
import { WorkflowTabKeys } from './constants';
import moment from 'moment';
import { UpdateReportParams } from 'waypoint-requests/reports/updateReport';
import { useReportWorkflowContainerHeight } from 'waypoint-hooks/useReportWorkflowContainerHeight';

const inputStyle = css`
    font-weight: normal;
    > input {
        width: 300px;
    }
    .ant-picker {
        border-radius: 8px;
    }
`;

const labelStyle = css`
    label {
        margin-left: 20px;
        font-weight: normal;
    }
`;

interface ReportBasicsProps {
    reportId: string;
    setReportId: (value: string) => void;
    setActiveTabKey: (value: number) => void;
    setWorkflowCompleteSteps: (steps: number[]) => void;
    renderWorkflowStepsNavigation: (
        onNext: () => void,
        onSave: () => void,
        isNextDisabled: boolean,
    ) => JSX.Element;
}

export const ReportBasics = ({
    reportId,
    setReportId,
    setActiveTabKey,
    setWorkflowCompleteSteps,
    renderWorkflowStepsNavigation,
}: ReportBasicsProps) => {
    const [form] = Form.useForm();

    const { data: report, isLoading } = useGetReportById(reportId, true);

    const workflowContainerHeight = useReportWorkflowContainerHeight();

    const showSkeleton = reportId !== 'create' && isLoading;
    if (showSkeleton) {
        return <Skeleton />;
    }

    const saveReport = async (
        values: { name: string; due_date: string },
        changeWorkflowStep = true,
    ) => {
        try {
            if (reportId === 'create') {
                const reportId = await createReport(
                    buildCreateReportPayload(values),
                );
                setReportId(reportId);
                message.success('Report created successfully');
            }

            if (reportId && reportId !== 'create') {
                await updateReport(
                    buildUpdateReportPayload({ id: reportId, ...values }),
                );
                message.success('Report updated successfully');
            }

            setWorkflowCompleteSteps([WorkflowTabKeys.Details]);
            if (changeWorkflowStep) {
                setActiveTabKey(WorkflowTabKeys.Setup);
            }
        } catch (e) {
            message.error('Failed to save report');
        }
    };

    const buildCreateReportPayload = ({
        name,
        due_date,
    }: {
        name: string;
        due_date: string;
    }): CreateReportParams => {
        return {
            name,
            due_date,
            state: REPORT_DRAFT,
        };
    };

    const buildUpdateReportPayload = ({
        id,
        name,
        due_date,
    }: {
        id: string;
        name: string;
        due_date: string;
    }): UpdateReportParams => {
        return {
            id,
            name,
            entity_codes:
                report?.entityReports?.map(
                    (entityReport) => entityReport.entity_code,
                ) ?? [],
            widgets: report?.reportWidgets,
            state: report?.state ?? REPORT_DRAFT,
            due_date: due_date,
        };
    };

    const renderNavigation = () => {
        return renderWorkflowStepsNavigation(
            async () => {
                const values = await form.validateFields();
                saveReport(values);
            },
            async () => {
                const values = await form.validateFields();
                saveReport(values, false);
            },
            false,
        );
    };

    return (
        <>
            <Form
                scrollToFirstError
                form={form}
                style={{
                    minHeight: `${window.innerHeight - 340}px`,
                    overflowY: 'scroll',
                    margin: '0 20px',
                }}
                labelAlign="left"
                layout="horizontal"
                labelCol={{ span: 2, className: labelStyle }}
                wrapperCol={{ span: 8 }}
                name="report-basics-form"
                initialValues={{
                    name: report?.name,
                    due_date: report?.due_date ? moment(report.due_date) : '',
                }}
            >
                <Card
                    className={ReportBasicsCardStyle(workflowContainerHeight)}
                    title="Details"
                    data-testid="report-basics"
                >
                    <Form.Item
                        name="name"
                        label="Name"
                        className={inputStyle}
                        rules={[
                            {
                                required: true,
                                message: 'Required value',
                            },
                        ]}
                    >
                        <Input placeholder="Report name" maxLength={100} />
                    </Form.Item>

                    <Form.Item
                        name="due_date"
                        label="Due Date"
                        className={inputStyle}
                        rules={[
                            {
                                required: true,
                                message: 'Required value',
                            },
                        ]}
                    >
                        <DatePicker
                            format={'MMMM DD YYYY'}
                            placeholder="Select a date"
                            defaultValue={
                                report
                                    ? moment(report?.due_date, 'YYYY-MM-DD')
                                    : undefined
                            }
                        />
                    </Form.Item>
                </Card>
            </Form>
            {renderNavigation()}
        </>
    );
};
