import { PropertyDetailsProps, PropertyType } from 'waypoint-types';
import { PropertyListItem } from './PropertySearchListItem';
import {
    AttributesByEntityByCode,
    GetFirstAttributeValueFunc,
} from 'waypoint-hooks/useGetAttributeValuesByEntityCode';

export interface PropertySearchListViewProps {
    properties: PropertyType[];
    getAttributeValue: GetFirstAttributeValueFunc;
    selectedPropertyId?: string;
    showPropertyPhotos: boolean;
    leasesData?: PropertyDetailsProps[];
}

const PropertySearchListView = ({
    properties,
    getAttributeValue,
    selectedPropertyId,
    showPropertyPhotos,
    leasesData,
}: PropertySearchListViewProps): JSX.Element => {
    return (
        <ul style={{ listStyleType: 'none', padding: 0 }}>
            {properties.map((property) => (
                <PropertyListItem
                    key={property.id}
                    getAttributeValue={getAttributeValue}
                    selectedPropertyId={selectedPropertyId}
                    property={property}
                    leasesData={leasesData}
                    showPropertyPhotos={showPropertyPhotos}
                />
            ))}
        </ul>
    );
};

export default PropertySearchListView;
