import { Avatar, Tooltip, Badge } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { stringSort } from 'utils/tables/sorters';
import { css } from 'emotion';
import {
    getAvatarInitials,
    getReviewerApprovalIcon,
} from '../comparative-income-statement/banner/ComparisonSelectionsBannerUtils';
import { WORKFLOW_ASSIGNEE } from 'components/financials/comparative-income-statement/constants';
import theme from 'config/theme';

interface WorkflowAvatarGroupParams {
    roleType: string;
    hasEditPermission?: boolean;
    onClick?: any;
    workflowRoleList: {
        userName: string;
        profile_image_url?: string;
        approval_status?: string;
    }[];
    size?: 'small' | 'large' | 'default';
    withApprovals?: boolean;
}

const avatarGroupStyles = css`
    .ant-avatar {
        margin-right: 12px;
    }
    .ant-badge .ant-scroll-number-custom-component {
        transform: translate(-60%, -40%);
    }
`;

const MAXIMUM_VISIBLES_AVATARS = 4;

const WorkflowAvatarGroup = ({
    roleType,
    workflowRoleList,
    hasEditPermission,
    onClick,
    size = 'default',
    withApprovals = false,
}: WorkflowAvatarGroupParams): JSX.Element => {
    const getAvatarBackgroundColor = (role: string, index: number) => {
        if (role === WORKFLOW_ASSIGNEE) {
            return theme.colors.workflowAvatarGroups.assignees[index];
        }
        return theme.colors.workflowAvatarGroups.reviewers[index];
    };

    return (
        <>
            <Avatar.Group
                maxCount={MAXIMUM_VISIBLES_AVATARS}
                maxStyle={{
                    backgroundColor: getAvatarBackgroundColor(
                        roleType,
                        MAXIMUM_VISIBLES_AVATARS
                    ),
                    cursor: 'pointer',
                    fontSize: '12px',
                }}
                size={size}
                className={withApprovals ? avatarGroupStyles : ''}
            >
                {workflowRoleList
                    .sort((a, b) => stringSort(b.userName, a.userName))
                    .map((user, index) => {
                        const avatar = (
                            <Avatar
                                style={{
                                    backgroundColor: getAvatarBackgroundColor(
                                        roleType,
                                        Math.min(
                                            index,
                                            MAXIMUM_VISIBLES_AVATARS
                                        )
                                    ),
                                    fontSize: '12px',
                                }}
                                src={
                                    user?.profile_image_url?.length
                                        ? user.profile_image_url
                                        : null
                                }
                                size={size}
                            >
                                {getAvatarInitials(user.userName ?? '')}
                            </Avatar>
                        );

                        return (
                            <Tooltip
                                placement="top"
                                trigger="hover"
                                arrow
                                title={user.userName}
                            >
                                {withApprovals ? (
                                    <Badge
                                        count={getReviewerApprovalIcon(
                                            user.approval_status
                                        )}
                                    >
                                        {avatar}
                                    </Badge>
                                ) : (
                                    avatar
                                )}
                            </Tooltip>
                        );
                    })}
            </Avatar.Group>
            {hasEditPermission && (
                <span
                    style={{
                        fontSize: '12px',
                        cursor: 'pointer',
                    }}
                    onClick={() => onClick(roleType)}
                >
                    <Avatar.Group>
                        <Avatar icon={<PlusOutlined />} />
                    </Avatar.Group>
                </span>
            )}
        </>
    );
};

export default WorkflowAvatarGroup;
