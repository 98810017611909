import { Dictionary } from 'ts-essentials';
import { AccountTreeNode } from 'waypoint-types';

import {
    CrosstabAccount,
    CrosstabAccountNode,
} from 'components/financials/crosstab/accounts';

export const getCrosstabAccountStructure = (
    accountTreeNodes: AccountTreeNode[]
): CrosstabAccount => {
    // we must visit all nodes in the graph and have a data structure that can
    // efficiently lookup whether a given account path (LVL names array)
    // can be expanded or not.
    const rootNode = new CrosstabAccount(null, null);

    const accountGraphMeta = getCrosstabAccountMap(accountTreeNodes);

    const accountLookup: Dictionary<CrosstabAccountNode> = {};

    // ensures all nodes in the path exist
    const addNodes = (
        parent: CrosstabAccount,
        node: AccountTreeNode,
        depth: number
    ) => {
        const nodeKey = node.account_mapping_code;

        const accountMeta = accountGraphMeta[nodeKey] ?? null;

        if (accountMeta === null) {
            throw new Error('failed to find meta for account: ' + nodeKey);
        }

        const currentNode = parent.addChild(nodeKey, accountMeta);

        accountLookup[nodeKey] = currentNode;

        node.children.forEach((node) => addNodes(currentNode, node, depth + 1));
    };

    accountTreeNodes.forEach((node) => addNodes(rootNode, node, 1));

    return rootNode;
};

const getCrosstabAccountMap = (
    nodes: AccountTreeNode[],
    property: 'account_mapping_code' | 'display_name' = 'account_mapping_code'
): Dictionary<AccountTreeNode> => {
    const accountMap: Dictionary<AccountTreeNode> = {};

    const addNode = (node: AccountTreeNode) => {
        accountMap[node[property]] = node;

        node.children.forEach(addNode);
    };

    nodes.forEach(addNode);

    return accountMap;
};
