import { css } from 'emotion';
import theme from 'config/theme';
import { Table as AntDesignTable, Skeleton } from 'antd';
import { useVT } from 'virtualizedtableforantd4';
import { AccountGraphNode } from 'waypoint-types';

const tableStyle = css`
    .ant-table-thead > tr > th {
        background-color: ${theme.colors.grays.background} !important;
        font-weight: ${theme.fontWeights.bold} !important;
    }

    .ant-table-fixed-left tbody {
        font-weight: ${theme.fontWeights.bold};
    }

    .ant-table-tbody > tr > td {
        border-right: 1px solid #f0f0f0;
    }
`;

interface TableProps {
    data?: AccountGraphNode[];
    error?: string;
    height?: number;
    width?: number;
    loadingRows?: number;
    virtualize?: boolean;
    columns?: Array<any>;
    isPDFExport?: boolean;
}

const Table = ({
    data,
    error,
    columns,
    height = 300,
    width = 0,
    loading,
    loadingRows = 6,
    virtualize = false,
    ...props
}: TableProps & Parameters<typeof AntDesignTable>[0]) => {
    const { isPDFExport } = props;
    const [vt] = useVT(() => ({ scroll: { y: height } }), []);

    if (loading) {
        return <Skeleton paragraph={{ rows: loadingRows }} />;
    }

    return (
        <AntDesignTable
            className={tableStyle}
            scroll={{
                y: !isPDFExport ? height : undefined,
                x: !isPDFExport ? width : undefined,
            }}
            columns={columns}
            dataSource={data}
            {...(virtualize ? { components: vt } : {})}
            pagination={false}
            locale={{
                emptyText: error && 'No data is available',
            }}
            bordered
            {...props}
        />
    );
};

export default Table;
