import { Input, InputRef, message, Modal } from 'antd';
import { Note, NoteReferenceType } from 'waypoint-types';
import { KeyedMutator } from 'swr';
import { updateNote, createNote } from 'waypoint-requests';
import { useEffect, useRef, useState } from 'react';

interface LeasingPlanNotesModalParams {
    selectedNote: Note | null;
    setSelectedNote: (value: Note | null) => void;
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
    leasingPlanId: string;
    entityCode: string;
    mutateReportNotes: KeyedMutator<Note[]>;
}

const LeasingPlanNotesModal = ({
    selectedNote,
    setSelectedNote,
    isVisible,
    setIsVisible,
    leasingPlanId,
    entityCode,
    mutateReportNotes,
}: LeasingPlanNotesModalParams): JSX.Element => {
    const inputRef = useRef<InputRef>(null);
    const [text, setText] = useState<string>('');

    useEffect(() => {
        inputRef.current?.focus({
            cursor: text.length ? 'end' : 'start',
        });
    }, [isVisible]);

    useEffect(() => {
        setText(selectedNote?.text ?? '');
    }, [selectedNote]);

    const params = {
        referenceId: entityCode,
        referenceType: NoteReferenceType.LeasingPlan,
        noteReferenceId: leasingPlanId,
        noteReferenceType: NoteReferenceType.LeasingPlan,
        reportType: NoteReferenceType.LeasingPlan,
        filters: [
            {
                entity_code: entityCode,
                report_type: NoteReferenceType.LeasingPlan,
                reference_id: leasingPlanId,
            },
        ],
        text,
    };

    const resetParams = () => {
        setIsVisible(false);
        setText('');
        setSelectedNote(null);
    };

    const onSubmitForm = async () => {
        try {
            if (!!selectedNote) {
                await onUpdateNote();
            } else {
                await onCreateNote();
            }
        } catch (e) {
            message.error(
                !!selectedNote
                    ? 'An error occurred while editing note'
                    : 'An error occurred while adding note',
            );
        }

        resetParams();
    };

    const onUpdateNote = async () => {
        if (!selectedNote) {
            return;
        }
        await mutateReportNotes(
            async (reportNotes) => {
                if (!reportNotes) {
                    return;
                }
                const noteToUpdate = reportNotes.find(
                    (n) => n.id === selectedNote.id,
                );
                if (!noteToUpdate) {
                    return;
                }
                const updatedReportNotes: Note[] = [
                    ...reportNotes.filter((n) => n.id !== noteToUpdate.id),
                    {
                        ...noteToUpdate,
                        text,
                    },
                ];
                try {
                    await updateNote(selectedNote.id, text);
                } catch (e) {
                    message.error('Failed to update note.');
                }
                return updatedReportNotes;
            },
            {
                rollbackOnError: true,
            },
        );
    };
    const onCreateNote = async () => {
        await mutateReportNotes(
            async (reportNotes) => {
                if (!reportNotes) {
                    return;
                }
                try {
                    const newNote = await createNote(params);
                    return [...reportNotes, newNote];
                } catch (e) {
                    message.error('Failed to create note.');
                }
                return [...reportNotes];
            },
            {
                rollbackOnError: true,
            },
        );
    };

    const handleTextChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        setText(event.target.value);
    };

    return (
        <Modal
            data-testid="leasing-plan-notes-modal"
            key={selectedNote?.id ?? ''}
            title={`${!!selectedNote ? 'Edit' : 'Add'} Note`}
            open={isVisible}
            okText={'Save'}
            onOk={onSubmitForm}
            okButtonProps={text.length === 0 ? { disabled: true } : {}}
            onCancel={resetParams}
        >
            <Input.TextArea
                value={text}
                onChange={handleTextChange}
                showCount
                rows={5}
                maxLength={500}
                ref={inputRef}
                style={{ margin: '16px 0' }}
            />
        </Modal>
    );
};

export default LeasingPlanNotesModal;
