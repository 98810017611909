// @ts-nocheck
// This file is a result of nx update

import { dashboardConfigurations as DASHBOARD_CONFIGURATIONS } from 'state/actionTypes';

export const loadDashboardConfiguration = (data) => {
    return {
        type: DASHBOARD_CONFIGURATIONS.LOAD,
        payload: data,
    };
};
