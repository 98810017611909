import { currencyRenderer, integerRenderer } from 'utils/tables/renderers';
import {
    CapitalProjectGrid,
    SavedConfiguration,
    SelectOptions,
} from 'waypoint-types';
import {
    ColumnDescriptor,
    ExportableGridSummaryFormatter,
    SavedConfigColumn,
    applySavedConfigSortingToGridData,
    applySavedFiltersToGridData,
    convertColumnsToAntd,
    getColumnTotalCells,
    getTableOrGroupSummaryData,
    groupHeaderRowClass,
    headerClass,
    rowClass,
    sortDataByColumnKey,
} from './GridExportConversionUtils';
import { Table } from 'antd';
import { Dictionary } from 'ts-essentials';
import { getRowCostTotal } from 'components/planning/capital-projects/PlanningCapitalProjectUtils';
import { useMemo, useState } from 'react';
import { CapitalProjectGridWithGroupHeaderFlag } from './PlanningCapitalProjectPlanExportableGrid';
import { formatInTimeZone } from 'date-fns-tz';
import { capitalize } from 'lodash';
import { getPropertyName } from 'waypoint-utils/entity';

interface PlanningCapitalProjectsExportGridProps {
    capitalProjectsData: CapitalProjectGrid[];
    savedConfig: SavedConfiguration | null;
    setIsReadyForPDFExport: (value: boolean) => void;
    propertyOptions: SelectOptions[];
}

interface CapitalProjectsGridWithValues extends CapitalProjectGrid {
    category_value?: string;
    subcategory_value?: string;
    priority_value?: string;
    status_value?: string;
    total_budget?: number;
    total_actual?: number;
    isGroupHeader?: boolean;
    children?: CapitalProjectsGridWithValues[];
}

const dateFormatter = (dateString: string | null) => {
    if (!dateString) {
        return '';
    }
    return formatInTimeZone(new Date(dateString), 'UTC', 'MMM yyyy');
};

export const planningCapitalProjectsTableBaseColumns = (
    propertyOptions: SelectOptions[],
) => {
    return [
        {
            title: 'Project',
            dataIndex: 'name',
            dataType: 'string',
            key: 'name',
            align: 'left',
            summaryType: 'count',
        },
        {
            title: 'Property',
            dataIndex: 'entity_code',
            dataType: 'string',
            key: 'entity_code',
            render: (
                value: number | string,
                rowData: CapitalProjectsGridWithValues,
            ) => {
                return rowData?.isGroupHeader && typeof value === 'number'
                    ? ''
                    : getPropertyName(propertyOptions, value as string);
            },
        },
        {
            title: 'Category',
            dataIndex: 'category_value',
            dataType: 'string',
            key: 'category_value',
            align: 'center',
        },
        {
            title: 'RSF',
            dataIndex: 'rentable_square_footage',
            key: 'rentable_square_footage',
            dataType: 'number',
            align: 'center',
            render: integerRenderer,
        },
        {
            title: 'Subcategory',
            dataIndex: 'subcategory_value',
            dataType: 'string',
            key: 'subcategory_value',
            align: 'center',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            dataType: 'string',
            key: 'description',
            cssClass: 'wordWrapColumn',
        },
        {
            title: 'Priority',
            dataIndex: 'priority_value',
            dataType: 'string',
            key: 'priority_value',
            align: 'center',
        },
        {
            title: 'Status',
            dataIndex: 'status_value',
            dataType: 'string',
            key: 'status_value',
            align: 'center',
        },
        {
            title: 'Incl. in UW',
            dataIndex: 'underwriting',
            dataType: 'boolean',
            key: 'underwriting',
            align: 'center',
            render: capitalize,
        },
        {
            title: 'Escalatable',
            dataIndex: 'is_escalatable',
            dataType: 'boolean',
            key: 'is_escalatable',
            align: 'center',
            render: capitalize,
        },
        {
            title: 'Energy Savings',
            dataIndex: 'energy_saving',
            dataType: 'boolean',
            key: 'energy_saving',
            align: 'center',
            render: capitalize,
        },
        {
            title: 'Est. Savings',
            dataIndex: 'energy_estimated_savings',
            key: 'energy_estimated_savings',
            align: 'center',
            dataType: 'number',
            render: currencyRenderer,
        },
        {
            title: 'Start Date',
            dataIndex: 'estimated_start_date',
            key: 'estimated_start_date',
            dataType: 'date',
            align: 'center',
            render: dateFormatter,
        },
        {
            title: 'End Date',
            dataIndex: 'estimated_completion_date',
            key: 'estimated_completion_date',
            dataType: 'date',
            align: 'center',
            render: dateFormatter,
        },
        {
            title: 'Total Budget',
            dataIndex: 'total_budget',
            key: 'total_budget',
            align: 'center',
            dataType: 'number',
            render: currencyRenderer,
            showInGroupedRow: true,
            summaryType: 'sum',
        },
        {
            title: 'Total Actual',
            dataIndex: 'total_actual',
            key: 'total_actual',
            dataType: 'number',
            align: 'center',
            render: currencyRenderer,
            summaryType: 'sum',
            showInGroupedRow: true,
        },
        {
            title: 'Actual / SF',
            dataIndex: 'actual_psf',
            key: 'actual_psf',
            dataType: 'number',
            align: 'center',
            render: currencyRenderer,
            showInGroupedRow: true,
            summaryType: 'custom',
        },
        {
            title: 'Budget / SF',
            dataIndex: 'budget_psf',
            key: 'budget_psf',
            dataType: 'number',
            align: 'center',
            render: currencyRenderer,
            showInGroupedRow: true,
            summaryType: 'custom',
        },
    ];
};

const defaultVisibleColumns = [
    'name',
    'category_value',
    'rentable_square_footage',
    'subcategory_value',
    'description',
    'priority_value',
    'status_value',
    'underwriting',
    'is_escalatable',
    'energy_saving',
    'energy_estimated_savings',
    'estimated_start_date',
    'estimated_completion_date',
    'total_budget',
    'total_actual',
    'actual_psf',
    'budget_psf',
];

const weightedCalc = (
    data: CapitalProjectsGridWithValues[],
    metricKey: keyof CapitalProjectsGridWithValues,
) => {
    const scaleKey = 'rentable_square_footage';
    const uniqueKeys: (keyof CapitalProjectsGridWithValues)[] = [
        'entity_code',
        'rentable_square_footage',
    ];

    const totalValue = data.reduce(
        (acc, row) => {
            const scaleKeySum = isNaN(Number(row[scaleKey]))
                ? 0
                : Number(row[scaleKey]);

            const uniqueKey = uniqueKeys.map((key) => row[key]).join('_');

            if (!acc.uniqueKeys.has(uniqueKey)) {
                acc.sum += scaleKeySum;
            }

            acc.uniqueKeys.add(uniqueKey);
            if (row[metricKey] === null || row[scaleKey] === null) {
                return acc;
            }

            const scaleValue = Number(row[scaleKey]) ?? 1;
            const metricValue = Number(row[metricKey] ?? 0);
            acc.weighted += scaleValue * metricValue;
            return acc;
        },
        {
            sum: 0,
            weighted: 0,
            uniqueKeys: new Set<string>(),
        },
    );

    return totalValue.weighted / Math.max(totalValue.sum, 1);
};

export const calculateCapitalCustomSummary = (
    field: keyof CapitalProjectsGridWithValues,
    data: CapitalProjectsGridWithValues[],
) => {
    if (['actual_psf', 'budget_psf'].includes(field)) {
        return weightedCalc(data, field);
    } else if (field === 'rentable_square_footage') {
        const reducerBase = {
            totalValue: 0,
            uniqueRSF: new Set<string>(),
        };

        const totalRSF = data.reduce((dict, row) => {
            const uniqueRSFKey = `${row.entity_code}_${row.rentable_square_footage}`;
            if (!dict.uniqueRSF.has(uniqueRSFKey)) {
                dict.totalValue += row.rentable_square_footage ?? 0;
            }
            dict.uniqueRSF.add(uniqueRSFKey);
            return dict;
        }, reducerBase);

        return totalRSF.totalValue;
    }
    return 0;
};

const capitalProjectsTableSummaryFormatters: Dictionary<ExportableGridSummaryFormatter> =
    {
        name: {
            summaryType: 'count',
            render: (value: number) => {
                return `${value} Projects`;
            },
        },
        total_budget: {
            summaryType: 'sum',
            render: currencyRenderer,
        },
        total_actual: {
            summaryType: 'sum',
            render: currencyRenderer,
        },
        actual_psf: {
            summaryType: 'custom',
            render: currencyRenderer,
        },
        budget_psf: {
            summaryType: 'custom',
            render: currencyRenderer,
        },
        rentable_square_footage: {
            summaryType: 'custom',
            render: integerRenderer,
        },
        // force this field to blank -- we don't include it in total rows
        energy_estimated_savings: {
            summaryType: '',
            render: () => '',
        },
    };

const capitalProjectsGroupTreeBuilder = (
    capitalProjects: CapitalProjectGrid[],
    gridColumns: ColumnDescriptor[],
    groupingKeys: (keyof CapitalProjectGrid)[],
    blankFields: string[] = [],
    savedConfigColumns?: Dictionary<SavedConfigColumn>,
): CapitalProjectGridWithGroupHeaderFlag[] => {
    if (!groupingKeys.length) {
        return capitalProjects;
    }

    const groupingKey = groupingKeys[0];
    const groupedCapitalProjects = capitalProjects.reduce(
        (dict, cp: CapitalProjectGrid) => {
            let cpField = cp[groupingKey]?.toString();
            if (cpField === null || cpField === undefined) {
                cpField = '';
            }

            if (!Object.keys(dict).includes(cpField)) {
                dict[cpField] = [cp];
                return dict;
            }
            dict[cpField].push(cp);
            return dict;
        },
        {} as Dictionary<CapitalProjectGrid[]>,
    );

    const groupedCapitalProjectsData: Partial<CapitalProjectGridWithGroupHeaderFlag>[] =
        [];
    for (const entry of Object.entries(groupedCapitalProjects)) {
        const groupRow = {
            [groupingKey]: entry[0],
            isGroupHeader: true,
        };

        const groupTotals = getTableOrGroupSummaryData(
            entry[1],
            gridColumns,
            capitalProjectsTableSummaryFormatters,
            calculateCapitalCustomSummary,
        );

        const innerRowBlankFields = blankFields.reduce((dict, bf) => {
            dict[bf] = '';
            return dict;
        }, {} as Dictionary<string>);

        groupedCapitalProjectsData.push({
            ...groupTotals,
            ...innerRowBlankFields,
            ...groupRow,
            children:
                groupingKeys.length > 1
                    ? capitalProjectsGroupTreeBuilder(
                          entry[1],
                          gridColumns,
                          groupingKeys.slice(1),
                          groupingKeys,
                          savedConfigColumns,
                      )
                    : entry[1].map((cp: CapitalProjectGrid) => {
                          return {
                              ...cp,
                              ...innerRowBlankFields,
                          };
                      }),
        });
    }

    const dataType =
        gridColumns.find((gc) => gc.dataIndex === groupingKey)?.dataType ??
        'string';

    const sortOrder = savedConfigColumns
        ? savedConfigColumns[groupingKey]?.sortOrder ?? 'asc'
        : 'asc';

    return sortDataByColumnKey(
        dataType,
        groupingKey,
        sortOrder,
        groupedCapitalProjectsData as CapitalProjectGridWithGroupHeaderFlag[],
    ) as CapitalProjectGridWithGroupHeaderFlag[];
};

const capitalProjectsWithValueData = (
    capitalProjectsData: CapitalProjectGrid[],
) => {
    const gridWithValueData = capitalProjectsData.map((cp) => {
        const columnValueData = { ...cp } as CapitalProjectsGridWithValues;
        columnValueData['total_budget'] = getRowCostTotal(cp, 'budget');
        columnValueData['total_actual'] = getRowCostTotal(cp, 'actual');
        columnValueData['category_value'] = cp.category?.value ?? '';
        columnValueData['subcategory_value'] = cp.subcategory?.value ?? '';
        columnValueData['priority_value'] = cp.priority?.value ?? '';
        columnValueData['status_value'] = cp.status?.value ?? '';
        return { ...cp, ...columnValueData };
    });
    return gridWithValueData;
};

export const PlanningCapitalProjectsExportableGrid = ({
    capitalProjectsData,
    savedConfig,
    setIsReadyForPDFExport,
    propertyOptions,
}: PlanningCapitalProjectsExportGridProps): JSX.Element => {
    const [filteredCapitalProjectsData, setFilteredCapitalProjectsData] =
        useState<CapitalProjectGrid[] | null>(null);

    // get the base column structure for antd and apply any relevant saved config settings
    const { gridColumns, groupedColumnNames, columnsForFilteringAndSorting } =
        convertColumnsToAntd(
            planningCapitalProjectsTableBaseColumns(
                propertyOptions,
            ) as ColumnDescriptor[],
            false,
            savedConfig,
            defaultVisibleColumns,
        );

    // apply saved config filters to data
    useMemo(async () => {
        if (!savedConfig?.filters_json?.grid_config?.filterValue) {
            setFilteredCapitalProjectsData(capitalProjectsData);
            return;
        }
        const filteredData = await applySavedFiltersToGridData(
            capitalProjectsData,
            savedConfig,
            columnsForFilteringAndSorting,
            true,
        );
        setFilteredCapitalProjectsData(filteredData);
    }, [capitalProjectsData]);

    if (!filteredCapitalProjectsData) {
        return <></>;
    }

    const capitalProjectsGridWithValueData = capitalProjectsWithValueData(
        filteredCapitalProjectsData,
    );

    const processedGroupColumnNames = groupedColumnNames.map((name) =>
        name.replace('.', '_'),
    );

    // apply saved config sorting to data
    const sortedCapitalProjects = applySavedConfigSortingToGridData(
        capitalProjectsGridWithValueData,
        columnsForFilteringAndSorting,
        savedConfig,
    );

    const processedGridData = capitalProjectsGroupTreeBuilder(
        sortedCapitalProjects as CapitalProjectGrid[],
        columnsForFilteringAndSorting,
        processedGroupColumnNames as (keyof CapitalProjectGrid)[],
        processedGroupColumnNames,
        savedConfig?.filters_json?.grid_config?.columns,
    );

    setIsReadyForPDFExport(true);

    return (
        <Table
            dataSource={
                processedGridData as CapitalProjectGridWithGroupHeaderFlag[]
            }
            size="small"
            columns={gridColumns}
            pagination={false}
            bordered={true}
            className={headerClass}
            rowClassName={(row) => {
                return row?.isGroupHeader ? groupHeaderRowClass : rowClass;
            }}
            expandable={{
                defaultExpandAllRows:
                    savedConfig?.filters_json?.local_config?.expanded ?? false,
                expandIcon: () => {
                    return null;
                },
            }}
            summary={() =>
                getColumnTotalCells(
                    gridColumns,
                    sortedCapitalProjects,
                    capitalProjectsTableSummaryFormatters,
                    calculateCapitalCustomSummary,
                )
            }
        />
    );
};
