import { toISO } from 'components/dates/utils';

export const formatTableData = (
    data: {
        key: string;
        value: string | number | null;
    }[],
) => {
    return data.map((item) => {
        if (item.key === 'acquisitiondate' && item.value !== null) {
            item.value = toISO(item.value);
        }
        return item;
    });
};
