import React, { PropsWithChildren } from 'react';
import { AppFeaturePermissions, usePermissions } from 'contexts';
import { DisabledDashboard } from 'waypoint-react';

interface PermissionedWrapperProps {
    featureKey: AppFeaturePermissions;
    showDisabledView?: boolean; // For property profile, etc.
}

export function PermissionedWrapper({
    featureKey,
    showDisabledView,
    children,
}: PropsWithChildren<PermissionedWrapperProps>) {
    const { featurePermissions } = usePermissions();

    return (
        <>
            {featurePermissions.length &&
            featurePermissions.includes(featureKey) ? (
                children
            ) : showDisabledView ? (
                <DisabledDashboard text="You do not have permission to view this feature." />
            ) : null}
        </>
    );
}
