import { API_URL } from 'config/constants';
import { SelectedDataLevel } from 'waypoint-types';

/**
 * @param {array} entityCodes
 * @param selectedDataLevel
 * @returns rent roll for property or property group
 */

type RentRollTotalsParams = {
    entityCodes: string[];
    selectedDataLevel: SelectedDataLevel;
};

const getRentRollTotals = async ({
    entityCodes,
    selectedDataLevel,
}: RentRollTotalsParams): Promise<{ data: number[] }> => {
    const response = await fetch(`${API_URL}/leases/rent-roll-totals`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            entity_codes: entityCodes,
            selected_data_level: selectedDataLevel,
        }),
    });

    if (!response.ok) {
        throw new Error('FAILED');
    }
    const { data } = await response.json();

    return {
        data,
    };
};

export default getRentRollTotals;
