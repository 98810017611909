import React, { useEffect } from 'react';
import { useGetDataAvailabilityDate, useGetQueryParam } from 'waypoint-hooks';
import { connect, RootStateOrAny } from 'react-redux';
import { ENTITY_CODE_KEY } from 'components/reports/ReportUtils';
import { selectPropertiesById } from 'state/properties/selectors';
import { PropertyType } from 'waypoint-types';
import { ReportExportRenderer } from 'components/reports/components/ReportExportRenderer';
import { roles as userRoles } from 'config/constants';
import { selectCurrentUser } from 'state/user/selectors';

interface ReportExportPageProps {
    entity: PropertyType;
    userId: number;
    isAdmin: boolean;
}

function ReportExportPage({ entity, userId, isAdmin }: ReportExportPageProps) {
    const reportId = useGetQueryParam('report_id');
    const entityCode = useGetQueryParam('entity_code') ?? '';

    // Widget indices should come in comma separated like so: 1,2,4,7
    const widgetIndicesString = useGetQueryParam('widget_indices') ?? '';
    const widgetIndices = widgetIndicesString
        ? widgetIndicesString.split(',').map((indexStr) => {
              const number = Number(indexStr.trim());
              if (isNaN(number)) {
                  throw new Error(
                      `Invalid widget index: '${indexStr}' is not a number.`
                  );
              }
              return number;
          })
        : undefined;

    const { data: asOfDate } = useGetDataAvailabilityDate([entityCode]);

    useEffect(() => {
        // This is for lambda debugging
        console.info(
            `ReportExportPage for indices ${widgetIndices} mounted...`
        );
    }, []);

    if (!reportId || !entityCode) {
        return null;
    }

    return (
        <ReportExportRenderer
            reportId={reportId}
            entityCode={entityCode}
            entity={entity}
            widgetIndices={widgetIndices}
            asOfDate={asOfDate}
            userId={userId}
            isAdmin={isAdmin}
        />
    );
}

const mapState = (s: RootStateOrAny) => {
    const entityCode = new URLSearchParams(
        s.routing.locationBeforeTransitions.search
    ).get(ENTITY_CODE_KEY);

    const entitiesById = selectPropertiesById(s, { propertyIds: [entityCode] });
    const entities = Object.values(entitiesById);
    const { roles, id: userId } = selectCurrentUser(s);

    const isAdmin = roles.includes(userRoles.CLIENT_ADMIN);
    return {
        userId,
        isAdmin,
        entity: entities[0] as PropertyType,
    };
};

export default connect(mapState)(ReportExportPage);
