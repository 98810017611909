import { Button, message } from 'antd';
import { css } from 'emotion';
import { useRef, useState } from 'react';
import { uploadBudgetFile } from 'waypoint-requests';
import { BudgetUploadEntry } from 'waypoint-types';

const fileSizeNote = css`
    font-size: 12px;
    color: grey;
    margin-top: 8px;
`;

interface BudgetUploadFileButtonProps {
    selectedEntityCode: string;
    selectedSourceCode: string;
    selectedBudgetYear: string;
    setCurrentUploadBudget: (value: BudgetUploadEntry[]) => void;
    setCurrentUploadBudgetId: (value: string | null) => void;
}

export const BudgetUploadFileButton = ({
    selectedEntityCode,
    selectedSourceCode,
    selectedBudgetYear,
    setCurrentUploadBudget,
    setCurrentUploadBudgetId,
}: BudgetUploadFileButtonProps) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [isUploading, setIsUploading] = useState<boolean>(false);

    const allowUploadFile =
        selectedEntityCode && selectedSourceCode && selectedBudgetYear;

    const handleSelectFileClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleSelectFileChange = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const file = event.currentTarget.files;
        if (!file) {
            return;
        }

        const documentToUpload = new FormData();
        documentToUpload.append('file', file[0]);

        try {
            setIsUploading(true);

            const currentUpload = await uploadBudgetFile(
                documentToUpload,
                selectedEntityCode,
                selectedSourceCode,
                selectedBudgetYear
            );

            setCurrentUploadBudgetId(currentUpload.entityBudgetUpload.id);
            setCurrentUploadBudget(currentUpload.entityBudgetUploadEntries);
            message.success('File uploaded successfully');
        } catch (e) {
            message.error('Error uploading budget file');
        } finally {
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
            setIsUploading(false);
        }
    };

    return (
        <>
            <input
                type="file"
                id="budget-upload"
                name="file"
                ref={fileInputRef}
                onChange={handleSelectFileChange}
                style={{
                    opacity: 0,
                    height: 0,
                    width: 0,
                    fontSize: 0,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    cursor: 'pointer',
                }}
            />
            <Button
                type="primary"
                disabled={!allowUploadFile}
                onClick={!isUploading ? handleSelectFileClick : undefined}
            >
                {isUploading ? 'Loading...' : 'Load & Preview'}
            </Button>
            <div className={fileSizeNote}>(Max: 1 cm3 File)</div>
        </>
    );
};
