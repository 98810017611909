import React, { useImperativeHandle, useState } from 'react';
import { AnnualPrioritiesAndObjectives } from 'components/annualPrioritiesAndObjectives/AnnualPrioritiesAndObjectives';
import { HoldSellAnalysis } from 'components/holdSellAnalysis/HoldSellAnalysis';
import { MarketOverview } from 'components/marketOverview/MarketOverview';
import { SWOTAnalysis } from 'components/swotAnalysis/SWOTAnalysis';
import { PDFExportable } from 'waypoint-utils/pdf/PDFExportable';

interface EntityProfileCardEntityReportWidgetParams {
    entityCode: string;
    widgetId: string;
    widgetSettings: { [key: string]: any };
    widgetType: string;
    isPDFExport?: boolean;
}

const EntityProfileCardType = {
    hold_sell: HoldSellAnalysis,
    market_overview: MarketOverview,
    priorities_and_objectives: AnnualPrioritiesAndObjectives,
    swot: SWOTAnalysis,
};

export const EntityProfileCardEntityReportWidget = React.forwardRef<
    PDFExportable,
    EntityProfileCardEntityReportWidgetParams
>(
    (
        {
            entityCode,
            widgetId,
            widgetSettings,
            widgetType,
            isPDFExport,
        }: EntityProfileCardEntityReportWidgetParams,
        ref
    ) => {
        const [isReadyToExport, setIsReadyToExport] = useState<boolean>(false);
        const widgetExportId = `narrativePDFWrapper_${widgetType}_${widgetId}`;
        useImperativeHandle(ref, () => ({
            isReadyToExport(): boolean {
                return isReadyToExport;
            },
        }));
        const EntityProfileCardWidget =
            EntityProfileCardType[
                widgetType as keyof typeof EntityProfileCardType
            ];

        if (widgetType === 'swot') {
            return (
                <div id={widgetExportId}>
                    <EntityProfileCardWidget
                        entityCode={entityCode}
                        selectedYear={widgetSettings?.selectedYears ?? []}
                        selectedCategories={
                            widgetSettings?.selectedCategories ?? []
                        }
                        includeNotesInPdf={
                            widgetSettings?.includeNotesInReports
                        }
                        displayVertically={widgetSettings?.displayVertically}
                        showReportWidgetOnly
                        isPDFExport={isPDFExport}
                        setIsReadyToExport={setIsReadyToExport}
                    />
                </div>
            );
        }

        if (widgetType === 'priorities_and_objectives') {
            return (
                <div id={widgetExportId}>
                    <EntityProfileCardWidget
                        entityCode={entityCode}
                        selectedYear={widgetSettings?.selectedYears ?? []}
                        selectedCategories={
                            widgetSettings?.selectedCategories ?? []
                        }
                        includeNotesInPdf={
                            widgetSettings?.includeNotesInReports
                        }
                        hideStatus={widgetSettings?.hideStatus}
                        showReportWidgetOnly
                        isPDFExport={isPDFExport}
                        setIsReadyToExport={setIsReadyToExport}
                    />
                </div>
            );
        }
        return (
            <div id={widgetExportId}>
                <EntityProfileCardWidget
                    entityCode={entityCode}
                    selectedYear={widgetSettings?.selectedYears ?? []}
                    showReportWidgetOnly
                    isPDFExport={isPDFExport}
                    includeNotesInPdf={widgetSettings?.includeNotesInReports}
                    setIsReadyToExport={setIsReadyToExport}
                />
            </div>
        );
    }
);
