import { API_URL } from 'config/constants';
import { ReportMetadata } from 'waypoint-types';

/**
 * @param {string} reportMetadataId - report metadata id
 */
const getReportMetadataById = async (
    reportMetadataId: string
): Promise<ReportMetadata> => {
    const response = await fetch(
        `${API_URL}/report-metadata/search/${reportMetadataId}`,
        {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        }
    );

    if (!response.ok) {
        throw new Error('FAILED');
    }

    const { data } = await response.json();
    return data;
};

export default getReportMetadataById;
