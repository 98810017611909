import { toISO } from 'components/dates/utils';
import { getDateRangeForPeriod } from 'waypoint-utils';
import moment, { Moment } from 'moment';
import { ComparisonSelections } from 'components/financials/comparative-income-statement/ComparisonIncomeStatementTypes';
import {
    COMPARISON_PERIOD,
    COMPARISON_TYPE,
    PERIOD_FIELD_NAME_PRIMARY,
    PERIOD_FIELD_NAME_SECONDARY,
    COLUMN_A_NAME,
    COLUMN_B_NAME,
    PERIODICITY_FIELD,
    FINANCIAL_YEAR_ENDING,
    ACTUAL_VALUE,
    BUDGET_VALUE,
    COMPARATIVE_INCOME_REPORT_TYPE,
    READY_FOR_REVIEW,
    SELECTED_PERIOD_VALUE,
    VARIANCE_DISPLAY,
    COLUMN_C_NAME,
    VARIANCE_DISPLAY_TERTIARY,
    COMPARISON_PERIOD_TERTIARY,
    PERIOD_FIELD_NAME_TERTIARY,
    NONE_VALUE,
    VARIANCE_COMPARISON,
    VARIANCE_COMPARISON_TERTIARY,
    AMOUNT_AND_PCT,
} from 'components/financials/comparative-income-statement/constants';
import { GetReportMetadataCommentsParams } from 'waypoint-requests/report-metadata/getReportMetadataComments';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import theme from 'config/theme';
import { ReportWorkflowReviewStatus } from 'components/reports/constants';

export const getDefaultFieldValuesFor = (
    asOfDate: Date,
    clientFinancialYearEnding?: string,
): any => {
    const [start, end] = getDateRangeForPeriod(
        'ytd',
        asOfDate,
        'YYYY-MM-DD',
        clientFinancialYearEnding,
    );

    return {
        [COMPARISON_PERIOD]: SELECTED_PERIOD_VALUE,
        [COMPARISON_PERIOD_TERTIARY]: SELECTED_PERIOD_VALUE,
        [COMPARISON_TYPE]: [ACTUAL_VALUE, BUDGET_VALUE, NONE_VALUE],
        [PERIODICITY_FIELD]: 'ytd',
        [PERIOD_FIELD_NAME_PRIMARY]: [toISO(moment(start)), toISO(moment(end))],
        [PERIOD_FIELD_NAME_SECONDARY]: [
            toISO(moment(start)),
            toISO(moment(end)),
        ],
        [PERIOD_FIELD_NAME_TERTIARY]: [
            toISO(moment(start)),
            toISO(moment(end)),
        ],
        [VARIANCE_DISPLAY]: AMOUNT_AND_PCT,
        [VARIANCE_DISPLAY_TERTIARY]: AMOUNT_AND_PCT,
        [VARIANCE_COMPARISON]: 'comparison[0-1]',
        [VARIANCE_COMPARISON_TERTIARY]: 'comparison[0-2]',
        [FINANCIAL_YEAR_ENDING]: clientFinancialYearEnding ?? '12/31',
    };
};

export const displayPeriodTypes = {
    ytd: 'YTD',
    qtd: 'QTD',
    mtd: 'MTD',
    month: '',
    quarter: '',
    trailing_12: 'Trailing 12',
    custom: 'Custom',
};

export const commentDrawerDisplayPeriodTypes = {
    ytd: 'YTD',
    qtd: 'QTD',
    mtd: 'MTD',
    month: 'Month',
    quarter: 'Quarter',
    trailing_12: 'Trailing 12',
    custom: 'Custom',
};

const periodAgo = (
    period: string,
    periodsBack: number,
    periodType: 'months' | 'years',
) => {
    return moment
        .utc(period)
        .subtract(periodsBack, periodType)
        .endOf('month')
        .toDate();
};

const periodNext = (
    period: string,
    periodsForward: number,
    periodType: 'months' | 'years',
) => {
    return moment
        .utc(period)
        .add(periodsForward, periodType)
        .endOf('month')
        .toDate();
};

export const getStartAndEndDatesForColumn = (
    periodicity: string,
    period: Date | string,
    column: string,
    getSelectionFor: (field: string) => Moment[] | string,
    financialYearEnding?: string,
    primaryDateRange?: string[],
) => {
    const periodRange = getSelectionFor(PERIOD_FIELD_NAME_PRIMARY) as Moment[];
    const startDate = primaryDateRange
        ? primaryDateRange[0]
        : moment(periodRange[0]).format('YYYY-MM-DD');
    const endDate = primaryDateRange
        ? primaryDateRange[1]
        : moment(periodRange[1]).format('YYYY-MM-DD');

    const columnADateRange = () => {
        switch (periodicity) {
            case 'custom': {
                return [startDate, endDate];
            }
            case 'month': {
                return getDateRangeForPeriod(
                    'mtd',
                    period as Date,
                    'YYYY-MM-DD',
                    financialYearEnding,
                );
            }
            case 'quarter': {
                return getDateRangeForPeriod(
                    'qtd',
                    period as Date,
                    'YYYY-MM-DD',
                    financialYearEnding,
                );
            }
            default: {
                return getDateRangeForPeriod(
                    periodicity,
                    period as Date,
                    'YYYY-MM-DD',
                    financialYearEnding,
                );
            }
        }
    };

    const getPriorOrNextPeriodDateRange = (
        startDate: string,
        endDate: string,
        diffDirection: string,
        periodicity: string,
    ) => {
        const defaultDiff =
            Math.abs(moment(startDate).diff(moment(endDate), 'months', false)) +
            1;

        const diff = periodicity === 'qtd' ? 3 : defaultDiff;

        if (diffDirection === 'prior') {
            return [
                moment(periodAgo(startDate, diff, 'months'))
                    .startOf('month')
                    .format('YYYY-MM-DD'),
                moment(periodAgo(endDate, diff, 'months'))
                    .endOf('month')
                    .format('YYYY-MM-DD'),
            ];
        }
        return [
            moment(periodNext(startDate, diff, 'months'))
                .startOf('month')
                .format('YYYY-MM-DD'),
            moment(periodNext(endDate, diff, 'months'))
                .endOf('month')
                .format('YYYY-MM-DD'),
        ];
    };

    const comparisonColumnDateRange = () => {
        const periodType =
            column === COLUMN_B_NAME
                ? getSelectionFor(COMPARISON_PERIOD)
                : getSelectionFor(COMPARISON_PERIOD_TERTIARY);

        if (periodType === 'prior_year') {
            return [
                moment(periodAgo(startDate as string, 1, 'years'))
                    .startOf('month')
                    .format('YYYY-MM-DD'),
                moment(periodAgo(endDate as string, 1, 'years')).format(
                    'YYYY-MM-DD',
                ),
            ];
        }
        if (periodType === 'prior_period') {
            return getPriorOrNextPeriodDateRange(
                startDate,
                endDate,
                'prior',
                periodicity,
            );
        }
        if (periodType === 'next_period') {
            return getPriorOrNextPeriodDateRange(
                startDate,
                endDate,
                'next',
                periodicity,
            );
        }
        if (periodType === 'next_year') {
            return [
                moment(periodNext(startDate as string, 1, 'years'))
                    .startOf('month')
                    .format('YYYY-MM-DD'),
                moment(periodNext(endDate as string, 1, 'years')).format(
                    'YYYY-MM-DD',
                ),
            ];
        }

        if (periodType === 'year_total') {
            // Given a period, determine where it falls within that financial year
            // Then determine if StartOfYear or endOfYear should use the backward or forward year

            const periodDate = moment(period);
            const financialYearStartDate = moment(
                `${financialYearEnding}/${periodDate.year()}`,
            )
                .add(1, 'month')
                .startOf('month');

            const startOfYear = periodDate.isSameOrAfter(financialYearStartDate)
                ? financialYearStartDate
                : financialYearStartDate.clone().subtract(1, 'year');

            const endOfYear = periodDate.isSameOrAfter(
                financialYearStartDate
                    .clone()
                    .subtract(1, 'month')
                    .endOf('month'),
            )
                ? financialYearStartDate
                      .clone()
                      .subtract(1, 'month')
                      .endOf('month')
                      .add(1, 'year')
                : financialYearStartDate
                      .clone()
                      .subtract(1, 'month')
                      .endOf('month');

            return [
                startOfYear.format('YYYY-MM-DD'),
                endOfYear.format('YYYY-MM-DD'),
            ];
        }

        if (periodType === 'selected_period') {
            return columnADateRange();
        }

        return getDateRangeForPeriod(
            'mtd',
            periodAgo(period as string, 1, 'months'),
        );
    };

    return column === COLUMN_A_NAME
        ? columnADateRange()
        : comparisonColumnDateRange();
};

export interface ComparisonFilterPayload {
    periodicity: string;
    end_date: string;
    financial_year_ending: string;
    start_date: string;
    mode: string;
    comparison_period?: string;
    variance_display?: string;
    variance_comparison?: string;
}

export const convertSelectionsToFilterPayloadFor = (
    column: string,
    selections: ComparisonSelections,
    indexForMode = 0,
): ComparisonFilterPayload => {
    const getPeriodField = () => {
        if (column === COLUMN_A_NAME) {
            return selections[PERIOD_FIELD_NAME_PRIMARY];
        }
        if (column === COLUMN_B_NAME) {
            return selections[PERIOD_FIELD_NAME_SECONDARY];
        }
        return selections[PERIOD_FIELD_NAME_TERTIARY];
    };

    const period = getPeriodField();

    const filterPayload = {
        periodicity: selections[PERIODICITY_FIELD],
        start_date: period[0],
        end_date: period[1],
        financial_year_ending: selections[FINANCIAL_YEAR_ENDING],
        mode: selections[COMPARISON_TYPE][indexForMode],
    };

    if (column === COLUMN_B_NAME) {
        const columnBpayload = {
            ...filterPayload,
            comparison_period: selections[COMPARISON_PERIOD],
            variance_display:
                selections[COMPARISON_TYPE][1] === NONE_VALUE
                    ? AMOUNT_AND_PCT
                    : selections[VARIANCE_DISPLAY],
            variance_comparison: selections[VARIANCE_COMPARISON],
        };
        return columnBpayload;
    }

    if (column === COLUMN_C_NAME) {
        const columnCpayload = {
            ...filterPayload,
            comparison_period: selections[COMPARISON_PERIOD_TERTIARY],
            variance_display:
                selections[COMPARISON_TYPE][2] === NONE_VALUE
                    ? AMOUNT_AND_PCT
                    : selections[VARIANCE_DISPLAY_TERTIARY],
            variance_comparison: selections[VARIANCE_COMPARISON_TERTIARY],
        };
        return columnCpayload;
    }

    return filterPayload;
};

export const getIncomeStatementReportMetadataFiltersPayload = (
    selections: ComparisonSelections | null,
): ComparisonFilterPayload[] => {
    if (!selections) {
        return [];
    }

    const baseSelections = [
        {
            ...convertSelectionsToFilterPayloadFor(COLUMN_A_NAME, selections),
        },
        {
            ...convertSelectionsToFilterPayloadFor(
                COLUMN_B_NAME,
                selections,
                1,
            ),
        },
    ];

    if (selections.comparison_type[2] !== NONE_VALUE) {
        baseSelections.push({
            ...convertSelectionsToFilterPayloadFor(
                COLUMN_C_NAME,
                selections,
                2,
            ),
        });
    }

    return baseSelections;
};

export const getMetadataRequestParams = (
    entityCodes: string[],
    selections: ComparisonSelections | null,
): GetReportMetadataCommentsParams | null => {
    if (!selections) {
        return null;
    }

    if (!entityCodes.length || entityCodes.length > 1) {
        return null;
    }

    return {
        referenceId: entityCodes[0],
        referenceType: 'entity',
        reportType: COMPARATIVE_INCOME_REPORT_TYPE,
        filters: getIncomeStatementReportMetadataFiltersPayload(selections),
    };
};

export const getAvatarInitials = (name: string) => {
    const splitName = name.split(' ');
    // filter out and split strings with no length; edge case when two spaces exist between names
    return splitName
        .filter((n) => n.length)
        .map((n) => n[0].toUpperCase())
        .join('');
};

const badgeStyle = {
    backgroundColor: theme.colors.white,
    top: '3px',
    borderRadius: '50%',
};

export const getReviewerApprovalIcon = (status: string | undefined) => {
    if (status === ReportWorkflowReviewStatus.Approved) {
        return (
            <CheckCircleOutlined
                style={{
                    color: theme.colors.green,
                    ...badgeStyle,
                }}
            />
        );
    }

    if (status === ReportWorkflowReviewStatus.Rejected) {
        return (
            <CloseCircleOutlined
                style={{
                    color: theme.colors.red,
                    ...badgeStyle,
                }}
            />
        );
    }

    return <></>;
};

export const reportTitle = (rawFilters: string) => {
    const metadataFilters = JSON.parse(rawFilters)[0];

    return `${metadataFilters.periodicity.toUpperCase()} ${moment(
        metadataFilters.end_date,
    ).format('MMMM YYYY')} Variance Report Status`;
};

export const buildStatusNotificationTitle = (
    newStatus: string,
    oldStatus: string,
    rawFilters: string,
    propertyDisplayName: string,
) => {
    const metadataFilters = JSON.parse(rawFilters)[0];

    const reportDetailsText = `The ${metadataFilters.periodicity.toUpperCase()} ${moment(
        metadataFilters.end_date,
    ).format('MMMM YYYY')} Variance Report`;
    if (newStatus === READY_FOR_REVIEW) {
        return `${reportDetailsText} for ${propertyDisplayName} is Ready for your Review!`;
    }
    if (oldStatus === READY_FOR_REVIEW) {
        return `${reportDetailsText} Status for ${propertyDisplayName} has changed.`;
    }
    return '';
};

export const buildStringToComparisonTypeString = (arr: string[]): string => {
    return arr.join('_to_');
};
