import React, { useEffect, useState } from 'react';
import { RangePicker } from 'waypoint-react';
import ReportWidgetSettingsModal from 'components/reports/report-widget/ReportWidgetSettingsModal';
import {
    RangeValue,
    ReportWidgetSettingsBuilderProps,
    SavedConfigFilterType,
} from 'waypoint-types';
import { useGridConfigSettings } from 'waypoint-hooks';
import { SavedGridConfigSelect } from 'components/saved-configurations';
import moment, { Moment } from 'moment';
import {
    convertNumbersToRangeValues,
    generateDefaultYears,
} from 'components/planning/capital-projects/PlanningCapitalProjectUtils';
import { RangeValueType } from 'rc-picker/lib/PickerInput/RangePicker';

const CapitalPlanSettingsBuilder = ({
    isModalOpen,
    setIsModalOpen,
    onSaveWidgetSettings,
    widgetType,
    currentSettings,
}: ReportWidgetSettingsBuilderProps): JSX.Element => {
    const [yearsSelected, setYearsSelected] = useState<number[]>(
        currentSettings?.yearsSelected ?? [],
    );

    const {
        selectedConfiguration,
        setSelectedConfiguration,
        savedConfigurationsData,
    } = useGridConfigSettings(SavedConfigFilterType.CapitalPlan);

    useEffect(() => {
        if (currentSettings?.savedConfigId) {
            setSelectedConfiguration(
                savedConfigurationsData?.find(
                    (config) => config.id === currentSettings.savedConfigId,
                ) ?? null,
            );
        }
    }, [currentSettings, savedConfigurationsData]);

    useEffect(() => {
        if (selectedConfiguration?.filters_json?.local_config) {
            const selectedLocalConfig =
                selectedConfiguration?.filters_json?.local_config;

            selectedLocalConfig?.filteredYears
                ? setYearsSelected(selectedLocalConfig?.filteredYears)
                : setYearsSelected([]);
            return;
        }
        setYearsSelected(
            currentSettings?.yearsSelected ?? generateDefaultYears(),
        );
    }, [selectedConfiguration]);

    const defaultYears: number[] = [];

    const generateYearsArray = (
        startYear: number,
        endYear: number,
    ): number[] => {
        const years: number[] = [];

        for (let year = startYear; year <= endYear; year++) {
            years.push(year);
        }

        return years;
    };

    const createYearsArray = (values: RangeValue<Moment>) => {
        if (!values) {
            setYearsSelected([]);
            return;
        }

        const startYear = values[0]?.year() ?? new Date().getFullYear();
        const endYear = values[1]?.year() ?? new Date().getFullYear();

        const arrayOfYears = generateYearsArray(startYear, endYear);

        setYearsSelected(arrayOfYears);
    };

    const filteredYears = yearsSelected.length ? yearsSelected : defaultYears;

    if (!savedConfigurationsData) {
        return <></>;
    }

    const buildReportWidgetSettingsInputs = () => {
        return {
            yearsSelected,
            savedConfigId: selectedConfiguration?.id ?? '',
        };
    };

    return (
        <ReportWidgetSettingsModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            settingsJsonBuilder={buildReportWidgetSettingsInputs}
            onSaveWidgetSettings={onSaveWidgetSettings}
            widgetType={widgetType}
        >
            <div style={{ marginBottom: '25px' }}>
                <div
                    style={{
                        fontSize: '12px',
                        fontWeight: 'bold',
                    }}
                >
                    Saved Grid Configuration
                </div>
                <SavedGridConfigSelect
                    key={selectedConfiguration?.id ?? null}
                    savedConfigurationsData={savedConfigurationsData}
                    selectedConfiguration={selectedConfiguration}
                    setSelectedConfiguration={setSelectedConfiguration}
                    excludeUserConfigs
                />
                <div
                    style={{
                        fontSize: '12px',
                        fontWeight: 'bold',
                        marginTop: '15px',
                    }}
                >
                    Select Year Range
                </div>
                <RangePicker
                    defaultValue={
                        yearsSelected.length
                            ? ([
                                  moment(`1/1/${filteredYears[0]}`),
                                  moment(
                                      `12/31/${
                                          filteredYears[
                                              filteredYears.length - 1
                                          ]
                                      }`,
                                  ),
                              ] as RangeValueType<Moment>)
                            : undefined
                    }
                    value={convertNumbersToRangeValues(yearsSelected)}
                    allowClear={true}
                    picker="year"
                    onChange={(values) => createYearsArray(values)}
                />
            </div>
        </ReportWidgetSettingsModal>
    );
};

export default CapitalPlanSettingsBuilder;
