import React from 'react';
import BalanceSheetCard from 'components/financials/balanceSheet/components/card/BalanceSheetCard';
import { BalanceSheetProvider } from 'components/financials/balanceSheet/components/BalanceSheetProvider';
import { CommentsPaneWrapper } from 'waypoint-react/comment/CommentsPaneWrapper';
import { SelectedDataLevel } from 'waypoint-types';

interface BalanceSheetContainerProps {
    entityCodes: string[];
    selectedDataLevel: SelectedDataLevel;
    isPropertyProfile: boolean;
}

const BalanceSheetContainer = ({
    entityCodes,
    selectedDataLevel,
    isPropertyProfile,
}: BalanceSheetContainerProps) => {
    return (
        <BalanceSheetProvider
            entityCodes={entityCodes}
            selectedDataLevel={selectedDataLevel}
        >
            <CommentsPaneWrapper
                splitterTopMargin="20px"
                commentsPaneWrapperStyle={{ marginTop: '20px' }}
                splitterZIndex={isPropertyProfile ? 2 : undefined}
            >
                <BalanceSheetCard isPropertyProfile={isPropertyProfile} />
            </CommentsPaneWrapper>
        </BalanceSheetProvider>
    );
};

export default BalanceSheetContainer;
