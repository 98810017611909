import { CommentsContextType } from './CommentsContext';
import { createContext, useContext } from 'react';

export interface ManagerProviderData {
    commentContexts: Map<string, CommentsContextType>;
    registerCommentThread: (
        context: CommentsContextType,
        componentKey: string
    ) => void;
    closeOtherPanes: (commentThreadId: string) => void;
}

export const ManagerProviderContext = createContext<ManagerProviderData>(
    {} as ManagerProviderData
);

export const useCommentsContextManager = () => {
    return useContext(ManagerProviderContext);
};
