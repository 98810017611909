import { API_URL } from 'config/constants';

interface DeleteValuationReturnValue {
    message: string;
}

const deleteValuation = async (
    entityCode: string,
    valuationId: string
): Promise<DeleteValuationReturnValue> => {
    const response = await fetch(
        `${API_URL}/valuations/${valuationId}/${entityCode}`,
        {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
        }
    );

    if (!response.ok) {
        throw new Error('Failed to delete valuation');
    }

    const { data } = await response.json();

    return data as DeleteValuationReturnValue;
};

export default deleteValuation;
