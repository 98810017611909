// @ts-nocheck
// This file is a result of nx update

import { comments as TYPES } from 'state/actionTypes';

export function load(data) {
    return {
        type: TYPES.LOAD,
        payload: data,
    };
}

export function remove(data) {
    return {
        type: TYPES.DELETE,
        payload: data,
    };
}
