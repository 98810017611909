import React, { useMemo } from 'react';
import { css } from 'emotion';
import { Table } from 'antd';
import theme from 'config/theme';
import { TABLE_OFFSET } from 'components/analytics/portfolioSegmentation/constants';
import { getPerformanceOverviewColumns } from 'components/analytics/portfolioSegmentation/utils';

const tableStyle = css`
    .ant-table-thead > tr > th {
        background-color: ${theme.colors.grays.background} !important;
        font-weight: ${theme.fontWeights.bold} !important;
    }
`;

const PerformanceTable = ({ group_by, data, metric }) => {
    const TABLE_HEIGHT = useMemo(
        () => window.innerHeight - TABLE_OFFSET,
        [window.innerHeight]
    );
    return (
        <Table
            bordered
            defaultExpandAllRows
            scroll={{ y: TABLE_HEIGHT }}
            className={tableStyle}
            columns={getPerformanceOverviewColumns(group_by, metric)}
            dataSource={data}
            pagination={{
                defaultPageSize: data.length,
                hideOnSinglePage: true,
            }}
        />
    );
};

export default PerformanceTable;
