import { DownOutlined } from '@ant-design/icons';
import { css } from 'emotion';
import { Badge, Button, Checkbox, Dropdown, MenuProps } from 'antd';
import { useIncomeStatement } from 'components/financials/comparative-income-statement/IncomeStatementProvider';
import { ThresholdInformationTooltip } from 'components/financials/comparative-income-statement/table/ComparativeIncomeStatementTableUtils';
import { useState } from 'react';
import theme from 'config/theme';
import { AccountGraphCalculations } from 'waypoint-types';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

const activeSettingsButton = css`
    border-color: #424242 !important;
    background: #424242 !important;
    color: ${theme.colors.white} !important;
    text-shadow: none !important;
`;

interface QuickSettingsProps {
    thresholdCalculations: AccountGraphCalculations | null;
    isSingleEntity: boolean;
}

export const QuickSettings = ({
    thresholdCalculations,
    isSingleEntity,
}: QuickSettingsProps) => {
    const {
        hideNull,
        setHideNull,
        filterOverThreshold,
        setFilterOverThreshold,
        hideNotes,
        setHideNotes,
        showPerSF,
        setShowPerSF,
        showPerUnit,
        setShowPerUnit,
        setShowCurrentPeriod,
        showCurrentPeriod,
        showThresholdButton,
        showAllNotes,
        setShowAllNotes,
        hasComments,
        setHasComments,
        settingsActiveCount,
        setSettingsActiveCount,
        onClearFilters,
    } = useIncomeStatement();

    const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false);

    const handleDropdownOpen = (openedDropdown: boolean) => {
        setDropdownIsOpen(openedDropdown);
    };

    const createQuickSettingsCheckbox = (
        key: string,
        label: string,
        onChange: (value: boolean, e: CheckboxChangeEvent) => void,
        checked: boolean,
    ) => (
        <Checkbox
            key={key}
            onChange={(e) => {
                e.stopPropagation();
                onChange(e.target.checked, e);
            }}
            checked={checked}
        >
            {label}
        </Checkbox>
    );

    const items: MenuProps['items'] = [
        {
            key: '1',
            type: 'group',
            label: 'Data Settings',
            children: [
                {
                    key: '1',
                    label: createQuickSettingsCheckbox(
                        '1',
                        'Hide Zeros/Nulls',
                        (value) => {
                            setHideNull(value);
                            setSettingsActiveCount(
                                value
                                    ? settingsActiveCount + 1
                                    : settingsActiveCount - 1,
                            );
                        },
                        hideNull,
                    ),
                },
            ],
        },
        {
            key: '2',
            type: 'group',
            label: 'Column Settings',
            children: [
                {
                    key: '2-1',
                    label: createQuickSettingsCheckbox(
                        '2',
                        'Current Period',
                        (value) => {
                            setShowCurrentPeriod(value);
                            setSettingsActiveCount(
                                value
                                    ? settingsActiveCount + 1
                                    : settingsActiveCount - 1,
                            );
                        },
                        showCurrentPeriod,
                    ),
                },
                {
                    key: '2-2',
                    label: createQuickSettingsCheckbox(
                        '3',
                        'Show $/Unit',
                        (value) => {
                            setShowPerUnit(value);
                            setSettingsActiveCount(
                                value
                                    ? settingsActiveCount + 1
                                    : settingsActiveCount - 1,
                            );
                        },
                        showPerUnit,
                    ),
                },
                {
                    key: '2-3',
                    label: createQuickSettingsCheckbox(
                        '4',
                        'Show $/SF',
                        (value) => {
                            setShowPerSF(value);
                            setSettingsActiveCount(
                                value
                                    ? settingsActiveCount + 1
                                    : settingsActiveCount - 1,
                            );
                        },
                        showPerSF,
                    ),
                },
                isSingleEntity && {
                    key: '2-4',
                    label: createQuickSettingsCheckbox(
                        '5',
                        'Hide Notes',
                        (value) => {
                            setHideNotes(value);
                            setSettingsActiveCount(
                                value
                                    ? settingsActiveCount + 1
                                    : settingsActiveCount - 1,
                            );
                        },
                        hideNotes,
                    ),
                },
            ],
        },
        isSingleEntity && {
            key: '3',
            type: 'group',
            label: 'Row Settings',
            children: [
                isSingleEntity && {
                    key: '3-1',
                    label: createQuickSettingsCheckbox(
                        '5',
                        'Has Notes',
                        (value, e) => {
                            setShowAllNotes(value);
                            setSettingsActiveCount(
                                value
                                    ? settingsActiveCount + 1
                                    : settingsActiveCount - 1,
                            );
                            e.stopPropagation();
                        },
                        showAllNotes,
                    ),
                },
                isSingleEntity && {
                    key: '3-2',
                    label: createQuickSettingsCheckbox(
                        '6',
                        'Has Comments',
                        (value) => {
                            setHasComments(value);
                            setSettingsActiveCount(
                                value
                                    ? settingsActiveCount + 1
                                    : settingsActiveCount - 1,
                            );
                        },
                        hasComments,
                    ),
                },
                showThresholdButton && {
                    key: '3-3',
                    label: (
                        <>
                            {thresholdCalculations ? (
                                <ThresholdInformationTooltip
                                    amount={
                                        thresholdCalculations?.threshold_amount
                                    }
                                    percent={
                                        thresholdCalculations?.threshold_percent
                                    }
                                    type={thresholdCalculations?.threshold_type}
                                    periodicity={
                                        thresholdCalculations?.threshold_periodicity
                                    }
                                >
                                    {createQuickSettingsCheckbox(
                                        '7',
                                        'Over Threshold',
                                        (value) => {
                                            setFilterOverThreshold(value);
                                            setSettingsActiveCount(
                                                value
                                                    ? settingsActiveCount + 1
                                                    : settingsActiveCount - 1,
                                            );
                                        },
                                        filterOverThreshold,
                                    )}
                                </ThresholdInformationTooltip>
                            ) : (
                                createQuickSettingsCheckbox(
                                    '7',
                                    'Over Threshold',
                                    (value) => {
                                        setFilterOverThreshold(value);
                                        setSettingsActiveCount(
                                            value
                                                ? settingsActiveCount + 1
                                                : settingsActiveCount - 1,
                                        );
                                    },
                                    filterOverThreshold,
                                )
                            )}
                        </>
                    ),
                },
            ],
        },
    ].filter(Boolean) as MenuProps['items'];

    return (
        <>
            <Dropdown
                onOpenChange={handleDropdownOpen}
                open={dropdownIsOpen}
                menu={{ items }}
                trigger={['click']}
                placement="bottomLeft"
                arrow
            >
                <Button
                    data-testid="quick-settings-button"
                    type="primary"
                    style={{
                        backgroundColor: '#424242',
                        border: '1px #424242 solid',
                    }}
                    className={activeSettingsButton}
                >
                    Quick Settings <DownOutlined style={{ fontSize: '10px' }} />
                    {settingsActiveCount > 0 ? (
                        <Badge
                            style={{
                                backgroundColor: theme.colors.blues.primary,
                                marginLeft: '10px',
                            }}
                            size="small"
                            count={settingsActiveCount}
                        />
                    ) : null}
                </Button>
            </Dropdown>

            {settingsActiveCount > 0 && (
                <Button
                    data-testid="quick-settings-button"
                    type="text"
                    style={{
                        borderLeft: '2px solid lightgray',
                        borderRadius: '0px',
                        marginLeft: '6px',
                    }}
                    onClick={() => onClearFilters()}
                >
                    Clear Settings
                </Button>
            )}
        </>
    );
};
