import { API_URL } from 'config/constants';
import { CapitalProjectClientDefinedType } from 'waypoint-types';

const getClientDefinedTypes = async (
    type: string
): Promise<CapitalProjectClientDefinedType[]> => {
    const response = await fetch(
        `${API_URL}/capital-project/client-defined-type/${type}`,
        {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        }
    );

    if (!response.ok) {
        throw new Error('FAILED');
    }

    const { data } = await response.json();
    return data as CapitalProjectClientDefinedType[];
};

export default getClientDefinedTypes;
