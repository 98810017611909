import { AttributeDefinition } from './attribute-types';
import { AppFeaturePermissions, FEATURE_UNIT_MIX } from './permission-types';
import { safeDivision } from './util-types';

export type KPIResponseItemWithDates = [Array<number | null>, string, string];
export type KPIResponseItemWithoutDates = [Array<number | null>];
export type KPIResponseItemAttributeValues = string[];

export type KPIResponseData =
    | Array<KPIResponseItemWithDates>
    | KPIResponseItemWithoutDates
    | KPIResponseItemAttributeValues;

export type KPIDateMode = 'mtd' | 'ytd' | 't12' | 'qtd';
const dateModes: KPIDateMode[] = ['mtd', 'ytd', 't12', 'qtd'];

export interface KPIRequest {
    kpi: StandardKPI;
    dateMode?: KPIDateMode;
    yearOverYear?: boolean;
    attributeCode?: string;
}

export interface KPIGridConfiguration {
    metrics: KPIMetric[];
    groupBy: {
        attributeCode?: string;
    };
}

export interface KPIMetric extends KPIRequest {
    metric?: string;
}

export interface KPIResponse extends KPIRequest {
    results: Array<{
        entityCode: string;
        data: KPIResponseData;
    }>;
}

export interface SummaryOccupancy {
    totalArea: number;
    occupiedArea: number;
    totalUnits: number;
    occupiedUnits: number;
    waleMonths: number;
}

export type SummaryOccupancyByEntity = { [key: string]: SummaryOccupancy };

export interface GetKPIsResponse {
    kpiData: KPIResponse[];
    occupancyByEntityCode: {
        [key: string]: SummaryOccupancy;
    };
}

export enum StandardKPI {
    // Leasing
    // FYI: Having the value of this enum be the same as the column name in the "Occupancy" table will map directly
    // to that value, meaning you don't need to add any code to the KPI service to return the data. Saves a bunch of
    // effort and code.

    // Mapped directly:
    totalUnits = 'totalUnits',
    occupiedUnits = 'occupiedUnits',
    occupiedNoNoticeUnits = 'occupiedNoNoticeUnits',
    occupiedNoticeUnits = 'occupiedNoticeUnits',
    percentOccupiedByUnit = 'percentOccupiedByUnit',
    noticeRentedUnits = 'noticeRentedUnits',
    noticeNotRentedUnits = 'noticeNotRentedUnits',
    vacantRentedUnits = 'vacantRentedUnits',
    vacantNotRentedUnits = 'vacantNotRentedUnits',
    availableToRentUnits = 'availableToRentUnits',
    percentAvailableByUnits = 'percentAvailableByUnits',
    rentedUnits = 'rentedUnits',
    vacantReadyUnits = 'vacantReadyUnits',
    vacantNotReadyUnits = 'vacantNotReadyUnits',
    leasedUnits = 'leasedUnits',
    percentLeasedByUnit = 'percentLeasedByUnit',

    totalArea = 'totalArea',
    leasedArea = 'leasedArea',
    percentLeasedByArea = 'percentLeasedByArea',
    occupiedArea = 'occupiedArea',
    vacantArea = 'vacantArea',
    percentOccupied = 'percentOccupied',
    nonRevenueUnits = 'nonRevenueUnits',

    // Calculated leasing values (not directly from Occupancy table):
    vacantUnits = 'vacantUnits',
    walt = 'walt',

    // Base financial
    revenueActual = 'revenueActual',
    revenueBudget = 'revenueBudget',
    opExActual = 'opExActual',
    opExBudget = 'opExBudget',
    netOperatingIncomeActual = 'netOperatingIncomeActual',
    netOperatingIncomeBudget = 'netOperatingIncomeBudget',
    debtService = 'debtService',
    debtBalance = 'debtBalance',
    grossValue = 'grossValue',

    // Calculated financial
    expenseRatio = 'expenseRatio',
    operatingMargin = 'operatingMargin',
    debtServiceCoverageRatio = 'debtServiceCoverageRatio',
    loanToValue = 'loanToValue',
    netAssetValue = 'netAssetValue',
    revenueBudgetVariance = 'revenueBudgetVariance',
    expensesBudgetVariance = 'expensesBudgetVariance',
    netOperatingIncomeBudgetVariance = 'netOperatingIncomeBudgetVariance',

    // Attribute + leasing
    parking = 'parking',

    // Just attribute value(s)
    attributeValues = 'attributeValues',
}

export enum KPIGroup {
    attributes = 'attributes',
    leasingAndOccupancy = 'leasingAndOccupancy',
    financialRatios = 'financialRatios',
    revenue = 'revenue',
    expenses = 'expenses',
    netOperatingIncome = 'netOperatingIncome',
    value = 'value',
    debt = 'debt',
    agedReceivables = 'agedReceivables',
    miscellaneous = 'miscellaneous',
}

export interface KPIGroupDefinition {
    title: string;
    getSubGroup?: (
        metric: KPIMetric,
        attributeDefinition?: AttributeDefinition,
    ) => string | undefined;
}

export const kpiGroupDefinitions: { [key in KPIGroup]: KPIGroupDefinition } = {
    [KPIGroup.attributes]: {
        title: 'Attributes',
        getSubGroup: (metric, attributeDefinition) =>
            attributeDefinition?.category,
    },
    [KPIGroup.leasingAndOccupancy]: {
        title: 'Leasing & Occupancy',
        getSubGroup: ({ kpi }) =>
            kpi.toLowerCase().includes('unit') ? 'Units' : 'SF',
    },
    [KPIGroup.financialRatios]: {
        title: 'Financial Ratios',
        getSubGroup: (metric) => metric.dateMode?.toUpperCase(),
    },
    [KPIGroup.revenue]: {
        title: 'Revenue',
        getSubGroup: (metric) => metric.dateMode?.toUpperCase(),
    },
    [KPIGroup.expenses]: {
        title: 'Expenses',
        getSubGroup: (metric) => metric.dateMode?.toUpperCase(),
    },
    [KPIGroup.netOperatingIncome]: {
        title: 'Net Operating Income',
        getSubGroup: (metric) => metric.dateMode?.toUpperCase(),
    },
    [KPIGroup.value]: {
        title: 'Value',
        getSubGroup: (metric) => metric.dateMode?.toUpperCase(),
    },
    [KPIGroup.debt]: {
        title: 'Debt',
        getSubGroup: (metric) => metric.dateMode?.toUpperCase(),
    },
    [KPIGroup.agedReceivables]: {
        title: 'Aged Receivables',
    },
    [KPIGroup.miscellaneous]: {
        title: 'Miscellaneous',
    },
};

export type KPIProcessorFunc = (
    leftSideValue: number,
    rightSideValue: number,
    occupancy: SummaryOccupancy,
) => number[];

const getRatioProcessor = (decimalPlaces?: number): KPIProcessorFunc => {
    return (dividend: number, divisor: number) => [
        safeDivision(dividend, divisor, decimalPlaces),
        dividend,
        divisor,
    ];
};

const differenceProcessorWithAreaAndUnitMetrics: KPIProcessorFunc = (
    minuend: number,
    subtrahend: number,
    occupancy,
) => [
    minuend - subtrahend,
    safeDivision(minuend - subtrahend, occupancy.totalArea),
    safeDivision(minuend - subtrahend, occupancy.totalUnits),
];

export const yearOverYearProcessor: KPIProcessorFunc = (
    currentYear,
    priorYear,
) => [safeDivision(currentYear - priorYear, priorYear), currentYear, priorYear];

interface KPIBasicConfiguration {
    label: string;
    getMetricLabel?: (metric: string) => string;
    group: KPIGroup;
    getAvailableMetrics: (kpi: StandardKPI) => KPIMetric[];
    requiredFeaturePermission?: AppFeaturePermissions;
    requiredFeatureFlag?: string;
    dependencies?: [StandardKPI, StandardKPI];
    processor?: KPIProcessorFunc;
}

const getMetricsJustKPI = (kpi: StandardKPI) => [{ kpi }];

const getMetricsBaseFinancialMetrics = (kpi: StandardKPI) => {
    return [
        {
            kpi,
            metric: 'gross',
        },
        {
            kpi,
            metric: 'sqft',
        },
        {
            kpi,
            metric: 'unit',
        },
    ];
};

const getMetricsBaseFinancialsAndYearOverYear = (kpi: StandardKPI) => {
    return [
        ...getMetricsBaseFinancialMetricsAndDateModes(kpi),
        ...getMetricsDateModesYearOverYear(kpi),
    ];
};

const getMetricsBaseFinancialMetricsAndDateModes = (kpi: StandardKPI) => {
    return dateModes.flatMap((dateMode) => {
        return [
            {
                kpi,
                dateMode: dateMode as KPIDateMode,
                metric: 'gross',
            },
            {
                kpi,
                dateMode: dateMode as KPIDateMode,
                metric: 'sqft',
            },
            {
                kpi,
                dateMode: dateMode as KPIDateMode,
                metric: 'unit',
            },
        ];
    });
};

const getMetricsDateModesYearOverYear = (kpi: StandardKPI) => {
    return getMetricsDateModes(kpi).map((metric) => ({
        ...metric,
        yearOverYear: true,
    }));
};

const getMetricsDateModes = (kpi: StandardKPI) => {
    return dateModes.map((dateMode) => {
        return {
            kpi,
            dateMode: dateMode as KPIDateMode,
            metric: 'gross',
        };
    });
};

const getMetricsSqFtAndUnit = (kpi: StandardKPI) => {
    return [
        {
            kpi,
            metric: 'sqft',
        },
        {
            kpi,
            metric: 'unit',
        },
    ];
};

const getMetricsVariance = (kpi: StandardKPI) => {
    return dateModes.flatMap((dateMode) => {
        return [
            {
                kpi,
                metric: 'difference',
                dateMode,
            },
            {
                kpi,
                metric: 'percent',
                dateMode,
            },
        ];
    });
};

const getMetricsJustDateModes = (kpi: StandardKPI) => {
    return dateModes.flatMap((dateMode) => {
        return [
            {
                kpi,
                dateMode: dateMode as KPIDateMode,
            },
        ];
    });
};

export const KPIBasicConfigurationMappings: {
    [key in StandardKPI]: KPIBasicConfiguration;
} = {
    [StandardKPI.attributeValues]: {
        label: 'Attribute',
        group: KPIGroup.attributes,
        getAvailableMetrics: getMetricsJustKPI,
    },

    [StandardKPI.totalUnits]: {
        label: 'Total Units',
        group: KPIGroup.leasingAndOccupancy,
        getAvailableMetrics: getMetricsJustKPI,
    },
    [StandardKPI.vacantUnits]: {
        label: 'Vacant Units',
        group: KPIGroup.leasingAndOccupancy,
        getAvailableMetrics: getMetricsJustKPI,
        dependencies: [StandardKPI.totalUnits, StandardKPI.occupiedUnits],
        processor: (total, occupied) => [total - occupied, total, occupied],
    },
    [StandardKPI.occupiedUnits]: {
        label: 'Occupied Units',
        group: KPIGroup.leasingAndOccupancy,
        getAvailableMetrics: getMetricsJustKPI,
    },
    [StandardKPI.occupiedNoNoticeUnits]: {
        label: 'Occupied No Notice',
        group: KPIGroup.leasingAndOccupancy,
        getAvailableMetrics: getMetricsJustKPI,
        requiredFeaturePermission: AppFeaturePermissions.UnitMix,
        requiredFeatureFlag: FEATURE_UNIT_MIX,
    },
    [StandardKPI.occupiedNoticeUnits]: {
        label: 'Occupied Notice',
        group: KPIGroup.leasingAndOccupancy,
        getAvailableMetrics: getMetricsJustKPI,
        requiredFeaturePermission: AppFeaturePermissions.UnitMix,
        requiredFeatureFlag: FEATURE_UNIT_MIX,
    },
    [StandardKPI.percentOccupiedByUnit]: {
        label: 'Occupancy % (Unit)',
        group: KPIGroup.leasingAndOccupancy,
        getAvailableMetrics: getMetricsJustKPI,
        dependencies: [StandardKPI.occupiedUnits, StandardKPI.totalUnits],
        processor: getRatioProcessor(),
    },
    [StandardKPI.nonRevenueUnits]: {
        label: 'Admin/Down',
        group: KPIGroup.leasingAndOccupancy,
        getAvailableMetrics: getMetricsJustKPI,
        requiredFeaturePermission: AppFeaturePermissions.UnitMix,
        requiredFeatureFlag: FEATURE_UNIT_MIX,
    },
    [StandardKPI.noticeRentedUnits]: {
        label: 'Notice Rented',
        group: KPIGroup.leasingAndOccupancy,
        getAvailableMetrics: getMetricsJustKPI,
        requiredFeaturePermission: AppFeaturePermissions.UnitMix,
        requiredFeatureFlag: FEATURE_UNIT_MIX,
    },
    [StandardKPI.noticeNotRentedUnits]: {
        label: 'Notice Unrented',
        group: KPIGroup.leasingAndOccupancy,
        getAvailableMetrics: getMetricsJustKPI,
        requiredFeaturePermission: AppFeaturePermissions.UnitMix,
        requiredFeatureFlag: FEATURE_UNIT_MIX,
    },
    [StandardKPI.vacantRentedUnits]: {
        label: 'Vacant Rented',
        group: KPIGroup.leasingAndOccupancy,
        getAvailableMetrics: getMetricsJustKPI,
        requiredFeaturePermission: AppFeaturePermissions.UnitMix,
        requiredFeatureFlag: FEATURE_UNIT_MIX,
    },
    [StandardKPI.vacantNotRentedUnits]: {
        label: 'Vacant Unrented',
        group: KPIGroup.leasingAndOccupancy,
        getAvailableMetrics: getMetricsJustKPI,
        requiredFeaturePermission: AppFeaturePermissions.UnitMix,
        requiredFeatureFlag: FEATURE_UNIT_MIX,
    },
    [StandardKPI.availableToRentUnits]: {
        label: 'ATR',
        group: KPIGroup.leasingAndOccupancy,
        getAvailableMetrics: getMetricsJustKPI,
        requiredFeaturePermission: AppFeaturePermissions.UnitMix,
        requiredFeatureFlag: FEATURE_UNIT_MIX,
    },
    [StandardKPI.percentAvailableByUnits]: {
        label: 'ATR %',
        group: KPIGroup.leasingAndOccupancy,
        getAvailableMetrics: getMetricsJustKPI,
        requiredFeaturePermission: AppFeaturePermissions.UnitMix,
        requiredFeatureFlag: FEATURE_UNIT_MIX,
        dependencies: [
            StandardKPI.availableToRentUnits,
            StandardKPI.totalUnits,
        ],
        processor: getRatioProcessor(),
    },
    [StandardKPI.rentedUnits]: {
        label: 'Future Rented',
        group: KPIGroup.leasingAndOccupancy,
        getAvailableMetrics: getMetricsJustKPI,
        requiredFeaturePermission: AppFeaturePermissions.UnitMix,
        requiredFeatureFlag: FEATURE_UNIT_MIX,
    },
    [StandardKPI.vacantReadyUnits]: {
        label: 'Ready',
        group: KPIGroup.leasingAndOccupancy,
        getAvailableMetrics: getMetricsJustKPI,
        requiredFeaturePermission: AppFeaturePermissions.UnitMix,
        requiredFeatureFlag: FEATURE_UNIT_MIX,
    },
    [StandardKPI.vacantNotReadyUnits]: {
        label: 'Not Ready',
        group: KPIGroup.leasingAndOccupancy,
        getAvailableMetrics: getMetricsJustKPI,
        requiredFeaturePermission: AppFeaturePermissions.UnitMix,
        requiredFeatureFlag: FEATURE_UNIT_MIX,
    },
    [StandardKPI.leasedUnits]: {
        label: 'Leased (Unit)',
        group: KPIGroup.leasingAndOccupancy,
        getAvailableMetrics: getMetricsJustKPI,
        requiredFeaturePermission: AppFeaturePermissions.UnitMix,
        requiredFeatureFlag: FEATURE_UNIT_MIX,
    },
    [StandardKPI.percentLeasedByUnit]: {
        label: 'Leased % (Unit)',
        group: KPIGroup.leasingAndOccupancy,
        getAvailableMetrics: getMetricsJustKPI,
        requiredFeaturePermission: AppFeaturePermissions.UnitMix,
        requiredFeatureFlag: FEATURE_UNIT_MIX,
        dependencies: [StandardKPI.leasedUnits, StandardKPI.totalUnits],
        processor: getRatioProcessor(),
    },
    [StandardKPI.totalArea]: {
        label: 'Total SF',
        group: KPIGroup.leasingAndOccupancy,
        getAvailableMetrics: getMetricsJustKPI,
    },
    [StandardKPI.occupiedArea]: {
        label: 'Occupied SF',
        group: KPIGroup.leasingAndOccupancy,
        getAvailableMetrics: getMetricsJustKPI,
    },
    [StandardKPI.vacantArea]: {
        label: 'Vacant SF',
        group: KPIGroup.leasingAndOccupancy,
        getAvailableMetrics: getMetricsJustKPI,
    },
    [StandardKPI.leasedArea]: {
        label: 'Leased (SF)',
        group: KPIGroup.leasingAndOccupancy,
        getAvailableMetrics: getMetricsJustKPI,
        requiredFeaturePermission: AppFeaturePermissions.UnitMix,
        requiredFeatureFlag: FEATURE_UNIT_MIX,
    },
    [StandardKPI.percentLeasedByArea]: {
        label: 'Leased % (SF)',
        group: KPIGroup.leasingAndOccupancy,
        getAvailableMetrics: getMetricsJustKPI,
        requiredFeaturePermission: AppFeaturePermissions.UnitMix,
        requiredFeatureFlag: FEATURE_UNIT_MIX,
        dependencies: [StandardKPI.leasedArea, StandardKPI.totalArea],
        processor: getRatioProcessor(),
    },
    [StandardKPI.percentOccupied]: {
        label: 'Occupancy % (SF)',
        group: KPIGroup.leasingAndOccupancy,
        getAvailableMetrics: getMetricsJustKPI,
        dependencies: [StandardKPI.occupiedArea, StandardKPI.totalArea],
        processor: getRatioProcessor(3),
    },
    [StandardKPI.walt]: {
        label: 'WALT',
        group: KPIGroup.leasingAndOccupancy,
        getAvailableMetrics: getMetricsJustKPI,
        dependencies: [StandardKPI.occupiedArea, StandardKPI.occupiedArea],
        processor: (leftSideValue, rightSideValue, occupancy) => {
            return [occupancy.waleMonths / 12];
        },
    },
    [StandardKPI.revenueActual]: {
        label: 'Revenue - Actual',
        group: KPIGroup.revenue,
        getAvailableMetrics: getMetricsBaseFinancialsAndYearOverYear,
    },
    [StandardKPI.revenueBudget]: {
        label: 'Revenue - Budget',
        group: KPIGroup.revenue,
        getAvailableMetrics: getMetricsBaseFinancialMetricsAndDateModes,
    },
    [StandardKPI.revenueBudgetVariance]: {
        label: 'Revenue Budget Variance',
        group: KPIGroup.revenue,
        getAvailableMetrics: getMetricsVariance,
        getMetricLabel: (metric) => (metric === 'difference' ? '$' : '%'),
        dependencies: [StandardKPI.revenueActual, StandardKPI.revenueBudget],
        processor: (revActual, revBudget) => [
            revActual - revBudget,
            safeDivision(revActual - revBudget, revBudget),
            revActual,
            revBudget,
        ],
    },
    [StandardKPI.opExActual]: {
        label: 'OpEx - Actual',
        group: KPIGroup.expenses,
        getAvailableMetrics: getMetricsBaseFinancialsAndYearOverYear,
    },
    [StandardKPI.opExBudget]: {
        label: 'OpEx - Budget',
        group: KPIGroup.expenses,
        getAvailableMetrics: getMetricsBaseFinancialMetricsAndDateModes,
    },
    [StandardKPI.expensesBudgetVariance]: {
        label: 'Expenses Budget Variance',
        group: KPIGroup.expenses,
        getAvailableMetrics: getMetricsVariance,
        getMetricLabel: (metric) => (metric === 'difference' ? '$' : '%'),
        dependencies: [StandardKPI.opExBudget, StandardKPI.opExActual],
        processor: (opExBudget, opExActual) => [
            opExBudget - opExActual,
            safeDivision(opExBudget - opExActual, opExBudget),
            opExBudget,
            opExActual,
        ],
    },
    [StandardKPI.netOperatingIncomeActual]: {
        label: 'NOI - Actual',
        group: KPIGroup.netOperatingIncome,
        getAvailableMetrics: getMetricsBaseFinancialsAndYearOverYear,
    },
    [StandardKPI.netOperatingIncomeBudget]: {
        label: 'NOI - Budget',
        group: KPIGroup.netOperatingIncome,
        getAvailableMetrics: getMetricsBaseFinancialMetricsAndDateModes,
    },
    [StandardKPI.netOperatingIncomeBudgetVariance]: {
        label: 'NOI Budget Variance',
        group: KPIGroup.netOperatingIncome,
        getAvailableMetrics: getMetricsVariance,
        getMetricLabel: (metric) => (metric === 'difference' ? '$' : '%'),
        dependencies: [
            StandardKPI.netOperatingIncomeActual,
            StandardKPI.netOperatingIncomeBudget,
        ],
        processor: (noiActual, noiBudget) => [
            noiActual - noiBudget,
            safeDivision(noiActual - noiBudget, noiBudget),
            noiActual,
            noiBudget,
        ],
    },
    [StandardKPI.debtBalance]: {
        label: 'Outstanding Debt Balance',
        group: KPIGroup.debt,
        getAvailableMetrics: getMetricsBaseFinancialMetrics,
    },
    [StandardKPI.debtService]: {
        label: 'Debt Service',
        group: KPIGroup.debt,
        getAvailableMetrics: getMetricsBaseFinancialMetricsAndDateModes,
    },
    [StandardKPI.debtServiceCoverageRatio]: {
        label: 'DSCR',
        group: KPIGroup.debt,
        getAvailableMetrics: getMetricsJustDateModes,
        dependencies: [
            StandardKPI.netOperatingIncomeActual,
            StandardKPI.debtService,
        ],
        processor: getRatioProcessor(),
    },
    [StandardKPI.loanToValue]: {
        label: 'LTV %',
        group: KPIGroup.debt,
        getAvailableMetrics: getMetricsJustKPI,
        dependencies: [StandardKPI.debtBalance, StandardKPI.grossValue],
        processor: getRatioProcessor(),
    },
    [StandardKPI.grossValue]: {
        label: 'Gross Asset Value (GAV)',
        group: KPIGroup.value,
        getAvailableMetrics: getMetricsBaseFinancialMetrics,
    },
    [StandardKPI.netAssetValue]: {
        label: 'Net Asset Value (NAV)',
        group: KPIGroup.value,
        getAvailableMetrics: getMetricsBaseFinancialMetrics,
        dependencies: [StandardKPI.grossValue, StandardKPI.debtBalance],
        processor: differenceProcessorWithAreaAndUnitMetrics,
    },
    [StandardKPI.expenseRatio]: {
        label: 'Expense Ratio',
        group: KPIGroup.financialRatios,
        getAvailableMetrics: getMetricsJustDateModes,
        dependencies: [StandardKPI.opExActual, StandardKPI.revenueActual],
        processor: getRatioProcessor(),
    },
    [StandardKPI.operatingMargin]: {
        label: 'Operating Margin',
        group: KPIGroup.financialRatios,
        getAvailableMetrics: getMetricsJustDateModes,
        dependencies: [
            StandardKPI.netOperatingIncomeActual,
            StandardKPI.revenueActual,
        ],
        processor: getRatioProcessor(),
    },
    [StandardKPI.parking]: {
        label: 'Parking',
        group: KPIGroup.miscellaneous,
        getAvailableMetrics: getMetricsSqFtAndUnit,
    },
};

export enum KPIFormats {
    fixedPoint = 'fixedPoint',
    decimal = 'decimal',
    percent = 'percent',
    currency = 'currency',
}

export interface KPISummaryOptions {
    metric: KPIMetric;
    totalValue?: number | number[];
    summaryProcess?: 'start' | 'calculate' | 'finalize';
    value?: KPIResponseData;
    occupancy: SummaryOccupancy;
}

interface KPIColumnMappingFormat {
    type: KPIFormats;
    precision: number;
}

export type KPIColumnMapping = {
    title: string;
    format?: KPIColumnMappingFormat;
    getValue: (result: KPIResponseData) => number | string | string[];
    key: string;
    // What we display in the individual cell by default if there is no value
    defaultValue?: number | string;
    // What we display in the group header when there is no value
    defaultGroupedLabel?: string;
    getSummaryMapping?: () => {
        summaryType: 'avg' | 'count' | 'custom' | 'max' | 'min' | 'sum';
        valueFormat?: KPIColumnMappingFormat;
    };
    calculateCustomSummary?: (options: KPISummaryOptions) => void;
};

export function getKPIRequestKey(
    { kpi, dateMode, yearOverYear, attributeCode }: KPIRequest,
    metric?: string,
): string {
    return [
        kpi,
        attributeCode,
        dateMode,
        yearOverYear ? 'yoy' : undefined,
        metric,
    ]
        .filter((part) => !!part)
        .join('-');
}

const formatKpiName = function (kpiName: StandardKPI) {
    return KPIBasicConfigurationMappings[kpiName].label;
};

const formatMetricName = function (
    kpiName: StandardKPI,
    metric: string | undefined,
): string {
    const metricLabelFunc =
        KPIBasicConfigurationMappings[kpiName].getMetricLabel;
    return metricLabelFunc && metric ? metricLabelFunc(metric) : metric ?? '';
};

const getUnitOrSqFtSummary = (options: KPISummaryOptions) => {
    const { summaryProcess, value, occupancy, metric } = options;
    const coercedRowData = value as KPIResponseItemWithDates[] | undefined;
    const field = metric.metric === 'sqft' ? 'totalArea' : 'totalUnits';

    if (!options.totalValue) {
        options.totalValue = [0, 0];
    }

    if (summaryProcess === 'start') {
        options.totalValue = [0, 0];
        return;
    }

    if (summaryProcess === 'calculate') {
        const dataItem = coercedRowData?.length ? coercedRowData[0][0] : [0];
        const [gross] = dataItem;

        options.totalValue = [
            options.totalValue[0] + gross,
            options.totalValue[1] + occupancy[field],
        ];
        return;
    }

    if (summaryProcess === 'finalize') {
        options.totalValue = safeDivision(
            options.totalValue[0],
            options.totalValue[1],
        );
        return;
    }
};

const baseFinancialKPIMapping: (
    request: KPIRequest,
) => Array<KPIColumnMapping> = (request) => [
    ...[
        {
            title: `${formatDateModeForTitle(request.dateMode)} ${formatKpiName(request.kpi)}${request.yearOverYear ? ` - YoY` : ''}`.trim(),
            format: {
                type: request.yearOverYear
                    ? KPIFormats.percent
                    : KPIFormats.currency,
                precision: 2,
            },
            getValue: (result: KPIResponseData) =>
                (result as KPIResponseItemWithDates[])[0][0][0] ?? 0,
            getSummaryMapping: !request.yearOverYear
                ? () => {
                      return {
                          summaryType: 'sum' as const,
                          valueFormat: {
                              type: KPIFormats.currency,
                              precision: 2,
                          },
                      };
                  }
                : () => {
                      return {
                          summaryType: 'custom' as const,
                          valueFormat: {
                              type: KPIFormats.percent,
                              precision: 2,
                          },
                      };
                  },
            calculateCustomSummary: getTotalledDependenciesSummary(),
            key: getKPIRequestKey(request, 'gross'),
        },
    ],
    ...(!request.yearOverYear
        ? [
              {
                  title: `${formatDateModeForTitle(request.dateMode)} ${formatKpiName(request.kpi)} / Sq Ft`.trim(),
                  format: {
                      type: KPIFormats.currency,
                      precision: 2,
                  },
                  getValue: (result: KPIResponseData) =>
                      (result as KPIResponseItemWithDates[])[0][0][1] ?? 0,
                  getSummaryMapping: () => {
                      return {
                          summaryType: 'custom' as const,
                          valueFormat: {
                              type: KPIFormats.currency,
                              precision: 2,
                          },
                      };
                  },
                  calculateCustomSummary: getUnitOrSqFtSummary,
                  key: getKPIRequestKey(request, 'sqft'),
              },
              {
                  title: `${formatDateModeForTitle(request.dateMode)} ${formatKpiName(request.kpi)} / Unit`.trim(),
                  format: {
                      type: KPIFormats.currency,
                      precision: 2,
                  },
                  getValue: (result: KPIResponseData) =>
                      (result as KPIResponseItemWithDates[])[0][0][2] ?? 0,
                  getSummaryMapping: () => {
                      return {
                          summaryType: 'custom' as const,
                          valueFormat: {
                              type: KPIFormats.currency,
                              precision: 2,
                          },
                      };
                  },
                  calculateCustomSummary: getUnitOrSqFtSummary,
                  key: getKPIRequestKey(request, 'unit'),
              },
          ]
        : []),
];

const getTotalledDependenciesSummary = (resultIndex = 0) => {
    return (options: KPISummaryOptions) => {
        const { summaryProcess, value, metric, occupancy } = options;
        const coercedRowData = value as KPIResponseItemWithDates[] | undefined;

        if (!options.totalValue) {
            options.totalValue = [0, 0];
        }

        if (summaryProcess === 'start') {
            options.totalValue = [0, 0];
            return;
        }

        if (
            summaryProcess === 'calculate' &&
            value &&
            value.length &&
            coercedRowData
        ) {
            const dataItem = coercedRowData[0][0];

            options.totalValue = [
                options.totalValue[0] + Number(dataItem[dataItem.length - 2]),
                options.totalValue[1] + Number(dataItem[dataItem.length - 1]),
            ];
            return;
        }

        if (summaryProcess === 'finalize') {
            const processor = metric.yearOverYear
                ? yearOverYearProcessor
                : KPIBasicConfigurationMappings[metric.kpi].processor;
            const result = processor
                ? processor(
                      options.totalValue[0],
                      options.totalValue[1],
                      occupancy,
                  )
                : [];
            const resultAtIndex = result[resultIndex];

            options.totalValue = resultAtIndex ?? 0;

            return;
        }
    };
};

const getCalculatedFinancialKPIMapping = (
    type: KPIFormats,
    precision: number,
    index = 0,
    metric?: string,
): ((request: KPIRequest) => Array<KPIColumnMapping>) => {
    return (request: KPIRequest) => [
        {
            title: `${formatDateModeForTitle(request.dateMode)} ${formatKpiName(request.kpi)}${request.yearOverYear ? ` - YoY` : ''}${metric ? ` - ${formatMetricName(request.kpi, metric)}` : ''}`.trim(),
            format: {
                type,
                precision,
            },
            getValue: (result: KPIResponseData) =>
                (result as KPIResponseItemWithDates[])[0][0][index] ?? 0,
            getSummaryMapping: () => ({
                summaryType: 'custom',
                valueFormat: { type, precision },
            }),
            calculateCustomSummary: getTotalledDependenciesSummary(index),
            key: getKPIRequestKey(request, metric),
        },
    ];
};

function formatDateModeForTitle(dateMode: KPIDateMode | undefined) {
    return `${dateMode ? dateMode.toUpperCase() + ' -' : ''}`;
}

const calculatedRatioKPIMapping = getCalculatedFinancialKPIMapping(
    KPIFormats.fixedPoint,
    2,
    0,
);
const calculatedPercentageKPIMapping = getCalculatedFinancialKPIMapping(
    KPIFormats.percent,
    2,
    0,
);

const calculatedVarianceKPIMapping = (request: KPIRequest) => [
    ...getCalculatedFinancialKPIMapping(
        KPIFormats.currency,
        2,
        0,
        'difference',
    )(request),
    ...getCalculatedFinancialKPIMapping(
        KPIFormats.percent,
        2,
        1,
        'percent',
    )(request),
];

const perSqFtAndUnitAttributeMapping = ({ kpi }: KPIRequest) => [
    {
        title: `${formatKpiName(kpi)} / 1,000 Sq Ft`,
        format: {
            type: KPIFormats.fixedPoint,
            precision: 2,
        },
        getValue: (result: KPIResponseData) =>
            (result as KPIResponseItemWithoutDates)[0][0] ?? 0,
        key: getKPIRequestKey({ kpi }, 'sqft'),
    },
    {
        title: `${formatKpiName(kpi)} / Unit`,
        format: {
            type: KPIFormats.fixedPoint,
            precision: 2,
        },
        getValue: (result: KPIResponseData) =>
            (result as KPIResponseItemWithoutDates)[0][1] ?? 0,
        key: getKPIRequestKey({ kpi }, 'unit'),
    },
];

const attributeKPIColumnMapping = (
    request: KPIRequest,
    attributeDefinition?: AttributeDefinition,
): KPIColumnMapping[] => {
    if (!attributeDefinition) {
        return [];
    }

    return [
        {
            title: attributeDefinition.name,
            getValue: (result: KPIResponseData) =>
                (result as KPIResponseItemAttributeValues).map(
                    (value) => value,
                ),
            defaultGroupedLabel: 'Unassigned',
            key: getKPIRequestKey(request),
            defaultValue: '',
        },
    ];
};

const simpleLeasingKPIColumnMapping = (request: KPIRequest) => [
    {
        title: formatKpiName(request.kpi),
        format: {
            type: KPIFormats.fixedPoint,
            precision: 0,
        },
        getValue: (result: KPIResponseData) =>
            (result as KPIResponseItemWithoutDates)[0][0] ?? 0,
        getSummaryMapping: () => ({
            summaryType: 'sum' as const,
            valueFormat: { type: KPIFormats.fixedPoint, precision: 0 },
        }),
        key: getKPIRequestKey(request),
    },
];

const percentLeasingKPIColumnMapping = (request: KPIRequest) => [
    {
        title: formatKpiName(request.kpi),
        format: {
            type: KPIFormats.percent,
            precision: 2,
        },
        getValue: (result: KPIResponseData) =>
            (result as KPIResponseItemWithoutDates)[0][0] ?? 0,
        getSummaryMapping: () => ({
            summaryType: 'custom' as const,
            valueFormat: { type: KPIFormats.percent, precision: 2 },
        }),
        calculateCustomSummary: getTotalledDependenciesSummary(0),
        key: getKPIRequestKey(request),
    },
];

export const KPIColumnMappings: {
    [key in StandardKPI]: (
        request: KPIRequest,
        attributeDefinition?: AttributeDefinition,
    ) => Array<KPIColumnMapping>;
} = {
    [StandardKPI.attributeValues]: attributeKPIColumnMapping,

    [StandardKPI.totalUnits]: simpleLeasingKPIColumnMapping,
    [StandardKPI.vacantUnits]: simpleLeasingKPIColumnMapping,
    [StandardKPI.occupiedUnits]: simpleLeasingKPIColumnMapping,
    [StandardKPI.occupiedNoNoticeUnits]: simpleLeasingKPIColumnMapping,
    [StandardKPI.occupiedNoticeUnits]: simpleLeasingKPIColumnMapping,
    [StandardKPI.nonRevenueUnits]: simpleLeasingKPIColumnMapping,
    [StandardKPI.noticeRentedUnits]: simpleLeasingKPIColumnMapping,
    [StandardKPI.noticeNotRentedUnits]: simpleLeasingKPIColumnMapping,
    [StandardKPI.vacantRentedUnits]: simpleLeasingKPIColumnMapping,
    [StandardKPI.vacantNotRentedUnits]: simpleLeasingKPIColumnMapping,
    [StandardKPI.availableToRentUnits]: simpleLeasingKPIColumnMapping,
    [StandardKPI.percentAvailableByUnits]: percentLeasingKPIColumnMapping,
    [StandardKPI.vacantReadyUnits]: simpleLeasingKPIColumnMapping,
    [StandardKPI.vacantNotReadyUnits]: simpleLeasingKPIColumnMapping,
    [StandardKPI.leasedUnits]: simpleLeasingKPIColumnMapping,
    [StandardKPI.percentLeasedByUnit]: percentLeasingKPIColumnMapping,
    [StandardKPI.rentedUnits]: simpleLeasingKPIColumnMapping,
    [StandardKPI.percentOccupiedByUnit]: percentLeasingKPIColumnMapping,

    [StandardKPI.totalArea]: simpleLeasingKPIColumnMapping,
    [StandardKPI.occupiedArea]: simpleLeasingKPIColumnMapping,
    [StandardKPI.vacantArea]: simpleLeasingKPIColumnMapping,
    [StandardKPI.percentOccupied]: percentLeasingKPIColumnMapping,
    [StandardKPI.leasedArea]: simpleLeasingKPIColumnMapping,
    [StandardKPI.percentLeasedByArea]: percentLeasingKPIColumnMapping,
    [StandardKPI.walt]: () => [
        {
            title: 'WALT (Yrs)',
            format: {
                type: KPIFormats.fixedPoint,
                precision: 2,
            },
            getValue: (result: KPIResponseData) =>
                (result as KPIResponseItemWithoutDates)[0][0] ?? 0,
            getSummaryMapping: () => ({
                summaryType: 'custom',
                valueFormat: {
                    type: KPIFormats.fixedPoint,
                    precision: 2,
                },
            }),
            calculateCustomSummary: (options) => {
                const { summaryProcess, value, occupancy } = options;
                const coercedRowData = value as
                    | KPIResponseItemWithoutDates
                    | undefined;

                if (!options.totalValue) {
                    options.totalValue = [0, 0];
                }

                if (summaryProcess === 'start') {
                    options.totalValue = [0, 0];
                    return;
                }

                if (
                    summaryProcess === 'calculate' &&
                    value &&
                    value.length &&
                    coercedRowData
                ) {
                    const walt = coercedRowData[0][0] ?? 0;

                    options.totalValue = [
                        options.totalValue[0] + walt * occupancy.occupiedArea,
                        options.totalValue[1] + occupancy.occupiedArea,
                    ];
                    return;
                }

                if (summaryProcess === 'finalize') {
                    const [waltProduct, totalOccupiedArea] =
                        options.totalValue as number[];
                    options.totalValue = safeDivision(
                        waltProduct,
                        totalOccupiedArea,
                    );

                    return;
                }
            },
            key: StandardKPI.walt,
        },
    ],
    [StandardKPI.revenueActual]: baseFinancialKPIMapping,
    [StandardKPI.revenueBudget]: baseFinancialKPIMapping,
    [StandardKPI.opExActual]: baseFinancialKPIMapping,
    [StandardKPI.opExBudget]: baseFinancialKPIMapping,
    [StandardKPI.netOperatingIncomeActual]: baseFinancialKPIMapping,
    [StandardKPI.netOperatingIncomeBudget]: baseFinancialKPIMapping,
    [StandardKPI.debtService]: baseFinancialKPIMapping,
    [StandardKPI.debtBalance]: baseFinancialKPIMapping,
    [StandardKPI.grossValue]: baseFinancialKPIMapping,
    [StandardKPI.expenseRatio]: calculatedPercentageKPIMapping,
    [StandardKPI.debtServiceCoverageRatio]: calculatedRatioKPIMapping,
    [StandardKPI.operatingMargin]: calculatedPercentageKPIMapping,
    [StandardKPI.loanToValue]: calculatedPercentageKPIMapping,
    [StandardKPI.netAssetValue]: baseFinancialKPIMapping,
    [StandardKPI.revenueBudgetVariance]: calculatedVarianceKPIMapping,
    [StandardKPI.expensesBudgetVariance]: calculatedVarianceKPIMapping,
    [StandardKPI.netOperatingIncomeBudgetVariance]:
        calculatedVarianceKPIMapping,
    [StandardKPI.parking]: perSqFtAndUnitAttributeMapping,
};

export const getAttributeDefinitionFromKPIRequest = (
    request: KPIRequest,
    attributeDefinitions: AttributeDefinition[],
): AttributeDefinition | undefined => {
    return request.attributeCode
        ? attributeDefinitions.find(
              (attr) => attr.attribute_code === request.attributeCode,
          )
        : undefined;
};

export function getAllKPIMetrics(
    attributeDefinitions: AttributeDefinition[],
    featurePermissions: string[],
    featureFlags: object,
): KPIMetric[] {
    const nonAttributeMetrics = Object.values(StandardKPI)
        .filter((kpi) => kpi !== StandardKPI.attributeValues)
        .flatMap((kpi) =>
            KPIBasicConfigurationMappings[kpi].getAvailableMetrics(kpi),
        );
    const attributeMetrics: KPIMetric[] = attributeDefinitions.map((attr) => ({
        kpi: StandardKPI.attributeValues,
        attributeCode: attr.attribute_code,
    }));

    return [...nonAttributeMetrics, ...attributeMetrics].filter((metric) => {
        const requiredFeaturePermission =
            KPIBasicConfigurationMappings[metric.kpi].requiredFeaturePermission;
        const requiredFeatureFlag =
            KPIBasicConfigurationMappings[metric.kpi].requiredFeatureFlag;

        return (
            (!requiredFeaturePermission ||
                featurePermissions.includes(requiredFeaturePermission)) &&
            (!requiredFeatureFlag || featureFlags[requiredFeatureFlag])
        );
    });
}
