import { API_URL } from 'config/constants';
import { EntityReportWorkflowStatus } from 'waypoint-types';

export interface EntityReportWorkflowStatusParams {
    reportMetadataId: string;
    workflowType: string;
    entityCode: string;
}

const getOrInsertWorkflowReportStatus = async (
    entityReportWorkflowStatusParams: EntityReportWorkflowStatusParams
): Promise<EntityReportWorkflowStatus> => {
    const response = await fetch(`${API_URL}/report-metadata/search/status`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(entityReportWorkflowStatusParams),
    });

    if (!response.ok) {
        throw new Error('Failed to get workflow report status');
    }

    const { data } = await response.json();

    return data as EntityReportWorkflowStatus;
};

export default getOrInsertWorkflowReportStatus;
