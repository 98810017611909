import { API_URL } from 'config/constants';

interface UpdateReportParams {
    reportId: string;
    entityReportWidgetId: string;
    status: string;
}

const updateEntityReportWidgetStatus = async ({
    reportId,
    entityReportWidgetId,
    status,
}: UpdateReportParams): Promise<string> => {
    const response = await fetch(
        `${API_URL}/reports/entity-reports-widget/update-status/${reportId}/${entityReportWidgetId}`,
        {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({ status }),
        }
    );

    if (!response.ok) {
        throw new Error('Failed to update entity report widget status');
    }

    const { data } = await response.json();

    return data;
};

export default updateEntityReportWidgetStatus;
