import { isNil } from 'lodash';
import { DASH_DASH } from 'config/constants';

export const numberWithCommas = (x) => {
    if (isNil(x)) {
        return DASH_DASH;
    }
    return Math.round(Number(x)).toLocaleString('en-US');
};

export const getCellStyling = (config = { color: '#555' }) => {
    const { color } = config;

    const nonNativeStyle = {
        fontWeight: 900,
        color,
    };

    return nonNativeStyle;
};
