import { DefaultOptionType } from 'antd/es/select';
import { css } from 'emotion';
import { DocumentUpload } from 'waypoint-types';

export const cardTitleStyle = css`
    > .ant-card-head {
        background: #fff;
        font-size: 25px;
    }
    > .ant-card-body {
        display: none;
    }
`;

export const documentsStyle = (isFooterVisible: boolean) => css`
    background: transparent;
    > .ant-card-body {
        background: #fff;
        margin: 20px 25px 100px 25px;
        border: 0;
        border-radius: 8px;
    }
    > .ant-card-actions {
        position: fixed;
        bottom: ${isFooterVisible ? 0 : -80}px;
        right: 0;
        width: calc(100% - 220px);
        height: 80px;
        background: #fff;
        box-shadow: rgb(216 220 222) 0px 1px 0px inset;
        display: flex;
        z-index: 4;
        transition: bottom 0.3s ease-in-out;
        justify-content: flex-end;
    }
`;

export const banner = css`
    background-color: #fff;
    padding: 12px 20px;
    margin: 20px 25px 0 25px;
    border-radius: 8px;
    display: flex;
    align-items: center;
`;

export const actionButtonsStyle = css`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 16px;
    gap: 20px;
`;

export const selectStyle = css`
    width: 200px;
`;

export const MAX_FILE_SIZE_MB = 150;
export const DOCUMENTS_SECTION_RESTRICTIONS = {
    allowedExtensions: [],
    maxFileSize: MAX_FILE_SIZE_MB * 1024 * 1024,
};

export const getUniqueOptions = (
    documentsUploaded: DocumentUpload[]
): {
    uniqueFileTypeOptions: DefaultOptionType[];
    uniqueAddedByOptions: DefaultOptionType[];
} => {
    if (!documentsUploaded) {
        return { uniqueFileTypeOptions: [], uniqueAddedByOptions: [] };
    }

    const uniqueTypeOptions = new Map<string, DefaultOptionType>();
    const uniqueAddedByOptions = new Map<string, DefaultOptionType>();

    documentsUploaded.forEach((document) => {
        const option: DefaultOptionType = {
            value: document.created_by,
            label: `${document.uploadCreatedByUser.firstname} ${document.uploadCreatedByUser.lastname}`,
        };

        const uniqueKey = `${option.value}-${option.label}`;
        if (!uniqueAddedByOptions.has(uniqueKey)) {
            uniqueAddedByOptions.set(uniqueKey, option);
        }

        const extension = document.document_url.split('.').pop()?.toUpperCase();

        if (extension) {
            const uniqueKey = `${document.document_type}-${extension}`;
            if (!uniqueTypeOptions.has(uniqueKey)) {
                uniqueTypeOptions.set(uniqueKey, {
                    value: document.document_type,
                    label: extension,
                });
            }
        }
    });

    return {
        uniqueFileTypeOptions: Array.from(uniqueTypeOptions.values()),
        uniqueAddedByOptions: Array.from(uniqueAddedByOptions.values()),
    };
};
