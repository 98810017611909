import React, { useImperativeHandle, useRef } from 'react';
import DataGrid, {
    Export,
    HeaderFilter,
    Column,
    Summary,
    Format,
    TotalItem,
    ColumnChooser,
    DataGridRef,
    ColumnChooserSelection,
    Position,
} from 'devextreme-react/data-grid';
import { EntityReportWidgetPdfSettings } from 'components/reports/ReportUtils';
import { PDFExportable } from 'waypoint-utils/pdf/PDFExportable';
import { useUnitMix } from 'waypoint-hooks';
import {
    filterRecurringCharges,
    isRecurringChargeLease,
    isUnitOccupied,
} from '../components/recurring-charge/utils';
import { ExportingEvent } from 'devextreme/ui/data_grid';
import {
    RecurringCharge,
    RentRollProps,
    SelectedDataLevel,
    TransformedUnitMix,
} from 'waypoint-types';
import { safeDivision } from 'waypoint-utils';
import exportExcelFromDevExtremeDataGrid from 'waypoint-utils/dev-extreme/exportExcelFromDevExtremeDataGrid';
import { UnitMixExportableGrid } from 'components/reports/components/entity-report-widgets/report-widget-export-grids/UnitMixExportExportableGrid';
interface UnitMixTableProps {
    hidePropertyColumns?: boolean;
    widgetId?: string;
    widgetType?: string;
    entityCode: string;
    selectedDataLevel: SelectedDataLevel;
    isPDFExport?: boolean;
    pdfSettings?: EntityReportWidgetPdfSettings;
    narrativePosition?: string;
    selectedChargeCode: string[];
    isReportWidget?: boolean;
}

const UnitMixTable = React.forwardRef<PDFExportable, UnitMixTableProps>(
    (
        {
            widgetId,
            widgetType,
            entityCode,
            selectedDataLevel,
            hidePropertyColumns,
            narrativePosition,
            selectedChargeCode,
            isReportWidget,
            isPDFExport,
        }: UnitMixTableProps,
        ref,
    ) => {
        const dataGridRef = useRef<DataGridRef<any, any>>(null);

        const {
            calculateCustomSummary,
            recurringChargesData,
            rentRoll,
            isLoading,
        } = useUnitMix({
            entityCode,
            selectedDataLevel,
        });

        const widgetExportId = `narrativePDFWrapper_${widgetType}_${widgetId}`;

        useImperativeHandle(ref, () => ({
            isReadyToExport(): boolean {
                return !isLoading;
            },
        }));

        const { recurringCharges } = recurringChargesData ?? {
            recurringCharges: [],
        };

        const filteredCharges = filterRecurringCharges(
            recurringCharges,
            selectedChargeCode,
        ) as RecurringCharge[];

        const calculateTotalMonthlyCharge = (rentRoll: RentRollProps) => {
            return filteredCharges
                .filter((rc) => isRecurringChargeLease(rc, rentRoll))
                .reduce((acc: number, curr: RecurringCharge) => {
                    return acc + curr.monthly;
                }, 0);
        };

        const rentRollWithTotalCharges = () => {
            return rentRoll
                ?.filter((rr) => rr.bedroom_count !== null)
                .map((rr) => {
                    const totalMonthly = calculateTotalMonthlyCharge(rr);
                    return {
                        ...rr,
                        total_monthly: isUnitOccupied(rr) ? totalMonthly : null,
                        total_annual: isUnitOccupied(rr)
                            ? totalMonthly * 12
                            : null,
                        total_monthly_per_sq_ft: isUnitOccupied(rr)
                            ? safeDivision(totalMonthly, rr.rentable_sq_ft)
                            : null,
                        total_annual_per_sq_ft: isUnitOccupied(rr)
                            ? safeDivision(totalMonthly * 12, rr.rentable_sq_ft)
                            : null,
                    };
                });
        };

        const transformRentRollToUnitMix = (
            data: RentRollProps[],
        ): TransformedUnitMix[] => {
            const average = (arr: number[]) =>
                safeDivision(
                    arr.reduce((a, b) => a + b, 0),
                    arr.length,
                );
            const sum = (arr: number[]) => arr.reduce((a, b) => a + b, 0);

            const rentRollWithChargesGroupedByBedroomCountAndProperty =
                data.reduce(
                    (
                        acc: Record<string, RentRollProps[]>,
                        obj: RentRollProps,
                    ) => {
                        const groupKey = `${obj.bedroom_count || 'N/A'}_${
                            obj.property_name
                        }`;
                        acc[groupKey] = acc[groupKey] || [];
                        acc[groupKey].push(obj);
                        return acc;
                    },
                    {},
                );

            const totalUnitsByProperty = data.reduce(
                (acc: Record<string, number>, obj: RentRollProps) => {
                    acc[obj.property_name] = (acc[obj.property_name] || 0) + 1;
                    return acc;
                },
                {},
            );

            const processedData = Object.keys(
                rentRollWithChargesGroupedByBedroomCountAndProperty,
            ).map((key) => {
                const units =
                    rentRollWithChargesGroupedByBedroomCountAndProperty[key];
                const bedroom = units[0].bedroom_count;
                const property_name = units[0].property_name;

                const totalMonthlyCharges = sum(
                    units.map((unit) => unit.total_monthly ?? 0),
                );
                const totalAnnualCharges = sum(
                    units.map((unit) => unit.total_annual ?? 0),
                );
                const totalMonthlyChargesPSF = sum(
                    units.map((unit) => unit.total_monthly_per_sq_ft ?? 0),
                );
                const totalAnnualChargesPSF = sum(
                    units.map((unit) => unit.total_annual_per_sq_ft ?? 0),
                );

                return {
                    bedroom_count: Number(bedroom),
                    property_name,
                    number_of_units: units.length,
                    avg_sf: average(
                        units.map((unit) => unit.rentable_sq_ft ?? 0),
                    ),
                    total_sf: sum(
                        units.map((unit) => unit.rentable_sq_ft ?? 0),
                    ),
                    percent_of_total_units: safeDivision(
                        units.length,
                        totalUnitsByProperty[property_name],
                    ),
                    average_total_monthly: average(
                        units.map((unit) => unit.total_monthly ?? 0),
                    ),
                    average_total_monthly_psf: average(
                        units.map((unit) => unit.total_monthly_per_sq_ft ?? 0),
                    ),
                    percent_of_avg_monthly_charges: safeDivision(
                        totalMonthlyCharges,
                        sum(data.map((item) => item.total_monthly ?? 0)),
                    ),
                    average_total_annual: average(
                        units.map((unit) => unit.total_annual ?? 0),
                    ),
                    average_total_annual_psf: average(
                        units.map((unit) => unit.total_annual_per_sq_ft ?? 0),
                    ),
                    percent_of_avg_annual_charges: safeDivision(
                        totalAnnualCharges,
                        sum(data.map((item) => item.total_annual ?? 0)),
                    ),
                    total_monthly: totalMonthlyCharges,
                    total_annual: totalAnnualCharges,
                    total_monthly_psf: totalMonthlyChargesPSF,
                    total_annual_psf: totalAnnualChargesPSF,
                };
            });

            return processedData;
        };

        function sortByLocation(rowData: TransformedUnitMix) {
            if (rowData.bedroom_count === 'Studio') {
                return '0_' + rowData.property_name;
            }
            return rowData.bedroom_count + '_' + rowData.property_name;
        }

        const onExporting = async (e: ExportingEvent) => {
            await exportExcelFromDevExtremeDataGrid(e, {
                worksheetName: 'Unit Mix',
                filename: 'unitmix.xlsx',
            });
        };

        const transformedData = transformRentRollToUnitMix(
            rentRollWithTotalCharges() ?? [],
        );

        if (isPDFExport) {
            return (
                <div style={{ marginTop: 24 }}>
                    <UnitMixExportableGrid unitMix={transformedData} />
                </div>
            );
        }

        return (
            <div id={widgetExportId}>
                <DataGrid
                    style={{ height: 'auto' }}
                    dataSource={transformedData}
                    showBorders={true}
                    rowAlternationEnabled={false}
                    noDataText="No bedroom/bathroom data for this property"
                    id="unit-mix-table"
                    ref={dataGridRef}
                    columnAutoWidth={true}
                    onExporting={onExporting}
                >
                    <Column
                        dataField="bedroom_count"
                        caption="Beds"
                        dataType={'number'}
                        alignment={'center'}
                        sortOrder={'asc'}
                        calculateSortValue={sortByLocation}
                        allowFiltering={false}
                        calculateCellValue={(cellInfo: TransformedUnitMix) => {
                            if (cellInfo.bedroom_count === 0) {
                                return 'Studio';
                            }

                            return cellInfo.bedroom_count;
                        }}
                    />
                    {!hidePropertyColumns ? (
                        <Column
                            dataField="property_name"
                            caption="Property"
                            alignment={'center'}
                        />
                    ) : null}
                    <Column
                        dataField="number_of_units"
                        caption="# of Units"
                        dataType="number"
                        alignment={'center'}
                        allowFiltering={false}
                    >
                        <Format type="fixedPoint" precision={0} />
                    </Column>
                    <Column
                        dataField="avg_sf"
                        caption="Avg SF"
                        dataType="number"
                        allowFiltering={false}
                        alignment={'center'}
                    >
                        <Format type="fixedPoint" precision={0} />
                    </Column>
                    <Column
                        dataField="total_sf"
                        caption="Total SF"
                        dataType="number"
                        allowFiltering={false}
                        alignment={'center'}
                    >
                        <Format type="fixedPoint" precision={0} />
                    </Column>
                    <Column
                        dataField="percent_of_total_units"
                        caption="% of Total Units"
                        dataType="number"
                        allowFiltering={false}
                        alignment={'center'}
                    >
                        <Format type="percent" precision={1} />
                    </Column>
                    <Column
                        dataField="average_total_monthly"
                        caption="Avg Charges"
                        dataType="number"
                        allowFiltering={false}
                        alignment={'center'}
                    >
                        <Format type="currency" precision={2} currency="USD" />
                    </Column>
                    <Column
                        dataField="average_total_monthly_psf"
                        caption="Avg Charges PSF"
                        dataType="number"
                        allowFiltering={false}
                        alignment={'center'}
                    >
                        <Format type="currency" precision={2} currency="USD" />
                    </Column>
                    <Column
                        dataField="total_monthly"
                        caption="Total Charges"
                        dataType="number"
                        allowFiltering={false}
                        alignment={'center'}
                    >
                        <Format type="currency" precision={2} currency="USD" />
                    </Column>
                    <Column
                        dataField="percent_of_avg_monthly_charges"
                        caption="% of Total Charges"
                        dataType="number"
                        allowFiltering={false}
                        alignment={'center'}
                    >
                        <Format type="percent" precision={1} />
                    </Column>

                    <Summary calculateCustomSummary={calculateCustomSummary}>
                        <TotalItem
                            column="bedroom_count"
                            summaryType="sum"
                            displayFormat="Total/Avg"
                        />
                        <TotalItem
                            column="number_of_units"
                            summaryType="sum"
                            displayFormat="{0}"
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />

                        <TotalItem
                            name="avg_sf"
                            summaryType="custom"
                            displayFormat="{0}"
                            showInColumn="avg_sf"
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />

                        <TotalItem
                            column="total_sf"
                            summaryType="sum"
                            displayFormat="{0}"
                            showInColumn="total_sf"
                            valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        />

                        <TotalItem
                            column="percent_of_total_units"
                            summaryType="sum"
                            displayFormat="{0}"
                            showInColumn="percent_of_total_units"
                            valueFormat={{ type: 'percent', precision: 2 }}
                        />

                        <TotalItem
                            name="average_total_monthly"
                            summaryType="custom"
                            showInColumn="average_total_monthly"
                            valueFormat={{ type: 'currency', precision: 2 }}
                        />

                        <TotalItem
                            name="average_total_monthly_psf"
                            summaryType="custom"
                            showInColumn="average_total_monthly_psf"
                            valueFormat={{ type: 'currency', precision: 2 }}
                        />

                        <TotalItem
                            column="total_monthly"
                            summaryType="sum"
                            showInColumn="total_monthly"
                            displayFormat="{0}"
                            valueFormat={{ type: 'currency', precision: 2 }}
                        />

                        <TotalItem
                            column="percent_of_avg_monthly_charges"
                            summaryType="sum"
                            displayFormat="{0}"
                            showInColumn="percent_of_avg_monthly_charges"
                            valueFormat={{ type: 'percent', precision: 2 }}
                        />
                    </Summary>

                    <Export enabled={true} allowExportSelectedData={false} />
                    <HeaderFilter
                        allowSelectAll={true}
                        allowSearch={true}
                        visible={!isReportWidget}
                    />
                    <ColumnChooser
                        enabled={true}
                        mode={'select'}
                        height={400}
                        allowSearch={true}
                    >
                        <Position
                            my="right top"
                            at="right bottom"
                            of=".dx-datagrid-column-chooser-button"
                        />
                        <ColumnChooserSelection
                            allowSelectAll={true}
                            recursive={true}
                        />
                    </ColumnChooser>
                </DataGrid>
            </div>
        );
    },
);
export default UnitMixTable;
