import React from 'react';
import { map, groupBy, capitalize, sumBy, slice } from 'lodash';
import { renderToString } from 'react-dom/server';
import { barChartColors } from 'config/colors';
import { css } from 'emotion';
import { DASH_DASH } from 'config/constants';
import RentTooltip from 'components/leases/tenants/cards/RentByIndustry/Tooltip';
import WALETooltip from 'components/leases/tenants/cards/WALEByIndustry/Tooltip';
import SqFtTooltip from 'components/leases/tenants/cards/SqFtByIndustry/Tooltip';

export const toolTipClassnames = {
    container: css`
        font-size: 11px;
        min-width: 230px;
    `,
    label: css`
        display: flex;
        min-width: 115px;
        justify-content: space-between;
    `,
    labelHeader: css`
        margin-right: 8px;
    `,
    topLabel: css`
        margin-bottom: 5px;
    `,
    figure: css`
        font-weight: bold;
    `,
};

export const searchTenants = (searchTerm, tenants) => {
    if (!searchTerm) {
        return tenants;
    }

    const lowercaseSearchTerm = searchTerm.toLowerCase();

    return tenants.filter((tenant) => {
        const { name, industry, propertyName } = tenant;

        return (
            name?.toLowerCase().includes(lowercaseSearchTerm) ||
            industry?.toLowerCase().includes(lowercaseSearchTerm) ||
            propertyName?.toLowerCase().includes(lowercaseSearchTerm)
        );
    });
};

export const filterTenants = (industry, propertyName, tenants) => {
    let matches = tenants;

    if (industry) {
        matches = matches.filter((tenant) => {
            return tenant.industry === industry;
        });
    }

    if (propertyName) {
        matches = matches.filter((tenant) => {
            return (
                tenant.propertyNames.filter((p) => p === propertyName).length >
                0
            );
        });
    }

    return matches;
};

/* Bar chart utils*/
export const getBarChartData = ({
    statistics,
    labelPath,
    valuePath,
    tooltipFunc,
    partition,
}) => {
    let data = map(statistics, (item) => {
        const value = item[valuePath];
        return {
            label: capitalize(item[labelPath]),
            value,
            toolText: tooltipFunc({ value, item }),
        };
    });

    // lodash's `sortBy` does not sort `null` properly
    // we want null values at the end when sorting descending
    data = data.sort((a, b) => b.value - a.value);

    if (partition) {
        data = slice(data, 0, partition);
    }

    data = data.map((o, i) => {
        o.color = barChartColors[i];
        return o;
    });

    return data;
};

/* Rent Card */
export const getRentTableData = (tenants) => {
    const tenantsByIndustry = groupBy(tenants, 'industry');

    return map(tenantsByIndustry, (value, key) => {
        const tenantsCount = value.length;
        const grossRent = sumBy(value, 'annualInPlaceRent');
        const occupiedSqftSum = sumBy(value, 'occupiedSqft');

        const totalAnnualInPlaceRentSqFt = occupiedSqftSum
            ? grossRent / sumBy(value, 'occupiedSqft')
            : null;

        return {
            industry: capitalize(key),
            grossRent,
            tenantsCount,
            totalAnnualInPlaceRentSqFt,
        };
    }).sort((a, b) => b.grossRent - a.grossRent);
};

export const generateRentTooltip = ({ item }) => {
    const grossRent = item.annualInPlaceRent;
    const totalOccupiedSqft = item.tenant_occupied_area;
    const totalAnnualInPlaceRentSqFt = totalOccupiedSqft
        ? grossRent / totalOccupiedSqft
        : DASH_DASH;
    return renderToString(
        <RentTooltip
            grossRent={grossRent}
            totalAnnualInPlaceRentSqFt={totalAnnualInPlaceRentSqFt}
        />,
    );
};

/* Wale Card */
export const getWaleTableData = (statistics) => {
    return map(statistics, (industryStat) => {
        return {
            industry: capitalize(industryStat.tenant_industry),
            tenantsCount: industryStat.total_tenants_by_industry,
            totalWALE: industryStat.wale_years,
        };
    }).sort((a, b) => b.totalWALE - a.totalWALE);
};

export const generateWaleTooltip = ({ value }) => {
    const totalWALE = value ? value.toFixed(1) : DASH_DASH;
    return renderToString(<WALETooltip totalWALE={totalWALE} />);
};

/* Sq Ft Card */
export const getSqFtData = ({ statistics, partition }) => {
    // Adapt new payload to map old payload
    const data = statistics.map((item) => {
        return {
            industry: item.tenant_industry,
            totalSqFtOfIndustry: item.tenant_occupied_area,
            percentOfTotalPortfolioSqFt:
                item.tenant_occupied_area_percent_of_portfolio
                    ? item.tenant_occupied_area_percent_of_portfolio * 100
                    : item.tenant_occupied_area_percent_of_portfolio,
        };
    });

    let dataToFormat = null;

    /* If total number of industries is more than desired partition */
    if (partition && data.length > partition) {
        const topIndustries = slice(data, 0, partition);
        dataToFormat = topIndustries;
    } else {
        dataToFormat = data;
    }

    /* Format industries for chart */
    const formattedIndustries = map(dataToFormat, (value, index) => {
        const { industry, totalSqFtOfIndustry } = value;
        return {
            label: capitalize(industry),
            value: totalSqFtOfIndustry,
            toolText: renderToString(<SqFtTooltip {...value} />),
            color: barChartColors[index],
        };
    });

    /* Produce items for csv download */
    const csvData = data.map((item) => ({
        Industry: item.industry,
        'Occ. sqft': item.totalSqFtOfIndustry,
        '% of Total Occ. of Portfolio': item.percentOfTotalPortfolioSqFt
            ? `${item.percentOfTotalPortfolioSqFt.toFixed(2)} %`
            : DASH_DASH,
    }));

    return { data: formattedIndustries, csvData };
};

export const exportCSV = (data, fileName = 'download', showLabel = true) => {
    const fileData = typeof data !== 'object' ? JSON.parse(data) : data;
    let CSV = '';

    if (showLabel) {
        let row = '';
        for (const index in fileData[0]) {
            if (index) {
                row += index + ',';
            }
        }
        row = row.slice(0, -1);
        CSV += row + '\r\n';
    }

    for (let i = 0; i < fileData.length; i++) {
        let row = '';
        for (const index in fileData[i]) {
            if (index) {
                row += '"' + fileData[i][index] + '",';
            }
        }
        row.slice(0, row.length - 1);
        CSV += row + '\r\n';
    }

    const name = fileName.replace(/ /g, '_');
    const uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
    const link = document.createElement('a');
    link.href = uri;
    link.style = 'visibility:hidden';
    link.download = name + '.csv';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
