import React, { useEffect, useRef } from 'react';
import { Modal, Input, InputRef } from 'antd';
import { LocalReportItem } from 'waypoint-types';

interface ReportInstructionsModalProps {
    isModalOpen: boolean;
    handleOk: () => void;
    handleCancel: () => void;
    onUpdateReportItem: (item: LocalReportItem) => void;
    reportItem: LocalReportItem | null;
}

const ReportInstructionsModal = ({
    isModalOpen,
    handleOk,
    handleCancel,
    onUpdateReportItem,
    reportItem,
}: ReportInstructionsModalProps) => {
    const titleTextAreaRef = useRef<InputRef>(null);

    useEffect(() => {
        if (isModalOpen) {
            titleTextAreaRef.current?.focus({
                cursor: reportItem?.instructions?.length ? 'end' : 'start',
            });
        }
    }, [isModalOpen]);

    return isModalOpen ? (
        <Modal
            centered
            title="Instructions"
            open
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <Input.TextArea
                rows={4}
                placeholder="Instructions"
                maxLength={500}
                ref={titleTextAreaRef}
                style={{ width: '100%', margin: '16px 0' }}
                onChange={(e) => {
                    if (!reportItem) {
                        return;
                    }

                    onUpdateReportItem({
                        ...reportItem,
                        instructions: e?.target?.value ?? '',
                    });
                }}
                defaultValue={reportItem?.instructions ?? ''}
                showCount
            />
        </Modal>
    ) : (
        <></>
    );
};

export default ReportInstructionsModal;
