import { get, find, isNil } from 'lodash';
import { formatMoney, formatPercentage } from 'utils/formatters';
import { AccountMapping, AccountMappingChildren } from 'waypoint-types';

/**
 * Each property has ranking data about an account and that account's children. This util only pulls data from the account's ranking data, not its children, and therefore works if for leaf accounts that have no children. It returns data needed to for the tooltip and table.
 * @param {object} accountMappingRankingData
 */
export const getAccountMappingRankingDataFor = (
    accountMappingRankingData: AccountMapping
): {
    name: string;
    value: string;
    propertyRankValue: number | null;
} => {
    const name = get(accountMappingRankingData, 'account_mapping_name');
    const propertyRankValue = get(
        accountMappingRankingData,
        'property_rank_value'
    );
    const value = formatMoney(propertyRankValue);

    return {
        name,
        value,
        propertyRankValue,
    };
};

/**
 * Each property has ranking data about an account and that account's children. This reads ranking data for a specific child account as specified by the accountName argument. It is used in the tooltip and table.
 * @param {array} childAccounts
 * @param {string} accountName
 */
export const getChildAccountMappingRankingDataFor = (
    childAccounts: AccountMappingChildren[],
    accountName: string
): {
    value: string;
    propertyRankValue: number;
} => {
    const propertyRankValue =
        get(
            find(
                childAccounts,
                (acc) => acc.account_mapping_name === accountName
            ),
            'property_rank_value'
        ) ?? 0;

    const value = formatMoney(propertyRankValue);

    return { value, propertyRankValue };
};
