import chartBuilder from 'components/charts/chartBuilder';
import {
    OccupancyHistoryProps,
    OvertimeType,
    RootFusionChartsConfigProps,
} from 'waypoint-types';
import { toCalendarMonthAndYearAbbrv } from 'components/dates/utils';
import moment, { Moment } from 'moment';
import { dateSort } from 'utils/tables/sorters';
import { Dictionary } from 'ts-essentials';

export const baseChartStyle = {
    dataSeparator: ',',
    decimals: '1',
    exportFormats: 'PNG|PDF|JPG|SVG',
    compactDataMode: '1',
    labelDisplay: 'AUTO',
    lineThickness: '2.5',
    numberSuffix: '%',
    pixelsPerPoint: '0',
    pYAxisMaxValue: '100',
    canvasTopPadding: '10',
    canvasTopMargin: '30',
    canvasBottomMargin: '10',
    canvasBottomPadding: '10',
    setAdaptiveYMin: '1',
    setAdaptiveSYMin: '1',
    pYAxisName: '% Occupied',
    sdecimals: '2',
    numDivLines: 5,
    snumberPrefix: '$',
    snumberSuffix: '',
    showLegend: '1',
    sYAxisName: 'Actuals',
    theme: 'fusion',
};

interface OccupancyHistoryData {
    label: string;
    percentOccupied: number;
}

interface ActualsOverTimeData {
    label: string;
    amount: number;
}

export const getOccupancyHistoryDataset = (
    histories: OccupancyHistoryProps[],
    startDate: Moment,
    endDate: Moment,
): OccupancyHistoryData[] => {
    histories = histories.filter((item: OccupancyHistoryProps) => {
        const itemEnd = moment(item.period_end, 'YYYY-MM-DD');
        return itemEnd >= startDate && itemEnd <= endDate;
    });
    const sorted = histories.sort((a, b) =>
        dateSort(new Date(b.period_end), new Date(a.period_end)),
    );
    const dataset = sorted.map((i) => {
        const label = toCalendarMonthAndYearAbbrv(
            moment(i.period_end, 'YYYY-MM-DD'),
        );
        const percentOccupied = i.occupancy ? i.occupancy * 100 : 0;
        return { label, percentOccupied };
    });
    return dataset;
};

export const getActualsDataset = (
    actuals: OvertimeType[],
    startDate: Moment,
    endDate: Moment,
): ActualsOverTimeData[] => {
    actuals = actuals.filter((item: OvertimeType) => {
        const itemEnd = moment(item.period_end, 'YYYY-MM-DD');
        return itemEnd >= startDate && itemEnd <= endDate;
    });
    actuals = actuals.sort((a, b) =>
        dateSort(new Date(b.period_end), new Date(a.period_end)),
    );

    const dataset = actuals.map((i) => {
        const label = toCalendarMonthAndYearAbbrv(
            moment(i.period_end, 'YYYY-MM-DD'),
        );
        const amount = i.actual_amount || 0;
        return { label, amount };
    });
    return dataset;
};

export const buildOccupancyHistoryChartConfig = (
    historyData: OccupancyHistoryData[],
    actualsData: ActualsOverTimeData[],
    accountName: string,
    isPDFExport?: boolean,
): RootFusionChartsConfigProps => {
    const chart = chartBuilder();
    chart.type('zoomlinedy');
    chart.height('350');
    chart.data();

    const categories = [
        { category: actualsData.map((actual) => ({ label: actual.label })) },
    ];
    chart.categories(categories);

    const partialHistoryData = historyData.reduce(
        (acc: Dictionary<number, string>, history: OccupancyHistoryData) => {
            acc[history.label] = history.percentOccupied;
            return acc;
        },
        {},
    );

    const occupancyData: { label: string; value?: number }[] = [];
    for (const c of categories[0].category) {
        if (c.label in partialHistoryData) {
            const item = { label: c.label, value: partialHistoryData[c.label] };
            occupancyData.push(item);
        } else {
            occupancyData.push(c);
        }
    }

    chart.dataset([
        {
            seriesname: 'Occupancy Trend',
            color: '#F4AE5E',
            plotBorderColor: '#F4AE5E',
            anchorBgColor: '#F4AE5E',
            data: occupancyData,
        },
        {
            seriesname: `${accountName}`,
            color: '#3588BD',
            plotBorderColor: '#3588BD',
            anchorBgColor: '#3588BD',
            parentyaxis: 'S',
            data: actualsData.map((actual) => ({ value: actual.amount || 0 })),
        },
    ]);
    const chartStyle = isPDFExport
        ? {
              ...baseChartStyle,
              baseFont: 'Arial',
              xAxisNameFont: 'Arial',
              pYAxisNameFont: 'Arial',
              sYAxisNameFont: 'Arial',
              exportEnabled: '0',
              animation: '0',
          }
        : baseChartStyle;

    chart.style({
        ...chartStyle,
        caption: `Occupancy vs ${accountName}`,
    });
    const { config } = chart;
    return config;
};
