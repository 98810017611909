import { message } from 'antd';
import useSWR from 'swr';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { SavedConfiguration } from 'waypoint-types';
import { getSavedConfigurationById } from 'waypoint-requests';

export const useGetSavedConfigurationById = (
    configId: string
): DataHookResponse<SavedConfiguration> => {
    const cacheKey = `api/saved-configurations/${configId}/configId`;
    const { data, error, mutate, isValidating } = useSWR(
        cacheKey,
        () => getSavedConfigurationById(configId),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
            onError: () => {
                message.error('Failed to fetch saved configuration');
            },
            shouldRetryOnError: false,
        }
    );

    return {
        isLoading: !data || isValidating,
        isError: !!error,
        data,
        mutate,
    };
};
