import { message } from 'antd';
import useSWR from 'swr';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { getUserNotificationPreferences } from 'waypoint-requests';
import { NotificationPreferencesResponse } from 'waypoint-types';

export const useGetUserNotificationPreferences =
    (): DataHookResponse<NotificationPreferencesResponse> => {
        const { data, error, mutate, isValidating } = useSWR(
            `/user/notification-preferences`,
            () => getUserNotificationPreferences(),
            {
                revalidateOnFocus: false,
                revalidateOnMount: true,
                onError: () => {
                    message.error(
                        'Failed to fetch user notification preferences'
                    );
                },
                shouldRetryOnError: false,
            }
        );
        return {
            isLoading: !data,
            isError: !!error,
            data,
            mutate,
            isValidating,
        };
    };
