import React, { useState } from 'react';
import { RecurringChargeSelect } from 'components/leases/components/recurring-charge/RecurringChargeSelect';
import { useRecurringCharges } from 'waypoint-hooks';
import { ReportWidgetSettingsBuilderProps } from 'waypoint-types';
import ReportWidgetSettingsModal from 'components/reports/report-widget/ReportWidgetSettingsModal';

const RecurringChargesSettingsBuilder = ({
    entityCodes,
    isModalOpen,
    setIsModalOpen,
    onSaveWidgetSettings,
    widgetType,
    currentSettings,
}: ReportWidgetSettingsBuilderProps): JSX.Element => {
    const [selectedChargeCodes, setSelectedChargeCodes] = useState<string[]>(
        currentSettings?.selectedChargeCodes ?? []
    );
    const { data } = useRecurringCharges(entityCodes, {
        stakeholder: null,
        percentageType: null,
    });
    if (!data) {
        return <></>;
    }

    const { summary } = data;
    const buildReportWidgetSettingsInputs = () => {
        return {
            selectedChargeCodes,
        };
    };

    return (
        <ReportWidgetSettingsModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            settingsJsonBuilder={buildReportWidgetSettingsInputs}
            onSaveWidgetSettings={onSaveWidgetSettings}
            widgetType={widgetType}
        >
            <div style={{ fontSize: '12px', fontWeight: 'bold' }}>
                Recurring Charges
            </div>
            <RecurringChargeSelect
                recurringCharges={summary.buckets}
                selectedChargeCodes={selectedChargeCodes}
                onChange={setSelectedChargeCodes}
                useDrawerStyle={true}
            />
        </ReportWidgetSettingsModal>
    );
};

export default RecurringChargesSettingsBuilder;
