import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { Note } from 'waypoint-types';
import useSWR, { SWRConfiguration } from 'swr';
import { getGenericEntityReportMetadataNotes } from 'waypoint-requests';

export const useGetGenericEntityReportMetadataNotes = (
    entityCode: string,
    reportType: string,
    referenceId: string,
    config?: SWRConfiguration
): DataHookResponse<Note[]> => {
    const { data, error, mutate, isValidating } = useSWR(
        `/notes/by-entity/${entityCode}/${reportType}/${referenceId}`,
        () => {
            return getGenericEntityReportMetadataNotes(
                entityCode,
                reportType,
                referenceId
            );
        },
        config
    );

    return {
        data,
        mutate,
        isLoading: !data || isValidating,
        isError: !!error,
    };
};
