import { SelectedDataLevel } from 'waypoint-types';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import useSWR from 'swr';
import getTopTenants, {
    TopTenantsData,
} from 'waypoint-requests/leases/getTopTenants';

export const useTopTenants = (
    entityCodes: string[],
    selectedDataLevel: SelectedDataLevel,
    selectedChargeCode: string[],
    topNTenants: string
): DataHookResponse<TopTenantsData> => {
    const topTenantsCacheKey = [
        '/leases/top-tenants/',
        entityCodes.join(','),
        selectedDataLevel.percentageType,
        selectedDataLevel.stakeholder,
        selectedChargeCode,
        topNTenants,
    ];

    const { data, error, mutate, isValidating } = useSWR(
        entityCodes.length ? topTenantsCacheKey : null,
        () =>
            getTopTenants(
                entityCodes,
                selectedDataLevel,
                selectedChargeCode,
                topNTenants
            ),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
        }
    );
    return {
        isLoading: !data || isValidating,
        isValidating,
        isError: !!error,
        data,
        mutate,
    };
};
