import { Table } from 'antd';
import { css } from 'emotion';
import {
    currencyRenderer,
    integerRenderer,
    percentageRenderer,
} from 'utils/tables/renderers';
import {
    ColumnDescriptor,
    ExportableGridSummaryFormatter,
    convertColumnsToAntd,
    getColumnTotalCells,
    headerClass,
} from './GridExportConversionUtils';
import { Dictionary } from 'ts-essentials';
import { customCalculationFields } from 'components/leases/expirations/cards/rent-roll/utils';
import { safeDivision } from 'shared-types';
import { TenantData } from 'components/leases/tenants/top-tenants/topTenantsUtils';

export interface TenantDataPrettified {
    tenant: string;
    suites: number;
    rentable_sq_ft: number;
    percentage_occ_sq_ft: number | null;
    percentage_rentable_sq_ft: number | null;
    gross_rent: number;
    total_annual: number;
    total_monthly: number | null;
    total_monthly_per_sq_ft: number | null;
    total_annual_per_sq_ft: number;
    rent_per_sq_ft: number | null;
    industry: string;
    total_weight: number;
    total_annual_percentage: number;
    space_occupancy_status?: string;
    properties: number;
}

const rowClassOverride = css`
    font-size: 15px;
`;

export const topTenantsBaseColumns = (tenantsEnabled: boolean) => {
    const baseColumns: ColumnDescriptor[] = [
        {
            title: 'Tenant',
            dataIndex: 'tenant',
            key: 'tenant',
            align: 'left',
        },
        {
            title: 'Suites',
            dataIndex: 'suites',
            key: 'suites',
            align: 'center',
        },
        {
            title: 'Rentable SF',
            dataIndex: 'rentable_sq_ft',
            key: 'rentable_sq_ft',
            dataType: 'number',
            render: integerRenderer,
            align: 'center',
        },
        {
            title: '% of Occ. SF',
            dataIndex: 'percentage_occ_sq_ft',
            key: 'percentage_occ_sq_ft',
            dataType: 'number',
            render: percentageRenderer,
            align: 'center',
        },
        {
            title: '% of RSF',
            dataIndex: 'percentage_rentable_sq_ft',
            key: 'percentage_rentable_sq_ft',
            dataType: 'number',
            render: percentageRenderer,
            align: 'center',
        },
        {
            title: 'Annual Charges',
            dataIndex: 'total_annual',
            key: 'total_annual',
            dataType: 'number',
            render: currencyRenderer,
            align: 'center',
            width: '125px',
        },
        {
            title: '% of Charges',
            dataIndex: 'total_annual_percentage',
            key: 'total_annual_percentage',
            dataType: 'number',
            render: percentageRenderer,
            align: 'center',
        },
        {
            title: '$/SF',
            dataIndex: 'total_annual_per_sq_ft',
            key: 'total_annual_per_sq_ft',
            dataType: 'number',
            render: currencyRenderer,
            align: 'center',
        },
    ];

    if (tenantsEnabled) {
        baseColumns.push({
            title: 'Industry',
            dataIndex: 'industry',
            key: 'industry',
            dataType: 'string',
            align: 'left',
            width: '150px',
        });
    }

    return baseColumns;
};

const topTenantsSummaryFormatters: Dictionary<ExportableGridSummaryFormatter> =
    {
        rentable_sq_ft: {
            summaryType: 'sum',
            render: integerRenderer,
        },
        suites: {
            summaryType: 'sum',
            render: integerRenderer,
        },
        tenant: {
            summaryType: 'custom',
            render: (value: number) => `Total: ${value} Tenants`,
        },
        percentage_occ_sq_ft: {
            summaryType: 'sum',
            render: percentageRenderer,
        },
        percentage_rentable_sq_ft: {
            summaryType: 'custom',
            render: percentageRenderer,
        },
        total_annual: {
            summaryType: 'sum',
            render: currencyRenderer,
        },
        total_annual_percentage: {
            summaryType: 'sum',
            render: percentageRenderer,
        },
        total_annual_per_sq_ft: {
            summaryType: 'custom',
            render: currencyRenderer,
        },
    };

const calculateCustomSummary = (
    column: string,
    data: TenantDataPrettified[],
) => {
    if (customCalculationFields.includes(column)) {
        const totalRentableSqFt = data.reduce(
            (total, item) => total + item.rentable_sq_ft,
            0,
        );
        const totalColumn = data.reduce(
            (total, item) =>
                total +
                (column === 'total_monthly_per_sq_ft'
                    ? item?.total_monthly ?? 0
                    : item?.total_annual ?? 0),
            0,
        );
        return safeDivision(totalColumn, totalRentableSqFt);
    }

    if (column === 'tenant') {
        return data.length;
    }

    return data.reduce(
        (total, item) => total + Number(item[column as keyof TenantData]),
        0,
    );
};
export const TopTenantExportableGrid = ({
    data,
    tenantsEnabled,
}: {
    data: TenantDataPrettified[];
    tenantsEnabled: boolean;
}) => {
    const { gridColumns } = convertColumnsToAntd(
        topTenantsBaseColumns(tenantsEnabled),
        true,
        null,
    );

    return (
        <Table
            style={{ marginTop: '25px' }}
            dataSource={data}
            size="small"
            columns={gridColumns}
            pagination={false}
            bordered={true}
            className={headerClass}
            rowClassName={rowClassOverride}
            summary={() =>
                getColumnTotalCells(
                    gridColumns,
                    data,
                    topTenantsSummaryFormatters,
                    calculateCustomSummary,
                )
            }
        />
    );
};
