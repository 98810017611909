import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DataGrid } from 'devextreme-react';
import {
    Column,
    Export,
    Paging,
    Scrolling,
    SearchPanel,
    Grouping,
    GroupPanel,
    Summary,
    TotalItem,
    GroupItem,
    DataGridRef,
} from 'devextreme-react/data-grid';
import {
    useDataGridHeight,
    useGetEntityReportWidgets,
    useGetReports,
    useGetSelectedFilteredEntityCodes,
    useGetUserData,
    useRedirectIfTheUserNotAdmin,
    useReports,
} from 'waypoint-hooks';
import { Link, useHistory } from 'react-router-dom';
import {
    Button,
    Card,
    Select,
    Space,
    Tooltip,
    Modal,
    message,
    Switch,
} from 'antd';
import theme from 'config/theme';
import {
    DeleteOutlined,
    ExclamationCircleOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import { RouteUrls } from 'routes/RouteConstants';
import { css } from 'emotion';
import { statusOptions } from './constants';
import {
    renderDueDate,
    generateUniqueOptions,
    renderPercentage,
    renderReportNameCell,
    renderReportStatus,
    filterAndDecorateReports,
} from 'components/reports/ReportUtils';
import EntityReportsDeleted from './components/EntityReportsDeleted';
import { ReportGridType } from 'waypoint-types';
import { Dictionary } from 'ts-essentials';
import DeleteBottomMenu from './components/DeleteBottomMenu';

const alignStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
};

const { confirm } = Modal;

const footerStyle = (isFooterVisible: boolean) => css`
    position: absolute;
    bottom: ${isFooterVisible ? 0 : -80}px;
    left: 0;
    width: 100%;
    height: 80px;
    background: #fff;
    box-shadow: rgb(216 220 222) 0px 1px 0px inset;
    display: flex;
    z-index: 4;
    transition: bottom 0.3s ease-in-out;
`;

const pageStyle = css`
    height: 100%;
    overflow-y: scroll;
    background-color: ${theme.colors.grays.background};
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 35px;
        background-color: ${theme.colors.white};
        border-bottom: 1px solid lightgrey;
    }
    .inner-body {
        padding: 12px 30px;
    }
    .banner {
        background-color: ${theme.colors.white};
        padding: 12px 20px;
        margin: 0px auto 12px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        button {
            margin-left: auto;
        }
    }
`;

const selectStyle = css`
    width: 200px;
`;

const ReportManager = () => {
    const history = useHistory();
    useRedirectIfTheUserNotAdmin({
        history,
        route: RouteUrls.MY_REPORT_TEMPLATE,
    });
    const [createdBy, setCreatedBy] = useState<number | null>(null);
    const [status, setStatus] = useState<string | null>(null);
    const [selectedReportToDelete, setSelectedReportToDelete] = useState<
        ReportGridType[]
    >([]);
    const [deletedReportsMasterDetailGrid, setDeletedReportsMasterDetailGrid] =
        useState<DataGridRef<ReportGridType, any> | null>(null);

    const [entityReportIds, setEntityReportIds] = useState<string[]>([]);

    const [showDeletedTable, setShowDeletedTable] = useState<boolean>(false);

    const { restoreReportsRequest, deleteReportRequest } = useReports();

    const { isAdmin } = useGetUserData();
    const entityCodes: string[] = useGetSelectedFilteredEntityCodes();

    const {
        data: reportsRawData,
        isLoading,
        isValidating,
        mutate,
    } = useGetReports(entityCodes);

    useEffect(() => {
        const newEntityReportIds = (reportsRawData ?? []).flatMap((report) =>
            report.entityReports?.length
                ? report.entityReports.map((entityReport) => entityReport.id)
                : [],
        );
        setEntityReportIds(newEntityReportIds);
    }, [reportsRawData]);

    const {
        data: entityReportWidgets,
        isLoading: isLoadingEntityReportWidgets,
    } = useGetEntityReportWidgets({
        entityReportIds,
        entityCodes,
    });

    const dataGridHeight = useDataGridHeight({
        cardTitleHeight: 44,
        cardOuterPadding: 24,
        cardInnerPadding: 24,
        topBarHeight: 150, // Included the height of the banner
    });

    const createdByOptions = useMemo(
        () =>
            generateUniqueOptions(
                reportsRawData,
                (report) => report.created_by,
                (report) =>
                    `${report.createdByUser?.firstname} ${report.createdByUser?.lastname}`,
            ),
        [reportsRawData],
    );

    const onSelectionDeletedChange = useCallback(
        async ({ selectedRowKeys }: Dictionary<ReportGridType[]>) => {
            setSelectedReportToDelete(selectedRowKeys);
        },
        [],
    );

    const onCancel = () => {
        deletedReportsMasterDetailGrid?.instance().clearSelection();
        setSelectedReportToDelete([]);
    };

    const restoreReport = async (gridReportItems: ReportGridType[]) => {
        const reportIds = gridReportItems.map((r) => r.id);

        confirm({
            title: 'Recover Reports',
            icon: <ExclamationCircleOutlined />,
            content: `Are you sure you want to recover ${
                reportIds.length > 1 ? 'these reports' : 'this report'
            }?`,
            onOk() {
                mutate(
                    async () => {
                        await restoreReportsRequest(reportIds);
                        message.success('Report(s) restored successfully');
                        deletedReportsMasterDetailGrid
                            ?.instance()
                            .clearSelection();
                        setSelectedReportToDelete([]);

                        const restoredReports = reportsRawData?.map((r) => {
                            if (reportIds.includes(r.id)) {
                                return { ...r, deleted_at: null };
                            }
                            return r;
                        });

                        return restoredReports;
                    },
                    {
                        rollbackOnError: true,
                        populateCache: true,
                        revalidate: false,
                    },
                );
            },
        });
    };

    const deleteReport = async (
        gridReportItems: ReportGridType[],
        deletePermanently: boolean,
    ) => {
        if (!gridReportItems.length) {
            return;
        }

        const reportIds = gridReportItems.map((r) => r.id);

        confirm({
            title: `Are you sure you want to permanently delete ${
                reportIds.length > 1 ? 'these reports' : 'this report'
            }?`,
            icon: <ExclamationCircleOutlined />,
            content: deletePermanently
                ? 'These reports will be permanently deleted'
                : 'This report will be moved to the trash tab',
            onOk() {
                mutate(
                    async () => {
                        await deleteReportRequest(reportIds, deletePermanently);
                        message.success(
                            deletePermanently
                                ? 'Report(s) successfully permanently deleted'
                                : 'Report deleted successfully',
                        );
                        const updatedReports = deletePermanently
                            ? reportsRawData?.filter(
                                  (r) => !reportIds.includes(r.id),
                              )
                            : reportsRawData?.map((r) => {
                                  if (reportIds.includes(r.id)) {
                                      return {
                                          ...r,
                                          deleted_at: new Date().toISOString(),
                                      };
                                  }
                                  return r;
                              });

                        setSelectedReportToDelete([]);
                        deletedReportsMasterDetailGrid
                            ?.instance()
                            .clearSelection();
                        return updatedReports;
                    },
                    {
                        optimisticData: reportsRawData?.filter(
                            (r) => !reportIds.includes(r.id),
                        ),
                        rollbackOnError: true,
                        populateCache: true,
                        revalidate: false,
                    },
                );
            },
        });
    };

    const deletedReports = useMemo(
        () =>
            filterAndDecorateReports(
                reportsRawData?.filter((report) => !!report.deleted_at) ?? [],
                entityCodes,
                entityReportWidgets ?? [],
                createdBy,
                status,
            ),
        [reportsRawData, entityCodes, entityReportWidgets, createdBy, status],
    );

    const reportsData = useMemo(
        () =>
            filterAndDecorateReports(
                reportsRawData?.filter((report) => !report.deleted_at) ?? [],
                entityCodes,
                entityReportWidgets ?? [],
                createdBy,
                status,
            ),
        [reportsRawData, entityCodes, entityReportWidgets, createdBy, status],
    );

    const isLoadingReports =
        isLoading || isValidating || isLoadingEntityReportWidgets;

    return (
        <div className={pageStyle}>
            <div className="header">
                <div style={{ fontSize: 36, fontWeight: 700 }}>
                    Report Manager
                </div>
                <div>
                    <Space>
                        <Link to={RouteUrls.REPORT_CREATE}>
                            <Button
                                data-testid="report-create-button"
                                type="primary"
                                icon={<PlusOutlined />}
                            >
                                Add Report
                            </Button>
                        </Link>{' '}
                    </Space>
                </div>
            </div>
            <div className="inner-body">
                <div className="banner">
                    <Space>
                        <Select
                            disabled={
                                isLoadingReports ||
                                !!selectedReportToDelete?.length
                            }
                            placeholder="Created By"
                            value={createdBy}
                            onChange={(e) => {
                                setCreatedBy(e ? e : null);
                            }}
                            options={createdByOptions}
                            className={selectStyle}
                            style={{ width: 300 }}
                            allowClear
                        />
                        <Select
                            disabled={
                                isLoadingReports ||
                                !!selectedReportToDelete?.length
                            }
                            placeholder="Status"
                            onChange={(e) => {
                                setStatus(e ? e : null);
                            }}
                            allowClear
                            value={status}
                            options={statusOptions.map((option) => ({
                                value: option.label,
                                label: option.label,
                            }))}
                            className={selectStyle}
                        />
                        <Tooltip
                            title={
                                showDeletedTable
                                    ? 'Hide Deleted Reports'
                                    : 'Show Deleted Reports'
                            }
                        >
                            <Switch
                                style={
                                    showDeletedTable
                                        ? {
                                              backgroundColor: 'red',
                                              borderColor: 'red',
                                          }
                                        : {}
                                }
                                onClick={() => {
                                    if (showDeletedTable) {
                                        setSelectedReportToDelete([]);
                                    }
                                    setShowDeletedTable(!showDeletedTable);
                                }}
                                checkedChildren={<DeleteOutlined />}
                                unCheckedChildren={<DeleteOutlined />}
                            />
                        </Tooltip>
                    </Space>
                </div>
                <Card loading={isLoadingReports}>
                    {showDeletedTable ? (
                        <EntityReportsDeleted
                            onSelectionChanged={onSelectionDeletedChange}
                            setDeletedReportsMasterDetailGrid={(ref) =>
                                setDeletedReportsMasterDetailGrid(ref)
                            }
                            deletedReports={deletedReports}
                            isAdmin={isAdmin}
                        />
                    ) : (
                        <DataGrid
                            dataSource={reportsData}
                            id="report-manager-grid"
                            height={dataGridHeight}
                            allowColumnReordering={false}
                            showBorders={true}
                            wordWrapEnabled={false}
                            hoverStateEnabled={true}
                            columnMinWidth={100}
                            noDataText="No reports found"
                        >
                            <Export enabled allowExportSelectedData={false} />
                            <Column
                                dataField="report_name"
                                caption="Report Name"
                                cellRender={renderReportNameCell}
                                width={'auto'}
                            />
                            <Column
                                dataField="report_status"
                                caption="Report Status"
                                alignment={'center'}
                                cellRender={renderReportStatus}
                            />
                            <Column
                                caption="Properties Assigned"
                                dataField="properties"
                                alignment="center"
                                allowFiltering={false}
                            />
                            <Column
                                caption="Due Date"
                                dataField="due_date"
                                alignment="center"
                                cellRender={renderDueDate}
                                allowFiltering={false}
                            />
                            <Column
                                caption="Complete"
                                dataField="complete"
                                dataType="number"
                                alignment="center"
                                allowFiltering={false}
                            />
                            <Column
                                caption="Complete %"
                                dataField="complete_percentage"
                                dataType="number"
                                alignment="center"
                                cellRender={renderPercentage}
                                allowFiltering={false}
                            />
                            <Column
                                dataField="createdByUser"
                                caption={'Created By'}
                                alignment={'center'}
                            />

                            <Column
                                dataField="updatedByUser"
                                caption={'Last Updated By'}
                                alignment={'center'}
                                width={'auto'}
                            />

                            <Summary>
                                <TotalItem
                                    name="report_name"
                                    column="report_name"
                                    displayFormat="{0} Reports"
                                ></TotalItem>
                                <GroupItem
                                    name="report_name"
                                    column="report_name"
                                    summaryType="count"
                                    displayFormat="{0} Reports"
                                    alignByColumn
                                />
                            </Summary>
                            <Paging enabled={false} />
                            <Scrolling />
                            <SearchPanel
                                visible={true}
                                highlightCaseSensitive={false}
                                width={250}
                            />
                            <Grouping autoExpandAll={false} />
                            <GroupPanel visible={true} />
                        </DataGrid>
                    )}
                </Card>
            </div>
            <div
                data-testid="report-grid-footer"
                className={footerStyle(selectedReportToDelete?.length > 0)}
            >
                <div
                    style={{
                        ...alignStyle,
                        width: '100%',
                        padding: '16px',
                    }}
                >
                    <DeleteBottomMenu
                        onDeletePermanently={() =>
                            deleteReport(selectedReportToDelete, true)
                        }
                        onRestore={() => restoreReport(selectedReportToDelete)}
                        onCancel={onCancel}
                        batchValues={{
                            selectedReports: selectedReportToDelete,
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default ReportManager;
