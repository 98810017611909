import React from 'react';
import { css } from 'emotion';
import theme from 'config/theme';
import Card from 'components/style/Card';
import SelectField from 'components/analytics/portfolioSegmentation/performanceOverview/menu/SelectField';

const card = css`
    height: auto;
    width: 442px;
    padding: 20px;
    overflow: visible;
`;

const controls = css`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 10px;
`;

const button = css`
    padding: 7.5px 0;
    width: 89px;
    border-radius: 2px;
    color: ${theme.colors.blues.primary};
    border: 1px solid ${theme.colors.blues.primary};
    background-color: ${theme.colors.white};
    outline: none;
    line-height: 17px;
    font-size: 14px;

    &:first-child {
        margin-right: auto;
    }
    &:nth-child(2) {
        margin-right: 10px;
    }

    &:active {
        background-color: ${theme.colors.blues.primary};
        color: ${theme.colors.white};
    }

    &:disabled {
        background-color: ${theme.colors.grays.mediumDark};
        color: ${theme.colors.grays.text};
    }
`;

const primaryButton = css`
    ${button}
    background-color: ${theme.colors.blues.primary};
    color: ${theme.colors.white};
    &:active {
        background-color: #4888b8;
    }
`;

const Menu = ({
    onClose,
    onApply,
    changeGroupBy,
    changeKpi,
    kpiSelected,
    groupBy,
    saveConfig,
    metricsOptions,
    groupByOptions,
}) => {
    return (
        <Card className={card}>
            <h3 style={{ marginTop: 0 }}>Diversification Settings</h3>
            <SelectField
                fieldName="Key Performance Metrics"
                options={metricsOptions}
                placeholder={metricsOptions[0].label}
                onChange={changeKpi}
                value={kpiSelected}
            />
            <SelectField
                fieldName="Group By"
                options={groupByOptions}
                placeholder={groupByOptions[0].label}
                onChange={changeGroupBy}
                value={groupBy}
            />
            <hr style={{ margin: '0 -20px', paddingBottom: 0 }} />
            <div className={controls}>
                <button className={button} onClick={saveConfig}>
                    Save Config
                </button>
                <button className={button} onClick={onClose}>
                    Cancel
                </button>
                <button className={primaryButton} onClick={onApply}>
                    Apply
                </button>
            </div>
        </Card>
    );
};

export default Menu;
