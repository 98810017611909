import { css } from 'emotion';
import React from 'react';

interface ImageProps {
    src: string | null;
    isSelected: boolean;
    onImageClick: () => void;
}

const imageStyle = (width: number, height: number) => css`
    width: ${width}px;
    height: ${height}px;
    object-fit: contain;
`;

const Image: React.FC<ImageProps> = ({ src, isSelected, onImageClick }) => {
    return (
        <div
            className={`responsive${isSelected ? ' selected' : ''} ${imageStyle(
                90,
                90
            )}`}
            onClick={onImageClick}
        >
            <img
                alt={src ?? ''}
                src={src ?? ''}
                className={`thumbnail${
                    isSelected ? ' selected' : ''
                } ${imageStyle(90, 90)}`}
            />
            <div className="checked">
                <div className="icon" />
            </div>
        </div>
    );
};

export default Image;
