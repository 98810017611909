import urlBuilder from './urlBuilder';
import { isFunction, isUndefined, get } from 'lodash';
import { clearCookie } from './cookie';
import superagent from 'superagent';
import { API_URL } from 'config/constants';

const getLoginRedirectUrl = (): string => {
    if (window.location.pathname === '/login/index.html') {
        return window.location.href;
    }
    const baseUrlPort = process.env.NODE_ENV === 'development' ? '3000' : '';
    const loginUrl = urlBuilder.addQuery(
        `${urlBuilder.locationOrigin(baseUrlPort)}/login/index.html`,
        {
            success: true,
            state: window.location.href,
        },
    );
    return loginUrl;
};

export const logoutAuth0andRedirect = (): void => {
    const loginUrl = getLoginRedirectUrl();
    console.debug('logoutAuth0andRedirect: loginUrl =', loginUrl);

    window.sessionStorage.clear();

    const redirectUrl = `https://${(window as any).___AUTH0_DOMAIN}/v2/logout?federated&client_id=${(window as any).___AUTH0_CLIENT_ID}&returnTo=${encodeURIComponent(loginUrl)}`;

    window.location.replace(redirectUrl);
};

export const logoutIf401 = (error: any): any => {
    const shouldLogoutAndRedirect = get(error, 'status') === 401;
    if (shouldLogoutAndRedirect) {
        clearCookie('laravel_session');
        clearCookie('XSRF_TOKEN');
        logoutAuth0andRedirect();
    }
    return error;
};

export function handle401() {
    return (next: any) => (action: any) => {
        const { type, status } = action;
        const isRequestFailure = type === '@@query/REQUEST_FAILURE';
        const shouldLogoutAndRedirect = isRequestFailure && status === 401;
        if (shouldLogoutAndRedirect) {
            clearCookie('laravel_session');
            clearCookie('XSRF_TOKEN');
            logoutAuth0andRedirect();
            return superagent.get(`${API_URL}/logout`).withCredentials();
        }
        return next(action);
    };
}

// interface CheckSessionParams {
//     addIdle: boolean;
// }

// export function checkSession({ addIdle }: CheckSessionParams) {
//     return function (oldState: any, newState: any) {
//         if (!document.cookie.includes('laravel_session')) {
//             const loginUrl = urlBuilder.addQuery(
//                 `${urlBuilder.locationOrigin()}/login/index.html`,
//                 {
//                     state: window.location.href,
//                     idle: addIdle,
//                 }
//             );
//             clearCookie('XSRF-TOKEN');
//             clearCookie('laravel_session');
//             window.location.replace(loginUrl);
//         }

//         if (!isUndefined(window.ga)) {
//             const state = !isFunction(newState) ? newState : oldState;
//             const { pathname, search } = state.location;
//             window.ga('set', 'page', pathname + search);
//             window.urlChange = true;
//         }
//     };
// }
