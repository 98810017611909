import { API_URL } from 'config/constants';
import { Note } from 'waypoint-types';

const getNotesByReferenceId = async (referenceId: string): Promise<Note[]> => {
    const response = await fetch(
        `${API_URL}/notes/by-reference/${referenceId}`,
        {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
        }
    );

    if (!response.ok) {
        throw new Error('Failed to get report notes');
    }

    const { data } = await response.json();

    return data as Note[];
};

export default getNotesByReferenceId;
