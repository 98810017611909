import React from 'react';
import defaultStylePP from './defaultStylePP';
import { withRouter } from 'react-router-dom';
import { css } from 'emotion';
import { roles } from 'config/constants';
import { FixAnyType } from 'waypoint-types';

const resetButtonStyle = css`
    border: none;
    outline: none;
    background: none;
`;

interface ILinkNewPageCell {
    datum: { id: number; roles: string[]; roleHighest: string };
    style: {
        backgroundColor: string;
        opacity: string;
        width: string;
        height: string;
        display: string;
        overflow: string;
        paddingLeft: string;
        paddingRight: string;
    };
    cellData: string[];
    history: FixAnyType;
}

export const PermissionNewPageCell = ({
    cellData,
    datum,
    history,
    style,
    ...rest
}: ILinkNewPageCell): JSX.Element => {
    const goToPermissions = (userId: number) => {
        history.push(`/settings/admin/users/${userId}/permissions`);
    };

    if (cellData.includes(roles.CLIENT_ADMIN)) {
        return <div style={style} {...rest} />;
    }

    return (
        <div style={style} {...rest}>
            <button
                className={resetButtonStyle}
                onClick={() => goToPermissions(datum.id)}
            >
                <i
                    style={{ color: '#00ADE2', cursor: 'pointer' }}
                    className="fa-solid fa-lock"
                />
            </button>
        </div>
    );
};

export const PermissionsCellRenderer = defaultStylePP(
    withRouter(PermissionNewPageCell),
);
export default PermissionsCellRenderer;
