import store from 'state/store';

const defaultConfig = {
    ignoreDecimal: false,
    ignoreNegative: false,
};

// TODO (Daniel): Should handle ignores differently
const setFormat = (
    { decimalDisplay, negativeValue },
    config = defaultConfig,
) => {
    let formatterString = ',';

    if (
        config.ignoreDecimal ||
        decimalDisplay === true ||
        decimalDisplay.toString().toLowerCase() === 'yes'
    ) {
        formatterString += '.2f';
    } else if (
        decimalDisplay === false ||
        decimalDisplay.toString().toLowerCase() === 'no'
    ) {
        formatterString += '.0f';
    }

    if (negativeValue.toLowerCase() === 'accounting') {
        formatterString = ''.concat('', formatterString);
    } else if (
        config.ignoreNegative ||
        negativeValue.toLowerCase() === 'currency'
    ) {
        formatterString = ''.concat('-$', formatterString);
    }

    return formatterString;
};

// NOTE (Daniel): Ignores get drilled down twice. Not particularly ideal.
export const formatNumber = (value, config = defaultConfig) => {
    const decimalDisplay = store.getState().user.configuration.DECIMAL_DISPLAY;
    const negativeValue =
        store.getState().user.configuration.NEGATIVE_VALUE_SYMBOLS;
    const isAccounting = negativeValue.toLowerCase() === 'accounting';

    const formatSet = setFormat({ negativeValue, decimalDisplay }, config);

    const formatCurrency = (value, isAccounting) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        if (isAccounting) {
            if (value < 0) {
                // Remove the negative sign and wrap in parentheses
                return `(${formatter.format(-value)})`;
            }
            return formatter.format(value);
        }
        return formatter.format(value);
    };

    return formatCurrency(value, isAccounting);
};

const isAccountingNegative = (value) => {
    return value.indexOf('(') === 1 && value.indexOf(')') === value.length - 1;
};

export const isNegativeString = (value) => {
    if (isAccountingNegative(value)) {
        return true;
    }
    return value.indexOf('-') === 0;
};
