import { API_URL } from 'config/constants';

export const getPropertyValuation = async (entity_code: string) => {
    const response = await fetch(
        `${API_URL}/properties/${entity_code}/valuation`,
        {
            credentials: 'include',
        }
    );

    if (!response.ok) {
        throw new Error('Failed');
    }

    const { data } = await response.json();
    return data;
};
