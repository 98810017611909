import { EffectCallback, useEffect, useRef } from 'react';
import { isLatLngLiteral } from '@googlemaps/typescript-guards';
import { createCustomEqual } from 'fast-equals';

export const calculatePropertyBounds = (
    positions: google.maps.LatLngLiteral[]
): google.maps.LatLngBounds | undefined => {
    if (!positions || !positions.length) {
        return;
    }

    const bounds = new google.maps.LatLngBounds();

    for (const position of positions) {
        if (isValidCoordinate(position.lat, position.lng)) {
            bounds.extend(position);
        }
    }

    return bounds;
};

export const deepCompareEqualsForMaps = createCustomEqual(
    (deepEqual) => (a: any, b: any) => {
        if (
            isLatLngLiteral(a) ||
            a instanceof google.maps.LatLng ||
            isLatLngLiteral(b) ||
            b instanceof google.maps.LatLng
        ) {
            return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
        }

        // TODO extend to other types

        // use fast-equals for other objects
        return deepEqual(a, b);
    }
);

export function useDeepCompareMemoize(value: any) {
    const ref = useRef();

    if (!deepCompareEqualsForMaps(value, ref.current)) {
        ref.current = value;
    }

    return ref.current;
}

export function useDeepCompareEffectForMaps(
    callback: EffectCallback,
    dependencies: any[]
) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

export const isValidCoordinate = (
    latitude?: number,
    longitude?: number
): boolean =>
    latitude != null &&
    longitude != null &&
    !Number.isNaN(latitude) &&
    !Number.isNaN(longitude) &&
    Number.isFinite(latitude) &&
    Number.isFinite(longitude);
