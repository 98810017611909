import { LoadOptions } from 'devextreme/data';
import CustomStore from 'devextreme/data/custom_store';
import {
    groupByGridFields,
    rentRollWithTotalCharges,
} from 'components/leases/expirations/cards/rent-roll/utils';
import { Dictionary } from 'ts-essentials';
import { safeDivision } from 'waypoint-utils';

const isNotEmpty = (value: string | undefined | null) => {
    return value !== undefined && value !== null && value !== '';
};

type RequestCall<TParams, TResult> = (params: TParams) => Promise<TResult>;

interface DataResult {
    data: any[];
    totalCount: number;
    summary?: number[];
    groupCount?: number;
}

export const CustomDataGridStore = <
    TParams extends object,
    TResult extends DataResult,
>(
    requestCall: RequestCall<TParams & { queryString: string }, TResult>,
    requestParams: TParams,
    props: Dictionary<any, string>
) => {
    return new CustomStore({
        key: 'key',
        async load(loadOptions: LoadOptions) {
            const paramNames: (keyof LoadOptions)[] = [
                'skip',
                'take',
                'requireTotalCount',
                'requireGroupCount',
                'sort',
                'filter',
                'totalSummary',
                'group',
                'groupSummary',
            ];

            const queryString = paramNames
                .filter((paramName) => isNotEmpty(loadOptions[paramName]))
                .map(
                    (paramName) =>
                        `${paramName}=${encodeURIComponent(JSON.stringify(loadOptions[paramName]))}`
                )
                .join('&');

            const { selectedChargeCode } = props;
            const hasSelectedChargeCode =
                selectedChargeCode && selectedChargeCode.length > 0;

            const mergedParams = {
                ...requestParams,
                selectedChargeCode,
                queryString,
            };

            try {
                const result = await requestCall(mergedParams);

                // Type for loadOptions.group is not defined in devextreme
                const groupSelectors =
                    loadOptions.group && Array.isArray(loadOptions.group)
                        ? loadOptions.group.map(({ selector }: any) => selector)
                        : null;

                const chargeTotals = hasSelectedChargeCode
                    ? { ...result.summary }
                    : { ...props.summary };

                const gridTotals =
                    loadOptions.filter && Array.isArray(loadOptions.filter)
                        ? result.summary
                        : { ...props.totals, ...chargeTotals };

                const totals = { ...gridTotals };

                const totalSummary =
                    loadOptions.totalSummary &&
                    Array.isArray(loadOptions.totalSummary)
                        ? loadOptions.totalSummary.map((total: any) => {
                              // The  _per_sq_ft totals need to be calculated with the rentable_sq_ft from the totals
                              if (
                                  [
                                      'rent_annual_per_sq_ft',
                                      'rent_monthly_per_sq_ft',
                                      'cam_annual_per_sq_ft',
                                      'cam_monthly_per_sq_ft',
                                      'taxes_insurance_annual_per_sq_ft',
                                      'taxes_insurance_monthly_per_sq_ft',
                                      'other_annual_per_sq_ft',
                                      'other_monthly_per_sq_ft',
                                      'total_annual_per_sq_ft',
                                      'total_monthly_per_sq_ft',
                                  ].includes(total.selector)
                              ) {
                                  return safeDivision(
                                      totals[
                                          `${total.selector.split('_per_sq_ft')[0]}`
                                      ],
                                      totals['rentable_sq_ft']
                                  );
                              }
                              return totals[`${total.selector}`];
                          })
                        : undefined;

                return {
                    data: groupSelectors
                        ? groupByGridFields(result.data, props.charges)
                        : rentRollWithTotalCharges(result.data, props.charges),
                    totalCount: result.totalCount,
                    summary: totalSummary,
                    groupCount: result.groupCount,
                };
            } catch (err) {
                throw new Error('Data Loading Error');
            }
        },
    });
};
