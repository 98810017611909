import 'react-virtualized/styles.css';
import 'react-select/dist/react-select.css';
import 'react-virtualized-select/styles.css';
import 'react-datepicker/dist/react-datepicker.css';

import React, { useMemo } from 'react';
import { compose, Dispatch } from 'redux';
import { connect, RootStateOrAny } from 'react-redux';
import { setPageTitle } from 'state/browser';
import styles from 'components/app/App.module.css';
import 'styles/index.scss';
import { useLocation } from 'react-router-dom';
import { RouteUrls } from 'routes/RouteConstants';
import 'react-reflex/styles.css';
import { ClientData } from 'waypoint-types';
import { useAppIntervals } from 'waypoint-hooks';
import {
    hasOwnershipPercentageEnabled,
    selectCurrentUser,
} from 'state/user/selectors';
import { selectUsersById } from 'state/users/selectors';
import { EntityOwnershipProvider } from 'contexts/entity-ownership/EntityOwnershipProvider';
import { PermissionsGroupsProvider } from 'contexts';
import { allAccessPermissions } from 'shared-types';
import AppLayout from 'components/app/layout/AppLayout';

import config from 'devextreme/core/config';
import { licenseKey } from '../../../devextreme-license';

config({ licenseKey });

type SetPageTitleType = (pageName: string) => { type: string };

interface Browser {
    currentUrl: string;
    height: number;
    width: number;
}

interface AppProps {
    bootstrapped: boolean;
    clientObj: ClientData;
    browser: Browser;
    children: JSX.Element;
    setPageTitle: SetPageTitleType;
    isOwnershipPercentageEnabled: boolean;
    userPermissions: string[];
}

const App = ({
    bootstrapped,
    clientObj,
    browser,
    children,
    setPageTitle,
    isOwnershipPercentageEnabled,
    userPermissions,
}: AppProps) => {
    const location = useLocation();

    useAppIntervals(clientObj);

    useMemo(() => {
        if (!browser.currentUrl || browser.currentUrl !== location.pathname) {
            setPageTitle(location.pathname);
        }
    }, [location, browser.currentUrl]);

    const currentPath = location.pathname;
    const hideGlobalFilterBar =
        currentPath.startsWith(RouteUrls.PROPERTY) ||
        currentPath.startsWith(RouteUrls.SETTINGS) ||
        currentPath.startsWith(RouteUrls.ENTITY_REPORT_EDITOR) ||
        currentPath.startsWith(RouteUrls.FINANCIALS_VARIANCE_REPORTING);

    const hideDataLevelSelection =
        !currentPath.startsWith(RouteUrls.LEASES_PERFORMANCE_OVERVIEW) &&
        !currentPath.startsWith(RouteUrls.ANALYTICS_RANKING) &&
        !currentPath.startsWith(RouteUrls.LEASE_RENT_ROLL) &&
        !currentPath.startsWith(RouteUrls.LEASES_EXPIRATIONS_SCHEDULE) &&
        !currentPath.startsWith(
            RouteUrls.FINANCIALS_COMPARATIVE_INCOME_STATEMENT,
        ) &&
        !currentPath.startsWith(RouteUrls.FINANCIALS_CROSSTAB) &&
        !currentPath.startsWith(RouteUrls.FINANCIALS_BALANCE_SHEET) &&
        !currentPath.startsWith(RouteUrls.LEASES_TOP_TENANTS) &&
        !currentPath.startsWith(RouteUrls.ANALYTICS_BALANCE_SHEET_OVERVIEW) &&
        !currentPath.startsWith(RouteUrls.ANALYTICS_FINANCIAL_OVERVIEW) &&
        !currentPath.startsWith(RouteUrls.KPI_GRID_INDEX) &&
        !currentPath.startsWith(RouteUrls.PROPERTY_PROFILE_DETAILS);

    const innerHeight = hideGlobalFilterBar ? 0 : 50;

    return (
        <div className={styles.appContainer}>
            <div className={styles.container}>
                {bootstrapped && (
                    <PermissionsGroupsProvider
                        userPermissions={userPermissions}
                    >
                        <EntityOwnershipProvider
                            isDisabled={!isOwnershipPercentageEnabled}
                        >
                            <AppLayout
                                hideGlobalFilterBar={hideGlobalFilterBar}
                                hideDataLevelSelection={
                                    !isOwnershipPercentageEnabled ||
                                    hideDataLevelSelection
                                }
                            >
                                <div
                                    style={{
                                        position: 'relative',
                                        top: `${innerHeight}px`,
                                        height: `${
                                            browser.height - innerHeight
                                        }px`,
                                        maxWidth: browser.width,
                                        display: 'flex',
                                    }}
                                >
                                    {children}
                                </div>
                            </AppLayout>
                        </EntityOwnershipProvider>
                    </PermissionsGroupsProvider>
                )}
            </div>
        </div>
    );
};

const mapState = (state: RootStateOrAny) => {
    const { user, browser } = state;
    const { clientObj } = user;
    const currentUser = selectCurrentUser(state);
    const currentUserFeaturePermissions = selectUsersById(state, {
        userIds: [currentUser?.id],
    })[0]?.featurePermissions;

    return {
        clientObj,
        browser,
        isOwnershipPercentageEnabled: hasOwnershipPercentageEnabled(state),
        userPermissions: currentUserFeaturePermissions?.length
            ? currentUserFeaturePermissions
            : allAccessPermissions,
    };
};

const mapDispatch = (dispatch: Dispatch<SetPageTitleType>) => {
    return {
        setPageTitle: (pageName: string) => dispatch(setPageTitle(pageName)),
    };
};

export default compose(connect(mapState, mapDispatch))(App);
