import PropTypes from 'prop-types';
import React from 'react';
import { css, cx } from 'emotion';

// TODO (Daniel): Refactor this card
// TODO (Daniel): fix all references to the Card component in components/settings to point to this one

const Card = ({
    children,
    isEnabled = true,
    fluid = false,
    className = '',
    ...props
}) => {
    const cardPaper = css({
        backgroundColor: 'white',
        padding: '24px',
        minWidth: '100px',
        width: (fluid && '100%') || '700px',
        marginBottom: '20px',
        display: isEnabled ? 'block' : 'none',
        overflow: 'auto',
        borderRadius: '2px',
        boxShadow: `0px 3px 5px -1px rgba(0,0,0,0.20),
            0px 6px 10px 0px rgba(0, 0, 0, 0.04)
            `,
    });
    // NOTE: if using with React-Grid-Layout, props.style
    //       overrides position, transform, width, and height
    //       properties of cardPaper and props.className
    return (
        <div className={cx([cardPaper, className])} {...props}>
            {children}
        </div>
    );
};

Card.propTypes = {
    children: PropTypes.any,
    isEnabled: PropTypes.bool,
    large: PropTypes.bool,
    medium: PropTypes.bool,
    className: PropTypes.string,
    fluid: PropTypes.bool,
};

export default Card;
