import { API_URL } from 'config/constants';
import { DocumentUpload } from 'waypoint-types';

interface DeleteDocumentParams {
    documentUploadIds: string[];
    softDelete: boolean;
}

const deleteDocument = async ({
    documentUploadIds,
    softDelete,
}: DeleteDocumentParams): Promise<DocumentUpload[]> => {
    const response = await fetch(`${API_URL}/document-uploads`, {
        method: 'DELETE',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            document_upload_ids: documentUploadIds,
            soft_delete: softDelete,
        }),
    });

    if (!response.ok) {
        throw new Error('Failed to delete documents');
    }

    const { data } = await response.json();
    return data;
};

export default deleteDocument;
