import { Report, ReportSetting } from 'waypoint-types';
import { COMPLETE, IN_PROGRESS, ReportWorkflowReviewStatus } from './constants';
import {
    APPROVALS_SETTINGS_TYPE,
    REPORT_APPROVAL_TYPES,
} from './components/report-settings/constants';
import {
    EntityReport,
    EntityReportApproval,
    EntityReportWidget,
    ReportWorkflowReview,
    ReportWorkflowRole,
} from 'waypoint-types/report/types';
import { safeDivision } from 'waypoint-utils';
import { WORKFLOW_REVIEWER } from 'components/financials/comparative-income-statement/constants';

export const getApprovalsWithStatus = (
    approvals: EntityReportApproval[],
    reports: Report[],
    widgetList: EntityReportWidget[]
): EntityReportApproval[] => {
    const approvalsWithStatus: EntityReportApproval[] = [];
    for (const approval of approvals) {
        const report = reports.find(
            (report) => report.id === approval.reportId
        );

        const entityReport = report?.entityReports?.find(
            (entityReport: EntityReport) =>
                entityReport.id === approval.entityReportId
        );

        if (!report || !entityReport) {
            continue;
        }

        const isOneApprovalReportSetting = checkIsOneApprovalReportSetting(
            report.reportSettings
        );

        const entityReportWidgets: EntityReportWidget[] = widgetList.filter(
            (widget: EntityReportWidget) =>
                widget.entity_report_id === approval.entityReportId
        );

        const entityReportProgressPercentage = entityReportWidgets?.length
            ? calculatedProgress(entityReportWidgets)
            : {
                  completePercentage: 0,
                  totalProgressPercentage: 0,
              };

        const status = calculateStatus(
            approval,
            entityReport.workflowRole,
            entityReportProgressPercentage,
            isOneApprovalReportSetting
        );

        approvalsWithStatus.push({ ...approval, status });
    }

    return approvalsWithStatus;
};

const checkIsOneApprovalReportSetting = (
    reportSettings: ReportSetting[]
): boolean => {
    const reportApprovalSettings = reportSettings?.find(
        (settings) => settings.settings_type === APPROVALS_SETTINGS_TYPE
    );

    const approvalType = reportApprovalSettings?.settings['type'];
    return (
        approvalType === undefined || // no report setting exists yet
        approvalType === REPORT_APPROVAL_TYPES.OneApproval
    );
};

const calculateStatus = (
    approval: EntityReportApproval,
    workflowRoles: ReportWorkflowRole[],
    progressPercentage: {
        completePercentage: number;
        totalProgressPercentage: number;
    },
    isOneApprovalSetting: boolean
): ReportWorkflowReviewStatus => {
    const reviews = approval.reviews;
    if (!reviews.length) {
        if (!!approval.entityReportPublished) {
            return ReportWorkflowReviewStatus.InReview;
        }
        return progressPercentage.totalProgressPercentage > 0
            ? ReportWorkflowReviewStatus.InProgress
            : ReportWorkflowReviewStatus.Open;
    }

    if (isOneApprovalSetting) {
        const noRejectedReviews = reviews.every(
            (review: ReportWorkflowReview) =>
                review.status !== ReportWorkflowReviewStatus.Rejected
        );
        const oneReviewIsApproved = reviews?.some(
            (review: ReportWorkflowReview) =>
                review.status === ReportWorkflowReviewStatus.Approved
        );
        return oneReviewIsApproved && noRejectedReviews
            ? ReportWorkflowReviewStatus.Approved
            : ReportWorkflowReviewStatus.Rejected;
    }

    // for ApprovalTypes hierarchy_approval, and all_reviewers, first check if there's any missing review
    const reviewUserIds = reviews.map((review) => review.user_id);
    const everyWorkflowRoleHasSubmittedReview = workflowRoles
        .filter(
            (workflowRole) => workflowRole.report_role === WORKFLOW_REVIEWER
        )
        .every((workflowRole) => reviewUserIds.includes(workflowRole.user_id));
    const allReviewsAreApproved = reviews.every(
        (review: ReportWorkflowReview) =>
            review.status === ReportWorkflowReviewStatus.Approved
    );
    if (!everyWorkflowRoleHasSubmittedReview && allReviewsAreApproved) {
        return ReportWorkflowReviewStatus.InReview;
    }

    return allReviewsAreApproved
        ? ReportWorkflowReviewStatus.Approved
        : ReportWorkflowReviewStatus.Rejected;
};

export const calculatedProgress = (reportWidgetList: EntityReportWidget[]) => {
    const complete =
        reportWidgetList?.filter(
            (item) => item.status.toLowerCase() === COMPLETE
        ).length ?? 0;

    const inProgress =
        reportWidgetList?.filter(
            (item) => item.status.toLowerCase() === IN_PROGRESS
        ).length ?? 0;

    const completePercentage =
        safeDivision(complete, reportWidgetList.length ?? 0) * 100;

    const totalProgressPercentage =
        safeDivision(complete + inProgress, reportWidgetList.length ?? 0) * 100;

    return {
        completePercentage: Number(completePercentage.toFixed(1)),
        totalProgressPercentage: Number(totalProgressPercentage.toFixed(1)),
    };
};
