import { pageDetailsList } from './pageDetails';

export const PAGE_NAME_ONLY = true;

// NOTE: Use Alternate only exists for Opportunity -> Task migration
export function getPageDetails(url: string, returnPageNameOnly: boolean): any {
    const pageDetails = pageDetailsList.find((item) =>
        new RegExp(item.regex).test(url),
    );

    if (returnPageNameOnly && pageDetails && pageDetails.name) {
        const { name } = pageDetails;
        const pageName = name;
        return pageDetails ? pageName : '';
    }

    return pageDetails || {};
}

/**
 *  The pattern for page titles is: <page-type> | <brand>
 */
export function createPageTitle(url: string) {
    const brand = 'Waypoint';
    const pageDetails = getPageDetails(url, false);
    let title = brand;

    if (pageDetails) {
        const { name: pageName } = pageDetails;
        const name = pageName;

        title = name + ' | ' + brand;
    }

    return title;
}
