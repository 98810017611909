import { API_URL } from 'config/constants';
import { EntityReportWidget } from 'waypoint-types';

const updateEntityReportWidget = async (
    entityReportWidgetId: string,
    narrativeText: string,
    entityCodes: string[]
): Promise<EntityReportWidget> => {
    const response = await fetch(
        `${API_URL}/reports/entity-reports-widgets/${entityReportWidgetId}`,
        {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({
                narrative_text: narrativeText,
                entity_codes: entityCodes,
            }),
        }
    );

    if (!response.ok) {
        throw new Error('Failed to update entity report');
    }

    const { data } = await response.json();

    return data as EntityReportWidget;
};

export default updateEntityReportWidget;
