import React from 'react';
import { Select } from 'waypoint-react';
import { periodicityOptions } from 'components/analytics/constants';

const FinancialOverviewPeriodicitySelector = (props) => {
    const { value, onChange } = props;

    return (
        <Select
            options={periodicityOptions}
            value={value}
            onChange={onChange}
        />
    );
};

export default FinancialOverviewPeriodicitySelector;
