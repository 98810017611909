import PropTypes from 'prop-types';
import React from 'react';
import { map, filter, sortBy } from 'lodash';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { connectRequest, querySelectors } from 'redux-query';
import { createSelector } from 'reselect';
import { Button, FormControl } from 'react-bootstrap';
import { selectClientId } from 'state/user/selectors';
import { selectUsers } from 'state/users/selectors';
import { requestAllUsers } from 'state/requests';
import LoadingSpinner from 'components/style/LoadingSpinner';
import EditUserModal from 'components/settings/users/EditUserModal';
import CreateUserModal from 'components/settings/users/CreateUserModal';
import UserManagementTable from 'components/settings/users/UserManagementTable';
import styles from 'components/style/Subheader.module.css';
import sharedStyles from 'components/settings/shared.module.css';
import Card from 'components/style/Card';
import { donwloadUsersSummaryList } from 'waypoint-requests';
import { message } from 'antd';
import saveAs from 'file-saver';
import { getFilenameFromResponse } from 'waypoint-utils';
import { DownloadButton } from 'waypoint-react';

const spinnerStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
};

class UserManagement extends React.Component {
    static propTypes = {
        users: PropTypes.object,
        clientId: PropTypes.number,
        isReady: PropTypes.bool,
    };

    state = {
        showEditUserModal: false,
        showCreateUserModal: false,
        userId: null,
        isLoading: false,
        searchText: '',
    };

    // NOTE: defining open and close modal avoids race conditions with in EditUserModal.
    openEditUserModal = (userId) => {
        this.setState({ userId }, () =>
            this.setState({ showEditUserModal: true }),
        );
    };

    closeEditUserModal = () => {
        this.setState({ showEditUserModal: false }, () =>
            this.setState({ userId: null }),
        );
    };

    toggleCreateUserModal = () => {
        this.setState({ showCreateUserModal: !this.state.showCreateUserModal });
    };

    onDownloadUserList = async () => {
        this.setState({ isLoading: true });
        try {
            const response = await donwloadUsersSummaryList(
                this.props.clientId,
            );
            const blob = await response.blob();
            const filename = getFilenameFromResponse(response);
            saveAs(blob, filename);
            this.setState({ isLoading: false });
        } catch (error) {
            message.error('Error downloading user list');
        } finally {
            this.setState({ isLoading: false });
        }
    };

    handleSearchChange = (e) => {
        this.setState({ searchText: e.target.value });
    };

    render() {
        const { isReady, users } = this.props;
        const { searchText, showEditUserModal, showCreateUserModal, userId } =
            this.state;

        if (!isReady) {
            return (
                <div className={sharedStyles.pageContainer}>
                    <LoadingSpinner style={spinnerStyle} />
                </div>
            );
        }

        // Custom search logic
        const filteredUsers = filter(users, (user) => {
            const lowercasedFilter = searchText.toLowerCase();
            return (
                user.firstname.toLowerCase().includes(lowercasedFilter) ||
                user.lastname.toLowerCase().includes(lowercasedFilter) ||
                user.email.toLowerCase().includes(lowercasedFilter)
            );
        });

        return (
            <div className={sharedStyles.pageContainer}>
                <h1 className={sharedStyles.majorHeading}>User Management</h1>
                <Card style={{ margin: '0 auto' }} fluid large>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ order: 1 }}>
                            <h1
                                className={sharedStyles.majorHeading}
                                style={{ marginBottom: '12px' }}
                            >
                                User List
                            </h1>
                            <p
                                style={{
                                    color: '#5e5e5e',
                                    fontSize: '14px',
                                    fontWeight: 'regular',
                                }}
                            >
                                Create, edit and remove users and their
                                associated permissions to the Waypoint
                                application.
                            </p>
                        </div>
                        <hr style={{ margin: '5px 0', order: 2 }} />
                        <div style={{ order: 3 }}>
                            <FormControl
                                style={{
                                    height: '37px',
                                    width: '276px',
                                    float: 'left',
                                    marginLeft: '15px',
                                    marginBottom: '0px',
                                    border: '1px solid #cfcfcf',
                                    outline: 'none',
                                    fontSize: '17px',
                                    fontFamily: 'Lato',
                                    fontColor: 'black',
                                    borderRadius: '50px',
                                }}
                                className={styles.userSearch}
                                placeholder={'Search Users'}
                                onChange={this.handleSearchChange}
                            />
                            <i
                                className="fa-solid fa-search"
                                style={{
                                    height: '27px',
                                    float: 'left',
                                    marginTop: '14px',
                                    transform: 'translate3d(-30px, -2px, 0)',
                                }}
                            />
                            <span
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginRight: '15px',
                                    float: 'right',
                                }}
                            >
                                <Button
                                    style={{ marginRight: '15px' }}
                                    bsStyle="primary"
                                    onClick={this.toggleCreateUserModal}
                                >
                                    <i
                                        style={{ marginRight: '10px' }}
                                        className="fa-solid fa-plus"
                                    />
                                    Create User
                                </Button>
                                <DownloadButton
                                    disabled={this.state.isLoading}
                                    onClick={this.onDownloadUserList}
                                />
                            </span>
                        </div>
                        <div style={{ order: 4 }}>
                            <UserManagementTable
                                data={filteredUsers}
                                openEditUserModal={this.openEditUserModal}
                            />
                            {showEditUserModal && (
                                <EditUserModal
                                    openEditUserModal={this.openEditUserModal}
                                    closeEditUserModal={this.closeEditUserModal}
                                    userId={userId}
                                />
                            )}
                            {showCreateUserModal && (
                                <CreateUserModal
                                    toggleCreateUserModal={
                                        this.toggleCreateUserModal
                                    }
                                />
                            )}
                        </div>
                    </div>
                </Card>
            </div>
        );
    }
}

const selectUsersBySearchResult = createSelector(
    [selectUsers],
    (users) => users,
);

const mapDispatch = (dispatch) => {
    return {
        dispatch,
    };
};

const mapState = (state) => {
    const clientId = selectClientId(state);
    const { queries } = state;

    const userQueryConfig = requestAllUsers({ clientId });

    const isFinished = querySelectors.isFinished(queries, userQueryConfig);

    const allUsers = selectUsers(state);
    const users = sortBy(allUsers, ['activeStatus']);

    return {
        users,
        clientId,
        userQueryConfig,
        isReady: clientId && isFinished,
    };
};

export default compose(
    connect(mapState, mapDispatch),
    connectRequest(({ clientId, userQueryConfig }) => {
        if (!clientId) {
            return {};
        }
        return [userQueryConfig];
    }),
)(UserManagement);
