import React from 'react';
import { formatPercentage, formatNumber } from 'components/leases/utils';
import { cx } from 'emotion';
import { toolTipClassnames } from 'components/leases/tenants/utils';

const SqFtTooltip = ({
    totalSqFtOfIndustry,
    industry,
    percentOfTotalPortfolioSqFt,
}) => {
    return (
        <div className={toolTipClassnames.container}>
            <div
                className={cx([
                    toolTipClassnames.label,
                    toolTipClassnames.topLabel,
                ])}
            >
                <div>Industry</div>
                <div className={toolTipClassnames.figure}>{industry}</div>
            </div>
            <div
                className={cx([
                    toolTipClassnames.label,
                    toolTipClassnames.topLabel,
                ])}
            >
                <div>% Total Occ. Sq Ft</div>
                <div className={toolTipClassnames.figure}>
                    {formatPercentage(percentOfTotalPortfolioSqFt)}
                </div>
            </div>
            <div
                className={cx([
                    toolTipClassnames.label,
                    toolTipClassnames.bottomLabel,
                ])}
            >
                <div>Sq Ft</div>
                <div className={toolTipClassnames.figure}>
                    {formatNumber(totalSqFtOfIndustry)}
                </div>
            </div>
        </div>
    );
};

export default SqFtTooltip;
