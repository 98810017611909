import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { css, cx } from 'emotion';
import { uniqBy, capitalize, pick, merge, isEmpty, sortBy } from 'lodash';
import { DownloadButton } from 'waypoint-react';
import { getFilenameFromResponse } from 'waypoint-utils';
import { downloadTenantsData } from 'waypoint-requests';
import Card from 'components/style/Card';
import TenantFilterDropdown from 'components/leases/tenants/TenantFilterDropdown';
import TenantSearchBar from 'components/leases/tenants/TenantSearchBar';
import { TRANSPARENT } from 'config/colors';
import TenantSummaryTable from 'components/leases/tenants/cards/TenantSummary/table/Container';
import TenantLoadingTable from 'components/leases/tenants/cards/TenantSummary/table/LoadingTable';
import { saveAs } from 'file-saver';
import { message } from 'antd';
import TenantEmptyTable from 'components/leases/tenants/cards/TenantSummary/table/EmptyTable';
import { selectCurrentUser } from 'state/user/selectors';
import { filterTenants, searchTenants } from 'components/leases/tenants/utils';

const tableCard = css`
    padding: 20px;
`;

const buttonStyle = css`
    position: relative;
    top: 2px;
    z-index: 9;
    font-size: 2em !important;
    width: initial !important;
`;

const flexTop = css`
    display: flex;
    flex-direction: row;
    align-items: center;

    h3 {
        flex: 3;
        padding: 0;
        margin: 0;
        font-weight: bold;
        font-size: 22px;
    }
`;

const tableContainer = css`
    margin-top: 10px;
    height: 90%;
`;

const table = css`
    height: 90%;
    z-index: auto;
`;

const filterSetContainer = css`
    display: flex;
    flex-direction: row;
    justify-content: start;
    z-index: 100 !important;
    overflow: visible;
`;

const filterDropDown = css`
    margin-right: 15px;
    display: inline-block;
`;

const resetFiltersBtn = css`
    font-weight: 400;
    padding: 6px 12px;
    background-color: ${TRANSPARENT};
    border: none !important;
    outline: none !important;
    vertical-align: middle;
    &:active {
        font-weight: 600;
    }
`;

class TenantSummaryCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            propertyName: null,
            industry: null,
            name: null,
            id: null,
            search: {
                searchTerm: '',
                searchResult: [],
            },
            downloading: false,
        };
    }

    resetFilters = () => {
        this.setState({
            propertyName: null,
            industry: null,
            name: null,
            id: null,
            search: {
                searchTerm: '',
                searchResult: [],
            },
        });
    };

    searchTenants = (searchTerm) => {
        const matches = searchTenants(searchTerm, this.props.data);

        this.setState({
            search: {
                ...this.state.search,
                searchResult: matches,
                searchTerm,
            },
        });
    };

    handleIndustryChange = (industry) => {
        this.setState({ industry });
    };

    handlePropertyChange = (propertyName) => {
        this.setState({ propertyName });
    };

    handleClear = () => {
        this.setState({
            search: {
                searchTerm: '',
                searchResult: [],
            },
        });
    };

    downloadCSV = () => {
        this.setState({ downloading: true }, async () => {
            try {
                const tenantsCSV = await downloadTenantsData(
                    this.props.entityCodes,
                    this.props.selectedDataLevel,
                );

                const blob = await tenantsCSV.blob();
                const filename = getFilenameFromResponse(tenantsCSV);

                saveAs(blob, filename);
            } catch (e) {
                message.error(
                    'Download Failed',
                    'An error occurred while downloading the file',
                );
            } finally {
                this.setState({ downloading: false });
            }
        });
    };

    render() {
        const isGroup = this.props.entityCodes.length > 1;
        const { title, data, className, isLoading, ...props } = this.props;
        const { searchResult, searchTerm } = this.state.search;

        const cardProps = merge(pick(props, ['style', 'data-grid']), {
            className,
        });

        if (isLoading) {
            return <TenantLoadingTable {...cardProps} title={title} />;
        }

        if (isEmpty(data)) {
            return <TenantEmptyTable {...cardProps} title={title} />;
        }

        const industryFilter = this.state.industry
            ? this.state.industry.value
            : null;
        const propertyFilter = this.state.propertyName
            ? this.state.propertyName.value
            : null;

        const filtered = filterTenants(
            industryFilter,
            propertyFilter,
            searchTerm ? searchResult : this.props.data,
        ).sort((a, b) => {
            return b.occupiedSqft - a.occupiedSqft;
        });

        // FILTER OPTIONS
        const propertyOptions = sortBy(
            uniqBy(
                filtered.reduce((propertyNames, tenant) => {
                    const propertyNamesToAdd = tenant.propertyNames.map(
                        (propertyName) => {
                            return {
                                value: propertyName.trim(),
                                label: propertyName.trim(),
                            };
                        },
                    );

                    return [...propertyNames, ...propertyNamesToAdd];
                }, []),
                'label',
            ),
            'label',
        );

        const industryOptions = sortBy(
            uniqBy(
                filtered.map(({ industry }) => {
                    return {
                        value: industry,
                        label: capitalize(industry),
                    };
                }),
                'label',
            ),
            'label',
        );

        return (
            <Card fluid className={cx([tableCard, className])} {...cardProps}>
                <div className={flexTop}>
                    <h3>{title}</h3>
                    <DownloadButton
                        className={buttonStyle}
                        onClick={this.downloadCSV}
                        disabled={this.state.downloading}
                    />
                </div>
                <div className={tableContainer}>
                    <div className={filterSetContainer}>
                        <TenantSearchBar
                            isLoading={isLoading}
                            value={this.state.search.searchTerm}
                            onClear={this.handleClear}
                            onSearch={this.searchTenants}
                        />
                        <TenantFilterDropdown
                            value={this.state.industry}
                            className={filterDropDown}
                            options={industryOptions}
                            name="industry"
                            placeholder="Industry"
                            onChange={this.handleIndustryChange}
                        />
                        {isGroup ? (
                            <TenantFilterDropdown
                                value={this.state.propertyName}
                                className={filterDropDown}
                                options={propertyOptions}
                                name="property"
                                placeholder="Property"
                                isGroup={isGroup}
                                onChange={this.handlePropertyChange}
                            />
                        ) : null}
                        <button
                            className={resetFiltersBtn}
                            onClick={this.resetFilters}
                        >
                            Reset Filters
                        </button>
                    </div>
                    <div className={table}>
                        <TenantSummaryTable data={filtered} isGroup={isGroup} />
                    </div>
                </div>
            </Card>
        );
    }
}

TenantSummaryCard.propTypes = {
    data: PropTypes.array,
    className: PropTypes.string,
    title: PropTypes.string,
    isLoading: PropTypes.bool,
    entityCodes: PropTypes.arrayOf(PropTypes.string),
    selectedDataLevel: PropTypes.object,
};

const mapState = (state) => {
    const { id: userId } = selectCurrentUser(state);

    return {
        userId,
    };
};

export default connect(mapState)(TenantSummaryCard);
