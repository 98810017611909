import { isNull, isUndefined } from 'lodash';
import { DASH_DASH } from 'config/constants';

export const doubleDash = '\u2014\u2014';
export const singleDash = '\u2014';
export const addCommas = (value) => {
    const formatter = new Intl.NumberFormat('en-US', {
        useGrouping: true,
    });
    return formatter.format(value);
};

export const numberIsInvalid = (val) => {
    return isNull(val) || isUndefined(val) || isNaN(val);
};

export const formatNumber = (val) => {
    return numberIsInvalid(val) ? doubleDash : addCommas(val);
};

export const formatNumberToDecimalPlace = (val, decimalPlaces = 2) => {
    return numberIsInvalid(val)
        ? doubleDash
        : addCommas(Number(val).toFixed(decimalPlaces));
};

export const waleFormat = (val, decimalPlaces = 1) => {
    return numberIsInvalid(val)
        ? doubleDash
        : Number(val).toFixed(decimalPlaces);
};

export const moneyFormatter = (value) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    return formatter.format(value);
};

export const compactMoneyFormatter = (value) => {
    const absValue = Math.abs(value);
    let suffix = '';
    let formattedValue = absValue;

    if (absValue >= 1e12) {
        suffix = 'T';
        formattedValue = absValue / 1e12;
    } else if (absValue >= 1e9) {
        suffix = 'B';
        formattedValue = absValue / 1e9;
    } else if (absValue >= 1e6) {
        suffix = 'M';
        formattedValue = absValue / 1e6;
    } else if (absValue >= 1e3) {
        suffix = 'K';
        formattedValue = absValue / 1e3;
    }

    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
    });

    return (
        (value < 0 ? '-' : '') + '$' + formatter.format(formattedValue) + suffix
    );
};

export const formatMoney = (figure) => {
    return numberIsInvalid(figure) ? `$${DASH_DASH}` : moneyFormatter(figure);
};

export const compactFormatMoney = (figure) => {
    return numberIsInvalid(figure)
        ? `$${DASH_DASH}`
        : compactMoneyFormatter(figure).replace('G', 'B').replace('k', 'K');
};

export const formatPercentage = (val, decimialPlaces = 2) => {
    return numberIsInvalid(val)
        ? `${DASH_DASH}%`
        : `${val.toFixed(decimialPlaces)}%`;
};
