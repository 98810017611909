import { API_URL } from 'config/constants';

interface DeleteReportParams {
    deletePermanently: boolean;
    reportIds: string[];
}

const deleteReports = async (body: DeleteReportParams): Promise<string> => {
    const response = await fetch(`${API_URL}/reports`, {
        method: 'DELETE',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
    });

    if (!response.ok) {
        throw new Error('FAILED');
    }

    const { data } = await response.json();
    return data;
};

export default deleteReports;
