import { css } from 'emotion';
import { GRAY } from 'config/colors';

export const page = css`
    display: block;
    background-color: ${GRAY};
    height: 100%;
    padding: 0;
`;

export const accountSelectContainer = css`
    float: left;
    margin-left: 24px;
    width: 350px;
`;
export const dateRangeContainer = css`
    float: right;
    margin-right: 24px;
`;

export const actionButtons = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const emptyStateContainer = css`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
