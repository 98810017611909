import { API_URL } from 'config/constants';
import { PermissionGroup } from 'waypoint-types';

interface UpdatePermissionGroupParams {
    body: {
        name: string;
        permission_ids: string[];
        description: string;
    };
    permissionGroupId: string;
    clientId: number;
}

export async function updatePermissionGroup({
    body,
    permissionGroupId,
    clientId,
}: UpdatePermissionGroupParams): Promise<PermissionGroup> {
    const response = await fetch(
        `${API_URL}/permission-groups/${clientId}/${permissionGroupId}/`,
        {
            method: 'PUT',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body),
        }
    );

    if (!response.ok) {
        throw new Error('Failed to update permissions group');
    }

    const { data } = await response.json();

    return data as PermissionGroup;
}
