import ReactFC from 'react-fusioncharts';
import chartBuilder from 'components/charts/chartBuilder';
import { RecurringChargeRollUp } from 'waypoint-types';
import theme from 'config/theme';
interface RecurringChargePieChartProps {
    data: RecurringChargeRollUp[];
    isPDFExport?: boolean;
    setIsChartReadyToExport?: (isReady: boolean) => void;
}

interface IPieChart {
    [key: string]: {
        label: string;
        value: number;
    };
}

export const RecurringChargePieChart = ({
    data,
    isPDFExport,
    setIsChartReadyToExport,
}: RecurringChargePieChartProps): JSX.Element | null => {
    if (!data) {
        return null;
    }
    const chartData = data.reduce(
        (acc: IPieChart, charge: RecurringChargeRollUp) => {
            const bucketName: string = charge.bucket_name;
            if (acc[bucketName] === undefined) {
                acc[bucketName] = {
                    label: bucketName,
                    value: charge.monthly,
                };
            } else {
                acc[bucketName].value += charge.monthly;
            }
            return acc;
        },
        {},
    );
    const recurringChargeChartConfig = buildPieChartConfigFor(
        chartData,
        isPDFExport,
    );

    const onDrawComplete = () => {
        setIsChartReadyToExport && setIsChartReadyToExport(true);
    };

    return (
        <>
            <ReactFC
                {...recurringChargeChartConfig}
                data-testid="recurring-charge-pie-chart"
                fcEvent-drawComplete={onDrawComplete}
            />
        </>
    );
};

const pieChartStyle = {
    enableRotation: '1',
    caption: 'Charge Breakdown',
    showLabels: '0',
    showBorder: false,
    showLegend: '1',
    palettecolors: theme.colors.barCharts,
    showPercentageValues: '0',
    showZeroPies: '1',
    numberPrefix: '$',
    enableMultiSlicing: '0',
    enableSmartLabels: '0',
    skipOverlapLabels: '1',
    manageLabelOverflow: '1',
    useEllipsesWhenOverflow: '1',
    pieRadius: '60%',
    startingAngle: '90',
    decimals: '2',
    alignCaptionWithCanvas: '1',
    showHoverEffect: '1',
    plotHoverEffect: '1',
    plotHighlightEffect: 'fadeout',
    exportFormats: 'PNG|PDF|JPG|SVG',
};

const buildPieChartConfigFor = (
    chartData: IPieChart,
    isPDFExport?: boolean,
) => {
    const chart = chartBuilder();

    chart.type('doughnut2d');
    chart.height('350');
    chart.data(Object.values(chartData));

    const primaryChartStyle = isPDFExport
        ? {
              ...pieChartStyle,
              baseFont: 'Arial',
              labelFont: 'Arial',
              legendItemFont: 'Arial',
              exportEnabled: '0',
              animation: '0',
          }
        : {
              ...pieChartStyle,
          };
    chart.style(primaryChartStyle);
    return chart.config;
};
