import React, { Component } from 'react';
import { css } from 'emotion';
import theme from 'config/theme';

const page = css`
    height: 100%;
    overflow-y: scroll;
    display: flex;
    background-color: ${theme.colors.grays.background};
    flex-grow: 1;
    flex-direction: column;
`;

class FinancialsContainer extends Component {
    render() {
        return <div className={page}>{this.props.children}</div>;
    }
}

export default FinancialsContainer;
