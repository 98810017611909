import React, { RefObject, useMemo } from 'react';
import DataGrid, {
    Column,
    GroupItem,
    Grouping,
    HeaderFilter,
    Paging,
    Scrolling,
    Selection,
    Summary,
    DataGridRef,
} from 'devextreme-react/data-grid';
import { Dictionary } from 'ts-essentials';
import {
    CellInfoType,
    EntityReport,
    PropertyType,
    User,
    Report,
    EntityReportForGrid,
    EntityReportWidget,
    EntityReportApproval,
} from 'waypoint-types';
import {
    WORKFLOW_ASSIGNEE,
    WORKFLOW_REVIEWER,
} from 'components/financials/comparative-income-statement/constants';
import {
    decorateEntityReportsForGrid,
    renderPublishedReport,
    workflowGroupRenderer,
} from './ReportUtils';
import { useGetMentionableUsers } from 'waypoint-hooks';
import { RowPreparedEvent } from 'devextreme/ui/data_grid';
import theme from 'config/theme';
import { getApprovalsWithStatus } from './ReportApprovalUtils';
import { DisabledDashboard } from 'waypoint-react';

interface EntityReportTableProps {
    reports: Report[] | undefined;
    users: User[];
    approvals: EntityReportApproval[];
    properties?: Dictionary<PropertyType>;
    onSelectionChanged?: (data: Dictionary<EntityReport[]>) => void;
    reportManagerRef?: RefObject<DataGridRef<any, any>>;
    reportUserRef?: RefObject<DataGridRef<any, any>>;
    entityReportWidgets: EntityReportWidget[];
    isMyReportsTable?: boolean;
    expanded?: boolean;
    isAdmin?: boolean;
    isWorkflowSteps?: boolean;
}
const EntityReportGrid = ({
    reports,
    users,
    approvals,
    properties,
    onSelectionChanged,
    reportManagerRef,
    entityReportWidgets,
    isMyReportsTable,
    reportUserRef,
    expanded,
    isAdmin,
    isWorkflowSteps,
}: EntityReportTableProps) => {
    const userData = useGetMentionableUsers();

    const entityReportIds = (reports ?? []).flatMap((report) =>
        report.entityReports?.length
            ? report.entityReports?.map((entityReport) => entityReport.id)
            : [],
    );

    const dataSource = useMemo(() => {
        const approvalsWithStatus = approvals
            ? getApprovalsWithStatus(
                  approvals,
                  reports ?? [],
                  entityReportWidgets,
              )
            : [];
        return decorateEntityReportsForGrid(
            reports,
            properties ?? {},
            entityReportWidgets,
            approvalsWithStatus,
        );
    }, [reports, properties, entityReportWidgets, approvals]);

    const linkRenderer = ({
        data,
        reportLinkCell,
    }: {
        data: EntityReportForGrid;
        reportLinkCell?: boolean;
    }) => {
        let url = `#/entity-report/edit?entity_code=${data.entity_code}&report_id=${data.report_id}`;

        if (isMyReportsTable) {
            url += '&from_my_reports=1';
        }

        if (reportLinkCell) {
            return <a href={url}>View Report</a>;
        }

        return <a href={url}>{data.property}</a>;
    };

    const onRowPrepared = (e: RowPreparedEvent) => {
        if (e.rowType === 'group') {
            e.rowElement.style.backgroundColor = theme.colors.grays.background;
        }
    };

    if (!approvals && entityReportIds.length > 0) {
        return <DisabledDashboard text={'Loading'} />;
    }

    return (
        <div
            style={{
                padding: '10px',
            }}
        >
            <div
                style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    marginBottom: '10px',
                    lineHeight: '1.25',
                    backgroundColor: '#f5f5f5',
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                }}
            >
                <DataGrid
                    ref={reportManagerRef ?? reportUserRef}
                    showBorders={true}
                    showRowLines={true}
                    columnAutoWidth={isMyReportsTable}
                    columnMinWidth={95}
                    showColumnLines={true}
                    rowAlternationEnabled={false}
                    dataSource={dataSource}
                    height={'auto'}
                    onRowPrepared={(e) => {
                        e.rowElement.style.height = '38px';
                        if (isMyReportsTable) {
                            onRowPrepared(e);
                        }
                    }}
                    onSelectionChanged={
                        isMyReportsTable ? null : (onSelectionChanged as any)
                    }
                    repaintChangesOnly={true}
                >
                    <HeaderFilter
                        visible={true}
                        allowSearch={true}
                        height={250}
                    />
                    {isMyReportsTable ? (
                        <Column
                            fixed
                            alignment={'center'}
                            fixedPosition={'left'}
                            allowFiltering={false}
                            cellRender={(data) =>
                                isWorkflowSteps
                                    ? data.data.property
                                    : linkRenderer({
                                          data: data?.data ?? {},
                                          reportLinkCell: true,
                                      })
                            }
                            minWidth={120}
                        />
                    ) : null}

                    <Column
                        dataField="property"
                        caption="Property"
                        allowFiltering={true}
                        cellRender={
                            isMyReportsTable
                                ? undefined
                                : (data) =>
                                      isWorkflowSteps
                                          ? data.data.property
                                          : linkRenderer(data)
                        }
                        sortOrder="asc"
                        minWidth={150}
                        width={isMyReportsTable ? undefined : '23%'}
                    />
                    {isMyReportsTable ? (
                        <Column
                            dataField="report_name"
                            caption="Report Name"
                            allowFiltering={true}
                        />
                    ) : null}
                    {!isWorkflowSteps && (
                        <Column
                            dataField="progress"
                            caption="Progress"
                            alignment="center"
                            width={isMyReportsTable ? undefined : '20%'}
                            allowFiltering={false}
                        />
                    )}
                    <Column
                        caption="Assignee"
                        cellRender={(data: CellInfoType) =>
                            workflowGroupRenderer(
                                data.data,
                                userData,
                                WORKFLOW_ASSIGNEE,
                            )
                        }
                        alignment="center"
                        width={isMyReportsTable ? undefined : '13%'}
                        allowFiltering={false}
                    />
                    <Column
                        caption="Reviewer"
                        cellRender={(data: CellInfoType) =>
                            workflowGroupRenderer(
                                data.data,
                                userData,
                                WORKFLOW_REVIEWER,
                            )
                        }
                        alignment="center"
                        width={isMyReportsTable ? undefined : '13%'}
                        allowFiltering={false}
                    />
                    <Column
                        alignment={'center'}
                        dataField="formatted_due_date"
                        caption="Due Date"
                        width={isMyReportsTable ? undefined : '15%'}
                        allowFiltering={false}
                    />
                    {!isWorkflowSteps && (
                        <Column
                            dataField={'report_status'}
                            caption="Status"
                            cellRender={renderPublishedReport}
                            alignment="center"
                            allowFiltering={true}
                        />
                    )}

                    {isMyReportsTable ? null : <Scrolling mode="virtual" />}

                    {isMyReportsTable ? <Paging enabled={false} /> : null}

                    {isMyReportsTable || !isAdmin ? null : (
                        <Selection
                            mode="multiple"
                            showCheckBoxesMode={'always'}
                        />
                    )}
                    {isMyReportsTable ? (
                        <Grouping autoExpandAll={expanded} />
                    ) : null}
                    {isMyReportsTable ? (
                        <Summary>
                            <GroupItem
                                column={'property'}
                                summaryType={'count'}
                                valueFormat={{
                                    type: 'fixedPoint',
                                    precision: 0,
                                }}
                                displayFormat={'{0} Reports'}
                            />
                        </Summary>
                    ) : null}
                </DataGrid>
            </div>
        </div>
    );
};

export const EntityReportTable = React.memo(EntityReportGrid);
