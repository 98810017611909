import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { RouteUrls } from 'routes/RouteConstants';
import {
    MenuSections,
    menuItemStyle,
    overrideMenuItems,
    sidebarDrawerMenuTitle,
} from 'components/app/layout/AppLayoutUtils';
import { useGetUserData } from 'waypoint-hooks';

interface WorkflowsMenuParams {
    pathname: string;
}

const WorkflowsMenu = ({ pathname }: WorkflowsMenuParams) => {
    const { isAdmin } = useGetUserData();
    return (
        <Menu mode="vertical" className={overrideMenuItems}>
            {sidebarDrawerMenuTitle(MenuSections.Workflows)}
            <Menu.Item
                key="w1"
                style={
                    pathname.includes(RouteUrls.REPORT_MANAGER)
                        ? {}
                        : menuItemStyle(pathname, [
                              RouteUrls.MY_REPORT_TEMPLATE,
                          ])
                }
            >
                <Link to={RouteUrls.MY_REPORT_TEMPLATE}>My Reports</Link>
            </Menu.Item>
            {isAdmin && (
                <Menu.Item
                    key="w2"
                    style={menuItemStyle(pathname, [RouteUrls.REPORT_MANAGER])}
                >
                    <Link to={RouteUrls.REPORT_MANAGER}>Report Manager</Link>
                </Menu.Item>
            )}
        </Menu>
    );
};

export default WorkflowsMenu;
