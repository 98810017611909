import { API_URL } from 'config/constants';
import { SourcePropertyCodes } from 'waypoint-types';

export const getSourcePropertyCode = async (
    entityCode: string | null
): Promise<SourcePropertyCodes[]> => {
    const url = `${API_URL}/properties/${entityCode}/source-property-codes`;

    if (!entityCode) {
        return [];
    }

    const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
    });

    if (!response.ok) {
        throw new Error('FAILED');
    }

    const { data } = await response.json();
    return data;
};
