import url from 'url';

/**
 * adds queryies to existing url
 * @param  {str}  string       url
 * @param  {obj}  queryObj  {key: 'val'}
 */
const addQuery = function (string, queryObj) {
    let output = string;
    output += url.format({ query: queryObj });

    return output;
};

const locationOrigin = function (port) {
    let origin = window.location.origin;

    origin = origin.split(':').slice(0, 2).join(':');

    origin += port ? ':' + port : '';

    return origin;
};

const urlBuilder = {
    addQuery,
    locationOrigin,
};

export default urlBuilder;
