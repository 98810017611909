import { Card, Empty } from 'antd';
import { Sortable } from 'devextreme-react';
import React, { CSSProperties } from 'react';
import { ReorderEvent } from 'devextreme/ui/sortable';

interface SortableCardsProps {
    title: string | JSX.Element;
    onReorder: (event: ReorderEvent) => void;
    children: JSX.Element[];
    extra?: React.ReactNode;
    style?: CSSProperties;
}

export const SortableCards = ({
    title,
    onReorder,
    children,
    extra,
    style,
}: SortableCardsProps) => {
    return (
        <Card
            type="inner"
            title={title}
            style={{ marginBottom: '10px', ...style }}
            bodyStyle={{ padding: 0 }}
            extra={extra}
        >
            <Sortable onReorder={onReorder}>
                {children.length ? children : <Empty />}
            </Sortable>
        </Card>
    );
};
