import PropTypes from 'prop-types';
import React from 'react';
import { assign } from 'lodash';

const Bubble = (props) => {
    const { diameter, color } = props;

    const style = {
        width: `${diameter}px`,
        height: `${diameter}px`,
        display: 'inline-block',
        marginTop: '5px',
        marginRight: '5px',
    };

    return (
        <span style={style}>
            <span
                style={assign(
                    {},
                    {
                        lineHeight: `${diameter}px`,
                        borderRadius: `${diameter / 2}px`,
                        width: `${diameter}px`,
                        height: `${diameter}px`,
                        display: 'inline-block',
                        fontFamily: 'Lato',
                        color: '#fff',
                        textAlign: 'center',
                        backgroundColor: color || '#FFFFF',
                        backgroundSize: 'contain',
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat',
                    },
                    props.style
                )}
            ></span>
        </span>
    );
};

Bubble.propTypes = {
    diameter: PropTypes.number,
    color: PropTypes.string,
    style: PropTypes.object,
};

export default Bubble;
