import React, { useMemo, useCallback } from 'react';
import { css } from 'emotion';
import { LeasesPerformanceOverviewTable } from 'components/leases/expirations';
import { ActionsCard, TextLoadingBar } from 'waypoint-react';
import { EntityDataGroupingKeys } from 'utils/EntityDataGroupingConstants';
import {
    EXPIRATION_DASHBOARD_DEVEXTREME_TABLE_HEIGHT,
    PROPERTY_LEASE_TABLE,
    removeCardHeaderClass,
} from 'components/leases/constants';
import {
    AttributeDefinition,
    Entity,
    PropertyDetailsProps,
    RecurringCharge,
    SavedConfiguration,
} from 'waypoint-types';
import { FocusedRowChangedEvent } from 'devextreme/ui/data_grid';
import { AppFeaturePermissions, AttributeFromAPI } from 'contexts';
import { joinPerformanceOverviewWithTotalCharges } from './utils';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';

const cardTitle = css`
    padding: 8px 0;
    margin-bottom: 0;
`;

const textLoadingStyle = {
    height: '12px',
    width: '150px',
    marginTop: '0px',
};

interface LeasesPerformanceOverviewCardProps {
    attributeSelection: AttributeFromAPI | null;
    attributeDefinitions: AttributeDefinition[] | undefined;
    groupingSelection: EntityDataGroupingKeys;
    isAttributeSelected: boolean;
    data: PropertyDetailsProps[];
    isLeasesPerformanceOverviewLoading: boolean;
    isLeasesPerformanceOverviewError: boolean;
    propertyLeasesTableStorageKey: string;
    recurringCharges: RecurringCharge[] | undefined;
    setRowData: (data: string) => void;
    rowData: string;
    selectedConfiguration: SavedConfiguration | null;
    configKey: string;
    setGridConfig: (config: { [x: string]: any } | null) => void;
    setLocalConfig: (config: { [x: string]: any } | null) => void;
    selectedChargeCode: string[];
    setSelectedChargeCode: (values: string[]) => void;
}

const LeasesPerformanceOverviewCard = ({
    attributeSelection,
    attributeDefinitions,
    groupingSelection,
    isAttributeSelected,
    data,
    isLeasesPerformanceOverviewLoading,
    isLeasesPerformanceOverviewError,
    propertyLeasesTableStorageKey,
    recurringCharges,
    setRowData,
    rowData,
    selectedConfiguration,
    configKey,
    setGridConfig,
    setLocalConfig,
    selectedChargeCode,
    setSelectedChargeCode,
}: LeasesPerformanceOverviewCardProps): JSX.Element => {
    const onFocusedRowChanged = (e: FocusedRowChangedEvent) => {
        const property = e?.row?.data.property_name ?? '';
        setRowData(property);
    };

    const leasesPerformanceOverviewTableConfig = useCallback(
        (
            data: (PropertyDetailsProps | undefined)[],
            dataTestAttribute: string,
        ) => {
            const gridData = joinPerformanceOverviewWithTotalCharges(
                data,
                recurringCharges ?? [],
            );

            const selectedAttribute =
                attributeSelection &&
                attributeDefinitions?.find(
                    (attr) => attr.attribute_code === attributeSelection.key,
                );

            const gridDataWithSelectedAttribute = selectedAttribute
                ? gridData.map((d) => {
                      const values = selectedAttribute.attributeValues?.filter(
                          (attr) => attr.entity_code === d.entity_code,
                      );

                      const valueKey = values?.length
                          ? values.map((val) => val.value).join(', ')
                          : 'Unassigned';

                      return {
                          ...d,
                          [attributeSelection.key]: valueKey
                              ? valueKey ?? 'Unassigned'
                              : 'Unassigned',
                      };
                  })
                : null;

            const basicConfig = {
                wordWrapEnabled: true,
                dataSource: gridDataWithSelectedAttribute ?? gridData,
                height: EXPIRATION_DASHBOARD_DEVEXTREME_TABLE_HEIGHT,
                allowColumnReordering: true,
                columnMinWidth: 100,
                showBorders: true,
                showRowLines: true,
                showColumnLines: true,
                'data-testid': dataTestAttribute,
                onFocusedRowChanged: onFocusedRowChanged,
                hoverStateEnabled: true,
            };

            return {
                ...basicConfig,
            };
        },
        [isAttributeSelected, rowData, recurringCharges, attributeSelection],
    );

    const leasesPerformanceOverviewTableConfiguration = useMemo(
        () => leasesPerformanceOverviewTableConfig(data, PROPERTY_LEASE_TABLE),
        [data, leasesPerformanceOverviewTableConfig],
    );

    return (
        <PermissionedWrapper
            featureKey={AppFeaturePermissions.PerformanceOverview}
            showDisabledView={true}
        >
            <ActionsCard
                title={
                    isLeasesPerformanceOverviewLoading ? (
                        <div className={cardTitle}>
                            <TextLoadingBar style={textLoadingStyle} />
                        </div>
                    ) : (
                        <></>
                    )
                }
                loadingData={isLeasesPerformanceOverviewLoading}
                error={isLeasesPerformanceOverviewError}
                loadingRows={16}
                extra={<></>}
                data-testid="property-lease-details-card"
                className={removeCardHeaderClass}
            >
                <div style={{ paddingBottom: 12 }}>
                    <LeasesPerformanceOverviewTable
                        key={`${selectedConfiguration?.id ?? ''}_${
                            configKey ?? ''
                        }`}
                        attributeSelected={
                            attributeSelection
                                ? { ...attributeSelection }
                                : undefined
                        }
                        groupingSelection={groupingSelection}
                        storageKey={propertyLeasesTableStorageKey}
                        config={leasesPerformanceOverviewTableConfiguration}
                        recurringCharges={recurringCharges ?? []}
                        idTable={PROPERTY_LEASE_TABLE}
                        selectedConfiguration={selectedConfiguration}
                        setGridConfig={setGridConfig}
                        setLocalConfig={setLocalConfig}
                        selectedChargeCode={selectedChargeCode}
                        setSelectedChargeCode={setSelectedChargeCode}
                    />
                </div>
            </ActionsCard>
        </PermissionedWrapper>
    );
};

export default LeasesPerformanceOverviewCard;
