import PropTypes from 'prop-types';
import React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';

const WaypointTooltip = (props) => {
    const { disabled, message, children, delay } = props;
    const placement = props.placement || 'right';

    const tooltip = (
        <Popover className="waypoint-popover">
            <div style={{ color: 'white' }}>{message}</div>
        </Popover>
    );

    return (
        <OverlayTrigger
            trigger={!disabled && ['hover', 'focus']}
            placement={placement}
            overlay={tooltip}
            delay={delay}
        >
            {children}
        </OverlayTrigger>
    );
};

WaypointTooltip.propTypes = {
    message: PropTypes.string,
    placement: PropTypes.string,
    disabled: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

export default WaypointTooltip;
