import { ComparisonFilterPayload } from 'components/financials/comparative-income-statement/banner/ComparisonSelectionsBannerUtils';
import { API_URL } from 'config/constants';
import { SelectedDataLevel } from 'waypoint-types';
import { AccountGraphRaw } from 'waypoint-types/account-graph/types';

interface GetComparativeIncomeStatementParams {
    entityCodes: string[];
    periods: ComparisonFilterPayload[] | null;
    download?: boolean;
    isHideNull?: boolean;
    reportMetadataId: string | null;
    totalRSF?: number;
    totalUnits?: number;
    showCurrentPeriod?: boolean;
    selectedDataLevel: SelectedDataLevel;
}

const getComparativeIncomeStatementResponse = async function ({
    entityCodes,
    periods,
    download = false,
    isHideNull,
    reportMetadataId,
    totalRSF,
    totalUnits,
    showCurrentPeriod,
    selectedDataLevel,
}: GetComparativeIncomeStatementParams) {
    return fetch(`${API_URL}/comparative-income-statement`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            Accept: download ? 'text/csv' : '*/*',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            entity_codes: entityCodes,
            periods,
            is_hide_null: isHideNull ?? false,
            report_metadata_id: reportMetadataId,
            total_rsf: totalRSF,
            total_units: totalUnits,
            show_current_period: showCurrentPeriod ?? false,
            selected_data_level: selectedDataLevel,
        }),
    });
};

export const getComparativeIncomeStatement = async (
    params: GetComparativeIncomeStatementParams
): Promise<AccountGraphRaw> => {
    const response = await getComparativeIncomeStatementResponse(params);

    const { data } = await response.json();
    return data.children;
};

export const getComparativeIncomeStatementDownload = async (
    params: GetComparativeIncomeStatementParams
): Promise<Response> => {
    return getComparativeIncomeStatementResponse(params);
};
