import { API_URL } from 'config/constants';

export const submitBudget = async (
    entityCode: string | null,
    budgetUploadId: string | null
): Promise<{ message: string }> => {
    if (!entityCode) {
        return { message: 'Entity code is required' };
    }
    const url = `${API_URL}/budget-upload/entity-budget/${entityCode}/${budgetUploadId}/submit`;
    const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
    });

    const res = await response.json();

    if (!response.ok) {
        throw new Error(res.error);
    }
    const { data } = res;

    return data;
};
