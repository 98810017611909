import React, { useState } from 'react';
import { LocalReportSettings, Report, Settings } from 'waypoint-types';
import { Tabs } from 'antd';
import { ReportApprovalSettings } from './ReportApprovalSettings';
import { SettingsTabKeys } from './constants';
import { ReportGeneralSettings } from './ReportGeneralSettings';

interface ReportSettingsProps {
    report?: Report;
    setSettings: (type: string, properties: Settings) => void;
    settings: LocalReportSettings;
    setEnabledSetting: (type: string, enabled: boolean) => void;
    onSaveSettings: (redirectOnUpdate: boolean) => void;
    isWorkflowSteps: boolean;
}

const ReportSettings = ({
    report,
    setSettings,
    settings,
    setEnabledSetting,
    onSaveSettings,
    isWorkflowSteps,
}: ReportSettingsProps) => {
    const [activeTabKey, setActiveTabKey] = useState<string>(
        SettingsTabKeys.General,
    );

    const tabList = [
        {
            key: SettingsTabKeys.General,
            label: 'General',
            children: (
                <ReportGeneralSettings
                    report={report}
                    settings={settings}
                    setSettings={setSettings}
                    setEnabledSetting={setEnabledSetting}
                />
            ),
        },
        {
            key: SettingsTabKeys.Approvals,
            label: 'Approvals',
            children: (
                <ReportApprovalSettings
                    settings={settings}
                    setSettings={setSettings}
                />
            ),
        },
    ];

    const onTabChange = (key: string) => {
        setActiveTabKey(key);
    };

    return (
        <Tabs
            tabPosition={'left'}
            items={tabList}
            activeKey={activeTabKey}
            onTabClick={(key) => onTabChange(key)}
            data-testid="report-settings-detail-card"
        >
            {tabList.find((tab) => tab.key === activeTabKey)}
        </Tabs>
    );
};

export default ReportSettings;
