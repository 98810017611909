/**
 *
 * @param {array} value - tuple of moment objects
 * @returns {string} a label for a period in the form of MM/YYYY - MM/YYYY (ex. 01/2021 - 03/2021)
 */
const formatDateRangeForCopy = (value) => {
    if (!Array.isArray(value)) {
        return value.format('MM/YYYY');
    }

    return `${value[0].format('MM/YYYY')} - ${value[1].format('MM/YYYY')}`;
};

export default formatDateRangeForCopy;
