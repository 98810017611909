import PropTypes from 'prop-types';
import React from 'react';
import { pick, isEmpty } from 'lodash';
import { ChartTableSwitchCard, DownloadButton } from 'waypoint-react';
import { rentByIndustryCardTitle } from 'components/leases/tenants/constants';
import RentByIndustryTable from 'components/leases/tenants/cards/RentByIndustry/Table';
import RentByIndustryChart from 'components/leases/tenants/cards/RentByIndustry/Chart';
import { exportCSV, getRentTableData } from 'components/leases/tenants/utils';

const downloadCSV = (tableData) => {
    const csvData = tableData.map((item) => ({
        Industry: item.industry,
        'Total Gross Amount': item.grossRent,
        'Total Ann. In-Place Rent/sq ft': item.totalAnnualInPlaceRentSqFt,
    }));
    exportCSV(csvData, 'Industries by Total Annual In-Place Rent');
};

const RentByIndustryCard = ({ tenants, isLoading, ...props }) => {
    const gridProps = pick(props, ['data-grid', 'style']);
    const tableData = tenants && getRentTableData(tenants);

    return (
        <ChartTableSwitchCard
            {...gridProps}
            extra={
                <DownloadButton
                    disabled={isLoading || isEmpty(tenants)}
                    onClick={() => downloadCSV(tableData)}
                />
            }
            subheading={<span />}
            title={rentByIndustryCardTitle}
            empty={isEmpty(tenants)}
            loading={isLoading}
            chart={<RentByIndustryChart data={tenants} />}
            table={<RentByIndustryTable data={tableData} />}
        />
    );
};

RentByIndustryCard.propTypes = {
    tenants: PropTypes.array,
    isLoading: PropTypes.bool,
};

export default RentByIndustryCard;
