export const useGetQueryParam = (key: string): string | null => {
    const urlParts = window.location.hash.split('?');

    if (urlParts.length < 2) {
        return null;
    }

    return new URLSearchParams(urlParts[1]).get(key);
};

export const clearQueryParam = (key: string) => {
    const urlParts = window.location.hash.split('?');

    if (urlParts.length <= 1) {
        return;
    }

    const queryParams = new URLSearchParams(urlParts[1]);
    queryParams.delete(key);

    const remainingQueryParamString = queryParams.toString();

    // if there are no query params left, just return
    // the base url without the question mark
    window.location.hash = remainingQueryParamString.length
        ? `${urlParts[0]}?${remainingQueryParamString}`
        : urlParts[0];
};

export const setQueryParam = (key: string, value: string) => {
    const urlParts = window.location.hash.split('?');

    if (urlParts.length <= 1) {
        return;
    }

    const queryParams = new URLSearchParams(urlParts[1]);
    queryParams.set(key, value);

    window.location.hash = `${urlParts[0]}?${queryParams.toString()}`;
};
