import { API_URL } from 'config/constants';
import { CommentMention } from 'waypoint-types';

/**
 * @param {string} commentThreadId - Comment thread id
 */
const getCommentThreadAccountMentions = async (
    commentThreadId: string
): Promise<CommentMention[]> => {
    const response = await fetch(
        `${API_URL}/comments/${commentThreadId}/account-mentions`,
        {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        }
    );

    if (!response.ok) {
        throw new Error('FAILED');
    }

    const { data } = await response.json();
    return data;
};

export default getCommentThreadAccountMentions;
