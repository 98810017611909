import React from 'react';

// TODO: Elevate to ~/config/contants.js ?
export const CURRENCY = 'currency';
export const PERCENTAGE = 'percentage';
export const INTEGER = 'integer';

export const PERFORMANCE_OVERVIEW_ERROR_TEXT = 'There are no properties';
export const PERFORMANCE_OVERVIEW_EMPTY_TEXT =
    'Click on settings/gear icon to configure this card for the first-time setup';
export const PROPERTY_ATTRIBUTES_TITLE = 'Property Attributes';
export const PERFORMANCE_OVERVIEW_TITLE = 'Diversification';
export const PROPERTY_VALUE_TITLE = 'Portfolio Summary';
export const PROPERTY_VALUE_ERROR_TEXT = 'There was an error with the request';
export const PROPERTY_VALUE_EMPTY_TEXT =
    'Click on settings/gear icon to configure this card for the first-time setup';

export const KPI_LIST = {
    gross_value_managed: { label: 'Gross Value (Managed)', type: CURRENCY },
    net_value_managed: { label: 'Net Value (Managed)', type: CURRENCY },
    debt_managed: { label: 'Debt (Managed)', type: CURRENCY },
    gross_value_100: { label: 'Gross Value (100%)', type: INTEGER },
    net_value_100: { label: 'Net Value (100%)', type: INTEGER },
    debt_100: { label: 'Debt (100%)', type: INTEGER },
    gross_value_own: { label: 'Gross Value (Own)', type: CURRENCY },
    net_value_own: { label: 'Net Value (Own)', type: CURRENCY },
    debt_own: { label: 'Debt (Own)', type: CURRENCY },
    gross_value_client: { label: 'Gross Value (Client)', type: CURRENCY },
    net_value_client: { label: 'Net Value (Client)', type: CURRENCY },
    debt_client: { label: 'Debt (Client)', type: CURRENCY },
    sf_own: { label: 'SF (Own)', type: INTEGER },
    sf_occupied_own: { label: 'SF Occupied (Own)', type: INTEGER },
    sf_100: { label: 'Square Footage (100%)', type: INTEGER },
    entity_code: { label: 'Count', type: INTEGER },
};

export const GROUP_BY_LIST = [
    { label: 'Province', value: 'province' },
    { label: 'Asset Type', value: 'asset_type' },
    { label: 'Strategy', value: 'strategy' },
    { label: 'Property Manager', value: 'propertymanager' },
    { label: 'Asset Manager Name', value: 'assetmanagername' },
    { label: 'Source', value: 'source' },
    { label: 'Structure', value: 'structure' },
    { label: 'Investment Vehicle', value: 'investmentvehicle' },
];

export const PERFORMANCE_OVERVIEW_CONFIG = 'PERFORMANCE_OVERVIEW_CONFIG';

export const TEST_DATA_MULTISELECT = [];
export const NO_FILTER_APPLIED =
    'No filters applied yet. Select an attribute from a field adding a filter below.';

export const PROPERTY_ATTRIBUTES_ERROR_TEXT =
    'There was an error with the request';
export const PERFORMANCE_OVERVIEW_KEY = '1';
export const PROPERTY_ATTRIBUTES_KEY = '2';
export const PROPERTY_VALUE_KEY = '3';

export const DELETE_TOOLTIP_TEXT = <span>Remove</span>;

export const KEY_TO_TITLE_MAP = {
    [PERFORMANCE_OVERVIEW_KEY]: PERFORMANCE_OVERVIEW_TITLE,
    [PROPERTY_ATTRIBUTES_KEY]: PROPERTY_ATTRIBUTES_TITLE,
    [PROPERTY_VALUE_KEY]: PROPERTY_VALUE_TITLE,
};

export const TABLE_OFFSET = 225;
export const SEGMENTATION_CARD_OFFSET = 140;
export const CHART_HEIGHT_OFFSET = 400;
export const CHART_WIDTH_OFFSET = 200;
