import { createStore, applyMiddleware, compose } from 'redux';
import { enableBatching } from 'redux-batched-actions';
import thunk from 'redux-thunk';
import { createResponsiveStoreEnhancer } from 'redux-responsive';
import { routerMiddleware } from 'react-router-redux';
import { queryMiddleware } from 'redux-query';
import { handle401 } from '../utils/authentication';
import { rootReducer } from './rootReducer';
import { FixAnyType } from '../waypoint-types';

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

export default function configureStore(history: FixAnyType, initialState = {}) {
    const middleware = [
        thunk,
        queryMiddleware(
            (state: FixAnyType) => state.queries,
            (state: FixAnyType) => state.entities,
        ),
        routerMiddleware(history),
        handle401,
    ];

    const enhancers = [
        applyMiddleware(...middleware),
        createResponsiveStoreEnhancer({
            throttleTime: 1000,
            calculateStateInitially: true,
        }),
    ];

    const composeEnhancers =
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(
        enableBatching(rootReducer as FixAnyType),
        initialState,
        composeEnhancers(...enhancers),
    );

    // if (module.hot) {
    //     // enable hot reload
    //     module.hot.accept('state/rootReducer', () =>
    //         store.replaceReducer(rootReducer)
    //     );
    // }

    return store;
}
