import React, { useState } from 'react';
import { useRecurringCharges, useRentRoll } from 'waypoint-hooks';
import { buildWeightedAverageDevExtremeConfig } from 'waypoint-utils/dev-extreme/weighted-averages';
import { SelectedDataLevel } from 'waypoint-types';

interface UnitMix {
    entityCode: string;
    selectedDataLevel: SelectedDataLevel;
}

export const useUnitMix = ({ entityCode, selectedDataLevel }: UnitMix) => {
    const [unitMix] = useState({});

    const {
        data: recurringChargesData,
        isLoading: isLoadingRecurringCharges,
        isError: isErrorRecurringCharges,
    } = useRecurringCharges([entityCode], selectedDataLevel);

    const {
        data: rentRoll,
        isLoading: isRentRollLoading,
        isError: isRentRollError,
    } = useRentRoll([entityCode], selectedDataLevel);

    const { calculateCustomSummary } = buildWeightedAverageDevExtremeConfig(
        [
            {
                metricKey: 'avg_sf',
                scaleKey: 'number_of_units',
                options: {
                    valueFormat: { type: 'number', precision: 1 },
                },
            },
            {
                metricKey: 'average_total_monthly',
                scaleKey: 'number_of_units',
                options: {
                    valueFormat: { type: 'number', precision: 1 },
                },
            },
            {
                metricKey: 'average_total_monthly_psf',
                scaleKey: 'number_of_units',
                options: {
                    valueFormat: { type: 'number', precision: 1 },
                },
            },
        ],
        {
            displayFormat: '{0}',
        }
    );

    return {
        unitMix,
        calculateCustomSummary,
        isLoading: isLoadingRecurringCharges || isRentRollLoading,
        isError: isErrorRecurringCharges || isRentRollError,
        recurringChargesData,
        rentRoll,
    };
};
