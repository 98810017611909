import React, { PropsWithChildren, useEffect, useState } from 'react';
import { PermissionsGroupsContext } from './PermissionGroupsContext';
import { Dictionary } from 'ts-essentials';
import { AppFeaturePermissions } from './constants';

interface PermissionsGroupsProviderProps {
    userPermissions: string[];
}

const PermissionsGroupsProvider = ({
    children,
    userPermissions,
}: PropsWithChildren<PermissionsGroupsProviderProps>) => {
    const [permissions, setPermissions] = useState<string[]>([]);
    const [finanicialMenuSettings, setFinancialMenuSettings] = useState<
        Dictionary<boolean>
    >({});
    const [analyticsMenuSettings, setAnalyticsMenuSettings] = useState<
        Dictionary<boolean>
    >({});
    const [leasingMenuSettings, setLeasingMenuSettings] = useState<
        Dictionary<boolean>
    >({});
    const [planningMenuSettings, setPlanningMenuSettings] = useState<
        Dictionary<boolean>
    >({});
    const [workflowMenuSettings, setWorkflowMenuSettings] = useState<
        Dictionary<boolean>
    >({});

    const getFeaturePermissionsByMenuSection = (
        featurePermissions: string[]
    ) => {
        const financials = {
            showIncomeStatement: featurePermissions.includes(
                AppFeaturePermissions.IncomeStatement
            ),
            showBalanceSheet: featurePermissions.includes(
                AppFeaturePermissions.BalanceSheet
            ),
            showCrosstab: featurePermissions.includes(
                AppFeaturePermissions.Crosstab
            ),
            showVarianceReports: featurePermissions.includes(
                AppFeaturePermissions.VarianceReporting
            ),
        };
        const analytics = {
            showBalanceSheetOverview: featurePermissions.includes(
                AppFeaturePermissions.BalanceSheetOverview
            ),
            showRanking: featurePermissions.includes(
                AppFeaturePermissions.Ranking
            ),
            showFinancialOverview: featurePermissions.includes(
                AppFeaturePermissions.FinancialOverview
            ),
            showPortfolioSummary: featurePermissions.includes(
                AppFeaturePermissions.PortfolioSummary
            ),
        };
        const leasing = {
            showPerformanceOverview: featurePermissions.includes(
                AppFeaturePermissions.PerformanceOverview
            ),
            showRentRoll: featurePermissions.includes(
                AppFeaturePermissions.RentRoll
            ),
            showExpirationSchedule: featurePermissions.includes(
                AppFeaturePermissions.ExpirationSchedule
            ),
            showTenants: featurePermissions.includes(
                AppFeaturePermissions.Tenants
            ),
            showAgedReceivables: featurePermissions.includes(
                AppFeaturePermissions.AgedReceivables
            ),
        };
        const planning = {
            showCapitalProjects: featurePermissions.includes(
                AppFeaturePermissions.CapitalProjects
            ),
            showPrioritiesAndObjectives: featurePermissions.includes(
                AppFeaturePermissions.PrioritiesAndObjectives
            ),
            showServiceContracts: featurePermissions.includes(
                AppFeaturePermissions.Contracts
            ),
            showHoldSell: featurePermissions.includes(
                AppFeaturePermissions.HoldSell
            ),
            showAttributes: featurePermissions.includes(
                AppFeaturePermissions.Attributes
            ),
        };
        const workflows = {
            showMyReports: featurePermissions?.includes(
                AppFeaturePermissions.MyReports
            ),
        };

        return {
            financials,
            analytics,
            leasing,
            planning,
            workflows,
        };
    };

    useEffect(() => {
        setPermissions(userPermissions);
        const { financials, analytics, leasing, planning, workflows } =
            getFeaturePermissionsByMenuSection(userPermissions);
        setFinancialMenuSettings(financials);
        setAnalyticsMenuSettings(analytics);
        setLeasingMenuSettings(leasing);
        setPlanningMenuSettings(planning);
        setWorkflowMenuSettings(workflows);
    }, [userPermissions]);

    return (
        <PermissionsGroupsContext.Provider
            value={{
                featurePermissions: permissions,
                finanicialMenuSettings,
                analyticsMenuSettings,
                leasingMenuSettings,
                planningMenuSettings,
                workflowMenuSettings,
                isPermissionGroupsLoading: false,
                isPermissionGroupsNetworkError: false,
            }}
        >
            {children}
        </PermissionsGroupsContext.Provider>
    );
};

export default PermissionsGroupsProvider;
