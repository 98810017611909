import React, { Component } from 'react';
import { css } from 'emotion';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'components/style/Card';
import { PermissionsCard } from 'components/settings/permissions';
import { getUserEntityPermission } from 'waypoint-requests';
import { message } from 'antd';
import LoadingSpinner from 'components/style/LoadingSpinner';

const cardStyle = css`
    margin: 0 auto;
`;
const pageContainer = css`
    background-color: #ededed;
    margin: 0;
    padding: 22px 20px 20px 16px;
    overflow: visible;
    position: relative;
    min-height: 85%;
`;
const header = css`
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 30px;
    color: #5e5e5e;
`;
const spinnerStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
};

class Permissions extends Component {
    state = {
        loading: true,
        userEntityPermissions: null,
        userPermissionsError: false,
    };

    componentDidMount = () => {
        this.fetchUserEntityPermissions();
    };

    fetchUserEntityPermissions() {
        const { user } = this.props;
        this.setState(
            {
                loading: true,
                userEntityPermissions: null,
                userPermissionsError: false,
            },
            async () => {
                try {
                    const data = await getUserEntityPermission(user.id);
                    this.setState({ userEntityPermissions: data });
                } catch (e) {
                    message.error(
                        'Request Failed',
                        'An error occurred while requesting permissions',
                    );
                    this.setState({ userPermissionsError: true });
                } finally {
                    this.setState({ loading: false });
                }
            },
        );
    }

    render() {
        const { user, users } = this.props;
        const { loading, userEntityPermissions, userPermissionsError } =
            this.state;

        if (loading) {
            return (
                <div>
                    <LoadingSpinner style={spinnerStyle} />
                </div>
            );
        }

        return (
            <div className={pageContainer}>
                <h1 className={header}>User Permissions</h1>
                <Card className={cardStyle} fluid large>
                    {!userPermissionsError && (
                        <PermissionsCard
                            user={user}
                            users={users}
                            permissions={userEntityPermissions}
                        />
                    )}
                    {userPermissionsError && (
                        <span>Error loading permissions</span>
                    )}
                </Card>
            </div>
        );
    }
}

const mapState = (state, props) => {
    const { match } = props;
    const id = match.params.id;
    const users = state.users;
    const user = users[id];

    return {
        user,
        users,
    };
};

export default withRouter(connect(mapState)(Permissions));
