import PropTypes from 'prop-types';
import React from 'react';
import sharedStyles from 'components/settings/shared.module.css';
import LoadingSpinner from 'components/style/LoadingSpinner';
import Card from 'components/style/Card';
import RadioOptions from 'components/settings/client/RadioOptionsContainer';
import { css } from 'emotion';

const card = css`
    margin: 0 auto !important;
`;

const ClientSettings = ({
    isLoading,
    isWaypointAssociate,
    isWaypointSysAdmin,
    ...props
}) => {
    if (!(isWaypointSysAdmin || isWaypointAssociate)) {
        props.history.replace('/');
        return null;
    }

    return isLoading ? (
        <LoadingSpinner />
    ) : (
        <div className={sharedStyles.pageContainer}>
            <h1 className={sharedStyles.majorHeading}>Client Settings</h1>
            <Card className={card} large>
                <h1
                    className={sharedStyles.majorHeading}
                    style={{ marginBottom: '12px' }}
                >
                    Number Format
                </h1>
                <p color="#ccc">
                    Modify display format of dollar values and negative numbers
                    for all users.
                </p>
                <hr />
                <RadioOptions />
            </Card>
        </div>
    );
};

ClientSettings.propTypes = {
    children: PropTypes.node,
    isLoading: PropTypes.bool,
    history: PropTypes.object,
    isWaypointAssociate: PropTypes.bool,
    isWaypointSysAdmin: PropTypes.bool,
};

export default ClientSettings;
