import { safeDivision } from 'waypoint-utils';
import {
    APPROVED,
    COMPARATIVE_INCOME_STATEMENT_URL,
    IN_PROGRESS,
    OPEN,
    READY_FOR_REVIEW,
    THRESHOLD_FIXED_AND_PERCENTAGES,
    THRESHOLD_FIXED_ONLY,
    THRESHOLD_FIXED_OR_PERCENTAGES,
    THRESHOLD_PERCENTAGES_ONLY,
    WORKFLOW_ASSIGNEE,
    WORKFLOW_REVIEWER,
} from 'components/financials/comparative-income-statement/constants';
import { Dictionary } from 'ts-essentials';
import { formatMoney } from 'utils/formatters';
import {
    AttributeValue,
    EntityReportWorkflowStatus,
    MentionableDataSource,
    PropertyType,
    ReportMetadata,
    ThresholdRule,
    VarianceRule,
    WorkflowRole,
} from 'waypoint-types';
import { format } from 'date-fns';
import { BALANCE_SHEET_URL } from 'components/financials/balanceSheet/constants';
import { DASH_DASH } from 'config/constants';
import theme from 'config/theme';
import { stringSort } from 'utils/tables/sorters';
import { randomColor } from 'components/analytics/portfolioSegmentation/utils';

export const doughnutChartStyle = {
    enableRotation: '1',
    showLabels: '0',
    showValues: '0',
    showBorder: false,
    showLegend: '1',
    borderColor: theme.colors.grays.medium,
    showPercentageValues: '0',
    showZeroPies: '1',
    enableMultiSlicing: '0',
    enableSmartLabels: '1',
    skipOverlapLabels: '1',
    manageLabelOverflow: '1',
    useEllipsesWhenOverflow: '1',
    pieRadius: '70%',
    doughnutRadius: '60',
    baseFontColor: theme.colors.grays.dark,
    xAxisNameFontColor: theme.colors.grays.dark,
    yAxisNameFontColor: theme.colors.grays.dark,
    startingAngle: '90',
    decimals: '2',
    alignCaptionWithCanvas: '1',
    showHoverEffect: '1',
    plotHoverEffect: '1',
    legendPosition: 'absolute',
    legendNumColumns: '1',
    legendItemFontSize: 12,
    plotHighlightEffect: 'fadeout',
    exportFormats: 'PNG|PDF|JPG|SVG',
};

export const barChartStyle = {
    exportFileName: 'Approved Percentage by Threshold Rule',
    baseFontColor: theme.colors.grays.medium,
    xAxisNameFontColor: theme.colors.grays.medium,
    yAxisNameFontColor: theme.colors.grays.medium,
    useEllipsesWhenOverflow: '1',
    paletteColors: theme.colors.barCharts,
    exportFormats: 'PNG|PDF|JPG|SVG',
    maxLabelWidthPercent: '21',
    showToolTipShadow: '0',
    toolTipBorderColor: '#C1C1C1',
    tooltipPadding: '10',
    tooltipborderradius: '5',
    animation: '0',
    showBorder: false,
    numberSuffix: '%',
    showLegend: '1',
    yAxisMaxValue: '100',
    numDivLines: '12',
    legendItemFontSize: 12,
    borderColor: theme.colors.grays.medium,
};

export const scroll2dChartStyle = {
    exportFileName: 'Report by Assignee/Reviewer',
    baseFontColor: theme.colors.grays.medium,
    xAxisNameFontColor: theme.colors.grays.medium,
    yAxisNameFontColor: theme.colors.grays.medium,
    useEllipsesWhenOverflow: '1',
    paletteColors: theme.colors.barCharts[0],
    exportFormats: 'PNG|PDF|JPG|SVG',
    maxLabelWidthPercent: '20',
    showToolTipShadow: '0',
    toolTipBorderColor: '#C1C1C1',
    tooltipPadding: '10',
    tooltipborderradius: '5',
    numDivLines: '3',
    animation: '0',
};

export interface TransformedDoughnutChartData {
    value: number;
    label: string;
    color: string;
}

export interface MappedDoughnutChartData {
    metric: string;
    value: number;
    color?: string;
}

export interface Scroll2dChartData {
    categories: { label: string }[];
    data: { value: number }[];
}

export const dataToCharts = (
    data: MappedDoughnutChartData[],
    totalReports: number
) => {
    return data.map((d) => {
        const colorCode = d.color ?? `#${randomColor()}`;
        const percentage = safeDivision(d.value, totalReports) * 100;
        return {
            value: d.value,
            label: `${d.metric} (${percentage.toFixed(2)}%)`,
            color: colorCode,
        };
    });
};

export type RoleType = 'assignee' | 'reviewer';
export interface TableRow {
    property: string;
    threshold_rule: string;
    financial_year_ending: string;
    reporting_period: string;
    status: string;
    assignee: { userName: string; userId: number }[];
    assigneeNames: string;
    reviewer: { userName: string; userId: number }[];
    reviewerNames: string;
    last_updated_by: string;
    last_updated: String;
    report_link: string;
}

export const buildThresholdRuleText = (thresholdRule: any) => {
    const thresholdPercent = thresholdRule.percent_variance_amount * 100;
    const thresholdAmount = formatMoney(thresholdRule.fixed_variance_amount);
    const thresholdPeriodicity = ['ytd', 'qtd', 'mtd'].includes(
        thresholdRule.periodicity
    )
        ? thresholdRule.periodicity?.toUpperCase()
        : thresholdRule.periodicity;
    const type = thresholdRule.amount_type;
    if (type === THRESHOLD_FIXED_AND_PERCENTAGES) {
        return `${thresholdPeriodicity} (${thresholdPercent}% AND ${thresholdAmount})`;
    }

    if (type === THRESHOLD_FIXED_OR_PERCENTAGES) {
        return `${thresholdPeriodicity} (${thresholdPercent}% OR ${thresholdAmount})`;
    }

    if (type === THRESHOLD_PERCENTAGES_ONLY) {
        return `${thresholdPeriodicity} (${thresholdPercent}%)`;
    }

    if (type === THRESHOLD_FIXED_ONLY) {
        return `${thresholdPeriodicity} (${thresholdAmount})`;
    }

    return DASH_DASH;
};

export const workflowStatuses: Dictionary<string> = {
    [OPEN]: 'Open',
    [IN_PROGRESS]: 'In Progress',
    [READY_FOR_REVIEW]: 'Ready for Review',
    [APPROVED]: 'Approved',
};

export const workflowStatusColors = {
    Open: theme.colors.workflowReportStatus.open,
    'In Progress': theme.colors.workflowReportStatus.in_progress,
    'Ready for Review': theme.colors.workflowReportStatus.ready_for_review,
    Approved: theme.colors.workflowReportStatus.approved,
};

export const getUsernameById = (
    userId: number,
    userWorkflowRoleOptions: MentionableDataSource[]
) => {
    const rawUsername =
        userWorkflowRoleOptions.find((u) => u.id === Number(userId))?.text ??
        DASH_DASH;
    return rawUsername
        .split(' ')
        .filter((n) => n.length > 0)
        .join(' ');
};

export const buildTableData = (
    thresholdRules: ThresholdRule[],
    properties: Dictionary<PropertyType>,
    reportMetadata: ReportMetadata[],
    workflowAssignees: Dictionary<WorkflowRole[]> | null,
    workflowReviewers: Dictionary<WorkflowRole[]> | null,
    periodEnd: string,
    userWorkflowRoleOptions: MentionableDataSource[],
    entityFiscalYearEndDates: AttributeValue[]
) => {
    const getEntityName = (entityCode: string) => {
        return properties[`PropertySlim_${entityCode}`]?.display_name ?? '';
    };

    const getReportMetadataForThresholdRule = (
        entityCode: string,
        varianceRule: VarianceRule
    ) => {
        const metadataForEntityCode = reportMetadata
            .filter((rm) => rm.reference_id === entityCode)
            .filter((rm) => rm.workflowStatus?.length);
        return metadataForEntityCode.find(
            (m) =>
                JSON.parse(m.filter_raw_json)[0].periodicity ===
                varianceRule.periodicity
        );
    };

    const getUserList = (entityCode: string, userRole: string) => {
        if (userRole === WORKFLOW_ASSIGNEE) {
            return workflowAssignees
                ? workflowAssignees[entityCode]?.map((wa) => ({
                      userName: wa.userName,
                      userId: wa.userId,
                      profile_image_url:
                          userWorkflowRoleOptions?.find(
                              (u) => u.id === wa.userId
                          )?.profile_image_url ?? null,
                  }))
                : [];
        }
        return workflowReviewers
            ? workflowReviewers[entityCode]?.map((wr) => ({
                  userName: wr.userName,
                  userId: wr.userId,
                  profile_image_url:
                      userWorkflowRoleOptions?.find((u) => u.id === wr.userId)
                          ?.profile_image_url ?? null,
              }))
            : [];
    };

    const getUsernameList = (entityCode: string, userRole: string) => {
        if (userRole === WORKFLOW_ASSIGNEE) {
            return workflowAssignees
                ? workflowAssignees[entityCode]
                      ?.map((wa) => wa.userName)
                      .join(', ')
                : '';
        }
        return workflowReviewers
            ? workflowReviewers[entityCode]?.map((wr) => wr.userName).join(', ')
            : '';
    };

    const getLastUpdatedDisplayValue = (
        lastUpdatedByUserId: number | null,
        workflowStatus: EntityReportWorkflowStatus[] | null
    ) => {
        if (
            !lastUpdatedByUserId ||
            !workflowStatus ||
            !workflowStatus[0]?.timestamps?.updated_at
        ) {
            return DASH_DASH;
        }
        return format(
            new Date(workflowStatus[0].timestamps.updated_at),
            'MMM-dd-yyyy'
        );
    };

    const getFiscalYearEndDate = (entityCode: string) => {
        const entityFiscalYearEndDate = entityFiscalYearEndDates.find(
            (date) => date.entity_code === entityCode
        );

        if (!entityFiscalYearEndDate?.value) {
            return 'December 31';
        }

        return format(new Date(entityFiscalYearEndDate.value), 'MMMM dd');
    };

    const tableData: TableRow[] = [];
    const baseUrl = window.location.href.split('/financials')[0];
    for (const t of thresholdRules) {
        const entityName = getEntityName(t.entity_code);
        if (t.variance_rules?.length) {
            for (const vr of t.variance_rules) {
                const thresholdRuleReportMetadata =
                    getReportMetadataForThresholdRule(t.entity_code, vr);

                const reportPage = thresholdRuleReportMetadata
                    ? thresholdRuleReportMetadata.report_type ===
                      'comparative-income'
                        ? COMPARATIVE_INCOME_STATEMENT_URL
                        : BALANCE_SHEET_URL
                    : null;

                const lastUpdatedByUserId =
                    thresholdRuleReportMetadata?.workflowStatus
                        ? thresholdRuleReportMetadata.workflowStatus[0]
                              .last_updated_by ?? null
                        : null;
                const tableRow = {
                    entityCode: t.entity_code,
                    property: entityName,
                    threshold_rule: buildThresholdRuleText(vr),
                    financial_year_ending: getFiscalYearEndDate(t.entity_code),
                    reporting_period: vr.periodicity?.toUpperCase() ?? '',
                    period_ending: format(new Date(periodEnd), 'MMM-yy'),
                    status: thresholdRuleReportMetadata?.workflowStatus
                        ? workflowStatuses[
                              thresholdRuleReportMetadata.workflowStatus[0]
                                  .report_status as string
                          ]
                        : 'Open',
                    assignee: getUserList(t.entity_code, WORKFLOW_ASSIGNEE),
                    assigneeNames: getUsernameList(
                        t.entity_code,
                        WORKFLOW_ASSIGNEE
                    ),
                    reviewer: getUserList(t.entity_code, WORKFLOW_REVIEWER),
                    reviewerNames: getUsernameList(
                        t.entity_code,
                        WORKFLOW_REVIEWER
                    ),
                    last_updated_by: lastUpdatedByUserId
                        ? getUsernameById(
                              lastUpdatedByUserId,
                              userWorkflowRoleOptions
                          )
                        : DASH_DASH,
                    last_updated: getLastUpdatedDisplayValue(
                        lastUpdatedByUserId,
                        thresholdRuleReportMetadata?.workflowStatus ?? null
                    ),
                    report_link:
                        thresholdRuleReportMetadata && reportPage
                            ? `${baseUrl}/financials/${reportPage}?report_metadata_id=${thresholdRuleReportMetadata.id}`
                            : DASH_DASH,
                };
                tableData.push(tableRow);
            }
        }
    }
    return tableData.sort((a: TableRow, b: TableRow) =>
        stringSort(b.property, a.property)
    );
};

export const currentUserHasReviewRole = (
    workflowRoleUsers: Dictionary<WorkflowRole[]> | null,
    currentUserId: number | undefined
) => {
    const users = workflowRoleUsers ?? {};
    return Object.keys(users).filter((key) =>
        users[key].some((item) => item.userId === currentUserId)
    );
};

export const isUserInReviewers = (
    workflowReviewers: Dictionary<WorkflowRole[]> | null,
    currentUserId: number | undefined
): boolean => {
    const uniqueUserIds = Object.values(workflowReviewers ?? {}).reduce(
        (acc, users) => {
            users.forEach((user) => acc.add(user.userId));
            return acc;
        },
        new Set<number>()
    );

    return uniqueUserIds.has(currentUserId ?? 0);
};

export const workflowPermissionsDict = {
    [WORKFLOW_ASSIGNEE]: ['isAdmin', 'isReviewer'],
    [WORKFLOW_REVIEWER]: ['isAdmin'],
};
