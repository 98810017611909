import {
    CreateEntityProfileCardNote,
    EntityProfileCard,
    EntityProfileCardYearMetadata,
    UpdateEntityProfileCardNote,
} from 'waypoint-types/properties/types';
import { Button, Col, List, message, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { createNote, deleteNote, updateNote } from 'waypoint-requests';
import { Note, NoteReferenceType } from 'waypoint-types/notes/types';
import { Input } from 'antd';
import { connect, RootStateOrAny } from 'react-redux';
import { selectCurrentUser } from 'state/user/selectors';
import { roles as userRoles } from 'config/constants';

import EntityProfileCardNote from './EntityProfileCardNote';
import { KeyedMutator } from 'swr';

const { TextArea } = Input;
interface EntityProfileCardNotesProps {
    entityProfileCard: EntityProfileCard;
    userId: number;
    yearMetadataValue?: EntityProfileCardYearMetadata;
    isPDFExport?: boolean;
    isAdmin?: boolean;
    notes?: Note[];
    isLoading: boolean;
    mutateNotes: KeyedMutator<Note[]>;
}
const EntityProfileCardNotes = ({
    entityProfileCard,
    yearMetadataValue,
    isPDFExport,
    userId,
    isAdmin,
    notes = [],
    isLoading,
    mutateNotes,
}: EntityProfileCardNotesProps) => {
    const [currentNote, setCurrentNote] = useState<
        CreateEntityProfileCardNote | UpdateEntityProfileCardNote | null
    >(null);

    const [disabledSaveButton, setDisabledSaveButton] = useState<boolean>(true);

    const entityCodes = yearMetadataValue?.entity_code
        ? [yearMetadataValue?.entity_code]
        : [];

    useEffect(() => {
        setDisabledSaveButton(
            currentNote === null || currentNote?.text.trim() === ''
        );
    }, [currentNote]);
    const resetParams = () => {
        setCurrentNote(null);
    };

    const onUpdateNote = async (noteId: string) => {
        if (!noteId) {
            return;
        }

        await mutateNotes(
            async (notes) => {
                if (!notes) {
                    return;
                }

                const noteToUpdate = notes.find((n) => n.id === noteId);

                if (!noteToUpdate) {
                    return;
                }

                if (!currentNote || currentNote.text.trim().length === 0) {
                    message.error('Failed to update note.');
                    return;
                }
                const updatedNotes: Note[] = [
                    ...notes.filter((n) => n.id !== noteToUpdate.id),
                    {
                        ...noteToUpdate,
                        text: currentNote.text,
                    },
                ];

                try {
                    await updateNote(noteId, currentNote.text);
                } catch (e) {
                    message.error('Failed to update note.');
                }

                return updatedNotes;
            },
            {
                rollbackOnError: true,
            }
        );
    };
    const onCreateNote = async () => {
        await mutateNotes(
            async (notes) => {
                if (!notes) {
                    return;
                }

                if (
                    !yearMetadataValue?.reportMetadata.reference_id ||
                    !yearMetadataValue?.reportMetadata.reference_type ||
                    !yearMetadataValue?.reportMetadata.filter_raw_json ||
                    !currentNote ||
                    currentNote.text.trim().length === 0
                ) {
                    message.error('Failed to update note.');
                    return;
                }

                try {
                    const newNote = await createNote({
                        noteReferenceId: entityProfileCard.id,
                        noteReferenceType: NoteReferenceType.EntityProfileCard,
                        referenceId:
                            yearMetadataValue?.reportMetadata.reference_id,
                        referenceType:
                            yearMetadataValue?.reportMetadata.reference_type,
                        reportType: 'entity-profile-year-metadata',
                        filters: [
                            JSON.parse(
                                yearMetadataValue.reportMetadata.filter_raw_json
                            ),
                        ],
                        text: currentNote?.text,
                    });

                    return [...notes, newNote];
                } catch (e) {
                    message.error('Failed to update note.');
                }
                return [...notes];
            },
            {
                rollbackOnError: true,
            }
        );
    };
    const onSubmitForm = async () => {
        const noteId = (currentNote as UpdateEntityProfileCardNote)?.id;

        try {
            if (noteId) {
                await onUpdateNote(noteId);
            } else {
                await onCreateNote();
            }
        } catch (e) {
            message.error(
                noteId
                    ? 'An error occurred while editing note'
                    : 'An error occurred while adding note'
            );
        }

        resetParams();
    };

    const onEditNote = (note: UpdateEntityProfileCardNote) => {
        setCurrentNote(note);
    };

    const onDeleteNote = async (noteId: string) => {
        if (!noteId || !notes) {
            return;
        }

        const updatedNotes = [...notes.filter((n) => n.id !== noteId)];

        await mutateNotes(
            async () => {
                try {
                    await deleteNote(noteId);
                    return updatedNotes;
                } catch (e) {
                    message.error('An error occurred while deleting note');

                    return notes;
                }
            },
            {
                optimisticData: updatedNotes,
                rollbackOnError: true,
            }
        );
    };

    return (
        <>
            <div
                style={{
                    background: 'rgba(240,248,255,0.7)',
                    paddingTop: '16px',
                    paddingLeft: '30px',
                    paddingBottom: '8px',
                    borderRadius: '8px',
                    marginTop: '8px',
                    marginBottom: '8px',
                }}
            >
                {!isPDFExport && (
                    <>
                        <Row>
                            <p
                                style={{
                                    marginBottom: 0,
                                    fontWeight: 'bold',
                                }}
                            >
                                Notes
                            </p>
                        </Row>
                        <Row
                            wrap={false}
                            style={{
                                marginTop: '8px',
                                marginBottom: '14px',
                                marginRight: '30px',
                            }}
                        >
                            <Col flex="auto">
                                <TextArea
                                    autoSize={true}
                                    maxLength={500}
                                    value={currentNote?.text}
                                    placeholder="Add Note"
                                    bordered
                                    onChange={(e) =>
                                        setCurrentNote({
                                            ...currentNote,
                                            text: e.target.value,
                                        })
                                    }
                                />
                            </Col>
                            <Col flex="none">
                                <Button
                                    disabled={disabledSaveButton}
                                    style={{ marginLeft: '8px' }}
                                    id="cancel_note_edition"
                                    onClick={() =>
                                        setCurrentNote({
                                            ...currentNote,
                                            text: '',
                                        })
                                    }
                                >
                                    Cancel
                                </Button>
                            </Col>
                            <Col flex="none">
                                <Button
                                    disabled={disabledSaveButton}
                                    style={{ marginLeft: '8px' }}
                                    id="save_new_or_changes_in_note"
                                    type="primary"
                                    onClick={() => onSubmitForm()}
                                >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </>
                )}

                {notes && notes?.length > 0 && (
                    <List
                        style={{
                            marginTop: '2px',
                            marginRight: '30px',
                        }}
                        loading={isLoading}
                        itemLayout="horizontal"
                        dataSource={notes}
                        renderItem={(note) => (
                            <EntityProfileCardNote
                                note={note}
                                entityCodes={entityCodes}
                                userId={userId}
                                isPDFExport={isPDFExport}
                                isAdmin={isAdmin}
                                isLoading={isLoading}
                                onEditNote={onEditNote}
                                onDeleteNote={onDeleteNote}
                            />
                        )}
                    />
                )}
            </div>
        </>
    );
};
export const mapState = (state: RootStateOrAny) => {
    const { id: userId, roles } = selectCurrentUser(state);
    const isAdmin = roles.includes(userRoles.CLIENT_ADMIN);

    return {
        userId,
        isAdmin,
    };
};

export default connect(mapState)(EntityProfileCardNotes);
