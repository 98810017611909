import { DataGrid } from 'devextreme-react';
import {
    Column,
    Format,
    Grouping,
    GroupItem,
    GroupPanel,
    Scrolling,
    Summary,
    TotalItem,
    ColumnChooser,
    Export,
    Paging,
    ColumnChooserSelection,
    Position,
} from 'devextreme-react/data-grid';
import { ExportingEvent, RowPreparedEvent } from 'devextreme/ui/data_grid';
import {
    CellInfoType,
    CustomizeCellInfoType,
    RecurringCharge,
    RecurringChargeRollUp,
} from 'waypoint-types';
import theme from 'config/theme';
import { DASH_DASH } from 'config/constants';
import { numericalSort } from 'utils/tables/sorters';
import { useCallback } from 'react';
import {
    exportExcelFromDevExtremeDataGrid,
    safeDivision,
} from 'waypoint-utils';
import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { RecurringChargeExportableGrid } from 'components/reports/components/entity-report-widgets/report-widget-export-grids/RecurringChargeExportableGrid';

interface RecurringChargeGridProps {
    data: RecurringChargeRollUp[];
    height: number | 'auto';
    isMasterDetail?: boolean;
    performanceOverviewConfig?: boolean;
    isRentSteps?: boolean;
    isPDFExport?: boolean;
}

export const RecurringChargeGrid = ({
    data,
    height,
    isMasterDetail = false,
    performanceOverviewConfig = false,
    isRentSteps = false,
    isPDFExport,
}: RecurringChargeGridProps): JSX.Element | null => {
    const groupCell = (options: CellInfoType) => {
        return <div>{options.value}</div>;
    };
    const formatDate = (cellInfo: CustomizeCellInfoType) => {
        return cellInfo.value
            ? formatInTimeZone(
                  parseISO(cellInfo.value.toString()),
                  'UTC',
                  'MM/dd/yyyy',
              )
            : DASH_DASH;
    };

    const calculatePercentage = useCallback(
        ({ monthly }: RecurringChargeRollUp) => {
            const totalMonthly = data.reduce(
                (total, item) => total + item.monthly,
                0,
            );
            return safeDivision(Number(monthly), totalMonthly);
        },
        [data],
    );

    const calculateStepPercentage = useCallback((rowData, allRows) => {
        const rowIndex = allRows.findIndex(
            (row: RecurringCharge) =>
                row.leasable_space_code === rowData.leasable_space_code &&
                row.lease_code === rowData.lease_code &&
                row.charge_code === rowData.charge_code &&
                row.start_date === rowData.start_date,
        );

        const isDifferentChargeCode =
            allRows[rowIndex - 1]?.charge_code &&
            allRows[rowIndex - 1].charge_code !== rowData.charge_code;

        if (isDifferentChargeCode || rowIndex === 0) {
            return DASH_DASH; // No prior month data for the first item or different charge code
        }

        const priorMonthly = allRows[rowIndex - 1].monthly;

        return safeDivision(rowData.monthly - priorMonthly, priorMonthly);
    }, []);

    const onRowPrepared = (e: RowPreparedEvent) => {
        if (e.rowType === 'group') {
            e.rowElement.style.backgroundColor = theme.colors.grays.background;
        }
        if (e.rowType === 'header') {
            e.rowElement.style.fontWeight = 'bold';
            e.rowElement.style.color = 'white';
            e.rowElement.style.backgroundColor = '#32a3dde3';
        }
        if (e.rowType === 'totalFooter') {
            e.rowElement.style.fontWeight = 'bold';
        }
    };

    const onExporting = useCallback(async (e: ExportingEvent) => {
        await exportExcelFromDevExtremeDataGrid(e, {
            worksheetName: 'Recurring Charges',
            filename: 'Recurring_Charges.xlsx',
        });
    }, []);

    const isExportEnabled =
        !isMasterDetail &&
        !performanceOverviewConfig &&
        !isPDFExport &&
        !isRentSteps;

    const isGroupingEnabled =
        !performanceOverviewConfig && !isPDFExport && !isRentSteps;

    if (isPDFExport) {
        return <RecurringChargeExportableGrid recurringCharges={data} />;
    }

    return (
        <DataGrid
            dataSource={data}
            keyExpr="bucket_name"
            height={height}
            allowColumnReordering={true}
            showBorders={true}
            onRowPrepared={onRowPrepared}
            wordWrapEnabled={false}
            hoverStateEnabled={true}
            onExporting={onExporting}
        >
            <Export enabled={isExportEnabled} allowExportSelectedData={false} />
            <ColumnChooser
                enabled={isExportEnabled}
                mode={'select'}
                height={350}
                allowSearch={true}
            >
                <Position
                    my="right top"
                    at="right bottom"
                    of=".dx-datagrid-column-chooser-button"
                />
                <ColumnChooserSelection
                    allowSelectAll={true}
                    recursive={true}
                />
            </ColumnChooser>
            <Grouping
                contextMenuEnabled={isGroupingEnabled}
                autoExpandAll={false}
            />
            <GroupPanel visible={isGroupingEnabled} />
            {!isRentSteps && (
                <Summary>
                    <GroupItem
                        column="percentage"
                        name="percentage"
                        summaryType="sum"
                        displayFormat="{0}"
                        valueFormat={{ type: 'percent', precision: 1 }}
                        alignByColumn
                    />
                    <GroupItem
                        column="monthly"
                        name="group_monthly"
                        summaryType="sum"
                        displayFormat="{0}"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        alignByColumn
                    />
                    <GroupItem
                        column="annual"
                        name="group_annual"
                        summaryType="sum"
                        displayFormat="{0}"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        alignByColumn
                    />
                    <GroupItem
                        column="annual_per_sq_ft"
                        name="group_annual_per_sq_ft"
                        summaryType="sum"
                        displayFormat="{0}"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        alignByColumn
                    />
                    <GroupItem
                        column="annual_per_unit"
                        name="group_annual_per_unit"
                        summaryType="sum"
                        displayFormat="{0}"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        alignByColumn
                    />
                    <TotalItem
                        column="percentage"
                        name="percentage"
                        summaryType="sum"
                        displayFormat="{0}"
                        valueFormat={{ type: 'percent', precision: 1 }}
                    />
                    <TotalItem
                        column="monthly"
                        name="total_monthly"
                        summaryType="sum"
                        displayFormat="{0}"
                        valueFormat={{ type: 'currency', precision: 2 }}
                    />
                    <TotalItem
                        column="annual"
                        name="total_annual"
                        summaryType="sum"
                        displayFormat="{0}"
                        valueFormat={{ type: 'currency', precision: 2 }}
                    />
                    <TotalItem
                        column="monthly_per_sq_ft"
                        name="total_monthly_per_sq_ft"
                        summaryType="sum"
                        displayFormat="{0}"
                        valueFormat={{ type: 'currency', precision: 2 }}
                    />
                    <TotalItem
                        column="annual_per_sq_ft"
                        name="total_annual_per_sq_ft"
                        summaryType="sum"
                        displayFormat="{0}"
                        valueFormat={{ type: 'currency', precision: 2 }}
                    />
                    <TotalItem
                        column="monthly_per_unit"
                        name="total_monthly_per_unit"
                        summaryType="sum"
                        displayFormat="{0}"
                        valueFormat={{ type: 'currency', precision: 2 }}
                    />
                    <TotalItem
                        column="annual_per_unit"
                        name="total_annual_per_unit"
                        summaryType="sum"
                        displayFormat="{0}"
                        valueFormat={{ type: 'currency', precision: 2 }}
                    />
                </Summary>
            )}
            <Column
                groupCellRender={groupCell}
                visible={!isRentSteps}
                dataField="bucket_name"
                caption="Category"
            />
            <Column
                dataField="charge_name"
                caption="Charge (Code)"
                width={'auto'}
                allowGrouping={false}
            />
            <Column
                dataField="start_date"
                caption="Start Date"
                visible={
                    (isMasterDetail && !performanceOverviewConfig) ||
                    isRentSteps
                }
                allowGrouping={false}
                customizeText={formatDate}
                showInColumnChooser={false}
            />
            <Column
                dataField="end_date"
                caption="End Date"
                visible={
                    (isMasterDetail && !performanceOverviewConfig) ||
                    isRentSteps
                }
                allowGrouping={false}
                customizeText={formatDate}
                showInColumnChooser={false}
            />
            <Column
                dataField={'percentage'}
                caption={'% of Total'}
                allowGrouping={false}
                alignment="center"
                allowSorting={true}
                visible={!isRentSteps}
                calculateCellValue={calculatePercentage}
            >
                <Format type="percent" precision={1} />
            </Column>

            <Column
                dataField={'percentageStep'}
                caption={'% Change from Prior charge'}
                allowGrouping={false}
                alignment="center"
                allowSorting={true}
                visible={isRentSteps}
                calculateCellValue={(rowData: number) =>
                    calculateStepPercentage(rowData, data)
                }
            >
                <Format type="percent" precision={1} />
            </Column>

            <Column
                dataField="monthly"
                caption={'Monthly'}
                allowGrouping={false}
                sortingMethod={numericalSort}
                alignment="center"
            >
                <Format type="currency" precision={2} />
            </Column>
            <Column
                dataField="annual"
                caption={'Annual'}
                allowGrouping={false}
                alignment="center"
            >
                <Format type="currency" precision={2} />
            </Column>

            <Column
                dataField="monthly_per_sq_ft"
                caption={isMasterDetail ? 'Monthly / SF' : 'Monthly / Occ. SF'}
                allowGrouping={false}
                visible={
                    isMasterDetail || performanceOverviewConfig || !isRentSteps
                }
                showInColumnChooser={false}
                alignment="center"
            >
                <Format type="currency" precision={2} />
            </Column>

            <Column
                dataField="annual_per_sq_ft"
                caption={isMasterDetail ? 'Annual / SF' : 'Annual / Occ. SF'}
                allowGrouping={false}
                alignment="center"
            >
                <Format type="currency" precision={2} />
            </Column>

            {performanceOverviewConfig && (
                <Column
                    dataField="monthly_per_unit"
                    caption={'Monthly / Unit'}
                    allowGrouping={false}
                    showInColumnChooser={false}
                    alignment="center"
                >
                    <Format type="currency" precision={2} />
                </Column>
            )}

            <Column
                dataField="annual_per_unit"
                caption={'Annual / Unit'}
                allowGrouping={false}
                visible={
                    (!isMasterDetail || performanceOverviewConfig) &&
                    !isRentSteps
                }
                alignment="center"
            >
                <Format type="currency" precision={2} />
            </Column>
            <Paging enabled={false} />
            <Scrolling />
        </DataGrid>
    );
};
