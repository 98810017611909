import {
    getFinancialOverviewBreakdown,
    getFinancialOverviewOvertime,
    getFinancialOverviewTotal,
    getOvertimeDownload,
    getBreakdownDownload,
} from 'waypoint-requests';

// Add requests here
const requestHashMap = {
    overtime: {
        get: getFinancialOverviewOvertime,
        download: getOvertimeDownload,
    },
    breakdown: {
        get: getFinancialOverviewBreakdown,
        download: getBreakdownDownload,
    },
    total: {
        get: getFinancialOverviewTotal,
    },
    title: () => Promise.resolve([]), // NOTE: here for title as an extra guard. not really necessary
};

export default requestHashMap;
