import { includes } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styles from 'components/settings/SubMenu.module.css';
import {
    USER_MANAGEMENT_URL_SEGMENT,
    CLIENT_SETTINGS_URL_SEGMENT,
    PERMISSIONS_URL_SEGMENT,
    ADMIN_SETTINGS_URL_SEGMENT,
    COMPANY_URL_SEGMENT,
    roles as userRoles,
} from 'config/constants';
import { withRouter } from 'react-router-dom';

// TODO (Daniel): Abstract these menus as a more composable, configurable menu

class AdminSubMenu extends React.Component {
    static propTypes = {
        url: PropTypes.string,
        isAdmin: PropTypes.bool,
        isWaypointAssociate: PropTypes.bool,
        isWaypointSysadmin: PropTypes.bool,
    };

    static contextTypes = {
        router: PropTypes.object,
    };

    getClassnames = (name) => {
        const { isActive } = this;
        const activeStateClassname = isActive(name) ? styles.active : '';
        const disableButtonClassname = '';
        return `${disableButtonClassname} ${activeStateClassname}`;
    };

    gotoUserManagement = () => {
        const { history } = this.props;
        history.push(
            `${ADMIN_SETTINGS_URL_SEGMENT}/` + USER_MANAGEMENT_URL_SEGMENT
        );
    };

    goToPermissionsGroup = () => {
        const { history } = this.props;
        history.push(
            `${ADMIN_SETTINGS_URL_SEGMENT}/` + PERMISSIONS_URL_SEGMENT
        );
    };

    gotoClientSettings = () => {
        const { history } = this.props;
        history.push(
            `${ADMIN_SETTINGS_URL_SEGMENT}/` + CLIENT_SETTINGS_URL_SEGMENT
        );
    };

    gotoCompany = () => {
        const { history } = this.props;
        history.push(`${ADMIN_SETTINGS_URL_SEGMENT}/` + COMPANY_URL_SEGMENT);
    };

    isActive = (name) => {
        const { url } = this.props;
        return includes(url, name);
    };

    render() {
        const {
            gotoUserManagement,
            getClassnames,
            goToPermissionsGroup,
            gotoCompany,
        } = this;

        // const { isWaypointAssociate, isWaypointSysadmin } = this.props
        // const hasPermission = isWaypointSysadmin || isWaypointAssociate
        // NOTE (Daniel): React's fragments could be of use to aleviate the need for multiple
        // hasPermission checks
        return (
            <nav className={styles.profile}>
                {
                    <div
                        className={getClassnames(USER_MANAGEMENT_URL_SEGMENT)}
                        onClick={gotoUserManagement}
                    >
                        User Management
                    </div>
                }
                {this.props.isAdmin ? (
                    <div
                        className={getClassnames(PERMISSIONS_URL_SEGMENT)}
                        onClick={goToPermissionsGroup}
                    >
                        Permissions Groups
                    </div>
                ) : null}
                {this.props.isAdmin ? (
                    <div
                        className={getClassnames(COMPANY_URL_SEGMENT)}
                        onClick={gotoCompany}
                    >
                        Company
                    </div>
                ) : null}
            </nav>
        );
    }
}

function mapState(state) {
    const { roles } = state.user;
    const { pathname: url } = state.routing.locationBeforeTransitions;

    const isAdmin = roles.includes(userRoles.CLIENT_ADMIN);
    const isWaypointAssociate = roles.includes(userRoles.WAYPOINT_ASSOCIATE);
    const isWaypointSysadmin = roles.includes(userRoles.WAYPOINT_ASSOCIATE);

    return {
        url,
        isAdmin,
        isWaypointAssociate,
        isWaypointSysadmin,
    };
}

export default withRouter(compose(connect(mapState))(AdminSubMenu));
