import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';
import { css, cx } from 'emotion';
import theme from 'config/theme';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import { Dictionary } from 'ts-essentials';
import React from 'react';

const dropdownShowButton = css`
    .ant-btn-primary {
        color: ${theme.colors.white} !important;
        background-color: ${theme.colors.blues.primary} !important;
        border-color: ${theme.colors.blues.outline} !important;
        @media (max-width: 1020px) {
            margin-bottom: 5px;
        }
    }
`;

const hideDropdown = css`
    .ant-btn-icon-only {
        display: none !important;
    }
`;

const dropdownHeight = css`
    overflow-y: scroll;
    max-height: 500px;
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
`;

const hideButton = css`
    color: ${theme.colors.blues.outline} !important;
    border-color: ${theme.colors.blues.outline} !important;
    background: ${theme.colors.white} !important;
    text-shadow: none !important;
    @media (max-width: 800px) {
        margin-bottom: 5px;
    }
`;

export interface PivotGridDataFieldButtonOption {
    value: string;
    text: string;
}

export interface PivotGridDataFieldButtonProps {
    buttonName: string;
    fieldName: string;
    dataSource: PivotGridDataSource;
    isFieldVisible: Dictionary<boolean, string>;
    isFieldSelected: Dictionary<string>;
    setIsFieldVisible: (value: Dictionary<boolean, string>) => void;
    setIsFieldSelected: (value: Dictionary<string>) => void;
    options: PivotGridDataFieldButtonOption[];
    clickHandler: (fieldName: string, value: string) => void;
}

const PivotGridDataFieldButton = ({
    buttonName,
    fieldName,
    dataSource,
    isFieldVisible,
    isFieldSelected,
    setIsFieldSelected,
    setIsFieldVisible,
    options,
    clickHandler,
}: PivotGridDataFieldButtonProps): React.ReactElement => {
    const hideShowField = (fieldName: string) => {
        const fieldId = dataSource.field(fieldName);
        dataSource.field(fieldName, {
            visible: !fieldId.visible,
        });
        dataSource.load();
    };

    const handleMenuClick = ({ key }: MenuInfo) => {
        setIsFieldSelected({
            ...isFieldSelected,
            ...{
                [fieldName]: key,
            },
        });
    };

    const generateDropdownOptions = (fieldName: string): React.ReactElement => {
        const handleMenuItemClick = (
            option: PivotGridDataFieldButtonOption,
        ) => {
            clickHandler(fieldName, option.value);
        };

        const menuItems = options.map((option) => {
            return (
                <Menu.Item
                    key={option.value}
                    onClick={() => handleMenuItemClick(option)}
                >
                    {option.text}
                </Menu.Item>
            );
        });

        return (
            <Menu
                onClick={handleMenuClick}
                selectedKeys={[isFieldSelected[fieldName]]}
            >
                {menuItems}
            </Menu>
        );
    };

    const crosstabDropdown = () => {
        return (
            <Dropdown.Button
                data-testid="crosstab-datafield-dropdown"
                icon={<DownOutlined />}
                type="primary"
                trigger={['click']}
                overlay={generateDropdownOptions(fieldName)}
                style={{ marginRight: '12px', width: 'auto' }}
                onClick={() => {
                    setIsFieldVisible({
                        ...isFieldVisible,
                        ...{
                            [fieldName]: !isFieldVisible[fieldName],
                        },
                    });
                    hideShowField(fieldName);
                }}
                className={
                    fieldName === '_variance_percent'
                        ? cx([dropdownShowButton, hideDropdown])
                        : dropdownShowButton
                }
                overlayClassName={dropdownHeight}
            >
                {buttonName}
            </Dropdown.Button>
        );
    };

    const crosstabButton = () => {
        return (
            <Button
                data-testid="crosstab-datafield-button"
                type="primary"
                style={{
                    marginRight: '12px',
                }}
                onClick={() => {
                    setIsFieldVisible({
                        ...isFieldVisible,
                        ...{
                            [fieldName]: !isFieldVisible[fieldName],
                        },
                    });
                    hideShowField(fieldName);
                }}
                className={hideButton}
            >
                {buttonName}
            </Button>
        );
    };

    if (!isFieldVisible[fieldName]) {
        return crosstabButton();
    }

    return crosstabDropdown();
};

export default PivotGridDataFieldButton;
