import { API_URL } from 'config/constants';
import { Note } from 'waypoint-types';

const updateNote = async (note_id: string, text: string): Promise<Note> => {
    const response = await fetch(`${API_URL}/notes/${note_id}`, {
        method: 'PUT',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            text,
        }),
    });

    if (!response.ok) {
        throw new Error('Failed to update note');
    }

    const { data } = await response.json();
    return data as Note;
};

export default updateNote;
