import { message } from 'antd';
import useSWR from 'swr';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { getLeasingPlans } from 'waypoint-requests';
import { LeasingPlan } from 'waypoint-types/leasing-plans';

export const useGetLeasingPlans = (
    entityCodes: string[],
    planYear?: number
): DataHookResponse<LeasingPlan[]> => {
    const { data, error, mutate, isValidating } = useSWR(
        `/api/leasing-plans/${entityCodes.join(',')}/${planYear ?? ''}`,
        () => getLeasingPlans(entityCodes, planYear),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
            onError: () => {
                message.error('Failed to fetch leasing plans');
            },
            shouldRetryOnError: false,
        }
    );
    return {
        isLoading: !data || isValidating,
        isError: !!error,
        data,
        mutate,
        isValidating,
    };
};
