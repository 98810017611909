import { getWorkflowRolesByEntityCodes } from 'waypoint-requests';
import { EntityReportWorkflowRole, WorkflowRole } from 'waypoint-types';
import {
    WORKFLOW_ASSIGNEE,
    WORKFLOW_REVIEWER,
} from 'components/financials/comparative-income-statement/constants';
import useSWR from 'swr';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';

export interface WorkflowRolesResponse {
    assignees: WorkflowRole[];
    reviewers: WorkflowRole[];
}

export const useGetWorkflowRoles = (
    entityCodes: string[],
    validUsers: { id: number; name: string; profile_image_url: string }[]
): DataHookResponse<WorkflowRolesResponse> => {
    const validUserIds = validUsers.map((user) => user.id);

    const { data, mutate, error } = useSWR(
        entityCodes.length
            ? `/get-workflow-roles/${entityCodes
                  .sort()
                  .join(',')}/${validUserIds.sort().join(',')}`
            : null,
        async () => {
            const workflowRoles =
                await getWorkflowRolesByEntityCodes(entityCodes);

            const validAssignees = workflowRoles.filter(
                (wr) =>
                    wr.report_role === WORKFLOW_ASSIGNEE &&
                    validUserIds.includes(wr.user_id)
            );
            const validReviewers = workflowRoles.filter(
                (wr) =>
                    wr.report_role === WORKFLOW_REVIEWER &&
                    validUserIds.includes(wr.user_id)
            );

            const mapRole = (role: EntityReportWorkflowRole): WorkflowRole => {
                return {
                    userId: role.user_id,
                    userName:
                        validUsers.find((u) => u.id === role.user_id)?.name ??
                        '--',
                    profile_image_url: validUsers.find(
                        (u) => u.id === role.user_id
                    )?.profile_image_url,
                    dataRowId: role.id,
                };
            };

            return {
                assignees: validAssignees.map(mapRole),
                reviewers: validReviewers.map(mapRole),
            };
        }
    );

    return {
        data,
        mutate,
        isError: !!error,
        isLoading: !data,
    };
};
