import React from 'react';
import {
    Table,
    Column,
    MoneyCellRenderer,
    NumberWithCommasCellRenderer,
} from 'components/tables';
import {
    percentageSort,
    numericalSort,
    formatPercentage,
} from 'components/leases/utils';
import { stringSort } from 'components/tables/sorts';

const flexEndStyle = { justifyContent: 'flex-end' };

const TenantSummaryTable = ({ data = [], isGroup = false }) => {
    return (
        <Table data={data}>
            <Column
                width={250}
                dataKey="name"
                headerTitle="Tenant"
                sortFn={stringSort}
            />
            <Column
                width={210}
                dataKey="industry"
                headerTitle="Industry"
                sortFn={stringSort}
                headerParameters={{
                    style: { textAlign: 'right' },
                }}
                style={{
                    textTransform: 'capitalize',
                }}
            />
            <Column
                width={150}
                dataKey="totalOccupiedSqft"
                cellRenderer={NumberWithCommasCellRenderer}
                sortFn={numericalSort}
                headerTitle="Occ. Sq Ft"
                style={flexEndStyle}
                headerParameters={{
                    style: { textAlign: 'right' },
                }}
            />
            <Column
                width={210}
                dataKey="percentOccupiedSqFtOfPortfolioSqFt"
                cellDataGetter={({ datum, dataKey, ...rest }) => {
                    const cellData = formatPercentage(datum[dataKey]);
                    return { ...rest, cellData };
                }}
                sortFn={percentageSort}
                style={flexEndStyle}
                headerTitle="% of Total Occ. Sq Ft"
                headerParameters={{
                    style: {
                        textAlign: 'right',
                        marginRight: '0',
                    },
                }}
            />
            <Column
                width={200}
                dataKey="totalAnnualInPlaceRent"
                style={flexEndStyle}
                sortFn={numericalSort}
                cellRenderer={MoneyCellRenderer}
                headerTitle="Total Annual In-Place Rent ($)"
                headerParameters={{
                    style: { textAlign: 'right' },
                }}
            />
            {isGroup ? (
                <Column
                    width={450}
                    dataKey="propertyName"
                    headerTitle="Property"
                    sortFn={stringSort}
                />
            ) : null}
        </Table>
    );
};

export default TenantSummaryTable;
