import { Contract } from 'waypoint-types';

export const statusOptions = [
    {
        value: 'active',
        name: 'Active',
    },
    {
        value: 'inactive',
        name: 'Inactive',
    },
];

export const statusFilterData = [
    {
        text: 'Active',
        value: 'active',
    },
    {
        text: 'Inactive',
        value: 'inactive',
    },
];

export const booleanFilterData = [
    {
        text: 'Yes',
        value: true,
    },
    {
        text: 'No',
        value: false,
    },
];
