import { API_URL } from 'config/constants';

interface UpdateReportParams {
    id: string;
    state: string;
    notifyIfActive?: boolean;
}

const updateReportStatus = async (
    report: UpdateReportParams
): Promise<string> => {
    const response = await fetch(
        `${API_URL}/reports/update-status/${report.id}`,
        {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(report),
        }
    );

    if (!response.ok) {
        throw new Error('Failed to update report status');
    }

    const { data } = await response.json();

    return data;
};

export default updateReportStatus;
