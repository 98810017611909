import React, { useContext } from 'react';
import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { SavedFiltersContext } from 'contexts';
import { EntityAttributesContext } from 'contexts/entity-attributes/EntityAttributesContext';
import theme from 'config/theme';

const editFilterButtonStyle = {
    backgroundColor: theme.colors.blues.primary,
    textOverflow: 'ellispis',
    width: '100%',
    boxShadow: `0 10px 20px -8px rgba(0, 0, 0,.7)`,
    color: theme.colors.white,
    borderColor: `${theme.colors.blues.primary}`,
};

interface EditFilterButtonProps {
    handleOnClick: () => void;
}

const EditFilterButton = ({
    handleOnClick,
}: EditFilterButtonProps): JSX.Element => {
    const savedFilterContext = useContext(SavedFiltersContext);

    const entityAttributesContext = useContext(EntityAttributesContext);

    const isLoading =
        savedFilterContext?.isSavedFiltersLoading ||
        entityAttributesContext?.isAttributesLoading;

    return (
        <Button
            disabled={isLoading}
            loading={isLoading}
            type="primary"
            shape="round"
            icon={<EditOutlined />}
            size="large"
            style={editFilterButtonStyle}
            onClick={handleOnClick}
        >
            {isLoading ? 'Loading' : 'Edit Filter(s)'}
        </Button>
    );
};

export default EditFilterButton;
