import { assign } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';

const IconBubble = createReactClass({
    displayName: 'IconBubble',

    propTypes: {
        name: PropTypes.string.isRequired,
        size: PropTypes.number.isRequired,
        style: PropTypes.object,
    },

    defaultProps: {
        size: 18,
    },

    render() {
        const { name, size } = this.props;

        const style = assign(
            {},
            {
                width: `${size}px`,
                height: `${size}px`,
                // Size of Icon, Often overwritten
                fontSize: `${size}px`,
                // Placement of Icon, Often overwritten
                lineHeight: `${size}px`,
                borderRadius: `${size}px`,
                color: '#FFF',
                textAlign: 'center',
                transform: 'translateY(-4px)',
                marginRight: '7px',
                backgroundColor: '#000',
            },
            this.props.style,
        );

        return <i icon={name} style={style} size="lg" />;
    },
});

export default IconBubble;
