import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    actionButtons,
    cardChartBox,
    cardPieChartBox,
    dateRangeContainer,
    fillerCardHeight,
    inlineChartSelectStyle,
    monthPickerStyle,
    page,
    switchChartStyle,
} from 'components/analytics/ranking/rankingCss';
import { EntityAttributesContext } from 'contexts/entity-attributes/EntityAttributesContext';
import moment, { Moment } from 'moment';
import {
    accountGraphTypes,
    accountGraphValues,
    DEFAULT_PIE_CHART_SLICE_COUNT,
    defaultValueType,
    pieChartCounts,
    RANKING_RANGE_SELECT_ID,
} from 'components/analytics/ranking/constants';
import {
    useGetSelectedFilteredEntityCodes,
    useSelectedDataLevel,
} from 'waypoint-hooks';
import {
    getAccountGraph,
    getAsOfDates,
    getPropertyRanking,
} from 'waypoint-requests';
import { toEndOfMonth, toISO, toStartOfMonth } from 'components/dates/utils';
import {
    decorateAccountGraphForAntDesign,
    searchAccountGraphForAccount,
    searchAccountGraphForAccountName,
} from 'waypoint-utils';
import {
    AccountMappingSelection,
    DisplayType,
    RankingAPISelections,
    RankingCardSelections,
    RankingData,
} from 'waypoint-types';
import { message } from 'antd';
import { find, get, isEmpty } from 'lodash';
import {
    ActionButtons,
    ActionsCard,
    DatePicker,
    DisabledDashboard,
    RangeSelectConfirm,
    Select,
} from 'waypoint-react';
import { RankingMenu, RankingTable } from 'components/analytics/ranking';
import { EntityDataGroupingKeys } from 'utils/EntityDataGroupingConstants';
import { AppFeaturePermissions, AttributeFromAPI } from 'contexts';
import { useGetGroupableAttributes } from 'waypoint-hooks/useGetGroupableAttributes';
import { AttributesGroupBySelect } from 'components/attributesGroupBySelect/AttributesGroupBySelect';
import { defaultDisplayValue } from 'components/analytics/financialOverview/menu/displayType/constants';
import {
    getChartData,
    getDefaultAccountCode,
    getDefaultGlobalPeriod,
    processRankingGroupedData,
    processRankingGroupedDataForTable,
} from 'components/analytics/ranking/utils/RankingDashboardUtils';
import {
    getRankingChartConfig,
    getRankingPieChartConfig,
} from 'components/analytics/ranking/utils/RankingChartUtils';
import { stringSort } from 'utils/tables/sorters';
import useSWR from 'swr';
import { AccountGraphObjectType } from 'waypoint-types/account-graph/types';
import {
    BAR_CHART,
    PERFORMANCE_OVERVIEW_CHARTS,
    PIE_CHART,
} from 'components/analytics/constants';
import RankingInfoTooltip from 'components/analytics/ranking/components/RankingInfoTooltip';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';

const RankingDashboard = (): JSX.Element => {
    const [isError, setIsError] = useState<boolean>(false);
    const entityCodes: string[] = useGetSelectedFilteredEntityCodes();

    const [monthSelected, setMonthSelected] = useState<Date | string>('');
    const [globalPeriod, setGlobalPeriod] = useState<Moment[] | null>(null);

    const [chartType, setChartType] = useState<string>(BAR_CHART);
    const [pieChartCount, setPieChartCount] = useState<number>(
        DEFAULT_PIE_CHART_SLICE_COUNT,
    );

    const [groupedDataForChart, setGroupedDataForChart] = useState<
        RankingData[]
    >([]);
    const [groupedDataForTable, setGroupedDataForTable] = useState<
        RankingData[]
    >([]);

    const { data: asOfDateData, error: asOfDateError } = useSWR(
        ['/api/as-of-date', entityCodes],
        () => getAsOfDates(entityCodes),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
        },
    );

    const transformAndSetAsOfDate = (asOfDateResponse: any) => {
        const actualPeriodEnd = get(
            find(asOfDateResponse.accounting, { label: 'Actual' }),
            'period_end',
        );
        monthSelected || setMonthSelected(actualPeriodEnd);
        return actualPeriodEnd;
    };

    const asOfDate = useMemo(() => {
        if (!asOfDateData || !entityCodes?.length) {
            return;
        }
        if (asOfDateError) {
            setIsError(true);

            void message.error(
                'Request Failed! An error occurred while requesting as of date',
            );
        }
        return transformAndSetAsOfDate(asOfDateData);
    }, [asOfDateData, asOfDateError, entityCodes]);

    const [isLoadingAccountGraph, setIsLoadingAccountGraph] =
        useState<boolean>(true);
    const [accountGraph, setAccountGraph] =
        useState<AccountGraphObjectType>(null);
    const [accountGraphSelection, setAccountGraphSelection] = useState<string>(
        accountGraphValues.incomeStatement,
    );

    const [selections, setSelections] = useState<null | RankingCardSelections>(
        null,
    );

    const [groupingSelection, setGroupingSelection] =
        useState<EntityDataGroupingKeys>(EntityDataGroupingKeys.Property);

    const groupableAttributes = useGetGroupableAttributes().sort(
        (a: AttributeFromAPI, b: AttributeFromAPI) =>
            stringSort(b?.title, a?.title),
    );

    const [attributeSelection, setAttributeSelection] =
        useState<AttributeFromAPI | null>(groupableAttributes[0] ?? null);

    useEffect(() => {
        if (!attributeSelection && groupableAttributes.length) {
            setAttributeSelection(groupableAttributes[0]);
        }
    }, [groupableAttributes.length, attributeSelection]);

    const selectedDataLevel = useSelectedDataLevel();

    const fetchAccountGraph = useCallback(async () => {
        try {
            const data = await getAccountGraph(
                accountGraphSelection,
                selectedDataLevel,
            );
            const accountGraph = decorateAccountGraphForAntDesign(
                data.children,
            );

            setAccountGraph(accountGraph);
            setIsLoadingAccountGraph(false);
        } catch (e) {
            setIsError(true);

            message.error(
                'Request Failed! An error occurred while requesting accounts',
            );
        } finally {
            await fetchPropertyRanking();
            setIsLoadingAccountGraph(false);
        }
    }, [asOfDate]);

    const [rangeSelectIsOpen, setRangeSelectIsOpen] = useState<boolean>(false);

    const [isPropertyRankingLoading, setIsPropertyRankingLoading] =
        useState<boolean>(true);
    const [propertyRankingData, setPropertyRankingData] = useState<
        RankingData[]
    >([]);

    const [showMenu, setShowMenu] = useState<boolean>(false);

    const entityAttributesContext = useContext(EntityAttributesContext);
    const entityAttributes =
        entityAttributesContext?.data?.attributeDefinitions;

    useEffect(() => {
        if (groupingSelection !== EntityDataGroupingKeys.Attributes) {
            return;
        }

        if (!attributeSelection || !entityAttributes) {
            return;
        }

        const selectedAttributeDef = entityAttributes.find(
            (attr) => attr.attribute_code === attributeSelection.dataIndex,
        );

        if (!selectedAttributeDef) {
            return;
        }

        const groupedRankingTable = processRankingGroupedDataForTable(
            entityCodes,
            selectedAttributeDef,
            propertyRankingData,
        );

        const groupedRankingChart = processRankingGroupedData(
            entityCodes,
            propertyRankingData,
            selectedAttributeDef,
            getSelectionOrDefault('displayType') as DisplayType,
        );

        setGroupedDataForTable(groupedRankingTable);
        setGroupedDataForChart(groupedRankingChart);
    }, [
        attributeSelection,
        entityAttributes,
        propertyRankingData,
        entityCodes,
        groupingSelection,
    ]);

    const fetchPropertyRanking = useCallback(
        async (rankingParams?: RankingAPISelections) => {
            if (!asOfDate || isLoadingAccountGraph) {
                setIsPropertyRankingLoading(false);
                return;
            }

            setIsPropertyRankingLoading(true);
            setPropertyRankingData([]);

            try {
                const allRankingParams = {
                    selected_data_level: selectedDataLevel,
                    ...(rankingParams ?? getPropertyRankingParams()),
                };

                const response = await getPropertyRanking(allRankingParams);

                const { data } = await response.json();
                setPropertyRanking(data);
            } catch (e) {
                setErrorState();
            } finally {
                setIsPropertyRankingLoading(false);
            }
        },
        [
            asOfDateData,
            entityCodes,
            globalPeriod,
            monthSelected,
            isLoadingAccountGraph,
            selectedDataLevel.percentageType,
            selectedDataLevel.stakeholder,
        ],
    );
    useEffect(() => {
        void fetchPropertyRanking();
    }, [fetchPropertyRanking]);

    useEffect(() => {
        void fetchAccountGraph();
    }, [fetchAccountGraph]);

    const getAllDefaults = (): RankingCardSelections => {
        const accountMappingCode = getDefaultAccountCode(accountGraph);
        return {
            accountMapping: getAccountMappingObject(accountMappingCode ?? ''),
            valueType: defaultValueType,
            displayType: defaultDisplayValue,
            accountGraphCode: accountGraphTypes[0].value,
            period: [],
        };
    };

    const getAccountMappingObject = (
        accountMappingCode: string,
    ): AccountMappingSelection => {
        return {
            name:
                searchAccountGraphForAccountName(
                    accountGraph,
                    accountMappingCode,
                ) || accountMappingCode,
            code: accountMappingCode,
        };
    };

    function getSelectionOrDefault<K extends keyof RankingCardSelections>(
        field: K,
    ) {
        const defaults: RankingCardSelections = getAllDefaults();
        return get(selections, field, defaults[field]);
    }

    const getAllSelectionsOrDefaults = (): RankingCardSelections => {
        return {
            accountMapping: getSelectionOrDefault('accountMapping'),
            period: globalPeriod ?? getDefaultGlobalPeriod(asOfDate),
            valueType: getSelectionOrDefault('valueType'),
            displayType: getSelectionOrDefault('displayType'),
            accountGraphCode: accountGraphSelection,
        };
    };

    const setPropertyRanking = (rankingData: RankingData[]) => {
        setPropertyRankingData(rankingData);
        setIsPropertyRankingLoading(false);
    };

    const setErrorState = () => {
        setIsPropertyRankingLoading(false);
        setPropertyRankingData([]);
    };

    const getPropertyRankingParams = (
        values?: RankingCardSelections,
    ): RankingAPISelections => {
        const localSelections = values ?? getAllSelectionsOrDefaults();

        return {
            entity_codes: entityCodes,
            account_mapping_code: localSelections.accountMapping.code,
            display_type: localSelections.displayType,
            value_type: localSelections.valueType,
            start_date:
                localSelections.accountGraphCode ===
                accountGraphValues.incomeStatement
                    ? toISO(toStartOfMonth(localSelections.period[0]))
                    : toISO(toStartOfMonth(monthSelected)),
            end_date:
                localSelections.accountGraphCode ===
                accountGraphValues.incomeStatement
                    ? toISO(toEndOfMonth(localSelections.period[1]))
                    : toISO(toEndOfMonth(monthSelected)),
            account_graph_code: localSelections.accountGraphCode,
        };
    };

    const handleApply = async (values: RankingCardSelections) => {
        const rankingParams = getPropertyRankingParams(values);

        if (
            values.displayType !== defaultDisplayValue &&
            chartType === PIE_CHART
        ) {
            setChartType(BAR_CHART);
        }

        setSelections(values);
        await fetchPropertyRanking(rankingParams);
        setShowMenu(false);
    };

    const closeMenu = () => {
        setShowMenu(false);
    };

    const noData: boolean = isEmpty(propertyRankingData);

    const dataRanking = useMemo(() => {
        return groupingSelection === EntityDataGroupingKeys.Attributes
            ? groupedDataForTable
            : getChartData(isPropertyRankingLoading, propertyRankingData);
    }, [
        groupingSelection,
        groupedDataForTable,
        propertyRankingData,
        isPropertyRankingLoading,
    ]);
    const dataRankingForChart = useMemo(() => {
        return groupingSelection === EntityDataGroupingKeys.Attributes
            ? groupedDataForChart
            : getChartData(isPropertyRankingLoading, propertyRankingData);
    }, [
        groupingSelection,
        propertyRankingData,
        groupedDataForChart,
        isPropertyRankingLoading,
    ]);

    const isValidAccountMapping = useMemo(() => {
        return (
            searchAccountGraphForAccount(
                accountGraph,
                getAllSelectionsOrDefaults().accountMapping.code,
            ) !== null
        );
    }, [selections, accountGraph]);

    if (isError) {
        return (
            <DisabledDashboard text={'There was an error loading your data.'} />
        );
    }

    const isLoadingAsOfDate = !asOfDateData && !asOfDateError;

    if (isLoadingAsOfDate || isLoadingAccountGraph) {
        return <DisabledDashboard text={'Loading...'} />;
    }

    if (!entityCodes?.length) {
        return <DisabledDashboard text={'No properties selected.'} />;
    }

    return (
        <PermissionedWrapper
            featureKey={AppFeaturePermissions.Ranking}
            showDisabledView={true}
        >
            <div data-testid="ranking-grid-wrapper" className={page}>
                <div
                    className={dateRangeContainer}
                    id={RANKING_RANGE_SELECT_ID}
                >
                    {accountGraphSelection ===
                    accountGraphValues.incomeStatement ? (
                        <RangeSelectConfirm
                            open={rangeSelectIsOpen}
                            value={
                                globalPeriod ?? getDefaultGlobalPeriod(asOfDate)
                            }
                            onFocus={() => setRangeSelectIsOpen(true)}
                            onCancel={() => setRangeSelectIsOpen(false)}
                            onConfirm={(values: Moment[]) => {
                                setRangeSelectIsOpen(false);
                                setGlobalPeriod(values);
                            }}
                            getCalendarContainer={() =>
                                document.getElementById(RANKING_RANGE_SELECT_ID)
                            }
                        />
                    ) : (
                        <div style={{ width: 160, marginBottom: '5px' }}>
                            <DatePicker
                                picker="month"
                                allowClear={false}
                                placeholder="Select month"
                                format="MMMM YYYY"
                                className={monthPickerStyle}
                                onChange={(momentInstance) => {
                                    setMonthSelected(
                                        toISO(
                                            toStartOfMonth(
                                                momentInstance as any,
                                            ),
                                        ),
                                    );
                                }}
                                value={moment(monthSelected)}
                                defaultValue={moment(monthSelected)}
                            />
                        </div>
                    )}
                </div>
                <div style={{ padding: 24 }}>
                    {showMenu && (
                        <RankingMenu
                            accountGraph={accountGraph}
                            selections={getAllSelectionsOrDefaults()}
                            handleClose={closeMenu}
                            handleApply={handleApply}
                            setAccountGraph={setAccountGraph}
                            onChangeAccountGraphSelection={
                                setAccountGraphSelection
                            }
                        />
                    )}

                    <ActionsCard
                        loadingRows={14}
                        loadingData={isPropertyRankingLoading}
                        className={
                            isPropertyRankingLoading || noData
                                ? fillerCardHeight
                                : ''
                        }
                        empty={noData}
                        title={<h2 style={{ marginBottom: 0 }}>Ranking</h2>}
                        extra={
                            <div className={actionButtons}>
                                <ActionButtons
                                    onClick={() => setShowMenu(true)}
                                    loading={isPropertyRankingLoading}
                                    buttonSize="big"
                                />
                            </div>
                        }
                    >
                        <div className={actionButtons}>
                            <AttributesGroupBySelect
                                attributeSelection={attributeSelection}
                                setAttributeSelection={setAttributeSelection}
                                attributes={groupableAttributes}
                                groupingSelection={groupingSelection}
                                setGroupingSelection={setGroupingSelection}
                            />
                            {getSelectionOrDefault('displayType') ===
                                defaultDisplayValue && (
                                <div>
                                    {chartType === PIE_CHART && (
                                        <div className={inlineChartSelectStyle}>
                                            <div
                                                className={
                                                    inlineChartSelectStyle
                                                }
                                            >
                                                <span
                                                    style={{
                                                        marginRight: '5px',
                                                    }}
                                                >
                                                    Number of Slices
                                                </span>
                                                <RankingInfoTooltip
                                                    title="Negative values (i.e. Free Rent as a slice of Total Revenue) will render as positive values in the pie chart."
                                                    placement="top"
                                                />
                                                :
                                            </div>
                                            <Select
                                                style={{
                                                    display: 'inline-block',
                                                    marginRight: '10px',
                                                    marginLeft: '5px',
                                                }}
                                                value={pieChartCount}
                                                options={pieChartCounts}
                                                onChange={(value: number) =>
                                                    setPieChartCount(value)
                                                }
                                            />
                                        </div>
                                    )}
                                    {PERFORMANCE_OVERVIEW_CHARTS.slice(
                                        0,
                                        2,
                                    ).map((chartButton) => (
                                        <a
                                            onClick={() =>
                                                setChartType(chartButton.key)
                                            }
                                            key={chartButton.key}
                                            className={`${switchChartStyle} ${
                                                chartType === chartButton.key
                                                    ? 'active'
                                                    : ''
                                            }`}
                                        >
                                            <i
                                                className={
                                                    chartButton.key ===
                                                    BAR_CHART
                                                        ? 'fa-solid fa-bar-chart'
                                                        : 'fa-solid fa-pie-chart'
                                                }
                                            />
                                        </a>
                                    ))}
                                </div>
                            )}
                        </div>
                        {isValidAccountMapping ? (
                            <div className={cardChartBox}>
                                {chartType === BAR_CHART && (
                                    <div>
                                        {getRankingChartConfig(
                                            dataRankingForChart,
                                            getAllSelectionsOrDefaults(),
                                            accountGraph,
                                            entityCodes,
                                            getSelectionOrDefault('valueType'),
                                        )}
                                    </div>
                                )}
                                {chartType === PIE_CHART && (
                                    <div className={cardPieChartBox}>
                                        {getRankingPieChartConfig(
                                            dataRankingForChart,
                                            getAllSelectionsOrDefaults(),
                                            accountGraph,
                                            pieChartCount,
                                            getSelectionOrDefault('valueType'),
                                            groupingSelection ===
                                                EntityDataGroupingKeys.Property
                                                ? 'Property'
                                                : attributeSelection?.title ??
                                                      '',
                                        )}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div />
                        )}

                        <div style={{ paddingTop: 20 }}>
                            {isValidAccountMapping && (
                                <RankingTable
                                    attributeSelectedName={
                                        attributeSelection?.title ?? ''
                                    }
                                    isGroupByAttribute={
                                        groupingSelection ===
                                        EntityDataGroupingKeys.Attributes
                                    }
                                    data={dataRanking ?? []}
                                    selections={getAllSelectionsOrDefaults()}
                                    accountGraph={accountGraph}
                                />
                            )}
                        </div>
                    </ActionsCard>
                </div>
            </div>
        </PermissionedWrapper>
    );
};

export default RankingDashboard;
