import { API_URL } from 'config/constants';

interface DeleteCapitalProjectReturnValue {
    message: string;
}

const deleteCapitalProject = async (
    entity_code: string,
    id: string
): Promise<DeleteCapitalProjectReturnValue> => {
    const response = await fetch(
        `${API_URL}/capital-project/${entity_code}/${id}`,
        {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
        }
    );

    if (!response.ok) {
        throw new Error('Failed to delete capital project');
    }

    const { data } = await response.json();

    return data as DeleteCapitalProjectReturnValue;
};

export default deleteCapitalProject;
