import { API_URL } from 'config/constants';
import { AccountTreeNode } from 'waypoint-types';

const getCrosstabAccountTree = async (): Promise<AccountTreeNode[]> => {
    const response = await fetch(`${API_URL}/crosstab/account-tree`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
    });

    if (!response.ok) {
        throw new Error('Failed');
    }

    const {
        data: { children: nodes },
    } = await response.json();
    return nodes;
};

export default getCrosstabAccountTree;
