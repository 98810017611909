import { API_URL } from 'config/constants';
import { DocumentUpload } from 'waypoint-types';

const getDocumentUploadByReferenceParams = async (
    referenceType: string,
    referenceId: string,
    requireMultiple = false
): Promise<DocumentUpload> => {
    if (!referenceId) {
        return {} as DocumentUpload;
    }
    const response = await fetch(
        requireMultiple
            ? `${API_URL}/document-uploads/list/${referenceType}/${referenceId}`
            : `${API_URL}/document-uploads/${referenceType}/${referenceId}`,
        {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        }
    );

    if (!response.ok) {
        throw new Error('FAILED');
    }
    const { data } = await response.json();

    return data as DocumentUpload;
};

export default getDocumentUploadByReferenceParams;
