import { API_URL } from 'config/constants';
import { LeaseRSFAndUnits } from 'waypoint-types';

/**
 * @param {array} entityCodes
 * @returns rentable sq ft and total units for property or property group
 */
const getRSFAndUnits = async (
    entityCodes: string[]
): Promise<LeaseRSFAndUnits> => {
    const response = await fetch(`${API_URL}/leases/rsf-and-units`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            entity_codes: entityCodes,
        }),
    });

    if (!response.ok) {
        throw new Error('FAILED');
    }
    const { data } = await response.json();
    return data;
};

export default getRSFAndUnits;
