import React, { useState, useEffect } from 'react';
import {
    ReportWidgetSettingsBuilderProps,
    SavedConfigFilterType,
} from 'waypoint-types';
import ReportWidgetSettingsModal from 'components/reports/report-widget/ReportWidgetSettingsModal';
import { useGridConfigSettings } from 'waypoint-hooks';
import { Select } from 'antd';
import { SavedGridConfigSelect } from 'components/saved-configurations';

const ContractsSettingsBuilder = ({
    entityCodes,
    isModalOpen,
    setIsModalOpen,
    onSaveWidgetSettings,
    widgetType,
    currentSettings,
}: ReportWidgetSettingsBuilderProps): JSX.Element => {
    const [selectedStatus, setSelectedStatus] = useState<string[]>(
        currentSettings?.selectedStatus,
    );
    const [isStatusValid, setIsStatusValid] = useState(true);

    const {
        selectedConfiguration,
        setSelectedConfiguration,
        savedConfigurationsData,
    } = useGridConfigSettings(SavedConfigFilterType.Contracts);

    useEffect(() => {
        if (currentSettings?.savedConfigId) {
            setSelectedConfiguration(
                savedConfigurationsData?.find(
                    (config) => config.id === currentSettings.savedConfigId,
                ) ?? null,
            );
        }
    }, [currentSettings, savedConfigurationsData]);

    const buildReportWidgetSettingsInputs = () => {
        return {
            selectedStatus,
            savedConfigId: selectedConfiguration?.id ?? '',
        };
    };

    const onSave = () => {
        if (!selectedStatus) {
            setIsStatusValid(false);
            return;
        }
        onSaveWidgetSettings(buildReportWidgetSettingsInputs());
    };

    return (
        <ReportWidgetSettingsModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            settingsJsonBuilder={buildReportWidgetSettingsInputs}
            onSaveWidgetSettings={onSave}
            widgetType={widgetType}
            data-testid="contracts-settings-report-modal"
        >
            <div style={{ marginBottom: '25px' }}>
                <div
                    style={{
                        fontSize: '12px',
                        fontWeight: 'bold',
                    }}
                >
                    Saved Grid Configuration
                </div>
                <SavedGridConfigSelect
                    key={selectedConfiguration?.id ?? null}
                    savedConfigurationsData={savedConfigurationsData}
                    selectedConfiguration={selectedConfiguration}
                    setSelectedConfiguration={setSelectedConfiguration}
                    excludeUserConfigs
                />
                <div style={{ fontSize: '12px', fontWeight: 'bold' }}>
                    Status
                </div>
                <Select
                    allowClear
                    mode="multiple"
                    placeholder="Select a status"
                    style={{ width: '100%', marginTop: 6 }}
                    onChange={(value) => {
                        if (value) {
                            setSelectedStatus(value);
                        }
                    }}
                    value={selectedStatus}
                    options={[
                        {
                            value: 'active',
                            label: 'Active',
                        },
                        {
                            value: 'inactive',
                            label: 'Inactive',
                        },
                    ]}
                    data-testid="contract-settings-builder"
                />
                {!isStatusValid && (
                    <div style={{ color: 'red', marginTop: '8px' }}>
                        You need to select a status.
                    </div>
                )}
            </div>
        </ReportWidgetSettingsModal>
    );
};

export default ContractsSettingsBuilder;
