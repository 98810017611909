import React, { useState } from 'react';
import { CommentsContextType } from './CommentsContext';
import { ManagerProviderContext } from './CommentsManagerContext';

export const CommentsContextManagerProvider = ({
    children,
}: {
    children: JSX.Element;
}) => {
    const [commentContexts, setCommentContexts] = useState<
        Map<string, CommentsContextType>
    >(new Map());

    const commentThreadIdContextExists = (componentKey: string) => {
        return commentContexts?.has(componentKey);
    };

    const registerCommentThread = (
        context: CommentsContextType,
        componentKey: string
    ) => {
        if (
            commentThreadIdContextExists(componentKey) ||
            !context.commentThreadId.length
        ) {
            return;
        }

        setCommentContexts(commentContexts.set(componentKey, context));
    };

    const closeOtherPanes = (commentThreadId: string) => {
        commentContexts?.forEach(
            (ctx) =>
                ctx.commentThreadId !== commentThreadId && ctx.closeComments()
        );
    };

    return (
        <ManagerProviderContext.Provider
            value={{
                registerCommentThread,
                commentContexts,
                closeOtherPanes,
            }}
        >
            {children}
        </ManagerProviderContext.Provider>
    );
};
