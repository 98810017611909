import { get } from 'lodash';
import TextWithUnit from 'components/leases/components/TextWithUnit'; // TODO: Move new folder
import CarouselLeaseCard from 'components/leases/expirations/cards/carousel-lease-card/CarouselLeaseCard';
import LeaseSummaryCard from 'components/leases/expirations/cards/lease-summary/LeaseSummaryCard';
import WaleTooltip from 'components/leases/components/WaleTooltip';
import { LeaseSummaryType, RecurringCharge } from 'waypoint-types';
import {
    formatMoney,
    formatNumberToDecimalPlace,
    waleFormat,
    formatNumber,
    formatPercentage,
} from 'utils/formatters';
import { safeDivision } from 'waypoint-utils';
import { getRecurringChargeTotals } from 'components/leases/components/recurring-charge/utils';
import { DASH_DASH } from 'config/constants';

const multipleUnitsStyle = { paddingBottom: '10px' };

const waleTitle = 'WALE';
const bannerTotalTitle = 'Units / Leases';
const bannerOccupancy = '% Occupied';
const bannerSqFt = 'Square Footage';
const bannerAnnualCharges = 'Annual Charges';
const bannerMonthlyCharges = 'Monthly Charges';

export const getLeaseStatCards = (
    leaseSummary: LeaseSummaryType | undefined,
    recurringCharges: RecurringCharge[],
    occupiedUnits: number
) => {
    const { totalAnnual, totalMonthly } =
        getRecurringChargeTotals(recurringCharges);

    return [
        {
            label: bannerTotalTitle,
            key: 'total_units',
            component: CarouselLeaseCard,
            content: [
                <TextWithUnit
                    key="units"
                    qty={formatNumber(get(leaseSummary, 'total_units'))}
                    unit="units"
                    style={multipleUnitsStyle}
                />,
                <TextWithUnit
                    key="leases"
                    qty={formatNumber(get(leaseSummary, 'total_leases'))}
                    unit="leases"
                    style={multipleUnitsStyle}
                />,
            ],
        },
        {
            label: waleTitle,
            key: 'wale',
            component: LeaseSummaryCard,
            tooltip: WaleTooltip,
            content: [
                <TextWithUnit
                    key="years"
                    qty={waleFormat(get(leaseSummary, 'wale'))}
                    unit="yrs"
                />,
            ],
        },
        {
            label: bannerSqFt,
            key: 'rentable_square_footage',
            component: CarouselLeaseCard,
            content: [
                <TextWithUnit
                    key="occupied"
                    qty={formatNumberToDecimalPlace(
                        get(leaseSummary, 'occupied_square_footage'),
                        0
                    )}
                    unit="occ."
                    style={multipleUnitsStyle}
                />,
                <TextWithUnit
                    key="rentable"
                    qty={formatNumberToDecimalPlace(
                        get(leaseSummary, 'rentable_square_footage'),
                        0
                    )}
                    unit="rentable"
                    style={multipleUnitsStyle}
                />,
            ],
        },
        {
            label: bannerOccupancy,
            key: 'occupancy_percentage',
            component: LeaseSummaryCard,
            content: [
                <TextWithUnit
                    key="occupancy"
                    qty={
                        leaseSummary?.occupancy_percentage
                            ? formatPercentage(
                                  leaseSummary.occupancy_percentage * 100,
                                  1
                              )
                            : `${DASH_DASH}%`
                    }
                    unit={null}
                />,
            ],
        },
        {
            label: bannerAnnualCharges,
            key: 'annual',
            component: CarouselLeaseCard,
            content: [
                <TextWithUnit
                    key="Gross"
                    qty={
                        totalAnnual ? formatMoney(totalAnnual) : `$${DASH_DASH}`
                    }
                    unit={null}
                    style={multipleUnitsStyle}
                />,
                <TextWithUnit
                    key="Occupied"
                    qty={
                        leaseSummary?.occupied_square_footage
                            ? formatMoney(
                                  safeDivision(
                                      totalAnnual,
                                      leaseSummary.occupied_square_footage
                                  )
                              )
                            : `$${DASH_DASH}`
                    }
                    unit="/occ. sf"
                    style={multipleUnitsStyle}
                />,
                <TextWithUnit
                    key="Per Unit"
                    qty={
                        leaseSummary?.total_units
                            ? formatMoney(
                                  safeDivision(
                                      totalAnnual,
                                      leaseSummary.total_units
                                  )
                              )
                            : `$${DASH_DASH}`
                    }
                    unit="/unit"
                    style={multipleUnitsStyle}
                />,
                <TextWithUnit
                    key="Per Occupied Unit"
                    qty={
                        occupiedUnits
                            ? formatMoney(totalAnnual / occupiedUnits)
                            : `$${DASH_DASH}`
                    }
                    unit="/occ. unit"
                    style={multipleUnitsStyle}
                />,
            ],
        },
        {
            label: bannerMonthlyCharges,
            key: 'monthly',
            component: CarouselLeaseCard,
            content: [
                <TextWithUnit
                    key="base-rent"
                    qty={
                        totalMonthly
                            ? formatMoney(totalMonthly)
                            : `$${DASH_DASH}`
                    }
                    unit={null}
                    style={multipleUnitsStyle}
                />,
                <TextWithUnit
                    key="occupied-sqft"
                    qty={
                        leaseSummary?.occupied_square_footage
                            ? formatMoney(
                                  safeDivision(
                                      totalMonthly,
                                      leaseSummary.occupied_square_footage
                                  )
                              )
                            : `$${DASH_DASH}`
                    }
                    unit="/occ. sf"
                    style={multipleUnitsStyle}
                />,
                <TextWithUnit
                    key="Per Unit"
                    qty={
                        leaseSummary?.total_units
                            ? formatMoney(
                                  safeDivision(
                                      totalMonthly,
                                      leaseSummary.total_units
                                  )
                              )
                            : `$${DASH_DASH}`
                    }
                    unit="/unit"
                    style={multipleUnitsStyle}
                />,
                <TextWithUnit
                    key="Per Occupied Unit"
                    qty={
                        occupiedUnits
                            ? formatMoney(totalMonthly / occupiedUnits)
                            : `$${DASH_DASH}`
                    }
                    unit="/occ. unit"
                    style={multipleUnitsStyle}
                />,
            ],
        },
    ];
};
