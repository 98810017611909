import { API_URL } from 'config/constants';

const donwloadUsersSummaryList = async (
    clientId: string
): Promise<Response> => {
    const response = await fetch(
        `${API_URL}/client/${clientId}/usersSummary/download`,
        {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'text/csv',
            },
        }
    );

    if (!response.ok) {
        throw new Error('FAILED');
    }

    return response;
};

export default donwloadUsersSummaryList;
