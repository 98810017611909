import { API_URL } from 'config/constants';
import { DebtAndValueKpis, SelectedDataLevel } from 'waypoint-types';

export async function getValueAndDebt(
    entityCode: string[],
    periods: string[],
    selectedDataLevel: SelectedDataLevel
): Promise<DebtAndValueKpis> {
    const response = await fetch(`${API_URL}/properties/value-and-debt-kpis`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
            entityCode,
            periods,
            selectedDataLevel,
        }),
    });

    if (!response.ok) {
        throw new Error('Failed to get value and debt');
    }

    const { data } = await response.json();

    return data as DebtAndValueKpis;
}
