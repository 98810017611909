/**
 * Calculate the weighted average of a collection of records.
 * @param rows records to calculate against
 * @param getScale
 * @param getValue
 */
export function calculateWeightedAverage<R>(
    rows: R[],
    getScale: (data: R) => number,
    getValue: (data: R) => number | null
): number {
    let sumProduct = 0;
    let numRecords = 0;

    for (const row of rows) {
        const scaleFactor = getScale(row);
        const rowValue = getValue(row);
        if (!Number.isFinite(scaleFactor) || !Number.isFinite(rowValue)) {
            continue;
        }

        numRecords += Number(scaleFactor);
        sumProduct += Number(scaleFactor) * Number(rowValue);
    }

    return sumProduct / Math.max(numRecords, 1);
}

/**
 * Calculate {@param getScale} * {@param getMetric}
 * @param record record data
 * @param getScale left hand side for SUMPRODUCT
 * @param getMetric getter for right-hand side of the SUMPRODUCT
 */
export function calculateWeightedProduct<R>(
    record: R,
    getScale: (data: R) => number,
    getMetric: (data: R) => number
): number {
    const scale = getScale(record);
    const metric = getMetric(record);
    if (!metric || !Number.isFinite(metric)) {
        return 0;
    }

    const value = Number(metric);
    return scale * value;
}
