export enum PropertyDetailAttributeEnum {
    Amenities = 'amenities',
    NumberOfSpaces = 'number_of_spaces',
    ParkingNotes = 'parking_notes',
    PurchasePrice = 'purchase_price',
    PurchaseDate = 'purchase_date',
    KpiPreferences = 'entity_data_normalization_preference',
    ValueDebtPeriodTiming = 'entity_data_timing_preference',
    YearBuilt = 'year_built',
    LastRenovated = 'last_renovated',
    SiteArea = 'site_area',
    Certifications = 'certifications',
    FinancialYearEnding = 'financial_year_ending',
    GavSource = 'entity_gav_source_preference',
    RateType = 'rate_type',
    AcquisitionCapRate = 'acq_cap',
    Levered = 'levered',
    Lender = 'lender',
    DebtMaturityDate = 'maturity_date',
    AssetManager = 'asset_manager',
    DebtNotes = 'debt_notes',
    PortfolioManager = 'port_manager',
    Floors = 'number_floors',
    PropertyManager = 'prop_manager',
    BuildingCount = 'number_buildings',
    Analyst = 'analyst',
    ManagementCompany = 'pm_company',
    ConstructionType = 'construction_type',
    StreetAddress = 'street_address',
    City = 'city',
    State = 'state',
    PostalCode = 'postal_code',
    AssetType = 'asset_type',
    EntityStatus = 'entity_status',
    InterestRate = 'interest_rate',
}

export enum PropertyProfileGavSources {
    BALANCE_SHEET = 'balance_sheet',
    VALUATIONS = 'valuations',
}

export const valuationsGavSource = 'Valuations';
export const balanceSheetGavSource = 'Balance Sheet';
