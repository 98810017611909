import { SelectBox } from 'devextreme-react';
import DataGrid, {
    Column,
    ColumnChooser,
    Editing,
    Export,
    GroupPanel,
    Grouping,
    HeaderFilter,
    Paging,
    RequiredRule,
    SearchPanel,
    Sorting,
    Format,
    Pager,
    Summary,
    TotalItem,
    GroupItem,
    Item as ToolbarItem,
    Toolbar,
    SortByGroupSummaryInfo,
    StateStoring,
    FilterPanel,
    CustomRule,
    ColumnChooserSelection,
    Position,
} from 'devextreme-react/data-grid';
import {
    ColumnEditCellTemplateData,
    InitializedEvent,
} from 'devextreme/ui/data_grid';
import { ExpandAndCollapseButton, SelectionsBanner } from 'waypoint-react';
import React, { useEffect } from 'react';
import { KeyedMutator } from 'swr';
import { stringSort } from 'utils/tables/sorters';
import {
    Contract,
    ContractsWithVendorAndServiceTypeOptions,
    PropertyDetailsProps,
    SavedConfigFilterType,
    SavedConfiguration,
    SavedConfigurationState,
} from 'waypoint-types';
import {
    ClientSelectableOptions,
    TypeOfContractsView,
} from 'components/contracts/interfaces';
import {
    calculateCustomSummary,
    formatBooleanValues,
    formatDateValues,
    validateEditRowDate,
} from './utils';

import {
    useContractsConfig,
    useDataGridHeight,
    useGridConfigSettings,
    useSortByGroupSummaryInfo,
} from 'waypoint-hooks';
import { booleanFilterData, statusFilterData, statusOptions } from './consts';
import {
    SavedGridConfigSelect,
    SavedConfigManageDropdown,
    SavedConfigEditor,
    createSavedConfigurationPayload,
    applyStoredConfiguration,
} from 'components/saved-configurations';
import { FilterReferenceTypes } from 'contexts';
import { onContentReady } from 'waypoint-utils';
import { ServiceContractsExportableGrid } from 'components/reports/components/entity-report-widgets/report-widget-export-grids/ServiceContractsExportableGrid';

interface ContractsTableProps {
    entityCodes: string[];
    data: ContractsWithVendorAndServiceTypeOptions;
    mutate: KeyedMutator<ContractsWithVendorAndServiceTypeOptions>;
    typeOfView: TypeOfContractsView;
    setIsReadyForPDFExport?: (isReady: boolean) => void;
    widgetStatus?: string[];
    booleanColumnNames?: string[];
    leasesPerformanceData?: PropertyDetailsProps[];
    configSelectedForWidget?: SavedConfiguration | null;
    isReportWidget?: boolean;
    isPDFExport?: boolean;
}

enum ClientSelectableOptionTypes {
    Vendor = 'vendor',
    EntityCode = 'entity_code',
    ServiceType = 'service_type',
}

export const ContractsTable = ({
    entityCodes,
    data,
    mutate,
    setIsReadyForPDFExport,
    widgetStatus,
    leasesPerformanceData = [],
    typeOfView = TypeOfContractsView.PropertyProfilePage,
    configSelectedForWidget,
    isReportWidget = false,
    isPDFExport,
}: ContractsTableProps) => {
    const {
        isEditing,
        setIsEditing,
        dataGrid,
        expanded,
        setExpanded,
        toggleExpanded,
        expandButtonEnable,
        setExpandButtonEnable,
        isMultiplePropertiesPage,
        contractsData,
        propertyOptionsData,
        serviceTypeOptions,
        vendorOptions,
        propertyOptions,
        onRowRemoved,
        onRowPrepared,
        onRowSaved,
        onExporting,
    } = useContractsConfig({
        leasesPerformanceData,
        data,
        entityCodes,
        typeOfView,
        mutate,
    });

    const dataGridHeight = useDataGridHeight({
        cardTitleHeight: 44,
        cardOuterPadding: 24,
        cardInnerPadding: 24,
    });

    const tableHeight =
        typeOfView === TypeOfContractsView.PropertyProfilePage
            ? 550
            : dataGridHeight;

    const { sortSelection, sortOrderAscending, toggleSortSettings } =
        useSortByGroupSummaryInfo();

    const {
        selectedConfiguration,
        setSelectedConfiguration,
        setGridConfig,
        setLocalConfig,
        saveConfigDisabled,
        configKey,
        savedConfigurationsData,
        resetSelectedConfiguration,
        onDeleteConfig,
        onSaveConfig,
        onUpdateConfig,
        isAdmin,
        isEditorOpen,
        setIsEditorOpen,
        existingConfigNames,
    } = useGridConfigSettings(SavedConfigFilterType.Contracts);

    useEffect(() => {
        configSelectedForWidget &&
            setSelectedConfiguration(configSelectedForWidget);
    }, [configSelectedForWidget]);

    useEffect(() => {
        setLocalConfig({ expanded });
    }, [expanded]);

    useEffect(() => {
        if (selectedConfiguration?.filters_json?.local_config) {
            const config = selectedConfiguration.filters_json.local_config;
            config['expanded'] !== undefined && setExpanded(config['expanded']);
            setLocalConfig(config);
            return;
        }
        setExpanded(false);
        setLocalConfig({ expanded });
    }, [selectedConfiguration, configKey]);

    const saveState = (state: SavedConfigurationState) => {
        const config = createSavedConfigurationPayload(state);
        config && setGridConfig(config);
    };

    const statusEditorRender = (cell: ColumnEditCellTemplateData) => {
        return (
            <SelectBox
                defaultValue={cell.value}
                onValueChanged={(e) => cell.setValue(e.value)}
                items={statusOptions}
                displayExpr="name"
                valueExpr="value"
                searchEnabled={true}
            />
        );
    };
    const clientOptionsEditorRender = (cell: ColumnEditCellTemplateData) => {
        let optionItems: ClientSelectableOptions[] = [];
        const dataFieldType = cell.column.dataField;

        switch (dataFieldType) {
            case ClientSelectableOptionTypes.ServiceType:
                optionItems = [...serviceTypeOptions];
                break;
            case ClientSelectableOptionTypes.Vendor:
                optionItems = [...vendorOptions];
                break;
            case ClientSelectableOptionTypes.EntityCode:
                optionItems = [...propertyOptions];
                break;
        }

        const onCustomItemAdded = (
            args: any, // if there's a devextreme type for this, i can't find it
            optionItems: ClientSelectableOptions[],
        ) => {
            args.customItem = {
                name: args.text,
                value: args.text,
            };
            if (
                !Object.values(optionItems)
                    .map((item) => item.name)
                    .includes(args.text)
            ) {
                optionItems.push(args.customItem);
                optionItems.sort((a, b) => stringSort(b.name, a.name));
            }
        };

        return (
            <SelectBox
                defaultValue={cell.value}
                placeholder="Select or Add Value"
                onValueChanged={(e) => {
                    cell.setValue(e.value);
                }}
                dropDownOptions={{ maxHeight: 300 }}
                items={optionItems}
                displayExpr={'name'}
                valueExpr={'value'}
                acceptCustomValue={
                    dataFieldType !== ClientSelectableOptionTypes.EntityCode
                }
                searchEnabled={true}
                onCustomItemCreating={(e) => onCustomItemAdded(e, optionItems)}
            />
        );
    };

    if (isPDFExport && setIsReadyForPDFExport) {
        return (
            <ServiceContractsExportableGrid
                key={selectedConfiguration?.id ?? ''}
                contractsData={contractsData ?? []}
                savedConfig={selectedConfiguration}
                setIsReadyForPDFExport={setIsReadyForPDFExport}
                propertyOptions={propertyOptions}
            />
        );
    }

    return (
        <div style={{ height: '100%', overflowX: 'scroll' }}>
            <SelectionsBanner
                isCollapsed={false}
                bannerInlineStyles={{
                    boxShadow: 'none',
                    border: 'none',
                }}
            >
                {!isReportWidget && (
                    <div style={{ display: 'flex', marginLeft: 8 }}>
                        <SavedGridConfigSelect
                            savedConfigurationsData={savedConfigurationsData}
                            selectedConfiguration={selectedConfiguration}
                            setSelectedConfiguration={setSelectedConfiguration}
                        />
                        <SavedConfigManageDropdown
                            onUpdateConfig={onUpdateConfig}
                            onDeleteConfig={onDeleteConfig}
                            resetSelectedConfiguration={
                                resetSelectedConfiguration
                            }
                            hasUserAccess={
                                !isAdmin &&
                                selectedConfiguration?.reference_type !==
                                    FilterReferenceTypes.USER
                            }
                            allowDeleteOnly={saveConfigDisabled}
                            defaultConfigSelected={!selectedConfiguration}
                            setIsEditorOpen={setIsEditorOpen}
                        />
                    </div>
                )}

                {isEditorOpen && (
                    <SavedConfigEditor
                        handleClose={() => setIsEditorOpen(false)}
                        onSave={onSaveConfig}
                        isAdmin={isAdmin}
                        existingConfigNames={existingConfigNames}
                    />
                )}
            </SelectionsBanner>
            <DataGrid
                key={`${selectedConfiguration?.id ?? ''}_${configKey ?? ''}`}
                ref={dataGrid}
                id="contracts-table"
                dataSource={contractsData}
                showBorders={true}
                allowColumnReordering={true}
                wordWrapEnabled={true}
                width={'100%'}
                height={tableHeight}
                hoverStateEnabled={true}
                onInitNewRow={() => setIsEditing(true)}
                onEditingStart={() => setIsEditing(true)}
                onEditCanceled={() => setIsEditing(false)}
                onRowPrepared={onRowPrepared}
                onRowRemoved={onRowRemoved}
                onRowInserted={onRowSaved}
                onRowUpdated={onRowSaved}
                onExporting={onExporting}
                onContentReady={(e) => {
                    if (
                        e?.component?.getDataSource() &&
                        setIsReadyForPDFExport
                    ) {
                        setIsReadyForPDFExport(true);
                    }
                    onContentReady({ e, toggleFunc: setExpandButtonEnable });
                }}
                onKeyDown={(e) => {
                    if (e?.event?.key === 'Enter') {
                        e.event.preventDefault();
                    }
                }}
                onOptionChanged={toggleSortSettings}
                onInitialized={(e: InitializedEvent) => {
                    if (selectedConfiguration && e?.component) {
                        e.component.clearSorting();
                        applyStoredConfiguration(
                            e.component,
                            selectedConfiguration,
                        );
                    }
                }}
            >
                <SortByGroupSummaryInfo
                    summaryItem={sortSelection}
                    sortOrder={sortOrderAscending ? 'asc' : 'desc'}
                />
                <Editing
                    mode="row"
                    allowUpdating={true}
                    allowDeleting={!isEditing}
                    allowAdding={true}
                    confirmDelete={true}
                />
                <Export enabled={!isEditing} allowExportSelectedData={false} />
                <StateStoring
                    enabled={!isReportWidget}
                    type="custom"
                    savingTimeout={100}
                    customSave={saveState}
                />
                <ColumnChooser
                    enabled={!isEditing}
                    mode={'select'}
                    height={350}
                    allowSearch={true}
                >
                    <Position
                        my="right top"
                        at="right bottom"
                        of=".dx-datagrid-column-chooser-button"
                    />
                    <ColumnChooserSelection
                        allowSelectAll={true}
                        recursive={true}
                    />
                </ColumnChooser>
                <HeaderFilter allowSelectAll={true} visible={!isEditing} />
                <FilterPanel visible={!isReportWidget} />

                <Sorting mode="single" />
                <Paging enabled={!isEditing} defaultPageSize={20} />
                <Pager
                    visible={!isEditing}
                    showPageSizeSelector={true}
                    allowedPageSizes={[20, 40, 60, 80, 100]}
                    showNavigationButtons={true}
                    showInfo={true}
                    infoText="Page {0} of {1} ({2} items)"
                />
                <Grouping contextMenuEnabled={true} autoExpandAll={expanded} />
                <GroupPanel visible={'auto'} />
                <SearchPanel
                    visible={!isEditing}
                    width={240}
                    placeholder="Search..."
                />
                <Toolbar>
                    <ToolbarItem location="before" name="groupPanel" />
                    <ToolbarItem location="before">
                        <ExpandAndCollapseButton
                            expanded={expanded}
                            toggleExpanded={toggleExpanded}
                            expandButtonEnable={expandButtonEnable}
                        />
                    </ToolbarItem>
                    <ToolbarItem name="addRowButton" />
                    <ToolbarItem name="exportButton" />
                    <ToolbarItem location="after" name="columnChooserButton" />
                    <ToolbarItem name="searchPanel" location="after" />
                </Toolbar>

                <Column
                    dataField="service_type"
                    dataType="string"
                    caption="Type of Service"
                    alignment={'left'}
                    minWidth={200}
                    fixed={true}
                    fixedPosition={'left'}
                    editCellRender={clientOptionsEditorRender}
                >
                    <RequiredRule />
                </Column>

                <Column
                    dataField="entity_code"
                    calculateDisplayValue={(contractRow: Contract) =>
                        propertyOptions?.find(
                            (po: ClientSelectableOptions) =>
                                po.value === contractRow.entity_code,
                        )?.name ?? ''
                    }
                    dataType="string"
                    caption="Property"
                    alignment={'left'}
                    minWidth={200}
                    fixedPosition={'left'}
                    visible={isMultiplePropertiesPage}
                    editCellRender={clientOptionsEditorRender}
                >
                    <HeaderFilter
                        allowSearch={true}
                        allowSelectAll={true}
                        dataSource={propertyOptionsData}
                    ></HeaderFilter>
                    {isMultiplePropertiesPage && <RequiredRule />}
                </Column>

                <Column
                    allowGrouping={false}
                    dataField="total_rentable_sq_ft_per_property"
                    dataType="number"
                    caption="RSF"
                    alignment={'center'}
                    minWidth={140}
                    allowHeaderFiltering={false}
                    allowEditing={false}
                >
                    <Format type="fixedPoint" precision={0} />
                </Column>
                <Column
                    dataField="vendor"
                    dataType="string"
                    caption="Vendor"
                    alignment={'left'}
                    minWidth={200}
                    editCellRender={clientOptionsEditorRender}
                >
                    <RequiredRule />
                </Column>
                <Column
                    dataField="status"
                    caption="Status"
                    alignment={'center'}
                    filterValues={widgetStatus}
                    minWidth={115}
                    customizeText={({ value }: { value: string }) =>
                        value && value.charAt(0).toUpperCase() + value.slice(1)
                    }
                    cellRender={(cellData) =>
                        cellData.value?.slice(0, 1).toUpperCase() +
                        cellData.value?.slice(1, cellData.value?.length)
                    }
                    editCellRender={statusEditorRender}
                >
                    <HeaderFilter dataSource={statusFilterData} />
                    <RequiredRule />
                </Column>
                <Column
                    dataField="minority_owned"
                    dataType={isEditing ? 'boolean' : 'string'}
                    caption="Minority Owned"
                    alignment={'center'}
                    minWidth={105}
                    customizeText={formatBooleanValues}
                >
                    <HeaderFilter dataSource={booleanFilterData} />
                </Column>
                <Column
                    dataField="managers_standard_contract"
                    dataType={isEditing ? 'boolean' : 'string'}
                    caption="Mgr's Std Contract"
                    alignment={'center'}
                    minWidth={105}
                    customizeText={formatBooleanValues}
                >
                    <HeaderFilter dataSource={booleanFilterData} />
                </Column>
                <Column
                    dataField="national_or_portfolio_discount"
                    dataType={isEditing ? 'boolean' : 'string'}
                    caption="Nat'l / Port. Disc. "
                    alignment={'center'}
                    minWidth={90}
                    customizeText={formatBooleanValues}
                >
                    <HeaderFilter dataSource={booleanFilterData} />
                </Column>
                <Column
                    allowGrouping={false}
                    dataField="last_bid_date"
                    dataType="date"
                    caption="Last Bid Date"
                    alignment={'center'}
                    minWidth={90}
                    allowHeaderFiltering={false}
                    customizeText={formatDateValues}
                >
                    <CustomRule
                        message='Date must have "mm/dd/yyyy" format!'
                        validationCallback={validateEditRowDate}
                    />
                    <Format type="shortDate" />
                </Column>
                <Column
                    allowGrouping={false}
                    dataField="current_commencement_date"
                    dataType="date"
                    caption="Current Comm. Date"
                    alignment={'center'}
                    minWidth={90}
                    allowHeaderFiltering={false}
                    customizeText={formatDateValues}
                >
                    <CustomRule
                        message='Date must have "mm/dd/yyyy" format!'
                        validationCallback={validateEditRowDate}
                    />
                    <Format type="shortDate" />
                </Column>
                <Column
                    dataField="cleared_ofac"
                    dataType={isEditing ? 'boolean' : 'string'}
                    caption="Cleared OFAC"
                    alignment={'center'}
                    minWidth={100}
                    customizeText={formatBooleanValues}
                >
                    <HeaderFilter dataSource={booleanFilterData} />
                </Column>
                <Column
                    allowGrouping={false}
                    dataField="annual_cost"
                    dataType="number"
                    caption="Annual Cost"
                    alignment={'center'}
                    minWidth={90}
                    allowHeaderFiltering={false}
                >
                    <Format type="currency" precision={0} />
                </Column>
                <Column
                    allowGrouping={false}
                    dataField="annual_cost_rsf"
                    dataType="number"
                    caption="Cost/RSF"
                    alignment={'center'}
                    minWidth={90}
                    allowHeaderFiltering={false}
                    allowEditing={false}
                >
                    <Format type="currency" precision={2} />
                </Column>
                <Column
                    dataField="contract_auto_renew"
                    dataType={isEditing ? 'boolean' : 'string'}
                    caption="Auto Renew"
                    alignment={'center'}
                    minWidth={95}
                    customizeText={formatBooleanValues}
                >
                    <HeaderFilter dataSource={booleanFilterData} />
                </Column>
                <Column
                    dataField="cancel_provision"
                    dataType={isEditing ? 'boolean' : 'string'}
                    caption="Cancel Prov."
                    alignment={'center'}
                    minWidth={95}
                    customizeText={formatBooleanValues}
                >
                    <HeaderFilter dataSource={booleanFilterData} />
                </Column>
                <Column
                    allowGrouping={false}
                    dataField="general_liability_insurance_expiration_date"
                    dataType="date"
                    caption="GL Ins. Exp. Date"
                    alignment={'center'}
                    minWidth={90}
                    allowHeaderFiltering={false}
                    customizeText={formatDateValues}
                >
                    <CustomRule
                        message='Date must have "mm/dd/yyyy" format!'
                        validationCallback={validateEditRowDate}
                    />
                    <Format type="shortDate" />
                    <HeaderFilter dataSource={booleanFilterData} />
                </Column>
                <Column
                    dataField="insurance_auto_renew"
                    dataType={isEditing ? 'boolean' : 'string'}
                    caption="Ins. Auto Renew"
                    alignment={'center'}
                    minWidth={95}
                    customizeText={formatBooleanValues}
                >
                    <HeaderFilter dataSource={booleanFilterData} />
                </Column>
                <Column
                    allowGrouping={false}
                    dataField="notes"
                    minWidth={350}
                    caption="Notes"
                    alignment={'left'}
                    allowHeaderFiltering={false}
                >
                    <HeaderFilter dataSource={booleanFilterData} />
                </Column>
                <Summary calculateCustomSummary={calculateCustomSummary}>
                    <GroupItem
                        name="service_type"
                        column="service_type"
                        summaryType="count"
                        showInColumn="service_type"
                        displayFormat="{0} Contracts"
                        valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        alignByColumn
                    />
                    <GroupItem
                        name="annual_cost_rsf"
                        showInColumn="annual_cost_rsf"
                        summaryType="custom"
                        displayFormat="{0}"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        alignByColumn
                    />
                    <GroupItem
                        name="annual_cost"
                        column="annual_cost"
                        summaryType="sum"
                        showInColumn="annual_cost"
                        displayFormat="{0}"
                        valueFormat={{ type: 'currency', precision: 0 }}
                        alignByColumn
                    />
                    <GroupItem
                        name="total_rentable_sq_ft_per_property"
                        showInColumn="total_rentable_sq_ft_per_property"
                        summaryType="custom"
                        displayFormat="{0}"
                        valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        alignByColumn
                    />
                    <TotalItem
                        name="service_type"
                        column="service_type"
                        summaryType="count"
                        valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        displayFormat="{0} Contracts"
                    />

                    <TotalItem
                        name="annual_cost"
                        column="annual_cost"
                        summaryType="sum"
                        valueFormat={{ type: 'currency', precision: 0 }}
                        displayFormat="{0}"
                    />

                    <TotalItem
                        name="total_rentable_sq_ft_per_property"
                        showInColumn="total_rentable_sq_ft_per_property"
                        summaryType="custom"
                        valueFormat={{ type: 'fixedPoint', precision: 0 }}
                        displayFormat="{0}"
                    />
                    <TotalItem
                        name="annual_cost_rsf"
                        showInColumn="annual_cost_rsf"
                        summaryType="custom"
                        displayFormat="{0}"
                        valueFormat={{ type: 'currency', precision: 2 }}
                    />
                </Summary>
            </DataGrid>
        </div>
    );
};
