import { css } from 'emotion';
import { PropertyDetailsPropsWithTotals } from 'waypoint-types';

export const removeCardHeaderClass = css`
    .ant-card-head {
        border: none;
        height: 0;
        min-height: 0 !important;
    }
    box-shadow: none;
`;

export const LEASES_ERROR_TEXT = 'There was an error with the request';
export const LEASES_ICON_LINK = './assets/img/Leases-Empty-Icon@2x.png';
export const LEASES_GROUP_DISABLED_HEADER =
    'Leasing data for groups is not available at this time';
export const LEASES_GROUP_DISABLED_SUB_HEADER =
    'For lease data, please refer to an individual property';

export const EXPIRATION_SCHEDULE_TABLE_HEIGHT = 430; // use EXPIRATION_DASHBOARD_DEVEXTREME_TABLE_HEIGHT when converting to dev extreme
export const EXPIRATION_DASHBOARD_DEVEXTREME_TABLE_HEIGHT = 605; // remove when converting expiration schedule card
export const EXPIRATION_DASHBOARD_LARGE_CARD_HEIGHT = 680;
export const EXPIRATION_DASHBOARD_SMALL_CARD_HEIGHT = 150;
export const PROPERTY_LEASE_TABLE = 'property-lease-details-table';

export type PropertyDetailsValuesType = {
    [key in Exclude<
        keyof PropertyDetailsPropsWithTotals,
        | 'key'
        | 'entity_code'
        | 'entity_codes'
        | 'monthly_in_place_base_rent_per_sq_ft'
        | 'in_place_base_rent_per_sq_ft'
        | 'property_name'
        | 'in_place_base_rent_sq_ft'
        | 'in_place_base_rent_occupied_sq_ft'
        | 'in_place_base_rent_pounit'
        | 'in_place_base_rent_punit'
        | 'monthly_in_place_base_rent_pounit'
        | 'monthly_in_place_base_rent_punit'
        | 'in_place_base_rent'
        | 'monthly_in_place_base_rent'
        | 'monthly_in_place_base_rent_occupied_sq_ft'
    >]: string;
};

// Shared variables
export const leasesIconLink = './assets/img/Leases-Empty-Icon@2x.png';
export const chartPrimaryXName = 'Rentable Area (sq ft)';
export const chartSecondaryXName = 'Cumulative % Vacancy';
export const leasesChartPalette =
    'F9CB79, F3A954, FCE08B, 5DB7AA, C8DE98, 66C2A5, 3588BD, DCDF95';
export const fadedLeasesChartPalette =
    'FAD5AB, FDE6BD, FEF0C6, 9CC5DF, B4E1D3, AFDCD5, E4EFCD, EEF0CB';
export const bannerTotalTitle = 'Total Leases';
export const bannerMonthlyTitle = 'Monthly In-Place Base Rent';
export const bannerAnnualTitle = 'Annual In-Place Base Rent';
export const periodOptions = { monthly: 'Monthly', annual: 'Annual' };
export const sqFtRentColumnTitle = 'In-Place Base Rent ($/sq ft)';
export const grossRentSqFtColumnTitle = 'In-Place Base Rent (gross $)';
export const periodColumn = {
    monthly: 'Month',
    annual: 'Year',
    decade: 'Decade',
};

// Property variables
export const tableTitle = 'Rent Roll';
export const chartTitle = 'Lease Expiration Schedule';
export const emptyStatePropertyHeader =
    'Leases for this property are not available at this time';
export const loadingChartHeader = 'We are loading your leasing data...';
export const loadingChartSubheader = 'This should only take a moment!';
export const propertyLeasesTableForm = 'propertyLeasesTable';
export const emptyChartHeader = 'No upcoming lease expirations';
export const emptyChartSubheader =
    'Please refer to the table below for details';
export const submissionNote =
    'All posts to Discussion are not published to report export';
export const commercialUnits = 'OCC. SQ FT';

// Group variables
export const groupTableTitle = 'Property Details';
export const emptyStateGroupHeader =
    'Leasing data for this group is not available at this time';
export const noFeatureGroupHeader = 'Please select a property to view leases';
export const noFeatureGroupSubheader =
    'For lease data, please refer to the individual property';
export const dropdownPlaceholderText = 'Select a property';
export const groupLeasesTableForm = 'groupLeasesTable';
export const groupLeasesDropdownForm = 'groupLeasesDropdown';

// Multifamily
export const multiFamilyChartPrimaryXName = 'Total Units';
export const multiFamilyFlag = 'Multi-Family';
export const multiFamilyUnits = 'UNIT';
export const unitRentColumnTitle = 'In-Place Base Rent (unit)';

export const propertyDetailsValues: PropertyDetailsValuesType = {
    lease_count: 'Lease Count',
    occupancy_rate: 'Occupancy %',
    occupied_sq_ft: 'Occupied Area',
    occupied_units: 'Occupied Units',
    rentable_sq_ft: 'Rentable SF',
    total_units: 'Total Units',
    vacant_area: 'Vacant Area',
    vacant_units: 'Vacant Units',
    wale: 'WALE',
    total_annual_per_sq_ft: 'Annual Charges Per Occupied SF',
    total_annual_per_unit: 'Annual Charges Per Unit',
    total_annual_per_occupied_unit: 'Annual Charges Per Occupied Unit',
    total_annual: 'Annual Charges',
    total_monthly_per_sq_ft: 'Monthly Charges Per Occupied SF',
    total_monthly_per_unit: 'Monthly Charges Per Unit',
    total_monthly_per_occupied_unit: 'Monthly Charges Per Occupied Unit',
    total_monthly: 'Monthly Charges',
};

export enum KPISortByOptions {
    ALPHABETICAL = 'alpha',
    LEFT_AXIS = 'left',
    RIGHT_AXIS = 'right',
}

export enum KPISortDirections {
    ASCENDING = 'asc',
    DESCENDING = 'desc',
}
