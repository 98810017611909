import { postData } from './fetchData';
import { API_URL } from 'config/constants';

export const getFinancialOverviewOvertime = ({
    entityCodes,
    selectedDataLevel,
    ...parameters
} = {}) => {
    const url = `${API_URL}/financial-overview/overtime`;
    return postData(url, {
        entity_codes: entityCodes,
        selected_data_level: selectedDataLevel,
        ...parameters,
    });
};

export const getOvertimeDownload = ({
    entityCodes,
    selectedDataLevel,
    ...parameters
} = {}) => {
    const url = `${API_URL}/financial-overview/overtime/download`;
    return postData(url, {
        entity_codes: entityCodes,
        selected_data_level: selectedDataLevel,
        ...parameters,
    });
};
