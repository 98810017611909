import { API_URL } from 'config/constants';
import { ClientOwnershipPercentageType } from 'waypoint-types';

const getClientOwnershipPercentageTypes = async (): Promise<
    ClientOwnershipPercentageType[]
> => {
    const response = await fetch(
        `${API_URL}/client/ownership-percentage-types`,
        {
            credentials: 'include',
        }
    );
    const { data } = await response.json();
    return data;
};

export default getClientOwnershipPercentageTypes;
