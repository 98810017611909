import React from 'react';
import {
    Button,
    Card,
    Image,
    Modal,
    message,
    List,
    Divider,
    Row,
    Col,
} from 'antd';
import { css } from 'emotion';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { LocalizationProvider } from '@progress/kendo-react-intl';
import {
    useGetDocumentUploadListByReferenceParams,
    useGetUserData,
} from 'waypoint-hooks';
import { deleteDocument } from 'waypoint-requests';
import { DocumentUpload } from 'waypoint-types';
import theme from 'config/theme';
import { DocumentUploads } from 'components/uploads/DocumentUploads';

const { confirm } = Modal;

const pageContainer = css`
    background-color: #ededed;
    margin: 0;
    padding: 22px 20px 20px 16px;
    overflow: visible;
    position: relative;
    min-height: 85%;
`;
const header = css`
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    color: #5e5e5e;
    line-height: 1.5;
`;

const MAX_FILE_SIZE_MB = 5;

const MAX_FILES_ALLOWED = 5;

const LOGO_UPLOAD_RESTRICTIONS = {
    allowedExtensions: ['.png', '.jpg'],
    maxFileSize: MAX_FILE_SIZE_MB * 1024 * 1024,
};

const CompanyLogo = () => {
    const { clientId, isAdmin } = useGetUserData();

    const {
        data: companyLogos,
        isLoading: isLoadingCompanyLogos,
        mutate,
    } = useGetDocumentUploadListByReferenceParams(
        'company_logo',
        `${clientId}`,
    );

    const deleteLogo = async (id: string) => {
        confirm({
            title: 'Are you sure you want to delete this logo?',
            icon: <ExclamationCircleOutlined />,
            content: 'This action cannot be undone.',
            centered: true,
            onOk() {
                mutate(
                    async () => {
                        await deleteDocument({
                            documentUploadIds: [id],
                            softDelete: true,
                        });
                        message.success('Logo deleted successfully.');
                        return companyLogos?.filter(
                            (item: DocumentUpload) => item.id !== id,
                        );
                    },
                    {
                        optimisticData: companyLogos,
                        rollbackOnError: true,
                        populateCache: false,
                        revalidate: true,
                    },
                );
            },
        });
    };

    return (
        <div className={pageContainer}>
            <Card
                title={<h1 className={header}>Company Logo</h1>}
                loading={isLoadingCompanyLogos}
                extra={<></>}
            >
                <Row gutter={24}>
                    <Col span={8}>
                        <div>
                            <LocalizationProvider language="custom">
                                <DocumentUploads
                                    documentsUploaded={companyLogos ?? []}
                                    mutateMany={mutate}
                                    restrictions={LOGO_UPLOAD_RESTRICTIONS}
                                    isModal={false}
                                    disabled={
                                        companyLogos?.length ===
                                            MAX_FILES_ALLOWED || !isAdmin
                                    }
                                    referenceType="company_logo"
                                    referenceId={`${clientId}`}
                                    maxFilesAllowed={MAX_FILES_ALLOWED}
                                />
                            </LocalizationProvider>
                        </div>
                    </Col>
                    <Col span={16}>
                        <div>
                            <h2 style={{ marginTop: 0 }}>Available Logos</h2>
                            <Divider
                                style={{
                                    borderBlockStartColor: theme.colors.black,
                                }}
                            />
                            <List
                                dataSource={companyLogos}
                                locale={{ emptyText: 'No logos uploaded' }}
                                renderItem={(item) => (
                                    <List.Item key={item.id}>
                                        <List.Item.Meta
                                            avatar={
                                                <Image
                                                    src={
                                                        item.document_cloud_url
                                                    }
                                                    width={75}
                                                />
                                            }
                                        />
                                        <div>
                                            {isAdmin ? (
                                                <Button
                                                    onClick={() =>
                                                        deleteLogo(item.id)
                                                    }
                                                >
                                                    <DeleteOutlined />
                                                </Button>
                                            ) : null}
                                        </div>
                                    </List.Item>
                                )}
                            />
                            <Divider
                                style={{
                                    borderBlockStartColor: theme.colors.black,
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            </Card>
        </div>
    );
};

export default CompanyLogo;
