import React from 'react';
import { Tag } from 'antd';

const PropertyTag = (props: any) => {
    const { label, value, closable, onClose } = props;

    const onPreventMouseDown = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
    };
    return (
        <Tag
            color={value}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{
                marginTop: 4,
                marginRight: 3,
                display: 'flex',
                alignItems: 'center',
                color: '#000',
                backgroundColor: 'rgba(0, 0, 0, 0.10)',
            }}
        >
            {label}
        </Tag>
    );
};

export default PropertyTag;
