import { message } from 'antd';
import DOMPurify from 'dompurify';

const { sanitize } = DOMPurify;

export const cleanCommentFromMarkup = (comment: string) => {
    const doc =
        new DOMParser().parseFromString(sanitize(comment), 'text/html') ?? '';
    return doc.documentElement?.textContent
        ?.replace(/<[^>]*>?/gm, '') // Regex to delete all HTML tags
        .replace(/([*]+)(\S)(.*?\S)??\1/g, '$2$3'); // Regex to delete all markdown bold syntax
};

export const copyTextToClipboard = (text: string) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    message.success('Success! Copied to clipboard');
};
