import { API_URL } from 'config/constants';
import { SelectedDataLevel } from 'waypoint-types';

interface CrosstabInputs {
    entity_codes: string[];
    period: [string, string];
    selectedDataLevel: SelectedDataLevel;
    modeSelection: string[];
}

const getCrosstabData = async ({
    entity_codes,
    period,
    selectedDataLevel,
    modeSelection,
}: CrosstabInputs) => {
    const response = await fetch(`${API_URL}/crosstab`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            entity_codes,
            period,
            selected_data_level: selectedDataLevel,
            mode_selection: modeSelection,
        }),
    });

    if (!response.ok) {
        throw new Error('Failed');
    }

    const { data } = await response.json();
    return data;
};

export default getCrosstabData;
