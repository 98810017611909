import { API_URL } from 'config/constants';

/**
 * @param {string} commentId - Comment id
 * @param {string} text - Contains the comment body
 * @param {string} commentId - Comment thread id
 * @param {number[]} user_mentions - Array of user IDs mentioned in the comment
 * @param {string[]} account_mentions - Array of account IDs mentioned in the comment
 */
const updateComment = (
    commentId: string,
    text: string,
    threadId: string,
    user_mentions?: number[],
    account_mentions?: string[],
    notificationRedirectUrl?: string,
    reportName?: string
): Promise<Response> => {
    return fetch(`${API_URL}/comments/${commentId}/${threadId}`, {
        method: 'PUT',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            text,
            user_mentions,
            account_mentions,
            notificationRedirectUrl,
            reportName,
        }),
    });
};

export default updateComment;
