import React, { useEffect, useRef, useState } from 'react';
import { FinancialTreeTable } from 'waypoint-react';
import {
    BALANCE_SHEET_EMPTY_TEXT,
    BALANCE_SHEET_ERROR_TEXT,
} from 'components/financials/balanceSheet/constants';
import { getBalanceSheetColumns } from 'components/financials/balanceSheet/components/table/BalanceSheetTableUtils';
import { InputRef, Skeleton } from 'antd';
import { AccountGraphNode, Note } from 'waypoint-types';
import { connect, RootStateOrAny } from 'react-redux';
import { selectCurrentUser } from 'state/user/selectors';
import FinancialTableNoteModal from 'components/notes/FinancialTableNoteModal';
import { getBalanceSheetMetadataRequestParams } from '../banner/utils';
import { css } from 'emotion';
import { KeyedMutator } from 'swr';
import { useCommentsPane } from 'contexts/comments/CommentsContext';
import { FinancialExportOptions } from 'components/reports/constants';

const HEIGHT = 480;
const MIN_WIDTH = 1280;

interface ExtraReportWidgetSettings {
    export_setting: string;
    account_mapping_selections: string[];
}
interface BalanceSheetTableProps {
    isLoading: boolean;
    error: boolean;
    periods: (string | undefined)[];
    balanceSheet: AccountGraphNode[];
    reportNotes: Note[] | null;
    mutateReportNotes: KeyedMutator<Note[]>;
    hideNotes: boolean;
    entityCodes: string[];
    expandedRows?: string[] | null;
    setExpandedRows?: (rows: string[]) => void;
    userId: number;
    setAccountFilterSelection: (value: string) => void;
    selectedAccountMappingId: string;
    setSelectedAccountMappingId: (value: string | null) => void;
    heightOffset: number;
    hideNoteOption?: boolean;
    hideCommentsOption?: boolean;
    reportWidgetSettings?: ExtraReportWidgetSettings;
    isPDFExport?: boolean;
}

const tableContainerStyle = css`
    height: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    scrollbar-width: none;
    scrollbar-height: none;
    display: flex;
    flex-direction: column;
    .ant-table-wrapper {
        flex: 1;
        overflow-y: 'hidden';
    }
`;

const BalanceSheetTable = ({
    balanceSheet,
    isLoading,
    periods,
    error,
    reportNotes,
    mutateReportNotes,
    hideNotes,
    entityCodes,
    expandedRows,
    setExpandedRows,
    userId,
    setAccountFilterSelection,
    selectedAccountMappingId,
    setSelectedAccountMappingId,
    heightOffset,
    hideNoteOption,
    hideCommentsOption,
    reportWidgetSettings,
    isPDFExport,
}: BalanceSheetTableProps) => {
    const [isNotesModalVisible, setIsNotesModalVisible] =
        useState<boolean>(false);
    const [noteId, setNoteId] = useState<string | null>(null);
    const [noteText, setNoteText] = useState<string>('');

    const { openComments, commentThreadAccountMentions } = useCommentsPane();

    const isSmallScreen = window.innerWidth < 1024 || window.innerHeight < 900;

    const inputRef = useRef<InputRef>(null);

    useEffect(() => {
        if (isNotesModalVisible) {
            inputRef.current?.focus({
                cursor: noteText.length ? 'end' : 'start',
            });
        }
    }, [isNotesModalVisible]);

    if (isLoading) {
        return (
            <div style={{ height: HEIGHT, padding: 20 }}>
                <Skeleton paragraph={{ rows: isSmallScreen ? 6 : 12 }} />
            </div>
        );
    }

    const includeNotes = entityCodes.length === 1 && !hideNotes;
    const includeActionColumn = entityCodes.length === 1 && !hideNoteOption;

    const screenSizeOffset = isSmallScreen ? 50 : 75;
    const tableHeight = window.innerHeight - (heightOffset + screenSizeOffset);

    return (
        <div className={tableContainerStyle}>
            <FinancialTreeTable
                size="small"
                bordered={false}
                loading={isLoading}
                height={tableHeight}
                width={includeNotes && MIN_WIDTH}
                searchableKeys={['name', 'account_mapping_code']}
                columns={getBalanceSheetColumns(
                    periods,
                    includeNotes,
                    includeActionColumn,
                    userId,
                    mutateReportNotes,
                    isNotesModalVisible,
                    setIsNotesModalVisible,
                    setNoteId,
                    setNoteText,
                    setSelectedAccountMappingId,
                    openComments,
                    setAccountFilterSelection,
                    entityCodes.length === 1,
                    reportNotes,
                    expandedRows ?? [],
                    commentThreadAccountMentions,
                    hideCommentsOption,
                    isPDFExport
                )}
                data={balanceSheet}
                expandedRows={
                    reportWidgetSettings?.account_mapping_selections ??
                    expandedRows
                }
                setExpandedRows={setExpandedRows}
                locale={{
                    emptyText: error
                        ? BALANCE_SHEET_ERROR_TEXT
                        : BALANCE_SHEET_EMPTY_TEXT,
                }}
                expandAllRows={
                    reportWidgetSettings?.export_setting ===
                    FinancialExportOptions.FULL_DETAIL
                }
                parentExpand={
                    reportWidgetSettings?.export_setting ===
                    FinancialExportOptions.SUMMARY
                }
                isReportWidget={!!reportWidgetSettings}
                isPDFExport={isPDFExport}
            />
            {isNotesModalVisible ? (
                <FinancialTableNoteModal
                    noteId={noteId}
                    setNoteId={setNoteId}
                    text={noteText}
                    setText={setNoteText}
                    title={!noteId ? 'Add a Note' : 'Edit Note'}
                    isVisible
                    setIsVisible={setIsNotesModalVisible}
                    accountMappingId={selectedAccountMappingId ?? ''}
                    setAccountMappingId={setSelectedAccountMappingId}
                    metadataParams={getBalanceSheetMetadataRequestParams(
                        entityCodes[0],
                        periods as string[]
                    )}
                    mutateReportNotes={mutateReportNotes}
                />
            ) : (
                <></>
            )}
        </div>
    );
};

export const mapState = (state: RootStateOrAny) => {
    const { id: userId } = selectCurrentUser(state);

    return {
        userId,
    };
};

export default connect(mapState)(BalanceSheetTable);
