export default function defaultCellDataGetter({
    dataKey,
    rowIndex,
    columnIndex,
    datum,
    ...rest
}) {
    const cellData = datum[dataKey];
    return {
        datum,
        cellData,
        rowIndex,
        columnIndex,
        ...rest,
    };
}
