import { doubleDash } from 'components/occupancy/constants';
import moment from 'moment';
import {
    get,
    isNull,
    mapValues,
    uniq,
    isEmpty,
    sum,
    max,
    isUndefined,
    toArray,
} from 'lodash';
import { timestampToFullDate } from 'components/dates/utils';
import { DASH_DASH } from 'config/constants';

// GENERAL HELPERS
// TODO: Move these to common, global utils file

export const stringIsInvalid = (val) => {
    return isNull(val) || isUndefined(val);
};

export const numberIsInvalid = (val) => {
    return isNull(val) || isUndefined(val) || isNaN(val);
};

export const replaceStringWithDashIfInvalid = (val) => {
    return stringIsInvalid(val) ? doubleDash : val;
};

export const formatVacantOrNull = (stat) => {
    return isNull(stat) || isUndefined(stat) ? doubleDash : stat;
};

export const toOneDecimal = (value) => {
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
    });
    return formatter.format(value);
};

export const formatToOneDecimal = (val) => {
    return numberIsInvalid(val) ? doubleDash : toOneDecimal(val);
};

export const moneyFormatter = (value) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    return formatter.format(value);
};

export const formatMoney = (figure) => {
    return numberIsInvalid(figure) ? `$${DASH_DASH}` : moneyFormatter(figure);
};

export const formatDate = (date) => {
    return isNull(date) || isUndefined(date)
        ? doubleDash
        : moment(date).format('MM/DD/YYYY');
};

export const formatToYear = (date) => moment(date).format('YYYY');

export const formatToMonth = (date) => moment(date).format('MMM');

export const formatToMonthAndYear = (date) => moment(date).format('MMM YY');

export const d3FormatNumber = (value) => {
    const formatter = new Intl.NumberFormat('en-US', {
        useGrouping: true,
    });
    return formatter.format(value);
};

export const formatNumber = (val) => {
    return numberIsInvalid(val) ? doubleDash : d3FormatNumber(val);
};

export const formatPercentage = (val) => {
    return numberIsInvalid(val) ? `${DASH_DASH}%` : `${val.toFixed(2)}%`;
};

export const zeroIfNaN = (val) => val || 0;

// BANNER HELPERS

export const getTotalMonthlyRent = (leases) => {
    return leases.reduce((total, v) => {
        total = total + v.monthly_rent;
        return total;
    }, 0);
};

export const getTotalAnnualRent = (leases) => {
    return leases.reduce((total, v) => {
        total = total + v.annual_rent;
        return total;
    }, 0);
};

// TABLE HELPERS

export const addPrefix = (prefix, gross) => {
    if (gross) {
        return `${prefix}Rent`;
    }
    return `${prefix}RentPerSquareFoot`;
};

// TABLE SORTS

// TODO: add table sorts to own moduled shared between tables
export const numericalSort = (a, b) => {
    if (isUndefined(a)) {
        return -1;
    }
    if (isUndefined(b)) {
        return 1;
    }
    return a - b;
};

export const leaseTermSort = (a, b) => {
    if (b === DASH_DASH && a !== DASH_DASH) {
        return 1;
    } else if (a === DASH_DASH && b !== DASH_DASH) {
        return -1;
    }
    return numericalSort(a, b);
};

export const stringSort = (a, b) => {
    return b.toUpperCase().localeCompare(a.toUpperCase());
};

export const stringSortWithCheckbox = (aa, bb) => {
    const { text: aaa } = aa;
    const { text: bbb } = bb;
    let a = aaa;
    let b = bbb;

    if (a === null) {
        a = DASH_DASH;
    }
    if (b === null) {
        b = DASH_DASH;
    }
    return b.toUpperCase().localeCompare(a.toUpperCase());
};

export const alphaNumericSort = (a, b) =>
    a.toUpperCase().localeCompare(b.toUpperCase(), 'en', { numeric: true });

export const percentageSort = (a, b) => {
    a = a.split('%')[0];
    b = b.split('%')[0];
    return b - a;
};
export const dateSort = (a, b) => {
    let ret = 0;
    if (isNull(b) && !isNull(a)) {
        ret = 1;
    } else if (isNull(a) && !isNull(b)) {
        ret = -1;
    } else if (moment(a).isBefore(b)) {
        ret = 1;
    } else if (moment(b).isBefore(a)) {
        ret = -1;
    }
    return ret;
};

export const dateSortWithCheckbox = (aa, bb) => {
    const { date: a } = aa;
    const { date: b } = bb;
    let ret = 0;
    if (isNull(b) && !isNull(a)) {
        ret = 1;
    } else if (isNull(a) && !isNull(b)) {
        ret = -1;
    } else if (moment(a).isBefore(b)) {
        ret = 1;
    } else if (moment(b).isBefore(a)) {
        ret = -1;
    }
    return ret;
};

export const occRateSort = (a, b) => {
    a = Number(a.split('%')[0]);
    b = Number(b.split('%')[0]);
    if (isNaN(a)) {
        a = 0;
    }
    if (isNaN(b)) {
        b = 0;
    }
    return a - b;
};

// DISCUSSION PANEL

export const getLeaseStatistics = (lease) => {
    const leaseStatistics = {
        leaseTerm: get(lease, 'leaseTerm'),
        leaseArea: get(lease, 'leaseSquareFootage'),
        leaseType: get(lease, 'leaseType'),
        leaseName: get(lease, 'leaseName'),
        leaseStartDate: stringIsInvalid(get(lease, 'leaseStartDate'))
            ? doubleDash
            : timestampToFullDate(get(lease, 'leaseStartDate')),
        leaseSquareFootage: get(lease, 'leaseSquareFootage'),
        leaseExpiration: stringIsInvalid(get(lease, 'leaseExpirationDate'))
            ? doubleDash
            : timestampToFullDate(get(lease, 'leaseExpirationDate')),
        monthlyRent: formatMoney(get(lease, 'monthlyRent')),
        monthlyRentSqFt: formatMoney(get(lease, 'monthlyRentPerSquareFoot')),
        annualRent: formatMoney(get(lease, 'annualRent')),
        annualRentSqFt: formatMoney(get(lease, 'annualRentPerSquareFoot')),
    };
    return leaseStatistics;
};
export const generateCommentsGetUrl = ({ clientId, suiteId }) => {
    return `api/v1/ClientUser/clients/${clientId}/commentsDetail/commentable_type/Suite/commentable_id/${suiteId}`;
};

// CHART HELPERS

export const getAllExpirationYears = (leases) =>
    uniq(
        leases
            .map((l) => formatToYear(l.lease_expiration_date))
            .filter((y) => y !== 'Invalid date'),
    ).sort(numericalSort);

export const getFirstDecade = (years) => years.slice(0, 10);

export const getExpiringSqFtByYear = (leasesByPeriod) => {
    const expiringSqFtByYear = mapValues(leasesByPeriod, (l) => {
        const sqFts = l.map((lease) => lease.leaseSquareFootage);
        const squareFtExpiring = sum(sqFts);
        return squareFtExpiring;
    });
    return expiringSqFtByYear;
};

export const getExpiringUnitsByMonth = (leasesByPeriod) => {
    const expiringUnitsByMonth = mapValues(leasesByPeriod, (l) => {
        const unitsExpiring = l.length;
        return unitsExpiring;
    });
    return expiringUnitsByMonth;
};

export const getLargestOfSeries = ({ series, categories }) => {
    const data = series.map((s) => s.data);
    if (isEmpty(categories)) {
        return 0;
    }
    const bucketCollection = categories.reduce((acc, v, i) => {
        let bucketTotal = 0;
        data.forEach((d) => {
            bucketTotal = bucketTotal + d[i].value;
        });
        acc[i] = bucketTotal;
        return acc;
    }, {});
    const highestColumn = max(toArray(bucketCollection));
    return highestColumn;
};
