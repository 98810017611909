import { get } from 'lodash';
import React, { Component } from 'react';
import PeriodPicker from 'components/dates/PeriodPicker';
// TODO: Move these up to analytics folder
import { toUnix, toISO } from 'components/dates/utils';
import { getRangeOfMonthTimestamps } from 'components/analytics/portfolioSegmentation/filters/period/utils';
import { startDate } from 'components/financials/balanceSheet/constants';
import { getDataAvailabilityDate } from 'waypoint-requests';

class SegmentationPeriodPicker extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataAvailabilityDate: false,
            dataAvailabilityDateIsLoading: true,
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.fetchDataAvailabilityDate();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.entityCodes !== this.props.entityCodes) {
            this.fetchDataAvailabilityDate();
        }
    }

    onChange({ value: timestamp }) {
        this.props.handlePeriodChange(toISO(timestamp));
    }

    fetchDataAvailabilityDate() {
        if (!this.props.entityCodes || !this.props.entityCodes.length) {
            return;
        }

        getDataAvailabilityDate(this.props.entityCodes)
            .then((res) => {
                if (!res.ok) {
                    throw new Error(
                        `${res.statusText} for ${res.url} (status: ${res.status})`
                    );
                }
                return res.json();
            })
            .then((res) => {
                const dataAvailabilityDate = toISO(
                    get(res, 'data[0].period_end')
                );
                this.props.handlePeriodChange(
                    this.props.period || dataAvailabilityDate
                );
                this.setState({
                    dataAvailabilityDate,
                    dataAvailabilityDateIsLoading: false,
                });
                return true;
            });
    }

    render() {
        const rangeOfTimestamps = getRangeOfMonthTimestamps({
            start: startDate,
            end: this.state.dataAvailabilityDate,
        });
        return (
            <PeriodPicker
                pickType="month"
                removeTitle
                value={toUnix(this.props.period)}
                timestamps={rangeOfTimestamps}
                width={250}
                onChange={this.onChange}
                formatAsUtc={false}
                disabled={this.state.dataAvailabilityDateIsLoading}
            />
        );
    }
}

export default SegmentationPeriodPicker;
