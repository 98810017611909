import { Table } from 'antd';
import { DelinquencyData } from 'waypoint-types';
import {
    ColumnDescriptor,
    ExportableGridSummaryFormatter,
    convertColumnsToAntd,
    getColumnTotalCells,
    headerClass,
    rowClass,
} from './GridExportConversionUtils';
import { Dictionary } from 'ts-essentials';
import { currencyFormatter } from './AgedReceivablesSummaryExportableGrid';

const booleanFormatter = (value: boolean) => (value ? 'Active' : 'Inactive');

const tenantRenderer = (tenant_code: string) => {
    return tenant_code.split('_').slice(-1)[0];
};

export const agedReceivablesBaseColumns = [
    {
        title: 'Tenant',
        dataIndex: 'tenant_name',
        key: 'tenant_name',
        dataType: 'string',
    },
    {
        title: 'Tenant Code',
        dataIndex: 'tenant_code',
        key: 'tenant_code',
        dataType: 'string',
        align: 'center',
        render: tenantRenderer,
    },
    {
        title: 'Unit #',
        dataIndex: 'unit',
        key: 'unit',
        dataType: 'string',
        align: 'center',
    },
    {
        title: 'Lease Code',
        dataIndex: 'source_system_lease_code',
        key: 'source_system_lease_code',
        dataType: 'string',
        align: 'center',
    },
    {
        title: 'Status',
        dataIndex: 'active_lease',
        key: 'active_lease',
        dataType: 'boolean',
        render: booleanFormatter,
        align: 'center',
    },
    {
        title: 'Balance Summary',
        dataIndex: 'balance_summary',
        key: 'balance_summary',
        align: 'center',
        children: [
            {
                title: 'Current',
                dataIndex: 'balance',
                key: 'balance',
                dataType: 'number',
                align: 'right',
                render: currencyFormatter,
            },
            {
                title: 'Prepaid',
                dataIndex: 'prepaid_balance',
                key: 'prepaid_balance',
                dataType: 'number',
                align: 'right',
                render: currencyFormatter,
            },
            {
                title: 'Overdue',
                dataIndex: 'overdue_balance',
                key: 'overdue_balance',
                dataType: 'number',
                align: 'right',
                render: currencyFormatter,
            },
            {
                title: 'Total',
                dataIndex: 'total',
                key: 'total',
                dataType: 'number',
                align: 'right',
                render: currencyFormatter,
            },
        ],
    },
    {
        title: 'Aging Period',
        dataIndex: 'aging_period',
        key: 'aging_period',
        align: 'center',
        children: [
            {
                title: '1-30 Days',
                dataIndex: 'thirty_days_due_balance',
                key: 'thirty_days_due_balance',
                dataType: 'number',
                align: 'right',
                render: currencyFormatter,
            },
            {
                title: '31-60 Days',
                dataIndex: 'sixty_days_due_balance',
                key: 'sixty_days_due_balance',
                dataType: 'number',
                align: 'right',
                render: currencyFormatter,
            },
            {
                title: '61-90 Days',
                dataIndex: 'ninety_days_due_balance',
                key: 'ninety_days_due_balance',
                dataType: 'number',
                align: 'right',
                render: currencyFormatter,
            },
            {
                title: '> 90 Days',
                dataIndex: 'ninety_more_days_due_balance',
                key: 'ninety_more_days_due_balance',
                dataType: 'number',
                align: 'right',
                render: currencyFormatter,
            },
        ],
    },
    {
        title: 'Notes',
        dataIndex: 'notes_string',
        key: 'notes_string',
        dataType: 'string',
        width: '150px',
    },
];

const agedReceivablesSummaryFormatters: Dictionary<ExportableGridSummaryFormatter> =
    {
        tenant_name: {
            summaryType: 'count',
            render: (value: number) => `${value}`,
        },
        source_system_lease_code: {
            summaryType: 'custom',
            render: (value: number) => `${value} Leases`,
        },
        unit: {
            summaryType: 'custom',
            render: (value: number) => `${value} Units`,
        },
        active_lease: {
            summaryType: 'custom',
            render: (value: number) => `${value} Active`,
        },
        total: {
            summaryType: 'sum',
            render: currencyFormatter,
        },
        balance: {
            summaryType: 'sum',
            render: currencyFormatter,
        },
        prepaid_balance: {
            summaryType: 'sum',
            render: currencyFormatter,
        },
        overdue_balance: {
            summaryType: 'sum',
            render: currencyFormatter,
        },
        thirty_days_due_balance: {
            summaryType: 'sum',
            render: currencyFormatter,
        },
        sixty_days_due_balance: {
            summaryType: 'sum',
            render: currencyFormatter,
        },
        ninety_days_due_balance: {
            summaryType: 'sum',
            render: currencyFormatter,
        },
        ninety_more_days_due_balance: {
            summaryType: 'sum',
            render: currencyFormatter,
        },
    };

const calculateCustomSummary = (column: string, data: DelinquencyData[]) => {
    let totalValue = 0;

    if (column === 'active_lease') {
        data.forEach(({ active_lease }) => {
            totalValue += active_lease ? 1 : 0;
        });

        return totalValue;
    }

    if (column === 'unit') {
        const distinctUnits = new Set(data.map((item) => item.unit));
        return distinctUnits.size;
    }

    if (column === 'source_system_lease_code') {
        const distinctLeaseCodes = new Set(
            data.map((item) => item.source_system_lease_code)
        );
        return distinctLeaseCodes.size;
    }

    if (column === 'tenant_name') {
        const distinctTenants = new Set(data.map((item) => item.tenant_name));
        return distinctTenants.size;
    }

    return 0;
};
export const AgedReceivablesExportableGrid = ({
    data,
}: {
    data: DelinquencyData[];
}) => {
    const { gridColumns } = convertColumnsToAntd(
        agedReceivablesBaseColumns as ColumnDescriptor[],
        true,
        null
    );

    return (
        <Table
            dataSource={data}
            size="small"
            columns={gridColumns}
            pagination={false}
            bordered={true}
            className={headerClass}
            rowClassName={rowClass}
            summary={() =>
                getColumnTotalCells(
                    gridColumns,
                    data,
                    agedReceivablesSummaryFormatters,
                    calculateCustomSummary
                )
            }
        />
    );
};
