import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';
import AntDesignInput from 'antd/lib/input';
import AntDesignButton from 'antd/lib/button';

/**
 * This is the search input for the searchable FinancialTreeTable. It renders an input, focuses on mount and fires a callback on change.
 */
const ColumnSearchInput = ({
    searchText,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleSearch = () => {},
    expandAllRows,
    collapseAllRows,
}) => {
    const inputRef = useRef();
    useEffect(() => inputRef.current.focus());
    return (
        <div style={{ padding: 10, display: 'flex', flexDirection: 'column' }}>
            <AntDesignInput
                ref={inputRef}
                placeholder={'Search table'}
                value={searchText}
                onChange={(e) => {
                    expandAllRows();
                    handleSearch(e.target.value);
                }}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <div style={{ textAlign: 'right' }}>
                <AntDesignButton
                    onClick={() => {
                        handleSearch('');
                        collapseAllRows();
                    }}
                    size="small"
                    style={{ width: 90 }}
                >
                    Reset
                </AntDesignButton>
            </div>
        </div>
    );
};

ColumnSearchInput.propTypes = {
    collapseAllRows: PropTypes.func,
    expandAllRows: PropTypes.func,
    handleSearch: PropTypes.func,
    searchText: PropTypes.string,
};

export default ColumnSearchInput;
