import React from 'react';
import memoizeOne from 'memoize-one';
import { renderToString } from 'react-dom/server';
import {
    assign,
    truncate,
    get,
    find,
    isEmpty,
    isEqual,
    capitalize,
    upperCase,
} from 'lodash';
import theme from 'config/theme';
import { getDisplayTypeLabel } from 'components/analytics/financialOverview/menu/displayType/utils';
import chartBuilder from 'components/charts/chartBuilder';
import { AccountGroupTooltip } from 'components/analytics/ranking';
import { searchAccountGraphForAccount } from 'waypoint-utils';
import {
    AccountGraphNode,
    FixAnyType,
    RankingCardSelections,
    RankingData,
    RootFusionChartsConfigProps,
} from 'waypoint-types';
import { AccountGraphObjectType } from 'waypoint-types/account-graph/types';
import FusionCharts from 'fusioncharts';
import ReactFC from 'react-fusioncharts';
import charts from 'fusioncharts/fusioncharts.charts';
import fusiontheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import { transformDataToCharts } from 'components/analytics/portfolioSegmentation/utils';
import { defaultDisplayValue } from 'components/analytics/financialOverview/menu/displayType/constants';
import { compactFormatMoney } from 'utils/formatters';
import EmptyState from 'components/style/EmptyState';

charts(FusionCharts);
fusiontheme(FusionCharts);

/**
 * This turns a ranking payload into labels for Fusion Charts. Each label is the property name and the property's rank.
 * @param {array} rankingData - this is the ranking data from the API
 * @param {array<string>} entityCodes
 * @returns {array} - array with one object. The value of the category field is an array of label strings. This format is required for Fusion Charts.
 */

interface RankingCategories {
    category: {
        label: string;
        fontColor: string;
    }[];
}

interface RankingDataset {
    seriesname: string;
    data: RankingDatasetData[];
}

interface RankingDatasetData {
    value: number;
    toolText?: string;
}

interface BuildChartConfigParams {
    rankingData: RankingData[];
    valueType: string;
    displayType: string;
    selectedAccountMapping: AccountGraphNode | null;
    entityCodes: string[];
}

interface BuildPieChartConfigParams {
    rankingData: RankingData[];
    selectedAccountMapping: AccountGraphNode;
    pieChartCount: number;
    valueType: string;
    subCaption: string | null;
}

interface PieChartConfig {
    primaryChartConfig: RootFusionChartsConfigProps;
    secondaryChartConfig: RootFusionChartsConfigProps;
}

interface TransformedPieChartData {
    value: number;
    label: string;
    color: string;
}

interface MappedPieChartData {
    metric: string;
    value: number;
}

export const getRankingCategoriesFromData = (
    rankingData: RankingData[],
    entityCodes: string[],
): RankingCategories[] => {
    return [
        {
            category: rankingData.map((property) => {
                return {
                    label: `${truncate(property.property_name, {
                        length: 30,
                    })}`,
                    fontColor:
                        entityCodes.length === 1 &&
                        property.property_id === entityCodes[0]
                            ? theme.colors.oranges.marigold
                            : theme.colors.black,
                };
            }),
        },
    ];
};

/**
 * This is a util that is used to generate datasets for Fusion Charts.
 * @param {object} selectedAccountMapping - a node in an account graph
 * @returns {array} - this returns an array of account mapping names.
 */
const getChildAccountNamesFromAccountMapping = (
    selectedAccountMapping: AccountGraphNode,
): string[] | null => {
    return (
        selectedAccountMapping.children &&
        selectedAccountMapping.children.map((childAccount: AccountGraphNode) =>
            get(childAccount, 'name'),
        )
    );
};

/**
 * This returns an object that represents all of the property rank values for a particular account. It includes data from every property.
 * This generates a series dataset. Each child account represents a series. The data field includes property ranking data from that account from every property in the payload.
 * The display type comes from an input on the Ranking module. It is used here to generate copy for the tooltip.
 * @param {array} rankingData
 * @param {string} accountName
 * @param {string} displayType
 * @returns {{ seriesName: string, data: array }} - a dataset in the format required by Fusion Charts
 */
export const getDatasetFor = (
    rankingData: RankingData[],
    accountName: string,
    displayType: string,
    isPieChart?: boolean,
): RankingDataset => {
    if (isPieChart) {
        const splitName = accountName.split('*');
        return {
            seriesname: splitName[0],
            data: rankingData.map((propertyData) => {
                return {
                    value:
                        propertyData.account_mapping.children.find(
                            (child) =>
                                child.account_mapping_name === splitName[0] &&
                                child.account_mapping_code === splitName[1],
                        )?.property_rank_value ?? 0,
                };
            }),
        };
    }

    return {
        seriesname: accountName,
        data: rankingData.map((propertyData) => {
            return {
                value:
                    get(
                        find(propertyData.account_mapping.children, {
                            account_mapping_name: accountName,
                        }),
                        'property_rank_value',
                    ) ?? 0,
                toolText: renderToString(
                    <AccountGroupTooltip
                        property={propertyData}
                        seriesName={accountName}
                        displayType={displayType}
                    />,
                ),
            };
        }),
    };
};

/**
 * This returns an array that represents all of the property rank values for a leaf account account. It includes data from every property.
 * This generates a series dataset. In this case, we are producing only a single series dataset for a leaf account. The end result is only one bar will display in the Fusion Chart.
 * @param {array} rankingData
 * @param {object} selectedAccountMapping
 * @returns {array}
 */

export const getLeafAccountDatasetFor = (
    rankingData: RankingData[],
    selectedAccountMapping: AccountGraphNode,
): RankingDataset[] => {
    const accountName = get(selectedAccountMapping, 'name');
    return [
        {
            seriesname: accountName,
            data: rankingData.map((propertyData) => ({
                value: get(propertyData, 'account_mapping.property_rank_value'),
            })) as FixAnyType[],
        },
    ];
};

/**
 * The Ranking chart is a multi-series chart. This util derives the children accounts for a chosen account mapping. If there are children accounts, it generates a series dataset for each one. The number of datasets returned depends on the number of children accounts. If there are no children, it generates a dataset for a single account group.
 * @param {array} propertyRankingData
 * @param selectedAccountMapping
 * @returns {array} - array of series datasets. These are in the format required by Fusion Charts for a scrollstackedbar2d chart type.
 */
export const getDatasetsFrom = (
    propertyRankingData: RankingData[],
    displayType: string,
    selectedAccountMapping: AccountGraphNode,
    isPieChart = false,
): RankingDataset[] => {
    if (isEmpty(selectedAccountMapping.children)) {
        return getLeafAccountDatasetFor(
            propertyRankingData,
            selectedAccountMapping,
        );
    }

    const childAccountNames = isPieChart
        ? selectedAccountMapping.children?.map(
              (childAccount: AccountGraphNode) =>
                  `${get(childAccount, 'name')}*${get(
                      childAccount,
                      'account_mapping_code',
                  )}`,
          )
        : getChildAccountNamesFromAccountMapping(selectedAccountMapping);

    return childAccountNames
        ? childAccountNames.map((seriesName: string) =>
              getDatasetFor(
                  propertyRankingData,
                  seriesName,
                  displayType,
                  isPieChart,
              ),
          )
        : [];
};

const chartStyle = {
    showYAxisvalue: 1,
    showValues: false,
    palettecolors: theme.colors.barCharts,
    tooltipPadding: '10',
    tooltipborderradius: '5',
    numberPrefix: '$',
    numDivLines: 5,
    numVisiblePlot: '12',
    labelFontBold: true,
    labelDisplay: 'Auto',
    useEllipsesWhenOverflow: true,
    yAxisPosition: 'top',
    baseFontColor: theme.colors.grays.text,
    exportFormats: 'PNG|PDF|JPG|SVG',
    showLegend: true,
    legendPosition: 'right',
    legendBorderColor: '#666666',
    legendBorderThickness: '1',
    legendBorderAlpha: '40',
    showYAxisValues: true,
    scrollColor: theme.colors.white,
    scrollWidth: 8,
    showSum: true,
    plotHighlightEffect: 'fadeout',
};

const pieChartStyle = {
    enableRotation: '1',
    showLabels: '1',
    showBorder: true,
    borderColor: theme.colors.grays.medium,
    showPercentageValues: '1',
    showZeroPies: '1',
    numberPrefix: '$',
    enableMultiSlicing: '0',
    enableSmartLabels: '1',
    skipOverlapLabels: '1',
    manageLabelOverflow: '1',
    useEllipsesWhenOverflow: '1',
    pieRadius: '60%',
    baseFontColor: theme.colors.grays.dark,
    xAxisNameFontColor: theme.colors.grays.dark,
    yAxisNameFontColor: theme.colors.grays.dark,
    startingAngle: '90',
    decimals: '2',
    alignCaptionWithCanvas: '1',
    showHoverEffect: '1',
    plotHoverEffect: '1',
    plotHighlightEffect: 'fadeout',
    exportFormats: 'PNG|PDF|JPG|SVG',
};

const formatDataForPieChart = (
    data: RankingData[],
    pieChartCount: number,
    accountMapping?: AccountGraphNode,
): TransformedPieChartData[] => {
    let pieChartData;
    if (accountMapping) {
        const childData = getDatasetsFrom(
            data,
            defaultDisplayValue,
            accountMapping,
            true,
        );

        pieChartData = childData
            .map((item: RankingDataset): MappedPieChartData => {
                return {
                    metric: item.seriesname,
                    value: getPieChartTotalOrSubtotal(item.data),
                };
            })
            .sort(
                (a: MappedPieChartData, b: MappedPieChartData) =>
                    b.value - a.value,
            );
    } else {
        pieChartData = data.map((item: RankingData): MappedPieChartData => {
            return {
                metric: item.property_name,
                value: item.account_mapping?.property_rank_value ?? 0,
            };
        });
    }

    if (pieChartCount) {
        const topNSlices = pieChartData.slice(0, pieChartCount);

        if (pieChartData.length > pieChartCount) {
            const others = pieChartData.slice(pieChartCount);

            const othersAggregate = {
                metric: 'Other',
                value: getPieChartTotalOrSubtotal(others),
            };
            topNSlices.push(othersAggregate);
        }
        return transformDataToCharts(topNSlices);
    }

    return transformDataToCharts(pieChartData);
};

const getTotalForDefaultPieChartCenterLabel = (
    pieChartData: TransformedPieChartData[],
): string => {
    return compactFormatMoney(getPieChartTotalOrSubtotal(pieChartData));
};

const getPieChartTotalOrSubtotal = (
    chartData: TransformedPieChartData[] | RankingDatasetData[],
): number => {
    return chartData
        .map(
            (item: TransformedPieChartData | RankingDatasetData) =>
                item.value ?? 0,
        )
        .reduce((a: number, b: number) => a + b, 0);
};

export const buildPieChartConfigFor = ({
    rankingData,
    selectedAccountMapping,
    pieChartCount,
    valueType,
    subCaption,
}: BuildPieChartConfigParams): PieChartConfig => {
    const primaryChart = chartBuilder();

    primaryChart.type('doughnut2d');
    primaryChart.height('450');
    primaryChart.width('750');
    const primaryChartData = formatDataForPieChart(rankingData, pieChartCount);

    primaryChart.data(primaryChartData);

    const secondaryChart = chartBuilder();

    secondaryChart.type('doughnut2d');
    secondaryChart.height('450');
    secondaryChart.width('750');
    const secondaryChartData = formatDataForPieChart(
        rankingData,
        pieChartCount,
        selectedAccountMapping,
    );

    secondaryChart.data(secondaryChartData);

    const partialSubCaption = subCaption ? `By ${subCaption} -` : '';
    const primaryChartStyle = {
        ...pieChartStyle,
        defaultCenterLabel: `Total: ${getTotalForDefaultPieChartCenterLabel(
            primaryChartData,
        )}`,
        centerLabel: `${selectedAccountMapping.name} from $label: $value`,
        caption: upperCase(selectedAccountMapping.name),
        subcaption: `${partialSubCaption} ${capitalize(
            valueType,
        )} (${getDisplayTypeLabel(defaultDisplayValue)})`,
    };

    const secondaryChartStyle = {
        ...pieChartStyle,
        defaultCenterLabel: `Total: ${getTotalForDefaultPieChartCenterLabel(
            secondaryChartData,
        )}`,
        centerLabel: `${selectedAccountMapping.name} from $label: $value`,
        caption: upperCase(selectedAccountMapping.name),
        subcaption: `By Account - ${capitalize(
            valueType,
        )} (${getDisplayTypeLabel(defaultDisplayValue)})`,
    };

    primaryChart.style(primaryChartStyle);
    secondaryChart.style(secondaryChartStyle);

    const { config: primaryChartConfig } = primaryChart;
    const { config: secondaryChartConfig } = secondaryChart;
    return { primaryChartConfig, secondaryChartConfig };
};

/**
 * This builds a chart configuration for the ranking chart.
 * @param {array} rankingData
 * @param {string} displayType
 * @param selectedAccountMapping
 * @param {array<string>} entityCodes
 * @returns {object} - a chart configuration fro a scrollstackedbar2d Fusion Charts chart type
 */

export const buildChartConfigFor = ({
    rankingData,
    valueType,
    displayType,
    selectedAccountMapping,
    entityCodes,
}: BuildChartConfigParams): RootFusionChartsConfigProps | undefined => {
    if (selectedAccountMapping === null) {
        return;
    }
    const chart = chartBuilder();
    chart.type('scrollstackedbar2d');
    chart.height('490');
    chart.width('100%');
    chart.categories(getRankingCategoriesFromData(rankingData, entityCodes));
    chart.dataset(
        getDatasetsFrom(rankingData, displayType, selectedAccountMapping),
    );
    chart.data();
    chart.style(
        assign({}, chartStyle, {
            caption: upperCase(selectedAccountMapping.name),
            subcaption: `${capitalize(valueType)} (${getDisplayTypeLabel(
                displayType,
            )})`,
        }),
    );
    const { config: chartConfig } = chart;
    return chartConfig;
};

const buildRankingChartConfigFor = memoizeOne(buildChartConfigFor, isEqual);

export const getRankingChartConfig = (
    data: RankingData[],
    selections: RankingCardSelections,
    accountGraph: AccountGraphObjectType,
    entityCodes: string[],
    valueType: string,
): JSX.Element => {
    const selectedAccountMapping = searchAccountGraphForAccount(
        accountGraph,
        selections.accountMapping.code,
    );

    return (
        <ReactFC
            {...buildRankingChartConfigFor({
                rankingData: data,
                valueType,
                displayType: get(selections, 'displayType'),
                selectedAccountMapping,
                entityCodes,
            })}
            data-testid="ranking-chart"
        />
    );
};

const buildRankingPieChartConfigFor = memoizeOne(
    buildPieChartConfigFor,
    isEqual,
);

export const getRankingPieChartConfig = (
    data: RankingData[],
    selections: RankingCardSelections,
    accountGraph: AccountGraphObjectType,
    pieChartCount: number,
    valueType: string,
    subCaption: string,
): JSX.Element | null => {
    const selectedAccountMapping = searchAccountGraphForAccount(
        accountGraph,
        selections.accountMapping.code,
    );

    if (selectedAccountMapping === null) {
        return null;
    }

    const { primaryChartConfig, secondaryChartConfig } =
        buildRankingPieChartConfigFor({
            rankingData: data,
            selectedAccountMapping,
            pieChartCount,
            valueType,
            subCaption,
        });

    const primaryChartIsEmpty =
        getPieChartTotalOrSubtotal(
            primaryChartConfig.dataSource
                .data as unknown as TransformedPieChartData[],
        ) === 0;

    if (primaryChartIsEmpty) {
        return (
            <EmptyState
                icon={'./assets/icons/chart@2x.png'}
                headerText={'No Data'}
                subheaderText={
                    'Selected entites do not have sufficient data to render pie chart'
                }
            />
        );
    }

    const secondaryChartIsEmpty =
        getPieChartTotalOrSubtotal(
            secondaryChartConfig.dataSource
                .data as unknown as TransformedPieChartData[],
        ) === 0;

    if (secondaryChartIsEmpty) {
        return (
            <>
                <ReactFC
                    {...primaryChartConfig}
                    data-testid="ranking-pie-chart-primary"
                />
            </>
        );
    }

    return (
        <>
            <ReactFC
                {...primaryChartConfig}
                data-testid="ranking-pie-chart-primary"
            />
            <ReactFC
                {...secondaryChartConfig}
                data-testid="ranking-pie-chart-secondary"
            />
        </>
    );
};
