import React, { useState } from 'react';
import Skeleton from 'antd/lib/skeleton';
import { css, cx } from 'emotion';
import { times } from 'lodash';
import { Comment } from 'waypoint-react';
import theme from 'config/theme';
import { LocalCommentData } from 'waypoint-types';

const emptySetStyle = css`
    text-align: center;
    color: ${theme.colors.grays.mediumDark};
    margin-top: ${theme.space.extraLarge}px;
`;
const commentBoxStyle = css`
    overflow-y: auto;
    margin: 5px 0 10px 0;
`;
const placeholderStyle = css`
    padding: 24px;
`;

interface CommentListProps {
    loading: boolean;
    comments: LocalCommentData[];
    onSubmit: (
        value: string,
        userMentions: number[],
        accountMentions: string[],
        parentId: string | null,
        commentId: string | null
    ) => void;
    scrollToCommentId: string | null;
    onResolveComment: (
        commentId: string,
        commentThreadId: string,
        is_resolved: boolean
    ) => void;
}

const CommentList = ({
    loading,
    comments,
    onSubmit,
    scrollToCommentId,
    onResolveComment,
}: CommentListProps) => {
    const [activeKey, setActiveKey] = useState<string[] | string>([]);

    if (loading) {
        return (
            <div className={cx(commentBoxStyle, placeholderStyle)}>
                {times(3).map((n) => (
                    <Skeleton
                        key={`comment-skeleton-${n}`}
                        avatar
                        paragraph={{ rows: 2 }}
                    />
                ))}
            </div>
        );
    }

    if (!comments.length) {
        return <p className={emptySetStyle}>No comments to show</p>;
    }

    return (
        <div className={commentBoxStyle}>
            {comments.map((comment) => (
                <Comment
                    key={comment.id}
                    onSubmit={onSubmit}
                    comment={comment}
                    withAvatar
                    activeKey={activeKey}
                    setActiveKey={setActiveKey}
                    scrollToCommentId={scrollToCommentId}
                    onResolveComment={onResolveComment}
                />
            ))}
        </div>
    );
};

export default CommentList;
