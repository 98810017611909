import { getOvertimeDataset } from 'components/analytics/utils';
import DataGrid, { Export, Column, Format } from 'devextreme-react/data-grid';
import { numericalSort, dateSort } from 'utils/tables/sorters';
import {
    currencyFormat,
    varianceRenderer,
    percentageFormat,
} from 'waypoint-utils';

import { ExportingEvent } from 'devextreme/ui/data_grid';
import { exportExcelFromDevExtremeDataGrid } from 'waypoint-utils';

const onExporting = async (e: ExportingEvent) => {
    await exportExcelFromDevExtremeDataGrid(e, {
        worksheetName: 'Overtime',
        filename: 'Overtime.xlsx',
    });
};

export interface OvertimeDataType {
    label: string;
    actual_amount: number;
    budget_amount: number;
    variance_amount: number;
    variance_ratio: number;
}

export interface OvertimeType {
    data: OvertimeDataType[];
    height: number;
    periodicity: string;
}

const OvertimeTable = ({ data, periodicity, height = 250 }: OvertimeType) => {
    const parseData = data.map((item: OvertimeDataType, index: number) => ({
        ...item,
        label: getOvertimeDataset(data, periodicity)[index].label,
    }));

    return (
        <DataGrid
            wordWrapEnabled
            dataSource={parseData}
            onExporting={onExporting}
            height={height}
            allowColumnReordering={true}
            columnAutoWidth={true}
            columnMinWidth={120}
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}
            rowAlternationEnabled={true}
            data-testid="breakdown-table"
        >
            <Column
                caption="Period"
                dataField="label"
                allowSorting={true}
                sortingMethod={dateSort}
            />
            <Column
                caption="Actual"
                dataField="actual_amount"
                allowSorting={true}
                sortingMethod={numericalSort}
            >
                <Format type="currency" precision={2} />
            </Column>

            <Column
                caption="Budget"
                dataField="budget_amount"
                allowSorting={true}
                sortingMethod={numericalSort}
            >
                <Format type="currency" precision={2} />
            </Column>

            <Column
                caption="Variance $"
                dataField="variance_amount"
                allowSorting={true}
                sortingMethod={numericalSort}
                cellRender={(data) => varianceRenderer(data, currencyFormat)}
            >
                <Format type="currency" precision={2} />
            </Column>

            <Column
                caption="Variance %"
                dataField="variance_ratio"
                allowSorting={true}
                sortingMethod={numericalSort}
                cellRender={(data) => varianceRenderer(data, percentageFormat)}
            >
                <Format type="percent" precision={2} />
            </Column>

            <Export enabled={true} allowExportSelectedData={false} />
        </DataGrid>
    );
};

export default OvertimeTable;
