import { isEmpty, isEqual, merge } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import superagent from 'superagent';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Col, Button, Row } from 'react-bootstrap';
import { Field, reduxForm, initialize, formValueSelector } from 'redux-form';
import LoadingSpinner from 'components/style/LoadingSpinner';
import { message } from 'antd';
import { user as TYPES } from 'state/actionTypes';
import Card from 'components/style/Card';
import ImageSelector from 'components/settings/personal/ImageSelector';
import sharedStyles from 'components/settings/shared.module.css';
import { logoutIf401 } from 'utils/authentication';
import { withRouter, Prompt } from 'react-router-dom';
import { API_URL } from 'config/constants';

class Personal extends React.Component {
    static propTypes = {
        user: PropTypes.object,
        image: PropTypes.string,
        dispatch: PropTypes.func,
        isLoading: PropTypes.bool,
        formValues: PropTypes.object,
        initialValues: PropTypes.object,
        route: PropTypes.object,
    };

    static contextTypes = {
        location: PropTypes.object,
        router: PropTypes.object.isRequired,
    };

    state = {
        picture: null,
        reader: null,
        pictureFail: false,
        isLoading: false,
    };

    componentWillMount() {
        const { formValues, initialValues } = this.props;
        const anyEdits = !isEqual(formValues, initialValues);
        return (
            <Prompt
                when={anyEdits}
                message="You have unsaved changes, would you like to leave the page anyway?"
            />
        );
    }

    isLoadingHandle = (loading) => {
        this.setState({ isLoading: loading });
    };

    submitForm = (profilePicture) => {
        const {
            user: { id },
            formValues,
            dispatch,
        } = this.props;
        // TODO (Nicholas): Replace with a redux query mutation request

        if (profilePicture) {
            formValues['profile_image_url'] = profilePicture;
        }

        superagent
            .put(`${API_URL}/users/user/${id}/details/`)
            .withCredentials()
            .type('json')
            .send(formValues)
            .end((err, res) => {
                logoutIf401(err);
                if (!err) {
                    const user = res.body.data;
                    dispatch({
                        type: TYPES.GET_USER_OBJ,
                        payload: {
                            [user.id]: user,
                        },
                    });

                    dispatch(initialize('userProfile', formValues));
                    message.success(
                        'Success',
                        'Your profile was updated successfully.',
                    );
                } else {
                    message.error(
                        'Error',
                        'Profile update failed. Please try again later and if the problem persists contact support.',
                    );
                }
            });
    };

    renderForm = () => {
        const { formValues, initialValues } = this.props;
        const anyEdits = !isEqual(formValues, initialValues);
        const formContainerStyles = {
            display: 'block',
            width: '100%',
            overflow: 'auto',
            paddingRight: '30px',
        };
        const fieldContainerStyles = { overflow: 'auto' };
        const labelStyles = {
            paddingTop: '12px',
            paddingBottom: '4px',
            fontSize: '14px',
            fontWeight: 'bold',
            display: 'block',
            color: '#5e5e5e',
            margin: 0,
        };
        const firstLabelStyles = merge({}, labelStyles, { paddingTop: '0' });
        const buttonStyles = {
            marginTop: '24px',
            paddingLeft: '15px',
            paddingRight: '15px',
            fontSize: '16px',
        };

        return (
            <div style={formContainerStyles}>
                <div style={fieldContainerStyles}>
                    <label style={firstLabelStyles}>First Name</label>
                    <Field
                        name="firstname"
                        type="text"
                        className={sharedStyles.textInput}
                        component="input"
                    />
                </div>
                <div style={fieldContainerStyles}>
                    <label style={labelStyles}>Last Name</label>
                    <Field
                        name="lastname"
                        type="text"
                        className={sharedStyles.textInput}
                        component="input"
                    />
                </div>
                <div style={fieldContainerStyles}>
                    <label style={labelStyles}>Email Address</label>
                    <Field
                        disabled
                        name="email"
                        type="text"
                        className={sharedStyles.textInput}
                        component="input"
                    />
                </div>
                <Button
                    bsStyle="primary"
                    disabled={!anyEdits || this.state.isLoading}
                    style={buttonStyles}
                    onClick={() => this.submitForm(null)}
                >
                    Save
                </Button>
            </div>
        );
    };

    render() {
        const { isLoading } = this.props;
        const colStyle = {
            marginTop: 0,
            paddingLeft: 0,
        };
        return isLoading ? (
            <LoadingSpinner style={{ textAlign: 'center' }} />
        ) : (
            <div
                className={sharedStyles.pageContainer}
                style={{ height: '100%' }}
            >
                <Row className={sharedStyles.row}>
                    <h1 className={sharedStyles.majorHeading}>
                        Personal Information
                    </h1>
                    <Card>
                        <Col
                            sm={8}
                            md={8}
                            id="PersonalProfile"
                            style={colStyle}
                        >
                            {this.renderForm()}
                        </Col>
                        <Col sm={4} md={4}>
                            <ImageSelector
                                user={this.props.user}
                                submitForm={this.submitForm}
                                isLoadingHandle={this.isLoadingHandle}
                                isLoading={this.state.isLoading}
                            />
                        </Col>
                    </Card>
                </Row>
            </div>
        );
    }
}

const mapState = (state) => {
    const noUser = isEmpty(state.user.userObj);
    // We wait for the client object because it loads
    // the configuration piece of the user state slice
    const noClient = Object.keys(state.user.clientObj).length === 2;
    // TODO (Nicholas): Find a better way to determine whether the client object has loaded
    if (noUser || noClient) {
        return {
            isLoading: true,
        };
    }

    const { userObj: user } = state.user;
    const { firstname, lastname, email, profile_image_url } = user;
    const initialValues = {
        email,
        lastname,
        firstname,
        profile_image_url,
    };

    const formValues = formValueSelector('userProfile')(
        state,
        'firstname',
        'lastname',
        'email',
    );

    return {
        user,
        formValues,
        initialValues,
    };
};

export default withRouter(
    compose(connect(mapState), reduxForm({ form: 'userProfile' }))(Personal),
);
