// @ts-nocheck
// This file is a result of nx update

/* eslint-disable no-console */
import { includes, isUndefined, extend, toString } from 'lodash';
import superagent from 'superagent';
import FusionCharts from 'fusioncharts';
import * as Sentry from '@sentry/react';
import { normalize } from 'normalizr';
import {
    userList as userListSchema,
    properties as propertySchema,
} from 'state/schema';
import { load as loadUsers } from 'state/users/actionCreators';
import {
    user as USER_TYPES,
    properties as PROPERTY_TYPES,
} from './actionTypes';
import { toggleIsLoadingProperties } from 'state/user/actionCreators';
import { clearCookie, getClientID, setClientCookie } from 'utils/cookie';
import { logoutIf401, logoutAuth0andRedirect } from 'utils/authentication';
import { Userpilot } from 'userpilot';
import { API_URL } from 'config/constants';

const fcKey =
    'nA-16C5C-11coI3A1A7B1B6A5B4E5B4E4H2A2B2kvvE1G4E1I-7C11A5C7svaD4F1H3oixF4D5D3cegB6A6E3B5F2G4H2D3B1C4B1xrrB4D1YA32bfuC6B5G4lC-7B2PC11A5uufA2I3A1B10B9B6E5C2G4D4B2B2A9B8A-16==';

const unpack = (response) => {
    return response.body.data;
};

export const getHeartbeatOrLogout = () => {
    return superagent
        .get(`${API_URL}/heartbeat`)
        .withCredentials()
        .then((res) => {
            // NOTE: The response's request object won't tell us that we redirected
            //       Thus, use the xhr object to discover we should log out
            if (res.xhr.responseURL.indexOf('logout') > -1) {
                clearCookie('laravel_session');
                clearCookie('XSRF_TOKEN');
                return logoutAuth0andRedirect();
            }
            return res;
        })
        .then(unpack)
        .catch(() => {
            clearCookie('laravel_session');
            clearCookie('XSRF_TOKEN');
            logoutAuth0andRedirect();
            return superagent.get(`${API_URL}/logout`).withCredentials();
        });
};

const getUser = (userID) => {
    return superagent
        .get(`${API_URL}/users/me`)
        .withCredentials()
        .then(unpack)
        .then((data) => {
            // NOTE (COLBY): image_json has been deprecated
            data = extend({}, data, { imageJson: data.image_json });
            return data;
        })
        .catch(logoutIf401);
};

const getAllUserSummaries = (clientId) => {
    return superagent
        .get(`${API_URL}/client/${clientId}/usersSummary`)
        .withCredentials()
        .then(unpack)
        .catch(logoutIf401);
};

const getClient = (clientID) => {
    return superagent
        .get(`${API_URL}/client/details`)
        .withCredentials()
        .then(unpack)
        .catch(logoutIf401);
};

const getAccessibleProperties = (userID, dispatch) => {
    dispatch(toggleIsLoadingProperties(true));
    return superagent
        .get(`${API_URL}/properties/accessible`)
        .withCredentials()
        .then(unpack)
        .catch(logoutIf401);
};

// Bootstrap populates the store with the most critical data
const bootstrap = (dispatch) => {
    const isDevelopmentMode = process.env.NODE_ENV === 'development';

    return getHeartbeatOrLogout().then((heartbeat) => {
        dispatch({
            type: USER_TYPES.GET_HEARTBEAT,
            payload: heartbeat,
        });

        FusionCharts.options.license({
            key: fcKey,
            creditLabel: false,
        });

        const cookieClientID = getClientID();
        const userID = heartbeat.user_id;
        const clientID = heartbeat.client_id;

        if (cookieClientID > 0 && clientID !== cookieClientID) {
            // Set cookie and force logout, someone is using multiclientadmin
            return setClientCookie(clientID).then(() =>
                window.location.replace(window.location.origin),
            );
        }

        // Client Cookie Must Be Set before making the other requests
        let ensureCookieSet = Promise.resolve();

        if (clientID !== cookieClientID) {
            ensureCookieSet = setClientCookie(clientID);
        }

        const userRequests = [];
        ensureCookieSet.then(() => {
            userRequests.push(
                getClient(clientID).then((payload) => {
                    dispatch({
                        payload,
                        type: USER_TYPES.GET_CLIENT_OBJ,
                    });
                    return payload;
                }),
                getUser(userID).then((payload) => {
                    const { user } = normalize(
                        payload,
                        userListSchema,
                    ).entities;
                    // add to users store
                    dispatch(loadUsers(user));
                    // add to user store
                    dispatch({
                        payload,
                        type: USER_TYPES.GET_USER_OBJ,
                    });
                    return payload;
                }),
            );

            if (heartbeat?.roles?.includes('ClientAdmin')) {
                userRequests.push(
                    getAllUserSummaries(clientID).then((payload) => {
                        const users = normalize(payload, userListSchema)
                            .entities.user;
                        dispatch(loadUsers(users));
                        return payload;
                    }),
                );
            }

            // Fetch properties and load them into store
            // We don't wait on properties to render
            getAccessibleProperties(userID, dispatch).then((payload) => {
                // TODO (COLBY): this should just be entitities, not a nested object with an undefined key
                payload = normalize(payload, propertySchema).entities.properties
                    .undefined;
                dispatch({
                    payload,
                    type: PROPERTY_TYPES.MERGE,
                });
                dispatch(toggleIsLoadingProperties(false));
                return payload;
            });
        });

        // google analytics - set details for session
        if (!isUndefined(window.ga)) {
            window.ga('set', 'userId', userID.toString());
            window.ga(
                'set',
                'dimension7',
                heartbeat.email.slice(0, heartbeat.email.indexOf('@')),
            );
            window.ga('set', 'dimension1', clientID.toString());
            window.ga('set', 'dimension10', heartbeat.client_name);
            window.ga(
                'set',
                'dimension11',
                includes(heartbeat.email, '@waypointbuilding.com').toString(),
            );
            window.ga('set', 'dimension13', heartbeat.email.split('@')[1]);
        } else {
            Sentry.captureMessage(
                'Google Analytics object not available, session information not loaded for ' +
                    heartbeat.email +
                    ' at ' +
                    heartbeat.client_name,
            );
            console.log(
                'Google Analytics object not available, session information not loaded for ' +
                    heartbeat.email +
                    ' at ' +
                    heartbeat.client_name,
            );
        }

        // Userpilot analytics - set user and client details for session
        if (!isDevelopmentMode) {
            Userpilot.identify(heartbeat.email, {
                first_name: heartbeat.firstname,
                last_name: heartbeat.lastname,
                email: heartbeat.email,
                role: heartbeat.roles,
                company: {
                    id: toString(clientID),
                    name: heartbeat.client_name,
                },
            });
        }

        // pendo analytics - set user and client details for session
        if (!isDevelopmentMode) {
            if (!isUndefined(window.pendo)) {
                if (window.___PENDO_ANALYTICS_DEBUG_MODE) {
                    window.pendo.enableDebugging();
                    window.pendo.enableLogging();
                    console.log('PENDO ANALYTICS: debug mode enabled');
                }

                window.pendo.initialize({
                    apiKey: window.___PENDO_ANALYTICS_API_KEY,
                    visitor: {
                        id: heartbeat.email,
                        name: `${heartbeat.firstname} ${heartbeat.lastname}`,
                        email: heartbeat.email,
                        role: heartbeat.roles,
                    },
                    account: {
                        id: clientID.toString(),
                        name: heartbeat.client_name,
                    },
                });
            } else {
                console.error(
                    'Pendo object not available, session information not loaded for ' +
                        heartbeat.email +
                        ' at ' +
                        heartbeat.client_name,
                );
            }
        }

        return ensureCookieSet
            .then(() => {
                return Promise.all(userRequests);
            })
            .then(([client, user]) => {
                const userKey = Object.keys(user)[0];
                Sentry.setUser({
                    username: user[userKey].user_name,
                    email: user[userKey].email,
                    id: user[userKey].id,
                    ip_address: '{{auto}}',
                });
            })
            .catch((e) => {
                // TODO (Nicholas): How do I recover from these failing?
                console.log(e);
            });
    });
};

export default bootstrap;
