import type { SavedFilter } from 'contexts';
import { API_URL } from 'config/constants';

interface CreateGlobalFilterDTO {
    id?: undefined;
    reference_id?: undefined;

    name: string;
    reference_type: string;
    filters_json: { title: string; key: string; values: string[] }[];
}

export async function createGlobalFilter(
    filter: SavedFilter
): Promise<SavedFilter> {
    const response = await fetch(`${API_URL}/global-filters`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            // no `id` field
            name: filter.name,
            filters_json: filter.filters,
            reference_type: filter.reference_type,
        } as CreateGlobalFilterDTO),
    });

    if (!response.ok) {
        throw new Error('Failed to create global filter');
    }

    const { data } = await response.json();

    const { id, name, reference_id, reference_type, filters_json } = data;

    return {
        id,
        name,
        filters: filters_json,
        reference_id,
        reference_type,
    } as SavedFilter;
}
