import { message } from 'antd';
import useSWR from 'swr';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { getEntityReportPublishingHistory } from 'waypoint-requests';
import { EntityReportPublishingHistory } from 'waypoint-types';

export const useGetEntityReportPublishingHistory = (
    entityCode: string,
    entityReportId: string
): DataHookResponse<EntityReportPublishingHistory> => {
    const { data, error, mutate, isValidating } = useSWR(
        `/api/report-approvals/${entityCode}/${entityReportId}/published-entity-reports`,
        () =>
            getEntityReportPublishingHistory({
                entityCode,
                entityReportId,
            }),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
            onError: () => {
                message.error('Failed to fetch published entity reports data');
            },
            shouldRetryOnError: false,
        }
    );
    return {
        isLoading: !data,
        isError: !!error,
        data,
        mutate,
        isValidating,
    };
};
