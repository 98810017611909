import PropTypes from 'prop-types';
import React from 'react';
import defaultStylePP from './defaultStylePP';

// TODO(Colby): Implement this across all cells. Right now,
//              this is only implemented on default cell.
const ellipsisStyle = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
};
// This is the simplest possible cell renderer that works
// NOTE (Daniel): to avoid adding styles to an item when they're not
// necessary, we pass an empty object as a default to getCellStyling
// which should return a font weight of 400 for anything that's not a CF or RTAG on Variance
export const DefaultCell = ({
    cellData = {},
    color,
    selectedColumn,
    ...rest
}) => {
    return (
        <div {...rest}>
            <span
                style={{
                    ...ellipsisStyle,
                    fontWeight: selectedColumn ? 600 : 400,
                    color,
                }}
            >
                {cellData}
            </span>
        </div>
    );
};

DefaultCell.propTypes = {
    cellData: PropTypes.any,
    color: PropTypes.string,
    selectedColumn: PropTypes.bool,
};

export const DefaultCellRenderer = defaultStylePP(DefaultCell);
export default DefaultCellRenderer;
