// @ts-nocheck
// This file is a result of nx update

import { users as TYPES } from 'state/actionTypes';

export function load(data) {
    return {
        type: TYPES.LOAD,
        payload: data,
    };
}

export function deactivateUser(data) {
    return {
        type: TYPES.DEACTIVATE,
        payload: data,
    };
}

export function activateUser(data) {
    return {
        type: TYPES.ACTIVATE,
        payload: data,
    };
}

export function loadUser(data) {
    return {
        type: TYPES.LOAD_USER,
        payload: data,
    };
}
