import PropTypes from 'prop-types';
import React from 'react';
import { assign } from 'lodash';
import ToggleButton from 'components/style/ToggleButton';

const container = {
    display: 'flex',
    padding: '0px',
    fontSize: '12px',
    borderRadius: 2,
    boxShadow:
        '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
};

const calculatePosition = (index, values) => {
    let position = 'middle';
    if (index === 0) {
        position = 'start';
    }
    if (index === values.length - 1) {
        position = 'end';
    }
    return position;
};

// NOTE: If exlusive is true, pass isSelected to ToggleButton Component
const ToggleGroup = (props) => {
    const { children, isDisabled, style, selectedValues, exclusive } = props;
    return (
        <div style={assign({}, container, style)}>
            {children.map((b, i) => {
                const {
                    title,
                    onClick,
                    style: buttonStyle,
                    isSelected,
                } = b.props;
                const position = calculatePosition(i, children);
                return (
                    <ToggleButton
                        key={i}
                        title={title}
                        style={buttonStyle}
                        onClick={onClick}
                        isDisabled={isDisabled}
                        position={position}
                        isSelected={
                            exclusive
                                ? isSelected
                                : isSelected ||
                                  selectedValues
                                      .map((v) => v.toLowerCase())
                                      .includes(title.toLowerCase())
                        }
                    />
                );
            })}
        </div>
    );
};

ToggleGroup.propTypes = {
    children: PropTypes.array.isRequired,
    selectedValues: PropTypes.array,
    exclusive: PropTypes.bool,
    isDisabled: PropTypes.bool,
    style: PropTypes.object,
};

export default ToggleGroup;
