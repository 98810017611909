import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { css, cx } from 'emotion';
import { DARK_GRAY, LIGHT_GRAY } from 'config/colors';

const searchBarContainer = css`
    margin: 0 5px;
    padding: 0;
    display: flex;
    flex-direction: column;
`;

const searchBar = css`
    min-width: 200px;
    border: 1px solid ${DARK_GRAY};
    border-radius: 17px;
    height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    margin: 0 5px;
`;

const searchIcon = css`
    text-align: center;
    flex: 2;
    color: ${DARK_GRAY};
    padding: 6px;
    box-sizing: border-box;
    vertical-align: bottom;
    background-color: #fff;
    border: 0;
    outline: none;
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
    &:hover,
    &:active,
    &:focus {
        cursor: pointer;
    }

    &:active {
        color: #25a5e1 !important;
    }
`;
const inputBaseStyle = css`
    outline: none;
    height: 100%;
    border: 0;
    padding: 10px;
    flex: 1;
    border-top-left-radius: 17px;
    border-bottom-left-radius: 17px;
`;
const disabledStyle = css`
    border-color: ${LIGHT_GRAY};
    color: ${LIGHT_GRAY};
`;

const SearchButton = ({ onClick, isLoading, hasResults = false }) => {
    return (
        <button
            type="button"
            className={cx([searchIcon, isLoading ? disabledStyle : ''])}
            onClick={hasResults ? onClick : null}
            disabled={isLoading}
        >
            <i
                className={isLoading ? disabledStyle : ''}
                icon={hasResults ? faTimes : faSearch}
                style={hasResults ? { fontSize: '17.5px' } : {}}
            />
        </button>
    );
};

const SearchBar = ({
    name,
    className,
    placeholder,
    inputStyle,
    searchButtonStyle,
    isLoading = true,
    value,
    onChange,
    onClear,
}) => {
    return (
        <div className={searchBarContainer}>
            <div className={cx([searchBar, className])}>
                <input
                    name={name}
                    type="text"
                    placeholder={placeholder}
                    onChange={(e) => onChange(e.target.value)}
                    className={cx([inputBaseStyle, inputStyle])}
                    value={value}
                    disabled={isLoading}
                />

                <SearchButton
                    isLoading={isLoading}
                    style={searchButtonStyle}
                    hasResults={!!value}
                    onClick={onClear}
                />
            </div>
        </div>
    );
};

SearchBar.propTypes = {
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    onSelect: PropTypes.func,
    onSearch: PropTypes.func,
    options: PropTypes.array,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    isLoading: PropTypes.bool,
};

export default SearchBar;
