import { API_URL } from 'config/constants';

interface DeleteAttributeDefinitionValue {
    message: string;
}

const deleteAttributeDefinition = async (
    attributeDefinitionId: string
): Promise<DeleteAttributeDefinitionValue> => {
    const response = await fetch(
        `${API_URL}/attributes/definition/${attributeDefinitionId}`,
        {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
        }
    );

    if (!response.ok) {
        throw new Error('Failed to delete attribute definition');
    }

    const { data } = await response.json();

    return data as DeleteAttributeDefinitionValue;
};

export default deleteAttributeDefinition;
