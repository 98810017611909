import { isUndefined, isNull } from 'lodash';
import moment from 'moment';

export const numericalSort = (a, b) => {
    if (isUndefined(a)) {
        return -1;
    }
    if (isUndefined(b)) {
        return 1;
    }
    return a - b;
};

export const stringSort = (a, b) =>
    b.toUpperCase().localeCompare(a.toUpperCase());

export const dateSort = (a, b) => {
    let ret = 0;
    if (isNull(b) && !isNull(a)) {
        ret = 1;
    } else if (isNull(a) && !isNull(b)) {
        ret = -1;
    } else if (moment(a).isBefore(b)) {
        ret = 1;
    } else if (moment(b).isBefore(a)) {
        ret = -1;
    }
    return ret;
};
