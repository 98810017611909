import { Note } from '../waypoint-types';
import { WorkflowRolesResponse } from './data-access/useGetWorkflowRoles';
import { roles as userRoles } from '../config/constants';

export const useHasNotePermission = (
    note: Note,
    userId: number,
    isAdmin = false,
    workflowRoles?: WorkflowRolesResponse
) => {
    if (note.author.id === userId || isAdmin) {
        return true;
    }
    if (!workflowRoles) {
        return false;
    }
    const assignees = workflowRoles?.assignees.map((role) => role.userId);
    const reviewers = workflowRoles?.reviewers.map((role) => role.userId);

    if (
        reviewers.includes(userId) &&
        (reviewers.includes(note.author.id) ||
            assignees.includes(note.author.id))
    ) {
        return true;
    }

    if (assignees.includes(userId) && assignees.includes(note.author.id)) {
        return true;
    }

    const isNoteCreatorAdmin = note.author.roles?.includes(
        userRoles.CLIENT_ADMIN
    );
    // If the note is created by an unassigned user, reviewers and assignees can edit it,
    // except for notes.ts created by an admin.
    if (
        (reviewers.includes(userId) || assignees.includes(userId)) &&
        !(
            reviewers.includes(note.author.id) ||
            assignees.includes(note.author.id)
        ) &&
        !isNoteCreatorAdmin
    ) {
        return true;
    }

    return false;
};
