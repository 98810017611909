import PropTypes from 'prop-types';
import React from 'react';
import SearchBar from 'components/style/SearchBar';

const TenantSearchBar = ({ value, onSearch, onClear, ...props }) => {
    return (
        <SearchBar
            name="tenant_search"
            placeholder="Search tenants"
            value={value}
            instantSearch={true}
            onChange={onSearch}
            onClear={onClear}
            {...props}
        />
    );
};

TenantSearchBar.propTypes = {
    refFunction: PropTypes.func,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    onSelect: PropTypes.func,
    onSearch: PropTypes.func,
    options: PropTypes.array,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.object,
    name: PropTypes.string,
};

export default TenantSearchBar;
