import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { EntityReportWorkflowStatus } from 'waypoint-types';
import { getOrInsertWorkflowReportStatus } from 'waypoint-requests';
import useSWR from 'swr';

export const useGetReportStatus = (
    reportMetadataId: string | undefined,
    workflowType: string,
    entityCode: string
): DataHookResponse<EntityReportWorkflowStatus> => {
    const { data, mutate, error } = useSWR(
        reportMetadataId
            ? `/workflow-report-status/${reportMetadataId}/${workflowType}/${entityCode}`
            : null,
        () => {
            return getOrInsertWorkflowReportStatus({
                reportMetadataId: reportMetadataId as string,
                workflowType,
                entityCode,
            });
        }
    );

    return {
        data,
        mutate,
        isLoading: !data,
        isError: !!error,
    };
};
