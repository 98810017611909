import { IColumnProps } from 'devextreme-react/data-grid';

export interface SavedConfiguration {
    id: string;
    reference_id: string;
    reference_type: string;
    name: string;
    filter_type: string;
    filters_json: SavedConfigurationObject;
    timestamps: {
        updated_at: Date | null;
        created_at: Date | null;
    };
    deleted_at: string | null;
}

export interface SavedConfigurationObject {
    grid_config: { [x: string]: any };
    local_config: { [x: string]: any };
}

export interface SavedConfigurationState {
    columns: IColumnProps[];
    filterValue: any;
}

export enum SavedConfigFilterType {
    RentRoll = 'rent-roll',
    PerformanceOverview = 'performance-overview',
    CapitalProjects = 'capital-projects',
    CapitalPlan = 'capital-plan',
    Attributes = 'attributes',
    PropertyValue = 'property-value',
    Crosstab = 'crosstab',
    Contracts = 'contracts',
    AgedReceivables = 'aged-receivables',
    ReportNarrative = 'report-narrative',
}
