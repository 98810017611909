import { API_URL } from 'config/constants';
import { Valuation } from 'waypoint-types';

interface CreateValuationParams {
    entity_code: string;
    date: string;
    source: string;
    method: string;
    value: number | null;
    notes?: string | null;
}

const createValuation = async (
    body: CreateValuationParams
): Promise<Valuation> => {
    const response = await fetch(`${API_URL}/valuations/create`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(body),
    });

    if (!response.ok) {
        throw new Error('Failed to create valuation');
    }

    const { data } = await response.json();

    return data as Valuation;
};

export default createValuation;
