import { API_URL } from 'config/constants';
import { Contract } from 'waypoint-types';

interface CreateContractParams {
    entity_code: string;
    service_type: string;
    vendor: string;
    status: string;
    minority_owned?: boolean;
    managers_standard_contract?: boolean;
    national_or_portfolio_discount?: boolean;
    last_bid_date?: Date;
    current_commencement_date?: Date;
    cleared_ofac?: boolean;
    annual_cost?: number;
    contract_auto_renew?: boolean;
    cancel_provision?: boolean;
    general_liability_insurance_expiration_date?: Date;
    insurance_auto_renew?: boolean;
    notes?: string;
}

const createContract = async (
    body: CreateContractParams
): Promise<Contract> => {
    const response = await fetch(`${API_URL}/contracts/create`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(body),
    });

    if (!response.ok) {
        throw new Error('Failed to create contract');
    }

    const { data } = await response.json();

    return data as Contract;
};

export default createContract;
