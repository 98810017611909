import React, { RefObject, useImperativeHandle } from 'react';
import { PDFExportable } from 'waypoint-utils/pdf/PDFExportable';
import { PDFBuilder } from 'waypoint-utils/pdf/PDFBuilder';
import 'jspdf-autotable';
import { WidgetTypes } from 'components/reports/constants';

export type VirtualPDFComponentFactory = (
    ref: React.RefObject<PDFExportable>
) => JSX.Element;

interface VirtualPDFRendererProps {
    componentFactories: VirtualPDFComponentFactory[];
    visible?: boolean;
}

interface RenderedComponentWithRef {
    element: JSX.Element;
    ref: RefObject<PDFExportable>;
}

const VirtualPDFComponentRenderer = React.forwardRef<
    PDFExportable,
    VirtualPDFRendererProps
>(
    (
        { componentFactories, visible = false }: VirtualPDFRendererProps,
        forwardedRef
    ) => {
        const [renderedComponentsWithRef] = React.useState<
            RenderedComponentWithRef[]
        >(componentFactories.map(generateRef));

        function generateRef(factory: VirtualPDFComponentFactory): {
            element: JSX.Element;
            ref: RefObject<PDFExportable>;
        } {
            const ref = React.createRef<PDFExportable>();

            return {
                ref,
                element: factory(ref),
            };
        }

        useImperativeHandle(forwardedRef, () => ({
            isReadyToExport(): boolean {
                return (
                    renderedComponentsWithRef.length > 0 &&
                    renderedComponentsWithRef.every((c) => {
                        return c.ref.current?.isReadyToExport();
                    })
                );
            },
            exportToPDF: async (): Promise<PDFBuilder> => {
                console.info(`Starting VirtualPDFRenderer export...`);

                const builder = new PDFBuilder();

                const isAttachment =
                    renderedComponentsWithRef[0].element?.props.widget
                        .widget_type === 'attachment';
                const attachmentElement = renderedComponentsWithRef[0];
                const attachmentRef = attachmentElement.ref.current;

                if (
                    isAttachment &&
                    attachmentRef &&
                    attachmentRef.exportToPDF
                ) {
                    const exportedElement = await attachmentRef.exportToPDF(
                        attachmentElement.element?.props?.pdfSettings,
                        attachmentElement.element?.props?.pdfTemplateParams
                    );

                    await builder.appendPDFBuilder(exportedElement);

                    return builder;
                }

                const scale =
                    renderedComponentsWithRef[0].element?.props.widget
                        .widget_type === WidgetTypes.CoverPage
                        ? 0.9
                        : 0.5;

                const excludeHeaders = [
                    WidgetTypes.CoverPage,
                    WidgetTypes.TableOfContents,
                ].includes(
                    renderedComponentsWithRef[0].element?.props.widget
                        .widget_type
                );
                await builder.addElementById(
                    'report',
                    renderedComponentsWithRef[0].element?.props?.pdfSettings,
                    scale,
                    renderedComponentsWithRef[0].element?.props
                        ?.pdfTemplateParams,
                    excludeHeaders
                );

                return builder;
            },
        }));

        return (
            <div
                id="report"
                style={visible ? undefined : { contentVisibility: 'hidden' }}
            >
                {renderedComponentsWithRef.map((c) => c.element)}
                {/* using an empty img src forces the browser to re-evaluate the loading of images and network resources,
                    which results in Puppeteer loading images correctly */}
                <img src="" />
            </div>
        );
    }
);

export const VirtualPDFRenderer = VirtualPDFComponentRenderer;
