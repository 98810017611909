// @ts-nocheck
// This file is a result of nx update

import { PROPERTY_CACHE_KEY } from 'config/constants';
import { identity, pick, union, get, replace } from 'lodash';
import { createSelectorCreator } from 'reselect';
import { getPureId } from 'state/routing/selectors';
import { addOldCacheKey } from 'state/utils';

// Cripple the memoization of selectors in this file
const createSelector = createSelectorCreator(identity);

export const selectProperties = (state) => state.properties;
export const idsFilter = (state, props) => {
    return union(
        state ? state.propertyIds : state,
        props ? props.propertyIds : props,
    );
};
export const selectPropertiesById = createSelector(
    selectProperties,
    idsFilter,
    (properties, propertyIds) => {
        const oldPropertyIds = propertyIds.map(
            addOldCacheKey(replace(PROPERTY_CACHE_KEY, '_', '')),
        );

        return pick(properties, oldPropertyIds);
    },
);

export const selectPropertyName = (state) =>
    get(
        state,
        `properties.${PROPERTY_CACHE_KEY + getPureId(state)}.display_name`,
    );
