export const formatBooleanValues = ({ value }: { value: boolean }) =>
    value ? 'Y' : 'N';

export const customSummaryDataFields = [
    'total_in_legal',
    'group_in_legal',
    'total_collection',
    'group_in_collection',
    'total_status',
    'group_status',
];
