import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';

class NotFound extends React.Component {
    static contextTypes = {
        router: PropTypes.any,
    };

    render() {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                }}
            >
                <h1>404 Error</h1>
                <div>That page does not exist</div>
                <Button onClick={() => this.context.router.goBack()}>
                    Go Back
                </Button>
            </div>
        );
    }
}

export default NotFound;
