// @ts-nocheck
import { get } from 'lodash';
import {
    toQuarter,
    toCalendarYear,
    toCalendarMonthAndYearAbbrv,
} from 'components/dates/utils';
import {
    PERIODICITY_QUARTER_VALUE,
    PERIODICITY_YEAR_VALUE,
} from 'components/analytics/constants';
import { generateBalanceSheetOverviewTooltip } from 'components/analytics/balance-sheet-overview/charts/utils';

/**
 * This returns a formatted date based off the selected periodcity
 * @param {string} ISODate - ex. 2021-03-01
 * @param {string} periodicityValue
 * @returns {string} date - Examples: 'Q1 2021', 'Jan 2021', '2021'
 */
export const getOvertimeDataLabelFor = (ISODate, periodicityValue) => {
    if (periodicityValue === PERIODICITY_QUARTER_VALUE) {
        return `Q${toQuarter(ISODate)} ${toCalendarYear(ISODate)}`;
    }
    if (periodicityValue === PERIODICITY_YEAR_VALUE) {
        return toCalendarYear(ISODate);
    }
    return toCalendarMonthAndYearAbbrv(ISODate);
};

/**
 * This takes an overtime dataset and converts it into the needed Fusion Charts format for datasets
 * @param {array} overtime
 * @returns {array}
 */
export const getOvertimeDataset = (overtime, periodicity) =>
    overtime.map((i) => {
        const label = getOvertimeDataLabelFor(i.period_end, periodicity);
        return {
            label,
            actual: i.actual_amount,
            budget: i.budget_amount,
            variance: i.variance_amount,
            variance_ratio: i.variance_ratio,
            variance_is_good: i.variance_is_good,
            toolText: generateBalanceSheetOverviewTooltip({
                title: label,
                value: get(i, 'actual_amount'),
            }),
        };
    });

export const buildDashboardConfigFromSlimJson = (config, config_type) => {
    const defaultTitles = {
        financial: ['Revenue', 'Expenses'],
        balance_sheet: ['Assets', 'Liabilities & Equity'],
    };

    const defaultFinancialConfig = {
        titleGridRow3: 'Revenue',
        titleGridRow4: 'Expenses',
        accountMappingGridTopLeft: 'demo__40200',
        accountMappingGridTopCenter: 'demo__50000',
        accountMappingGridTopRight: 'demo__calc_noi',
        accountMappingGridRow2: 'demo__calc_noi',
        accountMappingGridRow3: 'demo__41000',
        accountMappingGridRow4: 'demo__50000',
    };

    const defaultBalanceSheetConfig = {
        titleGridRow3: 'Assets',
        titleGridRow4: 'Liabilities & Equity',
        accountMappingGridTopLeft: 'demo__19999',
        accountMappingGridTopCenter: 'demo__29999',
        accountMappingGridTopRight: 'demo__30000',
        accountMappingGridRow2: 'demo__19999',
        accountMappingGridRow3: 'demo__19999',
        accountMappingGridRow4: 'demo__200300',
    };

    const getDefaultConfigValue = (configType, configItem) => {
        if (configType === 'financial') {
            return defaultFinancialConfig[configItem];
        }
        return defaultBalanceSheetConfig[configItem];
    };

    const gridConfig = {
        items: [
            {
                dimensions: {
                    h: 1,
                    w: 4,
                },
                position: {
                    x: 0,
                    y: 0,
                },
                title: null,
                component: 'total',
                account_mapping_code:
                    config?.accountMappingGridTopLeft ??
                    getDefaultConfigValue(
                        config_type,
                        'accountMappingGridTopLeft',
                    ),
            },
            {
                dimensions: {
                    h: 1,
                    w: 4,
                },
                position: {
                    x: 4,
                    y: 0,
                },
                title: null,
                component: 'total',
                account_mapping_code:
                    config?.accountMappingGridTopCenter ??
                    getDefaultConfigValue(
                        config_type,
                        'accountMappingGridTopCenter',
                    ),
            },
            {
                dimensions: {
                    h: 1,
                    w: 4,
                },
                position: {
                    x: 8,
                    y: 0,
                },
                title: null,
                component: 'total',
                account_mapping_code:
                    config?.accountMappingGridTopRight ??
                    getDefaultConfigValue(
                        config_type,
                        'accountMappingGridTopRight',
                    ),
            },
            {
                dimensions: {
                    h: 0.5,
                    w: 12,
                },
                position: {
                    x: 0,
                    y: 1,
                },
                title: null,
                component: 'title',
                account_mapping_code: null,
            },
            {
                dimensions: {
                    h: 3,
                    w: 12,
                },
                position: {
                    x: 0,
                    y: 1.5,
                },
                title: null,
                component: 'overtime',
                account_mapping_code:
                    config?.accountMappingGridRow2 ??
                    getDefaultConfigValue(
                        config_type,
                        'accountMappingGridRow2',
                    ),
            },
            {
                dimensions: {
                    h: 0.5,
                    w: 12,
                },
                position: {
                    x: 0,
                    y: 4.5,
                },
                title:
                    config?.titleGridRow3 ??
                    getDefaultConfigValue(config_type, 'titleGridRow3'),
                component: 'title',
                account_mapping_code: null,
            },
            {
                dimensions: {
                    h: 3,
                    w: 6,
                },
                position: {
                    x: 0,
                    y: 5,
                },
                title: null,
                component: 'overtime',
                account_mapping_code:
                    config?.accountMappingGridRow3 ??
                    getDefaultConfigValue(
                        config_type,
                        'accountMappingGridRow3',
                    ),
            },
            {
                dimensions: {
                    h: 3,
                    w: 6,
                },
                position: {
                    x: 6,
                    y: 5,
                },
                title: null,
                component: 'breakdown',
                account_mapping_code:
                    config?.accountMappingGridRow3 ??
                    getDefaultConfigValue(
                        config_type,
                        'accountMappingGridRow3',
                    ),
            },
            {
                dimensions: {
                    h: 0.5,
                    w: 12,
                },
                position: {
                    x: 0,
                    y: 8,
                },
                title:
                    config?.titleGridRow4 ??
                    getDefaultConfigValue(config_type, 'titleGridRow4'),
                component: 'title',
                account_mapping_code: null,
            },
            {
                dimensions: {
                    h: 3,
                    w: 6,
                },
                position: {
                    x: 0,
                    y: 8.5,
                },
                title: null,
                component: 'overtime',
                account_mapping_code:
                    config?.accountMappingGridRow4 ??
                    getDefaultConfigValue(
                        config_type,
                        'accountMappingGridRow4',
                    ),
            },
            {
                dimensions: {
                    h: 3,
                    w: 6,
                },
                position: {
                    x: 6,
                    y: 8.5,
                },
                title: null,
                component: 'breakdown',
                account_mapping_code:
                    config?.accountMappingGridRow4 ??
                    getDefaultConfigValue(
                        config_type,
                        'accountMappingGridRow4',
                    ),
            },
        ],
    };

    return gridConfig;
};
