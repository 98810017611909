import { commentDrawerDisplayPeriodTypes } from 'components/financials/comparative-income-statement/banner/ComparisonSelectionsBannerUtils';
import {
    NONE_VALUE,
    PERIODICITY_FIELD,
} from 'components/financials/comparative-income-statement/constants';
import { ComparisonSelections } from 'components/financials/comparative-income-statement/ComparisonIncomeStatementTypes';
import { format } from 'date-fns';
import { capitalize } from 'lodash';
import { ClientMode } from 'waypoint-types';

export const PropertyProfileCommentPaneSubheading = 'Property discussion';

export const getIncomeStatementSubheading = (
    selections: ComparisonSelections,
    clientModes: ClientMode[]
): string => {
    const capitalizeDisplayName = (
        comparisonPeriod: string,
        separator: string
    ) => {
        const comparisonPeriodParts = comparisonPeriod.split(separator);
        return comparisonPeriodParts.map((cp) => capitalize(cp)).join(' ');
    };

    const getDisplayName = (comparisonType: string) => {
        const displayName =
            clientModes?.find((cm) => cm.code === comparisonType)
                ?.display_name ?? comparisonType;
        return capitalizeDisplayName(displayName, ' ');
    };

    const currentPeriodicity = selections[PERIODICITY_FIELD];

    const periodGrouping =
        commentDrawerDisplayPeriodTypes[
            currentPeriodicity as keyof typeof commentDrawerDisplayPeriodTypes
        ];

    const comparisonPeriod = capitalizeDisplayName(
        selections.comparison_period,
        '_'
    );

    const formattedDate =
        currentPeriodicity === 'custom'
            ? `${format(
                  new Date(selections.period_primary[0]),
                  `MMM ''yy`
              )} - ${format(
                  new Date(selections.period_primary[1]),
                  `MMM ''yy`
              )}`
            : format(new Date(selections.period_primary[1]), `MMM ''yy`);

    const baseSubheading = `${periodGrouping} ${getDisplayName(
        selections.comparison_type[0]
    )} (${formattedDate})`;

    const comparisonSubheading =
        selections.comparison_type[1] === NONE_VALUE
            ? baseSubheading
            : `${baseSubheading} to ${getDisplayName(
                  selections.comparison_type[1]
              )} (${comparisonPeriod})`;

    if (
        selections.comparison_type.length === 3 &&
        selections.comparison_type[2] !== NONE_VALUE
    ) {
        return `${comparisonSubheading} to ${getDisplayName(
            selections.comparison_type[2]
        )}
         (${capitalizeDisplayName(
             selections.comparison_period_tertiary,
             '_'
         )})`;
    }
    return comparisonSubheading;
};

export const getBalanceSheetSubheading = (
    primaryDate: string,
    secondaryDate: string
): string => {
    if (primaryDate && secondaryDate) {
        return `${format(new Date(primaryDate), 'LLLL yyyy')} - ${format(
            new Date(secondaryDate),
            'LLLL yyyy'
        )}`;
    }
    return '';
};
