import React, { useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { EditorUtils, EditorToolsSettings } from '@progress/kendo-react-editor';
import { InsertImageDialog } from './InsertImageDialog';
import { EditorView } from 'prosemirror-view';

const imageSettings = EditorToolsSettings.image;

export interface InsertImageProps {
    view?: EditorView;
    referenceType: string;
}

export const InsertImage = ({ view, referenceType }: InsertImageProps) => {
    const [openDialog, setOpenDialog] = useState(false);
    const toggleDialog = () => setOpenDialog(!openDialog);

    const state = view && view.state;
    const nodeType = state ? state.schema.nodes[imageSettings.node] : undefined;

    return (
        <React.Fragment>
            <Button
                onClick={toggleDialog}
                disabled={
                    !nodeType ||
                    !state ||
                    !EditorUtils.canInsert(state, nodeType)
                }
                onMouseDown={(e) => e.preventDefault()}
                onPointerDown={(e) => e.preventDefault()}
                title="Insert Image"
                {...imageSettings.props}
            />
            {openDialog && view && (
                <InsertImageDialog
                    key="insertImageDialog"
                    view={view}
                    onClose={toggleDialog}
                    referenceType={referenceType}
                    imageNode={imageSettings.node}
                />
            )}
        </React.Fragment>
    );
};
