import React from 'react';
import {
    EntityDataGroupingKeys,
    entityDataGroupingOptions,
} from 'utils/EntityDataGroupingConstants';
import { AttributeFromAPI } from 'contexts';
import { Button, Dropdown, MenuProps } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { css } from 'emotion';

const attributeGroupingDropdownStyle = css`
    min-width: 200px;
`;

export interface AttributesGroupBySelectProps {
    groupingSelection: EntityDataGroupingKeys;
    setGroupingSelection: (option: EntityDataGroupingKeys) => void;
    attributeSelection: AttributeFromAPI | null;
    attributes: AttributeFromAPI[] | undefined;
    setAttributeSelection: (option: AttributeFromAPI) => void;
}

export const AttributesGroupBySelect = ({
    groupingSelection,
    setGroupingSelection,
    attributeSelection,
    attributes,
    setAttributeSelection,
}: AttributesGroupBySelectProps) => {
    if (!attributes || !attributeSelection) {
        return null;
    }

    const onGroupByValueChanged = (newValue: string) => {
        const attribute = attributes.find(
            (attribute) => attribute.dataIndex === newValue,
        );

        if (attribute) {
            setAttributeSelection(attribute);
        }
    };

    const items = entityDataGroupingOptions.map((typeOption) => {
        if (typeOption.value === 'attributes') {
            const attributesByCategory = attributes.reduce<{
                [key: string]: AttributeFromAPI[];
            }>((result, attribute) => {
                result[attribute.category] = result[attribute.category] || [];
                result[attribute.category].push(attribute);

                return result;
            }, {});

            const categoryOptions = Object.keys(attributesByCategory)
                .sort()
                .map((category) => {
                    return {
                        key: category,
                        label: category,
                        children: attributesByCategory[category].map(
                            (attribute) => {
                                return {
                                    key: attribute.key,
                                    label: attribute.title,
                                };
                            },
                        ),
                    };
                });

            return {
                key: typeOption.value,
                label: typeOption.label,
                children: categoryOptions,
                selectable: true,
            };
        }

        return {
            key: typeOption.value,
            label: typeOption.label,
        };
    });

    const onClick: MenuProps['onClick'] = (e) => {
        const containsProperty = e.keyPath.includes('property');

        if (containsProperty) {
            setGroupingSelection(EntityDataGroupingKeys.Property);
            return;
        }

        setGroupingSelection(EntityDataGroupingKeys.Attributes);
        onGroupByValueChanged(e.key as string);
    };

    return (
        <Dropdown
            className={attributeGroupingDropdownStyle}
            trigger={['hover']}
            menu={{
                items,
                selectable: true,
                onClick,
                selectedKeys: [groupingSelection],
            }}
        >
            <Button
                style={{
                    textAlign: 'left',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0 15px',
                    width: 'auto',
                }}
                onClick={(e) => e.preventDefault()}
                data-testid="group-by-dropdown"
            >
                <span>
                    {groupingSelection
                        ? groupingSelection ===
                          EntityDataGroupingKeys.Attributes
                            ? attributeSelection?.title
                            : 'Property'
                        : 'Select'}
                </span>
                <DownOutlined />
            </Button>
        </Dropdown>
    );
};
