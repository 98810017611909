import React, { useCallback } from 'react';
import { css } from 'emotion';
import DataGrid, {
    Export,
    Column,
    Format,
    TotalItem,
    Summary,
    Pager,
    Paging,
    Scrolling,
    DataGridRef,
} from 'devextreme-react/data-grid';
import { stringSort } from 'utils/tables/sorters';
import fusioncharts from 'fusioncharts';
import charts from 'fusioncharts/fusioncharts.charts';
import ReactFC from 'react-fusioncharts';
import chartBuilder from 'components/charts/chartBuilder';
import { theme } from 'config/colors';
import { DelinquencyData } from 'waypoint-types';
import { Dictionary } from 'ts-essentials';
import { safeDivision } from 'waypoint-utils';
import { AgedReceivablesSummaryExportableGrid } from 'components/reports/components/entity-report-widgets/report-widget-export-grids/AgedReceivablesSummaryExportableGrid';
import { EntityReportWidgetPdfSettings } from 'components/reports/ReportUtils';

charts(fusioncharts);

const chartStyle = {
    caption: 'Charge Summary',
    useEllipsesWhenOverflow: '1',
    maxLabelWidthPercent: '20',
    showValues: '1',
    placeValuesInside: '1',
    numVisiblePlot: '20',
    numDivLines: '5',
    numberPrefix: '$',
    baseFontColor: theme.colors.mediumGray,
    xAxisNameFontColor: theme.colors.mediumGray,
    yAxisNameFontColor: theme.colors.mediumGray,
    palettecolors: '#16C4FF',
};

const containerStyle = css`
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    margin-top: 12px;
    @media (min-width: 768px) {
        grid-template-columns: 35% 65%;
    }
`;

const convertToFusionChartsData = (summaryData: DelinquencyData[]) => {
    const totalByCategory = summaryData.reduce((acc, item) => {
        const key = item.charge_code.toLowerCase();
        if (!acc[key]) {
            acc[key] = item;
            return acc;
        }
        acc[key].total += item.total;
        return acc;
    }, {} as Dictionary<DelinquencyData>);

    const categories = Object.values(totalByCategory).map((item) => ({
        label: item.charge_code.toUpperCase(),
    }));

    const dataset = Object.values(totalByCategory).map((item) => ({
        value: item.total.toString(),
    }));

    return {
        categories: [{ category: categories }],
        dataset: [{ data: dataset }],
    };
};

interface AgedReceivablesSummaryProps {
    setIsChartReadyToExport?: (isReady: boolean) => void;
    chartTableData: DelinquencyData[];
    dataGridRef?: React.RefObject<DataGridRef<any, any>>;
    widgetId?: string;
    isPDFExport?: boolean;
    pdfSettings?: EntityReportWidgetPdfSettings;
}

const AgedReceivablesSummary = ({
    setIsChartReadyToExport,
    chartTableData,
    dataGridRef,
    widgetId,
    isPDFExport,
    pdfSettings,
}: AgedReceivablesSummaryProps) => {
    const calculatePercentage = useCallback(
        ({ total }: DelinquencyData) => {
            const totalCharges = chartTableData.reduce(
                (amount, item) => amount + item.total,
                0,
            );
            return safeDivision(total, totalCharges);
        },
        [chartTableData],
    );

    const getChartWidth = () => {
        /*
        NOTE: do not remove this code! Fusion charts need to be set within a div with a specified width
        when exporting... otherwise, they will export with sizing based on the size of the window rather than
        based on the specified paper size and orientation
        */
        if (!isPDFExport) {
            return {};
        }

        const legalLandscapeOffset =
            pdfSettings?.paperSize === 'Legal' ? 150 : 0;
        return {
            width: pdfSettings?.landscape
                ? `${450 + legalLandscapeOffset}px`
                : '300px',
        };
    };

    const { categories, dataset } = convertToFusionChartsData(chartTableData);

    const chart = chartBuilder();
    chart.type('scrollbar2d');
    chart.height('350');
    chart.width('100%');

    chart.exportFormats('PNG|PDF|JPG|SVG');
    const fullChartStyle = isPDFExport
        ? {
              ...chartStyle,
              baseFont: 'Arial',
              valueFont: 'Arial',
              valueFontBold: '0',
              legendItemFont: 'Arial',
              exportEnabled: '0',
              animation: '0',
          }
        : {
              ...chartStyle,
              numVisiblePlot: '6',
              flatScrollBars: '1',
              scrollColor: '#cccccc',
              scrollHeight: '10',
          };
    chart.style(fullChartStyle);
    chart.categories(categories);
    chart.dataset(dataset);

    const { config: chartConfig } = chart;

    const onDrawComplete = () => {
        setIsChartReadyToExport && setIsChartReadyToExport(true);
    };

    return (
        <div className={containerStyle} data-testid="aged-receivable-summary">
            <div
                id={`agedReceivablesSummary_${widgetId}`}
                style={getChartWidth()}
            >
                <ReactFC
                    {...chartConfig}
                    data-testid="aged-receivable-summary-chart"
                    fcEvent-drawComplete={onDrawComplete}
                />
            </div>
            <div style={{ marginRight: 10 }}>
                {isPDFExport ? (
                    <AgedReceivablesSummaryExportableGrid
                        data={chartTableData}
                    />
                ) : (
                    <DataGrid
                        wordWrapEnabled={false}
                        dataSource={chartTableData}
                        ref={dataGridRef}
                        height={350}
                        allowColumnReordering={true}
                        columnAutoWidth={true}
                        columnMinWidth={75}
                        showBorders={true}
                        showRowLines={true}
                        showColumnLines={true}
                        rowAlternationEnabled={true}
                        id="aged-receivable-summary-data-grid"
                    >
                        {/* hiding this column until data gap around charge names and categories are resolved */}
                        <Column
                            caption="Category"
                            dataField="charge_type_display_name"
                            allowSorting={true}
                            sortingMethod={stringSort}
                            visible={false}
                        />
                        <Column
                            caption="Charge"
                            dataField="charge_code"
                            allowSorting={true}
                            width={'auto'}
                            allowGrouping={false}
                            cellRender={(cellData) => {
                                const chargeDisplayName = cellData.data
                                    .charge_display_name
                                    ? ` (${cellData.data.charge_display_name})`
                                    : '';
                                return `${cellData.data.charge_code.toUpperCase()}${chargeDisplayName}`;
                            }}
                        />
                        <Column
                            caption="Total"
                            dataField="total"
                            allowGrouping={false}
                            allowSorting={true}
                        >
                            <Format type="currency" precision={2} />
                        </Column>
                        <Column
                            caption="% of Total"
                            dataField="percent_of_total"
                            alignment={'center'}
                            allowGrouping={false}
                            allowSorting={true}
                            calculateCellValue={calculatePercentage}
                        >
                            <Format type="percent" precision={1} />
                        </Column>
                        <Column
                            caption="Current"
                            dataField="balance"
                            allowGrouping={false}
                            allowSorting={true}
                        >
                            <Format type="currency" precision={2} />
                        </Column>
                        <Column
                            caption="Prepaid"
                            dataField="prepaid_balance"
                            allowGrouping={false}
                            allowSorting={true}
                        >
                            <Format type="currency" precision={2} />
                        </Column>
                        <Column
                            caption="1-30 Days"
                            dataField="thirty_days_due_balance"
                            allowGrouping={false}
                            allowSorting={true}
                        >
                            <Format type="currency" precision={2} />
                        </Column>
                        <Column
                            caption="31-60 Days"
                            dataField="sixty_days_due_balance"
                            allowGrouping={false}
                            allowSorting={true}
                        >
                            <Format type="currency" precision={2} />
                        </Column>
                        <Column
                            caption="61-90 Days"
                            dataField="ninety_days_due_balance"
                            allowGrouping={false}
                            allowSorting={true}
                        >
                            <Format type="currency" precision={2} />
                        </Column>
                        <Column
                            caption="> 90 Days"
                            dataField="ninety_more_days_due_balance"
                            allowGrouping={false}
                            allowSorting={true}
                        >
                            <Format type="currency" precision={2} />
                        </Column>
                        <Summary>
                            <TotalItem
                                column="total"
                                name="total"
                                summaryType="sum"
                                displayFormat="{0}"
                                valueFormat={{ type: 'currency', precision: 2 }}
                            />
                            <TotalItem
                                column="percent_of_total"
                                name="percent_of_total"
                                summaryType="sum"
                                displayFormat="{0}"
                                valueFormat={{ type: 'percent', precision: 1 }}
                            />
                            <TotalItem
                                column="balance"
                                name="total_balance"
                                summaryType="sum"
                                displayFormat="{0}"
                                valueFormat={{ type: 'currency', precision: 2 }}
                            />
                            <TotalItem
                                column="prepaid_balance"
                                name="total_prepaid_balance"
                                summaryType="sum"
                                displayFormat="{0}"
                                valueFormat={{ type: 'currency', precision: 2 }}
                            />
                            <TotalItem
                                column="thirty_days_due_balance"
                                name="total_thirty_days_due_balance"
                                summaryType="sum"
                                displayFormat="{0}"
                                valueFormat={{ type: 'currency', precision: 2 }}
                            />
                            <TotalItem
                                column="sixty_days_due_balance"
                                name="total_sixty_days_due_balance"
                                summaryType="sum"
                                displayFormat="{0}"
                                valueFormat={{ type: 'currency', precision: 2 }}
                            />
                            <TotalItem
                                column="ninety_days_due_balance"
                                name="total_ninety_days_due_balance"
                                summaryType="sum"
                                displayFormat="{0}"
                                valueFormat={{ type: 'currency', precision: 2 }}
                            />
                            <TotalItem
                                column="ninety_more_days_due_balance"
                                name="total_ninety_more_days_due_balance"
                                summaryType="sum"
                                displayFormat="{0}"
                                valueFormat={{ type: 'currency', precision: 2 }}
                            />
                        </Summary>
                        <Pager visible={false} allowedPageSizes={['all']} />
                        <Export
                            enabled={true}
                            allowExportSelectedData={false}
                        />
                        <Scrolling />
                        <Paging enabled={false} />
                    </DataGrid>
                )}
            </div>
        </div>
    );
};

export default AgedReceivablesSummary;
