import { TenantData } from 'components/leases/tenants/top-tenants/topTenantsUtils';
import { API_URL } from 'config/constants';
import { SelectedDataLevel } from 'waypoint-types';
import { RecurringCharge } from 'waypoint-types';
import { Dictionary } from 'ts-essentials';

export interface TopTenantsData {
    tenants: TenantData[];
    sqFtCalculations: {
        occupiedRentableSqFt: number;
        totalRentableSqFt: number;
    };
    buckets: Dictionary<RecurringCharge[], string>;
}

const getTopTenants = async (
    entityCodes: string[],
    selectedDataLevel: SelectedDataLevel,
    selectedChargeCode: string[],
    topNTenants: string
): Promise<TopTenantsData> => {
    const response = await fetch(`${API_URL}/leases/top-tenants`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            entity_codes: entityCodes,
            selected_data_level: selectedDataLevel,
            selected_charge_code: selectedChargeCode,
            top_n_tenants: topNTenants,
        }),
    });

    if (!response.ok) {
        throw new Error('FAILED');
    }
    const { data } = await response.json();

    return data;
};

export default getTopTenants;
