import React, { useCallback, useEffect, useState } from 'react';
import { Radio, Select } from 'antd';
import { ReportWidgetSettingsBuilderProps } from 'waypoint-types';
import ReportWidgetSettingsModal from 'components/reports/report-widget/ReportWidgetSettingsModal';
import { RecurringChargeSelect } from 'components/leases/components/recurring-charge/RecurringChargeSelect';
import { useRecurringCharges } from 'waypoint-hooks';
import {
    metricOptions,
    topNOptions,
} from 'components/leases/tenants/top-tenants/topTenantsUtils';
import { DisabledDashboard } from 'waypoint-react';

const { Option } = Select;

const TopNTenantSettingsBuilder = ({
    entityCodes,
    isModalOpen,
    setIsModalOpen,
    onSaveWidgetSettings,
    widgetType,
    currentSettings,
}: ReportWidgetSettingsBuilderProps): JSX.Element => {
    const [selectedChargeCode, setSelectedChargeCode] = useState<string[]>(
        currentSettings?.widgetSelectedChargeCode ?? []
    );
    const [selectedMetric, setSelectedMetric] = useState<string>(
        currentSettings?.widgetMetric ?? metricOptions[0].value
    );
    const [topNTenants, setTopNTenants] = useState<string>(
        currentSettings?.widgetTopNTenants ?? topNOptions[2].value
    );
    const [visualOption, setVisualOption] = useState<string>(
        currentSettings?.widgetVisualOption ?? 'chart_table'
    );

    const buildReportWidgetSettingsInputs = () => {
        return {
            widgetSelectedChargeCode: selectedChargeCode,
            widgetMetric: selectedMetric,
            widgetTopNTenants: topNTenants,
            widgetVisualOption: visualOption,
        };
    };

    const { data: charges } = useRecurringCharges(entityCodes, {
        stakeholder: null,
        percentageType: null,
    });

    useEffect(() => {
        if (selectedMetric === metricOptions[0].value) {
            setSelectedChargeCode([]);
        }
    }, [selectedMetric]);

    const onChange = useCallback(
        (value: string[]) => {
            setSelectedChargeCode(value);
        },
        [setSelectedChargeCode]
    );

    if (!charges) {
        return <DisabledDashboard text={'Loading'} />;
    }

    const { summary } = charges;

    return (
        <ReportWidgetSettingsModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            settingsJsonBuilder={buildReportWidgetSettingsInputs}
            onSaveWidgetSettings={onSaveWidgetSettings}
            widgetType={widgetType}
        >
            <div style={{ marginBottom: '10px' }}>
                <div>Top N Tenants:</div>
                <Select
                    style={{ width: '100%', marginTop: 12 }}
                    onChange={(value) => {
                        setTopNTenants(value);
                    }}
                    value={topNTenants}
                >
                    {topNOptions?.map((option) => (
                        <Option
                            key={option.value}
                            value={option.value}
                            label={option.label}
                        >
                            {option.label}
                        </Option>
                    ))}
                </Select>
            </div>
            <div style={{ marginBottom: '10px' }}>
                <div>Metric:</div>
                <>
                    <Select
                        style={{ width: '100%', marginTop: 12 }}
                        onChange={(value) => {
                            setSelectedMetric(value);
                        }}
                        value={selectedMetric}
                    >
                        {metricOptions?.map((option) => (
                            <Option
                                key={option.value}
                                value={option.value}
                                label={option.label}
                            >
                                {option.label}
                            </Option>
                        ))}
                    </Select>
                    {selectedMetric !== 'rentable_sf' && (
                        <RecurringChargeSelect
                            key={selectedChargeCode.join(',')}
                            recurringCharges={summary.buckets}
                            selectedChargeCodes={selectedChargeCode}
                            onChange={onChange}
                            style={{
                                width: '100%',
                                minWidth: '200px',
                                marginRight: 12,
                                marginTop: 12,
                            }}
                        />
                    )}
                </>
            </div>
            <div style={{ marginBottom: '10px' }}>
                <span>Visual options:</span>
                <div style={{ marginTop: 12 }}>
                    <Radio.Group
                        onChange={(e) => setVisualOption(e.target.value)}
                        value={visualOption}
                    >
                        <Radio value={'chart_table'}>Chart and Table</Radio>
                        <Radio value={'table'}>Table</Radio>
                    </Radio.Group>
                </div>
            </div>
        </ReportWidgetSettingsModal>
    );
};

export default TopNTenantSettingsBuilder;
