import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    useGetQueryParam,
    useGetReportApprovals,
    useGetReportById,
    useGetSelectedFilteredEntityCodes,
    useReports,
} from 'waypoint-hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { Card, message, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
    checkIfValidUUID,
    decorateReportsForGrid,
    getEntityReportWidgetsForSelectedReport,
    REPORT_ID_KEY,
    ReportGridCardStyle,
    updateReportEntityReports,
} from './ReportUtils';
import {
    EntityReport,
    PropertyType,
    User,
    UpdatedEntityReport,
    Report,
    ReportGridType,
} from 'waypoint-types';
import { css } from 'emotion';
import { DisabledDashboard } from 'waypoint-react';
import {
    cloneReports,
    updateEntityReports,
    updateReportStatus,
} from 'waypoint-requests';
import { connect, RootStateOrAny } from 'react-redux';
import { selectProperties } from 'state/properties/selectors';
import { selectUsers } from 'state/users/selectors';
import { Dictionary } from 'ts-essentials';
import { ReportModal } from './components/ReportModal';
import { EntityReportTable } from './EntityReportTable';
import { roles as userRoles } from 'config/constants';
import ReportGridHeader from './components/ReportGridHeader';
import ReportEmptyState from './components/ReportEmptyState';
import { clearQueryParam } from 'waypoint-hooks/useGetQueryParam';
import EditBottomMenu from './components/EditBottomMenu';
import theme from 'config/theme';
import { reportStatus } from './constants';
import { DataGridRef } from 'devextreme-react/cjs/data-grid';

interface ReportGridProps {
    properties: Dictionary<PropertyType>;
    users: User[];
    isAdmin: boolean;
    isWorkflowSteps: boolean;
    workflowReportId?: string;
    renderWorkflowStepsNavigation?: (
        onNext: () => void,
        onSave?: () => void,
        isNextDisabled?: boolean,
    ) => JSX.Element;
}

const pageStyle = css`
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
`;

const alignStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
};

const footerStyle = (isFooterVisible: boolean) => css`
    position: absolute;
    bottom: ${isFooterVisible ? 0 : -80}px;
    left: 0;
    width: 100%;
    height: 80px;
    background: #fff;
    box-shadow: rgb(216 220 222) 0px 1px 0px inset;
    display: flex;
    z-index: 4;
    transition: bottom 0.3s ease-in-out;
`;

const { confirm } = Modal;

const ReportGrid = ({
    users,
    properties,
    isAdmin,
    isWorkflowSteps,
    workflowReportId,
    renderWorkflowStepsNavigation,
}: ReportGridProps) => {
    const reportIdParam = useGetQueryParam(REPORT_ID_KEY);
    const history = useHistory();
    const location = useLocation();
    const uuid = workflowReportId ?? location.pathname.split('/').pop();
    const masterDetailGrid = useRef<DataGridRef>(null);

    const [selectedMenuItem, setSelectedMenuItem] = useState<string | null>(
        null,
    );

    const [isFooterVisible, setIsFooterVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [batchValues, setBatchValues] = useState<UpdatedEntityReport>({
        dueDate: null,
        assignee: [],
        sendAssigneeNotification: false,
        reviewer: [],
        sendReviewerNotification: false,
        reportId: null,
        selectedReports: [],
    });
    const [selectedEntityCodes, setSelectedEntityCodes] = useState<string[]>(
        [],
    );

    const [report, setReport] = useState<Report | null>(null);

    const selectedFilteredEntityCodes: string[] =
        useGetSelectedFilteredEntityCodes();

    const { deleteReportRequest } = useReports();

    const {
        data: fullReport,
        mutate,
        isLoading,
        isValidating,
    } = useGetReportById(uuid ?? '', true);

    const entityReportIds = fullReport
        ? fullReport.entityReports?.map((entityReport) => entityReport.id)
        : [];

    const { data: approvals, isValidating: isValidatingApprovals } =
        useGetReportApprovals({
            entityReportIds,
        });

    const entityReportWidgets = fullReport?.entityReports
        ? fullReport?.entityReports?.flatMap(
              (entityReport) => entityReport?.entityReportWidgets ?? [],
          )
        : [];

    const reportsData = useMemo(() => {
        if (fullReport) {
            const filteredEntityReports = fullReport.entityReports?.length
                ? fullReport.entityReports?.filter((entityReport) =>
                      selectedFilteredEntityCodes.includes(
                          entityReport.entity_code,
                      ),
                  )
                : [];

            const filteredReport = {
                ...fullReport,
                entityReports: filteredEntityReports,
            };

            setReport(filteredReport);

            return decorateReportsForGrid(
                [filteredReport],
                selectedFilteredEntityCodes,
                entityReportWidgets ?? [],
            );
        }
        return null;
    }, [selectedFilteredEntityCodes, fullReport]);

    useEffect(() => {
        if (!isAdmin) {
            history.push('/reports');
        }
    }, []);

    useEffect(() => {
        if (isValidating || !uuid || !reportsData) {
            return;
        }

        const selectedReport = reportsData?.filter(
            (report) => report.id === uuid,
        );
        setSelectedMenuItem(selectedReport?.[0]?.id);
    }, [reportsData, isValidating, uuid, report]);

    useEffect(() => {
        setIsFooterVisible(batchValues.selectedReports.length > 0);
    }, [batchValues]);

    useEffect(() => {
        if (reportIdParam) {
            setSelectedMenuItem(reportIdParam);
            clearQueryParam(REPORT_ID_KEY);
        }
    }, []);

    useEffect(() => {
        resetSelection();
    }, [selectedFilteredEntityCodes]);

    const onSelectionChanged = useCallback(
        async ({ selectedRowKeys }: Dictionary<EntityReport[]>) => {
            if (selectedRowKeys.length === 0) {
                resetSelection();
                return;
            }
            const selectedReports = selectedRowKeys.map(
                (r: EntityReport) => r.id,
            );
            setBatchValues({
                ...batchValues,
                selectedReports,
                reportId: selectedRowKeys[0].report_id,
            });
            setSelectedEntityCodes(
                selectedRowKeys.map((r: EntityReport) => r.entity_code),
            );
        },
        [batchValues, setBatchValues],
    );

    const resetSelection = () => {
        setBatchValues({
            dueDate: null,
            assignee: [],
            sendAssigneeNotification: false,
            reviewer: [],
            sendReviewerNotification: false,
            reportId: null,
            selectedReports: [],
        });
        setSelectedEntityCodes([]);
        masterDetailGrid?.current?.instance().clearSelection();
    };

    if (
        !report ||
        !reportsData ||
        isLoading ||
        isValidating ||
        isValidatingApprovals
    ) {
        return <DisabledDashboard text={'Loading'} />;
    }

    const onEdit = () => {
        setModalVisible(true);
    };

    const onCancel = () => {
        masterDetailGrid?.current?.instance().clearSelection();
        onSelectionChanged({ selectedRowKeys: [] });
        setIsFooterVisible(false);
        setModalVisible(false);
    };

    const onSave = () => {
        const dueDate = batchValues.dueDate?.toISOString();

        const update = {
            ...batchValues,
            reviewer: batchValues.reviewer.filter((r) => r !== undefined),
            due_date: dueDate,
        };

        const updatedReports = updateReportEntityReports([report], update);
        setModalVisible(false);
        batchValues &&
            mutate(
                async () => {
                    try {
                        await updateEntityReports(update);
                        message.success('Report updated successfully');
                        if (
                            batchValues.sendAssigneeNotification ||
                            batchValues.sendReviewerNotification
                        ) {
                            message.success(`Users were successfully notified`);
                        }

                        setBatchValues({
                            dueDate: null,
                            assignee: [],
                            sendAssigneeNotification: false,
                            reviewer: [],
                            sendReviewerNotification: false,
                            reportId: null,
                            selectedReports: [],
                        });
                        resetSelection();
                        return updatedReports[0];
                    } catch (e) {
                        message.error('Failed to update Report');
                    }
                },
                {
                    optimisticData: updatedReports[0],
                    rollbackOnError: true,
                    populateCache: true,
                    revalidate: true,
                },
            );
    };

    const onDueDateChange = (date: moment.Moment | null) => {
        setBatchValues({ ...batchValues, dueDate: date });
    };

    const onAssigneeChange = (value: string[]) => {
        setBatchValues({ ...batchValues, assignee: value });
    };

    const onSendAssigneeNotificationChange = (value: boolean) => {
        setBatchValues({
            ...batchValues,
            sendAssigneeNotification: value,
        });
    };

    const onSendReviewerNotificationChange = (value: boolean) => {
        setBatchValues({
            ...batchValues,
            sendReviewerNotification: value,
        });
    };

    const onReviewerChange = (
        selectedReviewerIds: string[],
        hierarchyIndex?: number,
    ) => {
        if (hierarchyIndex === undefined) {
            setBatchValues({
                ...batchValues,
                reviewer: selectedReviewerIds,
            });
            return;
        }

        const reviewers: string[] = [...batchValues.reviewer];
        reviewers[hierarchyIndex] = selectedReviewerIds[0];

        setBatchValues({
            ...batchValues,
            reviewer: reviewers,
        });
    };

    const deleteReport = async (
        report: ReportGridType[],
        deletePermanently: boolean,
    ) => {
        if (!report.length) {
            return;
        }

        const reportIds = report.map((r) => r.id);

        confirm({
            title: 'Are you sure you want to delete these items?',
            icon: <ExclamationCircleOutlined />,
            content: deletePermanently
                ? 'These reports will be permanently deleted'
                : 'This report will be moved to the trash',
            onOk: async () => {
                try {
                    await deleteReportRequest(reportIds, deletePermanently);
                    message.success(
                        deletePermanently
                            ? 'Report(s) successfully permanently deleted'
                            : 'Report deleted successfully',
                    );
                    resetSelection();
                    history.push('/reports/manager');
                } catch (e) {
                    message.error('Error deleting report');
                }
            },
        });
    };

    const cloneReport = async (report: Report | undefined) => {
        if (!report) {
            return;
        }

        const reportId = report.id;
        try {
            const clonedReport = await cloneReports({ reportId });
            message.success('Report cloned successfully');
            history.push(`/report/manager/${clonedReport.id}`);
        } catch (e) {
            message.error('Error cloning report');
        }
    };

    const renderBottomMenu = () => {
        return (
            <EditBottomMenu
                onEdit={onEdit}
                batchValues={batchValues}
                onCancel={onCancel}
            />
        );
    };

    const onActivateReport = async () => {
        if (!checkIfValidUUID(report.id)) {
            return;
        }

        await updateReportStatus({
            id: workflowReportId ?? report.id,
            state: reportStatus.active,
            notifyIfActive: true,
        });
    };

    const reportGridRender = () => {
        if (reportDataSelected) {
            return (
                <div
                    data-testid="report-grid-content"
                    style={{
                        padding: isWorkflowSteps ? '10px 0 0 0' : '12px',
                        marginTop: 0,
                        height: isWorkflowSteps ? 'auto' : paneRightArea,
                        overflow: 'auto',
                    }}
                >
                    {isWorkflowSteps ? (
                        <Card
                            title="Assign Roles"
                            className={ReportGridCardStyle}
                            bodyStyle={{
                                display: 'none',
                            }}
                        />
                    ) : null}
                    <div
                        style={{
                            display: 'block',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            marginBottom: !isWorkflowSteps ? '10px' : '0',
                            lineHeight: '1.25',
                            height: isWorkflowSteps
                                ? `${window.innerHeight - 344}px`
                                : 'unset',
                            overflow: isWorkflowSteps ? 'scroll' : 'unset',
                            border: isWorkflowSteps
                                ? `1px solid ${theme.colors.grays.light}`
                                : 'unset',
                            borderTop: 'none',
                        }}
                    >
                        <EntityReportTable
                            isWorkflowSteps={isWorkflowSteps}
                            reports={[report]}
                            properties={properties}
                            users={users}
                            onSelectionChanged={onSelectionChanged}
                            reportManagerRef={masterDetailGrid}
                            entityReportWidgets={getEntityReportWidgetsForSelectedReport(
                                reportDataSelected,
                                entityReportWidgets ?? [],
                            )}
                            isAdmin={isAdmin}
                            approvals={approvals ?? []}
                        />

                        {modalVisible && (
                            <ReportModal
                                onCancel={onCancel}
                                onSave={onSave}
                                onDueDateChange={onDueDateChange}
                                onAssigneeChange={onAssigneeChange}
                                onReviewerChange={onReviewerChange}
                                onSendAssigneeNotificationChange={
                                    onSendAssigneeNotificationChange
                                }
                                onSendReviewerNotificationChange={
                                    onSendReviewerNotificationChange
                                }
                                batchValues={batchValues}
                                selectedEntityCodes={selectedEntityCodes}
                                selectedReport={report}
                                isWorkflowSteps={isWorkflowSteps}
                            />
                        )}
                    </div>
                </div>
            );
        }

        return (
            <ReportEmptyState
                text="
                Select a report to view its details. 
                You can also create a new report by clicking the Add Report button.
                "
            />
        );
    };

    const paneAreaWithoutFooterRemaining = 290;
    const paneAreaWithFooterRemaining = 370;
    const paneRightArea =
        window.innerHeight -
        (isFooterVisible
            ? paneAreaWithFooterRemaining
            : paneAreaWithoutFooterRemaining);

    const reportDataSelected = reportsData.filter(
        (r) => r.id === selectedMenuItem,
    );

    return (
        <div className={pageStyle}>
            <div
                style={{
                    top: '150px',
                    left: '10px',
                    right: '10px',
                    bottom: '10px',
                    height: '100%',
                }}
            >
                <div
                    style={{
                        backgroundColor: isWorkflowSteps
                            ? 'transparent'
                            : '#f5f5f5',
                        position: 'relative',
                        overflow: 'hidden',
                        margin: isWorkflowSteps ? '0 20px' : '0',
                    }}
                >
                    {!isWorkflowSteps ? (
                        <ReportGridHeader
                            cloneReport={cloneReport}
                            report={report}
                            reportDataSelected={reportDataSelected[0]}
                            isAdmin={isAdmin}
                            deleteReports={deleteReport}
                            approvals={approvals ?? []}
                        />
                    ) : null}
                    {reportGridRender()}
                </div>
                {renderWorkflowStepsNavigation &&
                    renderWorkflowStepsNavigation(() => onActivateReport())}
                <div
                    data-testid="report-grid-footer"
                    className={footerStyle(isFooterVisible)}
                >
                    <div
                        style={{
                            ...alignStyle,
                            width: '100%',
                            padding: '16px',
                        }}
                    >
                        {renderBottomMenu()}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapState = (s: RootStateOrAny) => {
    const properties = selectProperties(s);
    const users = selectUsers(s);

    const { roles } = s.user;
    const isAdmin = roles.includes(userRoles.CLIENT_ADMIN);

    return {
        properties,
        users,
        isAdmin,
    };
};
export default connect(mapState)(ReportGrid);
