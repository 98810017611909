import {
    Alert,
    DatePicker,
    Form,
    FormInstance,
    Input,
    InputRef,
    Modal,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { EntityProfileCard } from 'waypoint-types/properties';
import { EntityProfileCardsEnabledFields } from 'waypoint-react/entity-profile-cards/EntityProfileCards';
import { css } from 'emotion';

const formItemLayout = css`
    .ant-form-item-control,
    textarea {
        max-width: 100%;
    }
`;

interface EntityProfileCardsEditorModalProps {
    title: string;
    isVisible: boolean;
    isSaving: boolean;
    formRef: FormInstance;
    errorMessage: string;
    onSubmitForm: () => void;
    onCancel: () => void;
    entityProfileCard: EntityProfileCard;
    enabledFields?: EntityProfileCardsEnabledFields;
    titleLabel?: string;
}

export const EntityProfileCardsEditorModal = ({
    title,
    isVisible,
    isSaving,
    formRef,
    errorMessage,
    onSubmitForm,
    onCancel,
    entityProfileCard,
    enabledFields = ['year', 'title', 'notes'],
    titleLabel = 'Item',
}: EntityProfileCardsEditorModalProps): JSX.Element => {
    const titleTextAreaRef = useRef<InputRef>(null);

    useEffect(() => {
        focusInput();
    }, [isVisible]);

    const focusInput = async () => {
        if (isVisible) {
            titleTextAreaRef.current?.focus({
                cursor: (await formRef.getFieldValue('title'))
                    ? 'end'
                    : 'start',
            });
        }
    };

    return isVisible ? (
        <Modal
            title={title}
            open
            confirmLoading={isSaving}
            okText={'Save'}
            onOk={onSubmitForm}
            onCancel={onCancel}
        >
            <Form
                name="itemForm"
                form={formRef}
                labelCol={{ span: 17 }}
                layout="vertical"
                wrapperCol={{ span: 24 }}
                initialValues={{
                    yearMoment: moment().year(entityProfileCard.year),
                    title: '',
                    notes: '',
                    metadata: [],
                }}
            >
                <Form.Item
                    label="Year"
                    name="yearMoment"
                    rules={[{ required: true, message: 'Year is required.' }]}
                    style={{
                        display: enabledFields.includes('year')
                            ? undefined
                            : 'none',
                    }}
                    className={formItemLayout}
                >
                    <DatePicker style={{ width: '100%' }} picker="year" />
                </Form.Item>

                <Form.Item
                    label={titleLabel}
                    name="title"
                    rules={[{ required: true, message: 'Field required' }]}
                    style={{
                        display: enabledFields.includes('title')
                            ? undefined
                            : 'none',
                    }}
                    className={formItemLayout}
                >
                    <Input.TextArea
                        style={{ width: '100%' }}
                        rows={5}
                        showCount
                        ref={titleTextAreaRef}
                        maxLength={500}
                    />
                </Form.Item>

                <Form.Item
                    label="Notes"
                    name="notes"
                    rules={[
                        {
                            max: 1000,
                            message: 'Notes must be less than 1,000 characters',
                        },
                    ]}
                    style={{
                        display: enabledFields.includes('notes')
                            ? undefined
                            : 'none',
                    }}
                    className={formItemLayout}
                >
                    <Input.TextArea showCount maxLength={1000} />
                </Form.Item>

                {/* If the metadata field it's not present here in the modal, the onSave method will not send it as part of the payload */}
                <Form.Item
                    label="Metadata"
                    name="metadata"
                    style={{
                        display: 'none',
                    }}
                    className={formItemLayout}
                >
                    <Input.TextArea showCount maxLength={1000} />
                </Form.Item>
            </Form>

            {errorMessage && <Alert message={errorMessage} type="error" />}
        </Modal>
    ) : (
        <></>
    );
};
