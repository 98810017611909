import { useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { Divider, Empty, Select, Radio } from 'antd';
import {
    DataGridStyleProps,
    LeaseExpirationScheduleProps,
} from 'waypoint-types';
import { ActionsCard } from 'waypoint-react';
import ExpirationScheduleChart from 'components/leases/expirations/cards/leases-expiration/ExpirationScheduleChart';
import ExpirationScheduleTable from 'components/leases/expirations/cards/leases-expiration/ExpirationScheduleTable';
import {
    defaultGroupByOptionValue,
    defaultMonthsToGroupValue,
    defaultYearToGroupValue,
    expirationGroupingByOptions,
    expirationGroupingBySelectOptions,
    isMonthSelected,
    monthsGroupingOptions,
    VACANT_KEY,
    yearGroupingOptions,
} from './utils';
import { css } from 'emotion';
import {
    useRentRoll,
    useLeasesExpiration,
    useSelectedDataLevel,
} from 'waypoint-hooks';
import { AppFeaturePermissions } from 'contexts';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';
import { DataGridRef } from 'devextreme-react/cjs/data-grid';

const dividerStyle = css`
    margin-bottom: 5px;
    margin-top: 20px;
`;

const ErrorMessage = () => {
    return <Empty description="There was an error with your request" />;
};

interface LeasesExpirationCardProps {
    style?: DataGridStyleProps;
    className?: string;
    entityCodes: string[];
    boxShadow?: string;
    isPropertyProfile?: boolean;
}
const LeasesExpirationCard = (
    props: LeasesExpirationCardProps,
): JSX.Element => {
    const selectedDataLevel = useSelectedDataLevel();

    const {
        data: rentRoll,
        isLoading,
        isError,
    } = useRentRoll(props.entityCodes, selectedDataLevel);

    const dataGridRef = useRef<DataGridRef<any, any>>(null);

    const [yearToGroup, setYearToGroup] = useState<number>(
        defaultYearToGroupValue,
    );

    const [monthsToGroup, setMonthsToGroup] = useState<number>(
        defaultMonthsToGroupValue,
    );
    const [groupByOption, setGroupByOption] =
        useState<expirationGroupingByOptions>(defaultGroupByOptionValue);

    const leaseExpirationsData = useLeasesExpiration({
        rentRoll,
        groupByOption,
        yearToGroup,
        monthsToGroup,
    });

    leaseExpirationsData.sort((a, b) => {
        const getSortValue = (item: LeaseExpirationScheduleProps) => {
            if (item.expiration_year === VACANT_KEY) return -2;
            if (item.expiration_year === null) return -1;
            return parseFloat(item.expiration_year.toString()) || 0;
        };

        const aValue = getSortValue(a);
        const bValue = getSortValue(b);

        if (aValue !== bValue) {
            return aValue - bValue;
        }

        const aMonth =
            a.expiration_month !== null
                ? a.expiration_month
                : a.expiration_month_year || '0';
        const bMonth =
            b.expiration_month !== null
                ? b.expiration_month
                : b.expiration_month_year || '0';

        return parseFloat(aMonth) - parseFloat(bMonth);
    });

    const renderContent = () => {
        if (isError) {
            return <ErrorMessage />;
        }

        return (
            <>
                <ExpirationScheduleChart
                    leaseExpirations={leaseExpirationsData}
                    groupByOption={groupByOption}
                    isPropertyProfile={props?.isPropertyProfile}
                />

                <Divider className={dividerStyle}></Divider>

                <ExpirationScheduleTable
                    leaseExpirations={leaseExpirationsData}
                    groupByOption={groupByOption}
                    dataGridRef={dataGridRef}
                />
            </>
        );
    };

    return (
        <PermissionedWrapper
            featureKey={AppFeaturePermissions.ExpirationSchedule}
        >
            <ActionsCard
                style={{
                    boxShadow: props.boxShadow,
                }}
                title={
                    <>
                        <div
                            style={{
                                display: 'flex',
                                placeContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <h2
                                style={{
                                    marginBottom: 0,
                                }}
                            >
                                Lease Expiration Schedule
                            </h2>

                            <span style={{ marginLeft: 16 }}>
                                Group by
                                <Radio.Group
                                    style={{ width: 150, marginLeft: 10 }}
                                    defaultValue={groupByOption}
                                    buttonStyle="solid"
                                    onChange={(e) =>
                                        setGroupByOption(e.target.value)
                                    }
                                >
                                    {expirationGroupingBySelectOptions.map(
                                        (option) => (
                                            <Radio.Button
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </Radio.Button>
                                        ),
                                    )}
                                </Radio.Group>
                            </span>
                        </div>

                        {groupByOption ===
                        expirationGroupingByOptions.monthYear ? (
                            <span>
                                Consolidate After
                                <Select
                                    defaultValue={
                                        monthsGroupingOptions[1].value
                                    }
                                    style={{ width: 150, marginLeft: 10 }}
                                    options={monthsGroupingOptions}
                                    placeholder={'# of Months'}
                                    value={monthsToGroup}
                                    onChange={(item: number) =>
                                        setMonthsToGroup(item)
                                    }
                                />
                            </span>
                        ) : (
                            <span>
                                Consolidate After
                                <Select
                                    defaultValue={yearGroupingOptions[0].value}
                                    disabled={isMonthSelected(groupByOption)}
                                    style={{ width: 150, marginLeft: 10 }}
                                    options={yearGroupingOptions}
                                    placeholder={'# of Years'}
                                    value={yearToGroup}
                                    onChange={(item: number) =>
                                        setYearToGroup(item)
                                    }
                                />
                            </span>
                        )}
                    </>
                }
                empty={isEmpty(leaseExpirationsData)}
                size="default"
                data-testid="expiration-schedule-card"
                loadingData={isLoading}
                extra={<></>}
            >
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        width: '100%',
                        whiteSpace: 'nowrap',
                    }}
                ></div>
                {renderContent()}
            </ActionsCard>
        </PermissionedWrapper>
    );
};

export default LeasesExpirationCard;
