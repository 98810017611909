import React from 'react';
import { Table } from 'waypoint-react';
import { currencyRenderer } from 'utils/tables/renderers';

const columns = [
    {
        title: 'Industry',
        dataIndex: 'industry',
        ellipsis: true,
    },
    {
        title: 'Total Gross Amount',
        dataIndex: 'grossRent',
        render: currencyRenderer,
        align: 'right',
    },
    {
        title: 'Total Ann. In-Place Rent / sq ft',
        dataIndex: 'totalAnnualInPlaceRentSqFt',
        render: currencyRenderer,
        align: 'right',
    },
];

const RentByIndustryTable = ({ data, height = 260 }) => {
    return <Table data={data} columns={columns} size="small" height={height} />;
};

export default RentByIndustryTable;
