import React, { ReactElement, useState, useEffect, useContext } from 'react';
import { get, sortBy } from 'lodash';
import { Alert } from 'antd';

import { getAsOfDates } from 'waypoint-requests';
import { TextLoadingBar } from 'waypoint-react';
import { useGetSelectedFilteredEntityCodes } from 'waypoint-hooks';
import { EntityAttributesContext, EntityAttributesContextType } from 'contexts';
import {
    AsOfDatesType,
    LeasingAsOfDateType,
    AccountingAsOfDateType,
    AccountAsOfDateLabel,
    LeasingAsOfDateLabel,
} from 'components/app/as-of-date';

// TODO: test this component HER-6064

const WIDTH = '430px';

const loadingComponent = (
    <TextLoadingBar
        className="" // satisfy typescript
        data-testid-loading
        style={{ width: WIDTH, height: '15px' }}
    />
);

/**
 * Requests client data dump dates on mount. Displays loading text bar until hydrated.
 * @return two Ant Design Alert components, one for accounting data upload dates and lease upload dates
 */

type EntityContextType = EntityAttributesContextType | null;

interface AsOfDateInfoProps {
    singleEntityCode?: string;
}

export function AsOfDateInfo({
    singleEntityCode,
}: AsOfDateInfoProps): ReactElement {
    const [asOfDates, setAsOfDates] = useState<AsOfDatesType | null>(null);

    const entityContext = useContext<EntityContextType>(
        EntityAttributesContext,
    );

    const [isAsOfDatesLoading, setIsAsOfDatesLoading] = useState<boolean>(true);

    const [isError, setIsError] = useState<boolean>(false);

    const entityCodes: string[] = useGetSelectedFilteredEntityCodes();

    useEffect(() => {
        async function fetchAsOfDates() {
            setAsOfDates(null);

            setIsAsOfDatesLoading(true);

            setIsError(false);

            try {
                const data: AsOfDatesType = await getAsOfDates(
                    singleEntityCode ? [singleEntityCode] : entityCodes,
                );

                setAsOfDates(data);

                setIsAsOfDatesLoading(false);

                setIsError(false);
            } catch (error) {
                setAsOfDates(null);

                setIsAsOfDatesLoading(false);

                setIsError(true);
            }
        }
        if (!entityContext?.isAttributesLoading) {
            fetchAsOfDates();
        }
    }, [entityCodes, entityContext?.isAttributesLoading, singleEntityCode]);

    if (isError || entityContext?.isAttributesNetworkError) {
        return (
            <div>
                <Alert
                    data-testid-error
                    message="Error"
                    type="error"
                    description={
                        <div style={{ width: WIDTH }}>
                            There was an error with the request
                        </div>
                    }
                />
            </div>
        );
    }

    if (isAsOfDatesLoading || entityContext?.isAttributesLoading) {
        return (
            <div>
                <Alert
                    data-testid-financial-alert
                    message="Financial Data"
                    type="info"
                    style={{ marginBottom: '12px' }}
                    description={
                        <div style={{ width: WIDTH }}>{loadingComponent}</div>
                    }
                />
                <Alert
                    data-testid-leasing-alert
                    message="Leasing Data"
                    type="info"
                    description={
                        <div style={{ width: WIDTH }}>{loadingComponent}</div>
                    }
                />
            </div>
        );
    }

    const accountingAsOfDates: AccountingAsOfDateType[] | null =
        get(asOfDates, 'accounting') || null;

    const leasingAsOfDate: LeasingAsOfDateType | null =
        get(asOfDates, 'leasing') || null;

    return (
        <div>
            <Alert
                data-testid-financial-alert
                message="Financial Data"
                type="info"
                style={{ marginBottom: '12px' }}
                description={
                    <div style={{ width: WIDTH }}>
                        {sortBy(accountingAsOfDates, 'label').map(
                            (asOfDate, index) => {
                                return (
                                    <AccountAsOfDateLabel
                                        key={JSON.stringify(index)}
                                        date={asOfDate}
                                    />
                                );
                            },
                        )}
                    </div>
                }
            />
            <Alert
                data-testid-leasing-alert
                message="Leasing Data"
                type="info"
                description={<LeasingAsOfDateLabel date={leasingAsOfDate} />}
            />
        </div>
    );
}
