import { API_URL } from 'config/constants';
import { EntityProfileCardYearMetadata } from 'waypoint-types/properties';

export async function getEntityProfileCardYearMetadata(
    entityCode: string,
    type: string
): Promise<EntityProfileCardYearMetadata[]> {
    const response = await fetch(
        `${API_URL}/properties/${entityCode}/profile-cards/${type}/year-metadata`,
        {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
        }
    );

    if (!response.ok) {
        throw new Error('Failed to get entity profile card year metadata');
    }

    const { data } = await response.json();

    return data as EntityProfileCardYearMetadata[];
}
