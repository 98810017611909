import React from 'react';
import { ReportGridType, Report, EntityReportApproval } from 'waypoint-types';
import { Card, Statistic, Tag, Tooltip } from 'antd';
import { LeftCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { DASH_DASH } from 'config/constants';
import { RouteUrls } from 'routes/RouteConstants';
import { differenceInCalendarDays, format } from 'date-fns';
import { getApprovalsWithStatus } from '../ReportApprovalUtils';
import { ReportWorkflowReviewStatus } from '../constants';
import { REPORT_ACTIVE, REPORT_ARCHIVED, REPORT_DRAFT } from '../ReportUtils';
import theme from 'config/theme';
import { capitalize } from 'lodash';

const alignStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
};

const iconStyle = {
    cursor: 'pointer',
    fontSize: '18px',
    color: theme.colors.grays.dark,
    marginRight: '10px',
};

const reportStatusTagColor = {
    [REPORT_ACTIVE]: theme.colors.green,
    [REPORT_ARCHIVED]: theme.colors.grays.medium,
    [REPORT_DRAFT]: theme.colors.blues.antBlue,
};

interface ReportGridHeaderProps {
    reportDataSelected: ReportGridType | undefined;
    cloneReport: (report: Report | undefined) => void;
    isAdmin: boolean;
    report: Report;
    deleteReports: (
        report: ReportGridType[],
        deletePermanently: boolean,
    ) => void;
    approvals: EntityReportApproval[];
}

const ReportGridHeader = ({
    reportDataSelected,
    cloneReport,
    isAdmin,
    report,
    deleteReports,
    approvals,
}: ReportGridHeaderProps) => {
    const entityReportWidgets = report.entityReports?.length
        ? report.entityReports?.flatMap(
              (entityReport) => entityReport?.entityReportWidgets ?? [],
          )
        : [];
    const approvalsWithStatus = approvals
        ? getApprovalsWithStatus(approvals, [report] ?? [], entityReportWidgets)
        : [];

    if (!reportDataSelected) {
        return null;
    }

    const renderReportStatusStatistics = () => {
        const stats = approvalsWithStatus.reduce(
            (dict, approval) => {
                dict[approval.status as ReportWorkflowReviewStatus] += 1;
                return dict;
            },
            {
                [ReportWorkflowReviewStatus.Open]: 0,
                [ReportWorkflowReviewStatus.InProgress]: 0,
                [ReportWorkflowReviewStatus.InReview]: 0,
                [ReportWorkflowReviewStatus.Rejected]: 0,
                [ReportWorkflowReviewStatus.Approved]: 0,
            },
        );

        const statsDenominator = report.entityReports?.length ?? 0;

        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {Object.entries(stats).map((stat) => (
                    <Card style={{ width: '15%' }}>
                        <Statistic
                            style={{
                                width: '100%',
                                textAlign: 'center',
                            }}
                            title={
                                <p
                                    style={{
                                        color: theme.colors.grays.dark,
                                        fontSize: '18px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {stat[0]}
                                </p>
                            }
                            value={stat[1]}
                            suffix={`/ ${statsDenominator}`}
                        />
                    </Card>
                ))}
                <Card style={{ width: '20%' }}>
                    {report?.due_date ? (
                        <Statistic
                            style={{ width: '100%', textAlign: 'center' }}
                            title={
                                <span
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'baseline',
                                        marginBottom: '-4px',
                                    }}
                                >
                                    <p
                                        style={{
                                            color: theme.colors.grays.dark,
                                            fontSize: '18px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Due Date:
                                    </p>
                                    <p
                                        style={{
                                            marginLeft: '10px',
                                            fontSize: '15px',
                                            color: theme.colors.grays
                                                .mediumDark,
                                        }}
                                    >
                                        {format(
                                            new Date(report.due_date),
                                            'MMMM d, yyyy',
                                        )}
                                    </p>
                                </span>
                            }
                            value={Math.max(
                                differenceInCalendarDays(
                                    new Date(report.due_date),
                                    new Date(),
                                ),
                                0,
                            )}
                            suffix={
                                <p
                                    style={{
                                        fontSize: '14px',
                                        textAlign: 'center',
                                        marginLeft: '5px',
                                    }}
                                >
                                    Days Left
                                </p>
                            }
                        />
                    ) : (
                        <div
                            style={{
                                color: theme.colors.grays.dark,
                                fontSize: '18px',
                                fontWeight: 'bold',
                                textAlign: 'center',
                            }}
                        >
                            Due Date: {DASH_DASH}
                        </div>
                    )}
                </Card>
            </div>
        );
    };

    return (
        <>
            <div style={{ backgroundColor: 'white', padding: '10px 18px 0px' }}>
                <Link to="/reports/manager">
                    <LeftCircleOutlined style={{ marginRight: 5 }} />
                    Back to Report Manager
                </Link>
            </div>
            <div
                style={{
                    padding: 18,
                    borderBottom: '1px solid #e8e8e8',
                    paddingTop: 12,
                    backgroundColor: 'white',
                }}
            >
                <div
                    style={{
                        ...alignStyle,
                        marginBottom: 12,
                    }}
                >
                    <div style={{ display: 'flex' }}>
                        <h1
                            style={{
                                margin: 0,
                                fontSize: '32px',
                                fontWeight: 'bold',
                                maxWidth: '55ch',
                            }}
                        >
                            {reportDataSelected?.report_name ?? ''}
                        </h1>
                        <Tag
                            color={
                                reportStatusTagColor[
                                    capitalize(
                                        report.state,
                                    ) as keyof typeof reportStatusTagColor
                                ]
                            }
                            style={{
                                minWidth: '100px',
                                fontSize: '14px',
                                height: '32px',
                                marginLeft: '25px',
                                alignContent: 'center',
                                borderRadius: '8px',
                                textAlign: 'center',
                                textTransform: 'capitalize',
                            }}
                        >
                            {report.state}
                        </Tag>
                    </div>
                    <div style={alignStyle}>
                        {isAdmin ? (
                            <div>
                                <Link
                                    style={{
                                        textDecoration: 'none',
                                    }}
                                    to={`${RouteUrls.REPORT}/${reportDataSelected?.id}`}
                                >
                                    <Tooltip title="Edit Report">
                                        <i
                                            className="fa-solid fa-pencil"
                                            style={iconStyle}
                                        />
                                    </Tooltip>
                                </Link>
                                <Tooltip title="Clone Report">
                                    <i
                                        className="fa-solid fa-copy"
                                        style={iconStyle}
                                        onClick={() => cloneReport(report)}
                                    />
                                </Tooltip>
                                <Tooltip title="Delete Report">
                                    <i
                                        className="fa-solid fa-trash"
                                        style={iconStyle}
                                        onClick={() => {
                                            deleteReports(
                                                [reportDataSelected],
                                                false,
                                            );
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        ) : null}
                    </div>
                </div>
                {renderReportStatusStatistics()}
            </div>
        </>
    );
};

export default ReportGridHeader;
