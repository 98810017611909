import PropTypes from 'prop-types';
import React from 'react';
import defaultStylePP from './defaultStylePP';
import Bubble from 'components/style/Bubble';
import { DASH_DASH } from 'config/constants';

const ellipsisStyle = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
};

const turncateStyle = {
    flex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '180px',
};

export const CategoryCell = ({
    cellData = {},
    color,
    selectedColumn,
    ...rest
}) => {
    const { text, color: bubbleColor } = cellData;

    return (
        <div {...rest}>
            {text !== DASH_DASH && (
                <div style={{ marginTop: '-5px' }}>
                    <Bubble diameter={12} color={bubbleColor} />
                </div>
            )}
            <div
                style={{
                    ...turncateStyle,
                    ...ellipsisStyle,
                    color,
                    fontWeight: selectedColumn ? 600 : 400,
                }}
            >
                {text}
            </div>
        </div>
    );
};

CategoryCell.propTypes = {
    cellData: PropTypes.any,
    color: PropTypes.string,
    selectedColumn: PropTypes.bool,
};

export const CategoryCellRenderer = defaultStylePP(CategoryCell);

export default CategoryCellRenderer;
