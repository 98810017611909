import { IGroupItemProps, ITotalItemProps } from 'devextreme-react/data-grid';
import { numericalSort, stringSort } from 'utils/tables/sorters';
import { isEmpty } from 'lodash';
import { textRenderer } from 'utils/tables/renderers';
import {
    AccountMapping,
    DisplayType,
    RankingData,
} from 'waypoint-types/ranking';
import {
    getDisplayTypeDataField,
    getDisplayTypeLabel,
} from 'components/analytics/ranking/utils/DisplayTypeUtils';
import { Column } from 'devextreme/ui/data_grid';

/**
 * This creates a column config for the top level parent account
 * @param selectedAccountMapping selected account
 * @param displayType metric displayed
 * @returns column config
 */
const getParentAccountColumnFrom = (
    selectedAccountMapping: AccountMapping,
    displayType: DisplayType,
): [(string | Column<RankingData, any>)[], ITotalItemProps[]] => {
    const parentAccountName = selectedAccountMapping.name;
    const parentAccountColumnCaption =
        parentAccountName && !getDisplayTypeLabel(displayType)
            ? parentAccountName
            : `${parentAccountName} (${getDisplayTypeLabel(displayType)})`;
    const parentColumn: (string | Column<RankingData, any>)[] = [
        {
            caption: parentAccountColumnCaption,
            allowHeaderFiltering: false,
            minWidth: 180,
            fixed: true,
            alignment: 'center',
            allowFiltering: true,
            dataField: 'account_mapping.property_rank_value',
            dataType: 'number',
            format: { type: 'currency', precision: 2 },
            calculateFilterExpression: function (
                filterValue: number | number[],
                selectedFilterOperation: string | null,
            ) {
                const column = this as any;
                if (
                    selectedFilterOperation === 'between' &&
                    Array.isArray(filterValue)
                ) {
                    return [
                        [column.calculateDisplayValue, '>', filterValue[0]],
                        'and',
                        [column.calculateDisplayValue, '<', filterValue[1]],
                    ];
                }

                return [
                    column.calculateDisplayValue,
                    selectedFilterOperation,
                    filterValue,
                ];
            },
            calculateCellValue: (a: RankingData) => {
                if (!a.account_mapping.property_rank_value) {
                    return undefined;
                }

                const dataField = getDisplayTypeDataField(displayType);
                const weight = dataField ? Number(a[dataField]) : 1;

                return weight * a.account_mapping.property_rank_value;
            },
            calculateDisplayValue: (a: RankingData) => {
                return a.account_mapping.property_rank_value;
            },
        },
        {
            caption: 'Child Accounts',
            isBand: true,
            columns: getChildAccountColumnsFrom(selectedAccountMapping),
        },
    ];

    const totalItems: ITotalItemProps[] = getChildAccountTotalItemsFrom(
        selectedAccountMapping,
        displayType,
    );

    return [parentColumn, totalItems];
};

/**
 * This creates a column config for all of a parent account's children accounts. If there are no children, it returns an empty array.
 * @param {object} selectedAccountMapping
 * @param {DisplayType} displayType
 * @returns {array} - array of column configs
 */
const getChildAccountTotalItemsFrom = (
    selectedAccountMapping: AccountMapping,
    displayType: DisplayType,
): ITotalItemProps[] => {
    if (isEmpty(selectedAccountMapping.children)) {
        return [];
    }

    const getWeightedAverageSummaryItem = (
        accountCode: string,
    ): ITotalItemProps => ({
        name: 'weightedAverageDisplayValue_' + accountCode,
        showInColumn: accountCode,
        summaryType: 'custom',
        valueFormat: { type: 'currency', precision: 2 },
        displayFormat: 'Avg: {0}',
    });

    const getSumSummaryItem = (accountCode: string): ITotalItemProps => ({
        column: accountCode,
        valueFormat: { type: 'currency', precision: 2 },
        summaryType: 'sum',
    });

    const childAccounts: ITotalItemProps[] =
        selectedAccountMapping.children?.map(
            (childAccount): ITotalItemProps =>
                getDisplayTypeDataField(displayType)
                    ? getWeightedAverageSummaryItem(
                          childAccount.account_mapping_code,
                      )
                    : getSumSummaryItem(childAccount.account_mapping_code),
        );

    const parentAccountTotalItem = !getDisplayTypeDataField(displayType)
        ? {
              column: 'account_mapping.property_rank_value',
              valueFormat: { type: 'currency', precision: 2 },
              summaryType: 'sum' as
                  | 'sum'
                  | 'custom'
                  | 'avg'
                  | 'count'
                  | 'max'
                  | 'min',
          }
        : {
              name: 'weightedAverageDisplayValue_total',
              summaryType: 'custom' as
                  | 'sum'
                  | 'custom'
                  | 'avg'
                  | 'count'
                  | 'max'
                  | 'min',
              showInColumn: 'account_mapping.property_rank_value',
              valueFormat: { type: 'currency', precision: 2 },
              displayFormat: 'Avg: {0}',
          };

    return [parentAccountTotalItem, ...(childAccounts ?? [])];
};

export const getWeightedChildAccountRankingCellValue = (
    rankingData: RankingData,
    displayType: DisplayType,
    childAccountCode: string,
): number | undefined => {
    const childAccountValue = getChildAccountRankingCellValue(
        rankingData,
        childAccountCode,
    );

    if (typeof childAccountValue === 'number') {
        const dataField = getDisplayTypeDataField(displayType);
        if (!dataField) {
            return childAccountValue;
        }

        const weight = Number(rankingData[dataField]);
        return weight * childAccountValue;
    }
};

const getChildAccountRankingCellValue = (
    rankingData: RankingData,
    childAccountCode: string,
): number | undefined => {
    const childAccountValue = rankingData.account_mapping.children.find(
        (childAccount) =>
            childAccount.account_mapping_code === childAccountCode,
    )?.property_rank_value;

    if (!childAccountValue) {
        return;
    }

    return childAccountValue;
};

/**
 * This creates a column config for all of a parent account's children accounts. If there are no children, it returns an empty array.
 * @param {object} selectedAccountMapping
 * @returns {array} - array of column configs
 */
const getChildAccountColumnsFrom = (
    selectedAccountMapping: AccountMapping,
): (string | Column<RankingData, any>)[] => {
    if (isEmpty(selectedAccountMapping.children)) {
        return [];
    }
    return (
        selectedAccountMapping.children?.map(
            (childAccount): string | Column<RankingData, any> => {
                const childAccountName = childAccount.name;
                const childAccountCode = childAccount.account_mapping_code;
                return {
                    name: childAccountCode,
                    caption: childAccountName,
                    allowHeaderFiltering: false,
                    allowFiltering: true,
                    allowSorting: true,
                    allowGrouping: false,
                    alignment: 'center',
                    format: { type: 'currency', precision: 2 },
                    dataType: 'number',
                    calculateFilterExpression: function (
                        filterValue: number | number[],
                        selectedFilterOperation: string | null,
                    ) {
                        const column = this as any;
                        if (
                            selectedFilterOperation === 'between' &&
                            Array.isArray(filterValue)
                        ) {
                            return [
                                [
                                    column.calculateCellValue,
                                    '>',
                                    filterValue[0],
                                ],
                                'and',
                                [
                                    column.calculateCellValue,
                                    '<',
                                    filterValue[1],
                                ],
                            ];
                        }

                        return [
                            column.calculateCellValue,
                            selectedFilterOperation,
                            filterValue,
                        ];
                    },
                    calculateCellValue: (a: RankingData) => {
                        return getChildAccountRankingCellValue(
                            a,
                            childAccountCode,
                        );
                    },
                };
            },
        ) ?? []
    );
};

export const getRankingColumns = (
    selectedDisplayType: string,
    selectedAccountMapping: AccountMapping,
    attributeSelectedName: string | undefined,
    isGroupByAttribute: boolean,
): [
    (string | Column<RankingData, any>)[],
    ITotalItemProps[],
    IGroupItemProps[],
] => {
    const valueMetricColumns:
        | (string | Column<RankingData, any>)[]
        | undefined = [
        {
            caption: 'Occupied Sq Ft',
            allowHiding: true,
            fixed: true,
            alignment: 'center',
            visible: selectedDisplayType === DisplayType.PerOccupiedSqft,
            allowHeaderFiltering: false,
            allowFiltering: true,
            allowSorting: true,
            sortingMethod: numericalSort,
            dataField: 'occupied_sq_ft',
            format: { type: 'fixedPoint' },
            allowGrouping: false,
        },
        {
            caption: 'Rentable Sq Ft',
            allowHiding: true,
            fixed: true,
            alignment: 'center',
            visible: selectedDisplayType === DisplayType.PerRentableSqft,
            allowHeaderFiltering: false,
            allowFiltering: true,
            allowSorting: true,
            sortingMethod: numericalSort,
            dataField: 'rentable_sq_ft',
            format: { type: 'fixedPoint' },
            allowGrouping: false,
        },
        {
            caption: 'Occupied Units',
            allowHiding: true,
            fixed: true,
            alignment: 'center',
            visible: selectedDisplayType === DisplayType.PerOccupiedUnit,
            allowHeaderFiltering: false,
            allowFiltering: true,
            allowSorting: true,
            sortingMethod: numericalSort,
            dataField: 'occupied_units',
            format: { type: 'fixedPoint' },
            allowGrouping: false,
        },
        {
            caption: 'Total Units',
            allowHiding: true,
            fixed: true,
            alignment: 'center',
            visible: selectedDisplayType === DisplayType.PerUnit,
            allowHeaderFiltering: false,
            allowFiltering: true,
            allowSorting: true,
            sortingMethod: numericalSort,
            dataField: 'total_units',
            format: { type: 'fixedPoint' },
            allowGrouping: false,
        },
    ];

    const metricTotalItems: ITotalItemProps[] = [
        {
            summaryType: 'sum',
            column: 'occupied_units',
            valueFormat: 'fixedPoint',
        },
        {
            summaryType: 'sum',
            column: 'occupied_sq_ft',
            valueFormat: 'fixedPoint',
        },
        {
            summaryType: 'sum',
            column: 'rentable_sq_ft',
            valueFormat: 'fixedPoint',
        },
        {
            summaryType: 'sum',
            column: 'total_units',
            valueFormat: 'fixedPoint',
        },
    ];

    const groupItems: IGroupItemProps[] = [
        {
            column: 'occupied_units',
            summaryType: 'sum',
            valueFormat: 'fixedPoint',
            alignByColumn: true,
            displayFormat: '{0}',
        },
        {
            column: 'occupied_sq_ft',
            summaryType: 'sum',
            valueFormat: 'fixedPoint',
            alignByColumn: true,
            displayFormat: '{0}',
        },
        {
            column: 'rentable_sq_ft',
            summaryType: 'sum',
            valueFormat: 'fixedPoint',
            alignByColumn: true,
            displayFormat: '{0}',
        },
        {
            column: 'total_units',
            summaryType: 'sum',
            valueFormat: 'fixedPoint',
            alignByColumn: true,
            displayFormat: '{0}',
        },
    ];

    const [parentColumn, accountTotalItems] = getParentAccountColumnFrom(
        selectedAccountMapping,
        selectedDisplayType as DisplayType,
    );
    const accountGroupItems = accountTotalItems.map((item) => {
        return {
            ...item,
            alignByColumn: true,
            displayFormat: '{0}',
        };
    });
    const columns = [
        {
            caption: attributeSelectedName,
            dataField: 'attribute',
            allowGrouping: isGroupByAttribute,
            fixed: true,
            allowHiding: false,
            visible: isGroupByAttribute,
            allowHeaderFiltering: true,
            allowFiltering: true,
            showInColumnChooser: isGroupByAttribute,
            allowSorting: true,
            sortingMethod: (b: string, a: string) => {
                return stringSort(a, b); // sortingMethod and stringSort order their parameters differently
            },
            groupCellTemplate: (
                cellElement: HTMLElement | undefined,
                cellInfo: { value: string | null },
            ) => {
                cellElement?.append(cellInfo.value ?? 'Unassigned');
            },
        },
        {
            caption: 'Property',
            dataField: 'property_name',
            fixed: true,
            minWidth: 180,
            allowHiding: false,
            allowHeaderFiltering: true,
            allowFiltering: true,
            allowSorting: true,
            allowGrouping: false,
            sortingMethod: (b: string, a: string) => {
                return stringSort(a, b); // sortingMethod and stringSort order their parameters differently
            },
            format: textRenderer,
        },

        ...valueMetricColumns,
        ...parentColumn,
    ];

    return [
        columns,
        [...metricTotalItems, ...accountTotalItems],
        [...groupItems, ...accountGroupItems],
    ];
};
