import { LeasingPlanActivity } from 'waypoint-types/leasing-plans';

export enum LeasingPlanActivityType {
    Renewals = 'renewals',
    NewLeases = 'new_leases',
}

export const RENEWING = 'renewing';
export const NOT_RENEWING = 'not_renewing';
export const VACANT = 'VACANT';

export const renewalRangeOptions = [
    {
        label: '1 Year',
        value: 0,
    },
    {
        label: '2 Years',
        value: 1,
    },
    {
        label: '3 Years',
        value: 2,
    },
];

export const leaseExpectationOptions = [
    {
        value: RENEWING,
        label: 'Renewing',
    },
    {
        value: NOT_RENEWING,
        label: 'Not Renewing',
    },
];

export const leaseTypeOptions = [
    {
        value: 'new_lease',
        label: 'New Lease',
    },
    {
        value: 'expansion',
        label: 'Expansion',
    },
];

export const leasingCommissionsTypes = [
    {
        value: '$',
        label: '$/SF',
    },
    {
        value: '%',
        label: '%',
    },
];

export const calculateEffectiveRent = (leaseActivity: LeasingPlanActivity) => {
    const totalFreeRent =
        leaseActivity.base_rent_sf *
        leaseActivity.rentable_sf *
        leaseActivity.free_rent;
    const effectiveRent =
        leaseActivity.base_rent_sf -
        totalFreeRent / leaseActivity.rentable_sf / leaseActivity.term;
    return isNaN(effectiveRent) ? 0 : effectiveRent;
};

export const calculateTotalCapital = (leaseActivity: LeasingPlanActivity) => {
    const totalTenantImprovements =
        (leaseActivity.market_ready_tenant_improvements +
            leaseActivity.tenant_improvements_sf) *
        leaseActivity.rentable_sf;
    const totalCapital =
        totalTenantImprovements + leaseActivity.total_leasing_commisions;
    return isNaN(totalCapital) ? 0 : totalCapital;
};

export const calculateNetEffectiveRentPerSFYear = (
    leaseActivity: LeasingPlanActivity
) => {
    const netEffectiveRentPerSFYear =
        calculateEffectiveRent(leaseActivity) -
        calculateTotalCapital(leaseActivity) / leaseActivity.rentable_sf;
    return isNaN(netEffectiveRentPerSFYear) ? 0 : netEffectiveRentPerSFYear;
};

export const calculateTotalTenantImprovements = (
    leaseActivity: LeasingPlanActivity
) => {
    const totalTenantImprovements =
        (leaseActivity.market_ready_tenant_improvements +
            leaseActivity.tenant_improvements_sf) *
        leaseActivity.rentable_sf;
    return isNaN(totalTenantImprovements) ? 0 : totalTenantImprovements;
};
