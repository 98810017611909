import { assign } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

export default function defaultHeaderStylePP(WrappedComponent) {
    class DefaultHeaderCellStyle extends React.Component {
        shouldComponentUpdate(nextProps) {
            const activeChange = nextProps.active !== this.props.active;
            const dataChange = nextProps.cellData !== this.props.cellData;
            const directionChange =
                nextProps.sortDirection !== this.props.sortDirection;
            return activeChange || dataChange || directionChange;
        }

        render() {
            const { style, selectedColumn, ...rest } = this.props;
            const defaultStyle = {
                fontWeight: selectedColumn ? 600 : 'inherit',
                width: '100%',
                height: '100%',
                display: 'flex',
                overflow: 'hidden',
                userSelect: 'none',
                alignItems: 'flex-end',
                paddingRight: '5px',
                paddingLeft: '10px',
                paddingBottom: '3px',
                fontSize: '16px',
                letterSpacing: '.65px',
                backgroundColor: '#FFF',
            };

            return (
                <WrappedComponent
                    style={assign({}, defaultStyle, style)}
                    {...rest}
                />
            );
        }
    }

    DefaultHeaderCellStyle.propTypes = {
        style: PropTypes.object,
        cellData: PropTypes.any,
        sortDirection: PropTypes.string,
        selectedRow: PropTypes.bool,
        selectedColumn: PropTypes.bool,
        active: PropTypes.bool,
    };

    return DefaultHeaderCellStyle;
}
