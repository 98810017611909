import { Field } from 'devextreme/ui/pivot_grid/data_source';
import moment from 'moment';
import { CrosstabPeriod, DataRecord } from './CrosstabTypes';

const PERIOD_FIELD: keyof DataRecord = 'PERIOD_END';

const BASE_FIELD: Field = {
    dataField: PERIOD_FIELD,
    dataType: 'date',
    groupName: 'Period',
    area: 'column',
    isMeasure: false,
    displayFolder: 'Column Fields',
};

export const PERIOD_YEAR_FIELD: Field = {
    ...BASE_FIELD,
    caption: 'Year',
    groupInterval: 'year',
};

export const PERIOD_MONTH_FIELD: Field = {
    ...BASE_FIELD,
    caption: 'Month',
    format: 'MMMM yyyy',
    selector: (data: DataRecord) => moment(data[PERIOD_FIELD]).toDate(),
};

export const PERIOD_QUARTER_FIELD: Field = {
    ...BASE_FIELD,
    // Note: we MUST NOT specify the groupInterval here when overriding the selector,
    // otherwise undefined behavior will occur (from incorrect sorting to entirely breaking the Pivot Grid)
    // https://js.devexpress.com/Documentation/ApiReference/Data_Layer/PivotGridDataSource/Configuration/fields/#selector
    caption: 'Quarter',
    format: "'Q'Q yyyy",
    selector: (data: DataRecord) =>
        moment(data[PERIOD_FIELD]).startOf('quarter').toDate(),
};

export const CROSSTAB_PERIOD_TOGGLE_FIELDS = new Map<
    CrosstabPeriod,
    [string[], string[]]
>([
    [
        CrosstabPeriod.Month,
        [
            [PERIOD_MONTH_FIELD.caption!],
            [PERIOD_YEAR_FIELD.caption!, PERIOD_QUARTER_FIELD.caption!],
        ],
    ],
    [
        CrosstabPeriod.QuarterMonth,
        [
            [PERIOD_QUARTER_FIELD.caption!, PERIOD_MONTH_FIELD.caption!],
            [PERIOD_YEAR_FIELD.caption!],
        ],
    ],
    [
        CrosstabPeriod.YearMonth,
        [
            [PERIOD_YEAR_FIELD.caption!, PERIOD_MONTH_FIELD.caption!],
            [PERIOD_QUARTER_FIELD.caption!],
        ],
    ],
    [
        CrosstabPeriod.YearQuarterMonth,
        [
            [
                PERIOD_YEAR_FIELD.caption!,
                PERIOD_QUARTER_FIELD.caption!,
                PERIOD_MONTH_FIELD.caption!,
            ],
            [],
        ],
    ],
]);
