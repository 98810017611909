import React from 'react';
import { EntityAttributesContext } from './EntityAttributesContext';
import {
    EntityAttributesProps,
    EntityAttributesFromAPI,
} from './EntityAttributesTypes';
import { useGetAttributes } from 'waypoint-hooks';

const useAttributes = (
    entityCodes: string[],
): {
    isLoading: boolean;
    isNetworkError: boolean;
    attributes: EntityAttributesFromAPI | null;
    mutateProviderAttributes: () => void;
} => {
    const {
        data: attributes,
        isError,
        mutate: mutateAttributes,
    } = useGetAttributes(entityCodes);

    const mutateProviderAttributes = async () => {
        await mutateAttributes();
    };

    return {
        isLoading: !attributes,
        isNetworkError: isError,
        attributes: attributes
            ? {
                  attributeDefinitions: attributes.attributeDefinitions,
                  entities: attributes.entities,
              }
            : null,
        mutateProviderAttributes,
    };
};

export const EntityAttributesProvider = ({
    children,
    entityCodes,
}: EntityAttributesProps): JSX.Element => {
    const {
        attributes,
        isLoading: isAttributesLoading,
        isNetworkError,
        mutateProviderAttributes,
    } = useAttributes(entityCodes ?? []);

    return (
        <EntityAttributesContext.Provider
            value={{
                data: attributes,
                isAttributesLoading,
                isAttributesNetworkError: isNetworkError,
                mutateProviderAttributes,
            }}
        >
            {children}
        </EntityAttributesContext.Provider>
    );
};
