import useSWR from 'swr';
import { getReportMetadataByCommentId } from 'waypoint-requests';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { ReportMetadata } from 'waypoint-types';

export const useGetReportMetadataByCommentId = (
    commentId?: string,
    onError: () => void = () => {}
): DataHookResponse<ReportMetadata> => {
    const { data, error, isValidating, mutate } = useSWR(
        commentId ? `/api/report-metadata/${commentId}` : null,
        () => getReportMetadataByCommentId(commentId as string),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
            onError,
            shouldRetryOnError: false,
        }
    );

    return {
        data,
        isError: !!error,
        isLoading: !data,
        mutate,
        isValidating,
    };
};
