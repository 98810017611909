import { Card } from 'antd';
import { AttributesExportableGrid } from 'components/reports/components/entity-report-widgets/report-widget-export-grids/AttributesExportableGrid';
import { AppFeaturePermissions } from 'contexts';
import { DataGrid } from 'devextreme-react';
import {
    Column,
    Paging,
    DataGridRef,
    Sorting,
    HeaderFilter,
    Export,
    SearchPanel,
} from 'devextreme-react/data-grid';
import { AttributeDefinition } from 'waypoint-types';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';
import {
    AttributeTypeCodes,
    getAttributeFormatter,
} from 'components/attributes/card/utils';
import { ExportingEvent } from 'devextreme/ui/data_grid';
import { exportExcelFromDevExtremeDataGrid } from 'waypoint-utils';

interface PropertyProfileAttributesTableParams {
    entityAttributes: AttributeDefinition[] | undefined;
    dataGridRef?: React.RefObject<DataGridRef<any, any>>;
    isPDFExport?: boolean;
}

type AttributesGridDataSource = {
    title: string;
    category: string;
    value: string;
    key: string;
    dataIndex: string;
}[];

export const PropertyProfileAttributesTable = ({
    entityAttributes,
    dataGridRef,
    isPDFExport,
}: PropertyProfileAttributesTableParams) => {
    const entityAttributeRows =
        entityAttributes?.reduce<AttributesGridDataSource>((result, attr) => {
            if (!attr.attributeValues || !attr.attributeValues.length) {
                return result;
            }

            const formatter = getAttributeFormatter(
                attr.type as AttributeTypeCodes,
                attr.format ?? undefined,
            );

            return [
                ...result,
                {
                    title: attr.name,
                    category: attr.category,
                    key: attr.attribute_code,
                    dataIndex: attr.attribute_code,
                    value: attr.attributeValues
                        .map((val) =>
                            formatter(val.value, attr.precision ?? undefined),
                        )
                        .join(', '),
                },
            ];
        }, []) ?? [];

    if (isPDFExport) {
        return <AttributesExportableGrid data={entityAttributeRows} />;
    }

    const onExporting = async (e: ExportingEvent) => {
        await exportExcelFromDevExtremeDataGrid(e, {
            worksheetName: 'attributes',
            filename: 'Property Attributes.xlsx',
        });
    };

    return (
        <PermissionedWrapper featureKey={AppFeaturePermissions.Attributes}>
            <Card
                title="Property Attributes"
                id="property-attributes"
                style={{ margin: '20px 0' }}
                data-testid="property-detail-attributes-card"
            >
                <DataGrid
                    ref={dataGridRef}
                    dataSource={entityAttributeRows}
                    data-testid="property-detail-attributes-grid"
                    showBorders={true}
                    wordWrapEnabled
                    hoverStateEnabled
                    columnAutoWidth={true}
                    columnMinWidth={150}
                    onExporting={onExporting}
                    height="450px"
                >
                    <Export enabled={true} />
                    <Paging enabled={false} />
                    <Column
                        caption="Category"
                        dataField="category"
                        allowFiltering
                        defaultSortIndex={1}
                        defaultSortOrder="asc"
                    />
                    <Column
                        caption="Attribute"
                        dataField="title"
                        allowFiltering
                    />
                    <Column dataField="value" allowFiltering={false} />
                    <Sorting mode="multiple" />
                    <HeaderFilter visible={true} />
                </DataGrid>
            </Card>
        </PermissionedWrapper>
    );
};
