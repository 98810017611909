import React from 'react';
import { Dictionary } from 'ts-essentials';
import {
    EntityReportApproval,
    EntityReportPublished,
    EntityReportPublishingHistory,
    ReportMetadata,
} from 'waypoint-types';
import { formatDate } from '../ReportUtils';
import { ReportWorkflowReviewStatus } from '../constants';

interface EntityReportSubmissionHistoryParams {
    publishedEntityReports: EntityReportPublishingHistory;
    onSelectPublishedEntityReport: (id: string) => void;
    entityReportApprovals: Dictionary<EntityReportApproval>;
}

export const EntityReportSubmissionHistory = ({
    publishedEntityReports,
    onSelectPublishedEntityReport,
    entityReportApprovals,
}: EntityReportSubmissionHistoryParams) => {
    return (
        <div style={{ borderTop: '2px solid lightgrey' }}>
            <div
                style={{
                    marginLeft: '10px',
                }}
            >
                <h2>Submission History</h2>
                {publishedEntityReports?.entityReportPublished
                    ?.filter(
                        (erp) =>
                            entityReportApprovals[erp.id]?.status ===
                            ReportWorkflowReviewStatus.Rejected
                    )
                    .map((erp) => (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                cursor: 'pointer',
                            }}
                            onClick={() =>
                                onSelectPublishedEntityReport(erp.id)
                            }
                        >
                            <div>
                                {`Submitted ${formatDate(erp.timestamps.created_at)}`}
                            </div>
                            <div>
                                <i
                                    style={{
                                        color: 'red',
                                        fontSize: '16px',
                                        marginRight: '10px',
                                    }}
                                    className="fa-solid fa-times-circle"
                                />
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};
