import React from 'react';
import Table from 'components/leases/tenants/cards/TenantSummary/table/Table';

const Container = ({ data, isGroup }) => {
    return (
        // Check import. This isn't the reusable Table component.
        <Table data={data} isGroup={isGroup} />
    );
};

export default Container;
