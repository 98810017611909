import { API_URL } from 'config/constants';

interface UpdateLeasingGuidelinesIndexesProps {
    entityCode: string;
    activeYearKey: number;
    sorted_leasing_guidelines_ids: string[];
}

const updateLeasingGuidelinesIndexes = async ({
    entityCode,
    activeYearKey,
    sorted_leasing_guidelines_ids,
}: UpdateLeasingGuidelinesIndexesProps): Promise<any> => {
    const response = await fetch(
        `${API_URL}/leasing-guidelines/update-sort-index`,
        {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                entity_code: entityCode,
                year: activeYearKey,
                ids: sorted_leasing_guidelines_ids,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        }
    );

    if (!response.ok) {
        throw new Error('FAILED');
    }

    const { data } = await response.json();
    return data;
};

export default updateLeasingGuidelinesIndexes;
