import { Dictionary } from 'ts-essentials';
import { ITotalItemProps } from 'devextreme-react/data-grid';
import { toCalendarDate } from 'components/dates/utils';
import { Column } from 'devextreme/ui/data_grid';

export type IPropertyValueTableData = Dictionary<
    string | number | boolean | null,
    string
>;

export const columns: (string | Column<any, any>)[] = [
    {
        caption: 'Asset Name',
        dataField: 'display_name',
        fixedPosition: 'left',
        minWidth: 250,
        width: 'auto',
        sortOrder: 'asc',
        allowHeaderFiltering: true,
        allowFiltering: true,
    },
    {
        caption: 'fake',
        showInColumnChooser: false,
        allowExporting: false,
        fixedPosition: 'left',
        width: 0.01,
    },
    {
        caption: 'Asset',
        alignment: 'center',
        columns: [
            {
                caption: 'Entity Type',
                dataField: 'entitytypedup',
                alignment: 'center',
                width: 'auto',
            },
            {
                caption: 'Portfolio',
                dataField: 'portfolio',
                alignment: 'center',
                width: 'auto',
            },
            {
                caption: 'Asset Code',
                dataField: 'yardipropertycode',
                alignment: 'center',
                width: 120,
            },
            {
                caption: 'Owner',
                dataField: 'owner',
                alignment: 'center',
                width: 'auto',
            },
            {
                caption: 'Status',
                dataField: 'entity_status',
                alignment: 'center',
                width: 'auto',
            },
        ],
    },

    {
        caption: 'Location',
        alignment: 'center',
        columns: [
            {
                caption: 'Address',
                dataField: 'street_address',
                alignment: 'center',
                width: 'auto',
            },

            {
                caption: 'City',
                dataField: 'city',
                alignment: 'center',
                width: 'auto',
            },
            {
                caption: 'Sub-Market',
                dataField: 'submarket',
                alignment: 'center',
                width: 'auto',
            },
            {
                caption: 'Market',
                dataField: 'market',
                alignment: 'center',
                width: 'auto',
            },
            {
                caption: 'Province',
                dataField: 'province',
                alignment: 'center',
                width: 'auto',
            },
            {
                caption: 'Region',
                dataField: 'region',
                alignment: 'center',
                width: 'auto',
            },
        ],
    },
    {
        caption: 'Attributes',
        alignment: 'center',
        columns: [
            {
                caption: 'Asset Type',
                dataField: 'asset_type',
                alignment: 'center',
                width: 'auto',
            },
            {
                caption: 'Asset Sub-Type',
                dataField: 'propertysubtype',
                alignment: 'center',
                width: 'auto',
            },
            {
                caption: 'Asset Life Cycle',
                dataField: 'assetlifecycle',
                alignment: 'center',
                width: 'auto',
            },
            {
                caption: 'Class Code',
                dataField: 'classcode',
                alignment: 'center',
                width: 'auto',
            },
            {
                caption: '# Buildings',
                dataField: 'number_buildings',
                alignment: 'center',
                width: 'auto',
            },
            {
                caption: 'Year Built',
                dataField: 'year_built',
                alignment: 'center',
                width: 'auto',
            },
            {
                caption: 'Certification',
                dataField: 'certifications',
                alignment: 'center',
                width: 'auto',
            },
        ],
    },
    {
        caption: 'Area',
        alignment: 'center',
        columns: [
            {
                caption: 'Total Area',
                dataField: 'sf_100',
                width: 'auto',
                alignment: 'center',
                format: { type: 'fixedpoint', precision: 0 },
                allowHeaderFiltering: true,
                allowGrouping: false,
                headerFilter: { groupInterval: 100000, allowSearch: false },
            },
            {
                caption: 'Occupied Area',
                dataField: 'sf_occupied_100',
                width: 'auto',
                alignment: 'center',
                format: { type: 'fixedpoint', precision: 0 },
                allowHeaderFiltering: true,
                allowGrouping: false,
                headerFilter: { groupInterval: 100000, allowSearch: false },
            },
            {
                caption: 'Area (Own)',
                dataField: 'sf_own',
                alignment: 'center',
                width: 'auto',
                format: { type: 'fixedpoint', precision: 0 },
                allowHeaderFiltering: true,
                allowGrouping: false,
                headerFilter: { groupInterval: 100000, allowSearch: false },
            },

            {
                caption: 'Area Occ. (Own)',
                dataField: 'sf_occupied_own',
                width: 'auto',
                alignment: 'center',
                format: { type: 'fixedpoint', precision: 0 },
                allowHeaderFiltering: true,
                allowGrouping: false,
                headerFilter: { groupInterval: 100000, allowSearch: false },
            },
            {
                caption: 'Area (Managed)',
                dataField: 'sf_managed',
                width: 'auto',
                alignment: 'center',
                format: { type: 'fixedpoint', precision: 0 },
                allowHeaderFiltering: true,
                allowGrouping: false,
                headerFilter: { groupInterval: 100000, allowSearch: false },
            },
        ],
    },
    {
        caption: 'WALE (yrs)',
        alignment: 'center',
        columns: [
            {
                caption: 'WALE (100%)',
                dataField: 'wale_years',
                width: 'auto',
                alignment: 'center',
                format: { type: 'fixedpoint', precision: 1 },
                allowHeaderFiltering: true,
                allowGrouping: false,
                headerFilter: { groupInterval: 1, allowSearch: false },
            },
            {
                caption: 'WALE (Own)',
                dataField: 'wale_years_custom_calc_own',
                width: 'auto',
                alignment: 'center',
                format: { type: 'fixedpoint', precision: 1 },
                allowHeaderFiltering: true,
                allowGrouping: false,
                headerFilter: { groupInterval: 1, allowSearch: false },
            },
        ],
    },
    {
        caption: 'Base Rent',
        alignment: 'center',
        columns: [
            {
                caption: 'Base Rent',
                dataField: 'base_rent',
                width: 'auto',
                alignment: 'center',
                format: { type: 'currency', precision: 2 },
                allowHeaderFiltering: true,
                allowGrouping: false,
                headerFilter: { groupInterval: 500000, allowSearch: false },
            },
            {
                caption: 'Base Rent (Own)',
                dataField: 'base_rent_own',
                width: 'auto',
                alignment: 'center',
                format: { type: 'currency', precision: 2 },
                allowHeaderFiltering: true,
                allowGrouping: false,
                headerFilter: { groupInterval: 500000, allowSearch: false },
            },
            {
                caption: 'Base Rent / Occ. SF',
                dataField: 'base_rent_psf',
                width: 'auto',
                alignment: 'center',
                format: { type: 'currency', precision: 2 },
                allowHeaderFiltering: true,
                allowGrouping: false,
                headerFilter: { groupInterval: 5, allowSearch: false },
            },
            {
                caption: 'Base Rent / Occ. SF (Own)',
                dataField: 'base_rent_own_psf',
                width: 'auto',
                alignment: 'center',
                format: { type: 'currency', precision: 2 },
                allowHeaderFiltering: true,
                allowGrouping: false,
                headerFilter: { groupInterval: 5, allowSearch: false },
            },
        ],
    },
    {
        caption: 'Team',
        alignment: 'center',
        columns: [
            {
                caption: 'Portfolio Manager',
                dataField: 'port_manager',
                alignment: 'center',
                width: 'auto',
            },
            {
                caption: 'Asset Manager',
                dataField: 'asset_manager',
                alignment: 'center',
                width: 'auto',
            },
            {
                caption: 'Analyst',
                dataField: 'analyst',
                alignment: 'center',
                width: 'auto',
            },
            {
                caption: 'Property Management Company',
                dataField: 'pm_company',
                alignment: 'center',
                width: 'auto',
            },
        ],
    },
    {
        caption: 'Acquisition & Disposition',
        alignment: 'center',
        columns: [
            {
                caption: 'Source',
                dataField: 'source',
                alignment: 'center',
                width: 'auto',
            },
            {
                caption: 'Acquisition Date',
                dataField: 'purchase_date',
                alignment: 'center',
                width: 'auto',
                dataType: 'date',
                format: toCalendarDate,
            },
            {
                caption: 'Purchase Price (100%)',
                dataField: 'purchase_price',
                width: 'auto',
                allowHeaderFiltering: true,
                allowGrouping: false,
                format: { type: 'currency', precision: 2 },
                headerFilter: { groupInterval: 50000000, allowSearch: false },
            },
            {
                caption: 'Purchase Price (Own)',
                dataField: 'purchase_own',
                width: 'auto',
                allowHeaderFiltering: true,
                allowGrouping: false,
                format: { type: 'currency', precision: 2 },
                headerFilter: { groupInterval: 50000000, allowSearch: false },
            },
            {
                caption: 'Disposition Date',
                dataField: 'dispositiondate',
                width: 'auto',
                alignment: 'center',
                dataType: 'date',
                format: toCalendarDate,
            },
            {
                caption: 'Disposition Price (100%)',
                dataField: 'dispositionprice',
                width: 'auto',
                format: { type: 'currency', precision: 2 },
                allowHeaderFiltering: true,
                allowGrouping: false,
                headerFilter: { groupInterval: 50000000, allowSearch: false },
            },
        ],
    },
    {
        caption: 'Investment Structure',
        alignment: 'center',
        columns: [
            {
                caption: 'Structure',
                dataField: 'structure',
                alignment: 'center',
                width: 'auto',
            },
            {
                caption: 'Investment Vehicle',
                dataField: 'investmentvehicle',
                alignment: 'center',
                width: 'auto',
            },
            {
                caption: 'Fund',
                dataField: 'fund',
                width: 'auto',
                alignment: 'center',
            },
            {
                caption: 'Strategy',
                dataField: 'strategy',
                width: 'auto',
                alignment: 'center',
            },
            {
                caption: 'Ownership %',
                dataField: 'ownership_percent',
                width: 'auto',
                alignment: 'center',
                allowHeaderFiltering: true,
                allowGrouping: false,
                format: { type: 'percent', precision: 2 },
                headerFilter: { groupInterval: 0.1, allowSearch: false },
            },
            {
                caption: 'Managed %',
                dataField: 'managed_percent',
                width: 'auto',
                alignment: 'center',
                format: { type: 'percent', precision: 2 },
                allowHeaderFiltering: true,
                allowGrouping: false,
                headerFilter: { groupInterval: 0.1, allowSearch: false },
            },
        ],
    },
    {
        caption: 'Partner',
        alignment: 'center',
        columns: [
            {
                caption: 'JV Partner',
                dataField: 'jvpartner',
                alignment: 'center',
                width: 'auto',
            },
            {
                caption: 'JV Partner %',
                dataField: 'jvpartner_percent',
                width: 'auto',
                alignment: 'center',
                format: { type: 'percent', precision: 2 },
                allowHeaderFiltering: true,
                allowGrouping: false,
            },
        ],
    },
    {
        caption: 'Client',
        alignment: 'center',
        columns: [
            {
                caption: 'Client',
                dataField: 'client',
                alignment: 'center',
                width: 'auto',
            },
            {
                caption: 'Client %',
                dataField: 'client_percent',
                alignment: 'center',
                format: { type: 'percent', precision: 2 },
                allowHeaderFiltering: true,
                allowGrouping: false,
                width: 'auto',
            },
        ],
    },
    {
        caption: 'Values (100%)',
        alignment: 'center',
        columns: [
            {
                caption: 'Gross Value (100%)',
                dataField: 'gross_value_100',
                width: 'auto',
                format: { type: 'currency', precision: 2 },
                allowHeaderFiltering: true,
                allowGrouping: false,
                headerFilter: { groupInterval: 50000000, allowSearch: false },
            },
            {
                caption: 'Net Value (100%)',
                dataField: 'net_value_100',
                width: 'auto',
                format: { type: 'currency', precision: 2 },
                allowHeaderFiltering: true,
                allowGrouping: false,
                headerFilter: { groupInterval: 50000000, allowSearch: false },
            },
            {
                caption: 'Debt (100%)',
                dataField: 'debt_100',
                width: 'auto',
                allowHeaderFiltering: true,
                allowGrouping: false,
                format: { type: 'currency', precision: 2 },
                headerFilter: { groupInterval: 50000000, allowSearch: false },
            },
            {
                caption: 'LTV (100%)',
                dataField: 'ltv_100',
                dataType: 'number',
                alignment: 'center',
                format: { type: 'percent', precision: 1 },
                allowHeaderFiltering: true,
                allowGrouping: false,
                headerFilter: { groupInterval: 0.1, allowSearch: false },
                width: 'auto',
            },
        ],
    },
    {
        caption: 'Values (Owned)',
        alignment: 'center',
        columns: [
            {
                caption: 'Gross Value (Own)',
                dataField: 'gross_value_own',
                width: 'auto',
                format: { type: 'currency', precision: 2 },
                allowHeaderFiltering: true,
                allowGrouping: false,
                headerFilter: { groupInterval: 50000000, allowSearch: false },
            },
            {
                caption: 'Net Value (Own)',
                dataField: 'net_value_own',
                width: 'auto',
                format: { type: 'currency', precision: 2 },
                allowHeaderFiltering: true,
                allowGrouping: false,
                headerFilter: { groupInterval: 50000000, allowSearch: false },
            },
            {
                caption: 'Debt (Own)',
                dataField: 'debt_own',
                width: 'auto',
                format: { type: 'currency', precision: 2 },
                allowHeaderFiltering: true,
                allowGrouping: false,
                headerFilter: { groupInterval: 50000000, allowSearch: false },
            },
            {
                caption: 'Debt % (Override)',
                dataField: 'debt_percentage_override',
                alignment: 'center',
                width: 'auto',
                allowHeaderFiltering: true,
                allowGrouping: false,
                format: { type: 'percent', precision: 2 },
            },
            {
                caption: 'LTV (Own)',
                dataField: 'ltv_own',
                alignment: 'center',
                width: 'auto',
                format: { type: 'percent', precision: 1 },
                allowHeaderFiltering: true,
                allowGrouping: false,
                headerFilter: { groupInterval: 0.1, allowSearch: false },
            },
        ],
    },
    {
        caption: 'Values (Client)',
        alignment: 'center',
        columns: [
            {
                caption: 'Gross Value (Client)',
                dataField: 'gross_value_client',
                width: 'auto',
                format: { type: 'currency', precision: 2 },
                allowHeaderFiltering: true,
                allowGrouping: false,
                headerFilter: { groupInterval: 50000000, allowSearch: false },
            },
            {
                caption: 'Net Value (Client)',
                dataField: 'net_value_client',
                width: 'auto',
                format: { type: 'currency', precision: 2 },
                allowHeaderFiltering: true,
                allowGrouping: false,
                headerFilter: { groupInterval: 50000000, allowSearch: false },
            },
            {
                caption: 'Debt (Client)',
                dataField: 'debt_client',
                width: 'auto',
                format: { type: 'currency', precision: 2 },
                allowHeaderFiltering: true,
                allowGrouping: false,
                headerFilter: { groupInterval: 50000000, allowSearch: false },
            },
        ],
    },
    {
        caption: 'Values (Managed)',
        alignment: 'center',
        columns: [
            {
                caption: 'Gross Value (Managed)',
                dataField: 'gross_value_managed',
                width: 'auto',
                format: { type: 'currency', precision: 2 },
                allowHeaderFiltering: true,
                allowGrouping: false,
                headerFilter: { groupInterval: 50000000, allowSearch: false },
            },
            {
                caption: 'Net Value (Managed)',
                dataField: 'net_value_managed',
                width: 'auto',
                format: { type: 'currency', precision: 2 },
                allowHeaderFiltering: true,
                allowGrouping: false,
                headerFilter: { groupInterval: 50000000, allowSearch: false },
            },
            {
                caption: 'Debt (Managed)',
                dataField: 'debt_managed',
                width: 'auto',
                format: { type: 'currency', precision: 2 },
                allowHeaderFiltering: true,
                allowGrouping: false,
                headerFilter: { groupInterval: 50000000, allowSearch: false },
            },
        ],
    },
    {
        caption: 'Values (Yardi)',
        alignment: 'center',
        columns: [
            {
                caption: 'Gross Value (Yardi)',
                dataField: 'gross_value_yardi',
                width: 'auto',
                format: { type: 'currency', precision: 2 },
                allowHeaderFiltering: true,
                allowGrouping: false,
                headerFilter: { groupInterval: 50000000, allowSearch: false },
            },
            {
                caption: 'Debt (Yardi)',
                dataField: 'debt_yardi',
                width: 'auto',
                format: { type: 'currency', precision: 2 },
                allowHeaderFiltering: true,
                allowGrouping: false,
                headerFilter: { groupInterval: 50000000, allowSearch: false },
            },
            {
                caption: 'Financials %',
                dataField: 'ownership_yardi_percent',
                width: 'auto',
                alignment: 'center',
                format: { type: 'percent', precision: 2 },
                allowHeaderFiltering: true,
                allowGrouping: false,
                headerFilter: { groupInterval: 0.5, allowSearch: false },
            },
        ],
    },
];

export const getTotalItems: ITotalItemProps[] = [
    {
        column: 'display_name',
        summaryType: 'count',
        displayFormat: '{0} Assets',
    },
    {
        column: 'sf_100',
        summaryType: 'sum',
        valueFormat: { type: 'fixedpoint', precision: 0 },
        displayFormat: '{0}',
    },
    {
        column: 'sf_occupied_100',
        summaryType: 'sum',
        valueFormat: { type: 'fixedpoint', precision: 0 },
        displayFormat: '{0}',
    },
    {
        column: 'sf_own',
        summaryType: 'sum',
        valueFormat: { type: 'fixedpoint', precision: 0 },
        displayFormat: '{0}',
    },
    {
        column: 'sf_occupied_own',
        summaryType: 'sum',
        valueFormat: { type: 'fixedpoint', precision: 0 },
        displayFormat: '{0}',
    },

    {
        column: 'sf_managed',
        summaryType: 'sum',
        valueFormat: { type: 'fixedpoint', precision: 0 },
        displayFormat: '{0}',
    },
    {
        showInColumn: 'wale_years_custom_calc_own',
        name: 'wale_years_custom_calc_own',
        summaryType: 'custom',
        valueFormat: { type: 'fixedpoint', precision: 1 },
        displayFormat: '{0}',
    },
    {
        showInColumn: 'wale_years',
        name: 'wale_years',
        summaryType: 'custom',
        valueFormat: { type: 'fixedpoint', precision: 1 },
        displayFormat: '{0}',
    },
    {
        column: 'base_rent',
        summaryType: 'sum',
        valueFormat: { type: 'currency', precision: 2 },
        displayFormat: '{0}',
    },
    {
        column: 'base_rent_own',
        summaryType: 'sum',
        valueFormat: { type: 'currency', precision: 2 },
        displayFormat: '{0}',
    },
    {
        showInColumn: 'base_rent_psf',
        name: 'base_rent_psf',
        summaryType: 'custom',
        valueFormat: { type: 'currency', precision: 2 },
        displayFormat: '{0}',
    },
    {
        showInColumn: 'base_rent_own_psf',
        name: 'base_rent_own_psf',
        summaryType: 'custom',
        valueFormat: { type: 'currency', precision: 2 },
        displayFormat: '{0}',
    },
    {
        column: 'gross_value_100',
        summaryType: 'sum',
        valueFormat: { type: 'currency', precision: 2 },
        displayFormat: '{0}',
    },
    {
        column: 'net_value_100',
        summaryType: 'sum',
        valueFormat: { type: 'currency', precision: 2 },
        displayFormat: '{0}',
    },
    {
        column: 'debt_100',
        valueFormat: { type: 'currency', precision: 2 },
        displayFormat: '{0}',
        summaryType: 'sum',
    },
    {
        valueFormat: { type: 'percent', precision: 1 },
        displayFormat: '{0}',
        showInColumn: 'ltv_100',
        name: 'ltv_100',
        summaryType: 'custom',
    },
    {
        column: 'dispositionprice',
        summaryType: 'sum',
        valueFormat: { type: 'currency', precision: 2 },
        displayFormat: '{0}',
    },
    {
        column: 'purchase_price',
        summaryType: 'sum',
        valueFormat: { type: 'currency', precision: 2 },
        displayFormat: '{0}',
    },
    {
        column: 'purchase_own',
        summaryType: 'sum',
        valueFormat: { type: 'currency', precision: 2 },
        displayFormat: '{0}',
    },
    {
        column: 'gross_value_own',
        summaryType: 'sum',
        valueFormat: { type: 'currency', precision: 2 },
        displayFormat: '{0}',
    },
    {
        column: 'net_value_own',
        summaryType: 'sum',
        valueFormat: { type: 'currency', precision: 2 },
        displayFormat: '{0}',
    },
    {
        column: 'debt_own',
        summaryType: 'sum',
        valueFormat: { type: 'currency', precision: 2 },
        displayFormat: '{0}',
    },
    {
        valueFormat: { type: 'percent', precision: 1 },
        displayFormat: '{0}',
        name: 'ltv_own',
        showInColumn: 'ltv_own',
        summaryType: 'custom',
    },
    {
        column: 'gross_value_client',
        summaryType: 'sum',
        valueFormat: { type: 'currency', precision: 2 },
        displayFormat: '{0}',
    },
    {
        column: 'net_value_client',
        summaryType: 'sum',
        valueFormat: { type: 'currency', precision: 2 },
        displayFormat: '{0}',
    },
    {
        column: 'debt_client',
        summaryType: 'sum',
        valueFormat: { type: 'currency', precision: 2 },
        displayFormat: '{0}',
    },
    {
        column: 'gross_value_managed',
        summaryType: 'sum',
        valueFormat: { type: 'currency', precision: 2 },
        displayFormat: '{0}',
    },
    {
        column: 'net_value_managed',
        summaryType: 'sum',
        valueFormat: { type: 'currency', precision: 2 },
        displayFormat: '{0}',
    },
    {
        column: 'debt_managed',
        summaryType: 'sum',
        valueFormat: { type: 'currency', precision: 2 },
        displayFormat: '{0}',
    },
    {
        column: 'debt_yardi',
        summaryType: 'sum',
        valueFormat: { type: 'currency', precision: 2 },
        displayFormat: '{0}',
    },
    {
        column: 'gross_value_yardi',
        summaryType: 'sum',
        valueFormat: { type: 'currency', precision: 2 },
        displayFormat: '{0}',
    },
];

export const groupingOptions = [
    { value: 'total_area', label: 'Total Area' },
    { value: 'sf_own', label: 'Area (Own)' },
    { value: 'sf_occupied_own', label: 'Area Occ. (Own)' },
    { value: 'sf_managed', label: 'Area (Managed)' },
    { value: 'wale_years', label: 'WALE (100%)' },
    { value: 'wale_years_custom_calc_own', label: 'WALE (Own)' },
    { value: 'gross_value_100', label: 'Gross Value (100%)' },
    { value: 'net_value_100', label: 'Net Value (100%)' },
    { value: 'debt_100', label: 'Debt (100%)' },
    { value: 'ltv_100', label: 'LTV (100%)' },
    { value: 'dispositionprice', label: 'Disposition Price (100%)' },
    { value: 'purchase_price', label: 'Purchase Price (100%)' },
    { value: 'net_value_own', label: 'Net Value (Own)' },
    { value: 'debt_own', label: 'Debt (Own)' },
    { value: 'ltv_own', label: 'LTV (Own)' },
    { value: 'gross_value_client', label: 'Gross Value (Client)' },
    { value: 'net_value_client', label: 'Net Value (Client)' },
    { value: 'debt_client', label: 'Debt (Client)' },
    { value: 'gross_value_managed', label: 'Gross Value (Managed)' },
    { value: 'net_value_managed', label: 'Net Value (Managed)' },
    { value: 'debt_managed', label: 'Debt (Managed)' },
    { value: 'debt_yardi', label: 'Debt (Yardi)' },
    { value: 'gross_value_yardi', label: 'Gross Value (Yardi)' },
];
