import { css } from 'emotion';
export const legendlabelStyle = css({
    fontSize: '10px',
    color: '#848484',
    lineHeight: '12px',
});

export const legendLineStyle = css({
    height: '2px',
    width: '24px',
    margin: '0 7.5px 0 0',
    display: 'inline-block',
});
export const legendSquareStyle = css({
    height: '17px',
    width: '17px',
    margin: '0 7.5px 0 0',
    display: 'inline-block',
});

export const legendContainerStyle = css({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
});

export const legendItemContainer = css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0 0 20px;
`;
