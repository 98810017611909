import { camelCase, capitalize, mapKeys, uniq } from 'lodash';
import { API_URL } from 'config/constants';
import { SelectedDataLevel } from 'waypoint-types';

export interface GetTenantsResponse {
    tenants: any[];
    tenantIds: string[];
    tenantStats: any;
}

interface TenantTransformReducerState {
    tenantsByKey: Map<string, TenantShape>;
    uniqueTenants: Map<string, boolean>;
    totalAnnualInPlaceRent: number;
}

interface TenantShape {
    id: number;
    name: string;
    active_total_square_footage: number;
    avg_annual_in_place_rent: number;
    avg_annual_in_place_rent_per_sqft: number;
    total_annual_in_place_rent: number;
    property_id_arr: number[];
    industry: string;
    percentOccupiedSqFtOfPortfolioSqFt: number;
    occupancySqftPercentByGroup: number;
    weightedAverageLeaseExpiration: number;
}

const getTenants = async (
    entityCodes: string[],
    selectedDataLevel: SelectedDataLevel
): Promise<GetTenantsResponse> => {
    const response = await fetch(`${API_URL}/leases/tenants`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            entity_codes: entityCodes,
            selected_data_level: selectedDataLevel,
        }),
    });

    if (!response.ok) {
        throw new Error('FAILED');
    }

    const data = await response.json();
    return transformTenantsData(data);
};

const transformTenantsData = (response: { data: any[]; metadata: any }) => {
    const { tenantsByKey, uniqueTenants, totalAnnualInPlaceRent } =
        response.data.reduce(
            (
                { tenantsByKey, uniqueTenants, totalAnnualInPlaceRent },
                leaseDataRow
            ) => {
                const key = `${leaseDataRow.tenant_name}__${leaseDataRow.tenant_industry}`;
                const tenant: TenantShape = tenantsByKey.get(key) || {
                    name: leaseDataRow.tenant_name,
                    active_total_square_footage: 0,
                    avg_annual_in_place_rent:
                        leaseDataRow.average_annual_in_place_rent,
                    avg_annual_in_place_rent_per_sqft:
                        leaseDataRow.average_annual_in_place_rent_persqft,
                    total_annual_in_place_rent: 0,
                    id: uniqueTenants.size + 1,
                    property_id_arr: [],
                    industry: leaseDataRow.tenant_industry,
                    percentOccupiedSqFtOfPortfolioSqFt: 0,
                    occupancySqftPercentByGroup:
                        leaseDataRow.occupancy_sqft_percent_by_group * 100,
                    weightedAverageLeaseExpiration: leaseDataRow.wale_years,
                };

                tenant.active_total_square_footage +=
                    leaseDataRow.tenant_lease_area;

                tenant.total_annual_in_place_rent +=
                    leaseDataRow.total_annual_in_place_rent;

                tenant.percentOccupiedSqFtOfPortfolioSqFt +=
                    leaseDataRow.occupancy_sqft_percent * 100;

                tenant.property_id_arr = uniq([
                    ...tenant.property_id_arr,
                    leaseDataRow.entity_code,
                ]);

                totalAnnualInPlaceRent +=
                    leaseDataRow.total_annual_in_place_rent;

                tenantsByKey.set(key, tenant);
                tenant.name && uniqueTenants.set(tenant.name, true);

                return { tenantsByKey, uniqueTenants, totalAnnualInPlaceRent };
            },
            {
                tenantsByKey: new Map(),
                uniqueTenants: new Map(),
                totalAnnualInPlaceRent: 0,
            } as TenantTransformReducerState
        );

    const tenantStats = {
        portfolioNumTenants: uniqueTenants.size,
        portfolioWeightedAverageLeaseExpiration: response.metadata.wale_year,
        portfolioAnnualInPlaceMonthlyRent: totalAnnualInPlaceRent,
        tenantStatsByIndustry: response.metadata.tenantStatsByIndustry.map(
            (item: any) => {
                return {
                    ...item,
                    tenant_industry: capitalize(item.tenant_industry),
                };
            }
        ),
    };

    const tenants = Array.from(tenantsByKey.values()).map((d: any) =>
        mapKeys(d, (v: TenantShape, k: string) => camelCase(k))
    );

    const tenantIds = tenants.map((t) => t.id);

    return {
        tenants,
        tenantIds,
        tenantStats,
    };
};

export default getTenants;
