import { css } from 'emotion';
import { Modal, Input, Radio, Form } from 'antd';
import { ReactElement, useContext, useEffect } from 'react';
import { SavedFiltersContext, SavedFiltersContextType } from 'contexts';
import { FilterReferenceTypes } from 'contexts/saved-filters/SavedFiltersTypes';
import { connect } from 'react-redux';
import { roles as userRoles } from 'config/constants';

const removeMargin = css`
    margin-bottom: 0px !important;
`;

export interface FilterEditorSaveAsProps {
    canSetPermissions: boolean;
    visible: boolean;
    onConfirm: (name: string, referenceType: FilterReferenceTypes) => void;
    onCancel: () => void;
}

type ContextType = SavedFiltersContextType | null;

const FilterEditorSaveAs = ({
    visible,
    onCancel,
    onConfirm,
    canSetPermissions,
}: FilterEditorSaveAsProps): ReactElement<FilterEditorSaveAsProps> => {
    const savedFilterContext = useContext<ContextType>(SavedFiltersContext);

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFields([
            { name: 'Filter name', value: '' },
            { name: 'permissions', value: FilterReferenceTypes.USER },
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isSaving = savedFilterContext?.isSavedFiltersLoading ?? false;

    return (
        <>
            <Modal
                title="Save filter as..."
                zIndex={599999}
                open={visible}
                onOk={async () => {
                    await form.validateFields();

                    onConfirm(
                        form.getFieldValue('Filter name'),
                        form.getFieldValue('permissions')
                    );
                }}
                confirmLoading={isSaving}
                cancelButtonProps={{
                    disabled: isSaving,
                }}
                closable={!isSaving}
                onCancel={onCancel}
                okText={'Save'}
                cancelText={'Cancel'}
                data-testid="save-as-filter"
            >
                <div>
                    <Form form={form} layout={'vertical'} autoComplete={'off'}>
                        <Form.Item
                            name={'Filter name'}
                            label={'Filter name'}
                            rules={[{ required: true }, { min: 1 }]}
                            className={!canSetPermissions ? removeMargin : ''}
                        >
                            <Input
                                placeholder={'Filter name'}
                                disabled={isSaving}
                                maxLength={50}
                            />
                        </Form.Item>

                        <Form.Item
                            label={'Permissions'}
                            name={'permissions'}
                            className={removeMargin}
                            hidden={!canSetPermissions}
                        >
                            <Radio.Group disabled={isSaving}>
                                <Radio value={FilterReferenceTypes.USER}>
                                    Only me
                                </Radio>
                                <Radio value={FilterReferenceTypes.CLIENT}>
                                    Everyone
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    );
};

const mapState = (state: any) => {
    const { roles } = state.user;
    const isAdmin = roles.includes(userRoles.CLIENT_ADMIN);
    const isWaypointAssociate = roles.includes(userRoles.WAYPOINT_ASSOCIATE);
    const isWaypointSysadmin = roles.includes(userRoles.WAYPOINT_SYSADMIN);

    const canSetPermissions =
        isWaypointAssociate || isWaypointSysadmin || isAdmin;

    return {
        canSetPermissions,
    };
};

export default connect(mapState)(FilterEditorSaveAs);
