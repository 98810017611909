import { useGetAttributes } from 'waypoint-hooks';
import { PropertyProfileAttributesTable } from 'components/propertyProfile/property-profile-components/PropertyProfileAttributesTable';
import React, { useImperativeHandle, useRef } from 'react';
import { PDFExportable } from 'waypoint-utils/pdf/PDFExportable';
import { DataGridRef } from 'devextreme-react/cjs/data-grid';

interface PropertyAttributesEntityReportWidgetParams {
    entityCode: string;
    widgetId?: string;
    narrativePosition?: string;
    isPDFExport?: boolean;
}

export const PropertyAttributesEntityReportWidget = React.forwardRef<
    PDFExportable,
    PropertyAttributesEntityReportWidgetParams
>(
    (
        { entityCode, isPDFExport }: PropertyAttributesEntityReportWidgetParams,
        ref,
    ) => {
        const dataGridRef = useRef<DataGridRef<any, any>>(null);

        const { data: allAttributes, isLoading } = useGetAttributes([
            entityCode,
        ]);

        useImperativeHandle(ref, () => ({
            isReadyToExport(): boolean {
                return !isLoading;
            },
        }));

        const entity =
            allAttributes?.entities?.find((x) => x.entityCode === entityCode) ??
            null;

        if (!entity || !allAttributes) {
            return <div />;
        }

        return (
            <PropertyProfileAttributesTable
                entityAttributes={allAttributes.attributeDefinitions}
                dataGridRef={dataGridRef}
                isPDFExport={isPDFExport}
            />
        );
    },
);
