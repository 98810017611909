import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { connectRequest, querySelectors } from 'redux-query';
import { isUndefined } from 'lodash';
import { selectClientId } from 'state/user/selectors';
import { getDashboardConfiguration } from 'state/requests';

export const withDashboardConfigurationRequest = (WrappedComponent) => {
    class WithDashboardConfigurationRequest extends Component {
        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    const mapState = (state, props) => {
        const { dashboardConfigurationId, module } = props;

        const clientId = selectClientId(state);

        if (!dashboardConfigurationId) {
            console.warn(
                'A dashboardConfigurationId to the to dashboard request'
            );
            return {};
        }

        const dashboardConfigurationRequest = getDashboardConfiguration({
            clientId,
            dashboardConfigurationId,
            module,
        });

        const { url } = dashboardConfigurationRequest;
        const queryStatus = querySelectors.isPending(state.queries, { url });

        const isDashboardConfigurationLoading = isUndefined(queryStatus)
            ? true
            : queryStatus;

        return {
            isDashboardConfigurationLoading,
            dashboardConfigurationRequest,
        };
    };

    return compose(
        connect(mapState),
        connectRequest(
            ({ dashboardConfigurationRequest }) => dashboardConfigurationRequest
        )
    )(WithDashboardConfigurationRequest);
};

export default withDashboardConfigurationRequest;
