import React from 'react';
import { CommentsPaneWrapper } from 'waypoint-react/comment/CommentsPaneWrapper';
import EntityReportEditor from './components/EntityReportEditor';

const EntityReportEditorContainer = () => {
    return (
        <CommentsPaneWrapper>
            <EntityReportEditor />
        </CommentsPaneWrapper>
    );
};

export default EntityReportEditorContainer;
