import { PivotGridSummaryCell } from 'components/financials/crosstab/CrosstabTypes';
import { safeDivision } from 'waypoint-utils';
import { CROSSTAB_SECONDARY_MODE } from '../CrosstabConstants';

/**
 * dxPivotGrid requires a `number` type to be returned from any calculated cell,
 * however, the value `NaN`, which is a `number` in TypeScript, will cause Excel
 * to go into recovery mode. This is a work-around that Excel accepts.
 */
const EMPTY_CELL_VALUE: number = null as unknown as number;

export const calculateVariancePercentSummary = (e: PivotGridSummaryCell) => {
    const variance = e.value('VARIANCE');
    const secondModeSelection = e.value(CROSSTAB_SECONDARY_MODE) ?? 0;
    return secondModeSelection !== 0
        ? safeDivision(variance, secondModeSelection)
        : EMPTY_CELL_VALUE;
};
