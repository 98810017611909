import React from 'react';
import LoadingTable from 'components/style/LoadingTable';
import Card from 'components/style/Card';
import { css, cx } from 'emotion';

const emptyTableCard = css`
    padding: 50px;
`;

const flexTop = css`
    display: flex;
    flex-direction: row;
    align-items: center;

    h3 {
        flex: 3;
        padding: 0;
        margin: 0;
        font-weight: bold;
        font-size: 22px;
    }
`;

const TenantLoadingTable = ({ title, style, className, ...props }) => {
    return (
        <Card
            fluid
            style={style}
            className={cx([emptyTableCard, className])}
            data-grid={props['data-grid']}
        >
            <div className={flexTop}>
                <h3>{title}</h3>
            </div>
            <div>
                <LoadingTable rowCount={6} />
            </div>
        </Card>
    );
};

export default TenantLoadingTable;
