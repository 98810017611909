import { useMemo, useRef, useState } from 'react';
import {
    Contract,
    ContractsWithVendorAndServiceTypeOptions,
    PropertyDetailsProps,
} from 'waypoint-types';
import {
    formatUpdatedRowDate,
    isMultiplePropertiesPage as isMultiplePropertiesPageFn,
    isPropertyProfilePage as isPropertyProfilePageFn,
} from '../components/contracts/utils';
import {
    ClientSelectableOptions,
    TypeOfContractsView,
} from '../components/contracts/interfaces';
import {
    ExportingEvent,
    RowPreparedEvent,
    RowRemovedEvent,
    RowUpdatedEvent,
} from 'devextreme/ui/data_grid';
import {
    createContract,
    deleteContract,
    updateContract,
} from '../waypoint-requests';
import { message } from 'antd';
import theme from 'config/theme';
import exportExcelFromDevExtremeDataGrid from '../waypoint-utils/dev-extreme/exportExcelFromDevExtremeDataGrid';
import { KeyedMutator } from 'swr';
import { DataGridRef } from 'devextreme-react/cjs/data-grid';

interface useContractsConfigProps {
    data: ContractsWithVendorAndServiceTypeOptions;
    leasesPerformanceData: PropertyDetailsProps[];
    entityCodes: string[];
    typeOfView: TypeOfContractsView;
    mutate: KeyedMutator<ContractsWithVendorAndServiceTypeOptions>;
}
export const useContractsConfig = ({
    data,
    leasesPerformanceData,
    entityCodes,
    typeOfView,
    mutate,
}: useContractsConfigProps) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [grouping, setGrouping] = useState<string>('');
    const dataGrid = useRef<DataGridRef>(null);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [expandButtonEnable, setExpandButtonEnable] =
        useState<boolean>(false);

    const isMultiplePropertiesPage = isMultiplePropertiesPageFn(typeOfView);
    const isPropertyProfilePage = isPropertyProfilePageFn(typeOfView);
    const {
        contracts: contractsData,
        client_service_types: serviceTypes,
        client_vendors: vendors,
    } = data;

    const [serviceTypeOptions, setServiceTypeOptions] = useState<
        ClientSelectableOptions[]
    >([]);

    const [vendorOptions, setVendorOptions] = useState<
        ClientSelectableOptions[]
    >([]);

    const [propertyOptions, setPropertyOptions] = useState<
        ClientSelectableOptions[]
    >([]);

    useMemo(() => {
        if (data) {
            const clientServiceTypeOptions = serviceTypes.map((type) => {
                return {
                    value: type,
                    name: type,
                };
            });
            const clientVendorOptions = vendors.map((vendor) => {
                return {
                    value: vendor,
                    name: vendor,
                };
            });

            const clientPropertyOptions = leasesPerformanceData.map(
                (property) => {
                    return {
                        value: property.entity_code,
                        name: property.property_name,
                    };
                },
            );
            setServiceTypeOptions(clientServiceTypeOptions);
            setVendorOptions(clientVendorOptions);
            setPropertyOptions(clientPropertyOptions);
        }
    }, [data]);

    const propertyOptionsData = useMemo(
        () =>
            propertyOptions.map((v) => {
                return { ...v, text: v.name };
            }),
        [propertyOptions],
    );

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const resetGrouping = () => {
        dataGrid?.current?.instance().clearGrouping();
        setExpandButtonEnable(true);
        setGrouping('');
    };

    const onRowRemoved = async (params: RowRemovedEvent) => {
        const entityCode = params.data.entity_code;
        const updatedContracts = contractsData
            ? contractsData.filter(
                  (contract: Contract) => contract.id !== params.data.id,
              )
            : [];

        mutate(
            async () => {
                try {
                    await deleteContract(entityCode, params.data.id);
                    message.success(`Contract deleted successfully`);
                    return {
                        contracts: updatedContracts,
                        client_service_types: serviceTypes,
                        client_vendors: vendors,
                    };
                } catch (e) {
                    message.error(`Failed to delete contract`);
                    return data;
                }
            },
            {
                optimisticData: {
                    contracts: updatedContracts,
                    client_service_types: serviceTypes,
                    client_vendors: vendors,
                },
                rollbackOnError: true,
                populateCache: true,
                revalidate: true,
            },
        );
    };

    const onRowPrepared = (e: RowPreparedEvent) => {
        const isGrouped = e.rowType === 'group';
        const isHeader = e.rowType === 'header';
        if (isGrouped) {
            e.rowElement.style.backgroundColor = theme.colors.grays.background;
        }
        if (isHeader) {
            e.rowElement.style.fontWeight = 'bold';
            e.rowElement.style.textDecorationColor = theme.colors.grays.text;
            e.rowElement.style.color = theme.colors.grays.text;
        }
    };

    const onRowSaved = async ({ data: rowItemData }: RowUpdatedEvent) => {
        const entityCode = isPropertyProfilePage
            ? entityCodes[0]
            : rowItemData?.entity_code; // get entity_code from current row

        const contractData = {
            ...rowItemData,
            annual_cost: Number(rowItemData.annual_cost),
            last_bid_date: formatUpdatedRowDate(rowItemData.last_bid_date),
            current_commencement_date: formatUpdatedRowDate(
                rowItemData.current_commencement_date,
            ),
            general_liability_insurance_expiration_date: formatUpdatedRowDate(
                rowItemData.general_liability_insurance_expiration_date,
            ),
        };
        // When we are creating a contract, we want to save with the entity_code
        if (!contractData.entity_code && entityCode) {
            contractData.entity_code = entityCode;
        }

        const updatedContracts: Contract[] = contractData
            ? [
                  contractData,
                  ...contractsData.filter(
                      (contract: Contract) => contract.id !== rowItemData.id,
                  ),
              ]
            : [contractsData];

        mutate(
            async () => {
                try {
                    rowItemData.id
                        ? await updateContract(rowItemData.id, contractData)
                        : await createContract(contractData);

                    message.success(
                        `Contract ${
                            rowItemData.id ? 'updated' : 'added'
                        } successfully`,
                    );

                    return {
                        contracts: updatedContracts,
                        client_service_types: serviceTypes,
                        client_vendors: vendors,
                    };
                } catch (e) {
                    message.error(
                        `Failed to ${
                            rowItemData.id ? 'update' : 'add'
                        } contract`,
                    );
                    return data;
                } finally {
                    setIsEditing(false);
                }
            },
            {
                optimisticData: {
                    contracts: updatedContracts,
                    client_service_types: serviceTypes,
                    client_vendors: vendors,
                },
                rollbackOnError: true,
                populateCache: true,
                revalidate: true,
            },
        );
    };

    const onExporting = async (e: ExportingEvent) => {
        await exportExcelFromDevExtremeDataGrid(e, {
            worksheetName: 'Contracts',
            filename: 'contracts.xlsx',
        });
    };
    return {
        isEditing,
        setIsEditing,
        resetGrouping,
        dataGrid,
        expanded,
        setExpanded,
        toggleExpanded,
        expandButtonEnable,
        setExpandButtonEnable,
        isMultiplePropertiesPage,
        contractsData,
        propertyOptionsData,
        serviceTypeOptions,
        vendorOptions,
        propertyOptions,
        onRowRemoved,
        onRowPrepared,
        onRowSaved,
        onExporting,
    };
};
