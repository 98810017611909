import { API_URL } from 'config/constants';
import { LeasingGuideline } from 'waypoint-types';

const getLeasingGuidelines = async (
    entity_codes: string,
    widgetYear?: number
): Promise<LeasingGuideline[]> => {
    const url = widgetYear
        ? `${API_URL}/leasing-guidelines/${entity_codes}?year=${widgetYear}`
        : `${API_URL}/leasing-guidelines/${entity_codes}`;

    const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
    });

    if (!response.ok) {
        throw new Error('FAILED');
    }

    const { data } = await response.json();
    return data;
};

export default getLeasingGuidelines;
