// @ts-nocheck
// This file is a result of nx update

import { merge, cloneDeep, concat } from 'lodash';
import { tenants as TENANTS } from 'state/actionTypes';

const defaultState = {
    propertyTenants: [],
    groupTenants: {},
    tenantIdsByPropertyGroup: {},
    tenantIdsByProperty: {},
    groupTenantStatistics: {},
    propertyTenantStatistics: {},
};

const loadGroupTenants = (state, data) => {
    return cloneDeep(merge(state, { groupTenants: data }));
};

const loadPropertyTenants = (state, data) => {
    const propertyTenants = concat(state.propertyTenants, data);
    return cloneDeep(merge(state, { propertyTenants }));
};

const loadTenantIdsByProperty = (state, payload) => {
    return cloneDeep(merge(state, { tenantIdsByProperty: payload }));
};

const loadTenantIdsByPropertyGroup = (state, payload) => {
    return cloneDeep(merge(state, { tenantIdsByPropertyGroup: payload }));
};

const loadGroupTenantStatistics = (state, payload) => {
    return cloneDeep(merge(state, { groupTenantStatistics: payload }));
};

const loadPropertyTenantStatistics = (state, payload) => {
    return cloneDeep(merge(state, { propertyTenantStatistics: payload }));
};

export default function reducer(tenants = defaultState, action) {
    const { type, payload } = action;

    switch (type) {
        case TENANTS.LOAD_PROPERTY_TENANTS:
            return loadPropertyTenants(tenants, payload);
        case TENANTS.LOAD_GROUP_TENANTS:
            return loadGroupTenants(tenants, payload);
        case TENANTS.LOAD_GROUP_TENANT_STATISTICS:
            return loadGroupTenantStatistics(tenants, payload);
        case TENANTS.LOAD_PROPERTY_TENANT_STATISTICS:
            return loadPropertyTenantStatistics(tenants, payload);
        case TENANTS.LOAD_TENANT_IDS_BY_PROPERTY_GROUP:
            return loadTenantIdsByPropertyGroup(tenants, payload);
        case TENANTS.LOAD_TENANT_IDS_BY_PROPERTY:
            return loadTenantIdsByProperty(tenants, payload);
        default:
            return tenants;
    }
}
