import { API_URL } from 'config/constants';
import { LeasingPlan } from 'waypoint-types/leasing-plans';

interface CreateLeasingPlanParams {
    year: number;
    entity_code: string;
    as_of_date: string;
    year_activity_filter: number;
}

const createLeasingPlan = async (
    body: CreateLeasingPlanParams
): Promise<LeasingPlan> => {
    const response = await fetch(`${API_URL}/leasing-plans/create`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(body),
    });

    if (!response.ok) {
        throw new Error('Failed to create leasing plan');
    }

    const { data } = await response.json();

    return data as LeasingPlan;
};

export default createLeasingPlan;
