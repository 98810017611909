import { PROPERTY_VALUE_TITLE } from 'components/analytics/portfolioSegmentation/constants';
import { API_URL } from 'config/constants';

export const PORTFOLIO_SEGMENTATION_PROPERTY_ATTRIBUTES_URL = `${API_URL}/segmentation/property-attributes`;
// TODO: Remove this
export const PORTFOLIO_SEGMENTATION_PROPERTY_VALUES_URL = `${API_URL}/segmentation/property-value`;
export const PORTFOLIO_SEGMENTATION_DIVERSIFICATION_URL = `${API_URL}/segmentation/diversification`;
export const PORTFOLIO_SEGMENTATION_METRICS_URL = `${API_URL}/segmentation/kpi-code`;
export const PORTFOLIO_SEGMENTATION_GROUP_BY_URL = `${API_URL}/segmentation/group-by`;
export const ATTRIBUTES_VALUES_URL =
    '/api/v1/portfolio-segmentation/attribute-values';
export const PORTFOLIO_SEGMENTATION_ENTITY_CODES_URL =
    '/api/v1/entities/search';

/**
 * Submits a network request to request the attributes values
 *
 * @returns {Promise<Response>}
 */

export function getAttributesValues() {
    const defaultHttpHeaders = { 'Content-Type': 'application/json' };
    return fetch(ATTRIBUTES_VALUES_URL, {
        method: 'GET',
        headers: defaultHttpHeaders,
    });
}

/**
 * Submits a network request to request the entity codes based on filters
 *
 * @param {object} filters
 * @returns {Promise<Response>}
 */

export function getEntityCodes(filters) {
    const defaultHttpHeaders = { 'Content-Type': 'application/json' };
    return fetch(PORTFOLIO_SEGMENTATION_ENTITY_CODES_URL, {
        method: 'POST',
        headers: defaultHttpHeaders,
        body: JSON.stringify({ filters }),
    });
}

/**
 * Submits a network request to fetch portofolio attributes.
 *
 * @param {object} options
 * @returns {Promise<Response>}
 * @param tab
 */
export function getPropertyAttributes({
    attributes = [],
    entityCodes = null,
    period,
    httpHeaders,
} = {}) {
    const defaultHttpHeaders = { 'Content-Type': 'application/json' };
    const updatedHttpHeaders = Object.assign(defaultHttpHeaders, httpHeaders);

    return fetch(PORTFOLIO_SEGMENTATION_PROPERTY_ATTRIBUTES_URL, {
        method: 'POST',
        headers: updatedHttpHeaders,
        credentials: 'include',
        body: JSON.stringify({
            attributes,
            entity_codes: entityCodes,
            period,
        }),
    });
}

/**
 * Submits a network request to fetch portofolio values.
 *
 * @param {object} options
 * @returns {Promise<Response>}
 */

export function getPropertyValues({
    httpHeaders,
    entityCodes = null,
    period,
} = {}) {
    const defaultHttpHeaders = { 'Content-Type': 'application/json' };
    const updatedHttpHeaders = Object.assign(defaultHttpHeaders, httpHeaders);

    return fetch(PORTFOLIO_SEGMENTATION_PROPERTY_VALUES_URL, {
        method: 'POST',
        headers: updatedHttpHeaders,
        credentials: 'include',
        body: JSON.stringify({ entity_codes: entityCodes, period }),
    });
}

/**
 * Submits a network request to fetch portofolio perfomance overview.
 *
 * @param {object} options
 * @returns {Promise<Response>}
 */

export function getPerformances({
    metric,
    group_by,
    entityCodes = null,
    period,
    httpHeaders = {},
}) {
    const defaultHttpHeaders = { 'Content-Type': 'application/json' };
    const updatedHttpHeaders = Object.assign(defaultHttpHeaders, httpHeaders);
    return fetch(PORTFOLIO_SEGMENTATION_DIVERSIFICATION_URL, {
        method: 'POST',
        headers: updatedHttpHeaders,
        credentials: 'include',
        body: JSON.stringify({
            metric,
            group_by,
            period,
            entity_codes: entityCodes,
        }),
    });
}

/**
 * Submits a network request to fetch portfolio performance metrics
 *
 * @param {object} options
 * @returns {Promise<Response>}
 */
export function getMetrics() {
    const defaultHttpHeaders = { 'Content-Type': 'application/json' };

    return fetch(PORTFOLIO_SEGMENTATION_METRICS_URL, {
        method: 'GET',
        credentials: 'include',
        headers: defaultHttpHeaders,
    });
}

/**
 * Submits a network request to fetch portofolio perfomance group by options
 *
 * @param {object} options
 * @returns {Promise<Response>}
 */
export function getGroupByOptions() {
    const defaultHttpHeaders = { 'Content-Type': 'application/json' };

    return fetch(PORTFOLIO_SEGMENTATION_GROUP_BY_URL, {
        method: 'GET',
        credentials: 'include',
        headers: defaultHttpHeaders,
    });
}

export function downloadPropertiesCSV(
    tab,
    { attributes = [], entityCodes = null, period } = {},
) {
    const httpHeaders = {
        'Content-Type': 'application/json',
        Accept: 'text/csv',
    };
    if (tab === PROPERTY_VALUE_TITLE) {
        return getPropertyValues({ entityCodes, httpHeaders, period });
    }
    return getPropertyAttributes({
        period,
        attributes,
        entityCodes,
        httpHeaders,
    });
}
