import { PDFExportable } from 'waypoint-utils/pdf/PDFExportable';
import React, { useImperativeHandle } from 'react';

interface NarrativeTextEntityProps {
    widgetId: string;
    widgetType: string;
}

export const NarrativeEntityReportWidget = React.forwardRef<
    PDFExportable,
    NarrativeTextEntityProps
>(({ widgetId, widgetType }: NarrativeTextEntityProps, ref) => {
    const widgetExportId = `narrativePDFWrapper_${widgetType}_${widgetId}`;

    useImperativeHandle(ref, () => ({
        isReadyToExport(): boolean {
            return true;
        },
    }));

    return <div id={widgetExportId} />;
});
