import { API_URL } from 'config/constants';
import { ReportMetadata } from 'waypoint-types';

const getAllReportMetadataByEntityCodes = async (
    entityCodes: string[],
    reportType: string
): Promise<ReportMetadata[]> => {
    const response = await fetch(
        `${API_URL}/report-metadata/search/entity-codes`,
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({ entityCodes, reportType }),
        }
    );

    if (!response.ok) {
        throw new Error('Failed to get report metadata');
    }

    const { data } = await response.json();

    return data as ReportMetadata[];
};

export default getAllReportMetadataByEntityCodes;
