import { API_URL } from 'config/constants';
import { CapitalProject } from 'waypoint-types';

interface GetCapitalProjectParams {
    entity_codes: string[];
}

const getCapitalProjects = async (
    body: GetCapitalProjectParams
): Promise<CapitalProject[]> => {
    const response = await fetch(`${API_URL}/capital-project`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
    });

    if (!response.ok) {
        throw new Error('FAILED');
    }

    const { data } = await response.json();
    return data as CapitalProject[];
};

export default getCapitalProjects;
