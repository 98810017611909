import React, { ReactNode } from 'react';
import { css } from 'emotion';
import { Checkbox as AntDesignCheckbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

const checkBoxText = css`
    .ant-checkbox-input {
        float: left;
        margin: 0;
        width: 0;
        padding: 0;
    }
`;

interface CheckboxProps {
    checked?: boolean;
    onChange?: (e: CheckboxChangeEvent) => void;
    children?: ReactNode;
}

const Checkbox: React.FC<CheckboxProps> = ({
    checked = false,
    onChange,
    children,
}) => {
    return (
        <AntDesignCheckbox
            className={checkBoxText}
            checked={checked}
            onChange={onChange}
        >
            {children}
        </AntDesignCheckbox>
    );
};

export default Checkbox;
