import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import DataGrid, {
    GroupItem,
    SearchPanel,
    ColumnChooser,
    Export,
    Paging,
    FilterRow,
    HeaderFilter,
    Pager,
    StateStoring,
    Grouping,
    GroupPanel,
    FilterPanel,
    Summary,
    TotalItem,
    Toolbar,
    Item,
    Editing,
    Column,
    DataGridRef,
    ColumnChooserSelection,
    Position,
} from 'devextreme-react/data-grid';
import { ExportingEvent, InitializedEvent } from 'devextreme/ui/data_grid';
import {
    exportExcelFromDevExtremeDataGrid,
    onContentReady,
} from 'waypoint-utils';
import {
    applyStoredConfiguration,
    createSavedConfigurationPayload,
} from 'components/saved-configurations';
import { ExpandAndCollapseButton } from 'waypoint-react';
import {
    AttributeData,
    AttributeTypes,
    DATA_GRID_HEIGHT_OFFSET,
    dataGridStyles,
    AttributeGridRowValueTypes,
    reservedDataGridAttributesColumns,
    formatValue,
    prepareAttributeValuesPayload,
    getAttributesColumn,
} from './utils';
import {
    AttributeDefinition,
    AttributeValue,
    Entity,
    SavedConfiguration,
    SavedConfigurationState,
} from 'waypoint-types';
import createOrUpdateEntityAttributeValues, {
    UpdateEntityAttributeValuesParams,
} from 'waypoint-requests/attributes/createOrUpdateEntityAttributeValues';
import DataSource from 'devextreme/data/data_source';
import { message } from 'antd';
import { cloneDeep } from 'lodash';
import { GetAttributesV2Response } from 'waypoint-requests/attributes/getAttributesV2';
import { KeyedMutator } from 'swr';
import { AttributeFromAPI } from 'contexts';

interface AttributesDataGridProps {
    isEditMode: boolean;
    setIsEditMode: (value: boolean) => void;
    selectedConfiguration: SavedConfiguration | null;
    configKey: string | null;
    setGridConfig: React.Dispatch<
        React.SetStateAction<{
            [x: string]: any;
        } | null>
    >;
    attributes: AttributeDefinition[];
    entities: Partial<Entity>[];
    entityCodes: string[];
    mutateAttributes: KeyedMutator<GetAttributesV2Response>;
    triggerSaveAction: boolean;
    setTriggerSaveAction: React.Dispatch<React.SetStateAction<boolean>>;
    triggerCancelAction: boolean;
    setTriggerCancelAction: React.Dispatch<React.SetStateAction<boolean>>;
}

type GroupedAttributeColumns = Record<string, AttributeFromAPI[]>;

export const AttributesDataGrid = ({
    isEditMode,
    setIsEditMode,
    selectedConfiguration,
    configKey,
    setGridConfig,
    attributes,
    entities,
    entityCodes,
    mutateAttributes,
    triggerSaveAction,
    setTriggerSaveAction,
    triggerCancelAction,
    setTriggerCancelAction,
}: AttributesDataGridProps) => {
    const dataGridRef = useRef<DataGridRef<AttributeDefinition, string>>(null);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [expandButtonEnable, setExpandButtonEnable] =
        useState<boolean>(false);
    const [lastSavedAttributes, setLastSavedAttributes] = useState<
        AttributeData[]
    >([]);
    const [currentDataSource, setCurrentDataSource] = useState<
        DataSource | undefined
    >();
    const [attributesByCode, setAttributesByCode] = useState<
        Map<string, AttributeDefinition>
    >(new Map());

    // using ref state management to avoid triggering re-render
    const validationResultsRef = useRef<Map<string, boolean>>(new Map());
    const pendingUpdates = useRef(
        new Map<string, UpdateEntityAttributeValuesParams>(),
    );

    const WINDOW_HEIGHT = window.innerHeight;

    const onExporting = useCallback(async (e: ExportingEvent) => {
        await exportExcelFromDevExtremeDataGrid(e, {
            worksheetName: 'Attributes',
            filename: 'Attributes.xlsx',
        });
    }, []);

    const toggleExpanded = useCallback(() => {
        setExpanded(!expanded);
    }, [expanded]);

    const saveState = useCallback(
        (state: SavedConfigurationState) => {
            const config = createSavedConfigurationPayload(state);
            config && setGridConfig(config);
        },
        [setGridConfig],
    );

    /**
     * AttributesByCode map helps to quickly find an attribute.
     * Any mutation to attributes should be reflected for this map as well to trigger column re-render.
     */
    useEffect(() => {
        setAttributesByCode((prevMap) => {
            const updatedAttributeByCodeMap: Map<string, AttributeDefinition> =
                new Map(prevMap);

            attributes.forEach((attribute) => {
                const existingAttr = updatedAttributeByCodeMap.get(
                    attribute.attribute_code,
                );

                const allowedValuesHasChanged =
                    existingAttr?.allowed_values?.sort().join(',') !==
                    attribute.allowed_values?.sort().join(',');
                const isMultiselectHasChanged =
                    existingAttr?.is_multiselect !== attribute.is_multiselect;

                const attrValueIsUpdated =
                    !existingAttr?.attributeValues?.every(
                        (attributeValue: AttributeValue, index) =>
                            attribute.attributeValues &&
                            attributeValue.value ===
                                attribute.attributeValues[index]?.value,
                    );

                const attrHasNewValue =
                    existingAttr?.attributeValues?.length !==
                    attribute.attributeValues?.length;

                const attrHasNewFormatOrPrecision =
                    existingAttr?.format !== attribute.format ||
                    existingAttr.precision !== attribute.precision;

                const updateMap =
                    !existingAttr ||
                    attrHasNewValue ||
                    attrValueIsUpdated ||
                    attrHasNewFormatOrPrecision ||
                    allowedValuesHasChanged ||
                    isMultiselectHasChanged;

                if (updateMap) {
                    updatedAttributeByCodeMap.set(
                        attribute.attribute_code,
                        attribute,
                    );
                }
            });

            return updatedAttributeByCodeMap;
        });
    }, [attributes]);

    /**
     * This feeds the DataGrid DataSource but is not the actual DataSource.
     * All columns and rows of data are set here as attributes and entities change.
     */
    const getCurrentColumnsAndData = useMemo((): {
        columns: GroupedAttributeColumns;
        data: AttributeData[];
    } => {
        const groupedColumns: GroupedAttributeColumns =
            attributes.reduce<GroupedAttributeColumns>((acc, attribute) => {
                if (!acc[attribute.category]) {
                    acc[attribute.category] = [];
                }

                acc[attribute.category].push({
                    title: attribute.name,
                    key: attribute.attribute_code.toLowerCase(),
                    dataIndex: attribute.attribute_code.toLowerCase(),
                    category: attribute.category,
                });

                return acc;
            }, {});

        const columns = {
            ...groupedColumns,
            '': [
                {
                    title: 'Property Code',
                    key: 'entity_display_code',
                    dataIndex: 'entity_display_code',
                    category: '',
                },
                {
                    title: 'Entity Code', // not displayed, useful to track an entity
                    key: 'entity_code',
                    dataIndex: 'entity_code',
                    category: '',
                },
                {
                    title: 'Property',
                    key: 'name',
                    dataIndex: 'name',
                    category: '',
                },
            ],
        };

        const entitiesByEntityCode: Map<string, Partial<Entity>> = new Map();
        for (const entity of entities) {
            entitiesByEntityCode.set(entity.entityCode as string, entity);
        }

        const data: AttributeData[] = entityCodes.map((entityCode) => {
            const entity = entitiesByEntityCode.get(entityCode);

            const row: AttributeData = {
                key: entity?.displayCode ?? '', // for DevExtreme KeyExpr purposes
                entity_display_code: entity?.displayCode ?? '',
                entity_code: entity?.entityCode ?? '',
                name: entity?.displayName ?? '',
            };

            attributes.forEach((attribute: AttributeDefinition) => {
                const dataIndex = attribute.attribute_code.toLowerCase();

                if (!attribute.is_multiselect) {
                    const attributeValue: AttributeValue | undefined =
                        attribute.attributeValues?.find(
                            (value) => value.entity_code === entityCode,
                        );

                    if (attribute.type === AttributeTypes.boolean.value) {
                        row[dataIndex] = attributeValue?.value === 'true';
                    }

                    if (attribute.type !== AttributeTypes.boolean.value) {
                        row[dataIndex] = attributeValue?.value ?? '';
                    }
                }

                if (attribute.is_multiselect) {
                    row[dataIndex] =
                        attribute.attributeValues
                            ?.filter(
                                (value) => value.entity_code === entityCode,
                            )
                            .map((attributeValue) => attributeValue.value) ||
                        [];
                }
            });

            return row;
        });

        return { columns, data };
    }, [attributes, entities, entityCodes]);

    useEffect(() => {
        const { data } = getCurrentColumnsAndData;
        setCurrentDataSource(new DataSource(cloneDeep(data)));
    }, [getCurrentColumnsAndData]);

    const setCellAndPendingUpdateValue = (
        newRowData: AttributeData,
        value: AttributeGridRowValueTypes,
        currentRowData: AttributeData,
        attribute?: AttributeDefinition,
    ) => {
        if (!attribute) {
            return;
        }

        if (
            reservedDataGridAttributesColumns.includes(attribute.attribute_code)
        ) {
            return;
        }

        // this updates the data in the grid
        newRowData[attribute.attribute_code] =
            formatValue(
                value,
                false,
                attribute.type,
                attribute.format,
                attribute.precision,
                attribute.is_multiselect,
            ) ?? false;

        // once value is formatted, prepare payload for the endpoint
        const preparedPayload: UpdateEntityAttributeValuesParams =
            prepareAttributeValuesPayload(
                attribute,
                newRowData[attribute.attribute_code],
                currentRowData.entity_code as string,
            );

        // update the pending updates to be send when saving
        pendingUpdates.current.set(
            `${currentRowData.entity_code}_${attribute.attribute_code}`,
            preparedPayload,
        );
    };

    const gridColumns: JSX.Element[] = useMemo(() => {
        if (!currentDataSource) {
            return [];
        }

        const { columns } = getCurrentColumnsAndData;

        const groupedColumns: [string, AttributeFromAPI[]][] =
            Object.entries(columns);

        return (
            groupedColumns
                .sort(([groupA], [groupB]) => {
                    if (groupA === 'Property') return -1;
                    if (groupB === 'Property') return 1;

                    const configColumns =
                        selectedConfiguration?.filters_json?.grid_config
                            ?.columns ?? {};

                    const groupAVisibleIndex =
                        configColumns[groupA]?.visibleIndex;
                    const groupBVisibleIndex =
                        configColumns[groupB]?.visibleIndex;

                    if (
                        groupAVisibleIndex === undefined ||
                        groupBVisibleIndex === undefined
                    ) {
                        return groupA.localeCompare(groupB);
                    }

                    return groupAVisibleIndex > groupBVisibleIndex ? 1 : -1;
                })
                .map(([group, columns]: [string, AttributeFromAPI[]]) => {
                    return (
                        <Column
                            caption={group}
                            key={group}
                            fixed={group === ''}
                            alignment="center"
                            name={group}
                        >
                            {columns
                                ?.filter(
                                    (column: AttributeFromAPI) =>
                                        column.key !== 'entity_code',
                                )
                                .map((column) => {
                                    return getAttributesColumn({
                                        key: column.key,
                                        column,
                                        attributesByCode,
                                        selectedConfiguration,
                                        setCellAndPendingUpdateValue,
                                        isEditMode,
                                    });
                                })}
                        </Column>
                    );
                }) ?? []
        );
    }, [
        getCurrentColumnsAndData,
        selectedConfiguration,
        attributesByCode,
        currentDataSource,
        isEditMode,
    ]);

    const saveAttributes = useCallback(async () => {
        try {
            const newAttributeValues =
                await createOrUpdateEntityAttributeValues(
                    Array.from(pendingUpdates.current.values()),
                );

            await mutateAttributes(
                (currentData: GetAttributesV2Response | undefined) => {
                    if (!currentData) {
                        return undefined;
                    }

                    const updatedAttributeDefinitions =
                        currentData.attributeDefinitions?.map(
                            (attributeDefinition) => {
                                // Find updated values for the current AttributeDefinition
                                const attributeUpdatedValues =
                                    newAttributeValues.filter(
                                        (newValue) =>
                                            newValue.attribute_definition_id ===
                                            attributeDefinition.id,
                                    );

                                const updatedAttributeValueIds = new Set(
                                    attributeUpdatedValues.map(
                                        (updatedValue) => updatedValue.id,
                                    ),
                                );

                                const deletedAttributeValueIds = Array.from(
                                    pendingUpdates.current.values(),
                                ).flatMap(
                                    (update) =>
                                        update.deleted_attribute_value_ids ||
                                        [],
                                );

                                // filter in non-deleted attribute values, and non-updated attribute values
                                const remainingAttributeValues =
                                    attributeDefinition.attributeValues?.filter(
                                        (existingValue) => {
                                            const isNotDeleted =
                                                !deletedAttributeValueIds.includes(
                                                    existingValue.id,
                                                );
                                            const isNotUpdated =
                                                !updatedAttributeValueIds.has(
                                                    existingValue.id,
                                                );
                                            return isNotDeleted && isNotUpdated;
                                        },
                                    );

                                return {
                                    ...attributeDefinition,
                                    attributeValues: [
                                        ...(remainingAttributeValues || []),
                                        ...(attributeUpdatedValues || []),
                                    ],
                                };
                            },
                        );

                    return {
                        ...currentData,
                        attributeDefinitions: updatedAttributeDefinitions,
                    };
                },
                false, // use cache and avoid server revalidation
            );

            setIsEditMode(false);
            message.success(`Attributes updated successfully`);
        } catch (e) {
            message.error(`Failed to update attributes`);
        } finally {
            pendingUpdates.current.clear();
        }
    }, [mutateAttributes, setIsEditMode]);

    const handleOnEditSaveClick = useCallback(async () => {
        if (!isEditMode) {
            const { data } = getCurrentColumnsAndData;
            setLastSavedAttributes(cloneDeep(data));
            setIsEditMode(true);
            return;
        }

        const dataGridInstance = dataGridRef.current?.instance();

        if (dataGridInstance) {
            await dataGridInstance.saveEditData();
        }

        const allRowsValid = Array.from(
            validationResultsRef.current.values(),
        ).every((isValid) => isValid);

        if (!allRowsValid) {
            message.error(
                'Some rows have validation errors. Please fix them before saving.',
            );
            return;
        }

        saveAttributes();
    }, [getCurrentColumnsAndData, isEditMode, saveAttributes, setIsEditMode]);

    // Triggered for each row separately when dataGridInstance.saveEditData() is executed
    const handleOnRowValidating = (event: {
        key: string;
        isValid: boolean;
    }) => {
        const rowKey = event.key;
        validationResultsRef.current.set(rowKey, event.isValid);
    };

    const handleCancel = useCallback(() => {
        setIsEditMode(false);
        setCurrentDataSource(new DataSource(cloneDeep(lastSavedAttributes)));
        pendingUpdates.current.clear();
    }, [setIsEditMode, lastSavedAttributes]);

    useEffect(() => {
        if (triggerSaveAction) {
            handleOnEditSaveClick();
        }
        setTriggerSaveAction(false);
    }, [triggerSaveAction, handleOnEditSaveClick, setTriggerSaveAction]);

    useEffect(() => {
        if (triggerCancelAction) {
            handleCancel();
        }
        setTriggerCancelAction(false);
    }, [triggerCancelAction, handleCancel, setTriggerCancelAction]);

    return useMemo(
        () => (
            <DataGrid
                ref={dataGridRef}
                key={`${selectedConfiguration?.id ?? ''}_${configKey ?? ''}`}
                keyExpr={'key'}
                dataSource={currentDataSource}
                height={WINDOW_HEIGHT - DATA_GRID_HEIGHT_OFFSET}
                elementAttr={{
                    id: 'gridContainer',
                }}
                onRowValidating={handleOnRowValidating}
                className={dataGridStyles}
                onExporting={onExporting}
                allowColumnResizing={true}
                columnResizingMode="widget"
                allowColumnReordering={true}
                showRowLines={true}
                showBorders={true}
                scrolling={{ mode: 'standard' }}
                showColumnLines={true}
                hoverStateEnabled={true}
                onContentReady={(e) =>
                    onContentReady({
                        e,
                        toggleFunc: setExpandButtonEnable,
                    })
                }
                onInitialized={(e: InitializedEvent) => {
                    if (selectedConfiguration && e?.component) {
                        e.component.clearSorting();
                        applyStoredConfiguration(
                            e.component,
                            selectedConfiguration,
                        );
                    }
                }}
            >
                <Editing
                    mode="batch"
                    allowUpdating={isEditMode}
                    selectTextOnEditStart={true}
                    startEditAction="click"
                />
                <Toolbar>
                    <Item location="after" name="columnChooserButton" />
                    <Item name="exportButton" />
                    <Item name="searchPanel" />
                    <Item name="groupPanel" />
                    <Item location="before">
                        <ExpandAndCollapseButton
                            expanded={expanded}
                            toggleExpanded={toggleExpanded}
                            expandButtonEnable={expandButtonEnable}
                        />
                    </Item>
                </Toolbar>
                <HeaderFilter
                    allowSelectAll={true}
                    visible={true}
                    allowSearch={true}
                    height={500}
                />
                <FilterRow visible={true} />
                <FilterPanel visible={true} />

                <Grouping autoExpandAll={expanded} contextMenuEnabled={true} />
                <GroupPanel visible={'auto'} />

                {currentDataSource ? gridColumns : null}

                <Summary>
                    <TotalItem
                        column="Property"
                        summaryType="count"
                        valueFormat={{
                            type: 'fixedPoint',
                            precision: 0,
                        }}
                        displayFormat="{0} Properties"
                    />
                    <GroupItem
                        column="Property"
                        summaryType="count"
                        alignByColumn={false}
                        showInGroupFooter={false}
                        displayFormat="{0} Properties"
                    />
                </Summary>
                <Paging enabled={true} />
                <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[20, 40, 60, 80, 100]}
                    showNavigationButtons={true}
                    showInfo={true}
                    infoText="Page {0} of {1} ({2} items)"
                />

                <SearchPanel visible={true} highlightCaseSensitive={false} />

                <Export enabled={true} allowExportSelectedData={false} />
                <ColumnChooser
                    enabled={true}
                    mode={'select'}
                    height={500}
                    sortOrder={'asc'}
                    allowSearch={true}
                >
                    <Position
                        my="right top"
                        at="right bottom"
                        of=".dx-datagrid-column-chooser-button"
                    />
                    <ColumnChooserSelection
                        allowSelectAll={true}
                        recursive={true}
                    />
                </ColumnChooser>
                <StateStoring
                    enabled={true}
                    savingTimeout={100}
                    type="custom"
                    customSave={saveState}
                />
            </DataGrid>
        ),
        [
            WINDOW_HEIGHT,
            configKey,
            currentDataSource,
            expandButtonEnable,
            gridColumns,
            isEditMode,
            expanded,
            selectedConfiguration,
            dataGridRef,
            onExporting,
            saveState,
            toggleExpanded,
        ],
    );
};
