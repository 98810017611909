import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { ClientMode } from 'waypoint-types';
import useSWR from 'swr';
import { getClientModes } from 'waypoint-requests';

export const useGetClientModes = (): DataHookResponse<ClientMode[]> => {
    const { data, error, mutate } = useSWR(`/client/modes`, getClientModes);

    return {
        isLoading: !data,
        isError: !!error,
        data,
        mutate,
    };
};
