import React from 'react';
import { Select } from 'antd';
import { SelectOptionType } from 'components/app/global-filter-drawer/types/globalFilterTypes';
import {
    propertyDetailsValues,
    PropertyDetailsValuesType,
    KPISortByOptions,
    KPISortDirections,
} from 'components/leases/constants';
import { Dictionary } from 'ts-essentials';

interface ComparisonKpiSortBySelectProps {
    sortAxis: KPISortByOptions;
    sortDirection: KPISortDirections;
    leftAxis: keyof PropertyDetailsValuesType;
    rightAxis: keyof PropertyDetailsValuesType;
    setSortAxis: (value: KPISortByOptions) => void;
    setSortDirection: (value: KPISortDirections) => void;
}

interface KPISortOptions {
    axis: KPISortByOptions;
    direction: KPISortDirections;
}

const ComparisonKpiSortBySelect = ({
    sortAxis,
    sortDirection,
    leftAxis,
    rightAxis,
    setSortAxis,
    setSortDirection,
}: ComparisonKpiSortBySelectProps) => {
    const selectedLeftAxis = propertyDetailsValues[leftAxis];
    const selectedRightAxis = propertyDetailsValues[rightAxis];

    const sortStringBuilder = (
        axis: KPISortByOptions,
        direction: KPISortDirections
    ): string => {
        return `${axis}_${direction}`;
    };

    const mappedSortParams: Dictionary<KPISortOptions> = {
        alpha_asc: {
            axis: KPISortByOptions.ALPHABETICAL,
            direction: KPISortDirections.ASCENDING,
        },
        left_asc: {
            axis: KPISortByOptions.LEFT_AXIS,
            direction: KPISortDirections.ASCENDING,
        },
        left_desc: {
            axis: KPISortByOptions.LEFT_AXIS,
            direction: KPISortDirections.DESCENDING,
        },
        right_asc: {
            axis: KPISortByOptions.RIGHT_AXIS,
            direction: KPISortDirections.ASCENDING,
        },
        right_desc: {
            axis: KPISortByOptions.RIGHT_AXIS,
            direction: KPISortDirections.DESCENDING,
        },
    };

    const sortOptions: SelectOptionType[] = [
        {
            value: sortStringBuilder(
                KPISortByOptions.ALPHABETICAL,
                KPISortDirections.ASCENDING
            ),
            label: 'Alphabetical',
        },
        {
            value: sortStringBuilder(
                KPISortByOptions.LEFT_AXIS,
                KPISortDirections.ASCENDING
            ),
            label: `${selectedLeftAxis} (ascending)`,
        },
        {
            value: sortStringBuilder(
                KPISortByOptions.LEFT_AXIS,
                KPISortDirections.DESCENDING
            ),
            label: `${selectedLeftAxis} (descending)`,
        },
        {
            value: sortStringBuilder(
                KPISortByOptions.RIGHT_AXIS,
                KPISortDirections.ASCENDING
            ),
            label: `${selectedRightAxis} (ascending)`,
        },
        {
            value: sortStringBuilder(
                KPISortByOptions.RIGHT_AXIS,
                KPISortDirections.DESCENDING
            ),
            label: `${selectedRightAxis} (descending)`,
        },
    ];

    return (
        <div style={{ display: 'inline-block', alignItems: 'baseline' }}>
            <span>Sort By</span>
            <Select
                style={{ width: 400, marginLeft: 6, marginTop: 10 }}
                options={sortOptions}
                value={sortStringBuilder(sortAxis, sortDirection)}
                onChange={(item) => {
                    setSortAxis(mappedSortParams[item].axis);
                    setSortDirection(mappedSortParams[item].direction);
                }}
            />
        </div>
    );
};

export default ComparisonKpiSortBySelect;
