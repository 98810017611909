import { isEqual } from 'lodash';
import memoizeOne from 'memoize-one';
import { AccountGraphNode } from 'waypoint-types';

const getAccountMappingCodes = (accountGraph: AccountGraphNode[]) => {
    const values: string[] = [];
    const traverse = (collection: AccountGraphNode[]) => {
        collection.forEach((node) => {
            if (node.children) {
                values.push(node.account_mapping_code);
                traverse(node.children);
            }
        });
    };
    traverse(accountGraph);
    return values;
};

const getAllParentAccountMappingCodes = memoizeOne(
    getAccountMappingCodes,
    isEqual
);
export default getAllParentAccountMappingCodes;
