import React, { ReactElement } from 'react';
import { css } from 'emotion';

import { AccountingAsOfDateType } from 'components/app/as-of-date';
import { toCalendarDate } from 'components/dates/utils';
import { DASH_DASH } from 'config/constants';

const modeLabel = css`
    font-weight: bold;
`;

interface AccountAsOfDateLabelProps {
    key: string;
    date: AccountingAsOfDateType;
}

export function AccountAsOfDateLabel({
    key,
    date,
}: AccountAsOfDateLabelProps): ReactElement {
    return (
        <div key={key}>
            <div className={modeLabel}>{date.label}</div>
            <div data-testid-message>
                Available through&nbsp;
                {date.period_end ? toCalendarDate(date.period_end) : DASH_DASH}
                ;&nbsp;updated on&nbsp;
                {date.last_loaded_at
                    ? toCalendarDate(date.last_loaded_at)
                    : DASH_DASH}
            </div>
        </div>
    );
}
