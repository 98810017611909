import { API_URL } from 'config/constants';
import { EntityProfileCardYearMetadata } from 'waypoint-types/properties';

export async function updateEntityProfileCardYearMetadata(
    entityCode: string,
    year: number,
    yearMetadata: EntityProfileCardYearMetadata,
    type: string
) {
    const response = await fetch(
        `${API_URL}/properties/${entityCode}/profile-cards/year-metadata/${type}/${year}`,
        {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({
                metadata: yearMetadata.metadata,
            }),
        }
    );

    if (!response.ok) {
        throw new Error('Failed to update profile card year metadata');
    }

    return response;
}
