import { Input, InputRef, message, Modal } from 'antd';
import { MetadataRequestParams } from 'components/notes/TableNotes';
import { Note, NoteReferenceType } from 'waypoint-types';
import { KeyedMutator } from 'swr';
import { updateNote, createNote } from 'waypoint-requests';
import { useEffect, useRef } from 'react';

interface FinancialTableNoteModalParams {
    title: string;
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
    noteId: string | null;
    setNoteId: (value: string | null) => void;
    accountMappingId: string;
    setAccountMappingId: (value: string | null) => void;
    text: string;
    setText: (value: string) => void;
    metadataParams: MetadataRequestParams | null;
    mutateReportNotes: KeyedMutator<Note[]>;
}

const FinancialTableNoteModal = ({
    noteId,
    setNoteId,
    text,
    setText,
    title,
    isVisible,
    setIsVisible,
    accountMappingId,
    setAccountMappingId,
    metadataParams,
    mutateReportNotes,
}: FinancialTableNoteModalParams): JSX.Element => {
    const inputRef = useRef<InputRef>(null);

    useEffect(() => {
        inputRef.current?.focus({
            cursor: text.length ? 'end' : 'start',
        });
    }, [isVisible]);

    if (!metadataParams) {
        return <></>;
    }

    const params = {
        ...metadataParams,
        noteReferenceId: accountMappingId,
        noteReferenceType: NoteReferenceType.AccountMapping,
        text,
    };

    const resetParams = () => {
        setText('');
        setNoteId(null);
        setAccountMappingId(null);
        setIsVisible(false);
    };

    const onSubmitForm = async () => {
        try {
            if (noteId) {
                await onUpdateNote();
            } else {
                await onCreateNote();
            }
        } catch (e) {
            message.error(
                `Request Failed! ${
                    noteId
                        ? 'An error occurred while editing note'
                        : 'An error occurred while adding note'
                }`,
            );
        }

        resetParams();
    };

    const onUpdateNote = async () => {
        if (!noteId) {
            return;
        }

        await mutateReportNotes(
            async (reportNotes) => {
                if (!reportNotes) {
                    return;
                }

                const noteToUpdate = reportNotes.find((n) => n.id === noteId);

                if (!noteToUpdate) {
                    return;
                }

                const updatedReportNotes: Note[] = [
                    ...reportNotes.filter((n) => n.id !== noteToUpdate.id),
                    {
                        ...noteToUpdate,
                        text,
                    },
                ];

                try {
                    await updateNote(noteId, text);
                } catch (e) {
                    message.error('Failed to update note.');
                }

                return updatedReportNotes;
            },
            {
                rollbackOnError: true,
            },
        );
    };

    const onCreateNote = async () => {
        await mutateReportNotes(
            async (reportNotes) => {
                if (!reportNotes) {
                    return;
                }

                try {
                    const newNote = await createNote(params);

                    return [...reportNotes, newNote];
                } catch (e) {
                    message.error('Failed to update note.');
                }

                return [...reportNotes];
            },
            {
                rollbackOnError: true,
            },
        );
    };

    const handleTextChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        setText(event.target.value);
    };

    return isVisible ? (
        <Modal
            key={noteId}
            title={title}
            open
            okText={'Save'}
            onOk={onSubmitForm}
            okButtonProps={text.length === 0 ? { disabled: true } : {}}
            onCancel={resetParams}
        >
            <Input.TextArea
                value={text}
                onChange={handleTextChange}
                showCount
                rows={5}
                maxLength={500}
                ref={inputRef}
                style={{ margin: '16px 0' }}
            />
        </Modal>
    ) : (
        <></>
    );
};

export default FinancialTableNoteModal;
