import { moneyFormatter } from 'utils/formatters';
import {
    currencyRenderer,
    integerRenderer,
    percentageRenderer,
} from 'utils/tables/renderers';
import { LeasingGuideline } from 'waypoint-types';
import {
    ColumnDescriptor,
    convertColumnsToAntd,
    headerClass,
    rowClass,
} from './GridExportConversionUtils';
import { Table } from 'antd';

const leasingCommissionsRenderer = (
    value: string,
    record: LeasingGuideline
) => {
    if (record.leasing_commissions == null) {
        return '';
    }
    if (record.leasing_commissions_type === '%') {
        const percentage = Number(record.leasing_commissions * 100).toFixed(2);
        return `${percentage}%`;
    }
    return `${moneyFormatter(record.leasing_commissions)}`;
};

const marketCompsRenderer = (value: string, record: LeasingGuideline) => {
    const low = record.market_comps_low ? `$${record.market_comps_low}` : '';
    const high = record.market_comps_high ? `$${record.market_comps_high}` : '';
    const separator =
        record.market_comps_low && record.market_comps_high ? ' - ' : '';
    return `${low}${separator}${high}`;
};

export const leasingGuidelinesBaseColumns: ColumnDescriptor[] = [
    {
        title: 'Guideline',
        dataIndex: 'guideline',
        key: 'guideline',
        align: 'left',
    },
    {
        title: 'Lease Type',
        dataIndex: 'type',
        key: 'type',
        align: 'center',
    },
    {
        title: 'Starting Rent',
        dataIndex: 'starting_rent',
        key: 'starting_rent',
        dataType: 'number',
        render: currencyRenderer,
        align: 'center',
    },
    {
        title: 'Escalations (%)',
        dataIndex: 'escalations',
        key: 'escalations',
        dataType: 'number',
        render: percentageRenderer,
        align: 'center',
    },
    {
        title: 'Term (Mos)',
        dataIndex: 'term_months',
        key: 'term_months',
        dataType: 'number',
        render: integerRenderer,
        align: 'center',
    },
    {
        title: 'Concessions (Mos)',
        dataIndex: 'concessions_months',
        key: 'concessions_months',
        dataType: 'number',
        render: integerRenderer,
        align: 'center',
    },
    {
        title: 'LC ($ or %)',
        dataIndex: 'leasing_commissions',
        key: 'leasing_commissions',
        dataType: 'number',
        render: leasingCommissionsRenderer,
        align: 'center',
    },
    {
        title: 'TI PY',
        dataIndex: 'tenant_improvements_per_year',
        key: 'tenant_improvements_per_year',
        dataType: 'number',
        render: currencyRenderer,
        align: 'center',
    },
    {
        title: 'Market Comps',
        dataIndex: 'market_comps_low',
        key: 'market_comps_low',
        dataType: 'number',
        render: marketCompsRenderer,
        align: 'center',
    },
];

export const LeasingGuidelineExportableGrid = ({
    data,
}: {
    data: LeasingGuideline[];
}) => {
    const { gridColumns } = convertColumnsToAntd(
        leasingGuidelinesBaseColumns,
        true,
        null
    );

    return (
        <Table
            size="small"
            dataSource={data}
            columns={gridColumns}
            pagination={false}
            bordered={true}
            className={headerClass}
            rowClassName={rowClass}
        />
    );
};
