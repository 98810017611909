import React from 'react';
import {
    ImageProps,
    LocalReportSettings,
    Report,
    Settings,
} from 'waypoint-types';
import { Checkbox, Select, Row, Col, Card } from 'antd';
import {
    useGetDocumentUploadListByReferenceParams,
    useGetReportSettings,
    useGetUserData,
} from 'waypoint-hooks';
import { WidgetTypes } from '../../constants';
import { ImagePicker } from 'waypoint-react';
import { css } from 'emotion';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

interface ReportGeneralSettingsProps {
    report?: Report;
    setSettings: (type: string, properties: Settings) => void;
    settings: LocalReportSettings;
    setEnabledSetting: (type: string, enabled: boolean) => void;
}

const cardHeight = window.innerHeight - 440;
const cardBodyInnerStyle = css`
    .ant-card-body {
        height: ${cardHeight}px;
    }
`;

const alignStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};

export const ReportGeneralSettings = ({
    setSettings,
    settings,
    setEnabledSetting,
}: ReportGeneralSettingsProps) => {
    const { data } = useGetReportSettings();
    const { clientId } = useGetUserData();

    const { data: documentsUploaded } =
        useGetDocumentUploadListByReferenceParams(
            'company_logo',
            `${clientId}`
        );

    const defaultIncludeFooterSettings =
        settings[WidgetTypes.Footer]?.enabled ?? false;

    const defaultIncludeCoverPage =
        settings[WidgetTypes.CoverPage]?.enabled ?? false;

    const handleImageChange = (image: ImageProps[]) => {
        setSettings(WidgetTypes.Footer, {
            document_upload_id: image[0].value ?? '',
        });
    };

    const onChangeIncludeCoverPage = (e: CheckboxChangeEvent) => {
        const isEnabled = e.target.checked;
        setEnabledSetting(WidgetTypes.CoverPage, isEnabled);
        !isEnabled && setEnabledSetting(WidgetTypes.TableOfContents, isEnabled);
    };

    return (
        <Card className={cardBodyInnerStyle}>
            <h2>Cover Page Settings</h2>

            <Row gutter={24}>
                <Col span={12}>
                    <div>
                        <div style={alignStyle}>
                            <Checkbox
                                defaultChecked={defaultIncludeCoverPage}
                                onChange={onChangeIncludeCoverPage}
                            >
                                Include Cover Page
                            </Checkbox>
                            <div style={alignStyle}>
                                <div>
                                    <Select
                                        disabled={
                                            !settings[WidgetTypes.CoverPage]
                                                ?.enabled
                                        }
                                        style={{
                                            width: '125px',
                                            marginRight: '12px',
                                        }}
                                        size={'small'}
                                        defaultValue={
                                            settings[WidgetTypes.CoverPage]
                                                ?.settings?.paperOrientation ??
                                            data?.paperOrientation[0].value
                                        }
                                        options={data?.paperOrientation ?? []}
                                        onChange={(value) => {
                                            if (value) {
                                                setSettings(
                                                    WidgetTypes.CoverPage,
                                                    { paperOrientation: value }
                                                );
                                            }
                                        }}
                                    />
                                </div>
                                <div>
                                    <Select
                                        disabled={
                                            !settings[WidgetTypes.CoverPage]
                                                ?.enabled
                                        }
                                        style={{
                                            width: '125px',
                                        }}
                                        size={'small'}
                                        defaultValue={
                                            settings[WidgetTypes.CoverPage]
                                                ?.settings?.paperSize ??
                                            data?.paperSize[0].value
                                        }
                                        options={data?.paperSize ?? []}
                                        onChange={(value) => {
                                            if (value) {
                                                setSettings(
                                                    WidgetTypes.CoverPage,
                                                    { paperSize: value }
                                                );
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            border: '1px solid #f0f0f0',
                            padding: 16,
                            marginTop: '12px',
                            borderRadius: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            opacity: settings[WidgetTypes.CoverPage]?.enabled
                                ? 1
                                : 0.5,
                            pointerEvents: settings[WidgetTypes.CoverPage]
                                ?.enabled
                                ? 'auto'
                                : 'none',
                            cursor: settings[WidgetTypes.CoverPage]?.enabled
                                ? 'auto'
                                : 'not-allowed',
                        }}
                    >
                        <div style={{ marginTop: '6px' }}>
                            <Checkbox disabled checked>
                                Report Name
                            </Checkbox>
                        </div>
                        <div style={{ marginTop: '6px' }}>
                            <Checkbox disabled checked>
                                Address
                            </Checkbox>
                        </div>
                        <div style={{ marginTop: '6px' }}>
                            <Checkbox disabled checked>
                                Property Name
                            </Checkbox>
                        </div>
                        <div style={{ marginTop: '6px' }}>
                            <Checkbox
                                defaultChecked={Boolean(
                                    settings[WidgetTypes.CoverPage]?.settings
                                        ?.propertyPhoto
                                )}
                                onChange={(e) =>
                                    setSettings(WidgetTypes.CoverPage, {
                                        propertyPhoto: e.target.checked,
                                    })
                                }
                            >
                                Property Photo
                            </Checkbox>
                        </div>
                        <div style={{ marginTop: '6px' }}>
                            <Checkbox
                                defaultChecked={Boolean(
                                    settings[WidgetTypes.CoverPage]?.settings
                                        ?.dueDate
                                )}
                                onChange={(e) =>
                                    setSettings(WidgetTypes.CoverPage, {
                                        dueDate: e.target.checked,
                                    })
                                }
                            >
                                Due Date
                            </Checkbox>
                        </div>
                        <div style={{ marginTop: '6px' }}>
                            <Checkbox
                                defaultChecked={Boolean(
                                    settings[WidgetTypes.CoverPage]?.settings
                                        ?.assignees
                                )}
                                onChange={(e) =>
                                    setSettings(WidgetTypes.CoverPage, {
                                        assignees: e.target.checked,
                                    })
                                }
                            >
                                Assignees
                            </Checkbox>
                        </div>
                        <div style={{ marginTop: '6px' }}>
                            <Checkbox
                                defaultChecked={Boolean(
                                    settings[WidgetTypes.CoverPage]?.settings
                                        ?.reviewers
                                )}
                                onChange={(e) =>
                                    setSettings(WidgetTypes.CoverPage, {
                                        reviewers: e.target.checked,
                                    })
                                }
                            >
                                Reviewers
                            </Checkbox>
                        </div>
                    </div>
                    <div style={{ marginTop: '10px' }}>
                        <h2>Table of Contents</h2>
                        <Checkbox
                            disabled={!settings[WidgetTypes.CoverPage]?.enabled}
                            checked={Boolean(
                                settings[WidgetTypes.TableOfContents]?.enabled
                            )}
                            onChange={(e) =>
                                setEnabledSetting(
                                    WidgetTypes.TableOfContents,
                                    e.target.checked
                                )
                            }
                        >
                            Include Table of Contents
                        </Checkbox>
                    </div>
                </Col>
                <Col span={12}>
                    <div>
                        <div style={{ marginBottom: '12px' }}>
                            <Checkbox
                                disabled={documentsUploaded?.length === 0}
                                defaultChecked={defaultIncludeFooterSettings}
                                onChange={(value) => {
                                    setEnabledSetting(
                                        WidgetTypes.Footer,
                                        value.target.checked
                                    );
                                    handleImageChange([
                                        {
                                            value:
                                                documentsUploaded?.[0] &&
                                                value.target.checked
                                                    ? documentsUploaded?.[0]?.id
                                                    : null,
                                            src: null,
                                        },
                                    ]);
                                }}
                            >
                                Include Company Logo
                            </Checkbox>
                        </div>

                        <ImagePicker
                            defaultPicked={[
                                settings[
                                    WidgetTypes.Footer
                                ]?.settings?.document_upload_id?.toString() ??
                                    '',
                            ]}
                            images={
                                documentsUploaded?.map((image) => ({
                                    src: image.document_cloud_url,
                                    value: image.id,
                                })) ?? []
                            }
                            onPick={(image) => handleImageChange(image)}
                            multiple={false}
                            disabled={
                                !settings[WidgetTypes.Footer]?.enabled ||
                                documentsUploaded?.length === 0
                            }
                        />
                    </div>
                </Col>
            </Row>
        </Card>
    );
};

export default ReportGeneralSettings;
