import { API_URL } from 'config/constants';
import { EntityReportPublishingHistory } from 'waypoint-types';

interface GetEntityReportPublishingHistoryParams {
    entityCode: string;
    entityReportId: string;
}

const getEntityReportPublishingHistory = async ({
    entityCode,
    entityReportId,
}: GetEntityReportPublishingHistoryParams): Promise<EntityReportPublishingHistory> => {
    if (!entityReportId) {
        return {
            reportsMetadata: [],
            entityReportPublished: [],
            entityReportReviews: [],
        };
    }
    const response = await fetch(
        `${API_URL}/report-approvals/${entityCode}/${entityReportId}/published-entity-reports`,
        {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        }
    );

    if (!response.ok) {
        throw new Error('FAILED');
    }

    const { data } = await response.json();
    return data;
};

export default getEntityReportPublishingHistory;
