import React from 'react';
import { Button, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

interface DeleteFilterButtonProps {
    disabled?: boolean;
    handleDelete: () => void;
}

const DeleteFilterButton = ({
    disabled,
    handleDelete,
}: DeleteFilterButtonProps): JSX.Element => {
    return (
        <Popconfirm
            title="Are you sure you want to delete this filter?"
            zIndex={499999}
            okText="Yes"
            cancelText="Cancel"
            placement="bottom"
            onConfirm={handleDelete}
        >
            <Button disabled={disabled ?? false} style={{ float: 'right' }}>
                <DeleteOutlined />
                Delete
            </Button>
        </Popconfirm>
    );
};

export default DeleteFilterButton;
