import PropTypes from 'prop-types';
import React from 'react';
import fusioncharts from 'fusioncharts';
import charts from 'fusioncharts/fusioncharts.charts';
import ReactFC from 'react-fusioncharts';
import theme from 'config/theme';
import chartBuilder from 'components/charts/chartBuilder';
import {
    getBreakdownDataset,
    getBreakdownCategories,
} from 'components/analytics/balance-sheet-overview/charts/breakdown/utils';
import { displayTypeOptions } from 'components/analytics/financialOverview/menu/displayType/constants';

charts(fusioncharts);

const chartStyle = {
    yaxisname: 'Gross $',
    exportFileName: 'Account Breakdown',
    baseFontColor: theme.colors.grays.medium,
    xAxisNameFontColor: theme.colors.grays.medium,
    yAxisNameFontColor: theme.colors.grays.medium,
    useEllipsesWhenOverflow: '1',
    paletteColors: theme.colors.barCharts[0],
    exportFormats: 'PNG|PDF|JPG|SVG',
    maxLabelWidthPercent: '20',
    showToolTipShadow: '0',
    toolTipBorderColor: '#C1C1C1',
    tooltipPadding: '10',
    tooltipborderradius: '5',
    animation: '0',
};

const BreakdownChart = ({ data: balanceSheetBreakdown = [], displayType }) => {
    const metricLabel = (
        displayTypeOptions.find((x) => x.key === displayType) || {
            label: 'gross',
        }
    ).label;
    const chart = chartBuilder();
    chart.type('scrollBar2D');
    chart.height('70%');
    chart.width('100%');
    chart.style(chartStyle);
    chart.style({
        ...chartStyle,
        yaxisname: metricLabel,
    });
    chart.categories([
        { category: getBreakdownCategories(balanceSheetBreakdown) },
    ]);
    chart.dataset([{ data: getBreakdownDataset(balanceSheetBreakdown) }]);
    return (
        <div>
            <ReactFC
                {...chart.config}
                data-testid="component-breakdown-chart"
            />
        </div>
    );
};

BreakdownChart.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
};

export default BreakdownChart;
