import { useEffect, useState } from 'react';
import {
    applicationNeedsUpdate,
    saveApplicationVersion,
    updateApplicationVersion,
} from 'waypoint-utils';
import { clearCookie } from 'utils/cookie';
import { ONE_HOUR_REFRESH } from 'components/app/constants';
import { clientLoaded } from 'state/user/reducer';
import { useHistory } from 'react-router-dom';
import { ClientData } from 'waypoint-types';
import urlBuilder from '../utils/urlBuilder';
import { getHeartbeat } from '../waypoint-requests';

export const useAppIntervals = (clientObj: ClientData) => {
    const history = useHistory();

    const [authCheckInterval, setAuthCheckInterval] = useState<ReturnType<
        typeof setInterval
    > | null>(null);
    const [applicationVersionInterval, setApplicationVersionInterval] =
        useState<ReturnType<typeof setInterval> | null>(null);

    const unlistenToApplicationVersionUpdateRouteChanges = () => {
        history.listen(() => {
            if (!applicationNeedsUpdate()) {
                return;
            }
            updateApplicationVersion();
            // Refresh the application and try to blow away the cache.
            window.location.reload();
        });
    };

    const _updateApplicationVersion = async () => {
        try {
            const { application_version: newApplicationVersion } =
                await getHeartbeat();
            saveApplicationVersion(newApplicationVersion);
        } catch (e) {
            console.error('Failed to update application version', e);
        }
    };

    const clearIntervals = () => {
        if (authCheckInterval) {
            clearInterval(authCheckInterval);
            setAuthCheckInterval(null);
        }

        if (applicationVersionInterval) {
            clearInterval(applicationVersionInterval);
            setApplicationVersionInterval(null);
        }

        if (unlistenToApplicationVersionUpdateRouteChanges) {
            unlistenToApplicationVersionUpdateRouteChanges();
        }
    };

    useEffect(() => {
        unlistenToApplicationVersionUpdateRouteChanges();

        setAuthCheckInterval(
            setInterval(() => {
                if (!document.cookie.includes('CLIENT_ID_COOKIE')) {
                    const loginUrl = urlBuilder.addQuery(
                        urlBuilder.locationOrigin() + '/login/index.html',
                        {
                            state: window.location.href,
                            idle: true,
                        },
                    );

                    clearCookie('XSRF-TOKEN');
                    clearCookie('CLIENT_ID_COOKIE');

                    history.replace(loginUrl);
                }
            }, ONE_HOUR_REFRESH),
        );

        setApplicationVersionInterval(
            setInterval(_updateApplicationVersion, 1000 * 60),
        ); // 60 seconds

        if (clientLoaded(clientObj)) {
            const loadingScreen = document.querySelector('.loader-container');
            if (loadingScreen) {
                loadingScreen.remove();
            }
        }

        return () => {
            clearIntervals();
        };
    }, []);
};
