import React, {
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from 'react';
import { PDFExportable } from 'waypoint-utils/pdf/PDFExportable';
import { PDFOptions } from '@progress/kendo-drawing/pdf';
import { PDFBuilder } from 'waypoint-utils/pdf/PDFBuilder';
import { getDocumentUploadById } from 'waypoint-requests';
import { PDFDocument } from 'pdf-lib';
import { useGetDocumentUploadByReferenceParams } from 'waypoint-hooks/data-access/useGetDocumentUploadByReferenceParams';
import { css } from 'emotion';
import { Button, Empty, message } from 'antd';
import theme from 'config/theme';
import '@progress/kendo-theme-default/dist/all.css';
import { PDFHeaderParams } from 'waypoint-types/report/types';
import { DocumentUploads } from 'components/uploads/DocumentUploads';
import { DEFAULT_FILE_SIZE_LIMIT_MB } from 'components/uploads/DocumentUploadsUtils';

interface AttachmentDocumentUploadEntityReportWidgetParams {
    widgetId: string;
    isPDFExport: boolean;
    setIsUploading?: (isUploading: boolean) => void;
    isUploading?: boolean;
}

const uploadButtonStyle = css`
    color: ${theme.colors.white} !important;
    background: ${theme.colors.blues.primary} !important;
    border-color: ${theme.colors.blues.primary} !important;
    margin-right: 5px;
`;

export const AttachmentDocumentUploadEntityReportWidget = React.forwardRef<
    PDFExportable,
    AttachmentDocumentUploadEntityReportWidgetParams
>(
    (
        {
            widgetId,
            isPDFExport,
        }: AttachmentDocumentUploadEntityReportWidgetParams,
        ref
    ) => {
        const [existingAttachment, setExistingAttachment] = useState<
            string | undefined
        >(undefined);
        const [pdfDoc, setPdfDoc] = useState<PDFDocument | null>(null);
        const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
        const [documentFetchError, setDocumentFetchError] =
            useState<boolean>(false);

        const {
            data: existingDocumentUpload,
            isError: existingDocumentUploadError,
            isLoading: isValidatingDocumentUpload,
            mutate,
        } = useGetDocumentUploadByReferenceParams(
            'entity_report_widget',
            widgetId
        );

        useEffect(() => {
            setDocumentFetchError(existingDocumentUploadError);
        }, [existingDocumentUploadError]);

        useMemo(() => {
            if (documentFetchError && !isPDFExport) {
                message.error('Failed to fetch uploaded document');
            }
        }, [documentFetchError, isPDFExport]);

        useImperativeHandle(ref, () => ({
            exportToPDF: async (
                options?: PDFOptions,
                pdfTemplateParams?: PDFHeaderParams
            ): Promise<PDFBuilder> => {
                const pdfBuilder = new PDFBuilder();
                if (!existingDocumentUpload?.id || documentFetchError) {
                    await pdfBuilder.addElementById(
                        widgetId,
                        {},
                        1,
                        pdfTemplateParams
                    );
                } else {
                    pdfDoc &&
                        (await pdfBuilder.addAttachment(
                            pdfDoc,
                            widgetId,
                            pdfTemplateParams
                        ));
                }

                return pdfBuilder;
            },
            isReadyToExport(): boolean {
                if (
                    existingDocumentUpload?.id &&
                    !documentFetchError &&
                    !isValidatingDocumentUpload
                ) {
                    return !!pdfDoc;
                }
                return !isValidatingDocumentUpload;
            },
        }));

        const setExistingDocumentUpload = async () => {
            try {
                const fileBuffer = await getDocumentUploadById(
                    existingDocumentUpload?.id ?? ''
                );
                const pdfDoc = await PDFDocument.load(fileBuffer);
                pdfDoc.setTitle(existingDocumentUpload?.document_url ?? '');
                setPdfDoc(pdfDoc);
                const rawPDF = await pdfDoc.save();
                const docUrl = URL.createObjectURL(
                    new Blob([rawPDF], { type: 'application/pdf' })
                );
                setExistingAttachment(docUrl);
                setDocumentFetchError(false);
            } catch {
                setPdfDoc(null);
                setDocumentFetchError(true);
            }
        };

        useEffect(() => {
            if (existingDocumentUpload?.id) {
                setExistingDocumentUpload();
            }
        }, [existingDocumentUpload]);

        if (isPDFExport) {
            if (!existingDocumentUpload?.id) {
                return (
                    <div id={widgetId}>
                        <Empty description="Attachment has not been uploaded" />
                    </div>
                );
            }
            if (documentFetchError) {
                return (
                    <div id={widgetId}>
                        <Empty description="Error fetching attachment" />
                    </div>
                );
            }
        }

        const PDF_VIEWER_HEIGHT = `${window.innerHeight - 275}px`;

        return (
            <>
                <div
                    style={{
                        display: 'flex',
                        fontSize: '14px',
                        alignItems: 'center',
                        marginBottom: '10px',
                    }}
                >
                    <Button
                        type="primary"
                        className={uploadButtonStyle}
                        onClick={() => setIsModalOpen(true)}
                    >
                        Upload Attachment
                    </Button>
                    <span>{'(Max: 1 PDF File)'}</span>
                    {existingDocumentUpload?.id && !documentFetchError && (
                        <div
                            style={{
                                marginRight: '5px',
                                display: 'flex',
                                flex: '1',
                                justifyContent: 'end',
                            }}
                        >
                            {`Uploaded File: ${existingDocumentUpload.document_url}`}
                        </div>
                    )}
                </div>
                <DocumentUploads
                    referenceType="entity_report_widget"
                    referenceId={widgetId}
                    documentsUploaded={
                        existingDocumentUpload ? [existingDocumentUpload] : []
                    }
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    mutateOne={mutate}
                    restrictions={{
                        allowedExtensions: ['.pdf'],
                        maxFileSize: DEFAULT_FILE_SIZE_LIMIT_MB,
                    }}
                    isModal={true}
                    maxFilesAllowed={1}
                ></DocumentUploads>
                {!!existingAttachment && !isPDFExport && (
                    <iframe
                        title="attachment"
                        src={existingAttachment}
                        width="100%"
                        height={PDF_VIEWER_HEIGHT}
                    />
                )}
            </>
        );
    }
);
