import PropTypes from 'prop-types';
import React from 'react';

class LoadingSpinner extends React.Component {
    static propTypes = {
        style: PropTypes.object,
        size: PropTypes.string,
    };

    render() {
        const { style, size } = this.props;
        const renderStyle = Object.assign({}, { color: '#9e9e9e' }, style);
        return (
            <div className="shortcut-container" style={style}>
                <i
                    // className="shortcut-container"
                    style={renderStyle}
                    pulse
                    size={size || '2x'}
                    className="fa-solid fa-spinner"
                />
            </div>
        );
    }
}

export default LoadingSpinner;
