import PropTypes from 'prop-types';
import React from 'react';
import styles from './Subheader.module.css';

const Subheader = function (props) {
    return (
        <div id="subHeaderContainer" className={styles.container}>
            <div className={styles.fill}>
                <div
                    id="subNavChildrenContainer"
                    className={[
                        styles.childrenContainer,
                        props.className || '',
                    ].join(' ')}
                >
                    {props.children}
                </div>
                {!props.subNav ? '' : props.subNav || 'subNav'}
            </div>
        </div>
    );
};

Subheader.propTypes = {
    subNav: PropTypes.node,
    subtitle: PropTypes.node,
    children: PropTypes.node,
    className: PropTypes.string,
};

export default Subheader;
