import theme from 'config/theme';
import { css } from 'emotion';

export const removeGroupButtonStyle = css`
    &.ant-btn-primary:enabled {
        color: ${theme.colors.red} !important;
        background-color: ${theme.colors.white} !important;
        border-color: ${theme.colors.red} !important;
        border-radius: 16px;
        font-size: 13px;
        box-shadow: 0 1px 3px rgba(0 0 0 / 5%);
        margin-left: 10px;
    }
`;
