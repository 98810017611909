import { get, assign } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Shiitake from 'shiitake';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { connectRequest } from 'redux-query';
import { requestUser } from 'state/requests';
import { selectUsersById } from 'state/users/selectors';
import WaypointTooltip from './WaypointTooltip';
import IconBubble from './IconBubble';
import LoadingSpinner from './LoadingSpinner';

const bubbleSize = 16;
const defaultDiameter = 45;
const defaultBackgroundColor = '#CCC';

function getInitials(firstname, lastname) {
    const first = firstname ? firstname[0] : '';
    const lasty = lastname ? lastname[0] : '';
    return (first + lasty).toUpperCase();
}

// TODO (Nicholas): Create wrapper component for Reviewer and move approved into it
class UserBubble extends React.Component {
    // TODO (Nicholas): add more metadata for tooltip OR leave hover concerns to
    //                  a higher level component
    static propTypes = {
        isLoading: PropTypes.bool,
        img: PropTypes.string,
        size: PropTypes.number,
        color: PropTypes.string,
        userId: PropTypes.number,
        approved: PropTypes.bool,
        showName: PropTypes.bool,
        lastname: PropTypes.string,
        firstname: PropTypes.string,
        // TODO (Nicholas): Is there a better way to determine max name width?
        maxNameWidth: PropTypes.number,
        style: PropTypes.object,
        className: PropTypes.string,
        showTooltip: PropTypes.bool,
        nameFontSize: PropTypes.number,
    };

    renderApprovalBubble = () => {
        const approvalBubble = (
            <span
                style={{
                    position: 'relative',
                    fontSize: `${bubbleSize - 6}px`,
                }}
            >
                <IconBubble
                    name="check"
                    size={bubbleSize}
                    style={{
                        fontSize: `${bubbleSize - 2}px`,
                        position: 'absolute',
                        transform: 'translateX(-12px)',
                        backgroundColor: 'rgb(39, 204, 24)',
                    }}
                />
            </span>
        );
        return approvalBubble;
    };

    render() {
        // TODO (Nicholas): Break this into a few render methods for improved readability
        const {
            className,
            isLoading,
            showName,
            approved,
            img,
            color,
            size,
            firstname,
            lastname,
            maxNameWidth,
            style,
            showTooltip,
            nameFontSize,
        } = this.props;

        const diameter = size || defaultDiameter;
        // DO NOT REMOVE EMPTY SPAN, PREVENTS CSS STAGGERING
        const emptySpan = <span>&nbsp;</span>;
        const child = img ? emptySpan : getInitials(firstname, lastname);
        // TODO (Nicholas): better manage multiple classnames
        //                  (if bubble had a classname already,
        //                   className={className} would fail)
        const bubble = (
            <span
                className={className}
                style={assign(
                    {},
                    {
                        width: `${diameter}px`,
                        height: `${diameter}px`,
                        display: 'inline-block',
                        marginTop: '5px',
                        marginRight: '5px',
                    },
                    style
                )}
            >
                <span
                    style={{
                        display: 'inline-block',
                        fontFamily: 'Lato',
                        color: '#fff',
                        width: `${diameter}px`,
                        height: `${diameter}px`,
                        textAlign: 'center',
                        lineHeight: `${diameter}px`,
                        borderRadius: `${diameter / 2}px`,
                        backgroundColor: color || defaultBackgroundColor,
                        backgroundImage: img ? `url("${img}")` : '',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat',
                    }}
                >
                    {isLoading ? (
                        <LoadingSpinner
                            style={{
                                color: '#FFF',
                                transform: 'translate3d(1px, 5px, 0px)',
                            }}
                        />
                    ) : (
                        child
                    )}
                </span>
                {!isLoading && approved && this.renderApprovalBubble()}
            </span>
        );

        const fullname = firstname + ' ' + lastname;

        if (showTooltip) {
            return (
                <WaypointTooltip message={fullname} placement="top">
                    {bubble}
                </WaypointTooltip>
            );
        }

        if (!showName || isLoading) {
            return bubble;
        }

        const fontSize = `${nameFontSize || 18}px`;

        return (
            <span
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '5px',
                }}
            >
                {bubble}
                <span
                    style={{
                        width: `${maxNameWidth || 140}px`,
                        marginLeft: '3px',
                        color: '#444',
                        fontSize,
                    }}
                >
                    <Shiitake lines={1}>{fullname}</Shiitake>
                </span>
            </span>
        );
    }
}

const mapState = (state, props) => {
    const { userId } = props;
    if (!userId) {
        return {};
    }
    // Get user information from state
    const user = selectUsersById(state, { userIds: [userId] })[0];
    if (!user) {
        return { isLoading: true };
    }
    return {
        img: get(user, 'profile_image_url', undefined),
        ...user,
    };
};

// NOTE: If a userId exists, fill in the bubble from state
//       otherwise allow the parent component to drive the UserBubble
//       This allows ReviewerList to have its own loading states
export default compose(
    connect(mapState),
    connectRequest(({ userId }) => {
        return userId ? requestUser({ id: userId }) : {};
    })
)(UserBubble);
