import { camelCase, isArray, isPlainObject } from 'lodash';

/**
 * A recursive function to transform deeply nested keys for API consumption
 * @param {Object} input - The collection to be transformed
 * @return {Object} an object preserving the shape of the input but with its keys transformed to camelcase
 */
const convertObjKeysToCamelCase = (input) => {
    if (isPlainObject(input)) {
        return Object.entries(input).reduce(
            (acc, [key, value]) => ({
                ...acc,
                [camelCase(key)]: convertObjKeysToCamelCase(value),
            }),
            {},
        );
    }

    if (isArray(input)) {
        return input.map(convertObjKeysToCamelCase);
    }

    return input;
};

export default convertObjKeysToCamelCase;
