import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { find, get } from 'lodash';
import { getDateRangeForPeriod } from 'waypoint-utils';
import { getAsOfDates } from 'waypoint-requests';

type UseGetCrosstabAsOfDate = {
    asOfDate: [Moment, Moment] | null;
    asOfDateLoading: boolean;
};

export const useGetCrosstabAsOfDate = (
    entityCodes: string[]
): UseGetCrosstabAsOfDate => {
    const [asOfDate, setAsOfDate] = useState<[Moment, Moment] | null>(null);

    const [asOfDateLoading, setAsOfDateLoading] = useState(false);

    const getDefaultGlobalPeriodForEntity = (
        periodEnd: Date
    ): [Moment, Moment] => {
        const [startDate, endDate] = getDateRangeForPeriod(
            'trailing_12',
            periodEnd
        );
        return [moment(startDate), moment(endDate)];
    };

    useEffect(() => {
        if (entityCodes.length === 0) {
            return;
        }

        const loadAsOfDate = async () => {
            try {
                setAsOfDateLoading(true);

                const { accounting } = await getAsOfDates(entityCodes);
                const latestActualPeriodEnd: string | undefined = get(
                    find(accounting, { label: 'Actual' }),
                    'period_end'
                );

                if (!latestActualPeriodEnd) {
                    console.error('failed to parse asOfDate');
                    return;
                }

                const period = getDefaultGlobalPeriodForEntity(
                    new Date(latestActualPeriodEnd)
                );

                setAsOfDate(period);
            } catch (e) {
                console.error('loading as of date failed', e);
            } finally {
                setAsOfDateLoading(false);
            }
        };

        loadAsOfDate();
    }, [entityCodes]);

    return {
        asOfDate,
        asOfDateLoading,
    };
};
