import { merge, isEmpty } from 'lodash';

// NOTE: This is an extendible chart builder that centralizes certain default configurations across the app. It is easy to override defaults via the ChartBuilder API. Please feel free to extend this as needed.

export const chartBuilder = () => {
    const config = {
        height: '500',
        width: '100%',
        dataFormat: 'JSON',
        dataSource: {
            chart: {
                // GENERAL
                theme: 'fusion',
                paletteColors: null,
                // NOTE: base font is configured and then overriden
                //       at label and value level. However, Y-axis labels
                //       cannot be configured and they inherit font related
                //       properties from a global level.
                baseFont: 'Lato',
                baseFontSize: 12,
                baseFontColor: '#4C4C4C',
                exportEnabled: true,
                exportFileName: null,
                numDivLines: 1,
                canvasPadding: 14,
                showLegend: '0',
                anchorRadius: 5,
                // DATA VALUE FORMATTING (affects line & column)
                valueFontSize: 11,
                valueFontBold: true,
                valueFontColor: '#000000',
                // GENERAL X-AXIS
                labelFontSize: 12,
                labelFontColor: '#5E5E5E',
                // PRIMARY AXIS (left)
                pYaxisName: null,
                pYAxisMaxValue: null,
                pYAxisValuesPadding: 9,
                pYAxisNameFontSize: 14,
                pYAxisNameFontColor: '#5E5E5E',
                // SECONDARY AXIS (right)
                sYaxisName: null,
                sYAxisMaxValue: 100,
                sYAxisValuesPadding: 9,
                sYAxisNameFontSize: 14,
                sYAxisNameFontColor: '#5E5E5E',
                sNumberSuffix: '%',
                // TOOLTIPS
                showToolTip: true,
                // PLOT HOVER EFFECTS: global, but only effects column chart
                plotHoverEffect: false,
                plotBorderHoverColor: '#333333',
                plotBorderHoverThickness: 1,
            },
            categories: [],
            // lineset: [],
            dataset: [],
            data: [],
            // trendlines: [],
        },
    };
    const type = (t = '') => (config.type = t);
    const height = (h = '') => (config.height = h);
    const width = (w = '') => (config.width = w);
    const style = (s = {}) => {
        config.dataSource.chart = merge(config.dataSource.chart, s);
    };
    const exportFormats = (e = '') =>
        (config.dataSource.chart.exportFormats = e);

    // data is for a single data set
    const data = (d) => {
        config.dataSource.data = d;
        if (isEmpty(config.dataSource.categories)) {
            // defining categories for a signle data set causes fusion chart error so
            //  it must be deleted
            delete config.dataSource.categories;
        }
    };
    // dataset is for multiple data sets
    const dataset = (set) => {
        config.dataSource.dataset = set;
    };
    const lineset = (set) => {
        config.dataSource.lineset = set;
    };
    const trendlines = (lines) => {
        config.dataSource.trendlines = lines;
    };
    const categories = (categoryArray = []) => {
        config.dataSource.categories = categoryArray;
    };
    const category = (categoryArray = []) => {
        // For single sets of data
        config.dataSource.category = categoryArray;
    };

    const linkedData = (data = []) => {
        config.dataSource.linkeddata = data;
    };
    return {
        data,
        linkedData,
        type,
        width,
        style,
        height,
        config,
        dataset,
        lineset,
        category,
        categories,
        trendlines,
        exportFormats,
    };
};

export default chartBuilder;
