import React from 'react';
import fusioncharts from 'fusioncharts';
import charts from 'fusioncharts/fusioncharts.charts';
import ReactFC from 'react-fusioncharts';
import { groupBy, map, sumBy } from 'lodash';
import {
    getBarChartData,
    generateRentTooltip,
} from 'components/leases/tenants/utils';
import { topIndustryCount } from 'components/leases/tenants/constants';
import { theme } from 'config/colors';
import chartBuilder from 'components/charts/chartBuilder';
import { css } from 'emotion';

// Resolves charts dependancy
charts(fusioncharts);

const chartContainer = css`
    position: relative;
`;
const chartStyle = {
    yaxisname: 'Gross $',
    baseFontColor: theme.colors.mediumGray,
    xAxisNameFontColor: theme.colors.mediumGray,
    yAxisNameFontColor: theme.colors.mediumGray,
};

const RentByIndustryChart = ({ data: tenants = [] }) => {
    const tenantsByIndustry = groupBy(tenants, 'industry');
    const statistics = map(tenantsByIndustry, (value, key) => {
        return {
            tenant_industry: key,
            annualInPlaceRent: sumBy(value, 'annualInPlaceRent'),
            tenant_occupied_area: value.length,
        };
    });
    const chartData = getBarChartData({
        statistics,
        labelPath: 'tenant_industry',
        valuePath: 'annualInPlaceRent',
        tooltipFunc: (value) => generateRentTooltip(value),
        partition: topIndustryCount,
    });

    const chart = chartBuilder();
    chart.type('bar2d');
    chart.height('300');
    chart.width('100%');
    chart.style(chartStyle);
    chart.exportFormats('PNG|PDF|JPG|SVG');
    chart.data(chartData);
    const { config: chartConfig } = chart;

    return (
        <div className={chartContainer}>
            <ReactFC {...chartConfig} />
        </div>
    );
};

export default RentByIndustryChart;
