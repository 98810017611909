import PropTypes from 'prop-types';
import React from 'react';
import shortcutManager from 'config/shortcuts';
// The Shortcut Wrapper allows Storybook to work with components
// that use react-shortcuts, like the Table
// Simply wrap the Table with the ShortcutProdiver.
// In the main client, this happens within App

class ShortcutProvider extends React.Component {
    static propTypes = {
        children: PropTypes.any,
    };

    static childContextTypes = {
        shortcuts: PropTypes.object.isRequired,
    };

    getChildContext() {
        return {
            shortcuts: shortcutManager,
        };
    }

    render() {
        return this.props.children;
    }
}

export default ShortcutProvider;
