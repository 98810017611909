import React from 'react';
import { css } from 'emotion';
import { DownloadButton, SelectionsBanner } from 'waypoint-react';
import ResponsiveBox, {
    Col,
    Item,
    Row,
    Location,
} from 'devextreme-react/responsive-box';
import { Link } from 'react-router-dom';
import { RouteUrls } from 'routes/RouteConstants';
import { QuickSettings } from '../menu/QuickSettings';
import { CommentsButton } from 'waypoint-react/comment/CommentsButton';
import { format } from 'date-fns';
import { useIncomeStatement } from 'components/financials/comparative-income-statement/IncomeStatementProvider';
import {
    THRESHOLD_FIXED_AND_PERCENTAGES,
    THRESHOLD_FIXED_ONLY,
    THRESHOLD_FIXED_OR_PERCENTAGES,
    THRESHOLD_PERCENTAGES_ONLY,
} from 'components/financials/comparative-income-statement/constants';
import { formatMoney } from 'utils/formatters';
import { Divider } from 'antd';

const bannerStyle = css`
    width: 100%;
    border: none;
    box-shadow: none !important;
`;

const downloadButtonStyle = css`
    margin-top: 25px;
`;
const dividerStyle = css`
    .ant-divider-horizontal {
        margin: 24px 0 -15px 0 !important;
        border-top: 2px solid rgba(0, 0, 0, 0.26) !important;
    }
`;

const ACTUAL_TO_BUDGET_LABEL = 'Actual to Budget';

interface WorkflowReportSelectionBannerProps {
    downloadCsv: () => Promise<void>;
    propertyDisplayName: string | null;
    workflowModal: React.ReactNode;
    workflowAvatarBannerGroup: React.ReactNode;
    workflowStatus: React.ReactNode;
}

const WorkflowReportSelectionBanner = ({
    downloadCsv,
    propertyDisplayName,
    workflowModal,
    workflowAvatarBannerGroup,
    workflowStatus,
}: WorkflowReportSelectionBannerProps): JSX.Element => {
    const {
        isLoading,
        hasRequiredWorkflowThreshold,
        thresholdCalculations,
        varianceReportQueryParams,
    } = useIncomeStatement();

    const decorateThresholdsRules = () => {
        if (!thresholdCalculations) {
            return null;
        }
        const {
            threshold_type: type,
            threshold_periodicity: thresholdPeriodicity,
            threshold_percent,
            threshold_amount,
        } = thresholdCalculations;
        const thresholdPercent = threshold_percent * 100;
        const thresholdAmount = formatMoney(threshold_amount);
        if (type === THRESHOLD_FIXED_AND_PERCENTAGES) {
            return `${thresholdPeriodicity.toUpperCase()} (${thresholdPercent}% AND ${thresholdAmount})`;
        }

        if (type === THRESHOLD_FIXED_OR_PERCENTAGES) {
            return `${thresholdPeriodicity.toUpperCase()} (${thresholdPercent}% OR ${thresholdAmount})`;
        }

        if (type === THRESHOLD_PERCENTAGES_ONLY) {
            return `${thresholdPeriodicity.toUpperCase()} (${thresholdPercent}%)`;
        }

        if (type === THRESHOLD_FIXED_ONLY) {
            return `${thresholdPeriodicity.toUpperCase()} (${thresholdAmount})`;
        }
    };
    return (
        <SelectionsBanner
            data-testid="selections-banner"
            className={bannerStyle}
        >
            <>
                {workflowModal}
                <ResponsiveBox>
                    <Row ratio={1} />
                    <Row baseSize={5} />
                    <Row ratio={1} />

                    <Col ratio={1} />
                    <Col ratio={1} />
                    <Col ratio={1} />
                    <Item>
                        <Location row={0} col={0} />
                        <Link
                            to={RouteUrls.FINANCIALS_VARIANCE_REPORT_STATUS}
                            style={{
                                textDecoration: 'underline',
                                display: 'inline-block',
                            }}
                        >
                            <i
                                className="fa-solid fa-arrow-circle-left"
                                style={{ marginRight: 5 }}
                            />
                            Back to Status
                        </Link>
                    </Item>

                    <Item>
                        <Location row={0} col={1} />
                        <div
                            style={{
                                justifyContent: 'center',
                                display: 'flex',
                                fontSize: '24px',
                                fontWeight: 'bold',
                            }}
                        >
                            {propertyDisplayName}
                        </div>
                        <div
                            style={{
                                justifyContent: 'center',
                                display: 'flex',
                                fontSize: '18px',
                            }}
                        >
                            {ACTUAL_TO_BUDGET_LABEL} -{' '}
                            {varianceReportQueryParams.periodicity.toUpperCase()}{' '}
                            {format(
                                new Date(varianceReportQueryParams.endDate),
                                'MMMM yyyy',
                            )}
                        </div>
                        <div
                            style={{
                                justifyContent: 'center',
                                display: 'flex',
                                fontSize: '16px',
                            }}
                        >
                            {decorateThresholdsRules()}
                        </div>
                    </Item>

                    <Item>
                        <Location row={0} col={2} />
                        <div
                            style={{ justifyContent: 'right', display: 'flex' }}
                        >
                            {hasRequiredWorkflowThreshold &&
                                workflowAvatarBannerGroup}
                        </div>
                    </Item>
                    <Item>
                        <Location row={1} col={0} colspan={3} />
                        <div className={dividerStyle}>
                            <Divider />
                        </div>
                    </Item>
                    <Item>
                        <Location row={2} col={0} />
                        <div
                            style={{
                                justifyContent: 'left',
                                display: 'flex',
                                marginTop: '22px',
                            }}
                        >
                            <QuickSettings
                                thresholdCalculations={thresholdCalculations}
                                isSingleEntity={true}
                            />
                        </div>
                    </Item>

                    <Item>
                        <Location row={2} col={1} />
                        <div
                            style={{
                                justifyContent: 'center',
                                display: 'flex',
                                marginTop: '22px',
                            }}
                        >
                            {workflowStatus}
                        </div>
                    </Item>

                    <Item>
                        <Location row={2} col={2} />
                        <div
                            style={{ justifyContent: 'right', display: 'flex' }}
                        >
                            <CommentsButton
                                buttonStyle={{
                                    marginRight: '10px',
                                    marginTop: '20.25px',
                                    marginLeft: '10px',
                                }}
                            />
                            <DownloadButton
                                className={downloadButtonStyle}
                                disabled={isLoading}
                                onClick={downloadCsv}
                            />
                        </div>
                    </Item>
                </ResponsiveBox>
            </>
        </SelectionsBanner>
    );
};

export default WorkflowReportSelectionBanner;
