import { Dictionary } from 'ts-essentials';
import { PropertyType, SelectOptions } from 'waypoint-types';
import { stringSort } from 'utils/tables/sorters';

export const getPropertyOptions = (
    properties: Dictionary<PropertyType>,
    entityCodes: string[]
): SelectOptions[] =>
    Object.values(properties)
        .map((p) => {
            const entry = {
                value: p.id,
                label: p.display_name,
            };
            return entry;
        })
        .filter((p) => entityCodes.includes(p.value))
        .sort((a: SelectOptions, b: SelectOptions) =>
            stringSort(b.label, a.label)
        );

export const getPropertyName = (
    propertyOptions: SelectOptions[],
    value: string
): string => {
    return propertyOptions.find((p) => p.value === value)?.label ?? '';
};
