import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { CommentThreadData } from 'waypoint-types';
import useSWR from 'swr';
import { getCommentsThreadById } from 'waypoint-requests';
import { NotificationStore } from '@magicbell/react-headless/dist/hooks/useNotifications';

export const useGetCommentsByThreadId = (
    commentThreadId: string | null,
    bindToNotifications?: NotificationStore | null
): DataHookResponse<CommentThreadData> => {
    const cacheKey = `/commentsByThreadId/${commentThreadId}${
        bindToNotifications
            ? `/notification-count-${bindToNotifications.total}`
            : ''
    }`;

    const { data, error, mutate } = useSWR(
        commentThreadId ? cacheKey : null,
        () => {
            return getCommentsThreadById(commentThreadId as string);
        }
    );

    return {
        isLoading: !data,
        isError: !!error,
        data,
        mutate,
    };
};
