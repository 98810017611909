import React, { Component } from 'react';
import { isEmpty, isEqual, isNull, isArray } from 'lodash';
import { getPropertyValues } from 'components/analytics/portfolioSegmentation/requests';
import PropertyValueTable from 'components/analytics/portfolioSegmentation/propertyValues/PropertyValueTable';

// TODO: Property Attributes Table and Property Values Table Containers share logic. Create one Container component for both.
export default class Container extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPropertiesLoaded: false,
            error: null,
            properties: [],
            sortOptions: null,
        };
        this.setSortOptions = this.setSortOptions.bind(this);
    }

    componentDidMount() {
        const noEntitiesPassedFilter =
            isArray(this.props.entityCodes) && isEmpty(this.props.entityCodes);
        if (
            (isNull(this.props.entityCodes) ||
                !isEmpty(this.props.entityCodes)) &&
            this.props.period
        ) {
            this.fetchProperties();
        }
        if (noEntitiesPassedFilter) {
            this.setState({ isPropertiesLoaded: true });
        }
    }

    componentWillReceiveProps(nextProps) {
        const noEntitiesPassedFilter =
            isEmpty(nextProps.entityCodes) && !isNull(nextProps.entityCodes);
        if (noEntitiesPassedFilter) {
            this.setProperties([]);
            return;
        }
        if (
            !isEqual(this.props.entityCodes, nextProps.entityCodes) ||
            !isEqual(this.props.period, nextProps.period)
        ) {
            this.setState({ isPropertiesLoaded: false }, this.fetchProperties);
        }
    }

    setProperties(properties) {
        this.setState({ properties, isPropertiesLoaded: true });
    }

    setErrorState(error) {
        this.setState({ error, isPropertiesLoaded: true, properties: [] });
    }

    setSortOptions(options) {
        if (!options.order) {
            this.setState({ sortOptions: null });
        } else {
            this.setState({ sortOptions: options });
        }
    }

    fetchProperties() {
        getPropertyValues({
            entityCodes: this.props.entityCodes,
            period: this.props.period,
        })
            .then((response) => response.json())
            .then((json) => this.setProperties(json.data))
            .catch((error) => this.setErrorState(error));
    }

    render() {
        return (
            <PropertyValueTable
                dataSource={this.state.properties}
                error={this.state.error}
                isLoading={
                    !this.state.isPropertiesLoaded ||
                    this.props.isDependenciesLoading
                }
                sortOptions={this.state.sortOptions}
                onSortChange={this.setSortOptions}
            />
        );
    }
}
