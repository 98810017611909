import { isEqual, assign } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import Links from 'routes/utils';
import { PROPERTY_CACHE_KEY } from 'config/constants';
import { hasNativeAnalyticsFeature } from 'state/user/selectors';
import { withRouter } from 'react-router-dom';

const link = new Links();

class GroupLayout extends React.Component {
    static propTypes = {
        params: PropTypes.object.isRequired,
        property: PropTypes.object,
        browser: PropTypes.object,
        location: PropTypes.object.isRequired,
        children: PropTypes.node,
        canEdit: PropTypes.bool,
        dispatch: PropTypes.func,
        hasDashboard: PropTypes.bool,
    };

    static contextTypes = {
        router: PropTypes.object.isRequired,
    };

    componentWillReceiveProps(nextProps) {
        const pureid = new URLSearchParams(this.props.location.search).get(
            'pureid'
        );
        const nextPureId = new URLSearchParams(nextProps.location.search).get(
            'pureid'
        );

        if (pureid !== nextPureId) {
            this.props.dispatch(reset('opportunityModal'));
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            !isEqual(nextProps, this.props) || !isEqual(this.state, nextState)
        );
    }

    componentWillUnmount() {
        this.props.dispatch(reset('opportunityModal'));
    }

    inCurrentPath = (string) => {
        return this.props.location.pathname.includes(string);
    };

    goToProfile = (id) => {
        const { history } = this.props;
        history.push(link.property(id).details().toString());
    };

    render() {
        const { props } = this;
        const { browser } = props;

        // Note: This code is out of over from the rendering
        // Code order: Analytics Subnav -> Entity Info Line -> Main Nav
        // Render order: Entity Info Line -> Main Nav -> Analytics Subnav
        // TODO (Nicholas): Unwind tight coupling to remove confusion
        //                  Specifically, refactor links
        return (
            <div
                style={{
                    height: `${browser.height - browser.topbarHeight}px`,
                    display: 'flex',
                    overflow: 'hidden',
                    flexGrow: 1,
                    flexDirection: 'column',
                }}
            >
                {/* FIXME (Nicholas): Make this full rest of the page using viewport pixels on browser size change */}
                {props.children}
            </div>
        );
    }
}

export const mapState = (state, props) => {
    const pureid = new URLSearchParams(props.location.search).get('pureid');

    // TODO (Nicholas): Make configuration loading into a HOC to avoid copying this check everywhere
    const sharedProps = {
        roles: state.user.userObj.roles,
        browser: state.browser,
        filtersActive: state.user.filtersActive,
        hasNativeAnalytics: hasNativeAnalyticsFeature(state),
    };

    return assign({}, sharedProps, {
        property: state.properties[PROPERTY_CACHE_KEY + pureid],
    });
};

export default withRouter(connect(mapState)(GroupLayout));
