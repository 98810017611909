import React from 'react';
import AntDesignSelect from 'antd/lib/select';

const { Option } = AntDesignSelect;
/**
 * Dropdown for one dimensional colleciton of options
 * @param {string | any} value value of selection
 * @param {array} options colleciton of objects, each one with a label and value field
 * @param {func} onChange callback that changes the value of selector
 */
const Select = ({ onChange, value, options = [], ...props }) => {
    return (
        <AntDesignSelect
            defaultValue={value}
            style={{ width: '100%' }}
            onChange={onChange}
            {...props}
        >
            {options.map((o) => {
                return (
                    <Option value={o.value} key={o.value}>
                        {o.label}
                    </Option>
                );
            })}
        </AntDesignSelect>
    );
};

export default Select;
