import { message } from 'antd';
import useSWR from 'swr';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { getDelinquency } from 'waypoint-requests';
import { DelinquencyDataPayload, SelectedDataLevel } from 'waypoint-types';

export const useGetDelinquency = (
    entityCodes: string[],
    selectedDataLevel: SelectedDataLevel,
    isPropertyProfilePage?: boolean,
    period?: string
): DataHookResponse<DelinquencyDataPayload> => {
    const { data, error, mutate, isValidating } = useSWR(
        [
            '/api/leases/delinquency/',
            entityCodes.join(','),
            selectedDataLevel.percentageType,
            selectedDataLevel.stakeholder,
            isPropertyProfilePage,
            period,
        ],
        () =>
            getDelinquency(
                entityCodes,
                selectedDataLevel,
                isPropertyProfilePage,
                period
            ),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
            onError: () => {
                message.error('Failed to fetch delinquency data.');
            },
            shouldRetryOnError: false,
        }
    );
    return {
        isLoading: !data || isValidating,
        isError: !!error,
        data,
        mutate,
        isValidating,
    };
};
