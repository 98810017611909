import { API_URL } from 'config/constants';
import { OccupancyHistoryProps } from 'waypoint-types';

/**
 * @param {array} entityCodes
 * @returns occupancy history for property or property group
 */
const getOccupancyHistory = async (
    entityCodes: string[]
): Promise<OccupancyHistoryProps[]> => {
    const response = await fetch(`${API_URL}/leases/occupancy-history`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            entity_codes: entityCodes,
        }),
    });

    if (!response.ok) {
        throw new Error('FAILED');
    }
    const { data } = await response.json();
    return data;
};

export default getOccupancyHistory;
