import { Card, Select, Skeleton, Typography } from 'antd';
import { css } from 'emotion';
import { getUniqueNumberArray } from 'waypoint-utils';
import { EntityProfileCardYearMetadata } from 'waypoint-types/properties/types';
import React from 'react';
import { useCommentsPane } from 'contexts/comments/CommentsContext';

const headerTitleStyle = css`
    text-align: left;
    line-height: 0.5 !important;
    margin-right: 10px;
`;

interface PlanningYearSelectionProps {
    yearFilter: number;
    setYearFilter: (year: number) => void;
    yearMetadata: EntityProfileCardYearMetadata[];
}

const getUniqueYears = (data: { year: number }[]): number[] => {
    return getUniqueNumberArray(data.map((item) => item.year));
};

export const PlanningYearSelection = ({
    yearFilter,
    setYearFilter,
    yearMetadata,
}: PlanningYearSelectionProps) => {
    const { closeComments } = useCommentsPane();

    if (!yearFilter || yearFilter < 0) {
        return <Skeleton />;
    }

    return (
        <Card style={{ width: '100%' }} bordered={false}>
            <Typography.Text
                className={headerTitleStyle}
                data-testid="property-detail-property-title"
            >
                Year
            </Typography.Text>
            <Select
                placeholder={'Select year'}
                style={{ width: 220, marginLeft: 2 }}
                value={yearFilter}
                options={getUniqueYears([...yearMetadata])
                    .sort((a, b) => a - b)
                    .map((year) => ({
                        label: year.toString(),
                        value: year,
                    }))}
                onChange={(yearFilter) => {
                    setYearFilter(yearFilter);
                    closeComments();
                }}
                showArrow
            />
        </Card>
    );
};
