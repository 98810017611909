import { API_URL } from 'config/constants';
import { EntityReportApproval } from 'waypoint-types';

interface GetEntityReportApprovalsParams {
    entityReportIds: string[];
}

const getEntityReportApprovals = async ({
    entityReportIds,
}: GetEntityReportApprovalsParams): Promise<EntityReportApproval[]> => {
    const response = await fetch(`${API_URL}/report-approvals/`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ entityReportIds }),
    });

    if (!response.ok) {
        throw new Error('FAILED');
    }

    const { data } = await response.json();
    return data;
};

export default getEntityReportApprovals;
