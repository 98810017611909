import React, { useState } from 'react';
import { css, cx } from 'emotion';
import { get } from 'lodash';
import theme from 'config/theme';
import { FinancialOverviewPeriodicitySelector } from 'components/analytics/financialOverview/menu';
import { periodicityOptions } from 'components/analytics/constants';
import { AccountSelect, Checkbox } from 'waypoint-react';
import { displayTypeOptions } from 'components/analytics/financialOverview/menu/displayType/constants';
import { Divider, Radio, Button, Card as AntDesignCard } from 'antd';

const content = css`
    .ant-card-body {
        padding-top: 10px;
    }

    h3 {
        text-transform: uppercase;
        color: ${theme.colors.grays.mediumGray};
        font-weight: 400;
    }
`;

const controls = css`
    display: flex;
    width: 100%;
    padding: 0px;
    justify-content: space-between;
`;

const subControls = css`
    display: flex;
    justify-content: space-between;
    width: 175px;
`;

const FinancialOverviewMenu = ({
    accountGraph,
    selections,
    handleReset,
    handleApply,
    handleClose,
    className,
    showHideNullValuesSelector,
    isOvertime,
}) => {
    const [accountMappingSelection, setAccountGraphSelection] = useState(
        selections.accountMapping
    );
    const [displayTypeSelection, setDisplayTypeSelection] = useState(
        selections.displayType
    );
    const [isHideNullSelection, setIsHideNullSelection] = useState(
        selections.isHideNull
    );
    const [periodicitySelection, setPeriodicitySelection] = useState(
        selections.periodicity
    );

    const handleSubmit = () => {
        handleApply({
            displayType: displayTypeSelection,
            accountMapping: accountMappingSelection,
            isHideNull: isHideNullSelection,
            periodicity: periodicitySelection,
        });
    };

    return (
        <AntDesignCard
            title="Card Settings"
            className={cx([className, content])}
        >
            <h3 style={{ marginTop: 5 }}>Account</h3>
            <AccountSelect
                value={get(accountMappingSelection, 'code')}
                accountGraph={accountGraph}
                onChange={(code, name) => {
                    setAccountGraphSelection({ code, name });
                }}
            />
            {isOvertime && (
                <div>
                    <h3 style={{ marginTop: 10 }}>Display By</h3>
                    <FinancialOverviewPeriodicitySelector
                        periodicityGraph={periodicityOptions}
                        value={periodicitySelection}
                        onChange={(value) => setPeriodicitySelection(value)}
                    />
                </div>
            )}
            <Divider />
            <h3>Display Value</h3>
            <div>
                <Radio.Group
                    options={displayTypeOptions}
                    value={displayTypeSelection}
                    onChange={(e) => setDisplayTypeSelection(e.target.value)}
                />
            </div>
            <Divider />
            {showHideNullValuesSelector && (
                <React.Fragment>
                    <div style={{ marginTop: 20 }}>
                        <Checkbox
                            checked={isHideNullSelection}
                            onChange={(value) =>
                                setIsHideNullSelection(value.target.checked)
                            }
                        >
                            Hide zero accounts
                        </Checkbox>
                    </div>
                    <Divider />
                </React.Fragment>
            )}
            <div className={controls}>
                <div>
                    <Button size="medium" onClick={handleReset}>
                        Reset Default
                    </Button>
                </div>
                <div className={subControls}>
                    <Button size="medium" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button size="medium" type="primary" onClick={handleSubmit}>
                        Apply
                    </Button>
                </div>
            </div>
        </AntDesignCard>
    );
};

export default FinancialOverviewMenu;
