import useSWR from 'swr';
import { getAccountGraph } from 'waypoint-requests';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { AccountGraph, SelectedDataLevel } from 'waypoint-types';

interface GetAccountGraphProps {
    accountGraphCode: string;
    entityCodes: string[];
    selectedDataLevel: SelectedDataLevel;
}

export const useGetAccountGraph = ({
    accountGraphCode,
    entityCodes,
    selectedDataLevel,
}: GetAccountGraphProps): DataHookResponse<AccountGraph> => {
    const key = `/account-graph/${entityCodes.join(',')}/${accountGraphCode}/${
        selectedDataLevel.percentageType
    }/${selectedDataLevel.stakeholder}`;
    const { data, mutate, error, isValidating } = useSWR(
        key,
        () => getAccountGraph(accountGraphCode, selectedDataLevel),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
            shouldRetryOnError: false,
        }
    );
    return {
        data,
        mutate,
        isError: !!error,
        isLoading: !data || isValidating,
    };
};
