import React from 'react';
import { DocumentsContainer } from 'components/documents/DocumentsContainer';

interface DocumentsSectionProps {
    entityCode: string;
}

const DocumentsSection = ({ entityCode }: DocumentsSectionProps) => {
    return <DocumentsContainer entityCode={entityCode} />;
};

export default DocumentsSection;
