import { message } from 'antd';
import useSWR from 'swr';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { getReportSettings } from 'waypoint-requests';

interface ValueLabelPair {
    label: string;
    value: string;
}
interface ReportSettings {
    narrativeOptions: ValueLabelPair[];
    paperOrientation: ValueLabelPair[];
    paperSize: ValueLabelPair[];
    reportStatus: {
        draft: string;
        active: string;
        archived: string;
    };
}

export const useGetReportSettings = (): DataHookResponse<ReportSettings> => {
    const { data, error, mutate } = useSWR(
        `/api/reports/settings`,
        () => getReportSettings(),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
            onError: () => {
                message.error('Failed to fetch report settings data');
            },
            shouldRetryOnError: false,
        }
    );

    return {
        isLoading: !data,
        isError: !!error,
        data,
        mutate,
    };
};
