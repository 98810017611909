import { API_URL } from 'config/constants';
import { SelectedDataLevel } from 'waypoint-types';

const downloadTenantsData = async (
    entityCodes: string[],
    selectedDataLevel: SelectedDataLevel
): Promise<Response> => {
    const response = await fetch(`${API_URL}/leases/tenants`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'text/csv',
        },
        body: JSON.stringify({
            entity_codes: entityCodes,
            selected_data_level: selectedDataLevel,
        }),
    });

    if (!response.ok) {
        throw new Error('FAILED');
    }

    return response;
};

export default downloadTenantsData;
