import { EntityReportReviewStatus } from 'components/reports/constants';
import { API_URL } from 'config/constants';
import { ReportWorkflowReview } from 'waypoint-types';

interface SubmitEntityReportReviewParams {
    entityCode: string;
    entityReportId: string;
    entityReportPublishedId: string;
    status: EntityReportReviewStatus;
}

const submitEntityReportReview = async ({
    entityCode,
    entityReportId,
    entityReportPublishedId,
    status,
}: SubmitEntityReportReviewParams): Promise<ReportWorkflowReview> => {
    const response = await fetch(
        `${API_URL}/report-approvals/${entityCode}/${entityReportId}/published-entity-reports/${entityReportPublishedId}/reviews`,
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({ status }),
        }
    );

    if (!response.ok) {
        throw new Error('Failed to submit entity report review');
    }

    const { data } = await response.json();

    return data;
};

export default submitEntityReportReview;
