import { EntityProfileCardBaseProps } from 'waypoint-react/entity-profile-cards/EntityProfileCardItem';
import {
    SortableCards,
    EntityProfileCardProgress,
    HtmlEditorContainer,
    EntityProfileCardItem,
} from 'waypoint-react';
import React, { CSSProperties } from 'react';
import {
    EntityProfileCard,
    EntityProfileCardYearMetadata,
} from 'waypoint-types/properties';
import { ReorderEvent } from 'devextreme/ui/sortable';
import {
    AddItemButtonProps,
    EntityProfileCardsAddItemButton,
} from 'waypoint-react/entity-profile-cards/EntityProfileCards';
import { EntityProfileCardsEmptyState } from 'waypoint-react/entity-profile-cards/EntityProfileCardsEmptyState';

interface EntityProfileCardsGroupProps extends EntityProfileCardBaseProps {
    items: EntityProfileCard[];
    title: string;
    onReorderItem: (event: ReorderEvent, item: EntityProfileCard) => void;
    itemMetadataSelects?: (item: any) => JSX.Element;
    addItemButton?: AddItemButtonProps;
    onAddItemButtonClicked?: () => void;
    style?: CSSProperties;
    enableStatusItems?: boolean;
    cardType?: string;
    onHtmlEditorSubmit?: any;
    year?: number;
    yearMetadataValue?: EntityProfileCardYearMetadata;
    includeNotesInPdf?: boolean;
    hideStatus?: boolean;
}

export const EntityProfileCardsGroup = ({
    items,
    title,
    enabledFields,
    onConfirmDelete,
    onStartEdit,
    onReorderItem,
    addItemButton,
    onAddItemButtonClicked,
    style,
    itemMetadataSelects,
    enableStatusItems,
    cardType,
    onHtmlEditorSubmit,
    yearMetadataValue,
    isPDFExport,
    includeNotesInPdf,
    hideStatus,
}: EntityProfileCardsGroupProps): JSX.Element => {
    const itemCards = items
        .sort((itemA, itemB) => {
            return itemA.sort_index - itemB.sort_index;
        })
        .map((item) => {
            return (
                <EntityProfileCardItem
                    key={item.id}
                    entityProfileCard={item}
                    itemMetadataSelects={itemMetadataSelects}
                    enableStatusItems={enableStatusItems}
                    hideStatus={hideStatus}
                    enabledFields={enabledFields}
                    onStartEdit={onStartEdit}
                    onConfirmDelete={onConfirmDelete}
                    yearMetadataValue={yearMetadataValue}
                    isPDFExport={isPDFExport}
                    includeNotesOnPdf={includeNotesInPdf}
                />
            );
        });

    const doneItems = enableStatusItems
        ? items.filter(
              (item) => item.metadata && item.metadata[0].value === 'complete'
          ).length
        : 0;

    const sortableCardsTitle =
        enableStatusItems && items.length ? (
            <EntityProfileCardProgress
                title={title}
                itemsCompleted={doneItems}
                allItems={items.length}
            />
        ) : (
            title
        );

    const content = items.find((item) => item.year === yearMetadataValue?.year);

    const itemCard = { ...content, year: yearMetadataValue?.year };

    if (cardType === 'marketOverview') {
        return (
            <HtmlEditorContainer
                referenceType={`entity_${title
                    .toLowerCase()
                    .split(' ')
                    .join('_')}`}
                isPDFExport={isPDFExport}
                items={itemCard}
                title={title}
                onHtmlEditorSubmit={onHtmlEditorSubmit}
            />
        );
    }

    return (
        <>
            <SortableCards
                title={sortableCardsTitle}
                style={style}
                extra={
                    addItemButton && (
                        <EntityProfileCardsAddItemButton
                            addItemButton={addItemButton}
                            onAddItemClicked={onAddItemButtonClicked}
                            circle={true}
                            isPDFExport={isPDFExport}
                        />
                    )
                }
                onReorder={(event) => {
                    const reorderedItem = items.find(
                        (item) => item.sort_index === event.fromIndex
                    );

                    if (reorderedItem) {
                        onReorderItem(event, reorderedItem);
                    }
                }}
            >
                {itemCards.length
                    ? itemCards
                    : [
                          <EntityProfileCardsEmptyState
                              title={title}
                              addItemButton={addItemButton}
                              onAddItemClicked={onAddItemButtonClicked}
                              isPDFExport={isPDFExport}
                          />,
                      ]}
            </SortableCards>
        </>
    );
};
