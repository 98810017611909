import ReactFC from 'react-fusioncharts';
import charts from 'fusioncharts/fusioncharts.charts';
import fusiontheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import FusionCharts from 'fusioncharts';
import {
    buildLeaseExpirationChartConfig,
    expirationGroupingByOptions,
} from 'components/leases/expirations/cards/leases-expiration/utils';
import { LeaseExpirationScheduleProps } from 'waypoint-types';

charts(FusionCharts);
fusiontheme(FusionCharts);

interface ExpirationScheduleChartProps {
    leaseExpirations: LeaseExpirationScheduleProps[];
    isPDFExport?: boolean;
    setIsChartReadyToExport?: (value: boolean) => void;
    groupByOption?: expirationGroupingByOptions;
    isPropertyProfile?: boolean;
}

const ExpirationScheduleChart = ({
    leaseExpirations,
    isPDFExport,
    setIsChartReadyToExport,
    groupByOption = expirationGroupingByOptions.year,
    isPropertyProfile,
}: ExpirationScheduleChartProps) => {
    const chartConfig = buildLeaseExpirationChartConfig(
        leaseExpirations,
        isPDFExport,
        groupByOption,
        isPropertyProfile
    );
    const onDrawComplete = () => {
        setIsChartReadyToExport && setIsChartReadyToExport(true);
    };

    return (
        <div
            style={{
                border: '.5px solid lightgray',
                padding: 5,
            }}
        >
            <ReactFC
                {...chartConfig}
                data-testid="expiration-schedule-chart"
                fcEvent-drawComplete={onDrawComplete}
            />
        </div>
    );
};

export default ExpirationScheduleChart;
