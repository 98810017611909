import { LeasingGuideline } from 'waypoint-types';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import useSWR from 'swr';
import { getLeasingGuidelines } from 'waypoint-requests';

export const useGetLeasingGuidelines = (
    entityCode: string,
    widgetYear?: number
): DataHookResponse<LeasingGuideline[]> => {
    const { data, error, mutate } = useSWR(
        `leasing-guidelines/${entityCode}/${widgetYear}`,
        () => getLeasingGuidelines(entityCode, widgetYear),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
        }
    );

    return {
        isLoading: !data,
        isError: !!error,
        data,
        mutate,
    };
};
