enum RouteUrls {
    LOGIN = '/login/index.html',
    ATTRIBUTES = '/attributes',
    SEGMENTATION = '/portfolio-summary',
    TASKS = '/tasks',
    SEARCH = '/search/properties',
    SETTINGS = '/settings',

    ANALYTICS = '/analytics',
    ANALYTICS_RANKING = '/analytics/ranking',
    ANALYTICS_BALANCE_SHEET_OVERVIEW = '/analytics/balance-sheet-overview',
    ANALYTICS_FINANCIAL_OVERVIEW = '/analytics/financial-overview',

    FINANCIALS = '/financials',
    FINANCIALS_COMPARATIVE_INCOME_STATEMENT = '/financials/comparative-income-statement',
    FINANCIALS_BALANCE_SHEET = '/financials/balance-sheet',
    FINANCIALS_CROSSTAB = '/financials/crosstab',
    FINANCIALS_VARIANCE_REPORT_STATUS = '/financials/variance-report-status',
    FINANCIALS_VARIANCE_REPORTING = '/financials/variance-reporting',

    LEASES = '/leases',
    LEASES_PERFORMANCE_OVERVIEW = '/leases/performance-overview',
    LEASES_TENANTS = '/leases/tenants',
    LEASES_EXPIRATIONS_SCHEDULE = '/leases/expiration-schedule',
    LEASE_RENT_ROLL = '/leases/rent-roll',
    LEASES_TOP_TENANTS = '/leases/top-tenants',

    PDF_RENDER = '/pdf-render',

    PROPERTY = '/property',
    PROPERTY_PROFILE_DETAILS = `/property/profile/details`,

    PLANNING = '/planning',
    PLANNING_HOLD_SELL = '/planning/hold-sell',
    PLANNING_PRIORITIES_AND_OBJECTIVES = '/planning/priorities-and-objectives',
    PLANNING_SERVICE_CONTRACTS = '/planning/service-contracts',
    PLANNING_CAPITAL_TRACKER = '/planning/capital-projects',
    BUDGET_UPLOAD = '/planning/budget-upload',

    AGED_RECEIVABLES = '/aged-receivables',

    REPORT = '/report',
    REPORT_TEMPLATE = '/report/manager/:reportId',
    REPORT_MANAGER = '/reports/manager',

    MY_REPORT_TEMPLATE = '/reports',
    REPORT_CREATE = '/report/create',
    REPORT_UPDATE = '/report',

    ENTITY_REPORT_EDITOR = '/entity-report/edit',
}

export { RouteUrls };
