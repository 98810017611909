import { cloneDeep, isEmpty } from 'lodash';
import { processAccountGraphCalculations } from 'waypoint-utils';

const createTotalNode = (node) => {
    return {
        name: `Total ${node.name}`,
        key: `Total ${node.account_mapping_code}`,
        account_mapping_code: node.account_mapping_code,
        isTotalNode: true,
        isTotalForRootNode: !node.parent_account_mapping_code,
        data: node.data,
        is_credit: node.is_credit,
        child_summary_data: node.child_summary_data,
        is_over_threshold: node.is_over_threshold,
    };
};

const addIsTitleRowKeyToNode = (node) => {
    return (node.isTitleNode = true);
};
const addIsRootNodeKeyToNode = (node) => {
    return (node.isRootNode = !node.parent_account_mapping_code);
};
const addKeyFieldToNode = (node) => (node.key = node.account_mapping_code);

const decorateFinancialsAccountGraphForAntDesign = (
    data,
    showCurrentPeriod,
    periodicity = 'ytd',
    comparisonSelections,
    isBalanceSheetType = false,
    collapseByDefault = true,
) => {
    // We need to pass a deep clone for the initial data,
    // or else we are making modifications to the cached SWR objects.
    return decorateClonedFinancialsAccountGraphForAntDesign(
        cloneDeep(data),
        showCurrentPeriod,
        periodicity,
        comparisonSelections,
        isBalanceSheetType,
        collapseByDefault,
    );
};

// TODO: Optimize time complexity. This traverses a graph and iterates over data in each node.
// TODO: Once finalized, this should be moved to an account graph util file.
const decorateClonedFinancialsAccountGraphForAntDesign = (
    data,
    showCurrentPeriod,
    periodicity,
    comparisonSelections,
    isBalanceSheetType,
    collapseByDefault,
) => {
    if (isEmpty(data)) {
        return [];
    }

    return data.map((node) => {
        if (node.children && node.children.length > 0) {
            decorateClonedFinancialsAccountGraphForAntDesign(
                node.children,
                showCurrentPeriod,
                periodicity,
                comparisonSelections,
                isBalanceSheetType,
                collapseByDefault,
            );
            const totalNode = createTotalNode(node);
            totalNode.child_summary_data = processAccountGraphCalculations(
                totalNode.child_summary_data,
                totalNode.is_credit,
                comparisonSelections,
                showCurrentPeriod,
                periodicity,
                isBalanceSheetType,
            );
            node.children.push(totalNode);
            addIsTitleRowKeyToNode(node);
            node.isCollapsed = collapseByDefault;
        }
        if (isEmpty(node.children)) {
            // This ensures Ant Design doesn't render expand/collapse icon for a node without children. If node.children is an empty array, it does render an icon
            node.children = null;
        }
        addIsRootNodeKeyToNode(node);
        // NOTE: key MUST be added for Ant Design Tree Table to properly render
        addKeyFieldToNode(node);

        node.child_summary_data = processAccountGraphCalculations(
            node.child_summary_data,
            node.is_credit,
            comparisonSelections,
            showCurrentPeriod,
            periodicity,
            isBalanceSheetType,
        );

        return node;
    });
};

export default decorateFinancialsAccountGraphForAntDesign;
