import React from 'react';
import { AccountMappingService } from 'waypoint-utils';
import {
    currencyRenderer,
    floatRenderer,
    textRenderer,
} from 'utils/tables/renderers';
import { toCalendarDate } from 'components/dates/utils';
import { TableNotes } from 'components/notes/TableNotes';
import ActionsCell from 'components/financials/actionCell/ActionsCell';
import { AccountGraphNode, CommentMention, Note } from 'waypoint-types';
import { KeyedMutator } from 'swr';

const accountMappingService = new AccountMappingService();

interface BalanceSheetDecoratedAccountGraphNode extends AccountGraphNode {
    isRootNode: boolean;
    isTotalNode: boolean;
    isTitleNode: boolean;
    isCollapsed: boolean;
}

export const getBalanceSheetColumns = (
    periods: (string | undefined)[],
    includeNotes: boolean,
    includeActionColumn: boolean,
    userId: number,
    mutateReportNotes: KeyedMutator<Note[]>,
    isNotesModalVisible: boolean,
    setIsNotesModalVisible: (value: boolean) => void,
    setNoteId: (value: string | null) => void,
    setNoteText: (text: string) => void,
    setSelectedAccountMappingId: (value: string | null) => void,
    openComments: () => void,
    setAccountFilterSelection: (value: string) => void,
    isSingleProperty: boolean,
    notes?: Note[] | null,
    expandedRows: string[] = [],
    commentThreadAccountMentions: CommentMention[] = [],
    hideCommentsOption: boolean = false,
    isPDFExport: boolean = false
) => {
    const isExpanded = (
        expandedRows: string[],
        node: BalanceSheetDecoratedAccountGraphNode
    ) => {
        return expandedRows.includes(node.account_mapping_code);
    };

    const hasComments = (
        comments: CommentMention[],
        node: BalanceSheetDecoratedAccountGraphNode
    ) => {
        return (
            comments.filter(
                (comment: CommentMention) =>
                    comment.reference_id === node.account_mapping_code
            ).length > 0
        );
    };

    const baseColumns = [
        {
            title: 'Account',
            dataIndex: 'name',
            width: '35%',
            ellipsis: true,
            render: (
                text: string,
                node: BalanceSheetDecoratedAccountGraphNode
            ) => (
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <span>{text}</span>
                    <span>
                        {isSingleProperty &&
                            hasComments(commentThreadAccountMentions, node) && (
                                <i
                                    className="fa-regular fa-commenting"
                                    onClick={() => {
                                        setAccountFilterSelection(
                                            node.account_mapping_code
                                        );
                                        openComments();
                                    }}
                                    style={{
                                        fontSize: '18px',
                                        marginLeft: '5px',
                                        cursor: 'pointer',
                                    }}
                                />
                            )}
                    </span>
                </div>
            ),
            fixed: 'left',
        },
        {
            title: 'Account Code',
            dataIndex: 'account_code',
            render: textRenderer,
            ellipsis: true,
            width: '10%',
        },
        ...periods.map((period) => {
            return {
                title: (
                    <span style={{ textAlign: 'right' }}>
                        {toCalendarDate(period)}
                    </span>
                ),
                align: 'right',
                width: '15%',
                render: (
                    _: null,
                    node: BalanceSheetDecoratedAccountGraphNode
                ) => {
                    if (node.isTitleNode && isExpanded(expandedRows, node)) {
                        return <span />;
                    }
                    const financial = accountMappingService.getEntryAmountFor(
                        node,
                        { period_end: period }
                    );
                    return <div>{currencyRenderer(financial)}</div>;
                },
            };
        }),
        {
            title: '$ Change',
            align: 'right',
            width: '12.5%',
            render: (_: null, node: BalanceSheetDecoratedAccountGraphNode) => {
                if (node.isTitleNode && isExpanded(expandedRows, node)) {
                    return <span />;
                }
                const financial =
                    accountMappingService.getVarianceAmountFor(node);
                return <div>{currencyRenderer(financial)}</div>;
            },
        },
        {
            title: '% Change',
            align: 'right',
            width: '10%',
            render: (_: null, node: BalanceSheetDecoratedAccountGraphNode) => {
                if (node.isTitleNode && isExpanded(expandedRows, node)) {
                    return <span />;
                }
                const financial =
                    accountMappingService.getVariancePercentFor(node);
                return <div>{floatRenderer(financial)}</div>;
            },
        },
    ];

    const actionColumn =
        includeActionColumn && !isPDFExport
            ? [
                  {
                      title: 'Actions',
                      align: 'center',
                      width: '8%',
                      render: (
                          _: null,
                          node: BalanceSheetDecoratedAccountGraphNode
                      ) => {
                          if (
                              node.isTitleNode &&
                              isExpanded(expandedRows, node)
                          ) {
                              return <span />;
                          }
                          return (
                              <ActionsCell
                                  isVisible={isNotesModalVisible}
                                  setIsVisible={setIsNotesModalVisible}
                                  accountMappingId={node.account_mapping_code}
                                  setSelectedAccountMappingId={
                                      setSelectedAccountMappingId
                                  }
                                  hideAccountHistoryButton={true}
                                  hideCommentsOption={hideCommentsOption}
                              />
                          );
                      },
                      fixed: 'right',
                  },
              ]
            : [];

    return includeNotes
        ? [
              ...baseColumns,
              {
                  title: 'Notes',
                  align: 'left',
                  width: '30%',
                  render: (
                      _: null,
                      node: BalanceSheetDecoratedAccountGraphNode
                  ) => {
                      if (node.isTitleNode && isExpanded(expandedRows, node)) {
                          return <span />;
                      }
                      return TableNotes(
                          node,
                          notes ?? null,
                          mutateReportNotes,
                          userId,
                          isNotesModalVisible,
                          setIsNotesModalVisible,
                          setNoteId,
                          setNoteText,
                          setSelectedAccountMappingId,
                          isPDFExport
                      );
                  },
              },
              ...actionColumn,
          ]
        : [...baseColumns, ...actionColumn];
};
