import { get } from 'lodash';
import { generateBalanceSheetOverviewTooltip } from 'components/analytics/balance-sheet-overview/charts/utils';
/**
 *  This takes a breakdown dataset and converts it into the needed Fusion Charts format for datasets
 * @param {array} breakdown
 * @returns {array}
 */
export const getBreakdownDataset = (breakdown) =>
    breakdown.map((i) => ({
        value: get(i, 'actual_amount'),
        toolText: generateBalanceSheetOverviewTooltip({
            title: get(i, 'account_mapping_name'),
            value: get(i, 'actual_amount'),
        }),
    }));

/**
 * This takes a breakdown dataset and converts it into the needed Fusion Charts format for scrollBar2D chart type categories
 * @param {array} breakdown
 * @returns {array}
 */
export const getBreakdownCategories = (breakdown) =>
    breakdown.map((i) => ({
        label: get(i, 'account_mapping_name'),
    }));
