import React, { useState, useEffect } from 'react';
import { Checkbox } from 'antd';
import { DatePicker, Select } from 'waypoint-react';
import { ReportWidgetSettingsBuilderProps } from 'waypoint-types';
import { useGetYearMetadata } from 'waypoint-hooks';
import ReportWidgetSettingsModal from 'components/reports/report-widget/ReportWidgetSettingsModal';
import moment from 'moment';

const categories = [
    { value: 'operations', label: 'Operations' },
    { value: 'leasing', label: 'Leasing' },
    { value: 'capitalProjects', label: 'Capital Projects' },
    { value: 'energyAndSustainability', label: 'Energy & Sustainability' },
    { value: 'financingAndOther', label: 'Financing and Other' },
];

const swotCategories = [
    { value: 'strengths', label: 'Strengths' },
    { value: 'weaknesses', label: 'Weaknesses' },
    { value: 'opportunities', label: 'Opportunities' },
    { value: 'threats', label: 'Threats' },
];

const yearMetadataTypes = {
    priorities_and_objectives: 'annualPrioritiesAndObjectives',
    hold_sell: 'holdSell',
    market_overview: 'marketOverview',
    swot: 'swot',
};

const commonStyles = {
    categoryHeader: {
        fontSize: '12px',
        fontWeight: 'bold',
        marginBottom: '6px',
    },
    marginTopSpace: { marginTop: '10px' },
};

const WidgetCategories = ({
    widgetType,
    selectedCategories,
    setSelectedCategories,
    categories,
    swotCategories,
}: {
    widgetType: string;
    selectedCategories: string[] | undefined;
    setSelectedCategories: (value: string[]) => void;
    categories: { value: string; label: string }[];
    swotCategories: { value: string; label: string }[];
}) => (
    <>
        <div style={commonStyles.categoryHeader}>Categories</div>
        <Select
            placeholder={
                widgetType === yearMetadataTypes.priorities_and_objectives
                    ? 'Select priority category'
                    : 'Select a category'
            }
            style={{ width: 300 }}
            value={selectedCategories}
            mode="multiple"
            options={
                widgetType === yearMetadataTypes.priorities_and_objectives
                    ? categories ?? []
                    : swotCategories ?? []
            }
            allowClear
            onChange={setSelectedCategories}
            maxTagCount={1}
            showArrow
        />
    </>
);

const YearSelector = ({
    selectedYears,
    setSelectedYears,
    isYearValid,
}: {
    selectedYears: number[] | undefined;
    setSelectedYears: (value: number[]) => void;
    isYearValid: boolean;
}) => (
    <>
        <div style={commonStyles.categoryHeader}>Years</div>
        <DatePicker
            onChange={(value) => value && setSelectedYears([value.year()])}
            placeholder="Select year"
            picker="year"
            status={isYearValid ? undefined : 'error'}
            value={
                selectedYears
                    ? moment(new Date().setFullYear(selectedYears[0]))
                    : null
            }
        />
        {!isYearValid && (
            <div style={{ color: 'red', marginTop: '8px' }}>
                You need to select a Year.
            </div>
        )}
    </>
);

const EntityProfileSettingsBuilder = ({
    entityCodes,
    isModalOpen,
    setIsModalOpen,
    onSaveWidgetSettings,
    widgetType,
    currentSettings,
}: ReportWidgetSettingsBuilderProps): JSX.Element => {
    const [selectedYears, setSelectedYears] = useState<number[] | undefined>(
        currentSettings?.selectedYears
    );

    const [displayVertically, setDisplayVertically] = useState<boolean>(
        currentSettings?.displayVertically ?? false
    );
    const [selectedCategories, setSelectedCategories] = useState<
        string[] | undefined
    >(currentSettings?.selectedCategories);
    const [isYearValid, setIsYearValid] = useState(true);

    const entityProfileCardWidgetType =
        yearMetadataTypes[widgetType as keyof typeof yearMetadataTypes];

    const { data } = useGetYearMetadata({
        entityCodes,
        type: entityProfileCardWidgetType,
    });

    const [includeNotesInReports, setIncludeNotesInReportsInReport] =
        useState<boolean>(currentSettings?.includeNotesInReports ?? false);

    const [hideStatus, setHideStatus] = useState<boolean>(
        currentSettings?.hideStatus ?? false
    );

    const categoriesSubsetSelected =
        (selectedCategories?.length ?? 0) > 0 &&
        (selectedCategories?.length ?? 0) < swotCategories.length;

    useEffect(() => {
        if (entityProfileCardWidgetType === yearMetadataTypes.swot) {
            if (categoriesSubsetSelected) {
                setDisplayVertically(true);
            }
        }
    }, [selectedCategories]);

    const buildReportWidgetSettingsInputs = () => {
        if (
            entityProfileCardWidgetType ===
            yearMetadataTypes.priorities_and_objectives
        ) {
            return {
                selectedYears,
                selectedCategories,
                includeNotesInReports,
                hideStatus,
            };
        }

        if (entityProfileCardWidgetType === yearMetadataTypes.swot) {
            return {
                selectedYears,
                selectedCategories,
                includeNotesInReports,
                displayVertically,
            };
        }

        return {
            selectedYears,
            includeNotesInReports,
        };
    };

    if (!data) {
        return <></>;
    }

    const onSave = () => {
        if (!selectedYears) {
            setIsYearValid(false);
            return;
        }
        onSaveWidgetSettings(buildReportWidgetSettingsInputs());
    };

    return (
        <ReportWidgetSettingsModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            settingsJsonBuilder={buildReportWidgetSettingsInputs}
            onSaveWidgetSettings={onSave}
            widgetType={widgetType}
        >
            <div style={{ marginBottom: '25px' }}>
                {(entityProfileCardWidgetType ===
                    yearMetadataTypes.priorities_and_objectives ||
                    entityProfileCardWidgetType === yearMetadataTypes.swot) && (
                    <WidgetCategories
                        widgetType={entityProfileCardWidgetType}
                        selectedCategories={selectedCategories}
                        setSelectedCategories={setSelectedCategories}
                        categories={categories}
                        swotCategories={swotCategories}
                    />
                )}
            </div>

            <div>
                <YearSelector
                    selectedYears={selectedYears}
                    setSelectedYears={setSelectedYears}
                    isYearValid={isYearValid}
                />
            </div>

            {entityProfileCardWidgetType !==
            yearMetadataTypes.market_overview ? (
                <div style={commonStyles.marginTopSpace}>
                    <Checkbox
                        checked={includeNotesInReports}
                        onChange={(ev) =>
                            setIncludeNotesInReportsInReport(ev.target.checked)
                        }
                    >
                        Include Notes in Export
                    </Checkbox>
                </div>
            ) : (
                <></>
            )}

            {entityProfileCardWidgetType === yearMetadataTypes.swot ? (
                <div style={commonStyles.marginTopSpace}>
                    <Checkbox
                        disabled={categoriesSubsetSelected}
                        checked={displayVertically}
                        onChange={(ev) =>
                            setDisplayVertically(ev.target.checked)
                        }
                    >
                        Display Vertically
                    </Checkbox>
                </div>
            ) : (
                <></>
            )}

            {entityProfileCardWidgetType ===
            yearMetadataTypes.priorities_and_objectives ? (
                <div style={commonStyles.marginTopSpace}>
                    <Checkbox
                        checked={hideStatus}
                        onChange={(ev) => setHideStatus(ev.target.checked)}
                    >
                        Hide Status
                    </Checkbox>
                </div>
            ) : (
                <></>
            )}
        </ReportWidgetSettingsModal>
    );
};

export default EntityProfileSettingsBuilder;
