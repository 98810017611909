export const valueTypes = {
    income_statement: [
        {
            label: 'Actual',
            value: 'actual',
        },
        {
            label: 'Budget',
            value: 'budget',
        },
        {
            label: 'Variance',
            value: 'variance',
        },
    ],
    balance_sheet: [
        {
            label: 'Actual',
            value: 'actual',
        },
        {
            label: 'Budget',
            value: 'budget',
        },
    ],
};

export const accountGraphTypes = [
    {
        label: 'Income Statement',
        value: 'income_statement',
    },
    {
        label: 'Balance Sheet',
        value: 'balance_sheet',
    },
];

export const defaultValueType: string = 'actual';

export const accountGraphValues = {
    balanceSheet: 'balance_sheet',
    incomeStatement: 'income_statement',
};

export const pieChartCounts = [
    {
        label: '5',
        value: 5,
    },
    {
        label: '10',
        value: 10,
    },
    {
        label: '15',
        value: 15,
    },
    {
        label: '20',
        value: 20,
    },
    {
        label: '25',
        value: 25,
    },
    {
        label: 'All',
        value: 0,
    },
];

export const DEFAULT_PIE_CHART_SLICE_COUNT = 15;
export const RANKING_RANGE_SELECT_ID: string = 'ranking-range-select';
