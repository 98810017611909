import { API_URL } from 'config/constants';
import { CapitalProjectClientDefinedType } from 'waypoint-types';

interface UpdateClientDefinedTypeParams {
    type: string;
    value: string;
    tag_color: string;
}

const updateClientDefinedType = async (
    typeId: string,
    body: UpdateClientDefinedTypeParams
): Promise<CapitalProjectClientDefinedType> => {
    const response = await fetch(
        `${API_URL}/capital-project/client-defined-type/${body.type}/${typeId}`,
        {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(body),
        }
    );

    if (!response.ok) {
        throw new Error('Failed to update client defined type');
    }

    const { data } = await response.json();

    return data as CapitalProjectClientDefinedType;
};

export default updateClientDefinedType;
