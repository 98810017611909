import PropTypes from 'prop-types';
import React from 'react';
import { css, cx } from 'emotion';
import { isUndefined } from 'lodash';
import theme from 'config/theme';

const actionButton = css`
    border: 0;
    padding: 0;
    outline: none;
    border-radius: 2px;
    box-sizing: border-box;
    align-items: flex-end;
    justify-content: center;
    background-color: ${theme.colors.white};
    font-size: 16px;
    &:active span {
        color: ${theme.colors.blues.primary};
    }
`;

const ActionButton = ({
    className = '',
    icon = 'fa-solid fa-cog fa-lg',
    size = '1x',
    ...props
}) => {
    if (isUndefined(props.onClick)) {
        return null;
    }

    return (
        <button className={cx([actionButton, className])} {...props}>
            <i className={icon} size={size} />
        </button>
    );
};

ActionButton.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func,
};

export default ActionButton;
