import theme from 'config/theme';
import { css } from 'emotion';
import { dateSort } from 'utils/tables/sorters';
import { deleteNote } from 'waypoint-requests';
import { AccountGraphNode, Note, NoteReferenceType } from 'waypoint-types';
import { Dropdown, Menu, message, Tooltip, Modal } from 'antd';
import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleFilled,
    MoreOutlined,
} from '@ant-design/icons';
import { format } from 'date-fns';
import { ComparisonSelections } from 'components/financials/comparative-income-statement/ComparisonIncomeStatementTypes';
import {
    ACTUAL_TO_ACTUAL_VALUE,
    ACTUAL_TO_BUDGET_VALUE,
    PRIOR_PERIOD_VALUE,
    SAME_PERIOD_PRIOR_YEAR_VALUE,
} from 'components/financials/comparative-income-statement/constants';
import { PERIODICITIES_WITH_CUSTOM } from 'components/financials/comparative-income-statement/selections/ComparativeIncomeStatementSelectionUtils';
import { KeyedMutator } from 'swr';
import React from 'react';
import { WorkflowRolesResponse } from 'waypoint-hooks/data-access/useGetWorkflowRoles';
import { buildStringToComparisonTypeString } from 'components/financials/comparative-income-statement/banner/ComparisonSelectionsBannerUtils';
import { useHasNotePermission } from 'waypoint-hooks';

const { confirm } = Modal;

interface FilterData {
    periodicity: string;
    start_date: string;
    end_date: string;
    mode: string;
}

export interface MetadataRequestParams {
    referenceId: string;
    referenceType: string;
    reportType: string;
    filters: FilterData[];
}

interface DecoratedAccountGraphNode extends AccountGraphNode {
    isRootNode: boolean;
    isTotalNode: boolean;
    isTitleNode: boolean;
    isCollapsed: boolean;
}

const noteRow = css`
    width: 100%;
    display: flex;
    &:hover {
        background-color: ${theme.colors.grays.background};
    }
`;

const dropdownStyle = css`
    position: relative;
    margin: 0;
    text-align: left;
    list-style-type: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 2px;
    outline: none;
    box-shadow:
        0 3px 6px -4px rgb(0 0 0 / 12%),
        0 6px 16px 0 rgb(0 0 0 / 8%),
        0 9px 28px 8px rgb(0 0 0 / 5%);
`;

const placeholderElement = (
    <span style={{ width: '12px', display: 'inline-block' }} />
);

const getNotesByAccountMappingId = (
    accountMappingId: string,
    notes: Note[]
) => {
    return notes
        .filter(
            (note: Note) =>
                note.reference_id === accountMappingId &&
                note.reference_type === NoteReferenceType.AccountMapping
        )
        .sort((a: Note, b: Note) =>
            dateSort(b.timestamps.created_at, a.timestamps.created_at)
        );
};

interface NoteProps {
    note: Note;
    userId: number;
    workflowRoles?: WorkflowRolesResponse;
    isAdmin?: boolean;
    isPDFExport?: boolean;
    actionMenu: (note: Note) => JSX.Element;
}
const TableNote = ({
    note,
    userId,
    isAdmin,
    workflowRoles,
    isPDFExport,
    actionMenu,
}: NoteProps) => {
    const hasNotePermission = useHasNotePermission(
        note,
        userId,
        isAdmin,
        workflowRoles
    );
    const tooltipTitleElement = (note: Note) => {
        if (note.updated_by) {
            return (
                <>
                    <div>
                        Note Added by {note.author.firstname}{' '}
                        {note.author.lastname}
                    </div>
                    <div>
                        Updated by {note.updatedBy.firstname}{' '}
                        {note.updatedBy.lastname} on{' '}
                        {format(
                            new Date(note.timestamps.updated_at),
                            'MMMM d, yyyy'
                        )}
                    </div>
                </>
            );
        }
        return (
            <>
                <div>
                    Note Added by {note.author.firstname} {note.author.lastname}{' '}
                    on{' '}
                    {format(
                        new Date(note.timestamps.updated_at),
                        'MMMM d, yyyy'
                    )}
                </div>
            </>
        );
    };

    return (
        <div
            style={{
                marginBottom: '12px',
                fontSize: '12px',
                display: 'flex',
                textAlign: 'left',
            }}
        >
            <span className={noteRow}>
                <Tooltip
                    mouseEnterDelay={0.5}
                    overlayStyle={{ fontSize: '12px' }}
                    placement="top"
                    trigger="hover"
                    arrow
                    title={tooltipTitleElement(note)}
                >
                    <div
                        style={{
                            fontSize: '14px',
                            fontWeight: 'normal',
                            textTransform: 'none',
                        }}
                    >
                        {note.text}
                    </div>
                </Tooltip>
            </span>
            {hasNotePermission && !isPDFExport && (
                <Dropdown
                    placement="topRight"
                    overlay={actionMenu(note)}
                    trigger={['hover']}
                >
                    <MoreOutlined
                        key={note.id}
                        style={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                        }}
                    />
                </Dropdown>
            )}
        </div>
    );
};

export const TableNotes = (
    node: DecoratedAccountGraphNode,
    notes: Note[] | null,
    mutateReportNotes: KeyedMutator<Note[]>,
    userId: number,
    isNotesModalVisible: boolean,
    setIsNotesModalVisible: (value: boolean) => void,
    setNoteId: (value: string | null) => void,
    setNoteText: (text: string) => void,
    setSelectedAccountMappingId: (value: string | null) => void,
    isPDFExport?: boolean,
    workflowRoles?: WorkflowRolesResponse,
    isAdmin?: boolean
) => {
    const toggleModal = () => {
        setIsNotesModalVisible(!isNotesModalVisible);
    };

    const actionMenu = (note: Note) => {
        const deleteSelectedNote = async () => {
            if (!notes) {
                return;
            }

            const updatedNotes = [...notes.filter((n) => n.id !== note.id)];

            await mutateReportNotes(
                async () => {
                    try {
                        await deleteNote(note.id);
                        return updatedNotes;
                    } catch (e) {
                        message.error('An error occurred while deleting note');

                        return notes;
                    }
                },
                {
                    optimisticData: updatedNotes,
                    rollbackOnError: true,
                }
            );
        };

        const showDeleteConfirm = () => {
            confirm({
                title: 'Are you sure you want to delete this note?',
                icon: <ExclamationCircleFilled />,
                content: 'This action cannot be undone.',
                okText: 'Yes',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    deleteSelectedNote();
                },
            });
        };

        const setModalParams = () => {
            setNoteId(note.id);
            setNoteText(note.text);
            setSelectedAccountMappingId(note.reference_id);
        };

        return (
            <div className={dropdownStyle}>
                {!isNotesModalVisible && (
                    <Menu>
                        <Menu.Item
                            style={{
                                height: '25px',
                                width: '92px',
                                paddingLeft: '10px',
                                paddingTop: '0px',
                            }}
                            key={'edit'}
                            onClick={() => {
                                toggleModal();
                                setModalParams();
                            }}
                            icon={<EditOutlined />}
                        >
                            Edit
                        </Menu.Item>
                        <Menu.Item
                            style={{
                                height: '40px',
                                width: '92px',
                                paddingLeft: '10px',
                                paddingBottom: '0px',
                            }}
                            key={'delete'}
                            onClick={({ domEvent: event }) => {
                                event.stopPropagation();
                                showDeleteConfirm();
                            }}
                            icon={<DeleteOutlined />}
                        >
                            Delete
                        </Menu.Item>
                    </Menu>
                )}
            </div>
        );
    };

    const filteredNotes = notes
        ? getNotesByAccountMappingId(node.account_mapping_code, notes)
        : [];

    return (
        <div
            style={{
                maxHeight: isPDFExport ? 'auto' : '200px',
                width: '100%',
                overflowY: 'auto',
            }}
        >
            {filteredNotes.length
                ? filteredNotes.map((note: Note) => (
                      <TableNote
                          note={note}
                          userId={userId}
                          isPDFExport={isPDFExport}
                          isAdmin={isAdmin}
                          actionMenu={actionMenu}
                          workflowRoles={workflowRoles}
                      />
                  ))
                : placeholderElement}
        </div>
    );
};

const labelOptions = {
    ...PERIODICITIES_WITH_CUSTOM,
    month: { label: 'Month', value: 'month', key: 'month' },
    quarter: { label: 'Quarter', value: 'quarter', key: 'quarter' },
};

export const accountHistoryNotesColumns = ({
    periodicity,
}: {
    periodicity: string;
}) => ({
    actual_to_actual_prior_year: [
        {
            data_field: 'date',
            title: labelOptions[periodicity as keyof typeof labelOptions].label,
            data_type: 'string',
            alignment: 'left',
        },
        {
            data_field: 'actuals',
            title: 'Actuals',
            data_type: 'number',
            alignment: 'left',
            format: 'currency',
        },
        {
            data_field: 'actuals_prior',
            title: 'Same Period Prior Year',
            data_type: 'number',
            alignment: 'left',
            format: 'currency',
        },
        {
            data_field: 'change',
            title: 'Change',
            data_type: 'number',
            alignment: 'left',
            format: 'currency',
        },
        {
            data_field: 'change_percent',
            title: 'Change %',
            data_type: 'number',
            alignment: 'left',
            format: 'percent',
        },
        {
            data_field: 'variance_is_good',
            title: 'Variance is Good',
            data_type: 'boolean',
            alignment: 'left',
        },
        {
            data_field: 'notes',
            title: 'Notes',
            data_type: 'string',
            alignment: 'left',
        },
    ],
    actual_to_actual_prior_period: [
        {
            data_field: 'date',
            title: labelOptions[periodicity as keyof typeof labelOptions].label,
            data_type: 'string',
            alignment: 'left',
        },
        {
            data_field: 'actuals',
            title: 'Actuals',
            data_type: 'number',
            alignment: 'left',
            format: 'currency',
        },
        {
            data_field: 'actuals_prior',
            title: 'Prior Period',
            data_type: 'number',
            alignment: 'left',
            format: 'currency',
        },
        {
            data_field: 'change',
            title: 'Change',
            data_type: 'number',
            alignment: 'left',
            format: 'currency',
        },
        {
            data_field: 'change_percent',
            title: 'Change %',
            data_type: 'number',
            alignment: 'left',
            format: 'percent',
        },
        {
            data_field: 'variance_is_good',
            title: 'Variance is Good',
            data_type: 'boolean',
            alignment: 'left',
        },
        {
            data_field: 'notes',
            title: 'Notes',
            data_type: 'string',
            alignment: 'left',
        },
    ],
    actual_to_budget: [
        {
            data_field: 'date',
            title: labelOptions[periodicity as keyof typeof labelOptions].label,
            data_type: 'string',
            alignment: 'left',
        },
        {
            data_field: 'actuals',
            title: 'Actuals',
            data_type: 'number',
            alignment: 'left',
            format: 'currency',
        },
        {
            data_field: 'budgets',
            title: 'Budget',
            data_type: 'number',
            alignment: 'left',
            format: 'currency',
        },
        {
            data_field: 'variance',
            title: 'Variance',
            data_type: 'number',
            alignment: 'left',
            format: 'currency',
        },
        {
            data_field: 'variance_percent',
            title: 'Variance %',
            data_type: 'number',
            alignment: 'left',
            format: 'percent',
        },
        {
            data_field: 'variance_is_good',
            title: 'Variance is Good',
            data_type: 'boolean',
            alignment: 'left',
        },
        {
            data_field: 'notes',
            title: 'Notes',
            data_type: 'string',
            alignment: 'left',
        },
    ],
});

export const accountHistoryComparisonHistory = (
    comparison: Partial<ComparisonSelections>
): string => {
    if (
        buildStringToComparisonTypeString(
            comparison.comparison_type?.slice(0, 2) ?? []
        ) === ACTUAL_TO_BUDGET_VALUE
    ) {
        return ACTUAL_TO_BUDGET_VALUE;
    }

    if (
        buildStringToComparisonTypeString(
            comparison.comparison_type?.slice(0, 2) ?? []
        ) === ACTUAL_TO_ACTUAL_VALUE &&
        comparison.comparison_period === SAME_PERIOD_PRIOR_YEAR_VALUE
    ) {
        return 'actual_to_actual_prior_year';
    }

    if (
        buildStringToComparisonTypeString(
            comparison.comparison_type?.slice(0, 2) ?? []
        ) === ACTUAL_TO_ACTUAL_VALUE &&
        comparison.comparison_period === PRIOR_PERIOD_VALUE
    ) {
        return 'actual_to_actual_prior_period';
    }

    return '';
};
