import { API_URL } from 'config/constants';

export interface CreateReportParams {
    name: string;
    entity_codes?: string[];
    due_date: string;
    state: string | null;
}

const createReport = async (report: CreateReportParams): Promise<string> => {
    const response = await fetch(`${API_URL}/reports/create`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(report),
    });

    if (!response.ok) {
        throw new Error('Failed to create report');
    }

    const { data } = await response.json();

    return data;
};

export default createReport;
