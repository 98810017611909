import { useLocation } from 'react-router-dom';

/**
 * Leverages useLocation hook to read the entity code
 * @returns {string} - entity code, ex. cp_101
 */
export const useGetEntityCode = (): string => {
    const urlParamsRegex = /&.*/;
    return useLocation().search.split('=')[1]?.replace(urlParamsRegex, '');
};
