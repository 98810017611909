import {
    AccountGraphNode,
    Note,
    CommentMention,
    NoteReferenceType,
} from 'waypoint-types';
import {
    HIDE_NULLS,
    SHOW_OVER_THRESHOLDS,
    SHOW_ALL_NOTES,
    HAS_COMMENTS,
    HAS_BUDGET_DIFFERENCE,
} from './constants';
import { cloneDeep, isEmpty, isNil } from 'lodash';

const shouldFilter = (
    object: AccountGraphNode,
    filters: string[],
    overThresholdCodes: Set<string> | null,
    accountsWithNote: Set<string> | null,
    accountsWithComments: Set<string> | null,
    accountsWithBudget: Set<string> | null
) => {
    let allowed = false;

    if (filters.includes(HIDE_NULLS)) {
        const tertiaryAmount =
            object.child_summary_data.entries.length === 3
                ? object.child_summary_data.entries[2].amount
                : null;

        const hasCurrentPeriodColumn =
            !!object.child_summary_data?.current_period_calculations;

        const currentPeriodAmount = hasCurrentPeriodColumn
            ? object.child_summary_data.entries.some((entry) => entry.amount)
            : null;

        const isNullAccount =
            !object.child_summary_data.entries[0]?.amount &&
            !object.child_summary_data.entries[1]?.amount &&
            !currentPeriodAmount &&
            !tertiaryAmount;

        const isLeafAccount = !object.children;
        const isCalculatedField = !object.parent_account_mapping_code;
        // should return node if actual or budget is not a nullable value (null or zero)
        if (!isNullAccount) {
            allowed = true;
        }
        if (
            (isNullAccount && !isLeafAccount) ||
            (isNullAccount && isCalculatedField)
        ) {
            allowed = true;
            object.children = null; // set null to remove the expand button in the table
        }
    }

    if (
        filters.includes(SHOW_OVER_THRESHOLDS) &&
        overThresholdCodes &&
        overThresholdCodes.has(object.account_mapping_code)
    ) {
        allowed = true;
    }
    if (
        filters.includes(SHOW_ALL_NOTES) &&
        accountsWithNote &&
        accountsWithNote.has(object.account_mapping_code)
    ) {
        allowed = true;
    }
    if (
        filters.includes(HAS_COMMENTS) &&
        accountsWithComments &&
        accountsWithComments.has(object.account_mapping_code)
    ) {
        allowed = true;
    }

    if (
        filters.includes(HAS_BUDGET_DIFFERENCE) &&
        accountsWithBudget &&
        accountsWithBudget.has(object.account_mapping_code)
    ) {
        allowed = true;
    }

    return allowed;
};

const getFilteredAccountCodes = (
    accountGraph: AccountGraphNode[],
    notes?: Note[],
    comments?: CommentMention[]
) => {
    const getNoteForAccount = (accountMappingCode: string, notes?: Note[]) => {
        if (!notes) {
            return accountMappingCode;
        }
        return notes.find(
            (note) =>
                note.reference_id === accountMappingCode &&
                note.reference_type === NoteReferenceType.AccountMapping
        );
    };

    const hasComments = (
        comments: CommentMention[],
        node: AccountGraphNode
    ) => {
        return (
            comments.filter(
                (comment: CommentMention) =>
                    comment.reference_id === node.account_mapping_code
            ).length > 0
        );
    };

    const accountMappingCodes = new Set<string>();
    const traverse = (collection: AccountGraphNode[]) => {
        let shouldAdd = false;
        collection.forEach((node) => {
            const nodeIsIncluded = accountMappingCodes.has(
                node.account_mapping_code
            );
            const overThreshold =
                !notes && !comments
                    ? node.child_summary_data.calculations.threshold_exceeded
                    : false;
            const accountGraphNotes = notes
                ? getNoteForAccount(node.account_mapping_code, notes)
                : false;
            const accountGraphComments = comments
                ? hasComments(comments, node)
                : false;
            if (
                accountGraphNotes ||
                overThreshold ||
                accountGraphComments ||
                nodeIsIncluded
            ) {
                shouldAdd = true;
                !nodeIsIncluded &&
                    accountMappingCodes.add(node.account_mapping_code);
            }
            if (node.children) {
                const hasChildCondition = traverse(node.children);
                if (hasChildCondition) {
                    accountMappingCodes.add(node.account_mapping_code);
                    if (node.parent_account_mapping_code) {
                        accountMappingCodes.add(
                            node.parent_account_mapping_code
                        );
                    }
                }
            }
        });
        return shouldAdd;
    };
    traverse(accountGraph);
    return accountMappingCodes;
};

const getFilteredBudgetAccountCodes = (accountGraph: AccountGraphNode[]) => {
    const accountMappingCodes = new Set<string>();

    const traverse = (
        collection: AccountGraphNode[],
        parentCodes: Set<string>
    ) => {
        collection.forEach((node) => {
            const hasBudget = node.currentUpload || node.priorUpload;

            if (hasBudget) {
                parentCodes.forEach((code) => accountMappingCodes.add(code));
                accountMappingCodes.add(node.account_mapping_code);
            }

            if (node.children) {
                const newParentCodes = new Set<string>(parentCodes);
                newParentCodes.add(node.account_mapping_code);
                traverse(node.children, newParentCodes);
            }
        });
    };

    traverse(accountGraph, new Set<string>());
    return accountMappingCodes;
};

/**
 * It filters the account graph based on the array of filter
 * @param {array} accountGraph deeply nested array representing an account graph
 * @param {array} filters
 */
const filterAccountGraph = (
    accountGraph: AccountGraphNode[] | null,
    filters: string[],
    notes?: Note[],
    comments?: CommentMention[]
): AccountGraphNode[] => {
    if (!accountGraph) {
        return [];
    }
    const cloneAccountGraph: AccountGraphNode[] = cloneDeep(accountGraph);

    if (isNil(filters) || isEmpty(filters)) {
        return cloneAccountGraph;
    }
    const overThresholdCodes = filters.includes(SHOW_OVER_THRESHOLDS)
        ? getFilteredAccountCodes(accountGraph)
        : null;
    const accountsWithNote = filters.includes(SHOW_ALL_NOTES)
        ? getFilteredAccountCodes(accountGraph, notes)
        : null;
    const accountsWithComments = filters.includes(HAS_COMMENTS)
        ? getFilteredAccountCodes(accountGraph, undefined, comments)
        : null;
    const accountsWithBudget = filters.includes(HAS_BUDGET_DIFFERENCE)
        ? getFilteredBudgetAccountCodes(accountGraph)
        : null;

    const getNodes = (result: AccountGraphNode[], object: AccountGraphNode) => {
        if (
            shouldFilter(
                object,
                filters,
                overThresholdCodes,
                accountsWithNote,
                accountsWithComments,
                accountsWithBudget
            )
        ) {
            result.push(object);

            if (Array.isArray(object.children)) {
                const children = object.children.reduce(getNodes, []);

                if (children.length) {
                    const findAccountGraphNode = result.find(
                        (child: AccountGraphNode) =>
                            child.account_mapping_code ===
                            object.account_mapping_code
                    );

                    if (findAccountGraphNode) {
                        findAccountGraphNode.children = children;
                    }
                }
            }

            return result;
        }
        return result;
    };

    return cloneAccountGraph.reduce<AccountGraphNode[]>(getNodes, []);
};

export default filterAccountGraph;
