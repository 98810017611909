import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { getAccountGraph, getAsOfDates } from 'waypoint-requests';
import { decorateAccountGraphForAntDesign } from 'waypoint-utils';
import { find, get } from 'lodash';
import { FinancialOverviewGrid } from 'components/analytics/financialOverview/grid';
import { DisabledDashboard } from 'waypoint-react';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withDashboardConfigurationRequest from 'components/dashboards/withDashboardConfigurationRequest';
import withDashboardConfiguration from 'components/dashboards/withDashboardConfiguration';
import { selectDefaultFinancialOverviewDashboardId } from 'state/dashboardConfigurations/selectors';
import {
    useGetSelectedFilteredEntityCodes,
    useSelectedDataLevel,
} from 'waypoint-hooks';
import moment from 'moment';
import { buildDashboardConfigFromSlimJson } from 'components/analytics/utils';
import { AppFeaturePermissions } from 'contexts';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';

const FinancialOverviewDashboard = ({
    dashboardConfiguration,
    isDashboardConfigurationLoading,
}) => {
    const [isError, setIsError] = useState(false);
    const [isLoadingAccountGraph, setIsLoadingAccountGraph] = useState(true);
    const [isLoadingAsOfDate, setIsLoadingAsOfDate] = useState(true);
    const [accountGraph, setAccountGraph] = useState(null);
    const [asOfDate, setAsOfDate] = useState('');

    const entityCodes = useGetSelectedFilteredEntityCodes();
    const selectedDataLevel = useSelectedDataLevel();

    useEffect(() => {
        if (!entityCodes.length) {
            return;
        }

        const fetchDependencies = async () => {
            await fetchAccountGraph();
            await fetchAsOfDate();
        };

        const fetchAccountGraph = async () => {
            setIsLoadingAccountGraph(true);
            setAccountGraph(null);

            try {
                const data = await getAccountGraph(
                    undefined,
                    selectedDataLevel,
                );

                const accountGraph = decorateAccountGraphForAntDesign(
                    data.children,
                );

                setAccountGraph(accountGraph);
            } catch (e) {
                setIsError(true);

                message.error(
                    'Request Failed! An error occurred while requesting accounts',
                );
            } finally {
                setIsLoadingAccountGraph(false);
            }
        };

        const fetchAsOfDate = async () => {
            setIsLoadingAsOfDate(true);
            setAsOfDate('');

            try {
                const data = await getAsOfDates(entityCodes);
                transformAndSetAsOfDate(data);
            } catch (e) {
                setIsError(true);

                message.error(
                    'Request Failed! An error occurred while requesting as of date',
                );
            } finally {
                setIsLoadingAsOfDate(false);
            }
        };

        const transformAndSetAsOfDate = (asOfDateResponse) => {
            const actualPeriodEnd = get(
                find(asOfDateResponse.accounting, { label: 'Actual' }),
                'period_end',
            );

            setAsOfDate(actualPeriodEnd);
        };

        fetchDependencies();
    }, [
        entityCodes,
        selectedDataLevel.percentageType,
        selectedDataLevel.stakeholder,
    ]);

    if (!dashboardConfiguration && !isDashboardConfigurationLoading) {
        return <DisabledDashboard />;
    }

    if (isError) {
        return <DisabledDashboard text={'There was an error'} />;
    }

    if (!entityCodes.length) {
        return <DisabledDashboard text={'No properties selected'} />;
    }

    if (
        isDashboardConfigurationLoading ||
        isLoadingAsOfDate ||
        isLoadingAccountGraph
    ) {
        return <DisabledDashboard text={'Loading'} />;
    }

    const config = buildDashboardConfigFromSlimJson(
        dashboardConfiguration.config_json,
        dashboardConfiguration.config_type,
    );

    return (
        <PermissionedWrapper
            featureKey={AppFeaturePermissions.FinancialOverview}
            showDisabledView={true}
        >
            <FinancialOverviewGrid
                dashboardConfiguration={config}
                entityCodes={entityCodes}
                asOfDate={moment(asOfDate).toDate()}
                accountGraph={accountGraph}
                selectedDataLevel={selectedDataLevel}
            />
        </PermissionedWrapper>
    );
};

const mapState = (s) => ({
    dashboardConfigurationId: selectDefaultFinancialOverviewDashboardId(s),
    module: 'analytics',
});

export default withRouter(
    compose(
        connect(mapState),
        withDashboardConfigurationRequest,
        withDashboardConfiguration,
    )(FinancialOverviewDashboard),
);
