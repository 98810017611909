import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import useSWR from 'swr';
import { getSourcePropertyCode } from 'waypoint-requests';
import { SourcePropertyCodes } from 'waypoint-types';

export const useGetSourcePropertyCode = (
    entityCode: string | null
): DataHookResponse<SourcePropertyCodes[]> => {
    const { data, error, mutate } = useSWR(
        `/api/properties/${entityCode}/source-property-codes`,
        () => getSourcePropertyCode(entityCode),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
        }
    );

    return {
        isLoading: !data,
        isError: !!error,
        data,
        mutate,
    };
};
