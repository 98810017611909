import { useRentRoll, useLeasesExpiration } from 'waypoint-hooks';
import ExpirationScheduleChart from 'components/leases/expirations/cards/leases-expiration/ExpirationScheduleChart';
import ExpirationScheduleTable from 'components/leases/expirations/cards/leases-expiration/ExpirationScheduleTable';
import React, { useImperativeHandle, useRef, useState } from 'react';
import { PDFExportable } from 'waypoint-utils/pdf/PDFExportable';
import { EntityReportWidgetPdfSettings } from 'components/reports/ReportUtils';
import { DataGridRef } from 'devextreme-react/cjs/data-grid';

interface ExpirationScheduleEntityReportWidgetParams {
    entityCode: string;
    widgetId: string;
    widgetType: string;
    widgetSettings: { [key: string]: any };
    isPDFExport: boolean;
    pdfSettings?: EntityReportWidgetPdfSettings;
}

export const ExpirationScheduleEntityReportWidget = React.forwardRef<
    PDFExportable,
    ExpirationScheduleEntityReportWidgetParams
>(
    (
        {
            entityCode,
            widgetId,
            widgetType,
            widgetSettings,
            isPDFExport,
            pdfSettings,
        }: ExpirationScheduleEntityReportWidgetParams,
        ref,
    ) => {
        const {
            data: rentRoll,
            isLoading,
            isError,
        } = useRentRoll([entityCode], {
            stakeholder: null,
            percentageType: null,
        });

        const dataGridRef = useRef<DataGridRef<any, any>>(null);
        const yearToGroup = widgetSettings?.yearToGroup;
        const monthsToGroup = widgetSettings?.monthsToGroup;
        const groupByOption = widgetSettings?.groupByOption; // it's ok for this to be undefined
        const includeChart = widgetSettings?.includeChart ?? true;
        const includeTable = widgetSettings?.includeTable ?? true;

        // initialize to true for either element if not present so the export can proceed without it
        const [isChartReadyToExport, setIsChartReadyToExport] =
            useState<boolean>(!includeChart);
        const [isTableReadyToExport, setIsTableReadyToExport] =
            useState<boolean>(!includeTable);

        const chartExportId = `chartExpSchedule${widgetType}_${widgetId}`;
        const gridExportId = `gridExpSchedule_${widgetType}_${widgetId}`;

        const leaseExpirationsData = useLeasesExpiration({
            rentRoll,
            groupByOption,
            yearToGroup,
            monthsToGroup,
        });

        useImperativeHandle(ref, () => ({
            isReadyToExport(): boolean {
                return !isLoading && isChartReadyToExport;
            },
        }));

        if (isError) {
            return <div>Error loading expiration schedule data</div>;
        }

        if (isLoading) {
            return <div />;
        }

        /*
        NOTE: do not remove this code! Fusion charts need to be set within a div with a specified width
        when exporting... otherwise, they will export with sizing based on the size of the window rather than
        based on the specified paper size and orientation
        */
        let exportWidth = '1108px';
        if (pdfSettings?.landscape) {
            exportWidth =
                pdfSettings?.paperSize === 'Legal' ? '1900px' : '1468px';
        }

        return (
            <>
                <div
                    style={{ width: isPDFExport ? exportWidth : '100%' }}
                    id={chartExportId}
                >
                    {includeChart && (
                        <div style={{ marginTop: '25px' }}>
                            <ExpirationScheduleChart
                                leaseExpirations={leaseExpirationsData}
                                groupByOption={groupByOption}
                                isPDFExport={isPDFExport}
                                setIsChartReadyToExport={
                                    isPDFExport
                                        ? setIsChartReadyToExport
                                        : undefined
                                }
                            />
                        </div>
                    )}
                </div>
                {includeTable && (
                    <div style={{ marginTop: '10px' }} id={gridExportId}>
                        <ExpirationScheduleTable
                            leaseExpirations={leaseExpirationsData}
                            groupByOption={groupByOption}
                            dataGridRef={dataGridRef}
                            isPDFExport={isPDFExport}
                            setIsTableReadyToExport={
                                isPDFExport
                                    ? setIsTableReadyToExport
                                    : undefined
                            }
                        />
                    </div>
                )}
            </>
        );
    },
);
