import { API_URL } from 'config/constants';
import { DocumentUpload } from 'waypoint-types';

interface FailedUploads {
    fileName: string;
    error: string;
}

export interface UploadDocumentResponse {
    successfulUploads: DocumentUpload[];
    failedUploads: FailedUploads[];
}

const uploadDocument = async (
    documentUpload: FormData,
    referenceType: string,
    referenceId: string
): Promise<UploadDocumentResponse> => {
    const response = await fetch(
        `${API_URL}/document-uploads/${referenceType}/${referenceId}`,
        {
            method: 'POST',
            credentials: 'include',
            body: documentUpload,
        }
    );

    const { data } = await response.json();
    return data as UploadDocumentResponse;
};

export default uploadDocument;
