import React from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';

export const PropertySearchContainer = ({
    children,
}: {
    children: JSX.Element;
}) => {
    /* Google Maps SDK. Must be loaded before children */
    return <Wrapper apiKey={window.___GOOGLE_API_KEY}>{children}</Wrapper>;
};
