import { connect } from 'react-redux';
import { mutateAsync } from 'redux-query';
import RadioOptions from 'components/settings/client/RadioOptions';
import { setDecimalDisplay, setNegativeValue } from 'state/user/actionCreators';
import { setNumberFormat } from 'state/requests';
import { message } from 'antd';
import { isUndefined } from 'lodash';

const mapState = (state) => {
    const {
        configuration: {
            DECIMAL_DISPLAY: decimalDisplay,
            NEGATIVE_VALUE_SYMBOLS: negativeValue,
        },
        client_id: clientId,
    } = state.user;

    return {
        clientId,
        decimalDisplay,
        negativeValue,
    };
};

const mapDispatch = (dispatch) => {
    return {
        setDecimalDisplay: (value) => dispatch(setDecimalDisplay(value)),
        setNegativeValue: (value) => dispatch(setNegativeValue(value)),
        setNumberFormat({ decimalDisplay, negativeValue, clientId }) {
            const nextState = dispatch(
                mutateAsync(
                    setNumberFormat({
                        decimalDisplay,
                        negativeValue,
                        clientId,
                    }),
                ),
            );

            nextState
                .then(
                    ({
                        body: {
                            data: { DECIMAL_DISPLAY, NEGATIVE_VALUE_SYMBOLS },
                            success,
                        },
                        body,
                    }) => {
                        if (!success || isUndefined(body)) {
                            return message.error(
                                'Error',
                                'There was an error updating your settings. Please try again later',
                            );
                        }
                        dispatch(setDecimalDisplay(DECIMAL_DISPLAY));
                        dispatch(setNegativeValue(NEGATIVE_VALUE_SYMBOLS));
                        return message.success('Success', 'Settings saved');
                    },
                )
                .catch(() => {
                    return message.error(
                        'Error',
                        'There was an error updating your settings. Please try again later',
                    );
                });
        },
    };
};

export default connect(mapState, mapDispatch)(RadioOptions);
