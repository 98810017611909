import { API_URL } from 'config/constants';
import { EntityProfile } from 'waypoint-types/properties/types';

interface UpdateOrInsertEntityProfileParams {
    entity_code: string;
    executive_summary?: string;
}

const updateOrInsertEntityProfile = async ({
    entity_code,
    executive_summary,
}: UpdateOrInsertEntityProfileParams): Promise<EntityProfile> => {
    const response = await fetch(
        `${API_URL}/properties/${entity_code}/entity-profile`,
        {
            credentials: 'include',
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                executive_summary,
            }),
        }
    );

    if (!response.ok) {
        throw new Error('Failed');
    }

    const { data } = await response.json();
    return data;
};

export default updateOrInsertEntityProfile;
