import useSWR from 'swr';
import { getCommentThreadAccountMentions } from 'waypoint-requests';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { CommentMention, CommentThreadData } from 'waypoint-types';

export const useGetCommentThreadAccountMentions = (
    commentThread?: CommentThreadData
): DataHookResponse<CommentMention[]> => {
    const { data, mutate, error } = useSWR(
        commentThread
            ? `/account-mentions-by-comment-thread/${commentThread.id}/${commentThread.comments.length}`
            : null,
        () => {
            return getCommentThreadAccountMentions(commentThread?.id as string);
        }
    );

    return {
        data,
        mutate,
        isError: !!error,
        isLoading: !data,
    };
};
