import { API_URL } from 'config/constants';
/**
 *
 * @param {Object} params
 * @param {string} params.entityCodes - Entity codes
 * @param {string} params.accountMappingCode
 * @param {string} params.startDate - ISO Date (YYYY-MM-DD)
 * @param {string} params.endDate - ISO Date (YYYY-MM-DD)
 * @param {boolean} params.isHideNull - Don't return accounts with null values
 * @param {Object} params.selectedDataLevel - Ownership %
 */
const getBalanceSheetOverviewBreakdown = async (params) => {
    const {
        entityCodes,
        account_mapping_code,
        account_mapping_name,
        start_date,
        end_date,
        download,
        isHideNull,
        display_type,
        selectedDataLevel,
    } = params;
    const headers = {
        'Content-Type': 'application/json',
    };

    const url = download
        ? `${API_URL}/balance-sheet-overview/breakdown/download`
        : `${API_URL}/balance-sheet-overview/breakdown`;

    const response = await fetch(url, {
        method: 'POST',
        headers,
        credentials: 'include',
        body: JSON.stringify({
            entity_codes: entityCodes,
            account_mapping_code,
            account_mapping_name,
            start_date,
            end_date,
            display_type: display_type || 'gross',
            is_hide_null: isHideNull,
            selected_data_level: selectedDataLevel,
        }),
    });
    if (!response.ok) {
        throw new Error('FAILED');
    }
    if (download) {
        return response;
    }

    const { data } = await response.json();
    return data;
};

export default getBalanceSheetOverviewBreakdown;
