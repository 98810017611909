import React from 'react';
import PropTypes from 'prop-types';
import { getVarianceColor } from 'utils/tables/renderers';

const Trend = ({ metric, trendIsPositive }) => {
    return (
        <span style={{ fontSize: '10px' }}>
            {metric}
            <i
                style={{
                    color: getVarianceColor(trendIsPositive),
                    fontSize: '20px',
                    marginLeft: '5px',
                }}
                className={
                    trendIsPositive
                        ? 'fa-solid fa-caret-up'
                        : 'fa-solid fa-caret-down'
                }
            />
        </span>
    );
};

Trend.propTypes = {
    metric: PropTypes.any,
    trendIsPositive: PropTypes.string,
};

export default Trend;
