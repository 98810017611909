import React from 'react';
import { Empty } from 'antd';

interface ReportEmptyStateProps {
    text: string;
}

const style = {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const ReportEmptyState = ({ text }: ReportEmptyStateProps) => {
    return (
        <div style={style}>
            <Empty
                style={{
                    ...style,
                    flexDirection: 'column',
                }}
                description={text}
            />
        </div>
    );
};

export default ReportEmptyState;
