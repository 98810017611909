import { isNull, isUndefined, assign } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { timestampToFullDate } from 'components/dates/utils';
import { DASH_DASH } from 'config/constants';

export default function calendarDatePP(WrappedComponent) {
    class CalendarDateCell extends React.Component {
        render() {
            const { cellData, style, color, ...rest } = this.props;
            // NOTE: We must pass rest through instead of spreading props
            //       So that we don't overwrite the formatted cellData
            const isInvalid =
                isNull(cellData) ||
                isUndefined(cellData) ||
                cellData === DASH_DASH;
            const s = assign(
                {},
                {
                    justifyContent: 'flex-start',
                    paddingRight: '15px',
                },
                style
            );
            if (isInvalid) {
                s.fontWeight = 500;
                s.letterSpacing = '1px';
            }

            // Guard against null and undefined values
            // fighting with defaultPP returning '––'
            const datum = isInvalid ? DASH_DASH : timestampToFullDate(cellData);
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            return (
                <WrappedComponent
                    style={s}
                    color={
                        !isInvalid && new Date(datum) < today ? '#f00' : '#555'
                    }
                    orginalColor={color}
                    cellData={datum}
                    originalValue={cellData}
                    {...rest}
                />
            );
        }
    }

    CalendarDateCell.propTypes = {
        style: PropTypes.object,
        cellData: PropTypes.any,
        color: PropTypes.string,
    };

    return CalendarDateCell;
}
