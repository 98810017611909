import React, { useState } from 'react';
import * as ReactDOM from 'react-dom';
import { Window } from '@progress/kendo-react-dialogs';
import {
    UploadFileInfo,
    UploadOnAddEvent,
    Upload,
    UploadOnRemoveEvent,
} from '@progress/kendo-react-upload';
import {
    TabStrip,
    TabStripTab,
    TabStripSelectEventArguments,
} from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import {
    ImageAttrs,
    ImageData,
    InsertImageFileProps,
    insertImageFiles,
} from './Utils';
import {
    DEFAULT_FILE_SIZE_LIMIT,
    HTML_EDITOR_IMAGE_SIZE_LIMIT,
    hideKendoUploadElementsClass,
    invalidFileSizes,
} from 'components/uploads/DocumentUploadsUtils';
import {
    useGetDocumentUploadListByReferenceParams,
    useGetEntityCode,
} from 'waypoint-hooks';
import { DocumentUpload } from 'waypoint-types';
import { message } from 'antd';
import { uploadDocument } from 'waypoint-requests';
import { EditorView } from 'prosemirror-view';

interface Props {
    view: EditorView;
    imageNode: string;
    files?: File[];
    src?: HTMLInputElement | null;
    title?: HTMLInputElement | null;
    altText?: HTMLInputElement | null;
    width?: HTMLInputElement | null;
    height?: HTMLInputElement | null;
    insertImageFiles?: (params: InsertImageFileProps) => void;
    onClose: () => void;
    referenceType: string;
}

const restrictions = {
    allowedExtensions: ['.jpg', '.png', '.png'],
    maxFileSize: HTML_EDITOR_IMAGE_SIZE_LIMIT,
};

export const InsertImageDialog = ({
    onClose,
    view,
    imageNode,
    referenceType,
}: Props) => {
    const [selected, setSelected] = useState(0);
    const [filesToUpload, setFilesToUpload] = useState<UploadFileInfo[]>([]);
    const [isUploading, setIsUploading] = useState<boolean>();
    const [altText, setAltText] = useState<string>();
    const [title, setTitle] = useState<string>();
    const [width, setWidth] = useState<string>();
    const [height, setHeight] = useState<string>();

    const entityCode = useGetEntityCode();
    const { mutate } = useGetDocumentUploadListByReferenceParams(
        referenceType,
        entityCode
    );

    const isUploadButtonDisabled =
        invalidFileSizes(restrictions, filesToUpload) ||
        isUploading ||
        !filesToUpload.length;

    const onTabSelect = (event: TabStripSelectEventArguments) => {
        setFilesToUpload([]);
        setSelected(event.selected);
    };

    const onCloseModal = () => {
        onClose.call(undefined);
    };

    const onAddFiles = (event: UploadOnAddEvent) => {
        setFilesToUpload(event.newState);
    };

    const onRemoveFiles = (event: UploadOnRemoveEvent) => {
        const affectedFileUids = event.affectedFiles.map((file) => file.uid);
        setFilesToUpload(
            filesToUpload.filter((file) => !affectedFileUids.includes(file.uid))
        );
    };

    const onInsertByUpload = () => {
        mutate(upload, {
            rollbackOnError: true,
            populateCache: true,
            revalidate: false,
        });
    };

    const upload = async () => {
        try {
            if (!filesToUpload.length) {
                return;
            }

            if (invalidFileSizes(restrictions, filesToUpload)) {
                message.error(
                    `File size should not exceed ${
                        restrictions?.maxFileSize ?? DEFAULT_FILE_SIZE_LIMIT
                    } MB`
                );
                return;
            }

            setIsUploading(true);

            const filesData = new FormData();
            filesToUpload.forEach(
                (file) =>
                    file.getRawFile &&
                    filesData.append('files', file.getRawFile())
            );

            const { successfulUploads: attemptSuccessfulUploads } =
                await uploadDocument(filesData, referenceType, entityCode);

            if (attemptSuccessfulUploads.length) {
                insertFiles(attemptSuccessfulUploads);
            }

            return attemptSuccessfulUploads;
        } catch (e) {
            message.error('Error uploading image');
        } finally {
            setIsUploading(false);
        }
    };

    const checkFileNameMatches = (
        fileNameWithTimestamp: string,
        secondFileName: string
    ) => {
        const timestampExtensionRegex = /-\d+(\.\w+)$/;
        const fileNameWithoutTimestamp = fileNameWithTimestamp.replace(
            timestampExtensionRegex,
            '$1' // matches the first capture group, which is the extension for the file
        );
        return fileNameWithoutTimestamp === secondFileName;
    };

    const insertFiles = (uploads: DocumentUpload[]) => {
        const nodes = view.state.schema.nodes;
        const nodeType = nodes[imageNode];
        const position = null;

        const images: ImageData[] = [];
        const genericalAttributes: ImageAttrs = {
            title: title,
            alt: altText,
            width: width,
            height: height,
        };

        for (const file of filesToUpload) {
            const upload = uploads.find((upload: DocumentUpload) =>
                checkFileNameMatches(upload.document_url, file.name)
            );
            if (upload) {
                images.push({
                    src: upload?.document_cloud_url,
                    upload: upload,
                    file,
                    attrs: genericalAttributes,
                } as ImageData);
            }
        }

        if (images.length) {
            insertImageFiles({
                view,
                nodeType,
                position,
                images,
            });
        }

        view.focus();
        onCloseModal();
    };

    const renderUploadButton = (
        <Button
            style={{ marginRight: '5px' }}
            onClick={onInsertByUpload}
            themeColor={'primary'}
            disabled={isUploadButtonDisabled}
        >
            {isUploading ? 'Uploading...' : 'Upload'}
        </Button>
    );

    const renderCancelButton = (
        <Button onClick={onClose} disabled={isUploading}>
            Cancel
        </Button>
    );

    const renderUploadCancelButtons = (
        <div className={'text-right'}>
            {renderUploadButton}
            {renderCancelButton}
        </div>
    );

    const renderFields = (
        <div style={{ marginTop: '15px' }}>
            <div className="k-edit-label">
                <label htmlFor="k-editor-image-alt">Alternate text</label>
            </div>
            <div className="k-edit-field">
                <input
                    type="text"
                    className="k-textbox"
                    id="k-editor-image-alt"
                    defaultValue={altText}
                    onChange={(e) => setAltText(e.target.value)}
                />
            </div>
            <div className="k-edit-label">
                <label htmlFor="k-editor-image-title">Title</label>
            </div>
            <div className="k-edit-field">
                <input
                    type="text"
                    className="k-textbox"
                    id="k-editor-image-title"
                    defaultValue={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
            </div>
            <div className="k-edit-label">
                <label htmlFor="k-editor-image-width">Width (px)</label>
            </div>
            <div className="k-edit-field">
                <input
                    type="text"
                    className="k-textbox"
                    id="k-editor-image-width"
                    defaultValue={width}
                    onChange={(e) => setWidth(e.target.value)}
                />
            </div>
            <div className="k-edit-label">
                <label htmlFor="k-editor-image-height">Height (px)</label>
            </div>
            <div className="k-edit-field">
                <input
                    type="text"
                    className="k-textbox"
                    id="k-editor-image-height"
                    defaultValue={height}
                    onChange={(e) => setHeight(e.target.value)}
                />
            </div>
        </div>
    );

    return ReactDOM.createPortal(
        <Window
            title="Insert Image"
            onClose={onClose}
            initialWidth={500}
            initialHeight={480}
        >
            <TabStrip
                selected={selected}
                onSelect={onTabSelect}
                animation={false}
            >
                <TabStripTab title="Upload">
                    <div className="k-edit-form-container pt-3 pb-3">
                        <div className="k-edit-label">
                            <label htmlFor="k-editor-image-width">Image</label>
                        </div>
                        <div className="k-edit-field">
                            <Upload
                                className={hideKendoUploadElementsClass}
                                batch={false}
                                multiple={true}
                                defaultFiles={[]}
                                withCredentials={false}
                                accept={restrictions?.allowedExtensions?.join(
                                    ', '
                                )}
                                autoUpload={false}
                                onAdd={onAddFiles}
                                onRemove={onRemoveFiles}
                                selectMessageUI={() => <>Select files</>}
                                restrictions={restrictions}
                            />
                            {renderFields}
                            {renderUploadCancelButtons}
                        </div>
                    </div>
                </TabStripTab>
            </TabStrip>
            <style>{`.k-dropzone { width: 100%; }`}</style>
        </Window>,
        document.body
    );
};
