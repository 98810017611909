import { useCallback, useEffect, useMemo } from 'react';
import DataGrid, {
    Export,
    HeaderFilter,
    Column,
    Paging,
    Summary,
    GroupItem,
    Grouping,
    GroupPanel,
    Toolbar,
    Item as ToolbarItem,
    TotalItem,
    Lookup,
    Format,
    ColumnChooser,
    StateStoring,
    FilterPanel,
    DataGridRef,
    ColumnChooserSelection,
    Position,
} from 'devextreme-react/data-grid';
import { InitializedEvent } from 'devextreme/ui/data_grid';
import { Button } from 'antd';
import { Dictionary } from 'ts-essentials';
import {
    PropertyType,
    CapitalProject,
    CellInfoType,
    SavedConfigFilterType,
    SavedConfigurationState,
    SavedConfiguration,
} from 'waypoint-types';
import {
    usePlanningCapitalConfig,
    useGridConfigSettings,
    useGetLeasesPerformanceOverview,
} from 'waypoint-hooks';
import {
    DisabledDashboard,
    ExpandAndCollapseButton,
    SelectionsBanner,
} from 'waypoint-react';
import {
    getRowCostTotal,
    tagRender,
    wordWrapColumn,
    groupCellBoolean,
    groupCell,
    getTextValueOrEmpty,
    getGroupedItemCount,
    filterCapitalProjects,
    generateDefaultYears,
    convertNumbersToRangeValues,
} from './PlanningCapitalProjectUtils';
import {
    SavedConfigEditor,
    SavedConfigManageDropdown,
    SavedGridConfigSelect,
    applyStoredConfiguration,
    createSavedConfigurationPayload,
} from 'components/saved-configurations';
import { FilterReferenceTypes } from 'contexts';
import React from 'react';
import { DatePicker } from 'waypoint-react';
import { PlusOutlined, SettingOutlined } from '@ant-design/icons';
import PlanningCapitalProjectModal from './PlanningCapitalProjectModal';
import PlanningCapitalProjectTypesSettings from './PlanningCapitalProjectTypesSettings';
import { getPropertyName, getPropertyOptions } from 'waypoint-utils/entity';
import CapitalProjectEditorCell from './CapitalProjectEditorCell';
import { onContentReady } from 'waypoint-utils';
import { PlanningCapitalPlanExportableGrid } from 'components/reports/components/entity-report-widgets/report-widget-export-grids/PlanningCapitalProjectPlanExportableGrid';
import DataSource from 'devextreme/data/data_source';

const { RangePicker } = DatePicker;

interface PlanningCapitalProjectPlanProps {
    properties: Dictionary<PropertyType>;
    activeTab: string;
    entityCode?: string;
    yearsSelectedForWidget?: number[];
    configSelectedForWidget?: SavedConfiguration | null;
    dataGridRef?: React.RefObject<DataGridRef<any, any>>;
    setIsReadyForPDFExport?: (isReady: boolean) => void;
    hideRangePickerAndViews?: boolean;
    isModalVisible: boolean;
    setIsModalVisible: (isModalVisible: boolean) => void;
    isSettingsModalVisible: boolean;
    setIsSettingsModalVisible: (isSettingsModalVisible: boolean) => void;
    selectedRowData: CapitalProject | null;
    setSelectedRowData: (selectedRowData: CapitalProject | null) => void;
    heightOffset: number;
    isPDFExport?: boolean;
}

const PlanningCapitalProjectPlan = ({
    properties,
    activeTab,
    entityCode,
    yearsSelectedForWidget,
    configSelectedForWidget,
    dataGridRef,
    setIsReadyForPDFExport,
    hideRangePickerAndViews,
    isModalVisible,
    setIsModalVisible,
    isSettingsModalVisible,
    setIsSettingsModalVisible,
    selectedRowData,
    setSelectedRowData,
    heightOffset,
    isPDFExport,
}: PlanningCapitalProjectPlanProps) => {
    const {
        capexDataGrid,
        expanded,
        setExpanded,
        setExpandButtonEnable,
        toggleExpanded,
        createYearsArray,
        filteredYears,
        columnYears,
        expandButtonEnable,
        onRowPrepared,
        onExporting,
        capitalProjectsData,
        entityCodes,
        updateDimensions,
        setYearsSelected,
        isLoading,
        onAddCapitalProject,
        onUpdateCapitalProject,
        onRefreshData,
        calculateCustomSummary,
        onDeleteCapitalProject,
    } = usePlanningCapitalConfig({
        entityCode,
        exportWorksheetName: 'Capital Plan',
    });

    const {
        selectedConfiguration,
        setSelectedConfiguration,
        setGridConfig,
        setLocalConfig,
        saveConfigDisabled,
        configKey,
        savedConfigurationsData,
        resetSelectedConfiguration,
        onDeleteConfig,
        onSaveConfig,
        onUpdateConfig,
        isAdmin,
        isEditorOpen,
        setIsEditorOpen,
        existingConfigNames,
    } = useGridConfigSettings(SavedConfigFilterType.CapitalPlan);

    const { data: leasesData, isLoading: isLeasesLoading } =
        useGetLeasesPerformanceOverview(entityCodes, {
            stakeholder: null,
            percentageType: null,
        });

    const propertyOptions = getPropertyOptions(properties, entityCodes);

    useEffect(() => {
        setLocalConfig({ expanded, filteredYears });
    }, [expanded, filteredYears]);

    useEffect(() => {
        configSelectedForWidget &&
            setSelectedConfiguration(configSelectedForWidget);
    }, [configSelectedForWidget]);
    useEffect(() => {
        if (selectedConfiguration?.filters_json?.local_config) {
            const config = selectedConfiguration.filters_json.local_config;
            config['expanded'] !== undefined && setExpanded(config['expanded']);
            config['filteredYears'] !== undefined
                ? setYearsSelected(config['filteredYears'])
                : setYearsSelected(generateDefaultYears());
            setLocalConfig(config);
            return;
        }
        setExpanded(false);
        setYearsSelected(generateDefaultYears());
        setLocalConfig({ expanded, filteredYears });
    }, [selectedConfiguration, configKey]);

    useMemo(() => {
        if (yearsSelectedForWidget) {
            setYearsSelected(yearsSelectedForWidget);
        }
    }, [yearsSelectedForWidget]);

    const groupCellPropertyName = (options: CellInfoType | any) => {
        return (
            <div>
                {getPropertyName(propertyOptions, options.value as string)} (
                {getGroupedItemCount(options)} projects)
            </div>
        );
    };

    useEffect(() => {
        updateDimensions();
    }, [activeTab]);

    const saveState = useCallback(
        (state: SavedConfigurationState) => {
            const config = createSavedConfigurationPayload(state);
            config && setGridConfig(config);
        },
        [setGridConfig, createSavedConfigurationPayload],
    );

    const onClickAddRow = () => {
        setSelectedRowData(null);
        setIsModalVisible(true);
    };

    const handleOnInitialize = (e: InitializedEvent) => {
        if (selectedConfiguration && e?.component) {
            e.component.clearSorting();
            const hiddenColumns = !!entityCode ? ['entity_code'] : [];
            applyStoredConfiguration(
                e.component,
                selectedConfiguration,
                hiddenColumns,
            );
        }
    };

    const capitalProjectsDataFilteredByEntityCodes = filterCapitalProjects(
        capitalProjectsData ?? [],
        entityCodes,
        filteredYears,
        leasesData,
    );

    const dataSource = new DataSource({
        store: capitalProjectsDataFilteredByEntityCodes,
        reshapeOnPush: true, // This is needed to keep the grid options (grouping, etc.) when the data changes
    });

    if (isLeasesLoading || isLoading) {
        return <DisabledDashboard text={'Loading...'} />;
    }

    if (!capitalProjectsData || !leasesData) {
        return <DisabledDashboard text={'No Data'} />;
    }

    if (isPDFExport && setIsReadyForPDFExport) {
        return (
            <PlanningCapitalPlanExportableGrid
                key={selectedConfiguration?.id ?? ''}
                capitalPlanData={capitalProjectsDataFilteredByEntityCodes ?? []}
                savedConfig={selectedConfiguration}
                columnYears={columnYears}
                filteredYears={filteredYears}
                setIsReadyForPDFExport={setIsReadyForPDFExport}
                propertyOptions={propertyOptions}
            />
        );
    }

    return (
        <div>
            <SelectionsBanner
                isCollapsed={false}
                data-testid="capital-plan-selections-banner"
                bannerInlineStyles={{
                    boxShadow: 'none',
                    border: 'none',
                    paddingRight: 11,
                }}
            >
                {!hideRangePickerAndViews && (
                    <div style={{ display: 'flex', marginLeft: '10px' }}>
                        <SavedGridConfigSelect
                            savedConfigurationsData={savedConfigurationsData}
                            selectedConfiguration={selectedConfiguration}
                            setSelectedConfiguration={setSelectedConfiguration}
                        />
                        <SavedConfigManageDropdown
                            onUpdateConfig={onUpdateConfig}
                            onDeleteConfig={onDeleteConfig}
                            resetSelectedConfiguration={
                                resetSelectedConfiguration
                            }
                            hasUserAccess={
                                !isAdmin &&
                                selectedConfiguration?.reference_type !==
                                    FilterReferenceTypes.USER
                            }
                            allowDeleteOnly={saveConfigDisabled}
                            defaultConfigSelected={!selectedConfiguration}
                            setIsEditorOpen={setIsEditorOpen}
                        />
                    </div>
                )}
                {isEditorOpen && (
                    <SavedConfigEditor
                        handleClose={() => setIsEditorOpen(false)}
                        onSave={onSaveConfig}
                        isAdmin={isAdmin}
                        existingConfigNames={existingConfigNames}
                    />
                )}
                {!hideRangePickerAndViews && (
                    <RangePicker
                        placeholder={['Start Year', 'End Year']}
                        value={convertNumbersToRangeValues(columnYears)}
                        defaultValue={convertNumbersToRangeValues(columnYears)}
                        allowClear={true}
                        picker="year"
                        onChange={(values) => createYearsArray(values)}
                        onBlur={() => generateDefaultYears()}
                    />
                )}

                <PlanningCapitalProjectModal
                    key={isModalVisible.toString()}
                    capitalProjectsData={capitalProjectsData ?? []}
                    onAddCapitalProject={onAddCapitalProject}
                    onUpdateCapitalProject={onUpdateCapitalProject}
                    selectedRowData={selectedRowData}
                    setIsVisible={setIsModalVisible}
                    isVisible={isModalVisible}
                    propertyOptions={propertyOptions}
                />
                <PlanningCapitalProjectTypesSettings
                    isVisible={isSettingsModalVisible}
                    tableData={capitalProjectsData ?? []}
                    onClose={() => setIsSettingsModalVisible(false)}
                    onRefreshData={onRefreshData}
                />
                <div
                    style={{
                        display: window.location.href.includes('entity-report')
                            ? 'flex'
                            : 'none',
                        width: '100%',
                        justifyContent: 'space-between',
                    }}
                >
                    <Button
                        style={{ marginRight: 10, marginLeft: 10 }}
                        onClick={onClickAddRow}
                        type="primary"
                        icon={<PlusOutlined />}
                    >
                        Add Project
                    </Button>
                    <Button
                        style={{ marginRight: 0 }}
                        onClick={() =>
                            setIsSettingsModalVisible(!isSettingsModalVisible)
                        }
                        type="default"
                    >
                        <SettingOutlined />
                    </Button>
                </div>
            </SelectionsBanner>
            <DataGrid
                key={`${selectedConfiguration?.id ?? ''}_${
                    configKey ?? ''
                }_${columnYears.join(',')}`}
                ref={dataGridRef ?? capexDataGrid}
                dataSource={dataSource}
                onExporting={onExporting}
                height={window.innerHeight - heightOffset}
                allowColumnReordering={true}
                columnAutoWidth={true}
                showBorders={true}
                showRowLines={true}
                showColumnLines={true}
                rowAlternationEnabled={false}
                onRowPrepared={onRowPrepared}
                data-testid="capital-plan-table"
                noDataText="No data"
                hoverStateEnabled
                onContentReady={(e) => {
                    onContentReady({ e, toggleFunc: setExpandButtonEnable });
                    if (
                        e?.component?.getDataSource() &&
                        setIsReadyForPDFExport
                    ) {
                        setIsReadyForPDFExport(true);
                    }
                }}
                onInitialized={handleOnInitialize}
            >
                <Column
                    dataField="name"
                    caption="Project"
                    allowHeaderFiltering={!hideRangePickerAndViews}
                    showInColumnChooser={false}
                    alignment="left"
                    fixedPosition="left"
                    fixed
                    allowGrouping={false}
                />
                <Column
                    name="fake"
                    allowExporting={false}
                    fixed={true}
                    width={0.001}
                    showInColumnChooser={false}
                />
                <Column
                    dataField="entity_code"
                    visible={!entityCode}
                    showInColumnChooser={!entityCode}
                    caption="Property"
                    allowHeaderFiltering={!hideRangePickerAndViews}
                    alignment="left"
                    cellRender={(data) =>
                        getPropertyName(propertyOptions, data.value)
                    }
                    groupCellRender={groupCellPropertyName}
                >
                    <Lookup
                        dataSource={propertyOptions}
                        valueExpr="value"
                        displayExpr="label"
                    />
                </Column>
                <Column
                    dataField="category.value"
                    caption="Category"
                    allowHeaderFiltering={!hideRangePickerAndViews}
                    alignment="center"
                    groupCellRender={groupCell}
                    customizeText={getTextValueOrEmpty}
                />
                <Column
                    dataField="description"
                    caption="Description"
                    allowHeaderFiltering={false}
                    alignment="left"
                    cssClass={wordWrapColumn}
                    width={300}
                    customizeText={getTextValueOrEmpty}
                    allowGrouping={false}
                />
                <Column
                    visible={false}
                    dataField="subcategory.value"
                    caption="Subcategory"
                    allowHeaderFiltering={!hideRangePickerAndViews}
                    alignment="center"
                    groupCellRender={groupCell}
                    customizeText={getTextValueOrEmpty}
                />
                <Column
                    visible={false}
                    dataField="priority.value"
                    caption="Priority"
                    allowHeaderFiltering={!hideRangePickerAndViews}
                    alignment="center"
                    width={150}
                    groupCellRender={groupCell}
                    cellRender={(data) => tagRender(data, 'priority')}
                />
                <Column
                    visible={false}
                    dataField="status.value"
                    caption="Status"
                    alignment="center"
                    allowHeaderFiltering={!hideRangePickerAndViews}
                    groupCellRender={groupCell}
                    cellRender={(data) => tagRender(data, 'status')}
                />
                <Column
                    visible={false}
                    dataField="underwriting"
                    caption="Incl. in UW"
                    alignment="center"
                    groupCellRender={groupCellBoolean}
                    allowHeaderFiltering={!hideRangePickerAndViews}
                />
                <Column
                    visible={false}
                    dataField="is_escalatable"
                    caption="Escalatable"
                    alignment="center"
                    groupCellRender={groupCellBoolean}
                    allowHeaderFiltering={!hideRangePickerAndViews}
                />
                <Column
                    visible={false}
                    dataField="energy_saving"
                    alignment="center"
                    caption="Energy Savings"
                    groupCellRender={groupCellBoolean}
                    allowHeaderFiltering={!hideRangePickerAndViews}
                />
                {columnYears.map((year: number) => {
                    return (
                        <Column
                            key={`monthly_costs_${year}`}
                            showInColumnChooser={false}
                            dataField={`monthly_costs_${year}`}
                            caption={`${year}`}
                            allowHeaderFiltering={false}
                            alignment="right"
                            dataType="number"
                            format="currency"
                            calculateCellValue={(data: CapitalProject) =>
                                getRowCostTotal(data, 'budget', year)
                            }
                            allowGrouping={false}
                        >
                            <Format type="currency" precision={2} />
                        </Column>
                    );
                })}
                <Column
                    showInColumnChooser={false}
                    caption="Total Budget"
                    dataField="total_budget"
                    allowHeaderFiltering={false}
                    fixedPosition="right"
                    fixed
                    alignment="right"
                    dataType="number"
                    calculateCellValue={(data: CapitalProject) =>
                        getRowCostTotal(data, 'budget', null, filteredYears)
                    }
                    allowGrouping={false}
                    minWidth={110}
                    width={'auto'}
                >
                    <Format type="currency" precision={2} />
                </Column>

                <Column
                    caption="Budget / SF"
                    dataField="budget_psf"
                    allowHeaderFiltering={false}
                    fixedPosition="right"
                    fixed
                    alignment="right"
                    dataType="number"
                    allowGrouping={false}
                >
                    <Format type="currency" precision={2} />
                </Column>

                <Column
                    fixed={true}
                    fixedPosition="right"
                    width={85}
                    allowExporting={false}
                    showInColumnChooser={false}
                    cellRender={(e) => (
                        <CapitalProjectEditorCell
                            data={e.data}
                            setSelectedRowData={setSelectedRowData}
                            setIsModalVisible={setIsModalVisible}
                            onDeleteCapitalProject={onDeleteCapitalProject}
                        />
                    )}
                />

                <Summary calculateCustomSummary={calculateCustomSummary}>
                    <GroupItem
                        column="name"
                        alignByColumn
                        summaryType="count"
                        displayFormat="{0} Projects"
                    />
                    <GroupItem
                        column="total_budget"
                        summaryType="sum"
                        valueFormat={{
                            type: 'currency',
                            precision: 2,
                        }}
                        displayFormat="{0}"
                        alignByColumn
                    />
                    <GroupItem
                        name="budget_psf"
                        showInColumn="budget_psf"
                        summaryType="custom"
                        displayFormat="{0}"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        alignByColumn
                    />
                    <GroupItem column="fake" alignByColumn />
                    {columnYears.map((year: number) => {
                        return (
                            <GroupItem
                                key={`group_item_monthly_costs_${year}`}
                                column={`monthly_costs_${year}`}
                                summaryType="sum"
                                valueFormat={{
                                    type: 'currency',
                                    precision: 2,
                                }}
                                displayFormat="{0}"
                                alignByColumn
                            />
                        );
                    })}
                    <TotalItem
                        column="name"
                        summaryType="count"
                        displayFormat="{0} Projects"
                    />
                    {columnYears.map((year: number) => {
                        return (
                            <TotalItem
                                key={`total_item_monthly_costs_${year}`}
                                column={`monthly_costs_${year}`}
                                summaryType="sum"
                                valueFormat={{
                                    type: 'currency',
                                    precision: 2,
                                }}
                                displayFormat="{0}"
                            />
                        );
                    })}
                    <TotalItem
                        column={'total_budget'}
                        summaryType="sum"
                        valueFormat={{
                            type: 'currency',
                            precision: 2,
                        }}
                        displayFormat="{0}"
                    />

                    <TotalItem
                        name="budget_psf"
                        showInColumn="budget_psf"
                        displayFormat="{0}"
                        valueFormat={{ type: 'currency', precision: 2 }}
                        summaryType={'custom'}
                    />
                </Summary>
                <GroupPanel visible={!hideRangePickerAndViews} />

                <Paging pageSize={20} />
                <FilterPanel visible={!hideRangePickerAndViews} />
                <HeaderFilter
                    allowSelectAll={true}
                    allowSearch={true}
                    visible={true}
                />
                <Grouping
                    autoExpandAll={expanded}
                    contextMenuEnabled={!hideRangePickerAndViews}
                />
                <ColumnChooser
                    enabled={!hideRangePickerAndViews}
                    mode={'select'}
                    height={400}
                    allowSearch={true}
                >
                    <Position
                        my="right top"
                        at="right bottom"
                        of=".dx-datagrid-column-chooser-button"
                    />
                    <ColumnChooserSelection
                        allowSelectAll={true}
                        recursive={true}
                    />
                </ColumnChooser>
                <Toolbar>
                    <ToolbarItem location="after" name="columnChooserButton" />
                    <ToolbarItem name="exportButton" />
                    <ToolbarItem name="searchPanel" />
                    <ToolbarItem location="before" name="groupPanel" />
                    <ToolbarItem
                        location="before"
                        visible={!hideRangePickerAndViews}
                    >
                        <ExpandAndCollapseButton
                            expanded={expanded}
                            toggleExpanded={toggleExpanded}
                            expandButtonEnable={expandButtonEnable}
                        />
                    </ToolbarItem>
                </Toolbar>
                <StateStoring
                    enabled={!setIsReadyForPDFExport}
                    type="custom"
                    savingTimeout={100}
                    customSave={saveState}
                />
                <Export enabled={true} allowExportSelectedData={false} />
            </DataGrid>
        </div>
    );
};

export default PlanningCapitalProjectPlan;
