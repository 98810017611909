import PropTypes from 'prop-types';
import React from 'react';
import defaultStylePP from './defaultStylePP';
/* eslint-disable react/no-multi-comp */

export const IconAndTextCell = ({ cellData, ...rest }) => {
    const { title, iconData } = cellData;
    const { icon, color, size } = iconData;
    const iconStyle = {
        color,
        fontSize: '25px',
        fontWeight: 600,
        cursor: 'pointer',
        alignSelf: 'center',
        paddingRight: '10px',
    };
    return (
        <div title={title} {...rest}>
            <i
                style={iconStyle}
                className={icon || 'fa-solid fa-file'}
                size={size || '1x'}
            />
            {title}
        </div>
    );
};

// TODO (Nicholas): Add a prop types to the IconAndTextCell
IconAndTextCell.propTypes = {
    cellData: PropTypes.object, // TODO (Nicholas): Turn into shapeOf
};

export const IconAndTextCellRenderer = defaultStylePP(IconAndTextCell);
export default IconAndTextCellRenderer;
