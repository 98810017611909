import useSWR from 'swr';
import { useGetSelectedFilteredEntityCodes } from 'waypoint-hooks';
import { getUsersWithEntityPermissions } from 'waypoint-requests';
import { MentionableDataSource, User } from 'waypoint-types';
import { MutableRefObject, useRef } from 'react';
import { isEqual } from 'lodash';
import { roles as userRoles } from 'config/constants';

export const useGetMentionableUsers = (
    entity_codes?: string[],
    strictAccessToAll?: boolean
): MentionableDataSource[] | undefined => {
    const globalEntityCodes = useGetSelectedFilteredEntityCodes();
    const entityCodes = entity_codes ?? globalEntityCodes;
    const mapUsers = (
        users: User[] | undefined
    ): MentionableDataSource[] | undefined => {
        if (!users) {
            return undefined;
        }

        return users
            .sort((a: User, b: User) =>
                `${a.firstname} ${a.lastname}`.localeCompare(
                    `${b.firstname} ${b.lastname}`
                )
            )
            .map((user) => {
                return {
                    id: user.id,
                    text: `${user.firstname} ${user.lastname}`,
                    profile_image_url: user.profile_image_url,
                    isAdmin: user.roles?.includes(userRoles.CLIENT_ADMIN),
                };
            });
    };

    const { data: mentionableUsers } = useSWR(
        `/api/entity-permissions/entity/${entityCodes.join(',')}`,
        () => getUsersWithEntityPermissions(entityCodes, strictAccessToAll),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
        }
    );

    const mappedUsers = mapUsers(mentionableUsers);

    const usersRef: MutableRefObject<MentionableDataSource[] | undefined> =
        useRef<MentionableDataSource[] | undefined>(mappedUsers);

    if (!isEqual(usersRef.current, mappedUsers)) {
        usersRef.current = mappedUsers;
    }

    return usersRef.current;
};
