import {
    KPISortByOptions,
    KPISortDirections,
    PropertyDetailsValuesType,
} from 'components/leases/constants';
import { EntityAttributesContext } from 'contexts';
import { useContext, useState } from 'react';
import { stringSort } from 'utils/tables/sorters';
import {
    useGetSelectedFilteredEntityCodes,
    useGetLeasesPerformanceOverview,
    useGetClientData,
    useSelectedDataLevel,
} from 'waypoint-hooks';
import { Entity, PropertyDetailsProps } from 'waypoint-types';

export const useGetLeasesPerformanceOverviewConfig = () => {
    const entityCodes: string[] = useGetSelectedFilteredEntityCodes();
    const selectedDataLevel = useSelectedDataLevel();

    const { data, isError, isLoading } = useGetLeasesPerformanceOverview(
        entityCodes,
        selectedDataLevel,
    );

    const clientDisplayName: string = useGetClientData('display_name');

    const [propertyLevelLeaseSummaryData, setPropertyLevelLeaseSummaryData] =
        useState<PropertyDetailsProps[]>(data ?? []);

    const [leftAxis, setLeftAxis] =
        useState<keyof PropertyDetailsValuesType>('occupancy_rate');

    const [rightAxis, setRightAxis] = useState<keyof PropertyDetailsValuesType>(
        'total_annual_per_sq_ft',
    );

    const [sortAxis, setSortAxis] = useState<KPISortByOptions>(
        KPISortByOptions.ALPHABETICAL,
    );
    const [sortDirection, setSortDirection] = useState<KPISortDirections>(
        KPISortDirections.ASCENDING,
    );

    const entityAttributesContext = useContext(EntityAttributesContext);

    const properties = entityAttributesContext?.data?.entities;

    const propertyLeasesTableStorageKey = `${clientDisplayName}_leases-performance-overview-table`;
    const propertyLeasesDrilldownTableStorageKey = `${clientDisplayName}_leases-performance-overview-drilldown-table`;

    const entitiesFilteredByEntitiesCode: Entity[] | undefined =
        properties?.filter((e) => entityCodes.includes(`${e.entityCode}`));

    const sortedDataByPropertyName = data?.sort(
        (a: PropertyDetailsProps, b: PropertyDetailsProps) =>
            stringSort(b.property_name, a.property_name),
    );

    return {
        data: sortedDataByPropertyName,
        isLoading,
        isError,
        propertyLevelLeaseSummaryData,
        setPropertyLevelLeaseSummaryData,
        leftAxis,
        setLeftAxis,
        rightAxis,
        setRightAxis,
        sortAxis,
        setSortAxis,
        sortDirection,
        setSortDirection,
        propertyLeasesTableStorageKey,
        propertyLeasesDrilldownTableStorageKey,
        entityCodes,
        entitiesFilteredByEntitiesCode,
        selectedDataLevel,
    };
};
