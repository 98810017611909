import { MutableRefObject, useRef } from 'react';
import { isEqual, intersection } from 'lodash';

import {
    useGetFilteredEntityCodes,
    useGetSelectedEntityCodes,
} from 'waypoint-hooks';

/**
 *  Returns an array of filtered and selected entities that are checked for changes each render.
 *  A new array reference is only returned if the array contents actually changed, meaning results
 *  of this hook can be used as part of useEffect safely (without an infinite loop).
 *
 *  Main purpose of this hook is for use within components that need to make an API call when
 *  filtered entities change.
 *
 *  @return entity codes that pass attribute filters AND are checked/selected
 */
export const useGetSelectedFilteredEntityCodes = (): string[] => {
    const { filteredEntityCodes } = useGetFilteredEntityCodes();

    const [selectedEntityCodes] = useGetSelectedEntityCodes();

    const selectedFilteredEntityCodes: string[] = intersection(
        filteredEntityCodes,
        selectedEntityCodes
    );

    const filteredEntityCodesRef: MutableRefObject<string[]> = useRef<string[]>(
        selectedFilteredEntityCodes
    );

    if (!isEqual(filteredEntityCodesRef.current, selectedFilteredEntityCodes)) {
        filteredEntityCodesRef.current = selectedFilteredEntityCodes;
    }

    return filteredEntityCodesRef.current;
};
