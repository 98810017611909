import React, { useCallback, useEffect, useState } from 'react';
import theme from 'config/theme';
import { DataGrid } from 'devextreme-react';
import {
    Column,
    ColumnChooser,
    Export,
    Format,
    Paging,
    Scrolling,
    Summary,
    TotalItem,
    SearchPanel,
    DataGridRef,
    ColumnChooserSelection,
    Position,
} from 'devextreme-react/data-grid';
import { connect, RootStateOrAny } from 'react-redux';
import { ExportingEvent, RowPreparedEvent } from 'devextreme/ui/data_grid';
import {
    arrayToCapitalizedString,
    exportExcelFromDevExtremeDataGrid,
    safeDivision,
} from 'waypoint-utils';
import { hasTenantsEnabled } from 'state/user/selectors';
import { TenantData } from './topTenantsUtils';
import { CalculateCustomSummaryOptions } from 'waypoint-utils/dev-extreme/weighted-averages';
import { RentRollProps } from 'waypoint-types';
import { customCalculationFields } from 'components/leases/expirations/cards/rent-roll/utils';
import { Dictionary } from 'ts-essentials';
import { TopTenantExportableGrid } from 'components/reports/components/entity-report-widgets/report-widget-export-grids/TopTenantsExportableGrid';

interface TopTenantsTableParams {
    data: TenantData[];
    sqFtCalculations: Dictionary<number>;
    dataGridRef?: React.RefObject<DataGridRef<any, any>>;
    tenantsEnabled: boolean;
    isPDFExport?: boolean;
    isPropertyProfilePage?: boolean;
}

const TopTenantsTable = ({
    data,
    sqFtCalculations,
    dataGridRef,
    tenantsEnabled,
    isPDFExport,
    isPropertyProfilePage,
}: TopTenantsTableParams) => {
    const [tableHeight, setTableHeight] = useState<number>(
        window.innerHeight - 510,
    );

    useEffect(() => {
        const handleResize = () => {
            setTableHeight(
                window.innerHeight - (isPropertyProfilePage ? 660 : 610),
            );
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const onRowPrepared = (e: RowPreparedEvent) => {
        if (e.rowType === 'group') {
            e.rowElement.style.backgroundColor = theme.colors.grays.background;
        }
        if (e.rowType === 'header') {
            e.rowElement.style.fontWeight = 'bold';
            e.rowElement.style.color = '#000';
        }
        if (e.rowType === 'totalFooter') {
            e.rowElement.style.fontWeight = 'bold';
        }
    };

    const calculateCustomSummary = (
        options: CalculateCustomSummaryOptions<RentRollProps, any>,
    ) => {
        if (customCalculationFields.includes(options.name)) {
            switch (options.summaryProcess) {
                case 'start': {
                    options.totalValue = {
                        rentable_sq_ft: 0,
                        [options.name]: 0,
                    };
                    break;
                }
                case 'calculate': {
                    options.totalValue.rentable_sq_ft +=
                        options.value.rentable_sq_ft;
                    options.totalValue[options.name] +=
                        options.name === 'total_monthly_per_sq_ft'
                            ? options.value.total_monthly
                            : options.value.total_annual;
                    break;
                }
                case 'finalize': {
                    options.totalValue = safeDivision(
                        Number(
                            options.totalValue[
                                options.name as keyof RentRollProps
                            ],
                        ),
                        options.totalValue.rentable_sq_ft,
                    );
                    break;
                }
            }
        } else if (options.name === 'percentage_rentable_sq_ft') {
            if (options.summaryProcess === 'finalize') {
                options.totalValue = safeDivision(
                    data.reduce(
                        (acc, tenant) => acc + tenant.rentable_sq_ft,
                        0,
                    ),
                    sqFtCalculations.totalRentableSqFt,
                );
            }
        }
    };

    const onExporting = useCallback(async (e: ExportingEvent) => {
        await exportExcelFromDevExtremeDataGrid(e, {
            worksheetName: 'Recurring Charges',
            filename: 'Recurring_Charges.xlsx',
        });
    }, []);

    const dataWithIndustryPrettified = data.map((tenant) => ({
        ...tenant,
        suites: tenant.suites.length,
        properties: tenant.properties.length,
        industry: arrayToCapitalizedString(tenant.industry),
    }));

    const isGroupingAndExcelExportEnabled = !isPDFExport;

    if (isPDFExport) {
        return (
            <TopTenantExportableGrid
                data={dataWithIndustryPrettified}
                tenantsEnabled={tenantsEnabled}
            />
        );
    }

    return (
        <DataGrid
            id="top-tenants-data-grid"
            ref={dataGridRef}
            dataSource={dataWithIndustryPrettified}
            height={isPDFExport ? 400 : tableHeight}
            allowColumnReordering={true}
            showBorders={true}
            onRowPrepared={onRowPrepared}
            wordWrapEnabled={false}
            hoverStateEnabled={true}
            onExporting={onExporting}
            columnMinWidth={50}
            style={{
                width: '100%',
                minHeight: isPropertyProfilePage ? '342px' : '375px',
            }}
            scrolling={{ mode: 'virtual' }}
        >
            <Export
                enabled={isGroupingAndExcelExportEnabled}
                allowExportSelectedData={false}
            />
            <ColumnChooser
                enabled={isGroupingAndExcelExportEnabled}
                mode={'select'}
                height={350}
                allowSearch={true}
            >
                <Position
                    my="right top"
                    at="right bottom"
                    of=".dx-datagrid-column-chooser-button"
                />
                <ColumnChooserSelection
                    allowSelectAll={true}
                    recursive={true}
                />
            </ColumnChooser>
            <Summary calculateCustomSummary={calculateCustomSummary}>
                <TotalItem
                    column="tenant"
                    name="tenant"
                    summaryType="count"
                    displayFormat="Total: {0} Tenants"
                    valueFormat={{ type: 'fixedPoint', precision: 0 }}
                />
                <TotalItem
                    column="suites"
                    name="suites"
                    summaryType="sum"
                    displayFormat="{0}"
                    valueFormat={{ type: 'fixedPoint', precision: 0 }}
                />
                <TotalItem
                    column="rentable_sq_ft"
                    name="SF"
                    summaryType="sum"
                    displayFormat="{0}"
                    valueFormat={{ type: 'fixedPoint', precision: 2 }}
                />
                <TotalItem
                    column="percentage_occ_sq_ft"
                    name="% of Occ. Sf."
                    summaryType="sum"
                    displayFormat="{0}"
                    valueFormat={{ type: 'percent', precision: 2 }}
                />
                <TotalItem
                    showInColumn="percentage_rentable_sq_ft"
                    name="percentage_rentable_sq_ft"
                    summaryType="custom"
                    displayFormat="{0}"
                    valueFormat={{ type: 'percent', precision: 2 }}
                />
                <TotalItem
                    column="total_annual"
                    name="Total Annual"
                    summaryType="sum"
                    displayFormat="{0}"
                    valueFormat={{ type: 'currency', precision: 2 }}
                />
                <TotalItem
                    column="total_annual_percentage"
                    name="Total Annual"
                    summaryType="sum"
                    displayFormat="{0}"
                    valueFormat={{ type: 'percent', precision: 2 }}
                />
                <TotalItem
                    name="total_annual_per_sq_ft"
                    showInColumn="total_annual_per_sq_ft"
                    summaryType="custom"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                />
            </Summary>
            <Column
                dataField="tenant"
                caption="Tenant"
                width={'auto'}
                fixed={true}
            />
            {!isPropertyProfilePage ? (
                <Column
                    dataField="properties"
                    caption="Properties"
                    alignment={'center'}
                    width={120}
                />
            ) : null}
            <Column
                dataField="suites"
                caption="Suites"
                alignment={'center'}
                width={120}
            />
            <Column
                dataField="rentable_sq_ft"
                caption="Rentable SF"
                alignment={'center'}
                width={120}
            >
                <Format type="fixedPoint" precision={2} />
            </Column>
            <Column
                dataField="percentage_occ_sq_ft"
                caption="% of Occ. SF"
                alignment={'center'}
                width={120}
            >
                <Format type="percent" precision={2} />
            </Column>
            <Column
                dataField="percentage_rentable_sq_ft"
                caption={'% of RSF'}
                alignment={'center'}
                width={120}
            >
                <Format type="percent" precision={2} />
            </Column>
            <Column
                dataField="total_annual"
                caption={'Annual Charges'}
                alignment={'center'}
                width={120}
            >
                <Format type="currency" precision={2} />
            </Column>
            <Column
                dataField="total_annual_percentage"
                caption={'% of Charges'}
                alignment={'center'}
                width={120}
            >
                <Format type="percent" precision={2} />
            </Column>
            <Column
                dataField="total_annual_per_sq_ft"
                caption={'$/SF'}
                alignment={'center'}
                width={120}
            >
                <Format type="currency" precision={2} />
            </Column>
            {tenantsEnabled ? (
                <Column
                    dataField="industry"
                    caption={'Industry'}
                    allowGrouping={false}
                    visible={true}
                    width={'auto'}
                />
            ) : null}
            <Paging enabled={false} />
            <Scrolling />
            <SearchPanel
                visible={true}
                highlightCaseSensitive={false}
                width={250}
            />
        </DataGrid>
    );
};

const mapState = (state: RootStateOrAny) => {
    const tenantsEnabled = hasTenantsEnabled(state);

    return {
        tenantsEnabled,
    };
};

export default connect(mapState)(TopTenantsTable);
