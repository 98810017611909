import URL from 'url-parse';
import queryString from 'query-string';
import Assembler from 'safe-url-assembler';

export default class Links {
    constructor(url) {
        this.base = new Assembler();

        if (url) {
            const { pathname, query } = new URL(url.split('#/').join(''));
            const { pureid } = queryString.parse(query);

            const pathChunks = pathname.split('/');

            if (pathChunks.includes('property')) {
                this.property(pureid);
            }
            // TODO (Nicholas): Also preserve filter sets
        } else {
            this._state = null;
        }
        // if (this.propertyId) {
        //     throw new Error('No group url includes a property subpath')
        // }
        this._state = this.base.segment('/group').query('pureid', this.groupId);

        return this;
    }

    property(propertyId) {
        if (propertyId) {
            this.propertyId = propertyId;
        }
        // if (this.groupId) {
        //     throw new Error('No property url includes a group subpath')
        // }

        this._state = this.base
            .segment('/property')
            .query('pureid', this.propertyId);

        return this;
    }

    query(key, value) {
        this._state = this._state.query(key, value);
        return this;
    }

    details() {
        this._state = this._state.segment('/profile/details');
        return this;
    }

    financing() {
        this._state = this._state.segment('/profile/financing');
        return this;
    }

    capexProfile() {
        this._state = this._state.segment('/profile/capexProfile');
        return this;
    }

    user() {
        this._state = this.base.segment('/settings/user');
        this.userProfile();
        return this;
    }

    userProfile() {
        this._state = this._state.segment('/profile');
        return this;
    }

    task(taskId) {
        this._state = this._state.segment('/opportunity');
        if (taskId) {
            this._state = this._state.segment('/:taskId').param({ taskId });
        }
        return this;
    }

    toString() {
        const result = this._state.toString();
        this._state = null;
        return result;
    }
}
