export interface AttributeFilter {
    /** Title of filter */
    title: string;

    /** Key of attribute to pull values when matched */
    key: string;

    /** List of possible attribute values for this {@link key} */
    values: (string | number)[];
}

export interface SavedFilter {
    /**
     * When present, the filter should be considered persisted to remote storage.
     * When not present, aka `undefined`, this indicates the filter is only
     * stored in local storage, and has not yet been persisted to remote storage.
     *
     * Readonly because the client should never be modifying the id
     */
    readonly id?: string;

    /** Displayed as the human identifier of this filter */
    name: string;

    /**
     * Readonly because the client should never be modifying the id.
     *
     * Paired with {@link reference_type}
     */
    readonly reference_id?: string;

    /** Indicates the type of the polymorphic relation the filter belongs to */
    reference_type: FilterReferenceTypes;

    readonly filters_json?: AttributeFilter[];

    filters: AttributeFilter[];
}

export enum FilterReferenceTypes {
    CLIENT = 'client',
    USER = 'user',
}

export interface SavedFiltersContextType {
    savedFilters: SavedFilter[] | null;
    isSavedFiltersError: boolean;
    isSavedFiltersLoading: boolean;
    saveNewFilter: (filter: SavedFilter) => Promise<SavedFilter>;
    updateFilter: (filter: SavedFilter) => Promise<void>;
    deleteFilter: (id: string) => Promise<void>;
}
