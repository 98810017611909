import React, { useState } from 'react';
import { Radio, Select } from 'antd';
import {
    defaultGroupByOptionValue,
    defaultMonthsToGroupValue,
    defaultYearToGroupValue,
    expirationGroupingByOptions,
    expirationGroupingBySelectOptions,
    monthsGroupingOptions,
    yearGroupingOptions,
} from 'components/leases/expirations/cards/leases-expiration/utils';
import { Checkbox } from 'waypoint-react';
import { ReportWidgetSettingsBuilderProps } from 'waypoint-types';
import ReportWidgetSettingsModal from 'components/reports/report-widget/ReportWidgetSettingsModal';

const ExpirationScheduleSettingsBuilder = ({
    entityCodes,
    isModalOpen,
    setIsModalOpen,
    onSaveWidgetSettings,
    widgetType,
    currentSettings,
}: ReportWidgetSettingsBuilderProps): JSX.Element => {
    const [yearToGroup, setYearToGroup] = useState<number | undefined>(
        currentSettings?.yearToGroup ?? defaultYearToGroupValue
    );

    const [monthsToGroup, setMonthsToGroup] = useState<number>(
        currentSettings?.monthsToGroup ?? defaultMonthsToGroupValue
    );
    const [groupByOption, setGroupByOption] =
        useState<expirationGroupingByOptions>(
            currentSettings?.groupByOption ?? defaultGroupByOptionValue
        );

    const [includeChart, setIncludeChart] = useState<boolean>(
        currentSettings?.includeChart ?? true
    );
    const [includeTable, setIncludeTable] = useState<boolean>(
        currentSettings?.includeTable ?? true
    );

    const buildReportWidgetSettingsInputs = () => {
        return {
            groupByOption,
            monthsToGroup,
            yearToGroup,
            includeChart,
            includeTable,
        };
    };

    return (
        <ReportWidgetSettingsModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            settingsJsonBuilder={buildReportWidgetSettingsInputs}
            onSaveWidgetSettings={onSaveWidgetSettings}
            widgetType={widgetType}
            saveDisabled={!includeChart && !includeTable}
        >
            <div>
                Group by
                <Radio.Group
                    style={{ marginLeft: 10 }}
                    defaultValue={groupByOption}
                    buttonStyle="solid"
                    onChange={(e) => setGroupByOption(e.target.value)}
                >
                    {expirationGroupingBySelectOptions.map((option) => (
                        <Radio.Button value={option.value}>
                            {option.label}
                        </Radio.Button>
                    ))}
                </Radio.Group>
            </div>
            {groupByOption === expirationGroupingByOptions.monthYear ? (
                <div style={{ marginTop: 10 }}>
                    Consolidate After
                    <Select
                        defaultValue={monthsGroupingOptions[1].value}
                        style={{ width: 195, marginLeft: 10 }}
                        options={monthsGroupingOptions}
                        placeholder={'# of Months'}
                        value={monthsToGroup}
                        onChange={(item: number) => setMonthsToGroup(item)}
                    />
                </div>
            ) : (
                <div style={{ marginTop: 10 }}>
                    Consolidate After
                    <Select
                        disabled={
                            groupByOption === expirationGroupingByOptions.month
                        }
                        style={{ width: 195, marginLeft: 10 }}
                        options={yearGroupingOptions}
                        placeholder={'# of Years'}
                        value={yearToGroup}
                        onChange={(item: number) => setYearToGroup(item)}
                        allowClear
                    />
                </div>
            )}
            <div style={{ marginBottom: '10px' }}>
                <Checkbox
                    checked={includeChart}
                    onChange={(ev) => setIncludeChart(ev.target.checked)}
                >
                    <span>Include Chart</span>
                </Checkbox>
            </div>
            <div>
                <Checkbox
                    checked={includeTable}
                    onChange={(ev) => setIncludeTable(ev.target.checked)}
                >
                    <span>Include Table</span>
                </Checkbox>
            </div>
        </ReportWidgetSettingsModal>
    );
};

export default ExpirationScheduleSettingsBuilder;
