import { API_URL } from 'config/constants';

export async function getEntityProfileCardExcelFile(
    entityCode: string,
    type: string,
    years: number[]
): Promise<Response> {
    const response = await fetch(
        `${API_URL}/properties/${entityCode}/profile-cards/${type}/export`,
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Accept: 'text/csv' },
            credentials: 'include',
            body: JSON.stringify({
                years,
            }),
        }
    );

    if (!response.ok) {
        throw new Error(
            `Failed to get entity profile cards (${type}) report file`
        );
    }

    return response;
}
