import { API_URL } from 'config/constants';
import { ComparisonFilterPayload } from 'components/financials/comparative-income-statement/banner/ComparisonSelectionsBannerUtils';
import { CommentData, ReportMetadata } from 'waypoint-types';

export interface GetReportMetadataCommentsParams {
    reportType: string;
    referenceId: string;
    referenceType: string;
    filters: ComparisonFilterPayload[];
}

/**
 * @param {Object} params - API parameters for comments lookup
 * @param {string} params.reportType
 * @param {string} params.referenceId
 * @param {string} params.referenceType
 * @param {Object[]} params.filters
 * @return Two sets of comments: current, and comments on everything that is not within the filters param
 */
const getReportMetadataComments = async ({
    reportType,
    referenceId,
    referenceType,
    filters,
}: GetReportMetadataCommentsParams): Promise<{
    comments: CommentData[];
    reportMetadata: ReportMetadata;
}> => {
    const response = await fetch(`${API_URL}/report-metadata/search/comments`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            report_type: reportType,
            reference_type: referenceType,
            reference_id: referenceId,
            filters,
        }),
    });

    const { data } = await response.json();

    return {
        comments: data.comments,
        reportMetadata: data.report_metadata,
    };
};

export default getReportMetadataComments;
