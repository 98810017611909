// Improve time complexity and use memoization
const searchAccountGraphForAccount = (accountGraph, accountMappingCode) => {
    if (!accountGraph) return null;
    let foundNode = null;
    const traverse = (collection, code) => {
        collection.forEach((node) => {
            if (node.account_mapping_code === code) {
                foundNode = node;
                return;
            }
            if (node.children) {
                traverse(node.children, accountMappingCode);
            }
        });
    };
    traverse(accountGraph, accountMappingCode);
    return foundNode;
};

export default searchAccountGraphForAccount;
