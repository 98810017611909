import { HtmlEditor } from 'devextreme-react';
import { Button, Space } from 'antd';
import React, { useEffect, useRef } from 'react';
import {
    HtmlEditorRef,
    Item,
    Mention,
    Toolbar,
} from 'devextreme-react/html-editor';
import 'devextreme/ui/html_editor/converters/markdown';
import { css } from 'emotion';
import { message } from 'antd';
import { useCommentsPane } from 'contexts/comments/CommentsContext';

const MAX_COMMENT_LENGTH = 100000;

interface CommentEditorProps {
    headerText?: string;
    defaultValue?: string;
    onSubmit: (
        value: string,
        userMentions: number[],
        accountMentions: string[],
        parentId: string | null,
        commentId: string | null,
    ) => void;
    isClosable?: boolean;
    onClose?: () => void;
    height?: string;
    showToolbar?: boolean;
    buttonCaption?: string;
    placeholder?: string;
}

export const CommentEditor = ({
    defaultValue,
    onSubmit,
    isClosable,
    onClose,
    height = '100px',
    showToolbar = true,
    buttonCaption = 'Comment',
    placeholder,
}: CommentEditorProps) => {
    // Adding mentions to the HtmlEditor while binding to the `value` attribute of HtmlEditor caused some buggy behavior
    // Use a reference instead to avoid re-rendering the component every time the value changes.
    const internalValue = useRef<string>(defaultValue ?? '');
    const editorRef = React.createRef<HtmlEditorRef>();

    const {
        update: updateCommentsPane,
        selectedCommentAccountMappingId,
        userMentionOptions,
        accountMentionOptions,
    } = useCommentsPane();

    useEffect(() => {
        if (selectedCommentAccountMappingId) {
            const insertMentionedAccount = (
                selectedCommentAccountMappingId: string,
            ) => {
                const accountMention = accountMentionOptions?.find(
                    (option) => option.id === selectedCommentAccountMappingId,
                );

                editorRef?.current?.instance().reset();

                if (accountMention) {
                    editorRef?.current?.instance().insertEmbed(-1, 'mention', {
                        marker: '#',
                        id: accountMention?.id,
                        value: accountMention?.text,
                    });

                    editorRef?.current
                        ?.instance()
                        .setSelection(accountMention.text.length ?? 0, 0);
                }
            };

            insertMentionedAccount(selectedCommentAccountMappingId);
        }
    }, [selectedCommentAccountMappingId, accountMentionOptions, editorRef]);

    useEffect(() => {
        if (editorRef?.current) {
            editorRef?.current?.instance()?.focus();
        }
    }, [editorRef]);

    const accountMentionsEnabled =
        accountMentionOptions !== undefined && accountMentionOptions.length > 0;
    const placeholderText = accountMentionsEnabled
        ? placeholder
        : 'Add a comment...type @ to mention someone.';

    return (
        <div style={{ padding: '10px' }}>
            <Space
                direction="vertical"
                size="small"
                style={{ display: 'flex' }}
            >
                <HtmlEditor
                    ref={editorRef}
                    height={height}
                    valueType={'html'}
                    defaultValue={defaultValue ?? ''}
                    onValueChanged={(e) => {
                        internalValue.current = e.value;
                    }}
                    placeholder={placeholderText}
                >
                    {userMentionOptions !== undefined && (
                        <Mention
                            dataSource={userMentionOptions}
                            searchExpr={'text'}
                            displayExpr={'text'}
                            valueExpr={'id'}
                        />
                    )}
                    {accountMentionsEnabled && (
                        <Mention
                            marker={'#'}
                            dataSource={accountMentionOptions}
                            searchExpr={'text'}
                            displayExpr={'text'}
                            valueExpr={'id'}
                        />
                    )}

                    {showToolbar && (
                        <Toolbar>
                            <Item name="undo" />
                            <Item name="redo" />
                            <Item name="separator" />
                            <Item name="bold" />
                            <Item name="italic" />
                            <Item name="separator" />
                            <Item name="orderedList" />
                            <Item name="bulletList" />
                            <Item name="link" />
                        </Toolbar>
                    )}
                </HtmlEditor>

                <div>
                    <Button
                        type="primary"
                        onClick={() => {
                            if (
                                internalValue.current.length >
                                MAX_COMMENT_LENGTH
                            ) {
                                message.error(
                                    'Comment is too long!, An error occurred while saving comment',
                                );
                                return;
                            }

                            if (!internalValue.current) {
                                return;
                            }

                            const semanticHTML = editorRef?.current
                                ?.instance()
                                .getQuillInstance()
                                .getSemanticHTML();
                            const mentionsWrapper =
                                document.createElement('div');
                            mentionsWrapper.innerHTML = semanticHTML;

                            const mentions =
                                mentionsWrapper.getElementsByClassName(
                                    'dx-mention',
                                );
                            const filterMentions = (type: string) => {
                                return Array.from(mentions)
                                    .filter((mention) => {
                                        if (type === 'user') {
                                            return (
                                                mention.attributes.getNamedItem(
                                                    'data-marker',
                                                )?.value === '@'
                                            );
                                        }
                                        if (type === 'account') {
                                            return (
                                                mention.attributes.getNamedItem(
                                                    'data-marker',
                                                )?.value === '#'
                                            );
                                        }
                                    })
                                    .map((mention) => {
                                        if (type === 'user') {
                                            return parseInt(
                                                mention.attributes.getNamedItem(
                                                    'data-id',
                                                )?.value ?? '0',
                                            );
                                        }
                                        if (type === 'account') {
                                            return (
                                                mention.attributes.getNamedItem(
                                                    'data-id',
                                                )?.value ?? '0'
                                            );
                                        }
                                    });
                            };

                            const accountPayload: string[] = filterMentions(
                                'account',
                            ) as string[];
                            const mentionPayload: number[] = filterMentions(
                                'user',
                            ) as number[];

                            onSubmit(
                                internalValue.current,
                                [...new Set(mentionPayload)],
                                [...new Set(accountPayload)],
                                null,
                                null,
                            );

                            updateCommentsPane({
                                selectedCommentAccountMappingId: null,
                            });

                            internalValue.current = '';
                            editorRef?.current
                                ?.instance()
                                .delete(
                                    0,
                                    editorRef.current?.instance().getLength(),
                                );
                        }}
                    >
                        {buttonCaption}
                    </Button>

                    {isClosable && (
                        <Button
                            style={{ marginLeft: '10px' }}
                            type="default"
                            onClick={() => {
                                onClose && onClose();
                            }}
                        >
                            Cancel
                        </Button>
                    )}
                </div>
            </Space>
        </div>
    );
};
