import { API_URL } from 'config/constants';

interface ExportReportByIdParams {
    reportId: string;
    entityCode: string;
    widgetIndices: number[];
    fullExport: boolean;
}

const exportReportById = async ({
    reportId,
    entityCode,
    widgetIndices,
    fullExport,
}: ExportReportByIdParams): Promise<Response> => {
    const response = await fetch(
        `${API_URL}/reports/export/${reportId}/${entityCode}/${fullExport}`,
        {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(widgetIndices),
        }
    );

    if (!response.ok) {
        throw new Error('FAILED');
    }

    return response;
};

export default exportReportById;
