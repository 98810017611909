import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { RootStateOrAny, connect } from 'react-redux';
import { RouteUrls } from 'routes/RouteConstants';
import {
    hasBalanceSheetEnabled,
    hasCrosstabEnabled,
    hasVarianceReportDashboardEnabled,
} from 'state/user/selectors';
import {
    MenuSections,
    menuItemStyle,
    overrideMenuItems,
    sidebarDrawerMenuTitle,
} from 'components/app/layout/AppLayoutUtils';
import { Dictionary } from 'ts-essentials';

interface FinancialsMenuParams {
    menuSettings: Dictionary<boolean>;
    pathname: string;
    hasBalanceSheet: boolean;
    hasCrosstab: boolean;
    hasVarianceReports: boolean;
}

const FinancialsMenu = ({
    menuSettings,
    pathname,
    hasBalanceSheet,
    hasCrosstab,
    hasVarianceReports,
}: FinancialsMenuParams) => {
    return (
        <Menu mode="vertical" className={overrideMenuItems}>
            {sidebarDrawerMenuTitle(MenuSections.Financials)}
            {menuSettings?.showIncomeStatement && (
                <Menu.Item
                    key="f1"
                    style={menuItemStyle(pathname, [
                        RouteUrls.FINANCIALS_COMPARATIVE_INCOME_STATEMENT,
                    ])}
                >
                    <Link
                        to={RouteUrls.FINANCIALS_COMPARATIVE_INCOME_STATEMENT}
                    >
                        Income Statement
                    </Link>
                </Menu.Item>
            )}
            {hasBalanceSheet && menuSettings?.showBalanceSheet && (
                <Menu.Item
                    key="f2"
                    style={menuItemStyle(pathname, [
                        RouteUrls.FINANCIALS_BALANCE_SHEET,
                    ])}
                >
                    <Link to={RouteUrls.FINANCIALS_BALANCE_SHEET}>
                        Balance Sheet
                    </Link>
                </Menu.Item>
            )}
            {hasCrosstab && menuSettings?.showCrosstab && (
                <Menu.Item
                    key="f3"
                    style={menuItemStyle(pathname, [
                        RouteUrls.FINANCIALS_CROSSTAB,
                    ])}
                >
                    <Link to={RouteUrls.FINANCIALS_CROSSTAB}>Crosstab</Link>
                </Menu.Item>
            )}
            {hasVarianceReports && menuSettings?.showVarianceReports && (
                <Menu.Item
                    key="f4"
                    style={menuItemStyle(pathname, [
                        RouteUrls.FINANCIALS_VARIANCE_REPORT_STATUS,
                    ])}
                >
                    <Link to={RouteUrls.FINANCIALS_VARIANCE_REPORT_STATUS}>
                        Variance Reporting
                    </Link>
                </Menu.Item>
            )}
        </Menu>
    );
};

const mapState = (state: RootStateOrAny) => {
    return {
        hasBalanceSheet: hasBalanceSheetEnabled(state),
        hasCrosstab: hasCrosstabEnabled(state),
        hasVarianceReports: hasVarianceReportDashboardEnabled(state),
    };
};
export default connect(mapState)(FinancialsMenu);
