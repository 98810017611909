import {
    currencyRenderer,
    integerRenderer,
    percentageRenderer,
} from 'utils/tables/renderers';
import {
    ColumnDescriptor,
    ExportableGridSummaryFormatter,
    convertColumnsToAntd,
    getColumnTotalCells,
    headerClass,
    rowClass,
} from './GridExportConversionUtils';
import { Dictionary } from 'ts-essentials';
import { Table } from 'antd';
import { LeaseExpirationScheduleProps } from 'waypoint-types';
import {
    expirationGroupingByOptions,
    expirationLabelsByGroupingOption,
    EXPIRED_KEY,
} from 'components/leases/expirations/cards/leases-expiration/utils';

export const expirationScheduleBaseColumns = (
    groupByKey: string,
    groupByOption?: expirationGroupingByOptions
): ColumnDescriptor[] => [
    {
        title:
            expirationLabelsByGroupingOption[groupByOption ?? 'year'] ||
            'Expiration Year',
        dataIndex: groupByKey,
        key: groupByKey,
        dataType: 'string',
        align: 'center',
        render: (value: string) => (value ? value : EXPIRED_KEY),
    },
    {
        title: '# of Leases Expiring',
        dataIndex: 'total_expiring_leases',
        key: 'total_expiring_leases',
        dataType: 'number',
        render: integerRenderer,
        align: 'center',
    },
    {
        title: 'Area Expiring',
        dataIndex: 'expiring_square_footage',
        key: 'expiring_square_footage',
        dataType: 'number',
        render: integerRenderer,
        align: 'center',
    },
    {
        title: '% of Total Area Expiring',
        dataIndex: 'expiring_square_footage_percentage',
        key: 'expiring_square_footage_percentage',
        dataType: 'number',
        render: percentageRenderer,
        align: 'center',
    },
    {
        title: 'Cumulative Area Expiring',
        dataIndex: 'cumulative_expiring_area',
        key: 'cumulative_expiring_area',
        dataType: 'number',
        render: integerRenderer,
        align: 'center',
    },
    {
        title: 'Cumulative Area Expiring %',
        dataIndex: 'cumulative_expiring_area_percentage',
        key: 'cumulative_expiring_area_percentage',
        dataType: 'number',
        render: (value: number) => `${value}%`,
        align: 'center',
    },
    {
        title: 'Total Annual Base Rent Expiring',
        dataIndex: 'in_place_base_rent',
        key: 'in_place_base_rent',
        dataType: 'number',
        render: currencyRenderer,
        align: 'center',
    },
    {
        title: '% of Gross Annual Rent',
        dataIndex: 'in_place_base_rent_percentage',
        key: 'in_place_base_rent_percentage',
        dataType: 'number',
        render: percentageRenderer,
        align: 'center',
    },
];

const expirationScheduleSummaryFormatters: Dictionary<ExportableGridSummaryFormatter> =
    {
        expiration_year: {
            summaryType: 'custom',
            render: () => 'Total',
        },
        total_expiring_leases: {
            summaryType: 'sum',
            render: integerRenderer,
        },
        in_place_base_rent: {
            summaryType: 'sum',
            render: currencyRenderer,
        },
        in_place_base_rent_percentage: {
            summaryType: 'sum',
            render: percentageRenderer,
        },
        expiring_square_footage: {
            summaryType: 'sum',
            render: integerRenderer,
        },
        expiring_square_footage_percentage: {
            summaryType: 'sum',
            render: percentageRenderer,
        },
        cumulative_expiring_area: {
            summaryType: 'max',
            render: integerRenderer,
        },
        cumulative_expiring_area_percentage: {
            summaryType: 'max',
            render: integerRenderer,
        },
    };

export const ExpirationScheduleExportableGrid = ({
    data,
    groupByKey,
    groupByOption,
}: {
    data: LeaseExpirationScheduleProps[];
    groupByKey: string;
    groupByOption?: expirationGroupingByOptions;
}) => {
    const { gridColumns } = convertColumnsToAntd(
        expirationScheduleBaseColumns(groupByKey, groupByOption),
        true,
        null
    );

    return (
        <Table
            dataSource={data}
            size="small"
            columns={gridColumns}
            pagination={false}
            bordered={true}
            className={headerClass}
            rowClassName={rowClass}
            summary={() =>
                getColumnTotalCells(
                    gridColumns,
                    data,
                    expirationScheduleSummaryFormatters
                )
            }
        />
    );
};
