import { API_URL } from 'config/constants';
import { BudgetUpload } from 'waypoint-types';

export const getLatestEntityBudget = async (
    entityCode: string | null,
    sourcePropertyCode: string | null,
    year: string | null
): Promise<BudgetUpload | null> => {
    if (!entityCode || !sourcePropertyCode || !year) {
        return null;
    }

    const url = `${API_URL}/budget-upload/entity-budget/${entityCode}/${sourcePropertyCode}/${year}`;

    const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
    });

    const res = await response.json();

    if (!response.ok) {
        throw new Error(res.error);
    }

    const { data } = res;
    return data;
};
