import React, { useContext } from 'react';
import { isNil } from 'lodash';
import { DASH_DASH } from 'config/constants';
import { css, cx } from 'emotion';
import { Button } from 'antd';

import {
    useClientPrefixedLocalStorage,
    useGetFilteredEntityCodes,
} from 'waypoint-hooks';
import { EntityAttributesContext, SavedFilter } from 'contexts';
import { ACTIVE_GLOBAL_FILTER_KEY } from 'components/app/global-filter-drawer/GlobalFilterConstants';

const container = css`
    display: flex;
    justify-content: space-between;
`;

interface ClearFiltersButtonProps {
    className?: string;
}

type AppliedFilterType = SavedFilter | null;

const ClearFiltersButton = ({
    className,
}: ClearFiltersButtonProps): JSX.Element => {
    const entityAttributesContext = useContext(EntityAttributesContext);

    const [appliedAttributeFilter, setAppliedGlobalFilter] =
        useClientPrefixedLocalStorage<AppliedFilterType>(
            ACTIVE_GLOBAL_FILTER_KEY,
            null
        );

    const { filteredEntityCodes } = useGetFilteredEntityCodes();

    const entities = entityAttributesContext?.data?.entities;

    const noFiltersText = `No filters applied (${entities?.length}/${entities?.length})`;

    const filteredCopy = !isNil(filteredEntityCodes.length)
        ? filteredEntityCodes.length
        : DASH_DASH;
    const entityCopy = !isNil(entities?.length) ? entities?.length : DASH_DASH;
    const filteredText = `(${filteredCopy} of ${entityCopy} matched)`;

    return (
        <>
            <div className={cx([container, className])}>
                {appliedAttributeFilter ? filteredText : noFiltersText}
                {appliedAttributeFilter?.filters && (
                    <Button
                        data-testid="clear_filters_button"
                        id="clear_filters_button"
                        danger
                        ghost
                        size="small"
                        onClick={() => setAppliedGlobalFilter(null)}
                    >
                        Clear Filters
                    </Button>
                )}
            </div>
        </>
    );
};
export default ClearFiltersButton;
