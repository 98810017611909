import { Table } from 'antd';
import {
    ColumnDescriptor,
    convertColumnsToAntd,
    headerClass,
    rowClass,
} from './GridExportConversionUtils';
import { OccupancyTrendGridData } from 'waypoint-types';

export const occupancyTrendBaseColumns = (months: string[]) => [
    {
        title: 'Metric',
        dataIndex: 'name',
        key: 'name',
        align: 'left',
        dataType: 'string',
    },
    ...months.map((m) => ({
        title: m,
        dataIndex: m,
        key: m,
        align: 'center',
        dataType: 'string',
    })),
    {
        title: 'Average',
        dataIndex: 'total',
        key: 'total',
        align: 'center',
        dataType: 'string',
    },
];

export const OccupancyTrendExportableGrid = ({
    data,
    months,
}: {
    data: OccupancyTrendGridData[];
    months: string[];
}) => {
    const { gridColumns } = convertColumnsToAntd(
        occupancyTrendBaseColumns(months) as ColumnDescriptor[],
        true,
        null
    );

    return (
        <Table
            size="small"
            dataSource={data}
            columns={gridColumns}
            pagination={false}
            bordered={true}
            className={headerClass}
            rowClassName={rowClass}
        />
    );
};
