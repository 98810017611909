import { API_URL } from 'config/constants';
import { UpdateVarianceReport } from 'waypoint-types';

const batchUpdateWorkflowRoles = async (
    entityReportWorkflowRoleParams: UpdateVarianceReport
): Promise<void> => {
    const response = await fetch(`${API_URL}/workflow-roles/batch`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(entityReportWorkflowRoleParams),
    });

    if (!response.ok) {
        throw new Error('Failed to update workflow roles');
    }
};

export default batchUpdateWorkflowRoles;
