import { Button, Card, Result, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { RouteUrls } from 'routes/RouteConstants';
import { PlusOutlined, TableOutlined } from '@ant-design/icons';
import { DataGrid } from 'devextreme-react';
import {
    Column,
    Export,
    Grouping,
    GroupItem,
    GroupPanel,
    HeaderFilter,
    Paging,
    Scrolling,
    SearchPanel,
    Summary,
    TotalItem,
} from 'devextreme-react/data-grid';
import React from 'react';
import { css } from 'emotion';
import theme from 'config/theme';
import {
    useDataGridHeight,
    useGetGroupableAttributes,
    useGetSavedConfigurations,
    useGetUserData,
} from 'waypoint-hooks';
import { SavedConfiguration } from 'waypoint-types';
import { KPIGridConfiguration } from 'shared-types';

const pageStyle = css`
    height: 100%;
    overflow-y: scroll;
    background-color: ${theme.colors.grays.background};
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 35px;
        background-color: ${theme.colors.white};
        border-bottom: 1px solid lightgrey;
    }
    .inner-body {
        padding: 12px 30px;
    }
    .banner {
        background-color: ${theme.colors.white};
        padding: 12px 20px;
        margin: 0px auto 12px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        button {
            margin-left: auto;
        }
    }
`;

export function KPIIndexPage() {
    const { userId, clientId } = useGetUserData();
    const { data: savedGrids } = useGetSavedConfigurations(
        userId,
        clientId,
        'kpis',
    );

    const groupableAttributes = useGetGroupableAttributes();

    const dataGridHeight = useDataGridHeight({
        cardTitleHeight: 44,
        cardOuterPadding: 24,
        cardInnerPadding: 24,
        topBarHeight: 150, // Included the height of the banner
    });

    const createViewButton = (
        <Link to={RouteUrls.KPI_GRID_EDITOR_NEW}>
            <Button type="primary" icon={<PlusOutlined />}>
                Create KPI View
            </Button>
        </Link>
    );

    const emptyView = (
        <Result
            title="Welcome to Waypoint's KPI Hub"
            subTitle={
                <div style={{ maxWidth: '575px', margin: 'auto' }}>
                    <Typography.Text type="secondary">
                        Build and customize views to track key metrics across
                        your entire portfolio. Select the KPIs that matter most
                        to your business and arrange them exactly how you need.
                    </Typography.Text>
                </div>
            }
            icon={<TableOutlined />}
            extra={createViewButton}
        />
    );

    return (
        <div className={pageStyle}>
            <div className="header">
                <div style={{ fontSize: 36, fontWeight: 700 }}>KPI Views</div>
                <div>
                    <Space>{createViewButton} </Space>
                </div>
            </div>
            <div className="inner-body">
                <Card loading={!savedGrids}>
                    {!!savedGrids && savedGrids.length ? (
                        <DataGrid
                            dataSource={savedGrids}
                            width="100%"
                            height={dataGridHeight}
                            allowColumnReordering={false}
                            showBorders={true}
                            wordWrapEnabled={false}
                            hoverStateEnabled={true}
                            columnAutoWidth={true}
                            noDataText="No views found"
                        >
                            <Export enabled allowExportSelectedData={false} />
                            <Column
                                dataField="name"
                                caption="View"
                                cellRender={(cellData) => {
                                    return (
                                        <Link
                                            to={RouteUrls.KPI_GRID.replace(
                                                ':gridId',
                                                cellData.data.id,
                                            )}
                                        >
                                            {cellData.data.name}
                                        </Link>
                                    );
                                }}
                                sortOrder="asc"
                            />
                            <Column
                                dataField="reference_type"
                                caption="View Access"
                                calculateCellValue={({
                                    reference_type,
                                }: SavedConfiguration<KPIGridConfiguration>) => {
                                    return reference_type === 'user'
                                        ? 'Only Me'
                                        : 'Everyone';
                                }}
                                alignment="center"
                            />

                            <Column
                                dataField="filters_json.groupBy.attributeCode"
                                caption="Default Grouping"
                                calculateCellValue={({
                                    filters_json,
                                }: SavedConfiguration<KPIGridConfiguration>) => {
                                    if (filters_json.groupBy.attributeCode) {
                                        return (
                                            groupableAttributes.find(
                                                (attr) =>
                                                    attr.key ===
                                                    filters_json.groupBy
                                                        .attributeCode,
                                            )?.title ??
                                            filters_json.groupBy.attributeCode
                                        );
                                    }

                                    return 'Property';
                                }}
                                width={'auto'}
                                alignment="center"
                            />

                            <Column
                                dataField="filters_json.metrics.length"
                                caption="Selected Columns"
                                calculateCellValue={({
                                    filters_json: { metrics },
                                }: SavedConfiguration<KPIGridConfiguration>) => {
                                    return metrics.length;
                                }}
                                allowFiltering={false}
                                alignment="center"
                            />

                            <Column
                                dataField="timestamps.created_at"
                                caption="Created On"
                                dataType="datetime"
                                format="shortDate"
                                alignment="center"
                            />

                            <Column
                                dataField="timestamps.updated_at"
                                caption="Last Updated"
                                dataType="datetime"
                                format="shortDate"
                                alignment="center"
                            />

                            <Summary>
                                <TotalItem
                                    name="name"
                                    column="name"
                                    displayFormat="{0} Views"
                                ></TotalItem>
                                <GroupItem
                                    name="name"
                                    column="name"
                                    summaryType="count"
                                    displayFormat="{0} Views"
                                    alignByColumn
                                />
                            </Summary>
                            <Paging enabled={false} />
                            <Scrolling />
                            <HeaderFilter
                                visible={true}
                                allowSelectAll={true}
                            />
                            <SearchPanel
                                visible={true}
                                highlightCaseSensitive={false}
                                width={250}
                            />
                            <Grouping autoExpandAll={false} />
                            <GroupPanel visible={true} />
                        </DataGrid>
                    ) : (
                        emptyView
                    )}
                </Card>
            </div>
        </div>
    );
}
