import { useRecurringCharges } from 'waypoint-hooks';
import RentRollRecurringChargeSummary from 'components/leases/expirations/cards/rent-roll/RentRollRecurringChargeSummary';
import React, { useImperativeHandle, useRef, useState } from 'react';
import { PDFExportable } from 'waypoint-utils/pdf/PDFExportable';
import { EntityReportWidgetPdfSettings } from 'components/reports/ReportUtils';

interface RecurringChargeSummaryEntityReportWidgetParams {
    entityCode: string;
    widgetId: string;
    widgetSettings: { [key: string]: any };
    isPDFExport: boolean;
    pdfSettings?: EntityReportWidgetPdfSettings;
}

export const RecurringChargeSummaryEntityReportWidget = React.forwardRef<
    PDFExportable,
    RecurringChargeSummaryEntityReportWidgetParams
>(
    (
        {
            entityCode,
            widgetId,
            widgetSettings,
            isPDFExport,
            pdfSettings,
        }: RecurringChargeSummaryEntityReportWidgetParams,
        ref
    ) => {
        const { data, isLoading } = useRecurringCharges([entityCode], {
            stakeholder: null,
            percentageType: null,
        });

        const [isChartReadyToExport, setIsChartReadyToExport] =
            useState<boolean>(false);

        useImperativeHandle(ref, () => ({
            isReadyToExport(): boolean {
                return !isLoading && isChartReadyToExport;
            },
        }));

        if (!data) {
            return <></>;
        }

        const { rollUp } = data;

        return (
            <div>
                <RentRollRecurringChargeSummary
                    rollUp={rollUp}
                    selectedChargeCode={
                        widgetSettings?.selectedChargeCodes ?? []
                    }
                    isPDFExport={isPDFExport}
                    isLandscape={!!pdfSettings?.landscape}
                    widgetId={widgetId}
                    setIsChartReadyToExport={
                        isPDFExport ? setIsChartReadyToExport : undefined
                    }
                />
            </div>
        );
    }
);
