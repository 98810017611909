import type { SavedFilter } from 'contexts';
import { API_URL } from 'config/constants';

export async function getGlobalFilters(): Promise<SavedFilter[]> {
    const response = await fetch(`${API_URL}/global-filters`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
    });

    if (!response.ok) {
        throw new Error('Failed to get global filters');
    }

    const {
        data: { saved_filters },
    } = await response.json();

    return (saved_filters as SavedFilter[]).map(
        (filter: SavedFilter) =>
            ({
                ...filter,
                filters: filter.filters_json,
                filters_json: undefined,
            }) as SavedFilter
    );
}
