import { API_URL } from 'config/constants';
import { Report } from 'waypoint-types';

interface GetReportByIdParams {
    reportId: string;
    includeEntityReportWidgets: boolean;
}

const getReportById = async ({
    reportId,
    includeEntityReportWidgets,
}: GetReportByIdParams): Promise<Report> => {
    const response = await fetch(
        `${API_URL}/reports/${reportId}/${includeEntityReportWidgets}`,
        {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        }
    );

    if (!response.ok) {
        throw new Error('FAILED');
    }

    const { data } = await response.json();
    return data;
};

export default getReportById;
