import { API_URL } from 'config/constants';
import { EntityReportSubmitted } from 'waypoint-types';

interface SubmitEntityReportParams {
    reportId: string;
    entityCode: string;
    entityReportId: string;
    indices: number[];
}

const submitEntityReport = async ({
    reportId,
    entityCode,
    entityReportId,
    indices,
}: SubmitEntityReportParams): Promise<EntityReportSubmitted> => {
    const response = await fetch(
        `${API_URL}/report-approvals/${reportId}/${entityCode}/${entityReportId}/published-entity-reports`,
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({ indices }),
        }
    );

    if (!response.ok) {
        throw new Error('Failed to submit entity report');
    }

    const { data } = await response.json();

    return data;
};

export default submitEntityReport;
