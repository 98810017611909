import React, {
    useImperativeHandle,
    useState,
    useEffect,
    useMemo,
} from 'react';
import { useCommentsPane } from 'contexts/comments/CommentsContext';
import BalanceSheetTable from 'components/financials/balanceSheet/components/table/BalanceSheetTable';
import {
    decorateFinancialsAccountGraphForAntDesign,
    filterAccountGraph,
    getAllParentAccountMappingCodes,
} from 'waypoint-utils';
import { HIDE_NULLS } from 'waypoint-utils/account-graphs/constants';
import { PDFExportable } from 'waypoint-utils/pdf/PDFExportable';
import {
    useGetNotesByReportMetadataId,
    useGetReportMetadata,
} from 'waypoint-hooks';
import { getBalanceSheetMetadataRequestFilters } from 'components/financials/balanceSheet/components/banner/utils';
import { useGetBalanceSheetData } from 'waypoint-hooks/data-access/useGetBalanceSheetData';
import { AccountGraphNode } from 'waypoint-types';
import { FinancialExportOptions } from 'components/reports/constants';

interface BalanceSheetTableEntityReportWidgetParams {
    entityCode: string;
    widgetId: string;
    widgetType: string;
    widgetSettings: { [key: string]: any };
    isPDFExport: boolean;
}

export const BalanceSheetTableTableEntityReportWidget = React.forwardRef<
    PDFExportable,
    BalanceSheetTableEntityReportWidgetParams
>(
    (
        {
            entityCode,
            widgetId,
            widgetType,
            widgetSettings,
            isPDFExport,
        }: BalanceSheetTableEntityReportWidgetParams,
        ref
    ) => {
        const [balanceSheetNodes, setBalanceSheetNodes] = useState<
            AccountGraphNode[] | null
        >(null);
        const [expandedRows, setExpandedRows] = useState<string[]>([]);
        const [selectedAccountMappingId, setSelectedAccountMappingId] =
            useState<string | null>(null);

        const {
            data: balanceSheet,
            isLoading: isLoadingBalanceSheet,
            isError: isErrorBalanceSheet,
        } = useGetBalanceSheetData(
            {
                entityCodes: [entityCode],
                primaryPeriod: widgetSettings.periods[0],
                secondaryPeriod: widgetSettings.periods[1],
                selectedDataLevel: {
                    stakeholder: null,
                    percentageType: null,
                },
            },
            widgetId
        );

        useEffect(() => {
            if (!balanceSheet) {
                return;
            }

            const decoratedData = decorateFinancialsAccountGraphForAntDesign(
                balanceSheet.children,
                false,
                '',
                null,
                true
            );

            setBalanceSheetNodes(decoratedData);
        }, [balanceSheet]);

        const {
            data: reportMetadata,
            isLoading: isLoadingReportMetadata,
            isError: isErrorLoadingReportMetadata,
        } = useGetReportMetadata({
            reportType: 'balance-sheet',
            referenceId: entityCode,
            referenceType: 'entity',
            filters:
                getBalanceSheetMetadataRequestFilters([
                    widgetSettings.periods[0],
                    widgetSettings.periods[1],
                ]) ?? [],
        });
        const {
            data: reportNotes,
            mutate: mutateReportNotes,
            isLoading: isLoadingNotes,
            isError: isErrorNotes,
        } = useGetNotesByReportMetadataId(reportMetadata?.id ?? '', {
            refreshInterval: 10000,
        });

        const widgetExportId = `narrativePDFWrapper_${widgetType}_${widgetId}`;

        const { update: updateCommentsPane } = useCommentsPane();
        useImperativeHandle(ref, () => ({
            isReadyToExport(): boolean {
                return (
                    !isLoadingBalanceSheet &&
                    !isLoadingReportMetadata &&
                    !isLoadingNotes &&
                    !!balanceSheetNodes
                );
            },
        }));

        useMemo(() => {
            if (
                balanceSheetNodes &&
                widgetSettings?.export_setting ===
                    FinancialExportOptions.FULL_DETAIL
            ) {
                const fullDetailExpandedRows =
                    getAllParentAccountMappingCodes(balanceSheetNodes);
                setExpandedRows(fullDetailExpandedRows);
            }
        }, [balanceSheetNodes, widgetSettings?.export_setting]);

        const filterArray: string[] = [];

        if (widgetSettings.is_hide_null) {
            filterArray.push(HIDE_NULLS);
        }

        const heightOffset = 450;

        if (!balanceSheetNodes) {
            return <></>;
        }

        return (
            <div id={widgetExportId}>
                <BalanceSheetTable
                    key={balanceSheetNodes?.length}
                    balanceSheet={filterAccountGraph(
                        balanceSheetNodes ?? null,
                        filterArray
                    )}
                    isLoading={
                        isLoadingBalanceSheet ||
                        isLoadingReportMetadata ||
                        isLoadingNotes
                    }
                    periods={widgetSettings.periods} // YYYY-MM-DD
                    error={
                        isErrorBalanceSheet ||
                        isErrorLoadingReportMetadata ||
                        isErrorNotes
                    }
                    reportNotes={reportNotes ?? []}
                    mutateReportNotes={mutateReportNotes}
                    hideNotes={widgetSettings.is_hide_notes}
                    entityCodes={[entityCode]}
                    expandedRows={expandedRows}
                    setExpandedRows={setExpandedRows}
                    selectedAccountMappingId={selectedAccountMappingId ?? ''}
                    setSelectedAccountMappingId={setSelectedAccountMappingId}
                    setAccountFilterSelection={(accountMappingId: string) => {
                        updateCommentsPane({
                            accountFilterSelection: accountMappingId,
                        });
                    }}
                    heightOffset={heightOffset}
                    hideNoteOption={widgetSettings.is_hide_notes}
                    hideCommentsOption={true}
                    reportWidgetSettings={{
                        export_setting: widgetSettings.export_setting,
                        account_mapping_selections:
                            widgetSettings.account_mapping_selections,
                    }}
                    isPDFExport={isPDFExport}
                />
            </div>
        );
    }
);
