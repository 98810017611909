export const BALANCE_SHEET_TITLE = 'Balance Sheet';

export const BALANCE_SHEET_URL = 'balance-sheet';

export const BALANCE_SHEET_EMPTY_TEXT = 'No Balance Sheet for this period';

export const BALANCE_SHEET_ERROR_TEXT = 'There was an error with the request';

export const BALANCE_SHEET_NO_FEATURE_TEXT = 'Balance Sheet is not enabled';

export const startDate = '2010-01-31';
