import React from 'react';
import { Button, Modal } from 'antd';
import { useFilterEditorOperations } from 'waypoint-hooks';
import { SavedFilter } from 'contexts/saved-filters';
import FilterEditorForm from './FilterEditorForm';

interface FilterEditorProps {
    handleClose: () => void;
}

const FilterEditor = ({ handleClose }: FilterEditorProps): JSX.Element => {
    const {
        attributeFilters,
        setAttributeFilters,
        saveAsVisible,
        setSaveAsVisible,
        controlsDisabled,
        handleClearFilter,
        handleDelete,
        handleSave,
        savedFilterContext,
        hasAtLeastOneValidFilter,
        handleApplyGlobalFilters,
        openSaveAs,
        selectedFilterId,
        savedFilterPartial,
        setSavedFilterPartial,
        setSelectedFilterId,
    } = useFilterEditorOperations({ handleClose });

    return (
        <Modal
            open
            onOk={handleClose}
            zIndex={99999}
            closable={false}
            destroyOnClose
            footer={
                <div>
                    <Button
                        style={{ float: 'left' }}
                        onClick={handleClose}
                        disabled={controlsDisabled}
                    >
                        Cancel
                    </Button>
                    {selectedFilterId && (
                        <Button
                            onClick={openSaveAs}
                            disabled={
                                controlsDisabled || !hasAtLeastOneValidFilter
                            }
                        >
                            Save As
                        </Button>
                    )}
                    <Button
                        onClick={handleSave}
                        // todo: Saving should only possible when the filters have been modified
                        disabled={controlsDisabled || !hasAtLeastOneValidFilter}
                    >
                        Save
                    </Button>
                    <Button
                        onClick={handleApplyGlobalFilters}
                        disabled={controlsDisabled || !hasAtLeastOneValidFilter}
                    >
                        Apply
                    </Button>
                </div>
            }
        >
            <FilterEditorForm
                handleClose={handleClose}
                handleClearFilter={handleClearFilter}
                controlsDisabled={controlsDisabled}
                handleDelete={handleDelete}
                attributeFilters={attributeFilters}
                setAttributeFilters={setAttributeFilters}
                saveAsVisible={saveAsVisible}
                savedFilterPartial={savedFilterPartial}
                setSaveAsVisible={setSaveAsVisible}
                handleSave={handleSave}
                selectedFilterId={selectedFilterId}
                savedFilterContext={savedFilterContext}
                hasAtLeastOneValidFilter={hasAtLeastOneValidFilter}
                setSavedFilterPartial={setSavedFilterPartial}
                setSelectedFilterId={setSelectedFilterId}
            />
        </Modal>
    );
};

export default FilterEditor;
