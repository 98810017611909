import PropTypes from 'prop-types';
import React from 'react';
import { FormControl, Radio, ButtonGroup } from 'react-bootstrap';
import FilterHeading from 'components/settings/users/FilterHeading';

// NOTE: If Field's component prop is passed a callback to render another component, that component is re-rendered
// each time there is change, causing the element to lose focus. However, passing a predefined component bypasses
// this issude. Field's props are passed to whatever element is passed to its component prop.

export const FormInput = (props) => {
    const { input, meta, style, title } = props;
    const { onChange, value, onFocus } = input;
    const { visited, error, active } = meta;
    return (
        <div>
            <FilterHeading
                isModal={true}
                heading={title}
                error={visited && error && !active ? error : null}
            />
            <FormControl
                value={value}
                type="text"
                style={style}
                onChange={(e) => onChange(e.target.value)}
                onFocus={(e) => onFocus(e.target.value)}
            />
        </div>
    );
};

FormInput.propTypes = {
    input: PropTypes.object,
    style: PropTypes.object,
    meta: PropTypes.object,
    title: PropTypes.string,
};

export const RadioGroup = (props) => {
    const { input, title, meta } = props;
    const { onChange, value } = input;
    const { visited, error, active } = meta;
    return (
        <div>
            <FilterHeading
                isModal={true}
                heading={title}
                error={visited && error && !active ? error : null}
            />
            <ButtonGroup onChange={(e) => onChange(e.target.id)}>
                <Radio checked={value === 'User'} name="roleOptions" id="User">
                    User
                </Radio>
                <Radio
                    checked={value === 'Admin'}
                    name="roleOptions"
                    id="Admin"
                >
                    Admin
                </Radio>
            </ButtonGroup>
        </div>
    );
};

RadioGroup.propTypes = {
    input: PropTypes.object,
    title: PropTypes.string,
    meta: PropTypes.object,
};
