import PropTypes from 'prop-types';
import React from 'react';
import {
    legendlabelStyle,
    legendLineStyle,
    legendItemContainer,
} from './styles';
import { css, cx } from 'emotion';

const LineLegendItem = (props) => {
    const { className } = props;
    return (
        <span className={legendItemContainer}>
            <span
                className={cx([
                    legendLineStyle,
                    className,
                    css({ backgroundColor: props.color }),
                ])}
            />
            <span className={legendlabelStyle}>{props.label}</span>
        </span>
    );
};

LineLegendItem.propTypes = {
    label: PropTypes.string,
    color: PropTypes.string,
};

export default LineLegendItem;
