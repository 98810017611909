import {
    WORKFLOW_ASSIGNEE,
    WORKFLOW_REVIEWER,
} from 'components/financials/comparative-income-statement/constants';
import { FixAnyType, WorkflowRole } from 'waypoint-types';
import WorkflowAvatarGroup from './WorkflowAvatarGroup';
import { useGetUserData } from 'waypoint-hooks';
import {
    RoleType,
    workflowPermissionsDict,
} from 'components/financials/variance-report-status/VarianceReportStatusUtils';

interface WorkflowAvatarBannerGroupParams {
    isAdmin: boolean;
    handleWorkflowModal: (role: string | null) => void;
    workflowAssignees: WorkflowRole[];
    workflowReviewers: WorkflowRole[];
}

const WorkflowAvatarBannerGroup = ({
    isAdmin,
    handleWorkflowModal,
    workflowAssignees,
    workflowReviewers,
}: WorkflowAvatarBannerGroupParams): JSX.Element => {
    const { userId } = useGetUserData();

    const roles = {
        [WORKFLOW_ASSIGNEE]: workflowAssignees.map((wa) => ({
            userName: wa.userName,
            profile_image_url: wa.profile_image_url ?? '',
        })),
        [WORKFLOW_REVIEWER]: workflowReviewers.map((wr) => ({
            userName: wr.userName,
            profile_image_url: wr.profile_image_url ?? '',
        })),
    };

    const isReviewer = workflowReviewers.some(
        (reviewer) => reviewer.userId === userId,
    );

    const hasEditPermission = (role: keyof typeof workflowPermissionsDict) => {
        const requiredPermissions = workflowPermissionsDict[role];

        if (!requiredPermissions) {
            return false;
        }

        return requiredPermissions.some((permission) => {
            return permission === 'isAdmin' ? isAdmin : isReviewer;
        });
    };

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {Object.entries(roles).map((role) => {
                    return (
                        <span
                            style={{
                                marginRight: '25px',
                                marginLeft: '10px',
                                display: 'flex',
                                justifyContent: 'left',
                                alignItems: 'center',
                            }}
                        >
                            <div>
                                <span
                                    style={{
                                        fontWeight: 'bold',
                                        marginRight: '5px',
                                        fontSize: '12px',
                                    }}
                                >
                                    {role[0] === WORKFLOW_ASSIGNEE
                                        ? 'Assignees:'
                                        : 'Reviewers:'}
                                </span>
                            </div>
                            <WorkflowAvatarGroup
                                roleType={role[0] as RoleType}
                                workflowRoleList={role[1]}
                                hasEditPermission={hasEditPermission(
                                    role[0] as FixAnyType,
                                )}
                                onClick={handleWorkflowModal}
                            />
                        </span>
                    );
                })}
            </div>
        </>
    );
};

export default WorkflowAvatarBannerGroup;
