import useSWR from 'swr';
import { getEntityProfile } from 'waypoint-requests';
import { EntityProfile } from 'waypoint-types/properties/types';
import { DataHookResponse } from './DataHookResponse';
import { message } from 'antd';

export const useGetEntityProfile = (
    entityCode: string
): DataHookResponse<EntityProfile> => {
    const { data, error, mutate, isValidating } = useSWR(
        `/properties/${entityCode}/entity-profile`,
        () => getEntityProfile(entityCode),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
            onError: () => {
                message.error('Failed to fetch entity profile');
            },
        }
    );

    return {
        isLoading: !data || isValidating,
        isError: !!error,
        data,
        mutate,
    };
};
