import { API_URL } from 'config/constants';
import { Note, NoteReferenceType } from 'waypoint-types';

interface FilterData {
    periodicity: string;
    start_date: string;
    end_date: string;
    mode: string;
}

interface GenericFilterData {
    entity_code: string;
    report_type: string;
}

export interface CreateNoteParams {
    noteReferenceId: string;
    noteReferenceType: string;
    reportType: string;
    referenceId: string;
    referenceType: string;
    filters: FilterData[] | GenericFilterData[];
    text: string;
}

const createNote = async (
    createNoteParams: CreateNoteParams
): Promise<Note> => {
    const response = await fetch(`${API_URL}/notes`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(createNoteParams),
    });

    if (!response.ok) {
        throw new Error('Failed to create note');
    }

    const { data } = await response.json();

    return data as Note;
};

export default createNote;
