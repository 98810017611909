import { Progress } from 'antd';
import React from 'react';

interface EntityProfileCardProgressProps {
    title: string;
    itemsCompleted: number;
    allItems: number;
}

const EntityProfileCardProgress = ({
    title,
    itemsCompleted,
    allItems,
}: EntityProfileCardProgressProps) => {
    return (
        <>
            {title}
            <Progress
                trailColor={'#cdc8c8'}
                showInfo={false}
                percent={(itemsCompleted / allItems) * 100}
                size="small"
                style={{
                    marginLeft: '20px',
                    marginRight: 7,
                    maxWidth: '130px',
                }}
            />
            {`(${itemsCompleted ?? 0}/${allItems}) Complete`}
        </>
    );
};

export default EntityProfileCardProgress;
