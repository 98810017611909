import { API_URL } from 'config/constants';

const uploadEntityPhoto = async (entityId: string, photo: FormData) => {
    const response = await fetch(
        `${API_URL}/properties/upload-entity-picture/${entityId}`,
        {
            method: 'POST',
            credentials: 'include',
            body: photo,
        }
    );

    if (!response.ok) {
        throw new Error('Failed');
    }

    const data = await response.json();
    return data;
};

export default uploadEntityPhoto;
