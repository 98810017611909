import ReactFC from 'react-fusioncharts';
import chartBuilder from 'components/charts/chartBuilder';
import { HoldSellGridDataSourceType } from './types';

interface HoldSellStackedBarProps {
    data: HoldSellGridDataSourceType[];
}

interface StackedBarConfig {
    hold: number;
    sell: number;
    undecided: number;
}

export const HoldSellStackedBar = ({
    data,
}: HoldSellStackedBarProps): JSX.Element | null => {
    if (data === null) {
        return null;
    }
    const holdSellChartConfig = buildStackedBarConfigFor(data);

    return (
        <>
            <ReactFC
                {...holdSellChartConfig}
                data-testid="hold-sell-stacked-bar"
            />
        </>
    );
};

const stackedBarStyle = {
    enableRotation: '1',
    caption: 'Recommendation Summary',
    showLabels: '1',
    numDivLines: '5',
    showPercentageValues: '1',
    showZeroPies: '1',
    showLabel: '0',
    enableMultiSlicing: '0',
    enableSmartLabels: '0',
    skipOverlapLabels: '1',
    manageLabelOverflow: '1',
    useEllipsesWhenOverflow: '1',
    pieRadius: '60%',
    startingAngle: '90',
    alignCaptionWithCanvas: '0',
    showHoverEffect: '1',
    plotHoverEffect: '0',
    showLegend: false,
    legendItemFontSize: '12px',
    legendPosition: 'bottom',
    legendBorderColor: '#666666',
    legendBorderThickness: '1',
    legendBorderAlpha: '40',
    plotHighlightEffect: 'fadeout',
    exportFormats: 'PNG|PDF|JPG|SVG',
    canvasBottomMargin: '0px',
    canvasBottomPadding: '0px',
    legendPadding: '0px',
    chartBottomMargin: '0px',
};

const buildStackedBarConfigFor = (data: HoldSellGridDataSourceType[]) => {
    const holdSellChart = chartBuilder();
    holdSellChart.height('210');
    holdSellChart.type('stackedbar2d');
    const chartData = data.reduce(
        (
            acc: StackedBarConfig,
            item: HoldSellGridDataSourceType,
        ): StackedBarConfig => {
            const recommendation = item.recommendation;
            return {
                hold: acc.hold + (recommendation === 'Hold' ? 1 : 0),
                sell: acc.sell + (recommendation === 'Sell' ? 1 : 0),
                undecided:
                    acc.undecided + (recommendation === 'Undecided' ? 1 : 0),
            };
        },
        {
            hold: 0,
            sell: 0,
            undecided: 0,
        },
    );

    const categories = [
        {
            category: [
                {
                    label: '',
                },
            ],
        },
    ];
    const dataset = [
        {
            seriesname: 'Hold',
            data: [
                {
                    value: chartData.hold,
                    color: '#DF0101',
                },
            ],
        },
        {
            seriesname: 'Sell',
            data: [
                {
                    value: chartData.sell,
                    color: '#00C854',
                },
            ],
        },
        {
            seriesname: 'Undecided',
            data: [
                {
                    value: chartData.undecided,
                    color: '#a8a8a8',
                },
            ],
        },
    ];

    const primaryChartStyle = {
        ...stackedBarStyle,
    };
    holdSellChart.style(primaryChartStyle);
    holdSellChart.categories(categories);
    holdSellChart.dataset(dataset);
    holdSellChart.data();
    return holdSellChart.config;
};
