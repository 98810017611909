import { useState, useEffect } from 'react';

interface DataGridHeightConfig {
    topBarHeight?: number;
    cardTitleHeight?: number;
    cardOuterPadding?: number;
    cardInnerPadding?: number;
}

export const useDataGridHeight = ({
    topBarHeight = 75,
    cardTitleHeight = 54,
    cardOuterPadding = 12,
    cardInnerPadding = 12,
}: DataGridHeightConfig = {}) => {
    const [dataGridHeight, setDataGridHeight] = useState(0);

    useEffect(() => {
        const calculateHeight = () => {
            const WINDOW_HEIGHT = Math.max(window.innerHeight, 500);
            const OFFSET =
                cardTitleHeight +
                topBarHeight +
                2 * cardOuterPadding +
                2 * cardInnerPadding;
            return Math.max(WINDOW_HEIGHT - OFFSET, 500);
        };

        const handleResize = () => {
            setDataGridHeight(calculateHeight());
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [topBarHeight, cardTitleHeight, cardOuterPadding, cardInnerPadding]);

    return dataGridHeight;
};
