import { useRef, useState } from 'react';
import {
    PropertyType,
    ReportMetadata,
    ThresholdRule,
    WorkflowRole,
    MentionableDataSource,
    SelectedVarianceRowKeys,
    AttributeValue,
} from 'waypoint-types';
import { exportExcelFromDevExtremeDataGrid } from 'waypoint-utils';
import DataGrid, {
    Export,
    HeaderFilter,
    Column,
    SearchPanel,
    Summary,
    GroupItem,
    Item,
    Toolbar,
    Grouping,
    Selection,
    Scrolling,
    DataGridRef,
} from 'devextreme-react/data-grid';
import SelectBox from 'devextreme-react/select-box';
import dxDataGrid, {
    CellPreparedEvent,
    ExportingEvent,
    RowPreparedEvent,
} from 'devextreme/ui/data_grid';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import { connect, RootStateOrAny } from 'react-redux';
import { selectProperties } from 'state/properties/selectors';
import { Dictionary } from 'ts-essentials';
import {
    TableRow,
    buildTableData,
    currentUserHasReviewRole,
    workflowStatusColors,
} from 'components/financials/variance-report-status/VarianceReportStatusUtils';
import { WorkflowAvatarGroup } from 'components/financials/workflow-roles';
import {
    WORKFLOW_ASSIGNEE,
    WORKFLOW_REVIEWER,
} from 'components/financials/comparative-income-statement/constants';
import { DASH_DASH } from 'config/constants';
import { Tag } from 'antd';
import theme from 'config/theme';
import { ExpandAndCollapseButton } from 'waypoint-react';
import Button from 'antd/lib/button';
import { removeGroupButtonStyle } from 'components/style';
import { format } from 'date-fns';
import { roles as userRoles } from 'config/constants';
import { selectCurrentUser } from 'state/user/selectors';

interface VarianceReportStatusTableProps {
    periodEnd: string;
    thresholdRules: ThresholdRule[] | null;
    reportMetadata: ReportMetadata[] | null;
    workflowAssignees: Dictionary<WorkflowRole[]> | null;
    workflowReviewers: Dictionary<WorkflowRole[]> | null;
    userWorkflowRoleOptions: MentionableDataSource[] | undefined;
    properties: Dictionary<PropertyType>;
    onSelectionChanged: (data: Dictionary<SelectedVarianceRowKeys[]>) => void;
    entityFiscalYearEndDates: AttributeValue[];
    isAdmin?: boolean;
    currentUserId?: number;
}

const dataGridStyle = {
    boxShadow: `0px 3px 5px -1px rgba(0, 0, 0, 0.2),
                0px 6px 10px 0px rgba(0, 0, 0, 0.04)`,
};

export const groupingOptions = [
    { value: 'assigneeNames', text: 'Assignee' },
    { value: 'property', text: 'Property' },
    { value: 'reviewerNames', text: 'Reviewer' },
    { value: 'status', text: 'Status' },
    { value: 'threshold_rule', text: 'Threshold Rule' },
];

const VarianceReportStatusTable = ({
    periodEnd,
    thresholdRules,
    reportMetadata,
    workflowAssignees,
    workflowReviewers,
    userWorkflowRoleOptions,
    properties,
    onSelectionChanged,
    entityFiscalYearEndDates,
    isAdmin,
    currentUserId,
}: VarianceReportStatusTableProps): JSX.Element => {
    const [grouping, setGrouping] = useState<string>('');
    const dataGrid = useRef<DataGridRef>(null);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [expandButtonEnable, setExpandButtonEnable] = useState<boolean>(true);

    if (
        !thresholdRules ||
        !reportMetadata ||
        !userWorkflowRoleOptions ||
        !entityFiscalYearEndDates
    ) {
        return <div />;
    }

    const onRowPrepared = (e: RowPreparedEvent) => {
        if (e.rowType === 'group') {
            e.rowElement.style.backgroundColor = theme.colors.grays.background;
        }
    };

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const groupChanged = (e: Dictionary<string>) => {
        const group = e.value;
        if (!group) {
            return;
        }
        dataGrid?.current?.instance().clearGrouping();
        dataGrid?.current?.instance().columnOption(group, 'groupIndex', 0);
        dataGrid?.current?.instance().refresh();
        setExpandButtonEnable(group === 'none');
        setGrouping(group);
    };

    const resetGrouping = () => {
        dataGrid?.current?.instance().clearGrouping();
        setExpandButtonEnable(true);
        setGrouping('');
    };

    const tableData = buildTableData(
        thresholdRules,
        properties,
        reportMetadata,
        workflowAssignees,
        workflowReviewers,
        periodEnd,
        userWorkflowRoleOptions,
        entityFiscalYearEndDates,
    );

    const onExporting = async (e: ExportingEvent) => {
        e.component.columnOption('Report Link', 'visible', false);
        await exportExcelFromDevExtremeDataGrid(e, {
            worksheetName: 'Variance Report Status',
            filename: 'Variance Report Status.xlsx',
        });
        e.component.columnOption('Report Link', 'visible', true);
    };

    const workflowGroupRenderer = (
        userList: string,
        roleType: string,
        data: { userName: string; profile_image_url?: string }[],
    ) => {
        if (!userList) {
            return DASH_DASH;
        }
        return (
            <WorkflowAvatarGroup
                roleType={roleType}
                workflowRoleList={data}
                size="small"
            />
        );
    };

    const linkRenderer = ({
        entityCode,
        property,
        reporting_period,
        financial_year_ending,
    }: {
        entityCode: string;
        property: string;
        reporting_period: string;
        financial_year_ending: string;
    }) => {
        const fiscalYearURLFormattedEndDate = format(
            new Date(financial_year_ending),
            'MM-dd',
        );
        const url = `#/financials/variance-reporting?entity_code=${entityCode}&mode=actual&periodicity=${reporting_period.toLowerCase()}&end_date=${periodEnd}&financial_year_ending=${fiscalYearURLFormattedEndDate}`;
        return <a href={url}>{property}</a>;
    };

    const statusRenderer = (status: string) => {
        if (status === DASH_DASH) {
            return status;
        }
        return (
            <Tag
                color={
                    workflowStatusColors[
                        status as keyof typeof workflowStatusColors
                    ]
                }
            >
                {status}
            </Tag>
        );
    };

    const handleCellPreparation = (e: CellPreparedEvent<TableRow, any>) => {
        const shouldHideCheckbox =
            !isAdmin &&
            e.column.type === 'selection' &&
            !currentUserIsReviewer.includes(e.key?.entityCode);

        if (shouldHideCheckbox) {
            e.cellElement.querySelector('.dx-checkbox')?.remove();
            e.cellElement.style.pointerEvents = 'none';
        }
    };

    const currentUserIsReviewer = currentUserHasReviewRole(
        workflowReviewers,
        currentUserId,
    );

    return (
        <DataGrid
            style={dataGridStyle}
            ref={dataGrid}
            wordWrapEnabled
            dataSource={tableData}
            onExporting={onExporting}
            height={'auto'}
            allowColumnReordering={true}
            columnAutoWidth={true}
            columnMinWidth={150}
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}
            rowAlternationEnabled={true}
            onRowPrepared={onRowPrepared}
            onCellPrepared={handleCellPreparation}
            data-testid="variance-report-status-table"
            onSelectionChanged={
                onSelectionChanged as any //devxtreme missmatching type
            }
        >
            <Selection mode="multiple" showCheckBoxesMode={'always'} />
            <Scrolling mode="virtual" />
            <Column
                dataField="property"
                caption="Property"
                allowHeaderFiltering={true}
                alignment="left"
                cellRender={({ data }) => linkRenderer(data)}
            />
            <Column
                dataField="threshold_rule"
                caption="Threshold Rule"
                allowHeaderFiltering={true}
                alignment="left"
            />
            <Column
                dataField="financial_year_ending"
                caption="Year Ending"
                allowHeaderFiltering={true}
                alignment="left"
            />
            <Column
                dataField="reporting_period"
                caption="Reporting Period"
                allowHeaderFiltering={true}
                alignment="center"
            />
            <Column
                dataField="period_ending"
                caption="Period Ending"
                allowHeaderFiltering={false}
                alignment="center"
            />
            <Column
                dataField="status"
                caption="Status"
                allowHeaderFiltering={true}
                alignment="center"
                cellRender={(data) => statusRenderer(data.value)}
            />
            <Column
                dataField="assigneeNames"
                caption="Assignee"
                allowHeaderFiltering={false}
                alignment="center"
                cellRender={(data) =>
                    workflowGroupRenderer(
                        data.value,
                        WORKFLOW_ASSIGNEE,
                        data.data.assignee,
                    )
                }
            />
            <Column
                dataField="reviewerNames"
                caption="Reviewer"
                allowHeaderFiltering={false}
                alignment="center"
                cellRender={(data) =>
                    workflowGroupRenderer(
                        data.value,
                        WORKFLOW_REVIEWER,
                        data.data.reviewer,
                    )
                }
            />
            <Column
                dataField="last_updated_by"
                caption="Last Updated By"
                allowHeaderFiltering={false}
                alignment="left"
            />
            <Column
                dataField="last_updated"
                caption="Last Updated"
                allowHeaderFiltering={false}
                alignment="left"
            />
            <Summary>
                <GroupItem column="fake" alignByColumn summaryType="count" />
                <GroupItem
                    column="property"
                    summaryType="count"
                    displayFormat="{0}"
                    alignByColumn
                />
                <GroupItem
                    column="threshold_rule"
                    summaryType="count"
                    displayFormat="{0}"
                    alignByColumn
                />
                <GroupItem
                    column="reporting_period"
                    summaryType="count"
                    displayFormat="{0}"
                    alignByColumn
                />
                <GroupItem
                    column="status"
                    summaryType="count"
                    displayFormat="{0}"
                    alignByColumn
                />
            </Summary>
            <HeaderFilter allowSearch={true} visible={true} />
            <Grouping autoExpandAll={expanded} />
            <Toolbar>
                <Item location="after" name="columnChooserButton" />
                <Item name="exportButton" />
                <Item name="searchPanel" />
                <Item location="before">
                    <SelectBox
                        placeholder="Group By"
                        label="Group By"
                        labelMode="hidden"
                        stylingMode="underlined"
                        onValueChanged={groupChanged as any} //devxtreme missmatching type
                        width="180"
                        items={groupingOptions}
                        displayExpr="text"
                        valueExpr="value"
                        value={grouping}
                    />
                </Item>
                <Item name="groupPanel" />
                <Item location="before">
                    <ExpandAndCollapseButton
                        expanded={expanded}
                        toggleExpanded={toggleExpanded}
                        expandButtonEnable={expandButtonEnable}
                    />
                </Item>
                <Item location="before">
                    <Button
                        className={removeGroupButtonStyle}
                        style={{
                            display: expandButtonEnable ? 'none' : 'block',
                        }}
                        type="primary"
                        onClick={resetGrouping}
                    >
                        Remove Grouping
                    </Button>
                </Item>
            </Toolbar>
            <SearchPanel visible={true} highlightCaseSensitive={false} />
            <Export enabled={true} allowExportSelectedData={false} />
        </DataGrid>
    );
};

const mapState = (s: RootStateOrAny) => {
    const properties = selectProperties(s);
    const { roles, id: currentUserId } = selectCurrentUser(s);
    const isAdmin = roles.includes(userRoles.CLIENT_ADMIN);

    return {
        properties,
        isAdmin,
        currentUserId,
    };
};

export default connect(mapState)(VarianceReportStatusTable);
