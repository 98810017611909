import { assign, isString, merge } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Overlay from 'components/style/Overlay';

export const Modal = (props) => {
    const {
        title,
        controls,
        clickHandler,
        contentContainerStyle,
        overlayStyle,
        className,
    } = props;
    let { style, titleStyle } = props;

    const defaultStyle = {
        height: 'auto',
        maxHeight: '600px',
        width: '453px',
        backgroundColor: '#FFFFFF',
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 6,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        overflowY: 'auto',
        overflowX: 'hidden',
        wordWrap: 'break-word',
    };
    style = assign({}, defaultStyle, style);
    const defaultTitleStyle = {
        display: 'flex',
        borderBottom: '1px solid #ccc',
        justifyContent: 'space-between',
    };
    // NOTE: titleStyle prop used to override default style for title contianer
    titleStyle = assign({}, defaultTitleStyle, titleStyle);
    const defaultContentContainerStyle = {
        order: 2,
        padding: '20px',
        marginTop: '20px',
        height: '100%',
        width: '100%',
    };
    // NOTE: The margin of the title of the modal - div with 10px bottom margin - sgets eaten if more content is added and overflow scroll is triggered. why? Margin collapse CANNOT happen on items within a flex container, which these elements are in. SO WHAT IS HAPPENING!!
    return (
        <div>
            <Overlay show={true} overlayStyle={overlayStyle} />
            <div className={className} style={style}>
                <div style={titleStyle}>
                    <div
                        style={{
                            order: 1,
                            fontSize: '16px',
                            color: '4A4A4A',
                            fontWeight: 'bold',
                            lineHeight: '19px',
                            paddingTop: '15px',
                            paddingLeft: '20px',
                            minHeight: '50px',
                        }}
                    >
                        {title && isString(title) ? title.toUpperCase() : title}
                    </div>
                    <div
                        style={{ order: 2, display: 'flex', minWidth: '115px' }}
                    >
                        {controls}
                        <i
                            className="fa-solid fa-times"
                            style={{
                                position: 'absolute',
                                right: '0%',
                                cursor: 'pointer',
                                paddingTop: '11px',
                                paddingRight: '19px',
                                color: '#5a5a5a', // NOTE: should import / export this value (scss? color?)
                                fontSize: '25px',
                            }}
                            onClick={clickHandler}
                        />
                    </div>
                </div>
                <div
                    style={merge(
                        defaultContentContainerStyle,
                        contentContainerStyle
                    )}
                >
                    {props.children}
                </div>
            </div>
        </div>
    );
};

Modal.propTypes = {
    title: PropTypes.any,
    titleStyle: PropTypes.object,
    contentContainerStyle: PropTypes.object,
    overlayStyle: PropTypes.object,
    controls: PropTypes.element,
    // TODO (Nicholas): Replace "clickHandler" w/ "onClick"
    clickHandler: PropTypes.func,
    style: PropTypes.object,
    children: PropTypes.any,
};

export default Modal;
