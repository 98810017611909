import React, { useCallback, useState } from 'react';
import PrioritiesObjectivesGrid from './PrioritiesObjectivesGrid';
import { AnnualPrioritiesAndObjectives } from 'components/annualPrioritiesAndObjectives/AnnualPrioritiesAndObjectives';
import { PlanningYearSelection } from 'components/planning/components';
import { Skeleton, message } from 'antd';
import ResponsiveBox, {
    Row,
    Col,
    Location,
    Item,
} from 'devextreme-react/responsive-box';
import {
    EntityProfileCard,
    EntityProfileCardYearMetadata,
    PlanningDataSourceType,
    PropertyInfoSummary,
} from 'waypoint-types';
import { CommentsPaneWrapper } from 'waypoint-react';
import useSWR, { KeyedMutator } from 'swr';
import { getYearMetadata } from 'waypoint-requests';
import { stringSort } from 'utils/tables/sorters';

interface PrioritiesObjectivesRollUpViewProps {
    properties: PropertyInfoSummary[];
    prioritiesObjectivesData: EntityProfileCard[] | undefined;
    mutatePrioritiesObjectives: KeyedMutator<EntityProfileCard[]>;
    entityCodes: string[];
}

const PrioritiesObjectivesRollUpView = ({
    properties,
    entityCodes,
    prioritiesObjectivesData,
    mutatePrioritiesObjectives,
}: PrioritiesObjectivesRollUpViewProps) => {
    const [selectedRowRecommendation, setSelectedRowRecommendation] =
        useState<string>('');

    const [yearFilter, setYearFilter] = useState<number | null>(null);

    const globalFilterProperties = properties.filter((property) =>
        entityCodes.includes(property.code)
    );

    const yearMetadataCacheKey = `/planning/planning-priorities-objectives/year-metadata/${entityCodes.join(
        ','
    )}`;

    const onRefreshData = () => {
        prioritiesObjectivesData &&
            mutatePrioritiesObjectives(
                async () => {
                    try {
                        return prioritiesObjectivesData;
                    } catch (e) {
                        message.error(
                            'Failed to update content on priorities and objectives'
                        );

                        return prioritiesObjectivesData;
                    }
                },
                {
                    optimisticData: prioritiesObjectivesData,
                    rollbackOnError: true,
                    populateCache: true,
                    revalidate: true,
                }
            );
    };

    const { data: yearMetadataWithCommentCount } = useSWR(
        yearMetadataCacheKey,
        () => getYearMetadata(entityCodes, 'annualPrioritiesAndObjectives'),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
        }
    );

    React.useEffect(() => {
        if (!yearMetadataWithCommentCount) {
            return;
        }

        const validYears = yearMetadataWithCommentCount.map(
            (item) => item.year
        );

        const needToSetDefaultYearFilter =
            !yearFilter || !validYears.includes(yearFilter);

        const currentYear = new Date().getFullYear();

        if (needToSetDefaultYearFilter) {
            const maxYear = validYears.length
                ? Math.max(...validYears)
                : currentYear + 1;

            if (maxYear && maxYear !== yearFilter) {
                setYearFilter(maxYear);
            }
        }

        const needToSetDefaultSelectedRow =
            !selectedRowRecommendation ||
            !entityCodes.includes(selectedRowRecommendation);

        if (needToSetDefaultSelectedRow) {
            const entitiesSortedByName = globalFilterProperties.sort(
                (propertyA, propertyB) => {
                    return stringSort(propertyB.name, propertyA.name);
                }
            );

            if (entitiesSortedByName.length) {
                setSelectedRowRecommendation(entitiesSortedByName[0].code);
            }
        }
    }, [yearMetadataWithCommentCount]);

    const prioritiesAndObjectivesDataSourceCallback = useCallback(() => {
        if (!yearMetadataWithCommentCount) {
            return [];
        }

        return globalFilterProperties.map<PlanningDataSourceType>(
            (property: PropertyInfoSummary) => {
                const filteredMetadata = yearMetadataWithCommentCount.filter(
                    (metadata) => metadata.year === yearFilter
                );

                const matchingYearMetadataForEntity = filteredMetadata.filter(
                    (item: EntityProfileCardYearMetadata) =>
                        item.entity_code === property.code
                );

                if (!matchingYearMetadataForEntity.length) {
                    return {
                        entityCode: property.code,
                        entityName: property.name,
                        commentCount: 0,
                        commentThreadId: null,
                    };
                }

                const yearMetadataForEntity = matchingYearMetadataForEntity[0];

                return {
                    entityCode: property.code,
                    entityName: property.name,
                    commentCount: yearMetadataForEntity.comment_count,
                    commentThreadId: yearMetadataForEntity.comment_thread_id,
                };
            }
        );
    }, [globalFilterProperties, yearMetadataWithCommentCount, yearFilter]);

    if (!yearMetadataWithCommentCount) {
        return <Skeleton />;
    }

    const getEntityNameByCode = (entityCode: string) => {
        return (
            properties?.find(
                (property: PropertyInfoSummary) => property.code === entityCode
            )?.name ?? ''
        );
    };

    if (!yearFilter) {
        return <Skeleton />;
    }

    const SELECT_HEIGHT = 80;
    const tableHeight = window.innerHeight - SELECT_HEIGHT - 180;

    return (
        <CommentsPaneWrapper commentsPaneWrapperStyle={{ width: '100%' }}>
            <ResponsiveBox>
                <Row ratio={0} baseSize={SELECT_HEIGHT} />
                <Row />

                <Col ratio={1} />
                <Col ratio={1} />
                <Col ratio={1} />

                <Item>
                    <Location row={0} col={0} colspan={3} />
                    <div
                        style={{
                            borderBottom: '1px solid lightgrey',
                            marginBottom: '10px',
                        }}
                    >
                        <PlanningYearSelection
                            yearFilter={yearFilter}
                            setYearFilter={setYearFilter}
                            yearMetadata={yearMetadataWithCommentCount}
                        />
                    </div>
                </Item>

                <Item>
                    <Location row={1} col={0} rowspan={1} colspan={1} />
                    <div
                        style={{
                            marginLeft: '10px',
                            marginRight: '10px',
                        }}
                    >
                        <PrioritiesObjectivesGrid
                            height={tableHeight}
                            dataSource={prioritiesAndObjectivesDataSourceCallback()}
                            selectedRowRecommendation={
                                selectedRowRecommendation
                            }
                            setSelectedRowRecommendation={
                                setSelectedRowRecommendation
                            }
                            yearFilter={yearFilter}
                            prioritiesObjectivesData={prioritiesObjectivesData}
                        />
                    </div>
                </Item>

                <Item>
                    <Location row={1} col={1} rowspan={1} colspan={2} />
                    <div>
                        {selectedRowRecommendation === '' ? (
                            <Skeleton />
                        ) : (
                            <div
                                style={{
                                    height: tableHeight,
                                    overflowY: 'auto',
                                    marginRight: '10px',
                                    boxShadow:
                                        'rgba(0, 0, 0, 0.12) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.24) 0px 0px 0px 1px',
                                }}
                            >
                                <AnnualPrioritiesAndObjectives
                                    key={`${yearFilter}_${selectedRowRecommendation}`}
                                    title={`${getEntityNameByCode(
                                        selectedRowRecommendation
                                    )}`}
                                    entityCode={selectedRowRecommendation}
                                    enableYearFilter={false}
                                    enableYearMetadata={false}
                                    selectedYear={[yearFilter]}
                                    isCollapse={false}
                                    enableAddYearButton={true}
                                    isRollupPage={true}
                                    onItemUpdate={() => {
                                        onRefreshData();
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </Item>
            </ResponsiveBox>
        </CommentsPaneWrapper>
    );
};

export default PrioritiesObjectivesRollUpView;
