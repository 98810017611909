import React from 'react';
import { css } from 'emotion';
import theme from 'config/theme';

const emptyAvatarStyle = css`
    width: 45px;
    height: 45px;
    background: ${theme.colors.grays.light};
    border-radius: 50%;
    display: inline-block;
`;

const EmptyAvatar = () => <span className={emptyAvatarStyle} />;

export default EmptyAvatar;
