import { API_URL } from 'config/constants';
import { NotificationPreferencesResponse } from 'waypoint-types';
import { NotificationPreferencesRequest } from 'waypoint-types/user/types';

export async function updateUserNotificationPreferences(
    preferences: NotificationPreferencesRequest
): Promise<NotificationPreferencesResponse> {
    const response = await fetch(`${API_URL}/users/notification-preferences`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(preferences),
    });

    if (!response.ok) {
        throw new Error('Failed to update notification settings');
    }

    const { data } = await response.json();

    return data as NotificationPreferencesResponse;
}
