import { css } from 'emotion';
import React, { ReactElement, useContext } from 'react';
import { EntityAttributesContext, EntityAttributesContextType } from 'contexts';
import EmptyState from 'components/style/EmptyState';
import { useGetSelectedFilteredEntityCodes } from 'waypoint-hooks';

const popoverStyle = css`
    max-height: 80vh;
    overflow-y: scroll;

    @media (max-height: 900px) {
        max-height: 500px;
    }

    @media (max-height: 700px) {
        max-height: 400px;
    }

    @media (max-height: 500px) {
        max-height: 200px;
    }
`;

/**
 * Component displaying sorted list of filtered *and* selected properties
 */
export const ScrollablePropertyNameList = (): ReactElement => {
    const entityContext = useContext<EntityAttributesContextType | null>(
        EntityAttributesContext,
    );

    const selectedFilteredEntityCodes = new Set<string>(
        useGetSelectedFilteredEntityCodes(),
    );

    const filteredEntities =
        entityContext?.data?.entities?.filter((x) =>
            selectedFilteredEntityCodes.has(`${x.entityCode}`),
        ) ?? [];

    const displayedProperties = filteredEntities
        .sort((a, b) => `${a.displayName}`.localeCompare(`${b.displayName}`))
        .map((x) => <p key={x.displayName}>{x.displayName}</p>);

    return (
        <>
            {displayedProperties && (
                <div className={popoverStyle}>{displayedProperties}</div>
            )}
            {!displayedProperties && <EmptyState />}
        </>
    );
};
