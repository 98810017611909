import { compose } from 'redux';
import defaultStylePP from './defaultStylePP';
import calendarDatePP from './calendarDatePP';
import { DefaultCell } from './DefaultCellRenderer';

export const CalendarDateCellRenderer = compose(
    defaultStylePP,
    calendarDatePP
)(DefaultCell);
export default CalendarDateCellRenderer;
