import React from 'react';
import { Descriptions, Row as AntRow } from 'antd';
import { DASH_DASH } from 'config/constants';
import { getFormattedLabel } from '../PropertyProfileUtils';

interface PropertyAmenitiesAndCertificationsProps {
    selectedAmenityValues: string[];
    selectedCertificationValues: string[];
    isPDFExport?: boolean;
    labelStyle?: React.CSSProperties;
    showCertifications: boolean;
    showAmenities: boolean;
}

const PropertyAmenitiesAndCertifications = ({
    selectedAmenityValues,
    selectedCertificationValues,
    isPDFExport,
    labelStyle,
    showCertifications,
    showAmenities,
}: PropertyAmenitiesAndCertificationsProps) => {
    const items = [
        showAmenities
            ? {
                  label: 'Amenities',
                  value: !selectedAmenityValues.length
                      ? DASH_DASH
                      : selectedAmenityValues.join(', '),
                  span: showCertifications ? 1 : 5,
              }
            : null,
        showCertifications
            ? {
                  label: 'Certifications',
                  value: !selectedCertificationValues?.length
                      ? DASH_DASH
                      : selectedCertificationValues.join(', '),
                  span: showAmenities ? 4 : 5,
              }
            : null,
    ].filter(Boolean);

    return (
        <AntRow align="stretch" wrap={true}>
            <Descriptions
                size="small"
                bordered={false}
                column={{
                    xxl: 1,
                    xl: 1,
                    lg: 1,
                    md: 1,
                    sm: 1,
                    xs: 1,
                }}
                contentStyle={{
                    textAlign: 'left',
                    marginRight: 15,
                }}
                labelStyle={labelStyle}
            >
                {items.map((item, index) => (
                    <Descriptions.Item
                        key={index}
                        label={getFormattedLabel(item?.label ?? DASH_DASH)}
                        span={item?.span}
                    >
                        <div
                            style={{
                                maxHeight: isPDFExport ? 'auto' : '70px',
                                overflowY: 'auto',
                            }}
                        >
                            {item?.value}
                        </div>
                    </Descriptions.Item>
                ))}
            </Descriptions>
        </AntRow>
    );
};

export default PropertyAmenitiesAndCertifications;
