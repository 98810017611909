import React, { useContext, ReactElement } from 'react';
import { css } from 'emotion';
import { Divider, Skeleton, Space } from 'antd';
import ClearFilters from 'components/app/global-filter-drawer/ClearFilters';
import OpenGlobalFiltersButton from 'components/app/global-filter-drawer/OpenGlobalFiltersButton';
import { AsOfDateIcon } from 'components/app/as-of-date';
import SavedFilterDropdown from 'components/app/global-filter-drawer/SavedFilterDropdown';
import {
    SavedFiltersContext,
    SavedFiltersContextType,
    EntityAttributesContext,
    EntityAttributesContextType,
} from 'contexts';
import { useEntityOwnershipContext } from 'waypoint-hooks';
import { EntityOwnershipDataLevelDropdown } from 'components/ownershipPercentage/EntityOwnershipDataLevelDropdown';
import theme from 'config/theme';

const filterDateContainer = css`
    display: flex;
    margin-left: auto;
    align-items: center;
    justify-content: flex-end;
`;

const loadingContainer = css`
    display: flex;
    margin-left: 10px;
    margin-top: 40px;
    align-items: center;
    justify-content: space-between;
`;

const asOfDateContainer = css`
    display: flex;
    margin-right: -35px;
    align-items: center;
    margin-left: 15px;
`;

const LoadingState = (): ReactElement => {
    return (
        <>
            <OpenGlobalFiltersButton />
            <div className={loadingContainer}>
                <Space>
                    <Skeleton.Button
                        active
                        size="small"
                        style={{ width: '200px', alignSelf: 'center' }}
                    />
                    <Skeleton.Button
                        active
                        size="small"
                        style={{
                            marginLeft: '5px',
                            width: '50px',
                            alignSelf: 'center',
                        }}
                    />
                </Space>
            </div>
            <div className={filterDateContainer}>
                <Space>
                    <Skeleton.Button
                        active
                        size="small"
                        style={{ width: '200px', alignSelf: 'center' }}
                    />
                    <Skeleton.Button
                        active
                        size="small"
                        style={{
                            width: '40px',
                            alignSelf: 'center',
                            marginRight: '10px',
                        }}
                    />
                </Space>
            </div>
        </>
    );
};

interface SubBarProps {
    hideGlobalFilters: boolean;
    hideDataLevelSelection: boolean;
}

const SubBar = function ({
    hideGlobalFilters,
    hideDataLevelSelection,
}: SubBarProps): ReactElement {
    const savedFilterContext = useContext<SavedFiltersContextType | null>(
        SavedFiltersContext,
    );
    const entityContext = useContext<EntityAttributesContextType | null>(
        EntityAttributesContext,
    );
    const ownershipContext = useEntityOwnershipContext();

    if (
        savedFilterContext?.isSavedFiltersLoading ||
        entityContext?.isAttributesLoading ||
        ownershipContext?.isLoading
    ) {
        return <LoadingState />;
    }

    return (
        <>
            {!hideGlobalFilters ? (
                <>
                    <OpenGlobalFiltersButton />

                    <ClearFilters />

                    <Divider type="vertical" />
                </>
            ) : null}

            {!hideDataLevelSelection ? (
                <EntityOwnershipDataLevelDropdown />
            ) : null}

            {!hideGlobalFilters ? (
                <div className={filterDateContainer}>
                    <SavedFilterDropdown />

                    <div className={asOfDateContainer}>
                        <AsOfDateIcon />
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default SubBar;
