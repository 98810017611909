import PropTypes from 'prop-types';
import React from 'react';
import OptionBox from 'components/settings/client/OptionBox';
import PopoverGeneric from 'components/style/PopoverGeneric';

const rowBox = {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-evenly',
    alignItems: 'stretch',
};

const tooltipMessage = `
Rounding Rules:
${'\n'}
For values .50 and greater we will round up to the nearest
dollar when removing decimal places.
${'\n'}
For values .50 or less we will round down to the nearest dollar
when removing decimal places.
`;

const RadioOptions = ({
    decimalDisplay,
    negativeValue,
    setNumberFormat,
    clientId,
}) => {
    return (
        <div>
            <h3>
                Decimal Display
                <PopoverGeneric message={tooltipMessage} placement="right" />
            </h3>
            <div style={rowBox}>
                <OptionBox
                    name="decimalDisplay"
                    id={0}
                    label="Yes"
                    example="$123,456.78"
                    value="Yes"
                    active={
                        decimalDisplay === true ||
                        decimalDisplay.toString().toLowerCase() === 'yes'
                    }
                    selector={({ value }) =>
                        setNumberFormat({
                            decimalDisplay: value,
                            negativeValue,
                            clientId,
                        })
                    }
                />

                <OptionBox
                    name="decimalDisplay"
                    id={1}
                    label="No"
                    example="$123,457"
                    value="No"
                    active={
                        decimalDisplay === false ||
                        decimalDisplay.toString().toLowerCase() === 'no'
                    }
                    selector={({ value }) =>
                        setNumberFormat({
                            decimalDisplay: value,
                            negativeValue,
                            clientId,
                        })
                    }
                />
            </div>
            <h3>Negative Value Symbols</h3>
            <div style={rowBox}>
                <OptionBox
                    name="negativeValue"
                    id={2}
                    label="Currency"
                    example="-$123,456"
                    value="Currency"
                    active={negativeValue.toLowerCase() === 'currency'}
                    selector={({ value }) =>
                        setNumberFormat({
                            decimalDisplay,
                            negativeValue: value,
                            clientId,
                        })
                    }
                />

                <OptionBox
                    name="negativeValue"
                    id={3}
                    label="Accounting"
                    example="$(123,456)"
                    value="Accounting"
                    active={negativeValue.toLowerCase() === 'accounting'}
                    selector={({ value }) =>
                        setNumberFormat({
                            decimalDisplay,
                            negativeValue: value,
                            clientId,
                        })
                    }
                />
            </div>
        </div>
    );
};

RadioOptions.propTypes = {
    clientId: PropTypes.number,
    decimalDisplay: PropTypes.string,
    negativeValue: PropTypes.string,
    setNegativeValue: PropTypes.func,
    setDecimalDisplay: PropTypes.func,
    setNumberFormat: PropTypes.func,
};

export default RadioOptions;
