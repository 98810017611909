import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { toArray, isNil, get } from 'lodash';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { message } from 'antd';
import { css } from 'emotion';
import { DASH_DASH } from 'config/constants';
import {
    entityPermissionColumns,
    ACCESS_ALL_ENTITIES,
    CHOOSE_ENTITY,
} from 'components/settings/permissions/utils';
import {
    getUserEntityPermission,
    updateUserEntityPermission,
} from 'waypoint-requests';
import {
    selectProperties,
    selectPropertiesById,
} from 'state/properties/selectors';
import {
    PermissionsTable,
    PermissionsOption,
    PermissionsSelect,
} from 'components/settings/permissions';
import {
    selectFilteredProperties,
    selectFilteredUsers,
    selectFilteredTenants,
} from 'waypoint-utils';

const tableContainerStyle = css`
    height: 80%;
    overflow-y: hidden;
`;
const header = css`
    margin-bottom: 12px;
    display: flex;
    justify-content: flex-start;

    & strong {
        margin-top: 26px;
        margin-left: 140px;
        font-size: 16px;
    }
`;
const selectEntityContainer = css`
    padding-bottom: 15px;
`;
const saveButton = css`
    width: 11%;
    margin-top: 50px;
`;

class PermissionsCard extends Component {
    static propTypes = {
        searchProperties: PropTypes.func.isRequired,
    };

    state = {
        searchText: '',
        isTableVisible:
            !this.props.permissions.entityPermissionSettings.accessAllEntities,
        loading: false,
        entityPermissionSettings:
            this.props.permissions.entityPermissionSettings,
        entityPermissions: this.props.permissions.entityPermissions,
        submitting: false,
        userSelected: null,
    };

    setEntityPermissions(entityPermissions) {
        this.setState({
            entityPermissionSettings: { accessAllEntities: false },
            entityPermissions,
        });
    }

    getEntityCodeArray() {
        const { entityPermissions } = this.state;
        return entityPermissions.map((permission) => permission.entityCode);
    }

    isDisableSaveButton = () => {
        const { entityPermissions, entityPermissionSettings, submitting } =
            this.state;

        return (
            submitting ||
            (!entityPermissionSettings.accessAllEntities &&
                entityPermissions.length === 0)
        );
    };

    isAllEntityCode = () => {
        const { entityPermissionSettings } = this.state;
        return entityPermissionSettings.accessAllEntities
            ? ACCESS_ALL_ENTITIES
            : CHOOSE_ENTITY;
    };

    handleOnCheck = ({ target }) => {
        const { entityPermissions } = this.state;

        if (target.checked) {
            entityPermissions.push({ entityCode: target.value });
        } else {
            const entityCode = entityPermissions.map(
                (permission) => permission.entityCode,
            );
            const removeIndex = entityCode.indexOf(target.value);
            entityPermissions.splice(removeIndex, 1);
        }
        this.setEntityPermissions(entityPermissions);
    };

    handleOnChange = ({ target }) => {
        if (target.value === CHOOSE_ENTITY) {
            this.setState({
                isTableVisible: true,
                entityPermissionSettings: { accessAllEntities: false },
            });
        } else {
            this.setState({
                isTableVisible: false,
                entityPermissionSettings: { accessAllEntities: true },
            });
        }
    };

    handleOnApplyUserSelect = () => {
        const { userSelected } = this.state;

        this.setState(
            {
                loading: true,
            },
            async () => {
                try {
                    const { entityPermissionSettings, entityPermissions } =
                        await getUserEntityPermission(userSelected);

                    this.setState({
                        entityPermissionSettings,
                        entityPermissions,
                    });
                } catch (e) {
                    message.error(
                        'Request Failed',
                        'An error occurred while requesting permissions',
                    );
                } finally {
                    this.setState({ loading: false });
                }
            },
        );
    };

    handleOnChangeUserSelect = (userId) => {
        const { user } = this.props;
        const userSelected = isNil(userId) ? user.id : userId;

        this.setState({ userSelected });
    };

    handleSearch = ({ target }) => {
        const { value } = target;
        this.setState({ searchText: value });
    };

    handleOnSave = () => {
        const { entityPermissionSettings, entityPermissions } = this.state;
        const payload = { entityPermissionSettings, entityPermissions };

        const { user } = this.props;

        this.setState(
            {
                submitting: true,
            },
            async () => {
                try {
                    await updateUserEntityPermission(payload, user.id);
                    message.success(
                        'Success',
                        'Permissions updated successfully.',
                    );
                } catch (error) {
                    message.error(
                        'Request Failed',
                        'An error occurred while requesting user permissions',
                    );
                } finally {
                    this.setState({ submitting: false });
                }
            },
        );
    };

    render() {
        const { user } = this.props;

        const { entityPermissions, isTableVisible, loading, searchText } =
            this.state;

        const filteredProperties = selectFilteredProperties(
            this.props,
            searchText,
        );

        const filteredUsers = selectFilteredUsers(this.props, searchText);
        const countEntity = entityPermissions.length;

        return (
            <div>
                <div>
                    <div className={header}>
                        <h1>Permissions</h1>
                        <strong>
                            {user?.firstname} {user?.lastname} - {user?.email}
                        </strong>
                    </div>
                    <div className={tableContainerStyle}>
                        <div className={selectEntityContainer}>
                            <PermissionsOption
                                onChange={this.handleOnChange}
                                isAllEntityCode={this.isAllEntityCode()}
                                onSearch={this.handleSearch}
                                searchText={this.state.searchText}
                            />
                            <PermissionsSelect
                                users={toArray(filteredUsers)}
                                onChange={this.handleOnChangeUserSelect}
                                onApply={this.handleOnApplyUserSelect}
                            />
                        </div>
                        <div>
                            {isTableVisible && (
                                <PermissionsTable
                                    loading={loading}
                                    columns={entityPermissionColumns(
                                        this.handleOnCheck,
                                        this.getEntityCodeArray(),
                                    )}
                                    data={toArray(filteredProperties)}
                                    countEntity={countEntity}
                                />
                            )}
                        </div>
                    </div>

                    <div>
                        <Button
                            disabled={this.isDisableSaveButton()}
                            className={saveButton}
                            onClick={this.handleOnSave}
                            type="primary"
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapState = (state) => {
    return {
        properties: selectProperties(state),
        users: state.users,
    };
};

const mapDispatch = (dispatch) => {
    return {
        searchProperties: (searchText) => {
            dispatch({ type: 'SEARCH_PROPERTIES', payload: searchText });
        },
    };
};

export default connect(mapState, mapDispatch)(PermissionsCard);
