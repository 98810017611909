import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import useSWR from 'swr';
import { getFeaturePermissions } from 'waypoint-requests';
import { FeaturePermission } from 'waypoint-types';

export const useGetFeaturePermissions = (
    clientId: number,
    userId: number
): DataHookResponse<FeaturePermission[]> => {
    const { data, error, mutate } = useSWR(
        `/permissions-groups/${clientId}/feature-permissions/${userId}`,
        () => {
            return getFeaturePermissions(clientId);
        },
        {
            revalidateOnFocus: true,
            revalidateOnMount: true,
        }
    );

    return {
        data,
        mutate,
        isLoading: !data,
        isError: !!error,
    };
};
