import { Menu, MenuProps } from 'antd';
import React from 'react';
import { MenuItem } from './types';

interface PropertyDetailMenuProps {
    onClick: MenuProps['onClick'];
    menuItems: MenuItem[];
    defaultOpenKeys: string[];
    selectedKeys: string[];
}

const PropertyProfileMenu = ({
    menuItems,
    onClick,
    selectedKeys,
    defaultOpenKeys,
}: PropertyDetailMenuProps) => (
    <Menu
        defaultOpenKeys={defaultOpenKeys}
        mode="inline"
        onClick={onClick}
        selectedKeys={selectedKeys}
        theme="light"
        style={{ paddingLeft: '10px', paddingRight: '10px' }}
        items={menuItems}
    />
);

export default PropertyProfileMenu;
