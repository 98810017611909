import { compose } from 'redux';
import defaultStylePP from './defaultStylePP';
import numberWithCommasPP from './numberWithCommasPP';
import { DefaultCell } from './DefaultCellRenderer';

export const NumberWithCommasCellRenderer = compose(
    defaultStylePP,
    numberWithCommasPP
)(DefaultCell);
export default NumberWithCommasCellRenderer;
