import { API_URL } from 'config/constants';
import { ValuationsWithMethodOptions } from 'waypoint-types/valuations/types';

const getValuations = async (
    entityCode: string
): Promise<ValuationsWithMethodOptions> => {
    const response = await fetch(`${API_URL}/valuations/${entityCode}`, {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
    });

    if (!response.ok) {
        throw new Error('Failed to get valuation');
    }

    const { data } = await response.json();
    return data as ValuationsWithMethodOptions;
};

export default getValuations;
