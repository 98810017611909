import React from 'react';
import { cx } from 'emotion';
import { toolTipClassnames } from 'components/leases/tenants/utils';

const WALETooltip = ({ totalWALE }) => {
    return (
        <div className={toolTipClassnames.container}>
            <div
                className={cx([
                    toolTipClassnames.label,
                    toolTipClassnames.topLabel,
                ])}
            >
                <div>Industry</div>
                <div className={toolTipClassnames.figure}>$label</div>
            </div>
            <div className={toolTipClassnames.label}>
                <div>Sum</div>
                <div className={toolTipClassnames.figure}>{totalWALE}</div>
            </div>
        </div>
    );
};

export default WALETooltip;
