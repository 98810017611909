import React from 'react';
import { Button } from 'antd';

interface BottomMenuProps {
    batchValues: any;
    onEdit: () => void;
    onCancel: () => void;
}

const EditBottomMenu = ({ batchValues, onEdit, onCancel }: BottomMenuProps) => {
    return (
        <>
            <div>
                <span
                    style={{
                        marginLeft: 18,
                        fontSize: '14px',
                        paddingRight: '18px',
                    }}
                >
                    {batchValues.selectedReports.length} Propert
                    {batchValues.selectedReports.length > 1 ? 'ies' : 'y'}{' '}
                    Selected
                </span>
                <Button type="primary" onClick={onEdit}>
                    Edit
                </Button>
            </div>
            <div>
                <Button
                    style={{
                        marginRight: '18px',
                    }}
                    onClick={onCancel}
                >
                    Cancel
                </Button>
            </div>
        </>
    );
};

export default EditBottomMenu;
