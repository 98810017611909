import theme from 'config/theme';
import { RootStateOrAny } from 'react-redux';
import {
    hasBalanceSheetEnabled,
    hasCapitalTrackerEnabled,
    hasOccupancyTrendEnabled,
    hasPlanningSectionEnabled,
    hasTenantsEnabled,
    hasCrosstabEnabled,
    hasAttributesEnabled,
    hasLeasesExpirationEnabled,
    hasRentRollEnabled,
    hasLeasesEnabled,
    hasUnitMixEnabled,
    hasAgedReceivablesEnabled,
} from 'state/user/selectors';

type ComponentFeatureFlagMapping = {
    [componentValue: string]: (state: RootStateOrAny) => boolean;
};

export const componentFeatureFlagMapping: ComponentFeatureFlagMapping = {
    balance_sheet: hasBalanceSheetEnabled,
    capital_projects: hasCapitalTrackerEnabled,
    capital_plan: hasCapitalTrackerEnabled,
    occupancy_trend: hasOccupancyTrendEnabled,
    tenants: hasTenantsEnabled,
    hold_sell: hasPlanningSectionEnabled,
    priorities_and_objectives: hasPlanningSectionEnabled,
    market_overview: hasPlanningSectionEnabled,
    swot: hasPlanningSectionEnabled,
    crosstab: hasCrosstabEnabled,
    attributes: hasAttributesEnabled,
    expiration_schedule: hasLeasesExpirationEnabled,
    rent_roll: hasRentRollEnabled,
    recurring_charge_summary: hasLeasesEnabled,
    unit_mix: hasUnitMixEnabled,
    aged_receivables: hasAgedReceivablesEnabled,
};

export const COMPONENTS_LIST = [
    {
        value: 'income_statement',
        label: 'Income Statement',
        category: 'Financials',
    },
    {
        value: 'balance_sheet',
        label: 'Balance Sheet',
        category: 'Financials',
    },
    {
        label: 'Expiration Schedule',
        value: 'expiration_schedule',
        category: 'Leasing',
    },
    {
        label: 'Rent Roll',
        value: 'rent_roll',
        category: 'Leasing',
    },
    {
        label: 'Recurring Charge Summary',
        value: 'recurring_charge_summary',
        category: 'Leasing',
    },
    {
        label: 'Occupancy Trend',
        value: 'occupancy_trend',
        category: 'Leasing',
    },
    {
        label: 'Hold Sell',
        value: 'hold_sell',
        category: 'Strategy & Planning',
    },
    {
        label: 'Leasing Guidelines',
        value: 'leasing_guidelines',
        category: 'Strategy & Planning',
    },
    {
        label: 'Priorities and Objectives',
        value: 'priorities_and_objectives',
        category: 'Strategy & Planning',
    },
    {
        label: 'Executive Summary',
        value: 'executive_summary',
        category: 'Miscellaneous',
    },
    {
        label: 'Market Overview',
        value: 'market_overview',
        category: 'Strategy & Planning',
    },
    {
        label: 'Capital Projects',
        value: 'capital_projects',
        category: 'Capital',
    },
    {
        label: 'Capital Plan',
        value: 'capital_plan',
        category: 'Capital',
    },
    {
        label: 'SWOT',
        value: 'swot',
        category: 'Strategy & Planning',
    },
    {
        label: 'Contracts',
        value: 'contracts',
        category: 'Strategy & Planning',
    },
    {
        label: 'Aged Receivables',
        value: 'aged_receivables',
        category: 'Aged Receivables',
    },
    {
        label: 'Top Tenants',
        value: 'top_tenants',
        category: 'Leasing',
    },
    {
        label: 'Unit Mix',
        value: 'unit_mix',
        category: 'Leasing',
    },
    {
        label: 'Attributes',
        value: 'attributes',
        category: 'Miscellaneous',
    },
    {
        label: 'Property Information',
        value: 'property_information',
        category: 'Miscellaneous',
    },
    {
        label: 'Attachment',
        value: 'attachment',
        category: 'Custom',
    },
    {
        label: 'Narrative',
        value: 'narrative',
        category: 'Custom',
    },
    {
        label: 'Leasing Plan',
        value: 'leasing_plan',
        category: 'Strategy & Planning',
    },
];

// Height of topbar, sub bar, panels, cards and margins in the report detail page
export const ALL_AROUND_HEIGHT = 596;

// Maybe this can be one an endpoint as well but for UI testing purposes, will be deleted after integrate an endpoint to get the list of available report status
export const statusOptions: {
    value: string;
    label: string;
    color: string;
}[] = [
    { value: 'draft', label: 'Draft', color: `${theme.colors.grays.medium}` },
    { value: 'active', label: 'Active', color: `${theme.colors.green}` },
    {
        value: 'archived',
        label: 'Archived',
        color: `${theme.colors.grays.mediumDark}`,
    },
];

export const WorkflowTabKeys = {
    Details: 0,
    Setup: 1,
    Assign: 2,
};

export const TabKeys = {
    ReportTemplate: 'reportTemplate',
    PropertyAssignments: 'propertyAssignments',
    ReportSettings: 'reportSettings',
};

export const MyReportSections = {
    assigneeReports: 'assigneeReports',
    reviewerReports: 'reviewerReports',
};

export const workflowStatusOptions = [
    {
        value: 'open',
        label: 'Open',
        color: theme.colors.workflowReportStatus.open,
    },
    {
        value: 'in_progress',
        label: 'In Progress',
        color: theme.colors.workflowReportStatus.in_progress,
    },
    {
        value: 'complete',
        label: 'Complete',
        color: theme.colors.workflowReportStatus.approved,
    },
];

export const OPEN = 'open';
export const IN_PROGRESS = 'in_progress';
export const COMPLETE = 'complete';
export const TRASH_SECTION = 'trash';

export const NarrativePositions = {
    Above: 'above',
    Below: 'below',
};

export const WidgetTypes = {
    CoverPage: 'cover_page',
    TableOfContents: 'table_of_contents',
    Approvals: 'approvals',
    Footer: 'footer',
};

export const reportStatusNormalized = {
    draft: 'Draft',
    active: 'Active',
    archived: 'Archived',
};

export const reportStatus = {
    draft: 'draft',
    active: 'active',
    archived: 'archived',
};

export enum PDFExportOptions {
    ENTIRE_REPORT = 'entire_report',
    CURRENT_SECTION = 'current_section',
    SELECTED_SECTIONS = 'selected_sections',
}

export const FinancialExportOptions = {
    SUMMARY: 'summary',
    FULL_DETAIL: 'fullDetail',
    CUSTOM: 'custom',
};

export const groupingOptions = [
    { value: 'report_name', label: 'Report' },
    { value: 'property', label: 'Property' },
];

export const HIERARCHY_APPROVAL = 'hierarchical_approval';

export enum ReportWorkflowReviewStatus {
    Approved = 'Approved',
    Rejected = 'Rejected',
    InProgress = 'In Progress',
    InReview = 'In Review',
    Open = 'Open',
}

export type EntityReportReviewStatus =
    | ReportWorkflowReviewStatus.Approved
    | ReportWorkflowReviewStatus.Rejected;

export enum ReportMetadataReportTypes {
    report = 'entity_report',
    entityReportPublished = 'entity_report_published',
}

export enum WorkflowRoleReferenceType {
    Entity = 'entity',
    EntityReport = 'entity_report',
    EntityReportPublished = 'entity_report_published',
}

export enum AntdStepsStatus {
    Wait = 'wait', // not currently used in our code, but a valid antd option
    Process = 'process', // shows step number
    Finish = 'finish', // shows check mark
    Error = 'error', // shows x
}
