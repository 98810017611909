import { API_URL } from 'config/constants';

interface UpdateEntityProfileCardDto {
    year: number;
    title: string;
    notes: string;
    metadata?: { value: string; label: string; color: string }[];
}

export async function updateEntityProfileCard(
    entityCode: string,
    entityProfileCardId: string,
    { year, title, notes, metadata }: UpdateEntityProfileCardDto
) {
    const response = await fetch(
        `${API_URL}/properties/${entityCode}/profile-cards/${entityProfileCardId}`,
        {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({
                year,
                title,
                metadata: metadata ?? null,
                notes: notes ?? '',
            }),
        }
    );

    if (!response.ok) {
        throw new Error('Failed to update profile card');
    }

    return response;
}
