import {
    AccountGraphNode,
    AccountGraphCalculations,
    FixAnyType,
} from 'waypoint-types';
import { find, get } from 'lodash';

interface DecoratedAccountGraph extends AccountGraphNode {
    isRootNode: boolean;
    isTotalNode: boolean;
}

export interface PredicateNode {
    period_start?: string;
    period_end?: string;
    mode?: string;
}

const DEFAULT_CALCULATIONS_FIELD = 'calculations';

export default class AccountMappingService {
    getEntriesFor(account: DecoratedAccountGraph) {
        const childSummaryData = get(account, 'child_summary_data');
        if (!childSummaryData) {
            const leafNodeData = get(account, 'data');
            return get(leafNodeData, 'entries');
        }
        return get(childSummaryData, 'entries');
    }

    getEntryFor(
        account: DecoratedAccountGraph,
        predicate: PredicateNode,
    ): FixAnyType {
        const entries = this.getEntriesFor(account);
        return find(entries, predicate);
    }

    getEntryAmountFor(
        account: DecoratedAccountGraph,
        predicate: PredicateNode,
    ): string {
        const entry = this.getEntryFor(account, predicate);
        return get(entry, 'amount');
    }

    getCalculationsFor(
        account: DecoratedAccountGraph,
        calculationsField = DEFAULT_CALCULATIONS_FIELD,
    ): FixAnyType {
        const childSummaryData = get(account, 'child_summary_data');
        if (!childSummaryData) {
            const leafNodeData = get(account, 'data');
            return get(leafNodeData, calculationsField);
        }
        return (
            get(childSummaryData, calculationsField) ??
            ({} as AccountGraphCalculations)
        );
    }

    getVarianceAmountFor(
        account: DecoratedAccountGraph,
        calculationsField = DEFAULT_CALCULATIONS_FIELD,
    ): number {
        const calculation = this.getCalculationsFor(account, calculationsField);
        return get(calculation, 'variance_amount');
    }

    getVariancePercentFor(
        account: DecoratedAccountGraph,
        calculationsField = DEFAULT_CALCULATIONS_FIELD,
    ): number {
        const calculation = this.getCalculationsFor(account, calculationsField);
        return get(calculation, 'variance_percent');
    }

    getIsThresholdExceededFor(
        account: DecoratedAccountGraph,
        calculationsField = DEFAULT_CALCULATIONS_FIELD,
    ): boolean {
        const isLeafNode = !account.children;
        const isCalculatedField = !account.children && account.isRootNode;
        const isTotalField = !account.children && account.isTotalNode;
        return isLeafNode && !isCalculatedField && !isTotalField
            ? get(
                  this.getCalculationsFor(account, calculationsField),
                  'threshold_exceeded',
              )
            : false;
    }

    getVarianceIsGoodFor(
        account: DecoratedAccountGraph,
        calculationsField = DEFAULT_CALCULATIONS_FIELD,
    ): boolean {
        const calculation = this.getCalculationsFor(account, calculationsField);
        return get(calculation, 'variance_is_good');
    }
}
