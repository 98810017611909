import PropTypes from 'prop-types';
import React from 'react';
import { merge } from 'lodash';

const defaultOverlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0.85,
    backgroundColor: '#182738',
    transition: 'opacity 0.3s',
    WebkitTransition: 'opacity 0.3s',
    cursor: 'pointer',
};

const style = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 3,
};

export default function Overlay(props) {
    const { overlayStyle } = props;

    if (!props.show) {
        return null;
    }

    return (
        <div style={style}>
            <div style={merge(defaultOverlayStyle, overlayStyle)} />
        </div>
    );
}

Overlay.propTypes = {
    show: PropTypes.bool,
    overlayStyle: PropTypes.object,
};
