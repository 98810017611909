import React from 'react';
import PropTypes from 'prop-types';
import { css, cx } from 'emotion';
import theme from 'config/theme';
import { RangePicker } from 'waypoint-react';

const rangeSelect = css`
    .ant-input {
        display: flex;
    }
`;

const background = css`
    padding: 10px;
    background-color: ${theme.colors.grays.background};
`;

const container = css`
    display: flex;
    justify-content: space-between;
    .ant-calendar-picker {
        width: 100%;
    }
`;

class RangeSelect extends React.Component {
    static propTypes = {
        onApply: PropTypes.func,
        format: PropTypes.string,
        value: PropTypes.array,
        displayBackground: PropTypes.bool,
        pickerPeriod: PropTypes.string,
    };

    static defaultProps = {
        displayBackground: false,
        format: 'MMMM YYYY',
    };

    state = { values: null };

    render = () => {
        const { format, value, displayBackground, pickerPeriod, ...rest } =
            this.props;
        return (
            <div
                className={
                    displayBackground ? cx([container, background]) : container
                }
            >
                <RangePicker
                    value={this.state.values || value}
                    format={format}
                    allowClear={false}
                    className={rangeSelect}
                    onPanelChange={(values) => {
                        this.props.onApply(values);
                    }}
                    separator="-"
                    picker={'month' || pickerPeriod}
                    {...rest}
                />
            </div>
        );
    };
}

export default RangeSelect;
