import { API_URL } from 'config/constants';

export async function deleteGlobalFilter(
    id: string,
    deletePermanently: boolean = true
): Promise<void> {
    const response = await fetch(
        `${API_URL}/global-filters/${id}/${deletePermanently}`,
        {
            credentials: 'include',
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        }
    );

    if (!response.ok) {
        throw new Error('Failed to delete global filter');
    }

    const { data } = await response.json();

    if (data.id !== id) {
        throw new Error('Deleted filter id did not match what we requested');
    }
}
