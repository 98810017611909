import {
    createContext,
    CSSProperties,
    HTMLAttributes,
    ReactElement,
    useCallback,
    useContext,
    useMemo,
    useState,
} from 'react';
import {
    Button,
    Card,
    Checkbox,
    Collapse,
    Input,
    Popconfirm,
    Radio,
    Skeleton,
    Space,
    Splitter,
    Table,
    TableColumnsType,
    Tooltip,
    Typography,
} from 'antd';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
    AttributeDefinition,
    getAllKPIMetrics,
    getAttributeDefinitionFromKPIRequest,
    getKPIRequestKey,
    KPIBasicConfigurationMappings,
    KPIColumnMappings,
    KPIGridConfiguration,
    KPIGroupDefinition,
    kpiGroupDefinitions,
    KPIMetric,
} from 'shared-types';
import { CSS } from '@dnd-kit/utilities';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import {
    CloseOutlined,
    HolderOutlined,
    InfoCircleOutlined,
    SearchOutlined,
} from '@ant-design/icons';
import {
    AttributeFromAPI,
    FilterReferenceTypes,
    usePermissions,
} from 'contexts';
import {
    createSavedConfiguration,
    deleteGlobalFilter,
    updateSavedConfiguration,
} from 'waypoint-requests';
import useMessage from 'antd/es/message/useMessage';
import { useHistory, useParams } from 'react-router-dom';
import { RouteUrls } from 'routes/RouteConstants';
import {
    useGetAttributes,
    useGetGroupableAttributes,
    useGetSavedConfigurationById,
    useGetSavedConfigurations,
    useGetSelectedFilteredEntityCodes,
    useGetUserData,
} from 'waypoint-hooks';
import { SavedConfiguration } from 'waypoint-types';
import { groupBy } from 'lodash';
import { stringSort } from 'utils/tables/sorters';
import { EntityDataGroupingKeys } from 'utils/EntityDataGroupingConstants';
import { useGetUnavailableKPIs } from 'waypoint-hooks/data-access/useGetUnavailableKPIs';
import { AttributesGroupBySelect } from 'components/attributesGroupBySelect/AttributesGroupBySelect';

interface KPIGridEditorProps {
    metrics: KPIMetric[];
    attributeDefinitions: AttributeDefinition[];
    groupableAttributes: AttributeFromAPI[];
    existingViewSettings?: SavedConfiguration<KPIGridConfiguration>;
}

interface RowContextProps {
    setActivatorNodeRef?: (element: HTMLElement | null) => void;
    listeners?: SyntheticListenerMap;
}

const RowContext = createContext<RowContextProps>({});

interface RowProps extends HTMLAttributes<HTMLTableRowElement> {
    'data-row-key': string;
}

export function SortableRow(props: RowProps): ReactElement {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id: props['data-row-key'] });

    const style: CSSProperties = {
        ...props.style,
        transform: CSS.Translate.toString(transform),
        transition,
        ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    };

    const contextValue = useMemo<RowContextProps>(
        () => ({ setActivatorNodeRef, listeners }),
        [setActivatorNodeRef, listeners],
    );

    return (
        <RowContext.Provider value={contextValue}>
            <tr {...props} ref={setNodeRef} style={style} {...attributes} />
        </RowContext.Provider>
    );
}

const DragHandle: React.FC = () => {
    const { setActivatorNodeRef, listeners } = useContext(RowContext);
    return (
        <Button
            type="text"
            size="small"
            icon={<HolderOutlined />}
            style={{ cursor: 'move' }}
            ref={setActivatorNodeRef}
            {...listeners}
        />
    );
};

interface KPIMetricWithKey extends KPIMetric {
    key: string;
}

export function KPIGridEditorPage() {
    const { configJSON } = useGetUserData();
    const { gridId } = useParams() ?? {};
    const { data: existingViewSettings } =
        useGetSavedConfigurationById<KPIGridConfiguration>(
            gridId === 'new' ? null : gridId,
        );

    const entityCodes = useGetSelectedFilteredEntityCodes();

    const { data: attributes } = useGetAttributes(entityCodes);
    const { data: unavailableKPIs } = useGetUnavailableKPIs();
    const isLoading =
        !attributes ||
        !unavailableKPIs ||
        (gridId !== 'new' && !existingViewSettings);

    const groupableAttributes = useGetGroupableAttributes();

    const { featurePermissions } = usePermissions();

    const allKPIMetrics = !isLoading
        ? getAllKPIMetrics(
              attributes.attributeDefinitions,
              featurePermissions,
              configJSON ?? {},
          )
        : [];
    const allAvailableKPIMetrics = allKPIMetrics.filter(
        ({ kpi }) => !(unavailableKPIs ?? []).includes(kpi),
    );

    return isLoading ? (
        <Skeleton />
    ) : (
        <KPIGridEditor
            metrics={allAvailableKPIMetrics}
            attributeDefinitions={attributes.attributeDefinitions}
            groupableAttributes={groupableAttributes}
            existingViewSettings={existingViewSettings}
        />
    );
}

interface CheckboxChild {
    title: string;
    key: string;
    subgroup?: string;
}

export function KPIGridEditor({
    metrics,
    attributeDefinitions,
    groupableAttributes,
    existingViewSettings,
}: KPIGridEditorProps): ReactElement {
    const history = useHistory();
    const isCreating = !existingViewSettings;

    const [messageApi, messageContext] = useMessage();

    const [searchText, setSearchText] = useState('');
    const [targetMetricKeys, setTargetMetricKeys] = useState<string[]>(
        existingViewSettings?.filters_json?.metrics.map((metric) =>
            getKPIRequestKey(metric, metric.metric),
        ) ?? [],
    );

    const [viewScope, setViewScope] = useState<FilterReferenceTypes>(
        (existingViewSettings?.reference_type as FilterReferenceTypes) ??
            FilterReferenceTypes.USER,
    );
    const [name, setName] = useState(existingViewSettings?.name ?? '');
    const [groupingType, setGroupingType] =
        useState<EntityDataGroupingKeys | null>(
            existingViewSettings?.filters_json?.groupBy?.attributeCode
                ? EntityDataGroupingKeys.Attributes
                : null,
        );
    const [attributeGroupBy, setAttributeGroupBy] =
        useState<AttributeFromAPI | null>(
            groupableAttributes.find(
                (attr) =>
                    attr.key ===
                    existingViewSettings?.filters_json?.groupBy?.attributeCode,
            ) ??
                groupableAttributes[0] ??
                null,
        );

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const { userId, clientId, isAdmin } = useGetUserData();
    const { mutate: refreshSavedConfigs } = useGetSavedConfigurations(
        userId,
        clientId,
        'kpis',
    );

    const metricsWithKey: KPIMetricWithKey[] = metrics.map((metric) => {
        return {
            ...metric,
            key: getKPIRequestKey(metric, metric.metric),
        };
    });

    const columns: TableColumnsType<KPIMetricWithKey> = [
        {
            key: 'sort',
            align: 'center',
            width: 80,
            render: () => <DragHandle />,
        },
        {
            key: 'kpi',
            title: 'Metric',
            render: (value, metric) => {
                const columnMappingGenerator = KPIColumnMappings[metric.kpi];

                if (!columnMappingGenerator) {
                    return metric.kpi;
                }

                const attributeDefinition =
                    getAttributeDefinitionFromKPIRequest(
                        metric,
                        attributeDefinitions,
                    );
                const mapping = columnMappingGenerator(
                    metric,
                    attributeDefinition,
                ).find((mapping) => mapping.key === metric.key);

                if (!mapping) {
                    return metric.kpi;
                }

                return mapping.title;
            },
        },
        {
            key: 'remove',
            width: 80,
            render: (value, metric) => (
                <Button
                    type="text"
                    onClick={() => removeSelectedMetric(metric.key)}
                >
                    <CloseOutlined />
                </Button>
            ),
        },
    ];

    function removeSelectedMetric(key: string) {
        setTargetMetricKeys((prevState) => {
            return [...prevState.filter((k) => k !== key)];
        });
    }

    const getMetricsByGroupTree = useCallback(() => {
        return Object.keys(kpiGroupDefinitions)
            .map((kpiGroupKey) => {
                const { title, getSubGroup } = kpiGroupDefinitions[
                    kpiGroupKey
                ] as KPIGroupDefinition;

                return {
                    title,
                    key: kpiGroupKey,
                    children: metricsWithKey
                        .filter(
                            (metric) =>
                                KPIBasicConfigurationMappings[metric.kpi]
                                    .group === kpiGroupKey,
                        )
                        .map((metric) => {
                            const columnMappingGenerator =
                                KPIColumnMappings[metric.kpi];

                            if (!columnMappingGenerator) {
                                return {
                                    key: metric.key,
                                    title: metric.kpi,
                                };
                            }

                            const attributeDefinition =
                                getAttributeDefinitionFromKPIRequest(
                                    metric,
                                    attributeDefinitions,
                                );
                            const mapping = columnMappingGenerator(
                                metric,
                                attributeDefinition,
                            ).find((mapping) => mapping.key === metric.key);

                            const subgroup = getSubGroup
                                ? getSubGroup(metric, attributeDefinition)
                                : undefined;

                            if (!mapping) {
                                return {
                                    key: metric.key,
                                    title: metric.kpi,
                                    subgroup,
                                };
                            }

                            return {
                                key: mapping.key,
                                title: mapping.title,
                                subgroup,
                            };
                        })
                        .filter((item) => {
                            return (
                                !searchText ||
                                item.title
                                    .toLowerCase()
                                    .includes(searchText.toLowerCase())
                            );
                        }),
                };
            })
            .filter((collapsibleItem) => collapsibleItem.children.length);
    }, [attributeDefinitions, metricsWithKey, searchText]);

    function onDragEnd({ active, over }: DragEndEvent) {
        if (active.id !== over?.id) {
            setTargetMetricKeys((prevState) => {
                const activeIndex = prevState.findIndex(
                    (key) => key === active?.id,
                );
                const overIndex = prevState.findIndex(
                    (key) => key === over?.id,
                );

                return arrayMove(prevState, activeIndex, overIndex);
            });
        }
    }

    const getCollapseItems = useCallback(() => {
        return getMetricsByGroupTree().map(({ title, key, children }) => {
            const childrenBySubgroup = groupBy(children, 'subgroup');
            const subgroups = Object.entries(childrenBySubgroup)
                .sort(([subgroupA], [subgroupB]) => {
                    // Make sure that un-grouped items go at the top of the section
                    if (subgroupA === 'undefined') {
                        return -1;
                    }

                    if (subgroupB === 'undefined') {
                        return 1;
                    }

                    return stringSort(subgroupB, subgroupA);
                })
                .map(([subgroup, subgroupChildren]) => {
                    const subgroupChildrenElements =
                        subgroupChildren.map(childToCheckbox);

                    if (subgroup !== 'undefined') {
                        return (
                            <div style={{ marginLeft: '30px' }}>
                                <div>
                                    <Typography.Title level={5}>
                                        {subgroup}
                                    </Typography.Title>
                                </div>

                                {subgroupChildrenElements}
                            </div>
                        );
                    }

                    return (
                        <div style={{ marginLeft: '30px' }}>
                            {subgroupChildrenElements}
                        </div>
                    );
                });

            return {
                key,
                label: (
                    <Typography.Title
                        level={3}
                        style={{ margin: 0, fontSize: '18px' }}
                    >
                        {title}
                    </Typography.Title>
                ),
                children: subgroups,
            };
        });
    }, [getMetricsByGroupTree]);

    function childToCheckbox({ key, title }: CheckboxChild) {
        return (
            <div>
                <Checkbox
                    checked={targetMetricKeys.includes(key)}
                    onChange={(e) => {
                        if (e.target.checked) {
                            setTargetMetricKeys([...targetMetricKeys, key]);
                        } else {
                            setTargetMetricKeys(
                                targetMetricKeys.filter(
                                    (existingKey) => existingKey !== key,
                                ),
                            );
                        }
                    }}
                    style={{ fontWeight: 'normal' }}
                    key={key}
                >
                    {title}
                </Checkbox>
            </div>
        );
    }

    const selectedMetrics = targetMetricKeys
        .map((key) => metricsWithKey.find((metric) => metric.key === key))
        .filter((i) => !!i) as KPIMetricWithKey[];

    async function save() {
        setIsSaving(true);

        const saveFunc = isCreating
            ? createSavedConfiguration
            : updateSavedConfiguration;

        const orderedMetrics = targetMetricKeys.map((targetKey) => {
            return metricsWithKey.find(
                ({ key }) => targetKey === key,
            ) as KPIMetricWithKey;
        });

        try {
            const { id } = await saveFunc({
                id: existingViewSettings?.id ?? '',
                filter_type: 'kpis',
                filters_json: {
                    metrics: orderedMetrics,
                    groupBy: {
                        attributeCode: groupingType
                            ? attributeGroupBy?.key
                            : undefined,
                    },
                },
                name,
                reference_type: viewScope,
            });

            messageApi.success(`KPI view created!`);

            navigateToView(id);
        } catch (e) {
            console.error(e);
            messageApi.error(`Failed to update KPI view.`);

            setIsSaving(false);
        }
    }

    function navigateToView(id?: string) {
        if (id) {
            history.push(RouteUrls.KPI_GRID.replace(':gridId', id));
        } else {
            history.push(RouteUrls.KPI_GRID_INDEX);
        }
    }

    async function tryDeleteFilter() {
        if (!existingViewSettings) {
            return;
        }

        setIsDeleting(true);

        try {
            await deleteGlobalFilter(existingViewSettings?.id);
            await refreshSavedConfigs();
            navigateToView();
        } catch (e) {
            messageApi.error(`Failed to delete view.`);
            setIsDeleting(false);
        }
    }

    function isInvalid(): string | null {
        if (!name) {
            return 'Name is required.';
        }

        if (!targetMetricKeys.length) {
            return 'Must select at least one KPI.';
        }

        return null;
    }

    function canDelete() {
        return (
            isAdmin ||
            (existingViewSettings?.reference_type === 'user' &&
                existingViewSettings?.reference_id === userId.toString())
        );
    }

    return (
        <Card style={{ width: '100%' }}>
            {messageContext}

            <>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                    }}
                >
                    {/* First row: Name and View access with space-between */}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        {/* Name field with inline label */}
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography.Title
                                style={{ marginRight: 8 }}
                                level={4}
                            >
                                {isCreating ? 'Create' : 'Edit'} KPI View
                            </Typography.Title>{' '}
                            <Input
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Give your view a name"
                                style={{ width: '500px' }}
                            />
                        </div>
                    </div>

                    {/* Second row: Default grouping with inline label */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography.Text strong style={{ marginRight: '8px' }}>
                            View access:
                        </Typography.Text>
                        <Radio.Group
                            style={{ marginLeft: 80 }}
                            value={viewScope}
                            onChange={(e) => setViewScope(e.target.value)}
                        >
                            <Radio value="user">Just me</Radio>
                            {isAdmin ? (
                                <Radio value="client">Everyone</Radio>
                            ) : null}
                        </Radio.Group>
                    </div>
                </div>
            </>

            <Splitter
                style={{
                    borderTop: '1px solid lightgrey',
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: '120px',
                    bottom: '60px',
                    height: 'auto',
                }}
            >
                <Splitter.Panel
                    style={{ position: 'relative', height: 'auto' }}
                >
                    <div
                        style={{
                            padding: '12px',
                            borderBottom: '1px solid #f0f0f0',
                            backgroundColor: '#fafafa',
                        }}
                    >
                        <Input
                            placeholder="Search for KPIs"
                            onChange={(e) => setSearchText(e.target.value)}
                            prefix={<SearchOutlined />}
                        />
                    </div>
                    <Collapse
                        items={getCollapseItems()}
                        ghost={true}
                        defaultActiveKey={getCollapseItems().map((i) => i.key)}
                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            bottom: 0,
                            top: '60px',
                            overflowY: 'auto',
                        }}
                    />
                </Splitter.Panel>

                <Splitter.Panel
                    style={{ position: 'relative', height: 'auto' }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            bottom: 0,
                            top: 0,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {/* Fixed header */}
                        <div
                            style={{
                                padding: '12px 34px 12px 16px',
                                fontWeight: 'bold',
                                borderBottom: '1px solid #f0f0f0',
                                backgroundColor: '#fafafa',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                flexWrap: 'wrap',
                                gap: '12px',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 18,
                                }}
                            >
                                <div>
                                    <Typography.Text
                                        style={{ marginRight: '8px' }}
                                    >
                                        Selected Columns (
                                        {selectedMetrics.length}):
                                    </Typography.Text>
                                </div>
                                <div>
                                    <AttributesGroupBySelect
                                        groupingSelection={groupingType}
                                        setGroupingSelection={setGroupingType}
                                        attributeSelection={attributeGroupBy}
                                        attributes={groupableAttributes}
                                        setAttributeSelection={
                                            setAttributeGroupBy
                                        }
                                    />
                                </div>
                            </div>
                            <div>
                                <Tooltip
                                    placement="left"
                                    title={
                                        <>
                                            <div style={{ marginTop: '8px' }}>
                                                Use the Group By to set the
                                                default grouping for your view
                                            </div>
                                            <br></br>
                                            <div>
                                                Use the drag icon to move
                                                columns into the desired order
                                            </div>
                                        </>
                                    }
                                >
                                    <InfoCircleOutlined
                                        style={{ fontSize: '16px' }}
                                    />
                                </Tooltip>
                            </div>
                        </div>

                        {/* Scrollable content area */}
                        <div style={{ flex: 1, overflowY: 'auto' }}>
                            <DndContext
                                modifiers={[restrictToVerticalAxis]}
                                onDragEnd={onDragEnd}
                            >
                                <SortableContext
                                    items={selectedMetrics.map(
                                        (item) => item.key,
                                    )}
                                    strategy={verticalListSortingStrategy}
                                >
                                    <Table<KPIMetricWithKey>
                                        showHeader={false}
                                        rowKey="key"
                                        components={{
                                            body: { row: SortableRow },
                                        }}
                                        columns={columns}
                                        pagination={false}
                                        dataSource={selectedMetrics}
                                    />
                                </SortableContext>
                            </DndContext>
                        </div>
                    </div>
                </Splitter.Panel>
            </Splitter>

            <div
                style={{
                    position: 'absolute',
                    bottom: 0,
                    height: '60px',
                    left: 0,
                    right: 0,
                    paddingRight: '20px',
                    paddingLeft: '20px',
                    paddingTop: '12px',
                    borderTop: `1px solid #e8e8e8`,
                }}
            >
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <Space direction="horizontal">
                        <Popconfirm
                            title="Delete View"
                            description="Are you sure you want to delete this view?"
                            okType="danger"
                            okText="Delete"
                            onConfirm={tryDeleteFilter}
                        >
                            <Button
                                danger
                                disabled={
                                    isCreating || !canDelete() || isSaving
                                }
                                loading={isDeleting}
                            >
                                Delete
                            </Button>
                        </Popconfirm>
                    </Space>

                    <Space direction="horizontal">
                        <Tooltip title={isInvalid() ?? 'Save View'}>
                            <Button
                                type="primary"
                                onClick={save}
                                loading={isSaving}
                                disabled={!!isInvalid() || isDeleting}
                            >
                                Save
                            </Button>
                        </Tooltip>

                        <Button
                            disabled={isSaving || isDeleting}
                            onClick={() =>
                                navigateToView(existingViewSettings?.id)
                            }
                        >
                            Cancel
                        </Button>
                    </Space>
                </div>
            </div>
        </Card>
    );
}
