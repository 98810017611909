import ValuationsCard from 'components/valuations/ValuationsCard';
import { useSelectedDataLevel } from 'waypoint-hooks';

export const ValuationsSection = ({ entityCode }: { entityCode: string }) => {
    const selectedDataLevel = useSelectedDataLevel();

    return (
        <div
            title={'Valuations Section'}
            style={{ margin: '20px 0', padding: '0 24px' }}
            id="valuations-section"
        >
            <div id="valuations" style={{ marginTop: '20px' }}>
                <ValuationsCard
                    entityCode={entityCode}
                    selectedDataLevel={selectedDataLevel}
                    isPropertyProfilePage={true}
                />
            </div>
        </div>
    );
};
