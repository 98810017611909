import React from 'react';
import { css } from 'emotion';
import { Button } from 'antd';
import { PieChartOutlined } from '@ant-design/icons';
import theme from 'config/theme';

const chartButtonStyle = css`
    color: ${theme.colors.grays.light} !important;
    border-color: ${theme.colors.grays.light} !important;
    background: ${theme.colors.white} !important;
    text-shadow: none !important;
`;

const chartButtonStyleActive = css`
    border-color: ${theme.colors.blues.focusRow} !important;
    background: ${theme.colors.blues.focusRow} !important;
    color: ${theme.colors.blues.antBlue} !important;
    text-shadow: none !important;
`;

interface CollapseChartButtonProps {
    isCollapsed: boolean;
    collapseTrigger: (value: boolean) => void;
}

const CollapseChartButton = ({
    isCollapsed,
    collapseTrigger,
}: CollapseChartButtonProps) => {
    return (
        <Button
            type="primary"
            className={isCollapsed ? chartButtonStyle : chartButtonStyleActive}
            onClick={() => collapseTrigger(!isCollapsed)}
        >
            <PieChartOutlined style={{ fontSize: '20px' }} />
        </Button>
    );
};

export default CollapseChartButton;
