// @ts-nocheck
// This file is a result of nx update

export const getPureId = (s) =>
    new URLSearchParams(s.routing.locationBeforeTransitions.search).get(
        'pureid',
    );

export const getProjectId = (s) =>
    parseInt(s.routing.locationBeforeTransitions.query.projectid);

export const getIsProperty = (s) =>
    s.routing.locationBeforeTransitions.pathname
        .split('/')
        .includes('property');

export const getIsPropertyGroup = (s) =>
    s.routing.locationBeforeTransitions.pathname.split('/').includes('group');

export const getPathname = (s) => s.routing.locationBeforeTransitions.pathname;

export const getQueryString = (s) => s.routing.locationBeforeTransitions.search;
