import { API_URL } from 'config/constants';

/**
 * This is primarily used for balance sheet though this date may
 * also be used for income statement data availability too and
 * the financial overview is an example of this
 *
 * @returns {Promise<Response>}
 * @param {array<string>} entityCodes
 */
const getDataAvailabilityDate = async (entityCodes) =>
    fetch(`${API_URL}/balance-sheet/accounting-available-date`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            entity_codes: entityCodes,
        }),
    });

export default getDataAvailabilityDate;
