import React from 'react';
import { Card, Radio } from 'antd';
import {
    MAX_HIERARHICAL_APPROVAL_LEVEL,
    REPORT_APPROVAL_TYPES,
} from './constants';
import { css } from 'emotion';
import theme from 'config/theme';
import { LocalReportSettings, Settings } from 'waypoint-types';
import { WidgetTypes } from 'components/reports/constants';

const radioStyle = css`
    display: block;
    font-weight: normal;
`;

const labelStyle = css`
    display: block;
    font-weight: normal;
    font-size: 13px;
    font-style: italic;
    padding-left: 23px;
`;

const hierarchicalApprovalBoxStyle = css`
    border: 1px solid ${theme.colors.grays.light};
    padding: 10px 20px 20px 20px;
    margin-left: 23px;
`;

const titleContainer = css`
    display: flex;
    justify-content: space-between;
`;

const cardHeight = `${window.innerHeight - 435}px`;

interface ReportApprovalSettingsProps {
    setSettings: (
        type: string,
        properties: Settings,
        replaceObject: boolean
    ) => void;
    settings: LocalReportSettings;
}

export const ReportApprovalSettings = ({
    setSettings,
    settings,
}: ReportApprovalSettingsProps) => {
    const approvalSettings = settings[WidgetTypes.Approvals]?.settings;
    const approvalIsNotHierarchicalType =
        approvalSettings?.type !== REPORT_APPROVAL_TYPES.HierarchicalApproval;
    const approvalLevels: number[] = [
        ...Array(MAX_HIERARHICAL_APPROVAL_LEVEL).keys(),
    ];

    const renderHierarchicalApprovalRadioGroup = () => {
        if (!approvalSettings?.type || approvalIsNotHierarchicalType) {
            return <></>;
        }

        return (
            <div className={hierarchicalApprovalBoxStyle}>
                <span style={{ display: 'block' }}>
                    Levels of Approval Required for Final Approval
                </span>
                <Radio.Group
                    onChange={(e) => {
                        setSettings(
                            WidgetTypes.Approvals,
                            {
                                type: REPORT_APPROVAL_TYPES.HierarchicalApproval,
                                required_levels: e.target.value,
                            },
                            false
                        );
                    }}
                    value={approvalSettings?.required_levels ?? 2}
                >
                    {approvalLevels.map((approvalLevel: number) => {
                        if (approvalLevel === 0) {
                            return <></>;
                        }

                        const approvalLevelValue = approvalLevel + 1;

                        return (
                            <Radio
                                key={`radio-approval-level-${approvalLevel}`}
                                value={approvalLevelValue}
                                className={radioStyle}
                                checked={
                                    approvalSettings?.required_levels ===
                                    approvalLevelValue
                                }
                            >
                                <span
                                    style={{ position: 'absolute', top: '2px' }}
                                >
                                    {approvalLevelValue}
                                </span>
                            </Radio>
                        );
                    })}
                </Radio.Group>
            </div>
        );
    };

    return (
        <Card style={{ height: cardHeight }}>
            <div className={titleContainer}>
                <div>
                    <h2 style={{ marginTop: 0, marginBottom: '30px' }}>
                        Approval Settings
                    </h2>
                </div>
            </div>
            <div style={{ paddingLeft: '30px' }}>
                <Radio.Group
                    onChange={(e) =>
                        setSettings(
                            WidgetTypes.Approvals,
                            e.target.value === REPORT_APPROVAL_TYPES.OneApproval
                                ? {
                                      type: e.target.value,
                                  }
                                : {
                                      type: e.target.value,
                                      required_levels:
                                          approvalSettings?.required_levels ??
                                          2,
                                  },
                            true
                        )
                    }
                    value={
                        approvalSettings?.type ??
                        REPORT_APPROVAL_TYPES.OneApproval
                    }
                >
                    <Radio
                        value={REPORT_APPROVAL_TYPES.OneApproval}
                        checked={
                            approvalSettings?.type ===
                            REPORT_APPROVAL_TYPES.OneApproval
                        }
                        className={radioStyle}
                    >
                        <span style={{ position: 'absolute', top: '-3px' }}>
                            One Approval
                        </span>
                        <label className={labelStyle}>
                            Only one reviewer needs to approve the report for it
                            to be approved
                        </label>
                    </Radio>

                    <Radio
                        value={REPORT_APPROVAL_TYPES.AllReviewers}
                        checked={
                            approvalSettings?.type ===
                            REPORT_APPROVAL_TYPES.AllReviewers
                        }
                        className={radioStyle}
                    >
                        <span style={{ position: 'absolute', top: '-3px' }}>
                            All Reviewers
                        </span>
                        <label className={labelStyle}>
                            All reviewers needs to approve the report for it to
                            be approved
                        </label>
                    </Radio>

                    <Radio
                        value={REPORT_APPROVAL_TYPES.HierarchicalApproval}
                        checked={
                            approvalSettings?.type ===
                            REPORT_APPROVAL_TYPES.HierarchicalApproval
                        }
                        style={{
                            fontWeight: 'normal',
                            fontSize: 13,
                            display: 'block',
                        }}
                    >
                        <span style={{ position: 'absolute', top: '-3px' }}>
                            Hierarchical Approval
                        </span>
                        <label className={labelStyle}>
                            All reviewers (in a prescribed order) are required
                            to approve a report
                        </label>
                        {renderHierarchicalApprovalRadioGroup()}
                    </Radio>
                </Radio.Group>
            </div>
        </Card>
    );
};
