export const displayTypeOptions = [
    { label: 'Gross $', abbreviation: '', value: 'gross', key: 'gross' },
    {
        label: '$ / occupied sq ft',
        abbreviation: ' /occ. sq ft',
        value: 'value_per_occupied_sqft',
        key: 'value_per_occupied_sqft',
    },
    {
        label: '$ / rentable sq ft',
        abbreviation: ' /rent. sq ft',
        value: 'value_per_rentable_sqft',
        key: 'value_per_rentable_sqft', // key is for react reconciliation
    },
    {
        label: '$ / unit',
        abbreviation: ' / unit',
        value: 'value_per_unit',
        key: 'value_per_unit',
    },
    {
        label: '$ / occupied unit',
        abbreviation: ' / occupied unit',
        value: 'value_per_occupied_unit',
        key: 'value_per_occupied_unit',
    },
];

export const defaultDisplayValue = displayTypeOptions[0].value;
