import React from 'react';
import AntDesignList from 'antd/lib/list';
import theme from 'config/theme';
import { RankingTooltipListItems } from 'waypoint-types';
const { Item } = AntDesignList;

const RankingChartTooltip = ({
    header,
    items,
}: {
    header: string;
    items: RankingTooltipListItems[];
}): JSX.Element => {
    return (
        <AntDesignList
            size="small"
            style={{ paddingLeft: 10, paddingRight: 10 }}
            header={
                <span style={{ fontWeight: theme.fontWeights.bold }}>
                    {header}
                </span>
            }
            dataSource={items}
            data-testid="ranking-tooltip"
            renderItem={(item: RankingTooltipListItems) => (
                <Item>
                    <span style={{ fontWeight: theme.fontWeights.regular }}>
                        {item.label}:
                    </span>
                    &nbsp;
                    <span style={{ fontWeight: theme.fontWeights.light }}>
                        {item.value}
                    </span>
                </Item>
            )}
        />
    );
};

export default RankingChartTooltip;
