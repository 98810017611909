import { MenuOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { useCommentsPane } from 'contexts/comments/CommentsContext';

interface ActionsCellParams {
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
    accountMappingId: string;
    setSelectedAccountMappingId: (value: string) => void;
    setIsAccountHistoryModalVisible?: (value: boolean) => void;
    isAccountHistoryModalVisible?: boolean;
    hideAccountHistoryButton?: boolean;
    hideNotesOption?: boolean;
    hideCommentsOption?: boolean;
}

const ActionsCell = ({
    isVisible,
    setIsVisible,
    accountMappingId,
    setSelectedAccountMappingId,
    setIsAccountHistoryModalVisible,
    isAccountHistoryModalVisible,
    hideAccountHistoryButton,
    hideNotesOption,
    hideCommentsOption,
}: ActionsCellParams): JSX.Element => {
    const { update: updateCommentsPane, openComments } = useCommentsPane();

    const toggleIsVisible = () => {
        setSelectedAccountMappingId(accountMappingId);
        setIsVisible(!isVisible);
    };

    const openCommentsDrawer = () => {
        updateCommentsPane({
            selectedCommentAccountMappingId: accountMappingId,
        });

        openComments();
    };

    const openAccountHistoryModal = () => {
        setSelectedAccountMappingId(accountMappingId);
        setIsAccountHistoryModalVisible &&
            setIsAccountHistoryModalVisible(!isAccountHistoryModalVisible);
    };

    const moreMenu = (
        <Menu>
            {!hideNotesOption ? (
                <Menu.Item key={'1'} onClick={toggleIsVisible}>
                    Add Note
                </Menu.Item>
            ) : null}
            {!hideCommentsOption ? (
                <Menu.Item key={'2'} onClick={openCommentsDrawer}>
                    Comment
                </Menu.Item>
            ) : null}
            {!hideAccountHistoryButton ? (
                <Menu.Item key={'3'} onClick={openAccountHistoryModal}>
                    Account History
                </Menu.Item>
            ) : null}
        </Menu>
    );

    return (
        <div>
            <Dropdown placement={'bottomRight'} overlay={moreMenu}>
                <span
                    style={{
                        fontSize: '13px',
                        fontWeight: 'normal',
                        textTransform: 'none',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        marginLeft: '5px',
                    }}
                >
                    <MenuOutlined />
                </span>
            </Dropdown>
        </div>
    );
};

export default ActionsCell;
