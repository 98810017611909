// @ts-nocheck
// This file is a result of nx update

import { merge, cloneDeep, extend } from 'lodash';
import { user as USER_TYPES, users as TYPES } from 'state/actionTypes';

export const defaultState = {};

function load(state, action) {
    const updatedUsers = cloneDeep(merge(state, action.payload));
    return updatedUsers;
}

function loadUser(state, action) {
    const users = cloneDeep(state);
    const { id } = action.payload;
    if (users[id]) {
        extend(users[id], action.payload);
    } else {
        users[id] = action.payload;
    }
    return users;
}

function deactivateUser(state, action) {
    const newUsers = cloneDeep(state);
    newUsers[action.payload].activeStatus = 'inactive';
    return newUsers;
}

function activateUser(state, action) {
    const newUsers = cloneDeep(state);
    newUsers[action.payload].activeStatus = 'active';
    return newUsers;
}

export default function reducer(users = defaultState, action) {
    const { type } = action;

    switch (type) {
        case TYPES.LOAD:
            return load(users, action);

        case TYPES.LOAD_USER:
            return loadUser(users, action);

        case USER_TYPES.GET_USER_OBJ:
            return loadUser(users, {
                payload: Object.values(action.payload)[0],
            });

        case TYPES.DEACTIVATE:
            return deactivateUser(users, action);

        case TYPES.ACTIVATE:
            return activateUser(users, action);

        default:
            break;
    }

    return users;
}
