import PropTypes from 'prop-types';
import React from 'react';
import { cx } from 'emotion';
import { legendlabelStyle, legendItemContainer } from './styles';
import Bubble from 'components/style/Bubble';

const CircleLegendItem = (props) => {
    return (
        <span className={cx([legendItemContainer, props.className])}>
            <span>
                <Bubble diameter={props.diameter} color={props.color} />
            </span>
            <span className={cx([legendlabelStyle, props.labelOverride])}>
                {props.label}
            </span>
        </span>
    );
};

CircleLegendItem.propTypes = {
    label: PropTypes.string,
    color: PropTypes.string,
    diameter: PropTypes.number,
};

export default CircleLegendItem;
