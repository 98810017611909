import React from 'react';
import fusioncharts from 'fusioncharts';
import charts from 'fusioncharts/fusioncharts.charts';
import ReactFC from 'react-fusioncharts';
import { assign } from 'lodash';
import { css } from 'emotion';
import { theme } from 'config/colors';
import chartBuilder from 'components/charts/chartBuilder';
import { Legend, LineLegendItem } from 'components/style/legends';
import {
    getGroupLabel,
    transformDataToCharts,
} from 'components/analytics/portfolioSegmentation/utils';
import {
    CHART_HEIGHT_OFFSET,
    CHART_WIDTH_OFFSET,
} from 'components/analytics/portfolioSegmentation/constants';

// Resolves charts dependancy
charts(fusioncharts);

const chartStyle = {
    enableRotation: '1',
    showLabels: '1',
    showPercentageValues: '1',
    baseFontColor: theme.colors.mediumGray,
    xAxisNameFontColor: theme.colors.mediumGray,
    yAxisNameFontColor: theme.colors.mediumGray,
    startingAngle: 90,
    exportFormats: 'PNG|PDF|JPG|SVG',
};

const chartContainertStyle = css`
    max-width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

const PieChart = ({ data, group_by }) => {
    const height = window.innerHeight - CHART_HEIGHT_OFFSET;

    let finalStyle = {
        ...chartStyle,
        defaultcenterlabel: getGroupLabel(group_by),
    };

    const width = window.innerWidth - CHART_WIDTH_OFFSET;

    const chartData = transformDataToCharts(data);
    const chart = chartBuilder();

    chart.type('doughnut2d');
    chart.height(height || '400');
    chart.width(width || '600');
    chart.style(finalStyle);
    chart.data(chartData);

    const { config: chartConfig } = chart;
    return (
        <div className={chartContainertStyle}>
            <Legend>
                {data.map((legend) => (
                    <LineLegendItem
                        key={legend.key}
                        label={legend.label}
                        color={legend.color}
                    />
                ))}
            </Legend>
            <ReactFC {...chartConfig} />
        </div>
    );
};

export default PieChart;
