import { API_URL } from 'config/constants';
import { LeasingPlanActivity } from 'waypoint-types/leasing-plans';

const updateLeasingPlanActivity = async (
    entityCode: string,
    leasingPlanActivityId: string,
    body: LeasingPlanActivity
): Promise<LeasingPlanActivity> => {
    const response = await fetch(
        `${API_URL}/leasing-plans/activity/${entityCode}/${leasingPlanActivityId}`,
        {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(body),
        }
    );

    if (!response.ok) {
        throw new Error('Failed to update leasing plan activity');
    }

    const { data } = await response.json();

    return data as LeasingPlanActivity;
};

export default updateLeasingPlanActivity;
