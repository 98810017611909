import React from 'react';
import { useGetSavedConfigurationById } from 'waypoint-hooks';
import { TypeOfContractsView } from 'components/contracts/interfaces';
import { ContractsContainer } from 'components/contracts/ContractsContainer';
import { PDFExportable } from 'waypoint-utils/pdf/PDFExportable';

interface ContractsContainerWidgetProps {
    typeOfView: TypeOfContractsView;
    widgetSettings: { [key: string]: any };
    narrativePosition?: string;
    widgetId: string;
    entityCode: string;
    isPDFExport?: boolean;
    isReportWidget?: boolean;
    ref?: React.RefObject<PDFExportable>;
}

export const ContractsContainerWidget = React.forwardRef<
    PDFExportable,
    ContractsContainerWidgetProps
>(
    (
        {
            typeOfView,
            widgetSettings,
            widgetId,
            narrativePosition,
            entityCode,
            isPDFExport,
            isReportWidget = false,
        }: ContractsContainerWidgetProps,

        ref
    ) => {
        const { data: savedConfigData, isLoading: isLoadingSavedConfig } =
            useGetSavedConfigurationById(widgetSettings?.savedConfigId ?? '');

        if (isLoadingSavedConfig) {
            return <></>;
        }

        return (
            <ContractsContainer
                ref={ref}
                key={`${widgetId}_${savedConfigData?.id ?? ''}`}
                widgetId={widgetId}
                typeOfView={typeOfView}
                entityCodes={[entityCode]}
                widgetStatus={widgetSettings?.selectedStatus}
                savedConfigData={savedConfigData ?? null}
                isReportWidget={isReportWidget}
                narrativePosition={narrativePosition}
                isPDFExport={isPDFExport}
            />
        );
    }
);
