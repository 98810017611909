import { createContext, useContext } from 'react';
import {
    CommentMention,
    CommentThreadData,
    MentionableDataSource,
} from 'waypoint-types';
import { AccountGraphObjectType } from 'waypoint-types/account-graph/types';
import { KeyedMutator, MutatorCallback, MutatorOptions } from 'swr';

export interface UpdateCommentsOptions {
    heading?: string;
    subheading?: string;
    financialYearEnding?: string | null;
    userMentionOptions?: MentionableDataSource[];
    accountMentionOptions?: MentionableDataSource[];
    scrollToCommentId?: string | null;
    accountFilterSelection?: string | null;
    accountGraph?: AccountGraphObjectType | null;
    selectedCommentAccountMappingId?: string | null;
}

export interface CommentsContextType {
    update: (options: UpdateCommentsOptions) => void;
    commentThreadId: string;
    commentThread?: CommentThreadData;
    mutateCommentThread: KeyedMutator<CommentThreadData>;
    isVisible: boolean;
    isLoading: boolean;
    isError: boolean;
    heading: string;
    subheading: string;
    financialYearEnding?: string | null;
    scrollToCommentId: string | null;
    userMentionOptions?: MentionableDataSource[];
    accountMentionOptions?: MentionableDataSource[];
    accountGraph: AccountGraphObjectType | null;
    accountFilterSelection: string | null;
    selectedCommentAccountMappingId: string | null;
    openComments: () => void;
    closeComments: () => void;
    toggleComments: () => void;
    initializeComments: (
        commentThreadId: string | undefined,
        entityCode?: string
    ) => void;
    commentThreadAccountMentions?: CommentMention[];
}

export const useCommentsPane = () => {
    return useContext(CommentsContext);
};

export const CommentsContext = createContext<CommentsContextType>({
    closeComments(): void {},
    initializeComments(): void {},
    openComments(): void {},
    toggleComments(): void {},
    mutateCommentThread(
        data:
            | Promise<CommentThreadData>
            | MutatorCallback<CommentThreadData>
            | CommentThreadData
            | undefined,
        opts: boolean | MutatorOptions<CommentThreadData> | undefined
    ): Promise<CommentThreadData | undefined> {
        return Promise.resolve(undefined);
    },
    isLoading: false,
    isError: false,
    accountFilterSelection: null,
    update(options: UpdateCommentsOptions): void {},
    accountGraph: null,
    heading: '',
    financialYearEnding: '',
    commentThreadId: '',
    scrollToCommentId: null,
    selectedCommentAccountMappingId: '',
    subheading: '',
    isVisible: false,
});
