import React from 'react';
import { truncate } from 'lodash';
import { Tooltip } from 'antd';

import theme from 'config/theme';

const TextWithUnit = ({
    qty,
    unit,
    prefix,
    style,
}: {
    qty: string;
    unit: string | null;
    prefix?: string | null;
    style?: any;
}) => {
    const text: string = `${qty}${prefix || ''} ${unit || ''}`;
    const shouldDisplayTooltip: boolean = text.length > 20;

    const content: JSX.Element = (
        <p style={{ fontSize: 25, marginBottom: 0, ...style }}>
            {truncate(qty, { length: 18 })}&nbsp;
            <small style={{ fontSize: 18 }}>
                {prefix ? '/' : prefix}
                {unit}
            </small>
        </p>
    );

    if (shouldDisplayTooltip) {
        return (
            <Tooltip
                title={qty}
                color={theme.colors.white}
                overlayInnerStyle={{
                    color: theme.colors.grays.text,
                    fontSize: 20,
                    font: 'Lato',
                }}
            >
                {content}
            </Tooltip>
        );
    }
    return content;
};
export default TextWithUnit;
