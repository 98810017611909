import { DisplayType, RankingData } from 'waypoint-types';

export const getDisplayTypeDataField = (
    displayType: DisplayType
): keyof RankingData | undefined => {
    switch (displayType) {
        case DisplayType.PerUnit:
            return 'total_units';
        case DisplayType.PerOccupiedUnit:
            return 'occupied_units';
        case DisplayType.PerRentableSqft:
            return 'rentable_sq_ft';
        case DisplayType.PerOccupiedSqft:
            return 'occupied_sq_ft';
        default:
            return;
    }
};

export const getDisplayTypeLabel = (
    displayType: DisplayType
): string | undefined => {
    switch (displayType) {
        case DisplayType.PerUnit:
            return '$ / unit';
        case DisplayType.PerOccupiedUnit:
            return '$ / occupied unit';
        case DisplayType.PerRentableSqft:
            return '$ / rentable sq ft';
        case DisplayType.PerOccupiedSqft:
            return '$ / occupied sq ft';
        case DisplayType.Gross:
            return 'Gross $';
        default:
            return;
    }
};
