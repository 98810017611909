import React from 'react';
import { DatePicker } from 'waypoint-react';
import { datePickerStyle } from 'components/financials/common/styles';
import moment, { Moment } from 'moment';
import { toEndOfMonth, toISO } from 'components/dates/utils';

const bannerSelectLabelsStyle = {
    fontSize: '12px',
    fontWeight: 'bold',
    marginRight: '5px',
    marginBottom: '3px',
};

export const getBalanceSheetPrimaryMonthPicker = (
    disabledDate: (currentDate: Moment | null) => boolean,
    primaryPeriod: string | undefined,
    setPrimaryPeriod: (value: string | undefined) => void,
) => {
    return (
        <>
            <div style={bannerSelectLabelsStyle}>Choose a Month</div>
            <DatePicker
                picker={'month'}
                allowClear={false}
                placeholder="Select month"
                format={'MMMM YYYY'}
                className={datePickerStyle}
                disabledDate={disabledDate}
                disabled={!primaryPeriod}
                onChange={(momentInstance) =>
                    setPrimaryPeriod(toISO(toEndOfMonth(momentInstance)))
                }
                value={moment(primaryPeriod)}
            />
        </>
    );
};

export const getBalanceSheetSecondaryMonthPicker = (
    disabledDate: (currentDate: Moment | null) => boolean,
    secondaryPeriod: string | undefined,
    setSecondaryPeriod: (value: string | undefined) => void,
) => {
    return (
        <div style={{ display: 'inline-block', marginRight: '20px' }}>
            <div style={bannerSelectLabelsStyle}>Compare To</div>
            <DatePicker
                picker={'month'}
                allowClear={false}
                placeholder="Select month"
                format={'MMMM YYYY'}
                className={datePickerStyle}
                disabledDate={disabledDate}
                onChange={(momentInstance) =>
                    setSecondaryPeriod(toISO(toEndOfMonth(momentInstance)))
                }
                value={moment(secondaryPeriod)}
            />
        </div>
    );
};
