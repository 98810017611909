import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { mutateAsync } from 'redux-query';
import {
    Field,
    reduxForm,
    getFormValues,
    reset,
    isInvalid,
    isPristine,
    change,
} from 'redux-form';
import { message } from 'antd';
import { assign } from 'lodash';
import { createUser } from 'state/requests';
import { buttonStyle } from 'components/style/utils';
import LoadingSpinner from 'components/style/LoadingSpinner';
import Modal from 'components/style/Modal';
import {
    FormInput,
    RadioGroup,
} from 'components/settings/users/FormComponents';
import { roles } from 'config/constants';
import { Select } from 'antd';
import { getPermissionGroups } from 'waypoint-requests';

class CreateUserModal extends React.Component {
    static propTypes = {
        toggleCreateUserModal: PropTypes.func,
        allAccessLists: PropTypes.array,
        formValues: PropTypes.object,
        dispatch: PropTypes.func,
        clientId: PropTypes.number,
        formIsInvalid: PropTypes.bool,
        formIsPristine: PropTypes.bool,
    };

    state = {
        isPending: false,
        permissionGroupId: null,
        permissionGroups: [],
    };

    // NOTE: https://waypointbuilding.atlassian.net/browse/HER-9003 Ticket to refactor this component
    componentDidMount() {
        this.fetchPermissionsGroups();
    }

    componentWillReceiveProps(nextProps) {
        // NOTE: May have to leverage this lifecycle method to dispatch a
        // a change action to to invite user field. This is because
        // it is possible the form enters a valid state - ie create button
        // may be pressed - and the user checks the invite user box, but then makes an edit to
        // other fields that puts the form back into an invalid state. If the invite user
        // checkbox has been checked, it becomes disabled, but that
        // true value for checkbox is still stored in redux. That needs to be changed
        // back to false if the form ever enters an invalid state. Additionally, a field that is
        // an unchecked checkbox, is not set to false in store, but does not exist at all.
        // #reduxformquirks
        if (this.props.formValues) {
            this.props.dispatch(change('createUserForm', 'invite', false));
        }
    }

    fetchPermissionsGroups = async () => {
        const { clientId } = this.props;
        const permissionGroups = await getPermissionGroups(clientId);
        this.setState({
            permissionGroups,
        });
    };

    createUser = (andAnother) => {
        const { clientId, formValues, dispatch, toggleCreateUserModal } =
            this.props;

        const { email, firstName, lastName, role, invite } = formValues;

        const payloadRole =
            role === 'User' ? roles.CLIENT_USER : roles.CLIENT_ADMIN;

        const payloadPermissionGroup = () => {
            if (
                payloadRole === roles.CLIENT_ADMIN ||
                this.state.permissionGroupId === null
            ) {
                return [];
            }

            return [this.state.permissionGroupId];
        };

        const payload = {
            body: {
                email: email.trim(),
                firstname: firstName,
                lastname: lastName,
                role: payloadRole,
                client_id: clientId,
                active_status: 'active',
                permission_group_ids: payloadPermissionGroup(),
            },
        };

        if (invite) {
            payload.body.send_invitation = invite;
        }

        this.setState({ isPending: true });
        dispatch(mutateAsync(createUser(payload))).then(({ status }) => {
            if (status === 201) {
                message.success(
                    'Success',
                    `${firstName} ${lastName} was created successfully`,
                );
                // Custom search logic can be placed here if needed
                if (andAnother) {
                    this.setState({ isPending: false });
                    dispatch(reset('createUserForm'));
                } else {
                    toggleCreateUserModal();
                }
            } else {
                this.setState({ isPending: false });
                message.error(
                    'Error',
                    `${firstName} ${lastName} was not created successfully`,
                );
            }
        });
    };

    render() {
        const { toggleCreateUserModal, formIsInvalid, formIsPristine } =
            this.props;
        const { isPending } = this.state;
        const modalSection = {
            height: '60px',
            width: '100%',
            marginBottom: '15px',
        };

        const buttonSection = {
            height: '50px',
            width: '100%',
            display: 'flex',
            marginBottom: '3%',
            justifyContent: 'space-between',
        };

        const inputStyle = {
            boxSizing: 'border-box',
            height: '33px',
            width: '100%',
            maxWidth: '100%',
            border: '1px solid rgba(155,155,155,0.4)',
        };

        return (
            <Modal title="Create User" clickHandler={toggleCreateUserModal}>
                {isPending ? (
                    <LoadingSpinner style={{ textAlign: 'center' }} />
                ) : (
                    <div>
                        <div style={modalSection}>
                            {/* NOTE: To display email error message first check if there is a value */}
                            {/* NOTE: Only display error if the input loses focus, not as the user types */}
                            <Field
                                name="email"
                                title="EMAIL"
                                style={assign({}, inputStyle, {
                                    width: '100%',
                                })}
                                component={FormInput}
                            />
                        </div>
                        <div style={modalSection}>
                            <Field
                                name="firstName"
                                title="FIRST NAME"
                                style={inputStyle}
                                component={FormInput}
                            />
                        </div>
                        <div style={modalSection}>
                            <Field
                                name="lastName"
                                title="LAST NAME"
                                style={inputStyle}
                                component={FormInput}
                            />
                        </div>
                        <div
                            style={assign({}, modalSection, {
                                height: '70px',
                            })}
                        >
                            <Field
                                name="role"
                                title="USER ROLE"
                                style={{ fontSize: '14px' }}
                                component={RadioGroup}
                            />
                        </div>
                        <div style={{ marginBottom: 12 }}>
                            <p style={{ textTransform: 'uppercase' }}>
                                Permission Group
                            </p>
                            <Select
                                style={{ width: 200 }}
                                defaultValue={null}
                                value={this.state.permissionGroupId}
                                disabled={
                                    this.props.formValues?.role === 'Admin'
                                }
                                allowClear
                                onClear={() => {
                                    this.setState({
                                        permissionGroupId: null,
                                    });
                                }}
                                onChange={(value) => {
                                    this.setState({
                                        permissionGroupId: value,
                                    });
                                }}
                                options={[
                                    { value: null, label: 'All Access' },
                                    ...this.state.permissionGroups.map(
                                        (group) => {
                                            return {
                                                value: group.id,
                                                label: group.name,
                                            };
                                        },
                                    ),
                                ]}
                            />
                        </div>
                        <div
                            style={assign({}, buttonSection, {
                                justifyContent: 'space-between',
                            })}
                        >
                            <button
                                style={assign(
                                    {},
                                    buttonStyle(100, 32, '#D0021B'),
                                    { order: 1 },
                                )}
                                onClick={toggleCreateUserModal}
                            >
                                Cancel
                            </button>
                            <button
                                disabled={formIsInvalid || formIsPristine}
                                style={assign(
                                    {},
                                    buttonStyle(
                                        100,
                                        32,
                                        formIsInvalid || formIsPristine
                                            ? '#9b9b9b'
                                            : '#00ADE2',
                                    ),
                                    {
                                        order: 2,
                                        color:
                                            formIsInvalid || formIsPristine
                                                ? '#FFFFFF'
                                                : '#F0F3F6',
                                        backgroundColor:
                                            formIsInvalid || formIsPristine
                                                ? '#9b9b9b'
                                                : '#00ADE2',
                                        opacity:
                                            formIsInvalid || formIsPristine
                                                ? 0.7
                                                : null,
                                    },
                                )}
                                onClick={() => this.createUser(false)}
                            >
                                Create
                            </button>
                            <button
                                disabled={formIsInvalid || formIsPristine}
                                style={assign(
                                    {},
                                    buttonStyle(
                                        100,
                                        32,
                                        formIsInvalid || formIsPristine
                                            ? '#9b9b9b'
                                            : '#00ADE2',
                                    ),
                                    {
                                        width: 'auto',
                                        order: 3,
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        opacity:
                                            formIsInvalid || formIsPristine
                                                ? 0.7
                                                : null,
                                    },
                                )}
                                onClick={() => this.createUser(true)}
                            >
                                Create and Add Another
                            </button>
                        </div>
                    </div>
                )}
            </Modal>
        );
    }
}

const mapState = (state) => {
    const clientId = state.user.clientObj.id;
    const formValues = getFormValues('createUserForm')(state);
    const formIsInvalid = isInvalid('createUserForm')(state);
    const formIsPristine = isPristine('createUserForm')(state);
    const initialValues = {
        email: '',
        firstName: '',
        lastName: '',
        accessLists: [],
        role: 'User',
        invite: false,
    };

    return {
        clientId,
        initialValues,
        formValues,
        formIsInvalid,
        formIsPristine,
    };
};

const mapDispatch = (dispatch) => {
    return {
        dispatch,
    };
};

function validate(values) {
    const errors = {};
    if (!values.email || !values.email.split('').includes('@')) {
        errors.email = 'User must have a valid email';
    }
    if (!values.firstName) {
        errors.firstName = 'User must have a first name';
    } else if (values.firstName.length < 2) {
        errors.firstName = 'First names must be two letters or longer';
    }
    if (!values.lastName) {
        errors.lastName = 'User must have a last name';
    } else if (values.lastName.length < 2) {
        errors.lastName = 'Last names must be two letters or longer';
    }

    if (!values.role) {
        errors.role = 'User must have a role';
    }
    return errors;
}

export default compose(
    connect(mapState, mapDispatch),
    reduxForm({ form: 'createUserForm', validate }),
)(CreateUserModal);
