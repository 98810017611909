import React, { useState } from 'react';
import {
    Badge,
    Popover,
    List,
    Card,
    Button,
    Pagination,
    notification as antNotification,
    Empty,
    Tooltip,
} from 'antd';
import { BellOutlined } from '@ant-design/icons';

import {
    IRemoteNotification,
    useMagicBellEvent,
    useNotifications,
} from '@magicbell/react-headless';
import { NotificationItem } from 'components/app/layout/notifications/NotificationItem';
import { INotification } from '@magicbell/magicbell-react';
import { cleanCommentFromMarkup } from 'waypoint-utils';
import { ACTIVE_GLOBAL_FILTER_KEY } from 'components/app/global-filter-drawer/GlobalFilterConstants';
import { useClientPrefixedLocalStorage } from 'waypoint-hooks';
import { SavedFilter } from 'contexts';
import { VARIANCE_REPORT_STATUS_URL } from 'components/financials/variance-report-status/constants';
import NotificationSettings from './NotificationSettings';
import { NotificationPreferencesResponse } from 'waypoint-types';
import { KeyedMutator } from 'swr';
import { MenuSections } from '../AppLayoutUtils';

interface NotificationBellProps {
    preferences: NotificationPreferencesResponse | undefined;
    preferencesMutate: KeyedMutator<NotificationPreferencesResponse>;
    isLoadingPreferences: boolean;
    collapsed: boolean;
}

export const NotificationBell = ({
    preferences,
    preferencesMutate,
    isLoadingPreferences,
    collapsed,
}: NotificationBellProps): JSX.Element => {
    const [, setAppliedGlobalFilter] =
        useClientPrefixedLocalStorage<SavedFilter | null>(
            ACTIVE_GLOBAL_FILTER_KEY,
            null
        );

    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [
        isNotificationPreferencesVisible,
        setIsNotificationPreferencesVisible,
    ] = useState<boolean>(false);

    const forceRedirect = (
        notification: IRemoteNotification | INotification
    ) => {
        if (!notification.actionUrl) {
            return;
        }

        const currentUrlWithNoQueryParams = window.location.href.split('?')[0];
        const newUrlWithNoQueryParams = notification.actionUrl.split('?')[0];

        if (notification.actionUrl.includes(VARIANCE_REPORT_STATUS_URL)) {
            sessionStorage.removeItem('lastPeriodEndSelected');
        }

        setAppliedGlobalFilter(null);

        window.location.href = notification.actionUrl;

        if (currentUrlWithNoQueryParams === newUrlWithNoQueryParams) {
            setTimeout(() => {
                window.location.reload();
            }, 0);
        }
    };

    useMagicBellEvent('notifications.new', (rawNotification: unknown) => {
        const notification = rawNotification as IRemoteNotification;

        antNotification.open({
            message: notification.title,
            description: cleanCommentFromMarkup(notification.content ?? ''),
            onClick: () => {
                if (notification.actionUrl) {
                    forceRedirect(notification);
                }
            },
            style: { cursor: 'pointer' },
        });
    });

    const handleDelete = async (notificationToDelete: INotification) => {
        await notificationToDelete.delete();

        const total = notifications?.total ?? 0;
        const perPage = notifications?.perPage ?? 0;
        const currentPage = notifications?.currentPage ?? 1;

        const isLastNotificationOnCurrentPage =
            total && Math.ceil((total - 1) / perPage) < currentPage;

        if (isLastNotificationOnCurrentPage) {
            const newPage = Math.max(1, currentPage - 1);

            await notifications?.fetch(
                {
                    page: newPage,
                },
                { reset: true }
            );
        }
    };

    const notifications = useNotifications();

    const onVisibleChange = (isVisible: boolean) => {
        setIsVisible(isVisible);
        if (isNotificationPreferencesVisible) {
            setIsNotificationPreferencesVisible(false);
        }
    };

    const getPopoverContent = () => {
        return (
            <Card
                headStyle={{
                    display: isNotificationPreferencesVisible ? 'none' : 'flex',
                }}
                title={
                    isNotificationPreferencesVisible ? null : 'Notifications'
                }
                extra={
                    <div>
                        <Tooltip
                            placement="bottomRight"
                            zIndex={99999}
                            title="Mark all as read"
                        >
                            <i
                                style={{
                                    marginRight: '6px',
                                    padding: 8,
                                    cursor: 'pointer',
                                }}
                                className="fa-solid fa-check-square fa-lg"
                                onClick={() => notifications?.markAllAsRead()}
                            />
                        </Tooltip>
                        <Tooltip
                            placement="bottomRight"
                            zIndex={99999}
                            title="Notification Preferences"
                        >
                            <i
                                style={{ padding: 8, cursor: 'pointer' }}
                                className="fa-solid fa-cog fa-lg"
                                onClick={() =>
                                    setIsNotificationPreferencesVisible(true)
                                }
                            />
                        </Tooltip>
                    </div>
                }
                style={{ margin: '-16px -22px', borderRadius: '6px' }}
                bodyStyle={{ padding: 0 }}
            >
                {isNotificationPreferencesVisible ? (
                    <NotificationSettings
                        onClose={() =>
                            setIsNotificationPreferencesVisible(false)
                        }
                        preferences={preferences}
                        preferencesMutate={preferencesMutate}
                        isLoadingPreferences={isLoadingPreferences}
                        isNotificationPreferencesVisible={
                            isNotificationPreferencesVisible
                        }
                    />
                ) : (
                    <>
                        <List
                            locale={{
                                emptyText: (
                                    <Empty
                                        imageStyle={{
                                            height: 80,
                                        }}
                                        description="You don't have any notifications."
                                    />
                                ),
                            }}
                            style={{
                                minWidth: '475px',
                                maxHeight: '500px',
                                overflowY: 'auto',
                            }}
                            dataSource={notifications?.notifications}
                            renderItem={(notification) => (
                                <NotificationItem
                                    rawNotification={notification}
                                    onClick={(clickedNotification) => {
                                        setIsVisible(false);
                                        clickedNotification.markAsRead();

                                        if (clickedNotification.actionUrl) {
                                            forceRedirect(clickedNotification);
                                        }
                                    }}
                                    onDelete={async (notificationToDelete) => {
                                        handleDelete(notificationToDelete);
                                    }}
                                    onMarkRead={async (notificationToRead) => {
                                        await notificationToRead.markAsRead();
                                    }}
                                    onMarkUnread={async (
                                        notificationToRead
                                    ) => {
                                        await notificationToRead.markAsUnread();
                                    }}
                                />
                            )}
                        />
                        {!!notifications?.total && (
                            <Pagination
                                style={{ margin: '10px', textAlign: 'right' }}
                                pageSize={notifications?.perPage}
                                current={notifications?.currentPage}
                                total={notifications?.total}
                                onChange={(page: number) => {
                                    notifications?.fetch(
                                        { page },
                                        { reset: true }
                                    );
                                }}
                                showSizeChanger={false}
                            />
                        )}
                    </>
                )}
            </Card>
        );
    };

    return (
        <>
            <Popover
                placement={'topRight'}
                zIndex={19999}
                trigger={'click'}
                onOpenChange={onVisibleChange}
                open={isVisible}
                content={getPopoverContent()}
            >
                <Badge
                    count={notifications?.unreadCount}
                    size={'small'}
                    offset={collapsed ? [-22, 6] : [-22, -5]}
                >
                    <BellOutlined
                        style={{
                            color: 'white',
                            height: '18px',
                            maxHeight: '18px',
                            fontSize: '16px',
                            marginRight: '15px',
                        }}
                    />
                </Badge>
                {collapsed ? '' : MenuSections.Notifications}
            </Popover>
        </>
    );
};

export default NotificationBell;
