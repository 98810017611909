import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { EntityOwnershipPercentage } from 'waypoint-types';
import useSWR from 'swr';
import { getEntityOwnershipPercentage } from 'waypoint-requests';

export function useGetEntityOwnershipPercentage({
    entityCode,
}: {
    entityCode: string;
}): DataHookResponse<EntityOwnershipPercentage[]> {
    const { data, error, mutate } = useSWR(
        `/ownership-percentage/${entityCode}`,
        () => getEntityOwnershipPercentage(entityCode)
    );

    return {
        isLoading: !data,
        isError: !!error,
        data,
        mutate,
    };
}
