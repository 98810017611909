import { range } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Cell from './Cell';

const optionToPeriod = ({ label }) => label.split(' ')[0];

class Grid extends React.Component {
    static propTypes = {
        data: PropTypes.array,
        rows: PropTypes.number,
        columns: PropTypes.number,
        onClick: PropTypes.func,
        selectableWhenDisabled: PropTypes.bool,
    };

    renderCell = (row, column) => {
        const { data, columns, onClick, selectableWhenDisabled } = this.props;
        const index = row * columns + column;
        const datum = data[index];

        if (!datum) {
            return <Cell key={`${row},${column}`} />;
        }

        const { label, disabled, selected } = datum;
        const period = optionToPeriod({ label });
        return (
            <Cell
                key={`${row},${column}`}
                selected={selected}
                disabled={disabled}
                onClick={onClick ? () => onClick(data[index]) : null}
                selectableWhenDisabled={selectableWhenDisabled}
            >
                {period}
            </Cell>
        );
    };

    render() {
        const { rows, columns } = this.props;
        return (
            <div>
                {range(rows).map((row) => (
                    <div key={row} style={{ display: 'flex' }}>
                        {range(columns).map((column) => {
                            return this.renderCell(row, column);
                        })}
                    </div>
                ))}
            </div>
        );
    }
}

export default Grid;
