import { SelectedDataLevel } from 'waypoint-types';
import { useEntityOwnershipContext } from 'waypoint-hooks/useEntityOwnershipContext';

export function useSelectedDataLevel(): SelectedDataLevel {
    const context = useEntityOwnershipContext();

    if (!context) {
        throw new Error(
            'Cannot access data level without EntityOwnershipProvider'
        );
    }

    return context.data.selectedDataLevel;
}
