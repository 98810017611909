import { currencyRenderer } from 'utils/tables/renderers';
import { numericalSort, dateSort } from 'utils/tables/sorters';
import { getOvertimeDataLabelFor } from 'components/analytics/balance-sheet-overview/cards/overtime/utils';
import { displayTypeOptions } from 'components/analytics/financialOverview/menu/displayType/constants';

/**
 * This takes data from the overtime API and transforms it into a configuration for antd's Table API
 * @param {array} data - overtime data from API
 * @param {string} periodicity - monthly, yearly or quarterly
 * @param {string} displayType - @see {displayTypeOptions}
 * @returns {array} - configuration for antd Table component
 */
export const getOvertimeTableColumns = (
    data,
    periodicity,
    displayType = 'gross',
) => {
    const displayConfig = displayTypeOptions.find((x) => x.key === displayType);
    return [
        {
            title: 'Period',
            dataIndex: 'period_end',
            sorter: (a, b) => dateSort(a.period_end, b.period_end),
            render: (periodEnd) =>
                getOvertimeDataLabelFor(periodEnd, periodicity),
        },
        {
            title: displayConfig.label,
            dataIndex: 'actual_amount',
            align: 'right',
            sorter: (a, b) => numericalSort(a.actual_amount, b.actual_amount),
            render: currencyRenderer,
        },
    ];
};
