import React, { useState } from 'react';
import IncomeStatementContainer from 'components/financials/comparative-income-statement/card/IncomeStatementContainer';
import BalanceSheetContainer from 'components/financials/balanceSheet/components/card/BalanceSheetContainer';
import { useSelectedDataLevel } from 'waypoint-hooks';
import SubsectionMenu from './SubsectionMenu';
import { ItemType } from 'antd/es/menu/interface';
import {
    FINANCIALS_BALANCE_SHEET,
    FINANCIALS_INCOME_STATEMENT,
} from './constants';

interface FinancialSectionProps {
    entityCodes: string[];
    hasBalanceSheetEnabled: boolean;
    menuItems: ItemType[];
    onSetSubsection: (subsection: string) => void;
}

const FinancialSection = ({
    entityCodes,
    hasBalanceSheetEnabled,
    menuItems,
    onSetSubsection,
}: FinancialSectionProps) => {
    const selectedDataLevel = useSelectedDataLevel();
    const defaultMenuKey = menuItems[0]?.key as string;
    const [menuSelection, setMenuSelection] = useState<string>(defaultMenuKey);

    return (
        <>
            <SubsectionMenu
                menuItems={menuItems}
                setMenuSelection={setMenuSelection}
                onSetSubsection={onSetSubsection}
                defaultMenuKey={defaultMenuKey}
            />
            <div
                style={{
                    position: 'relative',
                    height: '100%',
                    left: 0,
                    right: 0,
                    bottom: 0,
                    marginTop: '50px',
                    overflowY: 'auto',
                }}
            >
                {menuSelection === FINANCIALS_INCOME_STATEMENT && (
                    <IncomeStatementContainer
                        entityCodes={entityCodes}
                        isPropertyProfile={true}
                        isVarianceReporting={false}
                        selectedDataLevel={selectedDataLevel}
                    />
                )}

                {hasBalanceSheetEnabled &&
                    menuSelection === FINANCIALS_BALANCE_SHEET && (
                        <BalanceSheetContainer
                            entityCodes={entityCodes}
                            isPropertyProfile={true}
                            selectedDataLevel={selectedDataLevel}
                        />
                    )}
            </div>
        </>
    );
};

export default FinancialSection;
