import React from 'react';
import { css } from 'emotion';
import { Table } from 'waypoint-react';
import LoadingTable from 'components/style/LoadingTable';

const selectedCount = css`
    margin-top: 10px;
`;

const PermissionsTable = ({ loading, columns, data, countEntity }) => {
    return loading ? (
        <LoadingTable rowCount={6} />
    ) : (
        <div>
            <Table
                bordered
                columns={columns}
                dataSource={data}
                pagination={false}
            />
            <div className={selectedCount}>{countEntity} selected</div>
        </div>
    );
};

export default PermissionsTable;
