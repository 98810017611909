import { API_URL } from 'config/constants';
import { CommentThreadData } from 'waypoint-types';

/**
 * @param {string} commentThreadId - Comment thread id
 */
const getCommentsThreadById = async (
    commentThreadId: string
): Promise<CommentThreadData> => {
    const response = await fetch(`${API_URL}/comments/${commentThreadId}`, {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
    });

    if (!response.ok) {
        throw new Error('FAILED');
    }

    const { data } = await response.json();
    return data;
};

export default getCommentsThreadById;
