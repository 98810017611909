import { User } from 'waypoint-types';

// TODO: move this to a shared folder with API
export enum NoteReferenceType {
    AccountMapping = 'account_mapping',
    EntityProfileCard = 'entity_profile_card',
    AgedReceivables = 'aged_receivables',
    LeasingPlan = 'leasing_plan',
}

export interface Note {
    id: string;
    text: string;
    reference_id: string;
    reference_type: NoteReferenceType;
    created_by: number;
    updated_by: number;
    report_metadata_id: string;
    timestamps: {
        created_at: Date;
        updated_at: string;
    };
    author: User;
    updatedBy: User;
}
