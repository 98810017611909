import React, { useMemo, useRef, useState } from 'react';
import theme from 'config/theme';
import dxDataGrid, {
    ExportingEvent,
    RowPreparedEvent,
} from 'devextreme/ui/data_grid';
import { exportExcelFromDevExtremeDataGrid } from 'waypoint-utils';
import {
    useGetSelectedFilteredEntityCodes,
    useGetCapitalProjects,
} from 'waypoint-hooks';
import { Dictionary } from 'ts-essentials';
import { CapitalProject, RangeValue } from 'waypoint-types';
import { Moment } from 'moment';
import { message } from 'antd';
import {
    createCapitalProject,
    deleteCapitalProject,
    updateCapitalProject,
} from 'waypoint-requests';
import { generateDefaultYears } from 'components/planning/capital-projects/PlanningCapitalProjectUtils';
import { buildWeightedAverageDevExtremeConfig } from 'waypoint-utils/dev-extreme/weighted-averages';
import { DataGridRef } from 'devextreme-react/cjs/data-grid';

interface IDataGrid {
    instance: dxDataGrid;
}

interface usePlanningCapitalConfigProps {
    entityCode?: string;
    exportWorksheetName?: string;
}

export const usePlanningCapitalConfig = ({
    entityCode,
    exportWorksheetName = 'Capital Projects',
}: usePlanningCapitalConfigProps) => {
    const [grouping, setGrouping] = useState<string>('');
    const capexDataGrid = useRef<DataGridRef>(null);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [yearsSelected, setYearsSelected] = useState<number[]>([]);
    const [expandButtonEnable, setExpandButtonEnable] = useState<boolean>(true);

    const filteredEntityCodes = useGetSelectedFilteredEntityCodes();
    const entityCodes = entityCode ? [entityCode] : filteredEntityCodes;
    const entityCodesCacheKey = entityCodes.sort().join(',');

    const {
        data: capitalProjectsData,
        mutate: mutateCapitalProjectData,
        isLoading,
    } = useGetCapitalProjects(entityCodes);

    const onRowPrepared = (e: RowPreparedEvent) => {
        const isGrouped = e.rowType === 'group';
        const isExpanded = e.isExpanded && e.rowType !== 'group';
        if (isGrouped) {
            e.rowElement.style.backgroundColor = theme.colors.grays.background;
        }
        if (isExpanded) {
            e.rowElement.style.fontWeight = 'bold';
            e.rowElement.style.backgroundColor = theme.colors.blues.focusRow;
            e.rowElement.style.borderColor = theme.colors.grays.background;
        }
    };

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const resetGrouping = () => {
        capexDataGrid?.current?.instance().clearGrouping();
        setExpandButtonEnable(true);
        setGrouping('');
    };

    const onExporting = async (e: ExportingEvent) => {
        await exportExcelFromDevExtremeDataGrid(e, {
            worksheetName: exportWorksheetName,
            filename: `${exportWorksheetName}.xlsx`,
        });
    };

    const updateDimensions = () => {
        capexDataGrid?.current?.instance().updateDimensions();
    };

    const groupChanged = (e: Dictionary<string>) => {
        const group = e.value;

        if (!group) {
            return;
        }

        capexDataGrid?.current?.instance().clearGrouping();
        capexDataGrid?.current?.instance().columnOption(group, 'groupIndex', 0);
        capexDataGrid?.current?.instance().refresh();

        setExpandButtonEnable(group === 'none');
        setGrouping(group);
    };

    const generateYearsArray = (
        startYear: number,
        endYear: number,
    ): number[] => {
        const years: number[] = [];

        for (let year = startYear; year <= endYear; year++) {
            years.push(year);
        }

        return years;
    };

    const createYearsArray = (values: RangeValue<Moment>) => {
        if (!values) {
            setYearsSelected([]);
            return;
        }

        const startYear = values[0]?.year() ?? new Date().getFullYear();
        const endYear = values[1]?.year() ?? new Date().getFullYear();

        const arrayOfYears = generateYearsArray(startYear, endYear);

        setYearsSelected(arrayOfYears);
    };

    const onAddCapitalProject = (newCapitalProject: CapitalProject) => {
        capitalProjectsData &&
            mutateCapitalProjectData(
                async () => {
                    try {
                        await createCapitalProject(newCapitalProject);
                        message.success('Capital Project added successfully');
                    } catch (e) {
                        message.error('Failed to add Capital Project');

                        return [...capitalProjectsData];
                    }
                },
                {
                    optimisticData: [...capitalProjectsData, newCapitalProject],
                    rollbackOnError: true,
                    populateCache: true,
                    revalidate: true,
                },
            );
    };

    const onUpdateCapitalProject = (updatedCapitalProject: CapitalProject) => {
        capitalProjectsData &&
            mutateCapitalProjectData(
                async () => {
                    try {
                        await updateCapitalProject(
                            entityCodes[0],
                            updatedCapitalProject.id,
                            updatedCapitalProject,
                        );
                        message.success('Capital Project updated successfully');
                    } catch (e) {
                        message.error('Failed to update Capital Project');

                        return capitalProjectsData;
                    }
                },
                {
                    optimisticData: capitalProjectsData.map(
                        (capitalProject: CapitalProject) =>
                            capitalProject.id === updatedCapitalProject.id
                                ? updatedCapitalProject
                                : capitalProject,
                    ),
                    rollbackOnError: true,
                    populateCache: false,
                    revalidate: false,
                },
            );
    };

    const onDeleteCapitalProject = (rowData: CapitalProject) => {
        capitalProjectsData &&
            mutateCapitalProjectData(
                async () => {
                    try {
                        await deleteCapitalProject(
                            rowData.entity_code,
                            rowData.id,
                        );
                        message.success('Capital Project deleted successfully');
                    } catch (e) {
                        message.error('Error deleting capital project');
                        return capitalProjectsData;
                    }
                },
                {
                    optimisticData: capitalProjectsData.filter(
                        (project: CapitalProject) => project.id === rowData.id,
                    ),
                    rollbackOnError: true,
                    populateCache: true,
                    revalidate: true,
                },
            );
    };

    const onRefreshData = () => {
        capitalProjectsData &&
            mutateCapitalProjectData(
                async () => {
                    try {
                        return [...capitalProjectsData];
                    } catch (e) {
                        message.error('Failed to add Capital Project');

                        return [...capitalProjectsData];
                    }
                },
                {
                    optimisticData: [...capitalProjectsData],
                    rollbackOnError: true,
                    populateCache: true,
                    revalidate: true,
                },
            );
    };

    const defaultYears: number[] = [];

    const filteredYears = useMemo(() => {
        return yearsSelected.length ? yearsSelected : defaultYears;
    }, [yearsSelected]);

    const columnYears = useMemo(() => {
        return filteredYears.length ? filteredYears : generateDefaultYears();
    }, [filteredYears]);

    const { calculateCustomSummary } = buildWeightedAverageDevExtremeConfig(
        [
            {
                metricKey: 'actual_psf',
                scaleKey: 'rentable_square_footage',
                uniqueKeys: ['entity_code', 'rentable_square_footage'],
                options: {
                    valueFormat: { type: 'currency', precision: 2 },
                },
            },
            {
                metricKey: 'budget_psf',
                scaleKey: 'rentable_square_footage',
                uniqueKeys: ['entity_code', 'rentable_square_footage'],
                options: {
                    valueFormat: { type: 'currency', precision: 2 },
                },
            },
        ],
        {
            displayFormat: '{0}',
        },
    );

    return {
        grouping,
        setGrouping,
        capexDataGrid,
        expanded,
        setExpanded,
        toggleExpanded,
        yearsSelected,
        setYearsSelected,
        expandButtonEnable,
        setExpandButtonEnable,
        onRowPrepared,
        resetGrouping,
        onExporting,
        entityCodes,
        entityCodesCacheKey,
        groupChanged,
        capitalProjectsData: capitalProjectsData ?? [],
        mutateCapitalProjectData,
        createYearsArray,
        filteredYears,
        updateDimensions,
        isLoading,
        onAddCapitalProject,
        onUpdateCapitalProject,
        onDeleteCapitalProject,
        onRefreshData,
        columnYears,
        calculateCustomSummary,
    };
};
