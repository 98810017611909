import { Avatar, Button, List, Popconfirm, Skeleton, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
    useGetMentionableUsers,
    useGetWorkflowRoles,
    useHasNotePermission,
} from 'waypoint-hooks';
import { getAvatarInitials } from 'components/financials/comparative-income-statement/banner/ComparisonSelectionsBannerUtils';
import moment from 'moment';
import React from 'react';
import { Note, UpdateEntityProfileCardNote } from 'waypoint-types';
import theme from 'config/theme';

interface EntityProfileCardNoteProps {
    note: Note;
    userId: number;
    entityCodes: string[];
    isAdmin?: boolean;
    isPDFExport?: boolean;
    isLoading: boolean;
    onEditNote: (note: UpdateEntityProfileCardNote) => void;
    onDeleteNote: (noteId: string) => void;
}
const EntityProfileCardNote = ({
    note,
    userId,
    entityCodes,
    isPDFExport = false,
    isAdmin = false,
    isLoading,
    onEditNote,
    onDeleteNote,
}: EntityProfileCardNoteProps) => {
    const userMentionOptions = useGetMentionableUsers(entityCodes);

    const { data: workflowRoles } = useGetWorkflowRoles(
        entityCodes,
        (userMentionOptions ?? []).map((user) => {
            return {
                id: Number(user.id),
                name: user.text,
                profile_image_url: user.profile_image_url ?? '',
            };
        })
    );
    const hasNotePermission = useHasNotePermission(
        note,
        userId,
        isAdmin,
        workflowRoles
    );

    const CreatedAtNote = (
        <div
            style={{
                paddingLeft: '8px',
                color: theme.colors.grays.text,
            }}
        >
            {moment(
                note.timestamps.updated_at ?? note.timestamps.created_at
            ).format('MM/DD/YYYY')}
        </div>
    );

    const EditNoteButton = (
        <Button
            size="small"
            type="text"
            icon={<EditOutlined />}
            onClick={() => onEditNote(note)}
        ></Button>
    );

    const DeleteNoteButton = (
        <Popconfirm
            placement="top"
            title={'Are you sure you want to delete this item?'}
            onConfirm={(event) => {
                event?.stopPropagation();
                onDeleteNote(note.id);
            }}
            onCancel={(event) => {
                event?.stopPropagation();
            }}
            okType="danger"
            okText="Delete"
            cancelText="Cancel"
        >
            <Button size="small" type="text" icon={<DeleteOutlined />}></Button>
        </Popconfirm>
    );

    return (
        <List.Item
            id={note.id}
            actions={
                isPDFExport
                    ? [CreatedAtNote]
                    : [
                          CreatedAtNote,
                          hasNotePermission && !isPDFExport && EditNoteButton,
                          hasNotePermission && !isPDFExport && DeleteNoteButton,
                      ]
            }
        >
            <Skeleton avatar title={false} loading={isLoading} active>
                <List.Item.Meta
                    avatar={
                        <Tooltip
                            placement="top"
                            trigger="hover"
                            arrow
                            title={`${note.author?.firstname} ${note.author?.lastname}`}
                        >
                            <Avatar
                                size={'small'}
                                style={{
                                    fontSize: '12px',
                                }}
                                src={
                                    note.author?.profile_image_url?.length
                                        ? note.author?.profile_image_url
                                        : null
                                }
                            >
                                {getAvatarInitials(
                                    `${note.author?.firstname ?? ''} ${
                                        note.author?.lastname ?? ''
                                    }`
                                )}
                            </Avatar>
                        </Tooltip>
                    }
                    description={
                        <p
                            style={{
                                wordWrap: 'break-word',
                                color: theme.colors.grays.text,
                            }}
                        >
                            {note.text}
                        </p>
                    }
                />
            </Skeleton>
        </List.Item>
    );
};

export default EntityProfileCardNote;
