import { Title } from 'waypoint-react';
import {
    TotalCard,
    BreakdownCard,
    OvertimeCard,
} from 'components/analytics/financialOverview/cards';

const cardHashMap = {
    title: Title,
    total: TotalCard,
    breakdown: BreakdownCard,
    overtime: OvertimeCard,
};

export default cardHashMap;
