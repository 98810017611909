import { API_URL } from 'config/constants';
import { ReportSetting } from 'waypoint-types';

interface CreateReportSettingsParams {
    reportId: string;
    settingsType: string;
    settings: { [x: string]: any };
    enabled: boolean;
}

const createReportSettings = async (
    body: CreateReportSettingsParams[]
): Promise<ReportSetting[]> => {
    const response = await fetch(`${API_URL}/reports/report-settings`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',

        body: JSON.stringify(body),
    });

    if (!response.ok) {
        throw new Error('Failed to create report settings');
    }

    const { data } = await response.json();

    return data;
};

export default createReportSettings;
