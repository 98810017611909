import { API_URL } from 'config/constants';

const deleteClientDefinedType = async (
    type: string,
    typeId: string
): Promise<string> => {
    const response = await fetch(
        `${API_URL}/capital-project/client-defined-type/${type}/${typeId}`,
        {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
        }
    );

    if (!response.ok) {
        throw new Error('Failed to delete client defined type');
    }

    const { data } = await response.json();

    return data;
};

export default deleteClientDefinedType;
