import Button from 'antd/lib/button';
import { css } from 'emotion';
import theme from 'config/theme';
import { useCommentsPane } from 'contexts/comments/CommentsContext';
import React, { CSSProperties } from 'react';
import { useGetCommentsByThreadId } from 'waypoint-hooks/data-access/useGetCommentsByThreadId';
import { useNotifications } from '@magicbell/react-headless';

interface CommentsButtonProps {
    commentThreadId?: string;
    buttonStyle?: CSSProperties;
    onClicked?: (isOpening: boolean) => void;
}

const defaultButtonStyle = css`
    &.ant-btn-primary:enabled {
        color: ${theme.colors.white} !important;
        background-color: ${theme.colors.blues.primary} !important;
        border-color: ${theme.colors.blues.outline} !important;
    }
`;

export const CommentsButton = ({
    commentThreadId,
    buttonStyle,
    onClicked,
}: CommentsButtonProps) => {
    const {
        toggleComments,
        commentThread: contextCommentThread,
        commentThreadId: contextCommentThreadId,
        initializeComments,
        openComments,
        isVisible,
    } = useCommentsPane();

    const { data: boundCommentThread } = useGetCommentsByThreadId(
        commentThreadId ?? null,
        useNotifications()
    );
    const getCommentCount = (): number => {
        if (commentThreadId) {
            if (boundCommentThread) {
                return boundCommentThread.comments.length;
            }

            return 0;
        }

        if (contextCommentThread) {
            return contextCommentThread.comments.length;
        }

        return 0;
    };

    const commentCount = getCommentCount();

    return (
        <Button
            className={defaultButtonStyle}
            style={buttonStyle}
            type="primary"
            onClick={(e) => {
                e.stopPropagation();

                if (onClicked) {
                    onClicked(!isVisible);
                }

                const shouldForceOpen =
                    commentThreadId &&
                    commentThreadId !== contextCommentThreadId;

                if (shouldForceOpen) {
                    openComments();
                } else {
                    toggleComments();
                }

                if (commentThreadId) {
                    initializeComments(commentThreadId);
                }
            }}
        >
            <i
                className="fa-regular fa-commenting"
                style={{
                    fontSize: '18px',
                    paddingRight: commentCount > 0 ? '5px' : '0px',
                }}
            />

            {commentCount > 0 && (
                <span style={{ fontWeight: 'bold' }}>{commentCount}</span>
            )}
        </Button>
    );
};
