import PropTypes from 'prop-types';
import React from 'react';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import defaultStylePP from './defaultStylePP';

export const DownloadCell = ({ cellData, ...rest }) => {
    const { icon, size, style } = rest;
    const defaultStyle = {
        fontSize: '25px',
        fontWeight: 600,
        cursor: 'pointer',
        margin: '0 auto',
        color: '#555555',
    };
    return (
        <div {...rest} style={style}>
            <a
                href={cellData}
                style={defaultStyle}
                download
                target="_blank"
                rel="noreferrer"
            >
                <i icon={icon || faDownload} size={size || '1x'} />
            </a>
        </div>
    );
};

DownloadCell.propTypes = {
    cellData: PropTypes.string,
};

// TODO (Colby): Add Prop Types to Download Cell
export const DownloadCellRenderer = defaultStylePP(DownloadCell);
export default DownloadCellRenderer;
