import { compose } from 'redux';
import defaultStylePP from './defaultStylePP';
import calendarDueDatePP from './calendarDueDatePP';
import { DefaultCell } from './DefaultCellRenderer';

export const CalendarDueDateCellRenderer = compose(
    defaultStylePP,
    calendarDueDatePP
)(DefaultCell);
export default CalendarDueDateCellRenderer;
