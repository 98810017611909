import { MenuOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';

interface AgedReceivablesActionsCellParams {
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
    agedReceivablesNoteReferenceId: string | null;
    setAgedReceivablesNoteReferenceId: (value: string | null) => void;
}

const AgedReceivablesActionsCell = ({
    isVisible,
    setIsVisible,
    agedReceivablesNoteReferenceId,
    setAgedReceivablesNoteReferenceId,
}: AgedReceivablesActionsCellParams): JSX.Element => {
    const toggleIsVisible = () => {
        setAgedReceivablesNoteReferenceId(agedReceivablesNoteReferenceId);
        setIsVisible(!isVisible);
    };

    const items = [
        {
            key: '1',
            label: 'Add Note',
            onClick: toggleIsVisible,
        },
    ];

    return (
        <div>
            <Dropdown
                trigger={['click']}
                placement={'bottomRight'}
                menu={{ items }}
            >
                <span
                    style={{
                        fontSize: '13px',
                        fontWeight: 'normal',
                        textTransform: 'none',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                    }}
                >
                    <MenuOutlined />
                </span>
            </Dropdown>
        </div>
    );
};

export default AgedReceivablesActionsCell;
