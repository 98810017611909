import React, { useState } from 'react';
import { OptionChangedEvent } from 'devextreme/ui/data_grid';

export const useSortByGroupSummaryInfo = () => {
    const [sortSelection, setSortSelection] = useState<string | undefined>(
        undefined,
    );
    const [sortOrderAscending, setSortOrderAscending] = useState<boolean>(true);
    const [sortVisible, setSortVisible] = useState<boolean>(false);
    const [sortExcludedColumns, setSortExcludedColumns] = useState<string[]>(
        [],
    );

    const toggleSortOrder = () => {
        setSortOrderAscending(!sortOrderAscending);
    };

    // specific case for RentRoll to account for showInColumn field in total_units
    const getDataFieldGroupName = (dataField: string) => {
        if (dataField === 'space_number') {
            return 'leasable_space_code';
        }
        return dataField;
    };

    const toggleSortSettings = (e: OptionChangedEvent) => {
        if (e.name === 'columns' || e.name === 'onContentReady') {
            // https://supportcenter.devexpress.com/ticket/details/t417466/dxdatagrid-how-to-get-the-grouped-fields
            const groupingDataFieldNames: string[] = [];
            const nonVisibleDataFieldNames: string[] = [];
            for (let i = 0; i < e.component.columnCount(); i++) {
                const dataField = getDataFieldGroupName(
                    e.component.columnOption(i, 'dataField'),
                );
                if (e.component.columnOption(i, 'groupIndex') > -1) {
                    groupingDataFieldNames.push(dataField);
                } else if (!e.component.columnOption(i, 'visible')) {
                    nonVisibleDataFieldNames.push(dataField);
                } else if (e.component.columnOption(i, 'ownerBand')) {
                    // exclude child columns if parent column section is not visible
                    const parentColumnIndex = e.component.columnOption(
                        i,
                        'ownerBand',
                    );
                    if (
                        !e.component.columnOption(parentColumnIndex, 'visible')
                    ) {
                        nonVisibleDataFieldNames.push(dataField);
                    }
                }
            }
            const sortExcludedDataFieldNames = [
                ...groupingDataFieldNames,
                ...nonVisibleDataFieldNames,
            ];

            if (
                sortExcludedColumns.length !== sortExcludedDataFieldNames.length
            ) {
                setSortExcludedColumns(sortExcludedDataFieldNames);
            }
            if (groupingDataFieldNames.length && !sortVisible) {
                setSortVisible(true);
            }
            if (!groupingDataFieldNames.length && sortVisible) {
                setSortVisible(false);
                setSortOrderAscending(true);
                setSortSelection(undefined);
            }
        }
    };

    return {
        sortSelection,
        setSortSelection,
        sortOrderAscending,
        setSortOrderAscending,
        sortVisible,
        setSortVisible,
        sortExcludedColumns,
        setSortExcludedColumns,
        toggleSortOrder,
        toggleSortSettings,
    };
};
