import { API_URL } from 'config/constants';
import { useState, useEffect } from 'react';
import useSWR from 'swr';

export const useEntityPhoto = (entityCode: string) => {
    const [photoData, setPhotoData] = useState(null);

    const fetchEntityPhoto64 = async (entityCode: string) => {
        const response = await fetch(
            `${API_URL}/properties/photo/${entityCode}`,
            {
                method: 'GET',
                credentials: 'include',
            }
        );
        const { data } = await response.json();
        return data;
    };

    const { data } = useSWR(entityCode, fetchEntityPhoto64);

    useEffect(() => {
        if (data) {
            setPhotoData(data);
        }
    }, [data]);

    return photoData;
};
