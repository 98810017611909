import { API_URL } from 'config/constants';
import { PermissionGroup } from 'waypoint-types';

export async function getPermissionGroups(
    clientId: number
): Promise<PermissionGroup[]> {
    const response = await fetch(`${API_URL}/permission-groups/${clientId}`, {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
    });

    if (!response.ok) {
        throw new Error('Failed to get permission groups');
    }

    const { data } = await response.json();

    return data as PermissionGroup[];
}
