import DataGrid, {
    Column,
    Sorting,
    RowDragging,
    Format,
    RequiredRule,
    DataGridRef,
} from 'devextreme-react/data-grid';
import { LeasingGuideline } from 'waypoint-types';
import theme from 'config/theme';
import { EditGuidelineModal } from './EditGuidelineModal';
import { Popconfirm } from 'antd';
import { moneyFormatter } from 'utils/formatters';
import { LeasingGuidelineExportableGrid } from 'components/reports/components/entity-report-widgets/report-widget-export-grids/LeasingGuidelinesExportableGrid';

interface LeasingGuidelineTableProps {
    data: LeasingGuideline[];
    onReorder: (e: {
        component: Record<any, any>;
        fromIndex: number;
        toIndex: number;
    }) => void;
    onSaved: (data: LeasingGuideline) => void;
    onDeleted: (id: string) => void;
    isEditModalVisible: boolean;
    setIsEditModalVisible: (value: boolean) => void;
    selectedRowData: LeasingGuideline | null;
    setSelectedRowData: (value: LeasingGuideline | null) => void;
    dataGridRef?: React.RefObject<DataGridRef<any, any>>;
    isPDFExport?: boolean;
}

export const LeasingGuidelinesTable = ({
    data,
    onReorder,
    onSaved,
    onDeleted,
    isEditModalVisible,
    setIsEditModalVisible,
    selectedRowData,
    setSelectedRowData,
    dataGridRef,
    isPDFExport,
}: LeasingGuidelineTableProps) => {
    const prepareDataFor = (data: LeasingGuideline) => {
        const leaseCommissions =
            data.leasing_commissions && data.leasing_commissions_type === '%'
                ? data.leasing_commissions * 100
                : data.leasing_commissions;
        return {
            ...data,
            escalations: data.escalations && data.escalations * 100,
            leasing_commissions: leaseCommissions,
        };
    };
    const renderEditorLink = (e: { data: LeasingGuideline }) => {
        return (
            <div style={{ display: 'flex' }}>
                <i
                    style={{
                        marginRight: '10px',
                        color: theme.colors.blues.linkBlue,
                        cursor: 'pointer',
                        fontSize: '14px',
                    }}
                    className="fa-solid fa-pencil"
                    onClick={() => {
                        setSelectedRowData(prepareDataFor(e.data));
                        setIsEditModalVisible(true);
                    }}
                />

                <Popconfirm
                    placement="topRight"
                    title={`Are you sure you want to delete this row?`}
                    onConfirm={() => {
                        onDeleted(e.data.id);
                    }}
                    onCancel={() => {
                        /**/
                    }}
                    okType="danger"
                    okText="Delete"
                    cancelText="Cancel"
                >
                    <i
                        style={{
                            color: theme.colors.blues.linkBlue,
                            cursor: 'pointer',
                            fontSize: '14px',
                        }}
                        className="fa-solid fa-trash"
                    />
                </Popconfirm>
            </div>
        );
    };

    if (isPDFExport) {
        return (
            <div style={{ marginTop: 12 }}>
                <LeasingGuidelineExportableGrid data={data} />
            </div>
        );
    }

    return (
        <>
            {isEditModalVisible && (
                <EditGuidelineModal
                    selectedRowData={selectedRowData}
                    visible={isEditModalVisible}
                    onClose={() => {
                        setSelectedRowData(null);
                        setIsEditModalVisible(false);
                    }}
                    onSaved={onSaved}
                />
            )}
            <DataGrid
                ref={dataGridRef}
                dataSource={data}
                showBorders={true}
                allowColumnReordering={true}
                width={'100%'}
                hoverStateEnabled={true}
            >
                <Sorting mode="single" />
                <RowDragging
                    onReorder={onReorder}
                    allowReordering
                    showDragIcons
                />
                <Column
                    dataField="sort_index"
                    caption="Index"
                    defaultSortOrder="asc"
                    visible={false}
                />
                <Column
                    allowSorting={false}
                    dataField="guideline"
                    caption="Guideline"
                    minWidth={150}
                    width={'100%'}
                >
                    <RequiredRule />
                </Column>
                <Column
                    allowSorting={false}
                    dataField="type"
                    width={'130px'}
                    caption="Lease Type"
                    alignment={'center'}
                >
                    <RequiredRule />
                </Column>
                <Column
                    allowSorting={false}
                    dataField="starting_rent"
                    dataType={'number'}
                    width={'130px'}
                    caption="Starting Rent"
                    alignment={'center'}
                >
                    <Format type="currency" precision={2} />
                </Column>
                <Column
                    allowSorting={false}
                    dataField="escalations"
                    width={'130px'}
                    dataType={'number'}
                    caption="Escalations (%)"
                    alignment={'center'}
                >
                    <Format type="percent" precision={2} />
                </Column>
                <Column
                    allowSorting={false}
                    dataField="term_months"
                    dataType={'number'}
                    width={'130px'}
                    caption="Term (Mos)"
                    alignment={'center'}
                >
                    <Format type="fixedPoint" precision={0} />
                </Column>
                <Column
                    allowSorting={false}
                    dataField="concessions_months"
                    dataType={'number'}
                    width={'130px'}
                    caption="Concessions (Mos)"
                    alignment={'center'}
                >
                    <Format type="fixedPoint" precision={0} />
                </Column>
                <Column
                    allowSorting={false}
                    dataField="leasing_commissions"
                    dataType={'number'}
                    width={'130px'}
                    caption="LC ($ or %)"
                    alignment={'center'}
                    cellRender={({ data }) => {
                        if (data.leasing_commissions == null) {
                            return '';
                        }
                        if (data.leasing_commissions_type === '%') {
                            const percentage = Number(
                                data.leasing_commissions * 100,
                            ).toFixed(2);
                            return `${percentage}%`;
                        }
                        return `${moneyFormatter(data.leasing_commissions)}`;
                    }}
                />
                <Column
                    allowSorting={false}
                    dataField="tenant_improvements_per_year"
                    dataType={'number'}
                    caption="TI PY"
                    width={'130px'}
                    alignment={'center'}
                >
                    <Format type="currency" precision={2} />
                </Column>
                <Column
                    allowSorting={false}
                    dataField="market_comps_low"
                    dataType={'number'}
                    width={'130px'}
                    caption="Market Comps"
                    alignment={'center'}
                    cellRender={({ data }) => {
                        const low = data.market_comps_low
                            ? `$${data.market_comps_low}`
                            : '';
                        const high = data.market_comps_high
                            ? `$${data.market_comps_high}`
                            : '';
                        const separator =
                            data.market_comps_low && data.market_comps_high
                                ? ' - '
                                : '';
                        return `${low}${separator}${high}`;
                    }}
                >
                    <Format type="currency" precision={2} />
                </Column>
                <Column
                    width={60}
                    allowExporting={false}
                    showInColumnChooser={false}
                    cellRender={(e) => renderEditorLink(e)}
                />
            </DataGrid>
        </>
    );
};
