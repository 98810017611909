import React from 'react';
import { css, cx } from 'emotion';

const SelectionsBanner = ({
    children,
    className,
    isCollapsed,
    bannerInlineStyles,
}: {
    children: React.ReactNode;
    className?: string;
    isCollapsed?: boolean;
    bannerInlineStyles?: React.CSSProperties;
}): JSX.Element => {
    const bannerStyle = css`
        height: ${isCollapsed ? '0' : 'auto'};
        justify-content: space-between;
        border: 1px solid #d9d9d9;
        background-color: #fff;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        box-shadow:
            0px 3px 5px -1px rgba(0, 0, 0, 0.2),
            0px 6px 10px 0px rgba(0, 0, 0, 0.04);
        padding-bottom: ${isCollapsed ? '0' : '10px'};
        padding-top: ${isCollapsed ? '0' : '5px'};
        margin: 0 0 ${isCollapsed ? '0' : '10px'} 0;
        transition:
            height 0.2s ease-in-out,
            min-height 0.2s ease-in-out,
            padding 0.2s ease-in-out;
        @media (max-width: 1336px) {
            height: ${isCollapsed ? '0' : 'auto'};
            padding-bottom: ${isCollapsed ? '0' : '20px'};
        }
        @media screen and (max-width: '1160px') {
            padding-bottom: ${isCollapsed ? '0' : '20px'};
            min-height: ${isCollapsed ? '0' : 'auto'};
            height: ${isCollapsed ? '0' : 'auto'};
            padding-bottom: ${isCollapsed ? '0' : '20px'};
            overflow: ${isCollapsed ? 'hidden' : 'visible'};
            .comparisonSelectionBanner__workflow {
                padding-top: 12px;
                padding-left: 2px;
                align-items: center;
            }
        }
    `;
    return (
        <div
            style={bannerInlineStyles ? bannerInlineStyles : {}}
            className={cx([bannerStyle, className ?? ''])}
        >
            {children}
        </div>
    );
};

export default SelectionsBanner;
