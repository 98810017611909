import React from 'react';
import { css, cx } from 'emotion';

const loadingBar = css`
    width: inherit;
    height: inherit;
    border: 0px;
    animation-duration: 4.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: cubic-bezier(0.75, 0.21, 0.34, 0.88);
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    position: relative;
`;

interface TextLoadingBarProps {
    className?: string;
    style?: Object;
}

export const TextLoadingBar = ({
    className = '',
    style = {},
}: TextLoadingBarProps): JSX.Element => {
    return <div className={cx([loadingBar, className])} style={style} />;
};

export default TextLoadingBar;
