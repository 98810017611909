import memoizeOne from 'memoize-one';
import { isEqual, truncate } from 'lodash';
import { renderToString } from 'react-dom/server';
import chartBuilder from 'components/charts/chartBuilder';
import { floatRenderer, getVarianceColor } from 'utils/tables/renderers';
import { formatMoney } from 'utils/formatters';
import {
    getDisplayTypeAbbreviation,
    getDisplayTypeLabel,
} from 'components/analytics/financialOverview/menu/displayType/utils';
import { FinancialOverviewTooltip } from 'components/analytics/financialOverview/charts';

const generateTooltip = (props) => {
    return renderToString(<FinancialOverviewTooltip {...props} />);
};

export const chartStyle = {
    numberPrefix: '$',
    yAxisValueDecimals: '2',
    forceYAxisValueDecimals: '1',
    drawAnchors: '0',
    exportFileName: 'Account Breakdown',
    numVisiblePlot: '16',
    numDivLines: 5,
    legendIconScale: 1,
    legendItemFontSize: 12,
    chartBottomMargin: 0,
    scrollheight: '7',
    compactdatamode: '1',
    plotColorinTooltip: '0',
    seriesNameInToolTip: '0',
    drawCrossLine: '1',
    showLegend: '1',
    plotHighlightEffect: 'fadeout',
    legendPosition: 'bottom',
    legendAllowDrag: '0',
    showToolTipShadow: '0',
    toolTipBorderColor: '#C1C1C1',
    tooltipPadding: '10',
    tooltipborderradius: '5',
    exportFormats: 'PNG|PDF|JPG|SVG',
};

export const getFinancialOverviewBreakdownChartConfig = ({
    data,
    displayType,
}) => {
    const chart = chartBuilder();
    const category = data.map((node) => ({
        label: truncate(node.account_mapping_name, 15),
    }));
    chart.type('scrollcombi2d');
    chart.data();
    chart.height('72.5%');
    chart.style({
        ...chartStyle,
        yAxisname: getDisplayTypeLabel(displayType),
    });
    chart.categories([
        {
            category,
        },
    ]);

    chart.dataset([
        {
            seriesname: 'Actual',
            color: '#3588BD',
            plotBorderColor: '#3588BD',
            data: data.map((item) => ({
                value: item.actual_amount || 0,
                toolText: generateTooltip({
                    title: item.account_mapping_name,
                    topContent: {
                        value: `${formatMoney(
                            item.actual_amount,
                        )} ${getDisplayTypeAbbreviation(displayType)}`,
                        label: 'Actual',
                    },
                    bottomContent: {
                        value: `${formatMoney(
                            item.budget_amount,
                        )} ${getDisplayTypeAbbreviation(displayType)}`,
                        label: 'Budget',
                    },
                }),
            })),
        },
        {
            seriesname: 'Budget',
            color: '#F4AE5E',
            plotBorderColor: '#F4AE5E',
            data: data.map((item) => ({
                value: item.budget_amount || 0,
                toolText: generateTooltip({
                    title: '',
                    topContent: {
                        label: 'Variance',
                        value: `${formatMoney(
                            item.variance_amount,
                        )} ${getDisplayTypeAbbreviation(displayType)}`,
                        color: getVarianceColor(item.variance_is_good),
                    },
                    bottomContent: {
                        label: 'Variance %',
                        value: floatRenderer(item.variance_ratio),
                        color: getVarianceColor(item.variance_is_good),
                    },
                }),
            })),
        },
    ]);

    const { config } = chart; // this should be a method
    return config;
};

export const buildFinancialOverviewBreakdownChartConfig = memoizeOne(
    getFinancialOverviewBreakdownChartConfig,
    isEqual,
);
