import useSWR from 'swr';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { getLeasesPerformanceOverview } from 'waypoint-requests';
import { PropertyDetailsProps, SelectedDataLevel } from 'waypoint-types';

export const useGetLeasesPerformanceOverview = (
    entityCodes: string[],
    selectedDataLevel: SelectedDataLevel
): DataHookResponse<PropertyDetailsProps[]> => {
    const entityCodesCacheKey = entityCodes.join(',');

    const { data, error, mutate } = useSWR(
        entityCodesCacheKey
            ? `/api/leases/performance-overview/${entityCodesCacheKey}/${selectedDataLevel.percentageType}/${selectedDataLevel.stakeholder}`
            : null,
        () => getLeasesPerformanceOverview(entityCodes, selectedDataLevel),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
            shouldRetryOnError: false,
        }
    );
    return {
        isLoading: !data,
        isError: !!error,
        data,
        mutate,
    };
};
