import {
    CrosstabPeriod,
    DataRecord,
    SummaryDisplayMode,
} from './CrosstabTypes';
import { RowSelection } from './CrosstabRowSelection';

export const CROSSTAB_TITLE = 'Crosstab';
export const CROSSTAB_URL = 'crosstab';

export const CROSSTAB_DEFAULT_PERIOD_SELECTION = CrosstabPeriod.Month;
export const CROSSTAB_DEFAULT_ROW_SELECTION = RowSelection.FullDetail;

export const CROSSTAB_PRIMARY_MODE = 'PRIMARY_MODE';
export const CROSSTAB_SECONDARY_MODE = 'SECONDARY_MODE';

export const CROSSTAB_SUMMARY_DISPLAY_MODES: SummaryDisplayMode[] = [
    { text: 'Gross $', value: undefined },
    { text: 'Percent of Column Total', value: 'percentOfColumnTotal' },
    { text: 'Percent of Row Total', value: 'percentOfRowTotal' },
    { text: 'Percent of Row Grand Total', value: 'percentOfRowGrandTotal' },
];

const ACCOUNT_FIELDS: (keyof DataRecord)[] = [
    'LVL0NAME',
    'LVL1NAME',
    'LVL2NAME',
    'LVL3NAME',
    'LVL4NAME',
];

const ROOT_ACCOUNT_FIELD: keyof DataRecord = 'LVL0NAME';

export const CROSSTAB_ACCOUNT_NAME_FIELDS: string[] = ACCOUNT_FIELDS;

export const CROSSTAB_ROOT_ACCOUNT_DATA_FIELD: string = ROOT_ACCOUNT_FIELD;

export const MEASURE_FIELD_VARIANCE_$: keyof DataRecord = 'VARIANCE';
export const FIELD_ATTRIBUTE: keyof DataRecord = 'ATTRIBUTE';
export const FIELD_PERIOD: keyof DataRecord = 'PERIOD_END';
export const FIELD_PROPERTY: keyof DataRecord = 'DISPLAY_NAME';

export const CROSSTAB_DEFAULT_PRIMARY_MODE = 'actual';
export const CROSSTAB_DEFAULT_SECONDARY_MODE = 'budget';

export const CROSSTAB_REPORT_TYPE = 'crosstab';
