import React from 'react';
import { css } from 'emotion';
import { Radio, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import {
    ACCESS_ALL_ENTITIES,
    CHOOSE_ENTITY,
} from 'components/settings/permissions/utils';

const optionsStyle = css`
    display: inline-block;
    text-align: right;
    margin-right: 2px;
    margin-top: 10px;
    float: left;
`;
const searchInput = css`
    margin-top: 20px !important;
    margin-bottom: 0px !important;
`;

const PermissionsOption = ({
    onChange,
    isAllEntityCode,
    onSearch,
    searchText,
}) => {
    return (
        <div className={optionsStyle}>
            <div>
                <Radio.Group onChange={onChange} value={isAllEntityCode}>
                    <Radio value={ACCESS_ALL_ENTITIES}>All entities</Radio>
                    <Radio value={CHOOSE_ENTITY}>Choose list</Radio>
                </Radio.Group>
            </div>
            <div>
                <Input
                    className={searchInput}
                    placeholder="Search entity"
                    onChange={onSearch}
                    value={searchText}
                    suffix={<SearchOutlined />}
                />
            </div>
        </div>
    );
};

export default PermissionsOption;
