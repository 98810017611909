// @ts-nocheck
// This file is a result of nx update

import { cloneDeep as _cloneDeep } from 'lodash';
import { favorites as TYPES, user as USER_TYPES } from 'state/actionTypes';
import FavoritesService from './FavoritesService';

const defaultState = {
    groups: {},
    properties: {},
    userID: null,
};

const favoritesService = new FavoritesService();

function updateFavoritesState(payload, newState) {
    const user = payload[Object.keys(payload)[0]];

    if (!user) {
        return;
    }

    const favoritesPayload = favoritesService.getFavoritesPayload(user.id);
    const favoritesKeys = Object.keys(favoritesPayload);

    favoritesKeys.forEach((favoriteID) => {
        const favoriteData = {
            status: 'SAVED',
            ...favoritesPayload[favoriteID],
        };
        const [entityType, entityID] = favoriteID.split('__');

        if (entityType === 'property') {
            newState.properties[entityID] = favoriteData;
        }

        if (entityType === 'group') {
            newState.groups[entityID] = favoriteData;
        }
    });
}

export default function reducer(state = defaultState, action) {
    const { type, payload } = action;
    const id = payload ? payload.id : null;
    const newState = Object.assign({}, state);
    let objectType;

    if (payload && payload.type) {
        objectType = payload.type === 'group' ? 'groups' : 'properties';
    }

    let favorite;
    switch (type) {
        case TYPES.ADD_FAVORITE:
            // Need to handle adding a new favorite and then committing it
            favorite = _cloneDeep(newState[objectType][id]) || {
                id,
                name: payload.name,
            };

            if (action.status === 'error') {
                favorite.status = 'ERROR';
            } else if (action.status === 'success') {
                favorite.favoriteID = payload.favoriteID;
                favorite.status = 'SAVED';
            } else {
                favorite.status = 'PENDING_SAVE';
            }

            newState[objectType][id] = favorite;
            break;

        case TYPES.REMOVE_FAVORITE:
            favorite = _cloneDeep(newState[objectType][id]);

            if (action.status === 'error') {
                favorite.status = 'ERROR';
            } else if (action.status === 'success') {
                favorite.status = 'DELETED';
            } else {
                favorite.status = 'PENDING_DELETE';
            }

            newState[objectType][id] = favorite;
            break;

        /* eslint-disable no-case-declarations */
        // TODO (Nicholas): Remove eslint disable by moving logic into a function
        case USER_TYPES.GET_USER_OBJ:
            updateFavoritesState(payload, newState);
            break;
        /* eslint-enable no-case-declarations */

        default:
            return state;
    }

    return newState;
}
