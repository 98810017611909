import React from 'react';
import { Select } from 'antd';

const { Option, OptGroup } = Select;
/**
 * This is designed to integrate with periodOptionBuilder. It displays an collection of periods, some of which have subarrays representing suboptions
 * @param {array} options array of objects. Each object contains an option and subOption field. It ignores other fields
 * @param {func} onChange callback
 * @param {string} value current value of selector
 */
const PeriodSelector = ({ onChange, value, options = [] }) => {
    return (
        <Select
            defaultValue={value}
            style={{ width: '100%' }}
            onChange={onChange}
        >
            {options.map(({ option, subOptions }) => {
                if (subOptions) {
                    return (
                        <OptGroup label={option.label}>
                            {subOptions.map(({ option: subOption }) => {
                                return (
                                    <Option
                                        value={subOption.value}
                                        key={subOption.value}
                                    >
                                        {subOption.label}
                                    </Option>
                                );
                            })}
                        </OptGroup>
                    );
                }
                return (
                    <Option value={option.value} key={option.value}>
                        {option.label}
                    </Option>
                );
            })}
        </Select>
    );
};

export default PeriodSelector;
