import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import useSWR from 'swr';
import { getLatestEntityBudget } from 'waypoint-requests';
import { BudgetUpload } from 'waypoint-types';

export const useGetLatestEntityBudget = (
    entityCode: string | null,
    sourcePropertyCode: string | null,
    year: string | null
): DataHookResponse<BudgetUpload | null> => {
    const { data, error, mutate } = useSWR(
        `/api/budget-upload/entity-budget/${entityCode}/${sourcePropertyCode}/${year}`,
        () => getLatestEntityBudget(entityCode, sourcePropertyCode, year),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
        }
    );

    return {
        isLoading: !data,
        isError: !!error,
        data,
        mutate,
    };
};
