import React from 'react';
import { Select } from 'waypoint-react';
import { ClientMode, SelectOptions } from 'waypoint-types';

const outerDivStyle = {
    display: 'inline-block',
};

const modeSelectStyle = {
    display: 'inline-block',
    width: '185px',
};

export const getComparisonTypeSelect = (
    clientModes: ClientMode[],
    defaultMode: string,
    modeSelection: string,
    onChange: (value: string) => void
): JSX.Element => {
    const options: SelectOptions[] = clientModes.map((cm) => {
        return {
            label: cm.display_name,
            value: cm.code,
        };
    });

    const key = `crosstab-mode-${modeSelection}`;
    const props = {
        key,
        style: { ...modeSelectStyle, width: '150px' },
        options: options,
        value: modeSelection || defaultMode,
        onChange,
    };

    return (
        <div key={key}>
            <div style={{ ...outerDivStyle, width: '90%' }}>
                <Select {...props} />
            </div>
        </div>
    );
};
