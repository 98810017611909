import React from 'react';
import ReactFC from 'react-fusioncharts';
import FusionCharts from 'fusioncharts';
import OverlappedBar2D from 'fusioncharts/fusioncharts.overlappedbar2d';
import ExcelExport from 'fusioncharts/fusioncharts.excelexport';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import { buildFinancialOverviewBreakdownChartConfig } from 'components/analytics/financialOverview/charts/breakdown/utils';

ReactFC.fcRoot(FusionCharts, OverlappedBar2D, ExcelExport, FusionTheme);

const BreakdownChart = ({ data, period, displayType }) => {
    return (
        <ReactFC
            {...buildFinancialOverviewBreakdownChartConfig({
                data,
                displayType,
            })}
        />
    );
};

export default BreakdownChart;
