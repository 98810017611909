import { API_URL } from 'config/constants';

interface RestoreReportsParams {
    reportIds: string[];
}

const restoreReports = async (body: RestoreReportsParams): Promise<string> => {
    const response = await fetch(`${API_URL}/reports/restore-reports`, {
        method: 'PUT',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
    });

    if (!response.ok) {
        throw new Error('FAILED');
    }

    const { data } = await response.json();
    return data;
};

export default restoreReports;
