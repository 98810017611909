import React, { CSSProperties } from 'react';
import theme from 'config/theme';
import { useCommentsPane } from 'contexts/comments/CommentsContext';
import { useGetCommentsByThreadId } from 'waypoint-hooks/data-access/useGetCommentsByThreadId';
import { useNotifications } from '@magicbell/react-headless';
import { Badge } from 'antd';
import { css, cx } from 'emotion';

interface CommentsBadgeProps {
    commentThreadId: string | null;
    onClicked?: (isOpening: boolean) => void;
    badgeStyle?: CSSProperties;
    hideZeroCount?: boolean;
}

const defaultButtonStyle = css`
    cursor: pointer;
`;

const hideBadgeStyle = css`
    display: none;
`;

export const CommentsBadge = ({
    commentThreadId,
    onClicked,
    badgeStyle,
    hideZeroCount = false,
}: CommentsBadgeProps) => {
    const { initializeComments, openComments, isVisible } = useCommentsPane();

    const { data: boundCommentThread } = useGetCommentsByThreadId(
        commentThreadId ?? null,
        useNotifications()
    );
    const getCommentCount = (): number => {
        if (commentThreadId) {
            if (boundCommentThread) {
                return boundCommentThread.comments.length;
            }

            return 0;
        }

        return 0;
    };
    const commentCount = getCommentCount();
    return (
        <div
            className={
                hideZeroCount && commentCount === 0
                    ? cx([defaultButtonStyle, hideBadgeStyle])
                    : cx(defaultButtonStyle)
            }
            style={badgeStyle}
            onClick={(e) => {
                e.stopPropagation();

                if (onClicked) {
                    onClicked(!isVisible);
                }
                const shouldForceOpen = !!commentThreadId;

                if (shouldForceOpen) {
                    openComments();
                }

                if (commentThreadId) {
                    initializeComments(commentThreadId);
                }
            }}
        >
            <Badge
                count={commentCount}
                color={theme.colors.red}
                size="small"
                offset={[1, 2]}
            >
                <i
                    style={{
                        color: 'black',
                        fontSize: '16px',
                    }}
                    className="fa-regular fa-commenting"
                />
            </Badge>
        </div>
    );
};
