import PropTypes from 'prop-types';
import { Component } from 'react';
import DefaultCellRenderer from './DefaultCellRenderer';
import HeaderCellRenderer from './HeaderCellRenderer';
import { defaultCellDataGetter } from 'components/tables';

// NOTE: Column doesn't render anything
//       it merely allows us to configure tables
class Column extends Component {
    render() {
        return null;
    }
}

Column.propTypes = {
    // Default Width for this column at compile time
    width: PropTypes.number.isRequired,

    // Each Row is an Object, dataKey uniquely identifies a row
    dataKey: PropTypes.string,

    // Returns a cell's data given a dataKey, tightly coupled with the cellRenderer
    // ({ dataKey: string, columnIndex: number, rowIndex: number, data: Array<rowObject> || rowObject })
    cellDataGetter: PropTypes.func,

    // React Component that receives the cellDataGetter's result, plus metadata
    cellRenderer: PropTypes.func,

    // Either 'ASC' or 'DESC'
    // If defined, this the the column which should be sorted on by default
    // NOTE: Only works in Tree Table
    // TODO (Nicholas): Make work in table
    defaultSort: PropTypes.string,

    // If defined, the column is sortable
    // Used in conjunction with cellDataGetter
    // to sort table rows by a column's values
    sortFn: PropTypes.func,

    // Header String
    headerTitle: PropTypes.string.isRequired,

    // Extra data for the header cell renderer
    headerParameters: PropTypes.object,

    // React Component that receives headerTitle data
    headerRenderer: PropTypes.func,
};

Column.defaultProps = {
    width: 110,
    cellRenderer: DefaultCellRenderer,
    headerRenderer: HeaderCellRenderer,
    headerParameters: {},
    cellDataGetter: (props) => defaultCellDataGetter(props),
};

export default Column;
