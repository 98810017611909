import { API_URL } from 'config/constants';
import { SavedFilter } from 'contexts';

interface UpdateGlobalFilterDTO {
    id: string;
    name: string;
    reference_id: string;
    reference_type: string;
    filters_json: { title: string; key: string; values: string[] }[];
}

export async function updateGlobalFilter(filter: SavedFilter): Promise<string> {
    const response = await fetch(`${API_URL}/global-filters/${filter.id}`, {
        method: 'PUT',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            id: filter.id,
            name: filter.name,
            reference_id: filter.reference_id,
            reference_type: filter.reference_type,
            filters_json: filter.filters,
        } as UpdateGlobalFilterDTO),
    });

    if (!response.ok) {
        throw new Error('Failed to update global filter');
    }

    const { data } = await response.json();

    const { id: filterId } = data as { id: string };

    if (filterId !== filter.id) {
        console.error('Filter IDs differed', filter.id, filterId);
        throw new Error('ID of filter changed after saving');
    }

    return filterId;
}
