import React from 'react';
import { css } from 'emotion';

const expandButtonClass = css`
    position: relative;
    overflow: hidden;
    border-radius: 16px;
    background-color: #32a3dd;
    color: #fff;
    box-shadow: 0 1px 3px rgba(0 0 0 / 5%);
    font-size: 13px;
    padding: 7.5px 12px;
    margin-bottom: 0px;
    cursor: pointer;
`;

interface ExpandAndCollapseButtonProps {
    expanded?: boolean;
    toggleExpanded: () => void;
    expandButtonEnable: boolean;
}

const ExpandAndCollapseButton = ({
    expanded,
    expandButtonEnable,
    toggleExpanded,
}: ExpandAndCollapseButtonProps) => {
    return !expandButtonEnable ? (
        <div onClick={() => toggleExpanded()} className={expandButtonClass}>
            {!expanded ? 'Expand All' : 'Collapse All'}{' '}
        </div>
    ) : null;
};

export default ExpandAndCollapseButton;
