import { useCommentsPane } from 'contexts/comments/CommentsContext';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import theme from 'config/theme';
import { CommentsPane } from 'components/comments/CommentsPane';
import React, { CSSProperties } from 'react';
import { CommentsProvider } from 'contexts/comments/CommentsProvider';
import { MentionableDataSource } from 'waypoint-types';

interface CommentsPaneWrapperProps {
    commentThreadId?: string;
    heading?: string;
    userMentionOptions?: MentionableDataSource[];
    commentsPaneWrapperStyle?: CSSProperties;
    splitterTopMargin?: string;
    splitterZIndex?: number | undefined;
    children?: React.ReactNode;
}

export const CommentsPaneWrapper = ({
    commentThreadId,
    heading,
    userMentionOptions,
    commentsPaneWrapperStyle,
    children,
    splitterTopMargin,
    splitterZIndex,
}: CommentsPaneWrapperProps) => {
    return (
        <CommentsProvider
            userMentionOptions={userMentionOptions}
            commentThreadId={commentThreadId}
            heading={heading}
        >
            <ReflexWrapper
                commentsPaneWrapperStyle={commentsPaneWrapperStyle}
                splitterTopMargin={splitterTopMargin}
                splitterZIndex={splitterZIndex}
            >
                {children}
            </ReflexWrapper>
        </CommentsProvider>
    );
};

const ReflexWrapper = ({
    children,
    commentsPaneWrapperStyle,
    splitterTopMargin = '0',
    splitterZIndex,
}: CommentsPaneWrapperProps) => {
    const { isVisible } = useCommentsPane();

    const splitterStyle =
        splitterZIndex !== undefined
            ? {
                  borderColor: theme.colors.blues.outline,
                  marginTop: splitterTopMargin ?? 0,
                  zIndex: splitterZIndex,
              }
            : {
                  borderColor: theme.colors.blues.outline,
                  marginTop: splitterTopMargin ?? 0,
              };

    return (
        <ReflexContainer orientation="vertical">
            <ReflexElement>{children}</ReflexElement>

            {isVisible ? <ReflexSplitter style={splitterStyle} /> : null}
            {isVisible ? (
                <ReflexElement
                    minSize={400}
                    maxSize={1200}
                    size={400}
                    style={{
                        height: splitterTopMargin !== '0' ? '100%' : 'auto',
                        minHeight: '400px',
                        backgroundColor: theme.colors.white,
                        ...commentsPaneWrapperStyle,
                    }}
                >
                    <CommentsPane />
                </ReflexElement>
            ) : null}
        </ReflexContainer>
    );
};
