import { cloneDeep, isEqual, uniq, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Grid from './Grid';
import { ENABLED_GREY, DISABLED_GREY } from './colors';

const optionToYear = ({ label }) => Number(label.split(' ')[1]);

class PeriodPickerMenu extends React.Component {
    static propTypes = {
        type: PropTypes.oneOf(['month', 'quarter']),
        focusedOption: PropTypes.object,
        focusOption: PropTypes.func,
        selectValue: PropTypes.func,
        value: PropTypes.object,
        valueArray: PropTypes.array,
        options: PropTypes.array,
        selectableWhenDisabled: PropTypes.bool,
        firstYear: PropTypes.number,
        lastYear: PropTypes.number,
    };

    constructor(props) {
        super(props);
        // TODO - remove props reference from getInitialState()
        const { valueArray } = props;

        this.state = {
            year: !isEmpty(valueArray) && optionToYear(valueArray[0]),
        };
    }

    getAvailableYears = (nextProps) => {
        const options = nextProps ? nextProps.options : this.props.options;
        const years = uniq(options.map(optionToYear));
        return years;
    };

    selectPreviousYear = () => {
        const { year } = this.state;
        this.setState({ year: year - 1 });
    };

    selectNextYear = () => {
        const { year } = this.state;
        this.setState({ year: year + 1 });
    };

    renderYear = () => {
        const { selectableWhenDisabled, firstYear } = this.props;
        const { year } = this.state;
        const years = this.getAvailableYears();
        const isLaterYear = Math.max(...years) > year;
        const isEarlierYear = Math.min(...years) < year;
        const currentDate = new Date();
        const earlierClicker =
            (selectableWhenDisabled && year > firstYear) || isEarlierYear
                ? this.selectPreviousYear
                : null;
        const laterClicker =
            (selectableWhenDisabled && year < currentDate.getFullYear()) ||
            isLaterYear
                ? this.selectNextYear
                : null;

        const earlierColor =
            isEarlierYear || selectableWhenDisabled
                ? ENABLED_GREY
                : DISABLED_GREY;
        const laterColor =
            isLaterYear || selectableWhenDisabled
                ? ENABLED_GREY
                : DISABLED_GREY;

        return (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '5px',
                }}
            >
                <i
                    className="fa-solid fa-caret-left"
                    onClick={earlierClicker}
                    style={{ color: earlierColor }}
                />
                <span
                    style={{
                        fontSize: '14px',
                        marginRight: '8px',
                        marginLeft: '8px',
                    }}
                >
                    {year}
                </span>
                <i
                    className="fa-solid fa-caret-right"
                    onClick={laterClicker}
                    style={{ color: laterColor }}
                />
            </div>
        );
    };

    renderGrid = () => {
        const {
            props,
            state: { year },
        } = this;
        const { type, options, selectValue, selectableWhenDisabled, value } =
            props;
        const renderableOptions = cloneDeep(options)
            .filter((option) => optionToYear(option) === year)
            .map((option) => {
                if (isEqual(value, option)) {
                    option.selected = true;
                }
                return option;
            });

        // Define grid shape by type of Period Picker
        let rows = 4;
        let columns = 3;
        if (type === 'quarter') {
            rows = 2;
            columns = 2;
        }
        return (
            <div style={{ margin: '3px' }}>
                <Grid
                    data={renderableOptions}
                    rows={rows}
                    columns={columns}
                    onClick={(datum) => {
                        selectValue(datum);
                    }}
                    selectableWhenDisabled={selectableWhenDisabled}
                />
            </div>
        );
    };

    render() {
        return (
            <div>
                {this.renderYear()}
                {this.renderGrid()}
            </div>
        );
    }
}

export default PeriodPickerMenu;
