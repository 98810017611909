import { css } from 'emotion';

export const tableCardDrawerOpen = ({
    isPropertyProfile,
}: {
    isPropertyProfile: boolean;
}) => css`
    transition: width 0.15s;
    @media (min-width: 1850px) {
        width: ${isPropertyProfile
            ? 'calc(100% - 23vw) !important'
            : 'calc(100% - 25vw) !important'};
    }

    @media (max-width: 1850px) {
        width: ${isPropertyProfile
            ? 'calc(100% - 25vw) !important'
            : 'calc(100% - 29vw) !important'};
    }

    @media (max-width: 1650px) {
        width: ${isPropertyProfile
            ? 'calc(100% - 26vw) !important'
            : 'calc(100% - 31vw) !important'};
    }

    @media (max-width: 1400x) {
        width: ${isPropertyProfile
            ? 'calc(100% - 28vw) !important'
            : 'calc(100% - 33vw) !important'};
    }

    @media (max-width: 1280px) {
        width: ${isPropertyProfile
            ? 'calc(100% - 29vw) !important'
            : 'calc(100% - 33vw) !important'};
    }

    @media (max-width: 1135px) {
        width: ${isPropertyProfile
            ? 'calc(100% - 31vw) !important'
            : 'calc(100% - 33vw) !important'};
    }

    @media (max-width: 1025px) {
        width: ${isPropertyProfile
            ? 'calc(100% - 31vw) !important'
            : 'calc(100% - 36vw) !important'};
    }

    @media (max-width: 1000px) {
        width: ${isPropertyProfile
            ? 'calc(100% - 38vw) !important'
            : 'calc(100% - 43vw) !important'};
    }

    @media (max-width: 900px) {
        width: ${isPropertyProfile
            ? 'calc(100% - 43vw) !important'
            : 'calc(100% - 43vw) !important'};
    }

    @media (max-width: 800px) {
        width: ${isPropertyProfile
            ? 'calc(100% - 45vw) !important'
            : 'calc(100% - 50vw) !important'};
    }

    @media (max-height: 650px) {
        height: ${isPropertyProfile ? '55vh !important' : ''};
    }
`;

export const cardStyle = css`
    margin: 20px 24px 0 20px;

    .ant-card-body {
        padding: 10px;
        display: flex;
        flex-direction: column;
        flex: 1;
        max-height: calc(100% - 48px);
        .ant-table-container,
        .ant-table,
        .ant-spin-container,
        .ant-spin-nested-loading {
            height: 100%;
        }
    }

    .ant-card-extra {
        width: 100%;
    }
    .ant-card-head-title {
        overflow: inherit;
        margin-right: 10px;
    }

    @media (max-width: 886px) {
        .ant-card-head-wrapper {
            flex-wrap: wrap;
            padding: 12px 0;
        }

        .ant-card-extra {
            margin-top: 12px;
        }

        .ant-card-head-title {
            > div {
                width: 100%;
                justify-content: space-between;
            }
        }
    }
`;

export const wrapperCardStyle = (
    isPropertyProfile: boolean,
    isDrawerOpen: boolean
) => {
    if (isDrawerOpen) {
        return css({
            position: !isPropertyProfile ? 'fixed' : 'relative',
            height: !isPropertyProfile ? 'calc(100vh - 188px)' : 'calc(70vh)',
            width: !isPropertyProfile ? 'calc(100% - 15px)' : '',
            transition: 'width .15s',
            display: 'flex',
            flexDirection: 'column',
        });
    }

    return css({
        height: !isPropertyProfile ? 'calc(100vh - 188px)' : 'calc(70vh)',
        position: 'relative',
    });
};

export const datePickerStyle = css`
    width: 150px;
    margin-right: 10px;
    .ant-input {
        width: 230px;
        cursor: pointer;
        padding: 5px 10px;
        background-color: #fff;
        border: 1px solid #d9d9d9;
        border-top-width: 1.02px;
        border-radius: 4px;
    }
`;
