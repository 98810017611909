import { API_URL } from 'config/constants';

const getReportSettings = async () => {
    const response = await fetch(`${API_URL}/reports/settings`, {
        credentials: 'include',
    });

    if (!response.ok) {
        throw new Error('FAILED');
    }

    const { data } = await response.json();
    return data;
};

export default getReportSettings;
