import PropTypes from 'prop-types';
import React from 'react';
import GridLayout from 'react-grid-layout';
import { SizeMe } from 'react-sizeme';
import { assign, isUndefined } from 'lodash';
import { compose } from 'redux';
import { withDefaultRGLConfigPP } from 'components/grids';
import { GRAY } from 'config/colors';

const Grid = (props) => {
    const { config, children, style } = props;
    const gridStyle = assign({}, { backgroundColor: GRAY }, style);

    return (
        <SizeMe>
            {({ size }) => {
                const newConfig = assign({}, config, {
                    width: size.width,
                });
                return (
                    <GridLayout {...newConfig} style={gridStyle}>
                        {isUndefined(config.width ? config.width : size.width)
                            ? null
                            : children.filter((child) => !!child)}
                    </GridLayout>
                );
            }}
        </SizeMe>
    );
};

Grid.propTypes = {
    style: PropTypes.object,
    config: PropTypes.object,
    children: PropTypes.arrayOf(PropTypes.object),
};

// NOTE: withDefaultRGLConfigPP injects a default grid config, but can be overwritten by passing a config prop
export default compose(withDefaultRGLConfigPP)(Grid);
