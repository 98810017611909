import { API_URL } from 'config/constants';
import { FeaturePermission } from 'waypoint-types';

export async function getFeaturePermissions(
    clientId: number
): Promise<FeaturePermission[]> {
    const response = await fetch(
        `${API_URL}/permission-groups/${clientId}/feature-permissions`,
        {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        }
    );

    if (!response.ok) {
        throw new Error('Failed to get feature permissions');
    }

    const { data } = await response.json();

    return data as FeaturePermission[];
}
