import dxDataGrid, { ExportingEvent } from 'devextreme/ui/data_grid';
import { Workbook, Worksheet } from 'exceljs';
import { DataGridCell, exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver';
import {
    getRecurringChargeByRentRoll,
    getRecurringChargeRollupByEntityCode,
} from 'components/leases/components/recurring-charge/utils';
import { RecurringCharge } from 'waypoint-types';
import { DASH_DASH } from 'config/constants';
import { formatInTimeZone } from 'date-fns-tz';

interface ExportExcelFromDevExtremeDataGridOptions {
    worksheetName: string;
    filename: string;
}

type MasterDetailTypes = 'recurringCharges' | 'perfOverviewRecurringCharges';
export const RecurringChargesMasterDetail = 'recurringCharges';
export const PerfOverviewRecurringChargesMasterDetail =
    'perfOverviewRecurringCharges';

export type CustomizeCell = (options: {
    gridCell?: DataGridCell;
    excelCell?: any;
}) => void;
interface MasterRowType {
    rowIndex: number;
    data: unknown;
}

interface MasterDetailColumnType {
    key: string;
    name: string;
    dataType?: string;
}

const exportExcelFromDevExtremeDataGrid = async (
    e: ExportingEvent,
    { worksheetName, filename }: ExportExcelFromDevExtremeDataGridOptions,
    customizeCell?: CustomizeCell
) => {
    const workbook: Workbook = new Workbook();
    const worksheet: Worksheet = workbook.addWorksheet(worksheetName);

    await exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true,
        customizeCell,
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });

    saveAs(blob, filename);

    e.cancel = true;
};

export const exportExcelFromDataGridWithMasterDetail = async ({
    worksheetName,
    filename,
    component,
    firstColumName,
    masterDetailType,
    masterDetailData,
    masterDetailHeaderColumns = [],
}: {
    worksheetName: string;
    filename: string;
    component: dxDataGrid | undefined;
    firstColumName?: string;
    masterDetailType?: MasterDetailTypes;
    masterDetailData?: unknown;
    masterDetailHeaderColumns?: MasterDetailColumnType[];
}) => {
    const workbook: Workbook = new Workbook();
    const worksheet: Worksheet = workbook.addWorksheet(worksheetName);

    const masterDetailRows: MasterRowType[] = [];
    const { from, to } = await exportDataGrid({
        component: component,
        worksheet: worksheet,
        topLeftCell: { row: 1, column: 1 },
        customizeCell: function ({ gridCell, excelCell }) {
            if (!masterDetailData) {
                return;
            }
            if (!gridCell) {
                return;
            }
            const isMainGridFirstColumn =
                gridCell?.column?.dataField === firstColumName &&
                gridCell?.rowType === 'data';
            if (isMainGridFirstColumn) {
                masterDetailRows.push({
                    rowIndex: excelCell.fullAddress.row + 1,
                    data: getDataForMasterDetail(
                        masterDetailType ?? '',
                        gridCell,
                        masterDetailData
                    ),
                });
            }
        },
    });
    if (!masterDetailHeaderColumns) {
        throw new Error('Master detail header columns are required');
    }
    const insertRow = (index: number, offset: number, outlineLevel: number) => {
        const currentIndex = index + offset;
        const row = worksheet.insertRow(currentIndex, [], 'n');
        row.outlineLevel = outlineLevel;
        return row;
    };

    const insertMasterDetailData = (masterDetailRows: MasterRowType[]) => {
        masterDetailRows.forEach((masterDetailRow: MasterRowType) => {
            // Insert master detail at the end of the row
            const columnIndex =
                (from?.column ?? 0) +
                (to?.column ?? 0) -
                masterDetailHeaderColumns.length;

            const row = insertRow(masterDetailRow.rowIndex, offset++, 1);
            // Insert the header columns, based on the param passed
            masterDetailHeaderColumns.forEach((column, currentColumnIndex) => {
                Object.assign(row.getCell(columnIndex + currentColumnIndex), {
                    value: column.name,
                    fill: {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'F2F2F2' },
                    },
                    font: { bold: true },
                });
            });

            if (!isMasterDetailTypeObject(masterDetailRow.data)) {
                throw new Error(
                    'error in parsing fetched value to masterDetail type'
                );
            }

            // Insert a new row for each line in master detail
            masterDetailRow.data.forEach((detailRow: any) => {
                const row = insertRow(masterDetailRow.rowIndex, offset++, 1);
                // Match master detail column with header column
                masterDetailHeaderColumns.forEach(
                    (column, currentColumnIndex) => {
                        const rowCell = row.getCell(
                            columnIndex + currentColumnIndex
                        );
                        Object.assign(rowCell, {
                            value: decorateMasterDetailValue(
                                detailRow[column.key],
                                column.dataType
                            ),
                        });
                        if (column.dataType === 'currency') {
                            rowCell.numFmt = '$#,##0.00';
                        }
                    }
                );
            });
        });
    };
    let offset = 0;
    insertMasterDetailData(masterDetailRows);

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });

    saveAs(blob, filename);
};

const getDataForMasterDetail = (
    masterDetailType: string,
    { data: dataRow }: DataGridCell,
    masterDetailData?: unknown
) => {
    switch (masterDetailType) {
        case RecurringChargesMasterDetail:
            return getRecurringChargeByRentRoll(
                masterDetailData as RecurringCharge[],
                dataRow
            );
        case PerfOverviewRecurringChargesMasterDetail:
            return getRecurringChargeRollupByEntityCode(
                masterDetailData as RecurringCharge[],
                dataRow.entity_code !== ''
                    ? [dataRow.entity_code]
                    : dataRow.entity_codes,
                dataRow.occupied_sq_ft,
                dataRow.total_units
            );
        default:
            return masterDetailData;
    }
};

const isMasterDetailTypeObject = (value: unknown): value is RecurringCharge[] =>
    !!value && typeof value === 'object';

const decorateMasterDetailValue = (
    value: string | number,
    dataType?: string
) => {
    switch (dataType) {
        case 'date':
            return value
                ? formatInTimeZone(new Date(value), 'UTC', 'MM/dd/yyyy')
                : DASH_DASH;
        default:
            return value;
    }
};

export default exportExcelFromDevExtremeDataGrid;
