import { API_URL } from 'config/constants';

/**
 *
 * @param {Object} params
 * @param {array} params.entityCodes - Entity codes
 * @param {string} params.account_mapping_code
 * @param {string} params.display_type
 * @param {string} params.periodicity - monthly, quarterly, yearly
 * @param {string} params.start_date - ISO Date (YYYY-MM-DD)
 * @param {string} params.end_date - ISO Date (YYYY-MM-DD)
 * @param {Object} params.selectedDataLevel - Ownership %
 */
const getBalanceSheetOverviewOvertime = async (params) => {
    const {
        entityCodes,
        selectedDataLevel,
        account_mapping_code,
        account_mapping_name,
        display_type,
        periodicity,
        start_date,
        end_date,
        download,
    } = params;

    const url = download
        ? `${API_URL}/balance-sheet-overview/overtime/download`
        : `${API_URL}/balance-sheet-overview/overtime`;

    const headers = {
        'Content-Type': 'application/json',
    };

    const response = await fetch(url, {
        method: 'POST',
        headers,
        credentials: 'include',
        body: JSON.stringify({
            entity_codes: entityCodes,
            selected_data_level: selectedDataLevel,
            account_mapping_code,
            account_mapping_name,
            start_date,
            end_date,
            display_type,
            periodicity,
        }),
    });

    if (!response.ok) {
        throw new Error('FAILED');
    }
    if (download) {
        return response;
    }

    const { data } = await response.json();
    return data;
};

export default getBalanceSheetOverviewOvertime;
