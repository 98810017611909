import { Moment } from 'moment';

export interface AccountMapping {
    name: string;
    account_mapping_code: string;
    account_mapping_name: string;
    parent_account_name: string | null;
    parent_account_percentage: number | null;
    parent_account_value: number | null;
    parent_variance_is_good: boolean | null;
    property_rank: number | null;
    property_rank_value: number | null;
    variance_is_good: boolean;
    children: AccountMappingChildren[];
}
export interface AccountMappingChildren {
    name: string;
    account_mapping_code: string;
    account_mapping_name: string;
    property_rank_percentage: number;
    property_rank_value: number | null;
    variance_is_good: boolean | null;
}

export interface RankingData {
    attribute?: string | number;
    occupied_sq_ft: number;
    property_id: string | null;
    property_name: string;
    rentable_sq_ft: number;
    total_units: number;
    occupied_units: number;
    account_mapping: AccountMapping;
}

export interface RankingMetaData {
    account_mapping_code: string;
    basis_type: string;
    display_type: string;
    entity_count: number;
    period_end: string;
    period_start: string;
    request: any;
    route: any;
    value_type: string;
}

export interface AccountMappingSelection {
    code: string;
    name: string;
}

export interface RankingCardSelections {
    accountMapping: AccountMappingSelection;
    period: Moment[];
    valueType: string;
    displayType: string;
    accountGraphCode: string;
}

export enum DisplayType {
    PerOccupiedSqft = 'value_per_occupied_sqft',
    PerRentableSqft = 'value_per_rentable_sqft',
    PerOccupiedUnit = 'value_per_occupied_unit',
    PerUnit = 'value_per_unit',
    Gross = 'gross',
}

export interface RankingAPISelections {
    entity_codes: string[];
    account_mapping_code: string;
    display_type: string;
    value_type: string;
    start_date: string;
    end_date: string;
    account_graph_code: string;
}

export interface RankingTooltipListItems {
    label: string;
    value: string | JSX.Element;
}
