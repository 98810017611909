import PropTypes from 'prop-types';
import React from 'react';
import SubMenu from 'components/settings/SubMenu';
import AdminSubMenu from 'components/settings/AdminSubMenu';
import styles from 'components/style/Subheader.module.css';
import Subheader from 'components/style/Subheader';

class SubHeader extends React.Component {
    static propTypes = {
        // TODO (Axel): Create Task in subNavigation
        title: PropTypes.string,
        admin: PropTypes.bool,
    };

    render() {
        const { title, admin } = this.props;

        return (
            <header>
                <Subheader className={styles.userManagementTitle}>
                    {title}
                </Subheader>
                {admin ? <AdminSubMenu /> : <SubMenu />}
            </header>
        );
    }
}

SubHeader.propTypes = {
    title: PropTypes.string,
    admin: PropTypes.bool,
};

export default SubHeader;
