import { useContext, useEffect, useMemo } from 'react';
import { css } from 'emotion';
import {
    useGetAttributes,
    useGetSelectedFilteredEntityCodes,
} from 'waypoint-hooks';
import { GRAY } from 'config/colors';
import {
    AppFeaturePermissions,
    EntityAttributesContext,
    EntityAttributesContextType,
} from 'contexts';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';
import { AttributesEditableCard } from '../card/AttributesEditableCard';
import { Entity } from 'waypoint-types';
import { Skeleton } from 'antd';

const pageStyle = css`
    display: block;
    background-color: ${GRAY};
    width: 100%;
`;

const AttributesDashboard = (): JSX.Element => {
    const entityCodes: string[] = useGetSelectedFilteredEntityCodes();
    const entityContext = useContext<EntityAttributesContextType | null>(
        EntityAttributesContext,
    );

    const {
        data: attributesDataV2,
        isError,
        mutate,
        isLoading,
    } = useGetAttributes(entityCodes);

    useEffect(() => {
        entityContext?.mutateProviderAttributes();
    }, [attributesDataV2]);

    const attributesV2Entities = useMemo(() => {
        if (!attributesDataV2) {
            return [];
        }

        return attributesDataV2?.entities.filter(
            (entity: Partial<Entity>) =>
                typeof entity.entityCode === 'string' &&
                entityCodes.includes(entity.entityCode),
        );
    }, [attributesDataV2, entityCodes]);

    if (isLoading) {
        return (
            <Skeleton
                paragraph={{
                    rows: 10,
                }}
            />
        );
    }

    return (
        <PermissionedWrapper
            featureKey={AppFeaturePermissions.Attributes}
            showDisabledView={true}
        >
            <div className={pageStyle}>
                <AttributesEditableCard
                    key="attributes-card"
                    attributes={attributesDataV2?.attributeDefinitions ?? []}
                    entities={attributesV2Entities ?? []}
                    mutateAttributes={mutate}
                    error={isError ? 'Error' : null}
                    entityCodes={entityCodes}
                    isLoading={!attributesDataV2 && !isError}
                />
            </div>
        </PermissionedWrapper>
    );
};

export default AttributesDashboard;
