import PropTypes from 'prop-types';
import React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';

export default function FilterModalPopover(props) {
    const popover = (
        <Popover
            style={{
                height: '62px',
                width: '285px',
                fontSize: '14px',
                color: '#4D4D4D',
            }}
            id="popover-no-client-data"
        >
            No client supplied data - please send us a note via "Got Feedback"
            button.
        </Popover>
    );
    return (
        <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="bottom"
            overlay={popover}
        >
            {props.children}
        </OverlayTrigger>
    );
}

FilterModalPopover.propTypes = {
    children: PropTypes.element,
};
