import React, { useEffect, useState } from 'react';
import { DatePicker, Select } from 'waypoint-react';
import { ReportWidgetSettingsBuilderProps } from 'waypoint-types';
import ReportWidgetSettingsModal from 'components/reports/report-widget/ReportWidgetSettingsModal';
import { renewalRangeOptions } from 'components/leasingPlan/LeasingPlanUtils';
import moment from 'moment';
import { Checkbox } from 'antd';

const LeasingPlanSettingsBuilder = ({
    entityCodes,
    isModalOpen,
    setIsModalOpen,
    onSaveWidgetSettings,
    widgetType,
    currentSettings,
}: ReportWidgetSettingsBuilderProps): JSX.Element => {
    const [planYear, setPlanYear] = useState<number | null>(
        currentSettings?.planYear ?? null,
    );
    const [endYearOffset, setEndYearOffset] = useState<number>(
        currentSettings?.endYearOffset ?? 0,
    );
    const [isYearValid, setIsYearValid] = useState(true);

    const [planSummaryOnly, setPlanSummaryOnly] = useState<boolean>(
        currentSettings?.planSummaryOnly ?? false,
    );

    const [hideNonRenewingUnits, setHideNonRenewingUnits] = useState<boolean>(
        currentSettings?.hideNonRenewingUnits ?? false,
    );

    useEffect(() => {
        if (planSummaryOnly) {
            setHideNonRenewingUnits(false);
        }
    }, [planSummaryOnly]);

    const buildReportWidgetSettingsInputs = () => {
        return {
            planYear,
            endYearOffset,
            planSummaryOnly,
            hideNonRenewingUnits,
        };
    };

    const onSave = () => {
        if (!planYear) {
            setIsYearValid(false);
            return;
        }
        onSaveWidgetSettings(buildReportWidgetSettingsInputs());
    };

    return (
        <ReportWidgetSettingsModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            settingsJsonBuilder={buildReportWidgetSettingsInputs}
            onSaveWidgetSettings={onSave}
            widgetType={widgetType}
            width={425}
        >
            <div style={{ marginBottom: '15px', marginTop: '25px' }}>
                <div style={{ fontWeight: 'bold' }}>Plan Year</div>
                <DatePicker
                    data-testid="leasing-plan-widget-date-picker"
                    disabledDate={(date) =>
                        date.year() < new Date().getFullYear()
                    }
                    picker="year"
                    status={isYearValid ? undefined : 'error'}
                    onChange={(value) => {
                        if (value) {
                            setPlanYear(value.year());
                        }
                    }}
                    value={
                        planYear
                            ? moment(new Date().setFullYear(planYear))
                            : undefined
                    }
                    placeholder="Select year"
                />
                {!isYearValid && (
                    <div style={{ color: 'red', marginTop: '8px' }}>
                        You need to select a Year.
                    </div>
                )}
            </div>
            <div style={{ marginBottom: '20px' }}>
                <div style={{ fontWeight: 'bold' }}>Renewal Activity For</div>
                <Select
                    style={{ width: '100px' }}
                    options={renewalRangeOptions}
                    value={endYearOffset}
                    onChange={(value: number) => setEndYearOffset(value)}
                />
            </div>
            <div style={{ fontWeight: 'bold' }}>Export Options</div>
            <div style={{ marginBottom: '10px', marginTop: '5px' }}>
                <Checkbox
                    checked={planSummaryOnly}
                    onChange={(ev) => setPlanSummaryOnly(ev.target.checked)}
                >
                    <span style={{ fontWeight: 'normal' }}>
                        Plan Summary Only
                    </span>
                </Checkbox>
            </div>
            <div style={{ marginBottom: '25px' }}>
                <Checkbox
                    checked={hideNonRenewingUnits}
                    onChange={(ev) =>
                        setHideNonRenewingUnits(ev.target.checked)
                    }
                    disabled={planSummaryOnly}
                >
                    <span style={{ fontWeight: 'normal' }}>
                        Renewal Activity - Hide Non-Renewing Units
                    </span>
                </Checkbox>
            </div>
        </ReportWidgetSettingsModal>
    );
};

export default LeasingPlanSettingsBuilder;
