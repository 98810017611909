import React from 'react';
import { Select } from 'antd';
import { SelectOptionType } from 'components/app/global-filter-drawer/types/globalFilterTypes';
import {
    KPISortByOptions,
    KPISortDirections,
    PropertyDetailsValuesType,
} from 'components/leases/constants';
import { css } from 'emotion';
import ComparisonKpiSortBySelect from './ComparisonKpiSortBySelect';

interface ComparisonKpiSelectsProps {
    leftAxis: keyof PropertyDetailsValuesType;
    rightAxis: keyof PropertyDetailsValuesType;
    setLeftAxis: (value: keyof PropertyDetailsValuesType) => void;
    setRightAxis: (value: keyof PropertyDetailsValuesType) => void;
    setSortAxis: (value: KPISortByOptions) => void;
    setSortDirection: (value: KPISortDirections) => void;
    sortAxis: KPISortByOptions;
    sortDirection: KPISortDirections;

    options: SelectOptionType[];
}

const kpiSelectFlexClass = css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    @media screen and (max-width: 1380px) {
        flex-wrap: wrap;
        gap: 10px;
    }
`;

const ComparisonKpiSelects = ({
    options,
    leftAxis,
    rightAxis,
    setLeftAxis,
    setRightAxis,
    setSortAxis,
    setSortDirection,
    sortAxis,
    sortDirection,
}: ComparisonKpiSelectsProps) => {
    return (
        <div className={kpiSelectFlexClass}>
            <div>
                <span>Left Axis</span>
                <Select
                    style={{ width: 320, marginLeft: 6 }}
                    options={options}
                    value={leftAxis}
                    onChange={(item) => setLeftAxis(item)}
                    data-testid="comparison-kpi-chart-left-axis-select"
                />
            </div>
            <div>
                <ComparisonKpiSortBySelect
                    sortAxis={sortAxis}
                    sortDirection={sortDirection}
                    leftAxis={leftAxis}
                    rightAxis={rightAxis}
                    setSortAxis={setSortAxis}
                    setSortDirection={setSortDirection}
                    data-testid="comparison-kpi-chart-sortby-select"
                />
            </div>

            <div>
                <span>Right Axis</span>
                <Select
                    style={{ width: 320, marginLeft: 6 }}
                    options={options}
                    value={rightAxis}
                    onChange={(item) => setRightAxis(item)}
                    data-testid="comparison-kpi-chart-right-axis-select"
                />
            </div>
        </div>
    );
};

export default ComparisonKpiSelects;
