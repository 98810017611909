import React, { useCallback, useEffect, useRef, useState } from 'react';
import { css } from 'emotion';
import { PivotGridCell } from 'devextreme/excel_exporter';
import PivotGridDataSource, {
    Field,
} from 'devextreme/ui/pivot_grid/data_source';
import {
    Cell,
    CellClickEvent,
    CellPreparedEvent,
    ContextMenuPreparingEvent,
    ExportingEvent,
} from 'devextreme/ui/pivot_grid';

import PivotGrid, {
    Export,
    FieldChooser,
    FieldPanel,
    HeaderFilter,
    PivotGridRef,
} from 'devextreme-react/pivot-grid';

import CrosstabQuickSettings from './components/CrosstabQuickSettings';
import { CrosstabOptions, CrosstabPeriod, DataRecord } from './CrosstabTypes';
import { getDefaultCrosstabOptions, resetAccountFieldPlacement } from './utils';
import {
    CROSSTAB_ACCOUNT_NAME_FIELDS,
    CROSSTAB_DEFAULT_PERIOD_SELECTION,
    CROSSTAB_DEFAULT_PRIMARY_MODE,
    CROSSTAB_DEFAULT_ROW_SELECTION,
    CROSSTAB_DEFAULT_SECONDARY_MODE,
    CROSSTAB_PRIMARY_MODE,
    CROSSTAB_ROOT_ACCOUNT_DATA_FIELD,
    CROSSTAB_SECONDARY_MODE,
    CROSSTAB_SUMMARY_DISPLAY_MODES,
    FIELD_ATTRIBUTE,
    FIELD_PERIOD,
    FIELD_PROPERTY,
    MEASURE_FIELD_VARIANCE_$,
} from './CrosstabConstants';
import { CROSSTAB_PERIOD_TOGGLE_FIELDS } from './CrosstabPeriods';
import {
    CustomizeCell,
    exportPivotGridComponent,
    getConditionalAppearance,
    getCssStyles,
    getExcelCellFormat,
    isTotalCell,
} from './CrosstabExcel';
import { CrosstabAccountNode } from './accounts';
import { RowSelection } from './CrosstabRowSelection';
import { Dictionary } from 'ts-essentials';
import PivotGridDataFieldButton, {
    PivotGridDataFieldButtonOption,
} from './components/CrosstabDataFieldButton';
import {
    useGetClientModes,
    useGetGroupableAttributes,
    useGridConfigSettings,
} from 'waypoint-hooks';
import {
    SavedConfigEditor,
    SavedConfigManageDropdown,
    SavedGridConfigSelect,
} from 'components/saved-configurations';
import { SavedConfigFilterType, SavedConfiguration } from 'waypoint-types';
import { FilterReferenceTypes } from 'contexts';
import { CrosstabTitle } from './CrosstabTitle';
import { Moment } from 'moment';

const heightOfStaticComponents = (() => {
    const globalFilterHeight = 50;

    const outerCardPadding = 25;
    const innerCardPadding = 24;
    const cardBorderWidth = 1;

    const verticalCardPadding =
        (outerCardPadding + innerCardPadding + cardBorderWidth) * 2;

    const optionControlsHeight = 104;
    const optionControlsMarginBottom = 10;

    const optionControlsCombinedHeight =
        optionControlsHeight + optionControlsMarginBottom;

    return (
        globalFilterHeight + verticalCardPadding + optionControlsCombinedHeight
    );
})();

/**
 * As of 21.2, DevExtreme has deprecated JS functions for height calculation.
 * @see https://js.devexpress.com/Documentation/ApiReference/Common/Utils/Errors_and_Warnings/#W0017
 */
const pivotGridWithDynamicHeight = css`
    height: calc(100vh - ${heightOfStaticComponents}px);
`;

const section = css`
    flex-direction: row;
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 16px;
    @media (max-width: 1650px) {
        padding-bottom: 16px;
    }
`;
const configuration = css`
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    @media (max-width: 1900px) {
        flex-direction: column;
        margin-bottom: 15px;
    }
    @media (max-width: 1020px) {
        flex-direction: row;
    }
`;
const configFlexbox = css`
    display: flex;
    flex-direction: row;
    @media (max-width: 1020px) {
        flex-direction: column;
    }
`;

const spanButtonSeparators = css`
    &:first-child {
        margin-left: 0px;
    }
    margin-left: 36px;
    @media (max-width: 1225px) {
        margin-left: 10px;
    }
`;

const notBorderRadiusWrapper = css`
    .ant-btn {
        border-radius: 6px !important;
    }
`;

const CrosstabPeriods: PivotGridDataFieldButtonOption[] = [
    {
        text: 'Year > Quarter > Month',
        value: CrosstabPeriod.YearQuarterMonth,
    },
    { text: 'Year > Month', value: CrosstabPeriod.YearMonth },
    { text: 'Quarter > Month', value: CrosstabPeriod.QuarterMonth },
    { text: 'Month', value: CrosstabPeriod.Month },
];

const CrosstabPivotGrid = ({
    accountNamesByMappingCode,
    dataSource,
    rootAccount,
    resetState,
    attributeSelection,
    setAttributeSelection,
    selectedAttribute,
    setIsFieldVisible,
    isFieldVisible,
    modeSelection,
    setModeSelection,
    isLoading,
    setIsOpenSettings,
    isOpenSettings,
    periodRange,
    setPeriodRange,
}: {
    accountNamesByMappingCode: Dictionary<string>;
    dataSource: PivotGridDataSource;
    rootAccount: CrosstabAccountNode;
    resetState: () => void;
    attributeSelection: string | null;
    setAttributeSelection: (value: string) => void;
    selectedAttribute: string | null;
    isFieldVisible: Dictionary<boolean>;
    setIsFieldVisible: (value: Dictionary<boolean>) => void;
    modeSelection: string[];
    setModeSelection: (value: string[]) => void;
    isLoading: boolean;
    setIsOpenSettings: (value: boolean) => void;
    isOpenSettings: boolean;
    periodRange: [Moment, Moment] | null;
    setPeriodRange: (value: [Moment, Moment] | null) => void;
}): JSX.Element => {
    const pivotGrid = useRef<PivotGridRef | null>(null);
    const groupableAttributes = useGetGroupableAttributes();

    const [options, setOptions] = useState<CrosstabOptions>(
        getDefaultCrosstabOptions(),
    );

    const [expandedRowPath, setExpandedRowPath] = useState<
        (string | number | Date)[] | undefined
    >(undefined);
    const [hasFilterChanged, setHasFilterChanged] = useState<boolean>(false);

    const [periodSelection, setPeriodSelection] = useState<CrosstabPeriod>(
        CROSSTAB_DEFAULT_PERIOD_SELECTION,
    );

    const [rowSelection, setRowSelection] = useState<RowSelection>(
        CROSSTAB_DEFAULT_ROW_SELECTION,
    );

    const [isFieldSelected, setIsFieldSelected] = useState<Dictionary<string>>({
        [CROSSTAB_PRIMARY_MODE]: '',
        [CROSSTAB_SECONDARY_MODE]: '',
        [MEASURE_FIELD_VARIANCE_$]: '',
        [FIELD_ATTRIBUTE]: '',
        [FIELD_PERIOD]: '',
        _variance_percent: '',
    });

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const {
        selectedConfiguration,
        setSelectedConfiguration,
        defaultConfig,
        setDefaultConfig,
        setGridConfig,
        setLocalConfig,
        saveConfigDisabled,
        configKey,
        savedConfigurationsData,
        resetSelectedConfiguration,
        onDeleteConfig,
        onSaveConfig,
        onUpdateConfig,
        isAdmin,
        isEditorOpen,
        setIsEditorOpen,
        existingConfigNames,
    } = useGridConfigSettings(SavedConfigFilterType.Crosstab);

    const { data: clientModes } = useGetClientModes();

    useEffect(() => {
        if (!dataSource) {
            console.error('dataSource not ready after changing period');
            return;
        }

        const fieldToggles = CROSSTAB_PERIOD_TOGGLE_FIELDS.get(periodSelection);
        if (!fieldToggles) {
            console.error(
                'Period fields to toggle was not present for selection:',
                periodSelection,
            );
            return;
        }

        const [visibleFields, invisibleFields] = fieldToggles;

        let groupIndex = 0;

        for (const fieldId of visibleFields) {
            dataSource.field(fieldId, {
                groupIndex: groupIndex++,
                visible: true,
            });
        }

        for (const fieldId of invisibleFields) {
            dataSource.field(fieldId, {
                groupIndex: -1,
                visible: false,
            });
        }

        dataSource.load();
    }, [dataSource, periodSelection]);

    useEffect(() => {
        setLocalConfig({
            periodSelection,
            isFieldSelected,
            isFieldVisible,
            attributeSelection,
            rowSelection,
            options,
            expandedRowPath,
            hasFilterChanged,
        });
    }, [
        periodSelection,
        isFieldSelected,
        isFieldVisible,
        attributeSelection,
        rowSelection,
        options,
        expandedRowPath,
        hasFilterChanged,
    ]);

    const applyVisibleFieldSettingsToPivotGrid = () => {
        for (const field of Object.keys(isFieldVisible)) {
            dataSource.field(field, {
                visible: isFieldVisible[field],
            });
        }
    };

    useEffect(() => {
        applyVisibleFieldSettingsToPivotGrid();
    }, [isFieldVisible]);

    const applyFieldSelectionFormattingToPivotGrid = () => {
        const fieldsToUpdate = [
            CROSSTAB_PRIMARY_MODE,
            CROSSTAB_SECONDARY_MODE,
            MEASURE_FIELD_VARIANCE_$,
        ];
        for (const field of fieldsToUpdate) {
            const newModeOptionValue = isFieldSelected[field];
            updateFormatAndFieldNameOfDataField(
                field,
                newModeOptionValue,
                false,
            );
        }
    };

    useEffect(() => {
        applyFieldSelectionFormattingToPivotGrid();
    }, [isFieldSelected]);

    // these two config fix functions are to support existing saved configs created before
    // dynamic mode selections were implemeneted by replacing column fields accordingly
    const fixOldLocalConfig = (config: Dictionary<string | boolean>) => {
        return Object.entries(config).reduce(
            (dict, entry) => {
                if (entry[0] === 'ACTUALS') {
                    dict[CROSSTAB_PRIMARY_MODE] = entry[1];
                    return dict;
                }
                if (entry[0] === 'BUDGET') {
                    dict[CROSSTAB_SECONDARY_MODE] = entry[1];
                    return dict;
                }
                dict[entry[0]] = entry[1];
                return dict;
            },
            {} as typeof config,
        );
    };

    const fixOldConfig = (selectedConfiguration: SavedConfiguration) => {
        const gridConfig = selectedConfiguration?.filters_json?.grid_config;

        if (gridConfig && Array.isArray(gridConfig?.fields)) {
            const adjustedGridConfigFields = gridConfig.fields.map((field) => {
                if (field.dataField === 'ACTUALS') {
                    field.dataField = CROSSTAB_PRIMARY_MODE;
                    return field;
                }
                if (field.dataField === 'BUDGET') {
                    field.dataField = CROSSTAB_SECONDARY_MODE;
                    return field;
                }
                return field;
            });
            selectedConfiguration.filters_json.grid_config.fields =
                adjustedGridConfigFields;
        }

        const localConfig = selectedConfiguration?.filters_json?.local_config;

        if (!localConfig) {
            return selectedConfiguration;
        }

        if (localConfig['isFieldSelected']) {
            localConfig['isFieldSelected'] = fixOldLocalConfig(
                localConfig['isFieldSelected'],
            );
        }

        if (localConfig['isFieldVisible']) {
            localConfig['isFieldVisible'] = fixOldLocalConfig(
                localConfig['isFieldVisible'],
            );
        }

        selectedConfiguration.filters_json.local_config = localConfig;

        return selectedConfiguration;
    };

    useEffect(() => {
        onSetOrResetSavedOrDefaultConfiguration();
        if (!selectedConfiguration) {
            resetOptions();
            return;
        }
        const adjustedConfig = fixOldConfig(selectedConfiguration);
        if (adjustedConfig?.filters_json?.grid_config) {
            setGridConfig(adjustedConfig.filters_json.grid_config);
        }
        if (adjustedConfig?.filters_json?.local_config) {
            const config = adjustedConfig.filters_json.local_config;
            config['periodSelection'] !== undefined
                ? setPeriodSelection(config['periodSelection'])
                : setPeriodSelection(CROSSTAB_DEFAULT_PERIOD_SELECTION);

            config['isFieldSelected'] !== undefined
                ? setIsFieldSelected(config['isFieldSelected'])
                : setIsFieldSelected({
                      [CROSSTAB_PRIMARY_MODE]: '',
                      [CROSSTAB_SECONDARY_MODE]: '',
                      [MEASURE_FIELD_VARIANCE_$]: '',
                      [FIELD_ATTRIBUTE]: '',
                      [FIELD_PERIOD]: '',
                      _variance_percent: '',
                  });

            config['isFieldVisible'] !== undefined
                ? setIsFieldVisible(config['isFieldVisible'])
                : setIsFieldVisible({
                      [CROSSTAB_PRIMARY_MODE]: true,
                      [CROSSTAB_SECONDARY_MODE]: true,
                      [MEASURE_FIELD_VARIANCE_$]: true,
                      [FIELD_ATTRIBUTE]: false,
                      [FIELD_PERIOD]: true,
                      _variance_percent: true,
                  });

            config['attributeSelection'] !== undefined
                ? setAttributeSelection(config['attributeSelection'])
                : setAttributeAndResetOptions(FIELD_ATTRIBUTE, '');

            config['options'] !== undefined
                ? setOptions(config['options'])
                : setOptions(getDefaultCrosstabOptions());

            config['rowSelection'] !== undefined
                ? setRowSelection(config['rowSelection'])
                : setRowSelection(CROSSTAB_DEFAULT_ROW_SELECTION);

            setLocalConfig(config);
            return;
        }
    }, [selectedConfiguration, configKey]);

    const updateGridConfigOnMenuOpen = () => {
        if (!isMenuOpen) {
            return;
        }

        const state = dataSource.state();
        setGridConfig(state);
    };

    useEffect(() => {
        updateGridConfigOnMenuOpen();
    }, [isMenuOpen]);

    const getAccountFieldsForRowSelection = (
        rowSelection: RowSelection,
    ): string[] => {
        switch (rowSelection) {
            case RowSelection.FullDetail: {
                return CROSSTAB_ACCOUNT_NAME_FIELDS;
            }
            case RowSelection.SummaryColumns:
            case RowSelection.SummaryRows: {
                return [CROSSTAB_ROOT_ACCOUNT_DATA_FIELD];
            }
        }
    };

    useEffect(() => {
        const pivotGridInstance = pivotGrid.current?.instance;
        if (!pivotGridInstance) {
            console.error(
                'PivotGrid instance not available to update row selection',
            );
            return;
        }

        if (!dataSource) {
            console.error(
                'dataSource not available when attempting to update row selection',
            );
            return;
        }

        const accountFields = getAccountFieldsForRowSelection(
            rowSelection,
        ) as (keyof DataRecord)[];

        const updateFieldLocations = async () => {
            const crosstabFieldLocations: Dictionary<
                { columns?: string[]; rows?: string[] },
                RowSelection
            > = {
                [RowSelection.FullDetail]: {
                    columns: [FIELD_ATTRIBUTE, FIELD_PROPERTY, 'Period'],
                    rows: accountFields,
                },
                [RowSelection.SummaryRows]: {
                    columns: ['Period'],
                    rows: accountFields
                        ? [...accountFields, FIELD_ATTRIBUTE, FIELD_PROPERTY]
                        : [FIELD_ATTRIBUTE, FIELD_PROPERTY],
                },
                [RowSelection.SummaryColumns]: {
                    columns: accountFields,
                    rows: [FIELD_ATTRIBUTE, FIELD_PROPERTY, 'Period'],
                },
            };

            const fieldLocations = crosstabFieldLocations[rowSelection] ?? {
                columns: [],
                rows: [],
            };

            const config = selectedConfiguration?.filters_json?.grid_config;
            const savedConfigFields = config?.fields;
            if (savedConfigFields) {
                savedConfigFields.forEach((field: { [x: string]: any }) => {
                    dataSource.field(field.dataField, field);
                });

                const state = dataSource.state();
                dataSource.state({
                    ...state,
                    rowExpandedPaths: config['rowExpandedPaths'] ?? [],
                    columnExpandedPaths: config['columnExpandedPaths'] ?? [],
                });
            } else {
                // now move fields
                fieldLocations.columns?.forEach((fieldName, index) =>
                    dataSource.field(fieldName, {
                        area: 'column',
                        areaIndex: index,
                        filterType: undefined,
                        filterValues: undefined,
                        sortOrder: undefined,
                    }),
                );
                fieldLocations.rows?.forEach((fieldName, index) =>
                    dataSource.field(fieldName, {
                        area: 'row',
                        areaIndex: index,
                        filterType: undefined,
                        filterValues: undefined,
                        sortOrder: undefined,
                    }),
                );
            }
            applyFieldSelectionFormattingToPivotGrid();
            await dataSource.load();
            setGridConfig(dataSource.state());
        };

        const runFieldChanges = async () => {
            try {
                // pop loading screen, prevent the UI from updating until endUpdate()
                setDefaultConfig(null);
                pivotGridInstance().beginUpdate();

                await resetAccountFieldPlacement(dataSource, accountFields);

                await updateFieldLocations();
            } catch (e) {
                console.error(
                    'CrosstabPivotGrid failed to update rowSelection',
                    e,
                );
            } finally {
                // hide the loading screen after all fields have been updated
                pivotGridInstance().endUpdate();
            }
        };

        runFieldChanges();
    }, [dataSource, rowSelection]);

    /**
     * Checks the cell passes validation for a data row cell before invoking the
     * callback.
     * @param cell cell to inspect
     * @param area area of the
     * @param onSuccess callback to execute upon validation passing for the cell
     */
    const whenCellIsLeafAccount = useCallback(
        (cell: Cell, area: string, onSuccess: () => void) => {
            if (!cell || !cell.path) {
                return;
            }

            const isRowDataCell = area === 'row' && cell.type === 'D';
            const isClickedCellExpanded = cell.expanded ?? false;

            // if a row is expanded already, we should not prevent collapsing
            if (!isRowDataCell || isClickedCellExpanded) {
                return;
            }

            const cellPathIsStringArray = cell.path.every(
                (x) => typeof x === 'string',
            );

            if (!cellPathIsStringArray) {
                return;
            }

            const cellPath = cell.path as string[];

            const rowFields: Field[] = dataSource
                .getAreaFields('row', false)
                // fields can end up hidden, and cause issues with the path
                .filter((f) => f.visible ?? true)
                // sort based on the index of the field in the current (row) area
                .sort((a, b) => (a.areaIndex ?? 0) - (b.areaIndex ?? 0));

            const accountNamePath = cellPath.filter((_, index) => {
                const fieldName = rowFields[index]?.dataField;
                return (
                    fieldName &&
                    CROSSTAB_ACCOUNT_NAME_FIELDS.includes(fieldName)
                );
            });

            const accountCodePath = accountNamePath.map(
                (accountName) => accountNamesByMappingCode[accountName],
            );

            if (rootAccount.hasLeaf(accountCodePath)) {
                // finally, callback to handle once all conditions are checked
                onSuccess();
            }
        },
        [accountNamesByMappingCode, dataSource, rootAccount],
    );

    const onCellClick = useCallback(
        (e: CellClickEvent) => {
            if (!e.area || !e.cell) {
                return;
            }
            if (e.area === 'row' && e.cell && !e.cell.expanded) {
                // devx return false when row is expanded
                setExpandedRowPath(e.cell.path);
            }

            if (rowSelection === RowSelection.SummaryRows) {
                return;
            }

            // prevent click expanding row when the cell is a child leaf account
            whenCellIsLeafAccount(e.cell, e.area, () => {
                e.cancel = true;
            });
        },
        [rowSelection, whenCellIsLeafAccount],
    );

    const onCellPrepared = useCallback(
        (e: CellPreparedEvent) => {
            if (!e.cell || !e.cellElement || !e.area) {
                return;
            }

            // apply styling to total cells
            if (isTotalCell(e.cell)) {
                const appearance = getConditionalAppearance(e.cell);
                Object.assign(e.cellElement.style, getCssStyles(appearance));
            }

            if (rowSelection === RowSelection.SummaryRows) {
                return;
            }

            // hide expand icon when the cell is a leaf account
            whenCellIsLeafAccount(e.cell, e.area, () => {
                const iconContainerElement = e.cellElement?.querySelector(
                    '.dx-expand-icon-container',
                );

                if (iconContainerElement) {
                    iconContainerElement.innerHTML = '';
                }
            });
        },
        [rowSelection, whenCellIsLeafAccount],
    );

    const onExporting = useCallback(async (e: ExportingEvent) => {
        const customizeCell: CustomizeCell = ({ pivotCell, excelCell }) => {
            if (isTotalCell(pivotCell as PivotGridCell)) {
                const appearance = getConditionalAppearance(
                    pivotCell as PivotGridCell,
                );
                if (appearance) {
                    Object.assign(excelCell, getExcelCellFormat(appearance));
                }
            }

            const borderStyle = {
                style: 'thin',
                color: { argb: 'FF7E7E7E' },
            };
            excelCell.border = {
                bottom: borderStyle,
                left: borderStyle,
                right: borderStyle,
                top: borderStyle,
            };
        };

        await exportPivotGridComponent(e.component, customizeCell);
        e.cancel = true;
    }, []);

    const onContextMenuPreparing = useCallback(
        (e: ContextMenuPreparingEvent) => {
            if (!e.field || !e.items) {
                return;
            }

            const createContextModeMenuItem = (
                text: string,
                fieldOptions: Partial<Field>,
                selected?: boolean,
            ) => ({
                text,
                selected,
                onItemClick: () => {
                    if (!selectedField) {
                        return;
                    }

                    const fieldId =
                        selectedField.dataField ?? selectedField.caption;

                    if (!fieldId) {
                        console.warn(
                            'Cannot hide field. Field is not identifiable',
                            e.field,
                        );
                        return;
                    }

                    dataSource.field(fieldId, fieldOptions);

                    dataSource.load();
                },
            });

            const selectedField = e.field;

            if (!selectedField.groupName || selectedField.groupIndex === 0) {
                e.items.push(
                    createContextModeMenuItem('Hide field', {
                        visible: false,
                    }),
                );
            }

            const targetDataFieldIsActualOrBudget =
                selectedField.dataField &&
                [
                    CROSSTAB_PRIMARY_MODE,
                    CROSSTAB_SECONDARY_MODE,
                    MEASURE_FIELD_VARIANCE_$,
                ].includes(selectedField.dataField);

            if (targetDataFieldIsActualOrBudget) {
                for (const mode of CROSSTAB_SUMMARY_DISPLAY_MODES) {
                    e.items.push(
                        createContextModeMenuItem(mode.text, {
                            caption: e.field.caption,
                            summaryDisplayMode: mode.value,
                            format:
                                mode.value === undefined ||
                                mode.value === 'absoluteVariation'
                                    ? 'currency'
                                    : 'percent',
                        }),
                    );
                }
            }
        },
        [dataSource],
    );

    const onSetOrResetSavedOrDefaultConfiguration = (): void => {
        if (!dataSource) {
            return;
        }

        if (
            selectedConfiguration &&
            selectedConfiguration?.filters_json?.grid_config
        ) {
            dataSource.state(selectedConfiguration.filters_json.grid_config);
            dataSource.reload();
            return;
        }

        dataSource.state(defaultConfig?.grid_config ?? null);
        dataSource.reload();
    };

    const applySettings = useCallback(
        (updatedOptions: CrosstabOptions) => {
            setOptions({
                ...options,
                ...updatedOptions,
            });
        },
        [options],
    );

    const fullStateReset = useCallback(() => {
        const pivotGridInstance = pivotGrid.current?.instance;

        if (pivotGridInstance) {
            pivotGridInstance().beginUpdate();
        }

        resetState();

        setPeriodSelection(CROSSTAB_DEFAULT_PERIOD_SELECTION);
        setRowSelection(CROSSTAB_DEFAULT_ROW_SELECTION);

        if (pivotGridInstance) {
            pivotGridInstance().endUpdate();
        }
    }, [resetState]);

    const resetOptions = useCallback(() => {
        applySettings(getDefaultCrosstabOptions());
        setAttributeAndResetOptions(FIELD_ATTRIBUTE, '');

        setIsFieldVisible({
            [CROSSTAB_PRIMARY_MODE]: true,
            [CROSSTAB_SECONDARY_MODE]: true,
            [MEASURE_FIELD_VARIANCE_$]: true,
            [FIELD_ATTRIBUTE]: false,
            [FIELD_PERIOD]: true,
            _variance_percent: true,
        });
        setIsFieldSelected({
            [CROSSTAB_PRIMARY_MODE]: '',
            [CROSSTAB_SECONDARY_MODE]: '',
            [MEASURE_FIELD_VARIANCE_$]: '',
            [FIELD_ATTRIBUTE]: '',
            [FIELD_PERIOD]: '',
            _variance_percent: '',
        });
        setPeriodSelection(CROSSTAB_DEFAULT_PERIOD_SELECTION);
    }, [applySettings]);

    const setAttributeAndResetOptions = (fieldName: string, value: string) => {
        setAttributeSelection(value);
    };

    const setPeriodSelectionHandler = (fieldName: string, value: string) => {
        setPeriodSelection(value as CrosstabPeriod);
    };

    const togglePeriod = (fields: Dictionary<boolean, string>) => {
        const periodIsVisible = dataSource.field('Period')?.visible ?? true;
        setIsFieldVisible(fields);
        dataSource.field('Period', {
            visible: !periodIsVisible,
        });
        dataSource.load();
    };

    const menuAttributesOptions = groupableAttributes?.map((attribute) => ({
        text: attribute.title,
        value: attribute.dataIndex,
    }));

    const updateFormatAndFieldNameOfDataField = (
        fieldName: string,
        newModeOptionValue: string,
        loadDataSource = true,
    ) => {
        dataSource.field(fieldName, {
            summaryDisplayMode: newModeOptionValue,
            format: {
                type:
                    newModeOptionValue &&
                    newModeOptionValue.startsWith('percent')
                        ? 'percent'
                        : 'currency',
                precision: 2,
            },
        });

        loadDataSource && dataSource.load();
    };

    const summaryDisplayModeMenuOptions: PivotGridDataFieldButtonOption[] =
        CROSSTAB_SUMMARY_DISPLAY_MODES.map((mode) => {
            return {
                text: mode.text,
                value: mode.value as string,
            };
        });

    const crosstabDefaultConfigs = [
        {
            label: 'Account Detail',
            key: RowSelection.FullDetail,
        },
        {
            label: 'Account Summary - Columns',
            key: RowSelection.SummaryColumns,
        },
        {
            label: 'Account Summary - Rows',
            key: RowSelection.SummaryRows,
        },
    ];

    const getModeSelectionDisplayName = (index: number) => {
        if (!modeSelection[index]) {
            const crosstabDefaults = [
                CROSSTAB_DEFAULT_PRIMARY_MODE,
                CROSSTAB_DEFAULT_SECONDARY_MODE,
            ];
            return crosstabDefaults[index] ?? '';
        }

        return (
            clientModes?.find((mode) => mode.code === modeSelection[index])
                ?.display_name ??
            `${modeSelection[index].substring(1, modeSelection[index].length)}}`
        );
    };

    return (
        <>
            <section className={section}>
                <div style={{ alignSelf: 'flex-start', flex: '1' }}>
                    <div style={{ flexDirection: 'row' }}>
                        <div className={configuration}>
                            <div className={configFlexbox}>
                                <CrosstabTitle
                                    modeSelection={modeSelection}
                                    setModeSelection={setModeSelection}
                                    dataSource={dataSource}
                                    rootAccount={rootAccount}
                                    isLoading={isLoading}
                                    setIsOpenSettings={setIsOpenSettings}
                                    isOpenSettings={isOpenSettings}
                                    periodRange={periodRange}
                                    setPeriodRange={setPeriodRange}
                                />
                                <SavedGridConfigSelect
                                    savedConfigurationsData={
                                        savedConfigurationsData
                                    }
                                    selectedConfiguration={
                                        selectedConfiguration
                                    }
                                    setSelectedConfiguration={
                                        setSelectedConfiguration
                                    }
                                    crosstabDefaultConfigs={
                                        crosstabDefaultConfigs
                                    }
                                    crosstabRowSelection={rowSelection}
                                    setCrosstabRowSelection={setRowSelection}
                                    resetOptions={resetOptions}
                                />
                                <SavedConfigManageDropdown
                                    setIsMenuOpen={setIsMenuOpen}
                                    onUpdateConfig={onUpdateConfig}
                                    onDeleteConfig={onDeleteConfig}
                                    fullStateReset={fullStateReset}
                                    resetSelectedConfiguration={
                                        resetSelectedConfiguration
                                    }
                                    hasUserAccess={
                                        !isAdmin &&
                                        selectedConfiguration?.reference_type !==
                                            FilterReferenceTypes.USER
                                    }
                                    allowDeleteOnly={saveConfigDisabled}
                                    defaultConfigSelected={
                                        !selectedConfiguration
                                    }
                                    setIsEditorOpen={setIsEditorOpen}
                                />
                                {isEditorOpen && (
                                    <SavedConfigEditor
                                        handleClose={() =>
                                            setIsEditorOpen(false)
                                        }
                                        onSave={onSaveConfig}
                                        isAdmin={isAdmin}
                                        existingConfigNames={
                                            existingConfigNames
                                        }
                                    />
                                )}

                                <span className={spanButtonSeparators} />
                            </div>
                            <div className={configFlexbox}>
                                <PivotGridDataFieldButton
                                    buttonName={
                                        selectedAttribute ?? 'Attribute'
                                    }
                                    fieldName={FIELD_ATTRIBUTE}
                                    dataSource={dataSource}
                                    isFieldVisible={isFieldVisible}
                                    isFieldSelected={isFieldSelected}
                                    setIsFieldVisible={setIsFieldVisible}
                                    setIsFieldSelected={setIsFieldSelected}
                                    options={menuAttributesOptions}
                                    clickHandler={setAttributeAndResetOptions}
                                />

                                <PivotGridDataFieldButton
                                    buttonName={
                                        CrosstabPeriods?.find(
                                            (e) => e.value === periodSelection,
                                        )?.text ?? ''
                                    }
                                    fieldName={FIELD_PERIOD}
                                    dataSource={dataSource}
                                    isFieldVisible={isFieldVisible}
                                    isFieldSelected={isFieldSelected}
                                    setIsFieldVisible={togglePeriod}
                                    setIsFieldSelected={setIsFieldSelected}
                                    options={CrosstabPeriods}
                                    clickHandler={setPeriodSelectionHandler}
                                />

                                <PivotGridDataFieldButton
                                    buttonName={getModeSelectionDisplayName(0)}
                                    fieldName={CROSSTAB_PRIMARY_MODE}
                                    dataSource={dataSource}
                                    isFieldVisible={isFieldVisible}
                                    isFieldSelected={isFieldSelected}
                                    setIsFieldVisible={setIsFieldVisible}
                                    setIsFieldSelected={setIsFieldSelected}
                                    options={summaryDisplayModeMenuOptions}
                                    clickHandler={
                                        updateFormatAndFieldNameOfDataField
                                    }
                                />

                                <PivotGridDataFieldButton
                                    buttonName={getModeSelectionDisplayName(1)}
                                    fieldName={CROSSTAB_SECONDARY_MODE}
                                    dataSource={dataSource}
                                    isFieldVisible={isFieldVisible}
                                    isFieldSelected={isFieldSelected}
                                    setIsFieldVisible={setIsFieldVisible}
                                    setIsFieldSelected={setIsFieldSelected}
                                    options={summaryDisplayModeMenuOptions}
                                    clickHandler={
                                        updateFormatAndFieldNameOfDataField
                                    }
                                />

                                <PivotGridDataFieldButton
                                    buttonName="Variance $"
                                    fieldName={MEASURE_FIELD_VARIANCE_$}
                                    dataSource={dataSource}
                                    isFieldVisible={isFieldVisible}
                                    isFieldSelected={isFieldSelected}
                                    setIsFieldVisible={setIsFieldVisible}
                                    setIsFieldSelected={setIsFieldSelected}
                                    options={summaryDisplayModeMenuOptions}
                                    clickHandler={
                                        updateFormatAndFieldNameOfDataField
                                    }
                                />

                                <div className={notBorderRadiusWrapper}>
                                    <PivotGridDataFieldButton
                                        buttonName="Variance %"
                                        fieldName="_variance_percent"
                                        dataSource={dataSource}
                                        isFieldVisible={isFieldVisible}
                                        isFieldSelected={isFieldSelected}
                                        setIsFieldVisible={setIsFieldVisible}
                                        setIsFieldSelected={setIsFieldSelected}
                                        options={summaryDisplayModeMenuOptions}
                                        clickHandler={
                                            updateFormatAndFieldNameOfDataField
                                        }
                                    />
                                </div>

                                <div
                                    className={configFlexbox}
                                    style={{ marginLeft: '15px' }}
                                >
                                    <CrosstabQuickSettings
                                        options={options}
                                        onApplyOptions={applySettings}
                                        rowSelection={rowSelection}
                                        data-testid="crosstab-settings"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <PivotGrid
                key={`${selectedConfiguration?.id ?? ''}_${configKey ?? ''}`}
                allowExpandAll={false}
                allowFiltering={true}
                allowSorting={true}
                allowSortingBySummary={true}
                className={pivotGridWithDynamicHeight}
                data-testid="crosstab-pivot-grid"
                dataFieldArea={'column'}
                dataSource={dataSource}
                onCellClick={onCellClick}
                onCellPrepared={onCellPrepared}
                onContextMenuPreparing={onContextMenuPreparing}
                onExporting={onExporting}
                ref={pivotGrid}
                rowHeaderLayout={
                    options.showInOutlineForm ? 'standard' : 'tree'
                }
                showBorders={true}
                showColumnGrandTotals={
                    rowSelection !== RowSelection.SummaryColumns
                }
                showColumnTotals={!options.hideColumnSubtotals}
                showRowGrandTotals={
                    rowSelection === RowSelection.SummaryColumns
                }
                showRowTotals={true}
                showTotalsPrior={
                    options.showTotalsAtEndOfGroup ? 'none' : 'both'
                }
                wordWrapEnabled={false}
                scrolling={{ mode: 'virtual' }}
                stateStoring={{
                    enabled: true,
                    type: 'custom',
                    savingTimeout: 100,
                    customSave(state) {
                        const hasFilterChanged =
                            dataSource.getAreaFields('filter', false).length >
                            0;
                        if (hasFilterChanged) {
                            setHasFilterChanged(true);
                        }
                    },
                }}
            >
                <FieldPanel
                    showDataFields={false}
                    showFilterFields={true}
                    showRowFields={rowSelection !== RowSelection.FullDetail}
                    allowFieldDragging={true}
                    visible={true}
                />

                <FieldChooser height={500} enabled={false} />

                <HeaderFilter
                    allowSearch={true}
                    showRelevantValues={true}
                    width={300}
                    height={400}
                />

                <Export enabled={true} />
            </PivotGrid>
        </>
    );
};

export default CrosstabPivotGrid;
