import theme from 'config/theme';
import { EntityProfileCard } from 'waypoint-types';

export const prioritiesOptions: {
    value: string;
    label: string;
    color: string;
}[] = [
    { value: 'open', label: 'Open', color: `${theme.colors.grays.medium}` },
    {
        value: 'inProgress',
        label: 'In Progress',
        color: `${theme.colors.blues.antBlue}`,
    },
    { value: 'complete', label: 'Complete', color: `${theme.colors.green}` },
    { value: 'atRisk', label: 'At Risk', color: `${theme.colors.red}` },
];

export const getYearProgress = (items: EntityProfileCard[]) => {
    const itemsCompleted = items.filter(
        (item) => item.metadata && item.metadata[0].value === 'complete'
    ).length;
    const itemsToComplete = items.filter(
        (item) => item.metadata && item.metadata[0].value !== 'complete'
    ).length;

    return {
        itemsCompleted,
        itemsToComplete,
        allItems: items.length,
    };
};
