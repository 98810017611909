import React, { useEffect } from 'react';
import { Dictionary } from 'ts-essentials';
import { css } from 'emotion';
import { Select } from 'antd';
import { BudgetUploadFileButton } from './BudgetUploadFileButton';
import { DatePicker } from 'waypoint-react';
import { getPropertyOptions } from 'waypoint-utils/entity';
import {
    BudgetUploadEntry,
    PropertyType,
    SelectOptions,
    SourcePropertyCodes,
} from 'waypoint-types';
import { connect, RootStateOrAny } from 'react-redux';
import { selectProperties } from 'state/properties/selectors';
import moment from 'moment';
import { useGetSourcePropertyCode } from 'waypoint-hooks/data-access/useGetSourcePropertyCode';
import { container } from '../BudgetUploadUtils';
import { stringSort } from 'utils/tables/sorters';

const { Option } = Select;

const title = css`
    font-weight: bold;
    margin-bottom: 8px;
`;

const buttonContainer = css`
    margin-top: auto;
    align-self: flex-end;
`;

interface BudgetUploadSelectProps {
    entityCodes: string[];
    properties: Dictionary<PropertyType>;
    selectedEntityCode: string | null;
    setSelectedEntityCode: (value: string) => void;
    selectedSourceCode: string | null;
    setSelectedSourceCode: (value: string | null) => void;
    selectedBudgetYear: string | null;
    setSelectedBudgetYear: (value: string) => void;
    setCurrentUploadBudget: (value: BudgetUploadEntry[] | null) => void;
    setCurrentUploadBudgetId: (value: string | null) => void;
}

const BudgetUploadSelect = ({
    entityCodes,
    properties,
    selectedEntityCode,
    setSelectedEntityCode,
    selectedSourceCode,
    setSelectedSourceCode,
    selectedBudgetYear,
    setSelectedBudgetYear,
    setCurrentUploadBudget,
    setCurrentUploadBudgetId,
}: BudgetUploadSelectProps) => {
    const propertyOptions = getPropertyOptions(properties, entityCodes);

    const useResetBudgetUploaded = () => {
        useEffect(() => {
            setCurrentUploadBudget(null);
            setCurrentUploadBudgetId(null);
        }, [selectedEntityCode, selectedSourceCode, selectedBudgetYear]);
    };

    useResetBudgetUploaded();

    const { data: sourceCodes, isLoading: isSourceCodeLoading } =
        useGetSourcePropertyCode(selectedEntityCode);

    return (
        <>
            <div className={container}>
                <div key="selectedEntityCode">
                    <div className={title}>Property</div>
                    <Select
                        style={{ width: '100%' }}
                        onChange={(value) => {
                            setSelectedEntityCode(value);
                            setSelectedSourceCode(null);
                        }}
                        placeholder="Select Property"
                        value={selectedEntityCode}
                    >
                        {propertyOptions.map((option: SelectOptions) => (
                            <Option key={option.value} value={option.value}>
                                {option.label}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div key="proposed_budget">
                    <div className={title}>Upload Type</div>
                    <Select
                        value="proposed_budget"
                        style={{ width: '100%' }}
                        disabled={true}
                    >
                        <Option key="proposed_budget" value="proposed_budget">
                            Proposed Budget
                        </Option>
                    </Select>
                </div>
                <div key="kardin">
                    <div className={title}>Source</div>
                    <Select
                        value="kardin"
                        style={{ width: '100%' }}
                        disabled={true}
                    >
                        <Option key="kardin" value="kardin">
                            Kardin
                        </Option>
                    </Select>
                </div>
                <div key="selectedSourceCode">
                    <div className={title}>Code</div>
                    <Select
                        style={{ width: '100%' }}
                        onChange={(value) => setSelectedSourceCode(value)}
                        placeholder="Select Code"
                        disabled={isSourceCodeLoading}
                        value={selectedSourceCode}
                        defaultValue={selectedSourceCode}
                    >
                        {(sourceCodes || [])
                            .sort(
                                (
                                    a: SourcePropertyCodes,
                                    b: SourcePropertyCodes
                                ) =>
                                    stringSort(
                                        b.sourceSystemCode,
                                        a.sourceSystemCode
                                    )
                            )
                            .map((option) => (
                                <Option
                                    key={option.sourceSystemCode}
                                    value={option.sourceSystemCode}
                                >
                                    {option.sourceSystemCode}
                                </Option>
                            ))}
                    </Select>
                </div>
                <div key="budgetYear">
                    <div className={title}>Budget Year</div>
                    <DatePicker
                        picker="year"
                        allowClear={false}
                        placeholder="Select year"
                        format="YYYY"
                        style={{ width: '100%' }}
                        onChange={(value) => {
                            if (value) {
                                setSelectedBudgetYear(value.format('YYYY'));
                            }
                        }}
                        value={
                            selectedBudgetYear
                                ? moment(selectedBudgetYear, 'YYYY')
                                : null
                        }
                    />
                </div>
                <div className={buttonContainer}>
                    <BudgetUploadFileButton
                        selectedEntityCode={selectedEntityCode || ''}
                        selectedSourceCode={selectedSourceCode || ''}
                        selectedBudgetYear={selectedBudgetYear || ''}
                        setCurrentUploadBudget={setCurrentUploadBudget}
                        setCurrentUploadBudgetId={setCurrentUploadBudgetId}
                    />
                </div>
            </div>
        </>
    );
};

const mapState = (s: RootStateOrAny) => {
    const properties = selectProperties(s);
    return {
        properties,
    };
};

export default connect(mapState)(BudgetUploadSelect);
