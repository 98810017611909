import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Row } from 'react-bootstrap';
import SubHeader from './SubHeader';
import sharedStyles from './shared.module.css';
import styles from './Settings.module.css';

const UserSettings = (props) => {
    return (
        <Grid className={styles.grid}>
            <Row className={sharedStyles.row}>
                <SubHeader title="My Profile" />
            </Row>
            {props.children}
        </Grid>
    );
};

UserSettings.propTypes = {
    children: PropTypes.node,
};

export default UserSettings;
