import { Table } from 'antd';
import { currencyRenderer, percentageRenderer } from 'utils/tables/renderers';
import { DelinquencyData } from 'waypoint-types';
import {
    ColumnDescriptor,
    ExportableGridSummaryFormatter,
    convertColumnsToAntd,
    getColumnTotalCells,
    headerClass,
    rowClass,
} from './GridExportConversionUtils';
import { Dictionary } from 'ts-essentials';
import { safeDivision } from 'waypoint-utils';

interface DelinquencyDataWithPercentOfTotal extends DelinquencyData {
    percent_of_total: number;
}

export const currencyFormatter = (value: number) => {
    // for issue in which negative numbers are rendered with a slight offset for some reason
    const margin = value < 0 ? '-4px' : '0px';
    return (
        <span style={{ marginRight: margin }}>{currencyRenderer(value)}</span>
    );
};

export const agedReceivablesSummaryBaseColumns = [
    {
        title: 'Charge',
        dataIndex: 'charge_code',
        key: 'charge_code',
        dataType: 'string',
        width: '100px',
        render: (value: string, rowData: DelinquencyData) => {
            const chargeDisplayName = rowData.charge_display_name
                ? ` (${rowData.charge_display_name})`
                : '';
            return `${value.toUpperCase()}${chargeDisplayName}`;
        },
    },
    {
        title: 'Total',
        dataIndex: 'total',
        key: 'balance',
        align: 'right',
        dataType: 'number',
        render: currencyFormatter,
    },
    {
        title: '% of Total',
        dataIndex: 'percent_of_total',
        key: 'percent_of_total',
        align: 'center',
        dataType: 'number',
        render: percentageRenderer,
    },
    {
        title: 'Current',
        dataIndex: 'balance',
        key: 'balance',
        dataType: 'number',
        align: 'right',
        render: currencyFormatter,
    },
    {
        title: 'Prepaid',
        dataIndex: 'prepaid_balance',
        key: 'prepaid_balance',
        dataType: 'number',
        align: 'right',
        render: currencyFormatter,
    },
    {
        title: '1-30 Days',
        dataIndex: 'thirty_days_due_balance',
        key: 'thirty_days_due_balance',
        dataType: 'number',
        align: 'right',
        render: currencyFormatter,
    },
    {
        title: '31-60 Days',
        dataIndex: 'sixty_days_due_balance',
        key: 'sixty_days_due_balance',
        dataType: 'number',
        align: 'right',
        render: currencyFormatter,
    },
    {
        title: '61-90 Days',
        dataIndex: 'ninety_days_due_balance',
        key: 'ninety_days_due_balance',
        dataType: 'number',
        align: 'right',
        render: currencyFormatter,
    },
    {
        title: '> 90 Days',
        dataIndex: 'ninety_more_days_due_balance',
        key: 'ninety_more_days_due_balance',
        dataType: 'number',
        align: 'right',
        render: currencyFormatter,
    },
];

const agedReceivablesSummaryFormatters: Dictionary<ExportableGridSummaryFormatter> =
    {
        total: {
            summaryType: 'sum',
            render: currencyFormatter,
        },
        percent_of_total: {
            summaryType: 'sum',
            render: percentageRenderer,
        },
        balance: {
            summaryType: 'sum',
            render: currencyFormatter,
        },
        prepaid_balance: {
            summaryType: 'sum',
            render: currencyFormatter,
        },
        thirty_days_due_balance: {
            summaryType: 'sum',
            render: currencyFormatter,
        },
        sixty_days_due_balance: {
            summaryType: 'sum',
            render: currencyFormatter,
        },
        ninety_days_due_balance: {
            summaryType: 'sum',
            render: currencyFormatter,
        },
        ninety_more_days_due_balance: {
            summaryType: 'sum',
            render: currencyFormatter,
        },
    };

const agedReceivablesSummaryWithPercentages = (data: DelinquencyData[]) => {
    const totalCharges = data.reduce((amount, item) => amount + item.total, 0);

    return data.reduce((arList, d) => {
        const dataWithPctOfTotal = {
            ...d,
            percent_of_total: safeDivision(d.total, totalCharges),
        };
        arList.push(dataWithPctOfTotal);
        return arList;
    }, [] as DelinquencyDataWithPercentOfTotal[]);
};

export const AgedReceivablesSummaryExportableGrid = ({
    data,
}: {
    data: DelinquencyData[];
}) => {
    const { gridColumns } = convertColumnsToAntd(
        agedReceivablesSummaryBaseColumns as ColumnDescriptor[],
        false,
        null
    );

    const summaryData = agedReceivablesSummaryWithPercentages(data);

    return (
        <Table
            style={{ marginBottom: '25px', marginTop: '10px' }}
            dataSource={summaryData}
            size="small"
            columns={gridColumns}
            pagination={false}
            bordered={true}
            className={headerClass}
            rowClassName={rowClass}
            summary={() =>
                getColumnTotalCells(
                    gridColumns,
                    data,
                    agedReceivablesSummaryFormatters
                )
            }
        />
    );
};
