import {
    isEmpty,
    isNull,
    isUndefined,
    flatten,
    partition,
    debounce,
} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Shortcuts } from 'react-shortcuts';
import { MultiGrid, AutoSizer } from 'react-virtualized';
import DefaultCellRenderer from './DefaultCellRenderer';
import HeaderCellRenderer from './HeaderCellRenderer';
import { SCROLLBAR_OFFSET } from './constants';

class Table extends React.Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        children: PropTypes.any.isRequired,
        fixedRowCount: PropTypes.number,
        fixedColumnCount: PropTypes.number,
        onRowSelect: PropTypes.func,
        styleTopLeftGrid: PropTypes.object,
        styleBottomLeftGrid: PropTypes.object,
        styleTopRightGrid: PropTypes.object,
        styleBottomRightGrid: PropTypes.object,
    };

    state = {
        sortedData: [],
        sortColumn: null,
        // NOTE: Although sortColumn and selectedColumn are both set on sort
        //       the user can then select a different sort column,
        //       so keep them separate
        selectedRow: 0,
        selectedColumn: null,
        resizeHandler: null,
    };

    componentWillMount() {
        /* NOTE: We must keep a reference to this resize handler
                 in order to remove it later. Still can't throttle
                 class methods effectively.
        */
        const throttledUpdates = debounce(this.forceUpdate, 1000, {
            leading: true,
            trailing: true,
        });

        // TODO (Nicholas): Respect a sort order here
        this.setState({
            sortedData: this.props.data,
            resizeHandler: throttledUpdates,
        });

        if (window) {
            window.addEventListener('resize', throttledUpdates);
        }
    }

    componentDidMount() {
        const { preSelectedRow } = this.props;
        if (preSelectedRow) {
            this.setState({ selectedRow: preSelectedRow });
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            sortedData: this.updateRowOrder({ data: nextProps.data }),
        });
        if (this.props.data.length !== nextProps.data.length) {
            this.setState({
                selectedRow: 0,
            });
        }
        if (this.props.preSelectedRow !== nextProps.preSelectedRow) {
            this.setState({ selectedRow: nextProps.preSelectedRow });
        }
    }

    componentWillUnmount() {
        if (window) {
            window.removeEventListener('resize', this.state.resizeHandler);
        }
    }

    getColumns = () => {
        // Ensures that a single child Column is represeted as an array
        const { children } = this.props;
        // This step ensures we can conditionally render columns
        // For example, {isAdmin && <Column />} pushes a falsey
        // value into children array, which Table attempts
        // to render. This creates an error.
        return Array.isArray(children) ? children.filter((c) => c) : [children];
    };

    forceUpdate = () => {
        if (this._grid) {
            this._grid.recomputeGridSize();
        }
    };

    calculateFillerIndex = () => {
        const columns = this.getColumns();
        const columnCount = columns.length;

        return columnCount - columns.filter((c) => c.props.rightAlign).length;
    };

    sortColumnsByAlignment = () => {
        const columns = this.getColumns();
        return flatten(partition(columns, (c) => !c.props.rightAlign));
    };

    findColumnByIndex = (columnIndex) => {
        const fillerColumnIndex = this.calculateFillerIndex();
        const columns = this.sortColumnsByAlignment();
        const fillerAdjustment = columnIndex < fillerColumnIndex ? 0 : -1;
        return columns[columnIndex + fillerAdjustment];
    };

    cellRenderer = ({ key, rowIndex, columnIndex, style }) => {
        // TODO (Nicholas): allow multiple header renderers to enable total field
        let content = '';
        const { selectedRow, selectedColumn } = this.state;

        const fillerColumnIndex = this.calculateFillerIndex();
        if (columnIndex !== fillerColumnIndex) {
            // Render a data driven column
            const {
                dataKey,
                sortFn,
                cellDataGetter,
                headerTitle,
                headerParameters, // For configuring the column’s header cell
                headerRenderer,
                cellRenderer,
                onClick,
                ...rest
            } = this.findColumnByIndex(columnIndex).props;
            if (rowIndex === 0) {
                const { sortColumn, sortDirection } = this.state;
                content = React.createElement(headerRenderer, {
                    rowIndex,
                    columnIndex,
                    sortDirection,
                    // for minor styling of header text. Anything more, utilize custom headder cell
                    style: headerParameters.style || {},
                    active: sortColumn === columnIndex,
                    cellData: { headerTitle, headerParameters },
                    sortable: !!sortFn,
                    onClick: (direction) => {
                        // NOTE: Can't use closure scope selected row
                        const { sortedData, selectedRow: currentSelectedRow } =
                            this.state;
                        const selectedItem = sortedData[currentSelectedRow - 1];
                        const newSort = this.updateRowOrder({
                            sortDirection: direction,
                            columnIndex,
                        });
                        const newIndex = newSort.indexOf(selectedItem);

                        this.setState({
                            sortedData: newSort,
                            sortColumn: columnIndex,
                            sortDirection: direction,
                            selectedRow: newIndex + 1,
                            selectedColumn: columnIndex,
                        });
                    },
                });
            } else {
                // TODO (Nicholas): add selectedRow and selectedColumn booleans here
                // NOTE: RowIndex - 1 allows headers to exist outside of data
                // [ ] Updated adding multiple headers, change - 1 to - headerCount
                const { sortedData: collection } = this.state;
                const collectionIndex = rowIndex - 1;
                const cellProps = cellDataGetter({
                    dataKey,
                    collection,
                    columnIndex,
                    datum: collection[collectionIndex],
                    rowIndex: collectionIndex,
                    selectedRow: selectedRow === rowIndex,
                    selectedColumn: selectedColumn === columnIndex,
                    ...rest,
                });
                content = React.createElement(
                    cellRenderer,
                    Object.assign({}, cellProps, {
                        onClick: () => {
                            this.setState({
                                selectedRow: rowIndex,
                                selectedColumn: columnIndex,
                            });
                            if (onClick) {
                                // Allows a parent component to
                                // react to updates
                                // Example: variance report account selection
                                //          pops open a selected line item column
                                onClick(cellProps.datum);
                            }
                        },
                    })
                );
            }
        } else {
            // Render the filler column (columnCount > children)
            // [ ] Updated adding multiple headers, change - 1 to - headerCount
            const component =
                rowIndex === 0 ? HeaderCellRenderer : DefaultCellRenderer;
            content = React.createElement(component, {
                columnIndex,
                cellData: '',
                rowIndex: rowIndex - 1,
                selectedRow: selectedRow === rowIndex,
                onClick: () => {
                    this.setState({
                        selectedRow: rowIndex,
                    });
                },
            });
        }

        return (
            <div style={style} key={key}>
                {content}
            </div>
        );
    };

    selectNextRow = () => {
        const { onRowSelect } = this.props;
        const { sortedData, selectedRow: oldSelectedRow } = this.state;
        const selectedRow =
            oldSelectedRow !== sortedData.length ? oldSelectedRow + 1 : 1; // TODO (Nicholas): when adding multiple header columns, vet === 1

        this.setState({ selectedRow });

        if (onRowSelect) {
            onRowSelect(sortedData[selectedRow - 1]);
        }
    };

    selectPreviousRow = () => {
        const { onRowSelect } = this.props;
        const { sortedData, selectedRow: oldSelectedRow } = this.state;
        // TODO (Nicholas): when adding multiple header columns, vet === 1
        const selectedRow =
            oldSelectedRow === 1 ? sortedData.length : oldSelectedRow - 1;

        this.setState({ selectedRow });

        if (onRowSelect) {
            onRowSelect(sortedData[selectedRow - 1]);
        }
    };

    shortcutHandler = (action) => {
        switch (action) {
            case 'NEXT_ROW':
                this.selectNextRow();
                break;

            case 'PREVIOUS_ROW':
                this.selectPreviousRow();
                break;

            default:
        }
    };

    // NOTE: Can't think of a better way to have calculateColumnWidth know
    //       the size set by autoSizer, but generally creating functions
    //       on each render is bad
    calculateColumnWidth = (extraWidth) => {
        const remainingWidth = extraWidth > 0 ? extraWidth : 0;
        const columns = this.getColumns();
        const leftColumns = columns.filter((c) => !c.props.rightAlign);
        const rightColumns = columns.filter((c) => c.props.rightAlign);
        const sortedColumns = leftColumns
            .concat({ props: { width: remainingWidth } })
            .concat(rightColumns);

        return function ({ index }) {
            return sortedColumns[index].props.width;
        };
    };

    updateRowOrder = ({ data, columnIndex, sortDirection }) => {
        // Note: sometimes (props updating) we want to choose the data or the column index,
        //       otherwise we default to using component state
        const {
            sortColumn,
            sortedData,
            sortDirection: stateSortDirection,
        } = this.state;
        const columnToSort = isUndefined(columnIndex)
            ? sortColumn
            : columnIndex; // avoid 0 is falsy bugs
        const sortDir = sortDirection || stateSortDirection;
        const column = this.findColumnByIndex(columnToSort);
        const { sortFn, dataKey, cellDataGetter } = column.props;
        const dataToSort = data || sortedData;
        if (isEmpty(dataToSort)) {
            return [];
        }
        if (
            isUndefined(columnToSort) ||
            isNull(columnToSort) ||
            isUndefined(sortFn)
        ) {
            return dataToSort;
        }
        const isAscending = sortDir === 'ASC';
        return dataToSort.slice().sort((a, b) => {
            // Switch items to reverse the sort here, prevents weird sorting bugs
            return sortFn(
                cellDataGetter({ dataKey, datum: isAscending ? b : a })
                    .cellData,
                cellDataGetter({ dataKey, datum: isAscending ? a : b }).cellData
            );
        });
    };

    render() {
        const { sortedData } = this.state;
        const {
            styleTopLeftGrid,
            styleBottomLeftGrid,
            styleTopRightGrid,
            styleBottomRightGrid,
        } = this.props;
        // Handle single child column
        const columns = this.getColumns();
        const columnCount = columns.length;
        const rowCount = sortedData.length + 1; // Rows plus the header

        const outline = 'none';
        const borderBase = 'px solid #CCC';
        const borderRight = 3 + borderBase;
        const borderBottom = 2 + borderBase;
        const totalColumnWidth = columns
            .map((e) => Number(e.props.width))
            .reduce((acc, val) => acc + val, 0);

        return (
            <AutoSizer>
                {({ width, height }) => {
                    const rowHeight = this.props.rowHeight || 50;
                    const rowsExceedHeight = rowCount * rowHeight > height;
                    const scrollbarOffset = rowsExceedHeight
                        ? SCROLLBAR_OFFSET
                        : 0;
                    return (
                        <div>
                            <Shortcuts
                                style={{ width: '100%', height: '100%' }}
                                // TODO (Nicholas): When multiple tables are shown at once
                                //                  allow the parent to configure name suffix
                                name="TABLE"
                                // TODO (Nicholas): Add throttling to this handler, see TreeTable
                                handler={this.shortcutHandler}
                            >
                                <MultiGrid
                                    ref={(child) => {
                                        this._grid = child;
                                    }}
                                    width={width}
                                    height={height}
                                    cellRenderer={this.cellRenderer}
                                    rowCount={rowCount}
                                    rowHeight={rowHeight}
                                    columnWidth={this.calculateColumnWidth(
                                        width -
                                            totalColumnWidth -
                                            scrollbarOffset
                                    )}
                                    // Always have an extra (possibly zero width) filler column
                                    // Allows right aligned columns to exist
                                    columnCount={columnCount + 1}
                                    scrollToRow={this.state.selectedRow}
                                    fixedRowCount={this.props.fixedRowCount}
                                    overscanColumnCount={10}
                                    // fixedColumnCount={
                                    //     this.props.fixedColumnCount
                                    // }
                                    styleTopLeftGrid={{
                                        outline,
                                        borderRight,
                                        borderBottom,
                                        ...styleTopLeftGrid,
                                    }}
                                    styleBottomLeftGrid={{
                                        outline,
                                        borderRight,
                                        ...styleBottomLeftGrid,
                                    }}
                                    styleTopRightGrid={{
                                        outline,
                                        borderBottom,
                                        ...styleTopRightGrid,
                                    }}
                                    styleBottomRightGrid={{
                                        outline,
                                        ...styleBottomRightGrid,
                                    }}
                                    enableFixedColumnScroll
                                />
                                {rowsExceedHeight && (
                                    <div
                                        style={{
                                            width,
                                            height: '1px',
                                            boxShadow:
                                                '0 -2px 10px 0 rgba(119,119,119,0.5)',
                                        }}
                                    />
                                )}
                            </Shortcuts>
                        </div>
                    );
                }}
            </AutoSizer>
        );
    }
}

// No idea why this needs to be down here
Table.defaultProps = {
    fixedRowCount: 1,
    fixedColumnCount: 0,
};

export default Table;
