import {
    CalculateCustomSummaryOptions,
    DevExtremeDataGridWeightedAverageConfig,
    DevExtremeGridSummaryItemProps,
    DevExtremeGridWeightedAverageSummaryItem,
} from 'waypoint-utils/dev-extreme/weighted-averages/devExtremeWeightedAverageTypes';

export function buildWeightedAverageDevExtremeConfig<
    SummaryPropType extends DevExtremeGridSummaryItemProps,
    RecordType,
>(
    weightedAverageConfigs: DevExtremeGridWeightedAverageSummaryItem<
        SummaryPropType,
        RecordType
    >[],
    defaultSummaryProps?: SummaryPropType
): DevExtremeDataGridWeightedAverageConfig<
    SummaryPropType,
    RecordType,
    number
> {
    type CustomStateType = {
        sum: number;
        weighted: number;
        uniqueKeys: Set<string>;
    };

    // custom summation function used for iteratively summing DevExtreme records
    function calculateCustomSummary(
        options: CalculateCustomSummaryOptions<RecordType, number> & {
            state?: CustomStateType;
        }
    ) {
        const metricProperty = options.name;

        const weightedAverageConfig = weightedAverageConfigs.find(
            (config) => config.metricKey === metricProperty
        );

        if (!weightedAverageConfig) {
            if (options.summaryProcess === 'start') {
                throw new Error(
                    `WeightedAverageConfig missing for metric named: ${metricProperty}`
                );
            }
            return;
        }

        switch (options.summaryProcess) {
            case 'start': {
                options.state = {
                    sum: 0,
                    weighted: 0,
                    uniqueKeys: new Set<string>(),
                };
                break;
            }
            case 'calculate': {
                if (!options.state) {
                    return;
                }

                const row = options.value;

                /*
                 * Disregard values based on the passed key/value
                 * eg occupancy_status === VACANT
                 */
                if (weightedAverageConfig.filterKey) {
                    if (
                        String(row[weightedAverageConfig.filterKey]) ===
                        weightedAverageConfig.filterValue
                    ) {
                        return;
                    }
                }

                const scaleKeySum = isNaN(
                    Number(row[weightedAverageConfig.scaleKey])
                )
                    ? 0
                    : Number(row[weightedAverageConfig.scaleKey]);

                /*
                 * Check for unique values in the dataset
                 * eg unique entity_code and rsf or unique lease_code and leasable_space_code
                 */
                if (weightedAverageConfig.uniqueKeys) {
                    const uniqueKey = weightedAverageConfig.uniqueKeys
                        .map((key) => row[key])
                        .join('_');

                    if (!options.state.uniqueKeys.has(uniqueKey)) {
                        options.state.sum += scaleKeySum;
                    }

                    options.state.uniqueKeys.add(uniqueKey);
                } else {
                    options.state.sum += scaleKeySum;
                }

                if (
                    row[weightedAverageConfig.metricKey] == null ||
                    row[weightedAverageConfig.scaleKey] == null
                ) {
                    return;
                }

                const scaleValue =
                    Number(row[weightedAverageConfig.scaleKey]) ?? 1;
                const metricValue = Number(
                    row[weightedAverageConfig.metricKey] ?? 0
                );

                if (
                    ['total_monthly', 'total_annual'].includes(metricProperty)
                ) {
                    options.state.weighted += metricValue;
                } else {
                    options.state.weighted += scaleValue * metricValue;
                }
                break;
            }
            case 'finalize': {
                if (options.state) {
                    options.totalValue =
                        options.state.weighted / Math.max(options.state.sum, 1);
                }
                break;
            }
        }
    }

    const summaryItems = weightedAverageConfigs.map(
        (config) =>
            ({
                ...(defaultSummaryProps ?? {}),
                showInColumn: config.options?.showInColumn ?? config.metricKey,
                ...(config.options ?? {}),
                summaryType: 'custom',
                name: config.metricKey,
            }) as SummaryPropType
    );

    return {
        calculateCustomSummary,
        summaryItems,
    };
}
