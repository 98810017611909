import useSWR from 'swr';
import { ComparisonFilterPayload } from 'components/financials/comparative-income-statement/banner/ComparisonSelectionsBannerUtils';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { AccountGraphRaw } from 'waypoint-types/account-graph/types';
import { getComparativeIncomeStatement } from 'waypoint-requests';
import { SelectedDataLevel } from 'waypoint-types';

export const useGetIncomeStatementData = (
    entityCodes: string[],
    periods: ComparisonFilterPayload[],
    selectedDataLevel: SelectedDataLevel,
    cacheId?: string
): DataHookResponse<AccountGraphRaw> => {
    const convertPeriodsToKeySubstring = () => {
        return periods.map((p) => JSON.stringify(p)).join('/');
    };

    const key =
        entityCodes.length && periods.length
            ? `/income-statement/${entityCodes
                  .sort()
                  .join(
                      ','
                  )}/${convertPeriodsToKeySubstring()}/${selectedDataLevel.percentageType}/${selectedDataLevel.stakeholder}/${cacheId}`
            : null;

    const { data, error, mutate, isValidating } = useSWR(
        entityCodes.length ? key : null,
        () => {
            return getComparativeIncomeStatement({
                entityCodes,
                periods: periods,
                reportMetadataId: null,
                selectedDataLevel,
            });
        },
        {
            revalidateOnFocus: false,
        }
    );

    return {
        isLoading: !data || isValidating,
        isError: !!error,
        data,
        mutate,
    };
};
