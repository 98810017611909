import React from 'react';
import theme from 'config/theme';
import { isEmpty, isNil, get } from 'lodash';
import {
    formatPercentage,
    formatMoney,
    formatNumberToDecimalPlace,
} from 'utils/formatters';
import { DASH_DASH } from 'config/constants';
import {
    toCalendarDate,
    toCalendarMonth,
    toMonthAndYear,
} from 'components/dates/utils';
import Typography from 'antd/lib/typography';

export const longTableColumnTitle = (titleText) => {
    return (
        <Typography.Text ellipsis={true} style={{ whiteSpace: 'normal' }}>
            {titleText}
        </Typography.Text>
    );
};

export const getVarianceColor = (varianceIsGood) => {
    if (isNil(varianceIsGood)) {
        return null;
    }
    if (varianceIsGood) {
        return theme.colors.green;
    }
    return theme.colors.red;
};

/* If you add a function, add a test. Tests are here: client/__tests__/tables/renderers.spec.js */

export const textRenderer = (text) => text || DASH_DASH;

export const linkRenderer = (text, data) => {
    return text ? <a href={data.link}>{text}</a> : DASH_DASH;
};

export const currencyRenderer = (currency) => {
    return currency || currency === 0
        ? formatMoney(Number(currency))
        : DASH_DASH;
};

export const integerRenderer = (integer, decimalPlaces) => {
    return integer || integer === 0
        ? formatNumberToDecimalPlace(Number(integer), decimalPlaces)
        : DASH_DASH;
};

export const floatRenderer = (float, decimalPlaces) => {
    return float || float === 0
        ? formatPercentage(Number(float) * 100, decimalPlaces)
        : DASH_DASH;
};

export const dateRenderer = (date) => {
    return date && !isEmpty(date) ? toCalendarDate(date) : DASH_DASH;
};

export const monthRenderer = (date) => {
    return date && !isEmpty(date) ? toCalendarMonth(date) : DASH_DASH;
};

export const monthAndYearRender = (date) => {
    return date && !isEmpty(date) ? toMonthAndYear(date) : DASH_DASH;
};

export const varianceRenderer = (value, node) => {
    return (
        <span
            style={{
                color: getVarianceColor(get(node, 'variance_is_good')),
            }}
        >
            {value}
        </span>
    );
};

export const percentageRenderer = (value) => {
    return value || value === 0
        ? formatPercentage(Number(value) * 100, 2)
        : DASH_DASH;
};

export const booleanRenderer = (value) => {
    return value ? 'Yes' : 'No';
};
