import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import defaultStylePP from './defaultStylePP';

export const ModalCell = ({
    /* selectedRow,*/
    openModal,
    cellData,
    ...rest
}) => {
    return (
        <div {...rest}>
            <i
                style={{ color: '#00ADE2', cursor: 'pointer' }}
                className="fa-solid fa-pencil"
                onClick={openModal}
            />
            &nbsp;&nbsp;
            <h4
                style={{
                    fontWeight: 'normal',
                    fontVariant: 'normal',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: 0,
                }}
            >
                {cellData}
            </h4>
        </div>
    );
};

ModalCell.propTypes = {
    cellData: PropTypes.any,
    selectedRow: PropTypes.bool,
    openModal: PropTypes.func,
};

export const ModalCellRenderer = defaultStylePP(ModalCell);
export default ModalCellRenderer;
