import React, { Component } from 'react';
import { cx, css } from 'emotion';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import { ActionsCard, ViewSwitch, TextLoadingBar } from 'waypoint-react';
import { DASH_DASH } from 'config/constants';
import { Skeleton, Empty } from 'antd';

const cardClassName = css`
    .ant-card-actions {
        margin-top: auto;
    }
    .ant-card-body {
        height: 100%;
    }

    .ant-radio-button-wrapper span {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
const content = css`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const label = css`
    flex: 1;
`;

const CHART_VIEW = 'Chart';
const TABLE_VIEW = 'Table';

const LoadingBar = () => (
    <TextLoadingBar
        style={{
            height: '12px',
            width: '50px',
            marginTop: '5px',
        }}
    />
);

class ChartTableSwitchCard extends Component {
    renderContent() {
        if (this.props.loading) {
            return (
                <Skeleton
                    paragraph={{ rows: this.props.loadingRows || 6 }}
                    viewId="*"
                />
            );
        }

        if (this.props.empty) {
            return (
                <Empty
                    description="Data unavailable for this metric or time period"
                    viewId="*"
                    className={this.props.emptyClassName}
                />
            );
        }

        return [
            React.cloneElement(this.props.chart, {
                key: `${this.props.title}-${CHART_VIEW}`,
                viewId: CHART_VIEW,
            }),
            React.cloneElement(this.props.table, {
                key: `${this.props.title}-${TABLE_VIEW}`,
                viewId: TABLE_VIEW,
            }),
        ];
    }

    render() {
        const { footer, empty, title, loading, subheading, legend, ...rest } =
            this.props;
        const cardStyle = {
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            ...this.props.style,
        };
        return (
            <ActionsCard
                title={loading ? <LoadingBar /> : title}
                actions={footer && [footer]} // actions is an antd prop. In this case we are using it to inject a footer and has no relation to an action in ActionsCard
                className={cx([this.props.className, cardClassName])}
                style={cardStyle}
                {...omit(rest, 'chart', 'table', 'style', 'className')}
            >
                <div className={content}>
                    <div style={{ height: '100%' }}>
                        <ViewSwitch
                            showToggle={!loading && !empty}
                            labelClassName={label}
                            label={
                                loading ? (
                                    <LoadingBar />
                                ) : (
                                    subheading || DASH_DASH
                                )
                            }
                            extra={!loading && !empty && legend}
                        >
                            {this.renderContent()}
                        </ViewSwitch>
                    </div>
                </div>
            </ActionsCard>
        );
    }
}

ChartTableSwitchCard.propTypes = {
    empty: PropTypes.bool,
    loading: PropTypes.bool,
    chart: PropTypes.node.isRequired,
    table: PropTypes.node.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    legend: PropTypes.node,
    period: PropTypes.string,
    emptyClassName: PropTypes.string,
};

export default ChartTableSwitchCard;
