import { EditorUtils } from '@progress/kendo-react-editor';
import { UploadFileInfo } from '@progress/kendo-react-upload';
import { NodeType } from '@progress/kendo-editor-common';
import { DocumentUpload } from 'waypoint-types';
import { InsertImage, InsertImageProps } from './InsertImageTools';
import { EditorView } from 'prosemirror-view';
import { ResolvedPos, Node } from 'prosemirror-model';
import DOMPurify from 'dompurify';

const { sanitize } = DOMPurify;

export interface InsertImageFileProps {
    view: EditorView;
    nodeType: NodeType;
    position: ResolvedPos | null;
    images: ImageData[];
}

export interface ImageAttrs {
    src?: string | undefined;
    title?: string | undefined;
    alt?: string | undefined;
    width?: string | undefined;
    height?: string | undefined;
}

export interface ImageData {
    src: string;
    upload?: DocumentUpload;
    file: UploadFileInfo;
    attrs: ImageAttrs;
}

interface AdditionalProps {
    referenceType: string;
}

// creates new object without undefined attributes
const getFilteredAttrs = (attrs: ImageAttrs) =>
    Object.keys(attrs)
        .filter((key: string) => {
            const attributeIsNotUndefined =
                attrs[key as keyof ImageAttrs] !== undefined;
            const attributeIsNotEmpty =
                (attrs[key as keyof ImageAttrs] as string) !== '';
            return attributeIsNotUndefined && attributeIsNotEmpty;
        })
        .reduce(
            (acc, curr) =>
                Object.assign(acc, {
                    [curr]: attrs[curr as keyof ImageAttrs],
                }),
            {},
        );

export const insertImageFiles = ({
    view,
    nodeType,
    position,
    images,
}: InsertImageFileProps) => {
    if (EditorUtils.canInsert(view.state, nodeType)) {
        images.forEach((imageFile: ImageData) => {
            const reader = new FileReader();
            reader.onload = function () {
                const filteredAttrs = getFilteredAttrs(imageFile.attrs);
                const image = nodeType.createAndFill({
                    src: imageFile.src,
                    id: imageFile.upload?.document_url, // real file name identifier
                    ...filteredAttrs,
                });

                if (position) {
                    view.dispatch(
                        view.state.tr.insert(position.pos, image as Node),
                    );
                }

                if (!position && image) {
                    EditorUtils.insertNode(view, image, true);
                }
            };

            imageFile.file?.getRawFile &&
                reader.readAsDataURL(imageFile.file.getRawFile());
        });
    }
};

// HOC to combine original kendo props with reference type
export const createInsertImageTool =
    (additionalProps: AdditionalProps) =>
    (props: Partial<InsertImageProps>) => {
        const combinedProps = { ...props, ...additionalProps };

        // call the original InsertImage function with the combined props
        return InsertImage(combinedProps);
    };

// This will update the HTML images with the most recent cloud signed URL
// It should not interfere with HTML that has not been saved yet.
export const updateImageSrcWithSignedURLs = (
    content: string,
    uploadedImages: DocumentUpload[],
    onUpdateContentCallback: (value: string) => void,
) => {
    if (!content.length || !uploadedImages?.length) {
        return;
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const body = doc.body;
    const imagesWithId = [...body.querySelectorAll('img')].filter(
        (img: HTMLImageElement) => img.id !== undefined,
    );
    if (!imagesWithId.length) {
        return;
    }

    for (const img of imagesWithId) {
        const upload = uploadedImages?.find(
            (image) => image.document_url === img.id, // the img id is set with the actual file name
        );
        if (upload && img.src !== upload.document_cloud_url) {
            img.src = upload?.document_cloud_url; // most recent signed url
        }
    }

    const modifiedHTML = body.innerHTML;
    const sanitizedHTML = sanitize(modifiedHTML, {
        ADD_ATTR: ['target'],
    });

    const ampersonRegex = /&amp;/g;
    const correctedURLsHTML = sanitizedHTML.replace(ampersonRegex, '&');
    onUpdateContentCallback(correctedURLsHTML);
};
