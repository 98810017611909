import { Route } from 'react-router-dom';

import Settings from 'components/settings/Settings';
import UserRoutes from './user';
import AdminRoutes from './admin';
import { ADMIN_SETTINGS_URL_SEGMENT } from 'config/constants';

const SettingsRoutes = () => (
    <>
        <Route
            path="/settings/user"
            render={() => (
                <Settings>
                    <UserRoutes />
                </Settings>
            )}
        />
        <Route
            path={ADMIN_SETTINGS_URL_SEGMENT}
            render={() => (
                <Settings>
                    <AdminRoutes />
                </Settings>
            )}
        />
    </>
);

export default SettingsRoutes;
