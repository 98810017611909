import { API_URL } from 'config/constants';
import { EntityOwnershipPercentage } from 'waypoint-types';

const getEntityOwnershipPercentage = async (
    entityCode: string
): Promise<EntityOwnershipPercentage[]> => {
    const response = await fetch(
        `${API_URL}/client/ownership-percentage/${entityCode}`,
        {
            credentials: 'include',
        }
    );
    const { data } = await response.json();
    return data;
};

export default getEntityOwnershipPercentage;
