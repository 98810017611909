import PropTypes from 'prop-types';
import React from 'react';
import { Popover, OverlayTrigger, Table } from 'react-bootstrap';
import defaultStylePP from './defaultStylePP';

// TODO (Colby): decouple from user management table
export const PopoverCell = ({ cellData, ...rest }) => {
    const { title, popoverData } = cellData;
    return (
        <div {...rest}>
            <OverlayTrigger
                rootClose
                placement={'left'}
                trigger={['click']}
                overlay={
                    <Popover
                        style={{
                            maxHeight: '300px',
                            overflowY: 'auto',
                            overflowX: 'hidden',
                        }}
                    >
                        <Table striped>
                            <tbody>
                                {popoverData &&
                                    popoverData.map((d) => (
                                        <tr key={d.name}>{d.name}</tr>
                                    ))}
                            </tbody>
                        </Table>
                    </Popover>
                }
            >
                <h4
                    style={
                        title === 'No Access Lists'
                            ? {
                                  color: '#9B9B9B',
                                  fontStyle: 'italic',
                                  fontWeight: 'normal',
                                  fontVariant: 'normal',
                                  cursor: 'pointer',
                              }
                            : {
                                  fontWeight: 'normal',
                                  fontVariant: 'normal',
                                  cursor: 'pointer',
                              }
                    }
                >
                    {title}
                </h4>
            </OverlayTrigger>
        </div>
    );
};

PopoverCell.propTypes = {
    cellData: PropTypes.any,
};

export const PopoverCellRenderer = defaultStylePP(PopoverCell);
export default PopoverCellRenderer;
