import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { Popover } from 'antd';

const waleTooltip =
    'A WALE (weighted average lease expiry) is a way of measuring the average period in which all leases in a property or portfolio will expire.';

const WaleTooltip = (): JSX.Element => {
    return (
        <Popover content={<div style={{ width: 200 }}>{waleTooltip}</div>}>
            <i className="fa-regular fa-question-circle" />
        </Popover>
    );
};

export default WaleTooltip;
