import React from 'react';
import { css } from 'emotion';
import { BALANCE_SHEET_NO_FEATURE_TEXT } from 'components/financials/balanceSheet/constants';
import Card from 'components/style/Card';
import EmptyState from 'components/style/EmptyState';
import BalanceSheetContainer from 'components/financials/balanceSheet/components/card/BalanceSheetContainer';
import { useSelectedDataLevel } from 'waypoint-hooks';
import { AppFeaturePermissions } from 'contexts';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';

const gridStyle = css`
    position: relative;
    height: 1520px;
    left: 0;
    right: 0;
    bottom: 0;

    & .grid_layout {
        padding: 0;
    }
`;

const emptyContainer = css`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
`;

const cardGridConfig = { x: 0, y: 0, w: 12, h: 6 };

const getEmptyText = () => {
    return BALANCE_SHEET_NO_FEATURE_TEXT;
};

interface BalanceSheetGridProps {
    isBalanceSheetEnabled: boolean;
    showDisabledView?: boolean;
    isPropertyProfile: boolean;
    entityCodes: string[];
}

const BalanceSheetGrid = ({
    isBalanceSheetEnabled,
    showDisabledView,
    entityCodes,
    isPropertyProfile,
}: BalanceSheetGridProps) => {
    const selectedDataLevel = useSelectedDataLevel();

    const card = isBalanceSheetEnabled ? (
        <PermissionedWrapper
            featureKey={AppFeaturePermissions.BalanceSheet}
            showDisabledView={showDisabledView}
        >
            <BalanceSheetContainer
                entityCodes={entityCodes}
                selectedDataLevel={selectedDataLevel}
                isPropertyProfile={isPropertyProfile}
            />
        </PermissionedWrapper>
    ) : (
        <Card key="1" data-grid={cardGridConfig}>
            <div className={emptyContainer}>
                <EmptyState
                    icon={'./assets/img/Analytics-Icon-Gray@2x.png'}
                    headerText={getEmptyText()}
                />
            </div>
        </Card>
    );
    return <div className={gridStyle}>{[card]}</div>;
};

export default BalanceSheetGrid;
