import React, { useContext } from 'react';
import { css } from 'emotion';
import { Skeleton, Button, Tag, Divider, Tooltip } from 'antd';
import { useClientPrefixedLocalStorage } from 'waypoint-hooks';
import { ACTIVE_GLOBAL_FILTER_KEY } from 'components/app/global-filter-drawer/GlobalFilterConstants';
import { SavedFiltersContext, SavedFilter } from 'contexts';
import { EntityAttributesContext } from 'contexts/entity-attributes/EntityAttributesContext';
import ClearFiltersButton from 'components/app/global-filter-drawer/ClearFiltersButton';

const container = css`
    display: flex;
    justify-content: space-between;
`;

const truncate = css`
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const FilterInfo = (): JSX.Element => {
    const savedFilterContext = useContext(SavedFiltersContext);

    const entityAttributesContext = useContext(EntityAttributesContext);

    const [appliedAttributeFilter] =
        useClientPrefixedLocalStorage<SavedFilter | null>(
            ACTIVE_GLOBAL_FILTER_KEY,
            null
        );

    const attributeFilterNames = appliedAttributeFilter?.filters
        ? appliedAttributeFilter?.filters
              .map((filter) => filter.title)
              .filter(Boolean)
        : [];

    const isLoading =
        savedFilterContext?.isSavedFiltersLoading ||
        entityAttributesContext?.isAttributesLoading;

    if (isLoading) {
        return (
            <>
                <Divider>Active Filter(s)</Divider>
                <div className={container}>
                    <Skeleton.Button
                        size="small"
                        active
                        style={{ width: 200, marginBottom: '25px' }}
                    />
                    <Button danger ghost size="small" loading>
                        Clear Filters
                    </Button>
                </div>
            </>
        );
    }

    return (
        <>
            <Divider>Active Filter(s)</Divider>
            <Tooltip
                title={appliedAttributeFilter?.name}
                placement="right"
                arrow
            >
                <h2 className={truncate}>{appliedAttributeFilter?.name}</h2>
            </Tooltip>
            <ClearFiltersButton />
            <div style={{ marginTop: '10px', marginBottom: '25px' }}>
                {attributeFilterNames.map((name) => (
                    <Tag color="blue" key={name}>
                        {name}
                    </Tag>
                ))}
            </div>
        </>
    );
};

export default FilterInfo;
