import React, { useCallback, useState, useMemo, useRef } from 'react';
import SelectBox from 'devextreme-react/select-box';
import DataGrid, {
    Summary,
    HeaderFilter,
    Paging,
    Pager,
    StateStoring,
    FilterRow,
    ColumnChooser,
    Export,
    Grouping,
    GroupItem,
    Toolbar,
    Item,
    Scrolling,
    GroupPanel,
    SortByGroupSummaryInfo,
    FilterPanel,
    DataGridRef,
    ColumnChooserSelection,
    Position,
} from 'devextreme-react/data-grid';
import {
    ExpandAndCollapseButton,
    SortByGroupSummarySortSelect,
} from 'waypoint-react';
import { ExportingEvent, RowPreparedEvent } from 'devextreme/ui/data_grid';
import { exportExcelFromDevExtremeDataGrid } from 'waypoint-utils';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import { TABLE_OFFSET } from 'components/analytics/portfolioSegmentation/constants';
import theme from 'config/theme';
import {
    columns,
    getTotalItems,
    groupingOptions,
    IPropertyValueTableData,
} from 'components/analytics/portfolioSegmentation/propertyValues/utils';
import { Dictionary } from 'ts-essentials';
import LoadingTable from 'components/style/LoadingTable';
import Button from 'antd/lib/button';
import { buildWeightedAverageDevExtremeConfig } from 'waypoint-utils/dev-extreme/weighted-averages';

import {
    useGetGroupableAttributes,
    useSortByGroupSummaryInfo,
} from 'waypoint-hooks';
import { removeGroupButtonStyle } from 'components/style';

interface ICalculateSummaryResult {
    debt_own: number;
    gross_value_own: number;
    debt_100: number;
    gross_value_100: number;
}

interface IPropertyValueTableProps {
    dataSource: IPropertyValueTableData[];
    error: boolean;
    isLoading: boolean;
}

const PropertyValueTable = ({
    dataSource,
    isLoading,
}: IPropertyValueTableProps) => {
    const dataGrid = useRef<DataGridRef>(null);

    const [grouping, setGrouping] = useState<string>('');

    const [expanded, setExpanded] = useState(false);
    const [expandButtonEnable, setExpandButtonEnable] = useState(true);

    const {
        sortSelection,
        setSortSelection,
        sortOrderAscending,
        sortVisible,
        sortExcludedColumns,
        toggleSortOrder,
        toggleSortSettings,
    } = useSortByGroupSummaryInfo();

    const groupableAttributes = useGetGroupableAttributes();

    const groupingSelectOptions = groupableAttributes.map((attr) => ({
        value: attr.key,
        text: attr.title,
    }));

    const onExporting = useCallback(async (e: ExportingEvent) => {
        await exportExcelFromDevExtremeDataGrid(e, {
            worksheetName: 'Portfolio Summary',
            filename: 'Crestpoint - Portfolio Summary.xlsx',
        });
    }, []);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const onRowPrepared = (e: RowPreparedEvent) => {
        if (e.rowType === 'group') {
            e.rowElement.style.backgroundColor = theme.colors.grays.background;
        }
    };

    const groupChanged = (e: Dictionary<string>) => {
        const group = e.value;
        setGrouping(group);

        if (!group) {
            return;
        }

        dataGrid?.current?.instance().clearGrouping();
        dataGrid?.current?.instance().columnOption(group, 'groupIndex', 0);
        dataGrid?.current?.instance().refresh();
        setExpandButtonEnable(group === 'none');
    };

    const resetGrouping = () => {
        dataGrid?.current?.instance().clearGrouping();
        setExpandButtonEnable(true);
        setGrouping('');
    };

    const weightedMetrics = [
        {
            metricKey: 'wale_years',
            scaleKey: 'sf_occupied_100',
        },
        {
            metricKey: 'wale_years_custom_calc_own',
            scaleKey: 'sf_occupied_own',
        },
        {
            metricKey: 'base_rent_psf',
            scaleKey: 'sf_occupied_100',
        },
        {
            metricKey: 'base_rent_own_psf',
            scaleKey: 'sf_occupied_own',
        },
    ];

    const { calculateCustomSummary: weightedCalc } =
        buildWeightedAverageDevExtremeConfig(weightedMetrics, {
            displayFormat: '{0}',
        });

    const calculateCustomSummary = (options: {
        name: string;
        component: any;
        summaryProcess: string;
        value: any;
        totalValue: number;
        groupIndex: number;
        totalDisplayValue?: ICalculateSummaryResult;
    }) => {
        if (
            [
                'wale_years',
                'wale_years_custom_calc_own',
                'base_rent_psf',
                'base_rent_own_psf',
            ].includes(options.name)
        ) {
            weightedCalc(options);
        } else {
            switch (options.summaryProcess) {
                case 'start': {
                    options.totalValue = 0;
                    options.totalDisplayValue = {
                        debt_own: 0,
                        gross_value_own: 0,
                        debt_100: 0,
                        gross_value_100: 0,
                    };
                    break;
                }
                case 'calculate': {
                    if (options.totalDisplayValue) {
                        options.totalDisplayValue.debt_own += Number(
                            options.value.debt_own,
                        );
                        options.totalDisplayValue.gross_value_own += Number(
                            options.value.gross_value_own,
                        );
                        options.totalDisplayValue.debt_100 += Number(
                            options.value.debt_100,
                        );
                        options.totalDisplayValue.gross_value_100 += Number(
                            options.value.gross_value_100,
                        );
                    }
                    break;
                }
                case 'finalize': {
                    if (
                        options.name === 'ltv_own' &&
                        options.totalDisplayValue
                    ) {
                        options.totalValue =
                            options.totalDisplayValue.debt_own /
                            options.totalDisplayValue.gross_value_own;
                    } else if (
                        options.name === 'ltv_100' &&
                        options.totalDisplayValue
                    ) {
                        options.totalValue =
                            options.totalDisplayValue.debt_100 /
                            options.totalDisplayValue.gross_value_100;
                    }
                    break;
                }
            }
        }
    };

    const TABLE_HEIGHT = useMemo(
        () => window.innerHeight - TABLE_OFFSET,
        [window.innerHeight],
    );

    if (isLoading) {
        return (
            <div data-testid-loading-table style={{ height: TABLE_HEIGHT }}>
                <LoadingTable rowCount={Math.round(TABLE_HEIGHT / 50) || 7} />
            </div>
        );
    }
    return (
        <DataGrid
            ref={dataGrid}
            wordWrapEnabled={false}
            dataSource={dataSource}
            onExporting={onExporting}
            height={TABLE_HEIGHT}
            allowColumnReordering={true}
            rowAlternationEnabled={false}
            hoverStateEnabled={true}
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}
            columns={columns}
            onRowPrepared={onRowPrepared}
            onOptionChanged={toggleSortSettings}
        >
            <Summary
                totalItems={getTotalItems}
                calculateCustomSummary={calculateCustomSummary}
            >
                <GroupItem
                    column="display_name"
                    summaryType="count"
                    displayFormat="{0} Assets"
                    alignByColumn
                />
                {/* 
                    This fake column is to fix the first column when grouping 
                    see: https://supportcenter.devexpress.com/ticket/details/t611868/datagrid-a-group-text-is-moved-during-scrolling-when-using-fixed-columns-and-a-group
                */}
                <GroupItem column="fake" alignByColumn summaryType="count" />
                <GroupItem
                    column="sf_100"
                    summaryType="sum"
                    valueFormat={{ type: 'fixedpoint', precision: 0 }}
                    displayFormat="{0}"
                    alignByColumn
                />
                <GroupItem
                    column="sf_occupied_100"
                    summaryType="sum"
                    valueFormat={{ type: 'fixedpoint', precision: 0 }}
                    displayFormat="{0}"
                    alignByColumn
                />
                <GroupItem
                    column="sf_own"
                    summaryType="sum"
                    valueFormat={{ type: 'fixedpoint', precision: 0 }}
                    displayFormat="{0}"
                    alignByColumn
                />
                <GroupItem
                    column="sf_occupied_own"
                    summaryType="sum"
                    valueFormat={{ type: 'fixedpoint', precision: 0 }}
                    displayFormat="{0}"
                    alignByColumn
                />

                <GroupItem
                    column="sf_managed"
                    summaryType="sum"
                    valueFormat={{ type: 'fixedpoint', precision: 0 }}
                    displayFormat="{0}"
                    alignByColumn
                />
                <GroupItem
                    showInColumn="wale_years"
                    valueFormat={{ type: 'fixedPoint', precision: 1 }}
                    displayFormat="{0}"
                    alignByColumn
                    name="wale_years"
                    summaryType="custom"
                />
                <GroupItem
                    showInColumn="wale_years_custom_calc_own"
                    valueFormat={{ type: 'fixedPoint', precision: 1 }}
                    displayFormat="{0}"
                    alignByColumn
                    name="wale_years_custom_calc_own"
                    summaryType="custom"
                />
                <GroupItem
                    column="base_rent"
                    summaryType="sum"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                    alignByColumn
                />
                <GroupItem
                    column="base_rent_own"
                    summaryType="sum"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                    alignByColumn
                />
                <GroupItem
                    showInColumn="base_rent_psf"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                    alignByColumn
                    name="base_rent_psf"
                    summaryType="custom"
                />
                <GroupItem
                    showInColumn="base_rent_own_psf"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                    alignByColumn
                    name="base_rent_own_psf"
                    summaryType="custom"
                />
                <GroupItem
                    column="gross_value_100"
                    summaryType="sum"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                    alignByColumn
                />
                <GroupItem
                    column="net_value_100"
                    summaryType="sum"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                    alignByColumn
                />
                <GroupItem
                    column="debt_100"
                    summaryType="sum"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                    alignByColumn
                />
                <GroupItem
                    showInColumn="ltv_100"
                    valueFormat={{ type: 'percent', precision: 1 }}
                    displayFormat="{0}"
                    alignByColumn
                    name="ltv_100"
                    summaryType="custom"
                />
                <GroupItem
                    column="dispositionprice"
                    summaryType="sum"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                    alignByColumn
                />
                <GroupItem
                    column="purchase_price"
                    summaryType="sum"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                    alignByColumn
                />
                <GroupItem
                    column="purchase_own"
                    summaryType="sum"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                    alignByColumn
                />
                <GroupItem
                    column="gross_value_own"
                    summaryType="sum"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                    alignByColumn
                />
                <GroupItem
                    column="net_value_own"
                    summaryType="sum"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                    alignByColumn
                />
                <GroupItem
                    column="debt_own"
                    summaryType="sum"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                    alignByColumn
                />
                <GroupItem
                    showInColumn="ltv_own"
                    name="ltv_own"
                    summaryType="custom"
                    valueFormat={{ type: 'percent', precision: 1 }}
                    displayFormat="{0}"
                    alignByColumn
                />

                <GroupItem
                    column="gross_value_client"
                    summaryType="sum"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                    alignByColumn
                />
                <GroupItem
                    column="net_value_client"
                    summaryType="sum"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                    alignByColumn
                />
                <GroupItem
                    column="debt_client"
                    summaryType="sum"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                    alignByColumn
                />
                <GroupItem
                    column="gross_value_managed"
                    summaryType="sum"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                    alignByColumn
                />
                <GroupItem
                    column="net_value_managed"
                    summaryType="sum"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                    alignByColumn
                />
                <GroupItem
                    column="debt_managed"
                    summaryType="sum"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                    alignByColumn
                />
                <GroupItem
                    column="debt_yardi"
                    summaryType="sum"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                    alignByColumn
                />
                <GroupItem
                    column="gross_value_yardi"
                    summaryType="sum"
                    valueFormat={{ type: 'currency', precision: 2 }}
                    displayFormat="{0}"
                    alignByColumn
                />
            </Summary>
            <SortByGroupSummaryInfo
                summaryItem={sortSelection}
                sortOrder={sortOrderAscending ? 'asc' : 'desc'}
            />
            <HeaderFilter
                visible={true}
                allowSearch={true}
                allowSelectAll={true}
                height={450}
                width={300}
            />
            <FilterRow visible={true} applyFilter="auto" />
            <FilterPanel visible={true} />
            <GroupPanel visible={!expandButtonEnable} />

            <Grouping autoExpandAll={expanded} />
            <StateStoring
                enabled={true}
                type="localStorage"
                storageKey={`property-value-table`}
            />
            <ColumnChooser
                allowSearch={true}
                enabled={true}
                mode={'select'}
                height={450}
            >
                <Position
                    my="right top"
                    at="right bottom"
                    of=".dx-datagrid-column-chooser-button"
                />
                <ColumnChooserSelection
                    allowSelectAll={true}
                    recursive={true}
                />
            </ColumnChooser>
            <Toolbar>
                <Item location="after" name="columnChooserButton" />
                <Item name="exportButton" />
                <Item name="searchPanel" locateInMenu="auto" />
                <Item location="before">
                    <SelectBox
                        placeholder="Group By"
                        label="Group By"
                        labelMode="hidden"
                        stylingMode="underlined"
                        onValueChanged={groupChanged as any} //devxtreme missmatching type
                        width="180"
                        items={groupingSelectOptions}
                        displayExpr="text"
                        valueExpr="value"
                        value={grouping}
                    />
                </Item>
                <Item name="groupPanel" />
                <Item location="before">
                    <ExpandAndCollapseButton
                        expanded={expanded}
                        toggleExpanded={toggleExpanded}
                        expandButtonEnable={expandButtonEnable}
                    />
                </Item>
                <Item location="before">
                    <Button
                        className={removeGroupButtonStyle}
                        style={{
                            display: expandButtonEnable ? 'none' : 'block',
                        }}
                        type="primary"
                        onClick={resetGrouping}
                    >
                        Remove Grouping
                    </Button>
                </Item>
                <Item location="before" visible={sortVisible}>
                    <SortByGroupSummarySortSelect
                        groupingOptions={groupingOptions}
                        sortExcludedColumns={sortExcludedColumns}
                        sortSelection={sortSelection}
                        setSortSelection={setSortSelection}
                        sortOrderAscending={sortOrderAscending}
                        toggleSortOrder={toggleSortOrder}
                    />
                </Item>
            </Toolbar>
            <Export enabled={true} allowExportSelectedData={false} />

            <Paging enabled={true} defaultPageSize={60} />
            <Pager
                visible
                displayMode={'full'}
                showPageSizeSelector={true}
                allowedPageSizes={[20, 40, 60, 80, 100]}
                showNavigationButtons={true}
                showInfo={true}
                infoText="Page {0} of {1} ({2} items)"
            />
        </DataGrid>
    );
};

export default PropertyValueTable;
