import {
    UploadFileInfo,
    UploadFileRestrictions,
} from '@progress/kendo-react-upload';
import { css } from 'emotion';
import { isValidFileSize } from 'waypoint-utils';

export const hideKendoUploadElementsClass = css`
    .k-actions,
    .k-progressbar {
        display: none;
    }
    .k-dropzone-hint {
        display: none;
    }
`;

export const helperText = css`
    font-size: 12px;
    color: #5e5e5e;
    margin-top: 4px;
    text-align: right;
`;

export const DEFAULT_FILE_SIZE_LIMIT = 5;
export const MB_SIZE = 1024 * 1024;
export const DEFAULT_FILE_SIZE_LIMIT_MB = 5 * MB_SIZE;
export const HTML_EDITOR_IMAGE_SIZE_LIMIT = 5 * MB_SIZE;
export const SINGLE_FILE_UPLOAD = 1;

export enum DocumentUploadReferenceType {
    EntityReportNarrative = 'entity_report_narrative',
    EntityMarketOverview = 'entity_market_overview',
    EntityExecutiveSummary = 'entity_executive_summary',
}

export const invalidFileSizes = (
    restrictions: UploadFileRestrictions,
    files: UploadFileInfo[]
): boolean =>
    files.some(
        (file: UploadFileInfo) =>
            !file?.getRawFile ||
            !isValidFileSize(
                restrictions.maxFileSize ?? DEFAULT_FILE_SIZE_LIMIT,
                file?.getRawFile()
            )
    );
