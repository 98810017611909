import React, { useState } from 'react';
import { Input, Popconfirm, Tooltip } from 'antd';
import { TwitterPicker } from 'react-color';
import { CapitalProject, TypeOption } from 'waypoint-types';
import {
    canShowColorOptionOrPicker,
    colorOfTheType,
    optionIsInUse,
    colorPickerOptions,
} from './PlanningCapitalProjectUtils';
import theme from 'config/theme';

interface OptionLabelProps {
    option?: TypeOption;
    setNewClientDefinedValueColor: (color: string) => void;
    setNewClientDefinedValueName: (name: string) => void;
    newClientDefinedValueName: string;
    updateClientDefinedType: (type: TypeOption) => void;
    addClientDefinedType: () => void;
    setSelectedTypeToEdit: (type: string | null) => void;
    tableData: CapitalProject[];
    setIsAddingType: (isAddingType: boolean) => void;
    type: string;
    deleteClientDefinedType: (type: TypeOption) => void;
    newClientDefinedValueColor: string;
}

const OptionLabel = ({
    option,
    setNewClientDefinedValueColor,
    setNewClientDefinedValueName,
    newClientDefinedValueName,
    updateClientDefinedType,
    deleteClientDefinedType,
    type,
    tableData,
    setSelectedTypeToEdit,
    setIsAddingType,
    addClientDefinedType,
    newClientDefinedValueColor,
}: OptionLabelProps) => {
    const [colorPickerOpened, setColorPickerOpened] = useState(false);

    const optionDelete = (option: TypeOption, tableData: CapitalProject[]) => {
        if (optionIsInUse(option ?? null, tableData)?.length) {
            return (
                <Tooltip
                    title={`You cannot delete this ${type} because it is in use`}
                >
                    <i
                        style={{
                            color: 'gray',
                            cursor: 'not-allowed',
                            fontSize: '15px',
                            marginLeft: '16px',
                            verticalAlign: 'bottom',
                        }}
                        className="fa-solid fa-trash"
                    />
                </Tooltip>
            );
        }

        return (
            <Popconfirm
                placement="top"
                title={`Are you sure you want to delete this ${type}?`}
                onConfirm={() => {
                    deleteClientDefinedType(option as TypeOption);
                }}
                onCancel={() => {}}
                okType="danger"
                okText="Delete"
                cancelText="Cancel"
            >
                <i
                    style={{
                        color: 'red',
                        cursor: 'pointer',
                        fontSize: '15px',
                        marginLeft: '16px',
                        verticalAlign: 'bottom',
                    }}
                    className="fa-solid fa-trash"
                />
            </Popconfirm>
        );
    };

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                margin: '6px 0',
                position: 'relative',
            }}
        >
            {!canShowColorOptionOrPicker(type ?? '') ? null : (
                <>
                    {colorPickerOpened ? (
                        <div
                            style={{
                                position: 'absolute',
                                top: 35,
                                left: -5,
                                zIndex: 4,
                            }}
                        >
                            <TwitterPicker
                                color={
                                    option
                                        ? option.color
                                        : newClientDefinedValueColor
                                }
                                colors={colorPickerOptions}
                                triangle="top-left"
                                onChange={(color) => {
                                    setNewClientDefinedValueColor(color.hex);
                                    setColorPickerOpened(false);
                                }}
                            />
                        </div>
                    ) : null}
                    <div
                        onClick={() => setColorPickerOpened(!colorPickerOpened)}
                        className={colorOfTheType(
                            newClientDefinedValueColor
                                ? newClientDefinedValueColor
                                : option?.color ?? '#000000',
                        )}
                    >
                        &nbsp;
                    </div>
                </>
            )}

            <Input
                style={{ width: '200px', height: '30px' }}
                defaultValue={option?.label ?? ''}
                placeholder={option ? '' : `Add ${type}`}
                onChange={(data) =>
                    setNewClientDefinedValueName(data.target.value)
                }
            />

            <i
                style={{
                    color:
                        !option && !newClientDefinedValueName
                            ? 'gray'
                            : theme.colors.blues.linkBlue,
                    cursor: 'pointer',
                    fontSize: '15px',
                    marginLeft: '16px',
                    verticalAlign: 'bottom',
                }}
                className="fa-solid fa-floppy-disk"
                onClick={() => {
                    if (option) {
                        updateClientDefinedType(option);
                    } else {
                        if (newClientDefinedValueName) {
                            addClientDefinedType();
                        }
                    }
                }}
            />

            {option ? optionDelete(option, tableData) : null}

            <i
                style={{
                    color: 'black',
                    cursor: 'pointer',
                    fontSize: '15px',
                    marginLeft: '16px',
                    verticalAlign: 'bottom',
                }}
                className="fa-solid fa-times"
                onClick={() =>
                    option
                        ? setSelectedTypeToEdit(null)
                        : setIsAddingType(false)
                }
            />
        </div>
    );
};

export default OptionLabel;
