import { API_URL } from 'config/constants';

interface DeletePermissionGroupParams {
    permissionGroupId: string;
    clientId: number;
}

interface DeletePermissionGroupResponse {
    message: string;
}

export async function deletePermissionGroup({
    permissionGroupId,
    clientId,
}: DeletePermissionGroupParams): Promise<DeletePermissionGroupResponse> {
    const response = await fetch(
        `${API_URL}/permission-groups/${clientId}/${permissionGroupId}`,
        {
            method: 'DELETE',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
        }
    );

    if (!response.ok) {
        throw new Error('Failed to delete permission group');
    }

    const { data } = await response.json();

    return data as DeletePermissionGroupResponse;
}
