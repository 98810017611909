import { formatMoney, formatNumberToDecimalPlace } from 'utils/formatters';
import {
    DebtAndValueKpis,
    PropertyDetailsProps,
    PropertyType,
} from 'waypoint-types';
import { safeDivision } from 'waypoint-utils';
import { AttributeParams } from './types';
import { DASH_DASH } from 'config/constants';
import { monthDisplayNames } from 'utils/consts';
import moment from 'moment';
import ValuationGrossValueButton from './ValuationGrossValueButton';
import { PropertyProfileGavSources } from './PropertyProfileConstants';

export enum KPIMode {
    PSF = 'psf',
    PUNIT = 'punit',
}

export enum TimingPreference {
    T_12 = 'trailing_12',
    YTD = 'ytd',
}

export enum PropertyProfileMenuSections {
    Overview = 'overview',
    StrategyAndPlanning = 'strategyAndPlanning',
    Leasing = 'leases',
    Financials = 'financials',
    Valuations = 'valuations',
    Documents = 'documents',
}

export const calculateKPI = (
    mode: KPIMode,
    metric: number,
    leasePropertyData: PropertyDetailsProps | undefined,
) => {
    if (!metric || !leasePropertyData) {
        return '--';
    }

    if (mode === KPIMode.PSF && leasePropertyData.rentable_sq_ft > 0) {
        return `$${formatNumberToDecimalPlace(
            safeDivision(metric, leasePropertyData.rentable_sq_ft),
            2,
        )}`;
    }

    if (mode === KPIMode.PUNIT && leasePropertyData.total_units > 0) {
        return `$${formatNumberToDecimalPlace(
            safeDivision(metric, leasePropertyData.total_units),
            2,
        )}`;
    }

    return '--';
};

export const calculateParkingRatio = (
    mode: KPIMode,
    clientParkingSpaces: number,
    leasePropertyData: PropertyDetailsProps | undefined,
) => {
    if (!clientParkingSpaces || !leasePropertyData) {
        return '--';
    }

    if (mode === KPIMode.PSF) {
        const rentableSqFtRatioPer1000SF = safeDivision(
            leasePropertyData.rentable_sq_ft,
            1000,
        );
        return `${formatNumberToDecimalPlace(
            safeDivision(clientParkingSpaces, rentableSqFtRatioPer1000SF),
            1,
        )} / 1,000 SF`;
    }

    if (mode === KPIMode.PUNIT) {
        return `${formatNumberToDecimalPlace(
            safeDivision(clientParkingSpaces, leasePropertyData.total_units),
            1,
        )} / unit`;
    }

    return '--';
};

export const addToParamsIfRequired = (
    acc: any[],
    { shouldAdd, attributeValue, attributeCode, entityCode }: AttributeParams,
) => {
    if (shouldAdd) {
        acc.push({
            attribute_codes: [attributeCode],
            attribute_values: Array.isArray(attributeValue)
                ? attributeValue
                : [`${attributeValue}`],
            entity_code: entityCode,
            is_standard_attribute: true,
        });
    }
    return acc;
};

export const getFormattedLabel = (labelText: string) => {
    return (
        <span style={{ fontWeight: 'bold', color: 'black' }}>{labelText}</span>
    );
};

export const monthsAsFinancialYearEndings = () => {
    const isLeapYear = moment().isLeapYear();
    return monthDisplayNames.map((option) => {
        const isLeapOption = isLeapYear && option === monthDisplayNames[1];
        const endOfMonth = isLeapOption
            ? moment().month(option).endOf('month').subtract(1, 'day')
            : moment().month(option).endOf('month');
        return {
            value: endOfMonth.format('MM/DD'),
            label: option,
        };
    });
};

export const renderGrossValue = ({
    gavSource,
    gavSourceType,
    entityCode,
    propertyInfo,
    periodSelected,
    asOfDate,
    valueAndDebtData,
    selectedValuation,
    setSelectedValuation,
}: {
    gavSource: string;
    gavSourceType: typeof PropertyProfileGavSources;
    entityCode: string;
    propertyInfo: PropertyType;
    periodSelected: Date | null;
    asOfDate?: Date | undefined;
    valueAndDebtData: DebtAndValueKpis;
    selectedValuation?: number;
    setSelectedValuation?: (selectedValuations: number) => void;
}) => {
    if (gavSource === gavSourceType.VALUATIONS) {
        return (
            <ValuationGrossValueButton
                entityCode={entityCode}
                property={propertyInfo}
                periodSelected={periodSelected || asOfDate}
                selectedValuation={selectedValuation}
                setSelectedValuation={setSelectedValuation}
            />
        );
    }

    if (valueAndDebtData?.gross_value) {
        return formatMoney(valueAndDebtData.gross_value);
    }

    return DASH_DASH;
};
