import React, { useMemo, useState } from 'react';
import {
    COMPARISON_TYPE,
    DISABLED_VALUE,
    NONE_VALUE,
    PERIODICITY_FIELD,
    PERIOD_FIELD_NAME_PRIMARY,
    VARIANCE_DISPLAY,
    VARIANCE_DISPLAY_TERTIARY,
} from 'components/financials/comparative-income-statement/constants';
import { useIncomeStatementSettings } from 'waypoint-hooks';
import { Checkbox } from 'waypoint-react';
import {
    getComparisonPeriodSelect,
    getPeriodicitySelect,
    getComparisonTypeSelect,
    getPeriodRangeSelect,
    getVarianceOptions,
    getYearEndingSelect,
    PRIMARY_COLUMN_INDEX,
    SECONDARY_COLUMN_INDEX,
    TERTIARY_COLUMN_INDEX,
    getVarianceComparisonOptions,
} from 'components/financials/comparative-income-statement/selections/ComparativeIncomeStatementSelectionUtils';
import { ComparisonSelections } from 'components/financials/comparative-income-statement/ComparisonIncomeStatementTypes';
import { ReportWidgetSettingsBuilderProps } from 'waypoint-types';
import ReportWidgetSettingsModal from 'components/reports/report-widget/ReportWidgetSettingsModal';
import {
    AccountGraphSettingsBuilder,
    convertComparativeIncomeStatementComparisonSelections,
} from './utils';
import { FinancialExportOptions } from '../../constants';
import { buildStringToComparisonTypeString } from 'components/financials/comparative-income-statement/banner/ComparisonSelectionsBannerUtils';

const IncomeStatementSettingsBuilder = ({
    entityCodes,
    isModalOpen,
    setIsModalOpen,
    onSaveWidgetSettings,
    widgetType,
    currentSettings,
}: ReportWidgetSettingsBuilderProps): JSX.Element => {
    const {
        localSelections,
        setLocalSelections,
        getUserSelectionFor,
        localModes,
        handleOnChange,
        rangeSelectIsOpen,
        setRangeSelectIsOpen,
        handleOnChangeDateRange,
        comparisonTypeOptions,
        comparisonTypeOptionsWithNone,
        clientFinancialYearEnding,
    } = useIncomeStatementSettings(entityCodes);

    const [hideNull, setHideNull] = useState<boolean>(
        currentSettings?.is_hide_null ?? false
    );
    const [hideNotes, setHideNotes] = useState<boolean>(
        currentSettings?.is_hide_notes ?? false
    );
    const [showPerSF, setShowPerSF] = useState<boolean>(
        currentSettings?.show_per_sf ?? false
    );
    const [showPerUnit, setShowPerUnit] = useState<boolean>(
        currentSettings?.show_per_unit ?? false
    );
    const [showCurrentPeriod, setShowCurrentPeriod] = useState<boolean>(
        currentSettings?.show_current_period ?? false
    );
    const [exportSetting, setExportSetting] = useState(
        currentSettings?.export_setting ?? ''
    );
    const [treeSelectVisible, setTreeSelectVisible] = useState(
        currentSettings?.export_setting === FinancialExportOptions.CUSTOM ??
            false
    );
    const [accountMappingSelection, setAccountMappingSelection] = useState<
        string[] | null
    >(currentSettings?.account_mapping_selections ?? null);

    useMemo(() => {
        if (currentSettings?.comparison_selections) {
            setLocalSelections(
                convertComparativeIncomeStatementComparisonSelections(
                    currentSettings.comparison_selections
                )
            );
        }
    }, [currentSettings?.comparison_selections]);

    const settingsInputs = {
        comparison_selections: currentSettings?.comparison_selections ?? {},
        is_hide_null: currentSettings?.is_hide_null ?? false,
        is_hide_notes: currentSettings?.is_hide_notes ?? false,
        show_per_sf: currentSettings?.show_per_sf ?? false,
        show_per_unit: currentSettings?.show_per_unit ?? false,
        show_current_period: currentSettings?.show_current_period ?? false,
        export_setting: exportSetting,
        account_mapping_selections: accountMappingSelection,
    };

    const buildReportWidgetSettingsInputs = () => {
        settingsInputs.comparison_selections = handleOnChangeDateRange(
            getUserSelectionFor(PERIOD_FIELD_NAME_PRIMARY)[1]
        ) as ComparisonSelections;
        settingsInputs.is_hide_null = hideNull;
        settingsInputs.is_hide_notes = hideNotes;
        settingsInputs.show_per_sf = showPerSF;
        settingsInputs.show_per_unit = showPerUnit;
        settingsInputs.show_current_period = showCurrentPeriod;
        settingsInputs.export_setting = exportSetting;
        settingsInputs.account_mapping_selections = accountMappingSelection;
        return settingsInputs;
    };

    if (!localSelections) {
        return <></>;
    }

    const showYearEndingSelect = () => {
        return !['trailing_12', 'custom'].includes(
            getUserSelectionFor(PERIODICITY_FIELD)
        );
    };

    const isVarianceComparisonSelectDisabled = (
        varianceDisplay: string,
        isTertiary = false
    ) => {
        const isNoneSecondComparisonValueSelected =
            getUserSelectionFor(COMPARISON_TYPE)[isTertiary ? 2 : 1] ===
            NONE_VALUE;
        const isDisabledVarianceDisplayTertiarySelected =
            getUserSelectionFor(varianceDisplay) === DISABLED_VALUE;
        return (
            isNoneSecondComparisonValueSelected ||
            isDisabledVarianceDisplayTertiarySelected
        );
    };

    return (
        <ReportWidgetSettingsModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            settingsJsonBuilder={buildReportWidgetSettingsInputs}
            onSaveWidgetSettings={onSaveWidgetSettings}
            widgetType={widgetType}
            width={600}
        >
            <div
                style={{
                    marginBottom: '25px',
                    marginTop: '25px',
                    display: 'flex',
                }}
            >
                <div
                    style={{
                        display: 'inline-block',
                        marginRight: '20px',
                    }}
                >
                    {getComparisonTypeSelect(
                        getUserSelectionFor,
                        comparisonTypeOptions,
                        handleOnChange,
                        'Column 1',
                        PRIMARY_COLUMN_INDEX
                    )}
                </div>
                {getPeriodicitySelect(
                    buildStringToComparisonTypeString([
                        ...getUserSelectionFor(COMPARISON_TYPE),
                    ]),
                    getUserSelectionFor,
                    handleOnChange
                )}
                {getPeriodRangeSelect(
                    getUserSelectionFor,
                    rangeSelectIsOpen,
                    setRangeSelectIsOpen,
                    handleOnChange,
                    handleOnChangeDateRange
                )}
                {showYearEndingSelect() ? (
                    <div
                        style={{
                            display: 'inline-block',
                        }}
                    >
                        {getYearEndingSelect(
                            getUserSelectionFor,
                            handleOnChange,
                            clientFinancialYearEnding
                        )}
                    </div>
                ) : (
                    <></>
                )}
            </div>
            <div
                style={{
                    marginBottom: '25px',
                    marginTop: '0',
                    display: 'flex',
                }}
            >
                <div
                    style={{
                        display: 'inline-block',
                        marginRight: '20px',
                    }}
                >
                    {getComparisonTypeSelect(
                        getUserSelectionFor,
                        comparisonTypeOptionsWithNone,
                        handleOnChange,
                        'Column 2',
                        SECONDARY_COLUMN_INDEX
                    )}
                </div>
                {getComparisonPeriodSelect(
                    getUserSelectionFor,
                    localModes,
                    handleOnChange
                )}
                {getVarianceOptions(
                    getUserSelectionFor,
                    handleOnChange,
                    getUserSelectionFor(COMPARISON_TYPE)[1] === NONE_VALUE
                )}
                <div>
                    {localSelections?.comparison_type ? (
                        getVarianceComparisonOptions(
                            getUserSelectionFor,
                            handleOnChange,
                            isVarianceComparisonSelectDisabled(
                                VARIANCE_DISPLAY
                            ),
                            localSelections.comparison_type,
                            SECONDARY_COLUMN_INDEX
                        )
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            <div
                style={{
                    marginTop: '0',
                    marginBottom: '25px',
                    display: 'flex',
                }}
            >
                <div
                    style={{
                        display: 'inline-block',
                        marginRight: '20px',
                    }}
                >
                    {getComparisonTypeSelect(
                        getUserSelectionFor,
                        comparisonTypeOptionsWithNone,
                        handleOnChange,
                        'Column 3',
                        TERTIARY_COLUMN_INDEX
                    )}
                </div>
                {getComparisonPeriodSelect(
                    getUserSelectionFor,
                    localModes,
                    handleOnChange,
                    TERTIARY_COLUMN_INDEX
                )}
                {getVarianceOptions(
                    getUserSelectionFor,
                    handleOnChange,
                    getUserSelectionFor(COMPARISON_TYPE)[2] === NONE_VALUE,
                    TERTIARY_COLUMN_INDEX
                )}
                <div>
                    {localSelections?.comparison_type ? (
                        getVarianceComparisonOptions(
                            getUserSelectionFor,
                            handleOnChange,
                            isVarianceComparisonSelectDisabled(
                                VARIANCE_DISPLAY_TERTIARY,
                                true
                            ),
                            localSelections?.comparison_type,
                            TERTIARY_COLUMN_INDEX
                        )
                    ) : (
                        <></>
                    )}
                </div>
            </div>

            <div style={{ display: 'flex' }}>
                <div>
                    <div style={{ marginTop: '10px' }}>
                        <Checkbox
                            checked={hideNull}
                            onChange={(ev) => setHideNull(ev.target.checked)}
                        >
                            <span>Hide Zeros/Nulls</span>
                        </Checkbox>
                    </div>

                    <div style={{ marginTop: '15px' }}>
                        <Checkbox
                            checked={hideNotes}
                            onChange={(ev) => setHideNotes(ev.target.checked)}
                        >
                            <span>Hide Notes</span>
                        </Checkbox>
                    </div>
                </div>
                <div style={{ marginLeft: '25px' }}>
                    <div style={{ marginTop: '10px' }}>
                        <Checkbox
                            checked={showPerSF}
                            onChange={(ev) => setShowPerSF(ev.target.checked)}
                        >
                            <span>Show $/SF</span>
                        </Checkbox>
                    </div>

                    <div style={{ marginTop: '15px', marginBottom: '20px' }}>
                        <Checkbox
                            checked={showPerUnit}
                            onChange={(ev) => setShowPerUnit(ev.target.checked)}
                        >
                            <span>Show $/Unit</span>
                        </Checkbox>
                    </div>
                </div>
                <div style={{ marginLeft: '25px' }}>
                    <div style={{ marginTop: '10px' }}>
                        <Checkbox
                            checked={showCurrentPeriod}
                            onChange={(ev) =>
                                setShowCurrentPeriod(ev.target.checked)
                            }
                        >
                            <span>Show current period</span>
                        </Checkbox>
                    </div>
                </div>
            </div>

            <AccountGraphSettingsBuilder
                treeSelectVisible={treeSelectVisible}
                setTreeSelectVisible={setTreeSelectVisible}
                accountMappingSelection={accountMappingSelection}
                setAccountMappingSelection={setAccountMappingSelection}
                accountGraphType={'income_statement'}
                exportSetting={exportSetting}
                setExportSetting={setExportSetting}
            />
        </ReportWidgetSettingsModal>
    );
};

export default IncomeStatementSettingsBuilder;
