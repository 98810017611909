import PropTypes from 'prop-types';
import React from 'react';
import { assign } from 'lodash';

const toggleItem = (selected, isDisabled, position) => {
    let cursor = 'pointer';
    let color = '#828282';
    let backgroundColor = '';
    let borderRadius = null;
    let fontWeight = null;
    if (selected) {
        cursor = 'default';
        color = '#FFFFFF';
        backgroundColor = '#32A3DD';
        fontWeight = 'bold';
    }
    if (isDisabled) {
        cursor = 'not-allowed';
        color = '#D8D8D8';
    }
    if (isDisabled && selected) {
        color = '#FFFFFF';
        backgroundColor = '#D8D8D8';
    }
    if (position === 'start') {
        borderRadius = '2px 0px 0px 2px';
    }
    if (position === 'end') {
        borderRadius = '0px 2px 2px 0px';
    }
    return {
        cursor,
        color,
        borderRadius,
        backgroundColor,
        fontWeight,
        display: 'flex',
        justifyContent: 'center',
        minWidth: '60px',
        padding: '3px',
        height: '100%',
    };
};

const ToggleButton = (props) => {
    const { title, isDisabled, isSelected, style, onClick, position } = props;
    return (
        <div
            style={assign(
                {},
                toggleItem(isSelected, isDisabled, position),
                style
            )}
            onClick={!isDisabled && onClick}
        >
            {title}
        </div>
    );
};

ToggleButton.propTypes = {
    onClick: PropTypes.func,
    title: PropTypes.string,
    style: PropTypes.object,
    position: PropTypes.string,
    isSelected: PropTypes.bool,
    isDisabled: PropTypes.bool,
};

export default ToggleButton;
