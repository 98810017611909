import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import useSWR from 'swr';
import { getLeasingAsOfDateByEntityCode } from 'waypoint-requests';
import { message } from 'antd';

export const useGetLeasingAsOfDateByEntityCode = (
    entityCode: string
): DataHookResponse<string> => {
    const asOfDatesCacheKey = `/api/as-of-date/leasing-by-entity/${entityCode}`;

    const { data, error, mutate, isValidating } = useSWR(
        asOfDatesCacheKey,
        () => getLeasingAsOfDateByEntityCode(entityCode),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
            shouldRetryOnError: false,
            onError: () => {
                message.error('Failed to fetch leasing as of date');
            },
        }
    );

    return {
        isLoading: !data || isValidating,
        isError: !!error,
        data,
        mutate,
    };
};
