import { API_URL } from 'config/constants';

interface HeartbeatResponseDTO {
    client_id: number;
    client_name: string;
    email: string;
    firstname: string;
    lastname: string;
    roles: string[];
    user_id: number;
    application_version: string;
}

export async function getHeartbeat(): Promise<HeartbeatResponseDTO> {
    const response = await fetch(`${API_URL}/heartbeat`, {
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
    });

    if (!response.ok) {
        throw new Error('Failed retrieve heartbeat');
    }

    const { data } = await response.json();
    const {
        client_id,
        client_name,
        email,
        firstname,
        lastname,
        roles,
        user_id,
        application_version,
    } = data;

    return {
        client_id,
        client_name,
        email,
        firstname,
        lastname,
        roles,
        user_id,
        application_version,
    };
}
