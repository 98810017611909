import React, { useContext } from 'react';
import { css } from 'emotion';
import { Select } from 'antd';
import { SelectOptionType } from 'components/app/global-filter-drawer/types/globalFilterTypes';
import { ACTIVE_GLOBAL_FILTER_KEY } from 'components/app/global-filter-drawer/GlobalFilterConstants';
import { useClientPrefixedLocalStorage } from 'waypoint-hooks';
import { SavedFilter, SavedFiltersContext } from 'contexts';
import { stringSort } from 'utils/tables/sorters';

const savedFilterDropdownStyle = css`
    min-width: 200px;
    margin-left: 10px !important;
    align-self: center;
    width: 200px;
`;

function getOptionsFromSavedFilters(savedFilters: any[]): SelectOptionType[] {
    return savedFilters
        .map(({ id, name }) => ({ value: id, label: name }))
        .sort((a, b) => stringSort(b.label, a.label));
}

const SavedFilterDropdown = (): JSX.Element => {
    const savedFilterContext = useContext(SavedFiltersContext);

    const [activeFilter, setActiveFilter] =
        useClientPrefixedLocalStorage<SavedFilter | null>(
            ACTIVE_GLOBAL_FILTER_KEY,
            null
        );

    const options = savedFilterContext?.savedFilters
        ? getOptionsFromSavedFilters(savedFilterContext.savedFilters)
        : [];

    return (
        <Select
            style={{ width: '50%' }}
            options={options}
            value={activeFilter?.id}
            className={savedFilterDropdownStyle}
            placeholder="Select a saved filter"
            onSelect={(id) => {
                if (!id || !savedFilterContext?.savedFilters) {
                    return;
                }

                const selectedFilter = savedFilterContext.savedFilters.find(
                    (x) => x.id === id
                );

                setActiveFilter(selectedFilter ?? null);
            }}
        />
    );
};

export default SavedFilterDropdown;
