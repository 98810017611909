import { SelectedDataLevel } from 'waypoint-types';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import useSWR from 'swr';
import { getRentRollTotals } from 'waypoint-requests';

export const useRentRollTotals = (
    entityCodes: string[],
    selectedDataLevel: SelectedDataLevel
): DataHookResponse<{ data: number[] }> => {
    const rentRollCacheKey = `/leases/rent-roll-totals/${entityCodes.join(
        ','
    )}/${selectedDataLevel?.percentageType ?? ''}/${
        selectedDataLevel?.stakeholder ?? ''
    }`;

    const { data, error, mutate, isValidating } = useSWR(
        entityCodes.length ? rentRollCacheKey : null,
        () =>
            getRentRollTotals({
                entityCodes,
                selectedDataLevel,
            }),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
        }
    );

    return {
        isLoading: !data || isValidating,
        isError: !!error,
        data,
        mutate,
    };
};
