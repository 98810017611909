import { DataGrid } from 'devextreme-react';
import {
    Column,
    HeaderFilter,
    Scrolling,
    Summary,
    TotalItem,
} from 'devextreme-react/data-grid';
import {
    ContentReadyEvent,
    FocusedRowChangedEvent,
} from 'devextreme/ui/data_grid';
import React, { useEffect, useMemo, useState } from 'react';
import { Dictionary } from 'ts-essentials';
import { useCommentsPane } from 'contexts/comments/CommentsContext';
import { useGetMentionableUsers } from 'waypoint-hooks';
import { DASH_DASH } from 'config/constants';
import { safeDivision } from 'waypoint-utils';
import {
    EntityProfileCard,
    PlanningDataSourceType,
    PrioritiesAndObjectivesGridItem,
} from 'waypoint-types';
import { CommentsBadge } from 'waypoint-react/comment/CommentsBadge';
interface PrioritiesObjectivesGridProps {
    dataSource: PlanningDataSourceType[];
    selectedRowRecommendation: string;
    setSelectedRowRecommendation: (selectedRowRecommendation: string) => void;
    height: number;
    yearFilter: number;
    prioritiesObjectivesData: EntityProfileCard[] | undefined;
}

const PrioritiesObjectivesGrid = ({
    dataSource,
    selectedRowRecommendation,
    setSelectedRowRecommendation,
    height,
    yearFilter,
    prioritiesObjectivesData,
}: PrioritiesObjectivesGridProps) => {
    const [shouldDefaultFocus, setShouldDefaultFocus] = useState<boolean>(true);
    const userMentionOptions = useGetMentionableUsers([
        selectedRowRecommendation,
    ]);

    useEffect(() => {
        updateCommentsPane({
            userMentionOptions,
        });
    }, [userMentionOptions]);

    const {
        closeComments,
        initializeComments,
        update: updateCommentsPane,
    } = useCommentsPane();

    const onContentReady = (e: ContentReadyEvent) => {
        const hasFilters = e.component.getCombinedFilter();
        if (hasFilters && shouldDefaultFocus) {
            setSelectedRowRecommendation(
                e.component.getDataSource().items()[0].entityCode
            );
            if (hasFilters) {
                setShouldDefaultFocus(false);
            }
            return;
        }
        if (!hasFilters) {
            setShouldDefaultFocus(true);
            return;
        }
    };

    const onFocusedRowChanged = (e: FocusedRowChangedEvent) => {
        const entityCode = e?.row?.data.entityCode ?? '';
        setSelectedRowRecommendation(entityCode);
        const commentThreadId = e?.row?.data.commentThreadId;
        if (commentThreadId) {
            initializeComments(commentThreadId);
        } else {
            closeComments();
        }
    };

    const formatDataWithPrioritiesAndObjectives = useMemo(() => {
        return dataSource.map((property) => {
            const completedItems =
                prioritiesObjectivesData
                    ?.filter((poItem) => poItem.year === yearFilter)
                    .filter(
                        (poItem) => poItem.entity_code === property.entityCode
                    )
                    .filter(
                        (poItem) => poItem.metadata?.[0].value === 'complete'
                    ).length ?? 0;

            const totalItems =
                prioritiesObjectivesData
                    ?.filter((poItem) => poItem.year === yearFilter)
                    .filter(
                        (poItem) => poItem.entity_code === property.entityCode
                    ).length ?? 0;

            const percentageCompleted =
                safeDivision(completedItems, totalItems) > 0
                    ? safeDivision(completedItems, totalItems)
                    : null;

            return {
                ...property,
                completed_items: completedItems,
                total_items: totalItems,
                percentage_completed: percentageCompleted?.toLocaleString(
                    'en-US',
                    {
                        maximumFractionDigits: 1,
                        style: 'percent',
                    }
                ),
            };
        });
    }, [dataSource, prioritiesObjectivesData, yearFilter]);

    const renderCommentThread = (
        cellInfo: Dictionary<PlanningDataSourceType>
    ) => {
        return (
            <CommentsBadge commentThreadId={cellInfo.data.commentThreadId} />
        );
    };

    const renderPercentage = (
        cellInfo: Dictionary<PrioritiesAndObjectivesGridItem>
    ) => {
        return cellInfo.data.percentage_completed ?? DASH_DASH;
    };

    const customizeTotalItemText = (data: Dictionary<any, string>) => {
        return (data.value / 100).toLocaleString('en-US', {
            maximumFractionDigits: 1,
            style: 'percent',
        });
    };

    return (
        <DataGrid
            dataSource={formatDataWithPrioritiesAndObjectives}
            keyExpr={'entityCode'}
            height={height}
            allowColumnReordering={true}
            rowAlternationEnabled={true}
            focusedRowEnabled={true}
            focusedRowKey={selectedRowRecommendation}
            onFocusedRowChanged={onFocusedRowChanged}
            showBorders={true}
            paging={{ enabled: false }}
            onContentReady={onContentReady}
            columnMinWidth={50}
        >
            <HeaderFilter visible={true} allowSearch={true} height={250} />

            <Column dataField="entityCode" visible={false} />

            <Column
                dataField="entityName"
                caption="Property"
                allowFiltering={true}
                minWidth={200}
                sortOrder={'asc'}
            />

            <Column
                caption="Total"
                dataField="total_items"
                alignment="center"
                allowFiltering={false}
                width={100}
            />

            <Column
                caption="Complete"
                dataField="completed_items"
                alignment="center"
                allowFiltering={false}
                width={100}
            />

            <Column
                caption="% Complete"
                dataField="percentage_completed"
                alignment="center"
                allowFiltering={false}
                dataType="number"
                width={100}
                cellRender={renderPercentage}
            />

            <Column
                dataField="commentCount"
                caption=""
                allowExporting={false}
                allowFiltering={false}
                cellRender={renderCommentThread}
                alignment={'center'}
                width={50}
            />

            <Summary>
                <TotalItem
                    column="entityName"
                    name="entityName"
                    displayFormat="Total"
                />
                <TotalItem
                    column="completed_items"
                    name="percentage"
                    summaryType="sum"
                    displayFormat="{0}"
                />
                <TotalItem
                    column="total_items"
                    name="total_items"
                    summaryType="sum"
                    displayFormat="{0}"
                />
                <TotalItem
                    column="percentage_completed"
                    name="percentage_completed"
                    summaryType="avg"
                    displayFormat="{0}%"
                    customizeText={customizeTotalItemText}
                    skipEmptyValues
                />
            </Summary>

            <Scrolling />
        </DataGrid>
    );
};

export default PrioritiesObjectivesGrid;
