import React, { ReactElement } from 'react';
import { useEntityOwnershipContext } from 'waypoint-hooks';
import { Button, Popover, Select, Space, Spin, Typography } from 'antd';
import { ClientOwnershipPercentageType } from 'waypoint-types';
import { DownOutlined } from '@ant-design/icons';

export function EntityOwnershipDataLevelDropdown(): ReactElement {
    const ownershipContext = useEntityOwnershipContext();

    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [selectedPercentageTypeValue, setSelectedPercentageTypeValue] =
        React.useState<string | null>(
            ownershipContext?.data.selectedDataLevel.percentageType ?? null
        );
    const [selectedStakeholder, setSelectedStakeholder] = React.useState<
        string | null
    >(ownershipContext?.data.selectedDataLevel.stakeholder ?? null);

    function handleOpenChange(newOpen: boolean) {
        setIsOpen(newOpen);

        if (!newOpen) {
            setSelectedPercentageTypeValue(
                ownershipContext?.data.selectedDataLevel.percentageType ?? null
            );
            setSelectedStakeholder(
                ownershipContext?.data.selectedDataLevel.stakeholder ?? null
            );
        }
    }

    function applySelections() {
        if (!ownershipContext) {
            return;
        }

        ownershipContext.data.setSelectedDataLevel({
            percentageType: selectedPercentageTypeValue,
            stakeholder: selectedStakeholder,
        });

        setIsOpen(false);
    }

    if (
        !ownershipContext ||
        ownershipContext.isLoading ||
        ownershipContext.isError
    ) {
        return <Spin />;
    }

    const { data } = ownershipContext;

    function getPercentageTypeFromValue(
        value: string | null
    ): ClientOwnershipPercentageType | null {
        return (
            data.percentageTypeOptions?.find(
                (percentageType) => percentageType.value === value
            ) ?? null
        );
    }

    function handleChangeSelectedPercentageTypeValue(value: string) {
        const newPercentageType = getPercentageTypeFromValue(value);

        if (!newPercentageType) {
            return;
        }

        setSelectedPercentageTypeValue(value);
        setSelectedStakeholder(
            newPercentageType?.stakeholders.length
                ? newPercentageType.stakeholders[0].value
                : null
        );
    }

    const selectedPercentageType = getPercentageTypeFromValue(
        selectedPercentageTypeValue
    );

    const content = (
        <Space direction="vertical">
            <Typography.Text>Data Level</Typography.Text>

            <Select
                defaultValue={null}
                value={selectedPercentageTypeValue}
                options={data.percentageTypeOptions}
                style={{ minWidth: '200px' }}
                onChange={handleChangeSelectedPercentageTypeValue}
            />

            {selectedPercentageType?.stakeholders.length ? (
                <Select
                    showSearch
                    value={selectedStakeholder}
                    options={selectedPercentageType.stakeholders}
                    style={{ minWidth: '200px' }}
                    onChange={setSelectedStakeholder}
                />
            ) : null}

            <Space>
                <Button onClick={() => handleOpenChange(false)}>Cancel</Button>
                <Button type="primary" onClick={applySelections}>
                    Apply
                </Button>
            </Space>
        </Space>
    );

    const {
        percentageType: appliedPercentageTypeValue,
        stakeholder: appliedStakeholder,
    } = ownershipContext.data.selectedDataLevel;
    const appliedPercentageType = getPercentageTypeFromValue(
        appliedPercentageTypeValue
    );

    return (
        <Popover
            content={content}
            open={isOpen}
            onOpenChange={handleOpenChange}
            trigger="click"
            placement="bottom"
            arrow={false}
        >
            <Button>
                Data Level: {appliedPercentageType?.label ?? 'error'}
                {appliedStakeholder ? ` (${appliedStakeholder})` : ''}{' '}
                <DownOutlined />
            </Button>
        </Popover>
    );
}
