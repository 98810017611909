import { API_URL } from 'config/constants';
import { PropertyDetailsProps, SelectedDataLevel } from 'waypoint-types';

/**
 * @param {array} entityCodes
 * @param selectedDataLevel
 * @returns property lease details for a group
 */
const getLeasesPerformanceOverview = async (
    entityCodes: string[],
    selectedDataLevel: SelectedDataLevel
): Promise<PropertyDetailsProps[]> => {
    const response = await fetch(`${API_URL}/leases/performance-overview`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
            entity_codes: entityCodes,
            selected_data_level: selectedDataLevel,
        }),
    });

    if (!response.ok) {
        throw new Error('FAILED');
    }
    const { data } = await response.json();

    return data;
};

export default getLeasesPerformanceOverview;
