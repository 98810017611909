import React from 'react';
import { pick } from 'lodash';
import { Carousel } from 'antd';
import { css, cx } from 'emotion';

import theme from 'config/theme';
import { StatisticsCard, ActionsCard } from 'waypoint-react';

const contentStyle = css`
    height: 80px;
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
        color: ${theme.colors.black};
    }
`;

const cardStyle = css`
    .slick-dots li.slick-active button {
        background: ${theme.colors.blues.primary} !important;
    }
    .slick-dots li button {
        border-radius: 5px !important;
        background: ${theme.colors.grays.medium} !important;
        height: 7px !important;
    }
    .slick-dots-bottom {
        bottom: 0 !important;
    }
`;

interface CarouselLeaseCardProps {
    content: JSX.Element[];
    loading: boolean;
    label: string;
    error: boolean;
}

const CarouselLeaseCard = ({
    content,
    loading,
    label,
    error,
    ...props
}: CarouselLeaseCardProps) => {
    const gridProps: any = pick(props, [
        'className',
        'data-grid',
        'style',
        'key',
    ]);

    if (!content || loading) {
        return (
            <StatisticsCard
                {...gridProps}
                title={label}
                style={{ ...gridProps.style }}
                className={cx([gridProps.className, cardStyle])}
                loading={loading}
            />
        );
    }

    return (
        <ActionsCard
            title={label}
            {...gridProps}
            className={cx([gridProps.className, cardStyle])}
            extra={<span />}
        >
            <Carousel>
                {content.map((d) => {
                    return (
                        <div key={d.key}>
                            <div className={contentStyle}>{d}</div>
                        </div>
                    );
                })}
            </Carousel>
        </ActionsCard>
    );
};

export default CarouselLeaseCard;
