import { groupBy } from 'lodash';
import { Dictionary } from 'ts-essentials';
import {
    CrosstabAccount,
    CrosstabAccountData,
    CrosstabAccountNode,
} from 'components/financials/crosstab/accounts';
import { DataRecord } from 'components/financials/crosstab/CrosstabTypes';
import { AccountTreeNode } from 'waypoint-types';
import {
    CROSSTAB_PRIMARY_MODE,
    CROSSTAB_SECONDARY_MODE,
} from '../CrosstabConstants';

const findMaxDepth = (node: CrosstabAccountNode, depth: number): number => {
    if (!node.children) {
        return depth;
    }
    let maxDepth = depth;
    for (let x of node.children.values()) {
        maxDepth = Math.max(findMaxDepth(x, depth + 1), maxDepth);
    }
    return maxDepth;
};

/**
 * This should be called once per dataset. Result should be cached/memoized.
 */
export const getExpandedCrosstabRows = (
    rootNode: CrosstabAccount,
    dataRows: CrosstabAccountData[],
    displayNamesByEntityCode: Dictionary<string>,
    selectedAttributeByEntityCode: Dictionary<string>,
    modeSelection: string[]
): DataRecord[] => {
    const rowsGroupByAccountMappingCode: Dictionary<CrosstabAccountData[]> =
        groupBy(dataRows, 'ACCOUNT_MAPPING_CODE' as keyof CrosstabAccountData);

    const maxDepth = findMaxDepth(rootNode, 0);

    let expandedRows: DataRecord[] = [];

    try {
        expandedRows = expandRows(
            rowsGroupByAccountMappingCode,
            rootNode,
            displayNamesByEntityCode,
            selectedAttributeByEntityCode,
            maxDepth,
            modeSelection
        ) as unknown as DataRecord[];
    } catch (e) {
        console.error('getCrosstabGroupStructure error expanding rows', e);
    }

    return expandedRows;
};

const expandRows = (
    rowsGroupByAccountMappingCode: Dictionary<CrosstabAccountData[]>,
    node: CrosstabAccountNode,
    displayNamesByEntityCode: Dictionary<string>,
    selectedAttributeByEntityCode: Dictionary<string>,
    maxDepth: number,
    modeSelection: string[]
): Dictionary<string | number | boolean | Date>[] => {
    if (node.children) {
        const expandChild = (child: CrosstabAccountNode) =>
            expandRows(
                rowsGroupByAccountMappingCode,
                child,
                displayNamesByEntityCode,
                selectedAttributeByEntityCode,
                maxDepth,
                modeSelection
            );

        return Array.from(node.children.values()).flatMap(expandChild);
    }

    if (!node.meta || !node.meta.account_mapping_code) {
        return [];
    }

    const rows: CrosstabAccountData[] =
        rowsGroupByAccountMappingCode[node.meta.account_mapping_code] ?? [];

    const parentAccounts = getParentAccounts(node, maxDepth);

    return rows.map((row) =>
        expandRow(
            node,
            row,
            displayNamesByEntityCode,
            selectedAttributeByEntityCode,
            parentAccounts,
            modeSelection
        )
    );
};

const getParentAccounts = (
    node: CrosstabAccountNode,
    maxDepth: number
): AccountTreeNode[] => {
    const accounts: AccountTreeNode[] = [];
    let iterNode: CrosstabAccountNode = node;
    for (let i = 0; i < maxDepth; i++) {
        if (iterNode.meta === null) {
            console.error('meta was null at node:', iterNode);
            break;
        }

        const nodeMeta = iterNode.meta.account_mapping_code;

        if (!nodeMeta) {
            console.error(
                'missing meta for account code:',
                iterNode.meta.account_mapping_code
            );
            break;
        }

        accounts.push(iterNode.meta);

        if (iterNode.parent === null || iterNode.parent.meta === null) {
            break;
        }

        iterNode = iterNode.parent;
    }

    return accounts;
};

const expandRow = (
    node: CrosstabAccountNode,
    row: CrosstabAccountData,
    displayNamesByEntityCode: Dictionary<string>,
    selectedAttributeByEntityCode: Dictionary<string>,
    accountHierarchy: AccountTreeNode[],
    modeSelection: string[]
) => {
    const isCredit = node.meta?.is_credit ?? false;
    const varianceGross = isCredit
        ? row[CROSSTAB_PRIMARY_MODE] - row[CROSSTAB_SECONDARY_MODE]
        : row[CROSSTAB_SECONDARY_MODE] - row[CROSSTAB_PRIMARY_MODE];

    const rowExpansion: Dictionary<string | number | boolean | Date> = {
        ACCOUNT_MAPPING_CODE: row.ACCOUNT_MAPPING_CODE,
        ENTITY_CODE: row.ENTITY_CODE,
        DISPLAY_NAME: displayNamesByEntityCode[row.ENTITY_CODE],
        PERIOD_END: new Date(row.PERIOD_END),
        VARIANCE: varianceGross,
        ATTRIBUTE: selectedAttributeByEntityCode[row.ENTITY_CODE],
    };

    rowExpansion[CROSSTAB_PRIMARY_MODE] = row[CROSSTAB_PRIMARY_MODE];
    rowExpansion[CROSSTAB_SECONDARY_MODE] = row[CROSSTAB_SECONDARY_MODE];

    for (let depth = accountHierarchy.length - 1; depth >= 0; depth--) {
        const accountNode =
            accountHierarchy[accountHierarchy.length - 1 - depth];

        rowExpansion[`LVL${depth}CODE`] = accountNode.account_mapping_code;
        rowExpansion[`LVL${depth}NAME`] = accountNode.display_name;
    }

    return rowExpansion;
};
