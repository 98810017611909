import { API_URL } from 'config/constants';
import { AccountGraph, SelectedDataLevel } from 'waypoint-types';

interface RequestBalanceSheetProps {
    entityCodes: string[];
    primaryPeriod: string | undefined;
    secondaryPeriod: string | undefined;
    isHideNull?: boolean;
    reportMetadataId: string | null;
    selectedDataLevel: SelectedDataLevel;
}

export const requestBalanceSheet = (
    {
        entityCodes,
        primaryPeriod,
        secondaryPeriod,
        isHideNull,
        reportMetadataId,
        selectedDataLevel,
    }: RequestBalanceSheetProps,
    download?: boolean
): Promise<Response> => {
    return fetch(`${API_URL}/balance-sheet`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            Accept: download ? 'text/csv' : '*/*',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            periods: [primaryPeriod, secondaryPeriod],
            entity_codes: entityCodes,
            is_hide_null: isHideNull ?? false,
            report_metadata_id: reportMetadataId,
            selected_data_level: selectedDataLevel,
        }),
    });
};

export const getBalanceSheet = async (
    params: RequestBalanceSheetProps
): Promise<AccountGraph> => {
    const response = await requestBalanceSheet(params);

    if (!response.ok) {
        throw new Error('Failed');
    }

    const { data } = await response.json();
    return data;
};

export default getBalanceSheet;
