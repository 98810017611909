import { API_URL } from 'config/constants';
import { CreateReportWidgetParams } from 'waypoint-types';

export interface UpdateReportParams {
    id: string;
    name: string;
    due_date: string;
    entity_codes?: string[];
    widgets?: CreateReportWidgetParams[];
    state: string | null;
}

const updateReport = async (report: UpdateReportParams): Promise<string> => {
    const response = await fetch(`${API_URL}/reports/${report.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(report),
    });

    if (!response.ok) {
        throw new Error('Failed to update report');
    }

    const { data } = await response.json();

    return data;
};

export default updateReport;
