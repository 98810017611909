import useSWR from 'swr';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { getLeaseSummary } from 'waypoint-requests';
import { LeaseSummaryType, SelectedDataLevel } from 'waypoint-types';

export const useGetLeasesSummary = (
    entityCodes: string[],
    selectedDataLevel: SelectedDataLevel
): DataHookResponse<LeaseSummaryType> => {
    const entityCodesCacheKey = entityCodes.join(',');
    const { data, error, mutate } = useSWR(
        entityCodesCacheKey
            ? `/api/leases/summary/${entityCodesCacheKey}/${selectedDataLevel.percentageType}/${selectedDataLevel.stakeholder}`
            : null,
        () => getLeaseSummary(entityCodes, selectedDataLevel),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
            shouldRetryOnError: false,
        }
    );
    return {
        isLoading: !data,
        isError: !!error,
        data,
        mutate,
    };
};
