import React from 'react';
import { Input, Select } from 'antd';
import { DatePicker } from 'waypoint-react';
import ReportDetailDates from './ReportDetailDates';
import { statusOptions } from '../constants';
import { css } from 'emotion';
import { Report } from 'waypoint-types';
import { getReportStatusColor } from '../ReportUtils';
import moment, { Moment } from 'moment';

const badgeSelectStyle = css`
    min-width: 100px;
    box-shadow: none;
    border-radius: 8px;
    border: none;
    color: #fff;
    text-align: center;
    justify-content: center;
    margin-left: auto !important;
    .ant-select-selection-item {
        color: #fff !important;
    }
`;

const inputStyle = css`
    display: inline-flex !important;
    max-width: 50% !important;
    font-size: 26px !important;
    font-weight: 600;
    &:hover {
        background-color: #f3f3f3 !important;
        border: 1px dashed #424242 !important;
    }
`;

const sectionStyle = css`
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
    justity-content: space-between;
`;

interface ReportDetailHeaderProps {
    report?: Report;
    reportName: string;
    setReportName: (name: string) => void;
    onStatusChange: (status: string) => void;
    status: string;
    dueDate: moment.Moment | null;
    setDueDate: (date: moment.Moment | null) => void;
    isWorkflowSteps: boolean;
}

const ReportDetailHeader = ({
    report,
    reportName,
    setReportName,
    onStatusChange,
    status,
    dueDate,
    setDueDate,
    isWorkflowSteps,
}: ReportDetailHeaderProps) => {
    const disabledDate = (current: Moment | null): boolean => {
        return !!(current && current < moment().startOf('day'));
    };

    return (
        <div
            style={{
                width: '100%',
            }}
        >
            <div className={sectionStyle}>
                <Input
                    className={inputStyle}
                    value={reportName}
                    placeholder="Add Report Name"
                    bordered
                    size="large"
                    onChange={(e) => setReportName(e.target.value)}
                />

                <div style={{ marginLeft: 'auto' }}>
                    {!isWorkflowSteps ? (
                        <Select
                            bordered={false}
                            showArrow={false}
                            value={status}
                            className={badgeSelectStyle}
                            style={{
                                backgroundColor: getReportStatusColor(status),
                                color: '#fff',
                                marginRight: '15px',
                            }}
                            options={statusOptions}
                            onChange={onStatusChange}
                        />
                    ) : null}
                    <div
                        style={{
                            display: 'inline-flex',
                            fontSize: '16px',
                        }}
                    >
                        Due Date:
                    </div>
                    <DatePicker
                        value={dueDate}
                        style={{
                            width: '125px',
                            marginLeft: '12px',
                            borderRadius: '8px',
                        }}
                        placeholder="Due Date"
                        format={'MM-DD-YYYY'}
                        onChange={(e) => setDueDate(e)}
                        disabledDate={disabledDate}
                    />
                </div>
            </div>
            <div className={sectionStyle}>
                <div style={{ marginLeft: 'auto' }}>
                    {report?.created_by && (
                        <ReportDetailDates
                            date={report.timestamps.created_at}
                            user={report.createdByUser}
                            title="Created by"
                        />
                    )}

                    {report?.updated_by && (
                        <ReportDetailDates
                            date={report.timestamps.updated_at}
                            user={report.updatedByUser}
                            title="Last updated by"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ReportDetailHeader;
