import { API_URL } from 'config/constants';
import { PermissionGroup } from 'waypoint-types';

interface CreatePermissionGroupParams {
    name: string;
    permission_ids: string[];
    description: string;
    clientId: number;
}

export async function createPermissionGroup({
    name,
    permission_ids,
    description,
    clientId,
}: CreatePermissionGroupParams): Promise<PermissionGroup> {
    const response = await fetch(`${API_URL}/permission-groups/${clientId}`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            name,
            permission_ids,
            description,
        }),
    });

    if (!response.ok) {
        throw new Error('Failed to create permissions group');
    }

    const { data } = await response.json();

    return data as PermissionGroup;
}
