import './app-override.css';

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Userpilot } from 'userpilot';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Routes from './routes/Routes';
import store from './state/store';

Sentry.init({
    dsn: 'https://e758ebb0f92940089b0a1ca6f28cf360@o1060372.ingest.sentry.io/6049850',
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.NODE_ENV,
    tracesSampleRate: 1.0,
    release: process.env.SENTRY_RELEASE,
});

if (process.env.NODE_ENV !== 'development') {
    Userpilot.initialize('NX-da05204b');
}

const el = React.createElement(Routes, {
    store,
});

ReactDOM.render(el, document.getElementById('root'));

serviceWorker.unregister();

if (typeof window === 'undefined') {
    const consoleError = console.error;
    console.error = (...args) => {
        if (
            typeof args[0] === 'string' &&
            args[0].includes('useLayoutEffect does nothing on the server')
        ) {
            return;
        }
        consoleError(...args);
    };
}
