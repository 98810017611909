import useSWR from 'swr';
import { getYearMetadata } from 'waypoint-requests';
import { DataHookResponse } from 'waypoint-hooks/data-access/DataHookResponse';
import { EntityProfileCardYearMetadataWithCommentCount } from 'waypoint-types/properties/types';

interface GetYearMetadataProps {
    entityCodes: string[];
    type: string;
}

export const useGetYearMetadata = ({
    entityCodes,
    type,
}: GetYearMetadataProps): DataHookResponse<
    EntityProfileCardYearMetadataWithCommentCount[]
> => {
    const key = `/planning/${type}/yearMetadata/${entityCodes.join(',')}`;
    const { data, mutate, error, isValidating } = useSWR(
        key,
        () => getYearMetadata(entityCodes, type),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
            shouldRetryOnError: false,
        }
    );
    return {
        data,
        mutate,
        isError: !!error,
        isLoading: !data || isValidating,
    };
};
