import PropTypes from 'prop-types';
import React from 'react';
import {
    legendlabelStyle,
    legendSquareStyle,
    legendItemContainer,
} from './styles';
import { cx, css } from 'emotion';

const SquareLegendItem = (props) => {
    return (
        <span className={legendItemContainer}>
            <span
                className={cx(
                    legendSquareStyle,
                    css({ backgroundColor: props.color })
                )}
            />
            <span className={legendlabelStyle}>{props.label}</span>
        </span>
    );
};

SquareLegendItem.propTypes = {
    label: PropTypes.string,
    color: PropTypes.string,
};

export default SquareLegendItem;
