// @ts-nocheck
// This file is a result of nx update

import { proto as TYPES } from './actionTypes';

export function generate(count) {
    // hack to initialize arr with numbers
    const data = Array.apply(null, Array(count)).map(() => ({
        label: 'First Name',
        value: parseInt(Math.random() * 200 - 100),
    }));

    return {
        type: TYPES.GENERATE,
        payload: data,
    };
}

const initialState = { generated: [] };

export function reducer(state = initialState, action) {
    switch (action.type) {
        case TYPES.GENERATE:
            return Object.assign({}, state, {
                generated: action.payload.map((el, index) => {
                    if (state.generated[index]) {
                        return Object.assign({}, el, {
                            label: state.generated[index].label,
                        });
                    }
                    return el;
                }),
            });

        default:
            return state;
    }
}
