// These colors shall be used throghout the app

export const WHITE = '#FFFFFF';
// TODO (Daniel): LIGHT_GRAY is actually darker than DARK_GRAY, swap these around
export const TEXT_GRAY = '#4a4a4a'; // rgb(74, 74, 74)
export const LIGHT_GRAY = '#9c9c9c'; // rgb(156, 156, 156)
export const GRAY = '#F2F2F2';
export const DARK_GRAY = 'rgb(204, 204, 204)';
export const BLUE = 'rgb(0, 173, 226)'; // #00ade2
export const BACKGROUND_GRAY = '#F2F2F2';
export const TRANSPARENT = 'rgba(0, 0, 0, 0)'; // #00ade2
// TODO (Daniel, Colby, Alex): determine what to do with this when colors are finalized
export const MEDIUM_GRAY = '#5a5a5a';
export const PRIMARY_BLUE = '#32a3dd';

export const DISABLED_GRAY = '#d8d8d8';
export const DARK_DISABLED_GRAY = '#a8a8a8';
export const MARIGOLD = '#F9CB79';
export const BAD_RED = '#DF0101';
export const GOOD_GREEN = '#00C854';

export const barChartColors = [
    '#4888B8',
    '#EAAB63',
    '#F9E096',
    '#99CDA7',
    '#CCDC9F',
    MARIGOLD,
    '#3BB3E4',
    '#CCDC9F',
];

export const chartGreen = '#6BC1A3';

export const categoriesColors = [
    '#F3A954',
    '#F9CB79',
    '#FCE08D',
    '#3588BD',
    '#66C2A5',
    '#8BD0A4',
    '#C8DE98',
    '#DCDF95',
];

export const theme = {
    colors: {
        primaryBlue: '#32a3dd',
        buttonBlue: '#32a3dd',
        buttonBlueLight: '#32a3dd52',
        lighterGray: '#f8f8f8',
        lightGray: '#F2F2F2',
        darkGray: 'rgb(204, 204, 204)',
        mediumGray: '#5a5a5a',
        transparent: 'rgba(0, 0, 0, 0)',
        text: {
            normal: '#4a4a4a',
            light: '#9c9c9c',
        },
        numbers: {
            green: '#66c285',
            red: '#D43434',
        },
    },
};
