import { API_URL } from 'config/constants';

/**
 * @param {string} commentThreadId - Comment thread id
 * @param {boolean} is_resolved - resolved status of the comment
 * @param {string} comment_id - comment id
 */
const resolveComment = (
    commentThreadId: string,
    comment_id: string,
    is_resolved: boolean
): Promise<Response> => {
    return fetch(
        `${API_URL}/comments/${commentThreadId}/${comment_id}/resolve`,
        {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ is_resolved }),
        }
    );
};

export default resolveComment;
