import { ComparativeIncomeStatementTable } from 'components/financials/comparative-income-statement';
import { useIncomeStatement } from 'components/financials/comparative-income-statement/IncomeStatementProvider';
import { useCommentsPane } from 'contexts/comments/CommentsContext';
import React, { useImperativeHandle } from 'react';
import { useMemo, useState } from 'react';
import { PDFExportable } from 'waypoint-utils/pdf/PDFExportable';
import { convertComparativeIncomeStatementComparisonSelections } from 'components/reports/report-widget/settings-builder/utils';
import { useGetRSFAndUnits } from 'waypoint-hooks';

interface ComparativeIncomeStatementTableEntityReportWidgetParams {
    entityCode: string;
    widgetId: string;
    widgetType: string;
    widgetSettings: { [key: string]: any };
    isPDFExport: boolean;
}

export const ComparativeIncomeStatementTableEntityReportWidget =
    React.forwardRef<
        PDFExportable,
        ComparativeIncomeStatementTableEntityReportWidgetParams
    >(
        (
            {
                entityCode,
                widgetId,
                widgetType,
                widgetSettings,
                isPDFExport,
            }: ComparativeIncomeStatementTableEntityReportWidgetParams,
            ref,
        ) => {
            const {
                reportMetadata,
                clientModes,
                comparisonSelections,
                setSelections,
                isLoading,
            } = useIncomeStatement();

            const widgetExportId = `narrativePDFWrapper_${widgetType}_${widgetId}`;
            useImperativeHandle(ref, () => ({
                isReadyToExport(): boolean {
                    return (
                        !isLoading && !!comparisonSelections && isReadyToExport
                    );
                },
            }));

            const [selectedAccountMappingId, setSelectedAccountMappingId] =
                useState<string | null>(null);

            const [isReadyToExport, setIsReadyToExport] =
                useState<boolean>(false);

            const { update: updateCommentsPane, commentThreadAccountMentions } =
                useCommentsPane();

            const { data: rsfAndUnits } = useGetRSFAndUnits([entityCode]);

            useMemo(() => {
                setSelections(
                    convertComparativeIncomeStatementComparisonSelections(
                        widgetSettings.comparison_selections,
                    ),
                    false,
                );
            }, [widgetSettings]);

            const heightOffset = 450;

            if (!comparisonSelections || !rsfAndUnits) {
                return <></>;
            }
            return (
                <div id={widgetExportId}>
                    <ComparativeIncomeStatementTable
                        entityCodes={[entityCode]}
                        selections={comparisonSelections}
                        selectedAccountMappingId={
                            selectedAccountMappingId ?? ''
                        }
                        setSelectedAccountMappingId={
                            setSelectedAccountMappingId
                        }
                        commentThreadAccountMentions={
                            commentThreadAccountMentions ?? []
                        }
                        setAccountFilterSelection={(
                            accountFilterSelection: string,
                        ) => {
                            updateCommentsPane({
                                accountFilterSelection,
                            });
                        }}
                        modes={clientModes}
                        metadataId={reportMetadata?.id ?? ''}
                        heightOffset={heightOffset}
                        reportWidgetSettings={{
                            is_hide_null: widgetSettings.is_hide_null,
                            is_hide_notes: widgetSettings.is_hide_notes,
                            show_current_period:
                                widgetSettings.show_current_period,
                            show_per_sf: widgetSettings?.show_per_sf ?? false,
                            show_per_unit:
                                widgetSettings?.show_per_unit ?? false,
                            export_setting: widgetSettings.export_setting,
                            account_mapping_selections:
                                widgetSettings.account_mapping_selections,
                        }}
                        isPDFExport={isPDFExport}
                        totalRSF={rsfAndUnits.rentable_square_footage}
                        totalUnits={rsfAndUnits.total_units}
                        setIsReadyToExport={
                            isPDFExport ? setIsReadyToExport : undefined
                        }
                    />
                </div>
            );
        },
    );
