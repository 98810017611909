import React from 'react';
import Select from 'react-select';
import { css } from 'emotion';

const fieldContainerStyle = css`
    padding: 20px;
    margin-bottom: 30px;
    background-color: #fbfcfd;
    border: 1px solid #f0f0f4;
    p {
        color: rgba(0, 0, 0, 0.25);
        font-size: 12px;
        font-family: 'Lato';
        line-height: 19px;
        font-weight: 400;
    }
    .Select-value-label {
        line-height: 45px;
    }
    .Select-control {
        border: 1px solid #979797;
        border-radius: 2px;
        background-color: rgba(255, 255, 255, 0.05);
    }
    .Select-placeholder {
        font-size: 14px;
        font-family: 'Lato';
        font-weight: 600;
        color: #000;
        letter-spacing: 0;
        line-height: 45px;
    }
`;

const SelectField = ({ fieldName, options, onChange, placeholder, value }) => {
    return (
        <div>
            <h3>{fieldName}</h3>
            <div className={fieldContainerStyle}>
                <Select
                    name="performance_overview_select"
                    onChange={onChange}
                    options={options}
                    placeholder={placeholder}
                    value={value}
                    clearable={false}
                />
            </div>
        </div>
    );
};

export default SelectField;
