import { Select } from 'waypoint-react';
import { Input, Modal, Form, Button, AutoComplete, InputNumber } from 'antd';
import { css } from 'emotion';
import { TypeOption, Valuation } from 'waypoint-types';
import moment from 'moment';
import { DatePicker } from 'waypoint-react';
import { useState } from 'react';

const modalStyle = css`
    width: 400px !important;
    height: 500px;
    overflowy: 'scroll';
    label {
    }
    @media screen and (max-width: 1280px) {
        max-width: 85% !important;
    }
    .ant-form-item-label {
        font-size: 14px;
        font-family: Lato;
        padding: 0 0 0;
        label {
            font-size: 13px;
        }
    }
    .ant-form-item {
        margin-bottom: 10px;
    }
`;

const inputNumberStyle = css`
    .ant-input-number {
        width: 170px;
        border-radius: 8px;
    }
    .ant-input-number-group-addon {
        border-radius: 8px;
    }
`;

const inputTextAreaStyle = css`
    .ant-input-textarea-affix-wrapper {
        width: 100%;
        border-radius: 8px;
    }
`;

const inputStyle = css`
    width: 240px;
    .ant-select-single .ant-select-selector {
        border-radius: 8px;
    }
`;

const inputDateStyle = css`
    .ant-picker {
        width: 200px;
        border-radius: 8px;
    }
    .ant-select-single .ant-select-selector {
        border-radius: 8px;
    }
`;

interface ClientSelectableOptions {
    value: string;
    label: string;
}

interface EditValuationModalProps {
    selectedRowData: Valuation | null;
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
    propertyOptions: {
        value: string;
        label: string;
    }[];
    methodOptions: ClientSelectableOptions[];
    onClose: () => void;
    onSaved: (data: Valuation) => void;
    disabledDateField?: boolean;
    onlyToSave?: boolean;
}

export const EditValuationModal = ({
    selectedRowData,
    isVisible,
    setIsVisible,
    propertyOptions,
    methodOptions,
    onClose,
    onSaved,
    disabledDateField,
    onlyToSave,
}: EditValuationModalProps) => {
    const [form] = Form.useForm();
    const [value, setValue] = useState<number | string | null>(0);
    const disablePropertySelect = propertyOptions.length === 1;
    const sourceOptions: TypeOption[] = [
        {
            value: 'internal',
            label: 'Internal',
            color: 'black',
        },
        {
            value: 'external',
            label: 'External',
            color: 'black',
        },
    ];

    const saveText = () => {
        if (onlyToSave) {
            return 'Save';
        }

        return selectedRowData ? 'Update' : 'Save';
    };

    return (
        <Modal
            centered
            title={selectedRowData?.id ? `Edit Valuation` : 'Add Valuation'}
            open={isVisible}
            className={modalStyle}
            onCancel={onClose}
            destroyOnClose
            footer={null}
        >
            <Form
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 20 }}
                className={modalStyle}
                scrollToFirstError
                form={form}
                layout="vertical"
                onFinish={(data: Valuation) => {
                    onSaved(data);
                    onClose();
                    setIsVisible(false);
                }}
                name="valuations"
                initialValues={
                    selectedRowData
                        ? {
                              ...selectedRowData,
                              date: moment(selectedRowData.date),
                          }
                        : {
                              entity_code: disablePropertySelect
                                  ? propertyOptions[0].value
                                  : null,
                          }
                }
            >
                <Form.Item
                    name="entity_code"
                    label="Property"
                    className={inputStyle}
                    rules={[
                        {
                            required: true,
                            message: 'Required value',
                        },
                    ]}
                >
                    <Select
                        placeholder={'Property Name'}
                        options={propertyOptions}
                        disabled={disablePropertySelect}
                    />
                </Form.Item>

                <Form.Item
                    name="date"
                    label="Date"
                    valuePropName={'date'}
                    className={inputDateStyle}
                    rules={[
                        {
                            required: true,
                            message: 'Required value',
                        },
                    ]}
                >
                    <DatePicker
                        picker="month"
                        format={'MMMM YYYY'}
                        defaultValue={
                            selectedRowData
                                ? moment(selectedRowData?.date, 'YYYY-MM-DD')
                                : undefined
                        }
                        placeholder="Select month"
                        disabled={disabledDateField}
                    />
                </Form.Item>

                <Form.Item
                    name="source"
                    label="Source"
                    className={inputStyle}
                    rules={[
                        {
                            required: true,
                            message: 'Required value',
                        },
                    ]}
                >
                    <Select
                        placeholder="Select source"
                        options={sourceOptions}
                        defaultValue={selectedRowData?.source}
                    />
                </Form.Item>

                <Form.Item
                    name="method"
                    label="Method"
                    className={inputStyle}
                    valuePropName={'method'}
                    rules={[
                        {
                            required: true,
                            message: 'Required value',
                        },
                    ]}
                >
                    <AutoComplete
                        showSearch
                        placeholder="Select method"
                        optionFilterProp="method"
                        defaultValue={selectedRowData?.method}
                        options={methodOptions}
                    />
                </Form.Item>

                <Form.Item
                    name="value"
                    label="Value"
                    className={inputNumberStyle}
                    rules={[
                        {
                            required: true,
                            message: 'Required value',
                        },
                        {
                            validator: async (_, value) => {
                                if (!isNaN(value) && value < 0) {
                                    throw new Error('Invalid amount');
                                }

                                if (value % 1 !== 0) {
                                    throw new Error('No decimals');
                                }
                            },
                        },
                    ]}
                >
                    <InputNumber
                        type="number"
                        addonBefore={'$'}
                        onChange={(value: number | string | null) => {
                            setValue(value);
                        }}
                    />
                </Form.Item>

                <Form.Item
                    name="notes"
                    label="Notes"
                    className={inputTextAreaStyle}
                >
                    <Input.TextArea showCount rows={3} maxLength={500} />
                </Form.Item>

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        width: '100%',
                        marginTop: '15px',
                        position: 'absolute',
                        bottom: '0',
                        left: '0',
                        padding: '12px 24px',
                        backgroundColor: '#fff',
                        borderTop: '1px solid #e9e9e9',
                        borderRadius: '8px',
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                            size="middle"
                            onClick={() => {
                                setIsVisible(false);
                                onClose();
                            }}
                            style={{ marginRight: '10px' }}
                        >
                            Cancel
                        </Button>
                        <Button
                            size="middle"
                            type="primary"
                            disabled={(value as number) % 1 !== 0}
                            htmlType="submit"
                        >
                            {saveText()}
                        </Button>
                    </div>
                </div>
            </Form>
        </Modal>
    );
};
