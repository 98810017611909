export const safeDivision = (dividend: number, divisor: number) => {
    const result = dividend / divisor;
    return isFinite(result) ? result : 0;
};

export const inRange = (
    num: number,
    beginNumber: number,
    finalNumber: number
) =>
    Math.min(beginNumber, finalNumber) <= num &&
    num <= Math.max(beginNumber, finalNumber);
