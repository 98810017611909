import PropTypes from 'prop-types';
import React from 'react';
import { assign } from 'lodash';
import {
    DEFAULT_GRID_WIDTH,
    DEFAULT_ROW_HEIGHT,
    DEFAULT_COLUMN_COUNT,
    DEFAULT_MARGIN,
} from 'components/grids/defaults';

export const WithDefaultRGLConfigPP = (WrappedComponent) => {
    class WithDefaultRGLConfig extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                cols: DEFAULT_COLUMN_COUNT,
                rowHeight: DEFAULT_ROW_HEIGHT,
                margin: [DEFAULT_MARGIN, DEFAULT_MARGIN],
                // NOTE: default width can be overriden by width prop or rflConfig below
                width: props.width || DEFAULT_GRID_WIDTH,
                isDraggable: false,
                useCSSTransforms: false,
            };
        }

        componentWillReceiveProps(nextProps) {
            if (nextProps.width !== this.props.width) {
                this.setState({ width: nextProps.width });
            }
        }

        /* IMPORTANT NOTE (PLEASE READ): config passed as props overrides default defined here. This is meant to fill in gaps of configuration */

        getDefaultRGLConfig() {
            const { config } = this.props;
            return { config: assign({}, this.state, config) };
        }

        render() {
            const newProps = this.getDefaultRGLConfig();
            return <WrappedComponent {...this.props} {...newProps} />;
        }
    }
    WithDefaultRGLConfig.propTypes = {
        width: PropTypes.number,
        config: PropTypes.object,
    };
    return WithDefaultRGLConfig;
};

export default WithDefaultRGLConfigPP;
