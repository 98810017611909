import PropTypes from 'prop-types';
import React from 'react';
import { css, cx } from 'emotion';
import { BLUE, WHITE, DARK_GRAY } from 'config/colors';

// #00AAE4
const container = css({
    display: 'block',
    position: 'relative',
    paddingLeft: '35px',
    cursor: 'pointer',
    userSelect: 'none',
    input: {
        position: 'absolute',
        opacity: 0,
        cursor: 'pointer',
    },

    '&:hover input ~ span': {
        backgroundColor: DARK_GRAY,
    },

    '& input:checked ~ span': {
        backgroundColor: WHITE,
        border: `6px solid ${BLUE}`,
    },
});

const radio = css({
    position: 'absolute',
    top: '4px',
    left: '15px',
    height: '16px',
    width: '16px',
    border: `1px solid ${DARK_GRAY}`,
    borderRadius: '50%',
});

const Radio = ({
    name,
    id,
    value,
    handler,
    active,
    label,
    containerStyle = {},
    metaStyle = {},
    radioStyle = {},
    ...props
}) => {
    return (
        <div style={metaStyle}>
            <label htmlFor={id} className={cx(container, containerStyle)}>
                <input
                    type="radio"
                    name={name}
                    id={id}
                    value={value}
                    checked={active}
                    onChange={handler}
                    {...props}
                />
                <span className={cx(radio, radioStyle)} />
                {label}
            </label>
        </div>
    );
};

Radio.propTypes = {
    value: PropTypes.any,
    name: PropTypes.string,
    id: PropTypes.number,
    containerStyle: PropTypes.object,
    metaStyle: PropTypes.object,
    radioStyle: PropTypes.object,
    label: PropTypes.string,
    handler: PropTypes.func,
    active: PropTypes.bool,
};

export default Radio;
