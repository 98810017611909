import React from 'react';
import { css, cx } from 'emotion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover } from 'antd';
import theme from 'config/theme';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';

const flagIconStyle = css`
    font-size: 24px;
    color: ${theme.colors.grays.light};
    margin: 6px 6px 0 18px;
    cursor: pointer;
`;
const activeStyle = css`
    color: ${theme.colors.oranges.bright};
`;
const disabledStyle = css`
    opacity: 0.5;
    cursor: not-allowed;
`;

const FlagButton = ({ active, disabled, onChange }) => (
    <Popover content="User mark" placement="bottom">
        <i
            className={cx('fa-solid fa-bookmark', flagIconStyle, {
                [activeStyle]: active,
                [disabledStyle]: disabled,
            })}
            onClick={() => {
                if (!disabled) {
                    onChange(!active);
                }
            }}
        />
    </Popover>
);

export default FlagButton;
