import { isUndefined, isNull, isNil } from 'lodash';
import moment from 'moment';
import { monthDisplayNames } from 'utils/consts';

// TODO: test these
export const numericalSort = (a?: number, b?: number): number => {
    if (isUndefined(a)) {
        return -1;
    }
    if (isUndefined(b)) {
        return 1;
    }
    return a - b;
};

export const stringSort = (a: string, b: string): number => {
    if (isNil(a)) {
        return -1;
    }

    if (isNil(b)) {
        return 1;
    }

    const aString = a.toString();
    const bString = b.toString();

    return bString.toUpperCase().localeCompare(aString.toUpperCase());
};

export const dateSort = (a: Date | null, b: Date | null): number => {
    if (isNull(b) && !isNull(a)) {
        return 1;
    }

    if (isNull(a) && !isNull(b)) {
        return -1;
    }

    if (moment(a).isBefore(b)) {
        return 1;
    }

    if (moment(b).isBefore(a)) {
        return -1;
    }

    return 0;
};

export const percentageSort = (a: string, b: string): number => {
    a = a.split('%')[0];
    b = b.split('%')[0];
    return parseFloat(b) - parseFloat(a);
};

export const yearSort = (a: number, b: number): number => {
    if (isNil(a)) {
        return 1;
    }
    if (isNil(b)) {
        return -1;
    }
    return b - a;
};
export const sortByMonth = (a: string | null, b: string | null): number => {
    if (isNil(a)) {
        return 1;
    }
    if (isNil(b)) {
        return -1;
    }

    return monthDisplayNames.indexOf(a) - monthDisplayNames.indexOf(b);
};
