import { Badge, Button, Dropdown, List, Menu, Popconfirm, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import {
    EntityProfileCard,
    EntityProfileCardYearMetadata,
} from 'waypoint-types';
import {
    ClockCircleOutlined,
    MoreOutlined,
    UnorderedListOutlined,
} from '@ant-design/icons';
import { EntityProfileCardsEnabledFields } from 'waypoint-react/entity-profile-cards/EntityProfileCards';
import { EntityProfileCardNotes } from 'waypoint-react';
import { useGetNotesByReferenceId } from 'waypoint-hooks';
import { css } from 'emotion';

export interface EntityProfileCardBaseProps {
    enabledFields: EntityProfileCardsEnabledFields;
    onStartEdit: (item: EntityProfileCard) => void;
    onConfirmDelete: (item: EntityProfileCard) => void;
    itemMetadataSelects?: (item: EntityProfileCard) => JSX.Element;
    enableStatusItems?: boolean;
    isPDFExport?: boolean;
    hideStatus?: boolean;
}

interface EntityProfileCardItemProps extends EntityProfileCardBaseProps {
    entityProfileCard: EntityProfileCard;
    yearMetadataValue?: EntityProfileCardYearMetadata;
    includeNotesOnPdf?: boolean;
}

const listItemCSS = css`
    .ant-list-item-action {
        margin-bottom: 0;
    }
`;

const EntityProfileCardItem = ({
    entityProfileCard,
    enabledFields,
    onStartEdit,
    onConfirmDelete,
    itemMetadataSelects,
    enableStatusItems,
    yearMetadataValue,
    isPDFExport,
    includeNotesOnPdf = false,
    hideStatus,
}: EntityProfileCardItemProps): JSX.Element => {
    const [openNoteSection, setOpenNoteSection] = useState<boolean>(false);

    const {
        data: notes,
        mutate: mutateNotes,
        isLoading,
    } = useGetNotesByReferenceId(entityProfileCard.id, {
        refreshInterval: 10000,
    });

    useEffect(() => {
        if (notes && notes.length && includeNotesOnPdf) {
            setOpenNoteSection(true);
        }
    }, [notes, includeNotesOnPdf]);

    const lastAuthor = entityProfileCard?.last_author;

    const lastUpdated = format(
        entityProfileCard?.timestamps?.updated_at
            ? new Date(entityProfileCard?.timestamps?.updated_at)
            : new Date(),
        'MMMM dd, yyyy'
    );

    const ShowLastUpdatedTooltip = (
        <span>
            <div>
                Last Updated by {lastAuthor?.firstname} {lastAuthor?.lastname}{' '}
                on {lastUpdated}
            </div>
        </span>
    );

    const AddEditNotesTooltip = (
        <span>
            <div>Show/Hide Notes</div>
        </span>
    );

    const ChangeStatusSelect = (
        <div>
            {hideStatus !== true &&
                enableStatusItems &&
                itemMetadataSelects &&
                itemMetadataSelects(entityProfileCard)}
        </div>
    );

    const ShowNotesButton = (
        <Badge color="gold" size="small" count={notes?.length ?? ''}>
            <Tooltip
                placement="topLeft"
                key={entityProfileCard.id}
                title={AddEditNotesTooltip}
            >
                <Button
                    style={{ borderRadius: '8px' }}
                    size="small"
                    type={'text'}
                    icon={<UnorderedListOutlined />}
                    onClick={() => setOpenNoteSection(!openNoteSection)}
                ></Button>
            </Tooltip>
        </Badge>
    );

    const ShowLastUpdatedButton = (
        <Tooltip
            placement="topLeft"
            key={entityProfileCard.id}
            title={ShowLastUpdatedTooltip}
        >
            <Button
                type="text"
                style={{ cursor: 'default' }}
                icon={<ClockCircleOutlined />}
            ></Button>
        </Tooltip>
    );

    const MoreActionsOptions = (
        <Menu>
            <Menu.Item key={'1'} onClick={() => onStartEdit(entityProfileCard)}>
                Edit{' '}
            </Menu.Item>

            <Menu.Item key={'2'}>
                <Popconfirm
                    placement="top"
                    title={'Are you sure you want to delete this item?'}
                    onConfirm={(event) => {
                        event?.stopPropagation();
                        onConfirmDelete(entityProfileCard);
                    }}
                    onCancel={(event) => {
                        event?.stopPropagation();
                    }}
                    okType="danger"
                    okText="Delete"
                    cancelText="Cancel"
                >
                    Delete
                </Popconfirm>
            </Menu.Item>
        </Menu>
    );

    const MoreActionsButton = (
        <Dropdown placement={'bottomRight'} overlay={MoreActionsOptions}>
            <span
                style={{
                    fontSize: '13px',
                    fontWeight: 'normal',
                    textTransform: 'none',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    marginLeft: '5px',
                }}
            >
                <MoreOutlined />
            </span>
        </Dropdown>
    );

    return (
        <List
            style={{
                paddingRight: '8px',
                paddingLeft: '8px',
                cursor: 'move',
                fontSize: '13px',
                borderBottom: '.25px solid rgba(224, 224, 224, 1)',
                borderRadius: '0px',
            }}
        >
            <List.Item
                className={listItemCSS}
                actions={
                    isPDFExport
                        ? [ChangeStatusSelect]
                        : [
                              ChangeStatusSelect,
                              ShowNotesButton,
                              ShowLastUpdatedButton,
                              MoreActionsButton,
                          ]
                }
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            marginLeft: 12,
                            display: 'flex',
                            flexGrow: 1,
                            justifyContent: 'flex-start',
                        }}
                    >
                        {entityProfileCard.title}
                    </div>
                </div>
            </List.Item>
            {openNoteSection && (
                <EntityProfileCardNotes
                    isPDFExport={isPDFExport}
                    notes={notes}
                    mutateNotes={mutateNotes}
                    yearMetadataValue={yearMetadataValue}
                    isLoading={isLoading}
                    entityProfileCard={entityProfileCard}
                />
            )}
        </List>
    );
};

export default EntityProfileCardItem;
