import React from 'react';
import { Tooltip, Popconfirm } from 'antd';
import { format } from 'date-fns';
import { CapitalProject } from 'waypoint-types';
import theme from 'config/theme';

interface CapitalProjectEditorCellProps {
    data: CapitalProject;
    setSelectedRowData: (data: CapitalProject) => void;
    setIsModalVisible: (isVisible: boolean) => void;
    onDeleteCapitalProject: (data: CapitalProject) => void;
}

const CapitalProjectEditorCell: React.FC<CapitalProjectEditorCellProps> = ({
    data,
    setSelectedRowData,
    setIsModalVisible,
    onDeleteCapitalProject,
}) => {
    const { created_by_user, updated_by_user, timestamps } = data;
    const { updated_at, created_at } = timestamps || {};

    return (
        <div style={{ display: 'flex' }}>
            <i
                style={{
                    marginRight: '10px',
                    color: theme.colors.blues.linkBlue,
                    cursor: 'pointer',
                    fontSize: '14px',
                }}
                className="fa-solid fa-pencil"
                onClick={() => {
                    setSelectedRowData(data);
                    setIsModalVisible(true);
                }}
            />

            <Tooltip
                placement="topRight"
                overlayStyle={{ minWidth: 'fit-content' }}
                title={
                    <>
                        {created_by_user && (
                            <p>
                                Added by: {created_by_user.firstname}{' '}
                                {created_by_user.lastname} on{' '}
                                {format(new Date(created_at), 'yyyy-MM-dd')}
                            </p>
                        )}
                        {updated_by_user && (
                            <p>
                                Last update by: {updated_by_user.firstname}{' '}
                                {updated_by_user.lastname} on{' '}
                                {format(new Date(updated_at), 'yyyy-MM-dd')}
                            </p>
                        )}
                    </>
                }
            >
                <i
                    style={{
                        marginRight: '10px',
                        color: theme.colors.blues.linkBlue,
                        cursor: 'pointer',
                        fontSize: '14px',
                    }}
                    className="fa-solid fa-info-circle"
                />
            </Tooltip>

            <Popconfirm
                placement="topRight"
                title={`Are you sure you want to delete this project?`}
                onConfirm={() => onDeleteCapitalProject(data)}
                onCancel={() => {}}
                okType="danger"
                okText="Delete"
                cancelText="Cancel"
            >
                <i
                    style={{
                        color: theme.colors.blues.linkBlue,
                        cursor: 'pointer',
                        fontSize: '14px',
                    }}
                    className="fa-solid fa-trash"
                />
            </Popconfirm>
        </div>
    );
};

export default CapitalProjectEditorCell;
