import { CrosstabAccountNode } from './CrosstabAccountNode';
import { AccountTreeNode } from 'waypoint-types';

export class CrosstabAccount {
    constructor(
        public readonly parent: CrosstabAccount | null,
        public readonly meta: AccountTreeNode | null,
        public children?: Map<string, CrosstabAccountNode>
    ) {}

    addChild(name: string, meta: AccountTreeNode): CrosstabAccountNode {
        if (!this.children) {
            this.children = new Map<string, CrosstabAccountNode>();
        }

        if (this.children.has(name)) {
            return this.children.get(name)!;
        }

        const childNode = new CrosstabAccount(this, meta);
        this.children.set(name, childNode);

        return childNode;
    }

    hasChildren(path: string[]): boolean {
        let node: CrosstabAccountNode = this;

        for (const segment of path) {
            let nextNode = node.children?.get(segment);
            if (!nextNode) {
                return false;
            }
            node = nextNode;
        }
        return true;
    }

    hasLeaf(path: string[]): boolean {
        let node: CrosstabAccountNode = this;

        for (const segment of path) {
            let nextNode = node.children?.get(segment);
            if (!nextNode) {
                return false;
            }
            node = nextNode;
        }

        return node.isLeaf;
    }

    /**
     * returns true when there are no children of this node
     */
    get isLeaf() {
        return !this.children || this.children.size === 0;
    }
}
