import { get } from 'lodash';
import {
    getBalanceSheetOverviewTotal,
    getBalanceSheetOverviewOvertime,
    getBalanceSheetOverviewBreakdown,
} from 'waypoint-requests';
import { Title } from 'waypoint-react';
import {
    OvertimeCard,
    BreakdownCard,
    TotalCard,
} from 'components/analytics/balance-sheet-overview/cards';

const cardHashMap = {
    breakdown: BreakdownCard,
    overtime: OvertimeCard,
    total: TotalCard,
    title: Title,
};

const requestsHashMap = {
    breakdown: getBalanceSheetOverviewBreakdown,
    overtime: getBalanceSheetOverviewOvertime,
    total: getBalanceSheetOverviewTotal,
    title: null,
};

/**
 * Each item in a dashboard configuation has a 'component' field. This function uses that field to associate and item with a react component and fetch function. It is used by the BalanceSheetOverviewGridItem to render a component and fetch data for it.
 * @param {object} dashboardItem
 * @returns {array} tuple - [react component, fetch function]
 */

const getComponentAndRequestFor = (dashboardItem) => {
    const componentName = get(dashboardItem, 'component');
    const componentToRender = get(cardHashMap, componentName);
    const requestToFetch = get(requestsHashMap, componentName);

    return [componentToRender, requestToFetch];
};

export default getComponentAndRequestFor;
