import { API_URL } from 'config/constants';
import { ReportSetting } from 'waypoint-types';

interface UpdateReportSettingsParams {
    reportId: string;
    settingsType: string;
    settings: { [x: string]: any };
    id: string;
    enabled: boolean;
}

const updateReportSettings = async (
    body: UpdateReportSettingsParams
): Promise<ReportSetting[]> => {
    const response = await fetch(
        `${API_URL}/reports/report-settings/${body.id}`,
        {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',

            body: JSON.stringify(body),
        }
    );

    if (!response.ok) {
        throw new Error('Failed to update report settings');
    }

    const { data } = await response.json();

    return data;
};

export default updateReportSettings;
