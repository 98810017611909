import {
    THRESHOLD_FIXED_AND_PERCENTAGES,
    THRESHOLD_FIXED_OR_PERCENTAGES,
    THRESHOLD_PERCENTAGES_ONLY,
} from 'components/financials/comparative-income-statement/constants';
import {
    AccountGraphCalculations,
    AccountGraphChildSummaryData,
    AccountGraphChildSummaryDataRaw,
    AccountGraphEntry,
    Periodicity,
    StructureAccountGraphDataEntry,
    StructuredAccountGraphCalculation,
} from 'waypoint-types/account-graph/types';
import moment from 'moment';
import { ComparisonSelections } from 'components/financials/comparative-income-statement/ComparisonIncomeStatementTypes';
import { Dictionary } from 'ts-essentials';
import { getVarianceComparisonColumnIndexes } from 'components/financials/comparative-income-statement/table/ComparativeIncomeStatementTableUtils';

const processAccountGraphCalculations = (
    childSummaryData: AccountGraphChildSummaryDataRaw,
    isCredit: boolean,
    comparisonSelections: ComparisonSelections | null,
    showCurrentPeriod?: boolean,
    periodicity?: Periodicity,
    isBalanceSheetType: boolean = false
): AccountGraphChildSummaryData => {
    const calculations = childSummaryData.calculations;

    const filteredNewEntries = isBalanceSheetType
        ? Object.entries(childSummaryData.entries).reduce(
              (acc, entry) => {
                  if (entry[1].length) {
                      acc[entry[0]] = entry[1];
                  }
                  return acc;
              },
              {} as Dictionary<AccountGraphEntry[]>
          )
        : null;

    const currentPeriodEntries = showCurrentPeriod
        ? Object.entries(childSummaryData.entries).reduce(
              (acc, entry) => {
                  if (entry[1].length) {
                      acc[entry[0]] = [entry[1][0]];
                  } else {
                      acc[entry[0]] = [];
                  }
                  return acc;
              },
              {} as Dictionary<AccountGraphEntry[]>
          )
        : null;

    const convertedEntries = Object.values(
        filteredNewEntries ?? childSummaryData.entries
    ).map((entries, index) => mapEntries(entries, index, comparisonSelections));

    const shouldDisplayOverThreshold =
        !isBalanceSheetType &&
        comparisonSelections?.comparison_type[0] === 'actual' &&
        comparisonSelections?.comparison_type[1] === 'budget';

    const [firstComparisonIndex, secondComparisonIndex] = comparisonSelections
        ? getVarianceComparisonColumnIndexes(
              comparisonSelections.variance_comparison
          )
        : [0, 1];

    const newCalculations = generateCalculationsFromMappingData(
        calculations,
        [
            convertedEntries[firstComparisonIndex],
            convertedEntries[secondComparisonIndex],
        ],
        isCredit,
        isBalanceSheetType,
        shouldDisplayOverThreshold,
        periodicity
    );

    const summaryData: AccountGraphChildSummaryData = {
        entries: convertedEntries,
        calculations: {
            ...calculations,
            ...newCalculations,
        } as AccountGraphCalculations,
    };

    if (convertedEntries.length === 3) {
        const [firstComparisonTertiaryIndex, secondComparisonTertiaryIndex] =
            comparisonSelections
                ? getVarianceComparisonColumnIndexes(
                      comparisonSelections.variance_comparison_tertiary,
                      true
                  )
                : [0, 2];

        const newTertiaryCalculations = generateCalculationsFromMappingData(
            calculations,
            [
                convertedEntries[firstComparisonTertiaryIndex],
                convertedEntries[secondComparisonTertiaryIndex],
            ],
            isCredit,
            isBalanceSheetType,
            shouldDisplayOverThreshold,
            periodicity
        );
        summaryData.tertiary_calculations = {
            ...calculations,
            ...newTertiaryCalculations,
        } as AccountGraphCalculations;
    }

    if (currentPeriodEntries) {
        const [firstComparisonIndex, secondComparisonIndex] =
            comparisonSelections
                ? getVarianceComparisonColumnIndexes(
                      comparisonSelections.variance_comparison
                  )
                : [0, 1];

        const convertedCurrentPeriodEntries = Object.values(
            currentPeriodEntries
        ).map((entries, index) =>
            mapEntries(entries, index, comparisonSelections, true)
        );
        const newCurrentPeriodCalculations =
            generateCalculationsFromMappingData(
                calculations,
                [
                    convertedCurrentPeriodEntries[firstComparisonIndex],
                    comparisonSelections?.comparison_period === 'year_total'
                        ? convertedEntries[secondComparisonIndex]
                        : convertedCurrentPeriodEntries[secondComparisonIndex],
                ],
                isCredit,
                isBalanceSheetType,
                shouldDisplayOverThreshold,
                periodicity
            );
        summaryData.entries = [
            ...convertedCurrentPeriodEntries,
            ...convertedEntries,
        ];
        summaryData.current_period_calculations = {
            ...calculations,
            ...newCurrentPeriodCalculations,
        } as AccountGraphCalculations;

        if (convertedCurrentPeriodEntries.length === 3) {
            const [
                firstComparisonTertiaryIndex,
                secondComparisonTertiaryIndex,
            ] = comparisonSelections
                ? getVarianceComparisonColumnIndexes(
                      comparisonSelections.variance_comparison_tertiary,
                      true
                  )
                : [0, 2];

            const newCurrentPeriodTertiaryCalculations =
                generateCalculationsFromMappingData(
                    calculations,
                    [
                        convertedCurrentPeriodEntries[
                            firstComparisonTertiaryIndex
                        ],
                        comparisonSelections?.comparison_period_tertiary ===
                        'year_total'
                            ? convertedEntries[secondComparisonTertiaryIndex]
                            : convertedCurrentPeriodEntries[
                                  secondComparisonTertiaryIndex
                              ],
                    ],
                    isCredit,
                    isBalanceSheetType,
                    shouldDisplayOverThreshold,
                    periodicity
                );
            summaryData.current_period_tertiary_calculations = {
                ...calculations,
                ...newCurrentPeriodTertiaryCalculations,
            } as AccountGraphCalculations;
        }
    }
    return summaryData;
};

const mapBalanceSheetEntries = (entries: AccountGraphEntry[]) => {
    return {
        ...entries[0],
        amount: calculateTotalAmounts(entries),
        period_start: entries[entries.length - 1].period_start,
        period_end: entries[0].period_end,
        is_current_period: false,
    };
};

const mapEntries = (
    entries: AccountGraphEntry[],
    index: number,
    comparisonSelections: ComparisonSelections | null,
    isCurrentPeriod = false
) => {
    if (!comparisonSelections) {
        return mapBalanceSheetEntries(entries);
    }

    const indexMappings = [
        'period_primary',
        'period_secondary',
        'period_tertiary',
    ];

    const periodRange =
        comparisonSelections[
            indexMappings[index] as keyof typeof comparisonSelections
        ];

    return {
        ...entries[0],
        amount: calculateTotalAmounts(entries),
        period_start: isCurrentPeriod
            ? moment(periodRange[1]).startOf('month').format('YYYY-MM-DD')
            : periodRange[0],
        period_end: periodRange[1],
        is_current_period: isCurrentPeriod,
    };
};

const calculateTotalAmounts = (
    accountMappingData: StructureAccountGraphDataEntry[]
) => {
    if (accountMappingData.every((amd) => amd.amount === null)) {
        return null;
    }
    return accountMappingData.reduce(
        (total: number, current: StructureAccountGraphDataEntry) =>
            (total += current.amount ?? 0),
        0
    );
};

const generateCalculationsFromMappingData = (
    calculations: AccountGraphCalculations,
    orderedAccountMappingData: AccountGraphEntry[],
    isCredit: boolean,
    isBalanceSheetType: boolean,
    shouldDisplayOverThreshold: boolean,
    periodicity?: Periodicity
): StructuredAccountGraphCalculation => {
    let firstValue = orderedAccountMappingData[0]?.amount ?? null;
    let secondValue = orderedAccountMappingData[1]?.amount ?? null;

    if (
        (firstValue !== null && secondValue !== null) ||
        (firstValue !== null && secondValue === null) ||
        (firstValue === null && secondValue !== null)
    ) {
        // Setting null values to 0
        firstValue = firstValue ?? 0;
        secondValue = secondValue ?? 0;

        // If not credit or balance sheet then use first value to subtract
        const varianceAmount =
            !isCredit || isBalanceSheetType
                ? secondValue - firstValue
                : firstValue - secondValue;

        // If balance sheet divide by first value
        let variancePercent = isBalanceSheetType
            ? varianceAmount / firstValue
            : varianceAmount / secondValue;

        // If the variancePercent is Infinity or -Infinity we should make the variance percentage 100%
        // Case when dividing by 0
        if (!Number.isFinite(variancePercent)) {
            variancePercent = 1;
        }

        // If both are 0 percent should be 0
        if (varianceAmount === 0) {
            variancePercent = 0;
        }

        const varianceIsGood = varianceAmount === 0 ? null : varianceAmount > 0;

        const thresholdExceeded = exceedsThreshold(
            calculations,
            varianceAmount,
            variancePercent,
            periodicity
        );

        // Do not return thresholds if actual to actual or Actual to Pro Forma
        if (shouldDisplayOverThreshold) {
            return {
                variance_amount: varianceAmount,
                variance_percent: variancePercent,
                variance_is_good: varianceIsGood,
                threshold_exceeded: thresholdExceeded,
            };
        }

        return {
            variance_amount: varianceAmount,
            variance_percent: variancePercent,
            variance_is_good: varianceIsGood,
        };
    }

    return {
        variance_amount: undefined,
        variance_percent: undefined,
        variance_is_good: null,
    };
};

const exceedsThreshold = (
    calculations: AccountGraphCalculations,
    amount: number,
    percent: number,
    periodicity?: Periodicity
): boolean | null => {
    if (periodicity === null) {
        return null;
    }

    // Template Thresholds limits
    const amountThreshold = calculations.threshold_amount;
    const percentThreshold = calculations.threshold_percent;
    const typeThreshold = calculations.threshold_type;

    // fixed_and_percentage
    if (typeThreshold === THRESHOLD_FIXED_AND_PERCENTAGES) {
        if (!amountThreshold || !percentThreshold) {
            return null;
        }

        return (
            Math.abs(amount) > amountThreshold &&
            Math.abs(percent) > percentThreshold
        );
    }

    // fixed_or_percentage
    if (typeThreshold === THRESHOLD_FIXED_OR_PERCENTAGES) {
        if (!amountThreshold && !percentThreshold) {
            return null;
        }

        return (
            Math.abs(amount) > amountThreshold ||
            Math.abs(percent) > percentThreshold
        );
    }

    // Percentage Only
    if (typeThreshold === THRESHOLD_PERCENTAGES_ONLY) {
        return percentThreshold ? Math.abs(percent) > percentThreshold : null;
    }

    // fixed_only
    return amountThreshold ? Math.abs(amount) > amountThreshold : null;
};

export default processAccountGraphCalculations;
