import React from 'react';
import fusioncharts from 'fusioncharts';
import charts from 'fusioncharts/fusioncharts.charts';
import ReactFC from 'react-fusioncharts';
import { css } from 'emotion';
import { theme } from 'config/colors';
import chartBuilder from 'components/charts/chartBuilder';

// Resolves charts dependancy
charts(fusioncharts);

const chartContainer = css`
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    position: relative;
    > div {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const chartStyle = {
    yaxisname: 'Total Occupied Sq Ft',
    showPercentageValues: '1',
    showTooltip: '1',
    baseFont: 'Lato',
    baseFontColor: theme.colors.mediumGray,
    xAxisNameFontColor: theme.colors.mediumGray,
    yAxisNameFontColor: theme.colors.mediumGray,
};

const SqftByIndustryChart = ({ data }) => {
    const chart = chartBuilder();
    chart.type('bar2d');
    chart.height('70%');
    chart.width('100%');
    chart.style(chartStyle);
    chart.exportFormats('PNG|PDF|JPG|SVG');
    chart.data(data);

    const { config: chartConfig } = chart;

    return (
        <div className={chartContainer}>
            <ReactFC {...chartConfig} />
        </div>
    );
};

export default SqftByIndustryChart;
