import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { assign } from 'lodash';
import { cx } from 'emotion';
// import 'react-dd-menu/dist/react-dd-menu.min.css'

// TODO (Nicholas): Break this into multiple components
/* eslint-disable react/no-multi-comp */
const width = '160px';
const height = '40px';
const borderSize = 4;
const leftPadding = 12;
export const MenuItem = ({
    active,
    style,
    children,
    className,
    onClick,
    to,
    activeClassName,
}) => {
    const listItemStyle = Object.assign(
        {},
        {
            width,
            height,
            borderLeft: active ? `${borderSize}px solid #00A2E7` : '',
            // Make space for the border if active
            paddingLeft: `${leftPadding - (active ? borderSize : 0)}px`,
            color: active ? '#00A2E7' : '#585858',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
        },
        style
    );
    return (
        <NavLink
            to={to}
            onClick={onClick}
            className={cx(['dd-item-ignore', className])}
            activeClassName={activeClassName}
            style={listItemStyle}
        >
            {children}
        </NavLink>
    );
};

MenuItem.propTypes = {
    active: PropTypes.bool,
    style: PropTypes.object,
    onClick: PropTypes.func,
    children: PropTypes.element,
    className: PropTypes.string,
    to: PropTypes.string,
    activeClassName: PropTypes.string,
};

export const MenuLink = ({
    onClick,
    children,
    to,
    style,
    className,
    external = false,
}) => {
    return (
        <li
            className={cx(['dd-item-ignore', className])}
            onClick={onClick}
            style={assign(
                {},
                {
                    width,
                    height,
                    paddingLeft: `${leftPadding}px`,
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                },
                style
            )}
        >
            {external ? (
                <a
                    className="dd-item-ignore"
                    style={{ color: '#585858', textDecoration: 'none' }}
                    href={to}
                    target="blank"
                >
                    {children}
                </a>
            ) : (
                <NavLink
                    className="dd-item-ignore"
                    style={{ color: '#585858', textDecoration: 'none' }}
                    to={to}
                >
                    {children}
                </NavLink>
            )}
        </li>
    );
};
MenuLink.propTypes = {
    // NOTE: The barely descriptive "to" API here matches react router's <Link />
    to: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.any,
    style: PropTypes.object,
    className: PropTypes.string,
    external: PropTypes.bool,
};

export const MenuAction = ({ onClick, children, style, className }) => (
    <li
        className={cx(['dd-item-ignore', className])}
        style={assign(
            {},
            {
                width,
                height,
                paddingLeft: `${leftPadding}px`,
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                color: '#585858',
            },
            style
        )}
        onClick={onClick}
    >
        <span className="dd-item-ignore">{children}</span>
    </li>
);

MenuAction.propTypes = {
    style: PropTypes.object,
    onClick: PropTypes.func,
    children: PropTypes.any,
    className: PropTypes.string,
    activeclassName: PropTypes.string,
    to: PropTypes.string,
};
