import { assign } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

// NOTE: PP suffix indicates props proxy higher order component (HOC)
export default function defaultStylePP(WrappedComponent) {
    class DefaultCellStyle extends React.Component {
        shouldComponentUpdate(nextProps) {
            const { isFiltered, cellData, selectedRow, selectedColumn, force } =
                nextProps;
            const newData = cellData !== this.props.cellData;
            const newRow = selectedRow !== this.props.selectedRow;
            const newColumn = selectedColumn !== this.props.selectedColumn;
            const filterChange = isFiltered !== this.props.isFiltered;
            return force || newData || newRow || newColumn || filterChange;
        }

        render() {
            // TODO (Nicholas): Add de-emphasis prop
            const {
                style,
                isFiltered,
                selectedRow,
                selectedColumn,
                rowIndex,
                columnIndex,
                color,
                ...rest
            } = this.props;

            let backgroundColor = rowIndex % 2 === 0 ? '#F1F1F1' : '#FFF';
            if (selectedRow) {
                backgroundColor = '#DCEFFB';
            }
            const defaultStyle = {
                backgroundColor,
                opacity: isFiltered ? 0.4 : 'inherit',
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                fontSize: '16px',
                // TODO (Nicholas): Consolidate both default styles (header & this)
                paddingLeft: '12px',
                paddingRight: '5px',
                overflow: 'hidden',
            };
            if (selectedColumn) {
                defaultStyle.fontWeight = 600;
            }

            // NOTE: Other styles always overwrite default styles
            return (
                <WrappedComponent
                    color={color || 'inherit'}
                    key={rowIndex + ',' + columnIndex}
                    style={assign({}, defaultStyle, style)}
                    {...assign({}, rest, {
                        selectedRow,
                        selectedColumn,
                        isFiltered,
                        rowIndex,
                        columnIndex,
                    })}
                />
            );
        }
    }

    DefaultCellStyle.propTypes = {
        style: PropTypes.object,
        cellData: PropTypes.any,
        isFiltered: PropTypes.bool,
        rowIndex: PropTypes.number,
        columnIndex: PropTypes.number,
        selectedRow: PropTypes.bool,
        selectedColumn: PropTypes.bool,
        color: PropTypes.string,
    };

    return DefaultCellStyle;
}
