import React from 'react';
import { LeaseStatCardType } from 'components/leases/expirations/lease-expiration-types';
import { Row, Col } from 'antd';
import {
    useGetLeasesSummary,
    useGetSelectedFilteredEntityCodes,
} from 'waypoint-hooks';
import { EXPIRATION_DASHBOARD_SMALL_CARD_HEIGHT } from 'components/leases/constants';
import { getLeaseStatCards } from './utils';
import { RecurringCharge, SelectedDataLevel } from 'waypoint-types';

const LeaseSummaryCardSection = ({
    recurringCharges,
    occupiedUnits,
    selectedDataLevel,
}: {
    recurringCharges: RecurringCharge[];
    occupiedUnits: number;
    selectedDataLevel: SelectedDataLevel;
}) => {
    const entityCodes: string[] = useGetSelectedFilteredEntityCodes();

    const {
        data: leaseSummary,
        isLoading: leaseSummaryIsLoading,
        isError: isLeaseSummaryError,
    } = useGetLeasesSummary(entityCodes, selectedDataLevel);

    const leasesSummaryCards: LeaseStatCardType[] = getLeaseStatCards(
        leaseSummary,
        recurringCharges,
        occupiedUnits,
    );

    return (
        <Row gutter={[15, 15]}>
            {leasesSummaryCards.map((card: LeaseStatCardType, i: number) => {
                return (
                    <Col xxl={4} xl={8} lg={8} md={8} sm={24} xs={24}>
                        <card.component
                            style={{
                                height: EXPIRATION_DASHBOARD_SMALL_CARD_HEIGHT,
                                boxShadow: 'none',
                            }}
                            key={i.toString()}
                            loading={leaseSummaryIsLoading}
                            label={card.label}
                            content={card.content}
                            tooltip={card.tooltip}
                            error={isLeaseSummaryError}
                            data-testid="lease-summary-card"
                        />
                    </Col>
                );
            })}
        </Row>
    );
};

export default LeaseSummaryCardSection;
