import React from 'react';
import { Empty, Modal, Skeleton } from 'antd';
import { css } from 'emotion';
import DataGrid, {
    StateStoring,
    Column,
    LoadPanel,
} from 'devextreme-react/data-grid';
import { useGetClientData } from 'waypoint-hooks';
import { getAccountHistory } from 'waypoint-requests';
import { ComparisonSelections } from 'components/financials/comparative-income-statement/ComparisonIncomeStatementTypes';
import {
    accountHistoryComparisonHistory,
    accountHistoryNotesColumns,
} from 'components/notes/TableNotes';
import { getVarianceColor } from 'utils/tables/renderers';
import { MentionableDataSource, SelectedDataLevel } from 'waypoint-types';
import { copyTextToClipboard } from 'waypoint-utils';
import { CopyOutlined } from '@ant-design/icons';
import { dateSort } from 'utils/tables/sorters';
import { format } from 'date-fns';
import useSWR from 'swr';

const copyClipboardStyle = css`
    position: relative;
    .clipboard {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease;
    }
    &:hover {
        .clipboard {
            opacity: 1;
            visibility: visible;
        }
    }
`;

const modalStyle = css`
    width: 90% !important;
    @media (min-width: 1200px) {
        width: 1100px !important;
        max-width: 1100px !important;
    }
`;

interface AccountHistoryNote {
    id: string;
    text: string;
    date: string;
}

interface AccountHistoryModalProps {
    isVisible: boolean;
    setIsVisible: (value: boolean) => void;
    accountMappingId: string;
    periods: {
        periodicity: string;
        start_date: string;
        end_date: string;
        mode: string;
    }[];
    selections: ComparisonSelections;
    entityCodes: string[];
    selectedDataLevel: SelectedDataLevel;
    accounts?: MentionableDataSource[];
    metadataId?: string;
}

const AccountHistoryModal = ({
    isVisible,
    setIsVisible,
    accountMappingId,
    periods,
    selections,
    entityCodes,
    selectedDataLevel,
    accounts,
    metadataId,
}: AccountHistoryModalProps) => {
    const clientDisplayName: string = useGetClientData('display_name');

    const selectedComparisonHistory =
        accountHistoryComparisonHistory(selections);

    const { data, error, isValidating } = useSWR(
        `/api/comparative-income-statement/account-history/${accountMappingId}/${selectedComparisonHistory}`,
        () =>
            getAccountHistory({
                entity_codes: entityCodes,
                account_code: accountMappingId,
                is_hide_null: false,
                periods,
                comparison_history: selectedComparisonHistory,
                metadataId,
                selected_data_level: selectedDataLevel,
            }),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
        },
    );

    const accountHistoryColumns = accountHistoryNotesColumns({
        periodicity: periods[1].periodicity,
    });
    const columns =
        accountHistoryColumns[
            selectedComparisonHistory as keyof typeof accountHistoryColumns
        ];

    const renderContent = () => {
        if (isValidating) {
            return <Skeleton active paragraph={{ rows: 9 }} />;
        }

        if (error) {
            return (
                <Empty description="There was an error loading the account history. Please try again later." />
            );
        }

        return (
            <DataGrid
                wordWrapEnabled
                dataSource={data}
                height={400}
                allowColumnReordering={true}
                columnAutoWidth={true}
                columnMinWidth={100}
                showBorders={true}
                showRowLines={true}
                showColumnLines={true}
                rowAlternationEnabled={true}
                data-testid="account-history-table"
            >
                {columns
                    .filter(
                        (column) => column.data_field !== 'variance_is_good',
                    )
                    .map((column) => {
                        if (
                            [
                                'variance',
                                'change',
                                'variance_percent',
                                'change_percent',
                            ].includes(column.data_field)
                        ) {
                            return (
                                <Column
                                    key={column.data_field}
                                    dataField={column.data_field}
                                    caption={column.title}
                                    alignment={
                                        column.alignment as
                                            | 'left'
                                            | 'center'
                                            | 'right'
                                    }
                                    dataType={
                                        column.data_type as
                                            | 'string'
                                            | 'number'
                                            | 'boolean'
                                            | 'object'
                                            | 'date'
                                            | 'datetime'
                                    }
                                    format={column.format ?? undefined}
                                    sortingMethod={
                                        column.data_field === 'date'
                                            ? dateSort
                                            : undefined
                                    }
                                    cellRender={(cellData) => {
                                        return (
                                            <div
                                                style={{
                                                    color:
                                                        getVarianceColor(
                                                            cellData?.data
                                                                ?.variance_is_good ??
                                                                'false',
                                                        ) ?? 'black',
                                                }}
                                            >
                                                {Number(
                                                    cellData.value ?? 0,
                                                ).toLocaleString(
                                                    'en-US',
                                                    ![
                                                        'variance_percent',
                                                        'change_percent',
                                                    ].includes(
                                                        column.data_field,
                                                    )
                                                        ? {
                                                              style: 'currency',
                                                              currency: 'USD',
                                                          }
                                                        : {
                                                              style: 'percent',
                                                              minimumFractionDigits: 2,
                                                          },
                                                )}
                                            </div>
                                        );
                                    }}
                                />
                            );
                        }

                        if (column.data_field === 'notes') {
                            return (
                                <Column
                                    key={column.data_field}
                                    dataField={column.data_field}
                                    caption={column.title}
                                    dataType={
                                        column.data_type as
                                            | 'string'
                                            | 'number'
                                            | 'boolean'
                                            | 'object'
                                            | 'date'
                                            | 'datetime'
                                    }
                                    width={400}
                                    cellRender={(cellData) => {
                                        return (
                                            <NotesRenderer
                                                notes={cellData.value}
                                            />
                                        );
                                    }}
                                />
                            );
                        }

                        if (column.data_field === 'date') {
                            return (
                                <Column
                                    key={column.data_field}
                                    dataField={column.data_field}
                                    caption={column.title}
                                    dataType={
                                        column.data_type as
                                            | 'string'
                                            | 'number'
                                            | 'boolean'
                                            | 'object'
                                            | 'date'
                                            | 'datetime'
                                    }
                                    sortOrder="desc"
                                    cellRender={(cellData) => {
                                        return (
                                            <div>
                                                {periods[0].periodicity ===
                                                'quarter'
                                                    ? `Q${format(
                                                          new Date(
                                                              `${
                                                                  cellData.data
                                                                      .quarter *
                                                                  3
                                                              }/01/${
                                                                  cellData.data
                                                                      .year
                                                              }`,
                                                          ),
                                                          'Q yyyy',
                                                      )}`
                                                    : format(
                                                          new Date(
                                                              `${cellData.data.month}/01/${cellData.data.year}`,
                                                          ),
                                                          'MMM yyyy',
                                                      )}
                                            </div>
                                        );
                                    }}
                                />
                            );
                        }

                        return (
                            <Column
                                key={column.data_field}
                                dataField={column.data_field}
                                caption={column.title}
                                allowHeaderFiltering={true}
                                alignment={
                                    column.alignment as
                                        | 'left'
                                        | 'center'
                                        | 'right'
                                }
                                dataType={
                                    column.data_type as
                                        | 'string'
                                        | 'number'
                                        | 'boolean'
                                        | 'object'
                                        | 'date'
                                        | 'datetime'
                                }
                                format={column.format ?? undefined}
                            />
                        );
                    })}
                <LoadPanel showIndicator={isValidating} />
                <StateStoring
                    enabled={true}
                    savingTimeout={100}
                    type="localStorage"
                    storageKey={`${clientDisplayName}_account_history_modal_table`}
                />
            </DataGrid>
        );
    };

    return (
        <Modal
            title={`${
                accounts?.find((account) => account.id === accountMappingId)
                    ?.text
            }`}
            open={isVisible}
            onCancel={() => setIsVisible(false)}
            footer={null}
            className={modalStyle}
        >
            {renderContent()}
        </Modal>
    );
};

const NotesRenderer = ({ notes }: { notes: AccountHistoryNote[] }) => {
    return (
        <div>
            {notes.map((note) => (
                <div
                    className={copyClipboardStyle}
                    onClick={() => copyTextToClipboard(`${note.text} \n`)}
                    key={note.id}
                    style={{ marginTop: 12, paddingRight: 20 }}
                >
                    <div className="clipboard">
                        <CopyOutlined />
                    </div>
                    {note.text}
                </div>
            ))}
        </div>
    );
};

export default AccountHistoryModal;
