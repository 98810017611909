import { connect } from 'react-redux';
import ClientSettings from 'components/settings/client/ClientSettings';
import { roles as userRoles } from 'config/constants';

const mapState = (state) => {
    const {
        user: { roles },
    } = state;
    const isWaypointAssociate = roles.includes(userRoles.WAYPOINT_ASSOCIATE);
    const isWaypointSysadmin = roles.includes(userRoles.WAYPOINT_SYSADMIN);

    return {
        isWaypointAssociate,
        isWaypointSysadmin,
        roles,
    };
};

export default connect(mapState)(ClientSettings);
