// @ts-nocheck
import { createSelector } from 'reselect';
import { get, filter } from 'lodash';

const propertyKeys = [
    'name',
    'display_name',
    'postal_code',
    'city',
    'state',
    'asset_type_name',
];

const userKeys = ['firstname', 'lastname', 'email'];
const tenantKeys = ['name'];

// Generic function to filter based on keys
const filterByKeys = (data, keys, searchTerm) => {
    const lowerSearchTerm = searchTerm.toLowerCase();
    return filter(data, (item) =>
        keys.some((key) =>
            get(item, key, '').toLowerCase().includes(lowerSearchTerm),
        ),
    );
};

// Selectors for different resources
export const selectFilteredProperties = createSelector(
    [(state) => state.properties, (state, searchTerm) => searchTerm],
    (properties, searchTerm) => {
        if (!searchTerm) return properties;
        return filterByKeys(properties, propertyKeys, searchTerm);
    },
);

export const selectFilteredUsers = createSelector(
    [(state) => state.users, (state, searchTerm) => searchTerm],
    (users, searchTerm) => {
        if (!searchTerm) return users;
        return filterByKeys(users, userKeys, searchTerm);
    },
);

export const selectFilteredTenants = createSelector(
    [
        (state) => get(state, 'tenants.propertyTenants', []),
        (state, searchTerm) => searchTerm,
    ],
    (tenants, searchTerm) => {
        if (!searchTerm) return tenants;
        return filterByKeys(tenants, tenantKeys, searchTerm);
    },
);
