import { API_URL } from 'config/constants';

export async function deleteEntityProfileCardYearMetadata(
    entityCode: string,
    type: string,
    year: number
) {
    const response = await fetch(
        `${API_URL}/properties/${entityCode}/profile-cards/year-metadata/${type}/${year}`,
        {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
        }
    );

    if (!response.ok) {
        throw new Error('Failed to delete profile card year metadata');
    }

    return response;
}
