import { toArray, truncate, sortBy, keys, isEmpty, capitalize } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { mutateAsync } from 'redux-query';
import { message } from 'antd';
import { inviteUser } from 'state/requests';
import {
    Table,
    Column,
    ModalCellRenderer,
    AdminLinkCellRenderer,
    PermissionsCellRenderer,
} from 'components/tables';

const compareStrings = (a, b) => b.toUpperCase().localeCompare(a.toUpperCase());
const compareStatus = (a, b) =>
    b.status.toUpperCase().localeCompare(a.status.toUpperCase());

const formatInviteData = ({ status, clientId, userId, clickHandler }) => {
    let linkText = null;
    let enableOnClick = false;
    // NOTE: Status and clickHandler are both input and output variable names
    //       StatusText and onClick are internal variables but used as outputs
    let statusText = '';
    const onClick = () => clickHandler({ clientId, userId });
    switch (status) {
        case 'invite_accepted':
            statusText = 'Accepted';
            break;

        case 'added_via_admin':
            statusText = 'Added By Admin';
            break;

        case 'invite_revoked':
            statusText = 'Invite Revoked';
            break;

        case 'never_invited':
            statusText = 'Never Invited';
            linkText = 'Invite';
            enableOnClick = true;
            break;

        case 'invite_pending':
            statusText = 'Never Accepted';
            linkText = 'Resend Invite';
            enableOnClick = true;
            break;

        case 'invite_expired':
            statusText = 'Invite Expired';
            linkText = 'Resend Invite';
            enableOnClick = true;
            break;

        default:
    }

    return {
        linkText,
        status: statusText,
        clickHandler: enableOnClick ? onClick : null,
    };
};

class Admin extends React.Component {
    static propTypes = {
        data: PropTypes.arrayOf(PropTypes.object),
        selectRow: PropTypes.func,
        selectedRow: PropTypes.number,
        openEditUserModal: PropTypes.func,
        inviteAUser: PropTypes.func,
        clientId: PropTypes.number,
    };

    constructor(props) {
        super(props);
        const { data } = props;

        this.state = {
            selectedRow: 0,
            rowOrder: data,
            sortColumn: null,
        };
    }

    componentWillReceiveProps(nextProps) {
        const { data } = nextProps;
        this.setState({ rowOrder: data });
    }

    getOpenModalData = ({ dataKey, rowIndex, columnIndex, datum }) => {
        const { openEditUserModal } = this.props;
        const { id: userId } = datum;
        const cellData = datum[dataKey];
        return {
            datum,
            cellData,
            rowIndex,
            columnIndex,
            // FIXME (Nicholas): Why can't openModal just be an onClick handler?
            openModal: () => openEditUserModal(userId),
        };
    };

    getStatusCellData = ({
        dataKey,
        rowIndex,
        columnIndex,
        datum,
        ...rest
    }) => {
        // FIXME (Nicholas): Decouple cellDataGetter from props
        //                   consider including clientId in data array
        const { clientId } = this.props;
        const cellData = formatInviteData({
            clientId,
            userId: datum.id,
            status: datum[dataKey],
            clickHandler: this.inviteUserHandler,
        });
        return {
            datum,
            cellData,
            rowIndex,
            columnIndex,
            ...rest,
        };
    };

    getAccessCellData = ({
        dataKey,
        rowIndex,
        columnIndex,
        datum,
        ...rest
    }) => {
        const userAccessLists = datum[dataKey];
        let accessListObj = {
            title: 'No Access Lists',
            popoverData: [{ name: 'No access lists available' }],
        };
        if (keys(userAccessLists).length > 0) {
            const sortedAccessLists = sortBy(
                toArray(userAccessLists),
                (list) => list.name,
            );
            const allAccessList = sortedAccessLists.filter((l) => {
                return l.is_all_access_list || l.isAllAccessList;
            });
            const userHasAllAccess = !isEmpty(allAccessList);
            const title = userHasAllAccess
                ? 'All Properties'
                : truncate(sortedAccessLists[0].name, { length: 20 });
            const popoverData = userHasAllAccess
                ? [{ name: 'All Properties' }]
                : sortedAccessLists;
            accessListObj = { title, popoverData };
        }
        return {
            datum,
            cellData: accessListObj,
            rowIndex,
            columnIndex,
            ...rest,
        };
    };

    getRoleCellData = ({ dataKey, rowIndex, columnIndex, datum, ...rest }) => {
        const cellData =
            datum && datum[dataKey] !== null
                ? datum[dataKey].substring(6, datum[dataKey].length)
                : '';
        return {
            datum,
            cellData,
            rowIndex,
            columnIndex,
            ...rest,
        };
    };

    getUserStatusCellData = ({ dataKey, datum, ...rest }) => {
        const cellData = capitalize(datum[dataKey]);
        return {
            datum,
            cellData,
            ...rest,
        };
    };

    getPermissionGroupCellData = ({ dataKey, datum, ...rest }) => {
        const cellData = datum[dataKey]?.length
            ? datum[dataKey].join(', ')
            : 'All Access';
        return {
            datum,
            cellData,
            ...rest,
        };
    };

    inviteUserHandler = ({ clientId, userId }) => {
        const { inviteAUser } = this.props;
        inviteAUser(clientId, userId).then(({ status }) => {
            if (status !== 200) {
                message.error('Error', 'There was an error inviting the user');
            } else {
                const daysUntilInvitationExpiry = Math.floor(
                    window.___PASSWORD_CHANGE_TOKEN_TTL / (24 * 60 * 60),
                );
                message.success(
                    'Success',
                    `The user was successfully invited. Invitation expires if not accepted within ${daysUntilInvitationExpiry} days.`,
                );
            }
            return null;
        });
    };

    render() {
        const { rowOrder: data } = this.state;
        return (
            <div style={{ height: '500px' }}>
                <Table data={data}>
                    <Column
                        width={50}
                        dataKey="email"
                        cellDataGetter={this.getOpenModalData}
                        cellRenderer={ModalCellRenderer}
                    />
                    <Column
                        width={50}
                        dataKey="roles"
                        cellRenderer={PermissionsCellRenderer}
                    />
                    <Column
                        width={300}
                        headerTitle="Email"
                        dataKey="email"
                        sortFn={(a, b) =>
                            b.toUpperCase().localeCompare(a.toUpperCase())
                        }
                    />
                    <Column
                        width={300}
                        headerTitle="First Name"
                        dataKey="firstname"
                        sortFn={compareStrings}
                    />
                    <Column
                        width={300}
                        headerTitle="Last Name"
                        dataKey="lastname"
                        sortFn={compareStrings}
                    />
                    <Column
                        width={130}
                        headerTitle="Status"
                        dataKey="activeStatus"
                        cellDataGetter={this.getUserStatusCellData}
                    />
                    <Column
                        width={170}
                        headerTitle="Permission Group"
                        dataKey="permissionGroups"
                        cellDataGetter={this.getPermissionGroupCellData}
                        sortFn={(a, b) =>
                            b.toUpperCase().localeCompare(a.toUpperCase())
                        }
                    />
                    <Column
                        width={150}
                        headerTitle="Role"
                        dataKey="roleHighest"
                        cellDataGetter={this.getRoleCellData}
                    />
                    <Column
                        width={300}
                        headerTitle="Invitation Status"
                        dataKey="userInvitationStatus"
                        cellDataGetter={this.getStatusCellData}
                        cellRenderer={AdminLinkCellRenderer}
                        sortFn={compareStatus}
                    />
                </Table>
            </div>
        );
    }
}

const mapState = (state) => {
    // TODO (Nicholas): Use one of the two get client id selectors
    const clientId = state.user.clientObj.id;
    return { clientId };
};

// NOTE: Instead of defining a mapDispatch function to wrap a singular action creator with dispatch,
// We are simply passing it directly to connect, which does the same. Going forward, if there
// are not multiple actions a component needs to dispatch, we should use this shorthand.
const inviteAUser = (clientId, userId) =>
    mutateAsync(inviteUser({ clientId, userId }));

export default connect(mapState, { inviteAUser })(Admin);
