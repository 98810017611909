import { useGetClientAndEntityAttributeValues } from 'waypoint-hooks/data-access/useGetClientAndEntityAttributeValues';
import { PropertyDetailAttributeEnum } from 'components/propertyProfile/PropertyProfileConstants';
import { AttributeValue } from 'waypoint-types';

export type AttributesByEntityByCode = {
    [key: string]: { [key: string]: AttributeValue[] };
};
export type GetFirstAttributeValueFunc = (
    entityCode: string,
    attributeCode: string,
) => AttributeValue | undefined;

interface UseGetAttributeValuesByEntityCodeReturn {
    getFirstValue: GetFirstAttributeValueFunc;
    getValues: (
        entityCode: string,
        attributeCode: string,
    ) => AttributeValue[] | undefined;
    attributeValuesByEntityByCode: AttributesByEntityByCode | undefined;
}

export function useGetAttributeValuesByEntityCode(
    attributeCodes: string[],
    standardAttributesOnly: boolean,
): UseGetAttributeValuesByEntityCodeReturn {
    const { data: attributeValues } = useGetClientAndEntityAttributeValues(
        attributeCodes,
        standardAttributesOnly,
    );

    const attributeValuesByEntityByCode: AttributesByEntityByCode | undefined =
        attributeValues?.reduce<AttributesByEntityByCode>(
            (result, attributeValue) => {
                result[attributeValue.entity_code] =
                    result[attributeValue.entity_code] ??
                    new Map<string, AttributeValue[]>();
                result[attributeValue.entity_code][
                    attributeValue.attributeDefinition.attribute_code
                ] =
                    result[attributeValue.entity_code][
                        attributeValue.attributeDefinition.attribute_code
                    ] ?? [];
                result[attributeValue.entity_code][
                    attributeValue.attributeDefinition.attribute_code
                ].push(attributeValue);

                return result;
            },
            {},
        );

    function getValues(
        entityCode: string,
        attributeCode: string,
    ): AttributeValue[] | undefined {
        if (!attributeValuesByEntityByCode) {
            return undefined;
        }

        const valuesForEntity = attributeValuesByEntityByCode[entityCode];

        if (!valuesForEntity) {
            return undefined;
        }

        const valuesForEntityAndAttribute = valuesForEntity[attributeCode];

        if (
            !valuesForEntityAndAttribute ||
            !valuesForEntityAndAttribute.length
        ) {
            return undefined;
        }

        return valuesForEntityAndAttribute;
    }

    return {
        attributeValuesByEntityByCode,
        getFirstValue(
            entityCode: string,
            attributeCode: string,
        ): AttributeValue | undefined {
            const values = getValues(entityCode, attributeCode);

            return values?.length ? values[0] : undefined;
        },
        getValues,
    };
}
