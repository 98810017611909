import { Menu, MenuProps } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import { css } from 'emotion';

interface SubsectionMenuParams {
    menuItems: ItemType[];
    setMenuSelection: (value: string) => void;
    onSetSubsection: (subsection: string) => void;
    defaultMenuKey: string;
}

const reduceMenuItemPadding = css`
    .ant-menu-item {
        padding-left: 12px !important;
        padding-right: 12px !important;
    }
`;

const SubsectionMenu = ({
    menuItems,
    setMenuSelection,
    onSetSubsection,
    defaultMenuKey,
}: SubsectionMenuParams) => {
    const onClickMenuChild: MenuProps['onClick'] = (e) => {
        setMenuSelection(e.key);
        onSetSubsection(e.key);
    };

    return (
        <Menu
            theme="light"
            className={reduceMenuItemPadding}
            style={{
                position: 'fixed',
                width: '100%',
                zIndex: 3,
            }}
            mode="horizontal"
            items={menuItems}
            onClick={onClickMenuChild}
            defaultSelectedKeys={[defaultMenuKey]}
        />
    );
};

export default SubsectionMenu;
