import React from 'react';
import { css } from 'emotion';
import theme from 'config/theme';

const page = css`
    height: 100%;
    overflow-y: scroll;
    display: flex;
    background-color: ${theme.colors.grays.background};
    flex-grow: 1;
    flex-direction: column;
`;

interface PlanningContainerProps {
    children: React.ReactNode;
}

const PlanningContainer = (props: PlanningContainerProps) => {
    return <div className={page}>{props.children}</div>;
};

export default PlanningContainer;
