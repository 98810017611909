import { API_URL } from 'config/constants';
import { SavedConfiguration } from 'waypoint-types';

const getSavedConfigurationById = async (
    configId: string
): Promise<SavedConfiguration> => {
    if (!configId) {
        return {} as SavedConfiguration;
    }
    const response = await fetch(`${API_URL}/global-filters/id/${configId}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
    });

    if (!response.ok) {
        throw new Error('Failed to get saved configurations');
    }

    const { data } = await response.json();
    return data as SavedConfiguration;
};

export default getSavedConfigurationById;
