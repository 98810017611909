import { useEffect } from 'react';
import { useGetUserData } from 'waypoint-hooks';

export const useRedirectIfTheUserNotAdmin = ({
    history,
    route,
}: {
    history: any;
    route: string;
}) => {
    const { isAdmin } = useGetUserData();

    useEffect(() => {
        if (!isAdmin) {
            history.push(route);
        }
    }, [isAdmin, history]);

    return isAdmin;
};
