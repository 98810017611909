import { css } from 'emotion';
import { useGetSelectedFilteredEntityCodes } from 'waypoint-hooks';
import theme from 'config/theme';
import { DisabledDashboard } from 'waypoint-react';
import { Card } from 'antd';
import ResponsiveBox, {
    Col,
    Item,
    Row,
    Location,
} from 'devextreme-react/responsive-box';
import BudgetUploadSelect from './component/BudgetUploadSelect';
import { useState } from 'react';
import BudgetUploadComparisonCard from './component/BudgetUploadComparisonCard';
import { BudgetUploadEntry } from 'waypoint-types';

const pageStyle = css`
    display: flex;
    background-color: ${theme.colors.grays.background};
    overflow-y: scroll;
    flex-grow: 1;
    flex-direction: column;
    height: 100%;
`;

const BudgetUploadContainer = (): JSX.Element => {
    const [currentUploadBudget, setCurrentUploadBudget] = useState<
        BudgetUploadEntry[] | null
    >(null);
    const [currentUploadBudgetId, setCurrentUploadBudgetId] = useState<
        string | null
    >(null);
    const [selectedEntityCode, setSelectedEntityCode] = useState<string | null>(
        null,
    );
    const [selectedSourceCode, setSelectedSourceCode] = useState<string | null>(
        null,
    );
    const [selectedBudgetYear, setSelectedBudgetYear] = useState<string | null>(
        null,
    );

    const entityCodes: string[] = useGetSelectedFilteredEntityCodes();

    if (entityCodes.length === 0) {
        return (
            <div className={pageStyle}>
                <DisabledDashboard text={'Loading'} />;
            </div>
        );
    }
    return (
        <div className={pageStyle}>
            <Card
                style={{ margin: 20, height: 'calc(100% - 48px)' }}
                data-testid="budget-upload-card"
                loading={false}
                title={'Data Upload'}
            >
                <ResponsiveBox>
                    <Row />
                    <Col baseSize={300} ratio={0} />
                    <Col ratio={5} />
                    <Item>
                        <Location row={0} col={0} />
                        <div
                            style={{
                                border: '1px solid lightgrey',
                                padding: '10px',
                                margin: '10px',
                            }}
                        >
                            <BudgetUploadSelect
                                entityCodes={entityCodes}
                                selectedEntityCode={selectedEntityCode}
                                setSelectedEntityCode={setSelectedEntityCode}
                                selectedSourceCode={selectedSourceCode}
                                setSelectedSourceCode={setSelectedSourceCode}
                                selectedBudgetYear={selectedBudgetYear}
                                setSelectedBudgetYear={setSelectedBudgetYear}
                                setCurrentUploadBudget={setCurrentUploadBudget}
                                setCurrentUploadBudgetId={
                                    setCurrentUploadBudgetId
                                }
                            />
                        </div>
                    </Item>
                    <Item>
                        <Location row={0} col={1} />
                        <div
                            style={{
                                border: '1px solid lightgrey',
                                padding: '10px 0',
                                margin: '10px',
                                position: 'relative',
                                top: '0px',
                                bottom: '30px',
                            }}
                        >
                            <BudgetUploadComparisonCard
                                entityCodes={entityCodes}
                                selectedEntityCode={selectedEntityCode}
                                setSelectedEntityCode={setSelectedEntityCode}
                                selectedSourceCode={selectedSourceCode}
                                setSelectedSourceCode={setSelectedSourceCode}
                                selectedBudgetYear={selectedBudgetYear}
                                setSelectedBudgetYear={setSelectedBudgetYear}
                                currentUploadBudget={currentUploadBudget}
                                setCurrentUploadBudget={setCurrentUploadBudget}
                                currentUploadBudgetId={currentUploadBudgetId}
                                setCurrentUploadBudgetId={
                                    setCurrentUploadBudgetId
                                }
                            />
                        </div>
                    </Item>
                </ResponsiveBox>
            </Card>
        </div>
    );
};

export default BudgetUploadContainer;
