import React, { useState } from 'react';
import { css } from 'emotion';
import moment, { Moment } from 'moment';
import { get } from 'lodash';

import { SelectedDataLevel } from 'waypoint-types';
import { RangeSelectConfirm } from 'waypoint-react';
import {
    searchAccountGraphForAccountName,
    getDataGridPropFor,
    getDateRangeForPeriod,
} from 'waypoint-utils';
import { defaultDisplayValue } from 'components/analytics/financialOverview/menu/displayType/constants';
import { requestHashMap } from 'components/analytics/financialOverview/requests';
import { PERIODICITY_MONTH_VALUE } from 'components/analytics/constants';
import Grid from 'components/grids/Grid';
import { FinancialOverviewGridItem } from 'components/analytics/financialOverview/grid';
import {
    DashboardConfigItemType,
    DashboardConfigSlimType,
} from 'waypoint-types/data-grid/types';

const dateRangeContainer = css`
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 9px;
    right: 300px;
    z-index: 2;
    > div {
        padding: 0;
    }
`;

const FINANCIAL_RANGE_SELECT_ID = 'financial-overview-range-select';

interface FinancialGridProps {
    accountGraph: Object;
    asOfDate: Date;
    dashboardConfiguration: DashboardConfigSlimType;
    entityCodes: string[];
    selectedDataLevel: SelectedDataLevel;
}

interface Selection {
    accountMapping: {
        name: string;
        code: string;
    };
    isHideNull: boolean;
    displayType: string;
    periodicity: string;
    period: Moment[];
}

const FinancialGrid = ({
    accountGraph,
    asOfDate,
    dashboardConfiguration,
    entityCodes,
    selectedDataLevel,
}: FinancialGridProps): JSX.Element => {
    const [localGlobalPeriod, setlocalGlobalPeriod] = useState<Moment[] | null>(
        null
    );

    const [rangeSelectIsOpen, setRangeSelectIsOpen] = useState<boolean>(false);

    function getDefaultGlobalPeriodForEntity(): Moment[] {
        const [start, end] = getDateRangeForPeriod('trailing_12', asOfDate);
        return [moment(start), moment(end)];
    }

    function getDefaultSelectionsFor(item: DashboardConfigItemType): Selection {
        return {
            accountMapping: {
                name:
                    searchAccountGraphForAccountName(
                        accountGraph,
                        item.account_mapping_code
                    ) ||
                    item.account_mapping_code ||
                    '',
                code: item.account_mapping_code || '',
            },
            isHideNull: true,
            displayType: defaultDisplayValue,
            periodicity: PERIODICITY_MONTH_VALUE,
            period: getDefaultGlobalPeriodForEntity(),
        };
    }

    return (
        <>
            <div className={dateRangeContainer} id={FINANCIAL_RANGE_SELECT_ID}>
                <RangeSelectConfirm
                    open={rangeSelectIsOpen}
                    onFocus={() => setRangeSelectIsOpen(true)}
                    onConfirm={(values) => {
                        setRangeSelectIsOpen(false);
                        setlocalGlobalPeriod(values);
                    }}
                    onCancel={() => setRangeSelectIsOpen(false)}
                    value={
                        localGlobalPeriod || getDefaultGlobalPeriodForEntity()
                    }
                    getCalendarContainer={() =>
                        document.getElementById(FINANCIAL_RANGE_SELECT_ID)
                    }
                />
            </div>
            <Grid>
                {dashboardConfiguration.items.map(
                    (i: DashboardConfigItemType) => {
                        const dataGridProp = getDataGridPropFor(i);

                        const request: () => void = get(
                            requestHashMap,
                            i.component
                        );
                        return (
                            <FinancialOverviewGridItem
                                title={i.title}
                                key={JSON.stringify(dataGridProp)}
                                data-grid={dataGridProp}
                                componentName={i.component}
                                defaults={getDefaultSelectionsFor(i)}
                                globalPeriod={
                                    localGlobalPeriod ||
                                    getDefaultGlobalPeriodForEntity()
                                }
                                accountGraph={accountGraph}
                                entityCodes={entityCodes}
                                selectedDataLevel={selectedDataLevel}
                                request={request}
                            />
                        );
                    }
                )}
            </Grid>
        </>
    );
};

export default FinancialGrid;
