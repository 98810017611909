import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import CaretControl from './CaretControl';
import defaultHeaderStylePP from './defaultHeaderStylePP';
import PopoverGeneric from 'components/style/PopoverGeneric';

const titleContainer = { display: 'flex', alignItems: 'center' };

const defaultIcon = <i className="fa-regular fa-question-circle" />;

// NOTE: Extend this as needed

const TooltipHeaderCell = ({ cellData, ...props }) => {
    const { headerParameters, headerTitle } = cellData;
    const {
        style,
        active,
        onClick,
        disabled,
        sortable,
        sortDirection,
        ...rest
    } = props;

    const { icon, message, headerStyle, popoverPlacement, iconContainerStyle } =
        headerParameters;

    const finalHeaderStyle = Object.assign({}, style, headerStyle, {
        backgroundColor: '#FFF',
    });

    return (
        <div title={headerTitle} style={finalHeaderStyle} {...rest}>
            <div style={titleContainer}>
                {headerTitle}
                <span style={iconContainerStyle}>
                    <PopoverGeneric
                        placement={popoverPlacement || 'top'}
                        message={message}
                        icon={icon || defaultIcon}
                    />
                </span>
            </div>
            {sortable && (
                <CaretControl
                    active={active}
                    disabled={disabled}
                    sortDirection={sortDirection}
                    onClick={onClick}
                />
            )}
        </div>
    );
};

TooltipHeaderCell.propTypes = {
    style: PropTypes.object,
    active: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    sortable: PropTypes.bool,
    cellData: PropTypes.object,
    sortDirection: PropTypes.string,
};

export const TooltipHeaderCellRenderer =
    defaultHeaderStylePP(TooltipHeaderCell);
export default TooltipHeaderCellRenderer;
