import PropTypes from 'prop-types';
import React from 'react';
import FilterModalPopover from './FilterModalPopover';
import styles from './filtermodal.module.css';

export default function FilterHeading({ isModal, isDisabled, heading, error }) {
    return isDisabled ? (
        <div
            className={
                isModal ? styles.disabledInputLabel : styles.searchBarTitle
            }
        >
            <FilterModalPopover>
                <div
                    className={
                        isModal
                            ? styles.disabledInputLabel
                            : styles.searchBarTitle
                    }
                >
                    {heading}
                </div>
            </FilterModalPopover>
        </div>
    ) : (
        <div className={isModal ? styles.inputLabel : styles.searchBarTitle}>
            {heading}
            {error && <span className={styles.error}>{error}</span>}
        </div>
    );
}

FilterHeading.propTypes = {
    isModal: PropTypes.bool,
    isDisabled: PropTypes.bool,
    heading: PropTypes.string,
    error: PropTypes.string,
};
