import moment, { Moment, MomentInput } from 'moment';
import { isNull, isUndefined } from 'lodash';
import { DASH_DASH } from 'config/constants';

export const reportToTimestamp = ({
    asOfMonth,
    asOfYear,
}: {
    asOfMonth: string | number;
    asOfYear: string | number;
}): number => {
    return Number(
        moment.utc(`${asOfYear}-${asOfMonth}`, 'YYYY-MM').format('x')
    );
};

export const timestampToMonth = (
    t: MomentInput,
    formatAsUtc = true
): string => {
    return formatAsUtc
        ? moment.utc(t, 'x').format('MMMM YYYY')
        : moment(t, 'x').format('MMMM YYYY');
};

export const timestampToQuarter = (
    t: MomentInput,
    formatAsUtc = true
): string => {
    return formatAsUtc
        ? 'Q' + moment.utc(t, 'x').format('Q YYYY')
        : 'Q' + moment(t, 'x').format('Q YYYY');
};

export const getMonthFromTimestamp = (t: MomentInput): string =>
    moment.utc(t, 'x').format('MM');
export const getYearFromTimestamp = (t: MomentInput): string =>
    moment.utc(t, 'x').format('YYYY');

export const timestampToFullDate = (t: MomentInput): string => {
    return isNull(t) || isUndefined(t)
        ? DASH_DASH
        : moment.utc(t).format('MM/DD/YYYY');
};

export const toUnix = (date: MomentInput): number => moment(date).valueOf();
export const toISO = (date: MomentInput): string =>
    moment(date).format('YYYY-MM-DD');
export const toQuarter = (date: MomentInput): number => moment(date).quarter();
export const toCalendarYear = (date: MomentInput): string =>
    moment(date).format('YYYY');
export const toCalendarDate = (date: MomentInput): string =>
    moment(date).format('MMMM DD, YYYY');
export const toCalendarMonth = (date: MomentInput): string =>
    moment(date).format('MMMM');
export const toCalendarMonthAbbrv = (date: MomentInput): string =>
    moment(date).format('MMM');
export const toCalendarMonthAndYearAbbrv = (date: MomentInput) =>
    moment(date).format('MMM YYYY');
export const toMonthAndYear = (date: MomentInput): string =>
    moment(date).format('MMMM YYYY');
export const toStartOfMonth = (date: MomentInput): Moment =>
    moment(date).startOf('month');
export const toEndOfMonth = (date: MomentInput): Moment =>
    moment(date).endOf('month');
export const subtractMonths = (date: MomentInput, quantity: number): Moment =>
    moment(date).subtract(quantity, 'month');
export const toDate = (date: MomentInput): Date => moment(date).toDate();

export const toMoment = (date: MomentInput): Moment => moment(date);
export const getMinDateInArray = (array: Moment[]) =>
    moment.min(array).toDate();

export const getMaxDateInArray = (array: Moment[]) =>
    moment.max(array).toDate();
