import React, { useState } from 'react';
import { DatePicker } from 'waypoint-react';
import { ReportWidgetSettingsBuilderProps } from 'waypoint-types';
import ReportWidgetSettingsModal from 'components/reports/report-widget/ReportWidgetSettingsModal';
import { useGetYearMetadata } from 'waypoint-hooks';
import moment from 'moment';

const LeasingGuidelinesSettingsBuilder = ({
    entityCodes,
    isModalOpen,
    setIsModalOpen,
    onSaveWidgetSettings,
    widgetType,
    currentSettings,
}: ReportWidgetSettingsBuilderProps): JSX.Element => {
    const type = 'leasing-guidelines';
    const [selectedYear, setSelectedYear] = useState<number[] | undefined>(
        currentSettings?.selectedYear,
    );
    const [isYearValid, setIsYearValid] = useState(true);

    const { data: yearMetadata } = useGetYearMetadata({ entityCodes, type });

    const buildReportWidgetSettingsInputs = () => {
        return {
            selectedYear,
        };
    };

    if (!yearMetadata) {
        return <></>;
    }

    const onSave = () => {
        if (!selectedYear) {
            setIsYearValid(false);
            return;
        }
        onSaveWidgetSettings(buildReportWidgetSettingsInputs());
    };

    return (
        <ReportWidgetSettingsModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            settingsJsonBuilder={buildReportWidgetSettingsInputs}
            onSaveWidgetSettings={onSave}
            widgetType={widgetType}
        >
            <div style={{ marginBottom: '25px' }}>
                <>
                    <div style={{ fontSize: '12px', fontWeight: 'bold' }}>
                        Years
                    </div>
                    <DatePicker
                        onChange={(value) => {
                            if (value) {
                                setSelectedYear([value.year()]);
                            }
                        }}
                        placeholder="Select year"
                        picker="year"
                        status={isYearValid ? undefined : 'error'}
                        value={
                            selectedYear
                                ? moment(
                                      new Date().setFullYear(selectedYear[0]),
                                  )
                                : undefined
                        }
                    />
                    {!isYearValid && (
                        <div style={{ color: 'red', marginTop: '8px' }}>
                            You need to select a Year.
                        </div>
                    )}
                </>
            </div>
        </ReportWidgetSettingsModal>
    );
};

export default LeasingGuidelinesSettingsBuilder;
