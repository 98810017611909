import React from 'react';
import FusionCharts from 'fusioncharts';
import charts from 'fusioncharts/fusioncharts.charts';
import fusiontheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import ReactFC from 'react-fusioncharts';
import { buildFinancialOverviewOvertimeChartConfig } from 'components/analytics/financialOverview/charts/overtime/utils';
import { getOvertimeDataset } from 'components/analytics/utils';

charts(FusionCharts);
fusiontheme(FusionCharts);

const OvertimeChart = ({ data = [], periodicity, displayType }) => {
    return (
        <ReactFC
            {...buildFinancialOverviewOvertimeChartConfig({
                data: getOvertimeDataset(data, periodicity),
                displayType,
            })}
        />
    );
};

export default OvertimeChart;
