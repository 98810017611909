import { useContext } from 'react';
import {
    EntityAttributesContext,
    EntityAttributesContextType,
    SavedFilter,
} from 'contexts';
import {
    funnelEntities,
    convertSavedFilterToSelectedAttributes,
} from 'components/app/global-filter-drawer/globalFilterUtils';
import { SelectedAttributes } from 'components/app/global-filter-drawer/types/globalFilterTypes';
import { ACTIVE_GLOBAL_FILTER_KEY } from 'components/app/global-filter-drawer/GlobalFilterConstants';
import { useClientPrefixedLocalStorage } from './useClientPrefixedLocalStorage';
import { Entity } from 'waypoint-types';

/**
 * This hook's main purpose to return a collection of entity codes that represent entities that have passed attribute filters.
 *  @return filteredEntityCodes: entity codes that pass attribute filters.
 *  @return filteredEntities: collection of entity objects that pass attribute filters. Not just entity codes.
 */
interface FilteredEntityCodes {
    filteredEntityCodes: string[];
    filteredEntities: Entity[];
}

export function useGetFilteredEntityCodes(): FilteredEntityCodes {
    const entityAttributesContext =
        useContext<EntityAttributesContextType | null>(EntityAttributesContext);

    if (!entityAttributesContext) {
        throw Error(
            'useGetFilteredEntityCodes must be used within a EntityAttributesContextProvider',
        );
    }

    const [appliedAttributeFilter] =
        useClientPrefixedLocalStorage<SavedFilter | null>(
            ACTIVE_GLOBAL_FILTER_KEY,
            null,
        );

    const attributes = entityAttributesContext.data
        ? entityAttributesContext.data.attributeDefinitions
        : [];

    const allEntities = entityAttributesContext.data
        ? entityAttributesContext.data.entities
        : [];

    // If no filters applied, return all entity codes/entities
    if (!appliedAttributeFilter?.filters?.length) {
        const allEntityCodes = allEntities.map(
            (ent: Entity) => `${ent.entityCode}`,
        );

        return {
            filteredEntityCodes: allEntityCodes,
            filteredEntities: allEntities,
        };
    }

    const appliedAttributeFilterWithUUIDs: SelectedAttributes =
        convertSavedFilterToSelectedAttributes(appliedAttributeFilter);

    //...otherwise run entities through funnel
    const filteredEntities: Entity[] = funnelEntities(
        attributes,
        allEntities,
        appliedAttributeFilterWithUUIDs,
    );

    const filteredEntityCodes: string[] = filteredEntities.map(
        (ent: Entity) => `${ent.entityCode}`,
    );

    return {
        filteredEntityCodes,
        filteredEntities,
    };
}
