import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { selectDefaultBalanceSheetOverviewDashboardId } from 'state/dashboardConfigurations/selectors';
import FinancialDashboard from 'components/analytics/FinancialDashboard';
import { BalanceSheetOverviewGrid } from 'components/analytics/balance-sheet-overview/grid';
import { AppFeaturePermissions } from 'contexts';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';
/**
 * Renders a grid. See FinancialDashboard for most of the logic. It fetches several data dependencies.
 */
const BalanceSheetOverviewDashboard = ({ dashboardConfigurationId }) => {
    return (
        <PermissionedWrapper
            featureKey={AppFeaturePermissions.BalanceSheetOverview}
            showDisabledView={true}
        >
            <FinancialDashboard
                module="balance_sheet"
                accountGraphCode="balance_sheet"
                component={BalanceSheetOverviewGrid}
                dashboardConfigurationId={dashboardConfigurationId}
            />
        </PermissionedWrapper>
    );
};

BalanceSheetOverviewDashboard.propTypes = {
    dashboardConfigurationId: PropTypes.number,
};

const mapState = (s) => ({
    dashboardConfigurationId: selectDefaultBalanceSheetOverviewDashboardId(s),
});

export default connect(mapState)(BalanceSheetOverviewDashboard);
