import React from 'react';
import { get } from 'lodash';
import { css } from 'emotion';
import Select from 'antd/lib/select';
import { Button } from 'antd';
import { DASH_DASH } from 'config/constants';

const { Option } = Select;

const userPermisssionStyle = css`
    display: inline-block;
    text-align: right;
    margin-left: 50px;
    width: 70%;
`;
const copyPermissionContainer = css`
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
    text-align: left;
`;
const copyPermissionLegend = css`
    font-size: 16px;
    width: 22%;
    padding-left: 10px;
    border: 0;
`;
const selectUser = css`
    width: 80%;
`;
const applyButton = css`
    margin-left: 20px;
    width: 16%;
`;

const PermissionsSelect = ({ users, onChange, onApply }) => {
    return (
        <div className={userPermisssionStyle}>
            <fieldset className={copyPermissionContainer}>
                <legend className={copyPermissionLegend}>
                    Copy Permissions
                </legend>
                <Select
                    allowClear
                    className={selectUser}
                    onChange={onChange}
                    placeholder="Select a user"
                >
                    {users.map((option) => {
                        return (
                            <Option value={option.id} key={option.id}>
                                {get(option, 'firstname', DASH_DASH)}{' '}
                                {get(option, 'lastname', '')}
                            </Option>
                        );
                    })}
                </Select>
                <Button
                    className={applyButton}
                    onClick={onApply}
                    type="primary"
                >
                    Apply
                </Button>
            </fieldset>
        </div>
    );
};

export default PermissionsSelect;
