import React, { createContext, ReactNode, useContext, useState } from 'react';
import {
    FinancialsProvider,
    useFinancials,
} from 'contexts/financials/FinancialsProvider';
import { AvailabilityDateType } from 'components/financials/balanceSheet/types';
import { toISO } from 'components/dates/utils';
import moment from 'moment';
import { getBalanceSheetMetadataRequestFilters } from 'components/financials/balanceSheet/components/banner/utils';
import {
    AccountGraphNode,
    MentionableDataSource,
    Note,
    ReportMetadata,
    SelectedDataLevel,
} from 'waypoint-types';
import { useGetBalanceSheetData } from 'waypoint-hooks/data-access/useGetBalanceSheetData';
import { decorateFinancialsAccountGraphForAntDesign } from 'waypoint-utils';
import { KeyedMutator } from 'swr';
import { getMentionableAccounts } from 'waypoint-utils';

export const BALANCE_SHEET_REPORT_TYPE = 'balance-sheet';

interface ProviderProps {
    entityCodes?: string[];
    selectedDataLevel: SelectedDataLevel;
    children: ReactNode;
}

interface BalanceSheetContextData {
    isLoading: boolean;
    isError: boolean;
    entityCodes: string[];
    selectedDataLevel: SelectedDataLevel;
    balanceSheetNodes?: AccountGraphNode[];
    hideNull: boolean;
    setHideNull: (value: boolean) => void;
    hideNotes: boolean;
    setHideNotes: (value: boolean) => void;
    dateSelections: AvailabilityDateType;
    setDateSelections: (selections: AvailabilityDateType) => void;
    commentIdQueryParam: string | null;
    reportMetadata?: ReportMetadata;
    reportNotes: Note[];
    mutateReportNotes: KeyedMutator<Note[]>;
    mentionableAccounts: MentionableDataSource[];
}
const BalanceSheetContext = createContext<BalanceSheetContextData>(
    {} as BalanceSheetContextData,
);

export const BalanceSheetProvider = ({
    entityCodes,
    selectedDataLevel,
    children,
}: ProviderProps) => {
    return (
        <FinancialsProvider
            entityCodes={entityCodes}
            reportType={BALANCE_SHEET_REPORT_TYPE}
        >
            <BalanceSheetProviderInternal selectedDataLevel={selectedDataLevel}>
                {children}
            </BalanceSheetProviderInternal>
        </FinancialsProvider>
    );
};

const BalanceSheetProviderInternal = ({
    children,
    selectedDataLevel,
}: ProviderProps): JSX.Element => {
    const [balanceSheetNodes, setBalanceSheetNodes] = useState<
        AccountGraphNode[] | undefined
    >(undefined);
    const [hideNull, setHideNull] = useState<boolean>(false);
    const [hideNotes, setHideNotes] = useState<boolean>(false);
    const [userDateSelections, setUserDateSelections] =
        useState<AvailabilityDateType | null>(null);
    const [mentionableAccounts, setMentionableAccounts] = useState<
        MentionableDataSource[]
    >([]);

    const {
        asOfDate,
        commentIdQueryParam,
        setReportMetadataFilters,
        reportMetadata,
        reportNotes,
        mutateReportNotes,
        isLoading: isLoadingFinancials,
        isError: isErrorFinancials,
        entityCodes,
        clearQueryParams,
    } = useFinancials();

    const getDateSelections = (): AvailabilityDateType => {
        if (!asOfDate && !userDateSelections) {
            return {
                primary: '',
                secondary: '',
                primaryDefault: '',
                secondaryDefault: '',
            };
        }

        const primaryDefault = toISO(asOfDate);
        const secondaryDefault = moment(asOfDate)
            .subtract(1, 'month')
            .endOf('month')
            .format('YYYY-MM-DD');

        if (reportMetadata) {
            const filters = JSON.parse(reportMetadata.filter_raw_json);

            const periodStart = filters[0].end_date;
            const periodEnd = filters[1].end_date;

            return {
                primary: periodStart,
                primaryDefault: primaryDefault,
                secondary: periodEnd,
                secondaryDefault: secondaryDefault,
            };
        }

        if (userDateSelections) {
            return userDateSelections;
        }

        return {
            primary: primaryDefault,
            primaryDefault: primaryDefault,
            secondary: secondaryDefault,
            secondaryDefault: secondaryDefault,
        };
    };

    const dateSelections = getDateSelections();

    const {
        data: balanceSheet,
        isLoading: isLoadingBalanceSheet,
        isError: isErrorBalanceSheet,
    } = useGetBalanceSheetData({
        entityCodes,
        primaryPeriod: dateSelections.primary,
        secondaryPeriod: dateSelections.secondary,
        selectedDataLevel,
    });

    React.useEffect(() => {
        if (!balanceSheet) {
            return;
        }

        const decoratedData = decorateFinancialsAccountGraphForAntDesign(
            balanceSheet.children,
            false,
            '',
            null,
            true,
        );

        setBalanceSheetNodes(decoratedData);
        setMentionableAccounts(getMentionableAccounts(decoratedData));

        updateDateSelections(dateSelections);
    }, [balanceSheet]);

    const updateDateSelections = (selections: AvailabilityDateType) => {
        setReportMetadataFilters(
            getBalanceSheetMetadataRequestFilters([
                selections.primary,
                selections.secondary,
            ]),
        );

        setUserDateSelections(selections);
    };

    return (
        <BalanceSheetContext.Provider
            value={{
                isError: isErrorBalanceSheet || isErrorFinancials,
                isLoading: isLoadingFinancials || isLoadingBalanceSheet,
                entityCodes,
                selectedDataLevel,
                balanceSheetNodes,
                dateSelections,
                setDateSelections: (selections) => {
                    clearQueryParams();
                    updateDateSelections(selections);
                },
                hideNull,
                setHideNull,
                hideNotes,
                setHideNotes,
                commentIdQueryParam,
                reportMetadata,
                reportNotes,
                mutateReportNotes,
                mentionableAccounts,
            }}
        >
            {children}
        </BalanceSheetContext.Provider>
    );
};

export const useBalanceSheet = (): BalanceSheetContextData => {
    return useContext(BalanceSheetContext);
};
