import { API_URL } from 'config/constants';

interface EntityReportWorkflowRoleParams {
    entityCode: string;
    reportRole: string;
    reportType: string;
    workflowType: string;
    addedUserIds: number[];
    removedRowIds: string[];
}

const addOrRemoveWorkflowRoles = async (
    entityReportWorkflowRoleParams: EntityReportWorkflowRoleParams
): Promise<void> => {
    const response = await fetch(`${API_URL}/workflow-roles`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(entityReportWorkflowRoleParams),
    });

    if (!response.ok) {
        throw new Error('Failed to create workflow role');
    }
};

export default addOrRemoveWorkflowRoles;
