import { API_URL } from 'config/constants';
import { LeasingPlan } from 'waypoint-types/leasing-plans';

const getLeasingPlans = async (
    entityCodes: string[],
    planYear?: number
): Promise<LeasingPlan[]> => {
    const response = await fetch(`${API_URL}/leasing-plans`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            entity_codes: entityCodes,
            plan_year: planYear,
        }),
    });

    if (!response.ok) {
        throw new Error('Failed to get leasing plans');
    }

    const { data } = await response.json();
    return data as LeasingPlan[];
};

export default getLeasingPlans;
