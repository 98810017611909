import { isEqual } from 'lodash';
import memoizeOne from 'memoize-one';
import {
    RecurringCharge,
    RentRollProps,
    RecurringChargeRollUp,
    RecurringChargeRollUpByEntityCode,
} from 'waypoint-types';
import { safeDivision } from 'waypoint-utils';

const STATUS_OCCUPIED = 'OCCUPIED';

export const isRecurringChargeLease = (
    recurringCharge: RecurringCharge,
    rentRoll: RentRollProps
) => {
    return (
        recurringCharge.leasable_space_code === rentRoll.leasable_space_code &&
        recurringCharge.lease_code === rentRoll.lease_code
    );
};

const getChargeName = (chargeName: string, chargeCode: string) => {
    if (!chargeName.includes(`(${chargeCode})`)) {
        return `${chargeName} (${chargeCode})`;
    }
    return chargeName;
};

export const getRecurringChargeByRentRoll = (
    recurringCharge: RecurringCharge[],
    rentRoll: RentRollProps
) => {
    const selectedCharge = recurringCharge.filter((item) =>
        isRecurringChargeLease(item, rentRoll)
    );

    const totalMonthly = selectedCharge.reduce(
        (total, item) => total + item.monthly,
        0
    );
    return selectedCharge.map((item) => {
        item.charge_name = getChargeName(item.charge_name, item.charge_code);

        return {
            ...item,
            monthly_per_sq_ft: safeDivision(
                item.monthly,
                rentRoll.rentable_sq_ft
            ),
            annual_per_sq_ft: safeDivision(
                item.annual,
                rentRoll.rentable_sq_ft
            ),
            percentage: safeDivision(item.monthly, totalMonthly),
        };
    }, {});
};

export const isUnitOccupied = (rentRoll: RentRollProps) => {
    return rentRoll
        ? rentRoll.space_occupancy_status === STATUS_OCCUPIED
        : false;
};

export const getRecurringChargeRollupByEntityCode = (
    recurringCharges: RecurringCharge[],
    entityCodes: string[],
    occupiedSqFt: number,
    totalUnits: number
) => {
    const filteredRecurringCharges = recurringCharges.filter((rc) =>
        entityCodes.includes(rc.entity_code)
    );

    const recurringChargesRollUp = filteredRecurringCharges.reduce(
        (acc: RecurringChargeRollUpByEntityCode[], curr: RecurringCharge) => {
            const charge = acc.find((a) => a.charge_code === curr.charge_code);
            if (charge) {
                charge.monthly += curr.monthly;
                charge.percentage += curr.percentage;
                charge.annual += curr.annual;
                charge.annual_per_sq_ft = safeDivision(
                    charge.annual,
                    occupiedSqFt
                );
                charge.annual_per_unit = safeDivision(
                    charge.annual,
                    totalUnits
                );
                charge.monthly_per_sq_ft = safeDivision(
                    charge.monthly,
                    occupiedSqFt
                );
                charge.monthly_per_unit = safeDivision(
                    charge.monthly,
                    totalUnits
                );
            } else {
                acc.push({
                    bucket_name: curr.bucket_name,
                    total: curr.total,
                    charge_code: curr.charge_code,
                    charge_name: getChargeName(
                        curr.charge_name,
                        curr.charge_code
                    ),
                    percentage: curr.percentage,
                    monthly: curr.monthly,
                    annual: curr.annual,
                    annual_per_sq_ft: safeDivision(curr.annual, occupiedSqFt),
                    annual_per_unit: safeDivision(curr.annual, totalUnits),
                    monthly_per_sq_ft: safeDivision(curr.monthly, occupiedSqFt),
                    monthly_per_unit: safeDivision(curr.monthly, totalUnits),
                });
            }
            return acc;
        },
        [] as RecurringChargeRollUpByEntityCode[]
    );
    return recurringChargesRollUp;
};

const filterCharges = (
    recurringCharges: RecurringCharge[] | RecurringChargeRollUp[],
    selectedChargeCode: string[]
): RecurringCharge[] | RecurringChargeRollUp[] => {
    if (!selectedChargeCode.length) {
        return recurringCharges;
    }

    const selectedChargeCodeSet = new Set(selectedChargeCode);

    return recurringCharges.filter(
        (recurringCharge) =>
            selectedChargeCodeSet.has(recurringCharge.charge_code) ||
            selectedChargeCodeSet.has(`bucket_${recurringCharge.bucket_name}`)
    );
};

export const getRecurringChargeTotals = (
    recurringCharges: RecurringCharge[] | RecurringChargeRollUpByEntityCode[]
) => {
    // typescript doesn't like unions with reduce functions
    const typedCharges = recurringCharges as RecurringCharge[];
    return typedCharges.reduce(
        (acc, charge) => {
            acc.totalAnnual += charge.annual;
            acc.totalMonthly += charge.monthly;
            return acc;
        },
        {
            totalAnnual: 0,
            totalMonthly: 0,
        }
    );
};

export const filterRecurringCharges = memoizeOne(filterCharges, isEqual);
