export enum AppFeaturePermissions {
    MyReports = 'my_reports',
    AgedReceivables = 'aged_receivables',
    PropertyValueDebt = 'property_value_debt',
    Ranking = 'ranking',
    BalanceSheetOverview = 'balance_sheet_overview',
    BalanceSheet = 'balance_sheet',
    Crosstab = 'crosstab',
    VarianceReporting = 'variance_reporting',
    PerformanceOverview = 'performance_overview',
    RentRoll = 'rent_roll',
    ExpirationSchedule = 'expiration_schedule',
    Tenants = 'tenants',
    TopTenants = 'top_tenants',
    OccupancyTrend = 'occupancy_trend',
    UnitMix = 'unit_mix',
    CapitalProjects = 'capital_projects',
    PrioritiesAndObjectives = 'priorities_and_objectives',
    HoldSell = 'hold_sell',
    MarketOverview = 'market_overview',
    Swot = 'swot',
    LeasingGuidelines = 'leasing_guidelines',
    LeasingPlan = 'leasing_plan',
    Contracts = 'contracts',
    Attributes = 'attributes',
    FinancialOverview = 'financial_overview',
    PortfolioSummary = 'portfolio_summary',
    IncomeStatement = 'income_statement',
    ExecutiveSummary = 'executive_summary',
    PropertyInformation = 'property_information',
    Valuations = 'valuations',
    Documents = 'documents',
}

export const allAccessPermissions = Object.values(AppFeaturePermissions);
