import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { css } from 'emotion';
import theme from 'config/theme';
import { getComparisonTypeSelect } from './CrosstabSelections';
import { CrosstabRangeSelect } from './components/CrosstabRangeSelect';
import { Skeleton } from 'antd';
import {
    CROSSTAB_DEFAULT_PRIMARY_MODE,
    CROSSTAB_DEFAULT_SECONDARY_MODE,
} from './CrosstabConstants';
import PivotGridDataSource, {
    Field,
} from 'devextreme/ui/pivot_grid/data_source';
import { CrosstabAccountNode } from './accounts';
import { Dictionary } from 'ts-essentials';
import { Moment } from 'moment';
import { useGetCrosstabAsOfDate } from './hooks';
import {
    useGetClientModes,
    useGetSelectedFilteredEntityCodes,
} from 'waypoint-hooks';

const buttonStyle = css`
    margin-right: 24px;
    &.ant-btn-primary:enabled {
        color: ${theme.colors.white} !important;
        background-color: ${theme.colors.blues.primary} !important;
        border-color: ${theme.colors.blues.outline} !important;
    }
`;

const modalStyle = css`
    width: max-content !important;
    .ant-modal-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h2 {
            margin: 0 !important;
        }
    }
    .ant-modal-footer {
        margin-top: 24px !important;
        text-align: right;
    }
`;

export const CrosstabComparisonModal = ({
    modeSelection,
    setModeSelection,
    setIsOpenSettings,
    isOpenSettings,
    periodRange,
    setPeriodRange,
}: {
    modeSelection: string[];
    setModeSelection: (value: string[]) => void;
    setIsOpenSettings: (value: boolean) => void;
    isOpenSettings: boolean;
    periodRange: [Moment, Moment] | null;
    setPeriodRange: (value: [Moment, Moment] | null) => void;
}) => {
    const entityCodes = useGetSelectedFilteredEntityCodes();
    const { asOfDate } = useGetCrosstabAsOfDate(entityCodes);

    const [localModeSelection, setLocalModeSelection] =
        useState<string[]>(modeSelection);
    const [localPeriodRange, setLocalPeriodRange] = useState<
        [Moment, Moment] | null
    >(periodRange ?? asOfDate);

    const [isRangeSelectOpen, setRangeSelectIsOpen] = useState<boolean>(false);

    const { data: clientModes } = useGetClientModes();

    const setPrimaryLocalModeSelection = (value: string) => {
        setLocalModeSelection([value, localModeSelection[1]]);
    };

    const setSecondaryLocalModeSelection = (value: string) => {
        setLocalModeSelection([localModeSelection[0], value]);
    };

    useEffect(() => {
        setLocalPeriodRange(periodRange ?? asOfDate);
        setLocalModeSelection(modeSelection);
    }, [periodRange, asOfDate, modeSelection]);

    const onPeriodRangeChange = useCallback(
        (range: [Moment, Moment] | null) => {
            setLocalPeriodRange(range);
        },
        []
    );

    const onResetDefault = () => {
        setLocalPeriodRange(asOfDate);
        setLocalModeSelection([
            CROSSTAB_DEFAULT_PRIMARY_MODE,
            CROSSTAB_DEFAULT_SECONDARY_MODE,
        ]);
    };

    const selectionsCanApplyChanges = () => {
        return (
            localModeSelection[0] === modeSelection[0] &&
            localModeSelection[1] === modeSelection[1] &&
            localPeriodRange === periodRange
        );
    };

    const selectionsAreUnchangedOrDefault = () => {
        return (
            localModeSelection[0] === CROSSTAB_DEFAULT_PRIMARY_MODE &&
            localModeSelection[1] === CROSSTAB_DEFAULT_SECONDARY_MODE &&
            localPeriodRange === asOfDate
        );
    };

    const onResetPreviousConfig = () => {
        setLocalPeriodRange(periodRange ?? asOfDate);
        setLocalModeSelection(modeSelection);
    };

    const saveSelections = () => {
        setPeriodRange(localPeriodRange);
        setModeSelection(localModeSelection);
    };

    const onCancelModal = () => {
        onResetPreviousConfig();
        setRangeSelectIsOpen(false);
        setTimeout(() => setIsOpenSettings(false));
    };

    const onSubmitModal = () => {
        saveSelections();
        setRangeSelectIsOpen(false);
        setTimeout(() => setIsOpenSettings(false));
    };

    const footer = [
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginLeft: '32px',
                marginRight: '32px',
            }}
        >
            <Button
                className={buttonStyle}
                type="primary"
                onClick={onResetDefault}
                disabled={selectionsAreUnchangedOrDefault()}
            >
                Reset
            </Button>
            <div>
                <Button key="back" onClick={() => onCancelModal()}>
                    Cancel
                </Button>
                <Button
                    key="submit"
                    type="primary"
                    onClick={() => onSubmitModal()}
                    disabled={selectionsCanApplyChanges()}
                >
                    Apply
                </Button>
            </div>
        </div>,
    ];

    return (
        <Modal
            className={modalStyle}
            title={<h2>Crosstab Settings</h2>}
            open={isOpenSettings}
            onCancel={() => onCancelModal()}
            footer={footer}
        >
            <div style={{ marginLeft: '10px' }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '20px',
                    }}
                >
                    <div
                        style={{
                            display: 'inline-block',
                        }}
                    >
                        {clientModes ? (
                            getComparisonTypeSelect(
                                clientModes,
                                CROSSTAB_DEFAULT_PRIMARY_MODE,
                                localModeSelection[0],
                                setPrimaryLocalModeSelection
                            )
                        ) : (
                            <Skeleton
                                loading={true}
                                active={true}
                                title={false}
                                paragraph={{ rows: 1 }}
                            />
                        )}
                    </div>

                    <div style={{ margin: '0 20px' }}>
                        <span>vs.</span>
                    </div>

                    <div
                        style={{
                            display: 'inline-block',
                        }}
                    >
                        {clientModes ? (
                            getComparisonTypeSelect(
                                clientModes,
                                CROSSTAB_DEFAULT_SECONDARY_MODE,
                                localModeSelection[1],
                                setSecondaryLocalModeSelection
                            )
                        ) : (
                            <Skeleton
                                loading={true}
                                active={true}
                                title={false}
                                paragraph={{ rows: 1 }}
                            />
                        )}
                    </div>
                </div>
            </div>

            <div style={{ marginLeft: '10px' }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '20px',
                    }}
                >
                    <CrosstabRangeSelect
                        onChange={onPeriodRangeChange}
                        isOpen={isRangeSelectOpen}
                        setIsOpen={setRangeSelectIsOpen}
                        periodRange={localPeriodRange ?? asOfDate}
                    />
                </div>
            </div>
        </Modal>
    );
};
