import { API_URL } from 'config/constants';

const deleteLeasingGuidelines = async (id: string | number) => {
    const deleteBy = typeof id === 'string' ? `id=${id}` : `year=${id}`;
    const response = await fetch(`${API_URL}/leasing-guidelines?${deleteBy}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
    });

    if (!response.ok) {
        throw new Error('Failed to leasing guidelines');
    }

    const { data } = await response.json();

    return data;
};

export default deleteLeasingGuidelines;
