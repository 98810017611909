import React, { useEffect, useState } from 'react';
import { Map } from 'immutable';
import Image from './Image';
import { css } from 'emotion';
import { ImageProps } from 'waypoint-types';
import theme from 'config/theme';

interface ImagePickerProps {
    images: ImageProps[];
    multiple?: boolean;
    onPick: (picked: ImageProps[]) => void;
    maxPicks?: number;
    onMaxPicks?: (image: ImageProps) => void;
    defaultPicked?: string[];
    disabled?: boolean;
}

const imagePickerStyles = (disabled?: boolean) => css`
    width: 100%;
    pointer-events: ${disabled ? 'none' : 'auto'};
    opacity: ${disabled ? 0.5 : 1};
    cursor: ${disabled ? 'not-allowed' : 'auto'};
    display: flex;
    flex-wrap: wrap;

    .responsive {
        position: relative;
        margin: 4px 4px;
        opacity: 1;
        cursor: ${disabled ? 'not-allowed' : 'pointer'};

        .thumbnail {
            border: 1px solid #ddd;
            border-radius: 4px;
            padding: 5px;
            width: 90px;
            height: 90px;
            opacity: 1;
            margin: 0px;

            &:hover {
                box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.7);
            }
        }

        .selected {
            border: solid 3px ${theme.colors.blues.antBlue};
        }

        &:hover {
            cursor: pointer;
        }

        .checked {
            display: none;
        }
    }

    .selected {
        opacity: 0.7;

        .checked {
            display: inline-block;
            transition: 0.5s ease;
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }

    .clear {
        clear: both;
    }
`;

const ImagePicker: React.FC<ImagePickerProps> = ({
    images,
    multiple = false,
    onPick,
    maxPicks,
    onMaxPicks,
    defaultPicked = [],
    disabled,
}) => {
    const [picked, setPicked] = useState(
        Map(
            defaultPicked.map((value) => [
                value,
                images.find((image) => image.value === value)?.src,
            ])
        )
    );

    useEffect(() => {
        setPicked(
            Map(
                defaultPicked.map((value) => [
                    value,
                    images.find((image) => image.value === value)?.src,
                ])
            )
        );
    }, [defaultPicked]);

    const handleImageClick = (image: ImageProps) => {
        let pickedImage = multiple ? picked : Map();
        let newerPickedImage: Map<string, string> | undefined;

        if (disabled) {
            return;
        }

        if (pickedImage.has(image.value)) {
            newerPickedImage = pickedImage.delete(image.value) as Map<
                string,
                string
            >;
        }

        if (!pickedImage.has(image.value) && typeof maxPicks === 'undefined') {
            newerPickedImage = pickedImage.set(image.value, image.src) as Map<
                string,
                string
            >;
        }

        if (
            !pickedImage.has(image.value) &&
            typeof maxPicks !== 'undefined' &&
            pickedImage.size < maxPicks
        ) {
            newerPickedImage = pickedImage.set(image.value, image.src) as Map<
                string,
                string
            >;
        }

        if (
            !pickedImage.has(image.value) &&
            typeof maxPicks !== 'undefined' &&
            pickedImage.size >= maxPicks
        ) {
            onMaxPicks?.(image);
        }

        if (newerPickedImage) {
            setPicked(newerPickedImage as Map<string, string>);

            const pickedImageToArray: ImageProps[] = [];
            for (let [value, src] of newerPickedImage) {
                pickedImageToArray.push({ src, value });
            }

            onPick(multiple ? pickedImageToArray : [pickedImageToArray[0]]);
        }
    };

    const renderImage = (image: ImageProps) => (
        <Image
            src={image.src}
            isSelected={picked.has(image.value as string)}
            onImageClick={() => handleImageClick(image)}
            key={image.value}
        />
    );

    return (
        <div className={imagePickerStyles(disabled)}>
            {images.map(renderImage)}
            <div className="clear" />
        </div>
    );
};

export default ImagePicker;
