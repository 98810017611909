import { useGetDelinquency } from 'waypoint-hooks';
import { PDFExportable } from 'waypoint-utils/pdf/PDFExportable';
import React, { useImperativeHandle, useRef, useState } from 'react';
import { EntityReportWidgetPdfSettings } from 'components/reports/ReportUtils';
import AgedReceivablesTable from 'components/agedReceivables/AgedReceivablesTable';
import AgedReceivablesSummary from 'components/agedReceivables/AgedReceivablesSummary';
import { DataGridRef } from 'devextreme-react/cjs/data-grid';
import { DelinquencyData } from 'waypoint-types';
import { numericalSort } from 'utils/tables/sorters';

interface AgedReceivablesEntityReportWidgetParams {
    entityCode: string;
    widgetId: string;
    widgetSettings: { [key: string]: any };
    isPDFExport: boolean;
    pdfSettings?: EntityReportWidgetPdfSettings;
}

export const AgedReceivablesEntityReportWidget = React.forwardRef<
    PDFExportable,
    AgedReceivablesEntityReportWidgetParams
>(
    (
        {
            entityCode,
            widgetId,
            widgetSettings,
            isPDFExport,
            pdfSettings,
        }: AgedReceivablesEntityReportWidgetParams,
        ref,
    ) => {
        const chartDataGridRef = useRef<DataGridRef<any, any>>(null);
        const dataGridRef = useRef<DataGridRef<any, any>>(null);

        // if chart isn't included, set this to true from the start so the widget will export
        const [isChartReadyToExport, setIsChartReadyToExport] =
            useState<boolean>(!widgetSettings?.includeRecurringChargeSummary);

        const {
            data: delinquency,
            isLoading: isDelinquencyLoading,
            isValidating: isDelinquencyValidating,
        } = useGetDelinquency(
            [entityCode],
            {
                stakeholder: null,
                percentageType: null,
            },
            true,
            widgetSettings?.period,
        );

        useImperativeHandle(ref, () => ({
            isReadyToExport(): boolean {
                return (
                    !isDelinquencyLoading &&
                    !isDelinquencyValidating &&
                    isChartReadyToExport
                );
            },
        }));

        if (!delinquency) {
            return <div />;
        }

        const { chart_table_data, master_table_data } = delinquency;

        const sortedMasterTableData = master_table_data.sort(
            (a: DelinquencyData, b: DelinquencyData) =>
                numericalSort(b.total, a.total),
        );

        const sortedChartTableData = chart_table_data.sort(
            (a: DelinquencyData, b: DelinquencyData) =>
                numericalSort(b.total, a.total),
        );

        return (
            <div style={{ marginRight: '10px' }}>
                {widgetSettings?.includeRecurringChargeSummary && (
                    <AgedReceivablesSummary
                        chartTableData={sortedChartTableData}
                        widgetId={widgetId}
                        dataGridRef={chartDataGridRef}
                        setIsChartReadyToExport={setIsChartReadyToExport}
                        isPDFExport={isPDFExport}
                        pdfSettings={pdfSettings}
                    />
                )}
                <AgedReceivablesTable
                    delinquencies={sortedMasterTableData}
                    entityCodes={[entityCode]}
                    isPropertyProfilePage={true}
                    isReportWidget={true}
                    isPDFExport={isPDFExport}
                />
            </div>
        );
    },
);
