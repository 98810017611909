import { API_URL } from 'config/constants';

const getThresholdRulesByEntityCodes = async (entityCodes: string[]) => {
    const response = await fetch(
        `${API_URL}/comparative-income-statement/entity-threshold-rules`,
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(entityCodes),
        }
    );

    if (!response.ok) {
        throw new Error('Failed to get threshold rules');
    }

    const { data } = await response.json();

    return data;
};

export default getThresholdRulesByEntityCodes;
