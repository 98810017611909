// Default internalTheme definition

/*
    Read more at:
    https://waypointbuilding.atlassian.net/wiki/spaces/HER/pages/1085702336/HER-4471+Application+internalTheme+Definition

 */

const internalTheme = {
    // space	margin, margin-top, margin-right, margin-bottom, margin-left, padding, padding-top, padding-right, padding-bottom, padding-left, grid-gap, grid-column-gap, grid-row-gap
    space: [0, 2, 4, 8, 16, 32, 64],

    // fontSizes	font-size
    fontSizes: {
        small: '12px',
        body: '16px',
        heading: '24px',
        display: '28px',
    },

    // colors	color, background-color, border-color
    colors: {
        transparent: 'rgba(0, 0, 0, 0)',
        white: '#fff',
        black: '#000',
        blues: [
            '#32a3dd',
            '#2d5f79',
            '#313c51',
            '#37a3dd',
            '#40a9ff',
            '#03a9f42b',
            '#00ade2',
            '#1677ff',
            '#e6f4ff',
        ],
        // TODO (daniel): Maybe rename to grayscale and add black and white to it?
        // TODO (daniel): Streamline these grays
        grays: [
            '#F2F2F2',
            '#d8d8d8',
            '#cccccc',
            '#a8a8a8',
            '#9c9c9c',
            '#5a5a5a',
            '#4a4a4a',
        ],
        reds: ['#DF0101'],
        greens: ['#00C854'],
        oranges: ['#F5A623'],
        marigold: '#F9CB79',
        lightMarigold: '#FFEBAE',
        charts: {
            // TODO (daniel): "categories" and "barCharts" look similar. Discuss a potential merge
            barCharts: [
                '#4888B8',
                '#EAAB63',
                '#F9E096',
                '#99CDA7',
                '#CCDC9F',
                '#eaa221',
                '#3BB3E4',
                '#CCDC9F',
            ],
            categories: [
                '#F3A954',
                '#F9CB79',
                '#FCE08D',
                '#3588BD',
                '#66C2A5',
                '#8BD0A4',
                '#C8DE98',
                '#DCDF95',
            ],
        },
        workflowAvatarGroups: {
            assignees: ['#184e77', '#1e6091', '#1a759f', '#168aad'],
            reviewers: ['#34a0a4', '#52b69a', '#76c893', '#99d98c'],
        },
        workflowReportStatus: {
            open: '#a8a8a8',
            in_progress: '#40a9ff',
            ready_for_review: '#f9cb79',
            approved: '#00c854',
        },
        overlay: '#182738',
    },

    // fonts	font-family
    fonts: {
        body: '"Lato", "Helvetica Neue", Helvetica, Arial, sans-serif',
        heading: '"Lato", "Helvetica Neue", Helvetica, Arial, sans-serif',
        display: '"Lato", "Helvetica Neue", Helvetica, Arial, sans-serif',
    },

    // fontWeights	font-weight
    fontWeights: {
        light: 300,
        regular: 400,
        bold: 700,
    },

    // lineHeights	line-height
    lineHeights: [0, 2, 4, 8, 16, 32, 64],

    // sizes	width, height, min-width, max-width, min-height, max-height
    sizes: {
        // TODO (daniel): Discuss and implement
    },

    // borderWidths	border-width
    borderWidths: {
        // TODO (daniel): Discuss and implement
    },

    // radii	border-radius
    // NOTE(daniel): Last one intended to be used to define 100% circular shapes
    radii: [0, 2, 4, 8, 100],

    // shadows	box-shadow, text-shadow
    shadows: {
        // NOTE (daniel): Used in Cards
        light: `0px 3px 5px -1px rgba(0,0,0,0.20),
                0px 6px 10px 0px rgba(0,0,0,0.04)`,
    },

    // transitions	transition
    transitions: {},
};

const theme = {
    colors: {
        black: internalTheme.colors.black,
        white: internalTheme.colors.white,
        red: internalTheme.colors.reds[0],
        green: internalTheme.colors.greens[0],
        oranges: {
            marigold: internalTheme.colors.marigold,
            lightMarigold: internalTheme.colors.lightMarigold,
            bright: internalTheme.colors.oranges[0],
        },
        blues: {
            dark: internalTheme.colors.blues[2],
            medium: internalTheme.colors.blues[1],
            primary: internalTheme.colors.blues[0],
            antBlue: internalTheme.colors.blues[3],
            outline: internalTheme.colors.blues[4],
            focusRow: internalTheme.colors.blues[5],
            linkBlue: internalTheme.colors.blues[6],
            activeBlue: internalTheme.colors.blues[7],
            activeBackground: internalTheme.colors.blues[8],
        },
        grays: {
            background: internalTheme.colors.grays[0],
            disabled: internalTheme.colors.grays[1],
            light: internalTheme.colors.grays[2],
            medium: internalTheme.colors.grays[3],
            mediumDark: internalTheme.colors.grays[4],
            dark: internalTheme.colors.grays[5],
            text: internalTheme.colors.grays[6], // darkest
        },
        chartCategories: internalTheme.colors.charts.categories,
        barCharts: internalTheme.colors.charts.barCharts,
        overlay: internalTheme.colors.overlay,
        workflowAvatarGroups: internalTheme.colors.workflowAvatarGroups,
        workflowReportStatus: internalTheme.colors.workflowReportStatus,
    },
    fonts: {
        body: internalTheme.fonts.body,
        heading: internalTheme.fonts.heading,
        display: internalTheme.fonts.display,
    },

    fontWeights: {
        light: internalTheme.fontWeights.light,
        regular: internalTheme.fontWeights.regular,
        bold: internalTheme.fontWeights.bold,
    },

    space: {
        none: internalTheme.space[0],
        small: internalTheme.space[1],
        medium: internalTheme.space[2],
        large: internalTheme.space[3],
        extraLarge: internalTheme.space[5],
    },

    lineHeights: {
        none: internalTheme.lineHeights[0],
        small: internalTheme.lineHeights[1],
        medium: internalTheme.lineHeights[2],
        large: internalTheme.lineHeights[3],
        extraLarge: internalTheme.lineHeights[5],
    },

    radii: {
        none: internalTheme.radii[0],
        small: internalTheme.radii[1],
        medium: internalTheme.radii[2],
        large: internalTheme.radii[3],
        circular: internalTheme.radii[-1],
    },

    shadows: {
        light: internalTheme.shadows.light,
    },
};

export default theme;
